import React, { useEffect, useState } from "react";
import ScholarshipTable from "./ScholarshipTable";
import { useDispatch, useSelector } from "react-redux";
import { Alert, AlertTitle, Box } from "@mui/material";
import { scholarshipApplicationAction } from "../../../actions/LandingPageAction";
import { successTrueMsgAction } from "../../../actions/globalAction";
import Swal from "sweetalert2";
import { SUCCESS_TRUE_MSG_ERR } from "../../../constants/roleManagmentConstant";
import usePrivilegeCheck from "../../../constants/customHooks/UsePrivilegeCheck";
import TableNoItemComponent from "../../customComponent/TableNoItemComponent";

function ScholarshipMain() {
  const { privilege, hasPrivilege } = usePrivilegeCheck();

  const { scholarshipApplicationSuccess } = useSelector(
    (state) => state.scholarshipApplication
  );

  const {
    scholarshipApplicationConvertToLeadSuccess,
    scholarshipApplicationConvertToLeadErr,
  } = useSelector((state) => state.scholarshipApplicationConvertToLead);

  let { successAlertMsgSuccess, successAlertMsgErr } = useSelector((state) => {
    return state.successTrueMsg;
  });

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(scholarshipApplicationAction());
  }, [dispatch, scholarshipApplicationConvertToLeadSuccess]);

  useEffect(() => {
    if (scholarshipApplicationSuccess) {
      setData(scholarshipApplicationSuccess.data);
    }
  }, [scholarshipApplicationSuccess]);

  useEffect(() => {
    if (scholarshipApplicationConvertToLeadSuccess && successAlertMsgSuccess) {
      Swal.fire("Converted!", "converted Successfully.", "success");
      dispatch(successTrueMsgAction(false));
    }
    if (scholarshipApplicationConvertToLeadErr && successAlertMsgErr) {
      Swal.fire(
        "Error!",
        scholarshipApplicationConvertToLeadErr.message,
        "error"
      );
      dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: false });
    }
  }, [
    scholarshipApplicationConvertToLeadSuccess,
    scholarshipApplicationConvertToLeadErr,
    successAlertMsgSuccess,
    successAlertMsgErr,
  ]);
  console.log(
    scholarshipApplicationConvertToLeadErr,
    "scholarshipApplicationConvertToLeadErr",
    scholarshipApplicationConvertToLeadSuccess,
    "scholarshipApplicationConvertToLeadSuccess"
  );
  const columns = [
    { title: "SL NO", field: "sl" },
    { title: "Full Name", field: "fullName" },
    { title: "Phone Number", field: "phoneNumber" },
    { title: "Email", field: "email" },
    { title: "Scholarship", field: "scholarship" },
    { title: "Reason", field: "reason" },
    { title: "Action", field: "action" },
  ];

  const [data, setData] = useState();

  const [ids, setIds] = useState([]);
  const selectBoxIdPassHandler = (ids) => {
    setIds(ids);
  };

  if (!hasPrivilege("scholarship list") && privilege !== null) {
    return (
      <Alert severity="error">
        <AlertTitle>warning</AlertTitle>
        You don't have permission to access — <strong>this page.!</strong>
      </Alert>
    );
  }

  return (
    <Box
      sx={{
        mt: 2,
      }}
    >
      {data?.length > 0 ? (
        <ScholarshipTable
          data={data}
          columns={columns}
          selectBoxIdPassHandler={selectBoxIdPassHandler}
          subMenu="scholarship list"
        />
      ) : (
        <TableNoItemComponent />
      )}
    </Box>
  );
}

export default ScholarshipMain;
