import { Alert, AlertTitle, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import CourseMajorAdd from "./courseMajorAdd";
import { successTrueMsgAction } from "../../../actions/globalAction";
import { SUCCESS_TRUE_MSG_ERR } from "../../../constants/roleManagmentConstant";
import { courseMasterFindAction } from "../../../actions/courseMasterAction";
import CourseMajorEdit from "./courseMajorEdit";
import usePrivilegeCheck from "../../../constants/customHooks/UsePrivilegeCheck";
import PremissionDialog from "../../customComponent/premissionDialog";
import { MasterTable } from "../../setting/master/MasterTable";

function CourseMajorMain({ subMenu }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    privilege,
    hasPrivilege,
    isCreatePrevilage,
    isUpdatePrevilage,
  } = usePrivilegeCheck();

  const [courseMajor, setCourseMajor] = useState([]);
  const [open, setOpen] = useState(false);
  const [pageCount, setPageCount] = useState(1);
  const [edit, setEdit] = useState(false);
  const [id, setId] = useState("");
  const [openAlert, setOpenAlert] = useState(false);

  // handle close

  const handleClose = () => {
    setOpen(false);
  };

  const handleEditClose = () => {
    setEdit(false);
  };

  const handlePagination = (count) => {
    setPageCount(count);
  };

  const handleEdit = (id) => {
    setEdit(true);
    setId(id);
  };

  // HandleClose dialog

  const handleCloseDialog = () => {
    setOpenAlert(false);
  };

  // Response

  const { courseMajorFindSuccess, courseMajorFindErr } = useSelector(
    (state) => {
      return state.courseMajorMasterFind;
    }
  );

  // Success msg

  const { successAlertMsgSuccess, successAlertMsgErr } = useSelector(
    (state) => {
      return state.successTrueMsg;
    }
  );

  const { courseMajorCreateSuccess, courseMajorCreateErr } = useSelector(
    (state) => {
      return state.courseMajorMasterCreate;
    }
  );

  const { courseMajorUpdateSuccess, courseMajorUpdateErr } = useSelector(
    (state) => {
      return state.courseMajorMasterUpdate;
    }
  );
  const { courseMajorDeleteSuccess, courseMajorDeleteErr } = useSelector(
    (state) => {
      return state.courseMajorMasterDelete;
    }
  );

  useEffect(() => {
    if (successAlertMsgSuccess && courseMajorCreateSuccess?.status == true) {
      Swal.fire("Good job!", `created successfully`, "success");
      dispatch(successTrueMsgAction(false));
    }
    if (successAlertMsgSuccess && courseMajorCreateSuccess?.status == false) {
      Swal.fire("Oops!", `Already exist !`, "error");
      dispatch(successTrueMsgAction(false));
    }
    if (successAlertMsgErr && courseMajorCreateErr) {
      Swal.fire("Oops!", `Failed to create`, "error");
      dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: false });
    }
  }, [
    successAlertMsgSuccess,
    successAlertMsgErr,
    courseMajorCreateSuccess,
    courseMajorCreateErr,
    dispatch,
  ]);

  // Success msg for updated courseMaster

  useEffect(() => {
    if (successAlertMsgSuccess && courseMajorUpdateSuccess?.status == true) {
      Swal.fire("Good job!", `updated successfully`, "success");
      dispatch(successTrueMsgAction(false));
    }
    if (successAlertMsgSuccess && courseMajorUpdateSuccess?.status == false) {
      Swal.fire("Oops!", `Already exist !`, "error");
      dispatch(successTrueMsgAction(false));
    }
    if (successAlertMsgErr && courseMajorUpdateErr) {
      Swal.fire("Oops!", `Failed to create`, "error");
      dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: false });
    }
  }, [
    successAlertMsgSuccess,
    successAlertMsgErr,
    courseMajorUpdateSuccess,
    courseMajorUpdateErr,
    dispatch,
  ]);

  //find courseMajor list

  useEffect(() => {
    dispatch(courseMasterFindAction(pageCount));
  }, [
    dispatch,
    courseMajorCreateSuccess,
    courseMajorUpdateSuccess,
    courseMajorDeleteSuccess,
  ]);

  useEffect(() => {
    if (courseMajorFindSuccess) {
      setCourseMajor(courseMajorFindSuccess.data);
    }
  }, [courseMajorFindSuccess]);

  const data = courseMajor?.map((data, index) => ({
    id: data.id,
    sl: index + 1,
    column1: data.majorName,
    column2: data.courseMajorId,
    column3: data.icon,
  }));

  const columns = [
    {
      title: "SL NO",
      field: "sl",
      // type: "numeric",
      // hidden: true,
      headerStyle: { backgroundColor: "#F9F9FB" },
    },
    {
      title: "MAJOR'S NAME",
      field: "MAJOR'S NAME",
      //   hidden: showEmployeeNameColumn,
      headerStyle: { backgroundColor: "#F9F9FB" },
    },
    {
      title: "MAJOR ID",
      field: "MAJOR ID",
      //   hidden: showEmployeeNameColumn,
      headerStyle: { backgroundColor: "#F9F9FB" },
    },
    {
      title: "ICON",
      field: "ICON",
      //   hidden: showEmployeeNameColumn,
      headerStyle: { backgroundColor: "#F9F9FB" },
    },

    {
      title: "ACTION",
      field: "ACTION",
      //   hidden: showEmployeeNameColumn,
      headerStyle: { backgroundColor: "#F9F9FB" },
    },
  ];

  // Handle edit courseMajor

  if (!hasPrivilege("Course Master") && privilege !== null) {
    return (
      <Alert severity="error">
        <AlertTitle>warning</AlertTitle>
        You don't have permission to access — <strong>this page.!</strong>
      </Alert>
    );
  }

  console.log(
    hasPrivilege("Course Master"),
    "hasPrivilege",
    privilege,
    "hasPrivilegehasPrivilegehasPrivilege"
  );

  return (
    <>
      <Button
        sx={{
          color: "white",
          backgroundColor: "#141E3C",
          height: "38px",
          width: "143px",
          fontWeight: 700,
          fontSize: "12px",
          display: "flex",
          marginLeft: "auto",
          borderRadius: "8px",
          "&:hover": {
            backgroundColor: "rgba(20, 30, 60, 0.9)",
          },
        }}
        onClick={() => {
          if (isCreatePrevilage("Course Master")) {
            setOpen(true);
          } else {
            setOpenAlert(true);
          }
        }}
      >
        Add New
      </Button>

      <div style={{ marginTop: "15px" }}></div>

      <MasterTable
        data={data}
        columns={columns}
        component="courseMajorMaster"
        rollEditHandler={handleEdit}
        pagination={handlePagination}
        subMenu="Course Master"
      />

      {/* courseMajor add popup */}

      {open && <CourseMajorAdd open={open} handleClose={handleClose} />}

      {edit && (
        <CourseMajorEdit open={edit} handleClose={handleEditClose} id={id} />
      )}

      {openAlert && (
        <PremissionDialog
          openAlert={openAlert}
          handleClose={handleCloseDialog}
        />
      )}
    </>
  );
}

export default CourseMajorMain;
