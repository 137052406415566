export const CITY_MASTER_ADD_REQUEST = "CITY_MASTER_ADD_REQUEST";
export const CITY_MASTER_ADD_SUCCESS = "CITY_MASTER_ADD_SUCCESS";
export const CITY_MASTER_ADD_ERR = "CITY_MASTER_ADD_ERR";

export const CITY_MASTER_FIND_REQUEST = "CITY_MASTER_FIND_REQUEST";
export const CITY_MASTER_FIND_SUCCESS = "CITY_MASTER_FIND_SUCCESS";
export const CITY_MASTER_FIND_ERR = "CITY_MASTER_FIND_ERR";

export const CITY_MASTER_DELETE_REQUEST = "CITY_MASTER_DELETE_REQUEST";
export const CITY_MASTER_DELETE_SUCCESS = "CITY_MASTER_DELETE_SUCCESS";
export const CITY_MASTER_DELETE_ERR = "CITY_MASTER_DELETE_ERR";

export const CITY_MASTER_EDIT_FIND_REQUEST = "CITY_MASTER_EDIT_FIND_REQUEST";
export const CITY_MASTER_EDIT_FIND_SUCCESS = "CITY_MASTER_EDIT_FIND_SUCCESS";
export const CITY_MASTER_EDIT_FIND_ERR = "CITY_MASTER_EDIT_FIND_ERR";

export const CITY_MASTER_EDIT_REQUEST = "CITY_MASTER_EDIT_REQUEST";
export const CITY_MASTER_EDIT_SUCCESS = "CITY_MASTER_EDIT_SUCCESS";
export const CITY_MASTER_EDIT_ERR = "CITY_MASTER_EDIT_ERR";
