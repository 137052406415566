import React from 'react'
import Lottie from "react-lottie";
import Animation from "../extraComponents/uploadingAnimation.json";
import { Box } from '@mui/material';


 const defaultOptions = {
   loop: true,
   autoplay: true,
   animationData: Animation,
   rendererSettings: {
     preserveAspectRatio: "xMidYMid slice",
   },
 };

function Upload() {
  return (
    <Box sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minHeight:"100vh",
        backgroundColor: "rgba(0,0,0,0.3)",
        position: "fixed",
        top:"0",
        bottom:"0",
        right:"0",
        left:"0",
        zIndex: 1000
    }}>
      <Lottie options={defaultOptions} height={200} width={200} />
    </Box>
  );
}

export default Upload