export const COUNTRY_DOC_ADD_REQUEST = "COUNTRY_DOC_ADD_REQUEST";
export const COUNTRY_DOC_ADD_SUCCESS = "COUNTRY_DOC_ADD_SUCCESS";
export const COUNTRY_DOC_ADD_ERR = "COUNTRY_DOC_ADD_ERR";

export const COUNTRY_DOC_FIND_REQUEST = "COUNTRY_DOC_FIND_REQUEST";
export const COUNTRY_DOC_FIND_SUCCESS = "COUNTRY_DOC_FIND_SUCCESS";
export const COUNTRY_DOC_FIND_ERR = "COUNTRY_DOC_FIND_ERR";

export const COUNTRY_DOC_DELETE_REQUEST = "COUNTRY_DOC_DELETE_REQUEST";
export const COUNTRY_DOC_DELETE_SUCCESS = "COUNTRY_DOC_DELETE_SUCCESS";
export const COUNTRY_DOC_DELETE_ERR = "COUNTRY_DOC_DELETE_ERR";

export const ADD_DOCUMENT_OF_COUNTRY_REQUEST =
  "ADD_DOCUMENT_OF_COUNTRY_REQUEST";
export const ADD_DOCUMENT_OF_COUNTRY_SUCCESS =
  "ADD_DOCUMENT_OF_COUNTRY_SUCCESS";
export const ADD_DOCUMENT_OF_COUNTRY_ERR = "ADD_DOCUMENT_OF_COUNTRY_ERR";

export const FIND_DOCUMENT_OF_COUNTRY_REQUEST =
  "FIND_DOCUMENT_OF_COUNTRY_REQUEST";
export const FIND_DOCUMENT_OF_COUNTRY_SUCCESS =
  "FIND_DOCUMENT_OF_COUNTRY_SUCCESS";
export const FIND_DOCUMENT_OF_COUNTRY_ERR = "FIND_DOCUMENT_OF_COUNTRY_ERR";

export const DELETE_SOP_DOCUMENT_OF_COUNTRY_REQUEST =
  "DELETE_SOP_DOCUMENT_OF_COUNTRY_REQUEST";
export const DELETE_SOP_DOCUMENT_OF_COUNTRY_SUCCESS =
  "DELETE_SOP_DOCUMENT_OF_COUNTRY_SUCCESS";
export const DELETE_SOP_DOCUMENT_OF_COUNTRY_ERR =
  "FIND_DOCUMENT_OF_COUNTRY_ERR";

// travel
export const ADD_TRAVEL_DOCUMENT_OF_COUNTRY_REQUEST =
  "ADD_TRAVEL_DOCUMENT_OF_COUNTRY_REQUEST";
export const ADD_TRAVEL_DOCUMENT_OF_COUNTRY_SUCCESS =
  "ADD_TRAVEL_DOCUMENT_OF_COUNTRY_SUCCESS";
export const ADD_TRAVEL_DOCUMENT_OF_COUNTRY_ERR =
  "ADD_TRAVEL_DOCUMENT_OF_COUNTRY_ERR";

export const FIND_TRAVEL_DOCUMENT_OF_COUNTRY_REQUEST =
  "FIND_TRAVEL_DOCUMENT_OF_COUNTRY_REQUEST";
export const FIND_TRAVEL_DOCUMENT_OF_COUNTRY_SUCCESS =
  "FIND_TRAVEL_DOCUMENT_OF_COUNTRY_SUCCESS";
export const FIND_TRAVEL_DOCUMENT_OF_COUNTRY_ERR =
  "FIND_TRAVEL_DOCUMENT_OF_COUNTRY_ERR";

export const DELETE_TRAVEL_DOCUMENT_OF_COUNTRY_REQUEST =
  "DELETE_TRAVEL_DOCUMENT_OF_COUNTRY_REQUEST";
export const DELETE_TRAVEL_DOCUMENT_OF_COUNTRY_SUCCESS =
  "DELETE_TRAVEL_DOCUMENT_OF_COUNTRY_SUCCESS";
export const DELETE_TRAVEL_DOCUMENT_OF_COUNTRY_ERR =
  "DELETE_TRAVEL_DOCUMENT_OF_COUNTRY_ERR";

// Guidance documents

export const ADD_GUIDANCE_DOCUMENT_OF_COUNTRY_REQUEST =
  "ADD_GUIDANCE_DOCUMENT_OF_COUNTRY_REQUEST";
export const ADD_GUIDANCE_DOCUMENT_OF_COUNTRY_SUCCESS =
  "ADD_GUIDANCE_DOCUMENT_OF_COUNTRY_SUCCESS";
export const ADD_GUIDANCE_DOCUMENT_OF_COUNTRY_ERR =
  "ADD_GUIDANCE_DOCUMENT_OF_COUNTRY_ERR";

// for country
export const ADD_GUIDANCE_DOCUMENT_FOR_COUNTRY_REQUEST =
  "ADD_GUIDANCE_DOCUMENT_FOR_COUNTRY_REQUEST";
export const ADD_GUIDANCE_DOCUMENT_FOR_COUNTRY_SUCCESS =
  "ADD_GUIDANCE_DOCUMENT_FOR_COUNTRY_SUCCESS";
export const ADD_GUIDANCE_DOCUMENT_FOR_COUNTRY_ERR =
  "ADD_GUIDANCE_DOCUMENT_FOR_COUNTRY_ERR";

export const FIND_GUIDANCE_DOCUMENT_OF_COUNTRY_REQUEST =
  "FIND_GUIDANCE_DOCUMENT_OF_COUNTRY_REQUEST";
export const FIND_GUIDANCE_DOCUMENT_OF_COUNTRY_SUCCESS =
  "FIND_GUIDANCE_DOCUMENT_OF_COUNTRY_SUCCESS";
export const FIND_GUIDANCE_DOCUMENT_OF_COUNTRY_ERR =
  "FIND_GUIDANCE_DOCUMENT_OF_COUNTRY_ERR";

// for country guidance
export const FIND_GUIDANCE_DOCUMENT_FOR_COUNTRY_REQUEST =
  "FIND_GUIDANCE_DOCUMENT_FOR_COUNTRY_REQUEST";
export const FIND_GUIDANCE_DOCUMENT_FOR_COUNTRY_SUCCESS =
  "FIND_GUIDANCE_DOCUMENT_FOR_COUNTRY_SUCCESS";
export const FIND_GUIDANCE_DOCUMENT_FOR_COUNTRY_ERR =
  "FIND_GUIDANCE_DOCUMENT_FOR_COUNTRY_ERR";

export const DELETE_GUIDANCE_DOCUMENT_OF_COUNTRY_REQUEST =
  "DELETE_GUIDANCE_DOCUMENT_OF_COUNTRY_REQUEST";
export const DELETE_GUIDANCE_DOCUMENT_OF_COUNTRY_SUCCESS =
  "DELETE_GUIDANCE_DOCUMENT_OF_COUNTRY_SUCCESS";
export const DELETE_GUIDANCE_DOCUMENT_OF_COUNTRY_ERR =
  "DELETE_GUIDANCE_DOCUMENT_OF_COUNTRY_ERR";

// Admission
export const ADD_ADMISSION_DOCUMENT_OF_COUNTRY_REQUEST =
  "ADD_ADMISSION_DOCUMENT_OF_COUNTRY_REQUEST";
export const ADD_ADMISSION_DOCUMENT_OF_COUNTRY_SUCCESS =
  "ADD_ADMISSION_DOCUMENT_OF_COUNTRY_SUCCESS";
export const ADD_ADMISSION_DOCUMENT_OF_COUNTRY_ERR =
  "ADD_ADMISSION_DOCUMENT_OF_COUNTRY_ERR";

export const ADD_ADMISSION_PROCEDURE_OF_COUNTRY_REQUEST =
  "ADD_ADMISSION_PROCEDURE_OF_COUNTRY_REQUEST";
export const ADD_ADMISSION_PROCEDURE_OF_COUNTRY_SUCCESS =
  "ADD_ADMISSION_PROCEDURE_OF_COUNTRY_SUCCESS";
export const ADD_ADMISSION_PROCEDURE_OF_COUNTRY_ERR =
  "ADD_ADMISSION_PROCEDURE_OF_COUNTRY_ERR";

export const ADD_COST_ESTIMATE_OF_COUNTRY_REQUEST =
  "ADD_COST_ESTIMATE_OF_COUNTRY_REQUEST";
export const ADD_COST_ESTIMATE_OF_COUNTRY_SUCCESS =
  "ADD_COST_ESTIMATE_OF_COUNTRY_SUCCESS";
export const ADD_COST_ESTIMATE_OF_COUNTRY_ERR =
  "ADD_COST_ESTIMATE_OF_COUNTRY_ERR";

export const FIND_ADMISSION_DOCUMENT_OF_COUNTRY_REQUEST =
  "FIND_ADMISSION_DOCUMENT_OF_COUNTRY_REQUEST";
export const FIND_ADMISSION_DOCUMENT_OF_COUNTRY_SUCCESS =
  "FIND_ADMISSION_DOCUMENT_OF_COUNTRY_SUCCESS";
export const FIND_ADMISSION_DOCUMENT_OF_COUNTRY_ERR =
  "FIND_ADMISSION_DOCUMENT_OF_COUNTRY_ERR";

export const FIND_ADMISSION_PROCEDURE_OF_COUNTRY_REQUEST =
  "FIND_ADMISSION_PROCEDURE_OF_COUNTRY_REQUEST";
export const FIND_ADMISSION_PROCEDURE_OF_COUNTRY_SUCCESS =
  "FIND_ADMISSION_PROCEDURE_OF_COUNTRY_SUCCESS";
export const FIND_ADMISSION_PROCEDURE_OF_COUNTRY_ERR =
  "FIND_ADMISSION_PROCEDURE_OF_COUNTRY_ERR";

export const FIND_COST_ESTIMATE_OF_COUNTRY_REQUEST =
  "FIND_COST_ESTIMATE_OF_COUNTRY_REQUEST";
export const FIND_COST_ESTIMATE_OF_COUNTRY_SUCCESS =
  "FIND_COST_ESTIMATE_OF_COUNTRY_SUCCESS";
export const FIND_COST_ESTIMATE_OF_COUNTRY_ERR =
  "FIND_COST_ESTIMATE_OF_COUNTRY_ERR";

export const DELETE_ADMISSION_DOCUMENT_OF_COUNTRY_REQUEST =
  "DELETE_ADMISSION_DOCUMENT_OF_COUNTRY_REQUEST";
export const DELETE_ADMISSION_DOCUMENT_OF_COUNTRY_SUCCESS =
  "DELETE_ADMISSION_DOCUMENT_OF_COUNTRY_SUCCESS";
export const DELETE_ADMISSION_DOCUMENT_OF_COUNTRY_ERR =
  "DELETE_ADMISSION_DOCUMENT_OF_COUNTRY_ERR";

export const DELETE_ADMISSION_PROCEDURE_OF_COUNTRY_REQUEST =
  "DELETE_ADMISSION_PROCEDURE_OF_COUNTRY_REQUEST";
export const DELETE_ADMISSION_PROCEDURE_OF_COUNTRY_SUCCESS =
  "DELETE_ADMISSION_PROCEDURE_OF_COUNTRY_SUCCESS";
export const DELETE_ADMISSION_PROCEDURE_OF_COUNTRY_ERR =
  "DELETE_ADMISSION_PROCEDURE_OF_COUNTRY_ERR";

export const DELETE_COST_ESTIMATE_OF_COUNTRY_REQUEST =
  "DELETE_COST_ESTIMATE_OF_COUNTRY_REQUEST";
export const DELETE_COST_ESTIMATE_OF_COUNTRY_SUCCESS =
  "DELETE_COST_ESTIMATE_OF_COUNTRY_SUCCESS";
export const DELETE_COST_ESTIMATE_OF_COUNTRY_ERR =
  "DELETE_COST_ESTIMATE_OF_COUNTRY_ERR";

// crime and medical
export const ADD_CRIME_MEDICAL_DOCUMENT_OF_COUNTRY_REQUEST =
  "ADD_CRIME_MEDICAL_DOCUMENT_OF_COUNTRY_REQUEST";
export const ADD_CRIME_MEDICAL_DOCUMENT_OF_COUNTRY_SUCCESS =
  "ADD_CRIME_MEDICAL_DOCUMENT_OF_COUNTRY_SUCCESS";
export const ADD_CRIME_MEDICAL_DOCUMENT_OF_COUNTRY_ERR =
  "ADD_CRIME_MEDICAL_DOCUMENT_OF_COUNTRY_ERR";

export const FIND_CRIME_DOCUMENT_OF_COUNTRY_REQUEST =
  "FIND_CRIME_DOCUMENT_OF_COUNTRY_REQUEST";
export const FIND_CRIME_DOCUMENT_OF_COUNTRY_SUCCESS =
  "FIND_CRIME_DOCUMENT_OF_COUNTRY_SUCCESS";
export const FIND_CRIME_DOCUMENT_OF_COUNTRY_ERR =
  "FIND_CRIME_DOCUMENT_OF_COUNTRY_ERR";

export const DELETE_CRIME_MEDICAL_DOCUMENT_OF_COUNTRY_REQUEST =
  "DELETE_CRIME_MEDICAL_DOCUMENT_OF_COUNTRY_REQUEST";
export const DELETE_CRIME_MEDICAL_DOCUMENT_OF_COUNTRY_SUCCESS =
  "DELETE_CRIME_MEDICAL_DOCUMENT_OF_COUNTRY_SUCCESS";
export const DELETE_CRIME_MEDICAL_DOCUMENT_OF_COUNTRY_ERR =
  "DELETE_CRIME_MEDICAL_DOCUMENT_OF_COUNTRY_ERR";

export const FIND_MEDICAL_DOCUMENT_OF_COUNTRY_REQUEST =
  "FIND_MEDICAL_DOCUMENT_OF_COUNTRY_REQUEST";
export const FIND_MEDICAL_DOCUMENT_OF_COUNTRY_SUCCESS =
  "FIND_MEDICAL_DOCUMENT_OF_COUNTRY_SUCCESS";
export const FIND_MEDICAL_DOCUMENT_OF_COUNTRY_ERR =
  "FIND_MEDICAL_DOCUMENT_OF_COUNTRY_ERR";

export const DELETE_MEDICAL_DOCUMENT_OF_COUNTRY_REQUEST =
  "DELETE_MEDICAL_DOCUMENT_OF_COUNTRY_REQUEST";
export const DELETE_MEDICAL_DOCUMENT_OF_COUNTRY_SUCCESS =
  "DELETE_MEDICAL_DOCUMENT_OF_COUNTRY_SUCCESS";
export const DELETE_MEDICAL_DOCUMENT_OF_COUNTRY_ERR =
  "DELETE_MEDICAL_DOCUMENT_OF_COUNTRY_ERR";

export const PAYMENT_GETWAY_ADD_OF_COUNTRY_REQUEST =
  "PAYMENT_GETWAY_ADD_OF_COUNTRY_REQUEST";
export const PAYMENT_GETWAY_ADD_OF_COUNTRY_SUCCESS =
  "PAYMENT_GETWAY_ADD_OF_COUNTRY_SUCCESS";
export const PAYMENT_GETWAY_ADD_OF_COUNTRY_ERR =
  "PAYMENT_GETWAY_ADD_OF_COUNTRY_ERR";
