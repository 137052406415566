import { Box, MenuItem, Select, Typography } from "@mui/material";
import React from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

function LeadCharts() {
  const data = [
    {
      name: "Page A",
      tl: 4000,
      wl: 2400,
    },
    {
      name: "Page B",
      tl: 3000,
      wl: 1398,
    },
    {
      name: "Page C",
      tl: 2000,
      wl: 9800,
    },
    {
      name: "Page D",
      tl: 2780,
      wl: 3908,
    },
    {
      name: "Page E",
      tl: 1890,
      wl: 4800,
    },
    {
      name: "Page F",
      tl: 2390,
      wl: 3800,
    },
    {
      name: "Page G",
      tl: 3490,
      wl: 4300,
    },
    {
      name: "Page A",
      tl: 4000,
      wl: 2400,
    },
    {
      name: "Page B",
      tl: 3000,
      wl: 1398,
    },
    {
      name: "Page C",
      tl: 2000,
      wl: 4800,
    },
  ];

  return (
    <Box
      sx={{
        backgroundColor: "rgba(255,255,255)",
        p: "20px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: "20px",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography
          sx={{
            color: "#05050FBF",
            fontSize: "16px",
          }}
        >
          Leads
        </Typography>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          defaultValue={10}
          sx={{
            borderRadius: "30px",
            "& .MuiOutlinedInput-input": {
              p: "5px",
              pl: "15px",
              pr: "45px !important",
            },
          }}
        >
          <MenuItem value={10}>Daily</MenuItem>
        </Select>
      </Box>
      <Box
        // width="100%"
        // height="100%"
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: "24px",
        }}
      >
        <Box
          sx={{
            pl: "20px",
          }}
        >
          <Box>
            <Typography
              sx={{
                fontSize: "24px",
                lineHeight: "24px",
                color: "#05050F",
                fontWeight: "700",
              }}
            >
              243
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
              <Box
                sx={{
                  width: "12px",
                  borderRadius: "50%",
                  height: "12px",
                  background: "#FE0B7A",
                }}
              ></Box>
              <Typography
                sx={{
                  fontSize: "16px",
                  lineHeight: "24px",
                  color: "rgba(5, 5, 15, 0.75)",
                }}
              >
                New Leads
              </Typography>
            </Box>
          </Box>

          <Box sx={{ mt: "43px" }}>
            <Typography
              sx={{
                fontSize: "24px",
                lineHeight: "24px",
                color: "#05050F",
                fontWeight: "700",
              }}
            >
              243
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
              <Box
                sx={{
                  width: "12px",
                  borderRadius: "50%",
                  height: "12px",
                  background: "#2D2F7B",
                }}
              ></Box>
              <Typography
                sx={{
                  fontSize: "16px",
                  lineHeight: "24px",
                  color: "rgba(5, 5, 15, 0.75)",
                }}
              >
                Warm Lead
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            height: "300px",
            width: "100%",
          }}
        >
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              width={500}
              height={300}
              data={data}
              margin={{
                top: 20,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid vertical={false} />
              <Tooltip />
              <Legend />
              <Bar barSize={12} dataKey="tl" stackId="a" fill="#2D2F7B" />
              <Bar barSize={12} dataKey="wl" stackId="a" fill="#FE0B7A" />
            </BarChart>
          </ResponsiveContainer>
        </Box>
      </Box>
    </Box>
  );
}

export default LeadCharts;
