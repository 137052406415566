import { Box, Typography } from '@mui/material'
import React from 'react'

function PartnerKycHead({ active, pageNumber,order,title}) {
  return (
    <Box
      sx={{
        background: active ? "#ECECEC" : "#fff",
        border: "1px solid #ECECEC",
        borderBottom: active ? "5px solid #141E3C" : "#1px solid #ECECEC",
        padding: {
          xs:"35px 10px",
          lg:"35px",
        },
        cursor: "pointer",
      }}
      onClick={() => pageNumber(order)}
    >
      <Box
        sx={{
          background: "#141E3C",
          borderRadius: "20px",
          width: "fit-content",
          margin: "auto",
          padding: "0 10px",
          mb: "16px",
        }}
      >
        <Typography
          sx={{
            fontSize: "10px",
            lineHeight: "24px",
            color: "#fff",
            textAlign: "center",
            width: "100%",
            fontFamily: "Open Sans  !important",
          }}
        >
          step {order}
        </Typography>
      </Box>
      <Typography
        sx={{
          fontSize: "20px",
          lineHeight: "24px",
          color: "#05050F",
          textAlign: "center",
          width: "100%",
          fontFamily: "Montserrat !important",
        }}
      >
        {title}
      </Typography>
    </Box>
  );
}

export default PartnerKycHead