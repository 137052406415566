import { Box, Typography } from "@mui/material";
import React from "react";

function ApplicationDocumentsComponent({ item }) {
  const handleDownload = (content, filename) => {
    const blob = new Blob([content], { type: "application/zip" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = filename || "file";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  const showContent = (item) => {
    // let pdfMatchResult = item?.url?.match(/\/([^\/?#]+)\.pdf/i);
    // let zipMatchResult = item?.url?.match(/\/([^\/?#]+)\.zip/i);
    // if (pdfMatchResult && pdfMatchResult.length > 0 ) {
    return (
      <>
        <Box>
          <svg
            width="48"
            height="62"
            viewBox="0 0 48 62"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M47.6133 16.3477L34.8984 12.7148L31.2656 0H5.83594C2.82639 0 0.386719 2.43968 0.386719 5.44922V56.5508C0.386719 59.5603 2.82639 62 5.83594 62H42.1641C45.1736 62 47.6133 59.5603 47.6133 56.5508V16.3477Z"
              fill="#4086F4"
            />
            <path
              d="M47.6133 16.3477V56.5508C47.6133 59.5603 45.1736 62 42.1641 62H24V0H31.2656L34.8984 12.7148L47.6133 16.3477Z"
              fill="#4175DF"
            />
            <path
              d="M47.6133 16.3477H34.8984C32.9004 16.3477 31.2656 14.7129 31.2656 12.7148V0C31.7379 0 32.2102 0.181641 32.537 0.545043L47.0682 15.0763C47.4316 15.4031 47.6133 15.8754 47.6133 16.3477Z"
              fill="#80AEF8"
            />
            <path
              d="M34.8984 29.1836H13.1016C12.0976 29.1836 11.2852 28.3712 11.2852 27.3672C11.2852 26.3632 12.0976 25.5508 13.1016 25.5508H34.8984C35.9024 25.5508 36.7148 26.3632 36.7148 27.3672C36.7148 28.3712 35.9024 29.1836 34.8984 29.1836Z"
              fill="#FFF5F5"
            />
            <path
              d="M34.8984 36.4492H13.1016C12.0976 36.4492 11.2852 35.6368 11.2852 34.6328C11.2852 33.6288 12.0976 32.8164 13.1016 32.8164H34.8984C35.9024 32.8164 36.7148 33.6288 36.7148 34.6328C36.7148 35.6368 35.9024 36.4492 34.8984 36.4492Z"
              fill="#FFF5F5"
            />
            <path
              d="M34.8984 43.7148H13.1016C12.0976 43.7148 11.2852 42.9024 11.2852 41.8984C11.2852 40.8944 12.0976 40.082 13.1016 40.082H34.8984C35.9024 40.082 36.7148 40.8944 36.7148 41.8984C36.7148 42.9024 35.9024 43.7148 34.8984 43.7148Z"
              fill="#FFF5F5"
            />
            <path
              d="M27.6328 50.9805H13.1016C12.0976 50.9805 11.2852 50.1681 11.2852 49.1641C11.2852 48.1601 12.0976 47.3477 13.1016 47.3477H27.6328C28.6368 47.3477 29.4492 48.1601 29.4492 49.1641C29.4492 50.1681 28.6368 50.9805 27.6328 50.9805Z"
              fill="#FFF5F5"
            />
          </svg>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "12px",
          }}
        >
          <Typography
            sx={{
              fontSize: "20px",
              lineHeight: "24px",
              color: "#05050F",
            }}
          >
            {item?.name}
          </Typography>

          <Typography
            sx={{
              "& a": {
                fontSize: "16px",
                lineHeight: "24px",
                color: "#848484",
                cursor: "pointer",
                textDecoration: "none",
              },
            }}
          >
            <a href={item?.url} target="_blank">
              {item?.url ? "View" : "Not Uploaded"}
            </a>
          </Typography>
        </Box>
      </>
    );
    // }
    // if (zipMatchResult && zipMatchResult.length > 0) {
    //   return (
    //     <>
    //       <Box>
    //         <svg
    //           width="58"
    //           height="62"
    //           viewBox="0 0 58 62"
    //           fill="none"
    //           xmlns="http://www.w3.org/2000/svg"
    //         >
    //           <path
    //             fillRule="evenodd"
    //             clipRule="evenodd"
    //             d="M18.4101 0H39.4088L57.9446 18.5358V54.137C57.9446 58.4636 54.4082 62 50.0816 62H18.4101C14.0871 62 10.5508 58.4637 10.5508 54.137V7.85935C10.5508 3.53642 14.0871 0 18.4101 0Z"
    //             fill="#008BF5"
    //           />
    //           <path
    //             fillRule="evenodd"
    //             clipRule="evenodd"
    //             d="M39.4102 0L57.946 18.5358H41.4087C40.3066 18.5358 39.4102 17.6357 39.4102 16.5373V0Z"
    //             fill="#006FC4"
    //           />
    //           <path
    //             fillRule="evenodd"
    //             clipRule="evenodd"
    //             d="M1.77258 26.3994H44.4748C45.4245 26.3994 46.2006 27.1754 46.2006 28.1251V43.7908C46.2006 44.7404 45.4246 45.52 44.4748 45.52H1.77258C0.822965 45.52 0.046875 44.7404 0.046875 43.7908V28.1251C0.0469961 27.1755 0.822965 26.3994 1.77258 26.3994Z"
    //             fill="#006FC4"
    //           />
    //           <path
    //             d="M19.827 41.3204C19.827 41.8733 19.3788 42.3215 18.8259 42.3215H12.3838C12.0231 42.3215 11.6902 42.1273 11.5126 41.8133C11.3349 41.4993 11.34 41.1141 11.5258 40.8049L17.0567 31.5987H12.3838C11.8309 31.5987 11.3829 31.1506 11.3829 30.5976C11.3829 30.0447 11.8311 29.5967 12.3838 29.5967H18.8259C19.1867 29.5967 19.5195 29.7908 19.6972 30.1048C19.8748 30.4188 19.8697 30.804 19.684 31.1133L14.153 40.3194H18.8259C19.3788 40.3193 19.827 40.7675 19.827 41.3204ZM34.8689 33.8187V33.9569C34.8689 36.2849 32.9749 38.1788 30.6468 38.1788H28.427V41.3203C28.427 41.8732 27.9788 42.3213 27.426 42.3213C26.8732 42.3213 26.425 41.8732 26.425 41.3203V37.1779V35.8774V30.5976C26.425 30.0447 26.8732 29.5967 27.426 29.5967H30.647C32.975 29.5967 34.8689 31.4907 34.8689 33.8187ZM32.8669 33.8187C32.8669 32.5946 31.871 31.5987 30.6468 31.5987H28.427V35.8774V36.1769H30.6468C31.871 36.1769 32.8669 35.181 32.8669 33.957V33.8187ZM23.3013 29.5967C22.7484 29.5967 22.3003 30.0448 22.3003 30.5976V41.3203C22.3003 41.8732 22.7485 42.3213 23.3013 42.3213C23.8542 42.3213 24.3023 41.8732 24.3023 41.3203V30.5976C24.3023 30.0448 23.8542 29.5967 23.3013 29.5967Z"
    //             fill="white"
    //           />
    //         </svg>
    //       </Box>
    //       <Box
    //         sx={{
    //           display: "flex",
    //           flexDirection: "column",
    //           gap: "12px",
    //         }}
    //       >
    //         <Typography
    //           sx={{
    //             fontSize: "20px",
    //             lineHeight: "24px",
    //             color: "#05050F",
    //           }}
    //         >
    //           {item?.name}
    //         </Typography>
    //         <Typography
    //           sx={{
    //             fontSize: "16px",
    //             lineHeight: "24px",
    //             color: "#4086F4",
    //             cursor: "pointer",
    //             textDecoration: "none",
    //             cursor: "pointer",
    //           }}
    //           onClick={() => handleDownload(item?.url)}
    //         >
    //           Download
    //         </Typography>
    //       </Box>
    //     </>
    //   );
    // }
  };
  return (
    <>
      <Box
        key={item?.id}
        sx={{
          padding: "20px",
          pt: "15px",
          display: "flex",
          gap: "20px",
          alignItems: "center",
          border: "1px solid #ECECEC",
          borderRadius: "4px",
        }}
      >
        {showContent(item)}
      </Box>
    </>
  );
}

export default ApplicationDocumentsComponent;
