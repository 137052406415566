import { Box } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import IeltsSettingsContactUsTable from "./IeltsSettingsContactUsTable";
import { useDispatch, useSelector } from "react-redux";
import { ieltsSettingsContactFindAction } from "../../../../actions/IeltsActions/SettingsAction";

function ContactUsMain() {
  const dispatch = useDispatch();

  let { ieltsSettingsContactFindSuccess } = useSelector((state) => {
    return state.ieltsSettingsContactFind;
  });

  // delete
  let { ieltsSettingsContactDeleteSuccess } = useSelector((state) => {
    return state.ieltsSettingsContactDelete;
  });

  useEffect(() => {
    dispatch(ieltsSettingsContactFindAction());
  }, [dispatch, ieltsSettingsContactDeleteSuccess]);

  useEffect(() => {
    if (ieltsSettingsContactFindSuccess) {
      console.log(ieltsSettingsContactFindSuccess.data);
      setData(ieltsSettingsContactFindSuccess.data);
    }
  }, [ieltsSettingsContactFindSuccess]);

  const columns = [
    { title: "SL NO", field: "sl" },
    { title: "Name", field: "question" },
    { title: "Mail ID", field: "category" },
    { title: "DATE", field: "status" },
    { title: "message", field: "status" },
  ];

  const contactDemo = [
    {
      sl: 1,
      column1: "Swathi Mohan",
      column2: "sathimohan@gmail.com",
      column3: "20 / 02 / 2023 08:00 AM",
      column4: "Hi, i’d like to know more about the courses provided by you.",
    },
    {
      sl: 2,
      column1: "Swathi Mohan",
      column2: "sathimohan@gmail.com",
      column3: "20 / 02 / 2023 08:00 AM",
      column4: "Hi, i’d like to know more about the courses provided by you.",
    },
    {
      sl: 3,
      column1: "Swathi Mohan",
      column2: "sathimohan@gmail.com",
      column3: "20 / 02 / 2023 08:00 AM",
      column4: "Hi, i’d like to know more about the courses provided by you.",
    },
    {
      sl: 4,
      column1: "Swathi Mohan",
      column2: "sathimohan@gmail.com",
      column3: "20 / 02 / 2023 08:00 AM",
      column4: "Hi, i’d like to know more about the courses provided by you.",
    },
    {
      sl: 5,
      column1: "Swathi Mohan",
      column2: "sathimohan@gmail.com",
      column3: "20 / 02 / 2023 08:00 AM",
      column4: "Hi, i’d like to know more about the courses provided by you.",
    },
    {
      sl: 6,
      column1: "Swathi Mohan",
      column2: "sathimohan@gmail.com",
      column3: "20 / 02 / 2023 08:00 AM",
      column4: "Hi, i’d like to know more about the courses provided by you.",
    },
  ];

  const [data, setData] = useState();

  const [ids, setIds] = useState([]);
  const selectBoxIdPassHandler = (ids) => {
    setIds(ids);
  };
  return (
    <Box
      sx={{
        mt: 2,
      }}
    >
      <IeltsSettingsContactUsTable
        data={data}
        columns={columns}
        component="ieltsCategory"
        selectBoxIdPassHandler={selectBoxIdPassHandler}
      />
    </Box>
  );
}

export default ContactUsMain;
