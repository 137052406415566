// settings constats


// faq create constants
export const IELTS_SETTINGS_FAQ_CREATE_REQUEST =
  "IELTS_SETTINGS_FAQ_CREATE_REQUEST";
export const IELTS_SETTINGS_FAQ_CREATE_SUCCESS =
  "IELTS_SETTINGS_FAQ_CREATE_SUCCESS";
export const IELTS_SETTINGS_FAQ_CREATE_ERR = 
  "IELTS_SETTINGS_FAQ_CREATE_ERR";

// faq find constants
export const IELTS_SETTINGS_FAQ_FIND_REQUEST =
  "IELTS_SETTINGS_FAQ_FIND_REQUEST";
export const IELTS_SETTINGS_FAQ_FIND_SUCCESS =
  "IELTS_SETTINGS_FAQ_FIND_SUCCESS";
export const IELTS_SETTINGS_FAQ_FIND_ERR = 
  "IELTS_SETTINGS_FAQ_FIND_ERR";

// faq find by id constants
export const IELTS_SETTINGS_FAQ_FIND_BY_ID_REQUEST =
  "IELTS_SETTINGS_FAQ_FIND_BY_ID_REQUEST";
export const IELTS_SETTINGS_FAQ_FIND_BY_ID_SUCCESS =
  "IELTS_SETTINGS_FAQ_FIND_BY_ID_SUCCESS";
export const IELTS_SETTINGS_FAQ_FIND_BY_ID_ERR =
  "IELTS_SETTINGS_FAQ_FIND_BY_ID_ERR";

// faq update constants
export const IELTS_SETTINGS_FAQ_UPDATE_REQUEST =
  "IELTS_SETTINGS_FAQ_UPDATE_REQUEST";
export const IELTS_SETTINGS_FAQ_UPDATE_SUCCESS =
  "IELTS_SETTINGS_FAQ_UPDATE_SUCCESS";
export const IELTS_SETTINGS_FAQ_UPDATE_ERR =
  "IELTS_SETTINGS_FAQ_UPDATE_ERR";

// faq delete constants
export const IELTS_SETTINGS_FAQ_DELETE_REQUEST = "IELTS_SETTINGS_DELETE_REQUEST";
export const IELTS_SETTINGS_FAQ_DELETE_SUCCESS = "IELTS_SETTINGS_DELETE_SUCCESS";
export const IELTS_SETTINGS_FAQ_DELETE_ERR = "IELTS_SETTINGS_DELETE_ERR";

// faq status change constants
export const IELTS_SETTINGS_FAQ_STATUS_CHANGE_REQUEST =
  "IELTS_SETTINGS_STATUS_CHANGE_REQUEST";
export const IELTS_SETTINGS_FAQ_STATUS_CHANGE_SUCCESS =
  "IELTS_SETTINGS_STATUS_CHANGE_SUCCESS";
export const IELTS_SETTINGS_FAQ_STATUS_CHANGE_ERR = "IELTS_SETTINGS_STATUS_CHANGE_ERR";

// CONTACT

// contact find constants
export const IELTS_SETTINGS_CONTACT_FIND_REQUEST =
  "IELTS_SETTINGS_CONTACT_FIND_REQUEST";
export const IELTS_SETTINGS_CONTACT_FIND_SUCCESS =
  "IELTS_SETTINGS_CONTACT_FIND_SUCCESS";
export const IELTS_SETTINGS_CONTACT_FIND_ERR = 
  "IELTS_SETTINGS_CONTACT_FIND_ERR";

// contact find constants
export const IELTS_SETTINGS_CONTACT_FIND_BY_ID_REQUEST =
  "IELTS_SETTINGS_CONTACT_FIND_BY_ID_REQUEST";
export const IELTS_SETTINGS_CONTACT_FIND_BY_ID_SUCCESS =
  "IELTS_SETTINGS_CONTACT_FIND_BY_ID_SUCCESS";
export const IELTS_SETTINGS_CONTACT_FIND_BY_ID_ERR = 
  "IELTS_SETTINGS_CONTACT_FIND_BY_ID_ERR";

// contact find constants
export const IELTS_SETTINGS_CONTACT_DELETE_REQUEST =
  "IELTS_SETTINGS_CONTACT_DELETE_REQUEST";
export const IELTS_SETTINGS_CONTACT_DELETE_SUCCESS =
  "IELTS_SETTINGS_CONTACT_DELETE_SUCCESS";
export const IELTS_SETTINGS_CONTACT_DELETE_ERR = 
  "IELTS_SETTINGS_CONTACT_DELETE_ERR";



// Zoom configuration create constants

export const IELTS_SETTINGS_ZOOMCONFIG_CREATE_REQUEST =
  "IELTS_SETTINGS_ZOOMCONFIG_CREATE_REQUEST";
export const IELTS_SETTINGS_ZOOMCONFIG_CREATE_SUCCESS =
  "IELTS_SETTINGS_ZOOMCONFIG_CREATE_SUCCESS";
export const IELTS_SETTINGS_ZOOMCONFIG_CREATE_ERR = 
  "IELTS_SETTINGS_ZOOMCONFIG_CREATE_ERR";


  // Zoom configuration find constants


export const IELTS_SETTINGS_ZOOMCONFIG_FIND_REQUEST =
"IELTS_SETTINGS_ZOOMCONFIG_FIND_REQUEST";
export const IELTS_SETTINGS_ZOOMCONFIG_FIND_SUCCESS =
"IELTS_SETTINGS_ZOOMCONFIG_FIND_SUCCESS";
export const IELTS_SETTINGS_ZOOMCONFIG_FIND_ERR = 
"IELTS_SETTINGS_ZOOMCONFIG_FIND_ERR";


  // Zoom configuration findOne constants


  export const IELTS_SETTINGS_ZOOMCONFIG_FINDONE_REQUEST =
  "IELTS_SETTINGS_ZOOMCONFIG_FINDONE_REQUEST";
  export const IELTS_SETTINGS_ZOOMCONFIG_FINDONE_SUCCESS =
  "IELTS_SETTINGS_ZOOMCONFIG_FINDONE_SUCCESS";
  export const IELTS_SETTINGS_ZOOMCONFIG_FINDONE_ERR = 
  "IELTS_SETTINGS_ZOOMCONFIG_FINDONE_ERR";
  