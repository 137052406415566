import { Box, Checkbox, Typography } from "@mui/material";
import React, { useState } from "react";
import Lottie from "react-lottie";
import animationData from "../../../../../customComponent/TopicVoicePlaceholder.json";

function ModalTopicListComponent({
  type,
  item,
  handleCheckboxChange,
  selectedCheckboxes,
  listview,
}) {
  console.log(item, "itemmmmmmmmmmmmmmmm");
  console.log(type, "typeeeeeeeeeeeeeeee");
  const options = {
    animationData: animationData,
  };

  // const convertToTimeFormat = (number) => {
  //   const hours = Math.floor(number / 60);
  //   const minutes = number % 60;

  //   const hoursString = hours > 0 ? hours + " hr" : "";
  //   const minutesString = minutes > 0 ? minutes + " m" : "";

  //   return `Duration: ${hoursString} ${minutesString}`;
  // };

  return listview ? (
    <Box
      key={item?.id || item?.id || item?.id}
      sx={{
        padding: "8px",
        boxShadow: "0px 10px 30px 0px rgba(0, 0, 0, 0.08)",
        borderRadius: "8px",
        display: "grid",
        gridTemplateColumns: "103px 1fr",
        gap: "12px",
        position: "relative",
      }}
    >
      <Box
        sx={{
          width: "103px",
          height: "103px",
          "& img": {
            borderRadius: "8px",
            width: "100%",
            height: "100%",
          },
        }}
      >
        {type == "video" && (
          <img
            src={
              item.thumbnail_url
                ? item.thumbnail_url
                : "/loginImage/videoIcon.jpg"
            }
            alt=""
          />
        )}
        {type == "audio" && <Lottie options={options} />}
        {type == "notes" || type == "questionbank" || type == "examtip" ? (
          <img src="https://images.pexels.com/photos/13926757/pexels-photo-13926757.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load" />
        ) : (
          ""
        )}
      </Box>
      <Box>
        <Typography
          sx={{
            overflow: "hidden",
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 2,
            textOverflow: "ellipsis",
            color: "#141E3C",
            fontSize: "16px",
            fontWeight: "700",
          }}
        >
          {item?.TopicName || item?.title}
        </Typography>
        {/* <Typography
          sx={{
            fontSize: "16px",
            fontWeight: "700",
            color: "#141E3C",
          }}
        >
          Start Demo Class
        </Typography> */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            "& .MuiCheckbox-root": {
              padding: "0",
            },
          }}
        >
          <Typography
            sx={{
              color: "rgba(254, 11, 122, 1)",
              fontSize: "14px",
            }}
          >
            {item?.VideoDuration}
          </Typography>
        </Box>
      </Box>
    </Box>
  ) : (
    <Box
      key={item?.id || item?.id || item?.id}
      sx={{
        padding: "8px",
        boxShadow: "0px 10px 30px 0px rgba(0, 0, 0, 0.08)",
        borderRadius: "8px",
        display: "grid",
        gridTemplateColumns: "103px 1fr",
        gap: "12px",
        position: "relative",
      }}
    >
      <Box
        sx={{
          width: "103px",
          height: "103px",
          "& img": {
            borderRadius: "8px",
            width: "100%",
            height: "100%",
          },
        }}
      >
        {type == "video" && (
          <img
            src={
              item.thumbnail_url
                ? item.thumbnail_url
                : "/loginImage/videoIcon.jpg"
            }
            alt=""
          />
        )}
        {type == "audio" && <Lottie options={options} />}
        {type == "notes" || type == "questionbank" || type == "examtip" ? (
          <img src="https://images.pexels.com/photos/13926757/pexels-photo-13926757.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load" />
        ) : (
          ""
        )}
      </Box>
      <Box>
        <Typography
          sx={{
            overflow: "hidden",
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 2,
            textOverflow: "ellipsis",
            fontWeight: "700",
            color: "#141E3C",
            fontSize: "16px",
          }}
        >
          {item?.TopicName || item?.title}
        </Typography>
        {/* <Typography
          sx={{
            fontSize: "16px",
            fontWeight: "700",
            color: "#141E3C",
          }}
        >
          Start Demo Class
        </Typography> */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            "& .MuiCheckbox-root": {
              padding: "0",
            },
          }}
        >
          <Typography
            sx={{
              color: "rgba(254, 11, 122, 1)",
              fontSize: "14px",
            }}
          >
            {item?.VideoDuration}
          </Typography>
          <Checkbox
            Checked={selectedCheckboxes?.includes(item?.id)}
            onChange={() => handleCheckboxChange(item?.id)}
            sx={{
              color: "rgba(254, 11, 122, 1)",
              "&.Mui-checked": {
                color: "rgba(254, 11, 122, 1)",
              },
            }}
          />
          {selectedCheckboxes?.length > 0 &&
            selectedCheckboxes?.includes(item?.id) && (
              <Typography
                sx={{
                  color: "#fff",
                  right: "-10px",
                  top: "-10px",
                  fontSize: "14px",
                  position: "absolute",
                  borderRadius: "50%",
                  padding: "10px",
                  backgroundColor: "rgba(254, 11, 122, 1)",
                  width: "15px",
                  height: "15px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  boxSizing: "border-box",
                }}
              >
                {selectedCheckboxes?.indexOf(item?.id) + 1}
              </Typography>
            )}
        </Box>
      </Box>
    </Box>
  );
}

export default ModalTopicListComponent;
