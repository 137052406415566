// Scedule a Meeting reducer

import {
  SCEDULEMEETING_FIND_ERR,
  SCEDULEMEETING_FIND_REQUEST,
  SCEDULEMEETING_FIND_SUCCESS,
  SCEDULEMEETING_STATUSCHANGE_ERR,
  SCEDULEMEETING_STATUSCHANGE_REQUEST,
  SCEDULEMEETING_STATUSCHANGE_SUCCESS,
  SLOT_CREATE_ERR,
  SLOT_CREATE_REQUEST,
  SLOT_CREATE_SUCCESS,
  SLOT_FIND_ERR,
  SLOT_FIND_REQUEST,
  SLOT_FIND_SUCCESS,
  SLOT_STATUS_CHANGE_FIND_ERR,
  SLOT_STATUS_CHANGE_FIND_REQUEST,
  SLOT_STATUS_CHANGE_FIND_SUCCESS,
} from "../../constants/ticket/sceduleMeetingConstants";

export const sceduleMeetingFindReducer = (state = {}, action) => {
  switch (action.type) {
    case SCEDULEMEETING_FIND_REQUEST:
      return {
        ...state,
        sceduleMeetingFindLoading: true,
      };
    case SCEDULEMEETING_FIND_SUCCESS:
      return {
        ...state,
        sceduleMeetingFindLoading: false,
        sceduleMeetingFindSuccess: action.payload,
      };
    case SCEDULEMEETING_FIND_ERR:
      return {
        ...state,
        sceduleMeetingFindLoading: false,
        sceduleMeetingFinderror: action.payload,
      };
    default:
      return state;
  }
};

// Scedule Meeting status change

export const sceduleMeetingStatusChangeReducer = (state = {}, action) => {
  switch (action.type) {
    case SCEDULEMEETING_STATUSCHANGE_REQUEST:
      return {
        ...state,
        sceduleMeetingStatusChangeLoading: true,
      };
    case SCEDULEMEETING_STATUSCHANGE_SUCCESS:
      return {
        ...state,
        sceduleMeetingStatusChangeLoading: false,
        sceduleMeetingStatusChangeSuccess: action.payload,
      };
    case SCEDULEMEETING_STATUSCHANGE_ERR:
      return {
        ...state,
        sceduleMeetingStatusChangeLoading: false,
        sceduleMeetingStatusChangeerror: action.payload,
      };
    default:
      return state;
  }
};

// Scedule Meeting ManageSlot

export const sceduleMeetingManageSlotReducer = (state = {}, action) => {
  switch (action.type) {
    case SLOT_CREATE_REQUEST:
      return {
        ...state,
        slotCreateLoading: true,
      };
    case SLOT_CREATE_SUCCESS:
      return {
        ...state,
        slotCreateLoading: false,
        slotCreateSuccess: action.payload,
      };
    case SLOT_CREATE_ERR:
      return {
        ...state,
        slotCreateLoading: false,
        slotCreateerror: action.payload,
      };
    default:
      return state;
  }
};

// SLOT FIND REDUCER

export const SlotFindReducer = (state = {}, action) => {
  switch (action.type) {
    case SLOT_FIND_REQUEST:
      return {
        ...state,
        slotFindLoading: true,
      };
    case SLOT_FIND_SUCCESS:
      return {
        ...state,
        slotFindLoading: false,
        slotFindSuccess: action.payload,
      };
    case SLOT_FIND_ERR:
      return {
        ...state,
        slotFindLoading: false,
        slotFinderror: action.payload,
      };
    default:
      return state;
  }
};

// SLOT STATUS FIND REDUCER

export const slotStatusChangeReducer = (state = {}, action) => {
  switch (action.type) {
    case SLOT_STATUS_CHANGE_FIND_REQUEST:
      return {
        ...state,
        slotStatusFindLoading: true,
      };
    case SLOT_STATUS_CHANGE_FIND_SUCCESS:
      return {
        ...state,
        slotStatusFindLoading: false,
        slotStatusFindSuccess: action.payload,
      };
    case SLOT_STATUS_CHANGE_FIND_ERR:
      return {
        ...state,
        slotStatusFindLoading: false,
        slotStatusFinderror: action.payload,
      };
    default:
      return state;
  }
};
