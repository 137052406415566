import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";

import {
  confirmOTPReducer,
  findUserReducer,
  forgotPassSentOTPReducer,
  loginSubmitReducer,
  newPasswordSetReducer,
} from "./reducers/adminAuthReducer";
import {
  branchMasterAddReducer,
  branchMasterDeleteReducer,
  branchMasterEditReducer,
  findBranchMasterReducer,
} from "./reducers/branchMasterManagmentReducer";
import {
  cityMasterAddReducer,
  cityMasterDeleteReducer,
  cityMasterEditReducer,
  findCityMasterReducer,
  findEditCityFormReducer,
} from "./reducers/cityMasterManagmentReducer";

import {
  countryCurrencyFindReducer,
  countryMasterAddReducer,
  CountryMasterDeleteReducer,
  countryMasterEditReducer,
  editCountryFormReducer,
  fetchCountryUniqueTokenReducer,
  findcountryMasterAddReducer,
} from "./reducers/countryMasterManagmentReducer";

import {
  addEmployeeReducer,
  branchManagerFetchReducer,
  changePasswordReducer,
  checkEmployeePrivilegeReducer,
  deleteEmployeeReducer,
  editedEmployeeDataReducer,
  employeeBulkUploadReducer,
  employeedetailsShowReducer,
  findAllEmployeeReducer,
  findEmployeeMatchedRoleReducer,
  findEmployeeNotificationReducer,
  findEmployeeSalaryPaySlipReducer,
  findLeaveReducer,
  leaveApplicationDeleteReducer,
  leaveApplicationStatusChangeReducer,
  updateEmployeeStatusReducer,
} from "./reducers/employeeManagmentReducer";
import {
  CommonDocumentApplicationAddReducer,
  CommonDocumentApplicationFindReducer,
  deleteCommonDocReducer,
  updateIsFrontReducer,
  updateIsMandatoryReducer,
} from "./reducers/knowledgeBankReducer/commonDocsReducer";
import {
  countryAdmissionProcedureAddReducer,
  countryAdmissionProcedureFindReducer,
  countryCostEstimateAddReducer,
  countryCostEstimateDeleteReduer,
  countryCostEstimateFindReducer,
  countryCrimeAndMedicalApplicationAddReducer,
  countryCrimeApplicationGetReducer,
  countryDocDeleteReducer,
  CountryDocFindReducer,
  countryDocumentAdmissionApplicationAddReducer,
  countryDocumentAdmissionApplicationDeleteReducer,
  countryDocumentAdmissionApplicationFindReducer,
  countryDocumentAdmissionProcedureDeleteReducer,
  countryDocumentGuidanceApplicationAddReducer,
  countryDocumentGuidanceApplicationDeleteReducer,
  countryDocumentGuidanceApplicationFindReducer,
  countryDocumentSOPApplicationAddReducer,
  countryDocumentSOPApplicationFindReducer,
  countryDocumentTravelApplicationAddReducer,
  countryDocumentTravelApplicationFindReducer,
  countryMedicalApplicationGetReducer,
  countryPaymentGetwayUpdateReducer,
  criminalDocDeleteReducer,
  documentForCountryGuidanceApplicationAddReducer,
  documentForCountryGuidanceApplicationFindReducer,
  medicalDocDeleteReducer,
  SopDocDeleteReducer,
  submitCountryDocsAddReducer,
  TravelApplicationDeleteReducer,
} from "./reducers/knowledgeBankReducer/countryDocsReducer";
import {
  activityStatusChangeReducer,
  followUpActivitiesAddReducer,
  followUpActivitiesattAchmentFindReducer,
  followUpActivitiesLinkAddReducer,
  followUpActivitiesLinkFindReducer,
  followUpActivitiesNormalLinkFindReducer,
  followUpActivitiesNoteFindReducer,
  followUpComposeMailFindReducer,
  followUpComposeMailReducer,
  followUpTaskInformationAddReducer,
  followUpTaskInformationFindReducer,
  followUpTaskInformationUpdateReducer,
} from "./reducers/leadReducer/leadFollowUpReducer";
import {
  changeSopApplicationStatusReducer,
  countryAllAdmissionDocsFindReducer,
  courseAddShortListReducer,
  deleteAcademicDetailsReducer,
  deleteEnglishTestReducer,
  deleteLeadReducer,
  findApprovingStatusLeadReducer,
  findPendingStatusLeadChangeReducer,
  findShortListUniversityCourseReducer,
  findTimeLineReducer,
  leadAddReducer,
  leadApprovalRequestApproveReducer,
  leadApprovalRequestFindReducer,
  leadApprovalRequestRejectReducer,
  leadBulkUploadReducer,
  leadChangeStatusReducer,
  leadCounsilorNoteCreateReducer,
  leadCountryCommonDocsFindDocsReducer,
  leadCountrycommonDocUploadingReducer,
  leadCountrySpecificDocsFindingDocsReducer,
  leadCountrySpecificDocUploadingReducer,
  leadDetailFetchReducer,
  leadDocumentReUploadingReducer,
  leadEditReducer,
  leadEducationDetailsShowReducer,
  leadFindReducer,
  leadOwnerChangeApprovalFindReducer,
  leadOwnerChangeApproveReducer,
  leadOwnerChangeReducer,
  leadOwnerChangeRequestRejectReducer,
  leadPasswordSetReducer,
  leadPersonalDetailsAddReducer,
  leadPersonalDetailsShowReducer,
  leadRegistrationFeeCreateReducer,
  leadRegistrationFeeFindReducer,
  leadResendCredentialsReducer,
  leadRestartApplicationReducer,
  leadSopFindingReducer,
  leadSopUploadingReducer,
  leadStatusApprovingChangeReducer,
  leadStatusTimeLineReducer,
  leadTelecallerNoteCreateReducer,
  leadTraveAndImmigrationFindingReducer,
  leadTravelAndImmigrationUploadingReducer,
  leadWorkDetailsAddReducer,
  leadWorkDetailsDeleteReducer,
  leadWorkDetailsFindReducer,
  paymentUpdationInShortListReducer,
  pendingLeadStatusDocsReducer,
  shortListDeleteReducer,
  shortListMethodApplyReducer,
  shortListMethodFindReducer,
  shortListValidationFindReducer,
  universityCourseFindForShortListReducer,
  verifiedApplicationLeadFindReducer,
  leadDuplicateApprovalFindReducer,
  leadDuplicateApproveReducer,
  leadDuplicateRejectReducer,
  leadDuplicateApprovalFindOneReducer,
} from "./reducers/leadReducer/leadReducer";

import {
  allRoleFindReducer,
  createPrivilageReducer,
  createRoleSubMenuReducer,
  createRollReducer,
  createSelectAllPermissionReducer,
  createSelectAllPrivilageReducer,
  editRollReducer,
  findPermissionPrivilageReducer,
  findRoleSubMenuReducer,
  getAllMainMenuWithSubReducer,
  roleDeleteReducer,
  roleEditFindReducer,
  rollBulkUploadReducer,
  successTrueMsgReducer,
} from "./reducers/roleManagmentReducer";

import {
  findEditStateFormReducer,
  findStateMasterReducer,
  StateMasterAddReducer,
  stateMasterDeleteReducer,
  stateMasterEditReducer,
} from "./reducers/stateMasterManagmentReducer";
import {
  allFindCoursesReducer,
  courseBulkUploadReducer,
  courseEditFindReducer,
  courseEditReducer,
  courseTableFilterReducer,
  deleteCourseReducer,
  universityCourseAddReducer,
  universityFindCourseReducer,
} from "./reducers/universityReducers/courseReducers/courseReducers";

import {
  deleteUniversityImageReducer,
  findUniversityReducer,
  nullUniversityImageShowReducer,
  universityAddSubmitReducer,
  universityAndCourseBulkUploadReducer,
  universityDeleteReducer,
  universityEditReducer,
  universityViewReducer,
  uploadUniversityImageReducer,
} from "./reducers/universityReducers/UniversityManagmentReducer";
import {
  deleteUniversityMasterReducer,
  findMasterbyTypeIDReducer,
  universityMasterAddReducer,
  universityMasterEditReducer,
  universityMasterFindReducer,
} from "./reducers/universityReducers/universityMasterReducer";
import {
  appliedJobStatusChangeReducer,
  employeeJobAppliedReducer,
  employeeJobStatusChangeReducer,
  employeeSearchReducer,
  hrEmployeeAttendanceReducer,
  hrEmployeeFindReducer,
  hrEmployeeHolidayCreateReducer,
  hrEmployeeHolidayDeleteReducer,
  hrEmployeeHolidayEditCreateReducer,
  hrEmployeeHolidayEditReducer,
  hrEmployeeHolidayFindReducer,
  hrEmployeeJobCreateReducer,
  hrEmployeeJobDeleteReducer,
  hrEmployeeJobeEditReducer,
  hrEmployeeJobFindByIdReducer,
  hrEmployeeJobFindReducer,
  hrEmployeeJobUpdateReducer,
  hrEmployeeLeaveApplicationCreateReducer,
  hrEmployeeLeaveApplicationFindReducer,
  hrEmployeePayslipCreateReducer,
  hrEmployeePayslipDeleteReducer,
  hrEmployeePayslipFindReducer,
} from "./reducers/hrEmployeeManagmentReducer";
import {
  addNewGalleryItemsReducer,
  addNewOfficeReducer,
  AdmissionDocumentsFindReducer,
  blogCreateReducer,
  blogDeleteReducer,
  blogEditDataReducer,
  blogGetEditDataReducer,
  createFeaturedAdsReducer,
  deleteGalleryItemsByIdReducer,
  deleteOfficeByIdReducer,
  DestinationCreateReducer,
  DestinationDeleteReducer,
  destinationEditReducer,
  destinationFindByIDReducer,
  DestinationFindReducer,
  editOfficeReducer,
  eventDeleteReducer,
  eventsCreateReducer,
  eventsEditReducer,
  eventsFindReducer,
  featuredAdsDeleteReducer,
  findEventByIdReducer,
  findFeaturedAdsByIdReducer,
  findGalleryItemsByIdReducer,
  findLandingBlogsReducer,
  findLandingFeaturedAdsReducer,
  findLandingNewsReducer,
  findLandingTestimonialReducer,
  findNewGalleryItemsReducer,
  findTestimonialByIdReducer,
  getAllOfficeReducer,
  getOfficeByIdOfficeReducer,
  LandingPageCreateReducer,
  LandingPageDeleteReducer,
  LandingPageFindReducer,
  LandingServiceAddReducer,
  LandingServiceEditReducer,
  LandingServiceFindReducer,
  newsCreateReducer,
  newsDeleteReducer,
  newsFindByIdReducer,
  scholarshipApplicationConvertToLeadReducer,
  scholarshipApplicationReducer,
  serviceFindByIdReducer,
  talkToExpertChangeStatusReducer,
  talktoExpertConvertToLeadReducer,
  talkToExpertDeleteStatusReducer,
  talkToExpertReducer,
  testimonialEditReducer,
  testimonialReducer,
  testimonialsDeleteReducer,
  TopCoursesFindReducer,
  topNavigationEditReducer,
  topNavigationUpdateReducer,
  updateGalleryItemsByIdReducer,
} from "./reducers/LandingPageReducer";
import {
  employeeIncentiveCreateReducer,
  employeeIncentiveFindReducer,
  partnerCommissionCreateReducer,
  partnerCommissionDeleteReducer,
  partnerCommissionFindReducer,
} from "./reducers/incentiveReducer";
import {
  entrolledStudentsDetailsFindReducer,
  entrolledStudentsReducer,
  entrolledStudentsStatusChangeReducer,
  ieltsCategoryFindReducer,
  ieltsCategoryStatusChangeReducer,
  ieltsLearningManagmentCreateTopicReducer,
  ieltsLearningManagmentEntireTopicDeleteReducer,
  ieltsLearningManagmentFindTopicByIdReducer,
  ieltsLearningManagmentFindTopicReducer,
  ieltsLearningManagmentTopicDeleteReducer,
  ieltsLearningManagmentTopicStatusChangeReducer,
  ieltsLearningManagmentTopicUpdateReducer,
} from "./reducers/Ielts/ieltsReducers";

import {
  findEnquiriedPartnerReducer,
  partnerApprovalStatusUpdateReducer,
} from "./reducers/partnerReducer/partnerEnquiryReducer";
import {
  ieltsCourseManagmentCourseCenterReducer,
  ieltsCourseManagmentCourseCreatingReducer,
  ieltsCourseManagmentCourseEditFindReducer,
  ieltsCourseManagmentCourseEditReducer,
  ieltsCourseManagmentCourseFindByIdReducer,
  ieltsCourseManagmentCourseFindReducer,
  ieltsCourseManagmentCourseLiveClassCreateReducer,
  ieltsCourseManagmentCourseLiveClassDeleteReducer,
  ieltsCourseManagmentCourseLiveClassFindByIdReducer,
  ieltsCourseManagmentCourseLiveClassFindReducer,
  ieltsCourseManagmentCourseLiveClassStatusChangeReducer,
  ieltsCourseManagmentCourseLiveClassUpdateReducer,
  ieltsCourseManagmentCoursesSlotReducer,
  ieltsCourseManagmentCoursesStatusChangeReducer,
  ieltsCourseManagmentCourseTopicFindReducer,
  ieltsCourseManagmentCreateCheckErrorCourseReducer,
  ieltsCourseManagmentCreateCourseReducer,
  ieltsCourseManagmentExamtipsCreateReducer,
  ieltsCourseManagmentExamtipsDeleteReducer,
  ieltsCourseManagmentExamtipsFindByidReducer,
  ieltsCourseManagmentExamtipsFindReducer,
  ieltsCourseManagmentExamtipsPdfDeleteReducer,
  ieltsCourseManagmentExamtipsStatusChangeReducer,
  ieltsCourseManagmentExamtipsUpdateReducer,
  ieltsCourseManagmentQuestionBankCreateReducer,
  ieltsCourseManagmentQuestionBankDeleteReducer,
  ieltsCourseManagmentQuestionBankFindByidReducer,
  ieltsCourseManagmentQuestionBankFindReducer,
  ieltsCourseManagmentQuestionBankPdfDeleteReducer,
  ieltsCourseManagmentQuestionBankStatusChangeReducer,
  ieltsCourseManagmentQuestionBankUpdateReducer,
} from "./reducers/Ielts/ieltsCourseManagmentReducer";
import {
  allPartnerCommissionCategoryFindReducer,
  deletePartnerReducer,
  findAllPartnerEntrolledLeadReducer,
  findAllPartnerReducer,
  kycDetailsEditReducer,
  partnerAddReducer,
  partnerCommissionIdUpdateReducer,
  partnerKycApprovalReducer,
  partnerKycDetailFindReducer,
  partnerLeadFindReducer,
  partnerNotificationCreateReducer,
  partnerNotificationDeleteReducer,
  partnerNotificationFindAllReducer,
  partnerNotificationFindOneReducer,
  partnerNotificationUpdateReducer,
  partnerOtpVerifyReducer,
} from "./reducers/partnerReducer/partnerReducer";
import {
  ieltsTeacherManagmentAssignedStudentsReducer,
  ieltsTeacherManagmentTeacherCreateReducer,
  ieltsTeacherManagmentTeacherFindByCenterIdReducer,
  ieltsTeacherManagmentTeacherFindByIdReducer,
  ieltsTeacherManagmentTeacherFindReducer,
  ieltsTeacherManagmentTeacherStatusChangeReducer,
  ieltsTeacherManagmentTeacherUpdateReducer,
} from "./reducers/Ielts/ieltsTeacherManagmentReducer";
import {
  ieltsCenterAddTeacherModalUpdateReducer,
  ieltsCenterManagmentCenterCreateReducer,
  ieltsCenterManagmentCenterDeleteReducer,
  ieltsCenterManagmentCenterFindByIdReducer,
  ieltsCenterManagmentCenterFindReducer,
  ieltsCenterManagmentCenterSlotUpdateReducer,
  ieltsCenterManagmentCenterStatusChangeReducer,
  ieltsCenterManagmentCenterTeacherDeleteReducer,
} from "./reducers/Ielts/ieltsCenterManagmentReducer";
import {
  ieltsSettingsContactDeleteReducer,
  ieltsSettingsContactFindByIdReducer,
  ieltsSettingsContactFindReducer,
  ieltsSettingsFaqChangeStatusReducer,
  ieltsSettingsFaqCreateReducer,
  ieltsSettingsFaqDeleteReducer,
  ieltsSettingsFaqFindByIdReducer,
  ieltsSettingsFaqFindReducer,
  ieltsSettingsFaqUpdateReducer,
  ieltsSettingsZoomConfigCreateReducer,
  ieltsSettingsZoomConfigFindOneReducer,
  ieltsSettingsZoomConfigFindReducer,
} from "./reducers/Ielts/ieltsSettingsReducer";
import { ieltsStudentManagmentCreateReducer } from "./reducers/Ielts/ieltsStudentManagmentReducer";
import {
  ieltsPurchasedStudentAssignTeacherReducer,
  ieltsPurchasedStudentCourseFindByIdReducer,
  ieltsPurchasedStudentDetailsFindByIdReducer,
  ieltsPurchasedStudentFindReducer,
  ieltsPurchasedStudentStatusChangeReducer,
} from "./reducers/Ielts/ieltsPurchasedStudentReducer";
import {
  ieltsTeacherDashboardAssignedStudentsCourseFindReducer,
  ieltsTeacherDashboardAssignedStudentsFindByidReducer,
  ieltsTeacherDashboardFindReducer,
  ieltsTeacherDashboardLiveClassFindReducer,
} from "./reducers/Ielts/ieltsTeacherDashboardReducer";
import {
  bannerCreateReducer,
  bannerDeleteReducer,
  bannerFindReducer,
} from "./reducers/bannerReducer";
import {
  AddEmployeeBonusReducer,
  applicationDeclarationApproveReducer,
  applicationDepositeCreateReducer,
  applicationPaymenthistoryFindReducer,
  findAllAsignedStaffReducer,
  findAllLeadApplicationReducer,
  findApplicationDetailsReducer,
  findBonusDetailsReducer,
  findByIdLeadApplicationReducer,
  findStaffIncentiveReducer,
  listBonusEndrolledAppEmployeeReducer,
  partnerManagerChangeReducer,
  staffIncentiveAmmountAddReducer,
  teamLeadAssignStaffChangeReducer,
} from "./reducers/applicationReducers";
import {
  addUniversityCommissionReducer,
  deleteUniversityCommissionReducer,
  getAllFinalSummaryReducer,
  listUniversityCommissionReducer,
  paidPaymentUpdationReducer,
  UniversityCommissionFindByIdReducer,
  updateUniversityCommissionReducer,
} from "./reducers/reportsReducer/finalChoiceReducer";
import {
  sceduleMeetingFindReducer,
  sceduleMeetingManageSlotReducer,
  sceduleMeetingStatusChangeReducer,
  SlotFindReducer,
  slotStatusChangeReducer,
} from "./reducers/ticket/sceduleMeetingReducers";
import {
  deleteLeadSourceReducer,
  leadSourceCreateReducer,
  leadSourceFindDetailReducer,
  leadSourceFindReducer,
} from "./reducers/leadSourceReducers";

import {
  courseLevelMajorMasterDeleteReducer,
  courseLevelMajorMasterUpdateReducer,
  courseLevelMasterCreateReducer,
  courseLevelMasterFindReducer,
  courseMajorMasterCreateReducer,
  courseMajorMasterDeleteReducer,
  courseMajorMasterFindOneReducer,
  courseMajorMasterFindReducer,
  courseMajorMasterUpdateReducer,
  subjectBulkUploadReducer,
  subjectMajorMasterCreateReducer,
  subjectMajorMasterDeleteReducer,
  subjectMajorMasterFindOneReducer,
  subjectMajorMasterFindReducer,
  subjectMajorMasterUpdateReducer,
} from "./reducers/courseMajorMasterReducer";

import {
  ChatWithCounsilorNotificationReducer,
  chatWithCounsilorReducer,
  ChatWithCounsilorStatusChangeReducer,
  counsilorNotificationClearReducer,
  getChatWithCounsilorReducer,
} from "./reducers/leadChatReducer";

import {
  createFaqChangeReducer,
  createReferalSettingsReducer,
  deletereferalFaqReducer,
  getFaqChangeReducer,
  getReferalSettingsReducer,
  referalDetailsReducer,
  referralsFindReducer,
  referralsStatusChangeReducer,
  updatereferalFaqReducer,
  withdrawsFindReducer,
  withdrawsStatusChangeReducer,
} from "./reducers/referAndEarn/referAndEarnReducer";
import {
  createMenuInDeveloperTabReducer,
  createPermissionsInDeveloperTabReducer,
  createSubMenuInDeveloperTabReducer,
  deleteMenuInDeveloperTabReducer,
  deletePermissionsInDeveloperTabReducer,
  deleteSubMenuInDeveloperTabReducer,
  developerFindAllSubMenuDetailsReducer,
  editMenuInDeveloperTabReducer,
  editPermissionsInDeveloperTabReducer,
  editSubMenuInDeveloperTabReducer,
  findAllMenuInDeveloperTabReducer,
  findAllPermissionsDetailsInDeveloperTabReducer,
  findPermissionsDetailsInDeveloperTabReducer,
  findSubMenuDetailsInDeveloperTabReducer,
} from "./reducers/developersReducers";
import {
  counselorRatingsFindReducer,
  serviceRatingFindReducer,
} from "./reducers/ratingReducers";
import {
  addUniversityCoverImageReducer,
  deleteUniversityCoverImageReducer,
  getUniversityCoverImageReducer,
} from "./reducers/settings/settingsReducers";
import {
  enquirySearchReducer,
  enquiryStudentApplicationFindReducer,
  enquiryStudentDetailsReducer,
} from "./reducers/enquiryReducer";
import {
  paymentApprovalApproveReducer,
  paymentApprovalFindReducer,
  tutionFeePaymentApprovalApproveReducer,
  tutionFeePaymentApprovalFindReducer,
} from "./reducers/paymentApprovalReducer";
import { noteFindReducer } from "./reducers/dashBoardReducer";
import { LocationCreateReducer, LocationDeleteReducer, LocationFindOneReducer, LocationFindReducer, LocationUpdateReducer } from "./reducers/locationReducer";

const appReducer = combineReducers({
  // auth Reducer
  loginSubmit: loginSubmitReducer,
  findUser: findUserReducer,
  forgotPassSentOTP: forgotPassSentOTPReducer,
  confirmOTP: confirmOTPReducer,
  newPasswordSet: newPasswordSetReducer,

  // Role managment Reducer
  rollBulkUpload: rollBulkUploadReducer,
  createRoll: createRollReducer,
  allRoleFind: allRoleFindReducer,
  roleDelete: roleDeleteReducer,
  roleEditFind: roleEditFindReducer,
  editRoll: editRollReducer,
  getAllMainMenuWithSub: getAllMainMenuWithSubReducer,
  createRoleSubMenu: createRoleSubMenuReducer,
  findRoleSubMenu: findRoleSubMenuReducer,
  createPrivilage: createPrivilageReducer,
  findPermissionPrivilage: findPermissionPrivilageReducer,
  createSelectAllPrivilage: createSelectAllPrivilageReducer,
  createSelectAllPermission: createSelectAllPermissionReducer,

  // employee managment Reducer
  addEmployee: addEmployeeReducer,
  findAllEmployee: findAllEmployeeReducer,
  employeedetailsShow: employeedetailsShowReducer,
  findLeave: findLeaveReducer,
  changePassword: changePasswordReducer,
  deleteEmployee: deleteEmployeeReducer,
  updateEmployeeStatus: updateEmployeeStatusReducer,
  leaveApplicationStatusChange: leaveApplicationStatusChangeReducer,
  leaveApplicationDelete: leaveApplicationDeleteReducer,
  branchManagerFetch: branchManagerFetchReducer,
  findEmployeeMatchedRole: findEmployeeMatchedRoleReducer,
  findEmployeeSalaryPaySlip: findEmployeeSalaryPaySlipReducer,
  editedEmployeeData: editedEmployeeDataReducer,
  employeeBulkUpload: employeeBulkUploadReducer,

  // employee NOTIFICATION
  findEmployeeNotification: findEmployeeNotificationReducer,

  // university Managment
  uploadUniversityImage: uploadUniversityImageReducer,
  nullUniversityImageShow: nullUniversityImageShowReducer,
  universityAddSubmit: universityAddSubmitReducer,
  deleteUniversityImage: deleteUniversityImageReducer,
  findUniversity: findUniversityReducer,
  universityView: universityViewReducer,
  universityEdit: universityEditReducer,
  universityDelete: universityDeleteReducer,
  universityAndCourseBulkUpload: universityAndCourseBulkUploadReducer,

  // university course
  universityCourseAdd: universityCourseAddReducer,
  universityFindCourse: universityFindCourseReducer,
  allFindCourses: allFindCoursesReducer,
  deleteCourse: deleteCourseReducer,
  courseTableFilter: courseTableFilterReducer,
  courseEditFind: courseEditFindReducer,
  courseEdit: courseEditReducer,
  courseBulkUpload: courseBulkUploadReducer,
  courseLevelMasterCreate: courseLevelMasterCreateReducer,
  courseLevelMasterFind: courseLevelMasterFindReducer,
  courseLevelMajorMasterUpdate: courseLevelMajorMasterUpdateReducer,
  courseLevelMajorMasterDelete: courseLevelMajorMasterDeleteReducer,

  // university Master
  universityMasterAdd: universityMasterAddReducer,
  universityMasterFind: universityMasterFindReducer,
  findMasterbyTypeID: findMasterbyTypeIDReducer,
  deleteUniversityMaster: deleteUniversityMasterReducer,
  universityMasterEdit: universityMasterEditReducer,

  // country Master
  countryMasterAdd: countryMasterAddReducer,
  findcountryMasterAdd: findcountryMasterAddReducer,
  editCountryForm: editCountryFormReducer,
  CountryMasterDelete: CountryMasterDeleteReducer,
  countryMasterEdit: countryMasterEditReducer,
  fetchCountryUniqueToken: fetchCountryUniqueTokenReducer,
  countryCurrencyFind: countryCurrencyFindReducer,

  // state master
  StateMasterAdd: StateMasterAddReducer,
  findStateMaster: findStateMasterReducer,
  stateMasterDelete: stateMasterDeleteReducer,
  findEditStateForm: findEditStateFormReducer,
  stateMasterEdit: stateMasterEditReducer,

  // city master
  cityMasterAdd: cityMasterAddReducer,
  findCityMaster: findCityMasterReducer,
  cityMasterDelete: cityMasterDeleteReducer,
  findEditCityForm: findEditCityFormReducer,
  cityMasterEdit: cityMasterEditReducer,

  // knowledge Bank

  // common Docs
  CommonDocumentApplicationAdd: CommonDocumentApplicationAddReducer,
  CommonDocumentApplicationFind: CommonDocumentApplicationFindReducer,
  updateIsFront: updateIsFrontReducer,
  updateIsMandatory: updateIsMandatoryReducer,
  deleteCommonDoc: deleteCommonDocReducer,

  // country Docs
  submitCountryDocsAdd: submitCountryDocsAddReducer,
  CountryDocFind: CountryDocFindReducer,
  countryDocDelete: countryDocDeleteReducer,
  // sop
  countryDocumentSOPApplicationAdd: countryDocumentSOPApplicationAddReducer,
  countryDocumentSOPApplicationFind: countryDocumentSOPApplicationFindReducer,
  SopDocDelete: SopDocDeleteReducer,
  // tarvel
  countryDocumentTravelApplicationAdd: countryDocumentTravelApplicationAddReducer,
  countryDocumentTravelApplicationFind: countryDocumentTravelApplicationFindReducer,
  TravelApplicationDelete: TravelApplicationDeleteReducer,

  // Guidance

  countryDocumentGuidanceApplicationFind: countryDocumentGuidanceApplicationFindReducer,

  countryDocumentGuidanceApplicationAdd: countryDocumentGuidanceApplicationAddReducer,
  documentForCountryGuidanceApplicationAdd: documentForCountryGuidanceApplicationAddReducer,
  documentForCountryGuidanceApplicationFind: documentForCountryGuidanceApplicationFindReducer,

  countryDocumentGuidanceApplicationDelete: countryDocumentGuidanceApplicationDeleteReducer,

  // Admission

  countryDocumentAdmissionApplicationAdd: countryDocumentAdmissionApplicationAddReducer,
  countryAdmissionProcedureAdd: countryAdmissionProcedureAddReducer,
  countryCostEstimateAdd: countryCostEstimateAddReducer,
  countryAdmissionProcedureFind: countryAdmissionProcedureFindReducer,
  countryCostEstimateFind: countryCostEstimateFindReducer,
  countryDocumentAdmissionApplicationFind: countryDocumentAdmissionApplicationFindReducer,
  countryDocumentAdmissionApplicationDelete: countryDocumentAdmissionApplicationDeleteReducer,
  countryDocumentAdmissionProcedureDelete: countryDocumentAdmissionProcedureDeleteReducer,
  countryCostEstimateDelete: countryCostEstimateDeleteReduer,
  countryCrimeAndMedicalApplicationAdd: countryCrimeAndMedicalApplicationAddReducer,
  criminalDocDelete: criminalDocDeleteReducer,
  countryCrimeApplicationGet: countryCrimeApplicationGetReducer,
  countryMedicalApplicationGet: countryMedicalApplicationGetReducer,
  countryPaymentGetwayUpdate: countryPaymentGetwayUpdateReducer,
  medicalDocDelete: medicalDocDeleteReducer,

  // Lead
  leadFind: leadFindReducer,
  leadAdd: leadAddReducer,
  leadEdit: leadEditReducer,
  deleteLead: deleteLeadReducer,
  leadDetailFetch: leadDetailFetchReducer,
  leadChangeStatus: leadChangeStatusReducer,
  leadBulkUpload: leadBulkUploadReducer,
  leadRegistrationFeeCreate: leadRegistrationFeeCreateReducer,
  leadRegistrationFeeFind: leadRegistrationFeeFindReducer,
  leadResendCredentials: leadResendCredentialsReducer,
  leadPasswordSet: leadPasswordSetReducer,
  leadTelecallerNoteCreate: leadTelecallerNoteCreateReducer,
  leadCounsilorNoteCreate: leadCounsilorNoteCreateReducer,

  // LEAD TIME LINE
  findTimeLine: findTimeLineReducer,
  leadStatusTimeLine: leadStatusTimeLineReducer,

  // lead other details
  // personal
  leadPersonalDetailsAddReducer: leadPersonalDetailsAddReducer,
  leadPersonalDetailsShow: leadPersonalDetailsShowReducer,
  leadOwnerChange: leadOwnerChangeReducer,
  checkEmployeePrivilege: checkEmployeePrivilegeReducer,

  // education details
  leadEducationDetailsShow: leadEducationDetailsShowReducer,
  deleteAcademicDetails: deleteAcademicDetailsReducer,
  deleteEnglishTest: deleteEnglishTestReducer,

  // lead sop
  leadSopFinding: leadSopFindingReducer,
  changeSopApplicationStatus: changeSopApplicationStatusReducer,
  leadSopUploading: leadSopUploadingReducer,
  leadTravelAndImmigrationUploading: leadTravelAndImmigrationUploadingReducer,
  leadTraveAndImmigrationFinding: leadTraveAndImmigrationFindingReducer,

  //lead work Details
  leadWorkDetailsFind: leadWorkDetailsFindReducer,
  leadWorkDetailsAdd: leadWorkDetailsAddReducer,
  leadWorkDetailsDelete: leadWorkDetailsDeleteReducer,

  // country admission
  countryAllAdmissionDocsFind: countryAllAdmissionDocsFindReducer,

  leadCountryCommonDocsFindDocs: leadCountryCommonDocsFindDocsReducer,
  leadCountrycommonDocUploading: leadCountrycommonDocUploadingReducer,

  leadCountrySpecificDocsFindingDocs: leadCountrySpecificDocsFindingDocsReducer,
  leadCountrySpecificDocUploading: leadCountrySpecificDocUploadingReducer,

  leadDocumentReupload: leadDocumentReUploadingReducer,
  // lead short list
  universityCourseFindForShortList: universityCourseFindForShortListReducer,
  findShortListUniversityCourse: findShortListUniversityCourseReducer,
  courseAddShortList: courseAddShortListReducer,
  shortListDelete: shortListDeleteReducer,

  // short list applied
  shortListMethodFind: shortListMethodFindReducer,
  shortListMethodApply: shortListMethodApplyReducer,
  verifiedApplicationLeadFind: verifiedApplicationLeadFindReducer,
  paymentUpdationInShortList: paymentUpdationInShortListReducer,

  // branch Master
  findBranchMaster: findBranchMasterReducer,
  branchMasterAdd: branchMasterAddReducer,
  branchMasterDelete: branchMasterDeleteReducer,
  branchMasterEdit: branchMasterEditReducer,

  // LEAD PENDING STATUS CHANGE
  pendingLeadStatusDocs: pendingLeadStatusDocsReducer,
  findPendingStatusLeadChange: findPendingStatusLeadChangeReducer,

  // LEAD FOLLOW UP STORE
  // **********************
  followUpTaskInformationAdd: followUpTaskInformationAddReducer,
  followUpTaskInformationUpdate: followUpTaskInformationUpdateReducer,
  followUpTaskInformationFind: followUpTaskInformationFindReducer,
  activityStatusChange: activityStatusChangeReducer,
  followUpActivitiesAdd: followUpActivitiesAddReducer,
  followUpActivitiesLinkFind: followUpActivitiesLinkFindReducer,
  followUpActivitiesNoteFind: followUpActivitiesNoteFindReducer,
  followUpActivitiesattAchmentFind: followUpActivitiesattAchmentFindReducer,
  followUpComposeMail: followUpComposeMailReducer,
  followUpComposeMailFind: followUpComposeMailFindReducer,
  followUpActivitiesLinkAdd: followUpActivitiesLinkAddReducer,
  followUpActivitiesNormalLinkFind: followUpActivitiesNormalLinkFindReducer,

  // HR EMPLOYEE MANAGMENT
  // ***********************

  hrEmployeeFind: hrEmployeeFindReducer,
  hrEmployeeAttendanceCreation: hrEmployeeAttendanceReducer,
  employeeSearch: employeeSearchReducer,
  hrEmployeeHolidayFind: hrEmployeeHolidayFindReducer,
  hrEmployeeHolidayCreation: hrEmployeeHolidayCreateReducer,
  hrEmployeeHolidayDelete: hrEmployeeHolidayDeleteReducer,
  hrEmployeeHolidayEdit: hrEmployeeHolidayEditReducer,
  hrEmployeeHolidayEditCreate: hrEmployeeHolidayEditCreateReducer,
  hrEmployeeLeaveApplicationFind: hrEmployeeLeaveApplicationFindReducer,
  hrEmployeeLeaveApplicationApply: hrEmployeeLeaveApplicationCreateReducer,
  hrEmployeePayslipFind: hrEmployeePayslipFindReducer,
  hrEmployeePayslipCreate: hrEmployeePayslipCreateReducer,
  hrEmployeePayslipDelete: hrEmployeePayslipDeleteReducer,
  hrEmployeeJobCreate: hrEmployeeJobCreateReducer,
  hrEmployeeJobFind: hrEmployeeJobFindReducer,
  hrEmployeeJobFindById: hrEmployeeJobFindByIdReducer,
  hrEmployeeJobDelete: hrEmployeeJobDeleteReducer,
  hrEmployeeJobEdit: hrEmployeeJobeEditReducer,
  hrEmployeeJobUpdate: hrEmployeeJobUpdateReducer,

  //  LandingPage
  // ***********************

  topNavigationFind: LandingPageFindReducer,
  topNavigationCreate: LandingPageCreateReducer,
  topNavigationDelete: LandingPageDeleteReducer,
  topNavigationEdit: topNavigationEditReducer,
  topNavigationUpdate: topNavigationUpdateReducer,
  destinationCreate: DestinationCreateReducer,
  destinationFind: DestinationFindReducer,
  destinationFindByID: destinationFindByIDReducer,
  DestinationDelete: DestinationDeleteReducer,
  destinationEdit: destinationEditReducer,
  // *landin services
  LandingServiceAdd: LandingServiceAddReducer,
  LandingServiceFind: LandingServiceFindReducer,
  serviceFindById: serviceFindByIdReducer,
  LandingServiceEdit: LandingServiceEditReducer,

  // *landing News
  newsCreate: newsCreateReducer,
  findLandingNews: findLandingNewsReducer,
  newsFindById: newsFindByIdReducer,
  newsDelete: newsDeleteReducer,

  // *landing blog
  blogCreate: blogCreateReducer,
  blogGetEditData: blogGetEditDataReducer,
  blogEditData: blogEditDataReducer,
  findLandingBlogs: findLandingBlogsReducer,
  blogDelete: blogDeleteReducer,

  //* landing testimonial
  testimonial: testimonialReducer,
  findLandingTestimonial: findLandingTestimonialReducer,
  testimonialsDelete: testimonialsDeleteReducer,
  findTestimonialById: findTestimonialByIdReducer,
  testimonialEdit: testimonialEditReducer,

  // landing featured Ads
  findFeaturedAdsById: findFeaturedAdsByIdReducer,

  // *Landing Featured
  createFeaturedAds: createFeaturedAdsReducer,
  findLandingFeaturedAds: findLandingFeaturedAdsReducer,
  featuredAdsDelete: featuredAdsDeleteReducer,

  // *Landing Events

  eventsCreate: eventsCreateReducer,
  eventsFind: eventsFindReducer,
  findEventById: findEventByIdReducer,
  eventsEdit: eventsEditReducer,
  eventDelete: eventDeleteReducer,

  // landing office
  addNewOffice: addNewOfficeReducer,
  editOffice: editOfficeReducer,
  getAllOffice: getAllOfficeReducer,
  getOfficeByIdOffice: getOfficeByIdOfficeReducer,
  deleteOfficeById: deleteOfficeByIdReducer,
  addNewGalleryItems: addNewGalleryItemsReducer,
  findNewGalleryItems: findNewGalleryItemsReducer,
  findGalleryItemsById: findGalleryItemsByIdReducer,
  updateGalleryItemsById: updateGalleryItemsByIdReducer,
  deleteGalleryItemsById: deleteGalleryItemsByIdReducer,

  // scholarship application
  scholarshipApplication: scholarshipApplicationReducer,
  scholarshipApplicationConvertToLead: scholarshipApplicationConvertToLeadReducer,

  // ACCOUNT

  // *partner commission
  partnerCommissionCreate: partnerCommissionCreateReducer,
  partnerCommissionFind: partnerCommissionFindReducer,
  partnerCommissionDelete: partnerCommissionDeleteReducer,

  // *Employee Incentive

  employeeIncentiveCreate: employeeIncentiveCreateReducer,
  employeeIncentiveFind: employeeIncentiveFindReducer,

  // LEAD APPROVING STATUS
  findApprovingStatusLead: findApprovingStatusLeadReducer,
  leadStatusApprovingChange: leadStatusApprovingChangeReducer,
  leadRestartApplication: leadRestartApplicationReducer,

  //************** PARTNER STORE *****************//
  findEnquiriedPartner: findEnquiriedPartnerReducer,
  partnerApprovalStatusUpdate: partnerApprovalStatusUpdateReducer,
  findAllPartner: findAllPartnerReducer,
  partnerKycDetailFind: partnerKycDetailFindReducer,
  partnerKycApproval: partnerKycApprovalReducer,
  partnerAdd: partnerAddReducer,
  partnerOtpVerify: partnerOtpVerifyReducer,
  deletePartner: deletePartnerReducer,
  kycDetailsEdit: kycDetailsEditReducer,
  partnerLeadFind: partnerLeadFindReducer,
  allPartnerCommissionCategoryFind: allPartnerCommissionCategoryFindReducer,
  partnerCommissionIdUpdate: partnerCommissionIdUpdateReducer,
  partnerNotificationCreate: partnerNotificationCreateReducer,
  partnerNotificationFindAll: partnerNotificationFindAllReducer,
  partnerNotificationFindOne: partnerNotificationFindOneReducer,
  partnerNotificationDelete: partnerNotificationDeleteReducer,
  partnerNotificationUpdate: partnerNotificationUpdateReducer,
  findAllPartnerEntrolledLead:findAllPartnerEntrolledLeadReducer,

  // ********* APLIED APPLICATION ************//
  findAllLeadApplication: findAllLeadApplicationReducer,
  findByIdLeadApplication: findByIdLeadApplicationReducer,
  findAllAsignedStaff: findAllAsignedStaffReducer,
  findStaffIncentive: findStaffIncentiveReducer,
  listBonusEndrolledAppEmployee: listBonusEndrolledAppEmployeeReducer,
  AddEmployeeBonus: AddEmployeeBonusReducer,
  findBonusDetails: findBonusDetailsReducer,
  partnerManagerChange: partnerManagerChangeReducer,
  teamLeadAssignStaffChange: teamLeadAssignStaffChangeReducer,
  findApplicationDetails: findApplicationDetailsReducer,
  applicationDeclarationApprove: applicationDeclarationApproveReducer,
  applicationDepositeCreate: applicationDepositeCreateReducer,
  applicationPaymenthistoryFind: applicationPaymenthistoryFindReducer,
  staffIncentiveAmmountAdd: staffIncentiveAmmountAddReducer,

  // success msg alert
  successTrueMsg: successTrueMsgReducer,

  // ielts
  ieltsCourseManagmentCreateCourse: ieltsCourseManagmentCreateCourseReducer,

  // Entrolled students find

  ieltsEntrolledStudents: entrolledStudentsReducer,
  ieltsStudentsStatusChange: entrolledStudentsStatusChangeReducer,
  ieltsStudentsDetails: entrolledStudentsDetailsFindReducer,

  // Learning Managment

  // Find Category List

  ieltsCategoryList: ieltsCategoryFindReducer,
  ieltsCategoryStatusChange: ieltsCategoryStatusChangeReducer,

  // Learning managment create topic

  ieltslearningManagmentCreateTopic: ieltsLearningManagmentCreateTopicReducer,
  ieltslearningManagmentFindTopic: ieltsLearningManagmentFindTopicReducer,
  ieltslearningManagmentFindTopiById: ieltsLearningManagmentFindTopicByIdReducer,
  ieltslearningManagmentTopicsStatusChange: ieltsLearningManagmentTopicStatusChangeReducer,
  ieltslearningManagmentTopicsUpdate: ieltsLearningManagmentTopicUpdateReducer,
  ieltslearningManagmentTopicsDelete: ieltsLearningManagmentTopicDeleteReducer,
  ieltslearningManagmentEntireTopicDelete: ieltsLearningManagmentEntireTopicDeleteReducer,

  // Course Managment examTips

  ieltsCourseManagmentExamTipsCreate: ieltsCourseManagmentExamtipsCreateReducer,
  ieltsCourseManagmentExamTipsFind: ieltsCourseManagmentExamtipsFindReducer,
  ieltsCourseManagmentExamTipsStatusChange: ieltsCourseManagmentExamtipsStatusChangeReducer,
  ieltsCourseManagmentExamtipsDelete: ieltsCourseManagmentExamtipsDeleteReducer,
  ieltsCourseManagmentExamTipsUpdate: ieltsCourseManagmentExamtipsUpdateReducer,
  ieltsCourseManagmentExamtipsFindById: ieltsCourseManagmentExamtipsFindByidReducer,
  ieltsCourseManagmentExamtipsPdfDelete: ieltsCourseManagmentExamtipsPdfDeleteReducer,
  ieltsCourseManagmentCourseEdit: ieltsCourseManagmentCourseEditReducer,

  // Course Managment questionBank

  ieltsCourseManagmentQuestionBankCreate: ieltsCourseManagmentQuestionBankCreateReducer,
  ieltsCourseManagmentQuestionBankFind: ieltsCourseManagmentQuestionBankFindReducer,
  ieltsCourseManagmentQuestionBankStatusChange: ieltsCourseManagmentQuestionBankStatusChangeReducer,
  ieltsCourseManagmentQuestionBankDelete: ieltsCourseManagmentQuestionBankDeleteReducer,
  ieltsCourseManagmentQuestionBankUpdate: ieltsCourseManagmentQuestionBankUpdateReducer,
  ieltsCourseManagmentQuestionBankFindById: ieltsCourseManagmentQuestionBankFindByidReducer,
  ieltsCourseManagmentQuestionBankPdfDelete: ieltsCourseManagmentQuestionBankPdfDeleteReducer,

  // Course Managment course

  ieltsCourseManagmentCourseTopicFind: ieltsCourseManagmentCourseTopicFindReducer,
  ieltsCourseManagmentCourseFind: ieltsCourseManagmentCourseFindReducer,
  ieltsCourseManagmentCourseFindById: ieltsCourseManagmentCourseFindByIdReducer,
  ieltsCourseManagmentCourseCreating: ieltsCourseManagmentCourseCreatingReducer,
  ieltsCourseManagmentCoursesStatusChange: ieltsCourseManagmentCoursesStatusChangeReducer,
  ieltsCourseManagmentCoursesSlot: ieltsCourseManagmentCoursesSlotReducer,
  ieltsCourseManagmentCourseCenter: ieltsCourseManagmentCourseCenterReducer,
  ieltsCourseManagmentCourseEditFind: ieltsCourseManagmentCourseEditFindReducer,

  // Course Managment LiveClass

  ieltsCourseManagmentCourseLiveClassCreate: ieltsCourseManagmentCourseLiveClassCreateReducer,
  ieltsCourseManagmentCourseLiveClassFind: ieltsCourseManagmentCourseLiveClassFindReducer,
  ieltsCourseManagmentCourseLiveClassFindById: ieltsCourseManagmentCourseLiveClassFindByIdReducer,
  ieltsCourseManagmentCourseLiveClassStatusChange: ieltsCourseManagmentCourseLiveClassStatusChangeReducer,
  ieltsCourseManagmentCourseLiveClassUpdate: ieltsCourseManagmentCourseLiveClassUpdateReducer,
  ieltsCourseManagmentCourseLiveClassDelete: ieltsCourseManagmentCourseLiveClassDeleteReducer,

  // Teacher Managment

  ieltsTeacherManagmentTeacherCreate: ieltsTeacherManagmentTeacherCreateReducer,
  ieltsTeacherManagmentTeacherFind: ieltsTeacherManagmentTeacherFindReducer,
  ieltsTeacherManagmentTeacherFindById: ieltsTeacherManagmentTeacherFindByIdReducer,
  ieltsTeacherManagmentTeacherUpdate: ieltsTeacherManagmentTeacherUpdateReducer,
  ieltsTeacherManagmentTeacherStatusChange: ieltsTeacherManagmentTeacherStatusChangeReducer,
  ieltsTeacherManagmentTeacherFindByCenterId: ieltsTeacherManagmentTeacherFindByCenterIdReducer,
  ieltsTeacherManagmentAssignedStudents: ieltsTeacherManagmentAssignedStudentsReducer,

  // Center Managment

  ieltsCenterManagmentCenterCreate: ieltsCenterManagmentCenterCreateReducer,
  ieltsCenterManagmentCenterFind: ieltsCenterManagmentCenterFindReducer,
  ieltsCenterManagmentCenterFindById: ieltsCenterManagmentCenterFindByIdReducer,
  ieltsCenterManagmentCenterStatusChange: ieltsCenterManagmentCenterStatusChangeReducer,
  ieltsCenterManagmentCenterSlotUpdate: ieltsCenterManagmentCenterSlotUpdateReducer,
  ieltsCenterManagmentCenterDelete: ieltsCenterManagmentCenterDeleteReducer,
  ieltsCenterManagmentCenterTeacherDelete: ieltsCenterManagmentCenterTeacherDeleteReducer,
  ieltsCenterAddTeacherModalUpdate: ieltsCenterAddTeacherModalUpdateReducer,

  // settings / Faq
  ieltsSettingsFaqCreate: ieltsSettingsFaqCreateReducer,
  ieltsSettingsFaqFind: ieltsSettingsFaqFindReducer,
  ieltsSettingsFaqFindById: ieltsSettingsFaqFindByIdReducer,
  ieltsSettingsFaqUpdate: ieltsSettingsFaqUpdateReducer,
  ieltsSettingsFaqDelete: ieltsSettingsFaqDeleteReducer,
  ieltsSettingsFaqChangeStatus: ieltsSettingsFaqChangeStatusReducer,

  // settings / Contact Us
  ieltsSettingsContactFind: ieltsSettingsContactFindReducer,
  ieltsSettingsContactFindById: ieltsSettingsContactFindByIdReducer,
  ieltsSettingsContactDelete: ieltsSettingsContactDeleteReducer,

  // settings / zoom config

  ieltsSettingsZoomConfigCreate: ieltsSettingsZoomConfigCreateReducer,
  ieltsSettingsZoomConfigFind: ieltsSettingsZoomConfigFindReducer,
  ieltsSettingsZoomConfigFindOne: ieltsSettingsZoomConfigFindOneReducer,

  // Student Managment

  ieltsStudentManagmentCreate: ieltsStudentManagmentCreateReducer,
  ieltsCourseManagmentCreateCheckErrorCourse: ieltsCourseManagmentCreateCheckErrorCourseReducer,

  // purchased

  ieltsPurchasedStudentFind: ieltsPurchasedStudentFindReducer,
  ieltsPurchasedStudentStatusChange: ieltsPurchasedStudentStatusChangeReducer,
  ieltsPurchasedStudentDetailsFindById: ieltsPurchasedStudentDetailsFindByIdReducer,
  ieltsPurchasedStudentCourseFindById: ieltsPurchasedStudentCourseFindByIdReducer,
  ieltsPurchasedStudentAssignTeacher: ieltsPurchasedStudentAssignTeacherReducer,

  // Teacher Dashboard

  ieltsTeacherDashboardFind: ieltsTeacherDashboardFindReducer,
  ieltsTeacherDashboardAssignedStudentsFindByid: ieltsTeacherDashboardAssignedStudentsFindByidReducer,
  ieltsTeacherDashboardAssignedStudentsCourseFind: ieltsTeacherDashboardAssignedStudentsCourseFindReducer,
  ieltsTeacherDashboardLiveClassFind: ieltsTeacherDashboardLiveClassFindReducer,

  // Banner
  bannerCreate: bannerCreateReducer,
  bannerFind: bannerFindReducer,
  bannerDelete: bannerDeleteReducer,

  // talk to expert
  talkToExpert: talkToExpertReducer,
  talkToExpertDeleteStatus: talkToExpertDeleteStatusReducer,
  talkToExpertChangeStatus: talkToExpertChangeStatusReducer,
  talktoExpertConvertToLead: talktoExpertConvertToLeadReducer,

  // **************** REPORTS ****************** \\
  getAllFinalSummary: getAllFinalSummaryReducer,
  paidPaymentUpdation: paidPaymentUpdationReducer,
  addUniversityCommission: addUniversityCommissionReducer,
  listUniversityCommission: listUniversityCommissionReducer,
  deleteUniversityCommission: deleteUniversityCommissionReducer,
  UniversityCommissionFindById: UniversityCommissionFindByIdReducer,
  updateUniversityCommission: updateUniversityCommissionReducer,

  // ***************** Tickets ******************\\

  sceduleMeetingFind: sceduleMeetingFindReducer,
  sceduleMeetingStatusChange: sceduleMeetingStatusChangeReducer,
  sceduleMeetingManageSlot: sceduleMeetingManageSlotReducer,
  SlotFind: SlotFindReducer,

  slotStatusChange: slotStatusChangeReducer,

  // *************lead source ************* \\
  leadSourceCreate: leadSourceCreateReducer,
  leadSourceFind: leadSourceFindReducer,
  leadSourceFindDetail: leadSourceFindDetailReducer,
  deleteLeadSource: deleteLeadSourceReducer,

  // ************course master ************\\

  courseMajorMasterCreate: courseMajorMasterCreateReducer,
  courseMajorMasterFind: courseMajorMasterFindReducer,
  courseMajorMasterFindOne: courseMajorMasterFindOneReducer,
  courseMajorMasterUpdate: courseMajorMasterUpdateReducer,
  courseMajorMasterDelete: courseMajorMasterDeleteReducer,
  subjectMajorMasterCreate: subjectMajorMasterCreateReducer,
  subjectMajorMasterFind: subjectMajorMasterFindReducer,
  subjectMajorMasterFindOne: subjectMajorMasterFindOneReducer,
  subjectMajorMasterUpdate: subjectMajorMasterUpdateReducer,
  subjectMajorMasterDelete: subjectMajorMasterDeleteReducer,
  subjectBulkUpload: subjectBulkUploadReducer,

  // ************* chat ************* \\
  // checkCounselorAvailableById: checkCounselorAvailableByIdReducer,
  getChatWithCounsilor: getChatWithCounsilorReducer,
  chatWithCounsilor: chatWithCounsilorReducer,
  ChatWithCounsilorNotification: ChatWithCounsilorNotificationReducer,
  ChatWithCounsilorStatusChange: ChatWithCounsilorStatusChangeReducer,

  // refer and earn
  getReferalSettings: getReferalSettingsReducer,
  createReferalSettings: createReferalSettingsReducer,
  referralsFind: referralsFindReducer,
  referralsStatusChange: referralsStatusChangeReducer,
  createFaqChange: createFaqChangeReducer,
  getFaqChange: getFaqChangeReducer,
  deleteReferalFaq: deletereferalFaqReducer,
  updatereferalFaq: updatereferalFaqReducer,

  // withdraw
  withdrawsFind: withdrawsFindReducer,
  withdrawsStatusChange: withdrawsStatusChangeReducer,
  referalDetails: referalDetailsReducer,

  // developer
  // --menu
  createMenuInDeveloperTab: createMenuInDeveloperTabReducer,
  editMenuInDeveloperTab: editMenuInDeveloperTabReducer,
  deleteMenuInDeveloperTab: deleteMenuInDeveloperTabReducer,
  developerFindAllSubMenuDetails: developerFindAllSubMenuDetailsReducer,
  findAllMenuInDeveloperTab: findAllMenuInDeveloperTabReducer,

  // --submenu
  createSubMenuInDeveloperTab: createSubMenuInDeveloperTabReducer,
  editSubMenuInDeveloperTab: editSubMenuInDeveloperTabReducer,
  deleteSubMenuInDeveloperTab: deleteSubMenuInDeveloperTabReducer,
  findSubMenuDetailsInDeveloperTab: findSubMenuDetailsInDeveloperTabReducer,
  // --permission
  createPermissionsInDeveloperTab: createPermissionsInDeveloperTabReducer,
  deletePermissionsInDeveloperTab: deletePermissionsInDeveloperTabReducer,
  findPermissionsDetailsInDeveloperTab: findPermissionsDetailsInDeveloperTabReducer,
  findAllPermissionsDetailsInDeveloperTab: findAllPermissionsDetailsInDeveloperTabReducer,

  counsilorNotificationClear: counsilorNotificationClearReducer,

  // developer
  findAllMenuInDeveloperTab: findAllMenuInDeveloperTabReducer,
  createMenuInDeveloperTab: createMenuInDeveloperTabReducer,

  // ratings
  counselorRatingsFind: counselorRatingsFindReducer,
  serviceRatingFind: serviceRatingFindReducer,

  // settings
  addUniversityCoverImage: addUniversityCoverImageReducer,
  getUniversityCoverImage: getUniversityCoverImageReducer,
  deleteUniversityCoverImage: deleteUniversityCoverImageReducer,

  // Job
  employeeJobApplied: employeeJobAppliedReducer,
  employeeJobStatusChange: employeeJobStatusChangeReducer,
  appliedJobStatusChange: appliedJobStatusChangeReducer,

  // enquiry
  enquiryStudentDetails: enquiryStudentDetailsReducer,
  enquiryStudentApplicationFind: enquiryStudentApplicationFindReducer,
  enquirySearch: enquirySearchReducer,

  // PaymentApproval

  paymentApprovalFind: paymentApprovalFindReducer,
  paymentApprovalApprove: paymentApprovalApproveReducer,
  tutionFeePaymentApprovalFind: tutionFeePaymentApprovalFindReducer,
  tutionFeePaymentApprovalApprove: tutionFeePaymentApprovalApproveReducer,

  // Shortlist validation find reducer

  shortListValidationFind: shortListValidationFindReducer,

  // Lead approval find reducer

  leadApprovalRequestFind: leadApprovalRequestFindReducer,
  leadApprovalRequestApprove: leadApprovalRequestApproveReducer,
  leadApprovalRequestReject: leadApprovalRequestRejectReducer,

  // ::::::::::::::::::::::::::::::::LEAD OWNERCHANGE APPROVAL REDUCER ::::::::::::::::::::::::::::::::::::::::::

  leadOwnerChangeApprovalFind: leadOwnerChangeApprovalFindReducer,
  leadOwnerChangeApprove: leadOwnerChangeApproveReducer,
  leadOwnerChangeRequestReject: leadOwnerChangeRequestRejectReducer,

  //  :::::::::::::::::::::DashBorad Reducers :::::::::::::::::::::::::::;:

  noteFind:noteFindReducer,
 
  // ::::::::::::::::::: lead duplicate store :::::::::::

  leadDuplicateApprovalFind:leadDuplicateApprovalFindReducer,
  leadDuplicateApprovalFindOne:leadDuplicateApprovalFindOneReducer,
  leadDuplicateApprove:leadDuplicateApproveReducer,
  leadDuplicateReject:leadDuplicateRejectReducer,

  // ::::::::::::::::::::: Location :::::::::::::::::::::::::

  LocationCreate:LocationCreateReducer,
  LocationFind:LocationFindReducer,
  LocationFindOne:LocationFindOneReducer,
  LocationUpdate:LocationUpdateReducer,
  LocationDelete:LocationDeleteReducer
  
});

let Middleware = [thunk];

export const store = createStore(appReducer, applyMiddleware(...Middleware));
