import React, { useEffect, useRef, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Paper from "@mui/material/Paper";
import Draggable from "react-draggable";
import Select from "react-select";

// form
import {
  Grid,
  IconButton,
  Snackbar,
  Alert,
  Typography,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Box,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
// import { leadAddAction } from "../../../actions/leadActions/leadActions";

// import "./LeadCreateForm.css";

import Swal from "sweetalert2";
import { countries } from "countries-list";
import InputField, { SelectInputField } from "../customComponent/InputField";
import { SubmitButton } from "../customComponent/Buttons";
import { partnerAddAction } from "../../actions/partnerAction/partnerAction";
import { successTrueMsgAction } from "../../actions/globalAction";
import { SUCCESS_TRUE_MSG_ERR } from "../../constants/roleManagmentConstant";

const genders = ["Male", "Female", "Other"];
const leadSources = [
  "Email Enquiry",
  "Events",
  "Facebook",
  "Facebook Ads",
  "Google Ads",
  "IELTS Center",
  "Influencer",
  "Paid Referral",
  "Unpaid Referral",
  "Phone Enquiry",
  "Walkin",
];

const allCountries = Object.entries(countries).map(([code, country]) => ({
  value: code,
  label: country.name,
  phone: `+${country.phone}`,
}));

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

export default function PartnerCreateForm({ addForm, handleStateChange }) {
  const dispatch = useDispatch();
  const fileInputRef = useRef(null);

  const [selectedFile, setSelectedFile] = useState(null);
  const [previewImage, setPreviewImage] = useState("");
  const [open, setOpen] = React.useState(addForm);

  // form details
  const [firstName, setFirstName] = useState("");
  const [newPass, setNewPass] = useState("");
  const [confirmPass, setConfirmPass] = useState("");
  const [email, setEmail] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  //   const [countryCode, setCountryCode] = useState("+91");
  const [whatsappNumber, setWhatsappNumber] = useState("");
  const [websiteUrl, setWebsiteUrl] = useState("");
  //   const [dateOfBirth, setDateOfBirth] = useState("");
  //   const [education, setEducation] = useState("");
  //   const [leadSource, setLeadSource] = useState("");
  //   const [referralName, setReferralName] = useState("");
  //   const [preferredCountry, setPreferredCountry] = useState([]);
  //   const [remark, setRemark] = useState("");
  //   const [isSubmitted, setIsSubmitted] = useState(false);
  //   const [allCountry, setAllCountry] = useState([]);

  const [passwordError, setPasswordError] = useState(false);
  const [leadADDError, setLeadAddError] = useState(false);
  const [validError, setValidateMsg] = useState(false);

  let { partnerCreateSuccess, partnerCreateError } = useSelector((state) => {
    return state.partnerAdd;
  });

  const { successAlertMsgSuccess, successAlertMsgErr } = useSelector(
    (state) => {
      return state.successTrueMsg;
    }
  );
  //   let { countryFindSuccess } = useSelector((state) => {
  //     return state.findcountryMasterAdd;
  //   });

  useEffect(() => {
    if (partnerCreateSuccess && successAlertMsgSuccess) {
      setFirstName("");
      // setLastName("");
      setNewPass("");
      setConfirmPass("");
      setEmail("");
      // setGender("");
      setMobileNumber("");
      setWhatsappNumber("");
      setWebsiteUrl("");
      // setDateOfBirth("");
      // setEducation("");
      // setLeadSource("");
      // setReferralName("");
      // setPreferredCountry([]);
      // setRemark("");
      // setIsSubmitted(true);
      setPasswordError(false);
      setValidateMsg(false);
      dispatch(successTrueMsgAction(false));
    }
  }, [partnerCreateSuccess]);

  useEffect(() => {
    if (partnerCreateError && successAlertMsgErr) {
      setLeadAddError(true);
      dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: false });
    }
  }, [partnerCreateSuccess]);

  //   useEffect(() => {
  //     dispatch(findcountryMasterAddAction());
  //   }, [dispatch]);

  //   useEffect(() => {
  //     if (leadAdderror) {
  //       setLeadAddError(true);
  //     }
  //   }, [leadAdderror]);

  //   // country Code
  //   const handleCountryCodeChange = (event) => {
  //     let value = event.target.value;
  //     if (value) {
  //       setCountryCode(value);
  //     }
  //   };

  //   const handleCountryCodeSecondaryChange = (event) => {
  //     let value = event.target.value;
  //     if (value) {
  //       setCountryCodeSecondary(value);
  //     }
  //   };

  const handlePhoneChange = (event) => {
    const phoneNumber = event.target.value;
    if (/^\d{0,13}$/.test(phoneNumber) || /^\d{0,10}$/.test(phoneNumber)) {
      setMobileNumber(phoneNumber);
      setValidateMsg(false);
    }
  };

  //   const handleSecondaryPhoneChange = (event) => {
  //     const phoneNumber = event.target.value;
  //     if (/^\d{0,13}$/.test(phoneNumber) || /^\d{0,10}$/.test(phoneNumber)) {
  //       setSecMobileNumber(phoneNumber);
  //     }
  //   };

  // PROFILE PIC
  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
      setValidateMsg(false);
    }
  };

  const handleIconButtonClick = () => {
    fileInputRef.current.click();
  };

  // country of living
  //   const handleCountryChange = (selectedOption) => {
  //     console.log(selectedOption);
  //     setSelectedCountry(selectedOption);
  //   };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Perform form validation
    if (
      firstName.trim() === "" ||
      email.trim() === "" ||
      mobileNumber.trim() === "" ||
      whatsappNumber.trim() === "" ||
      websiteUrl.trim() === "" ||
      selectedFile === null
    ) {
      // Swal.fire({
      //   icon: "error",
      //   title: "Oops...",
      //   text: "Validation failed!",
      // });
      setValidateMsg(true);
    } else {
      if (newPass === confirmPass) {
        const details = {
          name: firstName,
          email: email,
          phone: mobileNumber,
          whatsAppNumber: whatsappNumber,
          websiteUrl: websiteUrl,
          password: newPass,
        };
        const formData = new FormData();
        formData.append("companyLogo", selectedFile);
        formData.append("details", JSON.stringify(details));
        dispatch(partnerAddAction(formData));

        // setFirstName("");
        // // setLastName("");
        // setNewPass("");
        // setConfirmPass("");
        // setEmail("");
        // // setGender("");
        // setMobileNumber("");
        // setWhatsappNumber("");
        // setWebsiteUrl("");
        // // setDateOfBirth("");
        // // setEducation("");
        // // setLeadSource("");
        // // setReferralName("");
        // // setPreferredCountry([]);
        // // setRemark("");
        // // setIsSubmitted(true);
        // setPasswordError(false);
        setPasswordError(false);
        setValidateMsg(false);
      } else {
        setPasswordError(true);
      }
    }
  };

  const handleClose = () => {
    handleStateChange(false);
    setFirstName("");
    setNewPass("");
    setConfirmPass("");
    setEmail("");
    setMobileNumber("");
    setWhatsappNumber("");
    setWebsiteUrl("");
    setPasswordError(false);
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        PaperProps={{
          style: {
            minHeight: "100vh",
            maxHeight: "100vh",
            display: "flex",
            flexDirection: "column",
            minWidth: "820px",
          },
        }}
      >
        <DialogTitle
          style={{ cursor: "move", fontSize: "20px", fontWeight: 700 }}
          id="draggable-dialog-title"
        >
          Add New Partner
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2} justifyContent="center">
                {/* User Profile */}
                <Snackbar
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                  open={partnerCreateError}
                  message={partnerCreateError}
                >
                  <Alert severity="error" sx={{ width: "100%" }}>
                    {partnerCreateError}
                  </Alert>
                </Snackbar>

                <Grid item xs={12} textAlign="center">
                  <IconButton
                    sx={{
                      width: "150px",
                      height: "150px",
                      backgroundColor: "#fdfdfd",
                    }}
                    onClick={handleIconButtonClick}
                  >
                    {selectedFile ? (
                      <img
                        src={URL.createObjectURL(selectedFile)}
                        alt="Uploaded"
                        style={{
                          width: "140px",
                          height: "140px",
                          border: "1px solid",
                          borderRadius: "50%",
                        }}
                      />
                    ) : (
                      <img src="/menuIcons/user.png" alt="gsl_profile" />
                    )}
                  </IconButton>

                  <input
                    id="profileUpload"
                    type="file"
                    accept="image/*"
                    style={{ display: "none" }}
                    onChange={handleFileUpload}
                    ref={fileInputRef}
                  />
                  <Typography
                    sx={{ fontWeight: 400, fontSize: "16px", mt: 0.5 }}
                  >
                    Company Logo Picture
                  </Typography>

                  {validError && (
                    <span style={{ color: "red" }}>
                      All mandatory field must be filled out!
                    </span>
                  )}
                </Grid>

                {/* First Name and Last Name */}
                <Grid item xs={6}>
                  <InputField
                    label="Name"
                    handleChange={(event) => setFirstName(event.target.value)}
                    value={firstName}
                    widthSize="99%"
                    required
                  />
                </Grid>
                <Grid item xs={6}>
                  <InputField
                    label="Email"
                    handleChange={(event) => setEmail(event.target.value)}
                    value={email}
                    widthSize="99%"
                    required
                    InputType="email"
                  />

                  {/* <InputField
                    label="Last Name"
                    handleChange={(event) => setLastName(event.target.value)}
                    value={lastName}
                    widthSize="99%"
                    required
                  /> */}
                </Grid>

                {/* New password and Confirm Password */}
                <Grid item xs={6}>
                  <InputField
                    label="New Password"
                    handleChange={(event) => {
                      setPasswordError(false);
                      setNewPass(event.target.value);
                    }}
                    value={newPass}
                    widthSize="99%"
                    InputType="password"
                    required
                  />
                  {passwordError && (
                    <span style={{ color: "red" }}>Password Not Matched</span>
                  )}
                </Grid>
                <Grid item xs={6}>
                  <InputField
                    label="Confirm Password"
                    handleChange={(event) => {
                      setPasswordError(false);
                      setConfirmPass(event.target.value);
                    }}
                    value={confirmPass}
                    widthSize="99%"
                    required
                    InputType="password"
                  />
                </Grid>

                {/* Email and Gender */}
                {/* <Grid item xs={6}>
                  <InputField
                    label="Email"
                    handleChange={(event) => setEmail(event.target.value)}
                    value={email}
                    widthSize="99%"
                    required
                    InputType="email"
                  />
                </Grid>
                <Grid item xs={6}>
                  <SelectInputField
                    label="Gender"
                    handleSelectChange={(event) => {
                      setGender(event.target.value);
                    }}
                    widthSize="54px"
                    value={gender}
                    mapValue={genders}
                    mapMethod="gender"
                  />
                </Grid> */}

                {/* Mobile Number */}
                {/* <Grid item xs={2}>
                  <SelectInputField
                    handleSelectChange={handleCountryCodeChange}
                    value={countryCode}
                    mapValue={allCountries}
                    mapMethod="countryCode"
                  />
                </Grid> */}

                <Grid item xs={6}>
                  <InputField
                    label="Mobile Number"
                    handleChange={handlePhoneChange}
                    value={mobileNumber}
                    widthSize="99%"
                    required
                    InputType="number"
                  />
                </Grid>

                {/*secondary Mobile Number */}
                {/* <Grid item xs={2}>
                  <SelectInputField
                    handleSelectChange={handleCountryCodeSecondaryChange}
                    value={countryCodeSecondary}
                    mapValue={allCountries}
                    mapMethod="countryCode"
                  />
                </Grid> */}

                {/* <Grid item xs={10}>
                  <InputField
                    label="Secondary Mobile Number"
                    handleChange={handleSecondaryPhoneChange}
                    value={secMobileNumber}
                    widthSize="99%"
                    required
                    InputType="number"
                  />
                </Grid> */}

                {/* WhatsApp Number */}
                <Grid item xs={6}>
                  <InputField
                    label="WhatsApp Number"
                    handleChange={(event) => {
                      const phoneNumber = event.target.value;
                      if (
                        /^\d{0,10}$/.test(phoneNumber) ||
                        /^\d{0,13}$/.test(phoneNumber)
                      ) {
                        setWhatsappNumber(phoneNumber);
                      }
                    }}
                    value={whatsappNumber}
                    widthSize="99%"
                    required
                    InputType="number"
                  />
                </Grid>

                {/* websiteUrl, Date of Birth, Education */}
                <Grid item xs={12}>
                  <InputField
                    label="Website URL"
                    handleChange={(event) => setWebsiteUrl(event.target.value)}
                    value={websiteUrl}
                    widthSize="99%"
                    required
                    InputType="text"
                  />
                </Grid>

                {/* <Grid item xs={12}>
                  <Select
                    options={allCountries}
                    value={selectedCountry}
                    onChange={handleCountryChange}
                    placeholder="Select a country"
                    className="custom-select"
                    styles={{
                      container: (provided) => ({
                        ...provided,
                        zIndex: 9999, // Adjust the z-index value
                      }),
                    }}
                  />
                </Grid> */}

                {/* <Grid item xs={12}>
                  <FormControl>
                    <FormLabel
                      id="demo-controlled-radio-buttons-group"
                      sx={{ color: "black" }}
                    >
                      Marital Status
                    </FormLabel>

                    <RadioGroup
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={isMarried}
                      onChange={(event) => setIsMarried(event.target.value)}
                    >
                      <Box sx={{ display: "flex" }}>
                        <FormControlLabel
                          value="married"
                          control={<Radio style={{ color: "#f81b82" }} />}
                          label="Married"
                        />
                        <FormControlLabel
                          value="unmarried"
                          control={<Radio style={{ color: "#f81b82" }} />}
                          label="Unmarried"
                        />
                      </Box>
                    </RadioGroup>
                  </FormControl>
                </Grid> */}

                {/* <Grid item xs={6}>
                  <InputField
                    label="Date of Birth"
                    handleChange={(event) => setDateOfBirth(event.target.value)}
                    value={dateOfBirth}
                    widthSize="99%"
                    required
                    InputType="date"
                  />
                </Grid>
                <Grid item xs={6}>
                  <InputField
                    label="Education"
                    handleChange={(event) => setEducation(event.target.value)}
                    value={education}
                    widthSize="99%"
                    required
                    InputType="text"
                  />
                </Grid> */}

                {/* Lead Source */}
                {/* <Grid item xs={12}>
                  <SelectInputField
                    label="Lead Source"
                    handleSelectChange={(event) =>
                      setLeadSource(event.target.value)
                    }
                    widthSize="83px"
                    value={leadSource}
                    mapValue={leadSources}
                    mapMethod="leadSource"
                  />
                </Grid>

                {leadSource === "Events" && (
                  <Grid item xs={12}>
                    <InputField
                      label={leadSource === "Events" ? "Event Name" : ""}
                      handleChange={(event) =>
                        setReferralName(event.target.value)
                      }
                      value={referralName}
                      widthSize="99%"
                      // required
                      InputType="text"
                    />
                  </Grid>
                )}

                {leadSource === "IELTS Center" && (
                  <Grid item xs={12}>
                    <InputField
                      label={leadSource === "IELTS Center" ? "Center Name" : ""}
                      handleChange={(event) =>
                        setReferralName(event.target.value)
                      }
                      value={referralName}
                      widthSize="99%"
                      // required
                      InputType="text"
                    />
                  </Grid>
                )}

                {leadSource === "Paid Referral" && (
                  <Grid item xs={12}>
                    <InputField
                      label={
                        leadSource === "Paid Referral" ? "Referred by" : ""
                      }
                      handleChange={(event) =>
                        setReferralName(event.target.value)
                      }
                      value={referralName}
                      widthSize="99%"
                      // required
                      InputType="text"
                    />
                  </Grid>
                )} */}

                {/* {leadSource === "Unpaid Referral" && (
                  <Grid item xs={12}>
                    <InputField
                      label={
                        leadSource === "Unpaid Referral" ? "Referred by" : ""
                      }
                      handleChange={(event) =>
                        setReferralName(event.target.value)
                      }
                      value={referralName}
                      widthSize="99%"
                      // required
                      InputType="text"
                    />
                  </Grid>
                )}

                {leadSource === "Phone Enquiry" && (
                  <Grid item xs={12}>
                    <InputField
                      label={
                        leadSource === "Phone Enquiry" ? "Referred by" : ""
                      }
                      handleChange={(event) =>
                        setReferralName(event.target.value)
                      }
                      value={referralName}
                      widthSize="99%"
                      // required
                      InputType="text"
                    />
                  </Grid>
                )} */}

                {/* Referral Name */}
                {/* <Grid item xs={12}>
                  <InputField
                    label="Referral Name"
                    handleChange={(event) =>
                      setReferralName(event.target.value)
                    }
                    value={referralName}
                    widthSize="99%"
                    // required
                    InputType="text"
                  />
                </Grid> */}

                {/* Preferred Country */}
                {/* <Grid item xs={12}>
                  <SelectInputField
                    label="Preferred Country"
                    handleSelectChange={(event) => {
                      const {
                        target: { value },
                      } = event;

                      console.log(value, "valuevaluevalue");
                      const filteredArray = value.filter((obj) =>
                        obj.hasOwnProperty("countryName")
                      );

                      setPreferredCountry((prevRanking) => {
                        const updatedRanking = [...prevRanking]; // Create a copy of the current ranking state

                        filteredArray.forEach((selectedItem) => {
                          const existingIndex = updatedRanking.findIndex(
                            (item) => item.id === selectedItem.id
                          );

                          if (existingIndex !== -1) {
                            // Item already exists in the ranking, remove it
                            updatedRanking.splice(existingIndex, 1);
                          } else {
                            // Item doesn't exist in the ranking, add it
                            updatedRanking.push({
                              name: selectedItem.countryName,
                              id: selectedItem.id,
                            });
                          }
                        });

                        return updatedRanking;
                      });
                    }}
                    widthSize="120px"
                    value={preferredCountry}
                    mapValue={allCountry}
                    mapMethod="allCountry"
                    multiple="true"
                  />
                </Grid> */}

                {/* Remark Description */}
                {/* <Grid item xs={12}>
                  <InputField
                    label="Remarks"
                    handleChange={(event) => setRemark(event.target.value)}
                    value={remark}
                    widthSize="99%"
                    required
                    InputType="discription"
                    dispcriptionHeight="150px"
                  />
                </Grid> */}

                {/* Save Button */}
                <Grid item xs={12} textAlign="center">
                  <SubmitButton
                    title="Save"
                    submit="submit"
                    widthSize="90px"
                    heightSize="49px"
                    type="submit"
                    handleSubmit=""
                  />
                </Grid>
              </Grid>
            </form>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}
