// create a new menu in developer
export const CREATE_NEW_MENU_REQUEST = "CREATE_NEW_MENU_REQUEST";
export const CREATE_NEW_MENU_SUCCESS = "CREATE_NEW_MENU_SUCCESS";
export const CREATE_NEW_MENU_ERR = "CREATE_NEW_MENU_ERR";

// edit a new menu in developer
export const EDIT_MENU_REQUEST = "EDIT_MENU_REQUEST";
export const EDIT_MENU_SUCCESS = "EDIT_MENU_SUCCESS";
export const EDIT_MENU_ERR = "EDIT_MENU_ERR";

// delete a new menu in developer
export const DELETE_MENU_REQUEST = "DELETE_MENU_REQUEST";
export const DELETE_MENU_SUCCESS = "DELETE_MENU_SUCCESS";
export const DELETE_MENU_ERR = "DELETE_MENU_ERR";

// find all menu in developer
export const FIND_MENU_REQUEST = "FIND_MENU_REQUEST";
export const FIND_MENU_SUCCESS = "FIND_MENU_SUCCESS";
export const FIND_MENU_ERR = "FIND_MENU_ERR";

// ************ submenu *******************

// create a new submenu in developer
export const CREATE_NEW_SUBMENU_REQUEST = "CREATE_NEW_SUBMENU_REQUEST";
export const CREATE_NEW_SUBMENU_SUCCESS = "CREATE_NEW_SUBMENU_SUCCESS";
export const CREATE_NEW_SUBMENU_ERR = "CREATE_NEW_SUBMENU_ERR";

// edit a new submenu in developer
export const UPDATE_SUBMENU_REQUEST = "UPDATE_SUBMENU_REQUEST";
export const UPDATE_SUBMENU_SUCCESS = "UPDATE_SUBMENU_SUCCESS";
export const UPDATE_SUBMENU_ERR = "UPDATE_SUBMENU_ERR";

// find a new submenu in developer
export const FIND_SUBMENU_DETAILS_REQUEST = "FIND_SUBMENU_DETAILS_REQUEST";
export const FIND_SUBMENU_DETAILS_SUCCESS = "FIND_SUBMENU_DETAILS_SUCCESS";
export const FIND_SUBMENU_DETAILS_ERR = "FIND_SUBMENU_DETAILS_ERR";

// delete a new submenu in developer
export const DELETE_SUBMENU_REQUEST = "DELETE_SUBMENU_REQUEST";
export const DELETE_SUBMENU_SUCCESS = "DELETE_SUBMENU_SUCCESS";
export const DELETE_SUBMENU_ERR = "DELETE_SUBMENU_ERR";

// find all menu in developer
export const FIND_SUB_MENU_REQUEST = "FIND_SUB_MENU_REQUEST";
export const FIND_SUB_MENU_SUCCESS = "FIND_SUB_MENU_SUCCESS";
export const FIND_SUB_MENU_ERR = "FIND_SUB_MENU_ERR";

// ************ Permission *******************

// create a new Permission in developer
export const CREATE_PERMISSION_REQUEST = "CREATE_PERMISSION_REQUEST";
export const CREATE_PERMISSION_SUCCESS = "CREATE_PERMISSION_SUCCESS";
export const CREATE_PERMISSION_ERR = "CREATE_PERMISSION_ERR";

// edit a new Permission in developer
export const UPDATE_PERMISSION_REQUEST = "UPDATE_PERMISSION_REQUEST";
export const UPDATE_PERMISSION_SUCCESS = "UPDATE_PERMISSION_SUCCESS";
export const UPDATE_PERMISSION_ERR = "UPDATE_PERMISSION_ERR";

// find a new Permission in developer
export const FIND_PERMISSION_DETAILS_REQUEST =
  "FIND_PERMISSION_DETAILS_REQUEST";
export const FIND_PERMISSION_DETAILS_SUCCESS =
  "FIND_PERMISSION_DETAILS_SUCCESS";
export const FIND_PERMISSION_DETAILS_ERR = "FIND_PERMISSION_DETAILS_ERR";

// find a new Permission in developer
export const FIND_ALL_PERMISSION_DETAILS_REQUEST =
  "FIND_ALL_PERMISSION_DETAILS_REQUEST";
export const FIND_ALL_PERMISSION_DETAILS_SUCCESS =
  "FIND_ALL_PERMISSION_DETAILS_SUCCESS";
export const FIND_ALL_PERMISSION_DETAILS_ERR =
  "FIND_ALL_PERMISSION_DETAILS_ERR";

// delete a new Permission in developer
export const DELETE_PERMISSION_REQUEST = "DELETE_PERMISSION_REQUEST";
export const DELETE_PERMISSION_SUCCESS = "DELETE_PERMISSION_SUCCESS";
export const DELETE_PERMISSION_ERR = "DELETE_PERMISSION_ERR";
