import axios from "axios";
import {
  USER_CONFIRM_OTP_ERR,
  USER_CONFIRM_OTP_REQUEST,
  USER_CONFIRM_OTP_SUCCESS,
  USER_FIND_ERR,
  USER_FIND_REQUEST,
  USER_FIND_SUCCESS,
  USER_LOGIN_ERR,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_OTP_ERR,
  USER_OTP_REQUEST,
  USER_OTP_SUCCESS,
  USER_SET_NEW_PASSWORD_ERR,
  USER_SET_NEW_PASSWORD_REQUEST,
  USER_SET_NEW_PASSWORD_SUCCESS,
} from "../constants/adminAuthConstant";

// LoginSubmitAction
export const LoginSubmitAction =
  (email, password) => async (dispatch, getState) => {
    try {
      dispatch({ type: USER_LOGIN_REQUEST });

      let { data } = await axios.post("/api/admin/auth/login", {
        email,
        password,
      });

      dispatch({ type: USER_LOGIN_SUCCESS, payload: data });

      localStorage.setItem("loginInfo", JSON.stringify(data));

      // let isUserExist = localStorage.getItem("loginInfo")
      //   ? JSON.parse(localStorage.getItem("loginInfo"))
      //   : null;
    } catch (error) {
      console.log(error.response, "error.response");
      dispatch({ type: USER_LOGIN_ERR, payload: error.response.data });
    }
  };

  // findUserAction
export const findUserAction = () => async (dispatch, getState) => {
  try {
    dispatch({ type: USER_FIND_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    let id = isUserExist.UserId;

    let { data } = await axios.get(`/api/admin/auth/findUser?id=${id}`);

    dispatch({ type: USER_FIND_SUCCESS, payload: data });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({ type: USER_FIND_ERR, payload: error?.response?.data });
  }
};

// forgotPassSentOTP
export const forgotPassSentOTPAction =
  (email) => async (dispatch, getState) => {
    try {
      dispatch({ type: USER_OTP_REQUEST });

      let { data } = await axios.post("/api/admin/auth/otpSendforgotPassword", {
        email,
      });

      dispatch({ type: USER_OTP_SUCCESS, payload: data });
    } catch (error) {
      dispatch({ type: USER_OTP_ERR, payload: error.response.data });
    }
  };

// for confirm Password
export const confirmOTPAction = (otp) => async (dispatch, getState) => {
  try {
    dispatch({ type: USER_CONFIRM_OTP_REQUEST });

    let { data } = await axios.post(
      "/api/admin/auth/otpConfirmforgotPassword",
      {
        otp,
      }
    );

    dispatch({ type: USER_CONFIRM_OTP_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: USER_CONFIRM_OTP_ERR, payload: error.response.data });
  }
};

// for set new Password
export const newPasswordSetAction =
  (newPassword, confirmPassword) => async (dispatch, getState) => {
    try {
      dispatch({ type: USER_SET_NEW_PASSWORD_REQUEST });

      let { data } = await axios.post("/api/admin/auth/SetnewPassword", {
        newPassword,
        confirmPassword,
      });

      console.log(data, "newPasswordSetActionnewPasswordSetAction");

      dispatch({ type: USER_SET_NEW_PASSWORD_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: USER_SET_NEW_PASSWORD_ERR,
        payload: error.response.data,
      });
    }
  };
