import axios from "axios";
import {
  IELTS_STUDENTS_DETAILS_FIND_ERR,
  IELTS_STUDENTS_DETAILS_FIND_REQUEST,
  IELTS_STUDENTS_DETAILS_FIND_SUCCESS,
  IELTS_STUDENTS_ENTROLLED_FIND_ERR,
  IELTS_STUDENTS_ENTROLLED_FIND_REQUEST,
  IELTS_STUDENTS_ENTROLLED_FIND_SUCCESS,
  IELTS_STUDENTS_STATUSCHANGE_ERR,
  IELTS_STUDENTS_STATUSCHANGE_REQUEST,
  IELTS_STUDENTS_STATUSCHANGE_SUCCESS,
} from "../../constants/Ielts/ieltsConstants";

// des:find entrolled students action

export const entrolledStudentsFindAction = () => async (dispatch, getState) => {
  try {
    dispatch({ type: IELTS_STUDENTS_ENTROLLED_FIND_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.get(
      "/api/admin/ielts/entrolledStudents",
      config
    );

    dispatch({ type: IELTS_STUDENTS_ENTROLLED_FIND_SUCCESS, payload: data });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({
      type: IELTS_STUDENTS_ENTROLLED_FIND_ERR,
      payload: error.response.data,
    });
  }
};

// des:EntrolledStudents status change action

export const entrolledStudentsStatusChangeAction = (status, id) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({ type: IELTS_STUDENTS_STATUSCHANGE_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.post(
      "/api/admin/ielts/entrolledStudentsStatusChange",
      {
        status,
        id,
      },
      config
    );

    dispatch({ type: IELTS_STUDENTS_STATUSCHANGE_SUCCESS, payload: data });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({
      type: IELTS_STUDENTS_STATUSCHANGE_ERR,
      payload: error.response.data,
    });
  }
};


// des:EntrolledStudents details find action


export const entrolledStudentsDetailsFindAction = (id) => async (
  dispatch,
  getState
) => {

  try {
    dispatch({ type: IELTS_STUDENTS_DETAILS_FIND_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.get(
      `/api/admin/ielts/entrolledstudentsview?id=${id}`,
      config
    );

    dispatch({ type: IELTS_STUDENTS_DETAILS_FIND_SUCCESS, payload: data });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({
      type: IELTS_STUDENTS_DETAILS_FIND_ERR,
      payload: error.response.data,
    });
  }
};