import { ENQUIRY_ENQUIRYSEARCH_ERR, ENQUIRY_ENQUIRYSEARCH_REQUEST, ENQUIRY_ENQUIRYSEARCH_SUCCESS, ENQUIRY_STUDENT_APPLICATION_FIND_ERR, ENQUIRY_STUDENT_APPLICATION_FIND_REQUEST, ENQUIRY_STUDENT_APPLICATION_FIND_SUCCESS, ENQUIRY_STUDENT_DETAILS_ERR, ENQUIRY_STUDENT_DETAILS_REQUEST, ENQUIRY_STUDENT_DETAILS_SUCCESS } from "../constants/enquiryConstant";

 export const enquiryStudentDetailsReducer = (state = {}, action) => {
   switch (action.type) {
     case ENQUIRY_STUDENT_DETAILS_REQUEST:
       return {
         ...state,
         enquiryStudentDetailsLoading: true,
       };
     case ENQUIRY_STUDENT_DETAILS_SUCCESS:
       return {
         ...state,
         enquiryStudentDetailsLoading: false,
         enquiryStudentDetailsSuccess: action.payload,
       };
     case ENQUIRY_STUDENT_DETAILS_ERR:
       return {
         ...state,
         enquiryStudentDetailsLoading: false,
         enquiryStudentDetailsErr: action.payload,
       };
     default:
       return state;
   }
 };


 export const enquirySearchReducer = (state = {}, action) => {
  switch (action.type) {
    case ENQUIRY_ENQUIRYSEARCH_REQUEST:
      return {
        ...state,
        enquirySearchLoading: true,
      };
    case ENQUIRY_ENQUIRYSEARCH_SUCCESS:
      return {
        ...state,
        enquirySearchLoading: false,
        enquirySearchSuccess: action.payload,
      };
    case ENQUIRY_ENQUIRYSEARCH_ERR:
      return {
        ...state,
        enquirySearchLoading: false,
        enquirySearchErr: action.payload,
      };
    default:
      return state;
  }
};



export const enquiryStudentApplicationFindReducer = (state = {}, action) => {
  switch (action.type) {
    case ENQUIRY_STUDENT_APPLICATION_FIND_REQUEST:
      return {
        ...state,
        enquiryStudentApplicationFindLoading: true,
      };
    case ENQUIRY_STUDENT_APPLICATION_FIND_SUCCESS:
      return {
        ...state,
        enquiryStudentApplicationFindLoading: false,
        enquiryStudentApplicationFindSuccess: action.payload,
      };
    case ENQUIRY_STUDENT_APPLICATION_FIND_ERR:
      return {
        ...state,
        enquiryStudentApplicationFindLoading: false,
        enquiryStudentApplicationFindErr: action.payload,
      };
    default:
      return state;
  }
};