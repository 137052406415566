import React, { useState } from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableFooter,
  TableSortLabel,
  Menu,
  DialogActions,
  Button,
  DialogContent,
  Dialog,
  DialogTitle,
  Typography,
} from "@mui/material";
import { SlOptionsVertical } from "react-icons/sl";
import { Box, Checkbox, MenuItem } from "@mui/material";
import { AiOutlineEye } from "react-icons/ai";
import { TbEdit } from "react-icons/tb";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  deleteReferalsAction,
  updateReferalsAction,
} from "../../../actions/referAndEarn/referAndEarnActions";
import { useDispatch } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import TableNoItemComponent from "../../customComponent/TableNoItemComponent";
import PremissionDialog from "../../customComponent/premissionDialog";
import usePrivilegeCheck from "../../../constants/customHooks/UsePrivilegeCheck";

function ReferalSettingsTable({ data, subMenu }) {
  const dispatch = useDispatch();
  const [showIcons, setShowIcons] = useState(null);
  const [tableId, setTableId] = useState();
  const [open, setOpen] = useState();
  const [formData, setFormData] = useState();
  const [errorData, setErrorData] = useState();

  const {
    privilege,
    hasPrivilege,
    isDeletePrevilage,
    isUpdatePrevilage,
  } = usePrivilegeCheck();

  const [openAlert, setOpenAlert] = useState(false);

  // HandleClose dialog

  const handleCloseDialog = () => {
    setOpenAlert(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validateForm()) {
      e.preventDefault();
      let datas = {
        question: formData.question,
        answer: formData.answer,
      };
      dispatch(updateReferalsAction(datas, tableId));
      setOpen(false);
    } else {
      console.log("Form has errors:", errorData);
    }
  };

  const handleClickAction = (event, id) => {
    setShowIcons(event.currentTarget);
    setTableId(id);
    setFormData(data.find((item) => item.id === id));
  };

  const handleCloseAction = () => {
    setShowIcons(null);
    setFormData(null);
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.question) {
      newErrors.question = "please enter a question";
    }
    if (!formData.answer) {
      newErrors.answer = "please enter a answer";
    }
    setErrorData(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    setErrorData((prev) => ({ ...prev, [name]: "" }));
  };

  const columns = [
    {
      title: "SL NO",
      field: "sl",
    },
    {
      title: "Question",
      field: "question",
    },
    {
      title: "answer",
      field: "answer",
    },
  ];

  //   {
  //     question: "Forem ipsum dolor sit amet, consectetur adipiscing",
  //     answer:
  //       "Forem ipsum dolor sit amet, consectetur adipiscing elit. Forem ipsum dolor sit amet, consectetur adipiscing elit.?",
  //   },
  //   {
  //     question: "Forem ipsum dolor sit amet, consectetur adipiscing",
  //     answer:
  //       "Forem ipsum dolor sit amet, consectetur adipiscing elit. Forem ipsum dolor sit amet, consectetur adipiscing elit.?",
  //   },
  //   {
  //     question: "Forem ipsum dolor sit amet, consectetur adipiscing",
  //     answer:
  //       "Forem ipsum dolor sit amet, consectetur adipiscing elit. Forem ipsum dolor sit amet, consectetur adipiscing elit.?",
  //   },
  //   {
  //     question: "Forem ipsum dolor sit amet, consectetur adipiscing",
  //     answer:
  //       "Forem ipsum dolor sit amet, consectetur adipiscing elit. Forem ipsum dolor sit amet, consectetur adipiscing elit.?",
  //   },
  //   {
  //     question: "Forem ipsum dolor sit amet, consectetur adipiscing",
  //     answer:
  //       "Forem ipsum dolor sit amet, consectetur adipiscing elit. Forem ipsum dolor sit amet, consectetur adipiscing elit.?",
  //   },
  //   {
  //     question: "Forem ipsum dolor sit amet, consectetur adipiscing",
  //     answer:
  //       "Forem ipsum dolor sit amet, consectetur adipiscing elit. Forem ipsum dolor sit amet, consectetur adipiscing elit.?",
  //   },
  // ];

  return (
    <>
      <Dialog
        open={open}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        sx={{
          "& .MuiDialog-paper": {
            margin: "0",
            width: "calc(100% - 30px)",
            maxWidth: "850px",
            borderRadius: "4px",
          },
        }}
        PaperProps={{
          style: {
            alignSelf: "flex-start",
          },
        }}
      >
        <form onSubmit={handleSubmit}>
          <DialogTitle
            sx={{
              display: "flex",
              alignSelf: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography
              sx={{
                fontWeight: "700",
                fontSize: "22px",
                color: "#05050F",
              }}
            >
              {"Edit FAQ"}
            </Typography>
            <CloseIcon
              sx={{
                fontSize: "30px",
                color: "#000",
                cursor: "pointer",
              }}
              onClick={handleClose}
            />
          </DialogTitle>
          <DialogContent
            sx={{
              "& input": {
                border: "1px solid #ECECEC !important",
                outline: "none",
                padding: "16px",
                width: "100%",
                boxSizing: "border-box",
                borderRadius: "4px",
              },
              "& input::placeholder": {
                color: "#ACB1C6",
              },
              "& textarea": {
                border: "1px solid #ECECEC !important",
                outline: "none",
                padding: "16px",
                width: "100%",
                boxSizing: "border-box",
                resize: "none",
                borderRadius: "4px",
              },
              "& textarea::placeholder": {
                color: "#ACB1C6",
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "20px",
                mt: "13px",
              }}
            >
              <Box
                sx={{
                  "& input": {
                    border: errorData?.question
                      ? "1px solid red !important"
                      : "1px solid #ECECEC !important",
                  },
                  "& input::placeholder": {
                    color: errorData?.question ? "red" : "#ACB1C6",
                  },
                }}
              >
                <input
                  type="text"
                  placeholder="Question"
                  onChange={handleChange}
                  name={"question"}
                  value={formData?.question}
                />
                <Typography
                  sx={{
                    color: "red",
                    fontSize: "12px",
                  }}
                >
                  {errorData?.question}
                </Typography>
              </Box>
              <Box
                sx={{
                  "& textarea": {
                    border: errorData?.answer
                      ? "1px solid red !important"
                      : "1px solid #ECECEC !important",
                  },
                  "& textarea::placeholder": {
                    color: errorData?.answer ? "red" : "#ACB1C6",
                  },
                }}
              >
                <textarea
                  placeholder="Answer"
                  name={"answer"}
                  value={formData?.answer}
                  onChange={handleChange}
                />
                <Typography
                  sx={{
                    color: "red",
                    fontSize: "12px",
                  }}
                >
                  {errorData?.answer}
                </Typography>
              </Box>
            </Box>
          </DialogContent>
          <DialogActions
            sx={{
              mb: "20px",
              mr: "15px",
            }}
          >
            <Button
              type={"text"}
              sx={{
                padding: "16px 24px",
                color: "white",
                backgroundColor: "rgba(20, 30, 60, 1)",
                borderRadius: "8px",
                fontWeight: 600,
                fontSize: "12px",
                "&:hover": {
                  backgroundColor: "rgba(20, 30, 60, 0.9)",
                },
              }}
            >
              {"Save"}
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      {data?.length === 0 ? (
        <TableNoItemComponent />
      ) : (
        <div
          style={{
            overflowX: "scroll",
            border: "0px solid #ffff",
          }}
          className="scrollBar"
        >
          <Table className="EmployeeJobTable">
            <TableHead>
              <TableRow className="EmployeeJobTableCell">
                {/* <TableCell
                  style={{
                    position: "sticky",
                    left: 0,
                    zIndex: 1,
                    padding: "1px",
                    border: "1px solid #ddd",
                    backgroundColor: "#fafafa",
                  }}
                >
                  <Checkbox
                    //   checked={selectedRows?.length === sortedData?.length}
                    //   onChange={handleSelectAllRows}
                    style={{ color: "#f81b82" }}
                  />
                </TableCell> */}
                {columns?.map((column) => {
                  return (
                    <TableCell
                      key={column.field}
                      className="EmployeeJobTableCell LeadCommon"
                      style={{ padding: "10px 16px", whiteSpace: "nowrap" }}
                    >
                      <TableSortLabel
                        className="LeadHeaderText"
                        //   active={column === sortBy}
                        //   direction={sortOrder}
                        //   onClick={() => requestSort(column)}
                        //   IconComponent={column.field !== "action" && CustomSortIcon}
                        sx={{
                          textTransform: "uppercase",
                          fontWeight: "700",
                          color: "#666E7D",
                        }}
                      >
                        {column.title}
                      </TableSortLabel>
                    </TableCell>
                  );
                })}
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.map((item, indx) => (
                <TableRow className="EmployeeJobTable" key={indx}>
                  {/* <TableCell
                    style={{
                      position: "sticky",
                      left: 0,
                      zIndex: 1, // To ensure it's above other cells
                      backgroundColor: "white", // Adjust as needed
                      padding: "1px",
                      border: "1px solid #ddd",
                    }}
                  >
                    <Checkbox
                      // checked={selectedRows.includes(item.id)}
                      // onChange={() => handleSelectRow(item.id)}
                      style={{ color: "#f81b82" }}
                    />
                  </TableCell> */}
                  <TableCell
                    style={{
                      padding: "10px 16px",
                      whiteSpace: "nowrap",
                      width: "80px",
                    }}
                  >
                    {indx + 1}
                  </TableCell>
                  <TableCell
                    style={{ padding: "10px 16px", whiteSpace: "nowrap" }}
                  >
                    {item.question}
                  </TableCell>

                  <TableCell style={{ padding: "10px 16px" }}>
                    {item.answer}
                  </TableCell>

                  <TableCell
                    style={{
                      padding: "10px 16px",
                      whiteSpace: "nowrap",
                      width: "20px",
                    }}
                  >
                    <SlOptionsVertical
                      id="demo-positioned-button"
                      aria-controls={
                        showIcons ? "demo-positioned-menu" : undefined
                      }
                      aria-haspopup="true"
                      aria-expanded={showIcons ? "true" : undefined}
                      onClick={(e) => {
                        handleClickAction(e, item.id);
                      }}
                      variant="outlined"
                      style={{ marginLeft: "10px", color: "#F80B7A" }}
                      sx={{
                        backgroundColor: "#141E3C",
                        size: "10px",
                        padding: 1,
                        margin: 3,
                        color: "#fff",
                        width: "75px",
                        height: "40px",
                        cursor: "pointer",
                      }}
                    />
                    {/* TABLE ACTION MENU START*/}
                    <Menu
                      id="demo-positioned-menu"
                      aria-labelledby="demo-positioned-button"
                      open={showIcons}
                      onClose={handleCloseAction}
                      anchorEl={showIcons}
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                      }}
                      anchorOrigin={{
                        vertical: "bottom", // Change from "top" to "bottom"
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "top", // Change from "top" to "bottom"
                        horizontal: "left",
                      }}
                      sx={{
                        borderColor: "black",
                        borderRadius: "24px",
                        mt: 1.5,
                        width: "auto",
                        height: "auto",
                      }}
                    >
                      <Box
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <MenuItem>
                          <TbEdit
                            style={{
                              fontSize: "lg",
                              width: "20px",
                              height: "19px",
                            }}
                            className="icons"
                            onClick={() => {
                              if (isUpdatePrevilage(subMenu)) {
                                setOpen(true);
                                setShowIcons(null);
                              } else {
                                setOpenAlert(true);
                              }
                            }}
                          />
                        </MenuItem>
                        <MenuItem>
                          <DeleteIcon
                            style={{
                              fontSize: "lg",
                              width: "20px",
                              height: "19px",
                              color: "red",
                            }}
                            onClick={() => {
                              if (isDeletePrevilage(subMenu)) {
                                dispatch(deleteReferalsAction(tableId));
                                setShowIcons(null);
                              } else {
                                setOpenAlert(true);
                              }
                            }}
                          />
                        </MenuItem>
                      </Box>
                    </Menu>{" "}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter />
          </Table>
        </div>
      )}

        {openAlert && (
          <PremissionDialog
            openAlert={openAlert}
            handleClose={handleCloseDialog}
          />
        )}
    </>
  );
}

export default ReferalSettingsTable;
