import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Divider,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { MainContainer } from "../content/content.element";
import CommonDocument from "./CommonDocument";

import Swal from "sweetalert2";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { findcountryMasterAddAction } from "../../actions/countryMasterAction";
import { useDispatch, useSelector } from "react-redux";
import {
  countryDocDeleteAction,
  CountryDocFindAction,
  submitCountryDocsAddAction,
} from "../../actions/knowledgeBankActions/CountryDocsActions";
import CountryDocument from "./CountryDocument";
import HeadBreadcrumbs from "../breadcrumbs/breadcrumbs";
import { SelectInputField } from "../customComponent/InputField";
import { OutLinedButton, SubmitButton } from "../customComponent/Buttons";
import usePrivilegeCheck from "../../constants/customHooks/UsePrivilegeCheck";
import { successTrueMsgAction } from "../../actions/globalAction";
import { Close } from "@mui/icons-material";
import AccessDeniedIcon from "../Leads/createLeads/AccessDeniedIcon";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
var statusChaId;
function KnowledgeBankMain(props) {
  // Custom hook for Previlage checking

  const {
    privilege,
    hasPrivilege,
    isCreatePrevilage,
    isDeletePrevilage,
  } = usePrivilegeCheck();

  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [country, setCountry] = useState("");
  const [docId, setDocId] = useState("");
  const [pageName, setPageName] = useState("");
  const [paymentMandotary, setPaymentMandotary] = useState(false);

  const [docDetailsPage, setDocDetailsPage] = useState("common");

  const [countryList, setCountryList] = useState([]);
  const [countryData, setCountryData] = useState([]);
  const [error, setError] = useState("");
  const [previlageAlert, setPrevilageAlert] = useState(false);

  // const [inputValue, setInputValue] = useState("");

  let { countryFindLoading, countryFindSuccess } = useSelector((state) => {
    return state.findcountryMasterAdd;
  });

  let { countryAddLoading, countryAddSuccess, countryAddError } = useSelector(
    (state) => {
      return state.submitCountryDocsAdd;
    }
  );

  let { successAlertMsgSuccess, successAlertMsgErr } = useSelector((state) => {
    return state.successTrueMsg;
  });

  let { countryDocFindSuccess } = useSelector((state) => {
    return state.CountryDocFind;
  });

  let { countryDocDeleteSuccess, countryDocDeleteError } = useSelector(
    (state) => {
      return state.countryDocDelete;
    }
  );

  let { countryPaymentGetWaySuccess } = useSelector((state) => {
    return state.countryPaymentGetwayUpdate;
  });

  const { countryDocAdmissionAddSuccess } = useSelector((state) => {
    return state.countryDocumentAdmissionApplicationAdd;
  });

  let { deleteCommonDocSuccess } = useSelector((state) => {
    return state.deleteCommonDoc;
  });

  useEffect(() => {
    if (countryDocFindSuccess) {
      setCountryData(countryDocFindSuccess);
    }
  }, [countryDocFindSuccess, countryPaymentGetWaySuccess]);

  useEffect(() => {
    if (countryAddError && successAlertMsgSuccess) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: countryAddError,
      });
      dispatch(successTrueMsgAction(false));
    }
  }, [countryAddError, successAlertMsgSuccess]);

  useEffect(() => {
    if (countryAddSuccess && successAlertMsgSuccess) {
      Swal.fire("Added", "Country added successfully.", "success");
      setOpen(false);
      dispatch(successTrueMsgAction(false));
    }
  }, [countryAddSuccess, successAlertMsgSuccess]);

  useEffect(() => {
    dispatch(findcountryMasterAddAction());
  }, [dispatch, countryDocAdmissionAddSuccess, countryPaymentGetWaySuccess]);

  useEffect(() => {
    if (countryFindSuccess) {
      setCountryList(countryFindSuccess);
    }
  }, [countryFindSuccess, countryDocDeleteSuccess]);

  useEffect(() => {
    dispatch(CountryDocFindAction());
  }, [
    dispatch,
    countryAddSuccess,
    countryDocDeleteSuccess,
    countryDocAdmissionAddSuccess,
    countryPaymentGetWaySuccess,
    deleteCommonDocSuccess,
  ]);

  // open country form
  const handleClickOpen = () => {
    if (isCreatePrevilage("document upload")) {
      setOpen(true);
    } else {
      setPrevilageAlert(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setCountry("");
  };

  const handleCountryChange = (event) => {
    setCountry(event.target.value);
    setError("");
  };

  // const handleInputChange = (event) => {
  //   setInputValue(event.target.value);
  // };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (country) {
      dispatch(submitCountryDocsAddAction(country));
      dispatch(successTrueMsgAction(true));
      setCountry("");
      setError("");
    } else {
      setError("Please choose a country");
    }
  };
  // handle country wise page
  const handlePageChangeClick = (data) => {
    // alert(data.DocName);
    statusChaId = data.id;
    setDocId(statusChaId);
    setDocDetailsPage(data.DocName);
    setPageName(data.DocName);
    setPaymentMandotary(data.isPaymentMandotary);
  };

  const handleCommonPageChangeClick = (name) => {
    setDocDetailsPage(name);
  };
  // handle delete country
  const handleCountryDocDeleteClick = (id) => {
    if (isDeletePrevilage("document upload")) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          dispatch(countryDocDeleteAction(id));
          Swal.fire("Deleted!", "Your file has been  deleted.", "success");
        }
      });
    } else {
      setPrevilageAlert(true);
    }
  };

  if (!hasPrivilege("document upload") && privilege !== null) {
    return (
      <MainContainer active={props.toggle}>
        <Alert severity="error">
          <AlertTitle>warning</AlertTitle>
          You don't have permission to access — <strong>this page.!</strong>
        </Alert>
      </MainContainer>
    );
  }

  return (
    <>
      <MainContainer active={props.toggle}>
        <Box sx={{ m: 3 }}>
          <HeadBreadcrumbs
            head="home"
            sechead="Knowledge Bank"
            first="Knowledge Bank"
            // main="Role Managment"
          />
          <Divider />
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4} lg={2.6} ml={2.1}>
            <Paper
              elevation={0}
              sx={{
                height: "100%",
                border: "1px solid #ECECEC",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  cursor: "pointer",
                  backgroundColor:
                    docDetailsPage === "common"
                      ? "rgba(246, 248, 250, 1)"
                      : null,
                }}
                onClick={() => handleCommonPageChangeClick("common")}
              >
                <Typography sx={{ m: 2, fontWeight: 600, fontSize: "14px" }}>
                  Common
                </Typography>{" "}
              </div>
              <Divider variant="middle" />
              {countryData?.map((data) => (
                <>
                  <div
                    key={data.id}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      cursor: "pointer",
                      backgroundColor:
                        docDetailsPage === data.DocName
                          ? "rgba(246, 248, 250, 1)"
                          : null,
                    }}
                  >
                    <Typography
                      sx={{ m: 2, fontWeight: 600, fontSize: "14px" }}
                      onClick={() => handlePageChangeClick(data)}
                    >
                      {data.DocName}
                    </Typography>{" "}
                    <img
                      src="/menuIcons/trash.png"
                      alt="gsl"
                      style={{
                        width: "20px",
                        height: "20px",
                        marginTop: "20px",
                        paddingRight: "15px",
                        cursor: "pointer",
                      }}
                      onClick={() => handleCountryDocDeleteClick(data.id)}
                    />
                  </div>
                  <Divider variant="middle" />
                </>
              ))}
              <Box sx={{ m: 2 }}>
                <Button
                  sx={{
                    width: "100%",
                    backgroundColor: "#ebf2fd",
                    fontWeight: 600,
                    fontSize: "14px",
                  }}
                  onClick={handleClickOpen}
                >
                  Add Country For Document
                </Button>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12} md={8} lg={9.1}>
            <Paper
              elevation={0}
              sx={{ p: 2, height: "100%", border: "1px solid #ECECEC" }}
            >
              {docDetailsPage === "common" && <CommonDocument />}
              {docDetailsPage === pageName && (
                <CountryDocument
                  docId={docId ? docId : statusChaId}
                  country={docDetailsPage}
                  paymentMandotary={paymentMandotary}
                />
              )}
            </Paper>
          </Grid>
        </Grid>

        {/* country form popup */}

        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
          PaperProps={{
            style: {
              marginTop: "10vh", // Adjust the value as needed
              alignSelf: "flex-start",
            },
          }}
        >
          <form onSubmit={handleSubmit}>
            <DialogTitle>{"Add Country"}</DialogTitle>
            <DialogContent>
              <DialogContentText
                id="alert-dialog-slide-description"
                sx={{ width: "400px", mt: 1, height: "70px" }}
              >
                <SelectInputField
                  label="Country"
                  handleSelectChange={handleCountryChange}
                  widthSize="50px"
                  value={country}
                  mapValue={countryList}
                  mapMethod="countryList"
                />
                {error && (
                  <Typography
                    sx={{
                      color: "#c51244",
                      fontSize: "12px",
                      marginTop: "5px",
                    }}
                  >
                    {error}
                  </Typography>
                )}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              {/* <Button
              variant="outlined"
              onClick={handleClose}
              sx={{
                width: "81px",
                height: "35px",
                border: "1px solid rgba(20, 30, 60, 1)",
                color: "black",
                fontWeight: 600,
              }}
            >
              Cancel
            </Button>  */}

              <OutLinedButton
                title="Cancel"
                handleClick={handleClose}
                widthSize="81px"
                heightSize="35px"
              />
              <SubmitButton
                title="Submit"
                submit="submit"
                widthSize="81px"
                heightSize="35px"
              />
            </DialogActions>
          </form>
        </Dialog>
      </MainContainer>

      {/* Dialog for Previlage popUp */}

      <Dialog
        open={previlageAlert}
        onClose={() => setPrevilageAlert(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          zIndex: "9999",
          // background:"red",
          "& .MuiDialog-paper": {
            width: "100%",
            borderRadius: "5px",
            p: "15px",
            textAlign: "center",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "end",
          }}
        >
          <Close
            sx={{
              cursor: "pointer",
            }}
            onClick={() => setPrevilageAlert(false)}
          />
        </Box>
        <Typography
          sx={{
            color: "#05050F",
            fontSize: "26px",
            fontWeight: "700",
          }}
        >
          Access Denied
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            mt: "-10px",
          }}
        >
          <AccessDeniedIcon />
        </Box>
        <Typography
          sx={{
            color: "#717171",
            fontSize: "16px",
            mb: "10px",
          }}
        >
          Sorry, you don’t have permission to create
        </Typography>
      </Dialog>
    </>
  );
}

export default KnowledgeBankMain;
