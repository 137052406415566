import { Box, Typography } from "@mui/material";
import React from "react";
import Lottie from "react-lottie";
import animationData from "./TopicVoicePlaceholder.json";

function TopicUploadComponent({ img, title, type, handleClick }) {
  const options = {
    animationData: animationData,
  };

  return (
    <>
      {type === "audio" ? (
        <Box
          sx={{
            position: "relative",
            cursor: "pointer",
            "&:hover > div:first-of-type": {
              opacity: 1,
            },
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: 0,
              bottom: 0,
              right: 0,
              left: 0,
              background: "rgba(0, 0, 0,.1)",
              display: "flex",
              justifyContent: "end",
              padding: "16px",
              opacity: 0,
              transition: "0.5s ease",
            }}
          >
            <svg
              onClick={handleClick}
              width="20"
              height="24"
              viewBox="0 0 20 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.00605 0.666328C6.00608 0.489775 6.07621 0.320458 6.20103 0.195585C6.32584 0.0707123 6.49512 0.000499242 6.67167 0.000375L13.3275 0C13.5041 0.000409405 13.6733 0.0708169 13.7981 0.19579C13.9229 0.320764 13.993 0.490107 13.9931 0.666703V2.31281H6.00605V0.666328ZM16.9495 23.0465C16.9326 23.306 16.817 23.5493 16.6266 23.7264C16.4361 23.9036 16.1851 24.0013 15.925 23.9994H3.99764C3.73761 23.9987 3.48746 23.8997 3.29745 23.7222C3.10743 23.5447 2.9916 23.3018 2.97323 23.0424L1.95258 8.11191H18.0389L16.9498 23.0463L16.9495 23.0465ZM19.6012 6.75966H0.398438V5.21278C0.398723 4.80252 0.561803 4.40914 0.851871 4.11901C1.14194 3.82888 1.53529 3.66572 1.94555 3.66534L18.0539 3.66483C18.4641 3.66546 18.8573 3.82877 19.1473 4.11893C19.4373 4.40909 19.6004 4.80242 19.6007 5.21264V6.75952L19.6012 6.75966ZM6.90788 20.4402C6.90788 20.5289 6.92536 20.6169 6.95934 20.6989C6.99331 20.7809 7.04311 20.8554 7.10588 20.9182C7.16866 20.981 7.24318 21.0308 7.3252 21.0647C7.40722 21.0987 7.49513 21.1162 7.58391 21.1162C7.67268 21.1162 7.76059 21.0987 7.84261 21.0647C7.92463 21.0308 7.99916 20.981 8.06193 20.9182C8.12471 20.8554 8.1745 20.7809 8.20848 20.6989C8.24245 20.6169 8.25994 20.5289 8.25994 20.4402V10.9598C8.25852 10.7816 8.18671 10.611 8.06016 10.4855C7.9336 10.3599 7.76255 10.2894 7.58426 10.2893C7.40596 10.2892 7.23486 10.3596 7.10822 10.4851C6.98158 10.6106 6.90966 10.7811 6.90811 10.9594V20.4402H6.90788ZM11.7313 20.4402C11.7313 20.6195 11.8025 20.7915 11.9293 20.9183C12.0561 21.0451 12.2281 21.1163 12.4074 21.1163C12.5868 21.1163 12.7587 21.0451 12.8855 20.9183C13.0123 20.7915 13.0836 20.6195 13.0836 20.4402V10.9598C13.0821 10.7815 13.0103 10.611 12.8838 10.4854C12.7572 10.3598 12.5861 10.2893 12.4078 10.2892C12.2295 10.2891 12.0583 10.3595 11.9317 10.4851C11.805 10.6106 11.7331 10.7811 11.7315 10.9594L11.7313 20.4402Z"
                fill="#FC0005"
              />
            </svg>
          </Box>
          <Box
            sx={{
              textAlign: "center",
              height: "134px",
              border: "1px solid rgba(239, 239, 239, 1)",
              borderBottom: "0",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "rgba(239, 239, 239, 1)",
              pointerEvents: "none",
              "& img": {
                width: img && "100%",
                height: img && "100%",
                objectFit: img && "cover",
                display: "block",
              },
            }}
          >
            <Lottie options={options} />
          </Box>
          <Box
            sx={{
              border: "1px solid rgba(239, 239, 239, 1)",
              padding: "12px 16px 15px 16px",
            }}
          >
            <Typography
              sx={{
                color: "rgba(0, 0, 0, 1)",
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                overflow: "hidden",
                WebkitLineClamp: 2, // Limit the text to two lines
                textOverflow: "ellipsis",
                fontSize: "14px",
              }}
            >
              {title}
            </Typography>
          </Box>
        </Box>
      ) : type == "pdf" ? (
        <Box>
          <Box
            sx={{
              position: "relative",
              cursor: "pointer",
              "&:hover > div:first-of-type": {
                opacity: 1,
              },
            }}
          >
            <Box
              sx={{
                position: "absolute",
                top: 0,
                bottom: 0,
                right: 0,
                left: 0,
                background: "rgba(0, 0, 0,.1)",
                display: "flex",
                justifyContent: "end",
                padding: "16px",
                opacity: 0,
                transition: "0.5s ease",
              }}
            >
              <svg
                onClick={handleClick}
                width="20"
                height="24"
                viewBox="0 0 20 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M6.00605 0.666328C6.00608 0.489775 6.07621 0.320458 6.20103 0.195585C6.32584 0.0707123 6.49512 0.000499242 6.67167 0.000375L13.3275 0C13.5041 0.000409405 13.6733 0.0708169 13.7981 0.19579C13.9229 0.320764 13.993 0.490107 13.9931 0.666703V2.31281H6.00605V0.666328ZM16.9495 23.0465C16.9326 23.306 16.817 23.5493 16.6266 23.7264C16.4361 23.9036 16.1851 24.0013 15.925 23.9994H3.99764C3.73761 23.9987 3.48746 23.8997 3.29745 23.7222C3.10743 23.5447 2.9916 23.3018 2.97323 23.0424L1.95258 8.11191H18.0389L16.9498 23.0463L16.9495 23.0465ZM19.6012 6.75966H0.398438V5.21278C0.398723 4.80252 0.561803 4.40914 0.851871 4.11901C1.14194 3.82888 1.53529 3.66572 1.94555 3.66534L18.0539 3.66483C18.4641 3.66546 18.8573 3.82877 19.1473 4.11893C19.4373 4.40909 19.6004 4.80242 19.6007 5.21264V6.75952L19.6012 6.75966ZM6.90788 20.4402C6.90788 20.5289 6.92536 20.6169 6.95934 20.6989C6.99331 20.7809 7.04311 20.8554 7.10588 20.9182C7.16866 20.981 7.24318 21.0308 7.3252 21.0647C7.40722 21.0987 7.49513 21.1162 7.58391 21.1162C7.67268 21.1162 7.76059 21.0987 7.84261 21.0647C7.92463 21.0308 7.99916 20.981 8.06193 20.9182C8.12471 20.8554 8.1745 20.7809 8.20848 20.6989C8.24245 20.6169 8.25994 20.5289 8.25994 20.4402V10.9598C8.25852 10.7816 8.18671 10.611 8.06016 10.4855C7.9336 10.3599 7.76255 10.2894 7.58426 10.2893C7.40596 10.2892 7.23486 10.3596 7.10822 10.4851C6.98158 10.6106 6.90966 10.7811 6.90811 10.9594V20.4402H6.90788ZM11.7313 20.4402C11.7313 20.6195 11.8025 20.7915 11.9293 20.9183C12.0561 21.0451 12.2281 21.1163 12.4074 21.1163C12.5868 21.1163 12.7587 21.0451 12.8855 20.9183C13.0123 20.7915 13.0836 20.6195 13.0836 20.4402V10.9598C13.0821 10.7815 13.0103 10.611 12.8838 10.4854C12.7572 10.3598 12.5861 10.2893 12.4078 10.2892C12.2295 10.2891 12.0583 10.3595 11.9317 10.4851C11.805 10.6106 11.7331 10.7811 11.7315 10.9594L11.7313 20.4402Z"
                  fill="#FC0005"
                />
              </svg>
            </Box>
            <Box
              sx={{
                textAlign: "center",
                height: "134px",
                border: "1px solid rgba(239, 239, 239, 1)",
                borderBottom: "0",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "rgba(239, 239, 239, 1)",
              }}
            >
              <svg
                width="78"
                height="78"
                viewBox="0 0 78 78"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M19.9864 0H46.0644L68.1663 23.0375V67.8456C68.1663 73.4588 63.6251 78 58.0313 78H19.9864C14.3732 78 9.83203 73.4588 9.83203 67.8456V10.1544C9.83193 4.54121 14.3731 0 19.9864 0Z"
                  fill="#E5252A"
                />
                <path
                  opacity="0.302"
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M46.0449 0V22.8621H68.1664L46.0449 0Z"
                  fill="white"
                />
                <path
                  d="M21.1172 58.1986V43.9512H27.1787C28.6794 43.9512 29.8684 44.3605 30.7649 45.1986C31.6614 46.0171 32.1098 47.1281 32.1098 48.5119C32.1098 49.8957 31.6614 51.0067 30.7649 51.8252C29.8684 52.6633 28.6794 53.0726 27.1787 53.0726H24.7619V58.1986H21.1172ZM24.7619 49.9737H26.7694C27.3151 49.9737 27.7439 49.8568 28.0363 49.584C28.3286 49.3306 28.4846 48.9797 28.4846 48.512C28.4846 48.0443 28.3287 47.6934 28.0363 47.44C27.744 47.1671 27.3152 47.0503 26.7694 47.0503H24.7619V49.9737ZM33.6104 58.1986V43.9512H38.6584C39.6524 43.9512 40.588 44.0876 41.465 44.38C42.342 44.6723 43.1412 45.0817 43.8428 45.6469C44.5445 46.1926 45.1097 46.9332 45.519 47.8687C45.9087 48.8043 46.1232 49.8763 46.1232 51.0846C46.1232 52.2736 45.9088 53.3455 45.519 54.281C45.1097 55.2165 44.5445 55.9572 43.8428 56.5028C43.1411 57.068 42.342 57.4773 41.465 57.7697C40.588 58.0621 39.6524 58.1986 38.6584 58.1986H33.6104ZM37.1772 55.0997H38.2296C38.7948 55.0997 39.3211 55.0412 39.8083 54.9047C40.276 54.7683 40.7244 54.5539 41.1532 54.2616C41.5625 53.9693 41.8938 53.5599 42.1277 53.0142C42.3615 52.4685 42.4785 51.8252 42.4785 51.0846C42.4785 50.3245 42.3615 49.6813 42.1277 49.1356C41.8938 48.59 41.5625 48.1807 41.1532 47.8883C40.7244 47.5959 40.2761 47.3815 39.8083 47.2451C39.3211 47.1087 38.7948 47.0502 38.2296 47.0502H37.1772V55.0997ZM47.9553 58.1986V43.9512H58.0902V47.0502H51.6V49.3305H56.7843V52.41H51.6V58.1986H47.9553Z"
                  fill="white"
                />
              </svg>
            </Box>
            <Box
              sx={{
                border: "1px solid rgba(239, 239, 239, 1)",
                padding: "12px 16px 15px 16px",
                background: "#fff",
              }}
            >
              <Typography
                sx={{
                  color: "rgba(0, 0, 0, 1)",
                  display: "-webkit-box",
                  WebkitBoxOrient: "vertical",
                  overflow: "hidden",
                  WebkitLineClamp: 2, // Limit the text to two lines
                  textOverflow: "ellipsis",
                  fontSize: "14px",
                }}
              >
                {title}
              </Typography>
            </Box>
          </Box>
        </Box>
      ) : type == "add" ? (
        <Box
          sx={{
            position: "relative",
            cursor: "pointer",
          }}
          onClick={handleClick}
        >
          <Box
            sx={{
              textAlign: "center",
              height: "134px",
              border: "1px solid rgba(239, 239, 239, 1)",
              borderBottom: "0",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "#fff",
              pointerEvents: "none",
              "& img": {
                width: img && "100%",
                height: img && "100%",
                objectFit: img && "cover",
                display: "block",
              },
            }}
          >
            <svg
              width="66"
              height="66"
              viewBox="0 0 66 66"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                y="28.7871"
                width="66"
                height="8.42553"
                rx="4.21277"
                fill="#3B478F"
              />
              <rect
                x="37.2129"
                width="66"
                height="8.42553"
                rx="4.21277"
                transform="rotate(90 37.2129 0)"
                fill="#3B478F"
              />
            </svg>
          </Box>
          <Box
            sx={{
              border: "1px solid rgba(239, 239, 239, 1)",
              padding: "12px 16px 15px 16px",
              background: "rgba(255, 255, 255,1)",
            }}
          >
            <Typography
              sx={{
                color: "rgba(0, 0, 0, 1)",
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                overflow: "hidden",
                WebkitLineClamp: 2, // Limit the text to two lines
                textOverflow: "ellipsis",
                fontSize: "14px",
                textAlign: "center",
              }}
            >
              {title}
            </Typography>
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            position: "relative",
            cursor: "pointer",
            "&:hover > div:first-of-type": {
              opacity: 1,
            },
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: 0,
              bottom: 0,
              right: 0,
              left: 0,
              background: "rgba(0, 0, 0,.1)",
              display: "flex",
              justifyContent: "end",
              padding: "16px",
              opacity: 0,
              transition: "0.5s ease",
            }}
          >
            <svg
              onClick={handleClick}
              width="20"
              height="24"
              viewBox="0 0 20 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.00605 0.666328C6.00608 0.489775 6.07621 0.320458 6.20103 0.195585C6.32584 0.0707123 6.49512 0.000499242 6.67167 0.000375L13.3275 0C13.5041 0.000409405 13.6733 0.0708169 13.7981 0.19579C13.9229 0.320764 13.993 0.490107 13.9931 0.666703V2.31281H6.00605V0.666328ZM16.9495 23.0465C16.9326 23.306 16.817 23.5493 16.6266 23.7264C16.4361 23.9036 16.1851 24.0013 15.925 23.9994H3.99764C3.73761 23.9987 3.48746 23.8997 3.29745 23.7222C3.10743 23.5447 2.9916 23.3018 2.97323 23.0424L1.95258 8.11191H18.0389L16.9498 23.0463L16.9495 23.0465ZM19.6012 6.75966H0.398438V5.21278C0.398723 4.80252 0.561803 4.40914 0.851871 4.11901C1.14194 3.82888 1.53529 3.66572 1.94555 3.66534L18.0539 3.66483C18.4641 3.66546 18.8573 3.82877 19.1473 4.11893C19.4373 4.40909 19.6004 4.80242 19.6007 5.21264V6.75952L19.6012 6.75966ZM6.90788 20.4402C6.90788 20.5289 6.92536 20.6169 6.95934 20.6989C6.99331 20.7809 7.04311 20.8554 7.10588 20.9182C7.16866 20.981 7.24318 21.0308 7.3252 21.0647C7.40722 21.0987 7.49513 21.1162 7.58391 21.1162C7.67268 21.1162 7.76059 21.0987 7.84261 21.0647C7.92463 21.0308 7.99916 20.981 8.06193 20.9182C8.12471 20.8554 8.1745 20.7809 8.20848 20.6989C8.24245 20.6169 8.25994 20.5289 8.25994 20.4402V10.9598C8.25852 10.7816 8.18671 10.611 8.06016 10.4855C7.9336 10.3599 7.76255 10.2894 7.58426 10.2893C7.40596 10.2892 7.23486 10.3596 7.10822 10.4851C6.98158 10.6106 6.90966 10.7811 6.90811 10.9594V20.4402H6.90788ZM11.7313 20.4402C11.7313 20.6195 11.8025 20.7915 11.9293 20.9183C12.0561 21.0451 12.2281 21.1163 12.4074 21.1163C12.5868 21.1163 12.7587 21.0451 12.8855 20.9183C13.0123 20.7915 13.0836 20.6195 13.0836 20.4402V10.9598C13.0821 10.7815 13.0103 10.611 12.8838 10.4854C12.7572 10.3598 12.5861 10.2893 12.4078 10.2892C12.2295 10.2891 12.0583 10.3595 11.9317 10.4851C11.805 10.6106 11.7331 10.7811 11.7315 10.9594L11.7313 20.4402Z"
                fill="#FC0005"
              />
            </svg>
          </Box>
          <Box
            sx={{
              textAlign: "center",
              height: "134px",
              border: "1px solid rgba(239, 239, 239, 1)",
              borderBottom: "0",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "rgba(239, 239, 239, 1)",
              "& img": {
                width: img && "100%",
                height: img && "100%",
                objectFit: img && "cover",
                display: "block",
              },
            }}
          >
            <img src={img ? img : "/TopicVideoPlaceholder.png"} />
          </Box>
          <Box
            sx={{
              border: "1px solid rgba(239, 239, 239, 1)",
              padding: "12px 16px 15px 16px",
            }}
          >
            <Typography
              sx={{
                color: "rgba(0, 0, 0, 1)",
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                overflow: "hidden",
                WebkitLineClamp: 2, // Limit the text to two lines
                textOverflow: "ellipsis",
                fontSize: "14px",
              }}
            >
              {title}
            </Typography>
          </Box>
        </Box>
      )}
    </>
  );
}

export default TopicUploadComponent;
