import axios from "axios";

import { SUCCESS_TRUE_MSG_ERR } from "../../constants/roleManagmentConstant";
import { IELTS_STUDENTMANAGMENT_CREATE_ERR, IELTS_STUDENTMANAGMENT_CREATE_REQUEST, IELTS_STUDENTMANAGMENT_CREATE_SUCCESS } from "../../constants/Ielts/ieltsStudentManagmentConstants";

export const ieltsStudentManagmentCreateAction = (formData) => async (
  dispatch
) => {
  try {
    // alert("entered")
    dispatch({ type: IELTS_STUDENTMANAGMENT_CREATE_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.post(
      "/api/admin/ielts/students/createStudent",
      formData,
      config
    );

    dispatch({ type: IELTS_STUDENTMANAGMENT_CREATE_SUCCESS, payload: data });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({
      type: IELTS_STUDENTMANAGMENT_CREATE_ERR,
      payload: error.response.data,
    });
  }
};
