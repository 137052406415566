import React, { useEffect, useRef, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Grid, TextField, Typography } from "@mui/material";

import Select from "react-select";
import { countries } from "countries-list";
import { useDispatch } from "react-redux";

import { countryMasterAddAction } from "../../../../actions/countryMasterAction";
import { OutLinedButton, SubmitButton } from "../../../customComponent/Buttons";
import { TypographyText } from "../../../customComponent/Typography";
import { successTrueMsgAction } from "../../../../actions/globalAction";

const allCountries = Object.entries(countries).map(([code, country]) => ({
  value: code,
  label: `${country.name} (+${country.phone})`,
  phone: `+${country.phone}`,
}));

console.log(allCountries,"allCountriesallCountries")

function AddCountry({ status, addMasterCategryHandler }) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(status);
  const [scroll, setScroll] = useState("paper");
  const [errorMsg, setErrorMsg] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(null);

  const handleClose = () => {
    setOpen(false);
    addMasterCategryHandler(status, false);
  };

  const handleSubmit = () => {
    if (selectedCountry || selectedCountry !== null) {
      dispatch(countryMasterAddAction(selectedCountry));
      dispatch(successTrueMsgAction(true))
      setSelectedCountry(null);
      setErrorMsg(false);
      setOpen(false);
    addMasterCategryHandler(status, false);
    } else {
      setErrorMsg(true);
    }
  };

  const descriptionElementRef = useRef(null);
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  //   form

  const handleCountryChange = (selectedOption) => {
    setErrorMsg(false);
    setSelectedCountry(selectedOption);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">
          {" "}
          <TypographyText title="Add New Country" variant="h5" />
        </DialogTitle>

        <DialogContent dividers={scroll === "paper"}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            <div style={{ width: "500px", height: "400px" }}>
              {errorMsg && (
                <Typography align="center" color="error">
                  Please Choose Country
                </Typography>
              )}
              <Select
                options={allCountries}
                value={selectedCountry}
                onChange={handleCountryChange}
                placeholder="Select a country"
                style={{ width: "100%", height: "50px" }}
              />
              {selectedCountry && (
                <Grid container>
                  <Grid item xs={12} sx={{ mt: 2 }}>
                    <TextField
                      label="Country Code"
                      value={selectedCountry.value}
                      disabled
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sx={{ mt: 2 }}>
                    <TextField
                      label="Phone Code"
                      value={countries[selectedCountry.value].phone}
                      disabled
                      fullWidth
                    />
                  </Grid>
                </Grid>
              )}
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <OutLinedButton
            title="Cancel"
            handleClick={handleClose}
            widthSize="81px"
            heightSize="35px"
          />
          <SubmitButton
            title="Add"
            submit=""
            widthSize="81px"
            heightSize="35px"
            handleSubmit={handleSubmit}
            type="click"
          />
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default AddCountry;
