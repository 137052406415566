import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  NormalInputField,
  NormalSelect,
} from "../../customComponent/InputField";
import { useDispatch, useSelector } from "react-redux";
import { findUniversityAction } from "../../../actions/universityActions/universityManagmentAction";
import {
  universityCommissionFindByIdAction,
  updateUniversityCommissionByIdAction,
} from "../../../actions/reportActions/reportsActions";
import { successTrueMsgAction } from "../../../actions/globalAction";

function UniversityCommessionPopUp({ open, handleClose, id }) {
  const dispatch = useDispatch();

  let { universityFindSuccess } = useSelector((state) => {
    return state.findUniversity;
  });

  let { universityCommissionByIdSuccess } = useSelector((state) => {
    return state.UniversityCommissionFindById;
  });

  useEffect(() => {
    dispatch(universityCommissionFindByIdAction(id));
  }, [id]);

  useEffect(() => {
    dispatch(findUniversityAction());
  }, []);

  useEffect(() => {
    if (universityCommissionByIdSuccess) {
      let response = universityCommissionByIdSuccess;
      setFormData({
        university: response?.universityName,
        territory: response?.territory,
        territoryRestrictions: response?.territory_Restriction,
        ug: response?.ug,
        pg: response?.pg,
        ps: response?.ps,
        fo: response?.fo,
        preM: response?.preM,
        phd: response?.phd,
        authorityCase: response?.authority_cases,
        courseType: response?.course_type,
        courseNameExclusion: response?.course_name_exclusion,
        notes: response?.notes,
      });
    }
  }, [universityCommissionByIdSuccess]);

  useEffect(() => {
    if (universityFindSuccess) {
      let filteredData = universityFindSuccess.map(
        ({ id, universityName }) => ({ id, universityName })
      );
      setListedUniversity(filteredData);
      console.log(filteredData);
    }
  }, [universityFindSuccess]);

  const [formData, setFormData] = useState({
    university: "",
    territory: "",
    territoryRestrictions: "",
    ug: "",
    pg: "",
    ps: "",
    fo: "",
    preM: "",
    phd: "",
    authorityCase: "",
    courseType: "",
    courseNameExclusion: "",
    notes: "",
  });

  const [listedUniversity, setListedUniversity] = useState();
  const [errors, setErrors] = useState({});

  const validateForm = () => {
    let valid = true;
    const newErrors = {};
    const integerRegex = /^\d+$/;

    if (formData.university === "") {
      newErrors.university = "University is required";
      valid = false;
    }

    if (!integerRegex.test(formData.ug) && formData.ug !== "") {
      newErrors.ug = "Please enter a valid number for ug";
      valid = false;
    }
    if (!integerRegex.test(formData.pg) && formData.pg !== "") {
      newErrors.pg = "Please enter a valid number for pg";
      valid = false;
    }
    if (!integerRegex.test(formData.fo) && formData.fo !== "") {
      newErrors.fo = "Please enter a valid number for fo";
      valid = false;
    }
    if (!integerRegex.test(formData.phd) && formData.phd !== "") {
      newErrors.phd = "Please enter a valid number for phd";
      valid = false;
    }

    // if (formData.territory.trim() === "") {
    //   newErrors.territory = "Territory is required";
    //   valid = false;
    // }
    // if (formData.territoryRestrictions.trim() === "") {
    //   newErrors.territoryRestrictions = "Territory Restrictions is required";
    //   valid = false;
    // }

    // if (formData.ug.trim() === "") {
    //   newErrors.ug = "Ug is required";
    //   valid = false;
    // } else if (!integerRegex.test(formData.ug)) {
    //   newErrors.ug = "Please enter a valid number for ug";
    //   valid = false;
    // }

    // if (formData.pg.trim() === "") {
    //   newErrors.pg = "Pg is required";
    //   valid = false;
    // } else if (!integerRegex.test(formData.pg)) {
    //   newErrors.pg = "Please enter a valid number for pg";
    //   valid = false;
    // }

    // if (formData.ps.trim() === "") {
    //   newErrors.ps = "Ps is required";
    //   valid = false;
    // }

    // if (formData.fo.trim() === "") {
    //   newErrors.fo = "Fo is required";
    //   valid = false;
    // } else if (!integerRegex.test(formData.fo)) {
    //   newErrors.fo = "Please enter a valid number for fo";
    //   valid = false;
    // }

    // if (formData.preM.trim() === "") {
    //   newErrors.preM = "Pre-M is required";
    //   valid = false;
    // }

    // if (formData.phd.trim() === "") {
    //   newErrors.phd = "Phd is required";
    //   valid = false;
    // } else if (!integerRegex.test(formData.phd)) {
    //   newErrors.phd = "Please enter a valid number for phd";
    //   valid = false;
    // }

    // if (formData.authorityCase.trim() === "") {
    //   newErrors.authorityCase = "Authority case is required";
    //   valid = false;
    // }
    // if (formData.courseNameExclusion.trim() === "") {
    //   newErrors.courseNameExclusion = "Course name exclusion case is required";
    //   valid = false;
    // }
    // if (formData.courseType.trim() === "") {
    //   newErrors.courseType = "Course type is required";
    //   valid = false;
    // }
    // if (formData.notes.trim() === "") {
    //   newErrors.notes = "Notes type is required";
    //   valid = false;
    // }

    setErrors(newErrors);
    return valid;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
    setErrors((prev) => ({
      ...prev,
      [name]: "",
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      let pickedUniversity = listedUniversity.find(
        (item) => item.universityName === formData.university
      );
      let submittedData = {
        university: pickedUniversity,
        territory: formData.territory,
        territoryRestrictions: formData.territoryRestrictions,
        ug: formData.ug,
        pg: formData.pg,
        ps: formData.ps,
        fo: formData.fo,
        preM: formData.preM,
        phd: formData.phd,
        authorityCase: formData.authorityCase,
        courseType: formData.courseType,
        courseNameExclusion: formData.courseNameExclusion,
        notes: formData.notes,
      };
      console.log("Form is valid, submit data:", submittedData, id);
      dispatch(updateUniversityCommissionByIdAction(submittedData, id));
      dispatch(successTrueMsgAction(true));
      handleClose();
    } else {
      console.log("Form is invalid");
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{
        "& .MuiDialog-paper": {
          maxWidth: "1100px",
          width: "100%",
        },
      }}
    >
      <DialogTitle
        id="alert-dialog-title"
        sx={{ fontWeight: "600", textAlign: "center" }}
      >
        {"Add university Commissions"}
      </DialogTitle>

      <DialogContent>
        <form>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              gap: "20px",
              pt: "20px",
            }}
          >
            <NormalSelect
              value={formData?.university}
              label={"University"}
              name={"university"}
              handleChange={handleChange}
              error={!!errors.university}
              helperText={errors.university}
              options={listedUniversity}
            />

            <NormalInputField
              value={formData.territory}
              name={"territory"}
              handleChange={handleChange}
              label="Territoty"
              error={!!errors.territory}
              helperText={errors.territory}
            />

            <NormalInputField
              value={formData.territoryRestrictions}
              name={"territoryRestrictions"}
              handleChange={handleChange}
              label="Territoty Restrictions"
              error={!!errors.territoryRestrictions}
              helperText={errors.territoryRestrictions}
            />

            <NormalInputField
              label="Ug"
              value={formData.ug}
              name={"ug"}
              handleChange={handleChange}
              error={!!errors.ug}
              helperText={errors.ug}
            />

            <NormalInputField
              label="Pg"
              value={formData.pg}
              name={"pg"}
              handleChange={handleChange}
              error={!!errors.pg}
              helperText={errors.pg}
            />

            <NormalInputField
              value={formData.ps}
              name={"ps"}
              handleChange={handleChange}
              label="Ps"
              error={!!errors.ps}
              helperText={errors.ps}
            />

            <NormalInputField
              value={formData.fo}
              name={"fo"}
              handleChange={handleChange}
              label="Fo"
              error={!!errors.fo}
              helperText={errors.fo}
            />

            <NormalInputField
              value={formData.preM}
              name={"preM"}
              handleChange={handleChange}
              label="Pre-M"
              error={!!errors.preM}
              helperText={errors.preM}
            />

            <NormalInputField
              value={formData.phd}
              name={"phd"}
              handleChange={handleChange}
              label="PHD"
              error={!!errors.phd}
              helperText={errors.phd}
            />

            <NormalInputField
              value={formData.authorityCase}
              name={"authorityCase"}
              handleChange={handleChange}
              label="Authority Cases"
              error={!!errors.authorityCase}
              helperText={errors.authorityCase}
            />

            <NormalInputField
              value={formData.courseType}
              name={"courseType"}
              handleChange={handleChange}
              label="Course Type"
              error={!!errors.courseType}
              helperText={errors.courseType}
            />

            <NormalInputField
              value={formData.courseNameExclusion}
              name={"courseNameExclusion"}
              handleChange={handleChange}
              label="Course Name Exclusion"
              error={!!errors.courseNameExclusion}
              helperText={errors.courseNameExclusion}
            />

            <NormalInputField
              value={formData.notes}
              name={"notes"}
              handleChange={handleChange}
              label="Notes"
              error={!!errors.notes}
              helperText={errors.notes}
            />
          </Box>
        </form>
      </DialogContent>

      <DialogActions>
        <Button
          autoFocus
          sx={{
            backgroundColor: "rgba(20, 30, 60, 1) !important",
            color: "#fff",
          }}
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default UniversityCommessionPopUp;
