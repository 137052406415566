import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import List from "@mui/material/List";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";

import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleTwoToneIcon from "@mui/icons-material/CheckCircleTwoTone";
import {
  Checkbox,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { findUserAction } from "../../actions/adminAuthAction";
import { findEmployeeMatchedRoleAction } from "../../actions/employeeManagmentAction";
import { leadOwnerChangeAction } from "../../actions/leadActions/leadActions";
import { useParams } from "react-router-dom";

const ukFlow = [
  "Application Started",
  "Application Rejected",
  "Submitted Declaration",
  "Declaration Completed",
  "Offer Letter Received",
  "Deposit Pending",
  "Deposit Paid",
  "Tuition Fee Pending",
  "Tuition Fee Paid",
  "Interview Training",
  "Interview In Training​",
  "Interview Failed",
  "Interview Completed​",
  "Refund In Progress",
  "Refund Credited",
  "Financial Documents and Medical Waiting",
  "Financial Documents and Medical Received",
  "CAS Applied",
  "CAS Received",
  "Visa In Progress",
  "Visa Received",
  "Visa Rejected",
  "Enrolled"
];

const UsaFlow = [
  "Application Started",
  "Application Rejected",
  "Offer Letter Received",
  "I20 Applied",
  "I20 Received",
  "Financial Documents",
  "Visa In Progress",
  "Visa Received",
  "Deposit Pending",
  "Deposit Paid",
  "Tuition Fee Pending",
  "Tuition Fee Paid",
  "Visa Rejected",
  "Enrolled"
];

const newZlandFlow = [
  "Application submitted", 
  "Started maintenance fund",  
  "Offer Letter Received",
  "Financial Documents and Medical Waiting",
  "Financial Documents and Medical Received",
  "Visa Applied",
  "Visa Rejected", 
  "AIP Received",
  "Deposit Pending", 
  "Deposit Paid" ,
  "Tuition Fee Pending", 
  "Tuition Fee Paid",
  "Visa Approved",
  "Enrolled" 
];

const GermanyFlow = [
  "Application submitted",  
  "Application Rejected",
  "APS Completed",
  "Offer Letter Received",
  "Started Block Account",
  "Deposit Pending", 
  "Deposit Paid",
  "Tuition Fee Pending",
  "Tuition Fee Paid",
  "Financial Documents and Medical Waiting",
  "Financial Documents and Medical Received", 
  "Insurance and Accommodation Received",
  "Visa In Progress",
  "Visa Received",
  "Visa Rejected",
  "Enrolled" 
];

const FranceFlow = [
  "Application started", 
  "Application rejected", 
  "Offer Letter Received",
  "Campus France Submitted", 
  "Interview Training",
  "Interview In Training​",
  "Interview Failed",
  "Interview Completed", 
  "NOC​",
  "Financial Documents",   
  "Insurance and Accommodation Received",
  "Deposit Pending",
  "Deposit Paid",
  "Tuition Fee Pending", 
  "Tuition Fee Paid", 
  "Visa In Progress",
  "Visa Received",
  "Visa Rejected",
  "Enrolled"  
];


const AustriaFlow = [
  "Application submitted",  
  "Interview Training",
  "Interview In Training",
  "Interview Failed",
  "Interview Completed",
  "Offer Letter Received",
  "Financial Document waiting", 
  "Financial Documents Received",  
  "Deposit Pending",
  "Deposit Paid",
  "Tuition Fee Pending", 
  "Tuition Fee Paid",
  "Started maintenance fund",  
  "Visa In Progress",
  "Visa Received",
  "Visa Rejected",
  "Enrolled"
];

  


const AustraliaFlow = [
  "Application submitted", 
  "Application Rejected",
  "Offer Letter Received",
  "Started maintenance fund",
  "GTE or GS",
  "GTE Approved",  
  "Deposit Pending", 
  "Deposit Paid",
  "Tuition Fee Pending", 
  "Tuition Fee Paid",
  "COE Applied",
  "COE Released", 
  "Financial Documents and Medical Waiting", 
  "Financial Documents and Medical Received", 
  "Visa In Progress",
  "Visa Received",
  "Visa Rejected",
  "Enrolled"
];


const CanadaFlow = [
  "Application submitted",
  "Application Rejected" ,
  "Offer Letter Received",
  "GIC",
  "Deposit Pending", 
  "Deposit Paid",
  "Tuition Fee Pending", 
  "Tuition Fee Paid",
  "PAL",
  "Financial Documents and Medical Waiting" ,
  "Financial Documents and Medical Received" ,
  "Visa In Progress" ,
  "Visa Received", 
  "Visa Rejected" ,
  "Enrolled" 
];

const LuxembourgFlow = [
  "Application Started", 
  "Mock Interview Completed", 
  "Application Accepted",
  "Application Rejected",
  "Offer Letter Received",
  "Deposit Pending",
  "Deposit Paid" , 
  "Tuition Fee Pending",
  "Tuition Fee Paid",   
  "Documents Attested",  
  "ATS Process Accepted",
  "ATS Process Rejected",
  "Applied For Diploma Recognition",
  "Book Accommodation" , 
  "VISA Accepted",
  "VISA Rejected", 
  "No interviews",  
  "Enrolled"
]


const LithuaniaFlow = [
  "Application Accepted",
  "Documents Attested", 
  "Mock Interview completed", 
  "Offer Letter Received",  
  "Deposit Pending", 
  "Deposit Paid",
  "Tuition Fee Pending",
  "Tuition Fee Paid",
  "University Interview Passed",
  "University Interview Failed",  
  "Financial Documents Waiting",
  "Financial Documents Received" , 
  "TRP Applied​",
  "TRP Rejected" ,
  "Visa Approved", 
  "Visa Rejected",
  "Enrolled"  
]

const LatviaFlow = [
  "Application Accepted",
  "Application Rejected",
  "Mock Interview completed", 
"Offer Letter Received",
"Deposit Pending", 
"Deposit Paid",
"Tuition Fee Pending", 
"Tuition Fee Paid", 
"University Interview Passed",
"University Interview Failed",  
"Documents Attested", 
"Applied For Diploma Recognition" , 
"Apply for a residence permit",
"Visa Approved",
"Visa Rejected",
"Enrolled"  
]

const IrelandFlow = [
  "Application Started", 
"Applicaton Rejected",
"Mock Completed",
"University Interview Passed", 
"Universityo Interview Rejcted",  
"Offer Letter Received",
"Deposit Pending",
"Deposit Paid",
"Tuition Fee Pending" ,
"Tuition Fee Paid",   
"Finance waiting",
"Finance Received", 
"VISA Applied", 
"VISA Approved" ,
"VISA Rejected" , 
"Entrolled" 

]

const commonFlow = [
"Application Started", 
"Application Rejected",
"Offer Letter Recieved", 
"Interview Training",
"Interview In Training", 
"Interview Completed",
"Deposit Pending",
"Deposit Paid",
"Tuition Fee Pending", 
"Tuition Fee Paid",
"Refund In Progress", 
"Refund Credited",
"Visa In Progress",
"Visa Received",
"Visa Rejected",
"Entrolled" 
]
const interviewerStatus = [
  "Interview Processing",
  "Mock section Schedule",
  "Practice Section",
  "Interview Completed"
];

const visaStatus = [
  // "Submitted university",
  "Visa Applied",
  "Visa in proccess",
  "Visa Approved",
  "Visa Rejected"
];

function SimpleDialog(props) {
  const { onClose, selectedValue, open, currentStatus, leadId } = props;
  const dispatch = useDispatch();
  const { id } = useParams();
  const [roleData, setRoleData] = useState();
  const [Status, setStatus] = useState([]);

  console.log(props.applicationCountry, "applicationCountryapplicationCountry");
  let {
    findEmployeeMatchedRoleLoading,
    findEmployeeMatchedRoleSuccess,
    findEmployeeMatchedRoleError
  } = useSelector((state) => {
    return state.findEmployeeMatchedRole;
  });

  const [isStatusUpdated, setIsStatusUpdated] = useState(false);

  useEffect(() => {
    if (!isStatusUpdated) {
      if (props.applicationCountry === "United Kingdom (+44)") {
        
        setStatus(ukFlow)
      }
      if (props.applicationCountry === "United States (+1)") {
        setStatus(UsaFlow)
      }
      if (props.applicationCountry === "New Zealand (+64)") {
        setStatus(newZlandFlow)
      }
      if (props.applicationCountry === "Germany (+49)") {
        setStatus(GermanyFlow)
      }
      if (props.applicationCountry === "France (+33)") {
        setStatus(FranceFlow)
      }

      if (props.applicationCountry === "Austria (+43)") {
        setStatus(AustriaFlow)
      }

      if (props.applicationCountry === "Australia (+61)") {
        setStatus(AustraliaFlow)
      }

      
      if (props.applicationCountry === "Canada (+1)") {
        setStatus(CanadaFlow)
      }

      if (props.applicationCountry === "Luxembourg (+352)") {
        setStatus(LuxembourgFlow)
      }

      if (props.applicationCountry === "Lithuania (+370)") {
        setStatus(LithuaniaFlow)
      }

      if (props.applicationCountry === "Latvia (+371)") {
        setStatus(LatviaFlow)
      }

      if (props.applicationCountry === "Ireland (+353)") {
        setStatus(IrelandFlow)
      }

      if (props.applicationCountry !== "Ireland (+353)"
        && props.applicationCountry !== "United Kingdom (+44)" 
        && props.applicationCountry !== "United States (+1)"
        && props.applicationCountry !== "New Zealand (+64)"
        && props.applicationCountry !== "Germany (+49)"
        && props.applicationCountry !== "France (+33)"
        && props.applicationCountry !== "Austria (+43)"
        && props.applicationCountry !== "Australia (+61)"
        && props.applicationCountry !== "Canada (+1)"
        && props.applicationCountry !== "Luxembourg (+352)"
        && props.applicationCountry !== "Lithuania (+370)"
        && props.applicationCountry !== "Latvia (+371)"
        && props.applicationCountry !== "Ireland (+353)"
      ) {
        setStatus(commonFlow)
      }
      setIsStatusUpdated(true);
    }
  }, [props.applicationCountry, isStatusUpdated]);

  // useEffect(() => {
  //   if (!isStatusUpdated) {
  //     if (props.applicationCountry === "United Kingdom (+44)") {

  //         Status.push(
  //           "Application Started",
  //           "Application Rejected" ,
  //           "Submitted  Declaration" ,
  //           "Declaration Completed",
  //           "Offer Letter Received" ,
  //           "Deposit Pending",
  //           "Deposit Paid",
  //           "Tuition Fee Pending",
  //           "Tuition Fee Paid",
  //           "Interview Training",
  //           "Interview In Training​",
  //           "Interview Failed",
  //           "Interview Completed​",
  //           "Refund In Progress",
  //           "Refund Credited" ,
  //           "Financial Documents and Medical Waiting",
  //           "CAS Applied" ,
  //           "CAS Received" ,
  //           "Visa In Progress",
  //           "Visa Received",
  //           "Visa Rejected",
  //           "Enrollment Successful"
  //         );

  //     }
  //     if (props.applicationCountry === "United States (+1)") {
  //       if (!Status.includes("i20")) {
  //         Status.splice(1, 0, "i20");
  //       }
  //     }
  //     if (props.applicationCountry === "Australia (+61)") {
  //       if (!Status.includes("GTE or GS")) {
  //         Status.splice(5, 0, "GTE or GS");
  //       }
  //       if (!Status.includes("CoE Applied")) {
  //         Status.splice(6, 0, "CoE Applied");
  //       }
  //       if (!Status.includes("CoE Released")) {
  //         Status.splice(7, 0, "CoE Released");
  //       }
  //     }
  //     if (
  //       props.applicationCountry === "New Zealand (+64)" ||
  //       props.applicationCountry === "Ireland (+353)"
  //     ) {
  //       if (!Status.includes("AIP Released")) {
  //         Status.splice(12, 0, "AIP Released");
  //       }
  //     }
  //     setIsStatusUpdated(true);
  //   }
  // }, [props.applicationCountry, isStatusUpdated]);

  console.log(Status, "StatusStatus");

  let { leadOwnerSuccess } = useSelector((state) => {
    return state.leadOwnerChange;
  });

  useEffect(() => {
    if (findEmployeeMatchedRoleSuccess) {
      if (findEmployeeMatchedRoleSuccess?.role === null) {
        findEmployeeMatchedRoleSuccess.role = "admin";
      }
      setRoleData(findEmployeeMatchedRoleSuccess);
    }
  }, [findEmployeeMatchedRoleSuccess]);

  useEffect(() => {
    dispatch(findEmployeeMatchedRoleAction("interviewer"));
  }, [dispatch]);

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value) => {
    onClose(value);
  };

  console.log(props.menuStatus, "menuStatus");

  const dialogStyle = {
    left: "80vh",
    width: "auto",
    border: "1.5px solid black",
    overflowY: "scroll",
    scrollbarWidth: "thin",
    bottom:
      roleData !== undefined &&
      roleData?.role !== null &&
      roleData?.role.roleName == "visa officer"
        ? "-21vh"
        : roleData !== undefined &&
          roleData?.role !== null &&
          roleData?.role.roleName === "interviewer"
        ? "-12vh"
        : null
  };

  const changeApplicationStatusHandler = (status, statusName) => {
    if (
      statusName === "Submitted to the university" ||
      statusName === "Interview training" ||
      statusName === "Visa in progress" ||
      statusName === "Visa received" ||
      statusName === "Visa Applied" ||
      statusName === "Offer Letter Recieved" ||
      statusName === "Unconditional Offer" ||
      statusName === "Submitted Declaration" ||
      statusName === "Declaration Completed" ||
      statusName === "Deposit Paid"
    ) {
      props.handleOpenDialog(status, statusName);
    } else {
      dispatch(
        leadOwnerChangeAction(
          "",
          id ? id : leadId,
          "",
          "",
          statusName,
          props.appliedApplicationId
        )
      );
      handleClose();
    }
  };
  return (
    <Dialog
      onClose={handleClose}
      open={open}
      PaperProps={{ style: dialogStyle }}
    >
      <DialogTitle>Select Status</DialogTitle>
      <List sx={{ pt: 0 }}>
        {roleData === undefined ? null : roleData?.role !== "admin" ? (
          <>
            {roleData !== undefined &&
            roleData?.role !== null &&
            roleData?.role?.roleName === "interviewer"
              ? interviewerStatus.map((status) => (
                  <MenuItem
                    sx={{
                      fontSize: "15px",
                      fontWeight: 400,
                      backgroundColor:
                        props?.menuStatus === status ? "#EFEFEF" : "transparent"
                    }}
                  >
                    <Checkbox
                      sx={{
                        color: "#F8469A",
                        "&.Mui-checked": {
                          color: "#F8469A"
                        },
                        width: "16px",
                        height: "16px",
                        margin: 2
                      }}
                      icon={<RadioButtonUncheckedIcon />}
                      checkedIcon={<CheckCircleTwoToneIcon />}
                      checked={props?.menuStatus === status ? true : false}
                      onChange={(e) => {
                        dispatch(
                          leadOwnerChangeAction(
                            "",
                            id ? id : leadId,
                            "",
                            "",
                            status,
                            props.appliedApplicationId
                          )
                        );
                        handleClose();
                      }}
                    />
                    {status}
                  </MenuItem>
                ))
              : roleData !== undefined &&
                roleData?.role !== null &&
                roleData?.role.roleName == "visa officer"
              ? visaStatus.map((status) => (
                  <MenuItem
                    sx={{ fontSize: "15px", fontWeight: 400 }}
                    onClick={() => {}}
                  >
                    <Checkbox
                      sx={{
                        color: "#F8469A",
                        "&.Mui-checked": {
                          color: "#F8469A"
                        },
                        width: "16px",
                        height: "16px",
                        margin: 2
                      }}
                      icon={<RadioButtonUncheckedIcon />}
                      checkedIcon={<CheckCircleTwoToneIcon />}
                      checked={props.menuStatus === true ? true : false}
                      onChange={(e) => {
                        if (
                          status !== "Visa Applied" &&
                          status !== "Submitted to the university"
                        ) {
                          dispatch(
                            leadOwnerChangeAction(
                              "",
                              id ? id : leadId,
                              "",
                              "",
                              status,
                              props.appliedApplicationId
                            )
                          );
                          handleClose();
                        } else {
                          changeApplicationStatusHandler(
                            e.target.checked,
                            status
                          );
                        }
                      }}
                    />
                    {status}
                  </MenuItem>
                ))
              : roleData !== undefined &&
                roleData?.role !== null &&
                roleData?.role?.roleName !== "interviewer" &&
                roleData?.role.roleName !== "visa officer"
              ? Status?.map((status) => (
                  <MenuItem
                    sx={{ fontSize: "15px", fontWeight: 400 }}
                    onClick={() => {
                      const checkbox = document.getElementById(
                        `checkbox-${status}`
                      );
                      if (checkbox) {
                        checkbox.click();
                      }
                    }}
                  >
                    <Checkbox
                      sx={{
                        color: "#F8469A",
                        "&.Mui-checked": {
                          color: "#F8469A"
                        },
                        width: "16px",
                        height: "16px",
                        margin: 2
                      }}
                      icon={<RadioButtonUncheckedIcon />}
                      checkedIcon={<CheckCircleTwoToneIcon />}
                      id={`checkbox-${status}`}
                      //   checked={showNameColumn === true ? true : false}
                      onChange={(e) =>
                        changeApplicationStatusHandler(e.target.checked, status)
                      }
                    />
                    {status}
                  </MenuItem>
                ))
              : null}
          </>
        ) : (
          Status.map((status) => (
            <MenuItem
              sx={{ fontSize: "15px", fontWeight: 400 }}
              onClick={() => {
                const checkbox = document.getElementById(`checkbox-${status}`);
                if (checkbox) {
                  checkbox.click();
                }
              }}
            >
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue={currentStatus?.toLowerCase()}
                name="radio-buttons-group"
              >
                <FormControlLabel
                  value={status.toLowerCase()}
                  control={
                    <Radio
                      sx={{
                        color: "#F8469A",
                        "&.Mui-checked": {
                          color: "#F8469A"
                        },
                        width: "16px",
                        height: "16px",
                        margin: 2
                      }}
                      icon={<RadioButtonUncheckedIcon />}
                      checkedIcon={<CheckCircleTwoToneIcon />}
                      id={`checkbox-${status}`}
                      //   checked={showNameColumn === true ? true : false}
                      onChange={(e) =>
                        changeApplicationStatusHandler(e.target.checked, status)
                      }
                    />
                  }
                  label={status}
                />
              </RadioGroup>
              {/* <Radio
                sx={{
                  color: "#F8469A",
                  "&.Mui-checked": {
                    color: "#F8469A",
                  },
                  width: "16px",
                  height: "16px",
                  margin: 2,
                }}
                icon={<RadioButtonUncheckedIcon />}
                checkedIcon={<CheckCircleTwoToneIcon />}
                id={`checkbox-${status}`}
                //   checked={showNameColumn === true ? true : false}
                onChange={(e) =>
                  changeApplicationStatusHandler(e.target.checked, status)
                }
              />
              {status} */}
            </MenuItem>
          ))
        )}
      </List>
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired
};

export default function MenuBar({
  openTrue,
  handleClose,
  anchorEl,
  handleOpenDialog,
  appliedApplicationId,
  menuStatus,
  currentStatus,
  leadId,
  applicationCountry
}) {
  const [open, setOpen] = useState(openTrue);
  const [selectedValue, setSelectedValue] = useState(ukFlow[1]);
  console.log(currentStatus, "currentStatus");

  return (
    <div>
      <SimpleDialog
        selectedValue={selectedValue}
        open={open}
        onClose={handleClose}
        handleOpenDialog={handleOpenDialog}
        appliedApplicationId={appliedApplicationId}
        menuStatus={menuStatus}
        currentStatus={currentStatus}
        leadId={leadId}
        applicationCountry={applicationCountry}
      />
    </div>
  );
}
