import React, { useEffect, useMemo, useState } from "react";

import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableFooter,
  TableSortLabel,
  Checkbox
} from "@mui/material";

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography
} from "@mui/material";

//  import file pond

import { FilePond, registerPlugin } from "react-filepond";

// Import FilePond styles
import "filepond/dist/filepond.min.css";

// Import the Image EXIF Orientation and Image Preview plugins
// Note: These need to be installed separately
// `npm i filepond-plugin-image-preview filepond-plugin-image-exif-orientation --save`

import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

import ShowUniversityCourse from "./ShowUniversityCourse";
import {
  findShortListUniversityCourseAction,
  paymentUpdationInShortListAction,
  shortListDeleteAction,
  shortListMethodApplyAction,
  universityCourseFindForShortListAction
} from "../../../../actions/leadActions/leadDocsActions";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { TypographyText1 } from "../../../customComponent/Typography";
import { makeStyles } from "@material-ui/core";
import { OutLinedButton, SubmitButton } from "../../../customComponent/Buttons";
import {
  DropDownSelect,
  SelectInputField,
  TextFieldInput,
  TopInputFieldBorderLabel,
  TopMonthOnlyInput
} from "../../../customComponent/InputField";
import { successTrueMsgAction } from "../../../../actions/globalAction";
import { SUCCESS_TRUE_MSG_ERR } from "../../../../constants/roleManagmentConstant";
import {
  fetchCountryUniqueTokenAction,
  findcountryMasterAddAction
} from "../../../../actions/countryMasterAction";
import { courseLevelOption } from "../../../university/universityCourse/AddUniversityCourse";
import { allFindCoursesAction } from "../../../../actions/universityActions/courseActions/universityCourseAction";
import { findUniversityAction } from "../../../../actions/universityActions/universityManagmentAction";
import { branchManagerFetchAction } from "../../../../actions/employeeManagmentAction";
import {
  FIND_SHORT_LIST_UNIVERSITY_COURSE_SUCCESS,
  SHORT_LIST_METHOD_APPLY_SUCCESS
} from "../../../../constants/leadConstant";
import {
  courseLevelMasterFindAction,
  subjectMasterFindAction
} from "../../../../actions/courseMasterAction";
import { locationFindAction } from "../../../../actions/locationAction";

const useStyles = makeStyles({
  table: {
    borderCollapse: "separate",
    borderSpacing: "18px",
    border: "1px solid #ececec",
    padding: "10px"
  },
  tableCell: {
    backgroundColor: "#fafafa",
    color: "#9499A4",
    fontFamily: "'Akatab', sans-serif",
    fontSize: "-16px",
    fontWeight: 500,
    marginTop: "10px"
  },
  headerText: {
    fontSize: "13px"
  },
  tableRow: {
    "& > *": {
      border: "none"
    }
  }
});

// Register the plugin

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

function ShortListLead({ handleNext, handleCompleteTick }) {
  const dispatch = useDispatch();
  const { id } = useParams();
  const classes = useStyles();
  const [shortListShowData, setShortListShowData] = useState([]);
  const [country, setCountry] = useState("");
  const [location, setLocation] = useState("");
  const [level, setLevel] = useState("");
  const [subject, setSubject] = useState("");
  const [university, setUniversity] = useState("");
  const [courseStartMonth, setCourseStartMonth] = useState("");
  const [intake, setIntake] = useState("");
  const [applyMethod, setApplyMethod] = useState("");
  const [countryData, setCountryData] = useState([]);
  const [locationList, setLocationList] = useState([]);
  const [allCourseList, setAllCoursesList] = useState([]);
  const [universityList, setUniversityList] = useState([]);
  const [applicationHanlder, setApplicationHandler] = useState("");
  const [applicationId, setApplicationId] = useState("");

  const applicationMethod = [
    "Direct University",
    "Paper Application",
    "Agent Portal"
  ];

  const handleChange = (event) => {
    setApplyMethod(event.target.value);
  };
  let {
    universityCourseFindLoading,
    universityCourseFindSuccess,
    universityCourseFinderror
  } = useSelector((state) => {
    return state.universityCourseFindForShortList;
  });

  let {
    leadShortListFindLoading,
    leadShortListFindSuccess,
    leadShortListFinderror
  } = useSelector((state) => {
    return state.findShortListUniversityCourse;
  });

  let {
    leadShortListAddLoading,
    leadShortListAddSuccess,
    leadShortListAdderror
  } = useSelector((state) => {
    return state.courseAddShortList;
  });

  const {
    leadShortListApplyAddToAppliedLoading,
    leadShortListApplyAddToAppliedSuccess,
    leadShortListApplyAddToAppliederror
  } = useSelector((state) => {
    return state.shortListMethodApply;
  });

  const {
    leadShortListDeleteLoading,
    leadShortListDeleteSuccess,
    leadShortListDeleteerror
  } = useSelector((state) => {
    return state.shortListDelete;
  });

  let { countryFindLoading, countryFindSuccess } = useSelector((state) => {
    return state.findcountryMasterAdd;
  });

  let { locationFindLoading, locationFindSuccess } = useSelector((state) => {
    return state.LocationFind;
  });

  const { successAlertMsgSuccess, successAlertMsgErr } = useSelector(
    (state) => {
      return state.successTrueMsg;
    }
  );

  const {
    countryUniqueFindLoading,
    countryUniqueFindSuccess,
    countryUniqueFindErr
  } = useSelector((state) => {
    return state.fetchCountryUniqueToken;
  });

  let { universityFindSuccess } = useSelector((state) => {
    return state.findUniversity;
  });

  const {
    // allCourseFindLoading,
    allCourseFindSuccess
    // allCourseFindErr,
  } = useSelector((state) => {
    return state.allFindCourses;
  });

  const {
    // allCourseFindLoading,
    shortListPaymentStatusSuccess
    // allCourseFindErr,
  } = useSelector((state) => {
    return state.paymentUpdationInShortList;
  });

  let { branchManagerLoading, branchManagerSuccess, branchManagerError } =
    useSelector((state) => {
      return state.branchManagerFetch;
    });

  // Course level find reducer

  const { courseLevelFindSuccess, courseLevelFindErr } = useSelector(
    (state) => {
      return state.courseLevelMasterFind;
    }
  );

  useEffect(() => {
    dispatch(branchManagerFetchAction(id, "application handler"));
    dispatch(courseLevelMasterFindAction());
    dispatch(locationFindAction());
  }, [dispatch, id]);

  console.log(branchManagerSuccess, "branchManagerSuccessbranchManagerSuccess");

  // all course
  useEffect(() => {
    if (allCourseFindSuccess) {
      const universityCourse =
        allCourseFindSuccess?.map((item) => item.subject) || [];

      const uniqueUniversityCourse = new Set(universityCourse);

      const uniqueUniversityCourseArray = Array.from(uniqueUniversityCourse);

      // Step 4: Set the unique array to your location list
      setAllCoursesList(uniqueUniversityCourseArray);
      // setAllCoursesList(allCourseFindSuccess?.map((course) => course.subject));
    }
  }, [allCourseFindSuccess]);

  useEffect(() => {
    if (universityFindSuccess) {
      setUniversityList(
        universityFindSuccess?.map((item) => item.universityName)
      );
    }
  }, [universityFindSuccess]);

  useEffect(() => {
    dispatch(allFindCoursesAction());
  }, [dispatch]);

  // university
  useEffect(() => {
    dispatch(findUniversityAction());
  }, [dispatch]);
  // location
  useEffect(() => {
    if (countryUniqueFindSuccess) {
      const universityCities =
        countryUniqueFindSuccess?.map((item) => item.universityCity) || [];

      const uniqueUniversityCitiesSet = new Set(universityCities);

      const uniqueUniversityCitiesArray = Array.from(uniqueUniversityCitiesSet);

      setLocationList(uniqueUniversityCitiesArray);

      // setLocationList(
      //   countryUniqueFindSuccess?.map((item) => item.universityCity)
      // );
    }
  }, [countryUniqueFindSuccess]);

  useEffect(() => {
    if (successAlertMsgErr && leadShortListApplyAddToAppliederror) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `${leadShortListApplyAddToAppliederror}`
      });
      dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: false });
    }
  }, [successAlertMsgErr, leadShortListApplyAddToAppliederror, dispatch]);

  useEffect(() => {
    if (
      successAlertMsgSuccess &&
      leadShortListApplyAddToAppliedSuccess !== undefined
    ) {
      Swal.fire("Good job!", "Application Applied", "success").then((value) => {
        dispatch({ type: SHORT_LIST_METHOD_APPLY_SUCCESS, payload: false });
      });
      dispatch(successTrueMsgAction(false));
    }
  }, [successAlertMsgSuccess, leadShortListApplyAddToAppliedSuccess]);

  // useEffect(() => {
  //   if (leadShortListFindSuccess) {
  //     Swal.fire("Good job!", "courses finded sucessfully", "success");
  //   }
  // }, [dispatch, leadShortListFindSuccess]);

  // console.log(
  //   leadShortListApplyAddToAppliedSuccess,
  //   "leadShortListApplyAddToAppliedSuccessleadShortListApplyAddToAppliedSuccessleadShortListApplyAddToAppliedSuccess"
  // );

  useEffect(() => {
    if (successAlertMsgErr && leadShortListAdderror) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `${leadShortListAdderror}`
      });
      dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: false });
    }
  }, [leadShortListAdderror, dispatch, successAlertMsgErr]);

  useEffect(() => {
    if (leadShortListFindSuccess && leadShortListFindSuccess?.length !== 0) {
      console.log(
        leadShortListFindSuccess,
        "leadShortListFindSuccessleadShortListFindSuccessleadShortListFindSuccessleadShortListFindSuccess"
      );
      setShortListShowData(leadShortListFindSuccess);
      handleCompleteTick();
    }
  }, [leadShortListFindSuccess, handleCompleteTick]);

  useEffect(() => {
    dispatch(findShortListUniversityCourseAction(id));
  }, [
    leadShortListAddSuccess,
    dispatch,
    id,
    leadShortListDeleteSuccess,
    shortListPaymentStatusSuccess,
    leadShortListApplyAddToAppliedSuccess
  ]);

  useEffect(() => {
    dispatch(findcountryMasterAddAction());
  }, [dispatch]);

  useEffect(() => {
    if (countryFindSuccess) {
      setCountryData(countryFindSuccess?.map((item) => item.countryName));
    }
  }, [countryFindSuccess]);

  // Response

  const { subjectMajorFindSuccess, subjectMajorFindErr } = useSelector(
    (state) => {
      return state.subjectMajorMasterFind;
    }
  );

  useEffect(() => {
    dispatch(subjectMasterFindAction());
  }, [dispatch]);

  // useEffect(() => {
  //   if (universityCourseFindSuccess) {
  //     setUniversitShowData(universityCourseFindSuccess);
  //   }
  // }, [universityCourseFindSuccess]);

  const [open, setOpen] = useState(false);
  const [applyData, setApplyData] = useState(false);
  const [approveStatus, setApproveStatus] = useState(false);

  const handleClickOpen = (data) => {
    setOpen(true);
    setApplyData(data);
  };

  const handleApproveClick = (data) => {
    setOpen(true);
    setApplyData(data);
    setApproveStatus(true);
  };

  const handleClose = () => {
    setOpen(false);
    setApplyData(data);
    setApproveStatus(false);
  };

  const handleMethodApplyClick = () => {
    if (applyData?.isPaymentRequired) {
      if (applyData?.isPaymentDone) {
        dispatch(
          shortListMethodApplyAction(
            applyMethod,
            applyData,
            id,
            approveStatus,
            applicationHanlder,
            applicationId
          )
        );
        dispatch(successTrueMsgAction(true));
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Please Update Payment Status"
        });
      }
    } else {
      dispatch(
        shortListMethodApplyAction(
          applyMethod,
          applyData,
          id,
          approveStatus,
          applicationHanlder,
          applicationId
        )
      );
      dispatch(successTrueMsgAction(true));
    }

    setApproveStatus(false);
    handleClose();
  };

  const handleDeleteClickOpen = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!"
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(shortListDeleteAction(id));
      }
    });
  };

  console.log(
    shortListShowData,
    "shortListShowDatashortListShowDatashortListShowData"
  );
  const data = shortListShowData?.map((data, index) => ({
    id: data.id,
    sl: index + 1,
    courseName: data.courseName,
    courseId: data.courseId,
    intakeId: data.intakeId,
    ID: data.shortListId,
    shortListId: data.shortListId,
    level: data.courseLevel,
    university: data.universityName,
    country: data.universityCountry,
    universityInternationalFee: data.universityInternationalFee,
    intake: data.intakeDate,
    applicationStatus: data.shortListStatus,
    isPaymentDone: data.isPaymentDone,
    isPaymentRequired: data?.university
      ? data?.university?.country?.isPaymentMandotary
      : false,
    universityId:data?.universityId
  }));

  // sorting table
  const [sortBy, setSortBy] = useState(""); // Current sort column
  const [sortOrder, setSortOrder] = useState("asc"); // Current sort order

  const requestSort = (column) => {
    let newSortOrder = "asc";

    if (column === sortBy && sortOrder === "asc") {
      newSortOrder = "desc";
    }

    setSortBy(column);
    setSortOrder(newSortOrder);
  };

  let sortedData = useMemo(() => {
    if (sortBy && data) {
      const sortedItems = [...data].sort((a, b) => {
        if (sortOrder === "asc") {
          return a[sortBy] < b[sortBy] ? -1 : 1;
        } else {
          return a[sortBy] < b[sortBy] ? 1 : -1;
        }
      });

      return sortedItems;
    }

    return data;
  }, [data, sortBy, sortOrder, shortListPaymentStatusSuccess]);

  console.log(sortedData, "sortedDatasortedData");

  // material Table
  const columns = [
    {
      title: "Sl no",
      field: "sl",
      // type: "numeric",
      // hidden: true,
      headerStyle: { backgroundColor: "#F9F9FB" }
    },
    {
      title: "Course Name",
      field: "courseName",
      //   hidden: showEmployeeNameColumn,
      headerStyle: { backgroundColor: "#F9F9FB" }
    },
    {
      title: "ID",
      field: "ID",
      //   hidden: showEmailColumn,
      headerStyle: { backgroundColor: "#F9F9FB" }
    },
    {
      title: "Level",
      field: "level",
      //   hidden: showEmailColumn,
      headerStyle: { backgroundColor: "#F9F9FB" }
    },
    {
      title: "University",
      field: "university",
      //   hidden: showEmailColumn,
      headerStyle: { backgroundColor: "#F9F9FB" }
    },
    {
      title: "Country",
      field: "country",
      //   hidden: showEmailColumn,
      headerStyle: { backgroundColor: "#F9F9FB" }
    },
    {
      title: "Intake",
      field: "intake",
      //   hidden: showEmailColumn,
      headerStyle: { backgroundColor: "#F9F9FB" }
    },
    {
      title: "Application status",
      field: "action",
      filtering: false,
      headerStyle: { backgroundColor: "#F9F9FB" }
    },

    {
      title: "Is Payment Paid",
      field: "payment",
      filtering: false,
      headerStyle: { backgroundColor: "#F9F9FB" }
    }
  ];

  const handleCourseSearchSubmit = (e) => {
    e.preventDefault();
    console.log({
      country: country,
      location: location,
      level: level,
      subject: subject,
      university: university,
      courseStartMonth: courseStartMonth,
      intake: intake
    });
    dispatch(
      universityCourseFindForShortListAction(
        country,
        location,
        level,
        subject,
        university,
        courseStartMonth,
        intake
      )
    );
    dispatch(successTrueMsgAction(true));
  };

  const handleClearClick = () => {
    setCountry("");
    setLocation("");
    setLevel("");
    setSubject("");
    setUniversity("");
    setCourseStartMonth("");
    setIntake("");
  };

  return (
    <div>
      <Grid container spacing={2}>
        {shortListShowData.length !== 0 && (
          <Grid item xs={12}>
            <TypographyText1 title="Shortlisted Course" variant="h6" />
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  {columns?.map((column) => {
                    if (column.hidden) {
                      return null;
                    }
                    return (
                      <TableCell
                        key={column.field}
                        className={classes.tableCell}
                      >
                        <TableSortLabel
                          className="headerText"
                          active={column === sortBy}
                          direction={sortOrder}
                          onClick={() => requestSort(column)}
                        >
                          {column.title}
                        </TableSortLabel>
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedData?.map((item, indx) => (
                  <TableRow className={classes.tableRow} key={indx}>
                    <TableCell>{item.sl}</TableCell>
                    <TableCell>{item.courseName}</TableCell>
                    <TableCell>{item.ID}</TableCell>
                    <TableCell>{item.level}</TableCell>
                    <TableCell>{item.university}</TableCell>
                    <TableCell>{item.country}</TableCell>
                    <TableCell>{item.intake}</TableCell>
                    {/* <TableCell>{item.applicationStatus}</TableCell> */}
                    <TableCell>
                      <div style={{ display: "flex" }}>
                        {item.applicationStatus !== "Application in progress" &&
                          item.applicationStatus !== "waiting" &&
                          item.applicationStatus !==
                            "Submitted to university" && (
                            <Box sx={{ display: "flex", gap: "20px" }}>
                              <Button
                                sx={{
                                  transition: "0.5s ease",
                                  color: "#0047FF",
                                  backgroundColor: "rgba(0, 71, 255, 0.10)",
                                  border: "1px solid rgba(0, 71, 255, 0.10)",
                                  "&:hover": {
                                    borderColor:
                                      "rgba(0, 71, 255, 1) !important"
                                  }
                                }}
                                onClick={() => handleClickOpen(item)}
                              >
                                Apply
                              </Button>
                              <Button
                                sx={{
                                  transition: "0.5s ease",
                                  color: "#F50303",
                                  backgroundColor: "rgba(245, 3, 3, 0.10)",
                                  border: "1px solid rgba(245, 3, 3, 0.10)",
                                  "&:hover": {
                                    borderColor: "rgba(245, 3, 3, 1) !important"
                                  }
                                }}
                                onClick={() => handleDeleteClickOpen(item.id)}
                              >
                                Delete
                              </Button>
                            </Box>
                          )}

                        {item.applicationStatus ===
                          "Application in progress" && (
                          <Typography
                            sx={{
                              color: "rgba(255, 122, 0, 1)",
                              fontWeight: 400,
                              fontSize: "14px"
                            }}
                          >
                            Application in progress
                          </Typography>
                        )}

                        {item.applicationStatus === "waiting" && (
                          <Button
                            sx={{
                              ml: "7px",
                              backgroundColor: "rgba(0, 71, 255, 0.1)",
                              color: "rgba(0, 71, 255, 1)",
                              width: "auto",
                              height: "29px"
                              // border: "1px solid",
                            }}
                            onClick={() => handleApproveClick(item)}
                          >
                            Approve
                          </Button>
                        )}
                      </div>
                    </TableCell>

                    {item.applicationStatus !== "Application in progress" &&
                    item.applicationStatus !== "waiting" &&
                    item.isPaymentRequired ? (
                      <TableCell>
                        {" "}
                        <Checkbox
                          checked={item.isPaymentDone}
                          onChange={(e) =>
                            dispatch(
                              paymentUpdationInShortListAction(
                                e.target.checked,
                                item.id
                              )
                            )
                          }
                          style={{ color: "#f81b82" }}
                        />
                      </TableCell>
                    ) : (
                      <TableCell> Payment Not Required</TableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter />
            </Table>
          </Grid>
        )}
        <Grid item xs={12}>
          <Typography
            variant="h6"
            sx={{
              fontWeight: 300,
              fontSize: "21px",
              fontFamily: "'Montserrat', sans-serif"
            }}
            gutterBottom
          >
            Course Search
          </Typography>

          <Box
            sx={{
              backgroundColor: "#efedf0",
              mt: 5
            }}
          >
            <form onSubmit={handleCourseSearchSubmit}>
              <Grid
                container
                spacing={2}
                sx={{
                  p: 1,
                  "& #demo-simple-select-label": {
                    pl: "15px",
                    fontSize: "12px",
                    pt: "4px",
                    pb: "2px"
                  }
                }}
              >
                <Grid item xs={12} md={6} lg={3}>
                  {/* <TopInputFieldBorderLabel
                    label="Country"
                    handleChange={(e) => setCountry(e.target.value)}
                    widthSize="fullWidth"
                    heightSize="42px"
                    value={country}
                    required
                    paramses
                    // type="date"
                  /> */}
                  <DropDownSelect
                    handleChange={(e) => {
                      dispatch(fetchCountryUniqueTokenAction(e.target.value));
                      setCountry(e.target.value);
                    }}
                    data={countryData ? countryData : []}
                    value={country}
                    label="Country"
                  />
                </Grid>

                <Grid item xs={12} md={6} lg={3}>
                  {/* <TopInputFieldBorderLabel
                    label="location"
                    handleChange={(e) => setLocation(e.target.value)}
                    widthSize="fullWidth"
                    heightSize="42px"
                    value={location}
                    required
                    paramses
                    // type="date"
                  /> */}
                  <DropDownSelect
                    handleChange={(e) => setLocation(e.target.value)}
                    data={
                      locationFindSuccess?.data ? locationFindSuccess?.data : []
                    }
                    value={location}
                    label="location"
                  />
                </Grid>

                <Grid item xs={12} md={6} lg={3}>
                  {/* <TopInputFieldBorderLabel
                    label="Level"
                    handleChange={(e) => setLevel(e.target.value)}
                    widthSize="fullWidth"
                    heightSize="42px"
                    value={level}
                    required
                    paramses
                    // type="date"
                  /> */}

                  <DropDownSelect
                    handleChange={(e) => setLevel(e.target.value)}
                    data={courseLevelFindSuccess?.data}
                    value={level}
                    label="Level"
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={3}>
                  {/* <TopInputFieldBorderLabel
                    label="Subject"
                    handleChange={(e) => setSubject(e.target.value)}
                    widthSize="fullWidth"
                    heightSize="42px"
                    value={subject}
                    required
                    paramses
                    // type="date"
                  /> */}

                  <DropDownSelect
                    handleChange={(e) => setSubject(e.target.value)}
                    data={subjectMajorFindSuccess?.data}
                    value={subject}
                    label="Subject"
                    component="ShortlistCourse"
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={3}>
                  {/* <TopInputFieldBorderLabel
                    label="University"
                    handleChange={(e) => setUniversity(e.target.value)}
                    widthSize="fullWidth"
                    heightSize="42px"
                    value={university}
                    required
                    paramses
                    // type="date"
                  /> */}

                  <DropDownSelect
                    handleChange={(e) => setUniversity(e.target.value)}
                    data={universityList}
                    value={university}
                    label="University"
                  />
                </Grid>

                <Grid item xs={12} md={6} lg={3}>
                  <TopInputFieldBorderLabel
                    label="start"
                    handleChange={(e) => setIntake(e.target.value)}
                    widthSize="fullWidth"
                    heightSize="42px"
                    value={intake}
                    // required
                    paramses
                    type="month"
                  />
                  {/* <TopMonthOnlyInput
                    label="start"
                    handleChange={(date) => {
                      setIntake(date);
                      console.log(date, "dateeeeeeeeeeeee");
                    }}
                    value={intake}
                    required
                  /> */}
                </Grid>

                {/* <Grid item xs={12} md={6} lg={3}>
                  <TopInputFieldBorderLabel
                    label="Choose Start Month"
                    handleChange={(e) => setCourseStartMonth(e.target.value)}
                    widthSize="fullWidth"
                    heightSize="42px"
                    value={courseStartMonth}
                    required
                    paramses
                    type="month"
                  /> */}
                {/* <TopMonthOnlyInput
                    label="Choose Start Month"
                    handleChange={(date) => {
                      setCourseStartMonth(date);
                      console.log(date, "sendDateeeeeeeee");
                    }}
                    value={courseStartMonth}
                    required
                  /> */}
                {/* </Grid> */}
                <Grid item xs={12}>
                  <div style={{ display: "flex" }}>
                    <Box sx={{ m: 2 }}>
                      <OutLinedButton
                        title="Clear"
                        handleClick={handleClearClick}
                        widthSize="92px"
                        heightSize="40px"
                      />
                    </Box>

                    <Box sx={{ m: 2 }}>
                      <SubmitButton
                        title="Search"
                        submit="submit"
                        widthSize="92px"
                        heightSize="40px"
                        type="submit"
                        handleSubmit=""
                      />
                    </Box>
                  </div>
                </Grid>
              </Grid>
            </form>
          </Box>
          <ShowUniversityCourse />
        </Grid>
      </Grid>

      {/* apply short list */}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        // PaperProps={{
        //   sx: {
        //     bottom: "35vh",
        //     width: "850px",
        //   },
        // }}

        sx={{
          // background: "red",
          zIndex: "1000",
          "& .MuiDialog-paper": {
            width: "100%"
          }
        }}
      >
        <DialogTitle
          id="alert-dialog-title"
          // sx={{ fontSize: "22px", fontWeight: 700 }}
        >
          {"choose application method"}
        </DialogTitle>
        <DialogContent>
          {/* <FormControl fullWidth sx={{ mt: 1 }}>
            <InputLabel id="demo-simple-select-label">
              Application Method
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={applyMethod}
              label="Application Method"
              onChange={handleChange}
            >
              <MenuItem value="GSL Application Platform">
                GSL Application Platform
              </MenuItem>
              <MenuItem value="Document Method">Document Method</MenuItem>
              {/* <MenuItem value={30}>Thirty</MenuItem> */}
          {/* </Select> */}
          {/* </FormControl> */}

          <FormControl fullWidth sx={{ mt: 1 }}>
            <Box sx={{ mb: 2 }}>
              <TextFieldInput
                labelcolor={"#ACB1C6"}
                color={"#000"}
                label={"Application Id"}
                backgroundColor="#fff"
                required
                handleChange={(e) => {
                  setApplicationId(e.target.value);
                }}
                value={applicationId}
                name={"applicationId"}
                type="text"
              />
            </Box>

            <SelectInputField
              label="Application Method"
              handleSelectChange={(e) => setApplyMethod(e.target.value)}
              widthSize="32px"
              value={applyMethod}
              mapValue={applicationMethod}
              mapMethod="ApplicationMethod"
              name="applicationMethod"
              height="50px"
              requiredSymbol={true}
            />
            <Box sx={{ mt: 2 }}>
              <SelectInputField
                label="Application Handler"
                handleSelectChange={(e) =>
                  setApplicationHandler(e.target.value)
                }
                widthSize="32px"
                value={applicationHanlder}
                mapValue={branchManagerSuccess}
                mapMethod="ApplicationHanlder"
                name="applicationHanlder"
                height="50px"
                requiredSymbol={true}
              />
            </Box>

            {applyMethod === "Direct University" && (
              <Box sx={{ mt: 3, gap: "20px" }}>
                <TextFieldInput
                  labelcolor={"#ACB1C6"}
                  color={"#000"}
                  label={"User Name"}
                  backgroundColor="#fff"
                  required
                  // handleChange={(e) => {
                  //   setMethodName(e.target.value);
                  //   setErrorData({
                  //     ...errorData,
                  //     methodName: "",
                  //   });
                  // }}
                  // value={methodName}
                  // error={Boolean(errorData.methodName)}
                  // helperText={errorData.methodName}
                  name={"name"}
                  type="text"
                />
                <Box sx={{ mt: 3, gap: "20px" }}>
                  <TextFieldInput
                    labelcolor={"#ACB1C6"}
                    color={"#000"}
                    label={"Password"}
                    backgroundColor="#fff"
                    required
                    // handleChange={(e) => {
                    //   setMethodPass(e.target.value);
                    //   setErrorData({
                    //     ...errorData,
                    //     methodPass: "",
                    //   });
                    // }}
                    // value={methodPass}
                    // error={Boolean(errorData.methodPass)}
                    // helperText={errorData.methodPass}
                    name={"password"}
                    type="text"
                  />
                </Box>
              </Box>
            )}

            {applyMethod === "Paper Application" && (
              <Box sx={{ mt: 3, gap: "20px", p: "10px" }}>
                {/* {imageError && (
                  <Typography variant="p" color="red">
                    Please Upload File
                  </Typography>
                )} */}
                <Typography variant="h6">Upload Documents</Typography>
                <FilePond
                  // files={files}
                  // onupdatefiles={setFiles}
                  allowMultiple={false}
                  maxFiles={1}
                  server={false}
                  name="files"
                  labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                  acceptedFileTypes={[
                    "application/zip",
                    "application/x-zip-compressed",
                    "application/pdf",
                    "application/octet-stream"
                  ]}
                />
              </Box>
            )}
            {/* <div style={{ marginTop: "10px", p: "10px" }}>
              <SubmitButton
                title="Add application"
                submit={"click"}
                widthSize="auto"
                heightSize="auto"
                type="click"
                handleSubmit={() => {
                  if (applyMethod === "Direct University") {
                    if (methodName !== "") {
                      if (methodPass !== "") {
                        dispatch(
                          shortListMethodApplyAction(
                            applyMethod,
                            applyData,
                            id,
                            approveStatus,
                            methodName,
                            methodPass
                          )
                        );
                        setOpen(false);
                        dispatch(successTrueMsgAction(true));
                      } else {
                        setErrorData({
                          ...errorData,
                          methodPass: "Please enter a valid Name.",
                        });
                      }
                      setApproveStatus(false);
                    } else {
                      setErrorData({
                        ...errorData,
                        methodName: "Please enter a valid name.",
                      });
                    }
                  } else {
                    if (applyMethod === "Paper Application") {
                      if (files.length !== 0) {
                        dispatch(
                          shortListMethodApplyAction(
                            applyMethod,
                            applyData,
                            id,
                            approveStatus,
                            files[0].file
                          )
                        );
                        setApproveStatus(false);
                        setOpen(false);
                        dispatch(successTrueMsgAction(true));
                      } else {
                        setImageError(true);
                      }
                    } else {
                      dispatch(
                        shortListMethodApplyAction(
                          applyMethod,
                          applyData,
                          id,
                          approveStatus
                        )
                      );
                      setApproveStatus(false);
                      setOpen(false);
                      dispatch(successTrueMsgAction(true));
                    }
                  }
                }}
              />
            </div> */}
          </FormControl>
        </DialogContent>
        <DialogActions>
          <OutLinedButton
            title="Cancel"
            handleClick={handleClose}
            widthSize="75px"
            heightSize="30px"
          />

          <SubmitButton
            title="Submit"
            submit=""
            widthSize="75px"
            heightSize="30px"
            type="click"
            handleSubmit={handleMethodApplyClick}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ShortListLead;
