import * as React from "react";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import PersonalDetails from "../creatApplicationDetails/PersonalDetails";
import EducationDetails from "../creatApplicationDetails/EducationDetails";
import SopDetails from "../creatApplicationDetails/SopDetails";
import TravelImmigration from "../creatApplicationDetails/TravelImmigration";
import WorkDetails from "../creatApplicationDetails/WorkDetails";
import CountryDocs from "../creatApplicationDetails/CountryDocs";
import ShortListLead from "../creatApplicationDetails/shortList/ShortListLead";
import AppliedShortList from "../creatApplicationDetails/shortList/AppliedShortList";
import { Dialog, Divider, Grid } from "@mui/material";
import {
  leadEducationDetailsShowAction,
  leadPersonalDetailsShowAction
} from "../../../actions/leadActions/leadActions";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  countryAllAdmissionDocsFindAction,
  findShortListUniversityCourseAction,
  leadSopFindingAction,
  leadTraveAndImmigrationFindingAction,
  leadWorkDetailsFindAction
} from "../../../actions/leadActions/leadDocsActions";
import usePrivilegeCheck from "../../../constants/customHooks/UsePrivilegeCheck";
import AccessDeniedIcon from "./AccessDeniedIcon";
import Close from "@mui/icons-material/Close";
import RegistrationFee from "../creatApplicationDetails/RegistrationFee";
import Swal from "sweetalert2";
import { successTrueMsgAction } from "../../../actions/globalAction";
import {
  LEAD_PERSONAL_DETAILS_ADD_ERR,
  LEAD_PERSONAL_DETAILS_ADD_SUCCESS
} from "../../../constants/leadConstant";

const steps = [
  "Personal Details",
  "Education",
  "SOP",
  "Travel & immigration",
  "Work Detail",
  "Documents",
  "Registration Fee",
  "Shortlist & apply",
  "Applications"
];

export default function LeaderCreateStepper({ isDuplicate }) {
  const {
    privilege,
    hasPrivilege,
    isApprovePrevilage,
    isCreatePrevilage,
    isDeletePrevilage,
    isUpdatePrevilage,
    isVerifyPrevilage,
    isChangeOwnerPrevilage,
    isChangePriorityPrevilage,
    isChangeStatusPrevilage,
    isAssignCounsilorPrevilage
  } = usePrivilegeCheck();
  const { id, subMenu } = useParams();
  const dispatch = useDispatch();

  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});
  const [countryDoc, setCountryDoc] = React.useState([]);
  const [commonDoc, setCommonDoc] = React.useState([]);
  const [openAlert, setOpenAlert] = React.useState(false);

  let { leadPersonalDetailFindSuccess } = useSelector((state) => {
    return state.leadPersonalDetailsShow;
  });

  let {
    leadEducationDetailFindLoading,
    leadEducationDetailFindSuccess,
    leadEducationDetailFinderror
  } = useSelector((state) => {
    return state.leadEducationDetailsShow;
  });

  let { leadSopFindingSuccess } = useSelector((state) => {
    return state.leadSopFinding;
  });

  let {
    leadTravelAndImmigrationFindingLoading,
    leadTravelAndImmigrationFindingSuccess,
    leadTravelAndImmigrationFindingerror
  } = useSelector((state) => {
    return state.leadTraveAndImmigrationFinding;
  });

  let { leadWorkDetailsFindingSuccess } = useSelector((state) => {
    return state.leadWorkDetailsFind;
  });

  let { countryAdmissionFindSuccess } = useSelector((state) => {
    return state.countryAllAdmissionDocsFind;
  });

  let {
    leadCountrySpecificDocsFindingLoading,
    leadCountrySpecificDocsFindingSuccess,
    leadCountrySpecificDocsFindingerror
  } = useSelector((state) => {
    return state.leadCountrySpecificDocsFindingDocs;
  });

  let {
    leadCountryCommonDocsFindLoading,
    leadCountryCommonDocsFindSuccess,
    leadCountryCommonDocsFinderror
  } = useSelector((state) => {
    return state.leadCountryCommonDocsFindDocs;
  });

  let { commonDocFindSuccess } = useSelector((state) => {
    return state.CommonDocumentApplicationFind;
  });

  // shortlist
  let {
    leadShortListFindLoading,
    leadShortListFindSuccess,
    leadShortListFinderror
  } = useSelector((state) => {
    return state.findShortListUniversityCourse;
  });

  let { successAlertMsgSuccess, successAlertMsgErr } = useSelector((state) => {
    return state.successTrueMsg;
  });

  // React.useEffect(() => {
  //   if (leadPersonalDetailAdderror && successAlertMsgSuccess) {
  //     Swal.fire("Warning!", leadPersonalDetailAdderror, "error").then(
  //       (value) => {
  //         dispatch({ type: LEAD_PERSONAL_DETAILS_ADD_ERR, payload: false });
  //       }
  //     );
  //     dispatch(successTrueMsgAction(false));
  //   }
  // }, [leadPersonalDetailAdderror, successAlertMsgSuccess]);

  React.useEffect(() => {
    dispatch(leadPersonalDetailsShowAction(id));
  }, [dispatch, id]);

  React.useEffect(() => {
    dispatch(countryAllAdmissionDocsFindAction(id));
  }, [dispatch, id]);

  React.useEffect(() => {
    dispatch(leadEducationDetailsShowAction(id));
  }, [dispatch, id]);

  const handleCompleteTick = (index) => {
    const newCompleted = completed;
    newCompleted[index ? index : activeStep] = true;
    setCompleted(newCompleted);
    // handleNext();
  };
  const handleCompleteTickFalse = (index) => {
    const newCompleted = completed;
    newCompleted[index ? index : activeStep] = false;
    setCompleted(newCompleted);
  };

  // personal details
  React.useEffect(() => {
    if (leadPersonalDetailFindSuccess) {
      handleCompleteTick(0);
    }
  }, [leadPersonalDetailFindSuccess]);

  // education details
  React.useEffect(() => {
    if (leadEducationDetailFindSuccess) {
      const isAnyValueNotNull = Object.values(
        leadEducationDetailFindSuccess
      ).some((value) => value !== null);

      if (isAnyValueNotNull) {
        handleCompleteTick(1);
      }
    }
  }, [leadEducationDetailFindSuccess, handleCompleteTick]);

  // sop
  React.useEffect(() => {
    dispatch(leadSopFindingAction(id));
  }, [dispatch, id]);

  React.useEffect(() => {
    if (leadSopFindingSuccess) {
      if (leadSopFindingSuccess?.length !== 0) {
        handleCompleteTick(2);
      }
    }
  }, [leadSopFindingSuccess, handleCompleteTick]);

  // travel
  React.useEffect(() => {
    dispatch(leadTraveAndImmigrationFindingAction(id));
  }, [dispatch, id]);

  React.useEffect(() => {
    if (leadTravelAndImmigrationFindingSuccess) {
      if (leadTravelAndImmigrationFindingSuccess?.length !== 0) {
        handleCompleteTick(3);
      }
    }
  }, [leadTravelAndImmigrationFindingSuccess, handleCompleteTick]);

  // work details
  React.useEffect(() => {
    dispatch(leadWorkDetailsFindAction(id));
  }, [dispatch, id]);

  React.useEffect(() => {
    if (leadWorkDetailsFindingSuccess) {
      console.log(
        leadWorkDetailsFindingSuccess,
        "leadWorkDetailsFindingSuccess"
      );
      const isAnyArrayNotEmpty = Object.values(
        leadWorkDetailsFindingSuccess
      ).some((value) => Array.isArray(value) && value.length > 0);

      if (isAnyArrayNotEmpty) {
        handleCompleteTick(4);
      } else {
        handleCompleteTickFalse(4);
      }
    }
  }, [leadWorkDetailsFindingSuccess, handleCompleteTick]);

  // docs

  React.useEffect(() => {
    if (leadCountryCommonDocsFindSuccess && commonDocFindSuccess) {
      const notEqualCountries = commonDocFindSuccess.filter((country) => {
        return !leadCountryCommonDocsFindSuccess.some(
          (sop) => country.id == sop.CommonDocId
        );
      });
      setCommonDoc(notEqualCountries);
    }
  }, [commonDocFindSuccess, leadCountryCommonDocsFindSuccess]);

  React.useEffect(() => {
    if (countryAdmissionFindSuccess && leadCountrySpecificDocsFindingSuccess) {
      const filterCountryDocs = countryAdmissionFindSuccess?.filter(
        (country) => {
          return !leadCountrySpecificDocsFindingSuccess.some(
            (sop) => country.id == sop.DocId
          );
        }
      );
      setCountryDoc(filterCountryDocs);
    }
  }, [countryAdmissionFindSuccess, leadCountrySpecificDocsFindingSuccess]);

  React.useEffect(() => {
    if (
      countryAdmissionFindSuccess !== undefined &&
      countryAdmissionFindSuccess.length !== 0
    ) {
      if (commonDoc.length === 0 && countryDoc.length === 0) {
        handleCompleteTick(5);
      }
    }
  }, [commonDoc, countryDoc, handleCompleteTick, countryAdmissionFindSuccess]);

  // shortlist
  React.useEffect(() => {
    if (leadShortListFindSuccess && leadShortListFindSuccess?.length !== 0) {
      handleCompleteTick(6);
    }
  }, [leadShortListFindSuccess, handleCompleteTick]);

  React.useEffect(() => {
    dispatch(findShortListUniversityCourseAction(id));
  }, [dispatch, id]);

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (_, step) => {
    setActiveStep(step);
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  return (
    <Grid container spacing={2}>
      <Grid item lg={12}>
        <Box sx={{ width: "100%" }}>
          <Tabs
            // variant="scrollable"
            // scrollButtons="auto"
            value={activeStep}
            onChange={handleStep}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between"
            }}
          >
            {steps.map((label, index) => (
              <Tab
                key={label}
                label={
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      width: "100%"
                    }}
                  >
                    {label !== "Applications" ? (
                      completed[index] ? (
                        <img
                          src="/menuIcons/applicationAddRight.svg"
                          alt="gsl"
                          style={{ color: "green" }}
                        />
                      ) : (
                        <img
                          src="/menuIcons/applicationWrong.svg"
                          alt="gsl"
                          style={{ color: "red" }}
                        />
                      )
                    ) : null}
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: 850,
                        fontFamily: "'Montserrat', sans-serif",
                        marginLeft: "10px",
                        color: "#141E3C"
                      }}
                    >
                      {label}
                    </Typography>
                  </div>
                }
                onClick={(e) => handleStep(e, index)}
              />
            ))}
          </Tabs>
          <Divider light={true} />

          <Box sx={{ mt: 2 }}>
            {allStepsCompleted() ? (
              <React.Fragment>
                <Typography sx={{ mt: 2, mb: 1 }}>
                  All steps completed - you&apos;re finished
                </Typography>
                <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                  <Box sx={{ flex: "1 1 auto" }} />
                  <Button onClick={handleReset}>Reset</Button>
                </Box>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Box
                  sx={{
                    position: "relative"
                  }}
                >
                  {!isUpdatePrevilage(subMenu) || isDuplicate == true ? (
                    <>
                      <Dialog
                        open={openAlert}
                        onClose={() => setOpenAlert(false)}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        sx={{
                          zIndex: "9999",
                          // background:"red",
                          "& .MuiDialog-paper": {
                            width: "100%",
                            borderRadius: "5px",
                            p: "15px",
                            textAlign: "center"
                          }
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "end"
                          }}
                        >
                          <Close
                            sx={{
                              cursor: "pointer"
                            }}
                            onClick={() => setOpenAlert(false)}
                          />
                        </Box>
                        <Typography
                          sx={{
                            color: "#05050F",
                            fontSize: "26px",
                            fontWeight: "700"
                          }}
                        >
                          {isDuplicate == true
                            ? "Lead locked"
                            : "Access Denied"}
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            mt: "-10px"
                          }}
                        >
                          <AccessDeniedIcon />
                        </Box>
                        <Typography
                          sx={{
                            color: "#717171",
                            fontSize: "16px",
                            mb: "10px"
                          }}
                        >
                          {isDuplicate == true
                            ? "This Lead is locked please contact admin"
                            : "Sorry, you don’t have permission to access this page"}
                        </Typography>
                      </Dialog>
                      <Box
                        sx={{
                          position: "absolute",
                          right: "0",
                          top: "0",
                          left: "0",
                          bottom: "0",
                          // background:"red",
                          zIndex: "9998"
                        }}
                        onClick={() => setOpenAlert(true)}
                      ></Box>
                    </>
                  ) : (
                    ""
                  )}

                  <Box
                    sx={{
                      mt: 2,
                      mb: 1,
                      py: 1,
                      "& .selectFormLabelTobeControlled .MuiInputLabel-root[data-shrink='true']":
                        {
                          transform: "translate(14px, 5px) scale(0.8)",
                          bgcolor: "transparent",
                          color: "rgba(0, 0, 0, 0.6)"
                        },
                      "& .selectFormLabelTobeControlled .MuiSelect-select": {
                        mt: "18px"
                      },
                      "& fieldset": {
                        display: "none"
                      }
                    }}
                  >
                    {activeStep === 0 && (
                      <PersonalDetails
                        handleNext={handleComplete}
                        handleCompleteTick={handleCompleteTick}
                      />
                    )}
                    {activeStep === 1 && (
                      <EducationDetails
                        handleNext={handleComplete}
                        handleCompleteTick={handleCompleteTick}
                      />
                    )}
                    {activeStep === 2 && (
                      <SopDetails
                        handleNext={handleComplete}
                        handleCompleteTick={handleCompleteTick}
                      />
                    )}
                    {activeStep === 3 && (
                      <TravelImmigration
                        handleNext={handleComplete}
                        handleCompleteTick={handleCompleteTick}
                      />
                    )}
                    {activeStep === 4 && (
                      <WorkDetails
                        handleNext={handleComplete}
                        handleCompleteTick={handleCompleteTick}
                      />
                    )}
                    {activeStep === 5 && (
                      <CountryDocs
                        handleNext={handleComplete}
                        handleCompleteTick={handleCompleteTick}
                      />
                    )}
                    {activeStep === 6 && (
                      <RegistrationFee
                        handleNext={handleComplete}
                        handleCompleteTick={handleCompleteTick}
                      />
                    )}
                    {activeStep === 7 && (
                      <ShortListLead
                        handleNext={handleComplete}
                        handleCompleteTick={handleCompleteTick}
                      />
                    )}
                    {activeStep === 8 && (
                      <AppliedShortList
                        handleNext={handleComplete}
                        handleCompleteTick={handleCompleteTick}
                      />
                    )}
                  </Box>
                </Box>

                <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                  <Button
                    color="inherit"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                  >
                    Back
                  </Button>
                  <Box sx={{ flex: "1 1 auto" }} />
                  {activeStep < 7 && (
                    <Button
                      style={{
                        backgroundColor: "#141E3C",
                        // size: "10px",
                        padding: 1,
                        color: "#fff",
                        width: "100px",
                        height: "30px",
                        borderRadius: "5px",
                        margin: "8px",
                        fontWeight: 600,
                        fontSize: "12px"
                      }}
                      onClick={handleNext}
                      sx={{ mr: 1 }}
                    >
                      Next
                    </Button>
                  )}
                  {activeStep !== steps.length &&
                    (completed[activeStep] ? (
                      <Typography
                        variant="caption"
                        sx={{ display: "inline-block" }}
                      >
                        Step {activeStep + 1} already completed
                      </Typography>
                    ) : (
                      // <Button onClick={handleComplete}>
                      //   {completedSteps() === totalSteps() - 1
                      //     ? "Finish"
                      //     : "Complete Step"}
                      // </Button><>
                      <></>
                    ))}
                </Box>
              </React.Fragment>
            )}
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}
