import axios from "axios";
import {
  CREATE_REFERAL_FAQ_ERR,
  CREATE_REFERAL_FAQ_REQUEST,
  CREATE_REFERAL_FAQ_SUCCESS,
  CREATE_REFERAL_SETTINGS_ERR,
  CREATE_REFERAL_SETTINGS_REQUEST,
  CREATE_REFERAL_SETTINGS_SUCCESS,
  DELETE_REFERAL_FAQ_ERR,
  DELETE_REFERAL_FAQ_REQUEST,
  DELETE_REFERAL_FAQ_SUCCESS,
  GET_REFERAL_FAQ_ERR,
  GET_REFERAL_FAQ_REQUEST,
  GET_REFERAL_FAQ_SUCCESS,
  GET_REFERAL_SETTINGS_ERR,
  GET_REFERAL_SETTINGS_REQUEST,
  GET_REFERAL_SETTINGS_SUCCESS,
  REFERAL_WITHDRAW_DETAILS_ERR,
  REFERAL_WITHDRAW_DETAILS_REQUEST,
  REFERAL_WITHDRAW_DETAILS_SUCCESS,
  UPDATE_REFERAL_FAQ_ERR,
  UPDATE_REFERAL_FAQ_REQUEST,
  UPDATE_REFERAL_FAQ_SUCCESS,
  WITHDRAW_REFERAL_ERR,
  WITHDRAW_REFERAL_REQUEST,
  WITHDRAW_REFERAL_SUCCESS,
  WITHDRAW_STATUS_CHANGE_REFERAL_ERR,
  WITHDRAW_STATUS_CHANGE_REFERAL_REQUEST,
  WITHDRAW_STATUS_CHANGE_REFERAL_SUCCESS,
} from "../../constants/referAndEarn/referAndEarnConstant";
import { SUCCESS_TRUE_MSG_ERR } from "../../constants/roleManagmentConstant";

// get referal settings
export const getReferalsAction = () => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_REFERAL_SETTINGS_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.get(
      `/api/admin/refer&earn/referalSettings`,
      config
    );

    dispatch({ type: GET_REFERAL_SETTINGS_SUCCESS, payload: data });
  } catch (err) {
    dispatch({ type: GET_REFERAL_SETTINGS_ERR });
  }
};

// create referal settings
export const createReferalsAction =
  (formData) => async (dispatch, getState) => {
    try {
      dispatch({ type: CREATE_REFERAL_SETTINGS_REQUEST });

      let isUserExist = localStorage.getItem("loginInfo")
        ? JSON.parse(localStorage.getItem("loginInfo"))
        : null;

      const config = {
        headers: {
          Authorization: `Bearer ${isUserExist?.token}`,
        },
      };
      let { data } = await axios.put(
        `/api/admin/refer&earn/referalSettings`,
        formData,
        config
      );

      console.log(data, "createReferalSettingsSuccess");

      dispatch({ type: CREATE_REFERAL_SETTINGS_SUCCESS, payload: data });
    } catch (err) {
      dispatch({ type: CREATE_REFERAL_SETTINGS_ERR });
    }
  };

// create referal faqs
export const createFaqAction = (formData) => async (dispatch, getState) => {
  try {
    dispatch({ type: CREATE_REFERAL_FAQ_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };
    let { data } = await axios.post(
      `/api/admin/refer&earn/faq/createFaq`,
      formData,
      config
    );

    dispatch({ type: CREATE_REFERAL_FAQ_SUCCESS, payload: data });
  } catch (err) {
    dispatch({ type: CREATE_REFERAL_FAQ_ERR });
  }
};

// get referal faqs
export const getFaqAction = () => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_REFERAL_FAQ_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };
    let { data } = await axios.get(`/api/admin/refer&earn/faq/findFaq`, config);

    dispatch({ type: GET_REFERAL_FAQ_SUCCESS, payload: data });
  } catch (err) {
    dispatch({ type: GET_REFERAL_FAQ_ERR });
  }
};

// edit referal settings
export const updateReferalsAction =
  (formData, id) => async (dispatch, getState) => {
    try {
      dispatch({ type: UPDATE_REFERAL_FAQ_REQUEST });

      let isUserExist = localStorage.getItem("loginInfo")
        ? JSON.parse(localStorage.getItem("loginInfo"))
        : null;

      const config = {
        headers: {
          Authorization: `Bearer ${isUserExist?.token}`,
        },
      };
      let { data } = await axios.put(
        `/api/admin/refer&earn/faq/faqUpdate?id=${id}`,
        formData,
        config
      );

      dispatch({ type: UPDATE_REFERAL_FAQ_SUCCESS, payload: data });
    } catch (err) {
      dispatch({ type: UPDATE_REFERAL_FAQ_ERR });
    }
  };

// delete referal settings
export const deleteReferalsAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: DELETE_REFERAL_FAQ_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };
    let { data } = await axios.delete(
      `/api/admin/refer&earn/faq/faqDelete?id=${id}`,
      config
    );

    dispatch({ type: DELETE_REFERAL_FAQ_SUCCESS, payload: data });
  } catch (err) {
    dispatch({ type: DELETE_REFERAL_FAQ_ERR });
  }
};

// get withdraws referal
export const getwithdrawsAction = () => async (dispatch, getState) => {
  try {
    dispatch({ type: WITHDRAW_REFERAL_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };
    let { data } = await axios.get(`/api/admin/refer&earn/withdrawals`, config);

    dispatch({ type: WITHDRAW_REFERAL_SUCCESS, payload: data });
  } catch (err) {
    dispatch({ type: WITHDRAW_REFERAL_ERR });
  }
};

//  referrals statuschang action

export const withdrawsStatusChangeAction =
  (formData) => async (dispatch, getState) => {
    try {
      dispatch({ type: WITHDRAW_STATUS_CHANGE_REFERAL_REQUEST });

      let isUserExist = localStorage.getItem("loginInfo")
        ? JSON.parse(localStorage.getItem("loginInfo"))
        : null;

      const config = {
        headers: {
          Authorization: `Bearer ${isUserExist?.token}`,
        },
      };

      let { data } = await axios.post(
        `/api/admin/refer&earn/withdrawalsStatusChange`,
        formData,
        config
      );

      dispatch({ type: WITHDRAW_STATUS_CHANGE_REFERAL_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: WITHDRAW_STATUS_CHANGE_REFERAL_ERR,
        payload: error.response.data,
      });
      dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: true });
    }
  };

// referal withdraw details
export const referalWithdrawDetailsAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: REFERAL_WITHDRAW_DETAILS_REQUEST });

      let isUserExist = localStorage.getItem("loginInfo")
        ? JSON.parse(localStorage.getItem("loginInfo"))
        : null;

      const config = {
        headers: {
          Authorization: `Bearer ${isUserExist?.token}`, 
        },
      };

      let { data } = await axios.get(
        `/api/admin/refer&earn/withdrawDetails?id=${id}`,
        config
      );

      dispatch({ type: REFERAL_WITHDRAW_DETAILS_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: REFERAL_WITHDRAW_DETAILS_ERR,
        payload: error.response.data,
      });
      dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: true });
    }
  };
