import React, { useEffect, useState } from "react";

import {
  Box,
  Grid,
  IconButton,
  Paper,
  CircularProgress,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Dialog,
  Typography,
  Divider,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import Swal from "sweetalert2";

import CloseIcon from "@mui/icons-material/Close";
import { TypographyText } from "../../../customComponent/Typography";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteUniversityImageAction,
  nullUniversityImageShowAction,
  uploadUniversityImageAction,
} from "../../../../actions/universityActions/universityManagmentAction";
import { OutLinedButton, SubmitButton } from "../../../customComponent/Buttons";
import InputField from "../../../customComponent/InputField";
import { stateToHTML } from "draft-js-export-html";
import { TiDelete } from "react-icons/ti";

function FileUpload({
  activeStep,
  steps,
  isLastStep,
  handleBack,
  handleComplete,
  handleUploadSubmit,
}) {
  const dispatch = useDispatch();

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [uploadBrochure, setUploadBrouchure] = useState(false);
  const [uploadLogo, setUploadLogo] = useState(false);
  const [brochurFile, setBrochurFile] = useState();
  const [logoFile, setLogoFile] = useState(null);
  const [brochurFileName, setBrochurFileName] = useState();
  const [logoFileName, setLogoFileName] = useState();
  const [section, setSection] = useState("");

  const [uploadCoverImage, setUploadCoverImage] = useState(false);
  const [universityCoverImage, setuniversityCoverImage] = useState(null);
  const [universityCoverImageName, setuniversityCoverImageName] = useState();

  let {
    unversityImageUploadLoading,
    unversityImageUploadSuccess,
  } = useSelector((state) => {
    return state.uploadUniversityImage;
  });

  let { unversityImageNullShowSuccess } = useSelector((state) => {
    return state.nullUniversityImageShow;
  });

  let { unversityImageNullDeleteSuccess } = useSelector((state) => {
    return state.deleteUniversityImage;
  });

  let universityName = JSON.parse(localStorage.getItem("universityName"));
  let universityAbout = JSON.parse(localStorage.getItem("universityAbout"));

  useEffect(() => {
    if (!universityName && !universityAbout) {
      dispatch(deleteUniversityImageAction(0));
    }
  }, [dispatch]);

  useEffect(() => {
    dispatch(nullUniversityImageShowAction());
  }, [dispatch, unversityImageNullDeleteSuccess, unversityImageUploadSuccess]);

  useEffect(() => {
    if (unversityImageNullShowSuccess) {
      setSelectedFiles(unversityImageNullShowSuccess);
    }
  }, [unversityImageNullShowSuccess]);

  const handleSelectedDeleteFile = (index) => {
    let filteredData = selectedFiles.filter((item, i) => index !== i);
    console.log(filteredData);
    setSelectedFiles(filteredData);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const filePromise = new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

    filePromise
      .then((base64Array) => {
        if (universityName && universityAbout) {
          console.log(base64Array);
          dispatch(uploadUniversityImageAction(base64Array));
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "please fill out all required fields!",
          });
        }
      })
      .catch((error) => {
        console.log("Error converting files to base64 : ", error);
      });
  };

  const handleRemoveFile = (fileId) => {
    dispatch(deleteUniversityImageAction(fileId));
  };

  // videos
  const [selectedVideoFiles, setSelectedVideoFiles] = useState([]);
  const [addIframe, setIframe] = useState("");
  const [iframeError, setIframeError] = useState(false);

  const [open, setOpen] = useState(false);

  const handleVideoFileChange = (event) => {
    setOpen(true);
  };

  // handleinput

  const handleChange = (e) => {
    const inputValue = e.target.value;
    const containsIframe = /<iframe.*?<\/iframe>/i.test(inputValue);

    if (containsIframe) {
      setIframe(e.target.value);
      setIframeError(false);
    } else {
      setIframeError(true);
    }
  };

  // file upload
  const handleFileUpload = (e) => {
    if (section === "brochure") {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setUploadBrouchure(true);
        setBrochurFile(reader.result);
        setBrochurFileName(file.name);
      };
      setSection("");
    }

    if (section === "logo") {
      const file = e.target.files[0];
      console.log(file, "filefilefilefilefilefile");
      const reader = new FileReader();

      // Read the image file as a data URL

      reader.readAsDataURL(file);

      // Set the previewImage state when the reader has finished loading the image :::::

      reader.onloadend = () => {
        setUploadLogo(true);
        setLogoFile(reader.result);
        setLogoFileName(file.name);
      };

      setSection("");
    }

    if (section === "coverImage") {
      const file = e.target.files[0];
      console.log(file, "filefilefilefilefilefile");
      const reader = new FileReader();

      // Read the image file as a data URL
      reader.readAsDataURL(file);

      // Set the previewImage state when the reader has finished loading the image
      reader.onloadend = () => {
        setUploadCoverImage(true);
        setuniversityCoverImage(reader.result);
        setuniversityCoverImageName(file.name);
      };
      console.log(e.target.files[0]);
      setSection("");
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    setSelectedVideoFiles((prevState) => [...prevState, addIframe]);
    setIframe("");
    setOpen(false);
  };

  // HandleClose
  const handleClose = () => {
    setOpen(false);
  };

  const handleVideoRemoveFile = (index) => {
    const newArray = selectedVideoFiles
      .slice(0, index)
      .concat(selectedVideoFiles.slice(index + 1));
    setSelectedVideoFiles(newArray);
  };

  // open country form
  const handleClickOpen = (sectionName) => {
    setSection(sectionName);
  };

  return (
    <>
      {/* <Typography variant="h5" sx={{ mt: "10px", fontWeight: "bold" }}>
       
      </Typography> */}
      <TypographyText
        variant="h5"
        title=" Images"
        style={{ marginTop: "10px" }}
      />

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: "20px",
          borderRadius: "10px",
          backgroundColor: "#fff",
          maxWidth: "100%",
          margin: "auto",
        }}
      >
        <Grid container spacing={2}>
          {selectedFiles.map((file, index) => (
            <>
              <Grid item key={index} xs={3} sx={{ mt: 2 }}>
                <Box
                  sx={{
                    position: "relative",
                    display: "flex",
                    alignItems: "center",
                    width: "300px",
                    height: "200px",
                    marginBottom: "10px",
                  }}
                >
                  {unversityImageUploadLoading ? (
                    <Box sx={{ display: "flex" }}>
                      <CircularProgress />
                    </Box>
                  ) : (
                    <>
                      <img
                        src={file.universityImageUrl}
                        alt={`Selected File ${index + 1}`}
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                      />
                      <div
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <IconButton
                          size="small"
                          color="secondary"
                          onClick={() => handleRemoveFile(file.id)}
                          sx={{
                            position: "absolute",
                            top: "-8px",
                            display: "flex",
                            justifyContent: "flex-end",
                            color: "white",
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </div>
                    </>
                  )}
                </Box>
              </Grid>
            </>
          ))}
          <Grid item xs={4}>
            <Box sx={{ marginTop: 2 }}>
              <label
                htmlFor="c"
                style={{ display: "inline-block", cursor: "pointer" }}
              >
                <img src="/menuIcons/imageUpload.svg" alt="gsl" />
              </label>
              <input
                id="c"
                type="file"
                accept="image/*"
                style={{ display: "none" }}
                onChange={handleFileChange}
              />
            </Box>
          </Grid>
        </Grid>
        <Typography
          sx={{
            color: "#ACB1C6",
            fontSize: "12px",
            width: "100%",
          }}
        >
          {" "}
          Recommended size: 400x190 pixels
        </Typography>
      </Box>

      <>
        <TypographyText
          variant="h5"
          title=" Videos"
          style={{ marginTop: "10px" }}
        />

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            padding: "20px",
            borderRadius: "10px",
            backgroundColor: "#fff",
            maxWidth: "100%",
            margin: "auto",
          }}
        >
          <Grid container spacing={2}>
            {selectedVideoFiles.map((file, index) => (
              <Grid item xs={6} key={index}>
                <Paper
                  sx={{
                    position: "relative",
                    display: "flex",
                    // justifyContent: "center",
                    // alignItems: "center",
                    width: "250px",
                    height: "auto",
                    marginBottom: "10px",
                  }}
                  elevation={0}
                >
                  <div
                    style={{
                      width: "100%",
                      height: "auto",
                    }}
                    dangerouslySetInnerHTML={{ __html: file }}
                  />
                  <IconButton
                    size="small"
                    color="secondary"
                    onClick={() => handleVideoRemoveFile(index)}
                    sx={{
                      position: "absolute",
                      top: "5px",
                      left: "10px",
                      display: "flex",
                      justifyContent: "start",
                      color: "red",
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Paper>
              </Grid>
            ))}

            <Grid item xs={6}>
              <img
                src="/menuIcons/imageUpload.svg"
                alt="gsl"
                onClick={handleVideoFileChange}
                style={{ cursor: "pointer" }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} m={2}>
            <div style={{ display: "flex" }}>
              <div style={{ margin: "15px" }}>
                <Grid item xs={12}>
                  <TypographyText
                    variant="h5"
                    title=" Brochure"
                    style={{ marginTop: "10px" }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Paper
                    elevation={0}
                    sx={{
                      backgroundColor: "#fdfdfd",
                      width: "216px",
                      height: "180px",
                      textAlign: "center",
                      mt: 2,
                      border: "1px solid #ECECEC",
                    }}
                  >
                    {uploadBrochure === false ? (
                      <>
                        <input
                          type="file"
                          accept="application/pdf"
                          id="avatar-file1"
                          style={{ display: "none" }}
                          onChange={(e) => handleFileUpload(e)}
                        />

                        <label htmlFor="avatar-file1">
                          <Box
                            component="div"
                            // startIcon={<AddIcon />}
                            sx={{
                              backgroundColor: "#e6eff7",
                              width: "auto",
                              cursor: "pointer",
                              height: "110px",
                              textAlign: "center",
                            }}
                            onClick={() => handleClickOpen("brochure")}
                          >
                            <AddIcon
                              sx={{
                                width: "71.87px",
                                height: "auto",
                                color: "#2a76d2",
                                fontSize: "20px",
                                margin: "15px",
                              }}
                            />
                          </Box>
                        </label>
                      </>
                    ) : (
                      <>
                        <Box
                          sx={{
                            backgroundColor: "#f8f8f8",
                            width: "auto",
                            cursor: "pointer",
                            height: "110px",
                            textAlign: "center",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "flex-end",
                              cursor: "pointer",
                            }}
                          >
                            <TiDelete
                              style={{ fontSize: "20px" }}
                              onClick={() => {
                                setUploadBrouchure(false);
                                setBrochurFileName("");
                              }}
                            />
                          </Box>
                          <a
                            // href={sopData.sopUrl}
                            // download={sopData.sopUrl}
                            target="blank"
                          >
                            <img
                              src="/menuIcons/pdf.png"
                              alt="Uploaded"
                              style={{
                                marginTop: "10px",
                                width: "57px",
                                height: "auto",
                                cursor: "pointer",
                              }}
                            />
                          </a>
                        </Box>
                      </>
                    )}

                    <Divider />
                    <Box sx={{ m: 1, height: "55px", p: "10px" }}>
                      <Typography sx={{ fontSize: "16px", fontWeight: 400 }}>
                        {brochurFileName ? brochurFileName : "Upload Document"}
                      </Typography>
                    </Box>
                  </Paper>
                </Grid>
              </div>

              <div style={{ margin: "15px" }}>
                <Grid item xs={12} sx={{ display: "flex" }}>
                  <TypographyText
                    variant="h5"
                    title="Logo"
                    style={{ marginTop: "10px" }}
                  />
                  <Typography component="h5" sx={{ color: "red", mt: "13px" }}>
                    *
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Paper
                    elevation={0}
                    sx={{
                      backgroundColor: "#fdfdfd",
                      width: "216px",
                      height: "180px",
                      textAlign: "center",
                      mt: 2,
                      border: "1px solid #ECECEC",
                    }}
                  >
                    {uploadLogo === false ? (
                      <>
                        <input
                          type="file"
                          accept="image/png, image/jpeg"
                          id="avatar-file"
                          style={{ display: "none" }}
                          onChange={(e) => handleFileUpload(e)}
                        />
                        <label htmlFor="avatar-file">
                          <Box
                            component="div"
                            // startIcon={<AddIcon />}
                            sx={{
                              backgroundColor: "#e6eff7",
                              width: "auto",
                              cursor: "pointer",
                              height: "110px",
                              textAlign: "center",
                            }}
                            onClick={() => handleClickOpen("logo")}
                          >
                            <AddIcon
                              sx={{
                                width: "71.87px",
                                height: "auto",
                                color: "#2a76d2",
                                fontSize: "20px",
                                margin: "15px",
                              }}
                            />
                          </Box>
                        </label>
                      </>
                    ) : (
                      <>
                        <Box
                          sx={{
                            backgroundColor: "#f8f8f8",
                            width: "auto",
                            cursor: "pointer",
                            height: "110px",
                            textAlign: "center",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "flex-end",
                              cursor: "pointer",
                            }}
                          >
                            <TiDelete
                              style={{ fontSize: "20px" }}
                              onClick={() => {
                                setUploadLogo(false);
                                setLogoFileName("");
                              }}
                            />
                          </Box>
                          <a
                            // href={sopData.sopUrl}
                            // download={sopData.sopUrl}
                            target="blank"
                          >
                            <img
                              src={logoFile}
                              alt="Uploaded"
                              style={{
                                marginTop: "10px",
                                width: "57px",
                                height: "auto",
                                cursor: "pointer",
                              }}
                            />
                          </a>
                        </Box>
                      </>
                    )}

                    <Divider />
                    <Box sx={{ m: 1, height: "55px", p: "10px" }}>
                      <Typography sx={{ fontSize: "16px", fontWeight: 400 }}>
                        {logoFileName ? logoFileName : "Upload logo"}
                      </Typography>
                    </Box>
                  </Paper>
                </Grid>
              </div>

              {/* Cover image */}

              <div style={{ margin: "15px" }}>
                <Grid item xs={12}>
                  <TypographyText
                    variant="h5"
                    title="Cover image"
                    style={{ marginTop: "10px" }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Paper
                    elevation={0}
                    sx={{
                      backgroundColor: "#fdfdfd",
                      width: "216px",
                      height: "180px",
                      textAlign: "center",
                      mt: 2,
                      border: "1px solid #ECECEC",
                    }}
                  >
                    {uploadCoverImage === false ? (
                      <>
                        <input
                          type="file"
                          accept="image/png, image/jpeg"
                          id="avatar-file"
                          style={{ display: "none" }}
                          onChange={(e) => handleFileUpload(e)}
                        />
                        <label htmlFor="avatar-file">
                          <Box
                            component="div"
                            // startIcon={<AddIcon />}
                            sx={{
                              backgroundColor: "#e6eff7",
                              width: "auto",
                              cursor: "pointer",
                              height: "110px",
                              textAlign: "center",
                            }}
                            onClick={() => handleClickOpen("coverImage")}
                          >
                            <AddIcon
                              sx={{
                                width: "71.87px",
                                height: "auto",
                                color: "#2a76d2",
                                fontSize: "20px",
                                margin: "15px",
                              }}
                            />
                          </Box>
                        </label>
                      </>
                    ) : (
                      <>
                        <Box
                          sx={{
                            backgroundColor: "#f8f8f8",
                            width: "auto",
                            cursor: "pointer",
                            height: "110px",
                            textAlign: "center",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "flex-end",
                              cursor: "pointer",
                            }}
                          >
                            <TiDelete
                              style={{ fontSize: "20px" }}
                              onClick={() => {
                                setUploadCoverImage(false);
                                setuniversityCoverImageName("");
                              }}
                            />
                          </Box>
                          <a
                            // href={sopData.sopUrl}
                            // download={sopData.sopUrl}
                            target="blank"
                          >
                            <img
                              src={universityCoverImage}
                              alt="Uploaded"
                              style={{
                                marginTop: "10px",
                                width: "57px",
                                height: "auto",
                                cursor: "pointer",
                              }}
                            />
                          </a>
                        </Box>
                      </>
                    )}

                    <Divider />
                    <Box sx={{ m: 1, height: "55px", p: "10px" }}>
                      <Typography sx={{ fontSize: "16px", fontWeight: 400 }}>
                        {universityCoverImageName
                          ? universityCoverImageName
                          : "Cover image"}
                      </Typography>
                    </Box>
                  </Paper>
                </Grid>
              </div>
            </div>
          </Grid>
        </Box>

        {activeStep !== steps.length && isLastStep() === true && (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "7px",
              paddingBottom: "10px",
            }}
          >
            <SubmitButton
              title={isLastStep() === true && "Finish"}
              submit=""
              widthSize="115px"
              heightSize="50px"
              type="click"
              handleSubmit={() =>
                handleUploadSubmit(
                  selectedVideoFiles,
                  selectedFiles,
                  brochurFile,
                  logoFile,
                  universityCoverImage
                )
              }
            />
          </div>
        )}
      </>

      {/* Video adding modal */}

      <Dialog
        open={open}
        // TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        PaperProps={{
          style: {
            marginTop: "10vh", // Adjust the value as needed
            alignSelf: "flex-start",
            width: "500px",
          },
        }}
      >
        <DialogTitle style={{ fontWeight: "bold" }}>
          {"Add Iframe"}
          <Typography sx={{ fontSize: "12px", fontWeight: 400, color: "grey" }}>
            Upload Video in youtube first then past iframe of youtube here
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <InputField
              label="Iframe"
              handleChange={handleChange}
              widthSize="100%"
              value={addIframe}
              InputType="discription"
            />
            {iframeError ? (
              <Typography
                sx={{ fontSize: "12px", fontWeight: 400, color: "red" }}
              >
                It is not Iframe Please Iframe
              </Typography>
            ) : null}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <OutLinedButton
            title="Cancel"
            handleClick={handleClose}
            widthSize="81px"
            heightSize="35px"
          />
          <SubmitButton
            title="Add"
            submit=""
            widthSize="81px"
            heightSize="35px"
            handleSubmit={handleSubmit}
            type="click"
          />
        </DialogActions>
      </Dialog>
    </>
  );
}

export default FileUpload;
