// Banner constants

export const BANNER_CREATE_REQUEST = "BANNER_CREATE_REQUEST";
export const BANNER_CREATE_SUCCESS = "BANNER_CREATE_SUCCESS";
export const BANNER_CREATE_ERR = "BANNER_CREATE_ERR";

export const BANNER_DELETE_REQUEST = "BANNER_DELETE_REQUEST";
export const BANNER_DELETE_SUCCESS = "BANNER_DELETE_SUCCESS";
export const BANNER_DELETE_ERR = "BANNER_DELETE_ERR";

export const BANNER_FIND_REQUEST = "BANNER_FIND_REQUEST";
export const BANNER_FIND_SUCCESS = "BANNER_FIND_SUCCESS";
export const BANNER_FIND_ERR = "BANNER_FIND_ERR";
