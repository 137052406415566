import React, { useState, useEffect } from "react";
import { InfoHeader } from "../../../customComponent/InfoHeader";
import { Box, FormLabel, Typography } from "@mui/material";
import { MainContainer } from "../../../content/content.element";
import InputField from "../../../customComponent/InputField";
import { SubmitButton } from "../../../customComponent/Buttons";

import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import CourseCategoryMain from "./courseCategory/CourseCategoryMain";
import SelectCourseCategory from "./courseCategory/SelectCourseCategory";
import { useDispatch, useSelector } from "react-redux";
import {
  ieltsCourseManagmentCourseCreatingAction,
  ieltsCourseManagmentCreateCourseAction,
  ieltsCourseManagmentCreateCourseErrorAction,
  ieltsCourseManagmentCreateCourseNullfyAction,
} from "../../../../actions/IeltsActions/courseManagmentAction";
import { EditorState } from "draft-js";
import { useLocation } from "react-router-dom";
import { successTrueMsgAction } from "../../../../actions/globalAction";
import Swal from "sweetalert2";
import Upload from "../../../customComponent/Upload";
import { stateToHTML } from "draft-js-export-html";
import { id } from "date-fns/locale";

function CourseAddNew({ toggle }) {
  let {
    ieltsCourseManagmentCourseCreateSuccess,
    ieltsCourseManagmentCourseCreateLoading,
  } = useSelector((state) => {
    return state.ieltsCourseManagmentCreateCourse;
  });
  let { ieltsCourseManagmentCourseCreateCheckErrorSuccess } = useSelector(
    (state) => {
      return state.ieltsCourseManagmentCreateCheckErrorCourse;
    }
  );

  useEffect(() => {
    if (ieltsCourseManagmentCourseCreateCheckErrorSuccess) {
    }
  }, [ieltsCourseManagmentCourseCreateCheckErrorSuccess]);

  const location = useLocation();

  const [formData, setFormData] = useState();
  const [errorData, setErrorData] = useState();
  const [avatr, setAvatar] = useState();
  const [image, setImage] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (ieltsCourseManagmentCourseCreateSuccess) {
      setFormData(ieltsCourseManagmentCourseCreateSuccess);
    }
  }, [ieltsCourseManagmentCourseCreateSuccess]);

  useEffect(() => {
    resetReduxState();
  }, [location]);

  const dispatch = useDispatch();

  const handleEditorChange = (state) => {
    console.log(state, "state");
    const updatedData = (prevSlots) => ({
      ...prevSlots,
      discription: state,
    });

    setFormData((prevSlots) => updatedData(prevSlots));
    dispatch(ieltsCourseManagmentCreateCourseAction(updatedData(formData)));
    setErrorData((prev) => ({
      ...prev,
      ["discription"]: "",
    }));
  };

  // Handling category switches

  const [categorySwitches, setCategorySwitches] = useState({
    speaking: true,
    reading: false,
    listening: false,
    writing: false,
    grammar: false,
  });

  const handleCategorySwitches = (e) => {
    const { name, checked } = e.target;
    setCategorySwitches((prevSwitches) => ({
      ...prevSwitches,
      [name]: checked,
    }));
  };

  // handles text field changes

  const handleChange = (event) => {
    let eventData;
    const { name, value, files } = event.target;
    if (name == "courseImage") {
      eventData = event.target.files[0];
      setImage(event.target.files[0]);
      const updatedData = (prevSlots) => ({
        ...prevSlots,
        [name]: eventData,
      });
      setAvatar(URL.createObjectURL(eventData));
      setFormData((prevSlots) => updatedData(prevSlots));
      setErrorData((prev) => ({
        ...prev,
        [name]: "",
      }));
      dispatch(ieltsCourseManagmentCreateCourseAction(updatedData(formData)));
    }else if (name === "fees") {
      const phoneRegex = /^\d{0,10}$/;
      if (phoneRegex.test(value) || value === "") {
        setFormData((prev) => ({
          ...prev,
          [name]: value,
        }));
        setErrorData((prev) => ({
          ...prev,
          [name]: "",
        }));
      } else {
        setErrorData((prev) => ({
          ...prev,
          [name]: "Invalid number",
        }));
      }
    } else {
      const updatedData = (prevSlots) => ({
        ...prevSlots,
        [name]: value,
      });
      setFormData((prevSlots) => updatedData(prevSlots));
      setErrorData((prev) => ({
        ...prev,
        [name]: "",
      }));
      dispatch(ieltsCourseManagmentCreateCourseAction(updatedData(formData)));
    }
  };

  // Success message

  let { successAlertMsgSuccess } = useSelector((state) => {
    return state.successTrueMsg;
  });

  let {
    ieltsCourseManagmentCourseCreatingSuccess,
    ieltsCourseManagmentCourseCreatingerror,
  } = useSelector((state) => {
    return state.ieltsCourseManagmentCourseCreating;
  });

  useEffect(() => {
    if (
      successAlertMsgSuccess &&
      ieltsCourseManagmentCourseCreatingSuccess?.status == true
    ) {
      setLoading(false);

      Swal.fire(
        "Created",
        ieltsCourseManagmentCourseCreatingSuccess.message,
        "success"
      );
      dispatch(successTrueMsgAction(false));
    }
    if (
      successAlertMsgSuccess &&
      ieltsCourseManagmentCourseCreatingSuccess?.status == false
    ) {
      Swal.fire(
        "Error",
        ieltsCourseManagmentCourseCreatingSuccess.message,
        "warning"
      );
      dispatch(successTrueMsgAction(false));
    }
  }, [ieltsCourseManagmentCourseCreatingSuccess, successAlertMsgSuccess]);

  const validateForm = () => {
    const descriptionEditorState = formData.discription;
    const checkOnlineContent = (onlineData) => {
      for (const category in onlineData) {
        if (onlineData.hasOwnProperty(category)) {
          const { DemoVideo, DemoAudio, DemoNote } = onlineData[category];
          if (DemoVideo || DemoAudio || DemoNote) {
            return true;
          }
        }
      }
      return false;
    };
    let isValid = true;
    const newErr = { ...errorData };

    if (avatr === "") {
      newErr.courseImage = "courseImage cannot be empty";
      isValid = false;
    } else {
      newErr.courseImage = "";
    }
    if (
      !(
        descriptionEditorState &&
        descriptionEditorState.getCurrentContent().hasText()
      )
    ) {
      newErr.discription = "discription cannot be empty";
      isValid = false;
    } else {
      newErr.discription = "";
    }

    if (formData.duration === "") {
      newErr.duration = "duration cannot be empty";
      isValid = false;
    } else {
      newErr.duration = "";
    }

    if (formData.fees === "") {
      newErr.fees = "fees cannot be empty";
      isValid = false;
    } else {
      newErr.fees = "";
    }

    if (formData.name?.trim() === "") {
      newErr.name = "Name cannot be empty";
      isValid = false;
    } else {
      newErr.name = "";
    }

    if (!(formData?.isOnline || formData?.isOffline)) {
      newErr.category = "please select a category";
      isValid = false;
    } else {
      newErr.category = "";
    }

    if (formData?.isOnline) {
      if (checkOnlineContent(formData.online)) {
        newErr.onlineItems = "";
      } else {
        newErr.onlineItems = "you have not filled all the fields in onine tabs";
        isValid = false;
      }
    } else {
      newErr.onlineItems = "";
    }

    if (formData.isOffline) {
      if (formData.offline.slots.length === 0) {
        newErr.offlineItems = "please select a slot on offline tab to continue";
        isValid = false;
      } else {
        newErr.offlineItems = "";
      }
    } else {
      newErr.offlineItems = "";
    }

    setErrorData(newErr);
    return isValid;
  };

  // handle form submission
  const onSubmit = () => {
    if (validateForm()) {
      setLoading(true);
      console.log(formData, "formdataaaaaaaaa");
      console.log("ssssssssssssssssssssssssssssss");
      const contentState = formData?.discription?.getCurrentContent();
      function getInlineStyle(entity) {
        const entityKey = entity.getEntity();
        const entityInstance = contentState.getEntity(entityKey);
        const entityType = entityInstance.getType();
        if (entityType === "STYLE") {
          // Check if the entity type is 'STYLE'
          const entityData = entityInstance.getData();
          return entityData.style; // Return the style data from the entity
        }
        return null; // Return null for other entity types
      }
      const htmlContent = stateToHTML(contentState, {
        inlineStyles: {
          STYLE: {
            element: "span", // Use a span element for text with 'STYLE' entity
            attributes: (style, entity) => ({
              style: getInlineStyle(entity), // Apply the style retrieved from the entity
            }),
          },
        },
      });

      console.log(htmlContent, "htmlContenthtmlContenthtmlContent");

      const formDatas = new FormData();
      formDatas.append("image", image);
      formDatas.append("name", formData.name);
      formDatas.append("duration", formData.duration);
      formDatas.append("fees", formData.fees);
      formDatas.append("description", htmlContent);
      formDatas.append("isOnline", formData.isOnline);
      formDatas.append("isOffline", formData.isOffline);
      formDatas.append("isTopCourse", formData.isTopCourse);
      formDatas.append("offline", JSON.stringify(formData.offline));
      formDatas.append("formData", JSON.stringify(formData.online));
      formDatas.append(
        "onlineGrammerDemoVideo",
        formData.online.Grammar.DemoVideo
      );
      formDatas.append(
        "onlineGrammerDemoAudio",
        formData.online.Grammar.DemoAudio
      );
      formDatas.append(
        "onlineGrammerDemoNote",
        formData.online.Grammar.DemoNote
      );

      formDatas.append(
        "onlineListeningDemoVideo",
        formData.online.Listening.DemoVideo
      );
      formDatas.append(
        "onlineListeningDemoAudio",
        formData.online.Listening.DemoAudio
      );
      formDatas.append(
        "onlineListeningDemoNote",
        formData.online.Listening.DemoNote
      );

      formDatas.append(
        "onlineReadingDemoVideo",
        formData.online.Reading.DemoVideo
      );
      formDatas.append(
        "onlineReadingDemoAudio",
        formData.online.Reading.DemoAudio
      );
      formDatas.append(
        "onlineReadingDemoNote",
        formData.online.Reading.DemoNote
      );
      formDatas.append(
        "onlineSpeakingDemoVideo",
        formData.online.Speaking.DemoVideo
      );
      formDatas.append(
        "onlineSpeakingDemoAudio",
        formData.online.Speaking.DemoAudio
      );
      formDatas.append(
        "onlineSpeakingDemoNote",
        formData.online.Speaking.DemoNote
      );

      formDatas.append(
        "onlineWritingDemoVideo",
        formData.online.Writing.DemoVideo
      );
      formDatas.append(
        "onlineWritingDemoAudio",
        formData.online.Writing.DemoAudio
      );
      formDatas.append(
        "onlineWritingDemoNote",
        formData.online.Writing.DemoNote
      );

      dispatch(ieltsCourseManagmentCourseCreatingAction(formDatas));
      dispatch(successTrueMsgAction(true));
      setAvatar("");
      resetReduxState();
    }
  };

  const resetReduxState = () => {
    let initialState = {
      ieltsCourseManagmentCourseCreateSuccess: {
        courseImage: "",
        name: "",
        duration: "",
        fees: "",
        discription: EditorState.createEmpty(),
        isOnline: false,
        isOffline: false,
        isTopCourse: false,
        online: {
          Speaking: {
            DemoVideo: "",
            DemoAudio: "",
            DemoNote: "",
            Topics: {
              video: [],
              audio: [],
              notes: [],
              examtip: [],
              questionbank: [],
            },
          },
          Reading: {
            DemoVideo: "",
            DemoAudio: "",
            DemoNote: "",
            Topics: {
              video: [],
              audio: [],
              notes: [],
              examtip: [],
              questionbank: [],
            },
          },
          Listening: {
            DemoVideo: "",
            DemoAudio: "",
            DemoNote: "",
            Topics: {
              video: [],
              audio: [],
              notes: [],
              examtip: [],
              questionbank: [],
            },
          },
          Writing: {
            DemoVideo: "",
            DemoAudio: "",
            DemoNote: "",
            Topics: {
              video: [],
              audio: [],
              notes: [],
              examtip: [],
              questionbank: [],
            },
          },
          Grammar: {
            DemoVideo: "",
            DemoAudio: "",
            DemoNote: "",
            Topics: {
              video: [],
              audio: [],
              notes: [],
              examtip: [],
              questionbank: [],
            },
          },
        },
        onlineItems: {
          Speaking: {
            DemoVideo: "",
            DemoAudio: "",
            DemoNote: "",
            Topics: {
              video: [],
              audio: [],
              notes: [],
              examtip: [],
              questionbank: [],
            },
          },
          Reading: {
            DemoVideo: "",
            DemoAudio: "",
            DemoNote: "",
            Topics: {
              video: [],
              audio: [],
              notes: [],
              examtip: [],
              questionbank: [],
            },
          },
          Listening: {
            DemoVideo: "",
            DemoAudio: "",
            DemoNote: "",
            Topics: {
              video: [],
              audio: [],
              notes: [],
              examtip: [],
              questionbank: [],
            },
          },
          Writing: {
            DemoVideo: "",
            DemoAudio: "",
            DemoNote: "",
            Topics: {
              video: [],
              audio: [],
              notes: [],
              examtip: [],
              questionbank: [],
            },
          },
          Grammar: {
            DemoVideo: "",
            DemoAudio: "",
            DemoNote: "",
            Topics: {
              video: [],
              audio: [],
              notes: [],
              examtip: [],
              questionbank: [],
            },
          },
        },
        offline: {
          slots: [],
        },
      },
    };
    // console.log(initialState);
    // setFormData(initialState.ieltsCourseManagmentCourseCreate)
    dispatch(
      ieltsCourseManagmentCreateCourseNullfyAction(
        initialState.ieltsCourseManagmentCourseCreateSuccess
      )
    );
  };

  return (
    <MainContainer active={toggle}>
      {loading && <Upload />}
      <Box
        sx={{
          mb: "50px",
        }}
      >
        <InfoHeader headTitle="Courses" url="/IELTS" />

        <form>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "482px 1fr",
              gap: "32px",
              mt: "32px",
            }}
          >
            <div>
              <FormLabel
                sx={{
                  boxSizing: "border-box",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                  maxWidth: "558px",
                  height: "240px",
                  cursor: "pointer",
                  border: `1px solid #C4C4C4`,
                  borderRadius: "4px",
                  display: "flex",
                  alignItems: "center",
                  // pl: "14px",
                  // pr: "8px",
                  "& img": {
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  },
                }}
              >
                {!avatr && (
                  <svg
                    width="40"
                    height="38"
                    viewBox="0 0 40 38"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.4"
                      d="M39.0456 13.2646V29.5006C39.1638 30.5141 39.0511 31.5412 38.716 32.505C38.3809 33.4687 37.8321 34.3441 37.1106 35.0656C36.3891 35.7871 35.5136 36.336 34.5499 36.6711C33.5861 37.0062 32.559 37.1189 31.5456 37.0006H7.54555C6.53207 37.1189 5.50498 37.0062 4.54122 36.6711C3.57747 36.336 2.70204 35.7871 1.98054 35.0656C1.25905 34.3441 0.710183 33.4687 0.375078 32.505C0.0399733 31.5412 -0.0726823 30.5141 0.0455516 29.5006V9.50063C-0.0726823 8.48715 0.0399733 7.46006 0.375078 6.4963C0.710183 5.53255 1.25905 4.65712 1.98054 3.93562C2.70204 3.21413 3.57747 2.66526 4.54122 2.33016C5.50498 1.99505 6.53207 1.8824 7.54555 2.00063H25.1456C25.234 2.00295 25.3209 2.02482 25.4 2.06467C25.479 2.10453 25.5483 2.16139 25.6028 2.23116C25.6572 2.30094 25.6956 2.38191 25.7151 2.46826C25.7346 2.55461 25.7348 2.64421 25.7156 2.73063C25.2437 5.06749 25.7182 7.49611 27.0351 9.48342C28.352 11.4707 30.4036 12.8544 32.7396 13.3306C34.5884 13.7002 36.5067 13.4799 38.2236 12.7006C38.3151 12.6642 38.4142 12.6508 38.5121 12.6617C38.6101 12.6727 38.7038 12.7075 38.785 12.7633C38.8663 12.819 38.9326 12.8939 38.978 12.9814C39.0234 13.0688 39.0466 13.1661 39.0456 13.2646Z"
                      fill="#396CE8"
                    />
                    <path
                      d="M35.8689 23.8246L26.9649 14.9206C26.5878 14.5449 26.0772 14.334 25.5449 14.334C25.0126 14.334 24.502 14.5449 24.1249 14.9206L14.2449 24.8006C14.058 24.9838 13.8067 25.0864 13.5449 25.0864C13.2832 25.0864 13.0318 24.9838 12.8449 24.8006L10.9649 22.9206C10.5878 22.5449 10.0772 22.334 9.54492 22.334C9.01263 22.334 8.50202 22.5449 8.12492 22.9206L3.22092 27.8246C3.10837 27.937 3.04506 28.0895 3.04492 28.2486V29.5006C3.04492 32.6606 4.38492 34.0006 7.54492 34.0006H31.5449C34.7049 34.0006 36.0449 32.6606 36.0449 29.5006V24.2486C36.0448 24.0895 35.9815 23.937 35.8689 23.8246Z"
                      fill="#396CE8"
                    />
                    <path
                      d="M11.5461 16C11.2178 16.0012 10.8925 15.9377 10.5887 15.8131C10.285 15.6886 10.0087 15.5054 9.77572 15.2741C9.30519 14.807 9.03951 14.172 9.03713 13.509C9.03474 12.846 9.29584 12.2091 9.76299 11.7386C10.2301 11.2681 10.8651 11.0024 11.5281 11H11.5461C12.2092 11 12.8451 11.2634 13.3139 11.7322C13.7827 12.2011 14.0461 12.837 14.0461 13.5C14.0461 14.163 13.7827 14.7989 13.3139 15.2678C12.8451 15.7366 12.2092 16 11.5461 16Z"
                      fill="#396CE8"
                    />
                    <path
                      d="M37.5449 4H35.0449V1.5C35.0449 1.10218 34.8869 0.720644 34.6056 0.43934C34.3243 0.158035 33.9427 0 33.5449 0C33.1471 0 32.7656 0.158035 32.4843 0.43934C32.203 0.720644 32.0449 1.10218 32.0449 1.5V4H29.5449C29.1471 4 28.7656 4.15804 28.4843 4.43934C28.203 4.72064 28.0449 5.10218 28.0449 5.5C28.0449 5.89782 28.203 6.27936 28.4843 6.56066C28.7656 6.84196 29.1471 7 29.5449 7H32.0449V9.5C32.0449 9.89782 32.203 10.2794 32.4843 10.5607C32.7656 10.842 33.1471 11 33.5449 11C33.9427 11 34.3243 10.842 34.6056 10.5607C34.8869 10.2794 35.0449 9.89782 35.0449 9.5V7H37.5449C37.9427 7 38.3243 6.84196 38.6056 6.56066C38.8869 6.27936 39.0449 5.89782 39.0449 5.5C39.0449 5.10218 38.8869 4.72064 38.6056 4.43934C38.3243 4.15804 37.9427 4 37.5449 4Z"
                      fill="#396CE8"
                    />
                  </svg>
                )}

                {avatr && <img src={avatr} />}

                <input
                  type="file"
                  hidden
                  name="courseImage"
                  multiple
                  onChange={handleChange}
                  accept=".jpeg, .jpg, .svg, image/*"
                />
              </FormLabel>
              {errorData?.courseImage && (
                <Typography sx={{ color: "red", fontSize: "12px" }}>
                  {errorData?.courseImage}
                </Typography>
              )}
            </div>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "32px",
              }}
            >
              <Box
                sx={{
                  height: "fit-content",
                  "& .input-group": {
                    mb: "0",
                    mt: "0",
                  },
                }}
              >
                <InputField
                  label="Name"
                  name="name"
                  handleChange={handleChange}
                  widthSize="546px"
                  paramses
                  InputType="text"
                  dispcriptionHeight
                  value={formData?.name}
                />
                {errorData?.name && (
                  <Typography sx={{ color: "red", fontSize: "12px" }}>
                    {errorData?.name}
                  </Typography>
                )}
              </Box>

              <Box
                sx={{
                  height: "fit-content",
                  "& .input-group": {
                    mb: "0",
                    mt: "0",
                  },
                }}
              >
                <InputField
                  label="Duration In Months"
                  name="duration"
                  handleChange={handleChange}
                  widthSize="546px"
                  paramses
                  InputType="text"
                  dispcriptionHeight
                  value={formData?.duration}
                />
                <Typography sx={{ color: "#05050F", fontSize: "12px" }}>
                  Enter duration in months (e.g., 3)
                </Typography>
                {errorData?.duration && (
                  <Typography sx={{ color: "red", fontSize: "12px" }}>
                    {errorData?.duration}
                  </Typography>
                )}
              </Box>

              <Box
                sx={{
                  height: "fit-content",
                  "& .input-group": {
                    mb: "0",
                    mt: "0",
                  },
                }}
              >
                <InputField
                  label="Fees"
                  name="fees"
                  handleChange={handleChange}
                  widthSize="546px"
                  paramses
                  InputType="text"
                  dispcriptionHeight
                  value={formData?.fees}
                />
                {errorData?.fees && (
                  <Typography sx={{ color: "red", fontSize: "12px" }}>
                    {errorData?.fees}
                  </Typography>
                )}
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              mt: "24px",
              "& .rdw-editor-main::-webkit-scrollbar": {
                display: "none",
              },
              "& .rdw-editor-main": {
                border: `1px solid #ECECEC`,
                padding: "0 16px",
                minHeight: "200px",
                borderRadius: "4px",
              },
            }}
          >
            <Typography
              sx={{
                color: "#05050F",
                mb: "24px",
              }}
            >
              Description
            </Typography>
            <Box
              sx={{
                overflow: "hidden",
              }}
            >
              <Box
                sx={{
                  overflowX: "scroll",
                }}
              >
                <Editor
                  editorState={formData?.discription}
                  onEditorStateChange={handleEditorChange}
                  placeholder="Content"
                  wrapperClassName="rdw-editor-wrapper"
                  editorClassName="rdw-editor-main"
                />
              </Box>
            </Box>
            {errorData?.discription && (
              <Typography sx={{ color: "red", fontSize: "12px" }}>
                {errorData?.discription}
              </Typography>
            )}
          </Box>
        </form>

        <Box
          sx={{
            mb: "24px",
          }}
        >
          <SelectCourseCategory
            switches={categorySwitches}
            handleChange={handleCategorySwitches}
            handleError={setErrorData}
          />
          {errorData?.category && (
            <Typography sx={{ color: "red", fontSize: "12px" }}>
              {errorData?.category}
            </Typography>
          )}
        </Box>

        <CourseCategoryMain switches={categorySwitches} />
        <Box
          sx={{
            mb: "30px",
          }}
        >
          {errorData?.onlineItems && (
            <Typography sx={{ color: "red", fontSize: "12px" }}>
              {errorData?.onlineItems}
            </Typography>
          )}
          {errorData?.offlineItems && (
            <Typography sx={{ color: "red", fontSize: "12px" }}>
              {errorData?.offlineItems}
            </Typography>
          )}
        </Box>
        <SubmitButton handleSubmit={onSubmit} type="click" title={"Save"}>
          Save
        </SubmitButton>
      </Box>
    </MainContainer>
  );
}

export default CourseAddNew;
