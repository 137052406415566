import axios from "axios";

import {
  CREATE_PRIVILAGE_ERR,
  CREATE_PRIVILAGE_REQUEST,
  CREATE_PRIVILAGE_SUCCESS,
  CREATE_ROLE_SUB_MENU_PERMISSION_ERR,
  CREATE_ROLE_SUB_MENU_PERMISSION_REQUEST,
  CREATE_ROLE_SUB_MENU_PERMISSION_SUCCESS,
  CREATE_SELECTALL_PERMISSION_ERR,
  CREATE_SELECTALL_PERMISSION_REQUEST,
  CREATE_SELECTALL_PERMISSION_SUCCESS,
  CREATE_SELECTALL_PREVILAGE_ERR,
  CREATE_SELECTALL_PREVILAGE_REQUEST,
  CREATE_SELECTALL_PREVILAGE_SUCCESS,
  FIND_PRIVILAGE_ERR,
  FIND_PRIVILAGE_REQUEST,
  FIND_PRIVILAGE_SUCCESS,
  FIND_ROLE_SUB_MENU_PERMISSION_ERR,
  FIND_ROLE_SUB_MENU_PERMISSION_REQUEST,
  FIND_ROLE_SUB_MENU_PERMISSION_SUCCESS,
  GET_ALL_MAIN_AND_SUB_ERR,
  GET_ALL_MAIN_AND_SUB_REQUEST,
  GET_ALL_MAIN_AND_SUB_SUCCESS,
  ROLE_ALL_FIND_ERR,
  ROLE_ALL_FIND_REQUEST,
  ROLE_ALL_FIND_SUCCESS,
  ROLE_BULKUPLOAD_ERR,
  ROLE_BULKUPLOAD_REQUEST,
  ROLE_BULKUPLOAD_SUCCESS,
  ROLE_CREATE_ERR,
  ROLE_CREATE_REQUEST,
  ROLE_CREATE_SUCCESS,
  ROLE_DELETE_ERR,
  ROLE_DELETE_REQUEST,
  ROLE_DELETE_SUCCESS,
  ROLE_EDIT_ERR,
  ROLE_EDIT_FIND_ERR,
  ROLE_EDIT_FIND_REQUEST,
  ROLE_EDIT_FIND_SUCCESS,
  ROLE_EDIT_REQUEST,
  ROLE_EDIT_SUCCESS,
  SUCCESS_TRUE_MSG_SUCCESS,
} from "../constants/roleManagmentConstant";

// @RoleManagmentBulkUploadAction

export const rollBulkUploadAction = (formData) => async (
  dispatch,
  getState
) => {
  try {
    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    dispatch({ type: ROLE_BULKUPLOAD_REQUEST });

    let { data } = await axios.post(
      "/api/admin/role/Bulkupload",
      formData,
      config
    );

    dispatch({ type: ROLE_BULKUPLOAD_SUCCESS, payload: data });
    dispatch({ type: SUCCESS_TRUE_MSG_SUCCESS, payload: true });
  } catch (error) {
    dispatch({ type: ROLE_BULKUPLOAD_ERR, payload: error.response.data });
  }
};

export const createRollAction = (
  roleName,
  roleDescription,
  isLeadUpdate,
  isLeadView,
  isLeadDelete,
  isLeadAll
) => async (dispatch, getState) => {
  try {
    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    dispatch({ type: ROLE_CREATE_REQUEST });

    let { data } = await axios.post(
      "/api/admin/role/createRole",
      {
        roleName,
        roleDescription,
        isLeadUpdate,
        isLeadView,
        isLeadDelete,
        isLeadAll,
      },
      config
    );

    dispatch({ type: ROLE_CREATE_SUCCESS, payload: data });
    dispatch({ type: SUCCESS_TRUE_MSG_SUCCESS, payload: true });
  } catch (error) {
    dispatch({ type: ROLE_CREATE_ERR, payload: error.response.data });
  }
};

// all roles find action
export const allRoleFindAction = () => async (dispatch, getState) => {
  try {
    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    dispatch({ type: ROLE_ALL_FIND_REQUEST });

    let { data } = await axios.get("/api/admin/role/findRole", config);

    dispatch({ type: ROLE_ALL_FIND_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: ROLE_ALL_FIND_ERR, payload: error.response.data });
  }
};

// roleDeleteAction
export const roleDeleteAction = (id) => async (dispatch, getState) => {
  try {
    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    dispatch({ type: ROLE_DELETE_REQUEST });

    let { data } = await axios.delete(
      `/api/admin/role/deleteRole?id=${id}`,
      config
    );

    dispatch({ type: ROLE_DELETE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: ROLE_DELETE_ERR, payload: error.response.data });
  }
};

// roleEditFindAction
export const roleEditFindAction = (id) => async (dispatch, getState) => {
  try {
    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    dispatch({ type: ROLE_EDIT_FIND_REQUEST });

    let { data } = await axios.post(
      "/api/admin/role/editRoleFind",
      { id },
      config
    );

    dispatch({ type: ROLE_EDIT_FIND_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: ROLE_EDIT_FIND_ERR, payload: error.response.data });
  }
};

// editRollAction
export const editRollAction = (
  id,
  roleName,
  roleDescription,
  isLeadUpdate,
  isLeadView,
  isLeadDelete,
  isLeadAll
) => async (dispatch, getState) => {
  try {
    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    dispatch({ type: ROLE_EDIT_REQUEST });

    let { data } = await axios.put(
      "/api/admin/role/editRole",
      {
        id,
        roleName,
        roleDescription,
        isLeadUpdate,
        isLeadView,
        isLeadDelete,
        isLeadAll,
      },
      config
    );

    dispatch({ type: ROLE_EDIT_SUCCESS, payload: data });
    dispatch({ type: SUCCESS_TRUE_MSG_SUCCESS, payload: true });
  } catch (error) {
    dispatch({ type: ROLE_EDIT_ERR, payload: error.response.data });
  }
};

// ROLE MAIN MENU AND SUB MENU

// getAllMainMenuWithSubAction
export const getAllMainMenuWithSubAction = () => async (dispatch, getState) => {
  try {
    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    dispatch({ type: GET_ALL_MAIN_AND_SUB_REQUEST });

    let { data } = await axios.get("/api/admin/role/getAllMenus", config);

    dispatch({ type: GET_ALL_MAIN_AND_SUB_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: GET_ALL_MAIN_AND_SUB_ERR, payload: error.response.data });
  }
};

// createRoleSubMenuAction
export const createRoleSubMenuAction = (
  status,
  mainMenuId,
  subId,
  roleId
) => async (dispatch, getState) => {
  console.log(mainMenuId, "main menu id");
  try {
    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    dispatch({ type: CREATE_ROLE_SUB_MENU_PERMISSION_REQUEST });

    let { data } = await axios.post(
      "/api/admin/role/createRoleSubMenu",
      { status, mainMenuId, subId, roleId },
      config
    );

    dispatch({
      type: CREATE_ROLE_SUB_MENU_PERMISSION_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CREATE_ROLE_SUB_MENU_PERMISSION_ERR,
      payload: error.response.data,
    });
  }
};

// findRoleSubMenuAction
export const findRoleSubMenuAction = (id) => async (dispatch, getState) => {
  try {
    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    dispatch({ type: FIND_ROLE_SUB_MENU_PERMISSION_REQUEST });

    let { data } = await axios.get(
      `/api/admin/role/findRoleSubMenu?id=${id}`,
      config
    );

    dispatch({
      type: FIND_ROLE_SUB_MENU_PERMISSION_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: FIND_ROLE_SUB_MENU_PERMISSION_ERR,
      payload: error.response.data,
    });
  }
};

// createPrivilageAction
export const createPrivilageAction = (
  status,
  permissionId,
  subId,
  roleId
) => async (dispatch, getState) => {
  try {
    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    dispatch({ type: CREATE_PRIVILAGE_REQUEST });

    let { data } = await axios.post(
      "/api/admin/role/createPrivilage",
      { status, permissionId, subId, roleId },
      config
    );

    dispatch({
      type: CREATE_PRIVILAGE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CREATE_PRIVILAGE_ERR,
      payload: error.response.data,
    });
  }
};

// findPermissionPrivilageAction
export const findPermissionPrivilageAction = (id) => async (
  dispatch,
  getState
) => {
  try {
    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    dispatch({ type: FIND_PRIVILAGE_REQUEST });

    let { data } = await axios.get(
      `/api/admin/role/findPermissionPrivilage?id=${id}`,
      config
    );

    dispatch({
      type: FIND_PRIVILAGE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: FIND_PRIVILAGE_ERR,
      payload: error.response.data,
    });
  }
};

// createSelectAllPrivilageAction

export const createSelectAllPrivilageAction = (
  status,
  MainMenuId,
  roleId
) => async (dispatch, getState) => {
  try {
    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    dispatch({ type: CREATE_SELECTALL_PREVILAGE_REQUEST });

    let { data } = await axios.post(
      "/api/admin/role/selectAllMainMenu",
      { status, MainMenuId, roleId },
      config
    );

    dispatch({
      type: CREATE_SELECTALL_PREVILAGE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CREATE_SELECTALL_PREVILAGE_ERR,
      payload: error.response.data,
    });
  }
};

// createSelectAllPermissionAction

export const createSelectAllPermissionAction = (
  status,
  subMenuId,
  roleId
) => async (dispatch, getState) => {
  try {
    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    dispatch({ type: CREATE_SELECTALL_PERMISSION_REQUEST });

    let { data } = await axios.post(
      "/api/admin/role/selectAllPermission",
      { status, subMenuId, roleId },
      config
    );

    dispatch({
      type: CREATE_SELECTALL_PERMISSION_SUCCESS,
      payload: data,
    });
    
  } catch (error) {
    dispatch({
      type: CREATE_SELECTALL_PERMISSION_ERR,
      payload: error.response.data,
    });
  }
};
