// TEACHER DASHBOARD FIND REDUCER

import {
  IELTS_TEACHER_DASHBOARD_FINDBYID_ASSIGNEDSTUDENTS_ERR,
  IELTS_TEACHER_DASHBOARD_FINDBYID_ASSIGNEDSTUDENTS_REQUEST,
  IELTS_TEACHER_DASHBOARD_FINDBYID_ASSIGNEDSTUDENTS_SUCCESS,
  IELTS_TEACHER_DASHBOARD_FIND_ERR,
  IELTS_TEACHER_DASHBOARD_FIND_REQUEST,
  IELTS_TEACHER_DASHBOARD_FIND_SUCCESS,
  IELTS_TEACHER_DASHBOARD_LIVECLASS_FIND_ERR,
  IELTS_TEACHER_DASHBOARD_LIVECLASS_FIND_REQUEST,
  IELTS_TEACHER_DASHBOARD_LIVECLASS_FIND_SUCCESS,
  IELTS_TEACHER_DASHBOARD__ASSIGNEDSTUDENTS_COURSEFIND_ERR,
  IELTS_TEACHER_DASHBOARD__ASSIGNEDSTUDENTS_COURSEFIND_REQUEST,
  IELTS_TEACHER_DASHBOARD__ASSIGNEDSTUDENTS_COURSEFIND_SUCCESS,
} from "../../constants/Ielts/ieltsTeacherDashBoardContants";

export const ieltsTeacherDashboardFindReducer = (state = {}, action) => {
  switch (action.type) {
    case IELTS_TEACHER_DASHBOARD_FIND_REQUEST:
      return {
        ...state,
        ieltsTeacherDashBoardFindLoading: true,
      };
    case IELTS_TEACHER_DASHBOARD_FIND_SUCCESS:
      return {
        ...state,
        ieltsTeacherDashBoardFindLoading: false,
        ieltsTeacherDashBoardFindSuccess: action.payload,
      };
    case IELTS_TEACHER_DASHBOARD_FIND_ERR:
      return {
        ...state,
        ieltsTeacherDashBoardFindLoading: false,
        ieltsTeacherDashBoardFinderror: action.payload,
      };
    default:
      return state;
  }
};

// TEACHER DASHBOARD FINDBYID ASSIGNED STUDENTS CONSTANTS

export const ieltsTeacherDashboardAssignedStudentsFindByidReducer = (
  state = {},
  action
) => {
  switch (action.type) {
    case IELTS_TEACHER_DASHBOARD_FINDBYID_ASSIGNEDSTUDENTS_REQUEST:
      return {
        ...state,
        ieltsTeacherDashBoardAssignedStudentsFindLoading: true,
      };
    case IELTS_TEACHER_DASHBOARD_FINDBYID_ASSIGNEDSTUDENTS_SUCCESS:
      return {
        ...state,
        ieltsTeacherDashBoardAssignedStudentsFindLoading: false,
        ieltsTeacherDashBoardAssignedStudentsFindSuccess: action.payload,
      };
    case IELTS_TEACHER_DASHBOARD_FINDBYID_ASSIGNEDSTUDENTS_ERR:
      return {
        ...state,
        ieltsTeacherDashBoardAssignedStudentsFindLoading: false,
        ieltsTeacherDashBoardAssignedStudentsFinderror: action.payload,
      };
    default:
      return state;
  }
};

// TEACHER DASHBOARD  ASSIGNED STUDENTS COURSE FIND REDUCER

export const ieltsTeacherDashboardAssignedStudentsCourseFindReducer = (
  state = {},
  action
) => {
  switch (action.type) {
    case IELTS_TEACHER_DASHBOARD__ASSIGNEDSTUDENTS_COURSEFIND_REQUEST:
      return {
        ...state,
        ieltsTeacherDashBoardAssignedStudentsCourseFindLoading: true,
      };
    case IELTS_TEACHER_DASHBOARD__ASSIGNEDSTUDENTS_COURSEFIND_SUCCESS:
      return {
        ...state,
        ieltsTeacherDashBoardAssignedStudentsCourseFindLoading: false,
        ieltsTeacherDashBoardAssignedStudentsCourseFindSuccess: action.payload,
      };
    case IELTS_TEACHER_DASHBOARD__ASSIGNEDSTUDENTS_COURSEFIND_ERR:
      return {
        ...state,
        ieltsTeacherDashBoardAssignedStudentsCourseFindLoading: false,
        ieltsTeacherDashBoardAssignedStudentsCourseFinderror: action.payload,
      };
    default:
      return state;
  }
};

// TEACHER DASHBOARD LIVECLASS FIND REDUCER

export const ieltsTeacherDashboardLiveClassFindReducer = (
  state = {},
  action
) => {
  switch (action.type) {
    case IELTS_TEACHER_DASHBOARD_LIVECLASS_FIND_REQUEST:
      return {
        ...state,
        ieltsTeacherDashBoardLiveClassFindLoading: true,
      };
    case IELTS_TEACHER_DASHBOARD_LIVECLASS_FIND_SUCCESS:
      return {
        ...state,
        ieltsTeacherDashBoardLiveClassFindLoading: false,
        ieltsTeacherDashBoardLiveClassFindSuccess: action.payload,
      };
    case IELTS_TEACHER_DASHBOARD_LIVECLASS_FIND_ERR:
      return {
        ...state,
        ieltsTeacherDashBoardLiveClassFindLoading: false,
        ieltsTeacherDashBoardLiveClassFinderror: action.payload,
      };
    default:
      return state;
  }
};
