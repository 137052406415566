import React, { useCallback, useEffect, useState } from "react";
import { MainContainer } from "../../content/content.element";
import { InfoHeader } from "../../customComponent/InfoHeader";
import { Box, Button, Chip, Grid, Typography } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import { bannerCreateAction } from "../../../actions/bannerAction";
import Swal from "sweetalert2";
import { successTrueMsgAction } from "../../../actions/globalAction";
import {
  TypographyText,
  TypographyText1,
} from "../../customComponent/Typography";
import InputField, { SelectInputField } from "../../customComponent/InputField";
import { SwitchButton } from "../../customComponent/Buttons";
import {
  leadSourceCreateAction,
  leadSourceEditAction,
  leadSourceFindDetailAction,
} from "../../../actions/leadSourceAction";
import { useParams } from "react-router-dom";

function EditLeadSource({ toggle }) {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [leadSource, setLeadSource] = useState("");
  const [leadSourceName, setLeadSourceName] = useState("");
  const [textBox, setTextBox] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  const [isMandatory, setIsMandatory] = useState(false);
  const [item, setItem] = useState("");
  const [items, setItems] = useState([]);

  let { createLeadSourceSuccess } = useSelector((state) => {
    return state.leadSourceCreate;
  });

  let { findDetailsLeadSourceSuccess } = useSelector((state) => {
    return state.leadSourceFindDetail;
  });

  const { successAlertMsgSuccess } = useSelector((state) => {
    return state.successTrueMsg;
  });

  useEffect(() => {
    dispatch(leadSourceFindDetailAction(id));
  }, [dispatch, id]);

  console.log(
    findDetailsLeadSourceSuccess,
    "findDetailsLeadSourceSuccessfindDetailsLeadSourceSuccess"
  );
  useEffect(() => {
    if (findDetailsLeadSourceSuccess) {
      setLeadSource(findDetailsLeadSourceSuccess?.leadSource);
      setLeadSourceName(findDetailsLeadSourceSuccess?.name);
      setTextBox(
        findDetailsLeadSourceSuccess?.type === "textBox" ? true : false
      );
      setDropdown(
        findDetailsLeadSourceSuccess?.type === "dropdown" ? true : false
      );
      setItems(
        findDetailsLeadSourceSuccess?.items
          ? JSON.parse(findDetailsLeadSourceSuccess?.items)
          : []
      );
      setIsMandatory(findDetailsLeadSourceSuccess?.isMandatory);
    }
  }, [findDetailsLeadSourceSuccess]);

  useEffect(() => {
    if (createLeadSourceSuccess && successAlertMsgSuccess) {
      Swal.fire("Edited!", `Lead Source Edited Successfully.`, "success");
      dispatch(successTrueMsgAction(false));
    }
  }, [createLeadSourceSuccess]);

  //   Delete function

  //   Handle change

  const handleSubmit = () => {
    let details = {
      leadSource: leadSource,
      leadSourceName: leadSourceName,
      type: textBox ? "textBox" : dropdown ? "dropdown" : null,
      items: items,
      isMandatory,
    };
    dispatch(leadSourceEditAction(details, id));
    dispatch(successTrueMsgAction(true));
  };

  return (
    <>
      <MainContainer active={toggle}>
        <InfoHeader headTitle="Edit Lead Source" url="/Settings" />
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12}>
            <TypographyText1 title="Enter Lead Source Details" variant="p" />
          </Grid>

          <Grid item lg={6} xs={12}>
            {/* <SelectInputField
              label={"Add New Lead Source"}
              handleSelectChange={(e) => setLeadSource(e.target.value)}
              value={leadSource}
              mapValue={["IELTS Center", "Refferance", "Events"]}
              mapMethod=""
              required={true}
            /> */}
            <InputField
              label="Add New Lead Source"
              handleChange={(e) => setLeadSource(e.target.value)}
              value={leadSource}
              required={true}
              InputType="text"
            />
          </Grid>
          {/* <Grid item lg={6} xs={12} mt={2}>
            <InputField
              label="Source Name"
              handleChange={(e) => setLeadSourceName(e.target.value)}
              value={leadSourceName}
              required={true}
              InputType="text"
            />
          </Grid> */}
          <Grid
            item
            xs={12}
            mt={1}
            style={{ display: "flex", justifyContent: "start" }}
          >
            <Box>
              <SwitchButton
                label="Text&nbsp;Box"
                handleChange={(e) => {
                  e.target.checked == false
                    ? setTextBox(e.target.checked)
                    : setTextBox(e.target.checked);
                  setDropdown(false);
                  setItems([]);
                  setItem("");
                }}
                checked={textBox}
              />
            </Box>
            <Box style={{ marginLeft: "35px" }}>
              <SwitchButton
                label="Dropdown"
                handleChange={(e) => {
                  e.target.checked == false
                    ? setDropdown(e.target.checked)
                    : setDropdown(e.target.checked);
                  setTextBox(false);
                }}
                checked={dropdown}
              />
            </Box>
            <Box style={{ marginLeft: "40px" }}>
              <SwitchButton
                label="Mandatory"
                handleChange={(e) => {
                  setIsMandatory(e.target.checked);
                }}
                checked={isMandatory}
              />
            </Box>
          </Grid>

          {dropdown && (
            <>
              <Grid
                item
                xs={12}
                mt={1}
                style={{ display: "flex", justifyContent: "start" }}
              >
                <Box
                  component={"form"}
                  // onSubmit={classSubmit}
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr 65px ",
                    gap: "17px",
                    maxWidth: "907px",
                  }}
                >
                  <InputField
                    label="Item"
                    handleChange={(e) => setItem(e.target.value)}
                    value={item}
                    required={true}
                    InputType="text"
                    placeholder="item"
                  />

                  <Button
                    onClick={() => {
                      setItems([...items, item]);
                      setItem("");
                    }}
                    sx={{
                      width: "auto",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      border: "1px solid rgba(236, 236, 236, 1)",
                      height: "47px",
                    }}
                  >
                    <svg
                      width="34"
                      height="34"
                      viewBox="0 0 34 34"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        x="0.5"
                        y="14.8906"
                        width="33"
                        height="4.21277"
                        rx="2.10638"
                        fill="#3B478F"
                      />
                      <rect
                        x="19.1055"
                        y="0.5"
                        width="33"
                        height="4.21277"
                        rx="2.10638"
                        transform="rotate(90 19.1055 0.5)"
                        fill="#3B478F"
                      />
                    </svg>
                  </Button>
                </Box>
              </Grid>

              <>
                {items &&
                  items?.map((data, i) => (
                    <Grid
                      item
                      xs={12}
                      mt={1}
                      style={{ display: "flex", justifyContent: "start" }}
                    >
                      <Chip
                        key={i}
                        sx={{
                          "& .MuiChip-label": {
                            pl: "5px",
                            pr: "5px",
                          },
                          "&.MuiChip-root": {
                            borderRadius: "8px",
                            border: "1px solid rgba(206, 206, 206, 1)",
                            backgroundColor: "rgba(255, 255, 255,1)",
                          },
                        }}
                        label={
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              gap: "10px",
                              padding: "2px",
                              color: "rgba(20, 30, 60, 1)",
                              "& svg": {
                                cursor: "pointer",
                              },
                            }}
                          >
                            <Typography>{data}</Typography>
                            {/* <Divider orientation="vertical" flexItem /> */}
                            {/* <Typography>{item.availability} Slots</Typography> */}
                            <svg
                              onClick={() => {
                                setItems(items.filter((jtem, ind) => ind != i));
                              }}
                              width="8"
                              height="8"
                              viewBox="0 0 8 8"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect
                                y="0.816406"
                                width="1"
                                height="10"
                                rx="0.5"
                                transform="rotate(-45 0 0.816406)"
                                fill="#D9D9D9"
                              />
                              <rect
                                x="7.07031"
                                y="0.109375"
                                width="1"
                                height="10"
                                rx="0.5"
                                transform="rotate(45 7.07031 0.109375)"
                                fill="#D9D9D9"
                              />
                            </svg>
                          </Box>
                        }
                      />
                    </Grid>
                  ))}
              </>
            </>
          )}

          <Button
            sx={{
              color: "white",
              backgroundColor: "#141E3C",
              height: "38px",
              width: "143px",
              fontWeight: 700,
              fontSize: "12px",
              borderRadius: "8px",
              "&:hover": {
                backgroundColor: "rgba(20, 30, 60, 0.9)",
              },
              mt: 3,
            }}
            onClick={handleSubmit}
          >
            submit
          </Button>
        </Grid>
      </MainContainer>
    </>
  );
}

export default EditLeadSource;
