// courseMajor master constants

export const COURSEMASTER_CREATE_REQUEST = "COURSEMASTER_CREATE_REQUEST";
export const COURSEMASTER_CREATE_SUCCESS = "COURSEMASTER_CREATE_SUCCESS";
export const COURSEMASTER_CREATE_ERR = "COURSEMASTER_CREATE_ERR";

// COURSEMAJOR MASTER FIND

export const COURSEMASTER_FIND_REQUEST = "COURSEMASTER_FIND_REQUEST";
export const COURSEMASTER_FIND_SUCCESS = "COURSEMASTER_FIND_SUCCESS";
export const COURSEMASTER_FIND_ERR = "COURSEMASTER_FIND_ERR";

// COURSEMAJOR MASTER FINDONE

export const COURSEMASTER_FINDONE_REQUEST = "COURSEMASTER_FINDONE_REQUEST";
export const COURSEMASTER_FINDONE_SUCCESS = "COURSEMASTER_FINDONE_SUCCESS";
export const COURSEMASTER_FINDONE_ERR = "COURSEMASTER_FINDONE_ERR";

// COURSEMAJOR MASTER FINDONE

export const COURSEMASTER_UPDATE_REQUEST = "COURSEMASTER_UPDATE_REQUEST";
export const COURSEMASTER_UPDATE_SUCCESS = "COURSEMASTER_UPDATE_SUCCESS";
export const COURSEMASTER_UPDATE_ERR = "COURSEMASTER_UPDATE_ERR";

// COURSEMAJOR MASTER FINDONE

export const COURSEMASTER_DELETE_REQUEST = "COURSEMASTER_DELETE_REQUEST";
export const COURSEMASTER_DELETE_SUCCESS = "COURSEMASTER_DELETE_SUCCESS";
export const COURSEMASTER_DELETE_ERR = "COURSEMASTER_DELETE_ERR";

// subject master constants

export const SUBJECTMASTER_CREATE_REQUEST = "SUBJECTMASTER_CREATE_REQUEST";
export const SUBJECTMASTER_CREATE_SUCCESS = "SUBJECTMASTER_CREATE_SUCCESS";
export const SUBJECTMASTER_CREATE_ERR = "SUBJECTMASTER_CREATE_ERR";

// SUBJECT MASTER FIND

export const SUBJECTMASTER_FIND_REQUEST = "SUBJECTMASTER_FIND_REQUEST";
export const SUBJECTMASTER_FIND_SUCCESS = "SUBJECTMASTER_FIND_SUCCESS";
export const SUBJECTMASTER_FIND_ERR = "SUBJECTMASTER_FIND_ERR";

// SUBJECT MASTER FINDONE

export const SUBJECTMASTER_FINDONE_REQUEST = "SUBJECTMASTER_FINDONE_REQUEST";
export const SUBJECTMASTER_FINDONE_SUCCESS = "SUBJECTMASTER_FINDONE_SUCCESS";
export const SUBJECTMASTER_FINDONE_ERR = "SUBJECTMASTER_FINDONE_ERR";

// SUBJECT MASTER UPDATE

export const SUBJECTMASTER_UPDATE_REQUEST = "SUBJECTMASTER_UPDATE_REQUEST";
export const SUBJECTMASTER_UPDATE_SUCCESS = "SUBJECTMASTER_UPDATE_SUCCESS";
export const SUBJECTMASTER_UPDATE_ERR = "SUBJECTMASTER_UPDATE_ERR";

// SUBJECT MASTER DELETE

export const SUBJECTMASTER_DELETE_REQUEST = "SUBJECTMASTER_DELETE_REQUEST";
export const SUBJECTMASTER_DELETE_SUCCESS = "SUBJECTMASTER_DELETE_SUCCESS";
export const SUBJECTMASTER_DELETE_ERR = "SUBJECTMASTER_DELETE_ERR";

// SUBJECT MASTER BULKUPLOAD

export const SUBJECTMASTER_BULKUPLOAD_REQUEST =
  "SUBJECTMASTER_BULKUPLOAD_REQUEST";
export const SUBJECTMASTER_BULKUPLOAD_SUCCESS =
  "SUBJECTMASTER_BULKUPLOAD_SUCCESS";
export const SUBJECTMASTER_BULKUPLOAD_ERR = "SUBJECTMASTER_BULKUPLOAD_ERR";



// subject master constants

export const COURSELEVELMASTER_CREATE_REQUEST = "COURSELEVELMASTER_CREATE_REQUEST";
export const COURSELEVELMASTER_CREATE_SUCCESS = "COURSELEVELMASTER_CREATE_SUCCESS";
export const COURSELEVELMASTER_CREATE_ERR = "COURSELEVELMASTER_CREATE_ERR";


// SUBJECT MASTER FIND

export const COURSELEVELMASTER_FIND_REQUEST = "COURSELEVELMASTER_FIND_REQUEST";
export const COURSELEVELMASTER_FIND_SUCCESS = "COURSELEVELMASTER_FIND_SUCCESS";
export const COURSELEVELMASTER_FIND_ERR = "COURSELEVELMASTER_FIND_ERR";


// SUBJECT MASTER UPDATE

export const COURSELEVELMASTER_UPDATE_REQUEST = "COURSELEVELMASTER_UPDATE_REQUEST";
export const COURSELEVELMASTER_UPDATE_SUCCESS = "COURSELEVELMASTER_UPDATE_SUCCESS";
export const COURSELEVELMASTER_UPDATE_ERR = "COURSELEVELMASTER_UPDATE_ERR";


// SUBJECT MASTER DELETE

export const COURSELEVELMASTER_DELETE_REQUEST = "COURSELEVELMASTER_DELETE_REQUEST";
export const COURSELEVELMASTER_DELETE_SUCCESS = "COURSELEVELMASTER_DELETE_SUCCESS";
export const COURSELEVELMASTER_DELETE_ERR = "COURSELEVELMASTER_DELETE_ERR"