import axios from "axios";
import {
  IELTS_FIND_PURCHASED_STUDENT_DETAILS_BY_ID_ERR,
  IELTS_FIND_PURCHASED_STUDENT_DETAILS_BY_ID_REQUEST,
  IELTS_FIND_PURCHASED_STUDENT_DETAILS_BY_ID_SUCCESS,
  IELTS_FIND_PURCHASED_STUDENT_COURSE_ERR,
  IELTS_FIND_PURCHASED_STUDENT_COURSE_REQUEST,
  IELTS_FIND_PURCHASED_STUDENT_COURSE_SUCCESS,
  IELTS_PURCHASED_STUDENT_COURSE_STATUS_CHANGE_ERR,
  IELTS_PURCHASED_STUDENT_COURSE_STATUS_CHANGE_REQUEST,
  IELTS_PURCHASED_STUDENT_COURSE_STATUS_CHANGE_SUCCESS,
  IELTS_FIND_PURCHASED_STUDENT_COURSE_BY_ID_ERR,
  IELTS_FIND_PURCHASED_STUDENT_COURSE_BY_ID_SUCCESS,
  IELTS_FIND_PURCHASED_STUDENT_COURSE_BY_ID_REQUEST,
  IELTS_PURCHASED_STUDENT_ASSIGN_TEACHER_REQUEST,
  IELTS_PURCHASED_STUDENT_ASSIGN_TEACHER_SUCCESS,
  IELTS_PURCHASED_STUDENT_ASSIGN_TEACHER_ERR,
} from "../../constants/Ielts/IeltsPurchasedConstants";
import { SUCCESS_TRUE_MSG_ERR } from "../../constants/roleManagmentConstant";

// des:purchased student course Action
export const ieltsPurchasedStudentFindAction = () => async (dispatch) => {
  try {
    dispatch({ type: IELTS_FIND_PURCHASED_STUDENT_COURSE_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.get(
      "/api/admin/ielts/students/findCoursePurchasedStudents",
      config
    );

    dispatch({
      type: IELTS_FIND_PURCHASED_STUDENT_COURSE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({
      type: IELTS_FIND_PURCHASED_STUDENT_COURSE_ERR,
      payload: error.response.data,
    });
    dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: true });
  }
};

// des:purchased student details by id Action
export const ieltsPurchasedStudentDetailsFindByIdAction =
  (id) => async (dispatch) => {
    try {
      dispatch({ type: IELTS_FIND_PURCHASED_STUDENT_DETAILS_BY_ID_REQUEST });

      let isUserExist = localStorage.getItem("loginInfo")
        ? JSON.parse(localStorage.getItem("loginInfo"))
        : null;

      const config = {
        headers: {
          Authorization: `Bearer ${isUserExist?.token}`,
        },
      };

      let { data } = await axios.get(
        `/api/admin/ielts/students/FindPurchasedStudentsDetailsById?id=${id}`,
        config
      );

      dispatch({
        type: IELTS_FIND_PURCHASED_STUDENT_DETAILS_BY_ID_SUCCESS,
        payload: data,
      });
    } catch (error) {
      console.log(error.response, "error.response");
      dispatch({
        type: IELTS_FIND_PURCHASED_STUDENT_DETAILS_BY_ID_ERR,
        payload: error.response.data,
      });
      dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: true });
    }
  };

// des:purchased student course by id Action
export const ieltsPurchasedStudentCourseFindByIdAction =
  (id) => async (dispatch) => {
    try {
      dispatch({ type: IELTS_FIND_PURCHASED_STUDENT_COURSE_BY_ID_REQUEST });

      let isUserExist = localStorage.getItem("loginInfo")
        ? JSON.parse(localStorage.getItem("loginInfo"))
        : null;

      const config = {
        headers: {
          Authorization: `Bearer ${isUserExist?.token}`,
        },
      };

      let { data } = await axios.get(
        `/api/admin/ielts/students/FindPurchasedStudentsCourseById?id=${id}`,
        config
      );

      dispatch({
        type: IELTS_FIND_PURCHASED_STUDENT_COURSE_BY_ID_SUCCESS,
        payload: data,
      });
    } catch (error) {
      console.log(error.response, "error.response");
      dispatch({
        type: IELTS_FIND_PURCHASED_STUDENT_COURSE_BY_ID_ERR,
        payload: error.response.data,
      });
      dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: true });
    }
  };

// des:purchased student assign teacher action
export const ieltsPurchasedStudentCourseAssignTeacherAction =
  (teacherId, id) => async (dispatch, getState) => {
    console.log(teacherId, id ,"teacherId, id : in action");
    try {
      dispatch({ type: IELTS_PURCHASED_STUDENT_ASSIGN_TEACHER_REQUEST });

      let isUserExist = localStorage.getItem("loginInfo")
        ? JSON.parse(localStorage.getItem("loginInfo"))
        : null;

      const config = {
        headers: {
          Authorization: `Bearer ${isUserExist?.token}`,
        },
      };

      let { data } = await axios.post(
        "/api/admin/ielts/students/purchasedStudentsAssignTeacher",
        {
          teacherId,
          id,
        },
        config
      );

      dispatch({
        type: IELTS_PURCHASED_STUDENT_ASSIGN_TEACHER_SUCCESS,
        payload: data,
      });
    } catch (error) {
      console.log(error.response, "error.response");
      dispatch({
        type: IELTS_PURCHASED_STUDENT_ASSIGN_TEACHER_ERR,
        payload: error.response.data,
      });
    }
  };

// des:purchased student course status change action
export const ieltsPurchasedStudentCourseStatusChangeAction =
  (status, id) => async (dispatch, getState) => {
    try {
      dispatch({ type: IELTS_PURCHASED_STUDENT_COURSE_STATUS_CHANGE_REQUEST });

      let isUserExist = localStorage.getItem("loginInfo")
        ? JSON.parse(localStorage.getItem("loginInfo"))
        : null;

      const config = {
        headers: {
          Authorization: `Bearer ${isUserExist?.token}`,
        },
      };

      let { data } = await axios.post(
        "/api/admin/ielts/students/purchasedStudentsStatusChange",
        {
          status,
          id,
        },
        config
      );

      dispatch({
        type: IELTS_PURCHASED_STUDENT_COURSE_STATUS_CHANGE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      console.log(error.response, "error.response");
      dispatch({
        type: IELTS_PURCHASED_STUDENT_COURSE_STATUS_CHANGE_ERR,
        payload: error.response.data,
      });
    }
  };
