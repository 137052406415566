import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import React from "react";
import Reorder from "./Reorder";

function NoteTable({ rows,component }) {
  console.log(rows, "rowsrowsrows");
  return (
    <Box
      sx={{
        overflowX: "scroll",
        maxWidth: "100%",
        "&::-webkit-scrollbar": {
          display: "none"
        },
        "& table": {
          minWidth: "1200px"
        }
      }}
    >
      <Table>
        <TableHead
          sx={{
            background: "#fff",
            "& th": {
              textTransform: "uppercase",
              fontWeight: "600",
              // fontFamily: "Inter",
              color: "#666E7D"
            }
          }}
        >
          <TableRow>
            <TableCell>
              NOTE <Reorder />
            </TableCell>
            <TableCell>
              NOTE DATE <Reorder />
            </TableCell>
            <TableCell>
              LEAD NAME <Reorder />
            </TableCell>
            <TableCell>
              CREATED BY <Reorder />
            </TableCell>
            {/* <TableCell>
                RELATED TO <Reorder />
              </TableCell>
              <TableCell>
                COUNSELLOR <Reorder />
              </TableCell>
              <TableCell>
                TASK OWNER <Reorder />
              </TableCell> */}
          </TableRow>
        </TableHead>

        {component == "teleCaller" && (
          <TableBody
            sx={{
              background: "#fff",
              border: "1px solid rgba(220, 223, 227, 0.50)"
            }}
          >
            {rows?.map((row, i) =>
              row.lead_telecaller_notes?.map((value, j) => (
                <TableRow
                  key={`${i}-${j}`}
                  sx={{
                    "& .MuiTableCell-root": {
                      borderBottom: "1px solid rgba(220, 223, 227, 0.50)"
                    }
                  }}
                >
                  <TableCell>{value.teleCallerNote}</TableCell>
                  <TableCell>{value.noteDate}</TableCell>
                  <TableCell>{row.firstName}</TableCell>
                  <TableCell>{value.CreatedBy}</TableCell>
                  {/* <TableCell sx={{ color: "blue" }}>{row.related}</TableCell> */}
                  {/* <TableCell>{row.counsellor}</TableCell>
        <TableCell>{row.taskOwner}</TableCell> */}
                </TableRow>
              ))
            )}
          </TableBody>
        )}

        {component == "counsilor" && (
          <TableBody
            sx={{
              background: "#fff",
              border: "1px solid rgba(220, 223, 227, 0.50)"
            }}
          >
            {rows?.map((row, i) =>
              row.lead_counsilor_notes?.map((value, j) => (
                <TableRow
                  key={`${i}-${j}`}
                  sx={{
                    "& .MuiTableCell-root": {
                      borderBottom: "1px solid rgba(220, 223, 227, 0.50)"
                    }
                  }}
                >
                  <TableCell>{value.counsilorNote}</TableCell>
                  <TableCell>{value.noteDate}</TableCell>
                  <TableCell>{row.firstName}</TableCell>
                  <TableCell>{value.CreatedBy}</TableCell>
                  {/* <TableCell sx={{ color: "blue" }}>{row.related}</TableCell> */}
                  {/* <TableCell>{row.counsellor}</TableCell>
<TableCell>{row.taskOwner}</TableCell> */}
                </TableRow>
              ))
            )}
          </TableBody>
        )}
      </Table>
    </Box>
  );
}

export default NoteTable;
