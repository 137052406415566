import { Box, Container, Grid, Paper, Typography } from "@mui/material";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { activityStatusChangeAction } from "../../../../actions/leadActions/leadFollowUpActions";
import { OutLinedButton, SubmitButton } from "../../../customComponent/Buttons";

import dayjs from "dayjs";
import ActivitiesEditModal from "../activities/ActivitiesEditModal";

function TaskInformation({ followUpDetails, counsellorName, removeButtons }) {
  const dispatch = useDispatch();

  console.log(followUpDetails,"followUpDetails");

  const [showModal, setShowModal] = useState(false);
  const handleClose = () => {
    setShowModal(false);
  };

  return (
    <>
      {showModal && (
        <ActivitiesEditModal
          open={showModal}
          handleClose={handleClose}
          followUpDetails={followUpDetails}
        />
      )}
      <Paper
        elevation={0}
        sx={{
          marginLeft: "10px",
          height: "auto",
          border: "1px solid #ececec",
        }}
      >
        {!removeButtons && (
          <Typography
            variant="h6"
            sx={{
              m: 2,
              fontWeight: 680,
              fontFamily: "'Montserrat', sans-serif",
              lineHeight: "23.5px",
            }}
          >
            {followUpDetails?.activityType === "task"
              ? "Task Information"
              : followUpDetails?.activityType === "meet"
              ? "Meeting Information"
              : followUpDetails?.activityType === "call"
              ? "Call Information"
              : ""}
          </Typography>
        )}
        
        <Box>
          <Container sx={{ m: "10px" }} maxWidth="lg">
            <Grid container spacing={2}>
              <Grid item xs={12} md={4} lg={4}>
                <Typography
                  style={{
                    fontFamily: "'Open Sans', sans-serif",
                    fontWeight: 400,
                    fontSize: "12px",
                    color: "rgba(172, 177, 198, 1)",
                  }}
                >
                  Task Owner
                </Typography>
                <Typography
                  style={{
                    fontFamily: "'Open Sans', sans-serif",
                    fontWeight: 400,
                    fontSize: "15px",
                  }}
                >
                  {followUpDetails?.owner}
                </Typography>
              </Grid>

              <Grid item xs={12} md={4} lg={4}>
                <Typography
                  style={{
                    fontFamily: "'Open Sans', sans-serif",
                    fontWeight: 400,
                    fontSize: "12px",
                    color: "rgba(172, 177, 198, 1)",
                  }}
                >
                  Subject
                </Typography>
                <Typography
                  style={{
                    fontFamily: "'Open Sans', sans-serif",
                    fontWeight: 400,
                    fontSize: "15px",
                  }}
                >
                  {followUpDetails?.subject}
                </Typography>
              </Grid>
            </Grid>

            <Grid container spacing={2} mt={2}>
              <Grid item xs={12} md={4} lg={4}>
                <Typography
                  style={{
                    fontFamily: "'Open Sans', sans-serif",
                    fontWeight: 400,
                    fontSize: "12px",
                    color: "rgba(172, 177, 198, 1)",
                  }}
                >
                  Counsellor
                </Typography>
                <Typography
                  style={{
                    fontFamily: "'Open Sans', sans-serif",
                    fontWeight: 400,
                    fontSize: "15px",
                  }}
                >
                  {counsellorName}
                  {/* {leadDetail?.counsilorID ? leadDetail?.counsilorID : "-"} */}
                </Typography>
              </Grid>
              <Grid item xs={12} md={4} lg={4}>
                <Typography
                  style={{
                    fontFamily: "'Open Sans', sans-serif",
                    fontWeight: 400,
                    fontSize: "12px",
                    color: "rgba(172, 177, 198, 1)",
                  }}
                >
                  {followUpDetails?.activityType === "meet"
                    ? "From "
                    : followUpDetails?.activityType === "call"
                    ? "Start time"
                    : followUpDetails?.activityType === "task"
                    ? "Due Date"
                    : ""}
                </Typography>
                <Typography
                  style={{
                    fontFamily: "'Open Sans', sans-serif",
                    fontWeight: 400,
                    fontSize: "15px",
                  }}
                >
                  {followUpDetails?.activityType === "meet"
                    ? dayjs(followUpDetails?.from).format("h:mm A")
                    : followUpDetails?.activityType === "call"
                    ? dayjs(followUpDetails?.from).format("h:mm A")
                    : followUpDetails?.activityType === "task"
                    ? followUpDetails.dueData
                    : ""}
                </Typography>
              </Grid>
              <Grid item xs={12} md={4} lg={4}>
                <Typography
                  style={{
                    fontFamily: "'Open Sans', sans-serif",
                    fontWeight: 400,
                    fontSize: "12px",
                    color: "rgba(172, 177, 198, 1)",
                    display: "flex",
                    justifyContent: "flex-start",
                  }}
                >
                  Lead
                </Typography>
                <Typography
                  style={{
                    fontFamily: "'Open Sans', sans-serif",
                    fontWeight: 400,
                    fontSize: "15px",
                  }}
                >
                  {followUpDetails?.lead?.firstName}
                </Typography>
              </Grid>
            </Grid>

            <Grid container spacing={2} mt={2}>
              <Grid item xs={12} md={4} lg={4}>
                <Typography
                  style={{
                    fontFamily: "'Open Sans', sans-serif",
                    fontWeight: 400,
                    fontSize: "12px",
                    color: "rgba(172, 177, 198, 1)",
                  }}
                >
                  Related to
                </Typography>
                <Typography
                  style={{
                    fontFamily: "'Open Sans', sans-serif",
                    fontWeight: 400,
                    fontSize: "15px",
                  }}
                >
                  {followUpDetails?.activityType === "meet"
                    ? followUpDetails?.reminder
                    : "-"}
                </Typography>
              </Grid>
              <Grid item xs={12} md={4} lg={4}>
                <Typography
                  style={{
                    fontFamily: "'Open Sans', sans-serif",
                    fontWeight: 400,
                    fontSize: "12px",
                    color: "rgba(172, 177, 198, 1)",
                  }}
                >
                  Status
                </Typography>
                <Typography
                  style={{
                    fontFamily: "'Open Sans', sans-serif",
                    fontWeight: 400,
                    fontSize: "15px",
                  }}
                >
                  {followUpDetails?.status}
                </Typography>
              </Grid>
              <Grid item xs={12} md={4} lg={4}>
                <Typography
                  style={{
                    fontFamily: "'Open Sans', sans-serif",
                    fontWeight: 400,
                    fontSize: "12px",
                    color: "rgba(172, 177, 198, 1)",
                    display: "flex",
                    justifyContent: "flex-start",
                  }}
                >
                  Priority
                </Typography>
                <Typography
                  style={{
                    fontFamily: "'Open Sans', sans-serif",
                    fontWeight: 400,
                    fontSize: "15px",
                  }}
                >
                  {followUpDetails?.priority ? followUpDetails?.priority : "-"}
                </Typography>
              </Grid>
            </Grid>
            {/* </div> */}
          </Container>

          {!removeButtons && (
            <Box>
              <div
                style={{ display: "flex", justifyContent: "flex-end", mt: 5 }}
              >
                <div style={{ marginTop: "20px" }}>
                  <OutLinedButton
                    title="Close"
                    handleClick={() =>
                      dispatch(activityStatusChangeAction(followUpDetails?.id))
                    }
                    widthSize="75px"
                    heightSize="38px"
                  />
                </div>
                <div style={{ margin: "20px" }}>
                  <SubmitButton
                    title="Edit"
                    submit="click"
                    widthSize="75px"
                    heightSize="38px"
                    type="click"
                    handleSubmit={() => setShowModal(true)}
                  />
                </div>
              </div>
            </Box>
          )}
        </Box>
        {/* <Box sx={{}}>
        
      </Box> */}
      </Paper>
    </>
  );
}

export default TaskInformation;
