import React, { useEffect, useState } from "react";
import { Alert, AlertTitle, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import usePrivilegeCheck from "../../../constants/customHooks/UsePrivilegeCheck";
import DownloadExcel from "../../extraComponents/DownloadExcel";
import DownloadPdf from "../../extraComponents/DownloadPdf";
import { ieltsTeacherManagmentFindAction } from "../../../actions/IeltsActions/teacherManagmentAction";
import WithDrawalSearchHeader from "./WithDrawalSearchHeader";
import WithDrawalFilterSearch from "./WithDrawalFilterSearch";
import WithDrawalTable from "./WithDrawalTable";
import { referralsFindAction } from "../../../actions/referAndEarnAction";
import { getwithdrawsAction } from "../../../actions/referAndEarn/referAndEarnActions";

function WithDrawalMain(props) {
  const columns = [
    {
      title: "SL NO",
      field: "sl",
    },
    {
      title: "Name",
      field: "name",
      // hidden: showNameColumn,
    },
    {
      title: "Date",
      field: "date",
      // hidden: showDateColumn,
    },
    {
      title: "Amount",
      field: "amount",
      // hidden: showMobileColumn,
    },
    {
      title: "bank name",
      field: "bankName",
      // hidden: showCenterColumn,
    },
    {
      title: "account holder",
      field: "accountHolder",
      // hidden: showSpecilizationColumn,
    },
    {
      title: "account number",
      field: "accountNumber",
      // hidden: showStatusColumn,
    },
    {
      title: "ifsc number",
      field: "ifscNumber",
      // hidden: showStatusColumn,
    },
    {
      title: "STATUS",
      field: "STATUS",
      // hidden: showStatusColumn,
    },
  ];

  const data = [
    // {
    //   name: "Shabeer Muhammed",
    //   date: "2024-01-05T06:53:20.000Z",
    //   amount: "200",
    //   bankName: "Bank of Briton",
    //   accountHolder: "Deric Abraham",
    //   accountNumber: "67774878383",
    //   ifscNumber: "1123",
    //   status: "Pending",
    // },
    {
      name: "Shabeer Muhammed",
      date: "2024-01-05T06:53:20.000Z",
      amount: "200",
      bankName: "Bank of Briton",
      accountHolder: "Deric Abraham",
      accountNumber: "67774878383",
      ifscNumber: "1123",
      status: "Pending",
    },
    {
      name: "Shabeer Muhammed",
      date: "2024-01-05T06:53:20.000Z",
      amount: "200",
      bankName: "Bank of Briton",
      accountHolder: "Deric Abraham",
      accountNumber: "67774878383",
      ifscNumber: "1123",
      status: "Pending",
    },
    // {
    //   name: "Shabeer Muhammed",
    //   date: "2024-02-05T06:53:20.000Z",
    //   amount: "200",
    //   bankName: "Bank of Briton",
    //   accountHolder: "Deric Abraham",
    //   accountNumber: "67774878383",
    //   ifscNumber: "1123",
    //   status: "Paid",
    // },
  ];

  const dispatch = useDispatch();
  const [filterPassData, setFilterPassData] = useState();
  const [whichFilter, setwhichFilter] = useState();
  const [reset, setReset] = useState(false);
  const [leadData, setLeadData] = useState([]);
  const [withdrawelslist, setWithdrawelList] = useState([]);

  const { privilege, hasPrivilege } = usePrivilegeCheck();

  // Employee column Visibility

  const [showNameColumn, setshowNameColumn] = useState(false);
  const [showDateColumn, setshowDateColumn] = useState(false);
  const [showMailIdColumn, setshowMailIdColumn] = useState(false);
  const [showPhoneNumberColumn, setshowPhoneNumberColumn] = useState(false);
  const [
    showCountryOfResidenceColumn,
    setShowCountryResidenceColumn,
  ] = useState(false);
  const [showReferredByColumn, setshowReferredByColumn] = useState(false);
  const [showStatusColumn, setShowStatusColumn] = useState(false);

  const [columnName, setColumnName] = useState("");
  const [leadId, setLeadId] = useState([]);
  const [ids, setIds] = useState([]);

  // Excel export function
  const excelDownloadClick = () => {
    let columnName = columns.filter((value) => value.hidden == false);
    const data = withdrawelslist.map((value, index) => ({
      sl: columns[index].hidden ? false : index + 1,
      coloumn1: columns[index].hidden ? false : value.teacherName,
      coloumn2: columns[index].hidden ? false : value.teacherEmail,
      coloumn3: columns[index].hidden ? false : value.teacherPhone,
      coloumn4: columns[index].hidden ? false : value.center,
      coloumn5: columns[index].hidden ? false : value.teacherSpecialization,
      coloumn6: columns[index].hidden ? false : value.status,
    }));

    DownloadExcel(columnName, data, "EntrolledTeacherList");
  };

  // Pdf Export function

  const pdfDownloadClick = () => {
    let columnName = columns.filter((value) => value.hidden == false);
    DownloadPdf(
      columnName,
      withdrawelslist,
      "Entrolled Teacher List",
      "Entrolled Teacher List"
    );
  };

  const toggleNameColumn = (status, column) => {
    if (column === "name") {
      setColumnName(column);
      setshowNameColumn(status);
    }

    if (column === "mailId") {
      setColumnName(column);
      setshowDateColumn(status);
    }
    if (column === "mobile") {
      setColumnName(column);
      setshowMailIdColumn(status);
    }
    if (column === "center") {
      setColumnName(column);
      setshowPhoneNumberColumn(status);
    }
    if (column === "specilization") {
      setColumnName(column);
      setShowCountryResidenceColumn(status);
    }
    if (column === "status") {
      setColumnName(column);
      setShowStatusColumn(status);
    }
  };

  useEffect(() => {
    // Retrieve column visibility state from localStorage
    const storedteacherName = localStorage.getItem("teacherName");
    const storedMail = localStorage.getItem("mailId");
    const storedMobile = localStorage.getItem("mobile");
    const storedCenter = localStorage.getItem("center");
    const storedSpecilization = localStorage.getItem("specilization");
    const storedStatus = localStorage.getItem("status");

    if (storedteacherName !== null) {
      setColumnName("teacherName");
      setshowNameColumn(JSON.parse(storedteacherName));
    }

    if (storedMail !== null) {
      setColumnName("mailId");
      setshowDateColumn(JSON.parse(storedMail));
    }
    if (storedMobile !== null) {
      setColumnName("mobile");
      setshowMailIdColumn(JSON.parse(storedMobile));
    }
    if (storedCenter !== null) {
      setColumnName("center");
      setshowPhoneNumberColumn(JSON.parse(storedCenter));
    }
    if (storedSpecilization !== null) {
      setColumnName("specilization");
      setShowCountryResidenceColumn(JSON.parse(storedSpecilization));
    }
    if (storedStatus !== null) {
      setColumnName("status");
      setShowStatusColumn(JSON.parse(storedStatus));
    }
  }, []);

  useEffect(() => {
    // Save column visibility state to localStorage
    localStorage.setItem("teacherName", JSON.stringify(showNameColumn));
    localStorage.setItem("mailId", JSON.stringify(showDateColumn));
    localStorage.setItem("mobile", JSON.stringify(showMailIdColumn));
    localStorage.setItem("center", JSON.stringify(showPhoneNumberColumn));
    localStorage.setItem(
      "specilization",
      JSON.stringify(showCountryOfResidenceColumn)
    );
    localStorage.setItem("status", JSON.stringify(showStatusColumn));
  }, [
    showNameColumn,
    showDateColumn,
    showMailIdColumn,
    showPhoneNumberColumn,
    showCountryOfResidenceColumn,
    showReferredByColumn,
    showStatusColumn,
    columnName,
  ]);

  let { withdrawsFindSuccess } = useSelector((state) => {
    return state.withdrawsFind;
  });

  let { withdrawsStatusChangeSuccess } = useSelector((state) => {
    return state.withdrawsStatusChange;
  });

  // useEffect(() => {
  //   if (withdrawsStatusChangeSuccess) {
  //   }
  // }, [withdrawsStatusChangeSuccess]);

  useEffect(() => {
    dispatch(getwithdrawsAction());
  }, [dispatch, withdrawsStatusChangeSuccess]);

  useEffect(() => {
    if (withdrawsFindSuccess) {
      setWithdrawelList(withdrawsFindSuccess.data);
    }
  }, [withdrawsFindSuccess, reset]);

  // Handle SearchFunction

  const searchHandleChange = (value) => {
    if (value) {
      setWithdrawelList(
        withdrawelslist?.filter((item) => {
          const bankMatch = item.BankName
            ? item.BankName.toLowerCase().includes(value.toLowerCase())
            : false;
          const nameMatch = item.referredByname
            .toLowerCase()
            .includes(value.toLowerCase());
          return bankMatch || nameMatch;
        })
      );
    } else {
      setWithdrawelList(withdrawsFindSuccess?.data);
    }
  };

  const setIdsFunction = (leadsId) => {
    setLeadId(leadsId);
  };

  const selectBoxIdPassHandler = (ids) => {
    setIds(ids);
  };

  if (!hasPrivilege("withdrawal") && privilege !== null) {
    return (
      <Alert severity="error">
        <AlertTitle>warning</AlertTitle>
        You don't have permission to access — <strong>this page.!</strong>
      </Alert>
    );
  }

  // handleFilter Data

  const LeadFilterFunction = (name, filterOption, checkedStatus) => {
    if (filterOption === "status") {
      if (checkedStatus === true) {
        const filterData = withdrawsFindSuccess?.data?.filter(
          (value) => name == value.status
        );
        setWithdrawelList(filterData);
        setwhichFilter(filterOption);
        setFilterPassData(name);
      } else {
        setFilterPassData(null);
        setwhichFilter(null);
        setWithdrawelList(withdrawsFindSuccess?.data);
      }
    }

    if (filterOption === "Date") {
      const date = new Date(name);
      const adjustedDate = new Date(
        date.getTime() - date.getTimezoneOffset() * 60000
      );
      const formattedDate = adjustedDate.toISOString().split("T")[0];

      const filterData = withdrawsFindSuccess?.data?.filter(
        (value) => formattedDate == value?.createdAt?.split("T")[0]
      );

      setWithdrawelList(filterData);
    }
  };

  // handleResetData

  const handleResetData = (status) => {
    setwhichFilter(null);
    setFilterPassData(null);
    setReset(!reset);
    setwhichFilter(null);
    setFilterPassData(null);
    setWithdrawelList(withdrawsFindSuccess?.data);
  };

  return (
    <>
      <WithDrawalSearchHeader
        columnVisibleHandler={toggleNameColumn}
        excelDownloadClick={excelDownloadClick}
        pdfDownloadClick={pdfDownloadClick}
        showNameColumnColumn={showNameColumn}
        showDateColumn={showDateColumn}
        showPhoneNumberColumn={showPhoneNumberColumn}
        showMailIdColumn={showMailIdColumn}
        showCountryOfResidenceColumn={showCountryOfResidenceColumn}
        showReferredByColumn={showReferredByColumn}
        showStatusColumn={showStatusColumn}
        handleResetData={handleResetData}
        searchHandleChange={searchHandleChange}
        ids={ids}
        leadId={leadId}
      />
      {/* <div style={{display:"flex",justifyContent:"space-evenly", width:"auto"}}> */}
      <Grid container spacing={-3} sx={{ mt: "-31px" }}>
        <Grid item xs={12} md={2} lg={2.2}>
          <WithDrawalFilterSearch
            LeadFilterFunction={LeadFilterFunction}
            handleResetData={handleResetData}
          />
        </Grid>
        <Grid item xs={12} md={10} lg={9.8}>
          <WithDrawalTable
            toggle={props.toggle}
            showNameColumnColumn={showNameColumn}
            showDateColumn={showDateColumn}
            showPhoneNumberColumn={showPhoneNumberColumn}
            showMailIdColumn={showMailIdColumn}
            showCountryOfResidenceColumn={showCountryOfResidenceColumn}
            showReferredByColumn={showReferredByColumn}
            showStatusColumn={showStatusColumn}
            reset={reset}
            setIdsFunction={setIdsFunction}
            data={withdrawelslist}
            // leadFindLoading={leadFindLoading}
            selectBoxIdPassHandler={selectBoxIdPassHandler}
            // isExistCounsilor={loginFindSuccess}
            component="EmployeeJob"
            columns={columns}
            subMenu="withdrawal"
          />
        </Grid>
      </Grid>
    </>
  );
}

export default WithDrawalMain;
