import React, { useEffect, useState } from "react";
import { MainContainer } from "../content/content.element";
import {
  Box,
  Button,
  FormControl,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import KeyboardBackspace from "@mui/icons-material/KeyboardBackspace";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { SelectinputFieldCustom } from "../customComponent/InputField";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useDispatch } from "react-redux";
import { sceduleMeetingManagSlotAction } from "../../actions/ticket/sceduleMeeting";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

function ManageSlot(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [value, setValue] = useState(dayjs(new Date()));
  const [slot, setSlot] = useState({
    starttime: "",
    endTime: "",
  });

  const [formData, setFormData] = useState({
    date: "",
    selectedSlots: [],
  });

  const [selectedSlot, setSelectedSlots] = useState([]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSlot((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmitedSlot = () => {
    if (slot.starttime && slot.endTime) {
      let slotValue = {
        startTime: slot.starttime,
        endTime: slot.endTime,
      };
      setSelectedSlots((prev) => [...prev, slotValue]);
      setTimeSlot(
        timeSlots.filter(
          (slots) => slots !== slot.starttime
          // (slots) => slots !== slot.starttime && slots !== slot.endTime
        )
      );
      setSlot({
        starttime: "",
        endTime: "",
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please select a slot",
      });
    }
  };

  const deleteSelectedSlot = (index) => {
    let filteredItem = selectedSlot[index];
    let arr1 = [...timeSlots];
    arr1.push(filteredItem.startTime);
    let arr2 = times.filter((item) => {
      return arr1.includes(item);
    });
    setSelectedSlots(() =>
      selectedSlot.filter((item) => item !== filteredItem)
    );
    setTimeSlot(arr2);
  };

  let isUserExist = localStorage.getItem("loginInfo")
    ? JSON.parse(localStorage.getItem("loginInfo"))
    : null;

  const manageSlotSubmit = () => {
    if (selectedSlot.length > 0) {
      let data = {
        date: value,
        selectedSlots: selectedSlot,
        counsilorId: isUserExist?.UserId,
      };
      setFormData((prev) => ({
        ...prev,
        data,
      }));
      // console.log(data);
      dispatch(sceduleMeetingManagSlotAction(data));
      setSelectedSlots([]);
      Swal.fire({
        title: "Good job!",
        text: "slot added successFully ",
        icon: "success",
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please select a slot",
      });
    }
  };

  const [times, setTime] = useState([]);

  const [timeSlots, setTimeSlot] = useState([]);

  const generateTimeIntervals = (date) => {
    const intervals = [];
    const currentDate = new Date();
    const selectedDateTime = new Date(date);

    const isToday =
      currentDate?.toDateString() === selectedDateTime?.toDateString();

    const startTime = isToday ? currentDate : selectedDateTime;
    startTime?.setSeconds(0, 0);

    const startHour = isToday ? startTime.getHours() + 1 : 0;

    for (let hour = startHour; hour < 24; hour++) {
      for (let minute = 0; minute < 60; minute += 30) {
        const time = new Date(startTime);
        time.setHours(hour, minute);
        intervals.push(
          time.toLocaleTimeString("en-US", {
            hour12: true,
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
          })
        );
      }
    }
    setTime(intervals);
    setTimeSlot(intervals);
    console.log(date,"Time intervals");
  };

  useEffect(() => {
    generateTimeIntervals(new Date());
  }, []);

  return (
    <MainContainer active={props.toggle}>
      <Box
        sx={{
          borderBottom: "1px solid rgba(236, 236, 236, 1)",
          mb: "20px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            fontWeight: "700",
            fontSize: "24px",
            pt: "10px",
            pb: "10px",
            // borderBottom: "1px solid rgba(236, 236, 236, 1)",
            width: "fit-content",
            cursor: "pointer",
          }}
          onClick={() => navigate(-1)}
        >
          <KeyboardBackspace />
          Manage Slot
        </Box>
      </Box>

      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "417px 1fr",
          gap: "20px",
        }}
      >
        <Box
          sx={{
            border: "1px solid rgba(236, 236, 236, 1)",
            borderRadius: "5px",
            padding: "30px",
          }}
        >
          <Box
            sx={{
              width: "100%",
            }}
          >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateCalendar
                disablePast={true}
                sx={{
                  maxHeight: "100%",
                  height: "100%",
                  ".MuiPickersDay-root.Mui-selected": {
                    backgroundColor: "#fff",
                    color: "#FF1B6F",
                    fontWeight: "700",
                  },
                  ".MuiDayCalendar-weekDayLabel": {
                    borderColor: "#141E3C",
                    border: "1px solid",
                    backgroundColor: "#fff !important",
                    margin: "5px",
                    fontSize: "15px",
                    fontWeight: "700",
                    color: "#141E3C",
                    borderRadius: 2,
                    width: "38px",
                    height: "38px",
                  },
                  ".MuiDayCalendar-header .MuiDayCalendar-weekDayLabel::first-of-type":
                    {
                      color: "#FF0000",
                    },
                  ".MuiDayCalendar-weekContainer": {
                    margin: "0px",
                  },
                  ".MuiPickersDay-root": {
                    color: "#AFAFAF",
                    borderRadius: 2,
                    borderWidth: 2,
                    borderColor: "#AFAFAF",
                    border: "1px solid",
                    backgroundColor: "#fff !important",
                    margin: "5px",
                    opacity: 1,
                    fontSize: "15px",
                    fontWeight: "600",
                    width: "38px",
                    height: "38px",
                  },
                }}
                defaultValue={value}
                onChange={(e) => {
                  setSelectedSlots([]);
                  generateTimeIntervals(e.$d)
                  setSlot({
                    starttime: "",
                    endTime: "",
                  });
                  setValue(e.$d);
                }}
              />
            </LocalizationProvider>
          </Box>
        </Box>

        <Box
          sx={{
            border: "1px solid rgba(236, 236, 236, 1)",
            borderRadius: "5px",
            padding: "30px",
            display: "flex",
            flexDirection: "column",
            gap: "30px",
          }}
        >
          <Typography
            component={"h2"}
            sx={{
              fontWeight: "400",
              fontSize: "20px",
              lineHeight: "24px",
            }}
          >
            Add Time Slot
          </Typography>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "1fr 22px 1fr 56px",
              gap: "20px",
              maxWidth: "750px",
            }}
          >
            <FormControl>
              <Select
                name="starttime"
                value={slot.starttime}
                IconComponent={() => <KeyboardArrowDownIcon />}
                onChange={handleChange}
                sx={{
                  pr: "10px",
                }}
              >
                {timeSlots.map((item) => (
                  <MenuItem value={item}>{item}</MenuItem>
                ))}
              </Select>
            </FormControl>

            <Typography
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              To
            </Typography>

            <FormControl>
              <Select
                value={slot.endTime}
                name="endTime"
                IconComponent={() => <KeyboardArrowDownIcon />}
                onChange={handleChange}
                sx={{
                  pr: "10px",
                }}
              >
                {timeSlots.map((item) => (
                  <MenuItem value={item}>{item}</MenuItem>
                ))}
              </Select>
            </FormControl>

            <Button
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "#fff",
                background: "rgba(254, 11, 122, 1)",
                "&:hover": {
                  background: "rgba(254, 11, 122, 1)",
                },
              }}
              onClick={handleSubmitedSlot}
            >
              <AddIcon />
            </Button>
          </Box>

          <Box
            sx={{
              display: "flex",
              gap: "20px",
              flexWrap: "wrap",
              mt: "20px",
            }}
          >
            {selectedSlot?.map((item, i) => (
              <Box
                key={i}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "16px",
                  border: "1px solid rgba(254, 11, 122, 1)",
                  position: "relative",
                  borderRadius: "5px",
                  color: "rgba(254, 11, 122, 1)",
                }}
              >
                <Box
                  sx={{
                    background: "rgba(254, 11, 122, 1)",
                    color: "#fff",
                    width: "20px",
                    height: "20px",
                    borderRadius: "50%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    position: "absolute",
                    right: "-10px",
                    top: "-10px",
                    cursor: "pointer",
                  }}
                  onClick={() => deleteSelectedSlot(i)}
                >
                  <CloseIcon sx={{ fontSize: "12px" }} />
                </Box>
                {item.startTime + " To " + item.endTime}
              </Box>
            ))}
          </Box>
        </Box>
      </Box>

      <Box sx={{ display: "flex", justifyContent: "end", mt: "20px" }}>
        <Button
          sx={{
            background: "rgba(20, 30, 60, 1)",
            color: "#fff",
            "&:hover": {
              background: "rgba(20, 30, 60, 1)",
            },
          }}
          onClick={manageSlotSubmit}
        >
          Save
        </Button>
      </Box>
    </MainContainer>
  );
}

export default ManageSlot;
