import React, { useEffect, useState } from "react";
import { Alert, AlertTitle, Grid } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";

import { LandingTable } from "../LandingTable";
import usePrivilegeCheck from "../../../constants/customHooks/UsePrivilegeCheck";

import {
  findLandingBlogsAction,
  findLandingTestimonialAction,
} from "../../../actions/LandingPageAction";

function TestimonialsMain(props) {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [testimonialList, setTestimonialList] = useState([]);
  const { privilege, hasPrivilege } = usePrivilegeCheck();

  let { testimonialCreateSuccess } = useSelector((state) => {
    return state.testimonial;
  });

  let { testimonialDeleteSuccess } = useSelector((state) => {
    return state.testimonialsDelete;
  });

  let { testimonialFindSuccess } = useSelector((state) => {
    return state.findLandingTestimonial;
  });

  useEffect(() => {
    if (testimonialFindSuccess) {
      setTestimonialList(testimonialFindSuccess);
    }
  }, [testimonialFindSuccess]);

  useEffect(() => {
    dispatch(findLandingTestimonialAction());
  }, [dispatch, testimonialCreateSuccess, testimonialDeleteSuccess]);

  useEffect(() => {
    setData(
      testimonialList.map((testimonial, index) => ({
        sl: index + 1,
        id: testimonial.id,
        coloum1: testimonial.imageUrl || "-",
        coloum2: testimonial.name || "-",
        coloum3: testimonial.university || "-",
        coloum4: "image",
        coloum5: testimonial.content || "-",
      }))
    );
  }, [testimonialList]);

  let columns = [
    {
      title: "SL NO",
      field: "SL NO",
    },
    {
      title: "Picture",
      field: "Image",
    },
    {
      title: "Name",
      field: "title",
    },
    {
      title: "University",
      field: "University",
    },
    {
      title: "Content",
      field: "Content",
    },
    {
      title: "",
      field: "action",
      filtering: false,
    },
  ];

  // Employee column Visibility
  const [showNameColumn, setShowNameColumn] = useState(false);
  const [showPhoneColumn, setShowPhoneColumn] = useState(false);
  const [showPrefferedColumn, setShowPrefferedColumn] = useState(false);
  const [showLeadSourceColumn, setShowLeadSourceColumn] = useState(false);
  const [showLeadTypeColumn, setShowLeadTypeColumn] = useState(false);
  const [showStatusColumn, setShowStatusColumn] = useState(false);
  const [showEducationColumn, setShowEducationColumn] = useState(false);
  const [showUniversityPlaceColumn, setShowUniversityPlaceColumn] =
    useState(false);

  const [columnName, setColumnName] = useState("");
  const [leadId, setLeadId] = useState([]);
  const [ids, setIds] = useState([]);

  useEffect(() => {
    // Retrieve column visibility state from localStorage
    const storedRollNameColumn = localStorage.getItem("name");
    const storedPhoneColumn = localStorage.getItem("mobile");
    const storedPrefferedCountryColumn =
      localStorage.getItem("prefferedCountry");
    const storedLeadSourceColumn = localStorage.getItem("leadSource");
    const storedLeadTypeColumn = localStorage.getItem("leadType");
    const storedStatusColumn = localStorage.getItem("status");
    const storedEducationColumn = localStorage.getItem("education");
    const storedUniversityTypeColumn = localStorage.getItem("universityPlace");

    if (storedRollNameColumn !== null) {
      setColumnName("name");
      setShowNameColumn(JSON.parse(storedRollNameColumn));
    }

    if (storedPhoneColumn !== null) {
      setColumnName("mobile");
      setShowPhoneColumn(JSON.parse(storedPhoneColumn));
    }
    if (storedPrefferedCountryColumn !== null) {
      setColumnName("prefferedCountry");
      setShowPrefferedColumn(JSON.parse(storedPrefferedCountryColumn));
    }
    if (storedLeadSourceColumn !== null) {
      setColumnName("leadSource");
      setShowLeadSourceColumn(JSON.parse(storedLeadSourceColumn));
    }

    if (storedLeadTypeColumn !== null) {
      setColumnName("leadType");
      setShowLeadTypeColumn(JSON.parse(storedLeadTypeColumn));
    }
    if (storedStatusColumn !== null) {
      setColumnName("status");
      setShowStatusColumn(JSON.parse(storedStatusColumn));
    }

    if (storedEducationColumn !== null) {
      setColumnName("education");
      setShowEducationColumn(JSON.parse(storedEducationColumn));
    }

    if (storedUniversityTypeColumn !== null) {
      setColumnName("universityPlace");
      setShowUniversityPlaceColumn(JSON.parse(storedUniversityTypeColumn));
    }
  }, []);

  useEffect(() => {
    // Save column visibility state to localStorage
    localStorage.setItem("name", JSON.stringify(showNameColumn));
    localStorage.setItem("email", JSON.stringify(showPrefferedColumn));
    localStorage.setItem("mobile", JSON.stringify(showPhoneColumn));
    localStorage.setItem(
      "prefferedCountry",
      JSON.stringify(showPrefferedColumn)
    );
    localStorage.setItem("leadSource", JSON.stringify(showLeadSourceColumn));
    localStorage.setItem("leadType", JSON.stringify(showLeadTypeColumn));
    localStorage.setItem("status", JSON.stringify(showStatusColumn));
    localStorage.setItem("education", JSON.stringify(showEducationColumn));
    localStorage.setItem(
      "universityPlace",
      JSON.stringify(showUniversityPlaceColumn)
    );
  }, [
    showNameColumn,
    showPhoneColumn,
    showPrefferedColumn,
    showLeadSourceColumn,
    showLeadTypeColumn,
    showStatusColumn,
    showEducationColumn,
    showUniversityPlaceColumn,
    columnName,
  ]);

  const setIdsFunction = (leadsId) => {
    setLeadId(leadsId);
  };

  const selectBoxIdPassHandler = (ids) => {
    setIds(ids);
  };

  if (!hasPrivilege("testmonials") && privilege !== null) {
    return (
      <Alert severity="error">
        <AlertTitle>warning</AlertTitle>
        You don't have permission to access — <strong>this page.!</strong>
      </Alert>
    );
  }

  
  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12} md={12}>
          <LandingTable
            toggle={props.toggle}
            showNameColumn={showNameColumn}
            showPrefferedColumn={showPrefferedColumn}
            showPhoneColumn={showPhoneColumn}
            showLeadSourceColumn={showLeadSourceColumn}
            showLeadTypeColumn={showLeadTypeColumn}
            showStatusColumn={showStatusColumn}
            showEducationColumn={showEducationColumn}
            showUniversityPlaceColumn={showUniversityPlaceColumn}
            // reset={reset}
            setIdsFunction={setIdsFunction}
            data={data}
            columns={columns}
            // leadFindLoading={leadFindLoading}
            selectBoxIdPassHandler={selectBoxIdPassHandler}
            // isExistCounsilor={loginFindSuccess}
            component="testimonialsJob"
          />
        </Grid>
      </Grid>
    </>
  );
}

export default TestimonialsMain;
