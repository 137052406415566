import {
  COMMON_DOC_ADD_ERR,
  COMMON_DOC_ADD_REQUEST,
  COMMON_DOC_ADD_SUCCESS,
  COMMON_DOC_DELETE_ERR,
  COMMON_DOC_DELETE_REQUEST,
  COMMON_DOC_DELETE_SUCCESS,
  COMMON_DOC_FIND_ERR,
  COMMON_DOC_FIND_REQUEST,
  COMMON_DOC_FIND_SUCCESS,
  COMMON_DOC_IS_FRON_BACK_UPDATE_ERR,
  COMMON_DOC_IS_FRON_BACK_UPDATE_REQUEST,
  COMMON_DOC_IS_FRON_BACK_UPDATE_SUCCESS,
  COMMON_DOC_IS_MANDATORY_UPDATE_ERR,
  COMMON_DOC_IS_MANDATORY_UPDATE_REQUEST,
  COMMON_DOC_IS_MANDATORY_UPDATE_SUCCESS,
} from "../../constants/knowledgeBankConstant/commonDocsConstant";

// CommonDocumentApplicationAddReducer
export const CommonDocumentApplicationAddReducer = (state = {}, action) => {
  switch (action.type) {
    case COMMON_DOC_ADD_REQUEST:
      return {
        ...state,
        commonDocAddLoading: true,
      };
    case COMMON_DOC_ADD_SUCCESS:
      return {
        ...state,
        commonDocAddLoading: false,
        commonDocAddSuccess: action.payload,
      };
    case COMMON_DOC_ADD_ERR:
      return {
        ...state,
        commonDocAddLoading: false,
        commonDocAddError: action.payload,
      };
    default:
      return state;
  }
};

// CommonDocumentApplicationFindReducer
export const CommonDocumentApplicationFindReducer = (state = {}, action) => {
  switch (action.type) {
    case COMMON_DOC_FIND_REQUEST:
      return {
        ...state,
        commonDocFindLoading: true,
      };
    case COMMON_DOC_FIND_SUCCESS:
      return {
        ...state,
        commonDocFindLoading: false,
        commonDocFindSuccess: action.payload,
      };
    case COMMON_DOC_FIND_ERR:
      return {
        ...state,
        commonDocFindLoading: false,
        commonDocFindError: action.payload,
      };
    default:
      return state;
  }
};

// updateIsFrontReducer
export const updateIsFrontReducer = (state = {}, action) => {
  switch (action.type) {
    case COMMON_DOC_IS_FRON_BACK_UPDATE_REQUEST:
      return {
        ...state,
        commonDocUpdateIsFrontLoading: true,
      };
    case COMMON_DOC_IS_FRON_BACK_UPDATE_SUCCESS:
      return {
        ...state,
        commonDocUpdateIsFrontLoading: false,
        commonDocUpdateIsFrontSuccess: action.payload,
      };
    case COMMON_DOC_IS_FRON_BACK_UPDATE_ERR:
      return {
        ...state,
        commonDocUpdateIsFrontLoading: false,
        commonDocUpdateIsFrontError: action.payload,
      };
    default:
      return state;
  }
};

// updateIsMandataryReducer
export const updateIsMandatoryReducer = (state = {}, action) => {
  switch (action.type) {
    case COMMON_DOC_IS_MANDATORY_UPDATE_REQUEST:
      return {
        ...state,
        commonDocUpdateIsMandatoryLoading: true,
      };
    case COMMON_DOC_IS_MANDATORY_UPDATE_SUCCESS:
      return {
        ...state,
        commonDocUpdateIsMandatoryLoading: false,
        commonDocUpdateIsMandatorySuccess: action.payload,
      };
    case COMMON_DOC_IS_MANDATORY_UPDATE_ERR:
      return {
        ...state,
        commonDocUpdateIsMandatoryLoading: false,
        commonDocUpdateIsMandatoryError: action.payload,
      };
    default:
      return state;
  }
};

// deleteCommonDocReducer
export const deleteCommonDocReducer = (state = {}, action) => {
  switch (action.type) {
    case COMMON_DOC_DELETE_REQUEST:
      return {
        ...state,
        deleteCommonDocLoading: true,
      };
    case COMMON_DOC_DELETE_SUCCESS:
      return {
        ...state,
        deleteCommonDocLoading: false,
        deleteCommonDocSuccess: action.payload,
      };
    case COMMON_DOC_DELETE_ERR:
      return {
        ...state,
        deleteCommonDocLoading: false,
        deleteCommonDocError: action.payload,
      };
    default:
      return state;
  }
};
