import { Box, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import InputField from "../../customComponent/InputField";
import { kycDetailsEditAction } from "../../../actions/partnerAction/partnerAction";
import { useParams } from "react-router-dom";
// import { kycDetailsAddAction } from "../../redux/action/AuthAction";

function AddressForm({ pageNumber, partnerData }) {
  const dispatch = useDispatch();
  const { id } = useParams();

  const [formData, setFormData] = useState({
    id: partnerData?.partnerId ? partnerData?.partnerId : id,
    addressLine1: partnerData?.addressLine1,
    addressLine2: partnerData?.addressLine2,
    city: partnerData?.city,
    state: partnerData?.state,
    country: partnerData?.country,
    postCode: partnerData?.postCode,
    skypeID: partnerData?.skypeID,
    whatsappNumber: partnerData?.whatsappNumber,
    section: "address",
  });

  useEffect(() => {
    if (partnerData) {
      setFormData({
        id: partnerData?.partnerId ? partnerData?.partnerId : id,
        addressLine1: partnerData?.addressLine1,
        addressLine2: partnerData?.addressLine2,
        city: partnerData?.city,
        state: partnerData?.state,
        country: partnerData?.country,
        postCode: partnerData?.postCode,
        skypeID: partnerData?.skypeID,
        whatsappNumber: partnerData?.whatsappNumber,
        section: "address",
      });
    }
  }, [partnerData]);

  const [formErrors, setFormErrors] = useState({});

  const validateForm = () => {
    const errors = {};

    if (!formData.addressLine1) {
      errors.addressLine1 = "Address Line is required";
    }

    if (!formData.city) {
      errors.city = "City is required";
    }

    if (!formData.state) {
      errors.state = "State is required";
    }

    // if (!formData.country) {
    //   errors.country = "Country is required";
    // }

    if (!formData.postCode) {
      errors.postCode = "Post code is required";
    }

    // if (!formData.whatsappNumber) {
    //   errors.whatsappNumber = "Whatsapp number is required";
    // } else {
    //   const phoneRegex = /^[0-9]{10}$/;
    //   if (!phoneRegex.test(formData.whatsappNumber)) {
    //     errors.whatsappNumber = "Invalid Whatsapp number";
    //   }
    // }

    setFormErrors(errors);
    console.log(errors, "errorserrors");
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (validateForm()) {
      console.log("Form submitted:", formData);
      let formDetail = new FormData();
      formDetail.append("formDetail", JSON.stringify(formData));
      dispatch(kycDetailsEditAction(formData.id, formDetail));
      pageNumber(3);
    } else {
      console.log("Form validation failed");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setFormErrors({
      ...formErrors,
      [name]: "",
    });
  };

  return (
    <Box component={"form"} onSubmit={handleSubmit}>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          columnGap: "32px",
          rowGap: "24px",
          mt: "50px",
          "& input":{
            maxWidth:"calc(100% - 10px)",
          }
        }}
      >
        <InputField
          error={Boolean(formErrors.addressLine1)}
          helperText={formErrors.addressLine1}
          required
          handleChange={handleChange}
          name={"addressLine1"}
          label={"Address Line 1"}
          color="#05050F"
          value={formData.addressLine1}
        />

        <InputField
          handleChange={handleChange}
          name="addressLine2"
          label={"Address Line 2"}
          color="#05050F"
          value={formData.addressLine2}
        />

        <InputField
          error={Boolean(formErrors.city)}
          helperText={formErrors.city}
          value={formData.city}
          name="city"
          label={"City"}
          color="#05050F"
          required
          handleChange={handleChange}
        />

        <InputField
          value={formData.state}
          error={Boolean(formErrors.state)}
          helperText={formErrors.state}
          label={"State"}
          required
          name="state"
          color="#05050F"
          handleChange={handleChange}
        />

        <InputField
          value={formData.country}
          error={formErrors.country}
          handleChange={handleChange}
          name={"country"}
          label={"Country"}
          color="#05050F"
        />

        <InputField
          value={formData.postCode}
          error={Boolean(formErrors.postCode)}
          helperText={formErrors.postCode}
          label={"Post Code"}
          name="postCode"
          required
          color="#05050F"
          handleChange={handleChange}
        />

        <InputField
          value={formData.skypeID}
          label={"Skype ID"}
          name="skypeID"
          color="#05050F"
          handleChange={handleChange}
        />

        <InputField
          value={formData.whatsappNumber}
          error={Boolean(formErrors.whatsappNumber)}
          helperText={formErrors.whatsappNumber}
          label={"Whatsapp Number"}
          name="whatsappNumber"
          color="#05050F"
          handleChange={handleChange}
        />
      </Box>
      <Box
        sx={{
          textAlign: "right",
        }}
      >
        <Button
          type="submit"
          sx={{
            padding: "16px 24px",
            borderRadius: "8px",
            fontWeight: "700",
            textTransform: "capitalize",
            fontFamily: "Open Sans  !important",
            border: "1px solid #141E3C !important",
            background: "#141E3C !important",
            color: "#fff !important",
            mt: "82px",
          }}
        >
          Next
        </Button>
      </Box>
    </Box>
  );
}

export default AddressForm;
