import jsPDF from "jspdf";
import "jspdf-autotable";

const DownloadPdf = (columns, data, tableName, titleText) => {
  const doc = new jsPDF();
  let tableRows;

  //   const tableColumnNames = ["Sl no", "Name", "Email", "Mobile", "Role", "Employee ID"]; // Replace with your actual column names

  // Calculate the width of the title and the table to center them
  const titleWidth =
    doc.getStringUnitWidth(titleText) * doc.internal.getFontSize();
  const tableWidth = doc.internal.pageSize.getWidth();
  const xOffset = (tableWidth - titleWidth) / 3;

  // Add the title to the PDF
  doc.text(titleText, xOffset, 12);

  if (tableName == "Hr_Jobvacancies_Managment") {
    tableRows = data.map((item) => [
      item.sl,
      item.coloum1,
      item.coloum2,
      item.coloum3,
      item.coloum4,
      item.coloum5,
      item.coloum6
    ]);
  } else if (tableName == "Ieltsentrolledstudents") {
    tableRows = data.map((item) => [
      item.sl,
      item.coloum1,
      item.coloum2,
      item.coloum3,
      item.coloum4,
      item.coloum5,
      item.coloum6,
      item.coloum7,
      item.coloum8,
      item.coloum9,
      item.coloum10
    ]);
  } else if (tableName == "EntrolledTeacherList") {
    tableRows = data.map((item, index) => [
      index + 1,
      item.teacherName,
      item.teacherEmail,
      item.teacherPhone,
      item.center,
      item.teacherSpecialization,
      item.status
    ]);
  } else if (tableName == "OnlinePurchasedStudentList") {
    tableRows = data.map((item, index) => [
      index + 1,
      item?.ielts_entrolled_student?.fullName,
      item?.ielts_entrolled_student?.dob,
      item?.ielts_entrolled_student?.gender,
      item?.ielts_entrolled_student?.email,
      item?.ielts_entrolled_student?.phoneNumber,
      item?.ielts_entrolled_student?.whatsAppNumber,
      item?.ielts_course?.CourseName,
      item?.ielts_entrolled_student?.status
    ]);
  } else if (tableName == "OfflinePurchasedStudentList") {
    tableRows = data.map((item, index) => [
      index + 1,
      item?.ielts_entrolled_student?.fullName,
      item?.ielts_entrolled_student?.dob,
      item?.ielts_entrolled_student?.gender,
      item?.ielts_entrolled_student?.email,
      item?.ielts_entrolled_student?.phoneNumber,
      item?.ielts_entrolled_student?.whatsAppNumber,
      item?.ielts_course?.CourseName,
      item?.ielts_center?.CenterName,
      item?.payment,
      item?.ielts_teacher?.teacherName,
      item?.ielts_entrolled_student?.status
    ]);
  } else if (tableName == "ALL_TICKETS_TABLE") {
    tableRows = data.map((item, index) => [
      index + 1,
      item?.ielts_entrolled_student?.fullName,
      item?.ielts_entrolled_student?.dob,
      item?.ielts_entrolled_student?.gender,
      item?.ielts_entrolled_student?.email,
      item?.ielts_entrolled_student?.phoneNumber,
      item?.ielts_entrolled_student?.whatsAppNumber,
      item?.ielts_course?.CourseName,
      item?.ielts_center?.CenterName,
      item?.payment,
      item?.ielts_teacher?.teacherName,
      item?.ielts_entrolled_student?.status
    ]);
  } else if (tableName == "Employee_Managment") {
    tableRows = data.map((item, index) => [
      index + 1,
      item?.ielts_entrolled_student?.fullName,
      item?.ielts_entrolled_student?.dob,
      item?.ielts_entrolled_student?.gender,
      item?.ielts_entrolled_student?.email,
      item?.ielts_entrolled_student?.phoneNumber,
      item?.ielts_entrolled_student?.whatsAppNumber,
      item?.ielts_course?.CourseName,
      item?.ielts_center?.CenterName,
      item?.payment,
      item?.ielts_teacher?.teacherName,
      item?.ielts_entrolled_student?.status
    ]);
  } else if (tableName == "sceduledMeeting") {
    tableRows = data.map((item, index) => [
      index + 1,
      item?.name,
      item?.counsilorName,
      item?.sceduledDate,
      item?.sceduledTime,
      item?.status
    ]);
  } else if (tableName == "Role_Managment") {
    tableRows = data.map((item, index) => [
      index + 1,
      item.name,
      item.description,
      item?.bulkID,
      "admin",
      item.lastupdateby
    ]);
  } else {
    tableRows = data.map((item) => [
      item.sl,
      item.name,
      item.description,
      item.updatedby,
      item.lastupdateby
    ]);
  }

  doc.autoTable({
    head: [columns.map((item) => item.title)],
    body: tableRows
  });

  doc.save(tableName);
};

export default DownloadPdf;
