// IELTS CENTER MANAGMENT CENTER CREATE REDUCER

import {
  IELTS_CENTERMANAGMENT_CENTER_CREATE_ERR,
  IELTS_CENTERMANAGMENT_CENTER_CREATE_REQUEST,
  IELTS_CENTERMANAGMENT_CENTER_CREATE_SUCCESS,
  IELTS_CENTERMANAGMENT_CENTER_DELETE_ERR,
  IELTS_CENTERMANAGMENT_CENTER_DELETE_REQUEST,
  IELTS_CENTERMANAGMENT_CENTER_DELETE_SUCCESS,
  IELTS_CENTERMANAGMENT_CENTER_FINDBYID_ERR,
  IELTS_CENTERMANAGMENT_CENTER_FINDBYID_REQUEST,
  IELTS_CENTERMANAGMENT_CENTER_FINDBYID_SUCCESS,
  IELTS_CENTERMANAGMENT_CENTER_FIND_ERR,
  IELTS_CENTERMANAGMENT_CENTER_FIND_REQUEST,
  IELTS_CENTERMANAGMENT_CENTER_FIND_SUCCESS,
  IELTS_CENTERMANAGMENT_CENTER_SLOTUPDATE_ERR,
  IELTS_CENTERMANAGMENT_CENTER_SLOTUPDATE_REQUEST,
  IELTS_CENTERMANAGMENT_CENTER_SLOTUPDATE_SUCCESS,
  IELTS_CENTERMANAGMENT_CENTER_STATUSCHANGE_ERR,
  IELTS_CENTERMANAGMENT_CENTER_STATUSCHANGE_REQUEST,
  IELTS_CENTERMANAGMENT_CENTER_STATUSCHANGE_SUCCESS,
  IELTS_CENTERMANAGMENT_CENTER_TEACHER_DELETE_ERR,
  IELTS_CENTERMANAGMENT_CENTER_TEACHER_DELETE_REQUEST,
  IELTS_CENTERMANAGMENT_CENTER_TEACHER_DELETE_SUCCESS,
  IELTS_CENTER_ADD_TEACHER_MODAL_UPDATE_ERR,
  IELTS_CENTER_ADD_TEACHER_MODAL_UPDATE_REQUEST,
  IELTS_CENTER_ADD_TEACHER_MODAL_UPDATE_SUCCESS,
} from "../../constants/Ielts/ieltsCenterManagmentConstants";

export const ieltsCenterManagmentCenterCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case IELTS_CENTERMANAGMENT_CENTER_CREATE_REQUEST:
      return {
        ...state,
        ieltsCenterManagmentCenterCreateLoading: true,
      };
    case IELTS_CENTERMANAGMENT_CENTER_CREATE_SUCCESS:
      return {
        ...state,
        ieltsCenterManagmentCenterCreateLoading: false,
        ieltsCenterManagmentCenterCreateSuccess: action.payload,
      };
    case IELTS_CENTERMANAGMENT_CENTER_CREATE_ERR:
      return {
        ...state,
        ieltsCenterManagmentCenterCreateLoading: false,
        ieltsCenterManagmentCenterCreateerror: action.payload,
      };
    default:
      return state;
  }
};

// IELTS CENTER MANAGMENT CENTER FIND REDUCER

export const ieltsCenterManagmentCenterFindReducer = (state = {}, action) => {
  switch (action.type) {
    case IELTS_CENTERMANAGMENT_CENTER_FIND_REQUEST:
      return {
        ...state,
        ieltsCenterManagmentCenterFindLoading: true,
      };
    case IELTS_CENTERMANAGMENT_CENTER_FIND_SUCCESS:
      return {
        ...state,
        ieltsCenterManagmentCenterFindLoading: false,
        ieltsCenterManagmentCenterFindSuccess: action.payload,
      };
    case IELTS_CENTERMANAGMENT_CENTER_FIND_ERR:
      return {
        ...state,
        ieltsCenterManagmentCenterFindLoading: false,
        ieltsCenterManagmentCenterFinderror: action.payload,
      };
    default:
      return state;
  }
};

// IELTS CENTER MANAGMENT CENTER FINDBYID REDUCER

export const ieltsCenterManagmentCenterFindByIdReducer = (
  state = {},
  action
) => {
  switch (action.type) {
    case IELTS_CENTERMANAGMENT_CENTER_FINDBYID_REQUEST:
      return {
        ...state,
        ieltsCenterManagmentCenterFindByIdLoading: true,
      };
    case IELTS_CENTERMANAGMENT_CENTER_FINDBYID_SUCCESS:
      return {
        ...state,
        ieltsCenterManagmentCenterFindByIdLoading: false,
        ieltsCenterManagmentCenterFindByIdSuccess: action.payload,
      };
    case IELTS_CENTERMANAGMENT_CENTER_FINDBYID_ERR:
      return {
        ...state,
        ieltsCenterManagmentCenterFindByIdLoading: false,
        ieltsCenterManagmentCenterFindByIderror: action.payload,
      };
    default:
      return state;
  }
};

// IELTS CENTER MANAGMENT CENTER STATUSCHANGE REDUCER

export const ieltsCenterManagmentCenterStatusChangeReducer = (
  state = {},
  action
) => {
  switch (action.type) {
    case IELTS_CENTERMANAGMENT_CENTER_STATUSCHANGE_REQUEST:
      return {
        ...state,
        ieltsCenterManagmentCenterStatusChangeLoading: true,
      };
    case IELTS_CENTERMANAGMENT_CENTER_STATUSCHANGE_SUCCESS:
      return {
        ...state,
        ieltsCenterManagmentCenterStatusChangeLoading: false,
        ieltsCenterManagmentCenterStatusChangeSuccess: action.payload,
      };
    case IELTS_CENTERMANAGMENT_CENTER_STATUSCHANGE_ERR:
      return {
        ...state,
        ieltsCenterManagmentCenterStatusChangeLoading: false,
        ieltsCenterManagmentCenterStatusChangeerror: action.payload,
      };
    default:
      return state;
  }
};


// IELTS CENTER MANAGMENT CENTER SLOTUPDATE REDUCER

export const ieltsCenterManagmentCenterSlotUpdateReducer = (
  state = {},
  action
) => {
  switch (action.type) {
    case IELTS_CENTERMANAGMENT_CENTER_SLOTUPDATE_REQUEST:
      return {
        ...state,
        ieltsCenterManagmentCenterSlotUpdateLoading: true,
      };
    case IELTS_CENTERMANAGMENT_CENTER_SLOTUPDATE_SUCCESS:
      return {
        ...state,
        ieltsCenterManagmentCenterSlotUpdateLoading: false,
        ieltsCenterManagmentCenterSlotUpdateSuccess: action.payload,
      };
    case IELTS_CENTERMANAGMENT_CENTER_SLOTUPDATE_ERR:
      return {
        ...state,
        ieltsCenterManagmentCenterSlotUpdateLoading: false,
        ieltsCenterManagmentCenterSlotUpdateerror: action.payload,
      };
    default:
      return state;
  }
};


// IELTS CENTER MANAGMENT CENTER DELETE REDUCER

export const ieltsCenterManagmentCenterDeleteReducer = (
  state = {},
  action
) => {
  switch (action.type) {
    case IELTS_CENTERMANAGMENT_CENTER_DELETE_REQUEST:
      return {
        ...state,
        ieltsCenterManagmentCenterDeleteLoading: true,
      };
    case IELTS_CENTERMANAGMENT_CENTER_DELETE_SUCCESS:
      return {
        ...state,
        ieltsCenterManagmentCenterDeleteLoading: false,
        ieltsCenterManagmentCenterDeleteSuccess: action.payload,
      };
    case IELTS_CENTERMANAGMENT_CENTER_DELETE_ERR:
      return {
        ...state,
        ieltsCenterManagmentCenterDeleteLoading: false,
        ieltsCenterManagmentCenterDeleteerror: action.payload,
      };
    default:
      return state;
  }
};



// IELTS CENTER MANAGMENT CENTER TEACHER DELETE REDUCER

export const ieltsCenterManagmentCenterTeacherDeleteReducer = (
  state = {},
  action
) => {
  switch (action.type) {
    case IELTS_CENTERMANAGMENT_CENTER_TEACHER_DELETE_REQUEST:
      return {
        ...state,
        ieltsCenterManagmentCenterTeacherDeleteLoading: true,
      };
    case IELTS_CENTERMANAGMENT_CENTER_TEACHER_DELETE_SUCCESS:
      return {
        ...state,
        ieltsCenterManagmentCenterTeacherDeleteLoading: false,
        ieltsCenterManagmentCenterTeacherDeleteSuccess: action.payload,
      };
    case IELTS_CENTERMANAGMENT_CENTER_TEACHER_DELETE_ERR:
      return {
        ...state,
        ieltsCenterManagmentCenterTeacherDeleteLoading: false,
        ieltsCenterManagmentCenterTeacherDeleteerror: action.payload,
      };
    default:
      return state;
  }
};

// IELTS CENTER IELTS CENTER ADD TEACHER MODAL UPDATE REDUCER

export const ieltsCenterAddTeacherModalUpdateReducer = (
  state = {},
  action
) => {
  switch (action.type) {
    case IELTS_CENTER_ADD_TEACHER_MODAL_UPDATE_REQUEST:
      return {
        ...state,
        ieltsCenterAddTeacherModalUpdateLoading: true,
      };
    case IELTS_CENTER_ADD_TEACHER_MODAL_UPDATE_SUCCESS:
      return {
        ...state,
        ieltsCenterAddTeacherModalUpdateLoading: false,
        ieltsCenterAddTeacherModalUpdateSuccess: action.payload,
      };
    case IELTS_CENTER_ADD_TEACHER_MODAL_UPDATE_ERR:
      return {
        ...state,
        ieltsCenterAddTeacherModalUpdateLoading: false,
        ieltsCenterAddTeacherModalUpdateerror: action.payload,
      };
    default:
      return state;
  }
};


