import React, { useMemo, useState } from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableFooter,
  TableSortLabel,
  Menu,
  Alert,
  AlertTitle,
  Grid,
  Typography,
} from "@mui/material";

import { SlOptionsVertical } from "react-icons/sl";
import { Box, Checkbox, MenuItem } from "@mui/material";
import { TbEdit } from "react-icons/tb";
import { RiDeleteBin6Line } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { useDispatch } from "react-redux";
import { MdNotInterested } from "react-icons/md";
import { BiSortAlt2 } from "react-icons/bi";
import usePrivilegeCheck from "../../constants/customHooks/UsePrivilegeCheck";

import "./hrMain.css";
import { hrEmployeeHolidayDeleteAction } from "../../actions/HrActions/hrEmployeeManagmentAction";
import Dialogue from "../customComponent/Dialogue";
import PremissionDialog from "../customComponent/premissionDialog";

export const EmployeeHolidayTable = ({
  columns,
  data,
  component,
  deleteSuccess,
  subMenu,
}) => {
  const dispatch = useDispatch();

  // custom Hooks
  const {
    privilege,
    isUpdatePrevilage,
    isDeletePrevilage,
  } = usePrivilegeCheck();

  const [actionButtonShow, setActionButtonShow] = useState(null);
  const [tableId, setTableId] = useState("");
  const [open, setOpen] = useState(false);

  const [openAlert, setOpenAlert] = useState(false);

  // HandleClose dialog

  const handleCloseDialog = () => {
    setOpenAlert(false);
  };

  const openActionButton = Boolean(actionButtonShow);

  const handleClickAction = (event, id) => {
    setActionButtonShow(event.currentTarget);
    setTableId(id);
  };

  const handleCloseAction = () => {
    setActionButtonShow(null);
  };

  // Delete Table

  function handleDeleteholidays(id) {
    dispatch(hrEmployeeHolidayDeleteAction(id));
  }

  // navigate to Edit page

  const holidayEditHandler = (tableId) => {
    if (isUpdatePrevilage(subMenu)) {
      setOpen(true);
    } else {
      setOpenAlert(true);
    }
  };

  const CustomSortIcon = ({ direction }) => {
    return direction === "asc" ? (
      <img
        src="/menuIcons/tableUpSort.png"
        className="administratCustomIcon"
        alt="gsl"
        style={{ padding: "15px" }}
      />
    ) : (
      <BiSortAlt2 className="administratCustomIcon" />
    );
  };

  // sorting table
  const [sortBy, setSortBy] = useState(""); // Current sort column
  const [sortOrder, setSortOrder] = useState("asc"); // Current sort order

  const requestSort = (column) => {
    let newSortOrder = "asc";

    if (column === sortBy && sortOrder === "asc") {
      newSortOrder = "desc";
    }

    setSortBy(column);
    setSortOrder(newSortOrder);
  };
  let sortedData = useMemo(() => {
    if (sortBy && data) {
      const sortedItems = [...data].sort((a, b) => {
        if (sortOrder === "asc") {
          return a[sortBy] < b[sortBy] ? -1 : 1;
        } else {
          return a[sortBy] < b[sortBy] ? 1 : -1;
        }
      });
      return sortedItems;
    }
    return data;
  }, [data, sortBy, sortOrder]);

  const handleClose = () => setOpen(false);

  // Create a function to handle selecting individual rows:

  // if (!hasPrivilege("isadministrationView") && privilege !== null) {
  //   return (
  //     <Alert severity="error">
  //       <AlertTitle>warning</AlertTitle>
  //       You don't have permission to access — <strong>this page.!</strong>
  //     </Alert>
  //   );
  // }

  return (
    <>
      {sortedData?.length != 0 ? (
        <Table className="hrTable">
          <TableHead>
            <TableRow className="hrTableCell">
              <TableCell>
                <Checkbox
                  // checked={selectedRows.length === sortedData.length}
                  // onChange={handleSelectAllRows}
                  style={{ color: "#f81b82" }}
                />
              </TableCell>
              {columns?.map((column) => {
                if (column.hidden) {
                  return null;
                }
                return (
                  <TableCell key={column.field} className="hrTableCell">
                    <TableSortLabel
                      className="hrHeaderText"
                      active={column === sortBy}
                      direction={sortOrder}
                      onClick={() => requestSort(column)}
                      IconComponent={
                        column.field !== "action" && CustomSortIcon
                      }
                    >
                      {column.title}
                    </TableSortLabel>
                  </TableCell>
                );
              })}
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedData?.map((item, indx) => (
              <TableRow className="hrTable" key={indx}>
                <TableCell>
                  <Checkbox
                    // checked={selectedRows.includes(item.id)}
                    // onChange={() => handleSelectRow(item.id)}
                    style={{ color: "#f81b82" }}
                  />
                </TableCell>
                <TableCell>{item.sl}</TableCell>
                <TableCell>{item.coloum1}</TableCell>
                <TableCell>{item.coloum2}</TableCell>
                <TableCell>{item.coloum3}</TableCell>
                <TableCell>{item.coloum4}</TableCell>

                <TableCell>
                  <SlOptionsVertical
                    id="demo-positioned-button"
                    aria-controls={
                      openActionButton ? "demo-positioned-menu" : undefined
                    }
                    aria-haspopup="true"
                    aria-expanded={openActionButton ? "true" : undefined}
                    onClick={(e) => {
                      handleClickAction(e, item.id);
                    }}
                    variant="outlined"
                    style={{ marginLeft: "10px", color: "#F80B7A" }}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter />
        </Table>
      ) : (
        <Grid
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center", // Center horizontally
            alignItems: "center", // Center vertically
            height: "50vh", // This makes sure the container takes up the entire viewport height
          }}
          lg={12}
        >
          <img src="/empyPageImage.png" alt="" />
          <Typography
            sx={{
              textAlign: "center",
              fontFamily: "'Poppins', sans-serif",
              fontWeight: 700,
              fontSize: "28.55px",
              color: "#777AAE",
            }}
          >
            No Holidays to show
          </Typography>
        </Grid>
      )}

      {/* TABLE ACTION MENU START*/}
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={actionButtonShow}
        open={openActionButton}
        onClose={handleCloseAction}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        anchorOrigin={{
          vertical: "bottom", // Change from "top" to "bottom"
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top", // Change from "top" to "bottom"
          horizontal: "left",
        }}
        sx={{
          borderColor: "black",
          borderRadius: "24px",
          mt: 1.5,
          width: "auto",
          height: "auto",
        }}
      >
        <Box style={{ display: "flex", justifyContent: "flex-end" }}>
          <MenuItem onClick={() => holidayEditHandler(tableId)}>
            <TbEdit
              style={{
                fontSize: "lg",
                width: "20px",
                height: "19px",
              }}
              className="icons"
            />
          </MenuItem>

          <MenuItem>
            <RiDeleteBin6Line
              onClick={() => {
                if (isDeletePrevilage(subMenu)) {
                  setActionButtonShow(null);
                  Swal.fire({
                    title: "Are you sure?",
                    text: "You won't be able to revert this!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes, delete it!",
                  }).then((result) => {
                    if (result.isConfirmed) {
                      handleDeleteholidays(tableId);
                      setActionButtonShow(false);
                      Swal.fire(
                        "Deleted!",
                        "Your file has been deleted.",
                        "success"
                      );
                    }
                  });
                } else {
                  setOpenAlert(true);
                }
              }}
              style={{
                fontSize: "lg",
                width: "20px",
                height: "19px",
              }}
              className="icons"
            />
          </MenuItem>
        </Box>
      </Menu>

      {/* Holiday_Edit_Modal */}

      {open && (
        <Dialogue
          openTrue={open}
          handleClose={handleClose}
          headerName="New Holiday"
          Dailogcomponent="holidayEdit"
          appliedApplicationId={tableId}
        />
      )}

      {openAlert && (
        <PremissionDialog
          openAlert={openAlert}
          handleClose={handleCloseDialog}
        />
      )}
    </>
  );
};
