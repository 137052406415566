import { EditorState } from "draft-js";
import {
  IELTS_COURSEMANAGMENT_COURSE_CENTER_FIND_ERR,
  IELTS_COURSEMANAGMENT_COURSE_CENTER_FIND_REQUEST,
  IELTS_COURSEMANAGMENT_COURSE_CENTER_FIND_SUCCESS,
  IELTS_COURSEMANAGMENT_COURSE_CREATE_CHECK_ERROR_SUCCESS,
  IELTS_COURSEMANAGMENT_COURSE_CREATE_ERR,
  IELTS_COURSEMANAGMENT_COURSE_CREATE_REQUEST,
  IELTS_COURSEMANAGMENT_COURSE_CREATE_SUCCESS,
  IELTS_COURSEMANAGMENT_COURSE_CREATING_ERR,
  IELTS_COURSEMANAGMENT_COURSE_CREATING_REQUEST,
  IELTS_COURSEMANAGMENT_COURSE_CREATING_SUCCESS,
  IELTS_COURSEMANAGMENT_COURSE_EDITFIND_ERR,
  IELTS_COURSEMANAGMENT_COURSE_EDITFIND_REQUEST,
  IELTS_COURSEMANAGMENT_COURSE_EDITFIND_SUCCESS,
  IELTS_COURSEMANAGMENT_COURSE_EDIT_ERR,
  IELTS_COURSEMANAGMENT_COURSE_EDIT_REQUEST,
  IELTS_COURSEMANAGMENT_COURSE_EDIT_SUCCESS,
  IELTS_COURSEMANAGMENT_COURSE_FINDBYID_ERR,
  IELTS_COURSEMANAGMENT_COURSE_FINDBYID_REQUEST,
  IELTS_COURSEMANAGMENT_COURSE_FINDBYID_SUCCESS,
  IELTS_COURSEMANAGMENT_COURSE_FIND_ERR,
  IELTS_COURSEMANAGMENT_COURSE_FIND_REQUEST,
  IELTS_COURSEMANAGMENT_COURSE_FIND_SUCCESS,
  IELTS_COURSEMANAGMENT_COURSE_LIVECLASS_CREATE_ERR,
  IELTS_COURSEMANAGMENT_COURSE_LIVECLASS_CREATE_REQUEST,
  IELTS_COURSEMANAGMENT_COURSE_LIVECLASS_CREATE_SUCCESS,
  IELTS_COURSEMANAGMENT_COURSE_LIVECLASS_DELETE_ERR,
  IELTS_COURSEMANAGMENT_COURSE_LIVECLASS_DELETE_REQUEST,
  IELTS_COURSEMANAGMENT_COURSE_LIVECLASS_DELETE_SUCCESS,
  IELTS_COURSEMANAGMENT_COURSE_LIVECLASS_FINDBYID_ERR,
  IELTS_COURSEMANAGMENT_COURSE_LIVECLASS_FINDBYID_REQUEST,
  IELTS_COURSEMANAGMENT_COURSE_LIVECLASS_FINDBYID_SUCCESS,
  IELTS_COURSEMANAGMENT_COURSE_LIVECLASS_FIND_ERR,
  IELTS_COURSEMANAGMENT_COURSE_LIVECLASS_FIND_REQUEST,
  IELTS_COURSEMANAGMENT_COURSE_LIVECLASS_FIND_SUCCESS,
  IELTS_COURSEMANAGMENT_COURSE_LIVECLASS_STATUSCHANGE_ERR,
  IELTS_COURSEMANAGMENT_COURSE_LIVECLASS_STATUSCHANGE_REQUEST,
  IELTS_COURSEMANAGMENT_COURSE_LIVECLASS_STATUSCHANGE_SUCCESS,
  IELTS_COURSEMANAGMENT_COURSE_LIVECLASS_UPDATE_ERR,
  IELTS_COURSEMANAGMENT_COURSE_LIVECLASS_UPDATE_REQUEST,
  IELTS_COURSEMANAGMENT_COURSE_LIVECLASS_UPDATE_SUCCESS,
  IELTS_COURSEMANAGMENT_COURSE_SLOT_ERR,
  IELTS_COURSEMANAGMENT_COURSE_SLOT_REQUEST,
  IELTS_COURSEMANAGMENT_COURSE_SLOT_SUCCESS,
  IELTS_COURSEMANAGMENT_COURSE_STATUSCHANGE_ERR,
  IELTS_COURSEMANAGMENT_COURSE_STATUSCHANGE_REQUEST,
  IELTS_COURSEMANAGMENT_COURSE_STATUSCHANGE_SUCCESS,
  IELTS_COURSEMANAGMENT_COURSE_TOPICFIND_ERR,
  IELTS_COURSEMANAGMENT_COURSE_TOPICFIND_REQUEST,
  IELTS_COURSEMANAGMENT_COURSE_TOPICFIND_SUCCESS,
  IELTS_COURSEMANAGMENT_EXAMTIPSPDF_DELETE_ERR,
  IELTS_COURSEMANAGMENT_EXAMTIPSPDF_DELETE_REQUEST,
  IELTS_COURSEMANAGMENT_EXAMTIPSPDF_DELETE_SUCCESS,
  IELTS_COURSEMANAGMENT_EXAMTIPS_CREATE_ERR,
  IELTS_COURSEMANAGMENT_EXAMTIPS_CREATE_REQUEST,
  IELTS_COURSEMANAGMENT_EXAMTIPS_CREATE_SUCCESS,
  IELTS_COURSEMANAGMENT_EXAMTIPS_DELETE_ERR,
  IELTS_COURSEMANAGMENT_EXAMTIPS_DELETE_REQUEST,
  IELTS_COURSEMANAGMENT_EXAMTIPS_DELETE_SUCCESS,
  IELTS_COURSEMANAGMENT_EXAMTIPS_FINDBYID_ERR,
  IELTS_COURSEMANAGMENT_EXAMTIPS_FINDBYID_REQUEST,
  IELTS_COURSEMANAGMENT_EXAMTIPS_FINDBYID_SUCCESS,
  IELTS_COURSEMANAGMENT_EXAMTIPS_FIND_ERR,
  IELTS_COURSEMANAGMENT_EXAMTIPS_FIND_REQUEST,
  IELTS_COURSEMANAGMENT_EXAMTIPS_FIND_SUCCESS,
  IELTS_COURSEMANAGMENT_EXAMTIPS_STATUSCHANGE_ERR,
  IELTS_COURSEMANAGMENT_EXAMTIPS_STATUSCHANGE_REQUEST,
  IELTS_COURSEMANAGMENT_EXAMTIPS_STATUSCHANGE_SUCCESS,
  IELTS_COURSEMANAGMENT_EXAMTIPS_UPDATE_ERR,
  IELTS_COURSEMANAGMENT_EXAMTIPS_UPDATE_REQUEST,
  IELTS_COURSEMANAGMENT_EXAMTIPS_UPDATE_SUCCESS,
  IELTS_COURSEMANAGMENT_QUESTIONBANKPDF_DELETE_ERR,
  IELTS_COURSEMANAGMENT_QUESTIONBANKPDF_DELETE_REQUEST,
  IELTS_COURSEMANAGMENT_QUESTIONBANKPDF_DELETE_SUCCESS,
  IELTS_COURSEMANAGMENT_QUESTIONBANK_CREATE_ERR,
  IELTS_COURSEMANAGMENT_QUESTIONBANK_CREATE_REQUEST,
  IELTS_COURSEMANAGMENT_QUESTIONBANK_CREATE_SUCCESS,
  IELTS_COURSEMANAGMENT_QUESTIONBANK_DELETE_ERR,
  IELTS_COURSEMANAGMENT_QUESTIONBANK_DELETE_REQUEST,
  IELTS_COURSEMANAGMENT_QUESTIONBANK_DELETE_SUCCESS,
  IELTS_COURSEMANAGMENT_QUESTIONBANK_FINDBYID_ERR,
  IELTS_COURSEMANAGMENT_QUESTIONBANK_FINDBYID_REQUEST,
  IELTS_COURSEMANAGMENT_QUESTIONBANK_FINDBYID_SUCCESS,
  IELTS_COURSEMANAGMENT_QUESTIONBANK_FIND_ERR,
  IELTS_COURSEMANAGMENT_QUESTIONBANK_FIND_REQUEST,
  IELTS_COURSEMANAGMENT_QUESTIONBANK_FIND_SUCCESS,
  IELTS_COURSEMANAGMENT_QUESTIONBANK_STATUSCHANGE_ERR,
  IELTS_COURSEMANAGMENT_QUESTIONBANK_STATUSCHANGE_REQUEST,
  IELTS_COURSEMANAGMENT_QUESTIONBANK_STATUSCHANGE_SUCCESS,
  IELTS_COURSEMANAGMENT_QUESTIONBANK_UPDATE_ERR,
  IELTS_COURSEMANAGMENT_QUESTIONBANK_UPDATE_REQUEST,
  IELTS_COURSEMANAGMENT_QUESTIONBANK_UPDATE_SUCCESS,
} from "../../constants/Ielts/ieltsCoursemanagmentConstnts";

// IELTS COURSE MANAGMENT EXAMTIP CREATE REDUCER

export const ieltsCourseManagmentExamtipsCreateReducer = (
  state = {},
  action
) => {
  switch (action.type) {
    case IELTS_COURSEMANAGMENT_EXAMTIPS_CREATE_REQUEST:
      return {
        ...state,
        ieltsCourseManagmentExamtipsCreateLoading: true,
      };
    case IELTS_COURSEMANAGMENT_EXAMTIPS_CREATE_SUCCESS:
      return {
        ...state,
        ieltsCourseManagmentExamtipsCreateLoading: false,
        ieltsCourseManagmentExamtipsCreateSuccess: action.payload,
      };
    case IELTS_COURSEMANAGMENT_EXAMTIPS_CREATE_ERR:
      return {
        ...state,
        ieltsCourseManagmentExamtipsCreateLoading: false,
        ieltsCourseManagmentExamtipsCreateerror: action.payload,
      };
    default:
      return state;
  }
};

// IELTS COURSE MANAGMENT EXAMTIP FIND REDUCER

export const ieltsCourseManagmentExamtipsFindReducer = (state = {}, action) => {
  switch (action.type) {
    case IELTS_COURSEMANAGMENT_EXAMTIPS_FIND_REQUEST:
      return {
        ...state,
        ieltsCourseManagmentExamtipsFindLoading: true,
      };
    case IELTS_COURSEMANAGMENT_EXAMTIPS_FIND_SUCCESS:
      return {
        ...state,
        ieltsCourseManagmentExamtipsFindLoading: false,
        ieltsCourseManagmentExamtipsFindSuccess: action.payload,
      };
    case IELTS_COURSEMANAGMENT_EXAMTIPS_FIND_ERR:
      return {
        ...state,
        ieltsCourseManagmentExamtipsFindLoading: false,
        ieltsCourseManagmentExamtipsFinderror: action.payload,
      };
    default:
      return state;
  }
};

// IELTS COURSE MANAGMENT EXAMTIP FINDBYID REDUCER

export const ieltsCourseManagmentExamtipsFindByidReducer = (
  state = {},
  action
) => {
  switch (action.type) {
    case IELTS_COURSEMANAGMENT_EXAMTIPS_FINDBYID_REQUEST:
      return {
        ...state,
        ieltsCourseManagmentExamtipsFindByIdLoading: true,
      };
    case IELTS_COURSEMANAGMENT_EXAMTIPS_FINDBYID_SUCCESS:
      return {
        ...state,
        ieltsCourseManagmentExamtipsFindByIdLoading: false,
        ieltsCourseManagmentExamtipsFindByIdSuccess: action.payload,
      };
    case IELTS_COURSEMANAGMENT_EXAMTIPS_FINDBYID_ERR:
      return {
        ...state,
        ieltsCourseManagmentExamtipsFindByIdLoading: false,
        ieltsCourseManagmentExamtipsFindByIderror: action.payload,
      };
    default:
      return state;
  }
};

// IELTS COURSE MANAGMENT EXAMTIP UPDATE REDUCER

export const ieltsCourseManagmentExamtipsUpdateReducer = (
  state = {},
  action
) => {
  switch (action.type) {
    case IELTS_COURSEMANAGMENT_EXAMTIPS_UPDATE_REQUEST:
      return {
        ...state,
        ieltsCourseManagmentExamtipsUpdateLoading: true,
      };
    case IELTS_COURSEMANAGMENT_EXAMTIPS_UPDATE_SUCCESS:
      return {
        ...state,
        ieltsCourseManagmentExamtipsUpdateLoading: false,
        ieltsCourseManagmentExamtipsUpdateSuccess: action.payload,
      };
    case IELTS_COURSEMANAGMENT_EXAMTIPS_UPDATE_ERR:
      return {
        ...state,
        ieltsCourseManagmentExamtipsUpdateLoading: false,
        ieltsCourseManagmentExamtipsUpdateerror: action.payload,
      };
    default:
      return state;
  }
};

// IELTS COURSE MANAGMENT EXAMTIP STATUSCHANGE REDUCER

export const ieltsCourseManagmentExamtipsStatusChangeReducer = (
  state = {},
  action
) => {
  switch (action.type) {
    case IELTS_COURSEMANAGMENT_EXAMTIPS_STATUSCHANGE_REQUEST:
      return {
        ...state,
        ieltsCourseManagmentExamtipsStatusLoading: true,
      };
    case IELTS_COURSEMANAGMENT_EXAMTIPS_STATUSCHANGE_SUCCESS:
      return {
        ...state,
        ieltsCourseManagmentExamtipsStatusLoading: false,
        ieltsCourseManagmentExamtipsStatusSuccess: action.payload,
      };
    case IELTS_COURSEMANAGMENT_EXAMTIPS_STATUSCHANGE_ERR:
      return {
        ...state,
        ieltsCourseManagmentExamtipsStatusLoading: false,
        ieltsCourseManagmentExamtipsStatuserror: action.payload,
      };
    default:
      return state;
  }
};

// IELTS COURSE MANAGMENT EXAMTIP DELETE REDUCER

export const ieltsCourseManagmentExamtipsDeleteReducer = (
  state = {},
  action
) => {
  switch (action.type) {
    case IELTS_COURSEMANAGMENT_EXAMTIPS_DELETE_REQUEST:
      return {
        ...state,
        ieltsCourseManagmentExamtipsDeleteLoading: true,
      };
    case IELTS_COURSEMANAGMENT_EXAMTIPS_DELETE_SUCCESS:
      return {
        ...state,
        ieltsCourseManagmentExamtipsDeleteLoading: false,
        ieltsCourseManagmentExamtipsDeleteSuccess: action.payload,
      };
    case IELTS_COURSEMANAGMENT_EXAMTIPS_DELETE_ERR:
      return {
        ...state,
        ieltsCourseManagmentExamtipsDeleteLoading: false,
        ieltsCourseManagmentExamtipsDeleteerror: action.payload,
      };
    default:
      return state;
  }
};

// IELTS COURSE MANAGMENT EXAMTIP DELETE REDUCER

export const ieltsCourseManagmentExamtipsPdfDeleteReducer = (
  state = {},
  action
) => {
  switch (action.type) {
    case IELTS_COURSEMANAGMENT_EXAMTIPSPDF_DELETE_REQUEST:
      return {
        ...state,
        ieltsCourseManagmentExamtipsPdfDeleteLoading: true,
      };
    case IELTS_COURSEMANAGMENT_EXAMTIPSPDF_DELETE_SUCCESS:
      return {
        ...state,
        ieltsCourseManagmentExamtipsPdfDeleteLoading: false,
        ieltsCourseManagmentExamtipsPdfDeleteSuccess: action.payload,
      };
    case IELTS_COURSEMANAGMENT_EXAMTIPSPDF_DELETE_ERR:
      return {
        ...state,
        ieltsCourseManagmentExamtipsPdfDeleteLoading: false,
        ieltsCourseManagmentExamtipsPdfDeleteerror: action.payload,
      };
    default:
      return state;
  }
};

// QUESTION BANK REDUCER ////////////////////////////////////////////////////////////////////////////

// IELTS COURSE MANAGMENT QUESTIONBANK CREATE REDUCER

export const ieltsCourseManagmentQuestionBankCreateReducer = (
  state = {},
  action
) => {
  switch (action.type) {
    case IELTS_COURSEMANAGMENT_QUESTIONBANK_CREATE_REQUEST:
      return {
        ...state,
        ieltsCourseManagmentQuestionBankCreateLoading: true,
      };
    case IELTS_COURSEMANAGMENT_QUESTIONBANK_CREATE_SUCCESS:
      return {
        ...state,
        ieltsCourseManagmentQuestionBankCreateLoading: false,
        ieltsCourseManagmentQuestionBankCreateSuccess: action.payload,
      };
    case IELTS_COURSEMANAGMENT_QUESTIONBANK_CREATE_ERR:
      return {
        ...state,
        ieltsCourseManagmentQuestionBankCreateLoading: false,
        ieltsCourseManagmentQuestionBankCreateerror: action.payload,
      };
    default:
      return state;
  }
};

// IELTS COURSE MANAGMENT EXAMTIP FIND REDUCER

export const ieltsCourseManagmentQuestionBankFindReducer = (
  state = {},
  action
) => {
  switch (action.type) {
    case IELTS_COURSEMANAGMENT_QUESTIONBANK_FIND_REQUEST:
      return {
        ...state,
        ieltsCourseManagmentQuestionBankFindLoading: true,
      };
    case IELTS_COURSEMANAGMENT_QUESTIONBANK_FIND_SUCCESS:
      return {
        ...state,
        ieltsCourseManagmentQuestionBankFindLoading: false,
        ieltsCourseManagmentQuestionBankFindSuccess: action.payload,
      };
    case IELTS_COURSEMANAGMENT_QUESTIONBANK_FIND_ERR:
      return {
        ...state,
        ieltsCourseManagmentQuestionBankFindLoading: false,
        ieltsCourseManagmentQuestionBankFinderror: action.payload,
      };
    default:
      return state;
  }
};

// IELTS COURSE MANAGMENT EXAMTIP FINDBYID REDUCER

export const ieltsCourseManagmentQuestionBankFindByidReducer = (
  state = {},
  action
) => {
  switch (action.type) {
    case IELTS_COURSEMANAGMENT_QUESTIONBANK_FINDBYID_REQUEST:
      return {
        ...state,
        ieltsCourseManagmentQuestionBankFindByIdLoading: true,
      };
    case IELTS_COURSEMANAGMENT_QUESTIONBANK_FINDBYID_SUCCESS:
      return {
        ...state,
        ieltsCourseManagmentQuestionBankFindByIdLoading: false,
        ieltsCourseManagmentQuestionBankFindByIdSuccess: action.payload,
      };
    case IELTS_COURSEMANAGMENT_QUESTIONBANK_FINDBYID_ERR:
      return {
        ...state,
        ieltsCourseManagmentQuestionBankFindByIdLoading: false,
        ieltsCourseManagmentQuestionBankFindByIderror: action.payload,
      };
    default:
      return state;
  }
};

// IELTS COURSE MANAGMENT EXAMTIP UPDATE REDUCER

export const ieltsCourseManagmentQuestionBankUpdateReducer = (
  state = {},
  action
) => {
  switch (action.type) {
    case IELTS_COURSEMANAGMENT_QUESTIONBANK_UPDATE_REQUEST:
      return {
        ...state,
        ieltsCourseManagmentQuestionBankUpdateLoading: true,
      };
    case IELTS_COURSEMANAGMENT_QUESTIONBANK_UPDATE_SUCCESS:
      return {
        ...state,
        ieltsCourseManagmentQuestionBankUpdateLoading: false,
        ieltsCourseManagmentQuestionBankUpdateSuccess: action.payload,
      };
    case IELTS_COURSEMANAGMENT_QUESTIONBANK_UPDATE_ERR:
      return {
        ...state,
        ieltsCourseManagmentQuestionBankUpdateLoading: false,
        ieltsCourseManagmentQuestionBankUpdateerror: action.payload,
      };
    default:
      return state;
  }
};

// IELTS COURSE MANAGMENT EXAMTIP STATUSCHANGE REDUCER

export const ieltsCourseManagmentQuestionBankStatusChangeReducer = (
  state = {},
  action
) => {
  switch (action.type) {
    case IELTS_COURSEMANAGMENT_QUESTIONBANK_STATUSCHANGE_REQUEST:
      return {
        ...state,
        ieltsCourseManagmentQuestionBankStatusLoading: true,
      };
    case IELTS_COURSEMANAGMENT_QUESTIONBANK_STATUSCHANGE_SUCCESS:
      return {
        ...state,
        ieltsCourseManagmentQuestionBankStatusLoading: false,
        ieltsCourseManagmentQuestionBankStatusSuccess: action.payload,
      };
    case IELTS_COURSEMANAGMENT_QUESTIONBANK_STATUSCHANGE_ERR:
      return {
        ...state,
        ieltsCourseManagmentQuestionBankStatusLoading: false,
        ieltsCourseManagmentQuestionBankStatuserror: action.payload,
      };
    default:
      return state;
  }
};

// IELTS COURSE MANAGMENT EXAMTIP DELETE REDUCER

export const ieltsCourseManagmentQuestionBankDeleteReducer = (
  state = {},
  action
) => {
  switch (action.type) {
    case IELTS_COURSEMANAGMENT_QUESTIONBANK_DELETE_REQUEST:
      return {
        ...state,
        ieltsCourseManagmentQuestionBankDeleteLoading: true,
      };
    case IELTS_COURSEMANAGMENT_QUESTIONBANK_DELETE_SUCCESS:
      return {
        ...state,
        ieltsCourseManagmentQuestionBankDeleteLoading: false,
        ieltsCourseManagmentQuestionBankDeleteSuccess: action.payload,
      };
    case IELTS_COURSEMANAGMENT_QUESTIONBANK_DELETE_ERR:
      return {
        ...state,
        ieltsCourseManagmentQuestionBankDeleteLoading: false,
        ieltsCourseManagmentQuestionBankDeleteerror: action.payload,
      };
    default:
      return state;
  }
};

// IELTS COURSE MANAGMENT EXAMTIP DELETE REDUCER

export const ieltsCourseManagmentQuestionBankPdfDeleteReducer = (
  state = {},
  action
) => {
  switch (action.type) {
    case IELTS_COURSEMANAGMENT_QUESTIONBANKPDF_DELETE_REQUEST:
      return {
        ...state,
        ieltsCourseManagmentQuestionBankPdfDeleteLoading: true,
      };
    case IELTS_COURSEMANAGMENT_QUESTIONBANKPDF_DELETE_SUCCESS:
      return {
        ...state,
        ieltsCourseManagmentQuestionBankPdfDeleteLoading: false,
        ieltsCourseManagmentQuestionBankPdfDeleteSuccess: action.payload,
      };
    case IELTS_COURSEMANAGMENT_QUESTIONBANKPDF_DELETE_ERR:
      return {
        ...state,
        ieltsCourseManagmentQuestionBankPdfDeleteLoading: false,
        ieltsCourseManagmentQuestionBankPdfDeleteerror: action.payload,
      };
    default:
      return state;
  }
};

// IELTS COURSE MANAGMENT CREATE COURSE REDUCER
let initialState = {
  ieltsCourseManagmentCourseCreateSuccess: {
    courseImage: "",
    name: "",
    duration: "",
    fees: "",
    discription: EditorState.createEmpty(),
    isOnline: false,
    isOffline: false,
    isTopCourse:false,
    htmlContent:"",
    online: {
      Speaking: {
        DemoVideo: "",
        DemoAudio: "",
        DemoNote: "",
        Topics: {
          video: [],
          audio: [],
          notes: [],
          examtip: [],
          questionbank: [],
        },
      },
      Reading: {
        DemoVideo: "",
        DemoAudio: "",
        DemoNote: "",
        Topics: {
          video: [],
          audio: [],
          notes: [],
          examtip: [],
          questionbank: [],
        },
      },
      Listening: {
        DemoVideo: "",
        DemoAudio: "",
        DemoNote: "",
        Topics: {
          video: [],
          audio: [],
          notes: [],
          examtip: [],
          questionbank: [],
        },
      },
      Writing: {
        DemoVideo: "",
        DemoAudio: "",
        DemoNote: "",
        Topics: {
          video: [],
          audio: [],
          notes: [],
          examtip: [],
          questionbank: [],
        },
      },
      Grammar: {
        DemoVideo: "",
        DemoAudio: "",
        DemoNote: "",
        Topics: {
          video: [],
          audio: [],
          notes: [],
          examtip: [],
          questionbank: [],
        },
      },
    },
    onlineItems: {
      Speaking: {
        DemoVideo: "",
        DemoAudio: "",
        DemoNote: "",
        Topics: {
          video: [],
          audio: [],
          notes: [],
          examtip: [],
          questionbank: [],
        },
      },
      Reading: {
        DemoVideo: "",
        DemoAudio: "",
        DemoNote: "",
        Topics: {
          video: [],
          audio: [],
          notes: [],
          examtip: [],
          questionbank: [],
        },
      },
      Listening: {
        DemoVideo: "",
        DemoAudio: "",
        DemoNote: "",
        Topics: {
          video: [],
          audio: [],
          notes: [],
          examtip: [],
          questionbank: [],
        },
      },
      Writing: {
        DemoVideo: "",
        DemoAudio: "",
        DemoNote: "",
        Topics: {
          video: [],
          audio: [],
          notes: [],
          examtip: [],
          questionbank: [],
        },
      },
      Grammar: {
        DemoVideo: "",
        DemoAudio: "",
        DemoNote: "",
        Topics: {
          video: [],
          audio: [],
          notes: [],
          examtip: [],
          questionbank: [],
        },
      },
    },
    offline: {
      slots: [],
    },
  },
};

export const ieltsCourseManagmentCreateCourseReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case IELTS_COURSEMANAGMENT_COURSE_CREATE_REQUEST:
      return {
        ...state,
        ieltsCourseManagmentCourseCreateLoading: true,
      };
    case IELTS_COURSEMANAGMENT_COURSE_CREATE_SUCCESS:
      return {
        ...state,
        ieltsCourseManagmentCourseCreateLoading: false,
        ieltsCourseManagmentCourseCreateSuccess: action.payload,
      };
    case IELTS_COURSEMANAGMENT_COURSE_CREATE_ERR:
      return {
        ...state,
        ieltsCourseManagmentCourseCreateLoading: false,
        ieltsCourseManagmentCourseCreateerror: action.payload,
      };

    default:
      return state;
  }
};

// IELTS COURSE MANAGMENT CREATE COURSE REDUCER
export const ieltsCourseManagmentCreateCheckErrorCourseReducer = (
  state = {
    isOffline: false,
    isOnline: false,
  },
  action
) => {
  switch (action.type) {
    case IELTS_COURSEMANAGMENT_COURSE_CREATE_CHECK_ERROR_SUCCESS:
      return {
        ...state,
        ieltsCourseManagmentCourseCreateCheckErrorSuccess: action.payload,
      };

    default:
      return state;
  }
};

// FIND COURSE MANAGMENT COURSE TOPIC

export const ieltsCourseManagmentCourseTopicFindReducer = (
  state = {},
  action
) => {
  switch (action.type) {
    case IELTS_COURSEMANAGMENT_COURSE_TOPICFIND_REQUEST:
      return {
        ...state,
        ieltsCourseManagmentCourseTopicFindLoading: true,
      };
    case IELTS_COURSEMANAGMENT_COURSE_TOPICFIND_SUCCESS:
      return {
        ...state,
        ieltsCourseManagmentCourseTopicFindLoading: false,
        ieltsCourseManagmentCourseTopicFindSuccess: action.payload,
      };
    case IELTS_COURSEMANAGMENT_COURSE_TOPICFIND_ERR:
      return {
        ...state,
        ieltsCourseManagmentCourseTopicFindLoading: false,
        ieltsCourseManagmentCourseTopicFinderror: action.payload,
      };
    default:
      return state;
  }
};

// FIND COURSEMANAGMENT COURSE

export const ieltsCourseManagmentCourseFindReducer = (state = {}, action) => {
  switch (action.type) {
    case IELTS_COURSEMANAGMENT_COURSE_FIND_REQUEST:
      return {
        ...state,
        ieltsCourseManagmentCourseFindLoading: true,
      };
    case IELTS_COURSEMANAGMENT_COURSE_FIND_SUCCESS:
      return {
        ...state,
        ieltsCourseManagmentCourseFindLoading: false,
        ieltsCourseManagmentCourseFindSuccess: action.payload,
      };
    case IELTS_COURSEMANAGMENT_COURSE_FIND_ERR:
      return {
        ...state,
        ieltsCourseManagmentCourseFindLoading: false,
        ieltsCourseManagmentCourseFinderror: action.payload,
      };
    default:
      return state;
  }
};

// FINDBYID COURSEMANAGMENT COURSE

export const ieltsCourseManagmentCourseFindByIdReducer = (
  state = {},
  action
) => {
  switch (action.type) {
    case IELTS_COURSEMANAGMENT_COURSE_FINDBYID_REQUEST:
      return {
        ...state,
        ieltsCourseManagmentCourseFindByIdLoading: true,
      };
    case IELTS_COURSEMANAGMENT_COURSE_FINDBYID_SUCCESS:
      return {
        ...state,
        ieltsCourseManagmentCourseFindByIdLoading: false,
        ieltsCourseManagmentCourseFindByIdSuccess: action.payload,
      };
    case IELTS_COURSEMANAGMENT_COURSE_FINDBYID_ERR:
      return {
        ...state,
        ieltsCourseManagmentCourseFindByIdLoading: false,
        ieltsCourseManagmentCourseFindByIderror: action.payload,
      };
    default:
      return state;
  }
};

// FINDBYID COURSEMANAGMENT COURSE CREATING REDUCER

export const ieltsCourseManagmentCourseCreatingReducer = (
  state = {},
  action
) => {
  switch (action.type) {
    case IELTS_COURSEMANAGMENT_COURSE_CREATING_REQUEST:
      return {
        ...state,
        ieltsCourseManagmentCourseCreatingLoading: true,
      };
    case IELTS_COURSEMANAGMENT_COURSE_CREATING_SUCCESS:
      return {
        ...state,
        ieltsCourseManagmentCourseCreatingLoading: false,
        ieltsCourseManagmentCourseCreatingSuccess: action.payload,
      };
    case IELTS_COURSEMANAGMENT_COURSE_CREATING_ERR:
      return {
        ...state,
        ieltsCourseManagmentCourseCreatingLoading: false,
        ieltsCourseManagmentCourseCreatingerror: action.payload,
      };
    default:
      return state;
  }
};

// COURSEMANAGMENT COURSE  REDUCER

export const ieltsCourseManagmentCoursesStatusChangeReducer = (
  state = {},
  action
) => {
  switch (action.type) {
    case IELTS_COURSEMANAGMENT_COURSE_STATUSCHANGE_REQUEST:
      return {
        ...state,
        ieltsCourseManagmentCourseStatusChangeLoading: true,
      };
    case IELTS_COURSEMANAGMENT_COURSE_STATUSCHANGE_SUCCESS:
      return {
        ...state,
        ieltsCourseManagmentCourseStatusChangeLoading: false,
        ieltsCourseManagmentCourseStatusChangeSuccess: action.payload,
      };
    case IELTS_COURSEMANAGMENT_COURSE_STATUSCHANGE_ERR:
      return {
        ...state,
        ieltsCourseManagmentCourseStatusChangeLoading: false,
        ieltsCourseManagmentCourseStatusChangeerror: action.payload,
      };
    default:
      return state;
  }
};

// COURSEMANAGMENT COURSE SLOT  REDUCER

export const ieltsCourseManagmentCoursesSlotReducer = (state = {}, action) => {
  switch (action.type) {
    case IELTS_COURSEMANAGMENT_COURSE_SLOT_REQUEST:
      return {
        ...state,
        ieltsCourseManagmentCourseSlotLoading: true,
      };
    case IELTS_COURSEMANAGMENT_COURSE_SLOT_SUCCESS:
      return {
        ...state,
        ieltsCourseManagmentCourseSlotLoading: false,
        ieltsCourseManagmentCourseSlotSuccess: action.payload,
      };
    case IELTS_COURSEMANAGMENT_COURSE_SLOT_ERR:
      return {
        ...state,
        ieltsCourseManagmentCourseSlotLoading: false,
        ieltsCourseManagmentCourseSloterror: action.payload,
      };
    default:
      return state;
  }
};

// COURSEMANAGMENT COURSE CENTER  REDUCER

export const ieltsCourseManagmentCourseCenterReducer = (state = {}, action) => {
  switch (action.type) {
    case IELTS_COURSEMANAGMENT_COURSE_CENTER_FIND_REQUEST:
      return {
        ...state,
        ieltsCourseManagmentCourseCenterFindLoading: true,
      };
    case IELTS_COURSEMANAGMENT_COURSE_CENTER_FIND_SUCCESS:
      return {
        ...state,
        ieltsCourseManagmentCourseCenterFindLoading: false,
        ieltsCourseManagmentCourseCenterFindSuccess: action.payload,
      };
    case IELTS_COURSEMANAGMENT_COURSE_CENTER_FIND_ERR:
      return {
        ...state,
        ieltsCourseManagmentCourseCenterFindLoading: false,
        ieltsCourseManagmentCourseCenterFinderror: action.payload,
      };
    default:
      return state;
  }
};

// courseManagment course edit find reducer

export const ieltsCourseManagmentCourseEditFindReducer = (state = {}, action) => {
  switch (action.type) {
    case IELTS_COURSEMANAGMENT_COURSE_EDITFIND_REQUEST:
      return {
        ...state,
        ieltsCourseManagmentCourseEditFindLoading: true,
      };
    case IELTS_COURSEMANAGMENT_COURSE_EDITFIND_SUCCESS:
      return {
        ...state,
        ieltsCourseManagmentCourseEditFindLoading: false,
        ieltsCourseManagmentCourseEditFindSuccess: action.payload,
      };
    case IELTS_COURSEMANAGMENT_COURSE_EDITFIND_ERR:
      return {
        ...state,
        ieltsCourseManagmentCourseEditFindLoading: false,
        ieltsCourseManagmentCourseEditFinderror: action.payload,
      };
    default:
      return state;
  }
};

// courseManagment course edit reducer

export const ieltsCourseManagmentCourseEditReducer = (state = {}, action) => {
  switch (action.type) {
    case IELTS_COURSEMANAGMENT_COURSE_EDIT_REQUEST:
      return {
        ...state,
        ieltsCourseManagmentCourseEditLoading: true,
      };
    case IELTS_COURSEMANAGMENT_COURSE_EDIT_SUCCESS:
      return {
        ...state,
        ieltsCourseManagmentCourseEditLoading: false,
        ieltsCourseManagmentCourseEditSuccess: action.payload,
      };
    case IELTS_COURSEMANAGMENT_COURSE_EDIT_ERR:
      return {
        ...state,
        ieltsCourseManagmentCourseEditLoading: false,
        ieltsCourseManagmentCourseEditerror: action.payload,
      };
    default:
      return state;
  }
};


// LIVE CLASS ::::::::::::::::::::::::::::::

// COURSEMANAGMENT LIVECLASS CREATE REDUCER

export const ieltsCourseManagmentCourseLiveClassCreateReducer = (
  state = {},
  action
) => {
  switch (action.type) {
    case IELTS_COURSEMANAGMENT_COURSE_LIVECLASS_CREATE_REQUEST:
      return {
        ...state,
        ieltsCourseManagmentCourseLiveClassCreateLoading: true,
      };
    case IELTS_COURSEMANAGMENT_COURSE_LIVECLASS_CREATE_SUCCESS:
      return {
        ...state,
        ieltsCourseManagmentCourseLiveClassCreateLoading: false,
        ieltsCourseManagmentCourseLiveClassCreateSuccess: action.payload,
      };
    case IELTS_COURSEMANAGMENT_COURSE_LIVECLASS_CREATE_ERR:
      return {
        ...state,
        ieltsCourseManagmentCourseLiveClassCreateLoading: false,
        ieltsCourseManagmentCourseLiveClassCreateerror: action.payload,
      };
    default:
      return state;
  }
};

// COURSEMANAGMENT LIVECLASS FIND REDUCER

export const ieltsCourseManagmentCourseLiveClassFindReducer = (
  state = {},
  action
) => {
  switch (action.type) {
    case IELTS_COURSEMANAGMENT_COURSE_LIVECLASS_FIND_REQUEST:
      return {
        ...state,
        ieltsCourseManagmentCourseLiveClassFindLoading: true,
      };
    case IELTS_COURSEMANAGMENT_COURSE_LIVECLASS_FIND_SUCCESS:
      return {
        ...state,
        ieltsCourseManagmentCourseLiveClassFindLoading: false,
        ieltsCourseManagmentCourseLiveClassFindSuccess: action.payload,
      };
    case IELTS_COURSEMANAGMENT_COURSE_LIVECLASS_FIND_ERR:
      return {
        ...state,
        ieltsCourseManagmentCourseLiveClassFindLoading: false,
        ieltsCourseManagmentCourseLiveClassFinderror: action.payload,
      };
    default:
      return state;
  }
};

// COURSEMANAGMENT LIVECLASS FINDBYID REDUCER

export const ieltsCourseManagmentCourseLiveClassFindByIdReducer = (
  state = {},
  action
) => {
  switch (action.type) {
    case IELTS_COURSEMANAGMENT_COURSE_LIVECLASS_FINDBYID_REQUEST:
      return {
        ...state,
        ieltsCourseManagmentCourseLiveClassFindByIdLoading: true,
      };
    case IELTS_COURSEMANAGMENT_COURSE_LIVECLASS_FINDBYID_SUCCESS:
      return {
        ...state,
        ieltsCourseManagmentCourseLiveClassFindByIdLoading: false,
        ieltsCourseManagmentCourseLiveClassFindByIdSuccess: action.payload,
      };
    case IELTS_COURSEMANAGMENT_COURSE_LIVECLASS_FINDBYID_ERR:
      return {
        ...state,
        ieltsCourseManagmentCourseLiveClassFindByIdLoading: false,
        ieltsCourseManagmentCourseLiveClassFindByIderror: action.payload,
      };
    default:
      return state;
  }
};

// COURSEMANAGMENT LIVECLASS STATUSCHANGE REDUCER

export const ieltsCourseManagmentCourseLiveClassStatusChangeReducer = (
  state = {},
  action
) => {
  switch (action.type) {
    case IELTS_COURSEMANAGMENT_COURSE_LIVECLASS_STATUSCHANGE_REQUEST:
      return {
        ...state,
        ieltsCourseManagmentCourseLiveClassStatusChangeLoading: true,
      };
    case IELTS_COURSEMANAGMENT_COURSE_LIVECLASS_STATUSCHANGE_SUCCESS:
      return {
        ...state,
        ieltsCourseManagmentCourseLiveClassStatusChangeLoading: false,
        ieltsCourseManagmentCourseLiveClassStatusChangeSuccess: action.payload,
      };
    case IELTS_COURSEMANAGMENT_COURSE_LIVECLASS_STATUSCHANGE_ERR:
      return {
        ...state,
        ieltsCourseManagmentCourseLiveClassStatusChangeLoading: false,
        ieltsCourseManagmentCourseLiveClassStatusChangeerror: action.payload,
      };
    default:
      return state;
  }
};

export const ieltsCourseManagmentCourseLiveClassUpdateReducer = (
  state = {},
  action
) => {
  switch (action.type) {
    case IELTS_COURSEMANAGMENT_COURSE_LIVECLASS_UPDATE_REQUEST:
      return {
        ...state,
        ieltsCourseManagmentCourseLiveClassUpdateLoading: true,
      };
    case IELTS_COURSEMANAGMENT_COURSE_LIVECLASS_UPDATE_SUCCESS:
      return {
        ...state,
        ieltsCourseManagmentCourseLiveClassUpdateLoading: false,
        ieltsCourseManagmentCourseLiveClassUpdateSuccess: action.payload,
      };
    case IELTS_COURSEMANAGMENT_COURSE_LIVECLASS_UPDATE_ERR:
      return {
        ...state,
        ieltsCourseManagmentCourseLiveClassUpdateLoading: false,
        ieltsCourseManagmentCourseLiveClassUpdateerror: action.payload,
      };
    default:
      return state;
  }
};

export const ieltsCourseManagmentCourseLiveClassDeleteReducer = (
  state = {},
  action
) => {
  switch (action.type) {
    case IELTS_COURSEMANAGMENT_COURSE_LIVECLASS_DELETE_REQUEST:
      return {
        ...state,
        ieltsCourseManagmentCourseLiveClassDeleteLoading: true,
      };
    case IELTS_COURSEMANAGMENT_COURSE_LIVECLASS_DELETE_SUCCESS:
      return {
        ...state,
        ieltsCourseManagmentCourseLiveClassDeleteLoading: false,
        ieltsCourseManagmentCourseLiveClassDeleteSuccess: action.payload,
      };
    case IELTS_COURSEMANAGMENT_COURSE_LIVECLASS_DELETE_ERR:
      return {
        ...state,
        ieltsCourseManagmentCourseLiveClassDeleteLoading: false,
        ieltsCourseManagmentCourseLiveClassDeleteerror: action.payload,
      };
    default:
      return state;
  }
};
