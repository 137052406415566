import { Alert, AlertTitle, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { MasterTable } from "../master/MasterTable";
import { useNavigate } from "react-router-dom";
import { bannerListAction } from "../../../actions/bannerAction";
import usePrivilegeCheck from "../../../constants/customHooks/UsePrivilegeCheck";
import PremissionDialog from "../../customComponent/premissionDialog";

function BannerMaster() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [bannerData, setBannerData] = useState([]);

  const { privilege, hasPrivilege, isCreatePrevilage } = usePrivilegeCheck();

  const [openAlert, setOpenAlert] = useState(false);

  // HandleClose dialog

  const handleCloseDialog = () => {
    setOpenAlert(false);
  };

  //find banner list

  let { bannerFindSuccess, bannerFinderror } = useSelector((state) => {
    return state.bannerFind;
  });

  let { bannerDeleteSuccess, bannerDeleteerror } = useSelector((state) => {
    return state.bannerDelete;
  });

  useEffect(() => {
    dispatch(bannerListAction());
  }, [dispatch, bannerDeleteSuccess]);

  useEffect(() => {
    if (bannerFindSuccess) {
      setBannerData(bannerFindSuccess.data);
    }
  }, [bannerFindSuccess]);

  const data = bannerData?.map((data, index) => ({
    id: data.id,
    sl: index + 1,
    column1: data.bannerImage,
    column2: data.orderNumber,
    column3: data.updatedAt.split("T")[0],
  }));

  // const [editData, setEdiData] = useState([]);
  // const [editForm, setEditFormOpen] = useState(false);

  // let {
  //   branchMasterFindLoading,
  //   branchMasterFindSuccess,
  //   branchMasterFindErr,
  // } = useSelector((state) => {
  //   return state.findBranchMaster;
  // });

  // let {
  //   branchMasterAddLoading,
  //   branchMasterAddSuccess,
  //   branchMasterAddErr,
  // } = useSelector((state) => {
  //   return state.branchMasterAdd;
  // });

  // let { branchMasterDeleteSuccess } = useSelector((state) => {
  //   return state.branchMasterDelete;
  // });

  // let { branchMasterEditSuccess } = useSelector((state) => {
  //   return state.branchMasterEdit;
  // });

  // const { successAlertMsgSuccess, successAlertMsgErr } = useSelector(
  //   (state) => {
  //     return state.successTrueMsg;
  //   }
  // );

  // const [addForm, setAddFormOpen] = useState(false);

  // useEffect(() => {
  //   if (successAlertMsgSuccess && !successAlertMsgErr) {
  //     Swal.fire("Good job!", `created a new branch`, "success");
  //     dispatch(successTrueMsgAction(false));

  //     dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: false });
  //   }
  // }, [successAlertMsgSuccess, dispatch]);

  // useEffect(() => {
  //   if (successAlertMsgErr) {
  //     Swal.fire({
  //       icon: "error",
  //       title: "Oops...",
  //       text: "Something went wrong!",
  //       footer: `<p>${branchMasterAddErr}</p>`,
  //     });

  //     dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: false });
  //   }
  // }, [successAlertMsgErr, branchMasterAddErr, dispatch]);

  // useEffect(() => {
  //   dispatch(findBranchMasterAction());
  // }, [
  //   branchMasterAddSuccess,
  //   branchMasterDeleteSuccess,
  //   branchMasterEditSuccess,
  //   dispatch,
  // ]);

  // useEffect(() => {
  //   if (branchMasterFindSuccess) {
  //     setBranchData(branchMasterFindSuccess);
  //   }
  // }, [branchMasterFindSuccess]);

  // const data = branchData?.map((data, index) => ({
  //   id: data.id,
  //   sl: index + 1,
  //   column1: data.branchName,
  //   column2: data.location,
  //   column3: data.phoneNumber,
  //   countryCode: data.countryCode,
  //   branchEmail: data.branchEmail,
  // }));

  const columns = [
    {
      title: "Sl no",
      field: "sl",
      // type: "numeric",
      // hidden: true,
      headerStyle: { backgroundColor: "#F9F9FB" },
    },
    {
      title: "IMAGE",
      field: "IMAGE",
      //   hidden: showEmployeeNameColumn,
      headerStyle: { backgroundColor: "#F9F9FB" },
    },
    {
      title: "IMAGE ORDER",
      field: "IMAGE ORDER",
      //   hidden: showEmployeeNameColumn,
      headerStyle: { backgroundColor: "#F9F9FB" },
    },
    {
      title: "UPLOADED DATE",
      field: "UPLOADED DATE",
      //   hidden: showEmployeeNameColumn,
      headerStyle: { backgroundColor: "#F9F9FB" },
    },
    {
      title: "ACTION",
      field: "ACTION",
      //   hidden: showEmployeeNameColumn,
      headerStyle: { backgroundColor: "#F9F9FB" },
    },
  ];

  // const handleModalClick = () => {
  //   setAddFormOpen(true);
  // };

  // const addMasterCategryHandler = () => {
  //   setAddFormOpen(false);
  //   setEditFormOpen(false);
  // };

  // const rollEditHandler = (id) => {
  //   setEditFormOpen(true);
  //   let filterEditData = data.filter((branch) => branch.id === id);
  //   console.log(filterEditData);
  //   setEdiData(filterEditData);
  // };

  if (!hasPrivilege("banner") && privilege !== null) {
    return (
      <Alert severity="error">
        <AlertTitle>warning</AlertTitle>
        You don't have permission to access — <strong>this page.!</strong>
      </Alert>
    );
  }

  console.log(
    hasPrivilege("banner"),
    "hasPrivilege",
    privilege,
    "hasPrivilegehasPrivilegehasPrivilege"
  );

  return (
    <>
      <Button
        sx={{
          color: "white",
          backgroundColor: "#141E3C",
          height: "38px",
          width: "143px",
          fontWeight: 700,
          fontSize: "12px",
          display: "flex",
          marginLeft: "auto",
          borderRadius: "8px",
          "&:hover": {
            backgroundColor: "rgba(20, 30, 60, 0.9)",
          },
        }}
        onClick={() => {
          if (isCreatePrevilage("banner")) {
            navigate("/Settings/addBanner");
          } else {
            setOpenAlert(true);
          }
        }}
      >
        Add New
      </Button>

      <div style={{ marginTop: "15px" }}></div>

      <MasterTable
        data={data}
        columns={columns}
        component="bannerMaster"
        subMenu="banner"
        // rollEditHandler={rollEditHandler}
      />

      {/* add master component */}
      {/* {addForm && (
        <AddBranchForm
          status={addForm}
          addMasterCategryHandler={addMasterCategryHandler}
        />
      )}

      {editForm && (
        <EditBranchForm
          status={editForm}
          addMasterCategryHandler={addMasterCategryHandler}
          data={editData}
        />
      )} */}

      {openAlert && (
        <PremissionDialog
          openAlert={openAlert}
          handleClose={handleCloseDialog}
        />
      )}
    </>
  );
}

export default BannerMaster;
