import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
// import PartnerSearch from "./PartnerSearch";
import ApplicationsTable from "./ApplicationsTable";
import DownloadExcel from "../../extraComponents/DownloadExcel";
import PartnerSearch from "../../Partner/PartnerSearch";
import { findAllLeadApplicationAction } from "../../../actions/aplicationActions/applicationsAction";
import { Alert, AlertTitle, Pagination } from "@mui/material";
import usePrivilegeCheck from "../../../constants/customHooks/UsePrivilegeCheck";

// material Table
const columns = [
  {
    title: "Sl no",
    field: "sl",
  },
  {
    title: "Student name",
    field: "studentName",
    hidden: false,
  },
  {
    title: "Country",
    field: "country",
    hidden: false,
  },
  {
    title: "Course",
    field: "course",
    hidden: false,
  },
  {
    title: "University",
    field: "University",
    hidden: false,
  },
  {
    title: "UNIVERSITY handler",
    field: "universityHandler",
    hidden: false,
  },
  {
    title: "Intake",
    field: "intake",
    hidden: false,
  },
  {
    title: "status",
    field: "status",
    hidden: false,
  },

  {
    title: "declaration form",
    field: "declarationForm",
    hidden: false,
  },
  {
    title: "action",
    field: "action",
    filtering: false,
  },
];

function DeclarationApproval() {
  const dispatch = useDispatch();
  const { privilege, hasPrivilege } = usePrivilegeCheck();

  const [data, setData] = useState([]);
  // const [applicationData, setApplicationData] = useState([]);
  const [applicationData, setApplicationData] = useState([]);
  const [filterPassData, setFilterPassData] = useState();
  const [columnName, setColumnName] = useState("");
  const [whichFilter, setwhichFilter] = useState();
  const [reset, setReset] = useState(false);
  const [paginationPageNumber, setPaginationPageNumber] = useState(1);
  const [leadId, setLeadId] = useState([]);
  const [ids, setIds] = useState([]);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pagiantionEntry, setPagiantionEntry] = useState(8);


  // / Employee column Visibility
  const [showNameColumn, setShowNameColumn] = useState(false);
  const [showEmailColumn, setShowEmailColumn] = useState(false);
  const [showPhoneColumn, setShowPhoneColumn] = useState(false);
  const [showWhatsAppNumColumn, setShowWhatsAppNumColumn] = useState(false);
  const [showPrefferedColumn, setShowPrefferedColumn] = useState(false);
  const [showLeadSourceColumn, setShowLeadSourceColumn] = useState(false);
  const [showLeadTypeColumn, setShowLeadTypeColumn] = useState(false);
  const [showStatusColumn, setShowStatusColumn] = useState(false);
  const [showEducationColumn, setShowEducationColumn] = useState(false);
  const [showUniversityPlaceColumn, setShowUniversityPlaceColumn] = useState(
    false
  );

  let { applicationAllFindSuccess } = useSelector((state) => {
    return state.findAllLeadApplication;
  });

  let { applicationDeclarationApproveSuccess } = useSelector((state) => {
    return state.applicationDeclarationApprove;
  });

  useEffect(() => {
    if (applicationAllFindSuccess) {
      setData(applicationAllFindSuccess?.data);

      setApplicationData(
        applicationAllFindSuccess?.data?.map((item) => ({
          id: item.id,
          column1: item.lead?.firstName,
          column2: item?.universityCountry,
          column3: item?.courseName,
          column4: item?.universityName,
          column5: item?.user !== null ? item?.user?.firstName : "Not Assigned",
          column6: item.intakeDate,
          column7: item.methodStatus,
          column8: item.declarationDocument,
          column9: item.isDeclarationFormApproved,
          leadId: item.leadId,
        }))
      );
    }
  }, [applicationAllFindSuccess, page]);

  useEffect(() => {
    dispatch(findAllLeadApplicationAction(page, "declarationApprove"));
  }, [dispatch, page, applicationDeclarationApproveSuccess]);

  console.log(
    applicationAllFindSuccess,
    "applicationAllFindSuccessapplicationAllFindSuccess"
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    alert(event.target.value);
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1); // Reset page number when changing rows per page
  };

  const toggleNameColumn = (status, column) => {
    if (column === "name") {
      setColumnName(column);
      setShowNameColumn(status);
    }

    if (column === "email") {
      setColumnName(column);
      setShowEmailColumn(status);
    }

    if (column === "mobile") {
      setColumnName(column);
      setShowPhoneColumn(status);
    }

    if (column === "whatsAppNumber") {
      setColumnName(column);
      setShowWhatsAppNumColumn(status);
    }

    if (column === "prefferedCountry") {
      setColumnName(column);
      setShowPrefferedColumn(status);
    }
    if (column === "leadSource") {
      setColumnName(column);
      setShowLeadSourceColumn(status);
    }

    if (column === "leadType") {
      setColumnName(column);
      setShowLeadTypeColumn(status);
    }

    if (column === "status") {
      setColumnName(column);
      setShowStatusColumn(status);
    }

    if (column === "education") {
      setColumnName(column);
      setShowEducationColumn(status);
    }

    if (column === "universityPlace") {
      setColumnName(column);
      setShowUniversityPlaceColumn(status);
    }
  };

  // excel download
  const excelDownloadClick = () => {
    DownloadExcel(columns, data, "Partner_Managment");
  };

  // searching
  const searchHandleChange = (e) => {
    if (e.target.value !== "" && e.target.value !== " ") {
      setApplicationData(
        applicationData?.filter((item) => {
          const name = item.column1 ? item.column1.toLowerCase() : "";
          const email = item.column2 ? item.column2.toLowerCase() : "";
          const phone = item.column3 ? item.column3.toLowerCase() : "";
          const manager = item.column4 ? item.column4.toLowerCase() : "";
          const preferredCountry = item.column5
            ? item.column5.toLowerCase()
            : "";
          const leadSource = item.column6 ? item.column6.toLowerCase() : "";
          // const leadOwner = item.leadOwner ? item.leadOwner.toLowerCase() : "";
          // const status = item.status ? item.status.toLowerCase() : "";
          // const education = item.education ? item.education.toLowerCase() : "";

          // const leadCity = item.leadCity ? item.leadCity.toLowerCase() : "";
          // const leadBranch = item.branchMaster
          //   ? item.leadCity.toLowerCase()
          //   : "";

          // :lead?.branch_master?.branchName
          return (
            name.includes(e.target.value.toLowerCase()) ||
            phone.includes(e.target.value.toLowerCase()) ||
            manager.includes(e.target.value.toLowerCase()) ||
            email.includes(e.target.value.toLowerCase()) ||
            preferredCountry.includes(e.target.value.toLowerCase()) ||
            leadSource.includes(e.target.value.toLowerCase())
            // leadOwner.includes(e.target.value.toLowerCase()) ||
            // status.includes(e.target.value.toLowerCase()) ||
            // education.includes(e.target.value.toLowerCase()) ||
            // leadCity.includes(e.target.value.toLowerCase()) ||
            // leadBranch.includes(e.target.value.toLowerCase())
          );
        })
      );
    } else {
      setApplicationData(
        data.map((item) => ({
          id: item.id,
          column1: item.lead?.firstName,
          column2: item?.universityCountry,
          column3: item?.courseName,
          column4: item?.universityName,
          column5: item?.user !== null ? item?.user?.firstName : "Not Assigned",
          column6: item.intakeDate,
          column7: item.methodStatus,
          column8: item.declarationDocument,
          column9: item.isDeclarationFormApproved,
          leadId: item.leadId,
        }))
      );
    }
  };

  // cleare filter
  const handleResetData = (status) => {
    setwhichFilter(null);
    setFilterPassData(null);
    setReset(!reset);
    setwhichFilter(null);
    setFilterPassData(null);
    setPaginationPageNumber(1);
  };

  const handleDeleteClick = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        // dispatch(deleteLeadAction(leadId));
      }
    });
  };

  if (!hasPrivilege("Declaration approval") && privilege !== null) {
    return (
      <Alert severity="error">
        <AlertTitle>warning</AlertTitle>
        You don't have permission to access — <strong>this page.!</strong>
      </Alert>
    );
  }

  return (
    <>
      <PartnerSearch
        columnVisibleHandler={toggleNameColumn}
        excelDownloadClick={excelDownloadClick}
        showNameColumn={showNameColumn}
        showEmailColumn={showEmailColumn}
        showPrefferedColumn={showPrefferedColumn}
        showPhoneColumn={showPhoneColumn}
        showWhatsAppNumColumn={showWhatsAppNumColumn}
        showLeadSourceColumn={showLeadSourceColumn}
        showLeadTypeColumn={showLeadTypeColumn}
        showStatusColumn={showStatusColumn}
        showEducationColumn={showEducationColumn}
        showUniversityPlaceColumn={showUniversityPlaceColumn}
        filterPassData={filterPassData ? filterPassData : null}
        whichFilter={whichFilter ? whichFilter : null}
        handleResetData={handleResetData}
        handleDeleteClick={handleDeleteClick}
        searchHandleChange={searchHandleChange}
        ids={ids}
        leadId={leadId}
      />
      <ApplicationsTable
        columns={columns}
        data={applicationData}
        component="declarationApprove"
        pagiantionEntry={pagiantionEntry}
        paginationPageNumber={paginationPageNumber}
        subMenu="Declaration approval"
      />

      {/* <TablePagination
        rowsPerPageOptions={[10, 25, 50, 100]} // Customize the rows per page options as needed
        component="div"
        count={applicationData.length} // Total number of rows
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      /> */}

      <Pagination
        sx={{
          mt: "28px",
          "& .Mui-selected": {
            backgroundColor: "#f81b82 !important",
            color: "#fff",
          },
          "& .MuiPagination-ul": {
            justifyContent: "end",
          },
        }}
        rowsPerPageOptions={[10, 25, 50, 100]} // Customize the rows per page options as needed
        component="div"
        count={applicationAllFindSuccess?.totalPages} // Total number of rows
        rowsPerPage={applicationAllFindSuccess?.currentPage}
        page={page}
        onChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
}

export default DeclarationApproval;
