import axios from "axios";
import {
  FOLLOW_UP_ADD_LINK_ERR,
  FOLLOW_UP_ADD_LINK_REQUEST,
  FOLLOW_UP_ADD_LINK_SUCCESS,
  FOLLOW_UP_ATTACHMENT_FIND_ERR,
  FOLLOW_UP_ATTACHMENT_FIND_REQUEST,
  FOLLOW_UP_ATTACHMENT_FIND_SUCCESS,
  FOLLOW_UP_COMPOSE_MAIL_ERR,
  FOLLOW_UP_COMPOSE_MAIL_FIND_ERR,
  FOLLOW_UP_COMPOSE_MAIL_FIND_REQUEST,
  FOLLOW_UP_COMPOSE_MAIL_FIND_SUCCESS,
  FOLLOW_UP_COMPOSE_MAIL_REQUEST,
  FOLLOW_UP_COMPOSE_MAIL_SUCCESS,
  FOLLOW_UP_FIND_LINK_ERR,
  FOLLOW_UP_FIND_LINK_REQUEST,
  FOLLOW_UP_FIND_LINK_SUCCESS,
  FOLLOW_UP_NOTE_FIND_ERR,
  FOLLOW_UP_NOTE_FIND_REQUEST,
  FOLLOW_UP_NOTE_FIND_SUCCESS,
  FOLLOW_UP_SOCIAL_MEDIA_ADD_ERR,
  FOLLOW_UP_SOCIAL_MEDIA_ADD_REQUEST,
  FOLLOW_UP_SOCIAL_MEDIA_ADD_SUCCESS,
  FOLLOW_UP_SOCIAL_MEDIA_FIND_ERR,
  FOLLOW_UP_SOCIAL_MEDIA_FIND_REQUEST,
  FOLLOW_UP_SOCIAL_MEDIA_FIND_SUCCESS,
  FOLLOW_UP_STATUS_CHANGE_ERR,
  FOLLOW_UP_STATUS_CHANGE_REQUEST,
  FOLLOW_UP_STATUS_CHANGE_SUCCESS,
  FOLLOW_UP_TASK_ADD_ERR,
  FOLLOW_UP_TASK_ADD_REQUEST,
  FOLLOW_UP_TASK_ADD_SUCCESS,
  FOLLOW_UP_TASK_EDIT_ERR,
  FOLLOW_UP_TASK_EDIT_REQUEST,
  FOLLOW_UP_TASK_EDIT_SUCCESS,
  FOLLOW_UP_TASK_FIND_ERR,
  FOLLOW_UP_TASK_FIND_REQUEST,
  FOLLOW_UP_TASK_FIND_SUCCESS,
} from "../../constants/leadConstant";

// followUpTaskInformationAddAction
export const followUpActivitiesAddActionAction =
  (
    subject,
    dueData,
    priority,
    owner,
    status,
    reminder,
    repeat,
    meetLink,
    description,
    id,
    Dailogcomponent
  ) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: FOLLOW_UP_TASK_ADD_REQUEST });

      let isUserExist = localStorage.getItem("loginInfo")
        ? JSON.parse(localStorage.getItem("loginInfo"))
        : null;

      const config = {
        headers: {
          Authorization: `Bearer ${isUserExist?.token}`,
        },
      };
      let createdBy = isUserExist?.name;
      let UserId = isUserExist?.UserId;

      console.log({
        subject:subject,
        dueData:dueData,
        priority:priority,
        owner:owner,
        status:status,
        reminder:reminder,
        repeat:repeat,
        meetLink:meetLink,
        description:description,
        leadId:id,
        createdBy:createdBy,
        Dailogcomponent:Dailogcomponent,
        UserId:UserId,
      },"*******************");

      let { data } = await axios.post(
        "/api/admin/lead/followUpActivitiesAdd",
        {
          subject: subject,
          dueData: dueData,
          priority: priority,
          owner: owner,
          status: status,
          reminder: reminder,
          repeat: repeat,
          meetLink: meetLink,
          description: description,
          leadId: id,
          createdBy: createdBy,
          Dailogcomponent: Dailogcomponent,
          UserId: UserId,
        },
        config
      );

      dispatch({
        type: FOLLOW_UP_TASK_ADD_SUCCESS,
        payload: data,
      });
    } catch (error) {
      console.log(error.response, "error.response");
      dispatch({
        type: FOLLOW_UP_TASK_ADD_ERR,
        payload: error.response.data,
      });
    }
  };

// followUpTaskInformationAddAction
export const followUpActivitiesEditAction =
  (
    id,
    subject,
    dueData,
    priority,
    owner,
    status,
    reminder,
    repeat,
    meetLink,
    description,
    leadId,
    Dailogcomponent
  ) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: FOLLOW_UP_TASK_EDIT_REQUEST });

      let isUserExist = localStorage.getItem("loginInfo")
        ? JSON.parse(localStorage.getItem("loginInfo"))
        : null;

      const config = {
        headers: {
          Authorization: `Bearer ${isUserExist?.token}`,
        },
      };
      let createdBy = isUserExist?.name;
      let UserId = isUserExist?.UserId;

      let { data } = await axios.post(
        "/api/admin/lead/followUpActivitiesUpdate",
        {
          id: id,
          subject: subject,
          dueData: dueData,
          priority: priority,
          owner: owner,
          status: status,
          reminder: reminder,
          repeat: repeat,
          meetLink: meetLink,
          description: description,
          leadId: leadId,
          createdBy: createdBy,
          Dailogcomponent: Dailogcomponent,
          UserId: UserId,
        },
        config
      );

      dispatch({
        type: FOLLOW_UP_TASK_EDIT_SUCCESS,
        payload: data,
      });
    } catch (error) {
      console.log(error.response, "error.response");
      dispatch({
        type: FOLLOW_UP_TASK_EDIT_ERR,
        payload: error.response.data,
      });
    }
  };

// followUpTaskInformationFindAction
export const followUpTaskInformationFindAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: FOLLOW_UP_TASK_FIND_REQUEST });

      let isUserExist = localStorage.getItem("loginInfo")
        ? JSON.parse(localStorage.getItem("loginInfo"))
        : null;

      const config = {
        headers: {
          Authorization: `Bearer ${isUserExist?.token}`,
        },
      };
      let userId = isUserExist?.UserId;
      
      let { data } = await axios.get(
        `/api/admin/lead/followUpActivitiesFind?id=${id}&userId=${userId}`,
        config
      );

      dispatch({
        type: FOLLOW_UP_TASK_FIND_SUCCESS,
        payload: data,
      });
    } catch (error) {
      console.log(error.response, "error.response");
      dispatch({
        type: FOLLOW_UP_TASK_FIND_ERR,
        payload: error.response.data,
      });
    }
  };

// activityStatusChangeAction
export const activityStatusChangeAction =
  (id, status) => async (dispatch, getState) => {
    try {
      dispatch({ type: FOLLOW_UP_STATUS_CHANGE_REQUEST });

      let isUserExist = localStorage.getItem("loginInfo")
        ? JSON.parse(localStorage.getItem("loginInfo"))
        : null;

      const config = {
        headers: {
          Authorization: `Bearer ${isUserExist?.token}`,
        },
      };
      let createdBy = isUserExist?.name;
      let { data } = await axios.put(
        `/api/admin/lead/followUpActivitiesStatusChange?id=${id}&createdBy=${createdBy}`,
        { status },
        config
      );

      dispatch({
        type: FOLLOW_UP_STATUS_CHANGE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      console.log(error.response, "error.response");
      dispatch({
        type: FOLLOW_UP_STATUS_CHANGE_ERR,
        payload: error.response.data,
      });
    }
  };

// followUpActivitiesAddAction
export const followUpActivitiesAddAction =
  (description, meetLink, id, section) => async (dispatch, getState) => {
    try {
      dispatch({ type: FOLLOW_UP_SOCIAL_MEDIA_ADD_REQUEST });

      let isUserExist = localStorage.getItem("loginInfo")
        ? JSON.parse(localStorage.getItem("loginInfo"))
        : null;

      const config = {
        headers: {
          Authorization: `Bearer ${isUserExist?.token}`,
        },
      };
      let createdBy = isUserExist?.name;
      let { data } = await axios.post(
        "/api/admin/lead/followUpActivitiesSocialMediaLinkAdd",
        {
          link: description,
          socialNetwork: meetLink,
          createdBy: createdBy,
          leadId: id,
          section: section,
        },
        config
      );

      dispatch({
        type: FOLLOW_UP_SOCIAL_MEDIA_ADD_SUCCESS,
        payload: data,
      });
    } catch (error) {
      console.log(error.response, "error.response");
      dispatch({
        type: FOLLOW_UP_SOCIAL_MEDIA_ADD_ERR,
        payload: error.response.data,
      });
    }
  };

// followUpActivitiesFindAction
export const followUpActivitiesFindAction =
  (id, section) => async (dispatch, getState) => {
    try {
      dispatch({ type: FOLLOW_UP_SOCIAL_MEDIA_FIND_REQUEST });

      let isUserExist = localStorage.getItem("loginInfo")
        ? JSON.parse(localStorage.getItem("loginInfo"))
        : null;

      const config = {
        headers: {
          Authorization: `Bearer ${isUserExist?.token}`,
        },
      };
      // let createdBy = isUserExist?.name;
      let { data } = await axios.get(
        `/api/admin/lead/followUpActivitiesLinkFind?id=${id}&section=${section}`,
        config
      );

      dispatch({
        type: FOLLOW_UP_SOCIAL_MEDIA_FIND_SUCCESS,
        payload: data,
      });
    } catch (error) {
      console.log(error.response, "error.response");
      dispatch({
        type: FOLLOW_UP_SOCIAL_MEDIA_FIND_ERR,
        payload: error.response.data,
      });
    }
  };

// followUpActivitiesFindAction
export const followUpActivitiesNoteFindAction =
  (id, section) => async (dispatch, getState) => {
    try {
      dispatch({ type: FOLLOW_UP_NOTE_FIND_REQUEST });

      let isUserExist = localStorage.getItem("loginInfo")
        ? JSON.parse(localStorage.getItem("loginInfo"))
        : null;

      const config = {
        headers: {
          Authorization: `Bearer ${isUserExist?.token}`,
        },
      };
      // let createdBy = isUserExist?.name;
      let { data } = await axios.get(
        `/api/admin/lead/followUpActivitiesNoteFind?id=${id}&section=${section}`,
        config
      );

      dispatch({
        type: FOLLOW_UP_NOTE_FIND_SUCCESS,
        payload: data,
      });
    } catch (error) {
      console.log(error.response, "error.response");
      dispatch({
        type: FOLLOW_UP_NOTE_FIND_ERR,
        payload: error.response.data,
      });
    }
  };

// followUpActivitiesattAchmentFindAction
export const followUpActivitiesattAchmentFindAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: FOLLOW_UP_ATTACHMENT_FIND_REQUEST });

      let isUserExist = localStorage.getItem("loginInfo")
        ? JSON.parse(localStorage.getItem("loginInfo"))
        : null;

      const config = {
        headers: {
          Authorization: `Bearer ${isUserExist?.token}`,
        },
      };
      // let createdBy = isUserExist?.name;
      let { data } = await axios.get(
        `/api/admin/lead/followUpActivitiesattAchmentFind?id=${id}`,
        config
      );

      dispatch({
        type: FOLLOW_UP_ATTACHMENT_FIND_SUCCESS,
        payload: data,
      });
    } catch (error) {
      console.log(error.response, "error.response");
      dispatch({
        type: FOLLOW_UP_ATTACHMENT_FIND_ERR,
        payload: error.response.data,
      });
    }
  };

//  followUpComposeMailAction
export const followUpComposeMailAction =
  (emailTo, emailCc, bcc, subject, htmlContent, leadId) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: FOLLOW_UP_COMPOSE_MAIL_REQUEST });

      let isUserExist = localStorage.getItem("loginInfo")
        ? JSON.parse(localStorage.getItem("loginInfo"))
        : null;

      const config = {
        headers: {
          Authorization: `Bearer ${isUserExist?.token}`,
        },
      };
      let sentBy = isUserExist?.name;
      let { data } = await axios.post(
        "/api/admin/lead/followUpActivitiesComposeMail",
        { emailTo, emailCc, bcc, subject, htmlContent, leadId, sentBy },
        config
      );

      dispatch({
        type: FOLLOW_UP_COMPOSE_MAIL_SUCCESS,
        payload: data,
      });
    } catch (error) {
      console.log(error.response, "error.response");
      dispatch({
        type: FOLLOW_UP_COMPOSE_MAIL_ERR,
        payload: error.response.data,
      });
    }
  };

//  followUpComposeMailAction
export const followUpComposeMailFindAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: FOLLOW_UP_COMPOSE_MAIL_FIND_REQUEST });

      let isUserExist = localStorage.getItem("loginInfo")
        ? JSON.parse(localStorage.getItem("loginInfo"))
        : null;

      const config = {
        headers: {
          Authorization: `Bearer ${isUserExist?.token}`,
        },
      };
      // let sentBy = isUserExist?.name;
      let { data } = await axios.get(
        `/api/admin/lead/followUpActivitiesComposeMailFind?id=${id}`,
        config
      );

      dispatch({
        type: FOLLOW_UP_COMPOSE_MAIL_FIND_SUCCESS,
        payload: data,
      });
    } catch (error) {
      console.log(error.response, "error.response");
      dispatch({
        type: FOLLOW_UP_COMPOSE_MAIL_FIND_ERR,
        payload: error.response.data,
      });
    }
  };

// followUpActivitiesLinkAddAction
export const followUpActivitiesLinkAddAction =
  (link, id) => async (dispatch, getState) => {
    try {
      dispatch({ type: FOLLOW_UP_ADD_LINK_REQUEST });

      let isUserExist = localStorage.getItem("loginInfo")
        ? JSON.parse(localStorage.getItem("loginInfo"))
        : null;

      const config = {
        headers: {
          Authorization: `Bearer ${isUserExist?.token}`,
        },
      };
      let createdBy = isUserExist?.name;
      let { data } = await axios.post(
        "/api/admin/lead/followUpActivitiesAddLink",
        { link, id, createdBy },
        config
      );

      dispatch({
        type: FOLLOW_UP_ADD_LINK_SUCCESS,
        payload: data,
      });
    } catch (error) {
      console.log(error.response, "error.response");
      dispatch({
        type: FOLLOW_UP_ADD_LINK_ERR,
        payload: error.response.data,
      });
    }
  };

// followUpActivitiesLinkAddAction
export const followUpActivitiesLinkFindAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: FOLLOW_UP_FIND_LINK_REQUEST });

      let isUserExist = localStorage.getItem("loginInfo")
        ? JSON.parse(localStorage.getItem("loginInfo"))
        : null;

      const config = {
        headers: {
          Authorization: `Bearer ${isUserExist?.token}`,
        },
      };

      let { data } = await axios.get(
        `/api/admin/lead/followUpActivitiesFindLink?id=${id}`,
        config
      );

      dispatch({
        type: FOLLOW_UP_FIND_LINK_SUCCESS,
        payload: data,
      });
    } catch (error) {
      console.log(error.response, "error.response");
      dispatch({
        type: FOLLOW_UP_FIND_LINK_ERR,
        payload: error.response.data,
      });
    }
  };
