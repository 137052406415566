import React, { useEffect, useState } from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableFooter,
  TableSortLabel,
  Button,
  DialogContent,
  DialogTitle,
  Dialog,
  DialogActions,
} from "@mui/material";
import { Box, Typography } from "@mui/material";
import {
  NormalInputField,
  NormalSelect,
  SelectInputField,
} from "../../customComponent/InputField";
import CloseIcon from "@mui/icons-material/Close";
import {
  developerCreateSubMenuAction,
  developerFindAllMenu,
  developerFindAllPermissionDetails,
  developerFindAllSubMenuDetailsAction,
  developerFindSubMenuDetails,
  developerUpdateSubMenuAction,
} from "../../../actions/developersActions";
import { useDispatch, useSelector } from "react-redux";

function SubMenuTable() {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [modalData, setModalData] = useState({});
  const [menuData, setMenuData] = useState([]);
  const [data, setData] = useState([]);
  const [permission, setPermission] = useState([]);
  const [error, setError] = useState("");
  const [editOpen, setEditOpen] = useState(false);
  const [tableId, setTableId] = useState("");

  const { findAllMenuSuccess } = useSelector((state) => {
    return state.findAllMenuInDeveloperTab;
  });

  const { createSubMenuInDeveloperTabSuccess } = useSelector((state) => {
    return state.createSubMenuInDeveloperTab;
  });

  const { subMenuFindAllSuccess } = useSelector((state) => {
    return state.developerFindAllSubMenuDetails;
  });

  const { findAllPermissionsDetailsInDeveloperTabSuccess } = useSelector(
    (state) => {
      return state.findAllPermissionsDetailsInDeveloperTab;
    }
  );

  const { findSubMenuDetailsInDeveloperTabSuccess } = useSelector((state) => {
    return state.findSubMenuDetailsInDeveloperTab;
  });

  useEffect(() => {
    dispatch(developerFindAllMenu());
    dispatch(developerFindAllPermissionDetails());
  }, [dispatch]);

  useEffect(() => {
    dispatch(developerFindAllSubMenuDetailsAction());
  }, [dispatch, createSubMenuInDeveloperTabSuccess]);

  useEffect(() => {
    if (findAllMenuSuccess) {
      const menuNames = findAllMenuSuccess?.map((item) => {
        return { value: item.name, id: item.id };
      });
      setMenuData(menuNames);
    }
  }, []);

  useEffect(() => {
    console.log(
      subMenuFindAllSuccess,
      "subMenuFindAllSuccesssubMenuFindAllSuccesssubMenuFindAllSuccess"
    );
    if (subMenuFindAllSuccess) {
      setData(
        subMenuFindAllSuccess?.map((item) => ({
          menu: "Home",
          submenu: item?.name,
          createdAt: item?.createdAt,
          updatedAt: item?.updatedAt,
          path: item.path,
          id: item.id,
        }))
      );
    }
  }, [subMenuFindAllSuccess]);

  const handleClose = () => {
    setOpen(false);
    setPermission([]);
    setModalData({});
    setMenuData([]);
  };

  const handleEditOpen = (id) => {
    // dispatch(developerFindSubMenuDetails(id));
    setEditOpen(true);
    const editData = subMenuFindAllSuccess.find((value) => value.id == id);
    setModalData({
      name: editData?.name,
    });
    setTableId(id);
  };

  const handleEditClose = () => {
    setEditOpen(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(name, value, "name, value");
    setModalData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log("handleSubmit");
    dispatch(developerCreateSubMenuAction(modalData));
  };

  const handleSubMenuEditSubmit = (e) => {
    e.preventDefault();
    const formaData = {
      modalData,
      permission,
    };
    dispatch(developerUpdateSubMenuAction(formaData, tableId));
  };

  const convertToDate = (date) => {
    let timestamp = new Date(date);
    return timestamp.toLocaleDateString();
  };

  const columns = [
    {
      title: "SL NO",
    },
    {
      title: "menu",
    },
    {
      title: "submenu",
    },
    {
      title: "path",
    },
    {
      title: "created date",
    },
    {
      title: "updated date",
    },
    {
      title: "action",
    },
  ];

  // const data = [
  //   {
  //     menu: "Home",
  //     submenu: "Administration",
  //     createdAt: "Thu Apr 01 2024 12:21:51 GMT+0530 (India Standard Time) ",
  //     updatedAt: "Thu Apr 04 2024 12:00:00 GMT+0530 (India Standard Time) ",
  //     path: "/",
  //   },
  //   {
  //     menu: "Home",
  //     submenu: "Administration",
  //     createdAt: "Thu Apr 01 2024 12:21:51 GMT+0530 (India Standard Time) ",
  //     updatedAt: "Thu Apr 04 2024 12:00:00 GMT+0530 (India Standard Time) ",
  //     path: "/",
  //   },
  //   {
  //     menu: "Home",
  //     submenu: "Administration",
  //     createdAt: "Thu Apr 01 2024 12:21:51 GMT+0530 (India Standard Time) ",
  //     updatedAt: "Thu Apr 04 2024 12:00:00 GMT+0530 (India Standard Time) ",
  //     path: "/",
  //   },
  // ];

  // Handle Change for MultipleBranchSelect

  const handlePermissionChange = (event) => {
    const {
      target: { value },
    } = event;

    console.log(value);

    const filteredArray = value.filter((obj) =>
      obj.hasOwnProperty("permission")
    );

    setPermission((prevRanking) => {
      const updatedRanking = [...prevRanking];

      filteredArray.forEach((selectedItem) => {
        const existingIndex = updatedRanking.findIndex(
          (item) => item.id === selectedItem.id
        );

        if (existingIndex !== -1) {
          // Item already exists in the ranking, remove it
          updatedRanking.splice(existingIndex, 1);
        } else {
          // Item doesn't exist in the ranking, add it
          updatedRanking.push({
            name: selectedItem.permission,
            id: selectedItem.id,
          });
        }
      });

      return updatedRanking;
    });

    setError(false);
  };

  console.log(
    findSubMenuDetailsInDeveloperTabSuccess,
    "findSubMenuDetailsInDeveloperTabSuccessfindSubMenuDetailsInDeveloperTabSuccess"
  );

  console.log(
    permission,
    modalData,
    "modalDatamodalDatamodalDatamodalDatamodalData"
  );

  return (
    <div>
      <Dialog
        open={open}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        sx={{
          "& .MuiDialog-paper": {
            margin: "0",
            width: "calc(100% - 30px)",
            maxWidth: "850px",
            borderRadius: "4px",
          },
        }}
        PaperProps={{
          style: {
            alignSelf: "flex-start",
          },
        }}
      >
        <form onSubmit={handleSubmit}>
          <DialogTitle
            sx={{
              display: "flex",
              alignSelf: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography
              sx={{
                fontWeight: "700",
                fontSize: "22px",
                color: "#05050F",
              }}
            >
              Add new submenu
            </Typography>
            <CloseIcon
              sx={{
                fontSize: "30px",
                color: "#000",
                cursor: "pointer",
              }}
              onClick={handleClose}
            />
          </DialogTitle>

          <DialogContent
            sx={{
              "& input": {
                border: "1px solid #ECECEC !important",
                outline: "none",
                padding: "16px",
                width: "100%",
                boxSizing: "border-box",
                borderRadius: "4px",
              },
              "& input::placeholder": {
                color: "#ACB1C6",
              },
              "& textarea": {
                border: "1px solid #ECECEC !important",
                outline: "none",
                padding: "16px",
                width: "100%",
                boxSizing: "border-box",
                resize: "none",
                borderRadius: "4px",
                // mt: "24px",
              },
              "& textarea::placeholder": {
                color: "#ACB1C6",
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "20px",
                mt: "13px",
                "& input": {
                  boxSizing: "content-box",
                },
              }}
            >
              <NormalSelect
                label="Menu"
                value={modalData?.menu}
                name="menuId"
                options={menuData}
                mapMethod={"developer"}
                handleChange={handleChange}
              />
              <NormalInputField
                label="Title"
                name="name"
                handleChange={handleChange}
              />

              <Box
                sx={{
                  "& label": {
                    color: "rgba(0, 0, 0, 0.6) !important",
                  },
                }}
              >
                <SelectInputField
                  label="Permission"
                  handleSelectChange={handlePermissionChange}
                  widthSize="49px"
                  height="56px"
                  value={permission}
                  mapValue={findAllPermissionsDetailsInDeveloperTabSuccess}
                  mapMethod="permission"
                  multiple="true"
                />
              </Box>

              <NormalInputField
                label="Path"
                name="path"
                handleChange={handleChange}
              />
            </Box>
          </DialogContent>

          <DialogActions
            sx={{
              mb: "20px",
              mr: "15px",
            }}
          >
            <Button
              type={"text"}
              sx={{
                padding: "16px 24px",
                color: "white",
                backgroundColor: "rgba(20, 30, 60, 1)",
                borderRadius: "8px",
                fontWeight: 600,
                fontSize: "12px",
                "&:hover": {
                  backgroundColor: "rgba(20, 30, 60, 0.9)",
                },
              }}
            >
              Submit
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <Dialog
        open={editOpen}
        keepMounted
        onClose={handleEditClose}
        aria-describedby="alert-dialog-slide-description"
        sx={{
          "& .MuiDialog-paper": {
            margin: "0",
            width: "calc(100% - 30px)",
            maxWidth: "850px",
            borderRadius: "4px",
          },
        }}
        PaperProps={{
          style: {
            alignSelf: "flex-start",
          },
        }}
      >
        <form onSubmit={handleSubMenuEditSubmit}>
          <DialogTitle
            sx={{
              display: "flex",
              alignSelf: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography
              sx={{
                fontWeight: "700",
                fontSize: "22px",
                color: "#05050F",
              }}
            >
              Add new submenu
            </Typography>
            <CloseIcon
              sx={{
                fontSize: "30px",
                color: "#000",
                cursor: "pointer",
              }}
              onClick={handleClose}
            />
          </DialogTitle>

          <DialogContent
            sx={{
              "& input": {
                border: "1px solid #ECECEC !important",
                outline: "none",
                padding: "16px",
                width: "100%",
                boxSizing: "border-box",
                borderRadius: "4px",
              },
              "& input::placeholder": {
                color: "#ACB1C6",
              },
              "& textarea": {
                border: "1px solid #ECECEC !important",
                outline: "none",
                padding: "16px",
                width: "100%",
                boxSizing: "border-box",
                resize: "none",
                borderRadius: "4px",
                // mt: "24px",
              },
              "& textarea::placeholder": {
                color: "#ACB1C6",
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "20px",
                mt: "13px",
                "& input": {
                  boxSizing: "content-box",
                },
              }}
            >
              {modalData.name && (
                <NormalInputField
                  label="Title"
                  name="name"
                  value={modalData.name}
                  handleChange={handleChange}
                />
              )}

              <Box
                sx={{
                  "& label": {
                    color: "rgba(0, 0, 0, 0.6) !important",
                  },
                }}
              >
                <SelectInputField
                  label="Permission"
                  handleSelectChange={handlePermissionChange}
                  widthSize="49px"
                  height="56px"
                  value={permission}
                  mapValue={findAllPermissionsDetailsInDeveloperTabSuccess}
                  mapMethod="permission"
                  multiple="true"
                />
              </Box>
            </Box>
          </DialogContent>

          <DialogActions
            sx={{
              mb: "20px",
              mr: "15px",
            }}
          >
            <Button
              type={"text"}
              sx={{
                padding: "16px 24px",
                color: "white",
                backgroundColor: "rgba(20, 30, 60, 1)",
                borderRadius: "8px",
                fontWeight: 600,
                fontSize: "12px",
                "&:hover": {
                  backgroundColor: "rgba(20, 30, 60, 0.9)",
                },
              }}
            >
              Submit
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
        }}
      >
        <Button
          sx={{
            backgroundColor: "#141E3C !important",
            color: "#fff",
            fontWeight: "700",
            textTransform: "capitalize",
            px: "15px",
            mb: "20px",
          }}
          onClick={() => setOpen(true)}
        >
          Create
        </Button>
      </Box>
      <div
        style={{
          overflowX: "scroll",
          border: "0px solid #ffff",
        }}
        className="scrollBar"
      >
        <Table className="EmployeeJobTable">
          <TableHead>
            <TableRow className="EmployeeJobTableCell">
              {columns?.map((column) => {
                return (
                  <TableCell
                    key={column.field}
                    className="EmployeeJobTableCell LeadCommon"
                    style={{ padding: "10px 16px", whiteSpace: "nowrap" }}
                  >
                    <TableSortLabel
                      className="LeadHeaderText"
                      sx={{
                        textTransform: "uppercase",
                        fontWeight: "700",
                        color: "#666E7D",
                      }}
                    >
                      {column.title}
                    </TableSortLabel>
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>

          <TableBody>
            {data?.map((item, indx) => (
              <TableRow className="EmployeeJobTable" key={indx}>
                <TableCell style={{ padding: "0 16px", whiteSpace: "nowrap" }}>
                  {indx + 1}
                </TableCell>

                <TableCell style={{ padding: "0 16px", whiteSpace: "nowrap" }}>
                  {item.menu}
                </TableCell>

                <TableCell style={{ padding: "0 16px", whiteSpace: "nowrap" }}>
                  {item.submenu}
                </TableCell>

                <TableCell style={{ padding: "0 16px", whiteSpace: "nowrap" }}>
                  {item.path}
                </TableCell>

                <TableCell style={{ padding: "0 16px", whiteSpace: "nowrap" }}>
                  {convertToDate(item.createdAt)}
                </TableCell>

                <TableCell style={{ padding: "0 16px", whiteSpace: "nowrap" }}>
                  {convertToDate(item.updatedAt)}
                </TableCell>

                <TableCell
                  style={{
                    padding: "5px 16px",
                    whiteSpace: "nowrap",
                    display: "flex",
                    gap: "10px",
                    alignItems: "center",
                  }}
                >
                  <Button
                    sx={{
                      backgroundColor: "#141E3C !important",
                      color: "#fff",
                      textTransform: "capitalize",
                      py: "4px",
                      px: "8px",
                    }}
                    onClick={() => handleEditOpen(item.id)}
                  >
                    Edit
                  </Button>
                  <Button
                    sx={{
                      backgroundColor: "#fff !important",
                      color: "#FF3333",
                      textTransform: "capitalize",
                      border: "1px solid #FF3333",
                      py: "4px",
                      px: "8px",
                    }}
                  >
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>

          <TableFooter />
        </Table>
      </div>
    </div>
  );
}

export default SubMenuTable;
