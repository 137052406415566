import React, { useEffect, useState } from "react";

import AddIcon from "@mui/icons-material/Add";
import { Box, Button, Divider, Paper, Typography } from "@mui/material";

import { TiDelete } from "react-icons/ti";
import Swal from "sweetalert2";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import {
  countryCrimeAndMedicalApplicationAddAction,
  countryCrimeApplicationGetAction,
  countryDocumentSOPApplicationAddAction,
  countryDocumentSOPApplicationFindAction,
  countryDocumentTravelApplicationAddAction,
  countryDocumentTravelApplicationFindAction,
  countryMedicalApplicationGetAction,
  criminalDocDeleteAction,
  medicalDocDeleteAction,
  SopDocDeleteAction,
  TravelApplicationDeleteAction,
} from "../../actions/knowledgeBankActions/CountryDocsActions";

import { useDispatch, useSelector } from "react-redux";
import CountryDocumentsGuidance from "./CountryDocumentsGuidance.js";
import CountryDocumentsadmision from "./CountryDocumentsadmision";
import { OutLinedButton, SubmitButton } from "../customComponent/Buttons";
import InputField from "../customComponent/InputField";
import AdmissionProcedureDocs from "./AdmissionProcedureDocs";
import GuidanceForCountryDocuments from "./GuidanceForCountryDocuments";
import CostEstimate from "./CostEstimate.js";
import { Close } from "@mui/icons-material";
import AccessDeniedIcon from "../Leads/createLeads/AccessDeniedIcon.jsx";
import usePrivilegeCheck from "../../constants/customHooks/UsePrivilegeCheck.js";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function CountryDocument({ docId, country, paymentMandotary }) {
  const dispatch = useDispatch();

  // Custom hook for Previlage checking

  const {
    privilege,
    hasPrivilege,
    isCreatePrevilage,
    isDeletePrevilage,
  } = usePrivilegeCheck();

  const [selectedFile, setSelectedFile] = useState(null);
  const [previewImage, setPreviewImage] = useState("");
  const [travelPreviewImage, setTravelPreviewImage] = useState("");

  const [open, setOpen] = useState(false);
  const [error, setError] = useState({});
  const [travelOpen, setTravelOpen] = useState(false);
  const [openCrimeAndMedical, setOpenCrimeAndMedical] = useState(false);

  const [isCrime, setIsCrime] = useState(true);

  const [docName, setDocName] = useState("");
  const [travelDocName, setTravelDocName] = useState("");

  const [sopData, setSopData] = useState();
  const [travelData, setTravelData] = useState();
  const [crimeDoc, setCrimeDoc] = useState();
  const [mediDoc, setMedimeDoc] = useState();

  const [previlageAlert, setPrevilageAlert] = useState(false);

  let { countryDocSopAddSuccess } = useSelector((state) => {
    return state.countryDocumentSOPApplicationAdd;
  });

  let { countryDocSopFindSuccess } = useSelector((state) => {
    return state.countryDocumentSOPApplicationFind;
  });

  // travel selector
  let { countryDocTravelAddSuccess } = useSelector((state) => {
    return state.countryDocumentTravelApplicationAdd;
  });

  let { countryDocTravelFindSuccess } = useSelector((state) => {
    return state.countryDocumentTravelApplicationFind;
  });

  let { countryDocSopDeleteSuccess } = useSelector((state) => {
    return state.SopDocDelete;
  });

  let { countryDocTravelDeleteSuccess } = useSelector((state) => {
    return state.TravelApplicationDelete;
  });

  let { countryCrimeDocFindSuccess } = useSelector((state) => {
    return state.countryCrimeApplicationGet;
  });

  let { countryMedicalDocFindSuccess } = useSelector((state) => {
    return state.countryMedicalApplicationGet;
  });

  let { countryCrimeMediDocSuccess } = useSelector((state) => {
    return state.countryCrimeAndMedicalApplicationAdd;
  });

  let { countryCrimeDocDeleteSuccess } = useSelector((state) => {
    return state.criminalDocDelete;
  });

  useEffect(() => {
    if (countryCrimeDocFindSuccess) {
      setCrimeDoc(countryCrimeDocFindSuccess);
    }
  }, [countryCrimeDocFindSuccess]);

  useEffect(() => {
    if (countryMedicalDocFindSuccess) {
      setMedimeDoc(countryMedicalDocFindSuccess);
    }
  }, [countryMedicalDocFindSuccess]);

  useEffect(() => {
    dispatch(countryMedicalApplicationGetAction(docId));
  }, [dispatch, countryCrimeMediDocSuccess]);

  useEffect(() => {
    dispatch(countryCrimeApplicationGetAction(docId));
  }, [dispatch, countryCrimeMediDocSuccess, countryCrimeDocDeleteSuccess]);

  useEffect(() => {
    dispatch(countryDocumentSOPApplicationFindAction(docId));
  }, [dispatch, countryDocSopAddSuccess, docId, countryDocSopDeleteSuccess]);

  useEffect(() => {
    if (countryDocSopFindSuccess) {
      setSopData(countryDocSopFindSuccess);
    }
  }, [countryDocSopFindSuccess]);

  useEffect(() => {
    dispatch(countryDocumentTravelApplicationFindAction(docId));
  }, [
    dispatch,
    countryDocTravelAddSuccess,
    docId,
    countryDocSopAddSuccess,
    countryDocTravelDeleteSuccess,
    countryCrimeDocDeleteSuccess,
  ]);

  useEffect(() => {
    if (countryDocTravelFindSuccess) {
      setTravelData(countryDocTravelFindSuccess);
    }
  }, [
    countryDocTravelFindSuccess,
    countryDocSopAddSuccess,
    countryDocTravelAddSuccess,
  ]);

  //   popup add common travel document

  const handleTravelClickOpen = () => {
    setTravelOpen(true);
  };

  const handlerTravelClose = () => {
    setTravelOpen(false);
    setTravelDocName("");
    setSelectedFile(null);
    setError({});
  };

  //   popup add common sop document
  const handleClickOpen = () => {
    setOpen(true);
  };

  // dialogue for criminal and medical
  const handleCrimeAndMedical = (section) => {
    if (section === "crime") {
      setIsCrime(true);
    } else {
      setIsCrime(false);
    }
    setOpenCrimeAndMedical(true);
  };

  const handlerCrimeMedicalClose = () => {
    setOpenCrimeAndMedical(false);
  };
  const handleClose = () => {
    setOpen(false);
  };

  // file upload travel
  const handleTravelFileUpload = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    setError((prev) => ({
      ...prev,
      ["selectedFile"]: "",
    }));

    const reader = new FileReader();

    reader.readAsDataURL(file);

    reader.onloadend = () => {
      setTravelPreviewImage(reader.result);
    };
  };

  // file upload
  const handleFileUpload = (event) => {
    console.log(event,"eventeventeventeventevent")
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
      setError((prev) => ({
        ...prev,
        ["selectedFile"]: "",
      }));

      const reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onloadend = () => {
        setPreviewImage(reader.result);
      };
    }
  };

  let validate = () => {
    let validateError = {};
    if (travelDocName === "") {
      validateError.travelDocName = "Please enter a document name";
    }
    if (!selectedFile) {
      validateError.selectedFile = "Please choose a file";
    }

    setError(validateError);
    return Object.keys(validateError).length === 0;
  };

  const handleTravelSubmit = () => {
    if (validate()) {
      dispatch(
        countryDocumentTravelApplicationAddAction(
          travelDocName,
          travelPreviewImage,
          docId
        )
      );
      setTravelOpen(false);
      setTravelDocName("");
      setSelectedFile(null);
    }
  };

  // submit crime and medical
  const medicalValidate = () => {
    let validateError = {};

    if (!selectedFile) {
      validateError.selectedFile = "Please select a file";
    }
    if (docName === "") {
      validateError.docName = "Please enter a document name";
    }
    console.log(validateError);
    setError(validateError);
    return Object.keys(validateError).length === 0;
  };

  const handleCrimeMedicalSubmit = () => {
    if (medicalValidate()) {
      dispatch(
        countryCrimeAndMedicalApplicationAddAction(
          docName,
          previewImage,
          docId,
          isCrime ? "crime" : "medical"
        )
      );
      setOpenCrimeAndMedical(false);
      setDocName("");
      setSelectedFile(null);
    }
  };

  const handleSubmit = () => {
    if (docName !== "" && selectedFile !== null) {
      dispatch(
        countryDocumentSOPApplicationAddAction(
          docName,
          previewImage,
          docId,
          isCrime ? "medical" : "crime"
        )
      );
      setOpen(false);
      setDocName("");
      setSelectedFile(null);
    } else {
      alert("Please add document Name");
    }
  };

  // handle delete country
  const handleSopDocDeleteClick = (id) => {
    if (isDeletePrevilage("document upload")) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          dispatch(SopDocDeleteAction(id));
          setSopData(undefined);
          Swal.fire("Deleted!", "Your file has been  deleted.", "success");
        }
      });
    } else {
      setPrevilageAlert(true);
    }
  };

  const handleTravelDelete = (id) => {
    if (isDeletePrevilage("document upload")) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          dispatch(TravelApplicationDeleteAction(id));
          setTravelData(undefined);
          Swal.fire("Deleted!", "Your file has been  deleted.", "success");
        }
      });
    } else {
      setPrevilageAlert(true);
    }
  };

  const handleDelete = (id, section) => {
    if (isDeletePrevilage("document upload")) {
      if (section === "crime") {
        Swal.fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
        }).then((result) => {
          if (result.isConfirmed) {
            dispatch(criminalDocDeleteAction(id));
            setCrimeDoc(undefined);
            Swal.fire("Deleted!", "Your file has been  deleted.", "success");
          }
        });
      }

      if (section === "medical") {
        Swal.fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
        }).then((result) => {
          if (result.isConfirmed) {
            dispatch(medicalDocDeleteAction(id));
            setMedimeDoc(undefined);
            Swal.fire("Deleted!", "Your file has been  deleted.", "success");
          }
        });
      }
    } else {
      setPrevilageAlert(true);
    }
  };

  console.log(
    countryMedicalDocFindSuccess,
    "countryMedicalDocFindSuccesscountryMedicalDocFindSuccess"
  );

  console.log(
    countryDocSopDeleteSuccess,
    "countryDocSopDeleteSuccesscountryDocSopDeleteSuccess"
  );

  return (
    <div>
      <Paper
        elevation={0}
        sx={{
          width: "auto",
          height: "53px",
          alignItems: "center",
          paddingLeft: "16px",
          backgroundColor: "#F6F6F6",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h6" sx={{ fontWeight: 700, fontSize: "19px" }}>
          SOP and Travel, Criminal, Medical Documents
        </Typography>
      </Paper>
      <Paper
        elevation={0}
        sx={{
          display: "flex",
          marginTop: "5px",
          border: "none",
        }}
      >
        <Paper
          elevation={0}
          sx={{
            backgroundColor: "#fdfdfd",
            width: "216px",
            height: "180px",
            textAlign: "center",
            mt: 2,
            border: "1px solid #ECECEC",
          }}
        >
          {sopData === undefined ? (
            <Box
              component="div"
              // startIcon={<AddIcon />}
              sx={{
                backgroundColor: "#e6eff7",
                width: "auto",
                cursor: "pointer",
                height: "110px",
                textAlign: "center",
              }}
              onClick={handleClickOpen}
            >
              <AddIcon
                sx={{
                  width: "71.87px",
                  height: "auto",
                  color: "#2a76d2",
                  fontSize: "20px",
                  margin: "15px",
                }}
              />
            </Box>
          ) : (
            <>
              <Box
                sx={{
                  backgroundColor: "#f8f8f8",
                  width: "auto",
                  cursor: "pointer",
                  height: "110px",
                  textAlign: "center",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    cursor: "pointer",
                  }}
                >
                  <TiDelete
                    style={{ fontSize: "20px" }}
                    onClick={() => handleSopDocDeleteClick(sopData.id)}
                  />
                </Box>
                <a
                  href={sopData.sopUrl}
                  download={sopData.sopUrl}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src="/menuIcons/pdf.png"
                    alt="Uploaded"
                    style={{
                      marginTop: "10px",
                      width: "57px",
                      height: "auto",
                      cursor: "pointer",
                    }}
                  />
                </a>
              </Box>
            </>
          )}

          <Divider />
          <Box sx={{ m: 1, height: "55px", p: "10px" }}>
            <Typography sx={{ fontSize: "16px", fontWeight: 400 }}>
              SOP
            </Typography>
          </Box>
        </Paper>

        {/* travel */}

        <Paper
          elevation={0}
          sx={{
            backgroundColor: "#fdfdfd",
            width: "216px",
            height: "180px",
            textAlign: "center",
            m: 2,
            border: "1px solid #ECECEC",
          }}
        >
          {travelData === undefined ? (
            <Box
              component="div"
              sx={{
                backgroundColor: "#e6eff7",
                width: "auto",
                cursor: "pointer",
                height: "110px",
                textAlign: "center",
              }}
              onClick={handleTravelClickOpen}
            >
              <AddIcon
                sx={{
                  width: "71.87px",
                  height: "auto",
                  color: "#2a76d2",
                  fontSize: "20px",
                  margin: "15px",
                }}
              />
            </Box>
          ) : (
            <>
              <Box
                sx={{
                  backgroundColor: "#f8f8f8",
                  width: "auto",
                  cursor: "pointer",
                  height: "110px",
                  textAlign: "center",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    cursor: "pointer",
                  }}
                >
                  <TiDelete
                    style={{ fontSize: "20px" }}
                    onClick={() => handleTravelDelete(travelData.id)}
                  />
                </Box>
                <a
                  href={travelData.TravelDocUrl}
                  download={travelData.TravelDocUrl}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src="/menuIcons/pdf.png"
                    alt="Uploaded"
                    style={{
                      marginTop: "10px",
                      width: "57px",
                      height: "auto",
                      cursor: "pointer",
                    }}
                  />
                </a>
              </Box>
            </>
          )}
          <Divider />
          <Box sx={{ m: 1, height: "55px", p: "10px" }}>
            <Typography sx={{ fontSize: "16px", fontWeight: 400 }}>
              Travel Document
            </Typography>
          </Box>
        </Paper>

        {/* criminal */}
        <Paper
          elevation={0}
          sx={{
            backgroundColor: "#fdfdfd",
            width: "216px",
            height: "180px",
            textAlign: "center",
            m: 2,
            border: "1px solid #ECECEC",
          }}
        >
          {crimeDoc === undefined ? (
            <Box
              component="div"
              // startIcon={<AddIcon />}
              sx={{
                backgroundColor: "#e6eff7",
                width: "auto",
                cursor: "pointer",
                height: "110px",
                textAlign: "center",
              }}
              onClick={() => handleCrimeAndMedical("crime")}
            >
              <AddIcon
                sx={{
                  width: "71.87px",
                  height: "auto",
                  color: "#2a76d2",
                  fontSize: "20px",
                  margin: "15px",
                }}
              />
            </Box>
          ) : (
            <>
              <Box
                sx={{
                  backgroundColor: "#f8f8f8",
                  width: "auto",
                  cursor: "pointer",
                  height: "110px",
                  textAlign: "center",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    cursor: "pointer",
                  }}
                >
                  <TiDelete
                    style={{ fontSize: "20px" }}
                    onClick={() => handleDelete(crimeDoc.id, "crime")}
                  />
                </Box>
                <a
                  href={crimeDoc.criminalDocUrl}
                  download={crimeDoc.criminalDocUrl}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src="/menuIcons/pdf.png"
                    alt="Uploaded"
                    style={{
                      marginTop: "10px",
                      width: "57px",
                      height: "auto",
                      cursor: "pointer",
                    }}
                  />
                </a>
              </Box>
            </>
          )}
          <Divider />
          <Box sx={{ m: 1, height: "55px", p: "10px" }}>
            <Typography sx={{ fontSize: "16px", fontWeight: 400 }}>
              Criminal Document
            </Typography>
          </Box>
        </Paper>

        {/* Medical */}
        <Paper
          elevation={0}
          sx={{
            backgroundColor: "#fdfdfd",
            width: "216px",
            height: "180px",
            textAlign: "center",
            m: 2,
            border: "1px solid #ECECEC",
          }}
        >
          {mediDoc === undefined ? (
            <Box
              component="div"
              // startIcon={<AddIcon />}
              sx={{
                backgroundColor: "#e6eff7",
                width: "auto",
                cursor: "pointer",
                height: "110px",
                textAlign: "center",
              }}
              onClick={() => handleCrimeAndMedical("medical")}
            >
              <AddIcon
                sx={{
                  width: "71.87px",
                  height: "auto",
                  color: "#2a76d2",
                  fontSize: "20px",
                  margin: "15px",
                }}
              />
            </Box>
          ) : (
            <>
              <Box
                sx={{
                  backgroundColor: "#f8f8f8",
                  width: "auto",
                  cursor: "pointer",
                  height: "110px",
                  textAlign: "center",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    cursor: "pointer",
                  }}
                >
                  <TiDelete
                    style={{ fontSize: "20px" }}
                    onClick={() => handleDelete(mediDoc.id, "medical")}
                  />
                </Box>
                <a
                  href={mediDoc.medicalDocUrl}
                  download={mediDoc.medicalDocUrl}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src="/menuIcons/pdf.png"
                    alt="Uploaded"
                    style={{
                      marginTop: "10px",
                      width: "57px",
                      height: "auto",
                      cursor: "pointer",
                    }}
                  />
                </a>
              </Box>
            </>
          )}
          <Divider />
          <Box sx={{ m: 1, height: "55px", p: "10px" }}>
            <Typography sx={{ fontSize: "16px", fontWeight: 400 }}>
              Medical Document
            </Typography>
          </Box>
        </Paper>
      </Paper>

      <CountryDocumentsGuidance docId={docId} country={country} />
      <GuidanceForCountryDocuments docId={docId} country={country} />
      <CountryDocumentsadmision docId={docId} country={country} />
      <AdmissionProcedureDocs docId={docId} country={country} />
      <CostEstimate
        docId={docId}
        country={country}
        paymentMandotary={paymentMandotary}
      />

      {/* dialog sop pupup */}
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        PaperProps={{
          style: {
            marginTop: "10vh", // Adjust the value as needed
            alignSelf: "flex-start",
            width: "500px",
          },
        }}
      >
        <DialogTitle>{"Add SOP Document"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description" mt={2}>
            <InputField
              label="Document Name"
              handleChange={(e) => setDocName(e.target.value)}
              widthSize="97%"
              value={docName}
            />

            <Paper
              variant="outlined"
              sx={{
                backgroundColor: "#F6F6F6",
                padding: "20px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "20px",
              }}
            >
              <input
                type="file"
                accept=".pdf,.doc,.docx,.xlsx"
                id="file-upload"
                // style={{ display: "none" }}
                onChange={handleFileUpload}
              />
              {selectedFile?.name.split(".")[1] === "pdf" && (
                <img
                  src="/menuIcons/pdf.png"
                  alt="Uploaded"
                  style={{
                    marginTop: "10px",
                    width: "100px",
                    height: "auto",
                  }}
                />
              )}
              {selectedFile?.name.split(".")[1] === "xlsx" && (
                <img
                  src="/menuIcons/xls.png"
                  alt="Uploaded"
                  style={{
                    marginTop: "10px",
                    width: "100px",
                    height: "auto",
                  }}
                />
              )}
              {/* {selectedFile && <p>Selected file: {selectedFile.name}</p>} */}
            </Paper>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={handleClose}>Cancel</Button>
          <Button
            variant="outlined"
            sx={{ m: 1, borderColor: "#141E3C", color: "#141E3C" }}
            onClick={handleSubmit}
          >
            Add
          </Button> */}
          <OutLinedButton
            title="Cancel"
            handleClick={handleClose}
            widthSize="81px"
            heightSize="35px"
          />
          <SubmitButton
            title="Add"
            submit=""
            widthSize="81px"
            heightSize="35px"
            handleSubmit={handleSubmit}
            type="click"
          />
        </DialogActions>
      </Dialog>

      {/* dialog Travel pupup */}
      <Dialog
        open={travelOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handlerTravelClose}
        aria-describedby="alert-dialog-slide-description"
        PaperProps={{
          style: {
            marginTop: "10vh", // Adjust the value as needed
            alignSelf: "flex-start",
            width: "500px",
          },
        }}
      >
        <DialogTitle>{"Add Travel Document"}</DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
            }}
          >
            <InputField
              label="Document Name"
              handleChange={(e) => {
                setTravelDocName(e.target.value);
                setError((prev) => ({
                  ...prev,
                  ["travelDocName"]: "",
                }));
              }}
              widthSize="97%"
              value={travelDocName}
              error={Boolean(error.travelDocName)}
              helperText={error.travelDocName}
            />

            {/* <Paper
              variant="outlined"
              sx={{
                backgroundColor: "#F6F6F6",
                padding: "20px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "20px",
              }}
            > */}
            <div>
              <label>
                <input
                  type="file"
                  accept=".pdf,.doc,.docx,.xlsx"
                  id="file-upload"
                  // style={{ display: "none" }}
                  onChange={handleTravelFileUpload}
                  hidden
                />
                {!selectedFile && (
                  <Box
                    sx={{
                      border: "1px solid #ECECEC",
                      padding: "9px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "14px",
                        color: "#ACB1C6",
                      }}
                    >
                      {" "}
                      Choose File
                    </Typography>
                    <Box
                      sx={{
                        height: "38px",
                        width: "118px",
                        backgroundColor: "#EBF2FD !important",
                        textTransform: "capitalize",
                        fontSize: "13px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        color: "#1E88E5",
                        cursor: "pointer",
                      }}
                    >
                      Browse
                    </Box>
                  </Box>
                )}
                {selectedFile && (
                  <Box
                    sx={{
                      border: "1px solid #ECECEC",
                      padding: "9px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    {selectedFile?.name.split(".")[1] === "pdf" && (
                      <img
                        src="/menuIcons/pdf.png"
                        alt="Uploaded"
                        style={{
                          marginTop: "10px",
                          width: "100px",
                          height: "auto",
                        }}
                      />
                    )}
                    {selectedFile?.name.split(".")[1] === "xlsx" && (
                      <img
                        src="/menuIcons/xls.png"
                        alt="Uploaded"
                        style={{
                          marginTop: "10px",
                          width: "100px",
                          height: "auto",
                        }}
                      />
                    )}
                    {selectedFile && <p>Selected file: {selectedFile.name}</p>}
                  </Box>
                )}
              </label>
              {/* </Paper> */}
              {Boolean(error.selectedFile) && (
                <Typography
                  sx={{
                    color: "#FF2400",
                    fontSize: "12px ",
                  }}
                >
                  {error.selectedFile}
                </Typography>
              )}
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <OutLinedButton
            title="Cancel"
            handleClick={handlerTravelClose}
            widthSize="81px"
            heightSize="35px"
          />
          <SubmitButton
            title="Add"
            submit=""
            widthSize="81px"
            heightSize="35px"
            handleSubmit={handleTravelSubmit}
            type="click"
          />
        </DialogActions>
      </Dialog>

      {/* crime and medical */}
      <Dialog
        open={openCrimeAndMedical}
        TransitionComponent={Transition}
        keepMounted
        onClose={handlerTravelClose}
        aria-describedby="alert-dialog-slide-description"
        PaperProps={{
          style: {
            marginTop: "10vh", // Adjust the value as needed
            alignSelf: "flex-start",
            width: "500px",
          },
        }}
      >
        <DialogTitle>
          {isCrime ? "Add Criminal Document" : "Add Medical Document"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            mt={2}
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
            }}
          >
            <InputField
              label="Document Name"
              handleChange={(e) => {
                setDocName(e.target.value);
                setError((prev) => ({
                  ...prev,
                  ["docName"]: "",
                }));
              }}
              widthSize="97%"
              value={docName}
              error={Boolean(error.docName)}
              helperText={error.docName}
            />

            <div>
              <label>
                <input
                  type="file"
                  accept=".pdf,.doc,.docx,.xlsx"
                  id="file-upload"
                  // style={{ display: "none" }}
                  onChange={handleFileUpload}
                  hidden
                />
                {!selectedFile && (
                  <Box
                    sx={{
                      border: "1px solid #ECECEC",
                      padding: "9px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "14px",
                        color: "#ACB1C6",
                      }}
                    >
                      {" "}
                      Choose File
                    </Typography>
                    <Box
                      sx={{
                        height: "38px",
                        width: "118px",
                        backgroundColor: "#EBF2FD !important",
                        textTransform: "capitalize",
                        fontSize: "13px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        color: "#1E88E5",
                        cursor: "pointer",
                      }}
                    >
                      Browse
                    </Box>
                  </Box>
                )}
                {selectedFile && (
                  <Box
                    sx={{
                      border: "1px solid #ECECEC",
                      padding: "9px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    {selectedFile?.name.split(".")[1] === "pdf" && (
                      <img
                        src="/menuIcons/pdf.png"
                        alt="Uploaded"
                        style={{
                          marginTop: "10px",
                          width: "100px",
                          height: "auto",
                        }}
                      />
                    )}
                    {selectedFile?.name.split(".")[1] === "xlsx" && (
                      <img
                        src="/menuIcons/xls.png"
                        alt="Uploaded"
                        style={{
                          marginTop: "10px",
                          width: "100px",
                          height: "auto",
                        }}
                      />
                    )}
                    {selectedFile && <p>Selected file: {selectedFile.name}</p>}
                  </Box>
                )}
              </label>
              {Boolean(error.selectedFile) && (
                <Typography
                  sx={{
                    color: "#FF2400",
                    fontSize: "12px ",
                  }}
                >
                  {error.selectedFile}
                </Typography>
              )}
            </div>
            {/* </Paper> */}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <OutLinedButton
            title="Cancel"
            handleClick={handlerCrimeMedicalClose}
            widthSize="81px"
            heightSize="35px"
          />
          <SubmitButton
            title="Add"
            submit=""
            widthSize="81px"
            heightSize="35px"
            handleSubmit={handleCrimeMedicalSubmit}
            type="click"
          />
        </DialogActions>
      </Dialog>

      {/* Dialog for Previlage popUp */}

      <Dialog
        open={previlageAlert}
        onClose={() => setPrevilageAlert(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          zIndex: "9999",
          // background:"red",
          "& .MuiDialog-paper": {
            width: "100%",
            borderRadius: "5px",
            p: "15px",
            textAlign: "center",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "end",
          }}
        >
          <Close
            sx={{
              cursor: "pointer",
            }}
            onClick={() => setPrevilageAlert(false)}
          />
        </Box>
        <Typography
          sx={{
            color: "#05050F",
            fontSize: "26px",
            fontWeight: "700",
          }}
        >
          Access Denied
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            mt: "-10px",
          }}
        >
          <AccessDeniedIcon />
        </Box>
        <Typography
          sx={{
            color: "#717171",
            fontSize: "16px",
            mb: "10px",
          }}
        >
          Sorry, you don’t have permission to create/delete
        </Typography>
      </Dialog>
    </div>
  );
}

export default CountryDocument;
