import {
  FOLLOW_UP_ADD_LINK_ERR,
  FOLLOW_UP_ADD_LINK_REQUEST,
  FOLLOW_UP_ADD_LINK_SUCCESS,
  FOLLOW_UP_ATTACHMENT_FIND_ERR,
  FOLLOW_UP_ATTACHMENT_FIND_REQUEST,
  FOLLOW_UP_ATTACHMENT_FIND_SUCCESS,
  FOLLOW_UP_COMPOSE_MAIL_ERR,
  FOLLOW_UP_COMPOSE_MAIL_FIND_ERR,
  FOLLOW_UP_COMPOSE_MAIL_FIND_REQUEST,
  FOLLOW_UP_COMPOSE_MAIL_FIND_SUCCESS,
  FOLLOW_UP_COMPOSE_MAIL_REQUEST,
  FOLLOW_UP_COMPOSE_MAIL_SUCCESS,
  FOLLOW_UP_FIND_LINK_ERR,
  FOLLOW_UP_FIND_LINK_REQUEST,
  FOLLOW_UP_FIND_LINK_SUCCESS,
  FOLLOW_UP_NOTE_FIND_ERR,
  FOLLOW_UP_NOTE_FIND_REQUEST,
  FOLLOW_UP_NOTE_FIND_SUCCESS,
  FOLLOW_UP_SOCIAL_MEDIA_ADD_ERR,
  FOLLOW_UP_SOCIAL_MEDIA_ADD_REQUEST,
  FOLLOW_UP_SOCIAL_MEDIA_ADD_SUCCESS,
  FOLLOW_UP_SOCIAL_MEDIA_FIND_ERR,
  FOLLOW_UP_SOCIAL_MEDIA_FIND_REQUEST,
  FOLLOW_UP_SOCIAL_MEDIA_FIND_SUCCESS,
  FOLLOW_UP_STATUS_CHANGE_ERR,
  FOLLOW_UP_STATUS_CHANGE_REQUEST,
  FOLLOW_UP_STATUS_CHANGE_SUCCESS,
  FOLLOW_UP_TASK_ADD_ERR,
  FOLLOW_UP_TASK_ADD_REQUEST,
  FOLLOW_UP_TASK_ADD_SUCCESS,
  FOLLOW_UP_TASK_EDIT_ERR,
  FOLLOW_UP_TASK_EDIT_REQUEST,
  FOLLOW_UP_TASK_EDIT_SUCCESS,
  FOLLOW_UP_TASK_FIND_ERR,
  FOLLOW_UP_TASK_FIND_REQUEST,
  FOLLOW_UP_TASK_FIND_SUCCESS,
} from "../../constants/leadConstant";

// followUpTaskInformationAddReducer
export const followUpTaskInformationAddReducer = (state = {}, action) => {
  switch (action.type) {
    case FOLLOW_UP_TASK_ADD_REQUEST:
      return {
        ...state,
        leadFollowUpAddLoading: true,
      };
    case FOLLOW_UP_TASK_ADD_SUCCESS:
      return {
        ...state,
        leadFollowUpAddLoading: false,
        leadFollowUpAddSuccess: action.payload,
      };
    case FOLLOW_UP_TASK_ADD_ERR:
      return {
        ...state,
        leadFollowUpAddLoading: false,
        leadFollowUpAddError: action.payload,
      };
    default:
      return state;
  }
};

// followUpTaskInformationEditReducer
export const followUpTaskInformationUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case FOLLOW_UP_TASK_EDIT_REQUEST:
      return {
        ...state,
        leadFollowUpEditLoading: true,
      };
    case FOLLOW_UP_TASK_EDIT_SUCCESS:
      return {
        ...state,
        leadFollowUpEditLoading: false,
        leadFollowUpEditSuccess: action.payload,
      };
    case FOLLOW_UP_TASK_EDIT_ERR:
      return {
        ...state,
        leadFollowUpEditLoading: false,
        leadFollowUpEditError: action.payload,
      };
    default:
      return state;
  }
};

// followUpTaskInformationFindReducer
export const followUpTaskInformationFindReducer = (state = {}, action) => {
  switch (action.type) {
    case FOLLOW_UP_TASK_FIND_REQUEST:
      return {
        ...state,
        leadFollowUpFindLoading: true,
      };
    case FOLLOW_UP_TASK_FIND_SUCCESS:
      return {
        ...state,
        leadFollowUpFindLoading: false,
        leadFollowUpFindSuccess: action.payload,
      };
    case FOLLOW_UP_TASK_FIND_ERR:
      return {
        ...state,
        leadFollowUpFindLoading: false,
        leadFollowUpFindError: action.payload,
      };
    default:
      return state;
  }
};

// activityStatusChangeReducer
export const activityStatusChangeReducer = (state = {}, action) => {
  switch (action.type) {
    case FOLLOW_UP_STATUS_CHANGE_REQUEST:
      return {
        ...state,
        leadFollowUpStatusChangeLoading: true,
      };
    case FOLLOW_UP_STATUS_CHANGE_SUCCESS:
      return {
        ...state,
        leadFollowUpStatusChangeLoading: false,
        leadFollowUpStatusChangeSuccess: action.payload,
      };
    case FOLLOW_UP_STATUS_CHANGE_ERR:
      return {
        ...state,
        leadFollowUpStatusChangeLoading: false,
        leadFollowUpStatusChangeError: action.payload,
      };
    default:
      return state;
  }
};

// followUpActivitiesAddReducer
export const followUpActivitiesAddReducer = (state = {}, action) => {
  switch (action.type) {
    case FOLLOW_UP_SOCIAL_MEDIA_ADD_REQUEST:
      return {
        ...state,
        leadFollowUpAddLoading: true,
      };
    case FOLLOW_UP_SOCIAL_MEDIA_ADD_SUCCESS:
      return {
        ...state,
        leadFollowUpAddLoading: false,
        leadFollowUpAddSuccess: action.payload,
      };
    case FOLLOW_UP_SOCIAL_MEDIA_ADD_ERR:
      return {
        ...state,
        leadFollowUpAddLoading: false,
        leadFollowUpAddError: action.payload,
      };
    default:
      return state;
  }
};

// followUpActivitiesLinkFindReducer
export const followUpActivitiesLinkFindReducer = (state = {}, action) => {
  switch (action.type) {
    case FOLLOW_UP_SOCIAL_MEDIA_FIND_REQUEST:
      return {
        ...state,
        leadFollowUpFindLoading: true,
      };
    case FOLLOW_UP_SOCIAL_MEDIA_FIND_SUCCESS:
      return {
        ...state,
        leadFollowUpFindLoading: false,
        leadFollowUpFindSuccess: action.payload,
      };
    case FOLLOW_UP_SOCIAL_MEDIA_FIND_ERR:
      return {
        ...state,
        leadFollowUpFindLoading: false,
        leadFollowUpFindError: action.payload,
      };
    default:
      return state;
  }
};

// followUpActivitiesNoteFindReducer
export const followUpActivitiesNoteFindReducer = (state = {}, action) => {
  switch (action.type) {
    case FOLLOW_UP_NOTE_FIND_REQUEST:
      return {
        ...state,
        leadFollowUpNoteFindLoading: true,
      };
    case FOLLOW_UP_NOTE_FIND_SUCCESS:
      return {
        ...state,
        leadFollowUpNoteFindLoading: false,
        leadFollowUpNoteFindSuccess: action.payload,
      };
    case FOLLOW_UP_NOTE_FIND_ERR:
      return {
        ...state,
        leadFollowUpNoteFindLoading: false,
        leadFollowUpNoteFindError: action.payload,
      };
    default:
      return state;
  }
};

// followUpActivitiesattAchmentFindReducer
export const followUpActivitiesattAchmentFindReducer = (state = {}, action) => {
  switch (action.type) {
    case FOLLOW_UP_ATTACHMENT_FIND_REQUEST:
      return {
        ...state,
        leadFollowUpAttachmentFindLoading: true,
      };
    case FOLLOW_UP_ATTACHMENT_FIND_SUCCESS:
      return {
        ...state,
        leadFollowUpAttachmentFindLoading: false,
        leadFollowUpAttachmentFindSuccess: action.payload,
      };
    case FOLLOW_UP_ATTACHMENT_FIND_ERR:
      return {
        ...state,
        leadFollowUpAttachmentFindLoading: false,
        leadFollowUpAttachmentFindError: action.payload,
      };
    default:
      return state;
  }
};

// followUpComposeMailReducer
export const followUpComposeMailReducer = (state = {}, action) => {
  switch (action.type) {
    case FOLLOW_UP_COMPOSE_MAIL_REQUEST:
      return {
        ...state,
        leadFollowUpComposeMailLoading: true,
      };
    case FOLLOW_UP_COMPOSE_MAIL_SUCCESS:
      return {
        ...state,
        leadFollowUpComposeMailLoading: false,
        leadFollowUpComposeMailSuccess: action.payload,
      };
    case FOLLOW_UP_COMPOSE_MAIL_ERR:
      return {
        ...state,
        leadFollowUpComposeMailLoading: false,
        leadFollowUpComposeMailError: action.payload,
      };
    default:
      return state;
  }
};

// followUpComposeMailFindReducer
export const followUpComposeMailFindReducer = (state = {}, action) => {
  switch (action.type) {
    case FOLLOW_UP_COMPOSE_MAIL_FIND_REQUEST:
      return {
        ...state,
        leadFollowUpComposeMailFindLoading: true,
      };
    case FOLLOW_UP_COMPOSE_MAIL_FIND_SUCCESS:
      return {
        ...state,
        leadFollowUpComposeMailFindLoading: false,
        leadFollowUpComposeMailFindSuccess: action.payload,
      };
    case FOLLOW_UP_COMPOSE_MAIL_FIND_ERR:
      return {
        ...state,
        leadFollowUpComposeMailFindLoading: false,
        leadFollowUpComposeMailFindError: action.payload,
      };
    default:
      return state;
  }
};

// followUpActivitiesLinkAddReducer
export const followUpActivitiesLinkAddReducer = (state = {}, action) => {
  switch (action.type) {
    case FOLLOW_UP_ADD_LINK_REQUEST:
      return {
        ...state,
        leadFollowUpLinkAddLoading: true,
      };
    case FOLLOW_UP_ADD_LINK_SUCCESS:
      return {
        ...state,
        leadFollowUpLinkAddLoading: false,
        leadFollowUpLinkAddSuccess: action.payload,
      };
    case FOLLOW_UP_ADD_LINK_ERR:
      return {
        ...state,
        leadFollowUpLinkAddLoading: false,
        leadFollowUpLinkAddError: action.payload,
      };
    default:
      return state;
  }
};

// followUpActivitiesLinkFindReducer
export const followUpActivitiesNormalLinkFindReducer = (state = {}, action) => {
  switch (action.type) {
    case FOLLOW_UP_FIND_LINK_REQUEST:
      return {
        ...state,
        leadFollowUpLinkFindLoading: true,
      };
    case FOLLOW_UP_FIND_LINK_SUCCESS:
      return {
        ...state,
        leadFollowUpLinkFindLoading: false,
        leadFollowUpLinkFindSuccess: action.payload,
      };
    case FOLLOW_UP_FIND_LINK_ERR:
      return {
        ...state,
        leadFollowUpLinkFindLoading: false,
        leadFollowUpLinkFindError: action.payload,
      };
    default:
      return state;
  }
};
