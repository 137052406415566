import React, { useEffect, useState } from "react";
import LeadsTable from "../Table/LeadsTable";
import LeadsFilterSearch from "./LeadsFilterSearch";

import { Alert, AlertTitle, Grid } from "@mui/material";
import LeadersSearchHeader from "./LeadersSearchHeader";
import {
  deleteLeadAction,
  leadFindAction
} from "../../actions/leadActions/leadActions";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import usePrivilegeCheck from "../../constants/customHooks/UsePrivilegeCheck";
import { findUserAction } from "../../actions/adminAuthAction";
import DownloadExcel from "../extraComponents/DownloadExcel";

function LeadDead(props) {
  const dispatch = useDispatch();
  const [filterPassData, setFilterPassData] = useState();
  const [whichFilter, setwhichFilter] = useState();
  const [reset, setReset] = useState(false);
  const [leadData, setLeadData] = useState([]);
  const [data, setData] = useState([]);
  const [paginationPageNumber, setPaginationPageNumber] = useState(1);
  const [pagiantionEntry, setPagiantionEntry] = useState(8);

  const { privilege, hasPrivilege } = usePrivilegeCheck();

  let { leadFindLoading, leadFindSuccess } = useSelector((state) => {
    return state.leadFind;
  });

  let { leadAddSuccess } = useSelector((state) => {
    return state.leadAdd;
  });

  let { leadDeleteSuccess } = useSelector((state) => {
    return state.deleteLead;
  });

  let { leadOwnerSuccess } = useSelector((state) => {
    return state.leadOwnerChange;
  });

  let { loginFindSuccess } = useSelector((state) => {
    return state.findUser;
  });

  useEffect(() => {
    dispatch(findUserAction());
  }, [dispatch]);

  useEffect(() => {
    dispatch(leadFindAction());
  }, [dispatch, leadAddSuccess, leadDeleteSuccess, leadOwnerSuccess]);

  useEffect(() => {
    if (leadFindSuccess || reset) {
      setLeadData(
        leadFindSuccess?.filter((data) => data.status === "deferral")
      );
    }
  }, [leadFindSuccess, reset]);

  useEffect(() => {
    setData(
      leadData?.map((lead, index) => ({
        id: lead.id,
        sl: index + 1,
        name: lead.firstName,
        email: lead.email,
        phone: lead.mobileNumber,
        whatsappNumber: lead.whatsappNumber,
        status: lead.status,
        leadSource: lead.leadSource,
        leadCity: lead.location,
        prefferedCountry: lead.preferredCountryId,
        leadBranch: lead?.branch_master?.branchName
          ? lead?.branch_master?.branchName
          : "-",
        leadCounsilor:
          loginFindSuccess?.role?.roleName !== "counsellor"
            ? lead?.counsilorID
              ? lead?.counsilorID
              : "-"
            : null,
        leadTeleCaller:
          loginFindSuccess?.role?.roleName !== "tele caller"
            ? lead?.teleCallerId
              ? lead?.teleCallerId
              : "-"
            : null,
        intakeMonth: lead.status == "deferral" ? lead?.deferalIntakeMonth : "-",
        intakeYear: lead.status == "deferral" ? lead?.deferalIntakeYear : "-"
      }))
    );
  }, [leadData]);

  console.log(leadFindSuccess, "leadFindSuccessleadFindSuccessleadFindSuccess");

  const searchHandleChange = (e) => {
    if (e.target.value !== "" && e.target.value !== " ") {
      setData(
        data?.filter((item) => {
          const name = item.name ? item.name.toLowerCase() : "";
          const email = item.email ? item.email.toLowerCase() : "";
          const phone = item.phone ? item.phone.toLowerCase() : "";
          const whatsappNumber = item.whatsappNumber
            ? item.whatsappNumber.toLowerCase()
            : "";
          const preferredCountry = item.preferredCountry
            ? item.preferredCountry.toLowerCase()
            : "";
          const leadSource = item.leadSource
            ? item.leadSource.toLowerCase()
            : "";
          const leadOwner = item.leadOwner ? item.leadOwner.toLowerCase() : "";
          const status = item.status ? item.status.toLowerCase() : "";
          const education = item.education ? item.education.toLowerCase() : "";

          const leadCity = item.leadCity ? item.leadCity.toLowerCase() : "";
          const leadBranch = item.branchMaster
            ? item.branch_master?.branchName.toLowerCase()
            : "";

          // :lead?.branch_master?.branchName
          return (
            name.includes(e.target.value.toLowerCase()) ||
            phone.includes(e.target.value.toLowerCase()) ||
            whatsappNumber.includes(e.target.value.toLowerCase()) ||
            email.includes(e.target.value.toLowerCase()) ||
            preferredCountry.includes(e.target.value.toLowerCase()) ||
            leadSource.includes(e.target.value.toLowerCase()) ||
            leadOwner.includes(e.target.value.toLowerCase()) ||
            status.includes(e.target.value.toLowerCase()) ||
            education.includes(e.target.value.toLowerCase()) ||
            leadCity.includes(e.target.value.toLowerCase()) ||
            leadBranch.includes(e.target.value.toLowerCase())
          );
        })
      );
    } else {
      setData(
        leadData.map((lead, index) => ({
          id: lead.id,
          sl: index + 1,
          name: lead.firstName,
          email: lead.email,
          phone: lead.mobileNumber,
          whatsappNumber: lead.whatsappNumber,
          status: lead.status,
          leadSource: lead.leadSource,
          leadCity: lead.location,
          leadBranch: lead?.branch_master?.branchName
            ? lead?.branch_master?.branchName
            : "-",
          leadCounsilor:
            loginFindSuccess?.role?.roleName !== "counsellor"
              ? lead?.counsilorID
                ? lead?.counsilorID
                : "-"
              : null,
          leadTeleCaller:
            loginFindSuccess?.role?.roleName !== "tele caller"
              ? lead?.counsilorID
                ? lead?.counsilorID
                : "-"
              : null,
          prefferedCountry: lead.preferredCountryId,
          intakeMonth:
            lead.status == "deferral" ? lead?.deferalIntakeMonth : "-",
          intakeYear: lead.status == "deferral" ? lead?.deferalIntakeYear : "-"
        }))
      );
    }
  };

  // lead column Visibility

  const [showNameColumn, setShowNameColumn] = useState(false);
  const [showEmailColumn, setShowEmailColumn] = useState(false);
  const [showPrefferedColumn, setShowPrefferedColumn] = useState(false);
  const [showPhoneColumn, setShowPhoneColumn] = useState(false);
  const [showWhatsAppNumColumn, setShowWhatsAppNumColumn] = useState(false);
  const [showLeadSourceColumn, setShowLeadSourceColumn] = useState(false);
  const [showLeadBranchColumn, setShowLeadBranchColumnn] = useState(false);
  const [showLeadPlaceColumn, setShowLeadPlaceColumn] = useState(false);
  const [showTelecallerColumn, setShowTelecallerColumn] = useState(false);
  const [showCounsilorColumn, setShowCounsilorColumn] = useState(false);
  const [showReasonColumn, setReasonColumn] = useState(false);
  const [columnName, setColumnName] = useState("");
  const [leadId, setLeadId] = useState([]);
  const [ids, setIds] = useState([]);

  // material Table
  // material Table
  const columns = [
    {
      title: "Sl no",
      field: "sl"
    },
    {
      title: "Name",
      field: "name",
      hidden: showNameColumn
    },
    {
      title: "Email",
      field: "email",
      hidden: showEmailColumn
    },
    {
      title: "Phone",
      field: "phone",
      hidden: showPhoneColumn
    },
    {
      title: "What's App Number",
      field: "whatsappNumber",
      hidden: showWhatsAppNumColumn
    },

    {
      title: "Lead Source",
      field: "leadSource",
      hidden: showLeadSourceColumn
    },
    {
      title: "Lead Place",
      field: "leadCity",
      hidden: showLeadPlaceColumn
    },
    {
      title: " Preffered Country",
      field: " prefferedCountry",
      hidden: showPrefferedColumn
    },

    {
      title: "Lead Branch",
      field: "Lead Branch",
      hidden: showLeadBranchColumn
    },

    {
      title: "Deferal Intake month",
      field: "Deferal",
      filtering: false,
      hidden: showReasonColumn
    },

    {
      title: "Deferal Intake year",
      field: "Deferal",
      filtering: false,
      hidden: showReasonColumn
    },

    {
      title:
        loginFindSuccess?.role == null ||
        loginFindSuccess?.isManager ||
        loginFindSuccess?.role?.roleName !== "tele caller"
          ? "TeleCaller"
          : null,
      field: "TeleCaller",
      hidden: showTelecallerColumn
    },
    {
      title:
        loginFindSuccess?.role == null ||
        loginFindSuccess?.isManager ||
        loginFindSuccess?.role?.roleName !== "counsellor"
          ? "Counsilor"
          : null,
      field: "Counsilor",
      hidden: showCounsilorColumn
    },

    {
      title: "Action",
      field: "action",
      filtering: false
    }
  ];

  const excelDownloadClick = () => {
    DownloadExcel(columns, data, "Lead_Managment");
  };

  const toggleNameColumn = (status, column) => {
    if (column === "name") {
      setColumnName(column);
      setShowNameColumn(status);
    }

    if (column === "email") {
      setColumnName(column);
      setShowEmailColumn(status);
    }

    if (column === "mobile") {
      setColumnName(column);
      setShowPhoneColumn(status);
    }

    if (column === "whatappnumber") {
      setColumnName(column);
      setShowWhatsAppNumColumn(status);
    }

    if (column === "prefferedCountry") {
      setColumnName(column);
      setShowPrefferedColumn(status);
    }
    if (column === "leadSource") {
      setColumnName(column);
      setShowLeadSourceColumn(status);
    }
    if (column === "leadbranch") {
      setColumnName(column);
      setShowLeadBranchColumnn(status);
    }

    if (column === "place") {
      setColumnName(column);
      setShowLeadPlaceColumn(status);
    }

    if (column === "reason") {
      setColumnName(column);
      setReasonColumn(status);
    }

    if (column === "telecaller") {
      setColumnName(column);
      setShowTelecallerColumn(status);
    }

    if (column === "counsilor") {
      setColumnName(column);
      setShowCounsilorColumn(status);
    }
  };

  useEffect(() => {
    // Retrieve column visibility state from localStorage
    const storedRollNameColumn = localStorage.getItem("Deferalname");
    const storedRollEmailColumn = localStorage.getItem("Deferalemail");
    const storedPhoneColumn = localStorage.getItem("Deferalmobile");
    const storedWhatsAppColumn = localStorage.getItem("Deferalwhatappnumber");
    const storedPrefferedCountryColumn = localStorage.getItem(
      "DeferalprefferedCountry"
    );
    const storedLeadSourceColumn = localStorage.getItem("DeferalleadSource");
    const storedLeadBranchColumn = localStorage.getItem("Deferalleadbranch");
    const storedPlaceColumn = localStorage.getItem("Deferalplace");
    const storedReasonColumn = localStorage.getItem("Deferalreason");
    const storedTeleCallerColumn = localStorage.getItem("Deferaltelecaller");
    const storedCounsilorColumn = localStorage.getItem("Deferalcounsilor");

    if (storedRollNameColumn !== null) {
      setColumnName("name");
      setShowNameColumn(JSON.parse(storedRollNameColumn));
    }

    if (storedRollEmailColumn !== null) {
      setColumnName("email");
      setShowEmailColumn(JSON.parse(storedRollEmailColumn));
    }

    if (storedPhoneColumn !== null) {
      setColumnName("mobile");
      setShowPhoneColumn(JSON.parse(storedPhoneColumn));
    }

    if (storedWhatsAppColumn !== null) {
      setColumnName("whatsAppNumber");
      setShowWhatsAppNumColumn(JSON.parse(storedWhatsAppColumn));
    }

    if (storedPrefferedCountryColumn !== null) {
      setColumnName("prefferedCountry");
      setShowPrefferedColumn(JSON.parse(storedPrefferedCountryColumn));
    }
    if (storedLeadSourceColumn !== null) {
      setColumnName("leadSource");
      setShowLeadSourceColumn(JSON.parse(storedLeadSourceColumn));
    }

    if (storedLeadBranchColumn !== null) {
      setColumnName("leadbranch");
      setShowLeadBranchColumnn(JSON.parse(storedLeadBranchColumn));
    }

    if (storedPlaceColumn !== null) {
      setColumnName("place");
      setShowLeadPlaceColumn(JSON.parse(storedPlaceColumn));
    }

    if (storedReasonColumn !== null) {
      setColumnName("reason");
      setReasonColumn(JSON.parse(storedReasonColumn));
    }

    if (storedTeleCallerColumn !== null) {
      setColumnName("telecaller");
      setShowTelecallerColumn(JSON.parse(storedTeleCallerColumn));
    }

    if (storedCounsilorColumn !== null) {
      setColumnName("counsilor");
      setShowCounsilorColumn(JSON.parse(storedCounsilorColumn));
    }
  }, []);

  useEffect(() => {
    // Save column visibility state to localStorage
    localStorage.setItem("Deferalname", JSON.stringify(showNameColumn));
    localStorage.setItem("Deferalemail", JSON.stringify(showEmailColumn));
    localStorage.setItem("Deferalemail", JSON.stringify(showPrefferedColumn));
    localStorage.setItem("Deferalmobile", JSON.stringify(showPhoneColumn));
    localStorage.setItem(
      "Deferalwhatappnumber",
      JSON.stringify(showWhatsAppNumColumn)
    );
    localStorage.setItem(
      "DeferalprefferedCountry",
      JSON.stringify(showPrefferedColumn)
    );
    localStorage.setItem(
      "DeferalleadSource",
      JSON.stringify(showLeadSourceColumn)
    );
    localStorage.setItem(
      "Deferalleadbranch",
      JSON.stringify(showLeadBranchColumn)
    );
    localStorage.setItem("Deferalplace", JSON.stringify(showLeadPlaceColumn));
    localStorage.setItem("Deferalreason", JSON.stringify(showReasonColumn));
    localStorage.setItem(
      "Deferaltelecaller",
      JSON.stringify(showTelecallerColumn)
    );
    localStorage.setItem(
      "Deferalcounsilor",
      JSON.stringify(showCounsilorColumn)
    );
  }, [
    showNameColumn,
    showEmailColumn,
    showPhoneColumn,
    showWhatsAppNumColumn,
    showPrefferedColumn,
    showLeadSourceColumn,
    showLeadBranchColumn,
    showLeadPlaceColumn,
    showReasonColumn,
    showTelecallerColumn,
    showCounsilorColumn,
    columnName
  ]);

  const LeadFilterFunction = (data, filterOption, checkedStatus) => {
    if (filterOption === "city") {
      console.log(data, "dataaaaaaaaaaaaaaaa");
      setLeadData(
        leadData?.filter((item) => {
          console.log(item, "itemitemitemitemitem");
          return (
            item.location !== null &&
            item.location.toLowerCase().includes(data.toLowerCase())
          );
        })
      );

      setwhichFilter(filterOption);
      setFilterPassData(data);
    }
    if (filterOption === "preffered country") {
      if (checkedStatus === true) {
        setLeadData(
          leadData?.filter((item) => {
            return (
              item.preferredCountryId !== null &&
              item.preferredCountryId.toLowerCase().includes(data.toLowerCase())
              // data.includes(item.leadSource)
            );
          })
        );
        setwhichFilter(filterOption);
        setFilterPassData(data);
      } else {
        setwhichFilter(null);
        setFilterPassData(null);
        setLeadData(
          leadFindSuccess?.filter((data) => data.status === "deferral")
        );
      }
    }

    if (filterOption === "Role Name") {
      if (checkedStatus === true) {
        setLeadData(
          leadData?.filter((item) => {
            return (
              item.name !== null &&
              item.name.toLowerCase().includes(data.toLowerCase())
            );
          })
        );
        setwhichFilter(filterOption);
        setFilterPassData(data);
      } else {
        setwhichFilter(null);
        setFilterPassData(null);
        setLeadData(leadFindSuccess);
      }
    }

    if (filterOption === "leadStatus") {
      console.log(data, "datadatadatadatadatadata");
      if (checkedStatus == true) {
        setLeadData(
          leadData?.filter((item) => {
            return (
              item.status !== null &&
              item.status.toLowerCase().includes(data.toLowerCase())
              // data.includes(item.status)
            );
          })
        );
        setwhichFilter(filterOption);
        setFilterPassData(checkedStatus);
      } else {
        setwhichFilter(null);
        setFilterPassData(null);
        setLeadData(
          leadFindSuccess?.filter((data) => data.status === "deferral")
        );
      }
    }

    if (filterOption === "leadSource") {
      if (checkedStatus === true) {
        setLeadData(
          leadData?.filter((item) => {
            return (
              item.leadSource !== null &&
              item.leadSource.toLowerCase().includes(data.toLowerCase())
            );
          })
        );
        setwhichFilter(filterOption);
        setFilterPassData(data);
      } else {
        setwhichFilter(null);
        setFilterPassData(null);
        setLeadData(
          leadFindSuccess?.filter((data) => data.status === "deferral")
        );
      }
    }

    if (filterOption === "leadBranch") {
      if (checkedStatus === true) {
        console.log(data, "evalueevalueevalue");
        setLeadData(
          leadData?.filter((item) => {
            return (
              item?.branch_master?.branchName !== null &&
              item.branch_master?.branchName
                .toLowerCase()
                .includes(data.toLowerCase())
            );
          })
        );
        setwhichFilter(filterOption);
        setFilterPassData(data);
      } else {
        setwhichFilter(null);
        setFilterPassData(null);
        setLeadData(
          leadFindSuccess?.filter((data) => data.status === "deferral")
        );
      }
    }

    if (filterOption === "month") {
      let month;
      // let year;
      // let changedStatus;
      const date = new Date(data);
      month = date.toLocaleString("default", { month: "long" });
      // const convertyear = new Date(intakeYear);
      // year = convertyear.getFullYear();
      console.log(month,"monthmonth")
      const deferalData = leadFindSuccess?.filter((data) => data.status === "deferral")
      setLeadData(
        deferalData?.filter((item) => {
          return (
            item?.deferalIntakeMonth !== null &&
            item?.deferalIntakeMonth
              .toLowerCase()
              .includes(month.toLowerCase())
          );
        })
      );
    }

    if (filterOption === "year") {
      // let month;
      let year;
      // let changedStatus;
      // const date = new Date(data);
      // month = date.toLocaleString("default", { month: "long" });
      const convertyear = new Date(data);
      year = convertyear.getFullYear();
      const deferalData = leadFindSuccess?.filter((data) => data.status === "deferral")
      setLeadData(
        deferalData?.filter((item) => {
          return (
            item?.deferalIntakeYear !== null &&
            item?.deferalIntakeYear.includes(year)
          );
        })
      );
    }
  };

  const handleResetData = (status) => {
    setwhichFilter(null);
    setFilterPassData(null);
    setReset(!reset);
    setwhichFilter(null);
    setFilterPassData(null);
    setPaginationPageNumber(1);
  };

  const handlePaginationEntry = (e) => {
    let value = e.target.value;
    if (value <= 100) {
      setPagiantionEntry(value);
    }
  };

  const setIdsFunction = (leadsId) => {
    setLeadId(leadsId);
  };
  const handleDeleteClick = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!"
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteLeadAction(leadId));
      }
    });
  };

  const selectBoxIdPassHandler = (ids) => {
    setIds(ids);
  };

  if (!hasPrivilege("Defferal") && privilege !== null) {
    return (
      <Alert severity="error">
        <AlertTitle>warning</AlertTitle>
        You don't have permission to access — <strong>this page.!</strong>
      </Alert>
    );
  }
  return (
    <>
      <LeadersSearchHeader
        columnVisibleHandler={toggleNameColumn}
        excelDownloadClick={excelDownloadClick}
        showNameColumn={showNameColumn}
        showEmailColumn={showEmailColumn}
        showPrefferedColumn={showPrefferedColumn}
        showPhoneColumn={showPhoneColumn}
        showWhatAppNumberColumn={showWhatsAppNumColumn}
        showLeadSourceColumn={showLeadSourceColumn}
        showLeadBranchColumn={showLeadBranchColumn}
        showLeadPlaceColumn={showLeadPlaceColumn}
        showReasonColumn={showReasonColumn}
        showTelecallerColumn={showTelecallerColumn}
        showCounsilorColumn={showCounsilorColumn}
        filterPassData={filterPassData ? filterPassData : null}
        whichFilter={whichFilter ? whichFilter : null}
        handleResetData={handleResetData}
        handleDeleteClick={handleDeleteClick}
        searchHandleChange={searchHandleChange}
        ids={ids}
        leadId={leadId}
        component="leadDeferral"
        subMenu="Defferal"
      />
      {/* <div style={{display:"flex",justifyContent:"space-evenly", width:"auto"}}> */}
      <Grid container spacing={-3} sx={{ mt: "-31px" }}>
        <Grid item xs={12} md={2} lg={2.2}>
          <LeadsFilterSearch
            LeadFilterFunction={LeadFilterFunction}
            handleResetData={handleResetData}
            statusComponent="leadDeferral"
            data={leadFindSuccess}
            pagiantionEntry={pagiantionEntry}
            handlePaginationEntry={handlePaginationEntry}
          />
        </Grid>
        <Grid item xs={12} md={10} lg={9.8}>
          <LeadsTable
            toggle={props.toggle}
            showNameColumn={showNameColumn}
            showEmailColumn={showEmailColumn}
            showPrefferedColumn={showPrefferedColumn}
            showPhoneColumn={showPhoneColumn}
            showWhatAppNumberColumn={showWhatsAppNumColumn}
            showLeadSourceColumn={showLeadSourceColumn}
            showLeadBranchColumn={showLeadBranchColumn}
            showLeadPlaceColumn={showLeadPlaceColumn}
            showReasonColumn={showReasonColumn}
            showTelecallerColumn={showTelecallerColumn}
            showCounsilorColumn={showCounsilorColumn}
            reset={reset}
            setIdsFunction={setIdsFunction}
            data={data}
            columns={columns}
            leadFindLoading={leadFindLoading}
            selectBoxIdPassHandler={selectBoxIdPassHandler}
            isExistCounsilor={loginFindSuccess}
            component="leadDeferral"
            pagiantionEntry={pagiantionEntry}
            paginationPageNumber={paginationPageNumber}
            subMenu="Defferal"
            mainMenu="Deals"
          />
        </Grid>
      </Grid>
    </>
  );
}

export default LeadDead;
