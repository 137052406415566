import React, { useEffect, useState } from 'react'
import { MainContainer } from '../../content/content.element';
import { InfoHeader } from '../../customComponent/InfoHeader';
import { Box } from '@mui/material';
import BasicTabs from '../../Tabs/TabsMain';
import IeltsPurchasedStudentView from './IeltsPurchasedStudentView';
import IeltsPurchasedCourseView from './IeltsPurchasedCourseView';
import { ieltsPurchasedStudentDetailsFindByIdAction } from '../../../actions/IeltsActions/purchasedStudentActions';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

function PurchasedDetailedView({ toggle }) {
  let { ieltsPurchasedStudentDetailsFindByIdSuccess } = useSelector((state) => {
    return state.ieltsPurchasedStudentDetailsFindById;
  });


  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(ieltsPurchasedStudentDetailsFindByIdAction(id));
  }, [dispatch]);

  return (
    <MainContainer active={toggle}>
      <InfoHeader headTitle={"GSL Calicut"} url="/IELTS" />
      <Box sx={{}}>
        <BasicTabs
          tab1Name={"Student Details"}
          tab2Name={"Courses"}
          tab1={<IeltsPurchasedStudentView />}
          tab2={<IeltsPurchasedCourseView />}
        />
      </Box>
    </MainContainer>
  );
}

export default PurchasedDetailedView