export const COUNTRY_MASTER_ADD_REQUEST = "COUNTRY_MASTER_ADD_REQUEST";
export const COUNTRY_MASTER_ADD_SUCCESS = "COUNTRY_MASTER_ADD_SUCCESS";
export const COUNTRY_MASTER_ADD_ERR = "COUNTRY_MASTER_ADD_ERR";

export const COUNTRY_MASTER_FIND_REQUEST = "COUNTRY_MASTER_FIND_REQUEST";
export const COUNTRY_MASTER_FIND_SUCCESS = "COUNTRY_MASTER_FIND_SUCCESS";
export const COUNTRY_MASTER_FIND_ERR = "COUNTRY_MASTER_FIND_ERR";

export const COUNTRY_MASTER_DELETE_REQUEST = "COUNTRY_MASTER_DELETE_REQUEST";
export const COUNTRY_MASTER_DELETE_SUCCESS = "COUNTRY_MASTER_DELETE_SUCCESS";
export const COUNTRY_MASTER_DELETE_ERR = "COUNTRY_MASTER_DELETE_ERR";

export const COUNTRY_MASTER_EDIT_FIND_REQUEST =
  "COUNTRY_MASTER_EDIT_FIND_REQUEST";
export const COUNTRY_MASTER_EDIT_FIND_SUCCESS =
  "COUNTRY_MASTER_EDIT_FIND_SUCCESS";
export const COUNTRY_MASTER_EDIT_FIND_ERR = "COUNTRY_MASTER_EDIT_FIND_ERR";

export const COUNTRY_MASTER_EDIT_REQUEST = "COUNTRY_MASTER_EDIT_REQUEST";
export const COUNTRY_MASTER_EDIT_SUCCESS = "COUNTRY_MASTER_EDIT_SUCCESS";
export const COUNTRY_MASTER_EDIT_ERR = "COUNTRY_MASTER_EDIT_ERR";

export const COUNTRY_FETCH_UNIQUE_TOKEN_REQUEST = "COUNTRY_FETCH_UNIQUE_TOKEN_REQUEST";
export const COUNTRY_FETCH_UNIQUE_TOKEN_SUCCESS = "COUNTRY_FETCH_UNIQUE_TOKEN_SUCCESS";
export const COUNTRY_FETCH_UNIQUE_TOKEN_ERR = "COUNTRY_FETCH_UNIQUE_TOKEN_ERR";


export const COUNTRY_CURRENCY_FIND_REQUEST = "COUNTRY_CURRENCY_FIND_REQUEST";
export const COUNTRY_CURRENCY_FIND_SUCCESS = "COUNTRY_CURRENCY_FIND_SUCCESS";
export const COUNTRY_CURRENCY_FIND_ERR = "COUNTRY_CURRENCY_FIND_ERR";
