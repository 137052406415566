import React, { useEffect, useState } from "react";
import PartnerTable from "./PartnerTable/PartnerTable";
import { useDispatch, useSelector } from "react-redux";
import { findAllPartnerAction } from "../../actions/partnerAction/partnerAction";
import LeadersSearchHeader from "../Leads/LeadersSearchHeader";
import DownloadExcel from "../extraComponents/DownloadExcel";
import Swal from "sweetalert2";
import PartnerSearch from "./PartnerSearch";
import { successTrueMsgAction } from "../../actions/globalAction";
import usePrivilegeCheck from "../../constants/customHooks/UsePrivilegeCheck";
import { Alert, AlertTitle } from "@mui/material";

// material Table
const columns = [
  {
    title: "Sl no",
    field: "sl",
  },
  {
    title: "Partner Name",
    field: "partnerName",
    hidden: false,
  },
  {
    title: "Email",
    field: "email",
    hidden: false,
  },
  {
    title: "Phone",
    field: "phone",
    hidden: false,
  },
  {
    title: "Manager",
    field: "manager",
    hidden: false,
  },
  {
    title: "OTP Verification",
    field: "OTP",
    hidden: false,
  },
  {
    title: "",
    field: "action",
    filtering: false,
  },
];

function AllPartners() {
  const dispatch = useDispatch();

  const [data, setData] = useState([]);
  // const [partnerdata, setPartnerData] = useState([]);
  const [partnerdata, setPartnerData] = useState([]);
  const [filterPassData, setFilterPassData] = useState();
  const [columnName, setColumnName] = useState("");
  const [whichFilter, setwhichFilter] = useState();
  const [reset, setReset] = useState(false);
  const [paginationPageNumber, setPaginationPageNumber] = useState(1);
  const [leadId, setLeadId] = useState([]);
  const [ids, setIds] = useState([]);

  // / Employee column Visibility
  const [showNameColumn, setShowNameColumn] = useState(false);
  const [showEmailColumn, setShowEmailColumn] = useState(false);
  const [showPhoneColumn, setShowPhoneColumn] = useState(false);
  const [showWhatsAppNumColumn, setShowWhatsAppNumColumn] = useState(false);
  const [showPrefferedColumn, setShowPrefferedColumn] = useState(false);
  const [showLeadSourceColumn, setShowLeadSourceColumn] = useState(false);
  const [showLeadTypeColumn, setShowLeadTypeColumn] = useState(false);
  const [showStatusColumn, setShowStatusColumn] = useState(false);
  const [showEducationColumn, setShowEducationColumn] = useState(false);
  const [showUniversityPlaceColumn, setShowUniversityPlaceColumn] = useState(
    false
  );
  const {
    privilege,
    hasPrivilege,
    isCreateNewPartnerPrevilage,
    isChangeLeaderPrevilage,
    isDeletePrevilage,
    isCommisionCategoryChangePrevilage,
  } = usePrivilegeCheck();

  let { partnerFindSuccess } = useSelector((state) => {
    return state.findAllPartner;
  });

  let { successAlertMsgSuccess, successAlertMsgErr } = useSelector((state) => {
    return state.successTrueMsg;
  });

  let { partnerTeamLeadAssignSuccess } = useSelector((state) => {
    return state.partnerManagerChange;
  });

  useEffect(() => {
    if (partnerTeamLeadAssignSuccess && successAlertMsgSuccess) {
      Swal.fire("Added!", "Manger assigned successfully.", "success");
      dispatch(successTrueMsgAction(false));
    }
  }, [partnerTeamLeadAssignSuccess, successAlertMsgSuccess]);

  useEffect(() => {
    if (partnerFindSuccess) {
      setData(partnerFindSuccess);
      setPartnerData(
        partnerFindSuccess?.map((item) => ({
          id: item.id,
          column1: item.name,
          column2: item.email,
          column3: item.phone,
          column4: item?.user?.firstName,
          column5: item?.user?.firstName,
          column6: item.isVerified,
        }))
      );
    }
  }, [partnerFindSuccess]);

  useEffect(() => {
    dispatch(findAllPartnerAction());
  }, [dispatch, partnerTeamLeadAssignSuccess]);

  const toggleNameColumn = (status, column) => {
    if (column === "name") {
      setColumnName(column);
      setShowNameColumn(status);
    }

    if (column === "email") {
      setColumnName(column);
      setShowEmailColumn(status);
    }

    if (column === "mobile") {
      setColumnName(column);
      setShowPhoneColumn(status);
    }

    if (column === "whatsAppNumber") {
      setColumnName(column);
      setShowWhatsAppNumColumn(status);
    }

    if (column === "prefferedCountry") {
      setColumnName(column);
      setShowPrefferedColumn(status);
    }
    if (column === "leadSource") {
      setColumnName(column);
      setShowLeadSourceColumn(status);
    }

    if (column === "leadType") {
      setColumnName(column);
      setShowLeadTypeColumn(status);
    }

    if (column === "status") {
      setColumnName(column);
      setShowStatusColumn(status);
    }

    if (column === "education") {
      setColumnName(column);
      setShowEducationColumn(status);
    }

    if (column === "universityPlace") {
      setColumnName(column);
      setShowUniversityPlaceColumn(status);
    }
  };

  // excel download
  const excelDownloadClick = () => {
    DownloadExcel(columns, data, "Partner_Managment");
  };

  // searching
  const searchHandleChange = (e) => {
    if (e.target.value !== "" && e.target.value !== " ") {
      setPartnerData(
        partnerdata?.filter((item) => {
          const name = item.column1 ? item.column1.toLowerCase() : "";
          const email = item.column2 ? item.column2.toLowerCase() : "";
          const phone = item.column3 ? item.column3.toLowerCase() : "";
          const manager = item.column4 ? item.column4.toLowerCase() : "";
          // const preferredCountry = item.preferredCountry
          //   ? item.preferredCountry.toLowerCase()
          //   : "";
          // const leadSource = item.leadSource
          //   ? item.leadSource.toLowerCase()
          //   : "";
          // const leadOwner = item.leadOwner ? item.leadOwner.toLowerCase() : "";
          // const status = item.status ? item.status.toLowerCase() : "";
          // const education = item.education ? item.education.toLowerCase() : "";

          // const leadCity = item.leadCity ? item.leadCity.toLowerCase() : "";
          // const leadBranch = item.branchMaster
          //   ? item.leadCity.toLowerCase()
          //   : "";

          // :lead?.branch_master?.branchName
          return (
            name.includes(e.target.value.toLowerCase()) ||
            phone.includes(e.target.value.toLowerCase()) ||
            manager.includes(e.target.value.toLowerCase()) ||
            email.includes(e.target.value.toLowerCase())
            // preferredCountry.includes(e.target.value.toLowerCase())
            // leadSource.includes(e.target.value.toLowerCase()) ||
            // leadOwner.includes(e.target.value.toLowerCase()) ||
            // status.includes(e.target.value.toLowerCase()) ||
            // education.includes(e.target.value.toLowerCase()) ||
            // leadCity.includes(e.target.value.toLowerCase()) ||
            // leadBranch.includes(e.target.value.toLowerCase())
          );
        })
      );
    } else {
      setPartnerData(
        data.map((item) => ({
          id: item.id,
          column1: item.name,
          column2: item.email,
          column3: item.phone,
          column4: item?.user?.firstName,
          column5: item?.user?.firstName,
          column6: item.isVerified,
        }))
      );
    }
  };

  // cleare filter
  const handleResetData = (status) => {
    setwhichFilter(null);
    setFilterPassData(null);
    setReset(!reset);
    setwhichFilter(null);
    setFilterPassData(null);
    setPaginationPageNumber(1);
  };

  const handleDeleteClick = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        // dispatch(deleteLeadAction(leadId));
      }
    });
  };

  const setIdsFunction = (leadsId) => {
    setLeadId(leadsId);
  };

  if (!hasPrivilege("All Partners") && privilege !== null) {
    return (
      <Alert severity="error">
        <AlertTitle>warning</AlertTitle>
        You don't have permission to access — <strong>this page.!</strong>
      </Alert>
    );
  }

  return (
    <>
      <PartnerSearch
        columnVisibleHandler={toggleNameColumn}
        excelDownloadClick={excelDownloadClick}
        showNameColumn={showNameColumn}
        showEmailColumn={showEmailColumn}
        showPrefferedColumn={showPrefferedColumn}
        showPhoneColumn={showPhoneColumn}
        showWhatsAppNumColumn={showWhatsAppNumColumn}
        showLeadSourceColumn={showLeadSourceColumn}
        showLeadTypeColumn={showLeadTypeColumn}
        showStatusColumn={showStatusColumn}
        showEducationColumn={showEducationColumn}
        showUniversityPlaceColumn={showUniversityPlaceColumn}
        filterPassData={filterPassData ? filterPassData : null}
        whichFilter={whichFilter ? whichFilter : null}
        handleResetData={handleResetData}
        handleDeleteClick={handleDeleteClick}
        searchHandleChange={searchHandleChange}
        ids={ids}
        leadId={leadId}
        subMenu="All Partners"
      />
      <PartnerTable
        columns={columns}
        data={partnerdata.reverse()}
        component="partnerEnquiry"
        setIdsFunction={setIdsFunction}
        subMenu="All Partners"
      />
    </>
  );
}

export default AllPartners;
