// EXAMTIPS CONSTANTS ///////////////////////////////////////////////////////////////////////////////

// IELTS COURSEMANAGMENT EXAMTIPS CREATE CONSTANTS

export const IELTS_COURSEMANAGMENT_EXAMTIPS_CREATE_REQUEST =
  "IELTS_COURSEMANAGMENT_EXAMTIPS_CREATE_REQUEST";
export const IELTS_COURSEMANAGMENT_EXAMTIPS_CREATE_SUCCESS =
  "IELTS_COURSEMANAGMENT_EXAMTIPS_CREATE_SUCCESS";
export const IELTS_COURSEMANAGMENT_EXAMTIPS_CREATE_ERR =
  "IELTS_COURSEMANAGMENT_EXAMTIPS_CREATE_ERR";

// IELTS COURSEMANAGMENT EXAMTIPS FIND CONSTANTS

export const IELTS_COURSEMANAGMENT_EXAMTIPS_FIND_REQUEST =
  "IELTS_COURSEMANAGMENT_EXAMTIPS_FIND_REQUEST";
export const IELTS_COURSEMANAGMENT_EXAMTIPS_FIND_SUCCESS =
  "IELTS_COURSEMANAGMENT_EXAMTIPS_FIND_SUCCESS";
export const IELTS_COURSEMANAGMENT_EXAMTIPS_FIND_ERR =
  "IELTS_COURSEMANAGMENT_EXAMTIPS_FIND_ERR";

// IELTS COURSEMANAGMENT EXAMTIPS FINDBYID CONSTANTS

export const IELTS_COURSEMANAGMENT_EXAMTIPS_FINDBYID_REQUEST =
  "IELTS_COURSEMANAGMENT_EXAMTIPS_FINDBYID_REQUEST";
export const IELTS_COURSEMANAGMENT_EXAMTIPS_FINDBYID_SUCCESS =
  "IELTS_COURSEMANAGMENT_EXAMTIPS_FINDBYID_SUCCESS";
export const IELTS_COURSEMANAGMENT_EXAMTIPS_FINDBYID_ERR =
  "IELTS_COURSEMANAGMENT_EXAMTIPS_FINDBYID_ERR";

// IELTS COURSEMANAGMENT EXAMTIPS UPDATE CONSTANTS

export const IELTS_COURSEMANAGMENT_EXAMTIPS_UPDATE_REQUEST =
  "IELTS_COURSEMANAGMENT_EXAMTIPS_UPDATE_REQUEST";
export const IELTS_COURSEMANAGMENT_EXAMTIPS_UPDATE_SUCCESS =
  "IELTS_COURSEMANAGMENT_EXAMTIPS_UPDATE_SUCCESS";
export const IELTS_COURSEMANAGMENT_EXAMTIPS_UPDATE_ERR =
  "IELTS_COURSEMANAGMENT_EXAMTIPS_UPDATE_ERR";

// IELTS COURSEMANAGMENT EXAMTIPS STATUSCHANGE CONSTANTS

export const IELTS_COURSEMANAGMENT_EXAMTIPS_STATUSCHANGE_REQUEST =
  "IELTS_COURSEMANAGMENT_EXAMTIPS_STATUSCHANGE_REQUEST";
export const IELTS_COURSEMANAGMENT_EXAMTIPS_STATUSCHANGE_SUCCESS =
  "IELTS_COURSEMANAGMENT_EXAMTIPS_STATUSCHANGE_SUCCESS";
export const IELTS_COURSEMANAGMENT_EXAMTIPS_STATUSCHANGE_ERR =
  "IELTS_COURSEMANAGMENT_EXAMTIPS_STATUSCHANGE_ERR";

// IELTS COURSEMANAGMENT EXAMTIPS DELETE CONSTANTS

export const IELTS_COURSEMANAGMENT_EXAMTIPS_DELETE_REQUEST =
  "IELTS_COURSEMANAGMENT_EXAMTIPS_DELETE_REQUEST";
export const IELTS_COURSEMANAGMENT_EXAMTIPS_DELETE_SUCCESS =
  "IELTS_COURSEMANAGMENT_EXAMTIPS_DELETE_SUCCESS";
export const IELTS_COURSEMANAGMENT_EXAMTIPS_DELETE_ERR =
  "IELTS_COURSEMANAGMENT_EXAMTIPS_DELETE_ERR";

// IELTS COURSEMANAGMENT EXAMTIPS PDFDELETE CONSTANTS

export const IELTS_COURSEMANAGMENT_EXAMTIPSPDF_DELETE_REQUEST =
  "IELTS_COURSEMANAGMENT_EXAMTIPSPDF_DELETE_REQUEST";
export const IELTS_COURSEMANAGMENT_EXAMTIPSPDF_DELETE_SUCCESS =
  "IELTS_COURSEMANAGMENT_EXAMTIPSPDF_DELETE_SUCCESS";
export const IELTS_COURSEMANAGMENT_EXAMTIPSPDF_DELETE_ERR =
  "IELTS_COURSEMANAGMENT_EXAMTIPSPDF_DELETE_ERR";

// QUESTION BANK CONSTANTS *** //////

// IELTS COURSEMANAGMENT QUESTIONBANK CREATE CONSTANTS

export const IELTS_COURSEMANAGMENT_QUESTIONBANK_CREATE_REQUEST =
  "IELTS_COURSEMANAGMENT_QUESTIONBANK_CREATE_REQUEST";
export const IELTS_COURSEMANAGMENT_QUESTIONBANK_CREATE_SUCCESS =
  "IELTS_COURSEMANAGMENT_QUESTIONBANK_CREATE_SUCCESS";
export const IELTS_COURSEMANAGMENT_QUESTIONBANK_CREATE_ERR =
  "IELTS_COURSEMANAGMENT_QUESTIONBANK_CREATE_ERR";

// IELTS COURSEMANAGMENT QUESTIONBANK FIND CONSTANTS

export const IELTS_COURSEMANAGMENT_QUESTIONBANK_FIND_REQUEST =
  "IELTS_COURSEMANAGMENT_QUESTIONBANK_FIND_REQUEST";
export const IELTS_COURSEMANAGMENT_QUESTIONBANK_FIND_SUCCESS =
  "IELTS_COURSEMANAGMENT_QUESTIONBANK_FIND_SUCCESS";
export const IELTS_COURSEMANAGMENT_QUESTIONBANK_FIND_ERR =
  "IELTS_COURSEMANAGMENT_QUESTIONBANK_FIND_ERR";

// IELTS COURSEMANAGMENT QUESTIONBANK FINDBYID CONSTANTS

export const IELTS_COURSEMANAGMENT_QUESTIONBANK_FINDBYID_REQUEST =
  "IELTS_COURSEMANAGMENT_QUESTIONBANK_FINDBYID_REQUEST";
export const IELTS_COURSEMANAGMENT_QUESTIONBANK_FINDBYID_SUCCESS =
  "IELTS_COURSEMANAGMENT_QUESTIONBANK_FINDBYID_SUCCESS";
export const IELTS_COURSEMANAGMENT_QUESTIONBANK_FINDBYID_ERR =
  "IELTS_COURSEMANAGMENT_QUESTIONBANK_FINDBYID_ERR";

// IELTS COURSEMANAGMENT QUESTIONBANK UPDATE CONSTANTS

export const IELTS_COURSEMANAGMENT_QUESTIONBANK_UPDATE_REQUEST =
  "IELTS_COURSEMANAGMENT_QUESTIONBANK_UPDATE_REQUEST";
export const IELTS_COURSEMANAGMENT_QUESTIONBANK_UPDATE_SUCCESS =
  "IELTS_COURSEMANAGMENT_QUESTIONBANK_UPDATE_SUCCESS";
export const IELTS_COURSEMANAGMENT_QUESTIONBANK_UPDATE_ERR =
  "IELTS_COURSEMANAGMENT_QUESTIONBANK_UPDATE_ERR";

// IELTS COURSEMANAGMENT QUESTIONBANK STATUSCHANGE CONSTANTS

export const IELTS_COURSEMANAGMENT_QUESTIONBANK_STATUSCHANGE_REQUEST =
  "IELTS_COURSEMANAGMENT_QUESTIONBANK_STATUSCHANGE_REQUEST";
export const IELTS_COURSEMANAGMENT_QUESTIONBANK_STATUSCHANGE_SUCCESS =
  "IELTS_COURSEMANAGMENT_QUESTIONBANK_STATUSCHANGE_SUCCESS";
export const IELTS_COURSEMANAGMENT_QUESTIONBANK_STATUSCHANGE_ERR =
  "IELTS_COURSEMANAGMENT_QUESTIONBANK_STATUSCHANGE_ERR";

// IELTS COURSEMANAGMENT QUESTIONBANK DELETE CONSTANTS

export const IELTS_COURSEMANAGMENT_QUESTIONBANK_DELETE_REQUEST =
  "IELTS_COURSEMANAGMENT_QUESTIONBANK_DELETE_REQUEST";
export const IELTS_COURSEMANAGMENT_QUESTIONBANK_DELETE_SUCCESS =
  "IELTS_COURSEMANAGMENT_QUESTIONBANK_DELETE_SUCCESS";
export const IELTS_COURSEMANAGMENT_QUESTIONBANK_DELETE_ERR =
  "IELTS_COURSEMANAGMENT_QUESTIONBANK_DELETE_ERR";

// IELTS COURSEMANAGMENT QUESTIONBANK PDFDELETE CONSTANTS

export const IELTS_COURSEMANAGMENT_QUESTIONBANKPDF_DELETE_REQUEST =
  "IELTS_COURSEMANAGMENT_QUESTIONBANKPDF_DELETE_REQUEST";
export const IELTS_COURSEMANAGMENT_QUESTIONBANKPDF_DELETE_SUCCESS =
  "IELTS_COURSEMANAGMENT_QUESTIONBANKPDF_DELETE_SUCCESS";
export const IELTS_COURSEMANAGMENT_QUESTIONBANKPDF_DELETE_ERR =
  "IELTS_COURSEMANAGMENT_QUESTIONBANKPDF_DELETE_ERR";

// COURSE MANAGEMENT COURSE CREATEION

export const IELTS_COURSEMANAGMENT_COURSE_CREATE_REQUEST =
  "IELTS_COURSEMANAGMENT_COURSE_CREATE_REQUEST";
export const IELTS_COURSEMANAGMENT_COURSE_CREATE_SUCCESS =
  "IELTS_COURSEMANAGMENT_COURSE_CREATE_SUCCESS";
export const IELTS_COURSEMANAGMENT_COURSE_CREATE_ERR =
  "IELTS_COURSEMANAGMENT_COURSE_CREATE_ERR";

export const IELTS_COURSEMANAGMENT_COURSE_CREATE_CHECK_ERROR_SUCCESS =
  "IELTS_COURSEMANAGMENT_COURSE_CREATE_ERROR_SUCCESS";

// IELTS COURSEMANAGMENT COURSE TOPIC FIND CONSTANTS

export const IELTS_COURSEMANAGMENT_COURSE_TOPICFIND_REQUEST =
  "IELTS_COURSEMANAGMENT_COURSE_TOPICFIND_REQUEST";
export const IELTS_COURSEMANAGMENT_COURSE_TOPICFIND_SUCCESS =
  "IELTS_COURSEMANAGMENT_COURSE_TOPICFIND_SUCCESS";
export const IELTS_COURSEMANAGMENT_COURSE_TOPICFIND_ERR =
  "IELTS_COURSEMANAGMENT_COURSE_TOPICFIND_ERR";

// IELTS COURSEMANAGMENT COURSE FIND CONSTANTS

export const IELTS_COURSEMANAGMENT_COURSE_FIND_REQUEST =
  "IELTS_COURSEMANAGMENT_COURSE_FIND_REQUEST";
export const IELTS_COURSEMANAGMENT_COURSE_FIND_SUCCESS =
  "IELTS_COURSEMANAGMENT_COURSE_FIND_SUCCESS";
export const IELTS_COURSEMANAGMENT_COURSE_FIND_ERR =
  "IELTS_COURSEMANAGMENT_COURSE_FIND_ERR";

// IELTS COURSEMANAGMENT COURSE FINDBYID CONSTANTS

export const IELTS_COURSEMANAGMENT_COURSE_FINDBYID_REQUEST =
  "IELTS_COURSEMANAGMENT_COURSE_FINDBYID_REQUEST";
export const IELTS_COURSEMANAGMENT_COURSE_FINDBYID_SUCCESS =
  "IELTS_COURSEMANAGMENT_COURSE_FINDBYID_SUCCESS";
export const IELTS_COURSEMANAGMENT_COURSE_FINDBYID_ERR =
  "IELTS_COURSEMANAGMENT_COURSE_FINDBYID_ERR";

// IELTS COURSEMANAGMENT COURSE CREATE CONSTANTS

export const IELTS_COURSEMANAGMENT_COURSE_CREATING_REQUEST =
  "IELTS_COURSEMANAGMENT_COURSE_CREATING_REQUEST";
export const IELTS_COURSEMANAGMENT_COURSE_CREATING_SUCCESS =
  "IELTS_COURSEMANAGMENT_COURSE_CREATING_SUCCESS";
export const IELTS_COURSEMANAGMENT_COURSE_CREATING_ERR =
  "IELTS_COURSEMANAGMENT_COURSE_CREATING_ERR";

// IELTS COURSEMANAGMENT COURSE EDIT FIND CONSTANTS

export const IELTS_COURSEMANAGMENT_COURSE_EDITFIND_REQUEST =
  "IELTS_COURSEMANAGMENT_COURSE_EDITFIND_REQUEST";
export const IELTS_COURSEMANAGMENT_COURSE_EDITFIND_SUCCESS =
  "IELTS_COURSEMANAGMENT_COURSE_EDITFIND_SUCCESS";
export const IELTS_COURSEMANAGMENT_COURSE_EDITFIND_ERR =
  "IELTS_COURSEMANAGMENT_COURSE_EDITFIND_ERR";



// IELTS COURSEMANAGMENT COURSE EDIT CONSTANTS

export const IELTS_COURSEMANAGMENT_COURSE_EDIT_REQUEST =
  "IELTS_COURSEMANAGMENT_COURSE_EDIT_REQUEST";
export const IELTS_COURSEMANAGMENT_COURSE_EDIT_SUCCESS =
  "IELTS_COURSEMANAGMENT_COURSE_EDIT_SUCCESS";
export const IELTS_COURSEMANAGMENT_COURSE_EDIT_ERR =
  "IELTS_COURSEMANAGMENT_COURSE_EDIT_ERR";


// IELTS COURSEMANAGMENT COURSE STATUSCHANGE CONSTANTS

export const IELTS_COURSEMANAGMENT_COURSE_STATUSCHANGE_REQUEST =
  "IELTS_COURSEMANAGMENT_COURSE_STATUSCHANGE_REQUEST";
export const IELTS_COURSEMANAGMENT_COURSE_STATUSCHANGE_SUCCESS =
  "IELTS_COURSEMANAGMENT_COURSE_STATUSCHANGE_SUCCESS";
export const IELTS_COURSEMANAGMENT_COURSE_STATUSCHANGE_ERR =
  "IELTS_COURSEMANAGMENT_COURSE_STATUSCHANGE_ERR";

// IELTS COURSEMANAGMENT COURSE CENTER SLOT CONSTANTS

export const IELTS_COURSEMANAGMENT_COURSE_SLOT_REQUEST =
  "IELTS_COURSEMANAGMENT_COURSE_SLOT_REQUEST";
export const IELTS_COURSEMANAGMENT_COURSE_SLOT_SUCCESS =
  "IELTS_COURSEMANAGMENT_COURSE_SLOT_SUCCESS";
export const IELTS_COURSEMANAGMENT_COURSE_SLOT_ERR =
  "IELTS_COURSEMANAGMENT_COURSE_SLOT_ERR";

// IELTS COURSEMANAGMENT COURSE CENTER  CONSTANTS

export const IELTS_COURSEMANAGMENT_COURSE_CENTER_FIND_REQUEST =
  "IELTS_COURSEMANAGMENT_COURSE_CENTER_FIND_REQUEST";
export const IELTS_COURSEMANAGMENT_COURSE_CENTER_FIND_SUCCESS =
  "IELTS_COURSEMANAGMENT_COURSE_CENTER_FIND_SUCCESS";
export const IELTS_COURSEMANAGMENT_COURSE_CENTER_FIND_ERR =
  "IELTS_COURSEMANAGMENT_COURSE_CENTER_FIND_ERR";

// IELTS COURSEMANAGMENT LIVECLASS CREATE CONSTANTS

export const IELTS_COURSEMANAGMENT_COURSE_LIVECLASS_CREATE_REQUEST =
  "IELTS_COURSEMANAGMENT_COURSE_LIVECLASS_CREATE_REQUEST";
export const IELTS_COURSEMANAGMENT_COURSE_LIVECLASS_CREATE_SUCCESS =
  "IELTS_COURSEMANAGMENT_COURSE_LIVECLASS_CREATE_SUCCESS";
export const IELTS_COURSEMANAGMENT_COURSE_LIVECLASS_CREATE_ERR =
  "IELTS_COURSEMANAGMENT_COURSE_LIVECLASS_CREATE_ERR";

// IELTS COURSEMANAGMENT LIVECLASS FIND CONSTANTS

export const IELTS_COURSEMANAGMENT_COURSE_LIVECLASS_FIND_REQUEST =
  "IELTS_COURSEMANAGMENT_COURSE_LIVECLASS_FIND_REQUEST";
export const IELTS_COURSEMANAGMENT_COURSE_LIVECLASS_FIND_SUCCESS =
  "IELTS_COURSEMANAGMENT_COURSE_LIVECLASS_FIND_SUCCESS";
export const IELTS_COURSEMANAGMENT_COURSE_LIVECLASS_FIND_ERR =
  "IELTS_COURSEMANAGMENT_COURSE_LIVECLASS_FIND_ERR";

// IELTS COURSEMANAGMENT LIVECLASS FINDBYID CONSTANTS

export const IELTS_COURSEMANAGMENT_COURSE_LIVECLASS_FINDBYID_REQUEST =
  "IELTS_COURSEMANAGMENT_COURSE_LIVECLASS_FINDBYID_REQUEST";
export const IELTS_COURSEMANAGMENT_COURSE_LIVECLASS_FINDBYID_SUCCESS =
  "IELTS_COURSEMANAGMENT_COURSE_LIVECLASS_FINDBYID_SUCCESS";
export const IELTS_COURSEMANAGMENT_COURSE_LIVECLASS_FINDBYID_ERR =
  "IELTS_COURSEMANAGMENT_COURSE_LIVECLASS_FINDBYID_ERR";

// IELTS COURSEMANAGMENT LIVECLASS STATUSCHANGE CONSTANTS

export const IELTS_COURSEMANAGMENT_COURSE_LIVECLASS_STATUSCHANGE_REQUEST =
  "IELTS_COURSEMANAGMENT_COURSE_LIVECLASS_STATUSCHANGE_REQUEST";
export const IELTS_COURSEMANAGMENT_COURSE_LIVECLASS_STATUSCHANGE_SUCCESS =
  "IELTS_COURSEMANAGMENT_COURSE_LIVECLASS_STATUSCHANGE_SUCCESS";
export const IELTS_COURSEMANAGMENT_COURSE_LIVECLASS_STATUSCHANGE_ERR =
  "IELTS_COURSEMANAGMENT_COURSE_LIVECLASS_STATUSCHANGE_ERR";

// IELTS COURSEMANAGMENT LIVECLASS UPDATE CONSTANTS

export const IELTS_COURSEMANAGMENT_COURSE_LIVECLASS_UPDATE_REQUEST =
  "IELTS_COURSEMANAGMENT_COURSE_LIVECLASS_UPDATE_REQUEST";
export const IELTS_COURSEMANAGMENT_COURSE_LIVECLASS_UPDATE_SUCCESS =
  "IELTS_COURSEMANAGMENT_COURSE_LIVECLASS_UPDATE_SUCCESS";
export const IELTS_COURSEMANAGMENT_COURSE_LIVECLASS_UPDATE_ERR =
  "IELTS_COURSEMANAGMENT_COURSE_LIVECLASS_UPDATE_ERR";

// IELTS COURSEMANAGMENT LIVECLASS DELETE CONSTANTS

export const IELTS_COURSEMANAGMENT_COURSE_LIVECLASS_DELETE_REQUEST =
  "IELTS_COURSEMANAGMENT_COURSE_LIVECLASS_DELETE_REQUEST";
export const IELTS_COURSEMANAGMENT_COURSE_LIVECLASS_DELETE_SUCCESS =
  "IELTS_COURSEMANAGMENT_COURSE_LIVECLASS_DELETE_SUCCESS";
export const IELTS_COURSEMANAGMENT_COURSE_LIVECLASS_DELETE_ERR =
  "IELTS_COURSEMANAGMENT_COURSE_LIVECLASS_DELETE_ERR";
