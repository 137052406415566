import React from 'react'

function WebsiteIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.5902 11.1589C13.3982 11.1589 13.2062 11.0857 13.06 10.9391C11.8903 9.76948 9.9872 9.76911 8.8172 10.9391C8.5247 11.232 8.0492 11.232 7.7567 10.9391C7.46383 10.6462 7.46383 10.1715 7.7567 9.87861C9.51133 8.12361 12.3662 8.12398 14.1205 9.87861C14.4133 10.1715 14.4133 10.6462 14.1205 10.9391C13.9742 11.0857 13.7818 11.1589 13.5902 11.1589Z"
        fill="#ACB1C6"
      />
      <path
        d="M13.0599 15.4378C11.9075 15.4378 10.7552 14.9991 9.87804 14.122C9.58517 13.8291 9.58517 13.3543 9.87804 13.0615C10.1705 12.7686 10.646 12.7686 10.9385 13.0615C12.1082 14.2311 14.0113 14.2315 15.1813 13.0615C15.4738 12.7686 15.9493 12.7686 16.2418 13.0615C16.5347 13.3543 16.5347 13.8291 16.2418 14.122C15.3643 14.9991 14.2119 15.4378 13.0599 15.4378Z"
        fill="#ACB1C6"
      />
      <path
        d="M15.7108 14.3418C15.5188 14.3418 15.3268 14.2686 15.1806 14.122C14.8877 13.8291 14.8877 13.3544 15.1806 13.0615L19.3701 8.872C19.6626 8.57912 20.1381 8.57912 20.4306 8.872C20.7235 9.16488 20.7235 9.63963 20.4306 9.9325L16.2411 14.122C16.0948 14.2686 15.9028 14.3418 15.7108 14.3418Z"
        fill="#ACB1C6"
      />
      <path
        d="M9.4003 20.6514C9.2083 20.6514 9.0163 20.5782 8.87005 20.4316C8.57717 20.1387 8.57717 19.664 8.87005 19.3711L11.2119 17.0292C11.5044 16.7364 11.9799 16.7364 12.2724 17.0292C12.5653 17.3221 12.5653 17.7969 12.2724 18.0897L9.93055 20.4316C9.7843 20.5782 9.5923 20.6514 9.4003 20.6514Z"
        fill="#ACB1C6"
      />
      <path
        d="M4.09744 15.3482C3.90544 15.3482 3.71344 15.2751 3.56719 15.1285C3.27432 14.8356 3.27432 14.3608 3.56719 14.068L7.75669 9.87884C8.04956 9.58596 8.52469 9.58596 8.81719 9.87884C9.11006 10.1717 9.11006 10.6465 8.81719 10.9393L4.62769 15.1285C4.48107 15.2747 4.28907 15.3482 4.09744 15.3482Z"
        fill="#ACB1C6"
      />
      <path
        d="M12.2556 7.19079C12.0636 7.19079 11.872 7.11766 11.7253 6.97141C11.4325 6.67854 11.4325 6.20379 11.7253 5.91054L14.0672 3.56829C14.3601 3.27541 14.8348 3.27541 15.1281 3.56829C15.421 3.86116 15.421 4.33591 15.1281 4.62916L12.7862 6.97141C12.6396 7.11729 12.4472 7.19079 12.2556 7.19079Z"
        fill="#ACB1C6"
      />
      <path
        d="M6.74906 21.7252C5.58919 21.7252 4.42931 21.2939 3.56719 20.4314C1.84219 18.7064 1.84219 15.7923 3.56719 14.0673C3.85969 13.7744 4.33519 13.7744 4.62769 14.0673C4.92056 14.3602 4.92056 14.8349 4.62769 15.1278C3.47794 16.2776 3.47794 18.2208 4.62769 19.3706C5.77744 20.5203 7.72069 20.5203 8.87044 19.3706C9.16294 19.0777 9.63844 19.0777 9.93094 19.3706C10.2238 19.6634 10.2238 20.1382 9.93094 20.4311C9.06881 21.2939 7.90894 21.7252 6.74906 21.7252Z"
        fill="#ACB1C6"
      />
      <path
        d="M19.9007 10.1517C19.7087 10.1517 19.5167 10.0785 19.3704 9.93191C19.0776 9.63904 19.0776 9.16429 19.3704 8.87141C20.5202 7.72166 20.5202 5.77841 19.3704 4.62866C18.2207 3.47891 16.2774 3.47891 15.1277 4.62866C14.8352 4.92154 14.3597 4.92154 14.0672 4.62866C13.7743 4.33579 13.7743 3.86104 14.0672 3.56816C15.7922 1.84316 18.7063 1.84316 20.4313 3.56816C22.1563 5.29316 22.1563 8.20729 20.4313 9.93229C20.2847 10.0785 20.0927 10.1517 19.9007 10.1517Z"
        fill="#ACB1C6"
      />
    </svg>
  );
}

export default WebsiteIcon