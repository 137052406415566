import React, { useEffect, useState } from "react";
import { Alert, AlertTitle, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import usePrivilegeCheck from "../../../constants/customHooks/UsePrivilegeCheck";
import DownloadExcel from "../../extraComponents/DownloadExcel";
import DownloadPdf from "../../extraComponents/DownloadPdf";
import IeltsTeacherSearchHeader from "./ieltsTeacherSearchHeader";
import IeltsTeacherFilterSearch from "./ieltsTeacherFilterSearch";
import IeltsTeacherTable from "./ieltsTeacherTable";
import { ieltsTeacherManagmentFindAction } from "../../../actions/IeltsActions/teacherManagmentAction";

function IeltsTeacherManagmentMain(props) {
  const dispatch = useDispatch();
  const [filterPassData, setFilterPassData] = useState();

  const [whichFilter, setwhichFilter] = useState();
  const [reset, setReset] = useState(false);
  const [leadData, setLeadData] = useState([]);
  const [ieltsTeacherlist, setIeltsTeacherList] = useState([]);

  const { privilege, hasPrivilege } = usePrivilegeCheck();

  // Employee column Visibility

  const [showTeacherNameColumn, setShowTeacherNameColumn] = useState(false);
  const [showMailIdColumn, setshowMailIdColumn] = useState(false);
  const [showMobileColumn, setShowMobileColumn] = useState(false);
  const [showCenterColumn, setShowCenterColumn] = useState(false);
  const [showSpecilizationColumn, setShowSpecializationColumn] = useState(
    false
  );
  const [showStatusColumn, setShowStatusColumn] = useState(false);

  const [columnName, setColumnName] = useState("");
  const [leadId, setLeadId] = useState([]);
  const [ids, setIds] = useState([]);

  // tabel columns

  const columns = [
    {
      title: "SL NO",
      field: "sl",
    },
    {
      title: "TEACHER NAME",
      field: "TEACHER NAME",
      hidden: showTeacherNameColumn,
    },
    {
      title: "MAIL ID",
      field: "MAIL ID",
      hidden: showMailIdColumn,
    },
    {
      title: "MOBILE",
      field: "MOBILE",
      hidden: showMobileColumn,
    },
    {
      title: "CENTER",
      field: "CENTER",
      hidden: showCenterColumn,
    },
    {
      title: "SPECIALIZATION",
      field: "SPECIALIZATION",
      hidden: showSpecilizationColumn,
    },
    {
      title: "STATUS",
      field: "STATUS",
      hidden: showStatusColumn,
    },
  ];

  // Excel export function
  const excelDownloadClick = () => {
    let columnName = columns.filter((value) => value.hidden == false);
    console.log(columnName, "columnNameeeeeeeeeee");
    console.log(ieltsTeacherlist, "ieltsTeacherlistieltsTeacherlist");
    const data = ieltsTeacherlist.map((value, index) => ({
      sl: columns[index].hidden ? false : index + 1,
      coloumn1: columns[index].hidden ? false : value.teacherName,
      coloumn2: columns[index].hidden ? false : value.teacherEmail,
      coloumn3: columns[index].hidden ? false : value.teacherPhone,
      coloumn4: columns[index].hidden ? false : value.center,
      coloumn5: columns[index].hidden ? false : value.teacherSpecialization,
      coloumn6: columns[index].hidden ? false : value.status,
    }));

    console.log(data, "datatatattata564s654644665676754");
    DownloadExcel(columnName, data, "EntrolledTeacherList");
  };

  // Pdf Export function

  const pdfDownloadClick = () => {
    let columnName = columns.filter((value) => value.hidden == false);
    DownloadPdf(
      columnName,
      ieltsTeacherlist,
      "Entrolled Teacher List",
      "Entrolled Teacher List"
    );
  };

  const toggleNameColumn = (status, column) => {
    if (column === "teacherName") {
      setColumnName(column);
      setShowTeacherNameColumn(status);
    }

    if (column === "mailId") {
      setColumnName(column);
      setshowMailIdColumn(status);
    }
    if (column === "mobile") {
      setColumnName(column);
      setShowMobileColumn(status);
    }
    if (column === "center") {
      setColumnName(column);
      setShowCenterColumn(status);
    }
    if (column === "specilization") {
      setColumnName(column);
      setShowSpecializationColumn(status);
    }
    if (column === "status") {
      setColumnName(column);
      setShowStatusColumn(status);
    }
  };

  useEffect(() => {
    // Retrieve column visibility state from localStorage
    const storedteacherName = localStorage.getItem("teacherName");
    const storedMail = localStorage.getItem("mailId");
    const storedMobile = localStorage.getItem("mobile");
    const storedCenter = localStorage.getItem("center");
    const storedSpecilization = localStorage.getItem("specilization");
    const storedStatus = localStorage.getItem("status");

    if (storedteacherName !== null) {
      setColumnName("teacherName");
      setShowTeacherNameColumn(JSON.parse(storedteacherName));
    }

    if (storedMail !== null) {
      setColumnName("mailId");
      setshowMailIdColumn(JSON.parse(storedMail));
    }
    if (storedMobile !== null) {
      setColumnName("mobile");
      setShowMobileColumn(JSON.parse(storedMobile));
    }
    if (storedCenter !== null) {
      setColumnName("center");
      setShowCenterColumn(JSON.parse(storedCenter));
    }
    if (storedSpecilization !== null) {
      setColumnName("specilization");
      setShowSpecializationColumn(JSON.parse(storedSpecilization));
    }
    if (storedStatus !== null) {
      setColumnName("status");
      setShowStatusColumn(JSON.parse(storedStatus));
    }
  }, []);

  useEffect(() => {
    // Save column visibility state to localStorage
    localStorage.setItem("teacherName", JSON.stringify(showTeacherNameColumn));
    localStorage.setItem("mailId", JSON.stringify(showMailIdColumn));
    localStorage.setItem("mobile", JSON.stringify(showMobileColumn));
    localStorage.setItem("center", JSON.stringify(showCenterColumn));
    localStorage.setItem(
      "specilization",
      JSON.stringify(showSpecilizationColumn)
    );
    localStorage.setItem("status", JSON.stringify(showStatusColumn));
  }, [
    showTeacherNameColumn,
    showMailIdColumn,
    showMobileColumn,
    showCenterColumn,
    showSpecilizationColumn,
    showStatusColumn,
    columnName,
  ]);

  // Find entrolled students

  let {
    ieltsTeacherManagmentTeacherFindSuccess,
    ieltsTeacherManagmentTeacherFinderror,
  } = useSelector((state) => {
    return state.ieltsTeacherManagmentTeacherFind;
  });

  let {
    ieltsTeacherManagmentTeacherStatusChangeSuccess,
    ieltsTeacherManagmentTeacherStatusChangeerror,
  } = useSelector((state) => {
    return state.ieltsTeacherManagmentTeacherStatusChange;
  });

  useEffect(() => {
    dispatch(ieltsTeacherManagmentFindAction("teacherTable"));
  }, [dispatch, ieltsTeacherManagmentTeacherStatusChangeSuccess]);

  useEffect(() => {
    if (ieltsTeacherManagmentTeacherFindSuccess) {
      setIeltsTeacherList(ieltsTeacherManagmentTeacherFindSuccess.data);
    }
  }, [ieltsTeacherManagmentTeacherFindSuccess, reset]);

  // Handle SearchFunction

  const searchHandleChange = (value) => {
    if (value) {
      setIeltsTeacherList(
        ieltsTeacherlist?.filter((item) => {
          return item.teacherName
            ? item.teacherName.toLowerCase().includes(value.toLowerCase())
            : "" || item.center.toLowerCase().includes(value.toLowerCase());
        })
      );
    } else {
      setIeltsTeacherList(ieltsTeacherManagmentTeacherFindSuccess.data);
    }
  };

  const setIdsFunction = (leadsId) => {
    setLeadId(leadsId);
  };

  const selectBoxIdPassHandler = (ids) => {
    setIds(ids);
  };

  if (!hasPrivilege("teacher managment") && privilege !== null) {
    return (
      <Alert severity="error">
        <AlertTitle>warning</AlertTitle>
        You don't have permission to access — <strong>this page.!</strong>
      </Alert>
    );
  }

  // // handleFilter Data

  const LeadFilterFunction = (data, filterOption, checkedStatus) => {
    console.log(data, filterOption, checkedStatus, "hhhhhhhhhhhhhhhhhhhhi");
    if (filterOption === "Sepcilization") {
      if (checkedStatus === true) {
        const filterData = ieltsTeacherManagmentTeacherFindSuccess.data?.filter(
          (teacher) => {
            const stateValues = teacher.teacherSpecialization
              .split(",")
              .map((s) => s.trim());
            return stateValues.some((value) => data === value);
          }
        );
        setIeltsTeacherList(filterData);
        setwhichFilter(filterOption);
        setFilterPassData(data);
      } else {
        setwhichFilter(null);
        setFilterPassData(null);
        setIeltsTeacherList(ieltsTeacherManagmentTeacherFindSuccess.data);
      }
    }
    if (filterOption === "status") {
      if (checkedStatus === true) {
        setIeltsTeacherList(
          ieltsTeacherManagmentTeacherFindSuccess.data?.filter(
            (status, index) => {
              return data == status.status;
            }
          )
        );
        setwhichFilter(filterOption);
        setFilterPassData(data);
      } else {
        setwhichFilter(null);
        setFilterPassData(null);
        setIeltsTeacherList(ieltsTeacherManagmentTeacherFindSuccess.data);
      }
    }
  };

  // handleResetData

  const handleResetData = (status) => {
    setwhichFilter(null);
    setFilterPassData(null);
    setReset(!reset);
    setwhichFilter(null);
    setFilterPassData(null);
  };

  return (
    <>
      <IeltsTeacherSearchHeader
        columnVisibleHandler={toggleNameColumn}
        excelDownloadClick={excelDownloadClick}
        pdfDownloadClick={pdfDownloadClick}
        showTeacherNameColumnColumn={showTeacherNameColumn}
        showMailIdColumn={showMailIdColumn}
        showCenterColumn={showCenterColumn}
        showMobileColumn={showMobileColumn}
        showSpecilizationColumn={showSpecilizationColumn}
        showStatusColumn={showStatusColumn}
        handleResetData={handleResetData}
        searchHandleChange={searchHandleChange}
        ids={ids}
        leadId={leadId}
        subMenu="teacher managment"
      />
      {/* <div style={{display:"flex",justifyContent:"space-evenly", width:"auto"}}> */}
      <Grid container spacing={-3} sx={{ mt: "-31px" }}>
        <Grid item xs={12} md={2} lg={2.2}>
          <IeltsTeacherFilterSearch
            LeadFilterFunction={LeadFilterFunction}
            handleResetData={handleResetData}
            statusComponent="EntrolledTeachers"
          />
        </Grid>
        <Grid item xs={12} md={10} lg={9.8}>
          <IeltsTeacherTable
            toggle={props.toggle}
            showTeacherNameColumnColumn={showTeacherNameColumn}
            showMailIdColumn={showMailIdColumn}
            showCenterColumn={showCenterColumn}
            showMobileColumn={showMobileColumn}
            showSpecilizationColumn={showSpecilizationColumn}
            showStatusColumn={showStatusColumn}
            reset={reset}
            setIdsFunction={setIdsFunction}
            data={ieltsTeacherlist}
            // leadFindLoading={leadFindLoading}
            selectBoxIdPassHandler={selectBoxIdPassHandler}
            // isExistCounsilor={loginFindSuccess}
            component="EmployeeJob"
            columns={columns}
            subMenu="teacher managment"
          />
        </Grid>
      </Grid>
    </>
  );
}

export default IeltsTeacherManagmentMain;
