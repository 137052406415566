import { Box } from "@mui/material";
import React, { useState } from "react";
import InputField from "../../customComponent/InputField";
import { SubmitButton } from "../../customComponent/Buttons";
import ChoiceVerification from "../ChoiceVerification";

function FinalChoiceVerificationTabs() {
  const TableData = [
    {
      id: 2654566,
      name: "Shabeer Muhammed",
      university: "Cranfield University",
      course: "Global Public Health",
      startDate: "15 / 02 / 2023",
      tutionFee: "25250",
      paymentDate: "15 / 02 / 2023",
      paidTogsl: false,
      paidToPartner: true,
      invoice: false,
    },

    {
      id: 26546566,
      name: "Shabeer Muhammed",
      university: "Cranfield University",
      course: "Global Public Health",
      startDate: "15 / 02 / 2023",
      tutionFee: "25250",
      paymentDate: "15 / 02 / 2023",
      paidTogsl: false,
      paidToPartner: true,
      invoice: true,
    },
    {
      id: 2654866,
      name: "Shabeer Muhammed",
      university: "Cranfield University",
      course: "Global Public Health",
      startDate: "15 / 02 / 2023",
      tutionFee: "25250",
      paymentDate: "15 / 02 / 2023",
      paidTogsl: true,
      paidToPartner: true,
      invoice: true,
    },
    {
      id: 2654366,
      name: "Shabeer Muhammed",
      university: "Cranfield University",
      course: "Global Public Health",
      startDate: "15 / 02 / 2023",
      tutionFee: "25250",
      paymentDate: "15 / 02 / 2023",
      paidTogsl: false,
      paidToPartner: false,
      invoice: false,
    },
  ];

  const columns = [
    {
      title: "Student ID",
      field: "id",
    },
    {
      title: "Name",
      field: "name",
    },
    {
      title: "University     ",
      field: "university",
    },
    {
      title: "Course",
      field: "course",
    },
    {
      title: "Start Date",
      field: "startDate",
    },
    {
      title: "Tuition fee",
      field: "tutionFee",
    },
    {
      title: "Invoice",
      field: "invoice",
    },
    {
      title: "Paid to Gsl",
      field: "paidTogsl",
    },
    {
      title: "Paid to partner",
      field: "paidToPartner",
    },
    {
      title: "payment date",
      field: "paymentDate",
    },
  ];

  const [data, setData] = useState(TableData);

  return (
    <Box>
      <Box
        sx={{
          background: "#F4F4F4",
          padding: "12px",
        }}
      >
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: {
              sm: "repeat(2,1fr)",
            },
            gap: "20px",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <InputField
            label={"Country"}
            value={"2023"}
            color={"#05050F"}
            labelcolor={"#ACB1C6"}
            backgroundColor="#fff"
            required
          />
          <InputField
            label={"Location"}
            value={"All Months"}
            color={"#05050F"}
            labelcolor={"#ACB1C6"}
            backgroundColor="#fff"
            required
          />
        </Box>
        <Box sx={{ mt: "40px" }}>
          <SubmitButton variant={"filled"} label="Show" fontWeight={"700"} />
        </Box>
      </Box>
      <Box>
        <Box sx={{ mb: "40px" }}></Box>
        <Box>
          <ChoiceVerification />
        </Box>
      </Box>
    </Box>
  );
}

export default FinalChoiceVerificationTabs;
