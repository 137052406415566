import { Button, Typography } from "@mui/material";
import React from "react";
import "./PartnerDetailsHead.css";

function PartnerDetailHeadTab({ title, handleClick }) {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        backgroundColor: "#F6F6F6",
      }}
    >
      <Typography
        variant="h5"
        sx={{
          m: "18px",
          fontWeight: 700,
          fontSize: "19px",
          fontFamily: "Montserrat', sans-serif",
        }}
      >
        {title}
      </Typography>
      {/* <TypographyText title=" Basic Information" variant="h4" style={{margin:"9px"}} /> */}
      <Button
        style={{
          display: "flex",
          justifyContent: "flex-end",
          margin: "10px",
          borderColor: "#141E3C",
          color: "black",
          fontSize: "15px",
          height: "35px",
          width: "70px",
          fontWeight: 600,
          borderRadius: "8px",
        }}
        variant="outlined"
        onClick={() => handleClick()}
      >
        +
      </Button>
    </div>
  );
}

export default PartnerDetailHeadTab;
