import React, { useEffect, useState } from "react";
import { Box, Grid } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import {
  leadPersonalDetailsAddAction,
  leadPersonalDetailsShowAction
} from "../../../actions/leadActions/leadActions.js";
import { TypographyText1 } from "../../customComponent/Typography.js";
import CustomCheckbox from "../../customComponent/CustomCheckbox.js";
import { SubmitButton } from "../../customComponent/Buttons.js";
import {
  SelectInputField,
  TopInputFieldBorderLabel
} from "../../customComponent/InputField.js";
import { countries } from "countries-list";
import { Country, State } from "country-state-city";
import { City } from "country-state-city";
import usePrivilegeCheck from "../../../constants/customHooks/UsePrivilegeCheck.js";
import Swal from "sweetalert2";
import { successTrueMsgAction } from "../../../actions/globalAction.js";
import {
  LEAD_PERSONAL_DETAILS_ADD_ERR_MESSAGE,
  LEAD_PERSONAL_DETAILS_ADD_SUCCESS_MESSAGE
} from "../../../constants/leadConstant.js";
import { FIND_ALL_ASIGNED_STAFF_ERR } from "../../../constants/applicationConstant.js";

const PersonalDetails = ({ handleNext, handleCompleteTick }) => {
  const {
    privilege,
    hasPrivilege,
    isApprovePrevilage,
    isCreatePrevilage,
    isDeletePrevilage,
    isUpdatePrevilage,
    isVerifyPrevilage,
    isChangeOwnerPrevilage,
    isChangePriorityPrevilage,
    isChangeStatusPrevilage,
    isAssignCounsilorPrevilage
  } = usePrivilegeCheck();

  const dispatch = useDispatch();
  const { id } = useParams();
  const [countryCode, setCountryCode] = useState("");
  const [stateCode, setStateCode] = useState([]);
  const [allState, setAllStates] = useState([]);
  const [allCities, setAllCity] = useState([]);
  const [nationality, setNationality] = useState();
  const [formValues, setFormValues] = useState({
    passportName: "",
    passportIssueLocation: "",
    passportNumber: "",
    country: "",
    state: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    cityCode: "",
    currentCountry: "",
    crrentState: "",
    currentAddressLine1: "",
    currentAddressLine2: "",
    currentCity: "",
    currentCityCode: "",
    sameAsPermanentAddress: false,
    emergencyName: "",
    emergencyMobile: "",
    emergencyEmail: "",
    emergencyRelationship: ""
  });

  const [formErrors, setFormErrors] = useState({});

  // AllCountry

  let allCountries = Object.entries(countries).map(([code, country]) => ({
    value: code,
    label: `${country.name}`,
    phone: `+${country.phone}`
  }));

  // console.log(allCountries, "allCountriesallCountries");

  //  Find state if Country is Available

  useEffect(() => {
    if (
      formValues.passportIssueLocation ||
      formValues.country ||
      formValues.currentCountry
    ) {
      const allStates = allCountries.filter(
        (value) =>
          value.label == formValues.passportIssueLocation ||
          value.label == formValues.country ||
          value.label == formValues.currentCountry
      );
      const countryCode = allStates[0]?.value;
      const states = State.getStatesOfCountry(countryCode);
      const stateLabels = states.map((state) => ({
        label: state.name
      }));
      const stateCode = states.map((state) => ({
        statecode: `${state.name}#${state.isoCode}`
      }));
      setCountryCode(countryCode);
      setStateCode(stateCode);
      setAllStates(stateLabels);
    }
  }, [formValues.currentCountry, formValues.country]);

  // console.log(formValues.country, "formValues.country");
  // console.log(formValues.state, "formValues.state");
  // console.log(
  //   formValues.passportIssueLocation,
  //   "formValues.passportIssueLocation"
  // );

  // Find City if State is available

  useEffect(() => {
    if (formValues.crrentState || formValues.state) {
      const city = stateCode?.filter(
        (city) =>
          formValues.crrentState == city.statecode.split("#")[0] ||
          formValues.state == city.statecode.split("#")[0]
      );
      const Statecode = city[0]?.statecode.split("#")[1];
      // console.log(Statecode, "stateCode");
      const stateCities = City.getCitiesOfState(countryCode, Statecode);
      setAllCity(stateCities);
      // console.log(stateCities, "stateCities");
    }
  }, [formValues.crrentState, formValues.state, stateCode]);

  // selectors
  let {
    leadPersonalDetailAddSuccess,
    leadPersonalDetailAddSuccessMsgSuccess,
    leadPersonalDetailAdderror,
    leadPersonalDetailAddErrorMsgerror
  } = useSelector((state) => {
    return state.leadPersonalDetailsAddReducer;
  });

  let { leadPersonalDetailFindSuccess } = useSelector((state) => {
    return state.leadPersonalDetailsShow;
  });

  let { successAlertMsgSuccess, successAlertMsgErr } = useSelector((state) => {
    return state.successTrueMsg;
  });

  useEffect(() => {
    if (leadPersonalDetailAddSuccessMsgSuccess) {
      Swal.fire(
        "Personal Details Added!",
        "Personal details added successfully.",
        "success"
      );
      handleNext();
      dispatch({
        type: LEAD_PERSONAL_DETAILS_ADD_SUCCESS_MESSAGE,
        payload: false
      });
      dispatch(successTrueMsgAction(false));
    }
    // if (leadPersonalDetailAddErrorMsgerror) {
    //   Swal.fire(
    //     "Error!",
    //     `${leadPersonalDetailAddErrorMsgerror.message}`,
    //     "success"
    //   );
    //   dispatch({
    //     type: LEAD_PERSONAL_DETAILS_ADD_ERR_MESSAGE,
    //     payload: false
    //   });
    //   dispatch(successTrueMsgAction(false));
    // }
   
  }, [
    leadPersonalDetailAddSuccessMsgSuccess,
    leadPersonalDetailAddErrorMsgerror,
  ]);

  const handleChange = (event) => {
    const { name, value, checked, type } = event.target;
    const newValue = type === "checkbox" ? checked : name === "passportNumber" ? value.toUpperCase().trim():value;
    setFormValues((prevValues) => ({ ...prevValues, [name]: newValue }));
    setFormErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  useEffect(() => {
    dispatch(leadPersonalDetailsShowAction(id));
  }, [dispatch, leadPersonalDetailAddSuccess, id]);

  useEffect(() => {
    if (leadPersonalDetailFindSuccess) {
      setFormValues((prevFormValues) => ({
        ...prevFormValues,
        passportName: leadPersonalDetailFindSuccess.passportName
      }));

      setFormValues((prevFormValues) => ({
        ...prevFormValues,
        passportExpire: leadPersonalDetailFindSuccess?.passportExpire
      }));

      setFormValues((prevFormValues) => ({
        ...prevFormValues,
        passportIssueLocation: leadPersonalDetailFindSuccess.passportLocation
      }));

      setFormValues((prevFormValues) => ({
        ...prevFormValues,
        passportNumber: leadPersonalDetailFindSuccess.passportNumber
      }));

      setFormValues((prevFormValues) => ({
        ...prevFormValues,
        country: leadPersonalDetailFindSuccess.country
      }));

      setFormValues((prevFormValues) => ({
        ...prevFormValues,
        state: leadPersonalDetailFindSuccess.state
      }));

      setFormValues((prevFormValues) => ({
        ...prevFormValues,
        addressLine1: leadPersonalDetailFindSuccess.addressLine1
      }));

      setFormValues((prevFormValues) => ({
        ...prevFormValues,
        addressLine2: leadPersonalDetailFindSuccess.addressLine2
      }));

      setFormValues((prevFormValues) => ({
        ...prevFormValues,
        city: leadPersonalDetailFindSuccess.city
      }));

      setFormValues((prevFormValues) => ({
        ...prevFormValues,
        cityCode: leadPersonalDetailFindSuccess.cityCode
      }));

      setFormValues((prevFormValues) => ({
        ...prevFormValues,
        sameAsPermanentAddress:
          leadPersonalDetailFindSuccess.sameAsPermanentAddress
      }));

      setFormValues((prevFormValues) => ({
        ...prevFormValues,
        emergencyName: leadPersonalDetailFindSuccess.emergencyName
      }));

      setFormValues((prevFormValues) => ({
        ...prevFormValues,
        emergencyMobile: leadPersonalDetailFindSuccess.emergencyMobile
      }));

      setFormValues((prevFormValues) => ({
        ...prevFormValues,
        emergencyEmail: leadPersonalDetailFindSuccess.emergencyEmail
      }));

      setFormValues((prevFormValues) => ({
        ...prevFormValues,
        emergencyRelationship:
          leadPersonalDetailFindSuccess.emergencyRelationship
      }));
    }
  }, [leadPersonalDetailFindSuccess]);

  useEffect(() => {
    if (leadPersonalDetailFindSuccess) {
      handleCompleteTick();
    }
  }, [leadPersonalDetailFindSuccess, handleCompleteTick]);

  const handleSubmit = (event) => {
    event.preventDefault();
    const errors = {};
    // Perform validation
    if (formValues.passportName.trim() === "") {
      errors.passportName = "Passport name is required";
    }

    if (formValues.passportIssueLocation.trim() === "") {
      errors.passportIssueLocation = "Passport issue location is required";
    }
    // if (formValues.passportNumber.trim() === "") {
    //   errors.passportNumber = "Passport issue number is required";
    // }

    if (formValues.passportExpire.trim() === "") {
      errors.passportExpire = "Passport expire date is required";
    }

    if (formValues.country.trim() === "") {
      errors.country = "country is required";
    }
    if (formValues.state.trim() === "") {
      errors.state = "state is required";
    }

    if (formValues.addressLine1.trim() === "") {
      errors.addressLine1 = "address is required";
    }

    if (formValues.addressLine2.trim() === "") {
      errors.addressLine2 = "address is required";
    }

    if (formValues.city.trim() === "") {
      errors.city = "city is required";
    }
    if (formValues.cityCode.trim() === "") {
      errors.cityCode = "cityCode is required";
    }

    if (formValues.emergencyName.trim() === "") {
      errors.emergencyName = "name is required";
    }
    if (formValues.emergencyMobile.trim() === "") {
      errors.emergencyMobile = "mobile is required";
    }
    if (formValues.emergencyEmail.trim() === "") {
      errors.emergencyEmail = "email is required";
    }
    if (formValues.emergencyRelationship.trim() === "") {
      errors.emergencyRelationship = "relation is required";
    }

    if (Object.keys(errors).length === 0) {
      // handleNext();
      dispatch(leadPersonalDetailsAddAction(formValues, id));
      dispatch(successTrueMsgAction(true));
    } else {
      // Form is invalid, update the error state
      console.log(errors, "error");
      setFormErrors(errors);
    }
  };

  console.log(
    formValues,
    "formValuesformValuesformValuesformValues"
  );

  return (
    <>
      <form onSubmit={handleSubmit}>
        <TypographyText1 title="Passport Information" variant="h6" />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            backgroundColor: "#efedf0",
            p: 3
          }}
        >
          <Grid container spacing={2}>
            {/* Passport Information */}

            <Grid item xs={12} sm={3}>
              <TopInputFieldBorderLabel
                label="Name as appeared in Passport"
                name="passportName"
                handleChange={handleChange}
                widthSize="fullWidth"
                heightSize="42px"
                value={formValues.passportName}
                required
                paramses
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              {/* <TopInputFieldBorderLabel
                label="Passport Issue Location"
                name="passportIssueLocation"
                handleChange={handleChange}
                widthSize="fullWidth"
                heightSize="42px"
                value={formValues.passportIssueLocation}
                required
                paramses
              /> */}

              <SelectInputField
                label="Passport Issue Location"
                handleSelectChange={handleChange}
                widthSize="32px"
                value={formValues.passportIssueLocation}
                mapValue={allCountries}
                mapMethod="nationality"
                name="passportIssueLocation"
                height="50px"
                required
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TopInputFieldBorderLabel
                label="Passport Number"
                name="passportNumber"
                handleChange={handleChange}
                widthSize="fullWidth"
                heightSize="45px"
                value={formValues.passportNumber}
                required
                paramses
                disabled={
                  leadPersonalDetailFindSuccess?.passportNumber ? true : false
                }
              />
            </Grid>

            <Grid item xs={12} sm={3}>
              <TopInputFieldBorderLabel
                label="Passport Expiry Date"
                name="passportExpire"
                handleChange={handleChange}
                widthSize="fullWidth"
                heightSize="45px"
                value={formValues.passportExpire}
                required
                paramses
                type="date"
              />
            </Grid>
          </Grid>
        </Box>
        <Box mt={3}>
          <TypographyText1 title="Permanent Address" variant="h6" />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            backgroundColor: "#efedf0",
            p: 2
          }}
        >
          <Grid container spacing={2}>
            {/* Permanent Address */}

            <Grid item xs={12} sm={3}>
              {/* <TopInputFieldBorderLabel
                label="Country"
                name="country"
                handleChange={handleChange}
                widthSize="fullWidth"
                heightSize="45px"
                value={formValues.country}
                required
                paramses
              /> */}
              <SelectInputField
                label="Country"
                handleSelectChange={handleChange}
                widthSize="32px"
                value={formValues.country}
                mapValue={allCountries}
                mapMethod="nationality"
                name="country"
                height="50px"
                required
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              {/* <TopInputFieldBorderLabel
                label="State"
                name="state"
                handleChange={handleChange}
                widthSize="fullWidth"
                heightSize="50px"
                value={formValues.state}
                required
                paramses
              /> */}

              <SelectInputField
                label="State"
                handleSelectChange={handleChange}
                widthSize="32px"
                value={formValues.state}
                mapValue={allState}
                mapMethod="State"
                name="state"
                height="50px"
                required
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TopInputFieldBorderLabel
                label="Address Line 1"
                name="addressLine1"
                handleChange={handleChange}
                widthSize="fullWidth"
                heightSize="45px"
                value={formValues.addressLine1}
                required
                paramses
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TopInputFieldBorderLabel
                label="Address Line 2"
                name="addressLine2"
                handleChange={handleChange}
                widthSize="fullWidth"
                heightSize="45px"
                value={formValues.addressLine2}
                required
                paramses
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              {/* <TopInputFieldBorderLabel
                label="City"
                name="city"
                handleChange={handleChange}
                widthSize="fullWidth"
                heightSize="45px"
                value={formValues.city}
                required
                paramses
              /> */}

              <SelectInputField
                label="City"
                handleSelectChange={handleChange}
                widthSize="32px"
                value={formValues.city}
                mapValue={allCities}
                mapMethod="permanantCity"
                name="city"
                height="50px"
                required
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TopInputFieldBorderLabel
                label="Pin Code"
                name="cityCode"
                handleChange={handleChange}
                widthSize="fullWidth"
                heightSize="45px"
                value={formValues.cityCode}
                required
                paramses
              />
            </Grid>
          </Grid>
        </Box>

        <Box mt={2}>
          <TypographyText1 title="Current Address" variant="h6" />
          <CustomCheckbox
            name="sameAsPermanentAddress"
            checked={formValues.sameAsPermanentAddress}
            onChange={handleChange}
            helpertext={formErrors.sameAsPermanentAddress}
            error={!!formErrors.sameAsPermanentAddress}
            label="Same as Permanent Address"
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            backgroundColor: "#efedf0",
            p: 2
          }}
        >
          <Grid container spacing={2}>
            {/* Current Address */}

            <Grid item xs={12} sm={4} md={3}>
              {/* <TopInputFieldBorderLabel
                label="Country"
                name="country"
                handleChange={handleChange}
                widthSize="fullWidth"
                heightSize="45px"
                value={
                  formValues.sameAsPermanentAddress
                    ? formValues.country
                    : formValues.currentCountry
                }
                required
                paramses
              /> */}

              <SelectInputField
                label="Country"
                handleSelectChange={handleChange}
                widthSize="32px"
                value={
                  formValues.sameAsPermanentAddress
                    ? formValues.country
                    : formValues.currentCountry
                }
                mapValue={allCountries}
                mapMethod="nationality"
                name="country"
                height="50px"
                required
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              {/* <TopInputFieldBorderLabel
                label="State"
                name="state"
                handleChange={handleChange}
                widthSize="fullWidth"
                heightSize="45px"
                value={
                  formValues.sameAsPermanentAddress
                    ? formValues.state
                    : formValues.crrentState
                }
                required
                paramses
              /> */}

              <SelectInputField
                label="State"
                handleSelectChange={handleChange}
                widthSize="32px"
                value={
                  formValues.sameAsPermanentAddress
                    ? formValues.state
                    : formValues.crrentState
                }
                mapValue={allState}
                mapMethod="State"
                name="state"
                height="50px"
                required
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <TopInputFieldBorderLabel
                label="Address Line 1"
                name="addressLine1"
                handleChange={handleChange}
                widthSize="fullWidth"
                heightSize="45px"
                value={
                  formValues.sameAsPermanentAddress
                    ? formValues.addressLine1
                    : formValues.currentAddressLine1
                }
                required
                paramses
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <TopInputFieldBorderLabel
                label="Address Line 2"
                name="addressLine2"
                handleChange={handleChange}
                widthSize="fullWidth"
                heightSize="45px"
                value={
                  formValues.sameAsPermanentAddress
                    ? formValues.addressLine2
                    : formValues.currentAddressLine2
                }
                required
                paramses
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              {/* <TopInputFieldBorderLabel
                label="City"
                name="city"
                handleChange={handleChange}
                widthSize="fullWidth"
                heightSize="45px"
                value={
                  formValues.sameAsPermanentAddress
                    ? formValues.city
                    : formValues.currentCity
                }
                required
                paramses
              /> */}

              <SelectInputField
                label="City"
                handleSelectChange={handleChange}
                widthSize="32px"
                value={
                  formValues.sameAsPermanentAddress
                    ? formValues.city
                    : formValues.currentCity
                }
                mapValue={allCities}
                mapMethod="permanantCity"
                name="city"
                height="50px"
                required
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <TopInputFieldBorderLabel
                label="Pin Code"
                handleChange={handleChange}
                name="cityCode"
                widthSize="fullWidth"
                heightSize="45px"
                value={
                  formValues.sameAsPermanentAddress
                    ? formValues.cityCode
                    : formValues.currentCityCode
                }
                required
                paramses
              />
            </Grid>
          </Grid>
        </Box>

        <Box mt={2}>
          <TypographyText1 title="Emergency Contact" variant="h6" />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            backgroundColor: "#efedf0",
            p: 2
          }}
        >
          <Grid container spacing={2}>
            {/* Emergency Contact */}

            <Grid item xs={12} sm={4} md={3}>
              <TopInputFieldBorderLabel
                label="Name"
                handleChange={handleChange}
                widthSize="fullWidth"
                name="emergencyName"
                heightSize="45px"
                value={formValues.emergencyName}
                required
                paramses
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <TopInputFieldBorderLabel
                label="Mobile"
                handleChange={handleChange}
                widthSize="fullWidth"
                heightSize="45px"
                name="emergencyMobile"
                value={formValues.emergencyMobile}
                required
                paramses
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <TopInputFieldBorderLabel
                label="Email"
                name="emergencyEmail"
                handleChange={handleChange}
                widthSize="fullWidth"
                heightSize="45px"
                value={formValues.emergencyEmail}
                required
                paramses
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <TopInputFieldBorderLabel
                label="Relationship"
                handleChange={handleChange}
                widthSize="fullWidth"
                heightSize="45px"
                name="emergencyRelationship"
                value={formValues.emergencyRelationship}
                required
                paramses
              />
            </Grid>
          </Grid>
        </Box>
        {/* Submit Button */}
        {isUpdatePrevilage("All Leads") ? (
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              justifyContent: "flex-end",
              margin: "25px"
            }}
          >
            <SubmitButton
              title="Continue to next section"
              submit="submit"
              widthSize="250px"
              heightSize="30px"
              type="submit"
              handleSubmit=""
            />
          </Grid>
        ) : (
          ""
        )}
      </form>
    </>
  );
};
export default PersonalDetails;
