import React, { useEffect, useRef, useState } from "react";
import { Box, Divider, Grid, Typography } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";

import InputField, { SelectInputField } from "../../customComponent/InputField";
import { MainContainer } from "../../content/content.element";
import { InfoHeader } from "../../customComponent/InfoHeader";
import { SubmitButton } from "../../customComponent/Buttons";
import {
  blogCreateAction,
  testimonialAction,
} from "../../../actions/LandingPageAction";
import { findUniversityAction } from "../../../actions/universityActions/universityManagmentAction";

function CreateTestimonials(props) {
  const dispatch = useDispatch();

  const [DestinationimageValue, setDestinationimageValue] = useState("");
  const [content, setContent] = useState("");
  const [name, setName] = useState("");
  const [universities, setUniversities] = useState();
  const [universityValue, setUniversityValue] = useState("");
  const [tags, setTags] = useState("");
  const [blogSlug, setBlogSlug] = useState("");
  const [seoKeyword, setSeoKeyword] = useState("");
  const [newsImage, setNewsImage] = useState();
  const [error, setError] = useState({});
  const newsimage = useRef();

  let { universityFindSuccess } = useSelector((state) => {
    return state.findUniversity;
  });

  useEffect(() => {
    dispatch(findUniversityAction());
  }, [dispatch]);

  useEffect(() => {
    if (universityFindSuccess) {
      setUniversities(
        universityFindSuccess.map((country, index) => ({
          universityname: country.universityName,
          id: country.id,
        }))
      );
    }
  }, [universityFindSuccess]);

  const handleDestinationImage = (event) => {
    const file = event.target.files[0];
    setDestinationimageValue(file.name);
    const reader = new FileReader();

    // Read the image file as a data URL
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      const UploadDestinationimage = reader.result;
      setNewsImage(UploadDestinationimage);
    };
  };

  // HandleCreate

  const validate = () => {
    const errorList = {};
    if (!newsImage) {
      errorList.newsImageError = "Please choose a news image";
    }
    if (!universityValue) {
      errorList.universityValue = "Please choose a university";
    }
    if (!name) {
      errorList.name = "Please enter a name";
    }
    if (!content) {
      errorList.content = "Please enter some content";
    }

    setError(errorList);
    console.log(errorList, "errorList");
    return Object.keys(errorList).length === 0;
  };

  const HandleCreate = () => {
    if (validate()) {
      dispatch(testimonialAction(newsImage, name, universityValue, content));
    }
    // setNewsImage("");
    // setDestinationimageValue("");
    // setName("");
    // setContent("");
    // setTags("");
    // setBlogSlug("");
    // setSeoKeyword("");
  };

  return (
    <MainContainer active={props.toggle}>
      <div
        style={{
          display: "flex",
          justifyContent: "start",
          backgroundColor: "white",
        }}
      >
        <InfoHeader headTitle="Create Testimonial" url={"/landingPage"} />
      </div>
      <Divider />

      <Grid container sx={{ mt: 3 }}>
        <Grid item xs={12} md={6} lg={4} style={{ position: "relative" }}>
          <Box>
            <Grid>
              <InputField
                label="Picture"
                widthSize="92%"
                value={DestinationimageValue}
                required
                paramses
              ></InputField>
            </Grid>
            <Grid style={{ position: "absolute", top: 5, right: 30 }}>
              <button
                onClick={() => {
                  newsimage.current.click();
                  setError((prev) => ({
                    ...prev,
                    ["newsImageError"]: "",
                  }));
                }}
                style={{
                  width: "100px",
                  height: "40px",
                  fontSize: "13px",
                  fontFamily: "monospace",
                  border: "none",
                }}
              >
                Browse
              </button>
              <input
                ref={newsimage}
                type="file"
                accept="image/*"
                onChange={(event) => handleDestinationImage(event)}
                style={{ display: "none" }}
              ></input>
            </Grid>
            {Boolean(error?.newsImageError) && (
              <Typography sx={{ color: "red", fontSize: "12px", mt: "-15px" }}>
                {error?.newsImageError}
              </Typography>
            )}
          </Box>
        </Grid>

        <Grid item xs={12} md={6} lg={4} style={{ position: "relative" }}>
          <InputField
            label="Name"
            widthSize="92%"
            value={name}
            handleChange={(e) => {
              setName(e.target.value);
              setError((prev) => ({
                ...prev,
                ["name"]: "",
              }));
            }}
            required
            paramses
            error={Boolean(error.name)}
            helperText={error.name}
          ></InputField>
        </Grid>

        <Grid item xs={12} md={6} lg={4} style={{ position: "relative" }}>
          {/* <div style={{ marginTop: "20px" }}> */}
          <SelectInputField
            label="Select Universities"
            docName
            handleSelectChange={(event) => {
              const { value } = event.target;
              // console.log(value);
              // console.log(universities);
              setUniversityValue(value);
              setError((prev) => ({
                ...prev,
                ["universityValue"]: "",
              }));
            }}
            widthSize="auto"
            value={universityValue}
            mapValue={universities}
            mapMethod="alluniversities"
          />
          {Boolean(error?.universityValue) && (
            <Typography sx={{ color: "red", fontSize: "12px" }}>
              {error?.universityValue}
            </Typography>
          )}
          {/* </div> */}
        </Grid>

        <Grid item xs={12} lg={12} sx={{ mt: 3 }}>
          <InputField
            label="Content"
            handleChange={(e) => {
              setContent(e.target.value);
              setError((prev) => ({
                ...prev,
                ["content"]: "",
              }));
            }}
            widthSize="99.2%"
            value={content}
            InputType="discription"
            // dispcriptionHeight=""
            required
            paramses
            error={Boolean(error.content)}
            helperText={error.content}
          />
        </Grid>
      </Grid>
      {/* Submit Button */}
      <Grid
        item
        xs={4}
        lg={12}
        style={{
          display: "flex",
          justifyContent: "flex-start",
          marginTop: "10px",
        }}
      >
        <SubmitButton
          title="Save"
          submit=""
          widthSize="100px"
          heightSize="38px"
          type="click"
          handleSubmit={HandleCreate}
        />
      </Grid>
    </MainContainer>
  );
}

export default CreateTestimonials;
