//   TEACHER CREATE REDUCER

import {
  IELTS_TEACHERMANAGMENT_ASSIGNED_STUDENTS_ERR,
  IELTS_TEACHERMANAGMENT_ASSIGNED_STUDENTS_REQUEST,
  IELTS_TEACHERMANAGMENT_ASSIGNED_STUDENTS_SUCCESS,
  IELTS_TEACHERMANAGMENT_TEACHER_CREATE_ERR,
  IELTS_TEACHERMANAGMENT_TEACHER_CREATE_REQUEST,
  IELTS_TEACHERMANAGMENT_TEACHER_CREATE_SUCCESS,
  IELTS_TEACHERMANAGMENT_TEACHER_FINDBYCENTERID_ERR,
  IELTS_TEACHERMANAGMENT_TEACHER_FINDBYCENTERID_REQUEST,
  IELTS_TEACHERMANAGMENT_TEACHER_FINDBYCENTERID_SUCCESS,
  IELTS_TEACHERMANAGMENT_TEACHER_FINDBYID_ERR,
  IELTS_TEACHERMANAGMENT_TEACHER_FINDBYID_REQUEST,
  IELTS_TEACHERMANAGMENT_TEACHER_FINDBYID_SUCCESS,
  IELTS_TEACHERMANAGMENT_TEACHER_FIND_ERR,
  IELTS_TEACHERMANAGMENT_TEACHER_FIND_REQUEST,
  IELTS_TEACHERMANAGMENT_TEACHER_FIND_SUCCESS,
  IELTS_TEACHERMANAGMENT_TEACHER_STATUSCHANGE_ERR,
  IELTS_TEACHERMANAGMENT_TEACHER_STATUSCHANGE_REQUEST,
  IELTS_TEACHERMANAGMENT_TEACHER_STATUSCHANGE_SUCCESS,
  IELTS_TEACHERMANAGMENT_TEACHER_UPDATE_ERR,
  IELTS_TEACHERMANAGMENT_TEACHER_UPDATE_REQUEST,
  IELTS_TEACHERMANAGMENT_TEACHER_UPDATE_SUCCESS,
} from "../../constants/Ielts/ieltsTeacherManagmentConstants";

export const ieltsTeacherManagmentTeacherCreateReducer = (
  state = {},
  action
) => {
  switch (action.type) {
    case IELTS_TEACHERMANAGMENT_TEACHER_CREATE_REQUEST:
      return {
        ...state,
        ieltsTeacherManagmentTeacherCreateLoading: true,
      };
    case IELTS_TEACHERMANAGMENT_TEACHER_CREATE_SUCCESS:
      return {
        ...state,
        ieltsTeacherManagmentTeacherCreateLoading: false,
        ieltsTeacherManagmentTeacherCreateSuccess: action.payload,
      };
    case IELTS_TEACHERMANAGMENT_TEACHER_CREATE_ERR:
      return {
        ...state,
        ieltsTeacherManagmentTeacherCreateLoading: false,
        ieltsTeacherManagmentTeacherCreateerror: action.payload,
      };
    default:
      return state;
  }
};

// TEAHCER FIND REDUCER

export const ieltsTeacherManagmentTeacherFindReducer = (state = {}, action) => {
  switch (action.type) {
    case IELTS_TEACHERMANAGMENT_TEACHER_FIND_REQUEST:
      return {
        ...state,
        ieltsTeacherManagmentTeacherFindLoading: true,
      };
    case IELTS_TEACHERMANAGMENT_TEACHER_FIND_SUCCESS:
      return {
        ...state,
        ieltsTeacherManagmentTeacherFindLoading: false,
        ieltsTeacherManagmentTeacherFindSuccess: action.payload,
      };
    case IELTS_TEACHERMANAGMENT_TEACHER_FIND_ERR:
      return {
        ...state,
        ieltsTeacherManagmentTeacherFindLoading: false,
        ieltsTeacherManagmentTeacherFinderror: action.payload,
      };
    default:
      return state;
  }
};

// TEAHCER FINDBYID REDUCER

export const ieltsTeacherManagmentTeacherFindByIdReducer = (
  state = {},
  action
) => {
  switch (action.type) {
    case IELTS_TEACHERMANAGMENT_TEACHER_FINDBYID_REQUEST:
      return {
        ...state,
        ieltsTeacherManagmentTeacherFindByIdLoading: true,
      };
    case IELTS_TEACHERMANAGMENT_TEACHER_FINDBYID_SUCCESS:
      return {
        ...state,
        ieltsTeacherManagmentTeacherFindByIdLoading: false,
        ieltsTeacherManagmentTeacherFindByIdSuccess: action.payload,
      };
    case IELTS_TEACHERMANAGMENT_TEACHER_FINDBYID_ERR:
      return {
        ...state,
        ieltsTeacherManagmentTeacherFindByIdLoading: false,
        ieltsTeacherManagmentTeacherFindByIderror: action.payload,
      };
    default:
      return state;
  }
};

// TEACHER UPDATE REDUCER

export const ieltsTeacherManagmentTeacherUpdateReducer = (
  state = {},
  action
) => {
  switch (action.type) {
    case IELTS_TEACHERMANAGMENT_TEACHER_UPDATE_REQUEST:
      return {
        ...state,
        ieltsTeacherManagmentTeacherUpdateLoading: true,
      };
    case IELTS_TEACHERMANAGMENT_TEACHER_UPDATE_SUCCESS:
      return {
        ...state,
        ieltsTeacherManagmentTeacherUpdateLoading: false,
        ieltsTeacherManagmentTeacherUpdateSuccess: action.payload,
      };
    case IELTS_TEACHERMANAGMENT_TEACHER_UPDATE_ERR:
      return {
        ...state,
        ieltsTeacherManagmentTeacherUpdateLoading: false,
        ieltsTeacherManagmentTeacherUpdateerror: action.payload,
      };
    default:
      return state;
  }
};

// TEACHER STATUS CHANGE

export const ieltsTeacherManagmentTeacherStatusChangeReducer = (
  state = {},
  action
) => {
  switch (action.type) {
    case IELTS_TEACHERMANAGMENT_TEACHER_STATUSCHANGE_REQUEST:
      return {
        ...state,
        ieltsTeacherManagmentTeacherStatusChangeLoading: true,
      };
    case IELTS_TEACHERMANAGMENT_TEACHER_STATUSCHANGE_SUCCESS:
      return {
        ...state,
        ieltsTeacherManagmentTeacherStatusChangeLoading: false,
        ieltsTeacherManagmentTeacherStatusChangeSuccess: action.payload,
      };
    case IELTS_TEACHERMANAGMENT_TEACHER_STATUSCHANGE_ERR:
      return {
        ...state,
        ieltsTeacherManagmentTeacherStatusChangeLoading: false,
        ieltsTeacherManagmentTeacherStatusChangeerror: action.payload,
      };
    default:
      return state;
  }
};



// TEACHER STATUS CHANGE

export const ieltsTeacherManagmentTeacherFindByCenterIdReducer = (
  state = {},
  action
) => {
  switch (action.type) {
    case IELTS_TEACHERMANAGMENT_TEACHER_FINDBYCENTERID_REQUEST:
      return {
        ...state,
        ieltsTeacherManagmentTeacherFindByIdCenterLoading: true,
      };
    case IELTS_TEACHERMANAGMENT_TEACHER_FINDBYCENTERID_SUCCESS:
      return {
        ...state,
        ieltsTeacherManagmentTeacherFindByIdCenterLoading: false,
        ieltsTeacherManagmentTeacherFindByIdCenterSuccess: action.payload,
      };
    case IELTS_TEACHERMANAGMENT_TEACHER_FINDBYCENTERID_ERR:
      return {
        ...state,
        ieltsTeacherManagmentTeacherFindByIdCenterLoading: false,
        ieltsTeacherManagmentTeacherFindByIdCentererror: action.payload,
      };
    default:
      return state;
  }
};

// TEACHER ASSIGNED STUDENTS CHANGE

export const ieltsTeacherManagmentAssignedStudentsReducer = (
  state = {},
  action
) => {
  switch (action.type) {
    case IELTS_TEACHERMANAGMENT_ASSIGNED_STUDENTS_REQUEST:
      return {
        ...state,
        ieltsTeacherManagmentAssignedStudentsLoading: true,
      };
    case IELTS_TEACHERMANAGMENT_ASSIGNED_STUDENTS_SUCCESS:
      return {
        ...state,
        ieltsTeacherManagmentAssignedStudentsLoading: false,
        ieltsTeacherManagmentAssignedStudentsSuccess: action.payload,
      };
    case IELTS_TEACHERMANAGMENT_ASSIGNED_STUDENTS_ERR:
      return {
        ...state,
        ieltsTeacherManagmentAssignedStudentsLoading: false,
        ieltsTeacherManagmentAssignedStudentserror: action.payload,
      };
    default:
      return state;
  }
};
