import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Chip,
  Grid,
  InputAdornment,
  TextField,
} from "@mui/material";
// import CountryMaster from "./country/CountryMaster";
// import StateMaster from "./state/StateMaster";
// import CityMaster from "./city/CityMaster";
import { SubmitButton } from "../../customComponent/Buttons";
import CategoriesMain from "./categoriesMain";
import { useNavigate } from "react-router-dom";
import TopicsMain from "./topicsMain";
import { RiSearch2Line } from "react-icons/ri";
import usePrivilegeCheck from "../../../constants/customHooks/UsePrivilegeCheck";
import PremissionDialog from "../../customComponent/premissionDialog";

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginBottom: theme.spacing(2),
    marginTop: "8px",
  },
  hoverElement: {
    "&:hover": {
      color: "#FE0B7A", // Set the hover color
    },
    "&.clicked": {
      backgroundColor: "#FE0B7A",
      color: "white",
    },
  },
  searchContainer: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(1),
  },
  searchInput: {
    marginLeft: theme.spacing(1),
  },
}));

function IeltsLearningManagmentMain({ masterPageHandler }) {
  const navigate = useNavigate();
  const { privilege, hasPrivilege, isCreatePrevilage } = usePrivilegeCheck();

  const classes = useStyles();
  const [clicked, setClicked] = useState("Categories");
  const [addFormCountry, setAddFormCountry] = useState(false);
  const [addFormState, setAddFormState] = useState(false);
  const [addFormCity, setAddFormCity] = useState(false);
  const [searchvalue, setSearchValue] = useState("");
  const [openAlert, setOpenAlert] = useState(false);

  // HandleClose dialog

  const handleCloseDialog = () => {
    setOpenAlert(false);
  };

  const handleClick = (master) => {
    setClicked(master);
  };

  const handleModalClick = () => {
    if (clicked === "Categories") {
      setAddFormCountry(true);
    }

    if (clicked === "Topics") {
      setAddFormState(true);
    }
  };

  const searchHandleChange = (value) => {
    setSearchValue(value);
  };

  if (!hasPrivilege("learning managment") && privilege !== null) {
    return (
      <Alert severity="error">
        <AlertTitle>warning</AlertTitle>
        You don't have permission to access — <strong>this page.!</strong>
      </Alert>
    );
  }

  return (
    <>
      <Grid container>
        <Grid item xs={12} lg={6}>
          <Chip
            className={`${classes.hoverElement} ${
              clicked === "Categories" ? "clicked" : ""
            }`}
            label="Categories"
            component="a"
            clickable
            sx={{ m: 1 }}
            onClick={() => handleClick("Categories")}
          />
          <Chip
            className={`${classes.hoverElement} ${
              clicked === "Topics" ? "clicked" : ""
            }`}
            label="Topics"
            component="a"
            clickable
            sx={{ m: 1 }}
            onClick={() => handleClick("Topics")}
          />
        </Grid>
        <Grid
          item
          xs={12}
          lg={6}
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          {clicked === "Topics" && (
            <div className={classes.searchContainer}>
              <TextField
                className={classes.searchInput}
                variant="outlined"
                placeholder="Search Here"
                sx={{
                  "& .MuiInputBase-input": {
                    borderRadius: "20px",
                    height: "1px",
                  },
                  "& .MuiFormLabel-root": {
                    lineHeight: "5px",
                  },

                  "& .MuiInputBase-root": {
                    borderRadius: "3px",
                    borderColor: "#fafafa",
                  },
                }}
                onChange={(e) => searchHandleChange(e.target.value)}
              />
              <>
                <Box
                  sx={{
                    width: "40px",
                    height: "16px",
                    pt: 1.9,
                    textAlign: "center",
                    border: "1px solid #9d9fa1",
                    borderBottomRightRadius: "5px",
                    WebkitBorderTopRightRadius: "5px",
                    fontSize: "24px",
                  }}
                >
                  <InputAdornment position="end">
                    <RiSearch2Line />
                  </InputAdornment>
                </Box>
              </>
            </div>
          )}
          {clicked === "Topics" ? (
            <SubmitButton
              title="Add New"
              submit=""
              widthSize="123px"
              heightSize="38px"
              type="click"
              handleSubmit={() => {
                if (isCreatePrevilage("learning managment")) {
                  navigate("/ielts/learningManagment/addTopics");
                } else {
                  setOpenAlert(true);
                }
              }}
            />
          ) : (
            ""
          )}
        </Grid>
      </Grid>

      {clicked === "Categories" && <CategoriesMain />}
      {clicked === "Topics" && <TopicsMain searchvalue={searchvalue} />}

      {/* {clicked === "state" && (
        <StateMaster
          addFormState={addFormState}
          stateFormStatusHandler={stateFormStatusHandler}
        />
      )}

      {clicked === "city" && (
        <CityMaster
          addFormCity={addFormCity}
          cityFormStatusHandler={cityFormStatusHandler}
        />
      )}  */}

      {openAlert && (
        <PremissionDialog
          openAlert={openAlert}
          handleClose={handleCloseDialog}
        />
      )}
    </>
  );
}

export default IeltsLearningManagmentMain;
