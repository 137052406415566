import React, { useState } from "react";
import HeadBreadcrumbs from "../breadcrumbs/breadcrumbs.js";
import { MainContainer } from "../content/content.element.js";
import BasicTabs from "../Tabs/TabsMain.js";

function AccountMain(props) {
  const [tabValue, setTabValue] = useState(0);

  return (
    <>
      <MainContainer active={props.toggle}>
        {tabValue === 0 && (
          <HeadBreadcrumbs
            head="home"
            sechead="Accounts"
            first="Accounts"
            main="Incentive Category"
          />
        )}

        {tabValue === 1 && (
          <HeadBreadcrumbs
            head="home"
            sechead="Accounts"
            first="Accounts"
            main="Employee Incentive"
          />
        )}

        {tabValue === 2 && (
          <HeadBreadcrumbs
            head="home"
            sechead="Accounts"
            first="Accounts"
            main="Other Incentive"
          />
        )}

        {tabValue === 3 && (
          <HeadBreadcrumbs
            head="home"
            sechead="Accounts"
            first="Accounts"
            main="Bonus"
          />
        )}

        {tabValue === 4 && (
          <HeadBreadcrumbs
            head="home"
            sechead="Accounts"
            first="Accounts"
            main="Agent Incentive"
          />
        )}

        {tabValue === 5 && (
          <HeadBreadcrumbs
            head="home"
            sechead="Accounts"
            first="Accounts"
            main="RegistrationFee approval"
          />
        )}

        {tabValue === 6 && (
          <HeadBreadcrumbs
            head="home"
            sechead="Accounts"
            first="Accounts"
            main="TutionFee approval"
          />
        )}
        <BasicTabs
          tab1={props.tab1}
          tab2={props.tab2}
          tab3={props.tab3}
          tab4={props.tab4}
          tab5={props.tab5}
          tab6={props.tab6}

          callback={(data) => setTabValue(data)}
          tab1Name="Incentive Category"
          tab2Name="Employee Incentive"
          tab3Name="Incentive"
          tab4Name="Bonus"
          tab5Name="RegistrationFee approval"
          tab6Name="TutionFee approval"

          // tab3Name="Agent Incentive"
          // tab4Name="Other Agency Incentive"
          // tab5Name="Subscription"
          head="Home"
          sechead="role"
        />
      </MainContainer>
    </>
  );
}

export default AccountMain;
