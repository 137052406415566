import React, { useCallback, useEffect, useState } from "react";
import { MainContainer } from "../../content/content.element";
import { InfoHeader } from "../../customComponent/InfoHeader";
import {
  Box,
  Button,
  FormLabel,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { getSectionOrder } from "@mui/x-date-pickers/internals/hooks/useField/useField.utils";
import { useDispatch, useSelector } from "react-redux";
import { bannerCreateAction } from "../../../actions/bannerAction";
import Swal from "sweetalert2";
import { successTrueMsgAction } from "../../../actions/globalAction";

function AddBannerForm({ toggle }) {
  const dispatch = useDispatch();
  const [uploadedBanner, setUploadedBanner] = useState([]);
  const [order, setOrder] = useState([]);
  const [err, setErr] = useState("");

  let { bannerCreateSuccess, bannerCreateerror } = useSelector((state) => {
    return state.bannerCreate;
  });

  const shadowValues = {
    X: 0,
    Y: 0,
    Blur: 4,
    Spread: 0,
  };

  const boxShadowStyle = `${shadowValues.X}px ${shadowValues.Y}px ${shadowValues.Blur}px ${shadowValues.Spread}px rgba(0, 0, 0, 0.5)`;

  // UseEffect to check is banner already upload

  const { successAlertMsgSuccess } = useSelector((state) => {
    return state.successTrueMsg;
  });

  useEffect(() => {
    if (bannerCreateSuccess && successAlertMsgSuccess) {
      Swal.fire("created!", `Banner create successfully.`, "success");
      dispatch(successTrueMsgAction(false));
    }
  }, [bannerCreateSuccess]);

  const handleBannerUpload = async (data) => {
    console.log(data, "dataaaaaaaaaaaaaaaa");

    if (data.length <= 5) {
      for (let i = 0; i < data.length; i++) {
        const file = data[i];
        const dimensions = await getImageDimensions(file);
        if (dimensions.width !== 328 || dimensions.height !== 128) {
          Swal.fire(
            "Warning!",
            `Invalid dimensions for ${file.name}. Please upload an image with dimensions 328x128.`,
            "warning"
          );
        } else {
          setUploadedBanner(Array.from(data));
          console.log(`${file.name} dimensions are valid.`);
        }
      }
    } else {
      Swal.fire("Warning!", `Maximum upload 5`, "warning");
    }
  };

  // function to validate image dimentions

  const getImageDimensions = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();

      reader.onload = (e) => {
        const img = new Image();
        img.src = e.target.result;

        img.onload = () => {
          resolve({ width: img.width, height: img.height });
        };
      };

      reader.readAsDataURL(file);
    });
  };

  //   Delete function

  const handleDelete = (index) => {
    const filteredData = uploadedBanner?.filter((value, i) => i !== index);
    setUploadedBanner(filteredData);
  };

  console.log(uploadedBanner, "uplodeBannerrrrrrrrrrrrrr");

  //   Handle change

  const handleChange = (index, value) => {
    const id = uploadedBanner?.findIndex((item, idx) => idx === index);
    setOrder((prev) => {
      const updatedArray = [...prev];
      updatedArray[index] = value;
      return updatedArray;
    });
  };

  const handleSubmit = () => {
    const formData = new FormData();
    uploadedBanner?.forEach((file, index) => {
      formData.append("image", file);
    });
    formData.append("order", JSON.stringify(order));
    dispatch(bannerCreateAction(formData));
    dispatch(successTrueMsgAction(true));
    setUploadedBanner([]);
  };

  console.log(order, "orderrrrrrrrrrrrrr");

  return (
    <>
      <MainContainer active={toggle}>
        <InfoHeader headTitle="Add Banner Image" url="/Settings" />
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item lg={6} xs={12}>
            <FormLabel
              sx={{
                width: "98.4%",
                height: "46px",
                border: "1px solid #C4C4C4",
                display: "flex",
                alignItems: "center",
                pl: "14px",
                pr: "14px",
              }}
              //   onChange={handleChange}
            >
              <Typography
                sx={{
                  fontSize: "16px",
                  lineHeight: "24px",
                  color: "#ACB1C6",
                  textAlign: "left",
                  width: "100%",
                }}
              >
                {"Select images"}
              </Typography>
              <input
                type="file"
                hidden
                name="image"
                onChange={(e) => {
                  handleBannerUpload(e.target.files);
                  setErr("");
                }}
                accept="image/jpeg, image/jpg, image/png, image/gif"
                multiple
              />
              <Box
                sx={{
                  cursor: "pointer",
                  padding: "8px 18px",
                  background: "#EBEBEB",
                  fontFamily: "Poppins",
                  color: "#010101",
                  fontWeight: 400,
                  fontSize: "12px",
                }}
              >
                Browse
              </Box>
            </FormLabel>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Typography
                sx={{
                  fontSize: "12px",
                  lineHeight: "24px",
                  color: "#656395",
                  textAlign: "left",
                  //   width: "100%",
                  fontWeight: 400,
                  fontfamily: "'Open Sans', sans-serif",
                }}
              >
                {err
                  ? err
                  : "You can select a minimum of 1 image and a maximum of 5 images"}
              </Typography>
              <Typography
                sx={{
                  fontSize: "12px",
                  lineHeight: "24px",
                  color: "#FF2D2E",
                  fontWeight: 400,
                  fontfamily: "'Open Sans', sans-serif",
                }}
              >
                size width=328,heigth=128,
              </Typography>
            </Box>
            {uploadedBanner?.length > 0 ? (
              <Box>
                <Typography
                  sx={{
                    fontSize: "20px",
                    lineHeight: "24px",
                    color: "#05050F",
                    fontWeight: 500,
                    fontFamily: "'Montserrat', sans-serif",
                    mt: 2,
                  }}
                >
                  Uploaded images
                </Typography>
              </Box>
            ) : (
              ""
            )}
            {/* <span style={{ color: "red", paddingLeft: "10px", fontWeight: 600 }}>
            {err.note}
          </span> */}
          </Grid>
          <Grid
            container
            lg={12}
            xs={6}
            sx={{ display: "flex", mt: 4, pl: "14px", flexWrap: "wrap" }}
          >
            {uploadedBanner.length > 0
              ? uploadedBanner?.map((value, index) => (
                  <Grid
                    key={index}
                    item
                    lg={3}
                    sx={{
                      borderColor: "#FFFFFF",
                      borderRadius: "12px",
                      boxShadow: boxShadowStyle,
                      height: "103px",
                      mr: 2, // Add right margin for the first 3 items in each row
                      mb: 2, // Add bottom margin for all items
                      flex: "0 0 25%", // Ensure only 4 items in a row
                    }}
                  >
                    <Box sx={{ display: "flex" }}>
                      <Box sx={{ p: 2 }}>
                        <img
                          src={URL.createObjectURL(value)}
                          width={71}
                          height={71}
                          alt={`item-${index}`}
                        />
                      </Box>
                      <Box sx={{ ml: 2, mt: 2 }}>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography>Order Number</Typography>
                          <img
                            src="/deleteMark.png"
                            width={15}
                            height={15}
                            onClick={() => handleDelete(index)}
                          ></img>
                        </Box>
                        <input
                          placeholder="type"
                          style={{
                            borderColor: "#FFFFFF",
                            boxShadow: "1px 3px 6px -1px rgba(0, 0, 0, 0.5)",
                            height: "41px",
                            borderRadius: "12px",
                          }}
                          onChange={(e) => handleChange(index, e.target.value)}
                        />
                      </Box>
                    </Box>
                  </Grid>
                ))
              : null}
          </Grid>

          <Button
            sx={{
              color: "white",
              backgroundColor: "#141E3C",
              height: "38px",
              width: "143px",
              fontWeight: 700,
              fontSize: "12px",
              borderRadius: "8px",
              "&:hover": {
                backgroundColor: "rgba(20, 30, 60, 0.9)",
              },
              mt: 3,
            }}
            onClick={handleSubmit}
          >
            submit
          </Button>
        </Grid>
      </MainContainer>
    </>
  );
}

export default AddBannerForm;
