import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import LeadersSearchHeader from "../Leads/LeadersSearchHeader";
import FinalChoicesSummery from "./FinalChoice/FinalChoicesSummery";
import FinalChoicesList from "./FinalChoice/FinalChoicesList";
import { SubmitButton } from "../customComponent/Buttons";
import InputField, { SelectInputField } from "../customComponent/InputField";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllFinalSummaryAction,
  getFinalSummaryByFilterAction,
} from "../../actions/reportActions/reportsActions";
import { findAllLeadApplicationAction } from "../../actions/aplicationActions/applicationsAction";

function ReportsTab() {
  const dispatch = useDispatch();

  const [page, setPage] = useState(1);
  const [data, setData] = useState([]);

  const [reportData, setReportData] = useState([]);
  const [name, setName] = useState("");
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");

  let { applicationAllFindSuccess } = useSelector((state) => {
    return state.findAllLeadApplication;
  });

  let { paidPaymentStatusSuccess } = useSelector((state) => {
    return state.paidPaymentUpdation;
  });

  useEffect(() => {
    dispatch(findAllLeadApplicationAction(page, "Enrolled"));
  }, [dispatch, page, paidPaymentStatusSuccess]);

  useEffect(() => {
    if (applicationAllFindSuccess) {
      console.log(
        applicationAllFindSuccess,
        "applicationAllFindSuccessapplicationAllFindSuccessapplicationAllFindSuccess"
      );
      setData(
        applicationAllFindSuccess?.data?.map((item) =>
          createData(
            item.id,
            item?.applicationId,
            item.lead?.firstName,
            item?.universityName,
            item?.courseName,
            // "15 / 02 / 2023",
            item?.createdAt?.split("T")[0],
            item?.isInvoiceCompleted,
            item?.isPaidToGsl,
            item?.isPaidToPartner,
            "15 / 02 / 2023"
          )
        )
      );

      setReportData(
        applicationAllFindSuccess?.data?.map((item) =>
          createData(
            item.id,
            item?.applicationId,
            item.lead?.firstName,
            item?.universityName,
            item?.courseName,
            // "15 / 02 / 2023",
            item?.createdAt?.split("T")[0],
            item?.isInvoiceCompleted,
            item?.isPaidToGsl,
            item?.isPaidToPartner,
            "15 / 02 / 2023"
          )
        )
      );

      // applicationAllFindSuccess?.data?.map(
      //   (item) => {
      //     // id: item.id,
      //     // universityName: item?.universityName,
      //     // studentName: item.lead?.firstName,
      //     // university: item?.universityCountry,
      //     // course: item?.courseName,
      //     // date: item.intakeDate,
      //     // status: item.methodStatus,
      //   },
      //   [applicationAllFindSuccess]
      // );

      // setApplicationData(
      //   applicationAllFindSuccess?.data?.map((item) => ({
      //     id: item.id,
      //     column1: item.lead?.firstName,
      //     column2: item?.universityCountry,
      //     column3: item?.courseName,
      //     column4: item?.user !== null ? item?.user?.firstName : "Not Assigned",
      //     column5: item.intakeDate,
      //     column6: item.methodStatus,
      //     leadId: item.leadId,
      //   }))
      // );
    }
  }, [applicationAllFindSuccess, page]);

  function createData(
    id,
    applicationId,
    name,
    university,
    course,
    startDate,
    invoice,
    paidToGsl,
    paidToPartner,
    paymentDate
  ) {
    return {
      id,
      applicationId,
      name,
      university,
      course,
      startDate,
      // tutionFee,
      invoice,
      paidToGsl,
      paidToPartner,
      paymentDate,
    };
  }

  // const rows = [
  //   createData(
  //     "2654566",
  //     "Ameen Muhammed",
  //     "Cranfield University",
  //     "Global Public Health",
  //     "15 / 02 / 2023",
  //     "25250",
  //     true,
  //     true,
  //     false,
  //     "15 / 02 / 2023"
  //   ),
  //   createData(
  //     "2654566",
  //     "favas ",
  //     "Cranfield University",
  //     "Global Public Health",
  //     "15 / 02 / 2023",
  //     "25250",
  //     true,
  //     false,
  //     true,
  //     "15 / 02 / 2023"
  //   ),
  //   createData(
  //     "2654566",
  //     "Shabeer",
  //     "Cranfield University",
  //     "Global Public Health",
  //     "15 / 02 / 2023",
  //     "25250",
  //     false,
  //     false,
  //     true,
  //     "15 / 02 / 2023"
  //   ),
  //   createData(
  //     "2654566",
  //     "Muhammed",
  //     "Cranfield University",
  //     "Global Public Health",
  //     "15 / 02 / 2023",
  //     "25250",
  //     true,
  //     false,
  //     false,
  //     "15 / 02 / 2023"
  //   ),
  // ];

  const [columnName, setColumnName] = useState(false);
  const [showStudentNameColumn, setShowStudentNameColumn] = useState(false);
  const [showStudentIdColumn, setShowStudentIdColumn] = useState(false);
  const [showStudentUniversityColumn, setShowStudentUniversityColumn] =
    useState(false);
  const [showStudentCourseColumn, setShowStudentCourseColumn] = useState(false);
  const [showStartDateColumn, setShowStartDateColumn] = useState(false);
  const [showTutionFeeColumn, setShowTutionFeeColumn] = useState(false);
  const [showInvoiceColumn, setShowInvoiceColumn] = useState(false);
  const [showPaidToGslColumn, setShowPaidToGslColumn] = useState(false);
  const [showPaidToPartnerColumn, setShowPaidToPartnerColumn] = useState(false);
  const [showPaymentDateColumn, setShowPaymentDateColumn] = useState(false);
  const [finalSummaryData, setFinalSummaryData] = useState({});

  const { finalSummaryCountsSuccess } = useSelector((state) => {
    return state.getAllFinalSummary;
  });

  const columns = [
    {
      title: "Student id",
      field: "studentId",
      hidden: showStudentIdColumn,
    },
    {
      title: "Student name",
      field: "studentName",
      hidden: showStudentNameColumn,
    },
    {
      title: "university",
      field: "university",
      hidden: showStudentUniversityColumn,
    },
    {
      title: "course",
      field: "course",
      hidden: showStudentCourseColumn,
    },
    {
      title: "start Date",
      field: "startDate",
      hidden: showStartDateColumn,
    },
    // {
    //   title: "Tution Fee",
    //   field: "tutionFee",
    //   hidden: showTutionFeeColumn,
    // },
    {
      title: "invoice",
      field: "invoice",
      hidden: showInvoiceColumn,
    },
    {
      title: "paid to gsl",
      field: "paidToGsl",
      hidden: showPaidToGslColumn,
    },
    {
      title: "paid to partner",
      field: "paidToPartner",
      hidden: showPaidToPartnerColumn,
    },
    {
      title: "payment Date",
      field: "paymentDate",
      hidden: showPaymentDateColumn,
    },
  ];

  useEffect(() => {
    if (finalSummaryCountsSuccess) {
      setFinalSummaryData(finalSummaryCountsSuccess);
    }
  }, [finalSummaryCountsSuccess]);

  useEffect(() => {
    dispatch(getAllFinalSummaryAction());
  }, [dispatch]);

  const toggleNameColumn = (status, column) => {
    console.log(status, column);
    if (column === "studentName") {
      setColumnName(column);
      setShowStudentNameColumn(status);
    }
    if (column === "studentId") {
      setColumnName(column);
      setShowStudentIdColumn(status);
    }
    if (column === "university") {
      setColumnName(column);
      setShowStudentUniversityColumn(status);
    }
    if (column === "course") {
      setColumnName(column);
      setShowStudentCourseColumn(status);
    }
    if (column === "startDate") {
      setColumnName(column);
      setShowStartDateColumn(status);
    }

    // if (column === "tutionFee") {
    //   setColumnName(column);
    //   setShowTutionFeeColumn(status);
    // }

    if (column === "invoice") {
      setColumnName(column);
      setShowInvoiceColumn(status);
    }
    if (column === "paidToGsl") {
      setColumnName(column);
      setShowPaidToGslColumn(status);
    }
    if (column === "paidToPartner") {
      setColumnName(column);
      setShowPaidToPartnerColumn(status);
    }
    if (column === "paymentDate") {
      setColumnName(column);
      setShowPaymentDateColumn(status);
    }
  };

  const handleFilterResetData = (status) => {
    setName("");
  };

  const applyFilters = () => {
    const filteredData = data.filter((dataItem) => {
      const matchesLocation = dataItem.name
        .toLowerCase()
        .includes(name.toLowerCase());
      return matchesLocation;
    });
    setReportData(filteredData);
  };

  const LeadFilterFunction = (data) => {
    console.log(data);
    setName(data);
  };

  useEffect(() => {
    applyFilters();
  }, [name]);

  const visibility = [
    {
      label: "Student id",
      name: "studentId",
      status: showStudentIdColumn,
    },
    {
      label: "Student name",
      name: "studentName",
      status: showStudentNameColumn,
    },
    {
      label: "university",
      name: "university",
      status: showStudentUniversityColumn,
    },
    {
      label: "course",
      name: "course",
      status: showStudentCourseColumn,
    },
    {
      label: "start Date",
      name: "startDate",
      status: showStartDateColumn,
    },
    {
      label: "Tution Fee",
      name: "tutionFee",
      status: showTutionFeeColumn,
    },
    {
      label: "invoice",
      name: "invoice",
      status: showInvoiceColumn,
    },
    {
      label: "paid to gsl",
      name: "paidToGsl",
      status: showPaidToGslColumn,
    },
    {
      label: "paid to partner",
      name: "paidToPartner",
      status: showPaidToPartnerColumn,
    },
    {
      label: "payment Date",
      name: "paymentDate",
      status: showPaymentDateColumn,
    },
  ];

  const finalSummaryShowClickHandler = () => {
    dispatch(getFinalSummaryByFilterAction(year, month));
  };

  return (
    <Box>
      <Box
        sx={{
          background: "#F4F4F4",
          padding: "12px",
        }}
      >
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: {
              sm: "repeat(2,1fr)",
            },
            gap: "20px",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <SelectInputField
            label="Year"
            handleSelectChange={(e) => setYear(e.target.value)}
            value={year}
            mapMethod=""
            mapValue={[2012, 2013, 2014, 2015]}
          />
          <SelectInputField
            label="Month"
            handleSelectChange={(e) => setMonth(e.target.value)}
            value={month}
            mapMethod=""
            mapValue={[
              "All Months",
              "January",
              "February",
              "March",
              "April",
              "May",
              "June",
              "July",
              "August",
              "September",
              "October",
              "November",
              "December",
            ]}
          />
        </Box>
        <Box sx={{ mt: "40px" }}>
          <SubmitButton
            variant={"filled"}
            title="Show"
            fontWeight={"700"}
            handleSubmit={finalSummaryShowClickHandler}
            type="click"
          />
        </Box>
      </Box>
      <Box>
        <Typography
          sx={{
            fontSize: "20px",
            lineHeight: "24px",
            fontWeight: "400",
            color: "#000",
            mt: "32px",
            mb: "24px",
          }}
        >
          Final Choices Summary
        </Typography>
        <Box sx={{ mb: "40px" }}>
          <FinalChoicesSummery
            data={finalSummaryData !== undefined && finalSummaryData}
          />
        </Box>
        <Box>
          <Box
            sx={{
              mb: "26px",
            }}
          >
            <LeadersSearchHeader
              handleApply={applyFilters}
              search
              LeadFilterFunction={LeadFilterFunction}
              handleFilterResetData={handleFilterResetData}
              visibility={visibility}
              columnVisibleHandler={toggleNameColumn}
              component="reports"
            />
          </Box>
          <FinalChoicesList
            rows={reportData}
            columns={columns}
            showStudentNameColumn={showStudentNameColumn}
            showStudentIdColumn={showStudentIdColumn}
            showStudentUniversityColumn={showStudentUniversityColumn}
            showStudentCourseColumn={showStudentCourseColumn}
            showStartDateColumn={showStartDateColumn}
            showTutionFeeColumn={showTutionFeeColumn}
            showInvoiceColumn={showInvoiceColumn}
            showPaidToGslColumn={showPaidToGslColumn}
            showPaidToPartnerColumn={showPaidToPartnerColumn}
            showPaymentDateColumn={showPaymentDateColumn}
          />
        </Box>
      </Box>
    </Box>
  );
}

export default ReportsTab;
