import {
  CREATE_PRIVILAGE_ERR,
  CREATE_PRIVILAGE_REQUEST,
  CREATE_PRIVILAGE_SUCCESS,
  CREATE_ROLE_SUB_MENU_PERMISSION_ERR,
  CREATE_ROLE_SUB_MENU_PERMISSION_REQUEST,
  CREATE_ROLE_SUB_MENU_PERMISSION_SUCCESS,
  CREATE_SELECTALL_PERMISSION_ERR,
  CREATE_SELECTALL_PERMISSION_REQUEST,
  CREATE_SELECTALL_PERMISSION_SUCCESS,
  CREATE_SELECTALL_PREVILAGE_ERR,
  CREATE_SELECTALL_PREVILAGE_REQUEST,
  CREATE_SELECTALL_PREVILAGE_SUCCESS,
  FIND_PRIVILAGE_ERR,
  FIND_PRIVILAGE_REQUEST,
  FIND_PRIVILAGE_SUCCESS,
  FIND_ROLE_SUB_MENU_PERMISSION_ERR,
  FIND_ROLE_SUB_MENU_PERMISSION_REQUEST,
  FIND_ROLE_SUB_MENU_PERMISSION_SUCCESS,
  GET_ALL_MAIN_AND_SUB_ERR,
  GET_ALL_MAIN_AND_SUB_REQUEST,
  GET_ALL_MAIN_AND_SUB_SUCCESS,
  ROLE_ALL_FIND_ERR,
  ROLE_ALL_FIND_REQUEST,
  ROLE_ALL_FIND_SUCCESS,
  ROLE_BULKUPLOAD_ERR,
  ROLE_BULKUPLOAD_REQUEST,
  ROLE_BULKUPLOAD_SUCCESS,
  ROLE_CREATE_ERR,
  ROLE_CREATE_REQUEST,
  ROLE_CREATE_SUCCESS,
  ROLE_DELETE_ERR,
  ROLE_DELETE_REQUEST,
  ROLE_DELETE_SUCCESS,
  ROLE_EDIT_ERR,
  ROLE_EDIT_FIND_ERR,
  ROLE_EDIT_FIND_REQUEST,
  ROLE_EDIT_FIND_SUCCESS,
  ROLE_EDIT_REQUEST,
  ROLE_EDIT_SUCCESS,
  SUCCESS_TRUE_MSG_ERR,
  SUCCESS_TRUE_MSG_REQUEST,
  SUCCESS_TRUE_MSG_SUCCESS,
} from "../constants/roleManagmentConstant";



// role bulkUpload Reducer


export const rollBulkUploadReducer = (state = {}, action) => {
  switch (action.type) {
    case ROLE_BULKUPLOAD_REQUEST:
      return {
        ...state,
        rollBulkUploadLoading: true,
      };
    case ROLE_BULKUPLOAD_SUCCESS:
      return {
        ...state,
        rollBulkUploadLoading: false,
        rollBulkUploadSuccess: action.payload,
      };
    case ROLE_BULKUPLOAD_ERR:
      return {
        ...state,
        rollBulkUploadLoading: false,
        rollBulkUploadErr: action.payload,
      };
    default:
      return state;
  }
};




// createRoll submit Reducer
export const createRollReducer = (state = {}, action) => {
  switch (action.type) {
    case ROLE_CREATE_REQUEST:
      return {
        ...state,
        createRollLoading: true,
      };
    case ROLE_CREATE_SUCCESS:
      return {
        ...state,
        createRollLoading: false,
        createRollSuccess: action.payload,
      };
    case ROLE_CREATE_ERR:
      return {
        ...state,
        createRollLoading: false,
        createRollErr: action.payload,
      };
    default:
      return state;
  }
};

export const allRoleFindReducer = (state = {}, action) => {
  switch (action.type) {
    case ROLE_ALL_FIND_REQUEST:
      return {
        ...state,
        allRoleFindLoading: true,
      };
    case ROLE_ALL_FIND_SUCCESS:
      return {
        ...state,
        allRoleFindLoading: false,
        allRoleFindSuccess: action.payload,
      };
    case ROLE_ALL_FIND_ERR:
      return {
        ...state,
        allRoleFindLoading: false,
        allRoleFindErr: action.payload,
      };
    default:
      return state;
  }
};

// roleDeleteReducer
export const roleDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case ROLE_DELETE_REQUEST:
      return {
        ...state,
        rollDeleteLoading: true,
      };
    case ROLE_DELETE_SUCCESS:
      return {
        ...state,
        rollDeleteLoading: false,
        rollDeleteSuccess: action.payload,
      };
    case ROLE_DELETE_ERR:
      return {
        ...state,
        rollDeleteLoading: false,
        rollDeleteErr: action.payload,
      };
    default:
      return state;
  }
};

// roleEditReducer
export const roleEditFindReducer = (state = {}, action) => {
  switch (action.type) {
    case ROLE_EDIT_FIND_REQUEST:
      return {
        ...state,
        rollEditFindLoading: true,
      };
    case ROLE_EDIT_FIND_SUCCESS:
      return {
        ...state,
        rollEditLoading: false,
        rollEditFindSuccess: action.payload,
      };
    case ROLE_EDIT_FIND_ERR:
      return {
        ...state,
        rollEditLoading: false,
        rollEditFindErr: action.payload,
      };
    default:
      return state;
  }
};

// editRollReducer
export const editRollReducer = (state = {}, action) => {
  switch (action.type) {
    case ROLE_EDIT_REQUEST:
      return {
        ...state,
        rollEditLoading: true,
      };
    case ROLE_EDIT_SUCCESS:
      return {
        ...state,
        rollEditLoading: false,
        rollEditSuccess: action.payload,
      };
    case ROLE_EDIT_ERR:
      return {
        ...state,
        rollEditLoading: false,
        rollEditErr: action.payload,
      };
    default:
      return state;
  }
};

// getAllMainMenuWithSubReducer
export const getAllMainMenuWithSubReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_ALL_MAIN_AND_SUB_REQUEST:
      return {
        ...state,
        findAllMenuWithSubLoading: true,
      };
    case GET_ALL_MAIN_AND_SUB_SUCCESS:
      return {
        ...state,
        findAllMenuWithSubLoading: false,
        findAllMenuWithSubSuccess: action.payload,
      };
    case GET_ALL_MAIN_AND_SUB_ERR:
      return {
        ...state,
        findAllMenuWithSubLoading: false,
        findAllMenuWithSubErr: action.payload,
      };
    default:
      return state;
  }
};

// createRoleSubMenuReducer
export const createRoleSubMenuReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_ROLE_SUB_MENU_PERMISSION_REQUEST:
      return {
        ...state,
        createRoleSubSuccessLoading: true,
      };
    case CREATE_ROLE_SUB_MENU_PERMISSION_SUCCESS:
      return {
        ...state,
        createRoleSubSuccessLoading: false,
        createRoleSubSuccessSuccess: action.payload,
      };
    case CREATE_ROLE_SUB_MENU_PERMISSION_ERR:
      return {
        ...state,
        createRoleSubSuccessLoading: false,
        createRoleSubSuccessErr: action.payload,
      };
    default:
      return state;
  }
};

// findRoleSubMenuReducer
export const findRoleSubMenuReducer = (state = {}, action) => {
  switch (action.type) {
    case FIND_ROLE_SUB_MENU_PERMISSION_REQUEST:
      return {
        ...state,
        findRoleSubSuccessLoading: true,
      };
    case FIND_ROLE_SUB_MENU_PERMISSION_SUCCESS:
      return {
        ...state,
        findRoleSubSuccessLoading: false,
        findRoleSubSuccessSuccess: action.payload,
      };
    case FIND_ROLE_SUB_MENU_PERMISSION_ERR:
      return {
        ...state,
        findRoleSubSuccessLoading: false,
        findRoleSubSuccessErr: action.payload,
      };
    default:
      return state;
  }
};

// createPrivilageReducer
export const createPrivilageReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_PRIVILAGE_REQUEST:
      return {
        ...state,
        createPrivilageSuccessLoading: true,
      };
    case CREATE_PRIVILAGE_SUCCESS:
      return {
        ...state,
        createPrivilageSuccessLoading: false,
        createPrivilageSuccess: action.payload,
      };
    case CREATE_PRIVILAGE_ERR:
      return {
        ...state,
        createPrivilageSuccessLoading: false,
        createPrivilageSuccessErr: action.payload,
      };
    default:
      return state;
  }
};

// findPermissionPrivilageReducer
export const findPermissionPrivilageReducer = (state = {}, action) => {
  switch (action.type) {
    case FIND_PRIVILAGE_REQUEST:
      return {
        ...state,
        findPrivilageLoading: true,
      };
    case FIND_PRIVILAGE_SUCCESS:
      return {
        ...state,
        findPrivilageLoading: false,
        findPrivilageSuccess: action.payload,
      };
    case FIND_PRIVILAGE_ERR:
      return {
        ...state,
        findPrivilageLoading: false,
        findPrivilageErr: action.payload,
      };
    default:
      return state;
  }
};


// createSelectAllPrivilageReducer

export const createSelectAllPrivilageReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_SELECTALL_PREVILAGE_REQUEST:
      return {
        ...state,
        createSelectAllPrivilageSuccessLoading: true,
      };
    case CREATE_SELECTALL_PREVILAGE_SUCCESS:
      return {
        ...state,
        createSelectAllPrivilageSuccessLoading: false,
        createSelectAllPrivilageSuccess: action.payload,
      };
    case CREATE_SELECTALL_PREVILAGE_ERR:
      return {
        ...state,
        createSelectAllPrivilageSuccessLoading: false,
        createSelectAllPrivilageSuccessErr: action.payload,
      };
    default:
      return state;
  }
};



// createSelectAllPrivilageReducer

export const createSelectAllPermissionReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_SELECTALL_PERMISSION_REQUEST:
      return {
        ...state,
        createSelectAllPermissionSuccessLoading: true,
      };
    case CREATE_SELECTALL_PERMISSION_SUCCESS:
      return {
        ...state,
        createSelectAllPermissionSuccessLoading: false,
        createSelectAllPermissionSuccess: action.payload,
      };
    case CREATE_SELECTALL_PERMISSION_ERR:
      return {
        ...state,
        createSelectAllPermissionSuccessLoading: false,
        createSelectAllPermissionSuccessErr: action.payload,
      };
    default:
      return state;
  }
};


// SUCCESS ALERT REDUCER

export const successTrueMsgReducer = (state = {}, action) => {
  switch (action.type) {
    case SUCCESS_TRUE_MSG_REQUEST:
      return {
        ...state,
        successAlertMsgLoading: true,
      };
    case SUCCESS_TRUE_MSG_SUCCESS:
      return {
        ...state,
        successAlertMsgLoading: false,
        successAlertMsgSuccess: action.payload,
      };
    case SUCCESS_TRUE_MSG_ERR:
      return {
        ...state,
        successAlertMsgLoading: false,
        successAlertMsgErr: action.payload,
      };
    default:
      return state;
  }
};
