import React, { useState } from "react";
import BasicTabs from "../Tabs/TabsMain";
import { MainContainer } from "../content/content.element";
import HeadBreadcrumbs from "../breadcrumbs/breadcrumbs";

function ApplicationMain(props) {
  const [whichPage, setWhichPage] = useState(0);

  const callback = (index) => {
    setWhichPage(index);
  };
  return (
    <>
      <MainContainer active={props.toggle}>
        {/* {whichPage === "leadList" && ( */}
        <>
          <HeadBreadcrumbs
            head="home"
            sechead="Applications"
            first="Applications"
            main={
              whichPage === 0
                ? "All Applications"
                : whichPage === 1
                ? "Enrolled"
                : whichPage === 2
                ? "Declaration Aprroval"
                : null
            }
          />
          <BasicTabs
            tab1Name="All Applications"
            // tab2Name="Partner Leads "
            tab2Name="Enrolled"
            tab3Name="Declaration Aprroval"
            // tab5Name="Deferral"
            // tab6Name="Refer&Earn"
            tab1={props.tab1}
            tab2={props.tab2}
            tab3={props.tab3}
            // tab3={props.tab3}
            // tab4={props.tab4}
            head="Home"
            sechead="lead"
            callback={callback}
          />
        </>
        {/* )} */}
      </MainContainer>
    </>
  );
}

export default ApplicationMain;
