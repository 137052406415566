import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useState } from "react";
import { Reorder } from "@mui/icons-material";
import CustomCheckbox from "../customComponent/CustomCheckbox";

function ChoiceVerification() {
  const [selectedRows, setSelectedRows] = useState();
  const [idsFunction, setIdsFunction] = useState();
  function createData(
    verify,
    id,
    name,
    university,
    course,
    intake,
    tutionFee,
    requestedDate
  ) {
    return {
      verify,
      id,
      name,
      university,
      course,
      intake,
      tutionFee,
      requestedDate,
    };
  }
  const rows = [
    createData(
      false,
      "2654566",
      "Shabeer Muhammed",
      "Cranfield University",
      "Global Public Health",
      "Jan, 2023",
      "25250",
      "15 / 02 / 2023"
    ),
    createData(
      false,
      "2654566",
      "Shabeer Muhammed",
      "Cranfield University",
      "Global Public Health",
      "Jan, 2023",
      "25250",
      "15 / 02 / 2023"
    ),
    createData(
      false,
      "2654566",
      "Shabeer Muhammed",
      "Cranfield University",
      "Global Public Health",
      "Jan, 2023",
      "25250",
      "15 / 02 / 2023"
    ),
    createData(
      false,
      "2654566",
      "Shabeer Muhammed",
      "Cranfield University",
      "Global Public Health",
      "Jan, 2023",
      "25250",
      "15 / 02 / 2023"
    ),
    createData(
      false,
      "2654566",
      "Shabeer Muhammed",
      "Cranfield University",
      "Global Public Health",
      "Jan, 2023",
      "25250",
      "15 / 02 / 2023"
    ),
  ];

  const handleSelectRow = (rowId) => {
    console.log(rowId);
    let updatedSelectedRows;
    if (selectedRows.includes(rowId)) {
      updatedSelectedRows = selectedRows.filter((id) => id !== rowId);
      setSelectedRows(selectedRows.filter((id) => id !== rowId));
    } else {
      updatedSelectedRows = [...selectedRows, rowId];
      setSelectedRows(updatedSelectedRows);
    }
    setIdsFunction(updatedSelectedRows);
  };

  return (
    <Box
      sx={{
        width: "100%",
        overflowX: "scroll",
        "&::-webkit-scrollbar": {
          display: "none",
        },
      }}
    >
      <Table
        sx={{
          "& .MuiTableCell-root": {
            borderBottom: "1px solid rgba(220, 223, 227, 0.50)",
          },
        }}
      >
        <TableHead
          sx={{
            background: "#F9F9FB",
            "& th": {
              textTransform: "uppercase",
              fontWeight: "600",
              fontFamily: "Inter",
              color: "#666E7D",
            },
          }}
        >
          <TableRow>
            <TableCell>Verify</TableCell>
            <TableCell>
              Student ID <Reorder />
            </TableCell>
            <TableCell>
              Name <Reorder />
            </TableCell>
            <TableCell>
              University <Reorder />
            </TableCell>
            <TableCell>
              Course <Reorder />
            </TableCell>
            <TableCell>
              Intake <Reorder />
            </TableCell>
            <TableCell>
              Tuition fee <Reorder />
            </TableCell>
            <TableCell>
              Requested date <Reorder />
            </TableCell>

            <TableCell>{""}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody
          sx={{
            background: "#fff",
            border: "1px solid rgba(220, 223, 227, 0.50)",
          }}
        >
          {rows.map((row, i) => (
            <TableRow key={i}>
              <TableCell>
                {" "}
                <CustomCheckbox
                  value={row.verify}
                  handleChange={() => handleSelectRow(i)}
                />
              </TableCell>
              <TableCell>{row.id}</TableCell>
              <TableCell>{row.name}</TableCell>
              <TableCell>{row.university}</TableCell>
              <TableCell>{row.course}</TableCell>
              <TableCell>{row.intake}</TableCell>
              <TableCell>{row.tutionFee}</TableCell>
              <TableCell>{row.requestedDate}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
}

export default ChoiceVerification;
