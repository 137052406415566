import React, { useEffect, useMemo, useState } from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableFooter,
  TableSortLabel,
  Menu,
  Paper,
  Dialog,
  FormHelperText,
  Button,
  FormControl,
  TextField,
} from "@mui/material";

import { InputLabel, makeStyles, Select } from "@material-ui/core";

import { SlOptionsVertical } from "react-icons/sl";
import {
  Box,
  Checkbox,
  Grid,
  MenuItem,
  Modal,
  TableContainer,
  Typography,
} from "@mui/material";
import { TbEdit } from "react-icons/tb";
import { AiOutlineEye } from "react-icons/ai";
import { RiDeleteBin6Line } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { roleDeleteAction } from "../../actions/roleMangmentAction";
import { useDispatch, useSelector } from "react-redux";

import "./Table.css";
import { deleteEmployeeAction } from "../../actions/employeeManagmentAction";
import {
  deleteUniversityMasterAction,
  findMasterbyTypeIDAction,
  universityMasterEditAction,
} from "../../actions/universityActions/universityMasterManagmentAction";
import { Close } from "@mui/icons-material";
import AccessDeniedIcon from "../Leads/createLeads/AccessDeniedIcon";
import usePrivilegeCheck from "../../constants/customHooks/UsePrivilegeCheck";
import { TypographyText } from "../customComponent/Typography";
import { OutLinedButton, SubmitButton } from "../customComponent/Buttons";
import { SelectInputField } from "../customComponent/InputField";
import { successTrueMsgAction } from "../../actions/globalAction";

const useStyles = makeStyles((theme) => ({
  table: {
    borderCollapse: "separate",
    border: "1px solid #ececec",
    padding: 0, // Set padding to 0 to remove excessive padding
    "& th, td": {
      padding: "4px", // Add desired padding to individual cells
    },
  },
  tableCell: {
    backgroundColor: "#fafafa",
    color: "#9499A4",
    fontFamily: "'Akatab', sans-serif",
    fontSize: "-16px",
    fontWeight: 500,
    height: "20px",
  },

  tableEachCell: {
    height: "18px",
  },
  headerText: {
    fontSize: "13px",
  },
  tableRow: {
    "& > *": {
      border: "none",
    },
    height: "8px",
  },

  customIcon: {
    fontSize: theme.typography.fontSize,
  },
}));

const useStyles2 = makeStyles((theme) => ({
  formControl: {
    marginBottom: theme.spacing(2),
    marginTop: "8px",
  },
  hoverElement: {
    "&:hover": {
      color: "#FE0B7A", // Set the hover color
    },
    "&.clicked": {
      color: "#FE0B7A", // Set the clicked color
    },
  },
}));

// show role details popup
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 550,
  height: 600,
  overflow: "scroll",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 20,
  p: 4,
};

export const TableWithoutCoulumnVisible = ({
  columns,
  data,
  findData,
  component,
  showUrl,
  subMenu,
  Clicked,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const classes = useStyles();
  const classes2 = useStyles2();

  const { privilege, hasPrivilege, isDeletePrevilage } = usePrivilegeCheck();

  let { universityMasterDeleteByIdSuccess } = useSelector((state) => {
    return state.deleteUniversityMaster;
  });

  console.log(data, "datadatadata");

  useEffect(() => {
    if (tableId) {
      dispatch(findMasterbyTypeIDAction(tableId));
    }
  }, [universityMasterDeleteByIdSuccess]);

  const [actionButtonShow, setActionButtonShow] = useState(null);
  const [tableId, setTableId] = useState("");
  const [roleData, setRoleData] = useState(findData);
  const [popupData, setPopupData] = useState({});
  const [open, setOpen] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [universityMasterEdit, setUniversityMasterEdit] = useState(false);
  const [file, setFile] = useState(null);
  const [universityMaster, setUniversityMaster] = useState("");
  const [masterName, setMasterName] = useState("");
  const [icon, setIcon] = useState("");
  const [errors, setErrors] = useState({});

  const openActionButton = Boolean(actionButtonShow);

  const handleClickAction = (event, id, item) => {
    console.log(item, "itemitemitemitemitem");
    setMasterName(item?.Column1);
    setIcon(item?.iconUrl);
    setUniversityMaster(item?.clickedId);
    setActionButtonShow(event.currentTarget);
    setTableId(id);
  };

  const handleCloseAction = () => {
    setActionButtonShow(null);
  };

  // navigate to Edit page
  const rollEditHandler = (tableId) => {
    if (component !== "universityMaster") {
      navigate(`/Administration/editRole/${tableId}`);
    }
    if (component === "universityMaster") {
      setUniversityMasterEdit(true);
    }
  };

  const handleOpen = (id) => {
    if (component === "employee") {
      navigate(`${showUrl}/${id}`);
    } else {
      let arrData;
      if (roleData.some((obj) => obj.id === id)) {
        arrData = roleData.find((obj) => obj.id === id);
      }

      console.log(roleData, "arrDataarrDataarrDataarrData");
      setPopupData(arrData);
      setOpen(true);
    }
  };

  // sorting table
  const [sortBy, setSortBy] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");

  const requestSort = (column) => {
    let newSortOrder = "asc";

    if (column === sortBy && sortOrder === "asc") {
      newSortOrder = "desc";
    }

    setSortBy(column);
    setSortOrder(newSortOrder);
  };

  let sortedData = useMemo(() => {
    if (sortBy && data) {
      const sortedItems = [...data].sort((a, b) => {
        if (sortOrder === "asc") {
          return a[sortBy] < b[sortBy] ? -1 : 1;
        } else {
          return a[sortBy] < b[sortBy] ? 1 : -1;
        }
      });

      return sortedItems;
    }

    return data;
  }, [data, sortBy, sortOrder]);

  // HandleIconChange in University masterEdit popUp

  const handleIconChange = (event) => {
    if (event?.target?.files) {
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      }).then((base64Array) => {
        setIcon(base64Array);
      });
    }
  };

  // Reset function

  const handleReset = () => {
    setUniversityMaster("");
    setFile(null);
    setMasterName("");
    setErrors({});
  };

  // submit function for edit

  const handleSubmit = (event) => {
    event.preventDefault();
    const errors = validateForm();
    if (Object.keys(errors).length === 0) {
      dispatch(
        universityMasterEditAction(universityMaster, masterName, icon, tableId)
      );
      dispatch(successTrueMsgAction(true));
      setUniversityMasterEdit(false);
    } else {
      setErrors(errors);
    }
  };

  const validateForm = () => {
    const errors = {};

    // Validate Name
    if (!masterName) {
      errors.masterName = "Name is required";
    }

    // Validate File
    if (!icon) {
      errors.file = "File is required";
    }

    return errors;
  };

  return (
    <>
      <Table className={classes.table}>
        <TableHead
          sx={{
            background: "#F9F9FB",
            "& th": {
              fontWeight: "700",
              textTransform: "uppercase",
            },
          }}
        >
          <TableRow>
            {columns?.map((column) => {
              if (column.hidden) {
                return null;
              }
              return (
                <TableCell
                  key={column.field}
                  className="tableCell"
                  sx={{
                    p: "10px 20px !important",
                  }}
                >
                  <TableSortLabel
                    className="headerText"
                    active={column === sortBy}
                    direction={sortOrder}
                    onClick={() => requestSort(column)}
                  >
                    {column.title}
                  </TableSortLabel>
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody sx={{}}>
          {sortedData?.map((item, indx) => (
            <TableRow
              className={classes.tableRow}
              key={indx}
              sx={{
                "& td": {
                  p: "10px 20px !important",
                },
              }}
            >
              <TableCell>{item.sl}</TableCell>
              {component == "universityMaster" && (
                <TableCell>{item.masterID ? item.masterID : "-"}</TableCell>
              )}

              <TableCell>{item.Column1}</TableCell>

              <TableCell>{item.Column2}</TableCell>

              <TableCell>
                <SlOptionsVertical
                  id="demo-positioned-button"
                  aria-controls={
                    openActionButton ? "demo-positioned-menu" : undefined
                  }
                  aria-haspopup="true"
                  aria-expanded={openActionButton ? "true" : undefined}
                  onClick={(e) => {
                    handleClickAction(e, item.id, item);
                  }}
                  variant="outlined"
                  style={{ marginLeft: "10px", color: "#F80B7A" }}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter />
      </Table>

      {/* TABLE ACTION MENU START*/}
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={actionButtonShow}
        open={openActionButton}
        onClose={handleCloseAction}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        sx={{
          borderColor: "black",
          borderRadius: "24px",
          marginLeft: "-20px",
          mt: "15px",
        }}
      >
        <Box style={{ display: "flex", justifyContent: "flex-end" }}>
          <MenuItem>
            <TbEdit
              onClick={() => rollEditHandler(tableId)}
              className="icons"
            />
          </MenuItem>
          {component !== "universityMaster" && (
            <MenuItem>
              <AiOutlineEye
                onClick={() => handleOpen(tableId)}
                className="icons"
              />
            </MenuItem>
          )}

          <MenuItem>
            <RiDeleteBin6Line
              onClick={() => {
                if (isDeletePrevilage(subMenu)) {
                  setActionButtonShow(null);
                  Swal.fire({
                    title: "Are you sure?",
                    text: "You won't be able to revert this!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes, delete it!",
                  }).then((result) => {
                    if (result.isConfirmed) {
                      dispatch(deleteUniversityMasterAction(tableId));
                      setActionButtonShow(false);
                      Swal.fire(
                        "Deleted!",
                        "Your file has been deleted.",
                        "success"
                      );
                    }
                  });
                } else {
                  setOpenAlert(true);
                }
              }}
            />
          </MenuItem>
        </Box>
      </Menu>
      {/* {/*TABLE ACTION MENU END */}

      {/*ROLE DETAILS POPUP START  */}
      {/* <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Role Name :{popupData.roleName}
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 2 }}
            variant="h6"
            component="h2"
          >
            Description : {popupData.roleDescription}
          </Typography>
          <Grid
            container
            spacing={2}
            sx={{ height: "500px", overflow: "scroll" }}
          >
            <Grid item lg={12} xs={12}>
              <Paper>
                <TableContainer>
                  <Table>
                    <TableHead sx={{ backgroundColor: "#F9F9FB" }}>
                      <TableRow>
                        <TableCell>Category Name</TableCell>
                        <TableCell>update</TableCell>
                        <TableCell>view</TableCell>
                        <TableCell>Delete</TableCell>
                        <TableCell>All</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </Grid>
          </Grid>
        </Box>
      </Modal> */}
      {/*ROLE DETAILS POPUP END*/}

      <Dialog
        open={openAlert}
        onClose={() => setOpenAlert(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          zIndex: "9999",
          // background:"red",
          "& .MuiDialog-paper": {
            width: "100%",
            borderRadius: "5px",
            p: "15px",
            textAlign: "center",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "end",
          }}
        >
          <Close
            sx={{
              cursor: "pointer",
            }}
            onClick={() => setOpenAlert(false)}
          />
        </Box>
        <Typography
          sx={{
            color: "#05050F",
            fontSize: "26px",
            fontWeight: "700",
          }}
        >
          Access Denied
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            mt: "-10px",
          }}
        >
          <AccessDeniedIcon />
        </Box>
        <Typography
          sx={{
            color: "#717171",
            fontSize: "16px",
            mb: "10px",
          }}
        >
          Sorry, you don’t have permission
        </Typography>
      </Dialog>

      <Dialog
        open={universityMasterEdit}
        onClose={() => setUniversityMasterEdit(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          zIndex: "9999",
          // background:"red",
          "& .MuiDialog-paper": {
            width: "100%",
            borderRadius: "5px",
            p: "15px",
            textAlign: "center",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "end",
          }}
        >
          <Close
            sx={{
              cursor: "pointer",
            }}
            onClick={() => setUniversityMasterEdit(false)}
          />
        </Box>
        <Box>
          <Paper elevation={0} sx={{ p: 2.5, border: "0.5px solid #ECECEC" }}>
            <TypographyText title="University Master Edit" variant="h6" />
            <form onSubmit={handleSubmit} style={{ margin: "9px" }}>
              <FormControl className={classes.formControl} fullWidth>
                <TextField
                  variant="outlined"
                  label="Name"
                  id="name"
                  value={masterName}
                  onChange={(e) => setMasterName(e.target.value)}
                  error={!!errors.masterName}
                  helperText={errors.masterName}
                  style={{
                    height: "50px",
                    fontSize: "14px",
                    fontFamily: "'Open Sans', sans-serif",
                    marginBottom: "10px",
                    marginTop: "20px",
                  }}
                  InputLabelProps={{
                    style: {
                      fontSize: "12px",
                      fontWeight: 600,
                    },
                  }}
                  InputProps={{
                    style: {
                      fontSize: "13px",
                      height: "48px",
                    },
                  }}
                />
              </FormControl>

              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: "15px",
                  fontFamily: "'Open Sans', sans-serif",
                  color: "rgba(172, 177, 198, 1)",
                  "& span": {
                    color: "#f44336",
                  },
                }}
              >
                Choose Icon <span>*</span>
              </Typography>

              <FormControl
                className={classes.formControl}
                fullWidth
                style={{
                  minHeight: "65px",
                  border: "0.7px dashed #ECECEC",
                  minWidth: "150px",
                  padding: "25px",
                  align: "center",
                  boxSizing: "border-box",
                  width: "inline",
                }}
              >
                {icon ? (
                  <Box
                    component="label"
                    sx={{
                      width: "200px",
                      // height: "400px",
                      "& img": {
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        display: "block",
                      },
                    }}
                  >
                    <img
                      style={{ width: "41px", height: "41px" }}
                      src={icon}
                      alt="gsl"
                    />
                    <input
                      style={{
                        height: "42px",
                        width: "1px",
                      }}
                      type="file"
                      hidden
                      onChange={handleIconChange}
                    />
                  </Box>
                ) : (
                  <Button
                    component="label"
                    variant="contained"
                    style={{
                      height: "32px",
                      width: "90px",
                      borderRadius: "4px",

                      padding: "10px",
                      margin: "18px",
                      boxShadow: "none",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "10px",
                        fontWeight: 500,
                        fontFamily: "'Poppins', sans-serif",
                      }}
                    >
                      {" "}
                      Choose File
                    </Typography>
                    <input
                      style={{
                        height: "42px",
                        width: "1px",
                      }}
                      type="file"
                      hidden
                      onChange={(e) => setIcon(e.target.files)}
                    />
                  </Button>
                )}
                {errors.file && (
                  <FormHelperText error>{errors.file}</FormHelperText>
                )}
              </FormControl>

              <Typography
                sx={{
                  color: "#ACB1C6",
                  fontSize: "12px",
                  width: "100%",
                }}
              >
                {" "}
                Recommended size: 100x100 pixels
              </Typography>

              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  // margin: "-22px",
                  // marginTop: "1px",
                }}
              >
                {/* <Button
                variant="outlined"
                style={{
                  color: "#141E3C",
                  marginRight: "13px",
                  borderRadius: "8px",
                  borderColor: "#141E3C",
                  width: "67px",
                  height: "auto",
                  fontWeight: 600,
                  fontSize: "8px",
                  fontFamily: "'Open Sans', sans-serif",
                }}
                onClick={handleReset}
              >
                Reset
              </Button> */}

                {/* <OutLinedButton
                  title="Reset"
                  // handleClick={handleReset}
                  widthSize="67px"
                  heightSize="auto"
                /> */}
                {/* <Button
                type="submit"
                style={{
                  color: "white",
                  backgroundColor: "#141E3C",
                  borderRadius: "8px",
                  width: "67px",
                  height: "auto",
                  fontWeight: 600,
                  fontSize: "8px",
                  fontFamily: "'Open Sans', sans-serif",
                }}
                variant="contained"
                color="primary"
              >
                Save
              </Button> */}
                <Box m={1}></Box>

                <SubmitButton
                  title="Save"
                  submit="submit"
                  widthSize="67px"
                  heightSize="auto"
                  type="Update"
                />
              </div>
            </form>
          </Paper>
        </Box>
      </Dialog>
    </>
  );
};
