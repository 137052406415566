import React, { useEffect, useState } from "react";
import io from "socket.io-client";

function useNotification() {
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    const socket = io("http://localhost:9000", {
      transports: ["websocket"],
    });

    socket.on("connect", () => {
      console.log("Connected to WebSocket server notification");
    });

    socket.on("notification", (notification) => {
      console.log("Received notification:", notification);
      setNotifications((prevNotifications) => [
        ...prevNotifications,
        notification,
      ]);
    });
    
    
    return () => {
      socket.disconnect();
    };
  }, []);

  return notifications;
}

export default useNotification;
