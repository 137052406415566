import {
  Avatar,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  ieltsTeacherManagmentFindByIdAction,
  ieltsTeacherManagmentStatusChangeAction,
} from "../../../actions/IeltsActions/teacherManagmentAction";

function IeltsTeacherDetailedView() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [ieltsTeacherDetails, setIeltsTeacherDetails] = useState("");

  let {
    ieltsTeacherManagmentTeacherFindByIdSuccess,
    ieltsTeacherManagmentTeacherFindByIderror,
  } = useSelector((state) => {
    return state.ieltsTeacherManagmentTeacherFindById;
  });

  let {
    ieltsTeacherManagmentTeacherStatusChangeSuccess,
    ieltsTeacherManagmentTeacherStatusChangeerror,
  } = useSelector((state) => {
    return state.ieltsTeacherManagmentTeacherStatusChange;
  });

  useEffect(() => {
    dispatch(ieltsTeacherManagmentFindByIdAction(id));
  }, [dispatch, ieltsTeacherManagmentTeacherStatusChangeSuccess]);

  useEffect(() => {
    setIeltsTeacherDetails(ieltsTeacherManagmentTeacherFindByIdSuccess?.data);
  }, [ieltsTeacherManagmentTeacherFindByIdSuccess]);

  console.log(
    ieltsTeacherManagmentTeacherFindByIdSuccess,
    "ieltsTeacherManagmentTeacherFindByIdSuccessieltsTeacherManagmentTeacherFindByIdSuccess"
  );

  // Handle statusChange

  const handleStatus = (status, id) => {
    dispatch(ieltsTeacherManagmentStatusChangeAction(status, id));
  };
  return (
    <>
      <Box
        sx={{
          display: "flex",
          border: "1px solid #ECECEC",
          //   gap: 10,
          pt: 2,
          pb: 2,
          // flexWrap:"wrap"
        }}
      >
        <Box
          sx={{
            p: 3,
            display: "flex",
            justifyContent: "space-between",
            gap: "100px",
            borderRight: "1px solid #ECECEC",
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: "20px",
              pr: 4,
              flexDirection: "column",
              borderRight: "1px solid #ECECEC",
            }}
          >
            <Avatar
              src={ieltsTeacherDetails?.imageUrl}
              alt="Avatar"
              sx={{
                width: 120,
                height: 120,
                backgroundColor: "#F4F4F4",
                m: 1.5,
              }}
            >
              {!ieltsTeacherDetails?.imageUrl && (
                <label htmlFor="avatar-file">
                  <img
                    src="/menuIcons/fileUploadCenter.png"
                    alt="gsl"
                    style={{
                      width: 35,
                      height: 35,
                      cursor: "pointer",
                    }}
                  />
                </label>
              )}
            </Avatar>
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: "14px",
                fontFamily: "'Poppins', sans-serif",
                textAlign: "center",
                mt: -3,
              }}
            >
              {ieltsTeacherDetails?.teacherName}
            </Typography>
          </Box>

          <Box sx={{ display: "flex", gap: "20px", flexDirection: "column" }}>
            <Box>
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: "14px",
                  fontFamily: "'Poppins', sans-serif",
                }}
              >
                Phone Number
              </Typography>
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: "12px",
                  fontFamily: "'Poppins', sans-serif",
                  color: "#ACB1C6",
                }}
              >
                {ieltsTeacherDetails?.teacherPhone}
              </Typography>
            </Box>
            <Box>
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: "14px",
                  fontFamily: "'Poppins', sans-serif",
                }}
              >
                Email
              </Typography>
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: "12px",
                  fontFamily: "'Poppins', sans-serif",
                  color: "#ACB1C6",
                }}
              >
                {ieltsTeacherDetails?.teacherEmail}
              </Typography>
            </Box>
            <Box>
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: "14px",
                  fontFamily: "'Poppins', sans-serif",
                }}
              >
                Specialization
              </Typography>
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: "12px",
                  fontFamily: "'Poppins', sans-serif",
                  color: "#ACB1C6",
                  // maxWidth:"150px",
                  // overflow:"scroll"
                }}
              >
                {ieltsTeacherDetails?.teacherSpecialization}
              </Typography>
            </Box>
          </Box>
          <Box sx={{ display: "flex", gap: "20px", flexDirection: "column" }}>
            <Box>
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: "14px",
                  fontFamily: "'Poppins', sans-serif",
                }}
              >
                Pan
              </Typography>
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: "12px",
                  fontFamily: "'Poppins', sans-serif",
                  color: "#ACB1C6",
                }}
              >
                {ieltsTeacherDetails?.teacherPanNo}
              </Typography>
            </Box>
            <Box>
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: "14px",
                  fontFamily: "'Poppins', sans-serif",
                }}
              >
                Aadhar
              </Typography>
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: "12px",
                  fontFamily: "'Poppins', sans-serif",
                  color: "#ACB1C6",
                }}
              >
                {ieltsTeacherDetails?.teacherAadharNo}
              </Typography>
            </Box>
            <Box>
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: "14px",
                  fontFamily: "'Poppins', sans-serif",
                }}
              >
                Joined Date
              </Typography>
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: "12px",
                  fontFamily: "'Poppins', sans-serif",
                  color: "#ACB1C6",
                }}
              >
                {ieltsTeacherDetails?.createdAt
                  ? ieltsTeacherDetails?.createdAt.split("T")[0]
                  : "Nil"}
              </Typography>
            </Box>
          </Box>
          <Box sx={{ display: "flex", gap: "20px", flexDirection: "column" }}>
            <Box>
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: "14px",
                  fontFamily: "'Poppins', sans-serif",
                }}
              >
                Teacher Status
              </Typography>
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: "12px",
                  fontFamily: "'Poppins', sans-serif",
                  color:
                    ieltsTeacherDetails?.status == "Inactive" ? "red" : "green",
                }}
              >
                {ieltsTeacherDetails?.status
                  ? ieltsTeacherDetails?.status
                  : "Nil"}
              </Typography>
            </Box>
            <Box>
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: "14px",
                  fontFamily: "'Poppins', sans-serif",
                }}
              >
                Username
              </Typography>
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: "12px",
                  fontFamily: "'Poppins', sans-serif",
                  color: "#ACB1C6",
                }}
              >
                {ieltsTeacherDetails?.teacherUserName}
              </Typography>
            </Box>
            <Box>
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: "14px",
                  fontFamily: "'Poppins', sans-serif",
                }}
              >
                Password
              </Typography>
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: "12px",
                  fontFamily: "'Poppins', sans-serif",
                  color: "#ACB1C6",
                }}
              >
                {ieltsTeacherDetails?.Password}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            p: 3,
            display: "flex",
            justifyContent: "space-between",
            gap: "20px",
          }}
        >
          <Box sx={{ display: "flex", gap: "5px", flexDirection: "column" }}>
            <Box>
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: "14px",
                  fontFamily: "'Poppins', sans-serif",
                }}
              >
                Center
              </Typography>
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: "12px",
                  fontFamily: "'Poppins', sans-serif",
                  color: "#ACB1C6",
                }}
              >
                {ieltsTeacherDetails?.center
                  ? ieltsTeacherDetails?.center
                  : "Not assigned"}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default IeltsTeacherDetailedView;
