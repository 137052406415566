import React, { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import {
  Dateinputfield,
  SearcInutfield,
  SelectInputField2,
} from "../customComponent/InputField";
import { SubmitButton } from "../customComponent/Buttons";
import { useDispatch, useSelector } from "react-redux";
import { findBranchMasterAction } from "../../actions/branchMasterActions";

function HrSearchHeader({
  handleClickSubmit,
  searchEmployeeList,
  handleSelectChange,
  selectedBranchValue,
}) {
  const dispatch = useDispatch();

  const [allBranch, setAllBranch] = useState([]);
  const [dateValue, setDateValue] = useState("");

  let { branchMasterFindSuccess } = useSelector((state) => {
    return state.findBranchMaster;
  });

  useEffect(() => {
    dispatch(findBranchMasterAction());
  }, [dispatch]);

  useEffect(() => {
    if (branchMasterFindSuccess) {
      setAllBranch(branchMasterFindSuccess);
    }
  }, [branchMasterFindSuccess]);

  const data = allBranch.map((branch, index) => ({
    id: branch.id,
    branchName: branch.branchName,
  }));

  // const handleSelectChange = (event) => {
  //   const newValue = event.target.value;
  //   setSelectedBranchValue(newValue);
  // };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent:"space-between",
        gap:"30px",
        alignItems: "center",
        mt:"-40px"
      }}
    >
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          gap:"15px",
        }}
      >
        <SearcInutfield handleOnchange={searchEmployeeList} />

        {/* List branches */}

        <SelectInputField2
          options={data}
          defaultValue="All Branches"
          handleSelectChange={handleSelectChange}
          value={selectedBranchValue}
        />
      </Box>
      {/* <Grid item xs={4} lg={2} style={{ marginLeft: "-30px" }}>
        <Dateinputfield
          handleDateChange={(event) => {
            // let selectedDate = new Date(event.target.value);
            setDateValue(event.target.value);
          }}
          dateValue={dateValue}
        />
      </Grid> */}

      <SubmitButton
        title="Submit"
        submit=""
        widthSize="136px"
        heightSize="38px"
        type="click"
        handleSubmit={() => handleClickSubmit(selectedBranchValue, dateValue)}
      />
    </Box>
  );
}

export default HrSearchHeader;
