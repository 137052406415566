import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Typography,
} from "@mui/material";
import React, { useRef, useState } from "react";
import { OutLinedButton, SubmitButton } from "../../customComponent/Buttons";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { courseMasterCreateAction, subjectMasterCreateAction } from "../../../actions/courseMasterAction";
import { successTrueMsgAction } from "../../../actions/globalAction";

function SubjectMajorAdd({ handleClose, open }) {
  const dispatch = useDispatch();
  const [scroll, setScroll] = useState("paper");
  const [selectedFile, setSelectedFile] = useState(null);
  const [name, setName] = useState("");

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setSelectedFile(selectedFile);
  };

  const getFileType = (file) => {
    const fileParts = file.name.split(".");
    return fileParts[fileParts.length - 1].toLowerCase();
  };

  const handleCreate = () => {
    dispatch(subjectMasterCreateAction(name));
    dispatch(successTrueMsgAction(true));
    handleClose();
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        PaperProps={{
          sx: {
            width: "850px",
            marginTop: "4%",
          },
        }}
      >
        <DialogTitle id="scroll-dialog-title">
          {" "}
          <Typography
            variant="h5"
            sx={{
              mt: "18px",
              fontWeight: 700,
              fontSize: "24px",
              fontFamily: "Montserrat', sans-serif",
            }}
          >
            Subject Major
          </Typography>
        </DialogTitle>

        <DialogContent>
          <Box
            sx={{
              "& input": {
                border: "1px solid #ECECEC",
                padding: "16px",
                width: "100%",
                boxSizing: "border-box",
                color: "#05050F",
                outline: "none !important",
                borderRadius: "4px",
              },
              "& input::placeholder": {
                border: "#ECECEC",
              },
            }}
          >
            <Typography
              sx={{
                color: "#05050F",
                mb: "10px",
                fontSize: "16px",
                lineHeight: "24px",
              }}
            >
              Subject major name
            </Typography>
            <input
              placeholder="Subject major name"
              onChange={(e) => setName(e.target.value)}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <SubmitButton
            title="Create"
            submit=""
            widthSize="81px"
            heightSize="35px"
            handleSubmit={handleCreate}
            type="click"
          />
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default SubjectMajorAdd;
