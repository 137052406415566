import React, { useEffect, useState } from "react";
import { MdClear } from "react-icons/md";
import { AiOutlineSearch } from "react-icons/ai";

import ListSubheader from "@mui/material/ListSubheader";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import StarBorder from "@mui/icons-material/StarBorder";
import { countries } from "countries-list";

import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputAdornment,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { RiSearch2Line } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { entrolledStudentsFindAction } from "../../../actions/IeltsActions/entrolledStudentsAction";
// import { findcountryMasterAddAction } from "../../actions/countryMasterAction";

function SlotFilterSearch(props) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [secListopen, setSecListOpen] = useState(false);
  const [thirdListopen, setThirdListOpen] = useState(false);
  const [fourthListopen, setFourthListOpen] = useState(false);
  const [fifthListopen, setFifthListOpen] = useState(false);
  const [sixthListopen, setSixthListOpen] = useState(false);
  const [sevenListopen, seventhListOpen] = useState(false);
  //   const [cityFind, setCityFind] = useState("");
  const [centerList, setCenterList] = useState([""]);
  const [specializationList, setSpecializationList] = useState([
    "Reading",
    "Writing",
    "Listening",
    "Speaking",
    "Grammer",
  ]);
  const [statusList, setStatusList] = useState(["active", "inactive"]);
  const [selectedCountry, setSelectedCountry] = useState(null);

  const handleClick = () => {
    setOpen(!open);
  };
  const handleClick2 = () => {
    setSecListOpen(!secListopen);
  };
  const handleClick3 = () => {
    setThirdListOpen(!thirdListopen);
  };
  const handleClick4 = () => {
    setFourthListOpen(!fourthListopen);
  };
  const handleClick5 = () => {
    setFifthListOpen(!fifthListopen);
  };
  const handleClick6 = () => {
    setSixthListOpen(!sixthListopen);
  };
  const handleClick7 = () => {
    seventhListOpen(!sevenListopen);
  };

  const handleClearClick = () => {
    props.handleResetData(true);
    setSelectedCountry("");
    setSecListOpen(false);
    setThirdListOpen(false);
    setFourthListOpen(false);
  };

  //   const handleChange = (e) => {
  //     const searchText = e.target.value.toLowerCase();

  //     if (searchText === "") {
  //       setCountryList(countryFindSuccess); // Reset to the original data when search text is empty
  //     } else {
  //       setCountryList((chips) =>
  //         chips.filter((data) =>
  //           data.countryName.toLowerCase().includes(searchText)
  //         )
  //       );
  //     }
  //   };


  const handleCheck = (event, specialization, CheckedValue) => {
    if (CheckedValue == "date") {
      props.LeadFilterFunction(event.target.value, "date");
    }
    if (CheckedValue == "status") {
      setSelectedCountry(
        specialization === selectedCountry ? null : specialization
      );
      props.LeadFilterFunction(specialization, "status", event.target.checked);
    }
  };


  // country

  return (
    <>
      <List
        sx={{
          // width:"fit-content",
          maxWidth: "316px",
          // height: "620px",
          height: "auto",
          bgcolor: "background.paper",
          borderRadius: "8px",
          backgroundColor: "rgba(255, 255, 255, 0.5)",
          // overflow: "scroll",
          // overflowX: "hidden",
          border: "1px solid #ECECEC",
          mt: 2.5,
        }}
        component="nav"
        aria-labelledby="nested-list-subheader"
        subheader={
          <ListSubheader
            component="div"
            id="nested-list-subheader"
            sx={{ backgroundColor: "#F2F2F2" }}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>Filter</div>
              <div
                style={{
                  display: "flex",
                  marginTop: "12px",
                  cursor: "pointer",
                }}
                onClick={handleClearClick}
              >
                <MdClear style={{ fontSize: "22px", height: "20px" }} />{" "}
                <Typography>Clear All</Typography>
              </div>
            </div>
          </ListSubheader>
        }
      >
        <>
          <ListItemButton onClick={handleClick2}>
            <ListItemText primary="Date" />
            {secListopen ? (
              <ExpandLess sx={{ fontSize: "17px", fontWeight: "thin" }} />
            ) : (
              <ExpandMore sx={{ fontSize: "17px", fontWeight: "thin" }} />
            )}
          </ListItemButton>

          <Collapse in={secListopen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemButton>
                <input
                  type="date"
                  onChange={(e) => handleCheck(e, null, "date")}
                  style={{ width: "100%" }}
                ></input>
              </ListItemButton>
            </List>
          </Collapse>

          <ListItemButton onClick={handleClick4}>
            <ListItemText primary="Status" />
            {fourthListopen ? (
              <ExpandLess sx={{ fontSize: "17px", fontWeight: "thin" }} />
            ) : (
              <ExpandMore sx={{ fontSize: "17px", fontWeight: "thin" }} />
            )}
          </ListItemButton>

          <Collapse in={fourthListopen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemButton>
                <FormGroup>
                  {statusList &&
                    statusList?.map((status) => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            sx={{
                              color: "#F8469A",
                              "&.Mui-checked": {
                                color: "#F8469A",
                              },
                            }}
                            checked={status === selectedCountry}
                            onChange={(e) => {
                              handleCheck(e, status, "status");
                            }}
                          />
                        }
                        label={status ? status : "No Cities to Show"}
                      />
                    ))}
                </FormGroup>
              </ListItemButton>
            </List>
          </Collapse>
        </>
      </List>
    </>
  );
}

export default SlotFilterSearch;
