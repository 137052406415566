import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Chip,
  Divider,
  Grid,
  Modal,
  Stack,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";

import FormatBoldIcon from "@mui/icons-material/FormatBold";
import FormatItalicIcon from "@mui/icons-material/FormatItalic";
import FormatUnderlinedIcon from "@mui/icons-material/FormatUnderlined";
import StrikethroughSIcon from "@mui/icons-material/StrikethroughS";
import FormatColorTextIcon from "@mui/icons-material/FormatColorText";
import InvertColorsIcon from "@mui/icons-material/InvertColors";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import CancelIcon from "@mui/icons-material/Cancel";

import { useDispatch, useSelector } from "react-redux";

import { countryDocumentAdmissionApplicationFindAction } from "../../../actions/knowledgeBankActions/CountryDocsActions";
import { findUniversityAction } from "../../../actions/universityActions/universityManagmentAction";
import { findcountryMasterAddAction } from "../../../actions/countryMasterAction";
import InputField from "../../customComponent/InputField";
import { MainContainer } from "../../content/content.element";
import { InfoHeader } from "../../customComponent/InfoHeader";
import { SubmitButton } from "../../customComponent/Buttons";
import { Editor, EditorState, RichUtils } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import { newsCreateAction } from "../../../actions/LandingPageAction";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "400",
  height: "400",
  //   overflow: "scroll",
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 20,
  p: 4,
};

function CreateNews(props) {
  const dispatch = useDispatch();
  const [selectedStyle, setSelectedStyle] = useState("NORMAL");
  const [bgColor, setBgColor] = useState("transparent");
  const [textColor, setTextColor] = useState("black");
  const [formats, setFormats] = React.useState(() => ["bold", "italic"]);

  const [DestinationimageValue, setDestinationimageValue] = useState("");
  const [title, setTitle] = useState("");
  const [category, setCategory] = useState("");
  const [addedCategory, setAddedCategory] = useState([]);
  const [tags, setTags] = useState("");
  const [enteredTags, setEnteredTags] = useState([]);
  const [newsImage, setNewsImage] = useState();
  const newsimage = useRef();

  const [description, setDescription] = useState(() =>
    EditorState.createEmpty()
  );

  //   DESCRIPTION STYLE
  const handleBoldClick = () => {
    setDescription(RichUtils.toggleInlineStyle(description, "BOLD"));
  };

  const handleItalicClick = () => {
    setDescription(RichUtils.toggleInlineStyle(description, "ITALIC"));
  };

  const handleUnderlineClick = () => {
    setDescription(RichUtils.toggleInlineStyle(description, "UNDERLINE"));
  };

  const handleStrikethroughClick = () => {
    setDescription(RichUtils.toggleInlineStyle(description, "STRIKETHROUGH"));
  };

  const handleBgColorChange = (color) => {
    setBgColor(color);
    setDescription(
      RichUtils.toggleInlineStyle(
        description,
        `BG_COLOR_${color.toUpperCase()}`
      )
    );
  };

  const handleTextColorChange = (color) => {
    setTextColor(color);
    setDescription(
      RichUtils.toggleInlineStyle(
        description,
        `TEXT_COLOR_${color.toUpperCase()}`
      )
    );
  };

  const handleTextAlignment = (alignment) => {
    setDescription(
      RichUtils.toggleBlockType(description, `ALIGN_${alignment.toUpperCase()}`)
    );
  };

  const handleFormat = (event, newFormats) => {
    setFormats(newFormats);
  };

  // text editor section
  const handleStyleChange = (e) => {
    const newStyle = e.target.value;

    // Toggle the new style immediately
    const newEditorState = RichUtils.toggleInlineStyle(description, newStyle);

    setSelectedStyle(newStyle);
    setDescription(newEditorState);
  };

  const handleDestinationImage = (event) => {
    const file = event.target.files[0];
    setDestinationimageValue(file.name);
    const reader = new FileReader();

    // Read the image file as a data URL
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      const UploadDestinationimage = reader.result;
      setNewsImage(UploadDestinationimage);
    };
  };

  // Handle add multiple tags

  const handleAddTags = () => {
    if (tags.trim() !== "") {
      setEnteredTags((prevEnteredTags) => [...prevEnteredTags, tags.trim()]);
      setTags(""); // Clear the input after adding a tag
    }
  };

  // handle add nultiple categories

  const handleAddCategory = () => {
    if (category.trim() !== "") {
      setAddedCategory((prevEnteredCategory) => [
        ...prevEnteredCategory,
        category.trim(),
      ]);
      setCategory(""); // Clear the input after adding a tag
    }
  };

  // HandleCreate

  const HandleCreate = () => {
    const contentState = description.getCurrentContent();

    function getInlineStyle(entity) {
      const entityKey = entity.getEntity();
      const entityInstance = contentState.getEntity(entityKey);
      const entityType = entityInstance.getType();
      if (entityType === "STYLE") {
        // Check if the entity type is 'STYLE'
        const entityData = entityInstance.getData();
        return entityData.style; // Return the style data from the entity
      }
      return null; // Return null for other entity types
    }

    const htmlContent = stateToHTML(contentState, {
      inlineStyles: {
        STYLE: {
          element: "span", // Use a span element for text with 'STYLE' entity
          attributes: (style, entity) => ({
            style: getInlineStyle(entity), // Apply the style retrieved from the entity
          }),
        },
      },
    });


    dispatch(newsCreateAction(newsImage, addedCategory, title, htmlContent, enteredTags));
    setDestinationimageValue("");
    setCategory("");
    setTitle("");
    setTags("");
    setAddedCategory([]);
    setEnteredTags([])
    setDescription(EditorState.createEmpty());
    // setDescription('')
  };

  return (
    <MainContainer active={props.toggle}>
      <div
        style={{
          display: "flex",
          justifyContent: "start",
          backgroundColor: "white",
        }}
      >
        <InfoHeader headTitle="Create News" url={"/landingPage"} />
      </div>
      <Divider />

      <Grid container sx={{ mt: 3 }}>
        <Grid item xs={12} lg={6} style={{ position: "relative" }}>
          <Grid>
            <InputField
              label="Cover Image"
              widthSize="92%"
              value={DestinationimageValue}
              required
              paramses
            ></InputField>
          </Grid>
          <Grid style={{ position: "absolute", top: 5, right: 50 }}>
            <button
              onClick={() => newsimage.current.click()}
              style={{
                width: "100px",
                height: "40px",
                fontSize: "13px",
                fontFamily: "monospace",
                border: "none",
              }}
            >
              Browse
            </button>
            <input
              ref={newsimage}
              type="file"
              accept="image/*"
              onChange={(event) => handleDestinationImage(event)}
              style={{ display: "none" }}
            ></input>
          </Grid>
        </Grid>

        <Grid item xs={12} lg={6} style={{ position: "relative" }}>
          <InputField
            label="Category"
            widthSize="98%"
            value={category}
            handleChange={(e) => setCategory(e.target.value)}
            required
            paramses
          />
          <Box sx={{ position: "absolute", top: 5, right: 5 }}>
            <button
              style={{
                width: "100px",
                height: "40px",
                fontSize: "13px",
                fontFamily: "monospace",
                border: "none",
              }}
              onClick={handleAddCategory}
            >
              Add
            </button>
          </Box>

          <Stack direction="row" spacing={1}>
            {addedCategory
              ? addedCategory.map((categories, index) => (
                  <Chip
                    label={categories}
                    key={index}
                    onDelete={() =>
                      setAddedCategory(
                        addedCategory.filter((value) => value !== categories)
                      )
                    }
                    deleteIcon={<CancelIcon />}
                  />
                ))
              : ""}
          </Stack>
        </Grid>

        <Grid item xs={12} lg={12} sx={{ mt: 3 }}>
          <InputField
            label="Title"
            handleChange={(e) => setTitle(e.target.value)}
            widthSize="99.2%"
            value={title}
            InputType="discription"
            // dispcriptionHeight=""
            required
            paramses
          />
        </Grid>

        <Grid item xs={12} sx={{ mt: 1,"& .public-DraftStyleDefault-ltr":{
          minHeight:"150px",
        } }}>
          <div>
            <ToggleButtonGroup
              value={formats}
              onChange={handleFormat}
              aria-label="text formatting"
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <select value={selectedStyle} onChange={handleStyleChange}>
                <option value="NORMAL">Normal</option>
                <option value="BOLD">Bold</option>
                <option value="ITALIC">Italic</option>
                <option value="UNDERLINE">Underline</option>
              </select>
              <FormatBoldIcon
                onClick={handleBoldClick}
                value="bold"
                aria-label="bold"
              />

              <FormatItalicIcon
                onClick={handleItalicClick}
                value="italic"
                aria-label="italic"
              />

              <FormatUnderlinedIcon
                onClick={handleUnderlineClick}
                value="underlined"
                aria-label="underlined"
              />

              <StrikethroughSIcon
                onClick={handleStrikethroughClick}
                value="strikethrough"
                aria-label="strikethrough"
              />
              <FormatColorTextIcon
                onClick={() => handleBgColorChange("yellow")}
                value="backgroundcolor"
                aria-label="backgroundcolor"
              />

              <InvertColorsIcon
                onClick={() => handleTextColorChange("blue")}
                value="textcolor"
                aria-label="textcolor"
              />

              <FormatAlignLeftIcon
                onClick={() => handleTextAlignment("left")}
                value="textcolor"
                aria-label="textcolor"
              />
            </ToggleButtonGroup>
          </div>
          <div
            style={{
              border: "1px solid #ececec",
              padding: "10px",
              minHeight: "200px",
              marginTop: "10px",
              
            }}
          >
            <Editor
              editorState={description}
              onChange={setDescription}
              customStyleMap={{
                // Define custom inline styles for text and background colors
                TEXT_COLOR_RED: { color: "red" },
                TEXT_COLOR_BLUE: { color: "blue" },
                BG_COLOR_YELLOW: { backgroundColor: "yellow" },
                BG_COLOR_GREEN: { backgroundColor: "green" },
                ALIGN_LEFT: { textAlign: "left" },
              }}
            />
          </div>
        </Grid>

        {/* List universities  */}

        <Grid item xs={12} lg={12} sx={{ mt: 4,position:"relative" }}>
          <InputField
            label="Tags"
            widthSize="99.2%"
            value={tags}
            handleChange={(e) => setTags(e.target.value)}
            required
            paramses
          />
          <Box sx={{ position: "absolute", top: 5, right: 0 }}>
            <button
              style={{
                width: "100px",
                height: "40px",
                fontSize: "13px",
                fontFamily: "monospace",
                border: "none",
              }}
              onClick={handleAddTags}
            >
              Add
            </button>
          </Box>

          <Stack direction="row" spacing={1}>
            {enteredTags
              ? enteredTags.map((tags, index) => (
                  <Chip
                    label={tags}
                    key={index}
                    onDelete={() =>
                      setEnteredTags(
                        enteredTags.filter((value) => value !== tags)
                      )
                    }
                    deleteIcon={<CancelIcon />}
                  />
                ))
              : ""}
          </Stack>
        </Grid>
      </Grid>
      {/* Submit Button */}
      <Grid
        item
        xs={4}
        lg={12}
        style={{
          display: "flex",
          justifyContent: "flex-start",
          marginTop: "10px",
        }}
      >
        <SubmitButton
          title="Save"
          submit=""
          widthSize="100px"
          heightSize="38px"
          type="click"
          handleSubmit={HandleCreate}
        />
      </Grid>

      {/* FAQ modal */}

      {/* {open && (
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              sx={{ fontWeight: "bold" }}
            >
              FAQ:
            </Typography>

            <div
              style={{
                marginTop: "10px",
                paddingTop: "10px",
                width: "500px",
              }}
            >
              <InputField
                label="FAQ"
                handleChange={handleFaq}
                widthSize="98%"
                value={faqValue}
                required
                paramses
              />
            </div>
            <Grid
              item
              xs={4}
              lg={12}
              style={{
                display: "flex",
                justifyContent: "flex-start",
                marginTop: "10px",
              }}
            >
              <SubmitButton
                title="Save"
                submit=""
                widthSize="100px"
                heightSize="38px"
                type="click"
                // handleSubmit={handleSave}
              />
            </Grid>
          </Box>
        </Modal>
      )} */}
    </MainContainer>
  );
}

export default CreateNews;
