import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Checkbox,
  Chip,
  Collapse,
  FormControlLabel,
  FormGroup,
  Grid,
  List,
  ListItemButton,
  ListSubheader,
  Typography,
} from "@mui/material";
import { SubmitButton } from "../../customComponent/Buttons";
import { useNavigate } from "react-router-dom";
import { MdClear } from "react-icons/md";
import CenterTable from "./CenterTable";
import { useDispatch, useSelector } from "react-redux";
import { ieltsCenterManagmentCenterFindAction } from "../../../actions/IeltsActions/centerManagmentAction";
import usePrivilegeCheck from "../../../constants/customHooks/UsePrivilegeCheck";
import PremissionDialog from "../../customComponent/premissionDialog";

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginBottom: theme.spacing(2),
    marginTop: "8px",
  },
  hoverElement: {
    "&:hover": {
      color: "#FE0B7A", // Set the hover color
    },
    "&.clicked": {
      backgroundColor: "#FE0B7A",
      color: "white",
    },
  },
}));

function IeltsCenterMain() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { privilege, hasPrivilege,isCreatePrevilage } = usePrivilegeCheck();

  const columns = [
    { title: "SL NO", field: "sl" },
    { title: "NAME", field: "name" },
    { title: "LOCATIONS", field: "location" },
    { title: "STATUS", field: "status" },
  ];

  const [center, setCenter] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [secListopen, setSecListOpen] = useState(false);
  const [location, setLocation] = useState([]);
  const [statusListopen, setStatusListOpen] = useState(true);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [status, setStatus] = useState(["Active", "Inactive"]);


  const [openAlert, setOpenAlert] = useState(false);

  // HandleClose dialog

  const handleCloseDialog = () => {
    setOpenAlert(false);
  };

  // Find center

  let {
    ieltsCenterManagmentCenterFindSuccess,
    ieltsCenterManagmentCenterFinderror,
  } = useSelector((state) => {
    return state.ieltsCenterManagmentCenterFind;
  });

  let {
    ieltsCenterManagmentCenterStatusChangeSuccess,
    ieltsCenterManagmentCenterStatusChangeerror,
  } = useSelector((state) => {
    return state.ieltsCenterManagmentCenterStatusChange;
  });

  let {
    ieltsCenterManagmentCenterDeleteSuccess,
    ieltsCenterManagmentCenterDeleteerror,
  } = useSelector((state) => {
    return state.ieltsCenterManagmentCenterDelete;
  });

  useEffect(() => {
    dispatch(ieltsCenterManagmentCenterFindAction());
  }, [
    dispatch,
    ieltsCenterManagmentCenterStatusChangeSuccess,
    ieltsCenterManagmentCenterDeleteSuccess,
  ]);

  useEffect(() => {
    if (ieltsCenterManagmentCenterFindSuccess) {
      setCenter(ieltsCenterManagmentCenterFindSuccess.data);
      let locations = ieltsCenterManagmentCenterFindSuccess.data.map(
        (value) => {
          return value.location;
        }
      );

      setLocation([...new Set(locations)]);
    }
  }, [ieltsCenterManagmentCenterFindSuccess]);

  // handleFilter function

  const handleFilterFunction = (filterValue, checkedStatus, type) => {
    if (type === "location") {
      if (checkedStatus == true) {
        setSelectedLocation(
          filterValue === selectedLocation ? null : filterValue
        );
        setCenter(
          ieltsCenterManagmentCenterFindSuccess.data.filter((value) => {
            return filterValue == value.location;
          })
        );
      }
    } else {
      if (checkedStatus == true) {
        setSelectedStatus(filterValue === selectedStatus ? null : filterValue);
        setCenter(
          ieltsCenterManagmentCenterFindSuccess.data.filter((value) => {
            return filterValue == value.status;
          })
        );
      }
    }
    if (checkedStatus) {
      // setCenter(
      //   ieltsCourseManagmentExamtipsFindSuccess?.data?.filter(
      //     (topics, index) => {
      //       return filterValue == topics.category;
      //     }
      //   )
      // );
    } else {
      // setCenter(ieltsCourseManagmentExamtipsFindSuccess.data);
    }
  };

  // Handle ClearAll function

  const handleClearAllFunction = () => {
    setCenter(ieltsCenterManagmentCenterFindSuccess.data);
    setStatusListOpen(false);
    setSelectedLocation(null);
    setSelectedStatus(null);
  };

  const [ids, setIds] = useState([]);
  const selectBoxIdPassHandler = (ids) => {
    setIds(ids);
  };

  const [page, setPage] = useState(10);
  const [pageError, setPageError] = useState("");

  const handlePageSearch = (e) => {
    const { value } = e.target;
    if (value >= 0){
      setPage(value);
    } 
    if (value >= 10) {
      setPageError("");
    } else {
      setPageError("please choose a value greater than 10");
    }
  };


  if (!hasPrivilege("center") && privilege !== null) {
    return (
      <Alert severity="error">
        <AlertTitle>warning</AlertTitle>
        You don't have permission to access — <strong>this page.!</strong>
      </Alert>
    );
  }

  return (
    <>
      <Grid container>
        <Grid
          item
          xs={12}
          lg={6}
          sx={{
            display: "flex",
            gap: "20px",
          }}
        >
          {selectedLocation && (
            <Chip
              sx={{
                "&.MuiChip-root": {
                  pl: "5px",
                  pr: "5px",
                },
              }}
              label={
                <Typography
                  sx={{
                    fontSize: "10px",
                    fontWeight: "700",
                    color: "rgba(88, 94, 106, 1)",
                  }}
                >
                  location:{" "}
                  <Typography
                    sx={{
                      fontSize: "10px",
                      fontWeight: "400",
                    }}
                    component={"span"}
                  >
                    {selectedLocation}
                  </Typography>
                </Typography>
              }
              onDelete={handleClearAllFunction}
            />
          )}
          {selectedStatus && (
            <Chip
              sx={{
                "&.MuiChip-root": {
                  pl: "5px",
                  pr: "5px",
                },
              }}
              label={
                <Typography
                  sx={{
                    fontSize: "10px",
                    fontWeight: "700",
                    color: "rgba(88, 94, 106, 1)",
                  }}
                >
                  status:{" "}
                  <Typography
                    sx={{
                      fontSize: "10px",
                      fontWeight: "400",
                    }}
                    component={"span"}
                  >
                    {selectedStatus}
                  </Typography>
                </Typography>
              }
              onDelete={handleClearAllFunction}
            />
          )}
        </Grid>
        <Grid
          item
          xs={12}
          lg={6}
          sx={{ display: "flex", justifyContent: "flex-end" }}
        >
          <SubmitButton
            title="Add New"
            submit=""
            widthSize="123px"
            heightSize="38px"
            type="click"
            handleSubmit={() => {
              if(isCreatePrevilage("center")){

                navigate("/ielts/center/addnew/")
              }else{
                setOpenAlert(true)
              }
            }}
          />
        </Grid>
      </Grid>

      <Box
        sx={{
          mt: 2,
          display: "grid",
          gridTemplateColumns: {
            xs: "1fr",
            lg: "330px 1fr",
          },
          gap: "25px",
        }}
      >
        <Box
          sx={{
            " & .MuiListSubheader-root": {
              backgroundColor: "#F2F2F2",
            },
          }}
        >
          <List
            component="nav"
            aria-labelledby="nested-list-subheader"
            subheader={
              <ListSubheader component="div" id="nested-list-subheader">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <div>Filter</div>
                  <div
                    style={{
                      display: "flex",
                      marginTop: "12px",
                      cursor: "pointer",
                    }}
                    //   onClick={handleClearClick}
                  >
                    <MdClear style={{ fontSize: "22px", height: "20px" }} />{" "}
                    <Typography onClick={handleClearAllFunction}>
                      Clear All
                    </Typography>
                  </div>
                </div>
              </ListSubheader>
            }
          >
            <ListItemButton
              onClick={() => setStatusListOpen(!statusListopen)}
              sx={{
                mt: "20px",
                fontWeight: "700",
                borderBottom: "1px solid rgba(236, 236, 236, 1)",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              Status
              <svg
                width="13"
                height="7"
                viewBox="0 0 13 7"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.30059 6.20042C5.83685 6.20042 5.37312 6.02042 5.02201 5.66708L0.702682 1.32042C0.510564 1.12708 0.510564 0.807083 0.702682 0.61375C0.894799 0.420417 1.21279 0.420417 1.4049 0.61375L5.72423 4.96042C6.04222 5.28042 6.55895 5.28042 6.87694 4.96042L11.1963 0.61375C11.3884 0.420417 11.7064 0.420417 11.8985 0.61375C12.0906 0.807083 12.0906 1.12708 11.8985 1.32042L7.57916 5.66708C7.22805 6.02042 6.76432 6.20042 6.30059 6.20042Z"
                  fill="#05050F"
                />
              </svg>
            </ListItemButton>
            <Collapse in={statusListopen} timeout="auto" unmountOnExit sx={{}}>
              <List component="div" disablePadding>
                <ListItemButton>
                  <FormGroup
                    sx={{
                      "& .MuiFormControlLabel-root": {
                        width: "100%",
                      },
                      width: "100%",
                    }}
                  >
                    {status &&
                      status?.map((item, i) => (
                        <FormControlLabel
                          key={i}
                          control={
                            <Checkbox
                              sx={{
                                color: "#F8469A",
                                "&.Mui-checked": {
                                  color: "#F8469A",
                                },
                              }}
                              checked={item === selectedStatus}
                              onChange={(e) => {
                                handleFilterFunction(
                                  item,
                                  e.target.checked,
                                  "status"
                                );
                              }}
                            />
                          }
                          label={item}
                        />
                      ))}
                  </FormGroup>
                </ListItemButton>
              </List>
            </Collapse>

            <ListItemButton
              onClick={() => setSecListOpen(!secListopen)}
              sx={{
                fontWeight: "700",
                borderBottom: "1px solid rgba(236, 236, 236, 1)",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              Location
              <svg
                width="13"
                height="7"
                viewBox="0 0 13 7"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.30059 6.20042C5.83685 6.20042 5.37312 6.02042 5.02201 5.66708L0.702682 1.32042C0.510564 1.12708 0.510564 0.807083 0.702682 0.61375C0.894799 0.420417 1.21279 0.420417 1.4049 0.61375L5.72423 4.96042C6.04222 5.28042 6.55895 5.28042 6.87694 4.96042L11.1963 0.61375C11.3884 0.420417 11.7064 0.420417 11.8985 0.61375C12.0906 0.807083 12.0906 1.12708 11.8985 1.32042L7.57916 5.66708C7.22805 6.02042 6.76432 6.20042 6.30059 6.20042Z"
                  fill="#05050F"
                />
              </svg>
            </ListItemButton>
            <Collapse
              in={secListopen}
              timeout="auto"
              unmountOnExit
              sx={
                {
                  // mt: "20px",
                }
              }
            >
              <List component="div" disablePadding>
                <ListItemButton>
                  <FormGroup
                    sx={{
                      "& .MuiFormControlLabel-root": {
                        width: "100%",
                      },
                      width: "100%",
                    }}
                  >
                    {location &&
                      location?.map((item, i) => (
                        <FormControlLabel
                          key={i}
                          control={
                            <Checkbox
                              sx={{
                                color: "#F8469A",
                                "&.Mui-checked": {
                                  color: "#F8469A",
                                },
                              }}
                              checked={item === selectedLocation}
                              onChange={(e) => {
                                handleFilterFunction(
                                  item,
                                  e.target.checked,
                                  "location"
                                );
                              }}
                            />
                          }
                          label={item}
                        />
                      ))}
                  </FormGroup>
                </ListItemButton>
              </List>
            </Collapse>
            <Box
              sx={{
                display: "flex",
                gap: "10px",
                alignItems: "center",
                mt: "20px",
                "& input": {
                  width: "30px",
                  outline: "none",
                },
              }}
            >
              <Typography
                sx={{
                  fontSize: 12,
                }}
              >
                Rows Per Page :{" "}
              </Typography>
              <input type="number" value={page} onChange={handlePageSearch} />
            </Box>
            {pageError && (
              <Typography
                sx={{
                  fontSize: 10,
                  color: "red",
                }}
              >
                {pageError}
              </Typography>
            )}
          </List>
        </Box>
        <CenterTable
          data={center}
          columns={columns}
          component="ieltsCategory"
          selectBoxIdPassHandler={selectBoxIdPassHandler}
          pageNumber={page} 
          subMenu="center"
        />
      </Box>


      {openAlert && (
        <PremissionDialog
          openAlert={openAlert}
          handleClose={handleCloseDialog}
        />
      )}
    </>
  );
}

export default IeltsCenterMain;
