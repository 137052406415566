import React, { useEffect, useState } from "react";
import { MainContainer } from "../../../content/content.element";
import { InfoHeader } from "../../../customComponent/InfoHeader";
import TaskInformation from "../followUpDetails/TaskInformation";
import { Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { followUpTaskInformationFindAction } from "../../../../actions/leadActions/leadFollowUpActions";
import { useParams } from "react-router-dom";
import { leadDetailFetchAction } from "../../../../actions/leadActions/leadActions";

function OpenedTasks(props) {
  const dispatch = useDispatch();
  const { id } = useParams();

  const { leadFollowUpFindSuccess } = useSelector((state) => {
    return state.followUpTaskInformationFind;
  });

  let { leadDetailSuccess } = useSelector((state) => {
    return state.leadDetailFetch;
  });

  useEffect(() => {
    dispatch(followUpTaskInformationFindAction(id));
    dispatch(leadDetailFetchAction(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (leadFollowUpFindSuccess) {
      let concatData = [].concat(...leadFollowUpFindSuccess);
      let filteredArray = concatData.filter(
        (item) => item.activityType === "task"
      );
      setDataArray(filteredArray);
    }
  }, [leadFollowUpFindSuccess]);

  useEffect(() => {
    if (leadDetailSuccess) {
      setCounsellor(leadDetailSuccess?.leadDetails.counsilorID);
    }
  }, [leadDetailSuccess]);

  const [dataArray, setDataArray] = useState();
  const [counsellor, setCounsellor] = useState();

  return (
    <MainContainer active={props.toggle}>
      <InfoHeader headTitle="Open Tasks" url={-1} />
      <Box
        sx={{
          margin: "20px 0",
          display: "flex",
          flexDirection: "column",
          gap: "20px",
        }}
      >
        {dataArray?.map((item) => (
          <TaskInformation
            removeButtons={true}
            followUpDetails={item}
            counsellorName={counsellor}
          />
        ))}
      </Box>
    </MainContainer>
  );
}

export default OpenedTasks;
