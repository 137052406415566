export const UNIVERSITY_SUBMIT_REQUEST = "UNIVERSITY_SUBMIT_REQUEST";
export const UNIVERSITY_SUBMIT_SUCCESS = "UNIVERSITY_SUBMIT_SUCCESS";
export const UNIVERSITY_SUBMIT_ERR = "UNIVERSITY_SUBMIT_ERR";

export const UNIVERSITY_IMAGE_UPLOAD_REQUEST =
  "UNIVERSITY_IMAGE_UPLOAD_REQUEST";
export const UNIVERSITY_IMAGE_UPLOAD_SUCCESS =
  "UNIVERSITY_IMAGE_UPLOAD_SUCCESS";
export const UNIVERSITY_IMAGE_UPLOAD_ERR = "UNIVERSITY_IMAGE_UPLOAD_ERR";

export const UNIVERSITY_NULL_IMAGE_REQUEST = "UNIVERSITY_NULL_IMAGE_REQUEST";
export const UNIVERSITY_NULL_IMAGE_SUCCESS = "UNIVERSITY_NULL_IMAGE_SUCCESS";
export const UNIVERSITY_NULL_IMAGE_ERR = "UNIVERSITY_NULL_IMAGE_ERR";

export const UNIVERSITY_IMAGE_DELETE_REQUEST =
  "UNIVERSITY_IMAGE_DELETE_REQUEST";
export const UNIVERSITY_IMAGE_DELETE_SUCCESS =
  "UNIVERSITY_IMAGE_DELETE_SUCCESS";
export const UNIVERSITY_IMAGE_DELETE_ERR = "UNIVERSITY_IMAGE_DELETE_ERR";

export const UNIVERSITY_FIND_REQUEST = "UNIVERSITY_FIND_REQUEST";
export const UNIVERSITY_FIND_SUCCESS = "UNIVERSITY_FIND_SUCCESS";
export const UNIVERSITY_FIND_ERR = "UNIVERSITY_FIND_ERR";

export const UNIVERSITY_SINGLE_VIEW_REQUEST = "UNIVERSITY_SINGLE_VIEW_REQUEST";
export const UNIVERSITY_SINGLE_VIEW_SUCCESS = "UNIVERSITY_SINGLE_VIEW_SUCCESS";
export const UNIVERSITY_SINGLE_VIEW_ERR = "UNIVERSITY_SINGLE_VIEW_ERR";


export const UNIVERSITY_EDIT_REQUEST = "UNIVERSITY_EDIT_REQUEST";
export const UNIVERSITY_EDIT_SUCCESS = "UNIVERSITY_EDIT_SUCCESS";
export const UNIVERSITY_EDIT_ERR = "UNIVERSITY_EDIT_ERR";


export const UNIVERSITY_DELETE_REQUEST = "UNIVERSITY_DELETE_REQUEST";
export const UNIVERSITY_DELETE_SUCCESS = "UNIVERSITY_DELETE_SUCCESS";
export const UNIVERSITY_DELETE_ERR = "UNIVERSITY_DELETE_ERR";


export const UNIVERSITYANDCOURSE_BULKUPLOAD_REQUEST = "UNIVERSITYANDCOURSE_BULKUPLOAD_REQUEST";
export const UNIVERSITYANDCOURSE_BULKUPLOAD_SUCCESS = "UNIVERSITYANDCOURSE_BULKUPLOAD_SUCCESS";
export const UNIVERSITYANDCOURSE_BULKUPLOAD_ERR = "UNIVERSITY_BULKUPLOAD_ERR";
