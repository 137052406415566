import React, { useEffect, useState } from "react";
import { MainContainer } from "../../content/content.element";
import {
  Alert,
  AlertTitle,
  Box,
  Checkbox,
  Chip,
  Collapse,
  FormControlLabel,
  FormGroup,
  List,
  ListItemButton,
  ListSubheader,
  Typography,
} from "@mui/material";
import { MdClear } from "react-icons/md";
import IeltsPurchasedSearchHeader from "./IeltsPurchasedSearchHeader";
import IeltsOnlinePurchaseTable from "./IeltsOnlinePurchaseTable";
import IeltsOfflinePurchaseTable from "./IeltsOfflinePurchaseTable";
import { useDispatch, useSelector } from "react-redux";
import { ieltsPurchasedStudentFindAction } from "../../../actions/IeltsActions/purchasedStudentActions";
import DownloadExcel from "../../extraComponents/DownloadExcel";
import DownloadPdf from "../../extraComponents/DownloadPdf";
import usePrivilegeCheck from "../../../constants/customHooks/UsePrivilegeCheck";

function IeltsPurchasedMain() {
  const dispatch = useDispatch();
  const { privilege, hasPrivilege } = usePrivilegeCheck();

  let { ieltsPurchasedStudentFindSuccess } = useSelector((state) => {
    return state.ieltsPurchasedStudentFind;
  });

  let { ieltsPurchasedStudentChangeStatusSuccess } = useSelector((state) => {
    return state.ieltsPurchasedStudentStatusChange;
  });

  let { ieltsPurchasedStudentAssignTeacherSuccess } = useSelector(
    (state) => state.ieltsPurchasedStudentAssignTeacher
  );

  useEffect(() => {
    if (ieltsPurchasedStudentFindSuccess) {
      let allcourse = ieltsPurchasedStudentFindSuccess?.data?.map(
        (item) => item?.ielts_course?.CourseName
      );

      let online = ieltsPurchasedStudentFindSuccess?.data?.filter(
        (item) => item.courseMethod === "onlineClass"
      );
      let offline = ieltsPurchasedStudentFindSuccess?.data?.filter(
        (item) => item.courseMethod === "offlineClass"
      );

      setOnlineData(online);
      setOnlineStudentValue(online);

      setOfflineData(offline);
      setOfflineStudentValue(offline);

      setCourse([...new Set(allcourse)]);
    }
  }, [ieltsPurchasedStudentFindSuccess]);

  useEffect(() => {
    dispatch(ieltsPurchasedStudentFindAction());
  }, [
    dispatch,
    ieltsPurchasedStudentChangeStatusSuccess,
    ieltsPurchasedStudentAssignTeacherSuccess,
  ]);

  const [selectedItem, setSelectedItem] = useState("Online");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [openStatus, setStatusOpen] = useState(true);
  const [selectedCourse, setSelectedCourse] = useState("");
  const [openCourse, setCourseOpen] = useState(false);
  const [selectedGender, setSelectedGender] = useState("");
  const [openGender, setGenderOpen] = useState(false);
  const [onlineStudentValue, setOnlineStudentValue] = useState();
  const [offlineStudentValue, setOfflineStudentValue] = useState();
  const [course, setCourse] = useState();
  const [OnlineData, setOnlineData] = useState(onlineStudentValue);
  const [offlineData, setOfflineData] = useState(offlineStudentValue);
  const [columnVisibility, setColumnVisibility] = useState([
    { title: "SL NO", value: true },
    { title: "NAME", value: true },
    { title: "DOB", value: true },
    { title: "GENDER", value: true },
    { title: "EMAIL", value: true },
    { title: "PHONE", value: true },
    { title: "WHATSAPP NO:", value: true },
    { title: "COURSE", value: true },
    { title: "CENTER", value: true },
    { title: "PAYMENT", value: true },
    { title: "TEACHER", value: true },
    { title: "STATUS", value: true },
  ]);

  console.log(offlineData, "offlineDataofflineData");
  // handleFilter function

  const handleFilterFunction = (filterValue, checkedStatus, type) => {
    handleClearAllFunction();

    if (type === "status") {
      if (checkedStatus) {
        setOfflineData(
          offlineStudentValue.filter(
            (item) =>
              item?.ielts_entrolled_student?.status?.toLowerCase() ===
              filterValue?.toLowerCase()
          )
        );
        setOnlineData(
          onlineStudentValue.filter(
            (item) => item?.ielts_entrolled_student?.status === filterValue
          )
        );
      } else {
        setOfflineData(offlineStudentValue);
        setOnlineData(onlineStudentValue);
      }
      setSelectedStatus(filterValue === selectedStatus ? null : filterValue);
    } else if (type === "course") {
      setSelectedCourse(filterValue === selectedCourse ? null : filterValue);
      if (checkedStatus) {
        setOfflineData(
          offlineStudentValue.filter(
            (item) =>
              item?.ielts_course?.CourseName?.toLowerCase() ===
              filterValue?.toLowerCase()
          )
        );
        setOnlineData(
          onlineStudentValue.filter(
            (item) =>
              item?.ielts_course?.CourseName?.toLowerCase() ===
              filterValue?.toLowerCase()
          )
        );
      } else {
        setOfflineData(offlineStudentValue);
        setOnlineData(onlineStudentValue);
      }
    } else if (type === "gender") {
      setSelectedGender(filterValue === selectedGender ? null : filterValue);
      if (checkedStatus) {
        setOfflineData(
          offlineStudentValue.filter(
            (item) =>
              item?.ielts_entrolled_student?.gender?.toLowerCase() ===
              filterValue?.toLowerCase()
          )
        );
        setOnlineData(
          onlineStudentValue.filter(
            (item) =>
              item?.ielts_entrolled_student?.gender?.toLowerCase() ===
              filterValue?.toLowerCase()
          )
        );
      } else {
        setOfflineData(offlineStudentValue);
        setOnlineData(onlineStudentValue);
      }
    } else {
      setOfflineData(offlineStudentValue);
      setOnlineData(onlineStudentValue);
    }
  };

  // column visibility

  const handleColumnVisibility = (e) => {
    const { name, checked } = e.target;
    setColumnVisibility((prevColumns) =>
      prevColumns.map((column) =>
        column.title === name ? { ...column, value: !checked } : column
      )
    );
  };

  // Handle ClearAll function

  const handleClearAllFunction = () => {
    setSelectedStatus("");
    setSelectedGender("");
    setSelectedCourse("");
    setOfflineData(offlineColumns);
    setOnlineData(onlineStudentValue);
  };

  const onlineColumns = [
    { title: "SL NO", field: "sl" },
    { title: "NAME", field: "name" },
    { title: "DOB", field: "location" },
    { title: "GENDER", field: "gender" },
    { title: "EMAIL", field: "email" },
    { title: "PHONE", field: "phone" },
    { title: "WHATSAPP NO:", field: "whatsapp" },
    { title: "COURSE", field: "course" },
    { title: "STATUS", field: "status" },
  ];

  const offlineColumns = [
    { title: "SL NO", field: "sl" },
    { title: "NAME", field: "name" },
    { title: "DOB", field: "location" },
    { title: "GENDER", field: "gender" },
    { title: "EMAIL", field: "email" },
    { title: "PHONE", field: "phone" },
    { title: "WHATSAPP NO:", field: "whatsapp" },
    { title: "COURSE", field: "course" },
    { title: "CENTER", field: "center" },
    { title: "PAYMENT", field: "payment" },
    { title: "TEACHER", field: "teacher" },
    { title: "STATUS", field: "status" },
  ];

  const status = ["Active", "Inactive"];
  const gender = ["Male", "Female"];

  const [ids, setIds] = useState([]);
  const selectBoxIdPassHandler = (ids) => {
    setIds(ids);
  };

  const searchHandleChange = (e) => {
    handleClearAllFunction();
    const { value } = e.target;
    if (value.length > 0) {
      setOfflineData(
        offlineStudentValue.filter((item) =>
          item?.ielts_entrolled_student?.fullName?.toLowerCase().includes(value)
        )
      );
      setOnlineData(
        onlineStudentValue.filter((item) =>
          item?.ielts_entrolled_student?.fullName?.toLowerCase().includes(value)
        )
      );
    } else {
      setOfflineData(offlineStudentValue);
      setOnlineData(onlineStudentValue);
    }
  };

  // Excel export function
  const excelDownloadClick = () => {
    if (selectedItem == "Offline") {
      DownloadExcel(offlineColumns, offlineData, "OfflinePurchasedStudentList");
    } else {
      DownloadExcel(onlineColumns, OnlineData, "OnlinePurchasedStudentList");
    }
  };

  // Pdf Export function

  const pdfDownloadClick = () => {
    if (selectedItem == "Offline") {
      DownloadPdf(
        offlineColumns,
        offlineData,
        "OfflinePurchasedStudentList",
        "OfflinePurchasedStudentList"
      );
    } else {
      DownloadPdf(
        onlineColumns,
        OnlineData,
        "OnlinePurchasedStudentList",
        "OnlinePurchasedStudentList"
      );
    }
  };

  const [page, setPage] = useState(10);
  const [pageError, setPageError] = useState("");

  const handlePageSearch = (e) => {
    const { value } = e.target;
    if (value >= 0) {
      setPage(value);
    }
    if (value >= 10) {
      setPageError("");
    } else {
      setPageError("please choose a value greater than 10");
    }
  };

  if (!hasPrivilege("purchased") && privilege !== null) {
    return (
      <Alert severity="error">
        <AlertTitle>warning</AlertTitle>
        You don't have permission to access — <strong>this page.!</strong>
      </Alert>
    );
  }

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "20px",
        }}
      >
        {["Online", "Offline"].map((item, i) => (
          <Chip
            key={i}
            sx={{
              bgcolor:
                selectedItem == item
                  ? "rgba(254, 11, 122, 1) !important"
                  : "rgba(172, 177, 198, 1) !important",
              "&.MuiChip-root": {
                pl: "5px",
                pr: "5px",
              },
            }}
            label={
              <Typography
                sx={{
                  fontSize: "14px",
                  color: "#fff",
                }}
              >
                {item}
              </Typography>
            }
            onClick={() => setSelectedItem(item)}
          />
        ))}
      </Box>

      <Box
        sx={{
          mt: 2,
          display: "grid",
          gridTemplateColumns: {
            xs: "1fr",
            lg: "330px 1fr",
          },
          gap: "25px",
        }}
      >
        <Box>
          <Box
            sx={{
              display: "flex",
              gap: "10px",
              flexWrap: "wrap",
              mb: "20px",
            }}
          >
            {selectedStatus && (
              <Chip
                sx={{
                  "&.MuiChip-root": {
                    pl: "5px",
                    pr: "5px",
                  },
                }}
                label={
                  <Typography
                    sx={{
                      fontSize: "10px",
                      fontWeight: "700",
                      color: "rgba(88, 94, 106, 1)",
                    }}
                  >
                    Status:{" "}
                    <Typography
                      sx={{
                        fontSize: "10px",
                        fontWeight: "400",
                      }}
                      component={"span"}
                    >
                      {selectedStatus}
                    </Typography>
                  </Typography>
                }
                onDelete={() => setSelectedStatus(null)}
              />
            )}
            {selectedCourse && (
              <Chip
                sx={{
                  "&.MuiChip-root": {
                    pl: "5px",
                    pr: "5px",
                  },
                }}
                label={
                  <Typography
                    sx={{
                      fontSize: "10px",
                      fontWeight: "700",
                      color: "rgba(88, 94, 106, 1)",
                    }}
                  >
                    Course:{" "}
                    <Typography
                      sx={{
                        fontSize: "10px",
                        fontWeight: "400",
                      }}
                      component={"span"}
                    >
                      {selectedCourse}
                    </Typography>
                  </Typography>
                }
                onDelete={() => setSelectedCourse(null)}
              />
            )}
            {selectedGender && (
              <Chip
                sx={{
                  "&.MuiChip-root": {
                    pl: "5px",
                    pr: "5px",
                  },
                }}
                label={
                  <Typography
                    sx={{
                      fontSize: "10px",
                      fontWeight: "700",
                      color: "rgba(88, 94, 106, 1)",
                    }}
                  >
                    Gender:{" "}
                    <Typography
                      sx={{
                        fontSize: "10px",
                        fontWeight: "400",
                      }}
                      component={"span"}
                    >
                      {selectedGender}
                    </Typography>
                  </Typography>
                }
                onDelete={() => setSelectedGender(null)}
              />
            )}
          </Box>
          <Box
            sx={{
              " & .MuiListSubheader-root": {
                backgroundColor: "#F2F2F2",
              },
            }}
          >
            <List
              component="nav"
              aria-labelledby="nested-list-subheader"
              subheader={
                <ListSubheader component="div" id="nested-list-subheader">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <div>Filter</div>
                    <div
                      style={{
                        display: "flex",
                        marginTop: "12px",
                        cursor: "pointer",
                      }}
                      //   onClick={handleClearClick}
                    >
                      <MdClear style={{ fontSize: "22px", height: "20px" }} />{" "}
                      <Typography onClick={handleClearAllFunction}>
                        Clear All
                      </Typography>
                    </div>
                  </div>
                </ListSubheader>
              }
            >
              <ListItemButton
                onClick={() => setStatusOpen(!openStatus)}
                sx={{
                  mt: "20px",
                  fontWeight: "700",
                  borderBottom: "1px solid rgba(236, 236, 236, 1)",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                Status
                <svg
                  width="13"
                  height="7"
                  viewBox="0 0 13 7"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.30059 6.20042C5.83685 6.20042 5.37312 6.02042 5.02201 5.66708L0.702682 1.32042C0.510564 1.12708 0.510564 0.807083 0.702682 0.61375C0.894799 0.420417 1.21279 0.420417 1.4049 0.61375L5.72423 4.96042C6.04222 5.28042 6.55895 5.28042 6.87694 4.96042L11.1963 0.61375C11.3884 0.420417 11.7064 0.420417 11.8985 0.61375C12.0906 0.807083 12.0906 1.12708 11.8985 1.32042L7.57916 5.66708C7.22805 6.02042 6.76432 6.20042 6.30059 6.20042Z"
                    fill="#05050F"
                  />
                </svg>
              </ListItemButton>
              <Collapse in={openStatus} timeout="auto" unmountOnExit sx={{}}>
                <List component="div" disablePadding>
                  <ListItemButton>
                    <FormGroup
                      sx={{
                        "& .MuiFormControlLabel-root": {
                          width: "100%",
                        },
                        width: "100%",
                      }}
                    >
                      {status &&
                        status?.map((item, i) => (
                          <FormControlLabel
                            key={i}
                            control={
                              <Checkbox
                                sx={{
                                  color: "#F8469A",
                                  "&.Mui-checked": {
                                    color: "#F8469A",
                                  },
                                }}
                                checked={item === selectedStatus}
                                onChange={(e) => {
                                  handleFilterFunction(
                                    item,
                                    e.target.checked,
                                    "status"
                                  );
                                }}
                              />
                            }
                            label={item}
                          />
                        ))}
                    </FormGroup>
                  </ListItemButton>
                </List>
              </Collapse>

              <ListItemButton
                onClick={() => setCourseOpen(!openCourse)}
                sx={{
                  fontWeight: "700",
                  borderBottom: "1px solid rgba(236, 236, 236, 1)",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                Course
                <svg
                  width="13"
                  height="7"
                  viewBox="0 0 13 7"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.30059 6.20042C5.83685 6.20042 5.37312 6.02042 5.02201 5.66708L0.702682 1.32042C0.510564 1.12708 0.510564 0.807083 0.702682 0.61375C0.894799 0.420417 1.21279 0.420417 1.4049 0.61375L5.72423 4.96042C6.04222 5.28042 6.55895 5.28042 6.87694 4.96042L11.1963 0.61375C11.3884 0.420417 11.7064 0.420417 11.8985 0.61375C12.0906 0.807083 12.0906 1.12708 11.8985 1.32042L7.57916 5.66708C7.22805 6.02042 6.76432 6.20042 6.30059 6.20042Z"
                    fill="#05050F"
                  />
                </svg>
              </ListItemButton>
              <Collapse in={openCourse} timeout="auto" unmountOnExit sx={{}}>
                <List component="div" disablePadding>
                  <ListItemButton>
                    <FormGroup
                      sx={{
                        "& .MuiFormControlLabel-root": {
                          width: "100%",
                        },
                        width: "100%",
                      }}
                    >
                      {course?.length > 0 &&
                        course?.map((item, i) => (
                          <FormControlLabel
                            key={i}
                            control={
                              <Checkbox
                                sx={{
                                  color: "#F8469A",
                                  "&.Mui-checked": {
                                    color: "#F8469A",
                                  },
                                }}
                                checked={item === selectedCourse}
                                onChange={(e) => {
                                  handleFilterFunction(
                                    item,
                                    e.target.checked,
                                    "course"
                                  );
                                }}
                              />
                            }
                            label={item}
                          />
                        ))}
                    </FormGroup>
                  </ListItemButton>
                </List>
              </Collapse>

              <ListItemButton
                onClick={() => setGenderOpen(!openGender)}
                sx={{
                  fontWeight: "700",
                  borderBottom: "1px solid rgba(236, 236, 236, 1)",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                Gender
                <svg
                  width="13"
                  height="7"
                  viewBox="0 0 13 7"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.30059 6.20042C5.83685 6.20042 5.37312 6.02042 5.02201 5.66708L0.702682 1.32042C0.510564 1.12708 0.510564 0.807083 0.702682 0.61375C0.894799 0.420417 1.21279 0.420417 1.4049 0.61375L5.72423 4.96042C6.04222 5.28042 6.55895 5.28042 6.87694 4.96042L11.1963 0.61375C11.3884 0.420417 11.7064 0.420417 11.8985 0.61375C12.0906 0.807083 12.0906 1.12708 11.8985 1.32042L7.57916 5.66708C7.22805 6.02042 6.76432 6.20042 6.30059 6.20042Z"
                    fill="#05050F"
                  />
                </svg>
              </ListItemButton>
              <Collapse in={openGender} timeout="auto" unmountOnExit sx={{}}>
                <List component="div" disablePadding>
                  <ListItemButton>
                    <FormGroup
                      sx={{
                        "& .MuiFormControlLabel-root": {
                          width: "100%",
                        },
                        width: "100%",
                      }}
                    >
                      {gender &&
                        gender?.map((item, i) => (
                          <FormControlLabel
                            key={i}
                            control={
                              <Checkbox
                                sx={{
                                  color: "#F8469A",
                                  "&.Mui-checked": {
                                    color: "#F8469A",
                                  },
                                }}
                                checked={item === selectedGender}
                                onChange={(e) => {
                                  handleFilterFunction(
                                    item,
                                    e.target.checked,
                                    "gender"
                                  );
                                }}
                              />
                            }
                            label={item}
                          />
                        ))}
                    </FormGroup>
                  </ListItemButton>
                </List>
              </Collapse>

              <Box
                sx={{
                  display: "flex",
                  gap: "10px",
                  alignItems: "center",
                  mt: "20px",
                  "& input": {
                    width: "30px",
                    outline: "none",
                  },
                }}
              >
                <Typography
                  sx={{
                    fontSize: 12,
                  }}
                >
                  Rows Per Page :{" "}
                </Typography>
                <input type="number" value={page} onChange={handlePageSearch} />
              </Box>
              {pageError && (
                <Typography
                  sx={{
                    fontSize: 10,
                    color: "red",
                  }}
                >
                  {pageError}
                </Typography>
              )}
            </List>
          </Box>
        </Box>
        <Box
          sx={{
            overflow: "hidden",
          }}
        >
          <IeltsPurchasedSearchHeader
            columnVisibility={columnVisibility}
            columnVisibleHandler={handleColumnVisibility}
            searchHandleChange={searchHandleChange}
            excelDownloadClick={excelDownloadClick}
            pdfDownloadClick={pdfDownloadClick}
            subMenu="purchased"
          />

          {selectedItem == "Online" && (
            <IeltsOnlinePurchaseTable
              columnVisibility={columnVisibility}
              data={OnlineData}
              columns={onlineColumns}
              component="ieltsCategory"
              selectBoxIdPassHandler={selectBoxIdPassHandler}
              pageNumber={page}
              subMenu="purchased"
              />
          )}

          {selectedItem == "Offline" && (
            <IeltsOfflinePurchaseTable
              columnVisibility={columnVisibility}
              data={offlineData}
              columns={offlineColumns}
              component="ieltsCategory"
              selectBoxIdPassHandler={selectBoxIdPassHandler}
              pageNumber={page}
              subMenu="purchased"

            />
          )}
        </Box>
      </Box>
    </Box>
  );
}

export default IeltsPurchasedMain;
