export const PAYMENTAPPROVAL_FIND_REQUEST = "PAYMENTAPPROVAL_FIND_REQUEST";
export const PAYMENTAPPROVAL_FIND_SUCCESS = "PAYMENTAPPROVAL_FIND_SUCCESS";
export const PAYMENTAPPROVAL_FIND_ERR = "PAYMENTAPPROVAL_FIND_ERR";

export const TUTIONFEEPAYMENTAPPROVAL_FIND_REQUEST =
  "TUTIONFEEPAYMENTAPPROVAL_FIND_REQUEST";
export const TUTIONFEEPAYMENTAPPROVAL_FIND_SUCCESS =
  "TUTIONFEEPAYMENTAPPROVAL_FIND_SUCCESS";
export const TUTIONFEEPAYMENTAPPROVAL_FIND_ERR =
  "TUTIONFEEPAYMENTAPPROVAL_FIND_ERR";

export const PAYMENTAPPROVAL_REGISTRATIONFEE_APPROVE_REQUEST =
  "PAYMENTAPPROVAL_REGISTRATIONFEE_APPROVE_REQUEST";
export const PAYMENTAPPROVAL_REGISTRATIONFEE_APPROVE_SUCCESS =
  "PAYMENTAPPROVAL_REGISTRATIONFEE_APPROVE_SUCCESS";
export const PAYMENTAPPROVAL_REGISTRATIONFEE_APPROVE_ERR =
  "PAYMENTAPPROVAL_REGISTRATIONFEE_APPROVE_ERR";

export const TUTIONFEEPAYMENTAPPROVAL_APPROVE_REQUEST =
  "TUTIONFEEPAYMENTAPPROVAL_APPROVE_REQUEST";
export const TUTIONFEEPAYMENTAPPROVAL_APPROVE_SUCCESS =
  "TUTIONFEEPAYMENTAPPROVAL_APPROVE_SUCCESS";
export const TUTIONFEEPAYMENTAPPROVAL_APPROVE_ERR =
  "TUTIONFEEPAYMENTAPPROVAL_APPROVE_ERR";
