import React, { useEffect, useState } from "react";

import { format } from "date-fns";

import {
  Avatar,
  Box,
  Card,
  Divider,
  Grid,
  Paper,
  Typography,
} from "@mui/material";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import "./mainFollowUp.css";
import { MainContainer } from "../../content/content.element";
import { InfoHeader } from "../../customComponent/InfoHeader";
import { useParams } from "react-router-dom";
import { SubmitButton } from "../../customComponent/Buttons";
import { makeStyles } from "@mui/styles";
import Dialogue from "../../customComponent/Dialogue";
import TaskInformation from "./followUpDetails/TaskInformation";
import { useDispatch, useSelector } from "react-redux";
import { followUpTaskInformationFindAction } from "../../../actions/leadActions/leadFollowUpActions";
import { leadDetailFetchAction } from "../../../actions/leadActions/leadActions";
import OpenActivities from "./followUpDetails/OpenActivities";
import CloseActivities from "./followUpDetails/CloseActivities";
import SocialMediaSection from "./followUpDetails/SocialMediaSection";
import NoteSection from "./followUpDetails/NoteSection";
import AttachmentSection from "./followUpDetails/AttachmentSection";
import ComposeMail from "./followUpDetails/ComposeMail";
import FollowLinks from "./followUpDetails/FollowLinks";
import Swal from "sweetalert2";

const useStyles = makeStyles((theme) => ({
  centeredButton: {
    display: "block",
    margin: "0 auto",
    boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.1)",
    padding: "10px 20px",
    borderRadius: "8px",
    backgroundColor: "rgba(0, 0, 0, 0.1)",
    color: "#333",
    fontFamily: "'Poppins', sans-serif",
    fontSize: "16px",
    fontWeight: "bold",
    width: "405px",
    "&:hover": {
      backgroundColor: "#e0e0e0",
    },
  },
  menuPaper: {
    boxShadow: "4px 4px 8px rgba(0, 0, 0, 0.2)",
    backgroundColor: "#fff",
    width: "202px",
    border: "1px solid #000",
    marginTop: "10px",
  },
}));

function MainFollowUp(props) {
  const { id } = useParams();
  const classes = useStyles();
  const dispatch = useDispatch();

  const [openCreate, setOpenCreate] = useState(false);
  const [headerDailogue, setHeaderDailogue] = useState("");
  const [dailogcomponent, setDilogcomponent] = useState("");

  const [followUpData, setFollowUpTaskData] = useState([]);
  const [click, setClick] = useState();
  const [followUpTaskDetails, setFollowUpTaskDetails] = useState();

  const { leadFollowUpAddSuccess } = useSelector((state) => {
    return state.followUpTaskInformationAdd;
  });

  const { leadFollowUpEditSuccess } = useSelector((state) => {
    return state.followUpTaskInformationUpdate;
  });

  const { leadFollowUpFindSuccess } = useSelector((state) => {
    return state.followUpTaskInformationFind;
  });

  let { leadDetailSuccess } = useSelector((state) => {
    return state.leadDetailFetch;
  });

  let { leadFollowUpStatusChangeSuccess } = useSelector((state) => {
    return state.activityStatusChange;
  });

  useEffect(() => {
    dispatch(leadDetailFetchAction(id));
  }, [dispatch, id]);

  useEffect(() => {
    dispatch(followUpTaskInformationFindAction(id));
  }, [
    dispatch,
    leadFollowUpAddSuccess,
    id,
    leadFollowUpStatusChangeSuccess,
    leadFollowUpEditSuccess,
  ]);

  useEffect(() => {
    if (leadFollowUpFindSuccess) {
      let concatData = [].concat(...leadFollowUpFindSuccess);
      setFollowUpTaskData(concatData);
      setFollowUpTaskDetails(undefined);
    }
  }, [leadFollowUpFindSuccess]);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenTaskCreate = (activity) => {
    let openTask = followUpData.filter((item) => item.status !== "closed");

    if (openTask.length < 2) {
      setOpenCreate(true);

      if (activity === "taskCreate") {
        setHeaderDailogue("Create Task");
        setDilogcomponent("taskCreate");
      }

      if (activity === "meetCreate") {
        setHeaderDailogue("Create Meeting");
        setDilogcomponent("meetCreate");
      }

      if (activity === "callCreate") {
        setHeaderDailogue("Schedule a call");
        setDilogcomponent("callCreate");
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "if you want to create activity you should close any activities",
      });
    }
  };
  const handleCloseCreate = () => {
    setOpenCreate(false);
  };
  return (
    <>
      <MainContainer active={props.toggle}>
        <InfoHeader headTitle="Follow up" url={`/Leads/details/${id}/All Leads/Leads`} />
        <Grid container sx={{ mt: 3 }}>
          <Grid item xs={12} lg={2.5}>
            <Paper variant="outlined" square>
              <div style={{ margin: "5px" }}>
                <SubmitButton
                  title="Create Activity"
                  submit=""
                  widthSize="100%"
                  heightSize=""
                  type="click"
                  handleSubmit={handleClick}
                />
              </div>
              {followUpData &&
                followUpData.length !== 0 &&
                followUpData.map((TaskData) => (
                  <Card
                    variant="outlined"
                    sx={{
                      height: "84px",
                      m: "5px",
                      cursor: "pointer",
                      backgroundColor: click === TaskData.id ? "#F1F5FF" : null,
                    }}
                    className="followupCard"
                    onClick={() => {
                      setFollowUpTaskDetails(TaskData);
                      setClick(TaskData.id);
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "'Open Sans', sans-serif",
                        fontSize: "10px",
                        pt: 0.5,
                        ml: 1,
                      }}
                      gutterBottom
                    >
                      {TaskData.activityType === "meet" ? (
                        <img
                          src="/menuIcons/createMeet.svg"
                          alt="gsl"
                          style={{ paddingTop: 2 }}
                        />
                      ) : TaskData.activityType === "task" ? (
                        <>
                          <img
                            src="/menuIcons/followTask.png"
                            alt="gsl_Follow"
                            style={{ paddingTop: 2 }}
                          />
                          &nbsp;&nbsp;&nbsp;
                        </>
                      ) : TaskData.activityType === "call" ? (
                        <>
                          <img
                            src="/menuIcons/createCall.svg"
                            alt="gsl_Follow"
                            style={{ paddingTop: 2 }}
                          />
                          &nbsp;&nbsp;&nbsp;
                        </>
                      ) : null}{" "}
                      {TaskData.subject} {""}
                      {TaskData?.lead && TaskData?.lead?.leadId}
                    </Typography>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: 10,
                      }}
                    >
                      <Typography
                        variant="small"
                        sx={{
                          fontFamily: "'Open Sans', sans-serif",
                          fontWeight: 700,
                          fontSize: "10px",
                          ml: 1,
                        }}
                      >
                        {TaskData.createdAt &&
                          format(new Date(TaskData.createdAt), "MMMM")}
                      </Typography>
                      <Typography
                        variant="small"
                        color="text.secondary"
                        sx={{
                          fontFamily: "'Open Sans', sans-serif",
                          fontWeight: 400,
                          fontSize: "12px",
                          mr: 1,
                        }}
                      >
                        {TaskData.priority}
                      </Typography>
                    </div>
                    <Divider />

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        margin: "15px",
                        height: "8px",
                      }}
                    >
                      <Typography
                        variant="small"
                        color="text.secondary"
                        sx={{
                          fontFamily: "'Open Sans', sans-serif",
                          fontWeight: 400,
                          fontSize: "12px",
                        }}
                      >
                        {TaskData.status}
                      </Typography>
                      <Typography
                        variant="small"
                        color="text.secondary"
                        sx={{
                          fontFamily: "'Open Sans', sans-serif",
                          fontWeight: 400,
                          fontSize: "12px",
                        }}
                      >
                        {TaskData?.lead?.firstName}
                      </Typography>
                    </div>
                  </Card>
                ))}
            </Paper>
          </Grid>
          <Grid item xs={12} lg={9.5}>
            <Paper
              elevation={0}
              sx={{
                marginLeft: "10px",
                height: "144px",
                border: "1px solid #ececec",
              }}
            >
              {/* <div style={{ display: "flex", justifyContent: "space-evenly" }}> */}
              <Grid container>
                <Grid item lg={4} xs={4}>
                  <div style={{ display: "flex", margin: 5 }}>
                    <Avatar
                      alt="Remy Sharp"
                      src={leadDetailSuccess?.leadDetails?.imgUrl}
                      sx={{ width: "75px", height: "75px", margin: "15px" }}
                    />
                    <div style={{ margin: "15px" }}>
                      <Typography
                        style={{
                          color: "text.secondary",
                          fontFamily: "'Open Sans', sans-serif",
                          fontWeight: 400,
                          fontSize: "13px",
                        }}
                        color="text.secondary"
                      >
                        {leadDetailSuccess?.leadDetails !== undefined &&
                          leadDetailSuccess?.leadDetails &&
                          leadDetailSuccess?.leadDetails.leadId}
                      </Typography>
                      <Typography
                        variant="h5"
                        sx={{
                          fontFamily: "'Montserrat', sans-serif",
                          fontWeight: 700,
                          fontSize: "24px",
                        }}
                      >
                        {leadDetailSuccess?.leadDetails !== undefined &&
                          leadDetailSuccess?.leadDetails &&
                          leadDetailSuccess?.leadDetails.firstName}{" "}
                        {leadDetailSuccess?.leadDetails?.lastName}
                      </Typography>
                    </div>
                  </div>
                </Grid>
                <Grid item lg={8} xs={8}>
                  <div
                    style={{ display: "flex", justifyContent: "space-around" }}
                  >
                    <div>
                      <Typography
                        style={{
                          fontFamily: "'Open Sans', sans-serif",
                          fontWeight: 400,
                          fontSize: "13px ",
                          margin: "7px",
                        }}
                      >
                        Phone
                      </Typography>
                      <Typography
                        style={{
                          fontFamily: "'Open Sans', sans-serif",
                          fontWeight: 400,
                          fontSize: "13px",
                          margin: "7px",
                        }}
                      >
                        Email ID
                      </Typography>
                      <Typography
                        style={{
                          fontFamily: "'Open Sans', sans-serif",
                          fontWeight: 400,
                          fontSize: "13px",
                          margin: "7px",
                        }}
                      >
                        Location
                      </Typography>
                      <Typography
                        style={{
                          fontFamily: "'Open Sans', sans-serif",
                          fontWeight: 400,
                          fontSize: "13px",
                          width: "10px",
                          margin: "5px",
                        }}
                      >
                        Preferred Country
                      </Typography>
                    </div>

                    <div
                      style={{
                        fontFamily: "Open Sans",
                        fontSize: "16px",
                        fontweight: 400,
                        letterSpacing: "0.4399999976158142px",
                      }}
                    >
                      <Typography
                        style={{
                          color: "text.secondary",
                          fontFamily: "'Open Sans', sans-serif",
                          fontWeight: 400,
                          fontSize: "15px",
                          margin: "7px",
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                        color="text.secondary"
                      >
                        {leadDetailSuccess?.leadDetails !== undefined &&
                          leadDetailSuccess?.leadDetails &&
                          leadDetailSuccess?.leadDetails?.mobileNumber}
                      </Typography>
                      <Typography
                        style={{
                          color: "text.secondary",
                          fontFamily: "'Open Sans', sans-serif",
                          fontWeight: 400,
                          fontSize: "15px",
                          margin: "7px",
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                        color="text.secondary"
                      >
                        {leadDetailSuccess?.leadDetails !== undefined &&
                          leadDetailSuccess?.leadDetails &&
                          leadDetailSuccess?.leadDetails?.email}
                      </Typography>
                      <Typography
                        style={{
                          color: "text.secondary",
                          fontFamily: "'Open Sans', sans-serif",
                          fontWeight: 400,
                          fontSize: "15px",
                          margin: "7px",
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                        color="text.secondary"
                      >
                        {leadDetailSuccess?.leadDetails &&
                          leadDetailSuccess?.leadDetails &&
                          leadDetailSuccess?.leadDetails?.location}
                      </Typography>
                      <Typography
                        style={{
                          fontFamily: "'Open Sans', sans-serif",
                          fontWeight: 400,
                          fontSize: "15px",
                          // margin: "7px",
                          display: "flex",
                          justifyContent: "flex-end",
                          flexDirection: "column",
                          alignItems: "flex-end",
                          textAlign: "right",
                        }}
                        color="text.secondary"
                      >
                        {leadDetailSuccess?.leadDetails !== undefined &&
                          leadDetailSuccess?.leadDetails &&
                          leadDetailSuccess?.leadDetails?.preferredCountryId}
                      </Typography>
                    </div>

                    <div
                      style={{
                        fontFamily: "Open Sans",
                        fontSize: "16px",
                        fontweight: 400,
                        letterSpacing: "0.4399999976158142px",
                        display: "flex",
                        alignItems: "flex-start",
                        margin: "12px",
                      }}
                    >
                      <Box
                        component="div"
                        sx={{
                          border: "0px solid #ececec",
                          height: "80px",
                          width: "85px",
                          background: "rgba(249, 249, 251, 1)",
                          textAlign: "center",
                          marginLeft: "10px",
                        }}
                      >
                        <Typography
                          color="text.secondary"
                          style={{
                            fontFamily: "'Open Sans', sans-serif",
                            fontWeight: 400,
                            fontSize: "15.5px",
                            paddingTop: "13px",
                            color: "#ACB1C6",
                          }}
                        >
                          Task:
                        </Typography>

                        <Typography
                          style={{
                            fontFamily: "'Montserrat', sans-serif",
                            fontWeight: 400,
                            fontSize: "15.5px",
                          }}
                        >
                          {followUpData
                            ? followUpData.filter(
                                (item) => item.activityType === "task"
                              ).length
                            : 0}
                        </Typography>
                      </Box>
                      <Box
                        component="div"
                        sx={{
                          border: "0px solid #ececec",
                          height: "80px",
                          width: "85px",
                          background: "rgba(249, 249, 251, 1)",
                          textAlign: "center",
                          marginLeft: "10px",
                        }}
                      >
                        <Typography
                          color="text.secondary"
                          style={{
                            fontFamily: "'Open Sans', sans-serif",
                            fontWeight: 400,
                            fontSize: "15.5px",
                            paddingTop: "13px",
                            color: "#ACB1C6",
                          }}
                        >
                          Meetings:
                        </Typography>

                        <Typography
                          style={{
                            fontFamily: "'Montserrat', sans-serif",
                            fontWeight: 400,
                            fontSize: "15.5px",
                          }}
                        >
                          {followUpData
                            ? followUpData.filter(
                                (item) => item.activityType === "meet"
                              ).length
                            : 0}
                        </Typography>
                      </Box>
                      <Box
                        component="div"
                        sx={{
                          border: "0px solid #ececec",
                          height: "80px",
                          width: "85px",
                          background: "rgba(249, 249, 251, 1)",
                          textAlign: "center",
                          marginLeft: "10px",
                        }}
                      >
                        <Typography
                          color="text.secondary"
                          style={{
                            fontFamily: "'Open Sans', sans-serif",
                            fontWeight: 400,
                            fontSize: "15.5px",
                            paddingTop: "13px",
                            color: "#ACB1C6",
                          }}
                        >
                          Calls:
                        </Typography>

                        <Typography
                          style={{
                            fontFamily: "'Montserrat', sans-serif",
                            fontWeight: 400,
                            fontSize: "15.5px",
                          }}
                        >
                          {followUpData
                            ? followUpData.filter(
                                (item) => item.activityType === "call"
                              ).length
                            : 0}
                        </Typography>
                      </Box>
                    </div>
                  </div>
                </Grid>
              </Grid>
              {/* </div> */}
            </Paper>
            <Box sx={{ mt: 2.5 }}>
              {followUpTaskDetails && (
                <TaskInformation
                  followUpDetails={followUpTaskDetails}
                  counsellorName={
                    leadDetailSuccess?.leadDetails !== undefined &&
                    leadDetailSuccess?.leadDetails &&
                    leadDetailSuccess?.leadDetails.counsilorID
                      ? leadDetailSuccess?.leadDetails.counsilorID
                      : "-"
                  }
                />
              )}
            </Box>
            <Box sx={{ mt: 2.5 }}>
              <OpenActivities followUpData={followUpData && followUpData} />
            </Box>

            <Box sx={{ mt: 2.5 }}>
              <CloseActivities followUpData={followUpData && followUpData} />
            </Box>

            {/* <Box sx={{ mt: 2.5 }}>
              {/* <SocialMediaSection followUpData={followUpData && followUpData} /> */}
            {/* </Box> */}
{/* 
            <Box sx={{ mt: 2.5 }}>
              <NoteSection />
            </Box> */}

            {/* <Box sx={{ mt: 2.5 }}>
              <AttachmentSection />
            </Box>

            <Box sx={{ mt: 2.5 }}>
              <ComposeMail />
            </Box>

            <Box sx={{ mt: 2.5 }}>
              <FollowLinks />
            </Box> */}
          </Grid>
        </Grid>

        {/* CREATE ACTIVITY DAILOGUE */}
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          classes={{
            paper: classes.menuPaper,
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          getContentAnchorEl={null}
        >
          <MenuItem
            onClick={() => handleOpenTaskCreate("taskCreate")}
            sx={{
              fontFamily: "'Open Sans', sans-serif",
              fontWeight: 700,
              fontSize: "15px",
            }}
          >
            <img
              src="/menuIcons/createTask.svg"
              alt="gsl"
              style={{ margin: "10px" }}
            />
            Create Task
          </MenuItem>
          <MenuItem
            onClick={() => handleOpenTaskCreate("meetCreate")}
            sx={{
              fontFamily: "'Open Sans', sans-serif",
              fontWeight: 700,
              fontSize: "15px",
            }}
          >
            <img
              src="/menuIcons/createMeet.svg"
              alt="gsl"
              style={{ margin: "10px" }}
            />
            Create Meeting
          </MenuItem>
          <MenuItem
            sx={{
              fontFamily: "'Open Sans', sans-serif",
              fontWeight: 700,
              fontSize: "15px",
            }}
            onClick={() => handleOpenTaskCreate("callCreate")}
          >
            <img
              src="/menuIcons/createCall.svg"
              alt="gsl"
              style={{ margin: "10px" }}
            />
            Create Call
          </MenuItem>
        </Menu>

        {/*  creat dialogue */}
        {openCreate && (
          <Dialogue
            openTrue={openCreate}
            handleClose={handleCloseCreate}
            headerName={headerDailogue}
            Dailogcomponent={dailogcomponent}
            appliedApplicationId=""
          />
        )}
      </MainContainer>
    </>
  );
}

export default MainFollowUp;
