import axios from "axios";
import {
  REFERRALS_FIND_ERR,
  REFERRALS_FIND_REQUEST,
  REFERRALS_FIND_SUCCESS,
  REFERRALS_STATUSCHANGE_ERR,
  REFERRALS_STATUSCHANGE_REQUEST,
  REFERRALS_STATUSCHANGE_SUCCESS,
} from "../constants/referAndEarn/referAndEarnConstant";
import { SUCCESS_TRUE_MSG_ERR } from "../constants/roleManagmentConstant";

//  referrals find action

export const referralsFindAction = () => async (dispatch, getState) => {
  try {
    dispatch({ type: REFERRALS_FIND_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.get("/api/admin/refer&earn/referrals", config);

    dispatch({ type: REFERRALS_FIND_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: REFERRALS_FIND_ERR, payload: error.response.data });
    dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: true });
  }
};

//  referrals statuschang action

export const referralsStatusChangeAction = (status, id) => async (
  dispatch,
  getState
) => {
  try {

    dispatch({ type: REFERRALS_STATUSCHANGE_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.post(
      "/api/admin/refer&earn/referralsStatusChange",
      { status, id },
      config
    );

    console.log(data,"dataaaaaaaaaaaaaaaaaaaaaa")

    dispatch({ type: REFERRALS_STATUSCHANGE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: REFERRALS_STATUSCHANGE_ERR,
      payload: error.response.data,
    });
    dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: true });
  }
};
