import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableFooter,
  TableSortLabel,
  Menu,
  Modal,
  Typography,
} from "@mui/material";

import { SlOptionsVertical } from "react-icons/sl";
import { BiUpload, BiDownload } from "react-icons/bi";

import { Box, Checkbox, MenuItem } from "@mui/material";
import { TbEdit } from "react-icons/tb";
import { AiOutlineEye } from "react-icons/ai";
import { RiDeleteBin6Line } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import { MdNotInterested } from "react-icons/md";
import { BiSortAlt2 } from "react-icons/bi";
import usePrivilegeCheck from "../../constants/customHooks/UsePrivilegeCheck";

import "./hrMain.css";
import {
  hrEmployeePayslipCreateAction,
  hrEmployeePayslipDeleteAction,
} from "../../actions/HrActions/hrEmployeeManagmentAction";
import PremissionDialog from "../customComponent/premissionDialog";
import TableNoItemComponent from "../customComponent/TableNoItemComponent";

// show role details popup
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "400",
  height: "400",
  //   overflow: "scroll",
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 20,
  p: 4,
};

export const EmployeePayslipTable = ({
  columns,
  data,
  component,
  subMenu,
  //   isPresentMap,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const PayslipInput = useRef();
  const [Payslip, setPaySlip] = useState("");

  // Payslip delete reducer

  let { hrEmployeePayslipDeleteSuccess } = useSelector((state) => {
    return state.hrEmployeePayslipDelete;
  });

  // Month

  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = currentDate.toLocaleString("en-US", { month: "long" });

  const monthName = `${month} ${year}`;

  // custom Hooks
  const { privilege, hasPrivilege, isUpdatePrevilage, isDeletePrevilage } =
    usePrivilegeCheck();

  const [openAlert, setOpenAlert] = useState(false);

  // HandleClose dialog

  const handleCloseDialog = () => {
    setOpenAlert(false);
  };

  const [actionButtonShow, setActionButtonShow] = useState(null);
  const [tableId, setTableId] = useState("");
  const [open, setOpen] = useState(false);
  const [EmploayeeId, setEmployeeId] = useState("");

  const openActionButton = Boolean(actionButtonShow);

  const handleClickAction = (event, id) => {
    console.log(id, "evide id kitttuvo");
    setActionButtonShow(event.currentTarget);
    setTableId(id);
  };

  const handleCloseAction = () => {
    setActionButtonShow(null);
  };

  // Handle_Upload_Payslip

  const handleUpload = (id) => {
    if (isUpdatePrevilage(subMenu)) {
      PayslipInput.current.click();
      setEmployeeId(id);
    } else {
      setOpenAlert(true);
    }
  };

  const handleUploadPayslip = (event, Month) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    // Read the image file as a data URL
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      const Payslip = reader.result;
      console.log(Payslip, ":::::::::::::::::::::::::::::::::;;;;");
      setPaySlip(Payslip);
      dispatch(hrEmployeePayslipCreateAction(Payslip, EmploayeeId, Month));
    };
  };

  const CustomSortIcon = ({ direction }) => {
    return direction === "asc" ? (
      <img
        src="/menuIcons/tableUpSort.png"
        className="administratCustomIcon"
        alt="gsl"
        style={{ padding: "15px" }}
      />
    ) : (
      <BiSortAlt2 className="administratCustomIcon" />
    );
  };

  // sorting table
  const [sortBy, setSortBy] = useState(""); // Current sort column
  const [sortOrder, setSortOrder] = useState("asc"); // Current sort order

  const requestSort = (column) => {
    let newSortOrder = "asc";

    if (column === sortBy && sortOrder === "asc") {
      newSortOrder = "desc";
    }

    setSortBy(column);
    setSortOrder(newSortOrder);
  };
  let sortedData = useMemo(() => {
    if (sortBy && data) {
      const sortedItems = [...data].sort((a, b) => {
        if (sortOrder === "asc") {
          return a[sortBy] < b[sortBy] ? -1 : 1;
        } else {
          return a[sortBy] < b[sortBy] ? 1 : -1;
        }
      });
      return sortedItems;
    }
    return data;
  }, [data, sortBy, sortOrder]);

  const handleClose = () => setOpen(false);

  // Create a function to handle selecting individual rows:

  // if (!hasPrivilege("isadministrationView") && privilege !== null) {
  //   return <div>You don't have permission to access this page.</div>;
  // }

  // Handle Method to delete PaySlip

  const handleDeletePaySlip = (id) => {
    dispatch(hrEmployeePayslipDeleteAction(id, monthName));
  };

  return (
    <>
      {sortedData.length > 0 ? (
        <Table className="hrTable">
          <TableHead>
            <TableRow className="hrTableCell">
              <TableCell>
                <Checkbox
                  // checked={selectedRows.length === sortedData.length}
                  // onChange={handleSelectAllRows}
                  style={{ color: "#f81b82" }}
                />
              </TableCell>
              {columns?.map((column) => {
                if (column.hidden) {
                  return null;
                }
                return (
                  <TableCell key={column.field} className="hrTableCell">
                    <TableSortLabel
                      className="hrHeaderText"
                      active={column === sortBy}
                      direction={sortOrder}
                      onClick={() => requestSort(column)}
                      IconComponent={
                        column.field !== "action" && CustomSortIcon
                      }
                    >
                      {column.title}
                    </TableSortLabel>
                  </TableCell>
                );
              })}
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedData?.map((item, indx) => (
              <TableRow className="hrTable" key={indx}>
                <TableCell>
                  <Checkbox
                    // checked={selectedRows.includes(item.id)}
                    // onChange={() => handleSelectRow(item.id)}
                    style={{ color: "#f81b82" }}
                  />
                </TableCell>
                <TableCell>{item.sl}</TableCell>
                <TableCell>{item.coloumn1}</TableCell>
                <TableCell>{item.coloumn2}</TableCell>
                <TableCell>{item.coloumn3}</TableCell>
                <TableCell>{monthName}</TableCell>

                <TableCell>
                  {item.coloumn4 == null ? (
                    <label
                      onClick={() => handleUpload(item.id)}
                      style={{
                        color: "white",
                        backgroundColor: "#ACB1C6",
                        padding: "5px",
                        paddingLeft: "10px",
                        paddingRight: "10px",
                        borderRadius: "5px",
                      }}
                    >
                      <BiUpload
                        style={{
                          marginRight: "10px",
                          height: "20px",
                          width: "25px",
                        }}
                      />
                      Upload
                      {/* {item.coloumn4 ==null ? "Upload" : "Download"} */}
                    </label>
                  ) : (
                    <a
                      style={{
                        color: "white",
                        backgroundColor: "#ACB1C6",
                        padding: "5px",
                        paddingLeft: "10px",
                        paddingRight: "10px",
                        borderRadius: "5px",
                        textDecoration: "none",
                      }}
                      href={item.coloumn4}
                      download={item.coloumn4}
                      target="blank"
                    >
                      <BiDownload
                        style={{
                          marginRight: "10px",
                          height: "20px",
                          width: "25px",
                        }}
                      />
                      Download
                      {/* {item.coloumn4 ==null ? "Upload" : "Download"} */}
                    </a>
                  )}
                  <input
                    type="file"
                    accept="application/pdf"
                    ref={PayslipInput}
                    onChange={(event) => handleUploadPayslip(event, monthName)}
                    style={{ display: "none" }}
                  ></input>
                </TableCell>

                <TableCell>
                  <SlOptionsVertical
                    id="demo-positioned-button"
                    aria-controls={
                      openActionButton ? "demo-positioned-menu" : undefined
                    }
                    aria-haspopup="true"
                    aria-expanded={openActionButton ? "true" : undefined}
                    onClick={(e) => {
                      console.log("evidenkilum vannno");
                      handleClickAction(e, item.id);
                    }}
                    variant="outlined"
                    style={{ marginLeft: "10px", color: "#F80B7A" }}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter />
        </Table>
      ) : (
        <TableNoItemComponent />
      )}
      {/* TABLE ACTION MENU START*/}
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={actionButtonShow}
        open={openActionButton}
        onClose={handleCloseAction}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        anchorOrigin={{
          vertical: "bottom", // Change from "top" to "bottom"
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top", // Change from "top" to "bottom"
          horizontal: "left",
        }}
        sx={{
          borderColor: "black",
          borderRadius: "24px",
          mt: 1.5,
          width: "auto",
          height: "auto",
        }}
      >
        <Box style={{ display: "flex", justifyContent: "flex-end" }}>
          <MenuItem>
            {/* {component !=="holiday" ? (
                <RiDeleteBin6Line
                  onClick={() => RoledeleteHandler(tableId)}
                  className="icons"
                  style={{
                    fontSize: "lg",
                    width: "20px",
                    height: "19px",
                  }}
                />
              ) : ( */}
            <RiDeleteBin6Line
              onClick={() => {
                if (isDeletePrevilage(subMenu)) {
                  setActionButtonShow(null);
                  Swal.fire({
                    title: "Are you sure?",
                    text: "You won't be able to revert this!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes, delete it!",
                  }).then((result) => {
                    if (result.isConfirmed) {
                      console.log("aravide");
                      handleDeletePaySlip(tableId);
                      setActionButtonShow(false);
                      Swal.fire(
                        "Deleted!",
                        "Your file has been deleted.",
                        "success"
                      );
                    }
                  });
                } else {
                  setOpenAlert(true);
                }
              }}
              style={{
                fontSize: "lg",
                width: "20px",
                height: "19px",
              }}
              className="icons"
            />
          </MenuItem>
        </Box>
      </Menu>
      {openAlert && (
        <PremissionDialog
          openAlert={openAlert}
          handleClose={handleCloseDialog}
        />
      )}
      {/* view modal */}
    </>
  );
};
