import React, { useEffect, useState } from "react";
import { Alert, AlertTitle, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import usePrivilegeCheck from "../../../constants/customHooks/UsePrivilegeCheck";
import DownloadExcel from "../../extraComponents/DownloadExcel";
import DownloadPdf from "../../extraComponents/DownloadPdf";
import ApprovalSearchHeader from "./ApprovalSearchHeader";
import ApprovalFilterSearch from "./ApprovalFilterSearch";
import {tutionFeepaymentApprovalFindAction } from "../../../actions/paymentApprovalAction";
import TutionFeeApprovalTable from "./tutionFeeApprovalTable";

function TutionFeeApprovalMain(props) {
  const dispatch = useDispatch();
  const [filterPassData, setFilterPassData] = useState();
  const [whichFilter, setwhichFilter] = useState();
  const [reset, setReset] = useState(false);
  const [leadData, setLeadData] = useState([]);
  const [paymentApprovallist, setPaymentApprovalList] = useState([]);

  const { privilege, hasPrivilege } = usePrivilegeCheck();

  // Employee column Visibility

  const [showNameColumn, setshowNameColumn] = useState(false);
  const [showDateColumn, setshowDateColumn] = useState(false);
  const [showMailIdColumn, setshowMailIdColumn] = useState(false);
  const [showPhoneNumberColumn, setshowPhoneNumberColumn] = useState(false);
  const [
    showCountryOfResidenceColumn,
    setShowCountryResidenceColumn,
  ] = useState(false);
  const [showReferredByColumn, setshowReferredByColumn] = useState(false);
  const [showStatusColumn, setShowStatusColumn] = useState(false);

  const [columnName, setColumnName] = useState("");
  const [leadId, setLeadId] = useState([]);
  const [ids, setIds] = useState([]);

  // tabel columns

  const columns = [
    {
      title: "SL NO",
      field: "sl",
    },
    {
      title: "STUDENTNAME",
      field: "STUDENTNAME",
      hidden: showNameColumn,
    },
    {
      title: "EMPLOYEE NAME",
      field: "EMPLOYEE NAME",
      hidden: showDateColumn,
    },
    {
      title: "EMPLOYEE ROLE",
      field: "EMPLOYEE ROLE",
      hidden: showDateColumn,
    },
    {
      title: "Email",
      field: "Email",
      hidden: showMailIdColumn,
    },
    {
      title: "AMOUNT",
      field: "AMOUNT",
      hidden: showPhoneNumberColumn,
    },
    {
      title: "DATE & TIME",
      field: "DATE & TIME",
      hidden: showCountryOfResidenceColumn,
    },
    {
      title: "REMARK",
      field: "REMARK",
      hidden: showReferredByColumn,
    },
    {
      title: "RECEIPT",
      field: "RECEIPT",
      hidden: showStatusColumn,
    },
    {
      title: "ACTION",
      field: "ACTION",
      hidden: showStatusColumn,
    },
  ];

  // // Excel export function
  // const excelDownloadClick = () => {
  //   let columnName = columns.filter((value) => value.hidden == false);
  //   console.log(columnName, "columnNameeeeeeeeeee");
  //   console.log(paymentApprovallist, "paymentApprovallistpaymentApprovallist");
  //   const data = paymentApprovallist.map((value, index) => ({
  //     sl: columns[index].hidden ? false : index + 1,
  //     coloumn1: columns[index].hidden ? false : value.teacherName,
  //     coloumn2: columns[index].hidden ? false : value.teacherEmail,
  //     coloumn3: columns[index].hidden ? false : value.teacherPhone,
  //     coloumn4: columns[index].hidden ? false : value.center,
  //     coloumn5: columns[index].hidden ? false : value.teacherSpecialization,
  //     coloumn6: columns[index].hidden ? false : value.status,
  //   }));

  //   console.log(data, "datatatattata564s654644665676754");
  //   DownloadExcel(columnName, data, "EntrolledTeacherList");
  // };

  // // Pdf Export function

  // const pdfDownloadClick = () => {
  //   let columnName = columns.filter((value) => value.hidden == false);
  //   DownloadPdf(
  //     columnName,
  //     paymentApprovallist,
  //     "Entrolled Teacher List",
  //     "Entrolled Teacher List"
  //   );
  // };

  // const toggleNameColumn = (status, column) => {
  //   if (column === "name") {
  //     setColumnName(column);
  //     setshowNameColumn(status);
  //   }

  //   if (column === "mailId") {
  //     setColumnName(column);
  //     setshowDateColumn(status);
  //   }
  //   if (column === "mobile") {
  //     setColumnName(column);
  //     setshowMailIdColumn(status);
  //   }
  //   if (column === "center") {
  //     setColumnName(column);
  //     setshowPhoneNumberColumn(status);
  //   }
  //   if (column === "specilization") {
  //     setColumnName(column);
  //     setShowCountryResidenceColumn(status);
  //   }
  //   if (column === "status") {
  //     setColumnName(column);
  //     setShowStatusColumn(status);
  //   }
  // };

  // useEffect(() => {
  //   // Retrieve column visibility state from localStorage
  //   const storedteacherName = localStorage.getItem("teacherName");
  //   const storedMail = localStorage.getItem("mailId");
  //   const storedMobile = localStorage.getItem("mobile");
  //   const storedCenter = localStorage.getItem("center");
  //   const storedSpecilization = localStorage.getItem("specilization");
  //   const storedStatus = localStorage.getItem("status");

  //   if (storedteacherName !== null) {
  //     setColumnName("teacherName");
  //     setshowNameColumn(JSON.parse(storedteacherName));
  //   }

  //   if (storedMail !== null) {
  //     setColumnName("mailId");
  //     setshowDateColumn(JSON.parse(storedMail));
  //   }
  //   if (storedMobile !== null) {
  //     setColumnName("mobile");
  //     setshowMailIdColumn(JSON.parse(storedMobile));
  //   }
  //   if (storedCenter !== null) {
  //     setColumnName("center");
  //     setshowPhoneNumberColumn(JSON.parse(storedCenter));
  //   }
  //   if (storedSpecilization !== null) {
  //     setColumnName("specilization");
  //     setShowCountryResidenceColumn(JSON.parse(storedSpecilization));
  //   }
  //   if (storedStatus !== null) {
  //     setColumnName("status");
  //     setShowStatusColumn(JSON.parse(storedStatus));
  //   }
  // }, []);

  // useEffect(() => {
  //   // Save column visibility state to localStorage
  //   localStorage.setItem("teacherName", JSON.stringify(showNameColumn));
  //   localStorage.setItem("mailId", JSON.stringify(showDateColumn));
  //   localStorage.setItem("mobile", JSON.stringify(showMailIdColumn));
  //   localStorage.setItem("center", JSON.stringify(showPhoneNumberColumn));
  //   localStorage.setItem(
  //     "specilization",
  //     JSON.stringify(showCountryOfResidenceColumn)
  //   );
  //   localStorage.setItem("status", JSON.stringify(showStatusColumn));
  // }, [
  //   showNameColumn,
  //   showDateColumn,
  //   showMailIdColumn,
  //   showPhoneNumberColumn,
  //   showCountryOfResidenceColumn,
  //   showReferredByColumn,
  //   showStatusColumn,
  //   columnName,
  // ]);

  // Find referred students

  let { tutionFeePaymentApprovalFindSuccess, tutionFeePaymentApprovalFindErr } = useSelector(
    (state) => {
      return state.tutionFeePaymentApprovalFind;
    }
  );

  let {
    tutionFeePaymentApprovalApproveSuccess,
    tutionFeePaymentApprovalApproveErr,
  } = useSelector((state) => {
    return state.tutionFeePaymentApprovalApprove;
  });

  useEffect(() => {
    dispatch(tutionFeepaymentApprovalFindAction());
  }, [dispatch, tutionFeePaymentApprovalApproveSuccess]);

  console.log(
    tutionFeePaymentApprovalFindSuccess,
    "tutionFeePaymentApprovalFindSuccesstutionFeePaymentApprovalFindSuccess"
  );

  useEffect(() => {
    if (tutionFeePaymentApprovalFindSuccess) {
      setPaymentApprovalList(tutionFeePaymentApprovalFindSuccess.data);
    }
  }, [tutionFeePaymentApprovalFindSuccess, reset]);

  // Handle SearchFunction

  const searchHandleChange = (value) => {
    if (value) {
      setPaymentApprovalList(
        paymentApprovallist?.filter((item) => {
          const countryMatch = item.country
            ? item.country.toLowerCase().includes(value.toLowerCase())
            : false;
          const nameMatch = item.name
            .toLowerCase()
            .includes(value.toLowerCase());
          return countryMatch || nameMatch;
        })
      );
    } else {
      setPaymentApprovalList(tutionFeePaymentApprovalFindSuccess.data);
    }
  };

  const setIdsFunction = (leadsId) => {
    setLeadId(leadsId);
  };

  const selectBoxIdPassHandler = (ids) => {
    setIds(ids);
  };

  if (!hasPrivilege("TutionFee Approval") && privilege !== null) {
    return (
      <Alert severity="error">
        <AlertTitle>warning</AlertTitle>
        You don't have permission to access — <strong>this page.!</strong>
      </Alert>
    );
  }

  // // handleFilter Data

  const LeadFilterFunction = (data, filterOption, checkedStatus) => {
    if (filterOption === "status") {
      if (checkedStatus === true) {
        const filterData = tutionFeePaymentApprovalFindSuccess.data?.filter(
          (value) => data == value.status
        );
        setPaymentApprovalList(filterData);
        setwhichFilter(filterOption);
        setFilterPassData(data);
      } else {
        setwhichFilter(null);
        setFilterPassData(null);
        setPaymentApprovalList(tutionFeePaymentApprovalFindSuccess.data);
      }
    }
    if (filterOption === "Date") {
      const date = new Date(data);
      const adjustedDate = new Date(
        date.getTime() - date.getTimezoneOffset() * 60000
      );
      const formattedDate = adjustedDate.toISOString().split("T")[0];

      const filterData = tutionFeePaymentApprovalFindSuccess.data?.filter(
        (value) => formattedDate == value.createdAt.split("T")[0]
      );

      console.log(filterData, "filterDataaaa");
      setPaymentApprovalList(filterData);
      // setwhichFilter(filterOption);
      // setFilterPassData("");
    }
  };

  // handleResetData

  const handleResetData = (status) => {
    setwhichFilter(null);
    setFilterPassData(null);
    setReset(!reset);
    setwhichFilter(null);
    setFilterPassData(null);
  };

  return (
    <>
      <ApprovalSearchHeader
        // columnVisibleHandler={toggleNameColumn}
        // excelDownloadClick={excelDownloadClick}
        // pdfDownloadClick={pdfDownloadClick}
        showNameColumnColumn={showNameColumn}
        showDateColumn={showDateColumn}
        showPhoneNumberColumn={showPhoneNumberColumn}
        showMailIdColumn={showMailIdColumn}
        showCountryOfResidenceColumn={showCountryOfResidenceColumn}
        showReferredByColumn={showReferredByColumn}
        showStatusColumn={showStatusColumn}
        handleResetData={handleResetData}
        searchHandleChange={searchHandleChange}
        ids={ids}
        leadId={leadId}
      />
      {/* <div style={{display:"flex",justifyContent:"space-evenly", width:"auto"}}> */}
      <Grid container spacing={-3} sx={{ mt: "-31px" }}>
        <Grid item xs={12} md={2} lg={2.2}>
          <ApprovalFilterSearch
            LeadFilterFunction={LeadFilterFunction}
            handleResetData={handleResetData}
          />
        </Grid>
        <Grid item xs={12} md={10} lg={9.8}>
          <TutionFeeApprovalTable
            toggle={props.toggle}
            showNameColumnColumn={showNameColumn}
            showDateColumn={showDateColumn}
            showPhoneNumberColumn={showPhoneNumberColumn}
            showMailIdColumn={showMailIdColumn}
            showCountryOfResidenceColumn={showCountryOfResidenceColumn}
            showReferredByColumn={showReferredByColumn}
            showStatusColumn={showStatusColumn}
            reset={reset}
            setIdsFunction={setIdsFunction}
            data={paymentApprovallist}
            // leadFindLoading={leadFindLoading}
            selectBoxIdPassHandler={selectBoxIdPassHandler}
            // isExistCounsilor={loginFindSuccess}
            component="EmployeeJob"
            columns={columns}
            subMenu="TutionFee Approval"
          />
        </Grid>
      </Grid>
    </>
  );
}

export default TutionFeeApprovalMain;
