import React, { useEffect, useState } from "react";
import { Box, Button, Chip, Divider, TextField, Typography } from "@mui/material";
import InputField, {
  SelectInputField,
} from "../../../../../customComponent/InputField";
import {
  ieltsCourseManagmentCourseCenterAction,
  ieltsCourseManagmentCourseSlotAction,
  ieltsCourseManagmentCreateCourseAction,
} from "../../../../../../actions/IeltsActions/courseManagmentAction";
import { useDispatch, useSelector } from "react-redux";

function OfflineTab() {
  let { ieltsCourseManagmentCourseCreateSuccess } = useSelector((state) => {
    return state.ieltsCourseManagmentCreateCourse;
  });

  let { ieltsCourseManagmentCourseCenterFindSuccess } = useSelector((state) => {
    return state.ieltsCourseManagmentCourseCenter;
  });

  let {
    ieltsCourseManagmentCourseSlotSuccess,
    ieltsCourseManagmentCourseSloterror,
  } = useSelector((state) => {
    return state.ieltsCourseManagmentCoursesSlot;
  });

  const dispatch = useDispatch();
  const [selectedSlots, setSelectedSlots] = useState([]);
  const [center, setCenter] = useState([]);
  const [centerSlot, setCenterSlot] = useState([]);
  const [slotId, setSlotId] = useState([]);
  const [slots, setSlots] = useState({
    center: "",
    day: "",
    timeSlot: [],
    availability: "",
  });

  // console.log(selectedSlots, "selectedSlotsselectedSlotsselectedSlots");
  useEffect(() => {
    if (ieltsCourseManagmentCourseCreateSuccess) {
      setSelectedSlots(ieltsCourseManagmentCourseCreateSuccess);
    }
  }, [ieltsCourseManagmentCourseCreateSuccess]);

  useEffect(() => {
    dispatch(ieltsCourseManagmentCourseCenterAction());
  }, [dispatch]);

  useEffect(() => {
    if (ieltsCourseManagmentCourseCenterFindSuccess) {
      setCenter(ieltsCourseManagmentCourseCenterFindSuccess.data);
    }
  }, [ieltsCourseManagmentCourseCenterFindSuccess]);

  useEffect(() => {
    if (ieltsCourseManagmentCourseSlotSuccess) {
      if (slotId?.length !== 0) {
        // Filter out objects with ids present in idsArray
        const resultArray = ieltsCourseManagmentCourseSlotSuccess?.data?.filter(
          (obj) => !slotId.includes(obj.id)
        );
        // Display the result
        // console.log(resultArray,"resultArray");
        setCenterSlot(resultArray);
      } else {
        setCenterSlot(ieltsCourseManagmentCourseSlotSuccess.data);
      }
    }
  }, [ieltsCourseManagmentCourseSlotSuccess]);

  // console.log(centerSlot, "centerSlotcenterSlotcenterSlotcenterSlot");

  const handlechange = (e) => {
    const { name, value } = e.target;

    // console.log(value, "valueeeee");

    if (name === "availability") {
      console.log(value)
      const inputNumber = value;
      if (/^\d+$/.test(inputNumber) || value === "" ) {
        setSlots((prevSlots) => ({
          ...prevSlots,
          [name]: inputNumber,
        }));
      } else {
        alert("please enter valid number");
      }
    } else {
      if (name == "center") {
        // console.log(value, "slotttttttttttttttttt");
        dispatch(ieltsCourseManagmentCourseSlotAction(value));
      }
      setSlots((prevSlots) => ({
        ...prevSlots,
        [name]: value,
      }));
      // setCenterSlot(centerSlot.filter((data) => data.id != value));
    }
  };

  // console.log(slotId, "slotIdslotId");

  const classSubmit = (e) => {
    e.preventDefault();
    if (
      slots.center &&
      // slots.day &&
      slots.timeSlot &&
      slots.availability
    ) {
      const formData = {
        center: center.find((c) => c.id === slots.center)?.CenterName || "",
        // day: day.find((d) => d.id === slots.day)?.name || "",
        slots: centerSlot.filter((list) => {
          if (slots.timeSlot && slots.timeSlot === list.id) {
            return true;
          }
          return false;
        }),
        availability: slots.availability,
      };
      console.log(formData, "formDataaa");
      setSelectedSlots((prevData) => {
        const updatedState = {
          ...prevData,
          offline: {
            ...prevData.offline,
            slots: [...prevData.offline.slots, formData],
          },
        };
        dispatch(ieltsCourseManagmentCreateCourseAction(updatedState));

        return updatedState;
      });

      setSlotId((prev) => [...prev, slots.timeSlot]);

      setSlots({ center: "", day: "", timeSlot: [], availability: "" });
    } else {
      alert("Please fill all the fields");
    }
  };

  const handleDelete = (indexToDelete) => {
    setSelectedSlots((prevData) => {
      const updatedState = {
        ...prevData,
        offline: {
          ...prevData.offline,
          slots: prevData.offline.slots.filter(
            (_, index) => index !== indexToDelete
          ),
        },
      };
      dispatch(ieltsCourseManagmentCreateCourseAction(updatedState));

      return updatedState;
    });
  };
  console.log(selectedSlots?.offline?.slots, "selectedSlotttt");
  return (
    <Box
      sx={{
        marginLeft: "-24px",
        display: "grid",
        gridTemplateColumns: "1fr 35.2%",
        gap: "16px",
      }}
    >
      <Box
        component={"form"}
        onSubmit={classSubmit}
        sx={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr 1fr 65px",
          gap: "17px",
          maxWidth: "1154px",
        }}
      >
        <SelectInputField
          mapValue={center}
          value={slots.center}
          name="center"
          mapMethod={"ieltsoffline"}
          label={"Select Center"}
          handleSelectChange={handlechange}
        />
        {/* <SelectInputField
          value={slots.day}
          name="day"
          label={"Select Day"}
          mapValue={day}
          mapMethod={"ieltsoffline"}
          handleSelectChange={handlechange}
        /> */}
        <SelectInputField
          value={slots.timeSlot}
          multiple={"false"}
          name="timeSlot"
          label={"Select Slots"}
          mapValue={centerSlot}
          mapMethod={"ieltsSlot"}
          handleSelectChange={handlechange}
        />
        <Box
          sx={{
            // "& .input-group": {
            //   marginBottom: "0",
            // },
            // "& input": {
            //   boxSizing:"border-box",
            //   height: "100%",
            // },
            "& .floating_label_field":{
              fontSize: "14px !important",
            }
          }}
        >
          <InputField
            name="availability"
            handleChange={handlechange}
            value={slots.availability}
            label="Seat Availability"
            //   handleChange={(e) => {
            //     setName(e.target.value);
            //   }}
            //   value={name}
            widthSize="calc(100% - 12px)"
            paramses
            InputType="text"
            dispcriptionHeight
          />

          {/* <TextField
            id="outlined-basic"
            name="availability"
            label="Seat Availability"
            variant="outlined"
            value={slots.availability}
            handleChange={handlechange}
          /> */}
        </Box>
        <Button
          type="submit"
          sx={{
            maxWidth: "65px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            border: "1px solid rgba(236, 236, 236, 1)",
            height: "47px",
          }}
          onClick={classSubmit}
        >
          <svg
            width="33"
            height="34"
            viewBox="0 0 33 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              y="14.8906"
              width="33"
              height="4.21277"
              rx="2.10638"
              fill="#3B478F"
            />
            <rect
              x="18.6055"
              y="0.5"
              width="33"
              height="4.21277"
              rx="2.10638"
              transform="rotate(90 18.6055 0.5)"
              fill="#3B478F"
            />
          </svg>
        </Button>
      </Box>

      {selectedSlots?.offline?.slots?.length > 0 && (
        <Box
          sx={{
            padding: "10px",
            borderRadius: "4px",
            border: "1px solid #CACACA",
            display: "flex",
            flexDirection: "column",
            gap: "16px",
          }}
        >
          {selectedSlots?.offline?.slots?.map((item, i) => (
            <Box
              key={i}
              sx={{
                padding: "12px",
                borderRadius: "4px",
                border: "1px solid #141E3C",
                display: "flex",
                flexDirection: "column",
                gap: "10px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  "& svg": {
                    cursor: "pointer",
                  },
                }}
              >
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "700",
                    color: "#141E3C",
                  }}
                >
                  {item.center}
                </Typography>
                <svg
                  onClick={() => handleDelete(i)}
                  width="15"
                  height="15"
                  viewBox="0 0 15 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M5.00476 0.416455C5.00478 0.306109 5.04861 0.200286 5.12662 0.122241C5.20462 0.0441952 5.31043 0.000312026 5.42077 0.000234375L9.58066 0C9.69104 0.000255878 9.79681 0.0442606 9.87479 0.122369C9.95277 0.200477 9.9966 0.306317 9.99668 0.416689V1.44551H5.00476V0.416455ZM11.8444 14.404C11.8338 14.5663 11.7616 14.7183 11.6426 14.829C11.5235 14.9397 11.3667 15.0008 11.2041 14.9996H3.7495C3.58698 14.9992 3.43064 14.9373 3.31188 14.8264C3.19312 14.7154 3.12073 14.5636 3.10925 14.4015L2.47134 5.06994H12.5253L11.8446 14.4039L11.8444 14.404ZM13.5017 4.22479H1.5V3.25799C1.50018 3.00157 1.6021 2.75571 1.7834 2.57438C1.96469 2.39305 2.21053 2.29107 2.46694 2.29084L12.5346 2.29052C12.791 2.29091 13.0368 2.39298 13.218 2.57433C13.3993 2.75568 13.5012 3.00151 13.5014 3.2579V4.2247L13.5017 4.22479ZM5.5684 12.7751C5.5684 12.8306 5.57933 12.8855 5.60056 12.9368C5.62179 12.9881 5.65292 13.0346 5.69215 13.0739C5.73139 13.1131 5.77796 13.1442 5.82923 13.1655C5.88049 13.1867 5.93543 13.1976 5.99092 13.1976C6.0464 13.1976 6.10135 13.1867 6.15261 13.1655C6.20387 13.1442 6.25045 13.1131 6.28968 13.0739C6.32892 13.0346 6.36004 12.9881 6.38128 12.9368C6.40251 12.8855 6.41344 12.8306 6.41344 12.7751V6.8499C6.41255 6.73847 6.36767 6.6319 6.28857 6.55341C6.20948 6.47491 6.10257 6.43085 5.99114 6.43081C5.8797 6.43077 5.77277 6.47476 5.69361 6.5532C5.61446 6.63164 5.56951 6.73818 5.56855 6.84961V12.7751H5.5684ZM8.58305 12.7751C8.58305 12.8872 8.62757 12.9947 8.70682 13.0739C8.78607 13.1532 8.89355 13.1977 9.00563 13.1977C9.1177 13.1977 9.22518 13.1532 9.30443 13.0739C9.38368 12.9947 9.4282 12.8872 9.4282 12.7751V6.8499C9.42731 6.73846 9.38243 6.63187 9.30332 6.55337C9.22422 6.47486 9.11729 6.43079 9.00584 6.43075C8.89439 6.43071 8.78744 6.47471 8.70828 6.55316C8.62912 6.63161 8.58416 6.73816 8.58319 6.84961L8.58305 12.7751Z"
                    fill="#141E3C"
                  />
                </svg>
              </Box>
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: "400",
                  color: "#686868",
                }}
              >
                {item.day}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  gap: "10px",
                  flexWrap: "wrap",
                }}
              >
                {item.slots.map((slot, i) => (
                  <Chip
                    key={i}
                    sx={{
                      "& .MuiChip-label": {
                        pl: "8px",
                        pr: "8px",
                      },
                      "&.MuiChip-root": {
                        borderRadius: "8px",
                        border: "1px solid rgba(206, 206, 206, 1)",
                        backgroundColor: "rgba(255, 255, 255,1)",
                      },
                    }}
                    label={
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: "5px",
                          padding: "6px",
                          color: "rgba(20, 30, 60, 1)",
                        }}
                      >
                        <Typography>
                          {slot.timeFrom + " " + slot.timeTo}
                        </Typography>
                        <Divider orientation="vertical" flexItem />
                        <Typography>{item.availability} Slots</Typography>
                      </Box>
                    }
                  />
                ))}
              </Box>
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
}

export default OfflineTab;
