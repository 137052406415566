import { CREATE_REFERAL_FAQ_ERR, CREATE_REFERAL_FAQ_REQUEST, CREATE_REFERAL_FAQ_SUCCESS, CREATE_REFERAL_SETTINGS_ERR, CREATE_REFERAL_SETTINGS_REQUEST, CREATE_REFERAL_SETTINGS_SUCCESS, DELETE_REFERAL_FAQ_ERR, DELETE_REFERAL_FAQ_REQUEST, DELETE_REFERAL_FAQ_SUCCESS, GET_REFERAL_FAQ_ERR, GET_REFERAL_FAQ_REQUEST, GET_REFERAL_FAQ_SUCCESS, GET_REFERAL_SETTINGS_ERR, GET_REFERAL_SETTINGS_REQUEST, GET_REFERAL_SETTINGS_SUCCESS, REFERAL_WITHDRAW_DETAILS_ERR, REFERAL_WITHDRAW_DETAILS_REQUEST, REFERAL_WITHDRAW_DETAILS_SUCCESS, REFERRALS_FIND_ERR, REFERRALS_FIND_REQUEST, REFERRALS_FIND_SUCCESS, REFERRALS_STATUSCHANGE_ERR, REFERRALS_STATUSCHANGE_REQUEST, REFERRALS_STATUSCHANGE_SUCCESS, UPDATE_REFERAL_FAQ_ERR, UPDATE_REFERAL_FAQ_REQUEST, UPDATE_REFERAL_FAQ_SUCCESS, WITHDRAW_REFERAL_ERR, WITHDRAW_REFERAL_REQUEST, WITHDRAW_REFERAL_SUCCESS, WITHDRAW_STATUS_CHANGE_REFERAL_ERR, WITHDRAW_STATUS_CHANGE_REFERAL_REQUEST, WITHDRAW_STATUS_CHANGE_REFERAL_SUCCESS } from "../../constants/referAndEarn/referAndEarnConstant";

export const createReferalSettingsReducer = (state={},action) => {
   switch (action.type) {
     case CREATE_REFERAL_SETTINGS_REQUEST:
       return {
         ...state,
         createReferalSettingsLoading: true,
       };
     case CREATE_REFERAL_SETTINGS_SUCCESS:
       return {
         ...state,
         createReferalSettingsLoading: false,
         createReferalSettingsSuccess: action.payload,
       };
     case CREATE_REFERAL_SETTINGS_ERR:
       return {
         ...state,
         createReferalSettingsLoading: false,
         createReferalSettingserror: action.payload,
       };
     default:
       return state;
   }
}

export const getReferalSettingsReducer = (state={},action) => {
   switch (action.type) {
     case GET_REFERAL_SETTINGS_REQUEST:
       return {
         ...state,
         getReferalSettingsLoading: true,
       };
     case GET_REFERAL_SETTINGS_SUCCESS:
       return {
         ...state,
         getReferalSettingsLoading: false,
         getReferalSettingsSuccess: action.payload,
       };
     case GET_REFERAL_SETTINGS_ERR:
       return {
         ...state,
         getReferalSettingsLoading: false,
         getReferalSettingserror: action.payload,
       };
     default:
       return state;
   }
  }
// Referrals find reducer

export const referralsFindReducer = (state={},action) => {
  switch (action.type) {
    case REFERRALS_FIND_REQUEST:
      return {
        ...state,
        referralsFindLoading: true,
      };
    case REFERRALS_FIND_SUCCESS:
      return {
        ...state,
        referralsFindLoading: false,
        referralsFindSuccess: action.payload,
      };
    case REFERRALS_FIND_ERR:
      return {
        ...state,
        referralsFindLoading: false,
        referralsFinderror: action.payload,
      };
    default:
      return state;
  }
}

// Referrals statusChange reducer

export const referralsStatusChangeReducer = (state={},action) => {
  switch (action.type) {
    case REFERRALS_STATUSCHANGE_REQUEST:
      return {
        ...state,
        referralsStatusChangeLoading: true,
      };
    case REFERRALS_STATUSCHANGE_SUCCESS:
      return {
        ...state,
        referralsStatusChangeLoading: false,
        referralsStatusChangeSuccess: action.payload,
      };
    case REFERRALS_STATUSCHANGE_ERR:
      return {
        ...state,
        referralsStatusChangeLoading: false,
        referralsStatusChangeerror: action.payload,
      };
    default:
      return state;
  }
}


// create faq
export const createFaqChangeReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_REFERAL_FAQ_REQUEST:
      return {
        ...state,
        createFaqLoading: true,
      };
    case CREATE_REFERAL_FAQ_SUCCESS:
      return {
        ...state,
        createFaqLoading: false,
        createFaqSuccess: action.payload,
      };
    case CREATE_REFERAL_FAQ_ERR:
      return {
        ...state,
        createFaqLoading: false,
        createFaqError: action.payload,
      };
    default:
      return state;
  }
};

// get faq
export const getFaqChangeReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_REFERAL_FAQ_REQUEST:
      return {
        ...state,
        getFaqLoading: true,
      };
    case GET_REFERAL_FAQ_SUCCESS:
      return {
        ...state,
        getFaqLoading: false,
        getFaqSuccess: action.payload,
      };
    case GET_REFERAL_FAQ_ERR:
      return {
        ...state,
        getFaqLoading: false,
        getFaqError: action.payload,
      };
    default:
      return state;
  }
};

// update faq
export const updatereferalFaqReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_REFERAL_FAQ_REQUEST:
      return {
        ...state,
        updateReferalFaqLoading: true,
      };
    case UPDATE_REFERAL_FAQ_SUCCESS:
      return {
        ...state,
        updateReferalFaqLoading: false,
        updateReferalFaqSuccess: action.payload,
      };
    case UPDATE_REFERAL_FAQ_ERR:
      return {
        ...state,
        updateReferalFaqLoading: false,
        updateReferalFaqError: action.payload,
      };
    default:
      return state;
  }
};

// delete faq
export const deletereferalFaqReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_REFERAL_FAQ_REQUEST:
      return {
        ...state,
        deleteReferalFaqLoading: true,
      };
    case DELETE_REFERAL_FAQ_SUCCESS:
      return {
        ...state,
        deleteReferalFaqLoading: false,
        deleteReferalFaqSuccess: action.payload,
      };
    case DELETE_REFERAL_FAQ_ERR:
      return {
        ...state,
        deleteReferalFaqLoading: false,
        deleteReferalFaqError: action.payload,
      };
    default:
      return state;
  }
};


// withdraws find reducer

export const withdrawsFindReducer = (state={},action) => {
  switch (action.type) {
    case WITHDRAW_REFERAL_REQUEST:
      return {
        ...state,
        withdrawsFindLoading: true,
      };
    case WITHDRAW_REFERAL_SUCCESS:
      return {
        ...state,
        withdrawsFindLoading: false,
        withdrawsFindSuccess: action.payload,
      };
    case WITHDRAW_REFERAL_ERR:
      return {
        ...state,
        withdrawsFindLoading: false,
        withdrawsFinderror: action.payload,
      };
    default:
      return state;
  }
}

// withdraws statusChange reducer

export const withdrawsStatusChangeReducer = (state = {}, action) => {
  switch (action.type) {
    case WITHDRAW_STATUS_CHANGE_REFERAL_REQUEST:
      return {
        ...state,
        withdrawsStatusChangeLoading: true,
      };
    case WITHDRAW_STATUS_CHANGE_REFERAL_SUCCESS:
      return {
        ...state,
        withdrawsStatusChangeLoading: false,
        withdrawsStatusChangeSuccess: action.payload,
      };
    case WITHDRAW_STATUS_CHANGE_REFERAL_ERR:
      return {
        ...state,
        withdrawsStatusChangeLoading: false,
        withdrawsStatusChangeerror: action.payload,
      };
    default:
      return state;
  }
};

// withdraws statusChange reducer
export const referalDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case REFERAL_WITHDRAW_DETAILS_REQUEST:
      return {
        ...state,
        referalDetailsLoading: true,
      };
    case REFERAL_WITHDRAW_DETAILS_SUCCESS:
      return {
        ...state,
        referalDetailsLoading: false,
        referalDetailsSuccess: action.payload,
      };
    case REFERAL_WITHDRAW_DETAILS_ERR:
      return {
        ...state,
        referalDetailsLoading: false,
        referalDetailserror: action.payload,
      };
    default:
      return state;
  }
};