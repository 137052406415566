import { Alert, AlertTitle, Box, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { successTrueMsgAction } from "../../../actions/globalAction";
import { SUCCESS_TRUE_MSG_ERR } from "../../../constants/roleManagmentConstant";
import {
  courseMasterFindAction,
  subjectBulkUploadAction,
  subjectMasterFindAction,
} from "../../../actions/courseMasterAction";
import usePrivilegeCheck from "../../../constants/customHooks/UsePrivilegeCheck";
import PremissionDialog from "../../customComponent/premissionDialog";
import { MasterTable } from "../../setting/master/MasterTable";
import SubjectMajorAdd from "./subjectMajorAdd";
import SubjectMajorEdit from "./subjectMajorEdit";
import {
  SUBJECTMASTER_CREATE_ERR,
  SUBJECTMASTER_CREATE_SUCCESS,
  SUBJECTMASTER_UPDATE_ERR,
  SUBJECTMASTER_UPDATE_SUCCESS,
} from "../../../constants/courseMajorMasterConstants";
import BulkUploadModal from "../universities/BulkUploadModal";

function SubjectMajorMain({ subMenu }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { privilege, hasPrivilege, isCreatePrevilage } = usePrivilegeCheck();

  const [subjectMajor, setSubjectMajor] = useState([]);
  const [open, setOpen] = useState(false);
  const [pageCount, setPageCount] = useState(1);
  const [edit, setEdit] = useState(false);
  const [id, setId] = useState("");
  const [bulkUploadModalOpen, setBulkUploadModalOpen] = useState(false);

  // handle close

  const handleClose = () => {
    setOpen(false);
  };

  const handleEditClose = () => {
    setEdit(false);
  };

  const handlePagination = (count) => {
    setPageCount(count);
  };

  const handleEdit = (id) => {
    setEdit(true);
    setId(id);
  };

  const [openAlert, setOpenAlert] = useState(false);

  // HandleClose dialog

  const handleCloseDialog = () => {
    setOpenAlert(false);
  };

  // Response

  const { subjectMajorFindSuccess, subjectMajorFindErr } = useSelector(
    (state) => {
      return state.subjectMajorMasterFind;
    }
  );

  // Success msg

  const { successAlertMsgSuccess, successAlertMsgErr } = useSelector(
    (state) => {
      return state.successTrueMsg;
    }
  );

  const { subjectMajorCreateSuccess, subjectMajorCreateErr } = useSelector(
    (state) => {
      return state.subjectMajorMasterCreate;
    }
  );

  const { subjectMajorUpdateSuccess, subjectMajorUpdateErr } = useSelector(
    (state) => {
      return state.subjectMajorMasterUpdate;
    }
  );
  const { subjectMajorDeleteSuccess, subjectMajorDeleteErr } = useSelector(
    (state) => {
      return state.subjectMajorMasterDelete;
    }
  );

  useEffect(() => {
    if (successAlertMsgSuccess && subjectMajorCreateSuccess?.status == true) {
      Swal.fire("Good job!", `created successfully`, "success").then((value) =>
        dispatch({ type: SUBJECTMASTER_CREATE_SUCCESS, payload: false })
      );
      dispatch(successTrueMsgAction(false));
    }
    if (successAlertMsgSuccess && subjectMajorCreateSuccess?.status == false) {
      Swal.fire("Oops!", `Already exist !`, "error").then((value) =>
        dispatch({ type: SUBJECTMASTER_CREATE_SUCCESS, payload: false })
      );
      dispatch(successTrueMsgAction(false));
    }
    if (successAlertMsgErr && subjectMajorCreateErr) {
      Swal.fire("Oops!", `Failed to create`, "error").then((value) =>
        dispatch({ type: SUBJECTMASTER_CREATE_ERR, payload: false })
      );
      dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: false });
    }
  }, [
    successAlertMsgSuccess,
    successAlertMsgErr,
    subjectMajorCreateSuccess,
    subjectMajorCreateErr,
    dispatch,
  ]);

  // Success msg for updated courseMaster

  useEffect(() => {
    if (successAlertMsgSuccess && subjectMajorUpdateSuccess?.status == true) {
      Swal.fire("Good job!", `updated successfully`, "success").then((value) =>
        dispatch({ type: SUBJECTMASTER_UPDATE_SUCCESS, payload: false })
      );
      dispatch(successTrueMsgAction(false));
    }
    if (successAlertMsgSuccess && subjectMajorUpdateSuccess?.status == false) {
      Swal.fire("Oops!", `Already exist !`, "error").then((value) =>
        dispatch({ type: SUBJECTMASTER_UPDATE_SUCCESS, payload: false })
      );
      dispatch(successTrueMsgAction(false));
    }
    if (successAlertMsgErr && subjectMajorUpdateErr) {
      Swal.fire("Oops!", `Failed to create`, "error").then((value) =>
        dispatch({ type: SUBJECTMASTER_UPDATE_ERR, payload: false })
      );
      dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: false });
    }
  }, [
    successAlertMsgSuccess,
    successAlertMsgErr,
    subjectMajorUpdateSuccess,
    subjectMajorUpdateErr,
    dispatch,
  ]);

  //find courseMajor list

  useEffect(() => {
    dispatch(subjectMasterFindAction());
  }, [
    dispatch,
    subjectMajorCreateSuccess,
    subjectMajorUpdateSuccess,
    subjectMajorDeleteSuccess,
  ]);

  useEffect(() => {
    if (subjectMajorFindSuccess) {
      setSubjectMajor(subjectMajorFindSuccess.data);
    }
  }, [subjectMajorFindSuccess]);

  const data = subjectMajor?.map((data, index) => ({
    id: data.id,
    sl: index + 1,
    column1: data.majorName,
    column2: data?.subjectId,
  }));

  console.log(subjectMajor, "subjectMajorsubjectMajor");
  const columns = [
    {
      title: "SL NO",
      field: "sl",
      headerStyle: { backgroundColor: "#F9F9FB" },
    },
    {
      title: "MAJOR'S NAME",
      field: "MAJOR'S NAME",
      headerStyle: { backgroundColor: "#F9F9FB" },
    },
    {
      title: "MAJOR ID",
      field: "MAJOR ID",
      headerStyle: { backgroundColor: "#F9F9FB" },
    },
    {
      title: "ACTION",
      field: "ACTION",
      headerStyle: { backgroundColor: "#F9F9FB" },
    },
  ];

  // Handle edit courseMajor

  if (!hasPrivilege("Course Master") && privilege !== null) {
    return (
      <Alert severity="error">
        <AlertTitle>warning</AlertTitle>
        You don't have permission to access — <strong>this page.!</strong>
      </Alert>
    );
  }

  // Bulk upload api call

  const handleBulkUploadSubmit = (bulkUploadValue) => {
    const formData = new FormData();
    formData.append("file", bulkUploadValue);
    dispatch(subjectBulkUploadAction(formData));
  };

  console.log(
    hasPrivilege("course major"),
    "hasPrivilege",
    privilege,
    "hasPrivilegehasPrivilegehasPrivilege"
  );

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          width: "100%",
          mb: 4,
        }}
      >
        <BulkUploadModal
          open={bulkUploadModalOpen}
          handleClose={() => setBulkUploadModalOpen(false)}
          handleSubmit={handleBulkUploadSubmit}
          title="Bulk Upload Subject"
          downloadurl="https://gsl-dev2.s3.ap-south-1.amazonaws.com/bulkUpload/bulkUpload367"
        />

        <Button
          sx={{
            color: "white",
            backgroundColor: "#141E3C",
            height: "38px",
            width: "143px",
            fontWeight: 700,
            fontSize: "12px",
            borderRadius: "8px",
            "&:hover": {
              backgroundColor: "rgba(20, 30, 60, 0.9)",
            },
            ml: 1, // Add some spacing between buttons
          }}
          onClick={() => {
            if (isCreatePrevilage("Course Master")) {
              setOpen(true);
            } else {
              setOpenAlert(true);
            }
          }}
        >
          Add New
        </Button>
        <Button
          sx={{
            color: "white",
            backgroundColor: "#141E3C",
            height: "38px",
            width: "143px",
            fontWeight: 700,
            fontSize: "12px",
            borderRadius: "8px",
            "&:hover": {
              backgroundColor: "rgba(20, 30, 60, 0.9)",
            },
            ml: 1, // Add some spacing between buttons
          }}
          onClick={() => {
            if (isCreatePrevilage("Course Master")) {
              setBulkUploadModalOpen(true);
            } else {
              setOpenAlert(true);
            }
          }}
        >
          Bulk Upload
        </Button>
      </Box>
      <MasterTable
        data={data}
        columns={columns}
        component="subjectMajorMaster"
        rollEditHandler={handleEdit}
        pagination={handlePagination}
        subMenu={subMenu}
      />
      {/* courseMajor add popup */}
      {open && <SubjectMajorAdd open={open} handleClose={handleClose} />}
      {edit && (
        <SubjectMajorEdit open={edit} handleClose={handleEditClose} id={id} />
      )}
      {openAlert && (
        <PremissionDialog
          openAlert={openAlert}
          handleClose={handleCloseDialog}
        />
      )}
    </>
  );
}

export default SubjectMajorMain;
