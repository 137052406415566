import React from "react";
import { Typography, Paper, List, Grid, Box, Chip } from "@mui/material";
import { useNavigate } from "react-router-dom";

const OpenActivities = ({ followUpData }) => {

  const navigate = useNavigate()

  const MAX_DISPLAY_COUNT = 1; // Maximum number of objects to display

  const displayedDataTask =
    followUpData &&
    followUpData
      ?.filter(
        (value, index) =>
          // index < MAX_DISPLAY_COUNT &&
          value.activityType === "task" && value.status !== "closed"
      )
      .filter((value, index) => index < MAX_DISPLAY_COUNT);

  const displayedDataMeet =
    followUpData &&
    followUpData
      ?.filter(
        (value, index) =>
          // index < MAX_DISPLAY_COUNT &&
          value.activityType === "meet" && value.status !== "closed"
      )
      .filter((value, index) => index < MAX_DISPLAY_COUNT);

  const displayedDataCall =
    followUpData &&
    followUpData
      ?.filter(
        (value, index) =>
          // index < MAX_DISPLAY_COUNT &&
          value.activityType === "call" && value.status !== "closed"
      )
      .filter((value, index) => index < MAX_DISPLAY_COUNT);

  return (
    <Paper
      elevation={0}
      sx={{
        marginLeft: "10px",
        height: "auto",
        border: "1px solid #ececec",
      }}
    >
      <Typography
        variant="h6"
        sx={{
          fontSize: "20px",
          fontWeight: 700,
          fontFamily: "'Montserrat', sans-serif",
          m: 2,
        }}
      >
        Open Activities
      </Typography>
      <List dense component="div" role="list">
        <Grid container spacing={2}>
          <Grid item xs={12} lg={12} m={2}>
            <table
              style={{
                width: "100%",
                height: "10px",
                borderCollapse: "collapse",
                border: "1px solid #ececec",
              }}
            >
              <tr style={{ border: "1px solid #ececec", width: "100%" }}>
                <th
                  colSpan="1"
                  style={{
                    textAlign: "center",
                    padding: "3px",
                    borderBottom: "1px solid #ececec",
                    borderRight: "1px solid #ececec",
                  }}
                >
                  <Typography sx={{ fontSize: "18px", fontWeight: 500 }}>
                    Open Tasks
                    <Chip
                      sx={{
                        ml: 1,
                        mb: 0.4,
                        fontSize: "12px",
                        width: "28px",
                        height: "21px",
                        pr: 1,
                      }}
                      label={displayedDataTask?.length}
                      variant="outlined"
                    />
                  </Typography>
                </th>

                <th
                  colSpan="2"
                  style={{
                    textAlign: "center",
                    padding: "3px",
                    borderBottom: "1px solid #ececec",
                    borderRight: "1px solid #ececec",
                  }}
                >
                  <Typography sx={{ fontSize: "18px", fontWeight: 500 }}>
                    Open Meetings
                    <Chip
                      sx={{
                        ml: 1,
                        mb: 0.4,
                        fontSize: "12px",
                        width: "28px",
                        height: "21px",
                        pr: 1,
                      }}
                      label={displayedDataMeet?.length}
                      variant="outlined"
                    />
                  </Typography>
                </th>

                <th
                  colSpan="3"
                  style={{
                    textAlign: "center",
                    padding: "3px",
                    borderBottom: "1px solid #ececec",
                    borderRight: "1px solid #ececec",
                  }}
                >
                  <Typography sx={{ fontSize: "18px", fontWeight: 500 }}>
                    Open Calls
                    <Chip
                      sx={{
                        ml: 1,
                        mb: 0.4,
                        fontSize: "12px",
                        width: "28px",
                        height: "21px",
                        pr: 1,
                      }}
                      label={displayedDataCall?.length}
                      variant="outlined"
                    />
                  </Typography>
                </th>
              </tr>

              <tr>
                <td
                  // key={index}
                  colSpan="1"
                  style={{
                    padding: "15px",
                    borderRight: "1px solid #ececec",
                  }}
                >
                  {displayedDataTask &&
                    displayedDataTask?.map((value, index) => {
                      return (
                        <Box>
                          <Typography component="span">1.{""}</Typography>
                          &nbsp;&nbsp;
                          <Typography
                            component="span"
                            sx={{
                              fontSize: "16px",
                              fontWeight: 500,
                              fontFamily: "'Montserrat', sans-serif",
                            }}
                          >
                            {value.subject}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "12px",
                              fontWeight: 300,
                              fontFamily: "'Montserrat', sans-serif",
                              pb: 1,
                            }}
                          >
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            {value.lead.firstName}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "12px",
                              fontWeight: 300,
                              fontFamily: "'Montserrat', sans-serif",
                              pb: 1,
                              color: "#BCC0D1",
                            }}
                          >
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; status{" "}
                            <span
                              style={{
                                fontSize: "12px",
                                fontWeight: 300,
                                fontFamily: "'Montserrat', sans-serif",
                                marginLeft: "30px",
                                color: "#000",
                              }}
                            >
                              :{""}
                              {value.status}
                            </span>
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "12px",
                              fontWeight: 300,
                              fontFamily: "'Montserrat', sans-serif",
                              color: "#BCC0D1",
                            }}
                          >
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Priority{" "}
                            <span
                              style={{
                                fontSize: "12px",
                                fontWeight: 300,
                                fontFamily: "'Montserrat', sans-serif",
                                marginLeft: "30px",
                                color: "#000",
                              }}
                            >
                              : {""}
                              {value.priority}
                            </span>
                          </Typography>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "flex-end",
                              mt: 2,
                            }}
                          >
                            <Typography
                              sx={{
                                fontFamily: "'Montserrat', sans-serif",
                                color: "rgba(20, 30, 60, 1)",
                                cursor: "pointer",
                              }}
                              onClick={() => navigate("open-tasks")}
                            >
                              View All..
                            </Typography>
                          </Box>
                        </Box>
                      );
                    })}
                </td>

                <td
                  // key={index}
                  colSpan="2"
                  style={{
                    padding: "15px",
                    borderRight: "1px solid #ececec",
                  }}
                >
                  {displayedDataMeet &&
                    displayedDataMeet?.map((value, index) => {
                      
                      return (
                        <Box>
                          <Typography component="span">1.{""}</Typography>
                          &nbsp;&nbsp;
                          <Typography
                            component="span"
                            sx={{
                              fontSize: "16px",
                              fontWeight: 500,
                              fontFamily: "'Montserrat', sans-serif",
                            }}
                          >
                            {value.subject}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "12px",
                              fontWeight: 300,
                              fontFamily: "'Montserrat', sans-serif",
                              pb: 1,
                            }}
                          >
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            {value.lead.firstName}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "12px",
                              fontWeight: 300,
                              fontFamily: "'Montserrat', sans-serif",
                              pb: 1,
                              color: "#BCC0D1",
                            }}
                          >
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; status{" "}
                            <span
                              style={{
                                fontSize: "12px",
                                fontWeight: 300,
                                fontFamily: "'Montserrat', sans-serif",
                                marginLeft: "30px",
                                color: "#000",
                              }}
                            >
                              :{""}
                              {value.status}
                            </span>
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "12px",
                              fontWeight: 300,
                              fontFamily: "'Montserrat', sans-serif",
                              color: "#BCC0D1",
                            }}
                          >
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Priority{" "}
                            <span
                              style={{
                                fontSize: "12px",
                                fontWeight: 300,
                                fontFamily: "'Montserrat', sans-serif",
                                marginLeft: "30px",
                                color: "#000",
                              }}
                            >
                              : {""}
                              {value.priority}
                            </span>
                          </Typography>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "flex-end",
                              mt: 2,
                            }}
                          >
                            <Typography
                              sx={{
                                fontFamily: "'Montserrat', sans-serif",
                                color: "rgba(20, 30, 60, 1)",
                                cursor: "pointer",
                              }}
                              onClick={() => navigate("open-meetings")}
                            >
                              View All..
                            </Typography>
                          </Box>
                        </Box>
                      );
                    })}
                </td>

                <td
                  // key={index}
                  colSpan="3"
                  style={{
                    padding: "20px",
                    borderRight: "1px solid #ececec",
                  }}
                >
                  {displayedDataCall &&
                    displayedDataCall?.map((value, index) => {
                      return (
                        <Box>
                          <Typography component="span">1.{""}</Typography>
                          &nbsp;&nbsp;
                          <Typography
                            component="span"
                            sx={{
                              fontSize: "16px",
                              fontWeight: 500,
                              fontFamily: "'Montserrat', sans-serif",
                            }}
                          >
                            {value.subject}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "12px",
                              fontWeight: 300,
                              fontFamily: "'Montserrat', sans-serif",
                              pb: 1,
                            }}
                          >
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            {value.lead.firstName}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "12px",
                              fontWeight: 300,
                              fontFamily: "'Montserrat', sans-serif",
                              pb: 1,
                              color: "#BCC0D1",
                            }}
                          >
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; status{" "}
                            <span
                              style={{
                                fontSize: "12px",
                                fontWeight: 300,
                                fontFamily: "'Montserrat', sans-serif",
                                marginLeft: "30px",
                                color: "#000",
                              }}
                            >
                              :{""}
                              {value.status}
                            </span>
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "12px",
                              fontWeight: 300,
                              fontFamily: "'Montserrat', sans-serif",
                              color: "#BCC0D1",
                            }}
                          >
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Priority{" "}
                            <span
                              style={{
                                fontSize: "12px",
                                fontWeight: 300,
                                fontFamily: "'Montserrat', sans-serif",
                                marginLeft: "30px",
                                color: "#000",
                              }}
                            >
                              : {""}
                              {value.priority}
                            </span>
                          </Typography>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "flex-end",
                              mt: 2,
                            }}
                          >
                            <Typography
                              sx={{
                                fontFamily: "'Montserrat', sans-serif",
                                color: "rgba(20, 30, 60, 1)",
                                cursor: "pointer",
                              }}
                              onClick={() => navigate("open-calls")}
                            >
                              View All..
                            </Typography>
                          </Box>
                        </Box>
                      );
                    })}
                </td>
              </tr>
            </table>
          </Grid>
        </Grid>
      </List>
    </Paper>
  );
};

export default OpenActivities;
