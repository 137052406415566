import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import CustomCheckbox from "../../customComponent/CustomCheckbox";
import Reorder from "../../HOME/HomePageComponents/Reorder";
import { useDispatch } from "react-redux";
import { paidPaymentUpdationAction } from "../../../actions/reportActions/reportsActions";

function FinalChoicesList({
  rows,
  columns,
  showStudentNameColumn,
  showStudentIdColumn,
  showStudentUniversityColumn,
  showStudentCourseColumn,
  showStartDateColumn,
  showTutionFeeColumn,
  showInvoiceColumn,
  showPaidToGslColumn,
  showPaidToPartnerColumn,
  showPaymentDateColumn,
}) {
  const dispatch = useDispatch();
  return (
    <Box
      sx={{
        width: "100%",
        overflowX: "scroll",
        "&::-webkit-scrollbar": {
          display: "none",
        },
      }}
    >
      <Table
        sx={{
          minWidth: "1500px",
          "& .MuiTableCell-root": {
            borderBottom: "1px solid rgba(220, 223, 227, 0.50)",
          },
        }}
      >
        <TableHead
          sx={{
            background: "#F9F9FB",
            "& th": {
              textTransform: "uppercase",
              fontWeight: "600",
              fontFamily: "Inter",
              color: "#666E7D",
            },
          }}
        >
          <TableRow>
            {columns?.map((item) => {
              if (item.hidden) {
                return null;
              }
              return (
                <TableCell>
                  {item.title} <Reorder />
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody
          sx={{
            background: "#fff",
            border: "1px solid rgba(220, 223, 227, 0.50)",
          }}
        >
          {rows?.map((row, i) => (
            <TableRow key={i}>
              {!showStudentIdColumn && (
                <TableCell>{row.applicationId}</TableCell>
              )}
              {!showStudentNameColumn && <TableCell>{row.name}</TableCell>}
              {!showStudentUniversityColumn && (
                <TableCell>{row.university}</TableCell>
              )}
              {!showStudentCourseColumn && <TableCell>{row.course}</TableCell>}
              {!showStartDateColumn && <TableCell>{row.startDate}</TableCell>}
              {/* {!showTutionFeeColumn && <TableCell>{row.tutionFee}</TableCell>} */}
              {!showInvoiceColumn && (
                <TableCell>
                  <CustomCheckbox
                    value={row.invoice}
                    checked={row.invoice}
                    onChange={(e) =>
                      dispatch(
                        paidPaymentUpdationAction(
                          e.target.checked,
                          "invoice",
                          row.id
                        )
                      )
                    }
                  />
                </TableCell>
              )}
              {!showPaidToGslColumn && (
                <TableCell>
                  <CustomCheckbox
                    value={row.paidToGsl}
                    checked={row.paidToGsl}
                    onChange={(e) =>
                      dispatch(
                        paidPaymentUpdationAction(
                          e.target.checked,
                          "paidToGsl",
                          row.id
                        )
                      )
                    }
                  />
                </TableCell>
              )}
              {!showPaidToPartnerColumn && (
                <TableCell>
                  <CustomCheckbox
                    value={row.paidToPartner}
                    checked={row.paidToPartner}
                    onChange={(e) =>
                      dispatch(
                        paidPaymentUpdationAction(
                          e.target.checked,
                          "paidToPartner",
                          row.id
                        )
                      )
                    }
                  />
                </TableCell>
              )}
              {!showPaymentDateColumn && (
                <TableCell>{row.paymentDate}</TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
}

export default FinalChoicesList;
