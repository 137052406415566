import React, { useEffect, useMemo, useState } from "react";

import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableFooter,
  TableSortLabel,
  Menu,
  FormControl,
  InputLabel,
  Select,
} from "@mui/material";

import { SlOptionsVertical } from "react-icons/sl";
import { Box, Checkbox, Grid, MenuItem, Typography } from "@mui/material";

import { AiOutlineEye } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import { Skeleton } from "@mui/material";
import { BiSortAlt2 } from "react-icons/bi";
import { ContentState, convertFromHTML } from "draft-js";
import { TbEdit } from "react-icons/tb";
import { ieltsTeacherManagmentStatusChangeAction } from "../../../actions/IeltsActions/teacherManagmentAction";
import TableNoItemComponent from "../../customComponent/TableNoItemComponent";
import { referralsStatusChangeAction } from "../../../actions/referAndEarnAction";
import { withdrawsStatusChangeAction } from "../../../actions/referAndEarn/referAndEarnActions";
import PremissionDialog from "../../customComponent/premissionDialog";
import usePrivilegeCheck from "../../../constants/customHooks/UsePrivilegeCheck";

function WithDrawalTable({
  setIdsFunction,
  data,
  leadFindLoading,
  selectBoxIdPassHandler,
  columns,
  subMenu,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedRows, setSelectedRows] = useState([]);
  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState("");

  // custom Hooks
  const { isStatusChangePrevilage } = usePrivilegeCheck();

  const [openAlert, setOpenAlert] = useState(false);

  // HandleClose dialog

  const handleCloseDialog = () => {
    setOpenAlert(false);
  };

  //Function to handle Description

  function convertHTMLToPlainText(html) {
    // Convert HTML to Draft.js ContentState
    const blocksFromHTML = convertFromHTML(html);
    const contentState = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap
    );

    // Convert Draft.js ContentState to plain text
    const plainText = contentState.getPlainText();

    return plainText;
  }

  // Create a function to handle selecting all rows:
  const handleSelectAllRows = () => {
    if (selectedRows?.length === sortedData?.length) {
      setSelectedRows([]);
    } else {
      let Ids = sortedData.map((item) => item.id);
      setSelectedRows(Ids);
      selectBoxIdPassHandler(Ids);
    }
  };

  // Create a function to handle selecting individual rows:
  const handleSelectRow = (rowId) => {
    let updatedSelectedRows;
    if (selectedRows.includes(rowId)) {
      updatedSelectedRows = selectedRows.filter((id) => id !== rowId);
      setSelectedRows(selectedRows.filter((id) => id !== rowId));
    } else {
      updatedSelectedRows = [...selectedRows, rowId];
      setSelectedRows(updatedSelectedRows);
    }
    setIdsFunction(updatedSelectedRows);
  };

  // table action
  const [leadActionButtonShow, setLeadActionButtonShow] = useState(null);
  const [tableId, setTableId] = useState("");

  const leadOpenActionButton = Boolean(leadActionButtonShow);

  const handleClickAction = (event, id) => {
    setLeadActionButtonShow(event.currentTarget);
    setTableId(id);
  };

  // Function_to_handle_EditForm

  const EmployeeJobEditHandler = (id) => {
    navigate(`/hrmanagment/Editjobs/${id}`);
  };

  const handleCloseAction = () => {
    setLeadActionButtonShow(null);
  };

  const CustomSortIcon = ({ direction }) => {
    return direction === "asc" ? (
      <img
        src="/menuIcons/tableUpSort.png"
        className="administratCustomIcon"
        alt="gsl"
        style={{ padding: "15px" }}
      />
    ) : (
      <BiSortAlt2 className="LeadCustomIcon" />
    );
  };

  // sorting table
  const [sortBy, setSortBy] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");

  const requestSort = (column) => {
    let newSortOrder = "asc";
    if (column === sortBy && sortOrder === "asc") {
      newSortOrder = "desc";
    }

    setSortBy(column);
    setSortOrder(newSortOrder);
  };

  const handleClose = () => setOpen(false);

  let sortedData = useMemo(() => {
    if (sortBy && data) {
      const sortedItems = [...data].sort((a, b) => {
        if (sortOrder === "asc") {
          return a[sortBy] < b[sortBy] ? -1 : 1;
        } else {
          return a[sortBy] < b[sortBy] ? 1 : -1;
        }
      });

      return sortedItems;
    }

    return data;
  }, [data, sortBy, sortOrder]);

  // HandleStatusChange

  const handleStatus = (status, id) => {
    if (isStatusChangePrevilage(subMenu)) {
      let formData = {
        id: id,
        status: status,
      };
      dispatch(withdrawsStatusChangeAction(formData));
    } else {
      setOpenAlert(true);
    }
  };

  const convertToDate = (date) => {
    let timestamp = new Date(date);
    return timestamp.toLocaleDateString();
  };

  return sortedData?.length === 0 ? (
    <TableNoItemComponent />
  ) : (
    <>
      <Grid container spacing={2} sx={{ mt: 1, ml: 1 }}>
        <Grid item xs={12} lg={12}>
          {leadFindLoading ? (
            <>
              {/* <Skeleton /> */}
              <Typography variant="h1">
                <Skeleton animation={false} />
              </Typography>

              <Typography variant="h3">
                <Skeleton animation="wave" />
              </Typography>

              <Skeleton animation={false} />
              <Typography variant="h3">
                <Skeleton animation="wave" />
              </Typography>

              <Skeleton animation={false} />
              <Typography variant="h3">
                <Skeleton animation="wave" />
              </Typography>

              <Skeleton animation={false} />
              <Typography variant="h3">
                <Skeleton animation="wave" />
              </Typography>

              <Skeleton animation={false} />
            </>
          ) : (
            <>
              <Typography
                sx={{
                  fontSize: "14px",
                  // fontFamily: "Inter",
                  lineHeight: "20px",
                  color: "rgba(5, 5, 15, 1)",
                  fontWeight: "400",
                  mb: "10px",
                }}
              >
                Showing{" "}
                <Typography
                  component={"span"}
                  sx={{
                    color: "rgba(5, 5, 15, 1)",
                    fontSize: "14px",
                    // fontFamily: "Inter",
                    lineHeight: "20px",
                    fontWeight: "700",
                  }}
                >
                  {
                    sortedData?.slice(
                      (page - 1) * rowsPerPage,
                      (page - 1) * rowsPerPage + rowsPerPage
                    ).length
                  }{" "}
                  Result
                </Typography>
              </Typography>
              <div
                style={{
                  overflowX: "scroll",
                  border: "0px solid #ffff",
                }}
                className="scrollBar"
              >
                <Table className="EmployeeJobTable">
                  <TableHead>
                    <TableRow className="EmployeeJobTableCell">
                      <TableCell
                        style={{
                          position: "sticky",
                          left: 0,
                          zIndex: 1, // To ensure it's above other cells
                          padding: "1px",
                          border: "1px solid #ddd",
                          // borderLeft: "1px solid #ddd",
                          backgroundColor: "#fafafa",
                        }}
                      >
                        <Checkbox
                          checked={selectedRows?.length === sortedData?.length}
                          onChange={handleSelectAllRows}
                          style={{ color: "#f81b82" }}
                        />
                      </TableCell>
                      {columns?.map((column) => {
                        if (column.hidden) {
                          return null;
                        }
                        return (
                          <TableCell
                            key={column.field}
                            className="EmployeeJobTableCell LeadCommon"
                            style={{
                              padding: "0 16px",
                              whiteSpace: "nowrap",
                              textTransform: "uppercase",
                              fontWeight: "700",
                            }}
                          >
                            <TableSortLabel
                              className="LeadHeaderText"
                              active={column === sortBy}
                              direction={sortOrder}
                              onClick={() => requestSort(column)}
                              IconComponent={
                                column.field !== "action" && CustomSortIcon
                              }
                            >
                              {column.title}
                            </TableSortLabel>
                          </TableCell>
                        );
                      })}
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {sortedData?.map((item, indx) => (
                      <TableRow className="EmployeeJobTable" key={indx}>
                        <TableCell
                          style={{
                            position: "sticky",
                            left: 0,
                            zIndex: 1, // To ensure it's above other cells
                            backgroundColor: "white", // Adjust as needed
                            padding: "1px",
                            border: "1px solid #ddd",
                          }}
                        >
                          <Checkbox
                            checked={selectedRows.includes(indx)}
                            onChange={() => handleSelectRow(indx)}
                            style={{ color: "#f81b82" }}
                          />
                        </TableCell>
                        <TableCell
                          style={{ padding: "0 16px", whiteSpace: "nowrap" }}
                        >
                          {indx + 1}
                        </TableCell>
                        <TableCell
                          style={{ padding: "0 16px", whiteSpace: "nowrap" }}
                        >
                          {item.referredByname}
                        </TableCell>

                        <TableCell
                          style={{ padding: "0 16px", whiteSpace: "nowrap" }}
                        >
                          {convertToDate(item.createdAt)}
                        </TableCell>

                        <TableCell
                          style={{ padding: "0 16px", whiteSpace: "nowrap" }}
                        >
                          {item.amount}
                        </TableCell>

                        <TableCell
                          style={{ padding: "0 16px", whiteSpace: "nowrap" }}
                        >
                          {item.BankName}
                        </TableCell>

                        <TableCell
                          style={{ padding: "0 16px", whiteSpace: "nowrap" }}
                        >
                          {item.accountHolderName}
                        </TableCell>

                        <TableCell
                          style={{ padding: "0 16px", whiteSpace: "nowrap" }}
                        >
                          {item.AccountNumber}
                        </TableCell>

                        <TableCell
                          style={{ padding: "0 16px", whiteSpace: "nowrap" }}
                        >
                          {item.ifsc}
                        </TableCell>
                        <FormControl
                          fullWidth
                          sx={{
                            mt: 1,
                            bgcolor:
                              item.status == "Paid" ? "#228200" : "#E52900",
                            borderRadius: "8px",
                            height: "30px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            "& .MuiSelect-icon": {
                              top: "3px",
                            },
                          }}
                        >
                          <InputLabel
                            htmlFor="demo-simple-select-placeholder"
                            style={{
                              transform: "translate(10px, 10px)",
                              pointerEvents: "none",
                              zIndex: 1,
                              transition: "transform 0.25s, font-size 0.25s",
                              margin: "-6px",
                              color: "white",
                              fontWeight: 400,
                              paddingLeft: "10px",
                              border: "none !important",
                            }}
                          >
                            {item.status ? item.status : "placeholder"}
                          </InputLabel>
                          <Select
                            value={status}
                            onChange={(e) =>
                              handleStatus(e.target.value, item.id)
                            }
                            inputProps={{
                              id: "demo-simple-select-placeholder",
                            }}
                            sx={{
                              position: "relative",
                              width: "100px",
                              top: "-4px",
                              ".MuiSelect-select": {
                                padding: "0",
                              },
                              ".MuiOutlinedInput-notchedOutline": {
                                outline: "none !important",
                                border: "none !important",
                              },
                            }}
                          >
                            <MenuItem value="Paid">Paid</MenuItem>
                            <MenuItem value="Pending">Pending</MenuItem>
                          </Select>
                        </FormControl>

                        <TableCell
                          style={{ padding: "0 16px", whiteSpace: "nowrap" }}
                        >
                          <SlOptionsVertical
                            id="demo-positioned-button"
                            aria-controls={
                              leadOpenActionButton
                                ? "demo-positioned-menu"
                                : undefined
                            }
                            aria-haspopup="true"
                            aria-expanded={
                              leadOpenActionButton ? "true" : undefined
                            }
                            onClick={(e) => {
                              handleClickAction(e, item.id);
                            }}
                            variant="outlined"
                            style={{ marginLeft: "10px", color: "#F80B7A" }}
                            sx={{
                              backgroundColor: "#141E3C",
                              size: "10px",
                              padding: 1,
                              margin: 3,
                              color: "#fff",
                              width: "75px",
                              height: "40px",
                              cursor: "pointer",
                            }}
                          />
                          {/* TABLE ACTION MENU START*/}
                          <Menu
                            id="demo-positioned-menu"
                            aria-labelledby="demo-positioned-button"
                            open={leadActionButtonShow}
                            onClose={handleCloseAction}
                            anchorEl={leadActionButtonShow}
                            MenuListProps={{
                              "aria-labelledby": "basic-button",
                            }}
                            anchorOrigin={{
                              vertical: "bottom", // Change from "top" to "bottom"
                              horizontal: "left",
                            }}
                            transformOrigin={{
                              vertical: "top", // Change from "top" to "bottom"
                              horizontal: "left",
                            }}
                            sx={{
                              borderColor: "black",
                              borderRadius: "24px",
                              mt: 1.5,
                              width: "auto",
                              height: "auto",
                            }}
                          >
                            <Box
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              <MenuItem>
                                <AiOutlineEye
                                  style={{
                                    fontSize: "lg",
                                    width: "20px",
                                    height: "19px",
                                  }}
                                  onClick={() =>
                                    navigate(`/ReferAndEarn/${tableId}`)
                                  }
                                />
                              </MenuItem>
                            </Box>
                          </Menu>{" "}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                  <TableFooter />
                </Table>
              </div>
            </>
          )}
        </Grid>
      </Grid>

      {openAlert && (
        <PremissionDialog
          openAlert={openAlert}
          handleClose={handleCloseDialog}
        />
      )}
    </>
  );
}

export default WithDrawalTable;
