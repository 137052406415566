import {
  BANNER_CREATE_ERR,
  BANNER_CREATE_REQUEST,
  BANNER_CREATE_SUCCESS,
  BANNER_DELETE_ERR,
  BANNER_DELETE_REQUEST,
  BANNER_DELETE_SUCCESS,
  BANNER_FIND_ERR,
  BANNER_FIND_REQUEST,
  BANNER_FIND_SUCCESS,
} from "../constants/bannerContants";

// Banner create reducer

export const bannerCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case BANNER_CREATE_REQUEST:
      return {
        ...state,
        bannerCreateLoading: true,
      };
    case BANNER_CREATE_SUCCESS:
      return {
        ...state,
        bannerCreateLoading: false,
        bannerCreateSuccess: action.payload,
      };
    case BANNER_CREATE_ERR:
      return {
        ...state,
        bannerCreateLoading: false,
        bannerCreateerror: action.payload,
      };
    default:
      return state;
  }
};

// Banner List reducer

export const bannerFindReducer = (state = {}, action) => {
  switch (action.type) {
    case BANNER_FIND_REQUEST:
      return {
        ...state,
        bannerFindLoading: true,
      };
    case BANNER_FIND_SUCCESS:
      return {
        ...state,
        bannerFindLoading: false,
        bannerFindSuccess: action.payload,
      };
    case BANNER_FIND_ERR:
      return {
        ...state,
        bannerFindLoading: false,
        bannerFinderror: action.payload,
      };
    default:
      return state;
  }
};

// Banner delete reducer

export const bannerDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case BANNER_DELETE_REQUEST:
      return {
        ...state,
        bannerDeleteLoading: true,
      };
    case BANNER_DELETE_SUCCESS:
      return {
        ...state,
        bannerDeleteLoading: false,
        bannerDeleteSuccess: action.payload,
      };
    case BANNER_DELETE_ERR:
      return {
        ...state,
        bannerDeleteLoading: false,
        bannerDeleteerror: action.payload,
      };
    default:
      return state;
  }
};
