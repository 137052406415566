// Hr_Employee_Attendance_Table

export const EMPLOYEE_FIND_REQUEST = "EMPLOYEE_FIND_REQUEST";
export const EMPLOYEE_FIND_SUCCESS = "EMPLOYEE_FIND_SUCCESS";
export const EMPLOYEE_FIND_ERR = "EMPLOYEE_FIND_ERR ";

// Hr_Employee_Present_Absent_Creation

export const EMPLOYEE_ATTENDANCE_CREATE_REQUEST =
  " EMPLOYEE_ATTENDANCE_CREATE_REQUEST";
export const EMPLOYEE_ATTENDANCE_CREATE_SUCCESS =
  "EMPLOYEE_ATTENDANCE_CREATE_SUCCESS";
export const EMPLOYEE_ATTENDANCE_CREATE_ERR = "EMPLOYEE_ATTENDANCE_CREATE_ERR";

export const EMPLOYEE_ATTENDANCE_SEARCH_REQUEST =
  " EMPLOYEE_ATTENDANCE_SEARCH_REQUEST";
export const EMPLOYEE_ATTENDANCE_SEARCH_SUCCESS =
  "EMPLOYEE_ATTENDANCE_SEARCH_SUCCESS";
export const EMPLOYEE_ATTENDANCE_SEARCH_ERR = "EMPLOYEE_ATTENDANCE_SEARCH_ERR";

// Hr_Employee_Holiday_Find

export const EMPLOYEE_HOLIDAY_FIND_REQUEST = " EMPLOYEE_HOLIDAY_FIND_REQUEST";
export const EMPLOYEE_HOLIDAY_FIND_SUCCESS = "EMPLOYEE_HOLIDAY_FIND_SUCCESS";
export const EMPLOYEE_HOLIDAY_FIND_ERR = "EMPLOYEE_HOLIDAY_FIND_ERR";

// Hr_Employee_Holiday_Create

export const EMPLOYEE_HOLIDAY_CREATE_REQUEST =
  " EMPLOYEE_HOLIDAY_CREATE_REQUEST";
export const EMPLOYEE_HOLIDAY_CREATE_SUCCESS =
  "EMPLOYEE_HOLIDAY_CREATE_SUCCESS";
export const EMPLOYEE_HOLIDAY_CREATE_ERR = "EMPLOYEE_HOLIDAY_CREATE_ERR";

//  Hr_Employee_Holiday_Delete

export const EMPLOYEE_HOLIDAY_DELETE_REQUEST =
  " EMPLOYEE_HOLIDAY_DELETE_REQUEST";
export const EMPLOYEE_HOLIDAY_DELETE_SUCCESS =
  "EMPLOYEE_HOLIDAY_DELETE_SUCCESS";
export const EMPLOYEE_HOLIDAY_DELETE_ERR = "EMPLOYEE_HOLIDAY_DELETE_ERR";

//  Hr_Employee_Holiday_Edit

export const EMPLOYEE_HOLIDAY_EDIT_REQUEST = " EMPLOYEE_HOLIDAY_EDIT_REQUEST";
export const EMPLOYEE_HOLIDAY_EDIT_SUCCESS = "EMPLOYEE_HOLIDAY_EDIT_SUCCESS";
export const EMPLOYEE_HOLIDAY_EDIT_ERR = "EMPLOYEE_HOLIDAY_EDIT_ERR";

//  Hr_Employee_Holiday_Edit

export const EMPLOYEE_HOLIDAY_CREATE_EDIT_REQUEST =
  " EMPLOYEE_HOLIDAY_CREATE_EDIT_REQUEST";
export const EMPLOYEE_HOLIDAY_CREATE_EDIT_SUCCESS =
  "EMPLOYEE_HOLIDAY_CREATE_EDIT_SUCCESS";
export const EMPLOYEE_HOLIDAY_CREATE_EDIT_ERR =
  "EMPLOYEE_HOLIDAY_CREATE_EDIT_ERR";

//Hr_Employee_Leave_Application

export const EMPLOYEE_LEAVE_APPLICATION_FIND_REQUEST =
  "EMPLOYEE_LEAVE_APPLICATION_FIND_REQUEST ";
export const EMPLOYEE_LEAVE_APPLICATION_FIND_SUCCESS =
  "EMPLOYEE_LEAVE_APPLICATION_FIND_SUCCESS";
export const EMPLOYEE_LEAVE_APPLICATION_FIND_ERR =
  "EMPLOYEE_LEAVE_APPLICATION_FIND_ERR";

//Hr_Employee_Payslip_Find

export const EMPLOYEE_PAYSLIP_FIND_REQUEST = "EMPLOYEE_PAYSLIP_FIND_REQUEST ";
export const EMPLOYEE_PAYSLIP_FIND_SUCCESS = "EMPLOYEE_PAYSLIP_FIND_SUCCESS";
export const EMPLOYEE_PAYSLIP_FIND_ERR = "EMPLOYEE_PAYSLIP_FIND_ERR";

//Hr_Employee_Payslip_Create

export const EMPLOYEE_PAYSLIP_CREATE_REQUEST =
  "EMPLOYEE_PAYSLIP_CREATE_REQUEST ";
export const EMPLOYEE_PAYSLIP_CREATE_SUCCESS =
  "EMPLOYEE_PAYSLIP_CREATE_SUCCESS";
export const EMPLOYEE_PAYSLIP_CREATE_ERR = "EMPLOYEE_PAYSLIP_CREATE_ERR";

// Hr_Employee_Payslip_Delete

export const EMPLOYEE_PAYSLIP_DELETE_REQUEST =
  "EMPLOYEE_PAYSLIP_DELETE_REQUEST ";
export const EMPLOYEE_PAYSLIP_DELETE_SUCCESS =
  "EMPLOYEE_PAYSLIP_DELETE_SUCCESS";
export const EMPLOYEE_PAYSLIP_DELETE_ERR = "EMPLOYEE_PAYSLIP_DELETE_ERR";

// Hr_Employee_Job_Create

export const EMPLOYEE_JOB_CREATE_REQUEST = "EMPLOYEE_JOB_CREATE_REQUEST ";
export const EMPLOYEE_JOB_CREATE_SUCCESS = "EMPLOYEE_JOB_CREATE_SUCCESS";
export const EMPLOYEE_JOB_CREATE_ERR = "EMPLOYEE_JOB_CREATE_ERR";

// Hr_Employee_Job_Find

export const EMPLOYEE_JOB_FIND_REQUEST = "EMPLOYEE_JOB_FIND_REQUEST ";
export const EMPLOYEE_JOB_FIND_SUCCESS = "EMPLOYEE_JOB_FIND_SUCCESS";
export const EMPLOYEE_JOB_FIND_ERR = "EMPLOYEE_JOB_FIND_ERR";

// Hr_Employee_Job_Find_By_Id

export const EMPLOYEE_JOB_FIND_BY_ID_REQUEST =
  "EMPLOYEE_JOB_FIND_BY_ID_REQUEST ";
export const EMPLOYEE_JOB_FIND_BY_ID_SUCCESS =
  "EMPLOYEE_JOB_FIND_BY_ID_SUCCESS";
export const EMPLOYEE_JOB_FIND_BY_ID_ERR = "EMPLOYEE_JOB_FIND_BY_ID_ERR";

// Hr_Employee_Job_Delete

export const EMPLOYEE_JOB_DELETE_REQUEST = "EMPLOYEE_JOB_DELETE_REQUEST ";
export const EMPLOYEE_JOB_DELETE_SUCCESS = "EMPLOYEE_JOB_DELETE_SUCCESS";
export const EMPLOYEE_JOB_DELETE_ERR = "EMPLOYEE_JOB_DELETE_ERR";

// Hr_Employee_Job_Find_to_Edit

export const EMPLOYEE_JOB_EDIT_REQUEST = " EMPLOYEE_JOB_EDIT_REQUEST";
export const EMPLOYEE_JOB_EDIT_SUCCESS = "EMPLOYEE_JOB_EDIT_SUCCESS";
export const EMPLOYEE_JOB_EDIT_ERR = "EMPLOYEE_JOB_EDIT_ERR";

// Hr_Employee_Job_Update

export const EMPLOYEE_JOB_UPDATE_REQUEST = " EMPLOYEE_JOB_UPDATE_REQUEST";
export const EMPLOYEE_JOB_UPDATE_SUCCESS = "EMPLOYEE_JOB_UPDATE_SUCCESS";
export const EMPLOYEE_JOB_UPDATE_ERR = "EMPLOYEE_JOB_UPDATE_ERR";

// Employee_Job_applied_list

export const JOB_APPLIED_LIST_REQUEST = " JOB_APPLIED_LIST_REQUEST";
export const JOB_APPLIED_LIST_SUCCESS = "JOB_APPLIED_LIST_SUCCESS";
export const JOB_APPLIED_LIST_ERR = "JOB_APPLIED_LIST_ERR";


// Employee_Job_Status_change_constants

export const JOB_STATUS_CHANGE_REQUEST = " JOB_STATUS_CHANGE_REQUEST";
export const JOB_STATUS_CHANGE_SUCCESS = "JOB_STATUS_CHANGE_SUCCESS";
export const JOB_STATUS_CHANGE_ERR = "JOB_STATUS_CHANGE_ERR";

export const APPLIED_JOB_STATUS_CHANGE_REQUEST = " APPLIED_JOB_STATUS_CHANGE_REQUEST";
export const APPLIED_JOB_STATUS_CHANGE_SUCCESS = "APPLIED_JOB_STATUS_CHANGE_SUCCESS";
export const APPLIED_JOB_STATUS_CHANGE_ERR = "APPLIED_JOB_STATUS_CHANGE_ERR";
