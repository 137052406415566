import React, { useEffect, useRef, useState } from "react";
import { Divider, Grid, Typography } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";

import InputField from "../../customComponent/InputField";
import { MainContainer } from "../../content/content.element";
import { InfoHeader } from "../../customComponent/InfoHeader";
import { SubmitButton } from "../../customComponent/Buttons";

import { findUniversityAction } from "../../../actions/universityActions/universityManagmentAction";
import { createFeaturedAdsAction } from "../../../actions/LandingPageAction";
import Swal from "sweetalert2";
import { successTrueMsgAction } from "../../../actions/globalAction";

function FeaturedAdsCreateForm(props) {
  const dispatch = useDispatch();

  const [DestinationimageValue, setDestinationimageValue] = useState("");
  const [content, setContent] = useState("");
  const [name, setName] = useState("");
  const [universities, setUniversities] = useState();
  const [tags, setTags] = useState("");
  const [blogSlug, setBlogSlug] = useState("");
  const [seoKeyword, setSeoKeyword] = useState("");
  const [newsImage, setNewsImage] = useState();
  const [addIframe, setIframe] = useState("");
  const [iframeError, setIframeError] = useState(false);

  const newsimage = useRef();

  let { universityFindSuccess } = useSelector((state) => {
    return state.findUniversity;
  });

  let { successAlertMsgSuccess, successAlertMsgErr } = useSelector((state) => {
    return state.successTrueMsg;
  });

  useEffect(() => {
    dispatch(findUniversityAction());
  }, [dispatch]);

  useEffect(() => {
    if (universityFindSuccess) {
      setUniversities(
        universityFindSuccess.map((country, index) => ({
          universityname: country.universityName,
          id: country.id,
        }))
      );
    }
  }, [universityFindSuccess]);

  useEffect(() => {
    if (successAlertMsgSuccess) {
      Swal.fire("Created", "Featured Ads Creted Successfully.", "success");
      dispatch(successTrueMsgAction(false));
    }
  }, [successAlertMsgSuccess]);

  const handleDestinationImage = (event) => {
    const file = event.target.files[0];
    setDestinationimageValue(file.name);
    const reader = new FileReader();

    // Read the image file as a data URL
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      const UploadDestinationimage = reader.result;
      setNewsImage(UploadDestinationimage);
    };
  };

  // handleinput

  const handleChange = (e) => {
    const inputValue = e.target.value;
    const containsIframe = /<iframe.*?<\/iframe>/i.test(inputValue);

    if (containsIframe) {
      setIframe(e.target.value);
      setIframeError(false);
    } else {
      setIframeError(true);
    }
  };

  // HandleCreate

  const HandleCreate = () => {
    dispatch(createFeaturedAdsAction(newsImage, name, addIframe, content));
    setNewsImage();
    setIframe("");
    setDestinationimageValue("");
    setName("");
    setContent("");
    setTags("");
    setBlogSlug("");
    setSeoKeyword("");
    dispatch(successTrueMsgAction(true));
  };

  return (
    <MainContainer active={props.toggle}>
      <div
        style={{
          display: "flex",
          justifyContent: "start",
          backgroundColor: "white",
        }}
      >
        <InfoHeader headTitle="Create Featured Ads" url={"/landingPage"} />
      </div>
      <Divider />

      <Grid container sx={{ mt: 3 }}>
        <Grid item xs={12} md={6} lg={4} style={{ position: "relative" }}>
          <Grid>
            <InputField
              label="Logo"
              widthSize="92%"
              value={DestinationimageValue}
              required
              paramses
              readonly={true}
            ></InputField>
          </Grid>
          <Grid style={{ position: "absolute", top: 5, right: 30 }}>
            <button
              onClick={() => newsimage.current.click()}
              style={{
                width: "100px",
                height: "40px",
                fontSize: "13px",
                fontFamily: "monospace",
                border: "none",
              }}
            >
              Browse
            </button>
            <input
              ref={newsimage}
              type="file"
              accept="image/*"
              onChange={(event) => handleDestinationImage(event)}
              style={{ display: "none" }}
            ></input>
          </Grid>
        </Grid>

        <Grid item xs={12} md={6} lg={4} style={{ position: "relative" }}>
          <InputField
            label="Name"
            widthSize="92%"
            value={name}
            handleChange={(e) => setName(e.target.value)}
            required
            paramses
          ></InputField>
        </Grid>

        <Grid item xs={12} md={6} lg={4} style={{ position: "relative" }}>
          <InputField
            label="Media"
            handleChange={handleChange}
            widthSize="100%"
            value={addIframe}
            InputType="discription"
          />
          {iframeError ? (
            <Typography
              sx={{ fontSize: "12px", fontWeight: 400, color: "red" }}
            >
              It is not Iframe Please Iframe
            </Typography>
          ) : null}
        </Grid>

        <Grid item xs={12} lg={12} sx={{ mt: 3 }}>
          <InputField
            label="Content"
            handleChange={(e) => setContent(e.target.value)}
            widthSize="99.2%"
            value={content}
            InputType="discription"
            // dispcriptionHeight=""
            required
            paramses
          />
        </Grid>
      </Grid>
      {/* Submit Button */}
      <Grid
        item
        xs={4}
        lg={12}
        style={{
          display: "flex",
          justifyContent: "flex-start",
          marginTop: "10px",
        }}
      >
        <SubmitButton
          title="Save"
          submit=""
          widthSize="100px"
          heightSize="38px"
          type="click"
          handleSubmit={HandleCreate}
        />
      </Grid>
    </MainContainer>
  );
}

export default FeaturedAdsCreateForm;
