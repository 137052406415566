import React, { useEffect, useState } from "react";
import { InfoHeader } from "../../../customComponent/InfoHeader";
import { Box, FormLabel, Grid, Typography } from "@mui/material";
import { MainContainer } from "../../../content/content.element";
import InputField from "../../../customComponent/InputField";
import { SubmitButton, SwitchButton } from "../../../customComponent/Buttons";
import { useDispatch, useSelector } from "react-redux";
import { successTrueMsgAction } from "../../../../actions/globalAction";
import Swal from "sweetalert2";
import { ieltsSettingsFaqFindbyIdAction, ieltsSettingsFaqUpdateAction } from "../../../../actions/IeltsActions/SettingsAction";
import { useParams } from "react-router-dom";

function IeltsEditFaq({ toggle }) {
  const dispatch = useDispatch();
  let { id } = useParams();

  let { successAlertMsgSuccess } = useSelector((state) => {
    return state.successTrueMsg;
  });

  let { ieltsSettingsFaqUpdateSuccess } = useSelector((state) => {
    return state.ieltsSettingsFaqUpdate;
  });

  let { ieltsSettingsFaqFindByIdSuccess } = useSelector((state) => {
    return state.ieltsSettingsFaqFindById;
  });

  useEffect(() => {
    dispatch(ieltsSettingsFaqFindbyIdAction(id));
  }, [dispatch, ieltsSettingsFaqUpdateSuccess]);


  // success toast

 useEffect(() => {
   if (
     successAlertMsgSuccess &&
     ieltsSettingsFaqUpdateSuccess?.status == true
   ) {
     Swal.fire("Created", ieltsSettingsFaqUpdateSuccess.message, "success");
     dispatch(successTrueMsgAction(false));
   }
   if (
     successAlertMsgSuccess &&
     ieltsSettingsFaqUpdateSuccess?.status == false
   ) {
     Swal.fire("Error", ieltsSettingsFaqUpdateSuccess.message, "warning");
     dispatch(successTrueMsgAction(false));
   }

 }, [
   ieltsSettingsFaqUpdateSuccess,
   successAlertMsgSuccess
 ]);


 useEffect(() => {
  if (ieltsSettingsFaqFindByIdSuccess) {
    setFormData(ieltsSettingsFaqFindByIdSuccess.data);
  }
 }, [ieltsSettingsFaqFindByIdSuccess]);
 

  const [formData, setFormData] = useState({
    question: "",
    answer: "",
  });

  const [err, setErr] = useState({
    question: "",
    answer: "",
  });

  const validateForm = () => {
    let isValid = true;
    const newErr = { ...err };

    if (formData.question.trim() === "") {
      newErr.question = "Question cannot be empty";
      isValid = false;
    } else {
      newErr.question = "";
    }

    if (formData.answer.trim() === "") {
      newErr.answer = "Answer cannot be empty";
      isValid = false;
    } else {
      newErr.answer = "";
    }

    setErr(newErr);
    return isValid;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
    setErr((prev) => ({
      ...prev,
      [name]: "",
    }));
  };

  const handleAddNewButton = () => {
    if (validateForm()) {
      console.log(formData);
      dispatch(ieltsSettingsFaqUpdateAction(formData));
      dispatch(successTrueMsgAction(true));
    } else {
      alert("Form is invalid, please check errors");
    }
  };

  return (
    <MainContainer active={toggle}>
      <InfoHeader headTitle="Edit FAQ" url="/IELTS" />

      <div style={{ marginTop: "30px" }}>
        <div style={{ maxWidth: "1194px" }}>
          <Box
            sx={{
              display: "grid",
              gap: "25px",
              "& .input-group": {
                mb: "0px",
                mt: "0",
              },
            }}
          >
            <Box>
              <InputField
                label="Question"
                handleChange={handleChange}
                value={formData?.question}
                widthSize="100%"
                required
                paramses
                InputType="text"
                name={"question"}
              />
              {err.question ? (
                <Typography
                  sx={{
                    color: "red",
                    fontWeight: 400,
                    fontSize: "12px",
                  }}
                >
                  {err.question}
                </Typography>
              ) : (
                ""
              )}
            </Box>
            <Box>
              <InputField
                label="Answer"
                handleChange={handleChange}
                value={formData?.answer}
                widthSize="100%"
                required
                paramses
                // InputType="text"
                name={"answer"}
                dispcriptionHeight={"200px"}
                InputType="discription"
              />
              {err.answer ? (
                <Typography
                  sx={{
                    color: "red",
                    fontWeight: 400,
                    fontSize: "12px",
                  }}
                >
                  {err.answer}
                </Typography>
              ) : (
                ""
              )}
            </Box>
          </Box>
        </div>

        <Box
          sx={{
            mt: "44px",
            mb: "30px",
          }}
        >
          <SubmitButton
            title="Save"
            submit=""
            widthSize="123px"
            heightSize="38px"
            type="click"
            handleSubmit={handleAddNewButton}
          />
        </Box>
      </div>
    </MainContainer>
  );
}

export default IeltsEditFaq;
