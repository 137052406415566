import React, { useState } from "react";
import { MainContainer } from "../../content/content.element";
import HeadBreadcrumbs from "../../breadcrumbs/breadcrumbs";
import BasicTabs from "../../Tabs/TabsMain";
import { Box } from "@mui/material";

function Ielts(props) {
  const [wichTab, setWichTab] = useState(0);

  const callback = (tab) => {
    setWichTab(tab);
  };
  return (
    <MainContainer active={props.toggle}>
      <Box>
        <HeadBreadcrumbs
          head="Home"
          sechead="IELTS Courses"
          first="IELTS Courses"
          main={
            wichTab === 0
              ? "Center"
              : wichTab === 1
              ? "Learning Management"
              : wichTab === 2
              ? "Course Management"
              : wichTab === 3
              ? "Teacher Managment"
              : wichTab === 4
              ? "Enrolled"
              : wichTab === 5
              ? "Purchased"
              : wichTab === 6
              ? "Settings"
              : null
          }
        />
      </Box>
      <Box>
        <BasicTabs
          tab1Name="Center"
          tab2Name="Learning Management"
          tab3Name="Course Management"
          tab4Name="Teacher Managment"
          tab5Name="Enrolled"
          tab6Name="Purchased"
          tab7Name="Settings"
          tab1={props.tab1}
          tab2={props.tab2}
          tab3={props.tab3}
          tab4={props.tab4}
          tab5={props.tab5}
          tab6={props.tab6}
          tab7={props.tab7}
          tab8={props.tab8}
          callback={callback}
          selected={true}
        />
      </Box>
    </MainContainer>
  );
}

export default Ielts;
