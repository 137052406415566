import axios from "axios";
import {
  EMPLOYEE_INCENTIVE_CREATE_ERR,
  EMPLOYEE_INCENTIVE_CREATE_REQUEST,
  EMPLOYEE_INCENTIVE_CREATE_SUCCESS,
  EMPLOYEE_INCENTIVE_FIND_ERR,
  EMPLOYEE_INCENTIVE_FIND_REQUEST,
  EMPLOYEE_INCENTIVE_FIND_SUCCESS,
  PARTNER_COMMISSION_CREATE_ERR,
  PARTNER_COMMISSION_CREATE_REQUEST,
  PARTNER_COMMISSION_CREATE_SUCCESS,
  PARTNER_COMMISSION_DELETE_ERR,
  PARTNER_COMMISSION_DELETE_REQUEST,
  PARTNER_COMMISSION_DELETE_SUCCESS,
  PARTNER_COMMISSION_FIND_ERR,
  PARTNER_COMMISSION_FIND_REQUEST,
  PARTNER_COMMISSION_FIND_SUCCESS,
} from "../constants/incentiveConstant";
import {
  SUCCESS_TRUE_MSG_ERR,
  SUCCESS_TRUE_MSG_SUCCESS,
} from "../constants/roleManagmentConstant";

// partnerCommissionCreateAction
export const partnerCommissionCreateAction =
  (category, percentage) => async (dispatch, getState) => {
    try {
      let isUserExist = localStorage.getItem("loginInfo")
        ? JSON.parse(localStorage.getItem("loginInfo"))
        : null;

      const config = {
        headers: {
          Authorization: `Bearer ${isUserExist?.token}`,
        },
      };

      dispatch({ type: PARTNER_COMMISSION_CREATE_REQUEST });

      let { data } = await axios.post(
        "/api/admin/incentive/partnerCommissionCreate",
        {
          category,
          percentage,
        },
        config
      );

      dispatch({ type: PARTNER_COMMISSION_CREATE_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: PARTNER_COMMISSION_CREATE_ERR,
        payload: error.response.data,
      });
    }
  };

// partnerCommissionFindAction
export const partnerCommissionFindAction = () => async (dispatch, getState) => {
  try {
    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    dispatch({ type: PARTNER_COMMISSION_FIND_REQUEST });

    let { data } = await axios.get(
      "/api/admin/incentive/partnerCommissionFind",
      config
    );

    dispatch({ type: PARTNER_COMMISSION_FIND_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: PARTNER_COMMISSION_FIND_ERR,
      payload: error.response.data,
    });
  }
};

// partnerCommissionDeleteAction
export const partnerCommissionDeleteAction =
  (id) => async (dispatch, getState) => {
    try {
      let isUserExist = localStorage.getItem("loginInfo")
        ? JSON.parse(localStorage.getItem("loginInfo"))
        : null;

      const config = {
        headers: {
          Authorization: `Bearer ${isUserExist?.token}`,
        },
      };

      dispatch({ type: PARTNER_COMMISSION_DELETE_REQUEST });

      let { data } = await axios.delete(
        `/api/admin/incentive/partnerCommissionDelete?id=${id}`,
        config
      );

      dispatch({ type: PARTNER_COMMISSION_DELETE_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: PARTNER_COMMISSION_DELETE_ERR,
        payload: error.response.data,
      });
    }
  };

// EMPLOYEE INCENTIVE CATEGORY

// employeeIncentiveCreateAction
export const employeeIncentiveCreateAction =
  (
    role,
    branch,
    enrolledStudentAmount,
    bonuStudentAmount,
    studyAbroadStudentAmount,
    categoryValue,
    extraIncentiveData,
    directUniversityNote,
    extraIncentiveNote,
    bonusNote,
    otherStudyAbroadNote,
    status
  ) =>
  async (dispatch, getState) => {
    try {
      let isUserExist = localStorage.getItem("loginInfo")
        ? JSON.parse(localStorage.getItem("loginInfo"))
        : null;

      const config = {
        headers: {
          Authorization: `Bearer ${isUserExist?.token}`,
        },
      };

      dispatch({ type: EMPLOYEE_INCENTIVE_CREATE_REQUEST });

      let { data } = await axios.post(
        "/api/admin/incentive/employeeIncentiveCreate",
        {
          role: role,
          branch: branch,
          enrolledStudentAmount: enrolledStudentAmount,
          bonuStudentAmount: bonuStudentAmount,
          studyAbroadStudentAmount: studyAbroadStudentAmount,
          categoryValue: categoryValue,
          extraIncentiveData: extraIncentiveData,
          directUniversityNote,
          extraIncentiveNote,
          bonusNote,
          otherStudyAbroadNote,
          status: status,
        },
        config
      );

      dispatch({ type: EMPLOYEE_INCENTIVE_CREATE_SUCCESS, payload: data });

      dispatch({ type: SUCCESS_TRUE_MSG_SUCCESS, payload: true });
    } catch (error) {
      dispatch({
        type: EMPLOYEE_INCENTIVE_CREATE_ERR,
        payload: error.response.data,
      });
      dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: true });
    }
  };

// employeeIncentiveFindAction
export const employeeIncentiveFindAction = () => async (dispatch, getState) => {
  try {
    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    dispatch({ type: EMPLOYEE_INCENTIVE_FIND_REQUEST });

    let { data } = await axios.get(
      "/api/admin/incentive/employeeIncentiveFind",
      config
    );

    dispatch({ type: EMPLOYEE_INCENTIVE_FIND_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: EMPLOYEE_INCENTIVE_FIND_ERR,
      payload: error.response.data,
    });
  }
};
