import React, { useEffect, useState } from "react";
import BasicTabs from "../../../../../Tabs/TabsMain";
import { Box, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { ieltsCourseManagmentCreateCourseAction } from "../../../../../../actions/IeltsActions/courseManagmentAction";
import TopicTabItemEdit from "./TopicTabItemEdit";

function SelectTopicTabEdit({ type }) {
  const dispatch = useDispatch();

  let { ieltsCourseManagmentCourseCreateSuccess } = useSelector((state) => {
    return state.ieltsCourseManagmentCreateCourse;
  });

  const [Selectedfile, setSelectedFiles] = useState();

  useEffect(() => {
    setSelectedFiles(ieltsCourseManagmentCourseCreateSuccess);
  }, [ieltsCourseManagmentCourseCreateSuccess]);

  console.log(ieltsCourseManagmentCourseCreateSuccess,"ieltsCourseManagmentCourseCreateSuccess")

  const handleFilesSelected = (fileType, value) => {
    console.log(fileType, "fileTypeeeeeeeeee");
    console.log(value, "valueeeeeeeee");

    const mappedValues = value.map((item) => ({
      id: item.id,
      categoryName: item.Category || item.category,
    }));
    console.log(mappedValues, "mappedValues");
    const updateItemData = (prevItemData) => ({
      ...prevItemData,
      online: {
        ...prevItemData.online,
        [type]: {
          ...prevItemData.online[type],
          Topics: {
            ...prevItemData.online[type].Topics,
            [fileType]: mappedValues,
          },
        },
      },
      onlineItems: {
        ...prevItemData?.onlineItems,
        [type]: {
          ...prevItemData?.onlineItems[type],
          Topics: {
            ...prevItemData?.onlineItems[type]?.Topics,
            [fileType]: value,
          },
        },
      },
    });
    setSelectedFiles((prevItemData) => updateItemData(prevItemData));
    dispatch(
      ieltsCourseManagmentCreateCourseAction(updateItemData(Selectedfile))
    );
    console.log(Selectedfile);
  };

  return (
    <Box sx={{}}>
      <Typography
        sx={{
          mb: "0px",
          fontWeight: "600",
          fontSize: "12px",
        }}
      >
        Select Topics
      </Typography>
      <BasicTabs
        tab1Name="Videos"
        tab2Name="Audios"
        tab3Name="Notes"
        tab4Name="Examtip"
        tab5Name="Questionbank"
        tab1={
          <TopicTabItemEdit
            category={type}
            Selectedfile={Selectedfile}
            handleSelected={handleFilesSelected}
            type={"video"}
          />
        }
        tab2={
          <TopicTabItemEdit
            category={type}
            Selectedfile={Selectedfile}
            handleSelected={handleFilesSelected}
            type={"audio"}
          />
        }
        tab3={
          <TopicTabItemEdit
            category={type}
            Selectedfile={Selectedfile}
            handleSelected={handleFilesSelected}
            type={"notes"}
          />
        }
        tab4={
          <TopicTabItemEdit
            category={type}
            Selectedfile={Selectedfile}
            handleSelected={handleFilesSelected}
            type={"examtip"}
          />
        }
        tab5={
          <TopicTabItemEdit
            category={type}
            Selectedfile={Selectedfile}
            handleSelected={handleFilesSelected}
            type={"questionbank"}
          />
        }
      />
    </Box>
  );
}

export default SelectTopicTabEdit;
