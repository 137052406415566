import React from 'react'

function ReferIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.6302 13.3333H5.29688V24C5.29688 28 6.63021 29.3333 10.6302 29.3333H21.2969C25.2969 29.3333 26.6302 28 26.6302 24V13.3333Z"
        stroke="#FF1B6F"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M28.6693 9.33335V10.6667C28.6693 12.1334 27.9626 13.3334 26.0026 13.3334H6.0026C3.9626 13.3334 3.33594 12.1334 3.33594 10.6667V9.33335C3.33594 7.86669 3.9626 6.66669 6.0026 6.66669H26.0026C27.9626 6.66669 28.6693 7.86669 28.6693 9.33335Z"
        stroke="#FF1B6F"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15.5184 6.66665H8.15841C7.70508 6.17332 7.71841 5.41332 8.19841 4.93332L10.0917 3.03998C10.5851 2.54665 11.3984 2.54665 11.8917 3.03998L15.5184 6.66665Z"
        stroke="#FF1B6F"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M23.8288 6.66665H16.4688L20.0954 3.03998C20.5887 2.54665 21.4021 2.54665 21.8954 3.03998L23.7887 4.93332C24.2687 5.41332 24.2821 6.17332 23.8288 6.66665Z"
        stroke="#FF1B6F"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.9219 13.3333V20.1866C11.9219 21.2533 13.0952 21.88 13.9885 21.3066L15.2419 20.48C15.6952 20.1866 16.2685 20.1866 16.7085 20.48L17.8952 21.28C18.7752 21.8666 19.9619 21.24 19.9619 20.1733V13.3333H11.9219Z"
        stroke="#FF1B6F"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default ReferIcon