import { Box, Button, Checkbox, Menu, MenuItem } from "@mui/material";
import React, { useState } from "react";

function ReportIncentiveMenu({ handleSearch }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "end",
        gap: "12px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          border: "1px solid #ECECEC",
          width: "fit-content",
          borderRadius: "5px",
          "& input": {
            outline: "none",
            border: "none",
            paddingLeft: "15px",
          },
        }}
      >
        <input type="text" placeholder="Search here" onChange={handleSearch} />
        <Button
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            border: "1px solid #ECECEC",
            borderRadius: "5px",
            padding: "8px 12px",
            backgroundColor: "#FFFFFF",
            width: "48px",
            height: "40px",
            boxSizing: "border-box",
            minWidth: "0",
            borderRight: "0",
            borderBottom: "0",
            borderTop: "0",
          }}
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.5 21.75C5.85 21.75 1.25 17.15 1.25 11.5C1.25 5.85 5.85 1.25 11.5 1.25C17.15 1.25 21.75 5.85 21.75 11.5C21.75 17.15 17.15 21.75 11.5 21.75ZM11.5 2.75C6.67 2.75 2.75 6.68 2.75 11.5C2.75 16.32 6.67 20.25 11.5 20.25C16.33 20.25 20.25 16.32 20.25 11.5C20.25 6.68 16.33 2.75 11.5 2.75Z"
              fill="#ACB1C6"
            />
            <path
              d="M21.9995 22.7475C21.8095 22.7475 21.6195 22.6775 21.4695 22.5275L19.4695 20.5275C19.1795 20.2375 19.1795 19.7575 19.4695 19.4675C19.7595 19.1775 20.2395 19.1775 20.5295 19.4675L22.5295 21.4675C22.8195 21.7575 22.8195 22.2375 22.5295 22.5275C22.3795 22.6775 22.1895 22.7475 21.9995 22.7475Z"
              fill="#ACB1C6"
            />
          </svg>
        </Button>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "end",
          gap: "12px",
        }}
      >
        <Box>
          <Button
            style={{
              border: "1px solid rgba(20, 30, 60, 1)",
              color: "black",
              cursor: "pointer",
              zIndex: 1,
              height: "100%",
              marginBottom: "2px",
              position: "relative",
              fontWeight: 600,
              fontSize: "12px",
              borderRadius: "8px",
              boxShadow: 0,
            }}
            variant="outlined"
            onClick={handleClick}
            id="demo-positioned-button"
            aria-controls={open ? "demo-positioned-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            {"Column visibility"}
          </Button>

          <Menu
            id="demo-positioned-menu"
            aria-labelledby="demo-positioned-button"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            sx={{ borderColor: "black", borderRadius: "24px", mt: 6 }}
          >
            <MenuItem>
              <Checkbox
                sx={{
                  color: "#F8469A",
                  "&.Mui-checked": {
                    color: "#F8469A",
                  },
                }}
              />
              ID
            </MenuItem>
            <MenuItem>
              <Checkbox
                sx={{
                  color: "#F8469A",
                  "&.Mui-checked": {
                    color: "#F8469A",
                  },
                }}
              />
              STUDENT NAME
            </MenuItem>
            <MenuItem>
              <Checkbox
                sx={{
                  color: "#F8469A",
                  "&.Mui-checked": {
                    color: "#F8469A",
                  },
                }}
              />
              UNIVERSITY
            </MenuItem>
            <MenuItem>
              <Checkbox
                sx={{
                  color: "#F8469A",
                  "&.Mui-checked": {
                    color: "#F8469A",
                  },
                }}
              />
              COURSE
            </MenuItem>
            <MenuItem>
              <Checkbox
                sx={{
                  color: "#F8469A",
                  "&.Mui-checked": {
                    color: "#F8469A",
                  },
                }}
              />
              DATE
            </MenuItem>
            <MenuItem>
              <Checkbox
                sx={{
                  color: "#F8469A",
                  "&.Mui-checked": {
                    color: "#F8469A",
                  },
                }}
              />
              STATUS
            </MenuItem>
          </Menu>
        </Box>

        <Button
          style={{
            border: "1px solid rgba(20, 30, 60, 1)",
            color: "black",
            cursor: "pointer",
            height: "100%",
            zIndex: 1,
            marginBottom: "2px",
            position: "relative",
            fontWeight: 600,
            fontSize: "12px",
            borderRadius: "8px",
            boxShadow: 0,
          }}
          variant="outlined"
        >
          {"Export"}
        </Button>
      </Box>
    </Box>
  );
}

export default ReportIncentiveMenu;
