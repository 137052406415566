
// add university cover image
export const ADD_UNIVERSITY_COVER_IMAGE_REQUEST = "ADD_UNIVERSITY_COVER_IMAGE_REQUEST";
export const ADD_UNIVERSITY_COVER_IMAGE_SUCCESS = "ADD_UNIVERSITY_COVER_IMAGE_SUCCESS";
export const ADD_UNIVERSITY_COVER_IMAGE_ERR = "ADD_UNIVERSITY_COVER_IMAGE_ERR";

// get university cover image
export const GET_UNIVERSITY_COVER_IMAGE_REQUEST = "GET_UNIVERSITY_COVER_IMAGE_REQUEST";
export const GET_UNIVERSITY_COVER_IMAGE_SUCCESS = "GET_UNIVERSITY_COVER_IMAGE_SUCCESS";
export const GET_UNIVERSITY_COVER_IMAGE_ERR = "GET_UNIVERSITY_COVER_IMAGE_ERR";

// delete university cover image
export const DELETE_UNIVERSITY_COVER_IMAGE_REQUEST = "DELETE_UNIVERSITY_COVER_IMAGE_REQUEST";
export const DELETE_UNIVERSITY_COVER_IMAGE_SUCCESS = "DELETE_UNIVERSITY_COVER_IMAGE_SUCCESS";
export const DELETE_UNIVERSITY_COVER_IMAGE_ERR = "DELETE_UNIVERSITY_COVER_IMAGE_ERR";
