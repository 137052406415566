import React, { useState } from "react";
import {
  Alert,
  AlertTitle,
  Box,
  Checkbox,
  Chip,
  Collapse,
  FormControlLabel,
  FormGroup,
  Grid,
  List,
  ListItemButton,
  ListSubheader,
  Typography,
} from "@mui/material";
import { MdClear } from "react-icons/md";
import IeltsTeacherDashBoardHeader from "./IeltsTeacherDashBoardHeader";
import IeltsTeacherDashBoardTable from "./IeltsTeacherDashBoardTable";
import { MainContainer } from "../../content/content.element";
import { InfoHeader } from "../../customComponent/InfoHeader";
import { ieltsTeacherDashBoardFindaction } from "../../../actions/IeltsActions/teacherDashboardAction";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import DownloadExcel from "../../extraComponents/DownloadExcel";
import DownloadPdf from "../../extraComponents/DownloadPdf";
import usePrivilegeCheck from "../../../constants/customHooks/UsePrivilegeCheck";

function IeltsTeacherDashBoardMain({ toggle }) {
  const dispatch = useDispatch();

  const { privilege, hasPrivilege } = usePrivilegeCheck();

  const [selectedStatus, setSelectedStatus] = useState("");
  const [openStatus, setStatusOpen] = useState(true);
  const [selectedCourse, setSelectedCourse] = useState("");
  const [openCourse, setCourseOpen] = useState(false);
  const [selectedGender, setSelectedGender] = useState("");
  const [openGender, setGenderOpen] = useState(false);
  const [assignedStudents, setAssignedstudentsList] = useState([]);
  const [coursesList, setCourses] = useState([]);

  const status = ["Active", "Inactive"];
  const gender = ["male", "female"];

  // Fetch data

  let isUserExist = localStorage.getItem("loginInfo")
    ? JSON.parse(localStorage.getItem("loginInfo"))
    : null;

  let {
    ieltsTeacherDashBoardFindSuccess,
    ieltsTeacherDashBoardFinderror,
  } = useSelector((state) => {
    return state.ieltsTeacherDashboardFind;
  });

  let {
    ieltsStudentsStatusChangeSuccess,
    ieltsStudentsStatusChangeerror,
  } = useSelector((state) => {
    return state.ieltsStudentsStatusChange;
  });

  useEffect(() => {
    dispatch(ieltsTeacherDashBoardFindaction(isUserExist.UserId));
  }, [dispatch, ieltsStudentsStatusChangeSuccess]);

  useEffect(() => {
    if (ieltsTeacherDashBoardFindSuccess) {
      setAssignedstudentsList(
        ieltsTeacherDashBoardFindSuccess?.data?.AssignedStudents
      );
      let uniqueState = Array.from(
        new Set(
          ieltsTeacherDashBoardFindSuccess?.data?.AssignedStudents.map(
            (item) => item?.ielts_course.CourseName
          )
        )
      );
      setCourses(uniqueState);
    }
  }, [ieltsTeacherDashBoardFindSuccess]);

  // handleFilter function

  const handleFilterFunction = (filterValue, checkedStatus, type) => {
    if (type === "status") {
      setSelectedStatus(filterValue === selectedStatus ? null : filterValue);
      if (checkedStatus) {
        setAssignedstudentsList(
          ieltsTeacherDashBoardFindSuccess?.data?.AssignedStudents.filter(
            (item) => item?.ielts_entrolled_student?.status === filterValue
          )
        );
      } else {
        setAssignedstudentsList(
          ieltsTeacherDashBoardFindSuccess?.data?.AssignedStudents
        );
      }
    } else if (type === "course") {
      setSelectedCourse(filterValue === selectedCourse ? null : filterValue);
      if (checkedStatus) {
        setAssignedstudentsList(
          ieltsTeacherDashBoardFindSuccess?.data?.AssignedStudents.filter(
            (item) => item?.ielts_course.CourseName === filterValue
          )
        );
      } else {
        setAssignedstudentsList(
          ieltsTeacherDashBoardFindSuccess?.data?.AssignedStudents
        );
      }
    } else if (type === "gender") {
      setSelectedGender(filterValue === selectedGender ? null : filterValue);
      if (checkedStatus) {
        setAssignedstudentsList(
          ieltsTeacherDashBoardFindSuccess?.data?.AssignedStudents.filter(
            (item) => item?.ielts_entrolled_student.gender === filterValue
          )
        );
      } else {
        setAssignedstudentsList(
          ieltsTeacherDashBoardFindSuccess?.data?.AssignedStudents
        );
      }
    } else {
      setAssignedstudentsList(
        ieltsTeacherDashBoardFindSuccess?.data?.AssignedStudents
      );
    }
  };

  // Handle SearchFunction

  const searchHandleChange = (value) => {
    console.log(value, "valueeeeeeeeeeeeeeeeeeeeeeee");
    if (value) {
      setAssignedstudentsList(
        ieltsTeacherDashBoardFindSuccess?.data?.AssignedStudents?.filter(
          (item) => {
            const name = item?.ielts_entrolled_student.fullName
              ? item?.ielts_entrolled_student.fullName.toLowerCase()
              : "";
            const course = item?.ielts_course.CourseName
              ? item?.ielts_course.CourseName.toLowerCase()
              : "";

            console.log(name, "nameeeeeeeeeeeeeeeeeeeeee");
            return (
              name.includes(value.toLowerCase()) ||
              course.includes(value.toLowerCase())
            );
          }
        )
      );
    } else {
      setAssignedstudentsList(
        ieltsTeacherDashBoardFindSuccess?.data?.AssignedStudents
      );
    }
  };

  console.log(assignedStudents, "assignedStudentsassignedStudents");
  // Handle ClearAll function

  const handleClearAllFunction = () => {
    setSelectedStatus("");
    setSelectedGender("");
    setSelectedCourse("");
    setAssignedstudentsList(
      ieltsTeacherDashBoardFindSuccess?.data?.AssignedStudents
    );
  };

  // Students column Visibility

  const [showName, setShowNameColumn] = useState(false);
  const [showDobColumn, setShowDobColumn] = useState(false);
  const [showgenderColumn, setShowGenderColumn] = useState(false);
  const [showEmailColumn, setShowEmailColumn] = useState(false);
  const [showPhoneColumn, setShowPhoneColumn] = useState(false);
  const [showWhatsAppNoColumn, setShowWhatsAppNoColumn] = useState(false);
  const [showCourseColumn, setShowCourseColumn] = useState(false);
  const [showCenterColumn, setShowCenterColumn] = useState(false);
  const [showPaymentColumn, setShowPaymentColumn] = useState(false);
  const [showStatusColumn, setShowStatusColumn] = useState(false);

  const [columnName, setColumnName] = useState("");
  const [leadId, setLeadId] = useState([]);
  const [ids, setIds] = useState([]);

  // tabel columns

  const columns = [
    {
      title: "SL NO",
      field: "sl",
    },
    {
      title: "STUDENT NAME",
      field: "STUDENT NAME",
      hidden: showName,
    },
    {
      title: "DOB",
      field: "DOB",
      hidden: showDobColumn,
    },
    {
      title: "GENDER",
      field: "GENDER",
      hidden: showgenderColumn,
    },
    {
      title: "EMAIL",
      field: "EMAIL",
      hidden: showEmailColumn,
    },
    {
      title: "PHONE",
      field: "PHONE",
      hidden: showPhoneColumn,
    },

    {
      title: "WHATSAPP NO",
      field: "WHATSAPP NO",
      hidden: showWhatsAppNoColumn,
    },

    {
      title: "COURSE",
      field: "COURSE",
      hidden: showCourseColumn,
    },

    {
      title: "CENTER",
      field: "CENTER",
      hidden: showCenterColumn,
    },

    {
      title: "PAYMENT",
      field: "PAYMENT",
      hidden: showPaymentColumn,
    },
    {
      title: "STATUS",
      field: "STATUS",
      hidden: showStatusColumn,
    },
  ];

  // Excel export function
  const excelDownloadClick = () => {
    DownloadExcel(columns, assignedStudents, "AssignedStudentsList");
  };

  // Pdf Export function

  const pdfDownloadClick = () => {
    DownloadPdf(
      columns,
      assignedStudents,
      "AssignedStudents",
      "AssignedStudentsList"
    );
  };

  const toggleNameColumn = (status, column) => {
    if (column === "name") {
      setColumnName(column);
      setShowNameColumn(status);
    }
    if (column === "dob") {
      setColumnName(column);
      setShowDobColumn(status);
    }
    if (column === "gender") {
      setColumnName(column);
      setShowGenderColumn(status);
    }
    if (column === "email") {
      setColumnName(column);
      setShowEmailColumn(status);
    }
    if (column === "phone") {
      setColumnName(column);
      setShowPhoneColumn(status);
    }
    if (column === "whatsapp") {
      setColumnName(column);
      setShowWhatsAppNoColumn(status);
    }
    if (column === "center") {
      setColumnName(column);
      setShowCenterColumn(status);
    }
    if (column === "payment") {
      setColumnName(column);
      setShowPaymentColumn(status);
    }
    if (column === "course") {
      setColumnName(column);
      setShowCourseColumn(status);
    }
    if (column === "status") {
      setColumnName(column);
      setShowStatusColumn(status);
    }
  };

  useEffect(() => {
    // Retrieve column visibility state from localStorage
    const storedFullName = localStorage.getItem("name");
    const storedDob = localStorage.getItem("dob");
    const storedGender = localStorage.getItem("gender");
    const storedEmail = localStorage.getItem("email");
    const storedPhone = localStorage.getItem("phone");
    const storedWhatsApp = localStorage.getItem("whatsapp");
    const storedCourse = localStorage.getItem("course");
    const storedCenter = localStorage.getItem("center");
    const storedPayment = localStorage.getItem("payment");
    const storedStatus = localStorage.getItem("status");

    if (storedFullName !== null) {
      setColumnName("name");
      setShowDobColumn(JSON.parse(storedFullName));
    }
    if (storedDob !== null) {
      setColumnName("dob");
      setShowDobColumn(JSON.parse(storedDob));
    }
    if (storedGender !== null) {
      setColumnName("gender");
      setShowGenderColumn(JSON.parse(storedGender));
    }
    if (storedEmail !== null) {
      setColumnName("email");
      setShowEmailColumn(JSON.parse(storedEmail));
    }
    if (storedPhone !== null) {
      setColumnName("phone");
      setShowPhoneColumn(JSON.parse(storedPhone));
    }
    if (storedWhatsApp !== null) {
      setColumnName("whatsapp");
      setShowWhatsAppNoColumn(JSON.parse(storedWhatsApp));
    }
    if (storedCenter !== null) {
      setColumnName("center");
      setShowCenterColumn(JSON.parse(storedCenter));
    }
    if (storedPayment !== null) {
      setColumnName("payment");
      setShowPaymentColumn(JSON.parse(storedPayment));
    }

    if (storedCourse !== null) {
      setColumnName("course");
      setShowCourseColumn(JSON.parse(storedCourse));
    }
    if (storedStatus !== null) {
      setColumnName("status");
      setShowStatusColumn(JSON.parse(storedStatus));
    }
  }, []);

  useEffect(() => {
    // Save column visibility payment to localStorage
    localStorage.setItem("name", JSON.stringify(showName));
    localStorage.setItem("dob", JSON.stringify(showDobColumn));
    localStorage.setItem("gender", JSON.stringify(showgenderColumn));
    localStorage.setItem("email", JSON.stringify(showEmailColumn));
    localStorage.setItem("phone", JSON.stringify(showPhoneColumn));
    localStorage.setItem("whatsapp", JSON.stringify(showWhatsAppNoColumn));
    localStorage.setItem("center", JSON.stringify(showCenterColumn));
    localStorage.setItem("payment", JSON.stringify(showPaymentColumn));
    localStorage.setItem("course", JSON.stringify(showCourseColumn));
    localStorage.setItem("status", JSON.stringify(showStatusColumn));
  }, [
    showName,
    showDobColumn,
    showDobColumn,
    showgenderColumn,
    showEmailColumn,
    showPhoneColumn,
    showWhatsAppNoColumn,
    showCenterColumn,
    showPaymentColumn,
    showCourseColumn,
    showStatusColumn,
    columnName,
  ]);

  // const [ids, setIds] = useState([]);
  const selectBoxIdPassHandler = (ids) => {
    setIds(ids);
  };

  if (!hasPrivilege("teacher managment") && privilege !== null) {
    return (
      <Alert severity="error">
        <AlertTitle>warning</AlertTitle>
        You don't have permission to access — <strong>this page.!</strong>
      </Alert>
    );
  }

  return (
    <MainContainer active={toggle}>
      <InfoHeader
        headTitle={"Assigned Students"}
        url="/ielts/teacherdashboard/"
      />
      <Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            my: "30px",
            flexWrap: "wrap",
            gap: "20px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: "10px",
              flexWrap: "wrap",
              //   maxWidth:"330px",
            }}
          >
            {selectedStatus && (
              <Chip
                sx={{
                  "&.MuiChip-root": {
                    pl: "5px",
                    pr: "5px",
                  },
                }}
                label={
                  <Typography
                    sx={{
                      fontSize: "10px",
                      fontWeight: "700",
                      color: "rgba(88, 94, 106, 1)",
                    }}
                  >
                    Status:{" "}
                    <Typography
                      sx={{
                        fontSize: "10px",
                        fontWeight: "400",
                      }}
                      component={"span"}
                    >
                      {selectedStatus}
                    </Typography>
                  </Typography>
                }
                onDelete={() => setSelectedStatus(null)}
              />
            )}
            {selectedCourse && (
              <Chip
                sx={{
                  "&.MuiChip-root": {
                    pl: "5px",
                    pr: "5px",
                  },
                }}
                label={
                  <Typography
                    sx={{
                      fontSize: "10px",
                      fontWeight: "700",
                      color: "rgba(88, 94, 106, 1)",
                    }}
                  >
                    Course:{" "}
                    <Typography
                      sx={{
                        fontSize: "10px",
                        fontWeight: "400",
                      }}
                      component={"span"}
                    >
                      {selectedCourse}
                    </Typography>
                  </Typography>
                }
                onDelete={() => setSelectedCourse(null)}
              />
            )}
            {selectedGender && (
              <Chip
                sx={{
                  "&.MuiChip-root": {
                    pl: "5px",
                    pr: "5px",
                  },
                }}
                label={
                  <Typography
                    sx={{
                      fontSize: "10px",
                      fontWeight: "700",
                      color: "rgba(88, 94, 106, 1)",
                    }}
                  >
                    Gender:{" "}
                    <Typography
                      sx={{
                        fontSize: "10px",
                        fontWeight: "400",
                      }}
                      component={"span"}
                    >
                      {selectedGender}
                    </Typography>
                  </Typography>
                }
                onDelete={() => setSelectedGender(null)}
              />
            )}
          </Box>
          <IeltsTeacherDashBoardHeader
            columnVisibleHandler={toggleNameColumn}
            excelDownloadClick={excelDownloadClick}
            pdfDownloadClick={pdfDownloadClick}
            showNameColumn={showName}
            showDobColumn={showDobColumn}
            showGenderColumn={showgenderColumn}
            showEmailColumn={showEmailColumn}
            showPhoneColumn={showPhoneColumn}
            showWhatsAppColumn={showWhatsAppNoColumn}
            showCourseColumn={showCourseColumn}
            showCenterColumn={showCenterColumn}
            showPaymentColumn={showPaymentColumn}
            showStatusColumn={showStatusColumn}
            //  handleResetData={handleResetData}
            searchHandleChange={searchHandleChange}
            ids={ids}
            leadId={leadId}
          />
        </Box>

        <Box
          sx={{
            mt: 2,
            display: "grid",
            gridTemplateColumns: {
              xs: "1fr",
              lg: "330px 1fr",
            },
            gap: "25px",
          }}
        >
          <Box
            sx={{
              " & .MuiListSubheader-root": {
                backgroundColor: "#F2F2F2",
              },
            }}
          >
            <List
              component="nav"
              aria-labelledby="nested-list-subheader"
              subheader={
                <ListSubheader component="div" id="nested-list-subheader">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <div>Filter</div>
                    <div
                      style={{
                        display: "flex",
                        marginTop: "12px",
                        cursor: "pointer",
                      }}
                      //   onClick={handleClearClick}
                    >
                      <MdClear style={{ fontSize: "22px", height: "20px" }} />{" "}
                      <Typography onClick={handleClearAllFunction}>
                        Clear All
                      </Typography>
                    </div>
                  </div>
                </ListSubheader>
              }
            >
              <ListItemButton
                onClick={() => setStatusOpen(!openStatus)}
                sx={{
                  mt: "20px",
                  fontWeight: "700",
                  borderBottom: "1px solid rgba(236, 236, 236, 1)",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                Status
                <svg
                  width="13"
                  height="7"
                  viewBox="0 0 13 7"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.30059 6.20042C5.83685 6.20042 5.37312 6.02042 5.02201 5.66708L0.702682 1.32042C0.510564 1.12708 0.510564 0.807083 0.702682 0.61375C0.894799 0.420417 1.21279 0.420417 1.4049 0.61375L5.72423 4.96042C6.04222 5.28042 6.55895 5.28042 6.87694 4.96042L11.1963 0.61375C11.3884 0.420417 11.7064 0.420417 11.8985 0.61375C12.0906 0.807083 12.0906 1.12708 11.8985 1.32042L7.57916 5.66708C7.22805 6.02042 6.76432 6.20042 6.30059 6.20042Z"
                    fill="#05050F"
                  />
                </svg>
              </ListItemButton>
              <Collapse in={openStatus} timeout="auto" unmountOnExit sx={{}}>
                <List component="div" disablePadding>
                  <ListItemButton>
                    <FormGroup
                      sx={{
                        "& .MuiFormControlLabel-root": {
                          width: "100%",
                        },
                        width: "100%",
                      }}
                    >
                      {status &&
                        status?.map((item, i) => (
                          <FormControlLabel
                            key={i}
                            control={
                              <Checkbox
                                sx={{
                                  color: "#F8469A",
                                  "&.Mui-checked": {
                                    color: "#F8469A",
                                  },
                                }}
                                checked={item === selectedStatus}
                                onChange={(e) => {
                                  handleFilterFunction(
                                    item,
                                    e.target.checked,
                                    "status"
                                  );
                                }}
                              />
                            }
                            label={item}
                          />
                        ))}
                    </FormGroup>
                  </ListItemButton>
                </List>
              </Collapse>

              <ListItemButton
                onClick={() => setCourseOpen(!openCourse)}
                sx={{
                  fontWeight: "700",
                  borderBottom: "1px solid rgba(236, 236, 236, 1)",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                Course
                <svg
                  width="13"
                  height="7"
                  viewBox="0 0 13 7"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.30059 6.20042C5.83685 6.20042 5.37312 6.02042 5.02201 5.66708L0.702682 1.32042C0.510564 1.12708 0.510564 0.807083 0.702682 0.61375C0.894799 0.420417 1.21279 0.420417 1.4049 0.61375L5.72423 4.96042C6.04222 5.28042 6.55895 5.28042 6.87694 4.96042L11.1963 0.61375C11.3884 0.420417 11.7064 0.420417 11.8985 0.61375C12.0906 0.807083 12.0906 1.12708 11.8985 1.32042L7.57916 5.66708C7.22805 6.02042 6.76432 6.20042 6.30059 6.20042Z"
                    fill="#05050F"
                  />
                </svg>
              </ListItemButton>
              <Collapse in={openCourse} timeout="auto" unmountOnExit sx={{}}>
                <List component="div" disablePadding>
                  <ListItemButton>
                    <FormGroup
                      sx={{
                        "& .MuiFormControlLabel-root": {
                          width: "100%",
                        },
                        width: "100%",
                      }}
                    >
                      {coursesList &&
                        coursesList?.map((item, i) => (
                          <FormControlLabel
                            key={i}
                            control={
                              <Checkbox
                                sx={{
                                  color: "#F8469A",
                                  "&.Mui-checked": {
                                    color: "#F8469A",
                                  },
                                }}
                                checked={item === selectedCourse}
                                onChange={(e) => {
                                  handleFilterFunction(
                                    item,
                                    e.target.checked,
                                    "course"
                                  );
                                }}
                              />
                            }
                            label={item}
                          />
                        ))}
                    </FormGroup>
                  </ListItemButton>
                </List>
              </Collapse>

              <ListItemButton
                onClick={() => setGenderOpen(!openGender)}
                sx={{
                  fontWeight: "700",
                  borderBottom: "1px solid rgba(236, 236, 236, 1)",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                Gender
                <svg
                  width="13"
                  height="7"
                  viewBox="0 0 13 7"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.30059 6.20042C5.83685 6.20042 5.37312 6.02042 5.02201 5.66708L0.702682 1.32042C0.510564 1.12708 0.510564 0.807083 0.702682 0.61375C0.894799 0.420417 1.21279 0.420417 1.4049 0.61375L5.72423 4.96042C6.04222 5.28042 6.55895 5.28042 6.87694 4.96042L11.1963 0.61375C11.3884 0.420417 11.7064 0.420417 11.8985 0.61375C12.0906 0.807083 12.0906 1.12708 11.8985 1.32042L7.57916 5.66708C7.22805 6.02042 6.76432 6.20042 6.30059 6.20042Z"
                    fill="#05050F"
                  />
                </svg>
              </ListItemButton>
              <Collapse in={openGender} timeout="auto" unmountOnExit sx={{}}>
                <List component="div" disablePadding>
                  <ListItemButton>
                    <FormGroup
                      sx={{
                        "& .MuiFormControlLabel-root": {
                          width: "100%",
                        },
                        width: "100%",
                      }}
                    >
                      {gender &&
                        gender?.map((item, i) => (
                          <FormControlLabel
                            key={i}
                            control={
                              <Checkbox
                                sx={{
                                  color: "#F8469A",
                                  "&.Mui-checked": {
                                    color: "#F8469A",
                                  },
                                }}
                                checked={item === selectedGender}
                                onChange={(e) => {
                                  handleFilterFunction(
                                    item,
                                    e.target.checked,
                                    "gender"
                                  );
                                }}
                              />
                            }
                            label={item}
                          />
                        ))}
                    </FormGroup>
                  </ListItemButton>
                </List>
              </Collapse>
            </List>
          </Box>
          <Box
            sx={{
              overflow: "hidden",
            }}
          >
            <IeltsTeacherDashBoardTable
              columnVisibleHandler={toggleNameColumn}
              excelDownloadClick={excelDownloadClick}
              pdfDownloadClick={pdfDownloadClick}
              showNameColumn={showName}
              showDobColumn={showDobColumn}
              showGenderColumn={showgenderColumn}
              showEmailColumn={showEmailColumn}
              showPhoneColumn={showPhoneColumn}
              showWhatsAppColumn={showWhatsAppNoColumn}
              showCourseColumn={showCourseColumn}
              showCenterColumn={showCenterColumn}
              showPaymentColumn={showPaymentColumn}
              showStatusColumn={showStatusColumn}
              data={assignedStudents}
              columns={columns}
              component="ieltsCategory"
              selectBoxIdPassHandler={selectBoxIdPassHandler}
            />
          </Box>
        </Box>
      </Box>
    </MainContainer>
  );
}

export default IeltsTeacherDashBoardMain;
