import React, { useEffect, useState } from "react";
import "./Login.css";

import CssBaseline from "@mui/material/CssBaseline";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Container } from "@mui/material";
import LoginField from "./LoginField";
import ForgotPass from "./forgotPassword/ForgotPass";
import ConfirmOtp from "./ConfirmOtp";
import ConfirmPassword from "./ConfirmPassword";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function SignInSide() {
  const [islogin, setIsLogin] = useState("login");

  const navigate = useNavigate();

  // selectors
  const { newPasswordLoading, newPasswordSuccess, newPasserror } = useSelector(
    (state) => {
      return state.newPasswordSet;
    }
  );

  const {
    forgotPassSentOTPSuccess,
    forgotPassSentOTPLoading,
    forgotPassSentOTPerror,
  } = useSelector((state) => {
    return state.forgotPassSentOTP;
  });

  const { confirmOTPSuccess, confirmOTPLoading, confirmOTPerror } = useSelector(
    (state) => {
      return state.confirmOTP;
    }
  );

  const { loginSuccess, loginSubmitLoading, loginSubmiterror } = useSelector(
    (state) => {
      return state.loginSubmit;
    }
  );

  // Hooks
  useEffect(() => {
    if (forgotPassSentOTPSuccess === "otp sent") {
      setIsLogin("otpConfirm");
    }

    if (confirmOTPSuccess === "Verfied") {
      setIsLogin("newPassword");
    }

    if (newPasswordSuccess === "updated") {
      setIsLogin("login");
    }
  }, [forgotPassSentOTPSuccess, confirmOTPSuccess, newPasswordSuccess]);

  let isUserExist = localStorage.getItem("loginInfo")
    ? JSON.parse(localStorage.getItem("loginInfo"))
    : null;
  useEffect(() => {
    console.log(isUserExist, "isUserExistisUserExist");
    if (isUserExist) {
      navigate("/");
    }
  }, [isUserExist, navigate]);
  //for forgot password page
  const forgotPageHandler = () => {
    setIsLogin("forgot");
  };

  //for login page
  const loginHandler = () => {
    setIsLogin("login");
  };
  return (
    <div>
      <div className="menu">
        <img
          className="loginBodyTop"
          src="/loginImage/loginTop.png"
          alt="gsl logo"
        />

        <img
          className="loginBodyBottom"
          src="/loginImage/loginBottom.png"
          alt="gsl logo"
        />
      </div>

      <Container
        className="container"
        component="main"
        // maxWidth="lg"
        sx={{
          width: "auto",
          // Add custom styles to create space between 'lg' and 'md' breakpoints
          [`@media (max-width: 1279px)`]: {
            paddingLeft: "20px",
            paddingRight: "20px",
          },
        }}
      >
        <Box
          component={Paper}
          elevation={1}
          sx={{

            borderRadius:"12px",
            marginTop: 15,
            width:"70vw",
          }}
        >
          <Grid container>
            <CssBaseline />

            {islogin === "login" && (
              <LoginField forgotPageHandler={forgotPageHandler} />
            )}

            {islogin === "forgot" && <ForgotPass loginHandler={loginHandler} />}

            {islogin === "otpConfirm" && <ConfirmOtp />}

            {islogin === "newPassword" && <ConfirmPassword />}

            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              sx={{
                backgroundPosition: "center",
                backgroundColor: "#fff",
                borderRadius:"12px",
                mt:3
              }}
            >
              <div
                style={{
                  backgroundColor: "#f8f8f8",
                  margin: "25px",
                  width: "auto",
                  textAlign: "center",
                  height: "auto",
                  borderRadius:"12px"
                }}
              >
                <div>
                  <img
                    style={{ margin: "25px" }}
                    src="/loginImage/gslLogo.png"
                    alt="gsl logo"
                  />

                  <Typography
                    sx={{
                      fontStyle: "normal",
                      fontWeight: 550,
                      fontSize: "24px",
                      mt: "15px",
                      fontFamily: "'Poppins', sans-serif",
                      color:"#000"
                    }}
                  >
                    Global Study Link
                  </Typography>

                  <Box
                    sx={{
                      mt: "10px",
                      width: "100%",
                      padding: "10px",
                      color: "#ACB1C6",
                    }}
                  >
                    <Typography
                      variant="subtitle1"
                      sx={{
                        fontWeight: 400,
                        fontSize: "14px",
                        fontFamily: "'Open Sans', sans-serif",
                      }}
                    >
                      Forem ipsum dolor sit amet, consectetur adipiscing elit.
                      Nunc vulputate libero et velit interdum, ac aliquet odio
                      mattis. Class aptent taciti sociosqu ad litora torquent
                      per conubia nostra, per inceptos
                    </Typography>
                  </Box>
                </div>
                <Box>
                  <Typography
                    sx={{
                      mt: "auto",
                      paddingTop: "80px",
                      fontWeight: 400,
                      fontSize: "15px",
                      fontFamily: "'Poppins', sans-serif",
                      color: "rgba(119, 122, 174, 1)",
                    }}
                    variant="subtitle1"
                  >
                    By signing in, you agree to our <br />
                    <a
                      href="/login"
                      style={{
                        color: "#FF1B6F",
                        fontWeight: 400,
                        fontSize: "15px",
                      }}
                    >
                      Terms & Conditions
                    </a>{" "}
                    and &nbsp;
                    <a
                      href="/login"
                      style={{
                        color: "#FF1B6F",
                        fontWeight: 400,
                        fontSize: "15px",
                      }}
                    >
                      Privacy Policy
                    </a>
                  </Typography>
                </Box>
              </div>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </div>
  );
}
