
//  note find reducer

import { NOTES_FIND_ERR, NOTES_FIND_REQUEST, NOTES_FIND_SUCCESS } from "../constants/dashBoardConstant";

export const noteFindReducer = (state = {}, action) => {
    switch (action.type) {
      case NOTES_FIND_REQUEST:
        return {
          ...state,
          noteFindLoading: true,
        };
      case NOTES_FIND_SUCCESS:
        return {
          ...state,
          noteFindLoading: false,
          noteFindSuccess: action.payload,
        };
      case NOTES_FIND_ERR:
        return {
          ...state,
          noteFindLoading: false,
          noteFindErr: action.payload,
        };
      default:
        return state;
    }
  };
  