 export const ENQUIRY_STUDENT_DETAILS_REQUEST =
   "ENQUIRY_STUDENT_DETAILS_REQUEST";
 export const ENQUIRY_STUDENT_DETAILS_SUCCESS =
   "ENQUIRY_STUDENT_DETAILS_SUCCESS";
 export const ENQUIRY_STUDENT_DETAILS_ERR = "ENQUIRY_STUDENT_DETAILS_ERR";


 export const ENQUIRY_ENQUIRYSEARCH_REQUEST =
   "ENQUIRY_ENQUIRYSEARCH_REQUEST";
 export const ENQUIRY_ENQUIRYSEARCH_SUCCESS =
   "ENQUIRY_ENQUIRYSEARCH_SUCCESS";
 export const ENQUIRY_ENQUIRYSEARCH_ERR = "ENQUIRY_ENQUIRYSEARCH_ERR";


 export const ENQUIRY_STUDENT_APPLICATION_FIND_REQUEST =
   "ENQUIRY_STUDENT_APPLICATION_FIND_REQUEST";
 export const ENQUIRY_STUDENT_APPLICATION_FIND_SUCCESS =
   "ENQUIRY_STUDENT_APPLICATION_FIND_SUCCESS";
 export const ENQUIRY_STUDENT_APPLICATION_FIND_ERR = "ENQUIRY_STUDENT_APPLICATION_FIND_ERR";

