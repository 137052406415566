import React, { useEffect, useRef, useState } from "react";
import { Box, Button, Chip, Typography } from "@mui/material";
import VideoPlayer from "../../../../../customComponent/VideoPlayer";
// import WaveformComponent from "../../../../../customComponent/WaveformComponent";
import Waveform from "../../../../../customComponent/WaveformComponent";

function AudioTabItem({topic,demo, courseDetails}) {
  const AudioUrls = [
    "https://cdn.pixabay.com/audio/2023/09/26/audio_5419786d14.mp3",
    "https://cdn.pixabay.com/audio/2023/10/17/audio_65ea4766f8.mp3",
    "https://cdn.pixabay.com/audio/2023/10/17/audio_fcdd88cc6b.mp3",
    "https://cdn.pixabay.com/audio/2021/11/23/audio_91b5dd8335.mp3",
    "https://cdn.pixabay.com/audio/2023/06/22/audio_780a52097f.mp3",
    "https://cdn.pixabay.com/audio/2023/12/05/audio_684694b510.mp3",
  ];
  const [selectedAudio, setSelectedAudio] = useState(AudioUrls[0]);

  const [isPlaying, setIsPlaying] = React.useState(false);
  const [currentTime, setCurrentTime] = React.useState(0);
  const [duration, setDuration] = React.useState(0);

  const audioRef = useRef();

  // useEffect(() => {
  //   if (audioRef.current) {
  //     //  audioRef.current.src = AudioUrls[selectedAudio];
  //     //  audioRef.current.load();
  //     //  audioRef.current.pause();
  //   }
  // }, [selectedAudio, AudioUrls]);

  const handleTimeUpdate = () => {
    setCurrentTime(audioRef?.current?.currentTime);
  };

  const handleDurationUpdate = () => {
    setDuration(audioRef?.current?.duration);
  };

  return demo?.length > 0 ? (
    <Box
      sx={{
        mt: "24px",
        display: "grid",
        gridTemplateColumns: "1fr 1fr 1fr ",
        gap: "20px",
      }}
    >
      {demo?.map((item, index) => (
        <Box
          key={index}
          sx={{
            cursor: "pointer",
            padding: "16px",
            borderRadius: "8px",
            display: "grid",
            gridTemplateColumns: "1fr",
            gap: "10px",
            alignItems: "center",
            boxShadow: "0px 10px 30px 0px rgba(0, 0, 0, 0.08)",
            border: "1px solid #F5F5F5",
          }}
          onClick={() => setSelectedAudio(item.DemoAudio)}
        >
          <Box>
            <Typography
              sx={{
                fontSize: "16px",
                color: "rgba(105, 110, 114, 1)",
              }}
            >
              Lesson 1 {item.type} Demo Class
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: "10px",
                mt: "10px",
              }}
            >
              <audio
                src={item.DemoAudio}
                onTimeUpdate={handleTimeUpdate}
                onLoadedMetadata={() => handleDurationUpdate(index)}
                ref={audioRef}
                controls
                autoPlay={false}
              />
              {/* <Waveform audioSrc={item.DemoAudio} /> */}
              {/* <Typography
                sx={{
                  fontSize: "16px",
                  color: "rgba(255, 27, 111, 1)",
                }}
              >
                Duration : 1hr 30 m
              </Typography> */}
            </Box>
          </Box>
        </Box>
      ))}

      {topic.map((item, index) => (
        <Box
          key={index}
          sx={{
            cursor: "pointer",
            padding: "16px",
            borderRadius: "8px",
            display: "grid",
            gridTemplateColumns: "1fr",
            gap: "10px",
            alignItems: "center",
            boxShadow: "0px 10px 30px 0px rgba(0, 0, 0, 0.08)",
            border: "1px solid #F5F5F5",
          }}
          onClick={() => setSelectedAudio(item?.ielts_added_topic?.AudioUrl)}
        >
          <Box>
            <Typography
              sx={{
                fontSize: "16px",
                color: "rgba(105, 110, 114, 1)",
              }}
            >
              Lesson {index + 2} {item?.ielts_added_topic?.TopicName}
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: "10px",
                mt: "10px",
              }}
            >
              <audio
                src={item?.ielts_added_topic?.AudioUrl}
                onTimeUpdate={handleTimeUpdate}
                onLoadedMetadata={() => handleDurationUpdate(index)}
                ref={audioRef}
                controls
                autoPlay={false}
              />
              {/* <Typography
                sx={{
                  fontSize: "16px",
                  color: "rgba(255, 27, 111, 1)",
                }}
              >
                Duration : 1hr 30 m
              </Typography> */}
            </Box>
          </Box>
        </Box>
      ))}
    </Box>
  ) : (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: "500px",
      }}
    >
      <Box
        sx={{
          "& img": {
            width: "200px",
          },
        }}
      >
        <img src="/course-no-item-to-display.png" alt="no item to list image" />
        <Typography
          sx={{
            textAlign: "center",
            fontWeight: "700",
          }}
        >
          No items to display
        </Typography>
      </Box>
    </Box>
  );
}

export default AudioTabItem;
