import React, { forwardRef, useEffect, useState } from "react";

// import { Menu, MenuItem } from "@mui/base";
import { Box } from "@mui/system";
import { ViewColumn } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import Swal from "sweetalert2";
import AddState from "./AddState";
import {
  findStateMasterAction,
  stateMasterDeleteAction,
} from "../../../../actions/StateMasterAction";
import EditState from "./EditState";
import { Skeleton, Typography } from "@mui/material";
import { MasterTable } from "../MasterTable";
import { SUCCESS_TRUE_MSG_ERR } from "../../../../constants/roleManagmentConstant";
import { successTrueMsgAction } from "../../../../actions/globalAction";
import TableNoItemComponent from "../../../customComponent/TableNoItemComponent";

function StateMaster({ addFormState, stateFormStatusHandler,searchHandle }) {
  const [open, setOpen] = useState(addFormState);

  const dispatch = useDispatch();

  const [stateData, setStateData] = useState([]);
  const [editForm, setEditForm] = useState(false);

  // selectore
  const { stateFindLoading, stateFindSuccess, stateFindErr } = useSelector(
    (state) => {
      return state.findStateMaster;
    }
  );

  const { stateAddSuccess, stateAddErr } = useSelector((state) => {
    return state.StateMasterAdd;
  });

  const { stateDeleteSuccess } = useSelector((state) => {
    return state.stateMasterDelete;
  });

  const { stateEditSuccess } = useSelector((state) => {
    return state.stateMasterEdit;
  });

  const { successAlertMsgSuccess, successAlertMsgErr } = useSelector(
    (state) => {
      return state.successTrueMsg;
    }
  );

  useEffect(() => {
    if (searchHandle !== "" && searchHandle !== " ") {
      console.log(searchHandle, "searchHandlesearchHandle");
      setStateData(
        stateFindSuccess?.filter((item) => {
          const CountryName = item.countryName
            ? item.countryName.split("(")[0].toLowerCase()
            : "";
          const CountryState = item.stateName
            ? item.stateName.toLowerCase()
            : "";

          return (
            CountryName.includes(searchHandle.toLowerCase()) ||
            CountryState.includes(searchHandle.toLowerCase())
          );
        })
      );
    } else {
      if (stateFindSuccess) {
        let reversedData = stateFindSuccess?.reverse();
        setStateData(reversedData);
      }
    }
  }, [searchHandle]);

  useEffect(() => {
    if (successAlertMsgSuccess && stateAddSuccess) {
      Swal.fire("Good job!", `created a new state`, "success");
      dispatch(successTrueMsgAction(false));

      dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: false });
    }
  }, [successAlertMsgSuccess, stateAddSuccess]);

  useEffect(() => {
    if (successAlertMsgSuccess && stateEditSuccess) {
      Swal.fire("Good job!", `Edited successfully`, "success");
      dispatch(successTrueMsgAction(false));

      dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: false });
    }
  }, [successAlertMsgSuccess, stateEditSuccess]);

  useEffect(() => {
    if (successAlertMsgErr) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: stateAddErr,
        // footer: `<p>${stateAddErr}</p>`,
      });

      dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: false });
    }
  }, [successAlertMsgErr, stateAddErr, dispatch]);

  useEffect(() => {
    if (stateFindSuccess && !successAlertMsgErr) {
      let reversedData = stateFindSuccess.reverse();
      setStateData(reversedData);
    }
  }, [stateFindSuccess, stateAddSuccess, stateEditSuccess]);

  useEffect(() => {
    if (addFormState) {
      setOpen(addFormState);
    }
  }, [addFormState]);

  useEffect(() => {
    dispatch(findStateMasterAction());
  }, [dispatch, stateAddSuccess, stateDeleteSuccess, stateEditSuccess]);

  const addMasterCategryHandler = (category, status) => {
    stateFormStatusHandler(status);
    setOpen(status);
  };

  // edit form
  const editMasterCategryHandler = (category, status) => {
    setEditForm(status);
  };

  // material table action
  const [tableId, setTableId] = useState("");

  // action button
  const [anchorEl, setAnchorEl] = React.useState(null);

  // navigate to Edit page
  const rollEditHandler = (id) => {
    setTableId(id);
    setEditForm(true);
  };

  // delete action
  const RoledeleteHandler = (id) => {
    setAnchorEl(false);
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(stateMasterDeleteAction(tableId));
        Swal.fire("Deleted!", "Your file has been deleted.", "success");
      }
    });
  };

  const columns = [
    {
      title: "Sl no",
      field: "sl",
      // type: "numeric",
      // hidden: true,
      headerStyle: { backgroundColor: "#F9F9FB" },
    },
    {
      title: "ISO CODE",
      field: "code",
      //   hidden: showEmployeeNameColumn,
      headerStyle: { backgroundColor: "#F9F9FB" },
    },
    {
      title: "Country",
      field: "country",
      //   hidden: showEmailColumn,
      headerStyle: { backgroundColor: "#F9F9FB" },
    },
    {
      title: "State",
      field: "state",
      //   hidden: showMobileColumn,
      headerStyle: { backgroundColor: "#F9F9FB" },
    },
    {
      title: "Updated By",
      field: "updatedby",
      //   hidden: showRollColumn,
      headerStyle: { backgroundColor: "#F9F9FB" },
    },

    // {
    //   title: "Action",
    //   field: "action",
    //   filtering: false,
    //   headerStyle: { backgroundColor: "#F9F9FB" },
    // },
  ];

  const data = stateData?.map((data, index) => ({
    id: data.id,
    sl: index + 1,
    column1: data.isoCode,
    column2: data.countryName,
    column3: data.stateName,
    column4: data.updatedBy,
  }));

  return (
    <>
      {/* edit component */}
      {editForm && (
        <EditState
          status={editForm}
          editMasterCategryHandler={editMasterCategryHandler}
          id={tableId}
        />
      )}
      {/* popup */}

      {open && (
        <AddState
          status={open}
          addMasterCategryHandler={addMasterCategryHandler}
        />
      )}

      <Box sx={{ mt: 1 }}>
        {stateFindLoading ? (
          <>
            <Typography variant="h1">
              <Skeleton animation={false} />
            </Typography>

            <Typography variant="h3">
              <Skeleton animation="wave" />
            </Typography>

            <Skeleton animation={false} />
            <Typography variant="h3">
              <Skeleton animation="wave" />
            </Typography>

            <Skeleton animation={false} />
            <Typography variant="h3">
              <Skeleton animation="wave" />
            </Typography>

            <Skeleton animation={false} />
            <Typography variant="h3">
              <Skeleton animation="wave" />
            </Typography>

            <Skeleton animation={false} />
          </>
        ) : (
          <>
            {data.length > 0 ? (
              <MasterTable
                data={data}
                columns={columns}
                component="stateMaster"
                rollEditHandler={rollEditHandler}
              />
            ) : (
              <TableNoItemComponent />
            )}
          </>
        )}
        {/*TABLE ACTION MENU START*/}

        {/* <Menu
          id="long-menu"
          anchorEl={anchorEl}
          open={openAction}
          onClose={handleClose}
          PaperProps={{
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,
              width: "20ch",
              borderColor: "black",
              // borderRadius: "24px",
              marginLeft: "81px",
              backgroundColor: "white",
            },
          }}
        >
          <Box style={{ display: "flex", justifyContent: "flex-end" }}>
            <MenuItem>
              <TbEdit
                onClick={() => rollEditHandler(tableId)}
                style={{ fontSize: "lg", width: "50px", height: "23px" }}
              />
            </MenuItem>

            <MenuItem>
              <RiDeleteBin6Line
                onClick={() => RoledeleteHandler(tableId)}
                style={{ fontSize: "lg", width: "50px", height: "23px" }}
              />
            </MenuItem>
          </Box>
        </Menu> */}
        {/*TABLE ACTION MENU END*/}
      </Box>
    </>
  );
}
export default StateMaster;
