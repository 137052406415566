import React, { useEffect, useState } from "react";
import LeadsTable from "../../Table/LeadsTable";

import { useDispatch, useSelector } from "react-redux";

import { Alert, AlertTitle, Box, Grid, InputAdornment, TextField } from "@mui/material";
import usePrivilegeCheck from "../../../constants/customHooks/UsePrivilegeCheck";
import { leadOwnerChangeRequestFindAction } from "../../../actions/leadActions/leadActions";
import LeadApprovalTable from "./leadApprovalTable";
import { makeStyles } from "@material-ui/core";
import { RiSearch2Line } from "react-icons/ri";

const useStyles = makeStyles((theme) => ({
  searchContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(2),
    padding: theme.spacing(1),
    position: "relative"
  },
  searchInput: {
    marginLeft: theme.spacing(1)
  }
}));

function LeadOwnerApprove(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [leadData, setLeadData] = useState([]);
  const [data, setData] = useState([]);
  const [paginationPageNumber, setPaginationPageNumber] = useState(1);
  const [pagiantionEntry, setPagiantionEntry] = useState(5);
  const [searchText, setSearchText] = useState("");


  let {
    leadOwnerChangeApprovalFindSuccess,
    leadOwnerChangeApprovalFindError,
  } = useSelector((state) => {
    return state.leadOwnerChangeApprovalFind;
  });

  let {
    leadOwnerChangeApprovalApproveSuccess,
    leadOwnerChangeApprovalApproveError,
  } = useSelector((state) => {
    return state.leadOwnerChangeApprove;
  });

  let {
    leadOwnerChnageApprovalRejectSuccess,
    leadOwnerChnageApprovalRejectError,
  } = useSelector((state) => {
    return state.leadOwnerChangeRequestReject;
  });

  //   let { loginFindSuccess } = useSelector((state) => {
  //     return state.findUser;
  //   });

  useEffect(() => {
    dispatch(leadOwnerChangeRequestFindAction());
  }, [
    dispatch,
    leadOwnerChangeApprovalApproveSuccess,
    leadOwnerChnageApprovalRejectSuccess,
  ]);

  console.log(leadOwnerChangeApprovalFindSuccess,"leadOwnerChangeApprovalFindSuccessleadOwnerChangeApprovalFindSuccess")

  // material Table
  const columns = [
    {
      title: "Sl no",
      field: "sl",
    },
    {
      title: "Name",
      field: "name",
      // hidden: showNameColumn,
    },
    {
      title: "Email",
      field: "name",
      // hidden: showNameColumn,
    },
    {
      title: "Phone",
      field: "phone",
      // hidden: showPhoneColumn,
    },
    {
      title: "Current Owner",
      field: "leadOwner",
      // hidden: showUniversityPlaceColumn,
    },
    {
      title: "Requested Owner",
      field: "counsilor",
      // hidden: showUniversityPlaceColumn,
    },
    {
      title: "Current Branch",
      field: "leadOwner",
      // hidden: showUniversityPlaceColumn,
    },
    {
      title: "Requested Branch",
      field: "counsilor",
      // hidden: showUniversityPlaceColumn,
    },
    {
      title: "Reject Reason",
      field: "rejectReason",
      // hidden: showUniversityPlaceColumn,
    },
    {
      title: "UpdatedBy",
      field: "UpdatedBy",
      // hidden: showUniversityPlaceColumn,
    },
    {
      title: "Request",
      field: "action",
      filtering: false,
    },
  ];

  // custom hook for privilage check
  const { privilege, hasPrivilege } = usePrivilegeCheck();

  useEffect(() => {
    if (leadOwnerChangeApprovalFindSuccess) {
      setLeadData(leadOwnerChangeApprovalFindSuccess?.data);
    }
  }, [leadOwnerChangeApprovalFindSuccess]);

  useEffect(() => {
    setData(
      leadData?.map((lead, index) => ({
        id: lead.id,
        sl: index + 1,
        leadId: lead.leadId,
        name: lead.leadName,
        email: lead.leadEmail,
        phone: lead.leadPhoneNumber,
        currentOwner: lead.leadOwnerName,
        requestedOwner: lead.UpdatedleadOwnerName,
        currentBranch: lead.previousBranchName,
        requestedBranch: lead.currentBranchName,
        rejectReason:lead?.rejectReason,
        updatedBy: lead.updatedBy,
        status: lead.status,
      }))
    );
  }, [leadData]);

  console.log(
    leadOwnerChangeApprovalFindSuccess,
    "leadOwnerChangeApprovalFindSuccessleadOwnerChangeApprovalFindSuccess"
  );

  const searchHandleChange = (e) => {
    let value = e.target.value;
    if (value) {
      let result = leadOwnerChangeApprovalFindSuccess?.data?.filter(
        (item) =>
          item.leadName?.toLowerCase()?.includes(value?.toLowerCase()) ||
          item.leadEmail?.toLowerCase()?.includes(value?.toLowerCase())||
          item.leadPhoneNumber?.toLowerCase()?.includes(value?.toLowerCase())
      );
      setLeadData(result);
     
    } else {
      setLeadData(leadOwnerChangeApprovalFindSuccess?.data);    }
  };


  return (
    <>
     <Box
        sx={{
          mt: "30px",
          display: "flex",
          // alignItems: "center",
          justifyContent: "end"
        }}
      >
        <div className={classes.searchContainer}>
          <TextField
            className={classes.searchInput}
            variant="outlined"
            placeholder="Search Here"
            value={searchText}
            onChange={(e) => {
              setSearchText(e.target.value);
              searchHandleChange(e);
            }}
            sx={{
              "& .MuiInputBase-input": {
                borderRadius: "20px",
                height: "1px",
                paddingRight: "48px !important"
              },
              "& .MuiFormLabel-root": {
                lineHeight: "5px"
              },
              "& .MuiInputBase-root": {
                borderRadius: "3px",
                borderColor: "#fafafa"
              },
              "& .MuiOutlinedInput-notchedOutline ": {
                borderRadius: "3px",
                borderColor: "#ECECEC !important",
                borderWidth: "1px !important"
              }
            }}
          />
          <>
            <InputAdornment
              position="end"
              sx={{
                position: "absolute",
                right: "8px",
                width: "40px",
                // top:"0",
                // bottom:"0",
                border: "1px solid #ECECEC",
                borderRadius: "3px",
                height: "34px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer"
              }}
            >
              <RiSearch2Line />
            </InputAdornment>
          </>
        </div>
      </Box>
    
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <LeadApprovalTable
          data={data}
          columns={columns}
          component="leadOwnerChangeApproval"
          selectBoxIdPassHandler={""}
          pageNumber={"page"}
          subMenu={props.submenu}
        />
      </Grid>
    </Grid>
    </>
  );
}

export default LeadOwnerApprove;
