import React, { useState } from "react";

import { MainContainer } from "../content/content.element";
import HeadBreadcrumbs from "../breadcrumbs/breadcrumbs";
import BasicTabs from "../Tabs/TabsMain";

function UniversityMain(props) {
  const [tabValue, setTabValue] = useState(0);

  return (
    <>
      <MainContainer active={props.toggle}>
        {tabValue === 0 && (
          <HeadBreadcrumbs
            head="home"
            sechead="University"
            first="University"
            main="University Managment"
          />
        )}

        {tabValue === 1 && (
          <HeadBreadcrumbs
            head="home"
            sechead="University"
            first="University"
            main="University Master"
          />
        )}
        {tabValue === 2 && (
          <HeadBreadcrumbs
            head="home"
            sechead="University"
            first="University"
            main="Course Master"
          />
        )}
        <BasicTabs
          tab1={props.tab1}
          tab2={props.tab2}
          tab3={props.tab3}
          callback={(data) => setTabValue(data)}
          tab1Name="Universities"
          tab2Name="University Master"
          tab3Name="Course Master"
          head="Home"
          sechead="role"
        />
      </MainContainer>
    </>
  );
}

export default UniversityMain;
