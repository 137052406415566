import {
  TOPNAVIGATION_FIND_ERR,
  TOPNAVIGATION_CREATE_REQUEST,
  TOPNAVIGATION_CREATE_SUCCESS,
  TOPNAVIGATION_FIND_REQUEST,
  TOPNAVIGATION_FIND_SUCCESS,
  TOPNAVIGATION_CREATE_ERR,
  // TOPCOURSE_FIND_REQUEST,
  // TOPCOURSE_FIND_SUCCESS,
  // TOPCOURSE_FIND_ERR,
  // ADMISSIONDOCUMENTS_FIND_REQUEST,
  // ADMISSIONDOCUMENTS_FIND_SUCCESS,
  // ADMISSIONDOCUMENTS_FIND_ERR,
  DESTINATION_FIND_REQUEST,
  DESTINATION_FIND_SUCCESS,
  DESTINATION_FIND_ERR,
  DESTINATION_CREATE_REQUEST,
  DESTINATION_CREATE_SUCCESS,
  DESTINATION_CREATE_ERR,
  TOPNAVIGATION_DELETE_REQUEST,
  TOPNAVIGATION_DELETE_SUCCESS,
  TOPNAVIGATION_DELETE_ERR,
  TOPNAVIGATION_FINDEDIT_REQUEST,
  TOPNAVIGATION_FINDEDIT_SUCCESS,
  TOPNAVIGATION_FINDEDIT_ERR,
  TOPNAVIGATION_UPDATE_REQUEST,
  TOPNAVIGATION_UPDATE_SUCCESS,
  TOPNAVIGATION_UPDATE_ERR,
  SERVICE_CREATE_REQUEST,
  SERVICE_CREATE_SUCCESS,
  SERVICE_CREATE_ERR,
  SERVICE_FIND_REQUEST,
  SERVICE_FIND_SUCCESS,
  SERVICE_FIND_ERR,
  NEWS_CREATE_REQUEST,
  NEWS_CREATE_SUCCESS,
  NEWS_CREATE_ERR,
  NEWS_FIND_REQUEST,
  NEWS_FIND_SUCCESS,
  NEWS_FIND_ERR,
  BLOGS_CREATE_REQUEST,
  BLOGS_CREATE_SUCCESS,
  BLOGS_CREATE_ERR,
  BLOGS_FIND_REQUEST,
  BLOGS_FIND_SUCCESS,
  BLOGS_FIND_ERR,
  FEATUREDADS_CREATE_REQUEST,
  FEATUREDADS_CREATE_SUCCESS,
  FEATUREDADS_CREATE_ERR,
  TESTIMONIAL_CREATE_REQUEST,
  TESTIMONIAL_CREATE_SUCCESS,
  TESTIMONIAL_CREATE_ERR,
  TESTIMONIAL_FIND_REQUEST,
  TESTIMONIAL_FIND_SUCCESS,
  TESTIMONIAL_FIND_ERR,
  TESTIMONIAL_DELETE_REQUEST,
  TESTIMONIAL_DELETE_SUCCESS,
  TESTIMONIAL_DELETE_ERR,
  FEATUREDADS_FIND_REQUEST,
  FEATUREDADS_FIND_SUCCESS,
  FEATUREDADS_FIND_ERR,
  FEATUREDADS_DELETE_REQUEST,
  FEATUREDADS_DELETE_SUCCESS,
  FEATUREDADS_DELETE_ERR,
  BLOGS_DELETE_REQUEST,
  BLOGS_DELETE_SUCCESS,
  BLOGS_DELETE_ERR,
  BLOGS_EDIT_GET_REQUEST,
  BLOGS_EDIT_GET_SUCCESS,
  BLOGS_EDIT_GET_ERR,
  BLOGS_EDIT_REQUEST,
  BLOGS_EDIT_SUCCESS,
  BLOGS_EDIT_ERR,
  EVENTS_CREATE_REQUEST,
  EVENTS_CREATE_SUCCESS,
  EVENTS_CREATE_ERR,
  EVENTS_FIND_REQUEST,
  EVENTS_FIND_SUCCESS,
  EVENTS_FIND_ERR,
  DESTINATION_FIND_BY_ID_REQUEST,
  DESTINATION_FIND_BY_ID_SUCCESS,
  DESTINATION_FIND_BY_ID_ERR,
  TALK_TO_EXPERT_FIND_REQUEST,
  TALK_TO_EXPERT_FIND_SUCCESS,
  TALK_TO_EXPERT_FIND_ERR,
  TALK_TO_EXPERT_CHANGE_STATUS_REQUEST,
  TALK_TO_EXPERT_CHANGE_STATUS_SUCCESS,
  TALK_TO_EXPERT_CHANGE_STATUS_ERR,
  TALK_TO_EXPERT_DELETE_STATUS_REQUEST,
  TALK_TO_EXPERT_DELETE_STATUS_SUCCESS,
  TALK_TO_EXPERT_DELETE_STATUS_ERR,
  SERVICE_FIND_BY_ID_REQUEST,
  SERVICE_FIND_BY_ID_SUCCESS,
  SERVICE_FIND_BY_ID_ERR,
  SERVICE_EDIT_REQUEST,
  SERVICE_EDIT_SUCCESS,
  SERVICE_EDIT_ERR,
  NEWS_FIND_BY_ID_REQUEST,
  NEWS_FIND_BY_ID_SUCCESS,
  NEWS_FIND_BY_ID_ERR,
  EVENTS_FIND_BY_ID_REQUEST,
  EVENTS_FIND_BY_ID_SUCCESS,
  EVENTS_FIND_BY_ID_ERR,
  EVENTS_EDIT_REQUEST,
  EVENTS_EDIT_SUCCESS,
  EVENTS_EDIT_ERR,
  EVENTS_DELETE_REQUEST,
  EVENTS_DELETE_SUCCESS,
  EVENTS_DELETE_ERR,
  TESTIMONIAL_FIND_BY_ID_REQUEST,
  TESTIMONIAL_FIND_BY_ID_SUCCESS,
  TESTIMONIAL_FIND_BY_ID_ERR,
  TESTIMONIAL_EDIT_REQUEST,
  TESTIMONIAL_EDIT_SUCCESS,
  TESTIMONIAL_EDIT_ERR,
  FEATURED_ADS_FIND_BY_ID_REQUEST,
  FEATURED_ADS_FIND_BY_ID_SUCCESS,
  FEATURED_ADS_FIND_BY_ID_ERR,
  SCHOLARSHIP_APPLICATION_CREATE_REQUEST,
  SCHOLARSHIP_APPLICATION_CREATE_SUCCESS,
  SCHOLARSHIP_APPLICATION_CREATE_ERR,
  SCHOLARSHIP_APPLICATION_CONVERT_TO_LEAD_REQUEST,
  SCHOLARSHIP_APPLICATION_CONVERT_TO_LEAD_SUCCESS,
  SCHOLARSHIP_APPLICATION_CONVERT_TO_LEAD_ERR,
  TALKTOEXPERT_CONVERT_TO_LEAD_REQUEST,
  TALKTOEXPERT_CONVERT_TO_LEAD_SUCCESS,
  TALKTOEXPERT_CONVERT_TO_LEAD_ERR,
  CREATE_OFFICE_REQUEST,
  CREATE_OFFICE_SUCCESS,
  CREATE_OFFICE_ERR,
  GET_OFFICE_REQUEST,
  GET_OFFICE_SUCCESS,
  GET_OFFICE_ERR,
  GET_OFFICE_BY_ID_REQUEST,
  GET_OFFICE_BY_ID_SUCCESS,
  GET_OFFICE_BY_ID_ERR,
  EDIT_OFFICE_REQUEST,
  EDIT_OFFICE_SUCCESS,
  EDIT_OFFICE_ERR,
  DELETE_OFFICE_BY_ID_REQUEST,
  DELETE_OFFICE_BY_ID_SUCCESS,
  DELETE_OFFICE_BY_ID_ERR,
  NEWS_DELETE_REQUEST,
  NEWS_DELETE_SUCCESS,
  NEWS_DELETE_ERR,
  ADD_NEW_GALLERY_ITEMS_REQUEST,
  ADD_NEW_GALLERY_ITEMS_SUCCESS,
  ADD_NEW_GALLERY_ITEMS_ERR,
  FIND_GALLERY_ITEMS_REQUEST,
  FIND_GALLERY_ITEMS_SUCCESS,
  FIND_GALLERY_ITEMS_ERR,
  FIND_GALLERY_ITEMS_BY_ID_REQUEST,
  FIND_GALLERY_ITEMS_BY_ID_SUCCESS,
  FIND_GALLERY_ITEMS_BY_ID_ERR,
  UPDATE_GALLERY_ITEMS_BY_ID_REQUEST,
  UPDATE_GALLERY_ITEMS_BY_ID_SUCCESS,
  UPDATE_GALLERY_ITEMS_BY_ID_ERR,
  DELETE_GALLERY_ITEMS_BY_ID_REQUEST,
  DELETE_GALLERY_ITEMS_BY_ID_SUCCESS,
  DELETE_GALLERY_ITEMS_BY_ID_ERR,
  DESTINATION_DELETE_REQUEST,
  DESTINATION_DELETE_SUCCESS,
  DESTINATION_DELETE_ERR,
  DESTINATION_EDIT_REQUEST,
  DESTINATION_EDIT_SUCCESS,
  DESTINATION_EDIT_ERR,
} from "../constants/LandingPage/LandingPageConstant";

export const LandingPageCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case TOPNAVIGATION_CREATE_REQUEST:
      return {
        ...state,
        topNavigationCreateLoading: true,
      };
    case TOPNAVIGATION_CREATE_SUCCESS:
      return {
        ...state,
        topNavigationCreateLoading: false,
        topNavigationCreateSuccess: action.payload,
      };
    case TOPNAVIGATION_CREATE_ERR:
      return {
        ...state,
        topNavigationCreateLoading: false,
        topNavigationCreateErr: action.payload,
      };
    default:
      return state;
  }
};

// Find

export const LandingPageFindReducer = (state = {}, action) => {
  switch (action.type) {
    case TOPNAVIGATION_FIND_REQUEST:
      return {
        ...state,
        topNavigationFindLoading: true,
      };
    case TOPNAVIGATION_FIND_SUCCESS:
      return {
        ...state,
        topNavigationFindLoading: false,
        topNavigationFindSuccess: action.payload,
      };
    case TOPNAVIGATION_FIND_ERR:
      return {
        ...state,
        topNavigationFindLoading: false,
        topNavigationFindErr: action.payload,
      };
    default:
      return state;
  }
};

// Delete

export const LandingPageDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case TOPNAVIGATION_DELETE_REQUEST:
      return {
        ...state,
        topNavigationDeleteLoading: true,
      };
    case TOPNAVIGATION_DELETE_SUCCESS:
      return {
        ...state,
        topNavigationDeleteLoading: false,
        topNavigationDeleteSuccess: action.payload,
      };
    case TOPNAVIGATION_DELETE_ERR:
      return {
        ...state,
        topNavigationDeleteLoading: false,
        topNavigationDeleteErr: action.payload,
      };
    default:
      return state;
  }
};

// FindEdit

export const topNavigationEditReducer = (state = {}, action) => {
  switch (action.type) {
    case TOPNAVIGATION_FINDEDIT_REQUEST:
      return {
        ...state,
        topNavigationFindEditLoading: true,
      };
    case TOPNAVIGATION_FINDEDIT_SUCCESS:
      return {
        ...state,
        topNavigationFindEditLoading: false,
        topNavigationFindEditSuccess: action.payload,
      };
    case TOPNAVIGATION_FINDEDIT_ERR:
      return {
        ...state,
        topNavigationFindEditLoading: false,
        topNavigationFindEditErr: action.payload,
      };
    default:
      return state;
  }
};

// Update

export const topNavigationUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case TOPNAVIGATION_UPDATE_REQUEST:
      return {
        ...state,
        topNavigationUpdateLoading: true,
      };
    case TOPNAVIGATION_UPDATE_SUCCESS:
      return {
        ...state,
        topNavigationUpdateLoading: false,
        topNavigationUpdateSuccess: action.payload,
      };
    case TOPNAVIGATION_UPDATE_ERR:
      return {
        ...state,
        topNavigationUpdateLoading: false,
        topNavigationUpdateErr: action.payload,
      };
    default:
      return state;
  }
};

// Destinatin_Tab

// Find

export const DestinationFindReducer = (state = {}, action) => {
  switch (action.type) {
    case DESTINATION_FIND_REQUEST:
      return {
        ...state,
        destinationFindLoading: true,
      };
    case DESTINATION_FIND_SUCCESS:
      return {
        ...state,
        destinationFindLoading: false,
        destinationFindSuccess: action.payload,
      };
    case DESTINATION_FIND_ERR:
      return {
        ...state,
        destinationFindLoading: false,
        destinationFindErr: action.payload,
      };
    default:
      return state;
  }
};

// Create

export const DestinationCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case DESTINATION_CREATE_REQUEST:
      return {
        ...state,
        destinationCreateLoading: true,
      };
    case DESTINATION_CREATE_SUCCESS:
      return {
        ...state,
        destinationCreateLoading: false,
        destinationCreateSuccess: action.payload,
      };
    case DESTINATION_CREATE_ERR:
      return {
        ...state,
        destinationCreateLoading: false,
        destinationCreateErr: action.payload,
      };
    default:
      return state;
  }
};

// destinationFindByIDReducer
export const destinationFindByIDReducer = (state = {}, action) => {
  switch (action.type) {
    case DESTINATION_FIND_BY_ID_REQUEST:
      return {
        ...state,
        destinationFindByIdLoading: true,
      };
    case DESTINATION_FIND_BY_ID_SUCCESS:
      return {
        ...state,
        destinationFindByIdLoading: false,
        destinationFindByIdSuccess: action.payload,
      };
    case DESTINATION_FIND_BY_ID_ERR:
      return {
        ...state,
        destinationFindByIdLoading: false,
        destinationFindByIdErr: action.payload,
      };
    default:
      return state;
  }
};

// destinationEditReducer

export const destinationEditReducer = (state = {}, action) => {
  switch (action.type) {
    case DESTINATION_EDIT_REQUEST:
      return {
        ...state,
        destinationEditLoading: true,
      };
    case DESTINATION_EDIT_SUCCESS:
      return {
        ...state,
        destinationEditLoading: false,
        destinationEditSuccess: action.payload,
      };
    case DESTINATION_EDIT_ERR:
      return {
        ...state,
        destinationEditLoading: false,
        destinationEditErr: action.payload,
      };
    default:
      return state;
  }
};


// Delete

export const DestinationDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case DESTINATION_DELETE_REQUEST:
      return {
        ...state,
        destinationDeleteLoading: true,
      };
    case DESTINATION_DELETE_SUCCESS:
      return {
        ...state,
        destinationDeleteLoading: false,
        destinationDeleteSuccess: action.payload,
      };
    case DESTINATION_DELETE_ERR:
      return {
        ...state,
        destinationDeleteLoading: false,
        destinationDeleteErr: action.payload,
      };
    default:
      return state;
  }
};

// SERVICES REDUCERS
// LandingServiceAddReducer
export const LandingServiceAddReducer = (state = {}, action) => {
  switch (action.type) {
    case SERVICE_CREATE_REQUEST:
      return {
        ...state,
        serviceCreateLoading: true,
      };
    case SERVICE_CREATE_SUCCESS:
      return {
        ...state,
        serviceCreateLoading: false,
        serviceCreateSuccess: action.payload,
      };
    case SERVICE_CREATE_ERR:
      return {
        ...state,
        serviceCreateLoading: false,
        serviceCreateErr: action.payload,
      };
    default:
      return state;
  }
};

// LandingServiceFindReducer
export const LandingServiceFindReducer = (state = {}, action) => {
  switch (action.type) {
    case SERVICE_FIND_REQUEST:
      return {
        ...state,
        serviceFindLoading: true,
      };
    case SERVICE_FIND_SUCCESS:
      return {
        ...state,
        serviceFindLoading: false,
        serviceFindSuccess: action.payload,
      };
    case SERVICE_FIND_ERR:
      return {
        ...state,
        serviceFindLoading: false,
        serviceFindErr: action.payload,
      };
    default:
      return state;
  }
};

// serviceFindByIdReducer
export const serviceFindByIdReducer = (state = {}, action) => {
  switch (action.type) {
    case SERVICE_FIND_BY_ID_REQUEST:
      return {
        ...state,
        serviceFindByIdLoading: true,
      };
    case SERVICE_FIND_BY_ID_SUCCESS:
      return {
        ...state,
        serviceFindByIdLoading: false,
        serviceFindByIdSuccess: action.payload,
      };
    case SERVICE_FIND_BY_ID_ERR:
      return {
        ...state,
        serviceFindByIdLoading: false,
        serviceFindByIdErr: action.payload,
      };
    default:
      return state;
  }
};

// LandingServiceEditReducer
export const LandingServiceEditReducer = (state = {}, action) => {
  switch (action.type) {
    case SERVICE_EDIT_REQUEST:
      return {
        ...state,
        serviceEditLoading: true,
      };
    case SERVICE_EDIT_SUCCESS:
      return {
        ...state,
        serviceEditLoading: false,
        serviceEditSuccess: action.payload,
      };
    case SERVICE_EDIT_ERR:
      return {
        ...state,
        serviceEditLoading: false,
        serviceEditErr: action.payload,
      };
    default:
      return state;
  }
};

// LANDING NEWS PAGE

// newsCreateReducer
export const newsCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case NEWS_CREATE_REQUEST:
      return {
        ...state,
        newsCreateLoading: true,
      };
    case NEWS_CREATE_SUCCESS:
      return {
        ...state,
        newsCreateLoading: false,
        newsCreateSuccess: action.payload,
      };
    case NEWS_CREATE_ERR:
      return {
        ...state,
        newsCreateLoading: false,
        newsCreateErr: action.payload,
      };
    default:
      return state;
  }
};

// findLandingNewsReducer
export const findLandingNewsReducer = (state = {}, action) => {
  switch (action.type) {
    case NEWS_FIND_REQUEST:
      return {
        ...state,
        newsFindingLoading: true,
      };
    case NEWS_FIND_SUCCESS:
      return {
        ...state,
        newsFindingLoading: false,
        newsFindingSuccess: action.payload,
      };
    case NEWS_FIND_ERR:
      return {
        ...state,
        newsFindingLoading: false,
        nnewsFindingErr: action.payload,
      };
    default:
      return state;
  }
};

// newsFindByIdReducer
export const newsFindByIdReducer = (state = {}, action) => {
  switch (action.type) {
    case NEWS_FIND_BY_ID_REQUEST:
      return {
        ...state,
        newsFindingByIdLoading: true,
      };
    case NEWS_FIND_BY_ID_SUCCESS:
      return {
        ...state,
        newsFindingByIdLoading: false,
        newsFindingByIdSuccess: action.payload,
      };
    case NEWS_FIND_BY_ID_ERR:
      return {
        ...state,
        newsFindingByIdLoading: false,
        nnewsFindingByIdErr: action.payload,
      };
    default:
      return state;
  }
};


export const newsDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case NEWS_DELETE_REQUEST:
      return {
        ...state,
        newsDeleteLoading: true,
      };
    case NEWS_DELETE_SUCCESS:
      return {
        ...state,
        newsDeleteLoading: false,
        newsDeleteSuccess: action.payload,
      };
    case NEWS_DELETE_ERR:
      return {
        ...state,
        newsDeleteLoading: false,
        newsDeleteErr: action.payload,
      };
    default:
      return state;
  }
};

// blogCreateReducer
export const blogCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case BLOGS_CREATE_REQUEST:
      return {
        ...state,
        blogCreateLoading: true,
      };
    case BLOGS_CREATE_SUCCESS:
      return {
        ...state,
        blogCreateLoading: false,
        blogCreateSuccess: action.payload,
      };
    case BLOGS_CREATE_ERR:
      return {
        ...state,
        blogCreateLoading: false,
        blogCreateErr: action.payload,
      };
    default:
      return state;
  }
};


// blogGetEditDataReducer
export const blogGetEditDataReducer = (state = {}, action) => {
  switch (action.type) {
    case BLOGS_EDIT_GET_REQUEST:
      return {
        ...state,
        blogEditLoading: true,
      };
    case BLOGS_EDIT_GET_SUCCESS:
      return {
        ...state,
        blogEditLoading: false,
        blogEditSuccess: action.payload,
      };
    case BLOGS_EDIT_GET_ERR:
      return {
        ...state,
        blogEditLoading: false,
        blogEditErr: action.payload,
      };
    default:
      return state;
  }
};

// blogEditDataReducer
export const blogEditDataReducer = (state = {}, action) => {
  switch (action.type) {
    case BLOGS_EDIT_REQUEST:
      return {
        ...state,
        blogEditedLoading: true,
      };
    case BLOGS_EDIT_SUCCESS:
      return {
        ...state,
        blogEditedLoading: false,
        blogEditedSuccess: action.payload,
      };
    case BLOGS_EDIT_ERR:
      return {
        ...state,
        blogEditedLoading: false,
        blogEditedErr: action.payload,
      };
    default:
      return state;
  }
};

// findLandingBlogsReducer
export const findLandingBlogsReducer = (state = {}, action) => {
  switch (action.type) {
    case BLOGS_FIND_REQUEST:
      return {
        ...state,
        blogFindingLoading: true,
      };
    case BLOGS_FIND_SUCCESS:
      return {
        ...state,
        blogFindingLoading: false,
        blogFindingSuccess: action.payload,
      };
    case BLOGS_FIND_ERR:
      return {
        ...state,
        blogFindingLoading: false,
        blogFindingErr: action.payload,
      };
    default:
      return state;
  }
};

// blogDeleteReducer
export const blogDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case BLOGS_DELETE_REQUEST:
      return {
        ...state,
        blogDeleteLoading: true,
      };
    case BLOGS_DELETE_SUCCESS:
      return {
        ...state,
        blogDeleteLoading: false,
        blogDeleteSuccess: action.payload,
      };
    case BLOGS_DELETE_ERR:
      return {
        ...state,
        blogDeleteLoading: false,
        blogDeleteErr: action.payload,
      };
    default:
      return state;
  }
};

// testimonialReducer
export const testimonialReducer = (state = {}, action) => {
  switch (action.type) {
    case TESTIMONIAL_CREATE_REQUEST:
      return {
        ...state,
        testimonialCreateLoading: true,
      };
    case TESTIMONIAL_CREATE_SUCCESS:
      return {
        ...state,
        testimonialCreateLoading: false,
        testimonialCreateSuccess: action.payload,
      };
    case TESTIMONIAL_CREATE_ERR:
      return {
        ...state,
        testimonialCreateLoading: false,
        testimonialCreateErr: action.payload,
      };
    default:
      return state;
  }
};

// findLandingTestimonialReducer
export const findLandingTestimonialReducer = (state = {}, action) => {
  switch (action.type) {
    case TESTIMONIAL_FIND_REQUEST:
      return {
        ...state,
        testimonialFindLoading: true,
      };
    case TESTIMONIAL_FIND_SUCCESS:
      return {
        ...state,
        testimonialFindLoading: false,
        testimonialFindSuccess: action.payload,
      };
    case TESTIMONIAL_FIND_ERR:
      return {
        ...state,
        testimonialFindLoading: false,
        testimonialFindErr: action.payload,
      };
    default:
      return state;
  }
};

// findTestimonialByIdReducer
export const findTestimonialByIdReducer = (state = {}, action) => {
  switch (action.type) {
    case TESTIMONIAL_FIND_BY_ID_REQUEST:
      return {
        ...state,
        testimonialFindByIdLoading: true,
      };
    case TESTIMONIAL_FIND_BY_ID_SUCCESS:
      return {
        ...state,
        testimonialFindByIdLoading: false,
        testimonialFindByIdSuccess: action.payload,
      };
    case TESTIMONIAL_FIND_BY_ID_ERR:
      return {
        ...state,
        testimonialFindByIdLoading: false,
        testimonialFindByIdErr: action.payload,
      };
    default:
      return state;
  }
};

// testimonialsDeleteReducer
export const testimonialsDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case TESTIMONIAL_DELETE_REQUEST:
      return {
        ...state,
        testimonialDeleteLoading: true,
      };
    case TESTIMONIAL_DELETE_SUCCESS:
      return {
        ...state,
        testimonialDeleteLoading: false,
        testimonialDeleteSuccess: action.payload,
      };
    case TESTIMONIAL_DELETE_ERR:
      return {
        ...state,
        testimonialDeleteLoading: false,
        testimonialDeleteErr: action.payload,
      };
    default:
      return state;
  }
};

// testimonialEditReducer
export const testimonialEditReducer = (state = {}, action) => {
  switch (action.type) {
    case TESTIMONIAL_EDIT_REQUEST:
      return {
        ...state,
        testimonialEditedLoading: true,
      };
    case TESTIMONIAL_EDIT_SUCCESS:
      return {
        ...state,
        testimonialEditedLoading: false,
        testimonialEditedSuccess: action.payload,
      };
    case TESTIMONIAL_EDIT_ERR:
      return {
        ...state,
        testimonialEditedLoading: false,
        testimonialEditedErr: action.payload,
      };
    default:
      return state;
  }
};

// FEATUREDADS

// createFeaturedAdsReducer
export const createFeaturedAdsReducer = (state = {}, action) => {
  switch (action.type) {
    case FEATUREDADS_CREATE_REQUEST:
      return {
        ...state,
        featuredAdsCreateLoading: true,
      };
    case FEATUREDADS_CREATE_SUCCESS:
      return {
        ...state,
        featuredAdsCreateLoading: false,
        featuredAdsCreateSuccess: action.payload,
      };
    case FEATUREDADS_CREATE_ERR:
      return {
        ...state,
        featuredAdsCreateLoading: false,
        featuredAdsCreateErr: action.payload,
      };
    default:
      return state;
  }
};

// findLandingFeaturedAdsReducer
export const findLandingFeaturedAdsReducer = (state = {}, action) => {
  switch (action.type) {
    case FEATUREDADS_FIND_REQUEST:
      return {
        ...state,
        featuredAdsFindLoading: true,
      };
    case FEATUREDADS_FIND_SUCCESS:
      return {
        ...state,
        featuredAdsFindLoading: false,
        featuredAdsFindSuccess: action.payload,
      };
    case FEATUREDADS_FIND_ERR:
      return {
        ...state,
        featuredAdsFindLoading: false,
        featuredAdsFindErr: action.payload,
      };
    default:
      return state;
  }
};

// featuredAdsDeleteReducer
export const featuredAdsDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case FEATUREDADS_DELETE_REQUEST:
      return {
        ...state,
        featuredAdsDeleteLoading: true,
      };
    case FEATUREDADS_DELETE_SUCCESS:
      return {
        ...state,
        featuredAdsDeleteLoading: false,
        featuredAdsDeleteSuccess: action.payload,
      };
    case FEATUREDADS_DELETE_ERR:
      return {
        ...state,
        featuredAdsDeleteLoading: false,
        featuredAdsDeleteErr: action.payload,
      };
    default:
      return state;
  }
};

// findFeaturedAdsByIdReducer
export const findFeaturedAdsByIdReducer = (state = {}, action) => {
  switch (action.type) {
    case FEATURED_ADS_FIND_BY_ID_REQUEST:
      return {
        ...state,
        featuredAdsFindByIdLoading: true,
      };
    case FEATURED_ADS_FIND_BY_ID_SUCCESS:
      return {
        ...state,
        featuredAdsFindByIdLoading: false,
        featuredAdsFindByIdSuccess: action.payload,
      };
    case FEATURED_ADS_FIND_BY_ID_ERR:
      return {
        ...state,
        featuredAdsFindByIdLoading: false,
        featuredAdsFindByIdErr: action.payload,
      };
    default:
      return state;
  }
};

// scholarship Application Reducer
export const scholarshipApplicationReducer = (state = {}, action) => {
  switch (action.type) {
    case SCHOLARSHIP_APPLICATION_CREATE_REQUEST:
      return {
        ...state,
        scholarshipApplicationLoading: true,
      };
    case SCHOLARSHIP_APPLICATION_CREATE_SUCCESS:
      return {
        ...state,
        scholarshipApplicationLoading: false,
        scholarshipApplicationSuccess: action.payload,
      };
    case SCHOLARSHIP_APPLICATION_CREATE_ERR:
      return {
        ...state,
        scholarshipApplicationLoading: false,
        scholarshipApplicationErr: action.payload,
      };
    default:
      return state;
  }
};

// event create reducer

export const eventsCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case EVENTS_CREATE_REQUEST:
      return {
        ...state,
        eventsCreateLoading: true,
      };
    case EVENTS_CREATE_SUCCESS:
      return {
        ...state,
        eventsCreateLoading: false,
        eventsCreateSuccess: action.payload,
      };
    case EVENTS_CREATE_ERR:
      return {
        ...state,
        eventsCreateLoading: false,
        eventsCreateErr: action.payload,
      };
    default:
      return state;
  }
};

// findEventByIdReducer
export const findEventByIdReducer = (state = {}, action) => {
  switch (action.type) {
    case EVENTS_FIND_BY_ID_REQUEST:
      return {
        ...state,
        eventsFindByIdLoading: true,
      };
    case EVENTS_FIND_BY_ID_SUCCESS:
      return {
        ...state,
        eventsFindByIdLoading: false,
        eventsFindByIdSuccess: action.payload,
      };
    case EVENTS_FIND_BY_ID_ERR:
      return {
        ...state,
        eventsFindByIdLoading: false,
        eventsFindByIdErr: action.payload,
      };
    default:
      return state;
  }
};

// eventsEditReducer
export const eventsEditReducer = (state = {}, action) => {
  switch (action.type) {
    case EVENTS_EDIT_REQUEST:
      return {
        ...state,
        eventsEditLoading: true,
      };
    case EVENTS_EDIT_SUCCESS:
      return {
        ...state,
        eventsEditLoading: false,
        eventsEditSuccess: action.payload,
      };
    case EVENTS_EDIT_ERR:
      return {
        ...state,
        eventsEditLoading: false,
        eventsEditErr: action.payload,
      };
    default:
      return state;
  }
};

// eventDeleteReducer
export const eventDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case EVENTS_DELETE_REQUEST:
      return {
        ...state,
        eventsDeleteLoading: true,
      };
    case EVENTS_DELETE_SUCCESS:
      return {
        ...state,
        eventsDeleteLoading: false,
        eventsDeleteSuccess: action.payload,
      };
    case EVENTS_DELETE_ERR:
      return {
        ...state,
        eventsDeleteLoading: false,
        eventsDeleteErr: action.payload,
      };
    default:
      return state;
  }
};

// event find reducer

export const eventsFindReducer = (state = {}, action) => {
  switch (action.type) {
    case EVENTS_FIND_REQUEST:
      return {
        ...state,
        eventsFindLoading: true,
      };
    case EVENTS_FIND_SUCCESS:
      return {
        ...state,
        eventsFindLoading: false,
        eventsFindSuccess: action.payload,
      };
    case EVENTS_FIND_ERR:
      return {
        ...state,
        eventsFindLoading: false,
        eventsFindErr: action.payload,
      };
    default:
      return state;
  }
};

// talk to expert reducer

export const talkToExpertReducer = (state = {}, action) => {
  switch (action.type) {
    case TALK_TO_EXPERT_FIND_REQUEST:
      return {
        ...state,
        talkToExpertLoading: true,
      };
    case TALK_TO_EXPERT_FIND_SUCCESS:
      return {
        ...state,
        talkToExpertLoading: false,
        talkToExpertSuccess: action.payload,
      };
    case TALK_TO_EXPERT_FIND_ERR:
      return {
        ...state,
        talkToExpertLoading: false,
        talkToExpertErr: action.payload,
      };
    default:
      return state;
  }
};

export const talkToExpertDeleteStatusReducer = (state = {}, action) => {
  switch (action.type) {
    case TALK_TO_EXPERT_DELETE_STATUS_REQUEST:
      return {
        ...state,
        talkToExpertDeleteStatusLoading: true,
      };
    case TALK_TO_EXPERT_DELETE_STATUS_SUCCESS:
      return {
        ...state,
        talkToExpertDeleteStatusLoading: false,
        talkToExpertDeleteStatusSuccess: action.payload,
      };
    case TALK_TO_EXPERT_DELETE_STATUS_ERR:
      return {
        ...state,
        talkToExpertDeleteStatusLoading: false,
        talkToExpertDeleteStatusErr: action.payload,
      };
    default:
      return state;
  }
};

export const talkToExpertChangeStatusReducer = (state = {}, action) => {
  switch (action.type) {
    case TALK_TO_EXPERT_CHANGE_STATUS_REQUEST:
      return {
        ...state,
        talkToExpertChangeStatusLoading: true,
      };
    case TALK_TO_EXPERT_CHANGE_STATUS_SUCCESS:
      return {
        ...state,
        talkToExpertChangeStatusLoading: false,
        talkToExpertChangeStatusSuccess: action.payload,
      };
    case TALK_TO_EXPERT_CHANGE_STATUS_ERR:
      return {
        ...state,
        talkToExpertChangeStatusLoading: false,
        talkToExpertChangeStatusErr: action.payload,
      };
    default:
      return state;
  }
};

// scholarship convert to lead Reducer

export const scholarshipApplicationConvertToLeadReducer = (
  state = {},
  action
) => {
  switch (action.type) {
    case SCHOLARSHIP_APPLICATION_CONVERT_TO_LEAD_REQUEST:
      return {
        ...state,
        scholarshipApplicationConvertToLeadLoading: true,
      };
    case SCHOLARSHIP_APPLICATION_CONVERT_TO_LEAD_SUCCESS:
      return {
        ...state,
        scholarshipApplicationConvertToLeadLoading: false,
        scholarshipApplicationConvertToLeadSuccess: action.payload,
      };
    case SCHOLARSHIP_APPLICATION_CONVERT_TO_LEAD_ERR:
      return {
        ...state,
        scholarshipApplicationConvertToLeadLoading: false,
        scholarshipApplicationConvertToLeadErr: action.payload,
      };
    default:
      return state;
  }
};


// scholarship convert to lead Reducer

export const talktoExpertConvertToLeadReducer = (
  state = {},
  action
) => {
  switch (action.type) {
    case TALKTOEXPERT_CONVERT_TO_LEAD_REQUEST:
      return {
        ...state,
        talktoExpertConvertToLeadLoading: true,
      };
    case TALKTOEXPERT_CONVERT_TO_LEAD_SUCCESS:
      return {
        ...state,
        talktoExpertConvertToLeadLoading: false,
        talktoExpertConvertToLeadSuccess: action.payload,
      };
    case TALKTOEXPERT_CONVERT_TO_LEAD_ERR:
      return {
        ...state,
        talktoExpertConvertToLeadLoading: false,
        talktoExpertConvertToLeadErr: action.payload,
      };
    default:
      return state;
  }
};

// add new office 
export const addNewOfficeReducer = (
  state = {},
  action
) => {
  switch (action.type) {
    case CREATE_OFFICE_REQUEST:
      return {
        ...state,
        addNewOfficeLoading: true,
      };
    case CREATE_OFFICE_SUCCESS:
      return {
        ...state,
        addNewOfficeLoading: false,
        addNewOfficeSuccess: action.payload,
      };
    case CREATE_OFFICE_ERR:
      return {
        ...state,
        addNewOfficeLoading: false,
        addNewOfficeErr: action.payload,
      };
    default:
      return state;
  }
};

// edit office 
export const editOfficeReducer = (
  state = {},
  action
) => {
  switch (action.type) {
    case EDIT_OFFICE_REQUEST:
      return {
        ...state,
        editOfficeLoading: true,
      };
    case EDIT_OFFICE_SUCCESS:
      return {
        ...state,
        editOfficeLoading: false,
        editOfficeSuccess: action.payload,
      };
    case EDIT_OFFICE_ERR:
      return {
        ...state,
        editOfficeLoading: false,
        editOfficeErr: action.payload,
      };
    default:
      return state;
  }
};

// get office by id 
export const getOfficeByIdOfficeReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_OFFICE_BY_ID_REQUEST:
      return {
        ...state,
        getOfficeByIdLoading: true,
      };
    case GET_OFFICE_BY_ID_SUCCESS:
      return {
        ...state,
        getOfficeByIdLoading: false,
        getOfficeByIdSuccess: action.payload,
      };
    case GET_OFFICE_BY_ID_ERR:
      return {
        ...state,
        getOfficeByIdLoading: false,
        getOfficeByIdErr: action.payload,
      };
    default:
      return state;
  }
};

// get all office 
export const getAllOfficeReducer = (
  state = {},
  action
) => {
  switch (action.type) {
    case GET_OFFICE_REQUEST:
      return {
        ...state,
        getAllOfficeLoading: true,
      };
    case GET_OFFICE_SUCCESS:
      return {
        ...state,
        getAllOfficeLoading: false,
        getAllOfficeSuccess: action.payload,
      };
    case GET_OFFICE_ERR:
      return {
        ...state,
        getAllOfficeLoading: false,
        getAllOfficeErr: action.payload,
      };
    default:
      return state;
  }
};

// delete office by id
export const deleteOfficeByIdReducer = (
  state = {},
  action
) => {
  switch (action.type) {
    case DELETE_OFFICE_BY_ID_REQUEST:
      return {
        ...state,
        deleteOfficeByIdLoading: true,
      };
    case DELETE_OFFICE_BY_ID_SUCCESS:
      return {
        ...state,
        deleteOfficeByIdLoading: false,
        deleteOfficeByIdSuccess: action.payload,
      };
    case DELETE_OFFICE_BY_ID_ERR:
      return {
        ...state,
        deleteOfficeByIdLoading: false,
        deleteOfficeByIdErr: action.payload,
      };
    default:
      return state;
  }
};

// add new gallery items
export const addNewGalleryItemsReducer = (
  state = {},
  action
) => {
  switch (action.type) {
    case ADD_NEW_GALLERY_ITEMS_REQUEST:
      return {
        ...state,
        addnewGalleryItemsLoading: true,
      };
    case ADD_NEW_GALLERY_ITEMS_SUCCESS:
      return {
        ...state,
        addnewGalleryItemsLoading: false,
        addnewGalleryItemsSuccess: action.payload,
      };
    case ADD_NEW_GALLERY_ITEMS_ERR:
      return {
        ...state,
        addnewGalleryItemsLoading: false,
        addnewGalleryItemsErr: action.payload,
      };
    default:
      return state;
  }
};

// find gallery items
export const findNewGalleryItemsReducer = (
  state = {},
  action
) => {
  switch (action.type) {
    case FIND_GALLERY_ITEMS_REQUEST:
      return {
        ...state,
        findNewGalleryItemsLoading: true,
      };
    case FIND_GALLERY_ITEMS_SUCCESS:
      return {
        ...state,
        findNewGalleryItemsLoading: false,
        findNewGalleryItemsSuccess: action.payload,
      };
    case FIND_GALLERY_ITEMS_ERR:
      return {
        ...state,
        findNewGalleryItemsLoading: false,
        findNewGalleryItemsErr: action.payload,
      };
    default:
      return state;
  }
};

// find gallery items by id
export const findGalleryItemsByIdReducer = (
  state = {},
  action
) => {
  switch (action.type) {
    case FIND_GALLERY_ITEMS_BY_ID_REQUEST:
      return {
        ...state,
        findGalleryItemsByIdLoading: true,
      };
    case FIND_GALLERY_ITEMS_BY_ID_SUCCESS:
      return {
        ...state,
        findGalleryItemsByIdLoading: false,
        findGalleryItemsByIdSuccess: action.payload,
      };
    case FIND_GALLERY_ITEMS_BY_ID_ERR:
      return {
        ...state,
        findGalleryItemsByIdLoading: false,
        findGalleryItemsByIdErr: action.payload,
      };
    default:
      return state;
  }
};

// update gallery items by id
export const updateGalleryItemsByIdReducer = (
  state = {},
  action
) => {
  switch (action.type) {
    case UPDATE_GALLERY_ITEMS_BY_ID_REQUEST:
      return {
        ...state,
        updateGalleryItemsByIdLoading: true,
      };
    case UPDATE_GALLERY_ITEMS_BY_ID_SUCCESS:
      return {
        ...state,
        updateGalleryItemsByIdLoading: false,
        updateGalleryItemsByIdSuccess: action.payload,
      };
    case UPDATE_GALLERY_ITEMS_BY_ID_ERR:
      return {
        ...state,
        updateGalleryItemsByIdLoading: false,
        updateGalleryItemsByIdErr: action.payload,
      };
    default:
      return state;
  }
};

// delete gallery items by id
export const deleteGalleryItemsByIdReducer = (
  state = {},
  action
) => {
  switch (action.type) {
    case DELETE_GALLERY_ITEMS_BY_ID_REQUEST:
      return {
        ...state,
        deleteGalleryItemsByIdLoading: true,
      };
    case DELETE_GALLERY_ITEMS_BY_ID_SUCCESS:
      return {
        ...state,
        deleteGalleryItemsByIdLoading: false,
        deleteGalleryItemsByIdSuccess: action.payload,
      };
    case DELETE_GALLERY_ITEMS_BY_ID_ERR:
      return {
        ...state,
        deleteGalleryItemsByIdLoading: false,
        deleteGalleryItemsByIdErr: action.payload,
      };
    default:
      return state;
  }
};
