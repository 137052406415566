import React, { useMemo, useState } from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableFooter,
  TableSortLabel,
  Menu,
  Checkbox,
} from "@mui/material";

import { SlOptionsVertical } from "react-icons/sl";
import { Box, MenuItem } from "@mui/material";
import { TbEdit } from "react-icons/tb";
import { RiDeleteBin6Line } from "react-icons/ri";
// import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { useDispatch } from "react-redux";
import { CountryMasterDeleteAction } from "../../../actions/countryMasterAction";
import { stateMasterDeleteAction } from "../../../actions/StateMasterAction";
import { cityMasterDeleteAction } from "../../../actions/cityManagmentAction";
import { branchMasterDeleteAction } from "../../../actions/branchMasterActions";
import { BiSortAlt2 } from "react-icons/bi";

import "./LeadSource.table.css";
import { format } from "date-fns";
import { bannerDeleteAction } from "../../../actions/bannerAction";
import { useNavigate } from "react-router-dom";
import { deleteLeadSourceAction } from "../../../actions/leadSourceAction";
import PremissionDialog from "../../customComponent/premissionDialog";
import usePrivilegeCheck from "../../../constants/customHooks/UsePrivilegeCheck";
import TableNoItemComponent from "../../customComponent/TableNoItemComponent";

export const LeadSourceTable = ({
  columns,
  data,
  allRoleFindSuccess,
  component,
  showUrl,
  subMenu,
  // rollEditHandler,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [actionButtonShow, setActionButtonShow] = useState(null);
  const [tableId, setTableId] = useState("");

  const [selectedRows, setSelectedRows] = useState([]);

  const { isUpdatePrevilage, isDeletePrevilage } = usePrivilegeCheck();

  const [openAlert, setOpenAlert] = useState(false);

  // HandleClose dialog

  const handleCloseDialog = () => {
    setOpenAlert(false);
  };

  const openActionButton = Boolean(actionButtonShow);

  const handleClickAction = (event, id) => {
    setActionButtonShow(event.currentTarget);
    setTableId(id);
  };

  const handleCloseAction = () => {
    setActionButtonShow(null);
  };

  const rollEditHandler = (id) => {
    if (isUpdatePrevilage(subMenu)) {
      navigate(`/Settings/editLeadSource/${id}`);
    } else {
      setOpenAlert(true);
    }
  };

  // delete action
  const RoledeleteHandler = (id) => {
    if (isDeletePrevilage(subMenu)) {
      setActionButtonShow(false);
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          // setAnchorEl(false);
          dispatch(deleteLeadSourceAction(tableId));
          Swal.fire("Deleted!", "Lead Source has been deleted.", "success");
        }
      });
    } else {
      setOpenAlert(true);
    }
  };

  // sorting table
  const [sortBy, setSortBy] = useState(""); // Current sort column
  const [sortOrder, setSortOrder] = useState("asc"); // Current sort order

  const requestSort = (column) => {
    let newSortOrder = "asc";

    if (column === sortBy && sortOrder === "asc") {
      newSortOrder = "desc";
    }

    setSortBy(column);
    setSortOrder(newSortOrder);
  };

  let sortedData = useMemo(() => {
    if (sortBy && data) {
      const sortedItems = [...data].sort((a, b) => {
        if (sortOrder === "asc") {
          return a[sortBy] < b[sortBy] ? -1 : 1;
        } else {
          return a[sortBy] < b[sortBy] ? 1 : -1;
        }
      });

      return sortedItems;
    }

    return data;
  }, [data, sortBy, sortOrder]);

  // const handleClose = () => setOpen(false);

  const CustomSortIcon = ({ direction }) => {
    return direction === "asc" ? (
      <img
        src="/menuIcons/tableUpSort.png"
        className="customIcon"
        alt="gsl"
        style={{ padding: "15px" }}
      />
    ) : (
      <BiSortAlt2 className="customIcon" />
    );
  };
  // Create a function to handle selecting all rows:
  const handleSelectAllRows = () => {
    if (selectedRows.length === sortedData.length) {
      setSelectedRows([]);
    } else {
      let Ids = sortedData.map((item) => item.id);
      setSelectedRows(Ids);
      // selectBoxIdPassHandler(Ids);
    }
  };

  // Create a function to handle selecting individual rows:
  const handleSelectRow = (rowId) => {
    let updatedSelectedRows;
    if (selectedRows.includes(rowId)) {
      updatedSelectedRows = selectedRows.filter((id) => id !== rowId);
      setSelectedRows(selectedRows.filter((id) => id !== rowId));
    } else {
      updatedSelectedRows = [...selectedRows, rowId];
      setSelectedRows(updatedSelectedRows);
    }
    // setIdsFunction(updatedSelectedRows);
  };

  return (
    <>
      {sortedData.length > 0 ? (
        <Table className="masterTable">
          <TableHead>
            <TableRow className="msterTableCell">
              <TableCell>
                <Checkbox
                  checked={selectedRows.length === sortedData.length}
                  onChange={handleSelectAllRows}
                  style={{ color: "#f81b82" }}
                />
              </TableCell>
              {columns?.map((column) => {
                if (column.hidden) {
                  return null;
                }
                return (
                  <TableCell key={column.field}>
                    <TableSortLabel
                      className="headerText"
                      active={column === sortBy}
                      direction={sortOrder}
                      onClick={() => requestSort(column)}
                      IconComponent={
                        column.field !== "action" && CustomSortIcon
                      }
                    >
                      {column.title}
                    </TableSortLabel>
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedData?.map((item, indx) => (
              <TableRow className="tableRow" key={indx}>
                <TableCell>
                  <Checkbox
                    checked={selectedRows.includes(item.id)}
                    onChange={() => handleSelectRow(item.id)}
                    style={{ color: "#f81b82" }}
                  />
                </TableCell>
                <TableCell>{item.sl}</TableCell>
                {component == "bannerMaster" ? (
                  <TableCell>
                    <img src={item.column1} width={52} height={25}></img>
                  </TableCell>
                ) : (
                  <TableCell>{item.column1}</TableCell>
                )}

                {/* {item.column2 && <TableCell>{item.column2}</TableCell>} */}

                {item.column2 && <TableCell>{item.column2}</TableCell>}

                {item.column3 && <TableCell>{item.column3}</TableCell>}

                {item.column4 && (
                  <TableCell>
                    {item.column4
                      ? format(
                          new Date(item?.column4.split("T")[0]),
                          "dd / MM / yyyy"
                        )
                      : null}
                  </TableCell>
                )}

                <TableCell>
                  <SlOptionsVertical
                    id="demo-positioned-button"
                    aria-controls={
                      openActionButton ? "demo-positioned-menu" : undefined
                    }
                    aria-haspopup="true"
                    aria-expanded={openActionButton ? "true" : undefined}
                    onClick={(e) => {
                      handleClickAction(e, item.id);
                    }}
                    variant="outlined"
                    style={{ marginLeft: "10px", color: "#F80B7A" }}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter />
        </Table>
      ) : (
        <TableNoItemComponent />
      )}
      {/* TABLE ACTION MENU START*/}
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={actionButtonShow}
        open={openActionButton}
        onClose={handleCloseAction}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        sx={{
          borderColor: "black",
          borderRadius: "24px",
          marginLeft: component === "branchMaster" ? "-31px" : "-81px",
        }}
      >
        <Box style={{ display: "flex", justifyContent: "flex-end" }}>
          <MenuItem>
            <TbEdit
              onClick={() => rollEditHandler(tableId)}
              style={{ fontSize: "lg", width: "50px", height: "23px" }}
            />
          </MenuItem>

          <MenuItem>
            <RiDeleteBin6Line
              onClick={() => RoledeleteHandler(tableId)}
              style={{ fontSize: "lg", width: "50px", height: "23px" }}
            />
          </MenuItem>
        </Box>
      </Menu>
      {/* {/*TABLE ACTION MENU END */}
      {openAlert && (
        <PremissionDialog
          openAlert={openAlert}
          handleClose={handleCloseDialog}
        />
      )}
    </>
  );
};
