import React from "react";

import ReactCountryFlag from "react-country-flag";

function CountryFlag({ countryCode, component, width, heigth }) {
  return (
    <ReactCountryFlag
      countryCode={countryCode}
      svg
      cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
      cdnSuffix="svg"
      style={{
        width: component == "stepper" ? width : "1.5em",
        height: component == "stepper" ? heigth : "1.5em",
        borderRadius: "50%",
        border: "0.1px solid #ececec",
        fontSize: "1.2em",
        lineHeight: "1.2em",
        marginTop: "5px",
        marginLeft: component == "stepper" ? "0px" : "70px",
      }}
    />
  );
}

export default CountryFlag;
