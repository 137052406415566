import { Paper, Typography, Slide, Menu, MenuItem } from "@mui/material";
import { Box } from "@mui/system";
import React, { forwardRef, useEffect, useMemo, useState } from "react";
import { TiDelete } from "react-icons/ti";

import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableFooter,
  TableSortLabel,
} from "@mui/material";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import { useDispatch, useSelector } from "react-redux";

import Swal from "sweetalert2";
import { SlOptionsVertical } from "react-icons/sl";
import { RiDeleteBin6Line } from "react-icons/ri";
import {
  countryDocumentAdmissionApplicationAddAction,
  countryDocumentAdmissionApplicationDeleteAction,
  countryDocumentAdmissionApplicationFindAction,
} from "../../actions/knowledgeBankActions/CountryDocsActions";
import { makeStyles } from "@material-ui/core";
import { OutLinedButton, SubmitButton } from "../customComponent/Buttons";
import InputField from "../customComponent/InputField";
import usePrivilegeCheck from "../../constants/customHooks/UsePrivilegeCheck";
import { Close } from "@mui/icons-material";
import AccessDeniedIcon from "../Leads/createLeads/AccessDeniedIcon";
import TableNoItemComponent from "../customComponent/TableNoItemComponent";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles({
  table: {
    borderCollapse: "separate",
    borderSpacing: "1 8px",
    border: "1px solid #ececec",
  },
  tableRow: {
    "& > *": {
      border: "none",
    },
  },
});

export default function CountryDocumentsadmision({ docId, country }) {
  const dispatch = useDispatch();
  const classes = useStyles();

  // Custom hook for Previlage checking

  const {
    privilege,
    hasPrivilege,
    isCreatePrevilage,
    isDeletePrevilage,
  } = usePrivilegeCheck();

  const [open, setOpen] = useState(false);
  const [error, setError] = useState({});
  const [docName, setDocName] = useState("");
  const [admissionData, setAdmissionData] = useState([]);

  const [previlageAlert, setPrevilageAlert] = useState(false);

  // table action
  const [employeeActionButtonShow, setEmployeeActionButtonShow] = useState(
    null
  );
  const [tableId, setTableId] = useState("");
  const employeeOpenActionButton = Boolean(employeeActionButtonShow);

  const handleClickAction = (event, id) => {
    setEmployeeActionButtonShow(event.currentTarget);
    setTableId(id);
  };

  const handleCloseAction = () => {
    setEmployeeActionButtonShow(null);
  };
  const {
    countryDocAdmissionAddLoading,
    countryDocAdmissionAddSuccess,
    countryDocAdmissionAddError,
  } = useSelector((state) => {
    return state.countryDocumentAdmissionApplicationAdd;
  });

  const {
    countryDocAdmissionFindLoading,
    countryDocAdmissionFindSuccess,
    countryDocAdmissionFindError,
  } = useSelector((state) => {
    return state.countryDocumentAdmissionApplicationFind;
  });

  const {
    countryDocAdmissionDeleteLoading,
    countryDocAdmissionDeleteSuccess,
    countryDocAdmissionDeleteError,
  } = useSelector((state) => {
    return state.countryDocumentAdmissionApplicationDelete;
  });

  useEffect(() => {
    dispatch(countryDocumentAdmissionApplicationFindAction(docId));
  }, [
    dispatch,
    countryDocAdmissionAddSuccess,
    countryDocAdmissionDeleteSuccess,
    docId,
  ]);

  useEffect(() => {
    if (countryDocAdmissionFindSuccess) {
      setAdmissionData(countryDocAdmissionFindSuccess);
    }
  }, [countryDocAdmissionFindSuccess]);


  console.log(countryDocAdmissionFindSuccess,"countryDocAdmissionFindSuccesscountryDocAdmissionFindSuccess") 

  //   popup add common sop document
  const handleClickOpen = () => {
    if (isCreatePrevilage("document upload")) {
      setOpen(true);
    } else {
      setPrevilageAlert(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setError({});
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (docName !== "") {
      dispatch(countryDocumentAdmissionApplicationAddAction(docName, docId));
      setDocName("");
    } else {
      setError((prev) => ({
        ...prev,
        ["documentName"]: "Please enter a document name",
      }));
    }
  };

  // delete document
  const handleAdmissionDelete = () => {
    if (isDeletePrevilage("document upload")) {
      setEmployeeActionButtonShow(false);
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          dispatch(countryDocumentAdmissionApplicationDeleteAction(tableId));
          Swal.fire("Deleted!", "Your file has been  deleted.", "success");
        }
      });
    } else {
      setPrevilageAlert(true);
    }
  };

  // material table data & column
  const data = admissionData?.map((data, index) => ({
    id: data.id,
    sl: index + 1,
    name: data.docName,
  }));

  // sorting table
  const [sortBy, setSortBy] = useState(""); // Current sort column
  const [sortOrder, setSortOrder] = useState("asc"); // Current sort order

  const requestSort = (column) => {
    let newSortOrder = "asc";

    if (column === sortBy && sortOrder === "asc") {
      newSortOrder = "desc";
    }

    setSortBy(column);
    setSortOrder(newSortOrder);
  };

  let sortedData = useMemo(() => {
    if (sortBy && data) {
      const sortedItems = [...data].sort((a, b) => {
        if (sortOrder === "asc") {
          return a[sortBy] < b[sortBy] ? -1 : 1;
        } else {
          return a[sortBy] < b[sortBy] ? 1 : -1;
        }
      });

      return sortedItems;
    }

    return data;
  }, [data, sortBy, sortOrder]);

  const columns = [
    {
      title: "Sl no",
      field: "sl",
      // type: "numeric",
      //   hidden: true,
      headerStyle: { backgroundColor: "#F9F9FB" },
    },
    {
      title: "Document Name",
      field: "name",
      //   hidden: showEmployeeNameColumn,
      headerStyle: { backgroundColor: "#F9F9FB" },
    },

    {
      title: "Action",
      field: "action",
      filtering: false,
      headerStyle: { backgroundColor: "#F9F9FB" },
    },
  ];

  return (
    <>
      <Paper
        elevation={0}
        sx={{
          width: "auto",
          height: "53px",
          alignItems: "center",
          paddingLeft: "16px",
          backgroundColor: "#F6F6F6",
          display: "flex",
          justifyContent: "space-between",
          mt: 2,
        }}
      >
        <Typography variant="h6" sx={{ fontWeight: 700, fontSize: "19px" }}>
          Documents for {country?.split("(")[0]} admission
        </Typography>

        <Box sx={{ m: 1 }}>
          <OutLinedButton
            title="Add"
            handleClick={handleClickOpen}
            widthSize="72px"
            heightSize="36px"
          />
        </Box>
      </Paper>

      <Box sx={{ m: 1 }}>
        {sortedData.length !== 0 ? (
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                {columns?.map((column) => {
                  if (column.hidden) {
                    return null;
                  }
                  return (
                    <TableCell
                      key={column.field}
                      className="tableCell"
                      sx={{
                        textTransform: "capitalize",
                      }}
                    >
                      <TableSortLabel
                        className="headerText"
                        active={column === sortBy}
                        direction={sortOrder}
                        onClick={() => requestSort(column)}
                      >
                        {column.title}
                      </TableSortLabel>
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedData?.map((item, indx) => (
                <TableRow className={classes.tableRow} key={item.id}>
                  <TableCell>{item.sl}</TableCell>
                  <TableCell>{item.name}</TableCell>
                  <TableCell>
                    <SlOptionsVertical
                      id="demo-positioned-button"
                      aria-controls={
                        employeeOpenActionButton
                          ? "demo-positioned-menu"
                          : undefined
                      }
                      aria-haspopup="true"
                      aria-expanded={
                        employeeOpenActionButton ? "true" : undefined
                      }
                      onClick={(e) => {
                        handleClickAction(e, item.id);
                      }}
                      variant="outlined"
                      style={{ marginLeft: "10px", color: "#F80B7A" }}
                    />
                    <Menu
                      open={employeeOpenActionButton}
                      aria-labelledby="demo-positioned-button"
                      onClose={handleCloseAction}
                      anchorEl={employeeActionButtonShow}
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                      }}
                      disablePortal
                      modifiers={{
                        flip: {
                          enabled: true,
                        },
                      }}
                    >
                      <Box
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        {/* </MenuItem> */}

                        <MenuItem>
                          <RiDeleteBin6Line
                            style={{
                              fontSize: "lg",
                              width: "50px",
                              height: "23px",
                            }}
                            onClick={handleAdmissionDelete}
                          />
                        </MenuItem>
                      </Box>
                    </Menu>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter />
          </Table>
        ) : (
          <TableNoItemComponent />
        )}
      </Box>

      {/* dialog sop pupup */}
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        PaperProps={{
          style: {
            marginTop: "10vh", // Adjust the value as needed
            alignSelf: "flex-start",
            width: "500px",
          },
        }}
      >
        {/* <form onSubmit={handleSubmit}> */}
        <DialogTitle>{"Add Admission Document"}</DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            sx={{
              pt: "8px",
            }}
          >
            <InputField
              label="Document Name"
              handleChange={(e) => {
                setDocName(e.target.value);
                setError((prev) => ({
                  ...prev,
                  ["documentName"]: "",
                }));
              }}
              widthSize="100%"
              error={Boolean(error?.documentName)}
              helperText={error?.documentName}
              value={docName}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <OutLinedButton
            title="Cancel"
            handleClick={handleClose}
            widthSize="81px"
            heightSize="35px"
          />
          <SubmitButton
            title="Add"
            submit="submit"
            widthSize="81px"
            heightSize="35px"
            handleSubmit={handleSubmit}
            type="click"
          />
        </DialogActions>
        {/* </form> */}
      </Dialog>

      {/* Dialog for Previlage popUp */}

      <Dialog
        open={previlageAlert}
        onClose={() => setPrevilageAlert(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          zIndex: "9999",
          // background:"red",
          "& .MuiDialog-paper": {
            width: "100%",
            borderRadius: "5px",
            p: "15px",
            textAlign: "center",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "end",
          }}
        >
          <Close
            sx={{
              cursor: "pointer",
            }}
            onClick={() => setPrevilageAlert(false)}
          />
        </Box>
        <Typography
          sx={{
            color: "#05050F",
            fontSize: "26px",
            fontWeight: "700",
          }}
        >
          Access Denied
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            mt: "-10px",
          }}
        >
          <AccessDeniedIcon />
        </Box>
        <Typography
          sx={{
            color: "#717171",
            fontSize: "16px",
            mb: "10px",
          }}
        >
          Sorry, you don’t have permission to create/delete
        </Typography>
      </Dialog>
    </>
  );
}
