import React, { useEffect, useState } from "react";
import { InfoHeader } from "../../../customComponent/InfoHeader";
import { Box, FormLabel, Grid, Typography } from "@mui/material";
import { MainContainer } from "../../../content/content.element";
import InputField, {
  SelectInputField,
} from "../../../customComponent/InputField";
import { SubmitButton, SwitchButton } from "../../../customComponent/Buttons";
import { useNavigate, useParams } from "react-router-dom";
import TopicUploadComponent from "../../../customComponent/TopicUploadComponent";
import { ieltsCategoryFindAction } from "../../../../actions/IeltsActions/learningManadmentAction";
import { useDispatch, useSelector } from "react-redux";
import {
  ieltsCourseManagmentExamTipsPdfDeleteAction,
  ieltsCourseManagmentExamtipUpdateAction,
  ieltsCourseManagmentExamtipsFindbyIdAction,
} from "../../../../actions/IeltsActions/courseManagmentAction";
import { successTrueMsgAction } from "../../../../actions/globalAction";
import Swal from "sweetalert2";

function ExamTipsEditForm({ toggle }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [formData, setFormData] = useState({});
  const [isDeleted,setIsDeleted] = useState(false)
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [title, setTitle] = useState("");
  const [category, setCategory] = useState("");
  const [categoryList, setCategoryList] = useState([]);
  const [uploadedExamTips, setUploadedExamTips] = useState("");
  const [err, setErr] = useState({
    title: "",
    category: "",
    uploadedExamTips: "",
    all: "",
  });

  // Category find response

  let { ieltsCategoryFindSuccess, ieltsCategoryFinderror } = useSelector(
    (state) => {
      return state.ieltsCategoryList;
    }
  );

  let {
    ieltsCourseManagmentExamtipsFindByIdSuccess,
    ieltsCourseManagmentExamtipsFindByIderror,
  } = useSelector((state) => {
    return state.ieltsCourseManagmentExamtipsFindById;
  });

  let {
    ieltsCourseManagmentExamtipsPdfDeleteSuccess,
    ieltsCourseManagmentExamtipsPdfDeleteerror,
  } = useSelector((state) => {
    return state.ieltsCourseManagmentExamtipsPdfDelete;
  });
  useEffect(() => {
    dispatch(ieltsCategoryFindAction("topicsAdd"));
  }, [dispatch]);

  useEffect(() => {
    dispatch(ieltsCourseManagmentExamtipsFindbyIdAction(id));
  }, [dispatch, ieltsCourseManagmentExamtipsPdfDeleteSuccess]);

  useEffect(() => {
    if (ieltsCategoryFindSuccess) {
      setCategoryList(ieltsCategoryFindSuccess.data);
    }
  }, [ieltsCategoryFindSuccess]);

  useEffect(() => {
    if (ieltsCourseManagmentExamtipsFindByIdSuccess) {
      setTitle(ieltsCourseManagmentExamtipsFindByIdSuccess?.data?.title);
      setCategory(ieltsCourseManagmentExamtipsFindByIdSuccess?.data?.category);
      setUploadedExamTips(
        ieltsCourseManagmentExamtipsFindByIdSuccess?.data?.examTipsUrl
      );
      setIsDeleted(false);
      console.log(uploadedExamTips)
    }
    
  }, [ieltsCourseManagmentExamtipsFindByIdSuccess]);


  /////////////////////////////////////////////////////////////////////

  const handleFileChange = (event) => {
    setUploadedExamTips(event.target.files[0]);
    setIsDeleted(false);
  };


  // Success message

  let { successAlertMsgSuccess } = useSelector((state) => {
    return state.successTrueMsg;
  });

  let {
    ieltsCourseManagmentExamtipsUpdateSuccess,
    ieltsCourseManagmentExamtipsUpdateerror,
  } = useSelector((state) => {
    return state.ieltsCourseManagmentExamTipsUpdate;
  });

  useEffect(() => {
    if (
      successAlertMsgSuccess &&
      ieltsCourseManagmentExamtipsUpdateSuccess?.status == true
    ) {
      Swal.fire("Updated", "Exam tip updated successfully.", "success");
      dispatch(successTrueMsgAction(false));
    }
    if (
      successAlertMsgSuccess &&
      ieltsCourseManagmentExamtipsUpdateSuccess?.status == false
    ) {
      Swal.fire("Error", "Exam tip not updated.", "warning");
      dispatch(successTrueMsgAction(false));
    }
  }, [ieltsCourseManagmentExamtipsUpdateSuccess, successAlertMsgSuccess]);

  // Handle DeleteFunction

  const handleDeleteFunction = () => {
    setIsDeleted(true)
  };


  // console.log(uploadedExamTips, "isDeleted");
  // Handle function for adding examTips

  const handleAddNewButton = () => {
    let isValid = true;
    if (!title && !category && !uploadedExamTips) {
      setErr({ all: "Add exam details" });
      isValid = false;
    }
    if (!category) {
      setErr({ category: "Enter category name" });
      isValid = false;
    }
    if (!title) {
      setErr({ title: "Enter title" });
      isValid = false;
    }
    if (!uploadedExamTips) {
      setErr({ uploadedExamTips: "Upload examTip pdf" });
      isValid = false;
    }
    if (!isValid) {
      return;
    }
    if (isDeleted) {
      dispatch(ieltsCourseManagmentExamTipsPdfDeleteAction(id));
    } 
    const formData = new FormData();
    formData.append("title", title);
    formData.append("category", category);
    formData.append("uploadExamTips", uploadedExamTips);
    formData.append("id", id);

    
    dispatch(ieltsCourseManagmentExamtipUpdateAction(formData));
    dispatch(successTrueMsgAction(true));
  };

  return (
    <MainContainer active={toggle}>
      <InfoHeader headTitle="Exam Tips" url="/IELTS" />

      <div style={{ marginTop: "30px" }}>
        <Grid
          item
          lg={4}
          xs={12}
          sx={{
            "& .input-group": {
              mb: "44px",
              mt: "0",
            },
          }}
        >
          <InputField
            label="Title"
            handleChange={(e) => {
              setTitle(e.target.value);
            }}
            value={title}
            widthSize="546px"
            required
            paramses
            InputType="text"
            dispcriptionHeight
          />
        </Grid>
        <Grid
          item
          lg={4}
          xs={12}
          sx={{
            "& .MuiFormControl-root": {
              maxWidth: "558px",
            },
          }}
        >
          <SelectInputField
            mapValue={categoryList}
            label="Select Category"
            handleSelectChange={(e) => setCategory(e.target.value)}
            widthSize="558px"
            value={category}
            mapMethod="ieltsCategory"
          />
        </Grid>

        <Grid
          item
          lg={4}
          xs={12}
          sx={{
            mt: "32px",
          }}
        >
          {!uploadedExamTips || isDeleted ? (
            <Box>
              <Typography
                sx={{
                  color: "#05050F",
                  fontSize: "16px",
                  lineHeight: "20px",
                  mb: "23px",
                }}
              >
                Upload File
              </Typography>
              <FormLabel
                sx={{
                  boxSizing: "border-box",
                  width: "100%",
                  maxWidth: "558px",
                  height: "46px",
                  border: "1px solid #C4C4C4",
                  display: "flex",
                  alignItems: "center",
                  pl: "14px",
                  pr: "8px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "16px",
                    lineHeight: "24px",
                    color: formData.logoFile ? "#05050F" : "#ACB1C6",
                    textAlign: "left",
                    width: "100%",
                  }}
                >
                  Choose file
                </Typography>
                <input
                  type="file"
                  hidden
                  name="logoFile"
                  multiple
                  onChange={(e) => handleFileChange(e)}
                  accept=".pdf"
                />
                <Box
                  sx={{
                    cursor: "pointer",
                    padding: "4px 18px",
                    background: "#EBF2FD",
                    color: "#1E88E5",
                    fontWeight: 600,
                    fontSize: "12px",
                  }}
                >
                  Browse
                </Box>
              </FormLabel>
            </Box>
          ) : (
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "repeat(4,234px)",
                padding: "30px 20px",
                background: "#F5F5F5",
                gap: "20px",
                // width: "100%",
              }}
            >
              <Box>
                <TopicUploadComponent
                  type="pdf"
                  title={
                    uploadedExamTips?.name
                      ? uploadedExamTips?.name
                      : uploadedExamTips
                          .split("/")
                          [uploadedExamTips.split("/").length - 1].replace(
                            /%20/g,
                            " "
                          )
                  }
                  handleClick={handleDeleteFunction}
                />
              </Box>
            </Box>
          )}
        </Grid>
        <Box
          sx={{
            mt: "44px",
          }}
        >
          <SubmitButton
            title="Edit ExamTips"
            submit=""
            widthSize="123px"
            heightSize="38px"
            type="click"
            handleSubmit={handleAddNewButton}
          />
        </Box>
      </div>
    </MainContainer>
  );
}

export default ExamTipsEditForm;
