import React, { useEffect, useState } from "react";
import { MdClear } from "react-icons/md";
import { AiOutlineSearch } from "react-icons/ai";

import ListSubheader from "@mui/material/ListSubheader";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import StarBorder from "@mui/icons-material/StarBorder";
import { countries } from "countries-list";

import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputAdornment,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { RiSearch2Line } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { entrolledStudentsFindAction } from "../../../actions/IeltsActions/entrolledStudentsAction";
// import { findcountryMasterAddAction } from "../../actions/countryMasterAction";

function EntrolledFilterEmployee(props) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [secListopen, setSecListOpen] = useState(false);
  const [thirdListopen, setThirdListOpen] = useState(false);
  const [fourthListopen, setFourthListOpen] = useState(false);
  const [fifthListopen, setFifthListOpen] = useState(false);
  const [sixthListopen, setSixthListOpen] = useState(false);
  const [sevenListopen, seventhListOpen] = useState(false);
  //   const [cityFind, setCityFind] = useState("");
  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [viewAll, setViewAll] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(null);

  //   let { countryFindLoading, countryFindSuccess } = useSelector((state) => {
  //     return state.findcountryMasterAdd;
  //   });

  //   useEffect(() => {
  //     dispatch(findcountryMasterAddAction());
  //   }, [dispatch]);

  //   useEffect(() => {limitedCountries
  //     if (countryFindSuccess) {
  //       setCountryList(countryFindSuccess);
  //     }
  //   }, [countryFindSuccess]);

  //   const handleSubmit = (e, chipName) => {
  //     e.preventDefault();
  // dispatch(city(cityFind, id, "cityFind"));
  // props.LeadFilterFunction(cityFind, chipName);
  // setCityFind("");
  // Add your logic here to handle the form submission with the entered application fee
  //   };

  let {
    ieltsStudentsEntrolledFindSuccess,
    ieltsStudentsEntrolledFinderror,
  } = useSelector((state) => {
    return state.ieltsEntrolledStudents;
  });

  useEffect(() => {
    dispatch(entrolledStudentsFindAction());
  }, [dispatch]);

  let uniqueCountries;
  let uniqueState;
  let uniqueCity;
  useEffect(() => {
    if (ieltsStudentsEntrolledFindSuccess) {
      uniqueCountries = Array.from(
        new Set(ieltsStudentsEntrolledFindSuccess.map((data) => data.country))
      );
      uniqueState = Array.from(
        new Set(ieltsStudentsEntrolledFindSuccess.map((data) => data.state))
      );
      uniqueCity = Array.from(
        new Set(ieltsStudentsEntrolledFindSuccess.map((data) => data.city))
      );
      setCountryList(uniqueCountries);
      setStateList(uniqueState);
      setCityList(uniqueCity);
    }
  }, [ieltsStudentsEntrolledFindSuccess]);

  console.log(countryList, "countryListcountryListcountryList");

  const handleClick = () => {
    setOpen(!open);
  };
  const handleClick2 = () => {
    setSecListOpen(!secListopen);
  };
  const handleClick3 = () => {
    setThirdListOpen(!thirdListopen);
  };
  const handleClick4 = () => {
    setFourthListOpen(!fourthListopen);
  };
  const handleClick5 = () => {
    setFifthListOpen(!fifthListopen);
  };
  const handleClick6 = () => {
    setSixthListOpen(!sixthListopen);
  };
  const handleClick7 = () => {
    seventhListOpen(!sevenListopen);
  };

  const handleClearClick = () => {
    props.handleResetData(true);
  };

  //   const handleChange = (e) => {
  //     const searchText = e.target.value.toLowerCase();

  //     if (searchText === "") {
  //       setCountryList(countryFindSuccess); // Reset to the original data when search text is empty
  //     } else {
  //       setCountryList((chips) =>
  //         chips.filter((data) =>
  //           data.countryName.toLowerCase().includes(searchText)
  //         )
  //       );
  //     }
  //   };

  const handleCheckCountry = (event, branchId, CheckedValue) => {
    if(event !== "gender"){

      setSelectedCountry(branchId === selectedCountry ? null : branchId);
      props.LeadFilterFunction(branchId, CheckedValue, event.target.checked);
  
      const searchText = event.target.cheked;
  
      if (searchText === false) {
        setCountryList(uniqueCountries); // Reset to the original data when search text is empty
      }
    }
    else{
      props.LeadFilterFunction(branchId.target.value,"gender",true);
    }
  };

  // country

  return (
    <>
      <List
        sx={{
          // width:"fit-content",
          maxWidth: "316px",
          // height: "620px",
          height: "auto",
          bgcolor: "background.paper",
          borderRadius: "8px",
          backgroundColor: "rgba(255, 255, 255, 0.5)",
          // overflow: "scroll",
          // overflowX: "hidden",
          border: "1px solid #ECECEC",
          mt: 2.5,
        }}
        component="nav"
        aria-labelledby="nested-list-subheader"
        subheader={
          <ListSubheader
            component="div"
            id="nested-list-subheader"
            sx={{ backgroundColor: "#F2F2F2" }}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>Filter</div>
              <div
                style={{
                  display: "flex",
                  marginTop: "12px",
                  cursor: "pointer",
                }}
                onClick={handleClearClick}
              >
                <MdClear style={{ fontSize: "22px", height: "20px" }} />{" "}
                <Typography>Clear All</Typography>
              </div>
            </div>
          </ListSubheader>
        }
      >
        {props.statusComponent === "EntrolledStudents" && (
          <>
            {/* first List */}
            {/* <ListItemButton onClick={handleClick}>
              <ListItemText
                primary="Country"
                sx={{
                  fontWeight: 600,
                  fontSize: "14px",
                  fontFamily: "Open Sans",
                }}
              />
              {open ? (
                <ExpandLess sx={{ fontSize: "17px", fontWeight: "thin" }} />
              ) : (
                <ExpandMore sx={{ fontSize: "17px", fontWeight: "thin" }} />
              )}
            </ListItemButton>
            {/* collapse */}
            {/* <Collapse in={open} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton sx={{ textAlign: "center" }}>
                  <form>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <TextField
                        variant="outlined"
                        id="city"
                        placeholder="Search Here"
                        // value={cityFind}
                        // onChange={(e) => setCityFind(e.target.value)}
                        sx={{
                          "& .MuiInputBase-input": {
                            borderRadius: "20px",
                            height: "1px",
                          },
                          "& .MuiFormLabel-root": {
                            lineHeight: "5px",
                          },

                          "& .MuiInputBase-root": {
                            borderRadius: "3px",
                            borderColor: "#fafafa",
                          },
                        }}
                      />
                      <>
                        <Box
                          component="button"
                          sx={{
                            width: "40px",
                            height: "34px",
                            textAlign: "center",
                            border: "1px solid #9d9fa1",
                            borderBottomRightRadius: "5px",
                            WebkitBorderTopRightRadius: "5px",
                            fontSize: "24px",
                          }}
                          type="submit"
                        >
                          <InputAdornment position="end">
                            <RiSearch2Line />
                          </InputAdornment>
                        </Box>
                      </>
                    </div>
                  </form>
                </ListItemButton>
              </List>
            </Collapse>  */}
            {/* second List */}
            <ListItemButton onClick={handleClick2}>
              <ListItemText primary="Country" />
              {secListopen ? (
                <ExpandLess sx={{ fontSize: "17px", fontWeight: "thin" }} />
              ) : (
                <ExpandMore sx={{ fontSize: "17px", fontWeight: "thin" }} />
              )}
            </ListItemButton>
            <Collapse in={secListopen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton>
                  <TextField
                    placeholder="Search Here"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment>
                          <IconButton>
                            <AiOutlineSearch />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    // onChange={handleChange}
                    sx={{
                      backgroundColor: "white",
                      margin: 1,
                      width: "auto",
                      "& .MuiInputBase-input": {
                        borderRadius: "20px",
                        height: "1px",
                      },
                      "& .MuiFormLabel-root": {
                        lineHeight: "5px",
                      },

                      "& .MuiInputBase-root": {
                        borderRadius: "3px",
                        borderColor: "#fafafa",
                      },
                    }}
                  />
                </ListItemButton>

                <ListItemButton>
                  <FormGroup>
                    {countryList &&
                      countryList?.map((country) => (
                        <FormControlLabel
                          control={
                            <Checkbox
                              sx={{
                                color: "#F8469A",
                                "&.Mui-checked": {
                                  color: "#F8469A",
                                },
                              }}
                              checked={country === selectedCountry}
                              onChange={(e) => {
                                handleCheckCountry(e, country, "Country");
                              }}
                            />
                          }
                          label={country ? country : "No Countries to list"}
                        />
                      ))}
                    <Typography
                      sx={{ fontWeight: 400, fontSize: "12px", color: "red" }}
                    >
                      viewAll
                    </Typography>
                  </FormGroup>
                </ListItemButton>
              </List>
            </Collapse>
            <ListItemButton onClick={handleClick3}>
              <ListItemText primary="State" />
              {thirdListopen ? (
                <ExpandLess sx={{ fontSize: "17px", fontWeight: "thin" }} />
              ) : (
                <ExpandMore sx={{ fontSize: "17px", fontWeight: "thin" }} />
              )}
            </ListItemButton>
            <Collapse in={thirdListopen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton>
                  <TextField
                    placeholder="Search Here"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment>
                          <IconButton>
                            <AiOutlineSearch />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    // onChange={handleChange}
                    sx={{
                      backgroundColor: "white",
                      margin: 1,
                      width: "auto",
                      "& .MuiInputBase-input": {
                        borderRadius: "20px",
                        height: "1px",
                      },
                      "& .MuiFormLabel-root": {
                        lineHeight: "5px",
                      },

                      "& .MuiInputBase-root": {
                        borderRadius: "3px",
                        borderColor: "#fafafa",
                      },
                    }}
                  />
                </ListItemButton>

                <ListItemButton>
                  <FormGroup>
                    {stateList &&
                      stateList?.map((state) => (
                        <FormControlLabel
                          control={
                            <Checkbox
                              sx={{
                                color: "#F8469A",
                                "&.Mui-checked": {
                                  color: "#F8469A",
                                },
                              }}
                              checked={state === selectedCountry}
                              onChange={(e) => {
                                handleCheckCountry(e, state, "State");
                              }}
                            />
                          }
                          label={state ? state : "No States to list"}
                        />
                      ))}
                    <Typography
                      sx={{ fontWeight: 400, fontSize: "12px", color: "red" }}
                    >
                      viewAll
                    </Typography>
                  </FormGroup>
                </ListItemButton>
              </List>
            </Collapse>
            <ListItemButton onClick={handleClick4}>
              <ListItemText primary="City" />
              {fourthListopen ? (
                <ExpandLess sx={{ fontSize: "17px", fontWeight: "thin" }} />
              ) : (
                <ExpandMore sx={{ fontSize: "17px", fontWeight: "thin" }} />
              )}
            </ListItemButton>
            <Collapse in={fourthListopen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton>
                  <TextField
                    placeholder="Search Here"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment>
                          <IconButton>
                            <AiOutlineSearch />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    // onChange={handleChange}
                    sx={{
                      backgroundColor: "white",
                      margin: 1,
                      width: "auto",
                      "& .MuiInputBase-input": {
                        borderRadius: "20px",
                        height: "1px",
                      },
                      "& .MuiFormLabel-root": {
                        lineHeight: "5px",
                      },

                      "& .MuiInputBase-root": {
                        borderRadius: "3px",
                        borderColor: "#fafafa",
                      },
                    }}
                  />
                </ListItemButton>

                <ListItemButton>
                  <FormGroup>
                    {cityList &&
                      cityList?.map((city) => (
                        <FormControlLabel
                          control={
                            <Checkbox
                              sx={{
                                color: "#F8469A",
                                "&.Mui-checked": {
                                  color: "#F8469A",
                                },
                              }}
                              checked={city === selectedCountry}
                              onChange={(e) => {
                                handleCheckCountry(e, city, "City");
                              }}
                            />
                          }
                          label={city ? city : "No Cities to Show"}
                        />
                      ))}
                    <Typography
                      sx={{ fontWeight: 400, fontSize: "12px", color: "red" }}
                    >
                      viewAll
                    </Typography>
                  </FormGroup>
                </ListItemButton>
              </List>
            </Collapse>
            <ListItemButton onClick={handleClick5}>
              <ListItemText primary="Gender" />
              {fifthListopen ? (
                <ExpandLess sx={{ fontSize: "17px", fontWeight: "thin" }} />
              ) : (
                <ExpandMore sx={{ fontSize: "17px", fontWeight: "thin" }} />
              )}
            </ListItemButton>

            <Collapse in={fifthListopen} timeout="auto" unmountOnExit>
              <RadioGroup
                name="gender"
                // value={selectedValue} // You can set the value if you want to control the selection
                // onChange={handleChange} // Handle change event if you want to control the selection
              >
                <ListItemButton>
                  <FormControlLabel
                    value="Male"
                    control={
                      <Radio
                        sx={{
                          color: "#F8469A",
                          "&.Mui-checked": {
                            color: "#F8469A",
                          },
                        }}
                        onChange={(e) => {
                          handleCheckCountry("gender",e);
                        }}
                      />
                    }
                    label="Male"
                  />
                </ListItemButton>
                <ListItemButton>
                  <FormControlLabel
                    value="Female"
                    control={
                      <Radio
                        sx={{
                          color: "#F8469A",
                          "&.Mui-checked": {
                            color: "#F8469A",
                          },
                        }}
                        onChange={(e) => {
                          handleCheckCountry("gender",e);
                        }}
                      />
                    }
                    label="Female"
                  />
                </ListItemButton>
              </RadioGroup>
            </Collapse>
          </>
        )}
      </List>
    </>
  );
}

export default EntrolledFilterEmployee;
