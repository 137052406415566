import React, { useEffect, useRef, useState } from "react";
import { Box, Divider, Grid, Modal, Typography } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";

import InputField from "../../customComponent/InputField";
import { MainContainer } from "../../content/content.element";
import { InfoHeader } from "../../customComponent/InfoHeader";
import { OutLinedButton, SubmitButton } from "../../customComponent/Buttons";
import { ServiceFeature } from "./ServiceFeature";
import { LandingServiceAddAction } from "../../../actions/LandingPageAction";
import { successTrueMsgAction } from "../../../actions/globalAction";
import Swal from "sweetalert2";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "400",
  height: "400",
  //   overflow: "scroll",
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 20,
  p: 4,
};

function CreateService(props) {
  let { serviceCreateSuccess } = useSelector(
    (state) => state.LandingServiceAdd
  );

  let { successAlertMsgSuccess } = useSelector((state) => {
    return state.successTrueMsg;
  });

  useEffect(() => {
    if (serviceCreateSuccess && successAlertMsgSuccess) {
      Swal.fire("Added!", "News Added Successfully.", "success");
      dispatch(successTrueMsgAction(false));
      setServiceName("");
      setDescription("");
      setDescription1("");
      setDescription2("");
      setFeatures([]);
    }
  }, [serviceCreateSuccess]);

  const dispatch = useDispatch();
  const [serviceName, setServiceName] = useState("");
  const [description, setDescription] = useState("");
  const [description1, setDescription1] = useState("");
  const [description2, setDescription2] = useState("");
  const [featuresValue, setFeaturesValue] = useState("");
  const [iconUpload, SetIconUpload] = useState();
  const [feature, setFeatures] = useState([]);
  const [error, setError] = useState({});

  const serviceIcon = useRef();

  const [open, setOpen] = useState(false);

  // FAQ

  const handleFeatures = (event) => {
    setFeaturesValue(event.target.value);
    setError((prev) => ({
      ...prev,
      ["featuresValue"]: "",
    }));
  };

  const handleServiceIcon = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    setError((prev) => ({
      ...prev,
      ["serviceIcon"]: "",
    }));

    // Read the image file as a data URL
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      const UploadDestinationimage = reader.result;

      SetIconUpload(UploadDestinationimage);
    };
  };

  // HandleCreate
  const validatefeatures = () => {
    let errorList = {};
    if (!iconUpload) {
      errorList.iconUpload = "please choose an icon a service name";
    }
    if (!featuresValue) {
      errorList.featuresValue = "please enter a feature name";
    }
    setError(errorList);
    return Object.keys(errorList).length === 0;
  };

  const handleAdd = () => {
    if (validatefeatures()) {
      const newItem = { featureName: featuresValue, icon: iconUpload };
      setFeatures((prevData) => [...prevData, newItem]);
      SetIconUpload("");
      setError((prev) => ({
        ...prev,
        ["feature"]: "",
      }));
      handleClose()
    }
  };

  const validate = () => {
    let errorList = {};
    if (!serviceName) {
      errorList.serviceName = "please enter a service name";
    }
    if (!description) {
      errorList.description = "please enter a description";
    }
    if (!description1) {
      errorList.description1 = "please enter a description";
    }
    if (!description2) {
      errorList.description2 = "please enter a description";
    }
    if (feature.length === 0) {
      errorList.feature = "please add some features";
    }
    setError(errorList);
    return Object.keys(errorList).length === 0;
  };

  const HandleCreate = () => {
    if (validate()) {
      dispatch(successTrueMsgAction(true));
      dispatch(
        LandingServiceAddAction(
          serviceName,
          description,
          description1,
          description2,
          feature
        )
      );
    } else {
      alert("please fill all fields");
    }
  };

  const handleClose = () => {
    setOpen(false);
    SetIconUpload("")
    setFeaturesValue("");
    setError((prev) => ({
      ...prev,
      ["iconUpload"]: "",
      ["featuresValue"]: "",
    }));
  };

  return (
    <MainContainer active={props.toggle}>
      <div
        style={{
          display: "flex",
          justifyContent: "start",
          backgroundColor: "white",
        }}
      >
        <InfoHeader headTitle="Create Service" url={"/landingPage"} />
      </div>
      <Divider />

      <Grid container sx={{ mt: 3 }}>
        <Grid item xs={12} lg={12}>
          <InputField
            label="Service Name"
            handleChange={(e) => {
              setServiceName(e.target.value);
              setError((prev) => ({
                ...prev,
                ["serviceName"]: "",
              }));
            }}
            widthSize="98%"
            value={serviceName}
            required
            paramses
            error={Boolean(error.serviceName)}
            helperText={error.serviceName}
          />
        </Grid>
        <Grid container sx={{ mt: 2 }}>
          <Grid item xs={12} lg={12}>
            <InputField
              label="Description"
              handleChange={(e) => {
                setDescription(e.target.value);
                setError((prev) => ({
                  ...prev,
                  ["description"]: "",
                }));
              }}
              widthSize="98%"
              value={description}
              InputType="discription"
              dispcriptionHeight="130px"
              required
              paramses
              error={Boolean(error.description)}
              helperText={error.description}
            />
          </Grid>
          <Grid item xs={12} lg={12} mt={4}>
            <Typography sx={{ fontWeight: 700, fontSize: "20px" }}>
              Section 1
            </Typography>
          </Grid>
          <Grid item xs={12} lg={12} mt={1.5}>
            <InputField
              label="Description"
              handleChange={(e) => {
                setDescription1(e.target.value);
                setError((prev) => ({
                  ...prev,
                  ["description1"]: "",
                }));
              }}
              widthSize="98%"
              value={description1}
              InputType="discription"
              dispcriptionHeight="130px"
              required
              paramses
              error={Boolean(error.description1)}
              helperText={error.description1}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} lg={12} sx={{ mt: 4 }}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <label
              style={{
                fontWeight: "700",
                fontSize: "20px",
                lineHeight: "24px",
              }}
            >
              Features
            </label>
            <div style={{ marginRight: "10px" }}>
              <OutLinedButton
                title="Add"
                handleClick={() => setOpen(true)}
                widthSize="71px"
                heightSize="35px"
              />
            </div>
          </div>

          <div style={{ marginTop: "20px" }}>
            <ServiceFeature
              columns={[
                {
                  title: "SL NO",
                  field: "SL NO",
                },
                {
                  title: "Features Name",
                  field: "Features",
                },
                {
                  title: "Icon",
                  field: "Icon",
                },
              ]}
              data={feature}
              component="FaqTable"
              //   isPresentMap={setFaq}
            />
          </div>

          {Boolean(error.feature) && (
            <Typography
              sx={{
                color: "#FF2400",
                fontSize: "12px",
                marginTop: "5px",
              }}
            >
              {error.feature}
            </Typography>
          )}
        </Grid>

        <Grid item xs={12} lg={12} mt={4}>
          <Typography sx={{ fontWeight: 700, fontSize: "20px" }}>
            Section 2
          </Typography>
        </Grid>
        <Grid item xs={12} lg={12} mt={1.5}>
          <InputField
            label="Description"
            handleChange={(e) => {
              setDescription2(e.target.value);
              setError((prev) => ({
                ...prev,
                ["description2"]: "",
              }));
            }}
            widthSize="98%"
            value={description2}
            InputType="discription"
            dispcriptionHeight="130px"
            required
            paramses
            error={Boolean(error.description2)}
            helperText={error.description2}
          />
        </Grid>
      </Grid>
      {/* Submit Button */}
      <Grid
        item
        xs={4}
        lg={12}
        style={{
          display: "flex",
          justifyContent: "flex-start",
          marginTop: "10px",
          marginBottom: "50px",
        }}
      >
        <SubmitButton
          title="Save"
          submit=""
          widthSize="100px"
          heightSize="38px"
          type="click"
          handleSubmit={HandleCreate}
        />
      </Grid>

      {/* FAQ modal */}

      {open && (
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              sx={{ fontWeight: "bold" }}
            >
              Features:
            </Typography>

            <div
              style={{
                marginTop: "10px",
                paddingTop: "10px",
                width: "500px",
              }}
            >
              <InputField
                label="Features Name"
                handleChange={handleFeatures}
                widthSize="98%"
                value={featuresValue}
                required
                paramses
                error={Boolean(error.featuresValue)}
                helperText={error.featuresValue}
              />
            </div>
            <Box>
              <Grid item xs={12} lg={12}>
                <button
                  onClick={() => serviceIcon.current.click()}
                  style={{
                    width: "100px",
                    height: "40px",
                    fontSize: "13px",
                    fontFamily: "monospace",
                    border: "none",
                    cursor: "pointer",
                  }}
                >
                  Browse
                </button>
                <input
                  ref={serviceIcon}
                  type="file"
                  accept="image/*"
                  onChange={(event) => {
                    handleServiceIcon(event);
                  }}
                  style={{ display: "none" }}
                ></input>
              </Grid>
              {Boolean(error.iconUpload) && (
                <Typography
                  sx={{
                    color: "#FF2400",
                    fontSize: "12px",
                    marginTop: "5px",
                  }}
                >
                  {error.iconUpload}
                </Typography>
              )}
            </Box>
            <Grid
              item
              xs={4}
              lg={12}
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "10px",
              }}
            >
              <SubmitButton
                title="Save"
                submit=""
                widthSize="100px"
                heightSize="38px"
                type="click"
                handleSubmit={handleAdd}
              />
            </Grid>
          </Box>
        </Modal>
      )}
    </MainContainer>
  );
}

export default CreateService;
