import React, { useState } from "react";

import { Box, Button, Grid, Paper, TextField, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { confirmOTPAction } from "../../../actions/adminAuthAction";

function ConfirmOtp() {
  const dispatch = useDispatch();

  const { confirmOTPSuccess, confirmOTPLoading, confirmOTPerror } = useSelector(
    (state) => {
      return state.confirmOTP;
    }
  );

  const [otp, setOtp] = useState("");
  const [otpError, setOtpError] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();

    if (otp === "") {
      setOtpError(true);
    }

    if (otp !== "") {
      event.preventDefault();
      dispatch(confirmOTPAction(otp));
    }
  };

  // onchange otp handle
  const handleOtpChange = (event) => {
    setOtp(event.target.value);
    setOtpError(false);
  };

  return (
    <Grid
      item
      xs={12}
      sm={8}
      md={5}
      component={Paper}
      elevation={6}
      square
      sx={{ width: "100%" }}
    >
      <Box
        sx={{
          my: 8,
          mx: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
        }}
      >
        {confirmOTPerror && (
          <Typography
            style={{
              fontWeight: 90,
              fontSize: "20px",
              fontFamily: "'Open Sans', sans-serif",
              lineHeight: "52px",
              color: "red",
            }}
          >
            {confirmOTPerror}
          </Typography>
        )}
        <Typography
          component="h2"
          variant="h2"
          style={{
            fontWeight: 700,
            fontSize: "40px",
            fontFamily: "'Open Sans', sans-serif",
            lineHeight: "52px",
            color: "#141E3C",
          }}
        >
          OTP Confirm
        </Typography>

        <Typography
          component="h6"
          variant="h6"
          style={{
            width: "425px",
            height: "24px",
            left: "40px",
            top: "101px",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "16px",
            lineHeight: "150%",
            color: "#777AAE",
          }}
        >
          Enter OTP For Confirmation
        </Typography>
        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="otp"
            label="Enter OTP"
            name="number"
            onChange={handleOtpChange}
            error={otpError}
            helperText={otpError ? "Please enter your otp" : ""}
            autoFocus
            sx={{ width: "100%" }}
          />

          <Grid container mt="122px">
            <Grid item></Grid>

            <Button
              className="button"
              type="submit"
              fullWidth
              size="large"
              sx={{
                mt: 3,
                mb: 2,
                fontWeight: "700",
                backgroundColor: "#141E3C",
                color: "#fff",
              }}
            >
              Get OTP
            </Button>
          </Grid>
        </Box>
      </Box>
    </Grid>
  );
}

export default ConfirmOtp;
