import React, { useEffect, useState } from "react";
import { MainContainer } from "../../content/content.element";
import {
  Box,
  Divider,
  FormControlLabel,
  FormLabel,
  Grid,
  Switch,
  Typography,
} from "@mui/material";
import { IoArrowBack } from "react-icons/io5";
import { useNavigate, useParams } from "react-router-dom";
import { InfoHeader } from "../../customComponent/InfoHeader";
import Swal from "sweetalert2";
import { successTrueMsgAction } from "../../../actions/globalAction";
import InputField, { SelectInputField } from "../../customComponent/InputField";
import { SubmitButton, SwitchButton } from "../../customComponent/Buttons";
import { useDispatch, useSelector } from "react-redux";
import {
  ieltsCategoryFindAction,
  ieltsLearningManagmentDeleteTopicAction,
  ieltsLearningManagmentFindTopicByIdAction,
  ieltsLearningManagmentUpdateTopicAction,
} from "../../../actions/IeltsActions/learningManadmentAction";
import TopicUploadComponent from "../../customComponent/TopicUploadComponent";

function TopicEditForm(props) {
  let isUserExist = localStorage.getItem("loginInfo")
    ? JSON.parse(localStorage.getItem("loginInfo"))
    : null;

  let {
    ieltsLearningManagmentFindTopicByIdSuccess,
    ieltsLearningManagmentFindTopicByIderror,
  } = useSelector((state) => {
    return state.ieltslearningManagmentFindTopiById;
  });

  const { id } = useParams();
  const leadId = isUserExist.UserId;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [checkedVideo, setCheckedVideo] = useState(false);
  const [checkedAudio, setCheckedAudio] = useState(false);
  const [checkedNote, setCheckedNote] = useState(false);
  const [topicName, setTopicName] = useState("");
  const [categoryList, setCategoryList] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [videoDuration, setVideoDuration] = useState("");
  const [uploadedVideo, setUploadedVideo] = useState(
    ieltsLearningManagmentFindTopicByIdSuccess?.data?.VideoUrl
  );
  const [audioDuration, setAudioDuration] = useState("");
  const [uploadedAudio, setUploadedAudio] = useState(
    ieltsLearningManagmentFindTopicByIdSuccess?.data?.AudioUrl
  );
  const [uploadedNote, setUplodedNote] = useState("");
  const [uploadedVideoName, setUploadedVideoName] = useState("");
  const [uploadedAudioName, setUploadedAudioName] = useState("");
  const [uploadedNoteName, setUploadedNoteName] = useState("");

  const [err, setErr] = useState({
    topicName: "",
    category: "",
    videoDuration: "",
    video: "",
    audio: "",
    note: "",
    audioDuration: "",
    all: "",
  });

  let { ieltsCategoryFindSuccess, ieltsCategoryFinderror } = useSelector(
    (state) => {
      return state.ieltsCategoryList;
    }
  );

  let {
    ieltsLearningManagmentTopicDeleteSuccess,
    ieltsLearningManagmentTopicDeleteerror,
  } = useSelector((state) => {
    return state.ieltslearningManagmentTopicsDelete;
  });

  let {
    ieltsLearningManagmentTopicUpdateSuccess,
    ieltsLearningManagmentTopicUpdateerror,
  } = useSelector((state) => {
    return state.ieltslearningManagmentTopicsUpdate;
  });

  useEffect(() => {
    dispatch(ieltsLearningManagmentFindTopicByIdAction(id));
  }, [
    dispatch,
    ieltsLearningManagmentTopicUpdateSuccess,
    ieltsLearningManagmentTopicDeleteSuccess,
  ]);

  // Success message

  let { successAlertMsgSuccess } = useSelector((state) => {
    return state.successTrueMsg;
  });

  useEffect(() => {
    if (
      successAlertMsgSuccess &&
      ieltsLearningManagmentTopicUpdateSuccess?.status == true
    ) {
      Swal.fire("Updated", "Topic updated successfully", "success");
      dispatch(successTrueMsgAction(false));
    }
  }, [ieltsLearningManagmentTopicUpdateSuccess, successAlertMsgSuccess]);

  console.log(
    ieltsLearningManagmentTopicUpdateSuccess,
    "ieltsLearningManagmentTopicUpdateSuccessieltsLearningManagmentTopicUpdateSuccess"
  );

  // handle the response from topicFindByidAction

  useEffect(() => {
    if (ieltsLearningManagmentFindTopicByIdSuccess) {
      setTopicName(ieltsLearningManagmentFindTopicByIdSuccess.data?.TopicName);
      setSelectedCategory(
        ieltsLearningManagmentFindTopicByIdSuccess.data?.Category
      );
      setUploadedVideo(
        ieltsLearningManagmentFindTopicByIdSuccess.data?.VideoUrl
      );
      setUploadedAudio(
        ieltsLearningManagmentFindTopicByIdSuccess.data?.AudioUrl
      );
      setUplodedNote(ieltsLearningManagmentFindTopicByIdSuccess.data?.NoteUrl);
      setVideoDuration(
        ieltsLearningManagmentFindTopicByIdSuccess.data?.VideoDuration
      );
      setAudioDuration(
        ieltsLearningManagmentFindTopicByIdSuccess.data?.AudioDuration
      );
      if (ieltsLearningManagmentFindTopicByIdSuccess.data?.VideoUrl) {
        setCheckedVideo(true);
      }
      if (ieltsLearningManagmentFindTopicByIdSuccess.data?.AudioUrl) {
        setCheckedAudio(true);
      }
      if (ieltsLearningManagmentFindTopicByIdSuccess.data?.NoteUrl) {
        setCheckedNote(true);
      }
      let videoName =
        ieltsLearningManagmentFindTopicByIdSuccess.data?.VideoUrl.split("/");
      let audioName =
        ieltsLearningManagmentFindTopicByIdSuccess.data?.AudioUrl.split("/");
      let noteName =
        ieltsLearningManagmentFindTopicByIdSuccess.data?.NoteUrl.split("/");

      let lastPart = videoName[videoName.length - 1];
      let extractedAudioName = audioName[audioName.length - 1];
      let extractedNoteName = noteName[noteName.length - 1];
      setUploadedVideoName(lastPart);
      setUploadedAudioName(extractedAudioName);
      setUploadedNoteName(extractedNoteName);
    }
  }, [ieltsLearningManagmentFindTopicByIdSuccess]);

  // Find all category

  useEffect(() => {
    dispatch(ieltsCategoryFindAction("topicsAdd"));
  }, [dispatch]);

  useEffect(() => {
    if (ieltsCategoryFindSuccess) {
      setCategoryList(ieltsCategoryFindSuccess.data);
    }
  }, [ieltsCategoryFindSuccess]);

  //   handleSaveTopi Function

  const handleSaveTopic = () => {
    let isValid = true;
    if (
      !topicName &&
      !selectedCategory &&
      !videoDuration &&
      !uploadedVideo &&
      !audioDuration &&
      !uploadedAudio &&
      !uploadedNote
    ) {
      setErr({ all: "Add topics details to save topic" });
      isValid = false;
    }
    if (!selectedCategory) {
      setErr({ category: "Enter category name" });
      isValid = false;
    }
    if (!topicName) {
      setErr({ topicName: "Enter Topic name" });
      isValid = false;
    }
    if (!isValid) {
      return;
    }

    const formData = new FormData();
    formData.append("Topicname", topicName);
    formData.append("Category", selectedCategory);
    formData.append("videoDuration", videoDuration);
    formData.append("video", uploadedVideo);
    formData.append("audioDuration", audioDuration);
    formData.append("audio", uploadedAudio);
    formData.append("note", uploadedNote);
    formData.append("leadId", leadId);
    formData.append("id", id);
    dispatch(ieltsLearningManagmentUpdateTopicAction(formData));
    dispatch(successTrueMsgAction(true));
  };

  // Handle delete function

  const handleDeleteFunction = (type) => {
    console.log(type, "type");
    dispatch(ieltsLearningManagmentDeleteTopicAction(id, type));
  };

  return (
    <MainContainer active={props.toggle}>
      <InfoHeader headTitle="Edit Topic" url="/IELTS" />
      <div style={{ marginTop: "30px" }}>
        <Grid container spacing={2}>
          <Grid item lg={4} xs={12}>
            <InputField
              label="Topic Name"
              handleChange={(e) => {
                setTopicName(e.target.value);
                setErr({ all: "" });
              }}
              widthSize="98.4%"
              value={topicName}
              required
              paramses
              InputType="text"
              dispcriptionHeight
            />
            <span
              style={{
                color: "red",
                paddingLeft: "10px",
                fontWeight: 400,
                fontSize: "12px",
              }}
            >
              {err.topicName}
            </span>
          </Grid>
          <Grid item lg={4} xs={12}>
            <SelectInputField
              label="Select Category"
              handleSelectChange={(e) => {
                setSelectedCategory(e.target.value);
                setErr({ all: "" });
              }}
              widthSize="110px"
              value={selectedCategory}
              mapValue={categoryList}
              mapMethod="ieltsCategory"
            />
            <span
              style={{
                color: "red",
                paddingLeft: "10px",
                fontWeight: 400,
                fontSize: "12px",
              }}
            >
              {err.category}
            </span>
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item lg={4} xs={12}>
            <SwitchButton
              label="Video"
              handleChange={() => {
                setCheckedVideo(!checkedVideo);
                // if (checkedVideo == false) {
                //   setUploadedVideo("");
                // }
              }}
              checked={checkedVideo}
            />
          </Grid>
        </Grid>
        {checkedVideo ? (
          <Box sx={{ mt: "30px" }}>
            <Box sx={{ maxWidth: "608px" }}>
              <InputField
                label="Video Duration"
                handleChange={(e) => {
                  setVideoDuration(e.target.value);
                  setErr({ all: "" });
                }}
                widthSize="98.4%"
                value={videoDuration}
                required
                paramses
                // InputType="time"
                dispcriptionHeight
              />
              <span
                style={{
                  fontSize: "12px",
                  color: "#777AAE",
                  marginTop: "-15px",
                  display: "block",
                }}
              >
                Enter values in "00:00:00" format
              </span>
              <span
                style={{ color: "red", paddingLeft: "10px", fontWeight: 600 }}
              >
                {err.videoDuration}
              </span>
            </Box>
            {!uploadedVideo ? (
              <Grid item lg={4} xs={12}>
                <FormLabel
                  sx={{
                    //   width: "98.4%",
                    height: "46px",
                    border: "1px solid #C4C4C4",
                    display: "flex",
                    alignItems: "center",
                    pl: "14px",
                    pr: "14px",
                    maxWidth: "calc(608px - 28px)",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "16px",
                      lineHeight: "24px",
                      color: uploadedVideo?.name ? "#05050F" : "#ACB1C6",
                      textAlign: "left",
                      width: "100%",
                    }}
                  >
                    Choose File
                  </Typography>
                  <input
                    type="file"
                    hidden
                    name="logoFile"
                    onChange={(e) => {
                      setUploadedVideo(e.target.files[0]);
                      setErr({ all: "" });
                    }}
                    accept="video/mp4,video/x-m4v,video/*"
                  />
                  <Box
                    sx={{
                      cursor: "pointer",
                      padding: "8px 18px",
                      background: "#EBF2FD",
                      color: "#1E88E5",
                      fontWeight: 600,
                      fontSize: "14px",
                    }}
                  >
                    Browse
                  </Box>
                </FormLabel>
                <span
                  style={{ color: "red", paddingLeft: "10px", fontWeight: 600 }}
                >
                  {err.video}
                </span>
              </Grid>
            ) : (
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "repeat(4,234px)",
                  padding: "30px 20px",
                  background: "#F5F5F5",
                }}
              >
                <Box>
                  <TopicUploadComponent
                    type="video"
                    title={topicName}
                    handleClick={() => handleDeleteFunction("video")}
                  />
                </Box>
              </Box>
            )}
          </Box>
        ) : (
          ""
        )}

        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item lg={4} xs={12}>
            <SwitchButton
              label="Audio"
              handleChange={() => {
                setCheckedAudio(!checkedAudio);
                // if (checkedAudio == false) {
                //   setUploadedAudio("");
                // }
              }}
              checked={checkedAudio}
            />
          </Grid>
        </Grid>
        {checkedAudio ? (
          <Box sx={{ mt: "30px" }}>
            <Box sx={{ maxWidth: "608px" }}>
              <InputField
                label="Audio Duration"
                handleChange={(e) => {
                  setAudioDuration(e.target.value);
                  setErr({ all: "" });
                }}
                widthSize="98.4%"
                value={audioDuration}
                required
                paramses
                // InputType="time"
                dispcriptionHeight
              />
              <span
                style={{
                  fontSize: "12px",
                  color: "#777AAE",
                  marginTop: "-15px",
                  display: "block",
                }}
              >
                Enter values in "00:00:00" format
              </span>
              <span
                style={{ color: "red", paddingLeft: "10px", fontWeight: 600 }}
              >
                {err.audioDuration}
              </span>
            </Box>
            {!uploadedAudio ? (
              <Grid item lg={4} xs={12}>
                <FormLabel
                  sx={{
                    //   width: "98.4%",
                    height: "46px",
                    border: "1px solid #C4C4C4",
                    display: "flex",
                    alignItems: "center",
                    pl: "14px",
                    pr: "14px",
                    maxWidth: "calc(608px - 28px)",
                  }}
                  //   onChange={handleChange}
                >
                  <Typography
                    sx={{
                      fontSize: "16px",
                      lineHeight: "24px",
                      color: uploadedAudio.name ? "#05050F" : "#ACB1C6",
                      textAlign: "left",
                      width: "100%",
                    }}
                  >
                    Choose File
                  </Typography>
                  <input
                    type="file"
                    hidden
                    name="logoFile"
                    onChange={(e) => {
                      setUploadedAudio(e.target.files[0]);
                      setErr({ all: "" });
                    }}
                    accept=".mp3,audio/mpeg3,!audio/webm"
                  />
                  <Box
                    sx={{
                      cursor: "pointer",
                      padding: "8px 18px",
                      background: "#EBF2FD",
                      color: "#1E88E5",
                      fontWeight: 600,
                      fontSize: "14px",
                    }}
                  >
                    Browse
                  </Box>
                </FormLabel>
                <span
                  style={{ color: "red", paddingLeft: "10px", fontWeight: 600 }}
                >
                  {err.audio}
                </span>
              </Grid>
            ) : (
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "repeat(4,234px)",
                  padding: "30px 20px",
                  background: "#F5F5F5",
                }}
              >
                <Box>
                  <TopicUploadComponent
                    type="audio"
                    title={topicName}
                    handleClick={() => handleDeleteFunction("audio")}
                  />
                </Box>
              </Box>
            )}
          </Box>
        ) : (
          ""
        )}
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item lg={4} xs={12}>
            <SwitchButton
              label="Note"
              handleChange={() => {
                setCheckedNote(!checkedNote);
                setErr({ all: "" });
                // if (checkedNote == false) {
                //   setUplodedNote("");
                // }
              }}
              checked={checkedNote}
            />
          </Grid>
        </Grid>
        {checkedNote ? (
          <Box sx={{ mt: "30px" }}>
            {!uploadedNote ? (
              <Box sx={{ maxWidth: "608px" }}>
                <FormLabel
                  sx={{
                    //   width: "98.4%",
                    height: "46px",
                    border: "1px solid #C4C4C4",
                    display: "flex",
                    alignItems: "center",
                    pl: "14px",
                    pr: "14px",
                    maxWidth: "calc(608px - 28px)",
                  }}
                  //   onChange={handleChange}
                >
                  <Typography
                    sx={{
                      fontSize: "16px",
                      lineHeight: "24px",
                      color: uploadedNote.name ? "#05050F" : "#ACB1C6",
                      textAlign: "left",
                      width: "100%",
                    }}
                  >
                    Choose File
                  </Typography>
                  <input
                    type="file"
                    hidden
                    name="logoFile"
                    onChange={(e) => {
                      setUplodedNote(e.target.files[0]);
                      setErr({ all: "" });
                    }}
                    accept="application/pdf,application/vnd.ms-excel"
                  />
                  <Box
                    sx={{
                      cursor: "pointer",
                      padding: "8px 18px",
                      background: "#EBF2FD",
                      color: "#1E88E5",
                      fontWeight: 600,
                      fontSize: "14px",
                    }}
                  >
                    Browse
                  </Box>
                </FormLabel>
                <span
                  style={{ color: "red", paddingLeft: "10px", fontWeight: 600 }}
                >
                  {err.note}
                </span>
              </Box>
            ) : (
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "repeat(4,234px)",
                  padding: "30px 20px",
                  background: "#F5F5F5",
                }}
              >
                <Box>
                  <TopicUploadComponent
                    type="pdf"
                    title={topicName}
                    handleClick={() => handleDeleteFunction("pdf")}
                  />
                </Box>
              </Box>
            )}
          </Box>
        ) : (
          ""
        )}

        <Grid container spacing={2} sx={{ mt: 1,mb:2 }}>
          <Grid item lg={4} xs={12}>
            <SubmitButton
              title="Save Topics"
              submit=""
              widthSize="123px"
              heightSize="38px"
              type="click"
              handleSubmit={handleSaveTopic}
            />
            <span
              style={{ color: "red", paddingLeft: "10px", fontWeight: 600 }}
            >
              {err.all}
            </span>
          </Grid>
        </Grid>
      </div>
    </MainContainer>
  );
}

export default TopicEditForm;
