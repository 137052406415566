import React, { useEffect, useMemo, useState } from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableFooter,
  TableSortLabel,
  Menu,
  Modal,
  Typography,
  Select,
  FormControl,
  InputLabel,
} from "@mui/material";

import { SlOptionsVertical } from "react-icons/sl";
import { Box, Checkbox, MenuItem } from "@mui/material";
import { TbEdit } from "react-icons/tb";
import { AiOutlineEye } from "react-icons/ai";
import { RiDeleteBin6Line } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import { MdNotInterested } from "react-icons/md";
import { BiSortAlt2 } from "react-icons/bi";
import usePrivilegeCheck from "../../constants/customHooks/UsePrivilegeCheck";

import "./hrMain.css";
import { leaveApplicationStatusChangeAction } from "../../actions/employeeManagmentAction";
import PremissionDialog from "../customComponent/premissionDialog";
import TableNoItemComponent from "../customComponent/TableNoItemComponent";

// show role details popup
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "400",
  height: "400",
  //   overflow: "scroll",
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 20,
  p: 4,
};

export const EmployeeLeaveApplicationTable = ({
  columns,
  data,
  component,
  subMenu,
  //   isPresentMap,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // custom Hooks
  const { isStatusChangePrevilage } = usePrivilegeCheck();

  const [openAlert, setOpenAlert] = useState(false);

  // HandleClose dialog

  const handleCloseDialog = () => {
    setOpenAlert(false);
  };

  const [actionButtonShow, setActionButtonShow] = useState(null);
  const [tableId, setTableId] = useState("");
  const [open, setOpen] = useState(false);
  const [leaveReason, SetleaveReason] = useState([]);

  const openActionButton = Boolean(actionButtonShow);

  const handleClickAction = (event, id) => {
    setActionButtonShow(event.currentTarget);
    setTableId(id);
  };

  const handleCloseAction = () => {
    setActionButtonShow(null);
  };

  const handleChange = (e, id) => {
    if (isStatusChangePrevilage(subMenu)) {
      dispatch(leaveApplicationStatusChangeAction(id, e.target.value));
    } else {
      setOpenAlert(true);
    }
  };
  // Handle_View_icon

  let { hrEmployeeLeaveApplicationFindSuccess } = useSelector((state) => {
    return state.hrEmployeeLeaveApplicationFind;
  });
  console.log(hrEmployeeLeaveApplicationFindSuccess, "kitttiyallo");

  const handleOpen = (id) => {
    setOpen(true);
    const foundItem = hrEmployeeLeaveApplicationFindSuccess?.find(
      (item) => item.id === id
    );

    console.log(foundItem.leaves[0].leaveReason, "foundItemfoundItemfoundItem");
    if (foundItem) {
      SetleaveReason(foundItem.leaves[0].leaveReason);
    } else {
      console.log("item not found");
    }
  };

  const CustomSortIcon = ({ direction }) => {
    return direction === "asc" ? (
      <img
        src="/menuIcons/tableUpSort.png"
        className="administratCustomIcon"
        alt="gsl"
        style={{ padding: "15px" }}
      />
    ) : (
      <BiSortAlt2 className="administratCustomIcon" />
    );
  };

  // sorting table
  const [sortBy, setSortBy] = useState(""); // Current sort column
  const [sortOrder, setSortOrder] = useState("asc"); // Current sort order

  const requestSort = (column) => {
    let newSortOrder = "asc";

    if (column === sortBy && sortOrder === "asc") {
      newSortOrder = "desc";
    }

    setSortBy(column);
    setSortOrder(newSortOrder);
  };
  let sortedData = useMemo(() => {
    if (sortBy && data) {
      const sortedItems = [...data].sort((a, b) => {
        if (sortOrder === "asc") {
          return a[sortBy] < b[sortBy] ? -1 : 1;
        } else {
          return a[sortBy] < b[sortBy] ? 1 : -1;
        }
      });
      return sortedItems;
    }
    return data;
  }, [data, sortBy, sortOrder]);

  const handleClose = () => setOpen(false);

  // Create a function to handle selecting individual rows:

  // if (!hasPrivilege("isadministrationView") && privilege !== null) {
  //   return <div>You don't have permission to access this page.</div>;
  // }

  return (
    <>
      {sortedData.length > 0 ? (
        <Table className="hrTable">
          <TableHead>
            <TableRow className="hrTableCell">
              <TableCell>
                <Checkbox
                  // checked={selectedRows.length === sortedData.length}
                  // onChange={handleSelectAllRows}
                  style={{ color: "#f81b82" }}
                />
              </TableCell>
              {columns?.map((column) => {
                if (column.hidden) {
                  return null;
                }
                return (
                  <TableCell key={column.field} className="hrTableCell">
                    <TableSortLabel
                      className="hrHeaderText"
                      active={column === sortBy}
                      direction={sortOrder}
                      onClick={() => requestSort(column)}
                      IconComponent={
                        column.field !== "action" && CustomSortIcon
                      }
                    >
                      {column.title}
                    </TableSortLabel>
                  </TableCell>
                );
              })}
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedData?.map((item, indx) => (
              <TableRow className="hrTable" key={indx}>
                <TableCell>
                  <Checkbox
                    // checked={selectedRows.includes(item.id)}
                    // onChange={() => handleSelectRow(item.id)}
                    style={{ color: "#f81b82" }}
                  />
                </TableCell>
                <TableCell>{item.sl}</TableCell>
                <TableCell>{item.coloum1}</TableCell>
                <TableCell>{item.coloum2}</TableCell>
                <TableCell>{item.coloum3}</TableCell>
                <TableCell>{item.coloum4}</TableCell>
                <TableCell>{item.coloum5}</TableCell>
                <TableCell>{item.coloum6}</TableCell>
                <TableCell>
                  {" "}
                  <FormControl fullWidth>
                    <InputLabel
                      htmlFor="demo-simple-select-placeholder"
                      style={{
                        transform: "translate(10px, 10px)",
                        pointerEvents: "none",
                        zIndex: 1,
                        transition: "transform 0.25s, font-size 0.25s",
                        margin: "-6px",
                      }}
                    >
                      {item.coloum7 ? item.coloum7 : "placeholder"}
                    </InputLabel>
                    <Select
                      value={null}
                      onChange={(e) => handleChange(e, item.leaveId)}
                      inputProps={{
                        // name: {item.coloum7},
                        id: "demo-simple-select-placeholder",
                      }}
                      style={{
                        position: "relative",
                        width: "100px",
                        height: "30px",
                      }}
                    >
                      <MenuItem value="Pending">Pending</MenuItem>
                      <MenuItem value="Canceled">Cancel</MenuItem>
                      <MenuItem value="Approved">Approved</MenuItem>
                    </Select>
                  </FormControl>
                </TableCell>
                <TableCell>
                  <SlOptionsVertical
                    id="demo-positioned-button"
                    aria-controls={
                      openActionButton ? "demo-positioned-menu" : undefined
                    }
                    aria-haspopup="true"
                    aria-expanded={openActionButton ? "true" : undefined}
                    onClick={(e) => {
                      handleClickAction(e, item.id);
                    }}
                    variant="outlined"
                    style={{ marginLeft: "10px", color: "#F80B7A" }}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter />
        </Table>
      ) : (
        <TableNoItemComponent />
      )}
      {/* TABLE ACTION MENU START*/}
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={actionButtonShow}
        open={openActionButton}
        onClose={handleCloseAction}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        anchorOrigin={{
          vertical: "bottom", // Change from "top" to "bottom"
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top", // Change from "top" to "bottom"
          horizontal: "left",
        }}
        sx={{
          borderColor: "black",
          borderRadius: "24px",
          mt: 1.5,
          width: "auto",
          height: "auto",
        }}
      >
        <Box style={{ display: "flex", justifyContent: "flex-end" }}>
          <MenuItem>
            <AiOutlineEye
              onClick={() => handleOpen(tableId)}
              className="icons"
              style={{
                fontSize: "lg",
                width: "20px",
                height: "19px",
              }}
            />
          </MenuItem>
          {/* 
          {privilege?.isadministrationDelete !== false ? (
            // <MenuItem>
            //   {/* {component !=="holiday" ? (
            //     <RiDeleteBin6Line
            //       onClick={() => RoledeleteHandler(tableId)}
            //       className="icons"
            //       style={{
            //         fontSize: "lg",
            //         width: "20px",
            //         height: "19px",
            //       }}
            //     />
            //   ) : ( */}
          {/* <RiDeleteBin6Line
            //     onClick={() => {
            //       setActionButtonShow(null);
            //       Swal.fire({
            //         title: "Are you sure?",
            //         text: "You won't be able to revert this!",
            //         icon: "warning",
            //         showCancelButton: true,
            //         confirmButtonColor: "#3085d6",
            //         cancelButtonColor: "#d33",
            //         confirmButtonText: "Yes, delete it!",
            //       }).then((result) => {
            //         if (result.isConfirmed) {
            //           console.log("aravide");
            //           //   handleDeleteholidays(tableId);
            //           setActionButtonShow(false);
            //           Swal.fire(
            //             "Deleted!",
            //             "Your file has been deleted.",
            //             "success"
            //           );
            //         }
            //       });
            //     }}
            //     style={{
            //       fontSize: "lg",
            //       width: "20px",
            //       height: "19px",
            //     }}
            //     className="icons"
            //   /> */}
          {/* // </MenuItem> */}
          {/* ) : (
            <MdNotInterested
              className="icons"
              onClick={() => {
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "you don't have delete permission",
                });
              }}
            />
          )}  */}
        </Box>
      </Menu>
      {/* view modal */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ fontWeight: "bold" }}
          >
            Leave Reason:
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{
              mt: 2,
              width: 500,
              border: "1px solid #ECECEC",
              paddingBottom: "10rem",
              paddingLeft: "5px",
            }}
          >
            {leaveReason ? leaveReason : "Reason not speacified"}
          </Typography>
        </Box>
      </Modal>
      {openAlert && (
        <PremissionDialog
          openAlert={openAlert}
          handleClose={handleCloseDialog}
        />
      )}
    </>
  );
};
