import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { OutLinedButton, SubmitButton } from "../../customComponent/Buttons";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import {
  courseMasterCreateAction,
  courseMasterFindByIdAction,
  courseMasterUpdateAction,
} from "../../../actions/courseMasterAction";
import { successTrueMsgAction } from "../../../actions/globalAction";

function CourseMajorEdit({ handleClose, open, id }) {
  const dispatch = useDispatch();
  const [scroll, setScroll] = useState("paper");
  const [selectedFile, setSelectedFile] = useState(null);
  const [name, setName] = useState("");

  //   Response

  const { courseMajorFindOneSuccess, courseMajorFindOneErr } = useSelector(
    (state) => {
      return state.courseMajorMasterFindOne;
    }
  );

  //   Find added data

  useEffect(() => {
    dispatch(courseMasterFindByIdAction(id));
  }, []);

  //   Handle response

  useEffect(() => {
    if (courseMajorFindOneSuccess?.data) {
      setSelectedFile(courseMajorFindOneSuccess?.data.icon);
      setName(courseMajorFindOneSuccess?.data.majorName);
    }
  }, [courseMajorFindOneSuccess]);

  console.log(typeof selectedFile, "selectedFileselectedFile");

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setSelectedFile(selectedFile);
  };

  const getFileType = (file) => {
    const fileParts = file?.name?.split(".");
    return fileParts[fileParts?.length - 1].toLowerCase();
  };

  const handleCreate = () => {
    const formData = new FormData();
    formData.append("icon", selectedFile);
    formData.append("majorName", name);
    formData.append("id", id);
    dispatch(courseMasterUpdateAction(formData));
    dispatch(successTrueMsgAction(true));
    handleClose();
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        PaperProps={{
          sx: {
            width: "850px",
            marginTop: "4%",
          },
        }}
      >
        <DialogTitle id="scroll-dialog-title">
          {" "}
          <Typography
            variant="h5"
            sx={{
              mt: "18px",
              fontWeight: 700,
              fontSize: "24px",
              fontFamily: "Montserrat', sans-serif",
            }}
          >
            New Major
          </Typography>
        </DialogTitle>

        <DialogContent>
          <Box
            sx={{
              "& input": {
                border: "1px solid #ECECEC",
                padding: "16px",
                width: "100%",
                boxSizing: "border-box",
                color: "#05050F",
                outline: "none !important",
                borderRadius: "4px",
              },
              "& input::placeholder": {
                border: "#ECECEC",
              },
            }}
          >
            <Typography
              sx={{
                color: "#05050F",
                mb: "10px",
                fontSize: "16px",
                lineHeight: "24px",
              }}
            >
              Course major name
            </Typography>
            <input
              placeholder="Course major name"
              onChange={(e) => setName(e.target.value)}
              value={name}
            />
          </Box>
          <Box
            sx={{
              mt: "20px",
            }}
          >
            <Typography
              sx={{
                color: "#05050F",
                mb: "10px",
                fontSize: "16px",
                lineHeight: "24px",
              }}
            >
              Icon
            </Typography>

            <Box
              sx={{
                "& label": {
                  border: "2px dashed #ECECEC",
                  borderRadius: "4px",
                  height: "150px",
                  padding: "15px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                  position: "relative",
                },
              }}
            >
              {selectedFile ? (
                <Box
                  sx={{
                    border: "2px dashed #ECECEC",
                    borderRadius: "4px",
                    height: "150px",
                    padding: "15px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    position: "relative",
                  }}
                >
                  <Box
                    sx={{
                      textAlign: "center",
                      "& img": {
                        width: "37px",
                        height: "37px",
                      },
                    }}
                  >
                    <CloseIcon
                      sx={{
                        textAlign: "right",
                        position: "absolute",
                        top: "16px",
                        right: "16px",
                        color: "#ACB1C6",
                        cursor: "pointer",
                      }}
                      onClick={() => setSelectedFile("")}
                    />
                    {console.log(selectedFile)}
                    <img
                      src={
                        typeof selectedFile == "string"
                          ? selectedFile
                          : URL.createObjectURL(selectedFile)
                      }
                      alt="Selected SVG"
                    />
                    <Typography
                      sx={{
                        color: "#141E3C",
                        fontSize: "16px",
                        lineHeight: "24px",
                      }}
                    >
                      {selectedFile.name}
                    </Typography>
                    <Typography
                      sx={{
                        color: "#ACB1C6",
                        fontSize: "12px",
                        lineHeight: "24px",
                      }}
                    >
                      format :{" "}
                      {typeof selectedFile !== "string"
                        ? getFileType(selectedFile)
                        : name}
                      , size : 32*32px
                    </Typography>
                  </Box>
                </Box>
              ) : (
                <label>
                  <Box
                    sx={{
                      textAlign: "center",
                    }}
                  >
                    <input
                      type="file"
                      // ref={fileInputRef}
                      onChange={handleFileChange}
                      hidden
                      accept="image/svg+xml"
                    />
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M22 13C21.7348 13 21.4804 13.1054 21.2929 13.2929C21.1054 13.4804 21 13.7348 21 14V18.213C20.9992 18.9519 20.7053 19.6603 20.1828 20.1828C19.6603 20.7053 18.9519 20.9992 18.213 21H5.787C5.04809 20.9992 4.33966 20.7053 3.81717 20.1828C3.29468 19.6603 3.00079 18.9519 3 18.213V14C3 13.7348 2.89464 13.4804 2.70711 13.2929C2.51957 13.1054 2.26522 13 2 13C1.73478 13 1.48043 13.1054 1.29289 13.2929C1.10536 13.4804 1 13.7348 1 14V18.213C1.00132 19.4822 1.50609 20.699 2.40354 21.5965C3.30099 22.4939 4.51782 22.9987 5.787 23H18.213C19.4822 22.9987 20.699 22.4939 21.5965 21.5965C22.4939 20.699 22.9987 19.4822 23 18.213V14C23 13.7348 22.8946 13.4804 22.7071 13.2929C22.5196 13.1054 22.2652 13 22 13Z"
                        fill="#141E3C"
                      />
                      <path
                        d="M6.70643 8.70679L10.9994 4.41379V16.9998C10.9994 17.265 11.1048 17.5194 11.2923 17.7069C11.4799 17.8944 11.7342 17.9998 11.9994 17.9998C12.2646 17.9998 12.519 17.8944 12.7065 17.7069C12.8941 17.5194 12.9994 17.265 12.9994 16.9998V4.41379L17.2924 8.70679C17.481 8.88894 17.7336 8.98974 17.9958 8.98746C18.258 8.98518 18.5088 8.88001 18.6942 8.6946C18.8797 8.5092 18.9848 8.25838 18.9871 7.99619C18.9894 7.73399 18.8886 7.48139 18.7064 7.29279L12.7064 1.29279C12.5189 1.10532 12.2646 1 11.9994 1C11.7343 1 11.48 1.10532 11.2924 1.29279L5.29243 7.29279C5.11027 7.48139 5.00948 7.73399 5.01176 7.99619C5.01403 8.25838 5.1192 8.5092 5.30461 8.6946C5.49002 8.88001 5.74083 8.98518 6.00303 8.98746C6.26523 8.98974 6.51783 8.88894 6.70643 8.70679Z"
                        fill="#141E3C"
                      />
                    </svg>
                    <Typography
                      sx={{
                        color: "#141E3C",
                        fontSize: "16px",
                        lineHeight: "24px",
                      }}
                    >
                      {/* Drag and drop or{" "} */}
                      <Typography
                        component={"span"}
                        sx={{
                          color: "#FE0B7A",
                          fontWeight: "700",
                          fontSize: "16px",
                          lineHeight: "24px",
                        }}
                      >
                        choose file
                      </Typography>{" "}
                      to upload
                    </Typography>
                    <Typography
                      sx={{
                        color: "#ACB1C6",
                        fontSize: "12px",
                        lineHeight: "24px",
                      }}
                    >
                      format : svg, size : 32*32px
                    </Typography>
                  </Box>
                </label>
              )}
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <SubmitButton
            title="Update"
            submit=""
            widthSize="81px"
            heightSize="35px"
            handleSubmit={handleCreate}
            type="click"
          />
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default CourseMajorEdit;
