import {
  ADD_BONUS_EMPLOYEES_ERR,
  ADD_BONUS_EMPLOYEES_REQUEST,
  ADD_BONUS_EMPLOYEES_SUCCESS,
  APPLICATION_ALL_FIND_LEAD_ERR,
  APPLICATION_ALL_FIND_LEAD_REQUEST,
  APPLICATION_ALL_FIND_LEAD_SUCCESS,
  APPLICATION_DECLARATION_APPROVE_ERR,
  APPLICATION_DECLARATION_APPROVE_REQUEST,
  APPLICATION_DECLARATION_APPROVE_SUCCESS,
  APPLICATION_DEPOSITE_CREATE_ERR,
  APPLICATION_DEPOSITE_CREATE_REQUEST,
  APPLICATION_DEPOSITE_CREATE_SUCCESS,
  APPLICATION_DETAILS_FIND_ERR,
  APPLICATION_DETAILS_FIND_REQUEST,
  APPLICATION_DETAILS_FIND_SUCCESS,
  APPLICATION_FIND_BY_ID_LEAD_ERR,
  APPLICATION_FIND_BY_ID_LEAD_REQUEST,
  APPLICATION_FIND_BY_ID_LEAD_SUCCESS,
  APPLICATION_PAYMENTHISTORY_FIND_ERR,
  APPLICATION_PAYMENTHISTORY_FIND_REQUEST,
  APPLICATION_PAYMENTHISTORY_FIND_SUCCESS,
  FIND_ALL_ASIGNED_STAFF_ERR,
  FIND_ALL_ASIGNED_STAFF_REQUEST,
  FIND_ALL_ASIGNED_STAFF_SUCCESS,
  FIND_BONUS_EMPLOYEES_DETAILS_ERR,
  FIND_BONUS_EMPLOYEES_DETAILS_REQUEST,
  FIND_BONUS_EMPLOYEES_DETAILS_SUCCESS,
  GET_BONUS_EMPLOYEES_ERR,
  GET_BONUS_EMPLOYEES_REQUEST,
  GET_BONUS_EMPLOYEES_SUCCESS,
  GET_STAFF_INCENTIVE_AMOUNT_ERR,
  GET_STAFF_INCENTIVE_AMOUNT_REQUEST,
  GET_STAFF_INCENTIVE_AMOUNT_SUCCESS,
  PARTNER_TEAM_LEAD_ASSIGN_ERR,
  PARTNER_TEAM_LEAD_ASSIGN_REQUEST,
  PARTNER_TEAM_LEAD_ASSIGN_SUCCESS,
  STAFF_ADD_INCENTIVE_AMOUNT_ERR,
  STAFF_ADD_INCENTIVE_AMOUNT_REQUEST,
  STAFF_ADD_INCENTIVE_AMOUNT_SUCCESS,
  TEAM_LEAD_ASSIGN_STAFF_ERR,
  TEAM_LEAD_ASSIGN_STAFF_REQUEST,
  TEAM_LEAD_ASSIGN_STAFF_SUCCESS,
} from "../constants/applicationConstant";

// findAllLeadApplicationReducer
export const findAllLeadApplicationReducer = (state = {}, action) => {
  switch (action.type) {
    case APPLICATION_ALL_FIND_LEAD_REQUEST:
      return {
        ...state,
        applicationAllFindLoading: true,
      };
    case APPLICATION_ALL_FIND_LEAD_SUCCESS:
      return {
        ...state,
        applicationAllFindLoading: false,
        applicationAllFindSuccess: action.payload,
      };
    case APPLICATION_ALL_FIND_LEAD_ERR:
      return {
        ...state,
        applicationAllFindLoading: false,
        applicationAllFinderror: action.payload,
      };
    default:
      return state;
  }
};

// findByIdLeadApplicationReducer
export const findByIdLeadApplicationReducer = (state = {}, action) => {
  switch (action.type) {
    case APPLICATION_FIND_BY_ID_LEAD_REQUEST:
      return {
        ...state,
        applicationFindByIdLoading: true,
      };
    case APPLICATION_FIND_BY_ID_LEAD_SUCCESS:
      return {
        ...state,
        applicationFindByIdLoading: false,
        applicationFindByIdSuccess: action.payload,
      };
    case APPLICATION_FIND_BY_ID_LEAD_ERR:
      return {
        ...state,
        applicationFindByIdLoading: false,
        applicationFindByIderror: action.payload,
      };
    default:
      return state;
  }
};

// findAllAsignedStaffReducer
export const findAllAsignedStaffReducer = (state = {}, action) => {
  switch (action.type) {
    case FIND_ALL_ASIGNED_STAFF_REQUEST:
      return {
        ...state,
        asignedStafFindLoading: true,
      };
    case FIND_ALL_ASIGNED_STAFF_SUCCESS:
      return {
        ...state,
        asignedStafFindLoading: false,
        asignedStafFindSuccess: action.payload,
      };
    case FIND_ALL_ASIGNED_STAFF_ERR:
      return {
        ...state,
        asignedStafFindLoading: false,
        asignedStafFinderror: action.payload,
      };
    default:
      return state;
  }
};

// staffIncentiveAmmountAddReducer
export const staffIncentiveAmmountAddReducer = (state = {}, action) => {
  switch (action.type) {
    case STAFF_ADD_INCENTIVE_AMOUNT_REQUEST:
      return {
        ...state,
        addStaffIncentiveLoading: true,
      };
    case STAFF_ADD_INCENTIVE_AMOUNT_SUCCESS:
      return {
        ...state,
        addStaffIncentiveLoading: false,
        addStaffIncentiveSuccess: action.payload,
      };
    case STAFF_ADD_INCENTIVE_AMOUNT_ERR:
      return {
        ...state,
        addStaffIncentiveLoading: false,
        addStaffIncentiveerror: action.payload,
      };
    default:
      return state;
  }
};

// findStaffIncentiveReducer
export const findStaffIncentiveReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_STAFF_INCENTIVE_AMOUNT_REQUEST:
      return {
        ...state,
        findStaffIncentiveLoading: true,
      };
    case GET_STAFF_INCENTIVE_AMOUNT_SUCCESS:
      return {
        ...state,
        findStaffIncentiveLoading: false,
        findStaffIncentiveSuccess: action.payload,
      };
    case GET_STAFF_INCENTIVE_AMOUNT_ERR:
      return {
        ...state,
        findStaffIncentiveLoading: false,
        findStaffIncentiveerror: action.payload,
      };
    default:
      return state;
  }
};

// listBonusEndrolledAppEmployeeReducer
export const listBonusEndrolledAppEmployeeReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_BONUS_EMPLOYEES_REQUEST:
      return {
        ...state,
        bonusEmployeesLoading: true,
      };
    case GET_BONUS_EMPLOYEES_SUCCESS:
      return {
        ...state,
        bonusEmployeesLoading: false,
        bonusEmployeesSuccess: action.payload,
      };
    case GET_BONUS_EMPLOYEES_ERR:
      return {
        ...state,
        bonusEmployeesLoading: false,
        bonusEmployeeserror: action.payload,
      };
    default:
      return state;
  }
};

// AddEmployeeBonusReducer
export const AddEmployeeBonusReducer = (state = {}, action) => {
  switch (action.type) {
    case ADD_BONUS_EMPLOYEES_REQUEST:
      return {
        ...state,
        addBonusEmployeesLoading: true,
      };
    case ADD_BONUS_EMPLOYEES_SUCCESS:
      return {
        ...state,
        addBonusEmployeesLoading: false,
        addBonusEmployeesSuccess: action.payload,
      };
    case ADD_BONUS_EMPLOYEES_ERR:
      return {
        ...state,
        addBonusEmployeesLoading: false,
        addBonusEmployeeserror: action.payload,
      };
    default:
      return state;
  }
};

// findBonusDetailsReducer
export const findBonusDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case FIND_BONUS_EMPLOYEES_DETAILS_REQUEST:
      return {
        ...state,
        findBonusEmployeesLoading: true,
      };
    case FIND_BONUS_EMPLOYEES_DETAILS_SUCCESS:
      return {
        ...state,
        findBonusEmployeesLoading: false,
        findBonusEmployeesSuccess: action.payload,
      };
    case FIND_BONUS_EMPLOYEES_DETAILS_ERR:
      return {
        ...state,
        findBonusEmployeesLoading: false,
        findBonusEmployeeserror: action.payload,
      };
    default:
      return state;
  }
};

// partnerManagerChangeReducer
export const partnerManagerChangeReducer = (state = {}, action) => {
  switch (action.type) {
    case PARTNER_TEAM_LEAD_ASSIGN_REQUEST:
      return {
        ...state,
        partnerTeamLeadAssignLoading: true,
      };
    case PARTNER_TEAM_LEAD_ASSIGN_SUCCESS:
      return {
        ...state,
        partnerTeamLeadAssignLoading: false,
        partnerTeamLeadAssignSuccess: action.payload,
      };
    case PARTNER_TEAM_LEAD_ASSIGN_ERR:
      return {
        ...state,
        partnerTeamLeadAssignLoading: false,
        partnerTeamLeadAssignerror: action.payload,
      };
    default:
      return state;
  }
};

// teamLeadAssignStaffChangeReducer
export const teamLeadAssignStaffChangeReducer = (state = {}, action) => {
  switch (action.type) {
    case TEAM_LEAD_ASSIGN_STAFF_REQUEST:
      return {
        ...state,
        teamLeadAssignEmployeeLoading: true,
      };
    case TEAM_LEAD_ASSIGN_STAFF_SUCCESS:
      return {
        ...state,
        teamLeadAssignEmployeeLoading: false,
        teamLeadAssignEmployeeSuccess: action.payload,
      };
    case TEAM_LEAD_ASSIGN_STAFF_ERR:
      return {
        ...state,
        teamLeadAssignEmployeeLoading: false,
        teamLeadAssignEmployeeerror: action.payload,
      };
    default:
      return state;
  }
};

// findApplicationDetailsReducer
export const findApplicationDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case APPLICATION_DETAILS_FIND_REQUEST:
      return {
        ...state,
        applicationDetailsFindLoading: true,
      };
    case APPLICATION_DETAILS_FIND_SUCCESS:
      return {
        ...state,
        applicationDetailsFindLoading: false,
        applicationDetailsFindSuccess: action.payload,
      };
    case APPLICATION_DETAILS_FIND_ERR:
      return {
        ...state,
        applicationDetailsFindLoading: false,
        applicationDetailsFindError: action.payload,
      };
    default:
      return state;
  }
};

// findApplicationDeclarationReducer

export const applicationDeclarationApproveReducer = (state = {}, action) => {
  switch (action.type) {
    case APPLICATION_DECLARATION_APPROVE_REQUEST:
      return {
        ...state,
        applicationDeclarationApproveLoading: true,
      };
    case APPLICATION_DECLARATION_APPROVE_SUCCESS:
      return {
        ...state,
        applicationDeclarationApproveLoading: false,
        applicationDeclarationApproveSuccess: action.payload,
      };
    case APPLICATION_DECLARATION_APPROVE_ERR:
      return {
        ...state,
        applicationDeclarationApproveLoading: false,
        applicationDeclarationApproveError: action.payload,
      };
    default:
      return state;
  }
};


// applicationDepositeCreateReducer

export const applicationDepositeCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case APPLICATION_DEPOSITE_CREATE_REQUEST:
      return {
        ...state,
        applicationDepositeCreateLoading: true,
      };
    case APPLICATION_DEPOSITE_CREATE_SUCCESS:
      return {
        ...state,
        applicationDepositeCreateLoading: false,
        applicationDepositeCreateSuccess: action.payload,
      };
    case APPLICATION_DEPOSITE_CREATE_ERR:
      return {
        ...state,
        applicationDepositeCreateLoading: false,
        applicationDepositeCreateError: action.payload,
      };
    default:
      return state;
  }
};



// applicationPaymentHistoryReducer

export const applicationPaymenthistoryFindReducer = (state = {}, action) => {
  switch (action.type) {
    case APPLICATION_PAYMENTHISTORY_FIND_REQUEST:
      return {
        ...state,
        applicationPaymenthistoryFindLoading: true,
      };
    case APPLICATION_PAYMENTHISTORY_FIND_SUCCESS:
      return {
        ...state,
        applicationPaymenthistoryFindLoading: false,
        applicationPaymenthistoryFindSuccess: action.payload,
      };
    case APPLICATION_PAYMENTHISTORY_FIND_ERR:
      return {
        ...state,
        applicationPaymenthistoryFindLoading: false,
        applicationPaymenthistoryFindError: action.payload,
      };
    default:
      return state;
  }
};
