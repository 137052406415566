import React, { useEffect, useState } from "react";
import {
  Alert,
  AlertTitle,
  Box,
  Checkbox,
  Grid,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";

import { useDispatch, useSelector } from "react-redux";

import usePrivilegeCheck from "../../../constants/customHooks/UsePrivilegeCheck";

import { findNewGalleryItemsAction } from "../../../actions/LandingPageAction";
import GalleryTable from "./GalleryTable";

function GalleryMain(props) {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const { privilege, hasPrivilege } = usePrivilegeCheck();

  let { deleteGalleryItemsByIdSuccess } = useSelector((state) => {
    return state.deleteGalleryItemsById;
  });

  let { findNewGalleryItemsSuccess } = useSelector((state) => {
    return state.findNewGalleryItems;
  });

  useEffect(() => {
    if (findNewGalleryItemsSuccess) {
        console.log(findNewGalleryItemsSuccess);
      setData(findNewGalleryItemsSuccess.data);
    }
  }, [findNewGalleryItemsSuccess]);

  useEffect(() => {
    dispatch(findNewGalleryItemsAction());
  }, [deleteGalleryItemsByIdSuccess]);

  let columns = [
    {
      title: "SL NO",
      field: "SL NO",
    },
    {
      title: "Picture",
      field: "Image",
    },
    {
      title: "Title",
      field: "title",
    },
    {
      title: "discription",
      field: "Content",
    },
    {
      title: "",
      field: "action",
      filtering: false,
    },
  ];

  if (!hasPrivilege("testmonials") && privilege !== null) {
    return (
      <Alert severity="error">
        <AlertTitle>warning</AlertTitle>
        You don't have permission to access — <strong>this page.!</strong>
      </Alert>
    );
  }

  return (
    <Box>
      <GalleryTable data={data} columns={columns} />
    </Box>
  );
}

export default GalleryMain;
