import React, { useEffect, useState } from "react";
import { InfoHeader } from "../../customComponent/InfoHeader";
import { Box, FormLabel, Grid, Typography } from "@mui/material";
import { MainContainer } from "../../content/content.element";
import InputField from "../../customComponent/InputField";
import { SubmitButton } from "../../customComponent/Buttons";

import SelectDayAndSlots from "./IeltesCutomComponents/SelectDayAndSlots";
import SelectTeacher from "./IeltesCutomComponents/SelectTeacher";
import { useDispatch, useSelector } from "react-redux";
import { ieltsCenterManagmentCenterCreateAction } from "../../../actions/IeltsActions/centerManagmentAction";
import Swal from "sweetalert2";
import { successTrueMsgAction } from "../../../actions/globalAction";

function CenterTableAddNewForm({ toggle }) {
  const dispatch = useDispatch();
  const [selectedTeacher, setSelectedTeacher] = useState([]);
  const [formData, setFormData] = useState({
    name: "",
    location: "",
    center:[],
    selectedTeacher: [],
  });

  const [err, setErr] = useState({
    name: "",
    location: "",
    center: "",
    selectedTeacher: "",
  });

  // Success message

  let { successAlertMsgSuccess } = useSelector((state) => {
    return state.successTrueMsg;
  });


  let {
    ieltsCenterManagmentCenterCreateSuccess,
    ieltsCenterManagmentCenterCreateerror,
  } = useSelector((state) => {
    return state.ieltsCenterManagmentCenterCreate;
  });

  useEffect(() => {
    if (
      successAlertMsgSuccess &&
      ieltsCenterManagmentCenterCreateSuccess?.status == true
    ) {
      Swal.fire(
        "Created",
        ieltsCenterManagmentCenterCreateSuccess.message,
        "success"
      );
      dispatch(successTrueMsgAction(false));
    }
    if (
      successAlertMsgSuccess &&
      ieltsCenterManagmentCenterCreateSuccess?.status == false
    ) {
      Swal.fire(
        "Error",
        ieltsCenterManagmentCenterCreateSuccess.message,
        "warning"
      );
      dispatch(successTrueMsgAction(false));
    }
  }, [ieltsCenterManagmentCenterCreateSuccess, successAlertMsgSuccess]);

  const validateForm = () => {
    let isValid = true;
    const newErr = { ...err };

    if (formData.name.trim() === "") {
      newErr.name = "Name cannot be empty";
      isValid = false;
    } else {
      newErr.name = "";
    }

    if (formData.location.trim() === "") {
      newErr.location = "Location cannot be empty";
      isValid = false;
    } else {
      newErr.location = "";
    }

    if (formData.center.length === 0) {
      newErr.center = "Select at least one time slot";
      isValid = false;
    } else {
      newErr.center = "";
    }

    if (formData.selectedTeacher.length === 0) {
      newErr.selectedTeacher = "Select at least one teacher";
      isValid = false;
    } else {
      newErr.selectedTeacher = "";
    }

    setErr(newErr);
    return isValid;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
    setErr((prev) => ({
      ...prev,
      [name]: "",
    }));
  };

  const handleAddNewButton = () => {
    if (validateForm()) {
      console.log(formData, "FormDataaaa");
      dispatch(ieltsCenterManagmentCenterCreateAction(formData));
      dispatch(successTrueMsgAction(true));
      setFormData({ name: "", location: "", center: [], selectedTeacher: [] });
      setSelectedTeacher([]);
    } else {
      // alert("Form is invalid, please check errors");
      console.log("Form is invalid, please check errors");
    }
  };

  console.log(formData, "sndksfdf")

  return (
    <MainContainer active={toggle}>
      <InfoHeader headTitle="Center" url="/IELTS" />

      <div style={{ marginTop: "30px" }}>
        <div style={{ maxWidth: "1194px" }}>
          <Grid
            item
            lg={4}
            xs={12}
            sx={{
              "& .input-group": {
                mb: "44px",
                mt: "0",
              },
            }}
          >
            <InputField
              label="Name"
              handleChange={handleChange}
              value={formData?.name}
              widthSize="100%"
              required
              paramses
              InputType="text"
              name={"name"}
              dispcriptionHeight
            />
            {err.name ? (
              <Typography
                sx={{
                  mb: "44px",
                  mt: "-34px",
                  color: "red",
                  fontWeight: 400,
                  fontSize: "12px",
                }}
              >
                {err.name}
              </Typography>
            ) : (
              ""
            )}
          </Grid>
          <Grid
            item
            lg={4}
            xs={12}
            sx={{
              "& .input-group": {
                mb: "44px",
                mt: "0",
              },
            }}
          >
            <InputField
              label="Location"
              handleChange={handleChange}
              value={formData?.location}
              widthSize="100%"
              required
              paramses
              InputType="text"
              dispcriptionHeight
              name={"location"}
            />
            {err.location ? (
              <Typography
                sx={{
                  mb: "44px",
                  mt: "-34px",
                  color: "red",
                  fontWeight: 400,
                  fontSize: "12px",
                }}
              >
                {err.location}
              </Typography>
            ) : (
              ""
            )}
          </Grid>
          {formData.name !== "" && (
            <SelectDayAndSlots
              name={formData?.name}
              handlecenter={setFormData}
              handleError={setErr}
            />
          )}
          {formData.name !== "" && err.center ? (
            <Typography
              sx={{
                // mb: "44px",
                // mt: "-10px",
                color: "red",
                fontWeight: 400,
                fontSize: "12px",
              }}
            >
              {err.center}
            </Typography>
          ) : (
            ""
          )}
        </div>

        <Box
          sx={{
            mt: "44px",
          }}
        >
          <SelectTeacher
            setFormData={setFormData}
            handleError={setErr}
            setSelectedTeacher={setSelectedTeacher}
            selectedTeacher={selectedTeacher}
          />
          {err.selectedTeacher ? (
            <Typography
              sx={{
                mt: "8px",
                color: "red",
                fontWeight: 400,
                fontSize: "12px",
              }}
            >
              {err.selectedTeacher}
            </Typography>
          ) : (
            ""
          )}
        </Box>

        <Box
          sx={{
            mt: "44px",
            mb: "30px",
          }}
        >
          <SubmitButton
            title="Save"
            submit=""
            widthSize="123px"
            heightSize="38px"
            type="click"
            handleSubmit={handleAddNewButton}
          />
        </Box>
      </div>
    </MainContainer>
  );
}

export default CenterTableAddNewForm;
