// SUCCESS MSG ALERT

import {
  SUCCESS_TRUE_MSG_ERR,
  SUCCESS_TRUE_MSG_REQUEST,
  SUCCESS_TRUE_MSG_SUCCESS,
} from "../constants/roleManagmentConstant";

// successTrueMsgAction
export const successTrueMsgAction = (status) => async (dispatch, getState) => {
  try {
    // let isUserExist = localStorage.getItem("loginInfo")
    // ? JSON.parse(localStorage.getItem("loginInfo"))
    // : null;

    dispatch({ type: SUCCESS_TRUE_MSG_REQUEST });

    dispatch({ type: SUCCESS_TRUE_MSG_SUCCESS, payload: status });
  } catch (error) {
    dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: false});
  }
};
