import React from "react";

function ReportIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.875 0H7.125C6.21975 0 5.46225 0.645375 5.28787 1.5H4.875C3.42788 1.5 2.25 2.6775 2.25 4.125V21.375C2.25 22.8225 3.42788 24 4.875 24H18C19.4471 24 20.625 22.8225 20.625 21.375V20.841C21.2861 20.5508 21.75 19.8919 21.75 19.125V1.875C21.75 0.841125 20.9089 0 19.875 0ZM6.75 1.875C6.75 1.66838 6.918 1.5 7.125 1.5H19.875C20.082 1.5 20.25 1.66838 20.25 1.875V19.125C20.25 19.3316 20.082 19.5 19.875 19.5H7.125C6.918 19.5 6.75 19.3316 6.75 19.125V1.875ZM18 22.5H4.875C4.25475 22.5 3.75 21.9953 3.75 21.375V4.125C3.75 3.50475 4.25475 3 4.875 3H5.25V19.125C5.25 20.1589 6.09113 21 7.125 21H19.125V21.375C19.125 21.9953 18.6203 22.5 18 22.5Z"
        fill="#ACB1C6"
      />
      <path
        d="M9.00003 8.24979C9.19203 8.24979 9.38365 8.17667 9.53028 8.03004L12 5.56067L14.0948 7.65504C14.3877 7.94792 14.8624 7.94792 15.1553 7.65504L16.875 5.93529L17.0948 6.15504C17.2377 6.29792 17.4293 6.37479 17.625 6.37479C17.6974 6.37479 17.7709 6.36429 17.8422 6.34254C18.1069 6.26267 18.306 6.04329 18.3604 5.77179L18.7354 3.89679C18.7849 3.65079 18.7077 3.39692 18.5303 3.21954C18.3525 3.04217 18.0994 2.96567 17.8527 3.01442L15.9777 3.38942C15.7065 3.44379 15.4872 3.64254 15.4073 3.90729C15.3274 4.17204 15.3994 4.45929 15.5948 4.65504L15.8145 4.87479L14.625 6.06392L12.5303 3.96954C12.2374 3.67667 11.7627 3.67667 11.4698 3.96954L8.46978 6.96954C8.1769 7.26242 8.1769 7.73717 8.46978 8.03004C8.6164 8.17667 8.80803 8.24979 9.00003 8.24979Z"
        fill="#ACB1C6"
      />
      <path
        d="M9 11.25H18C18.414 11.25 18.75 10.914 18.75 10.5C18.75 10.086 18.414 9.75 18 9.75H9C8.586 9.75 8.25 10.086 8.25 10.5C8.25 10.914 8.586 11.25 9 11.25Z"
        fill="#ACB1C6"
      />
      <path
        d="M18 13.5H9.375C8.961 13.5 8.625 13.836 8.625 14.25C8.625 14.664 8.961 15 9.375 15H18C18.414 15 18.75 14.664 18.75 14.25C18.75 13.836 18.414 13.5 18 13.5Z"
        fill="#ACB1C6"
      />
      <path
        d="M13.875 16.5H9.375C8.961 16.5 8.625 16.836 8.625 17.25C8.625 17.664 8.961 18 9.375 18H13.875C14.289 18 14.625 17.664 14.625 17.25C14.625 16.836 14.289 16.5 13.875 16.5Z"
        fill="#ACB1C6"
      />
    </svg>
  );
}

export default ReportIcon;
