import { Box, Typography } from '@mui/material';
import React from 'react'

function CommissonCard({Data}) {

  return (
    <Box
      sx={{
        display: "flex",
        gap: "24px",
        alignItems: "center",
        "& img": {
          width: "100px",
          height: "100px",
        },
      }}
    >
      <img src={Data.img} alt="" />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "24px",
        }}
      >
        <Box>
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: "400",
              color: "#ACB1C6",
              lineHeight: "24px",
              maxWidth:"77px",
            }}
          >
            {Data.Title}
          </Typography>
          <Typography
            sx={{
              fontSize: "20px",
              fontWeight: "700",
              mt: "8px",
              color: "#000",
              lineHeight: "24px",
            }}
          >
            {"₹ " + Data.Count}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default CommissonCard