import React, { useEffect, useState } from "react";
// import { MainContainer } from "../../content/content.element";
import {
  Box,
  Checkbox,
  Chip,
  Collapse,
  FormControlLabel,
  FormGroup,
  List,
  ListItemButton,
  ListSubheader,
  Typography,
} from "@mui/material";
import { MdClear } from "react-icons/md";

import { useDispatch, useSelector } from "react-redux";
import DownloadPdf from "../../../extraComponents/DownloadPdf";
import DownloadExcel from "../../../extraComponents/DownloadExcel";
import AssignedStudentTable from "./AssignedStudentTable";
import { ieltsTeacherManagmentAssignedStudentsAction } from "../../../../actions/IeltsActions/teacherManagmentAction";
import { useParams } from "react-router-dom";
import AssignedStudentFilter from "./AssignedStudentSearchFilter";

function AssignedStudentsMain() {
  const { id } = useParams();

  const dispatch = useDispatch();

  let { ieltsTeacherManagmentAssignedStudentsSuccess } = useSelector(
    (state) => {
      return state.ieltsTeacherManagmentAssignedStudents;
    }
  );

  useEffect(() => {
    if (ieltsTeacherManagmentAssignedStudentsSuccess) {
      let filter = ieltsTeacherManagmentAssignedStudentsSuccess?.data?.filter(
        (item) => item?.payment?.toLowerCase() === "paid"
      );
      console.log(filter);
      setAssignedStudentsData(filter);
      setData(filter);

      const uniqueCourseNames = [
        ...new Set(
          ieltsTeacherManagmentAssignedStudentsSuccess?.data?.map(
            (item) => item?.ielts_course?.CourseName
          )
        ),
      ];
      setCourse(uniqueCourseNames);
    }
  }, [ieltsTeacherManagmentAssignedStudentsSuccess]);

  useEffect(() => {
    dispatch(ieltsTeacherManagmentAssignedStudentsAction(id));
  }, [dispatch]);

  const [selectedCourse, setSelectedCourse] = useState("");
  const [openCourse, setCourseOpen] = useState(false);
  const [course, setCourse] = useState();

  const [selectedGender, setSelectedGender] = useState("");
  const [openGender, setGenderOpen] = useState(false);

  const [data, setData] = useState("");
  const [AssignedStudentsData, setAssignedStudentsData] = useState("");

  const [columnVisibility, setColumnVisibility] = useState([
    { title: "SL NO", value: true },
    { title: "NAME", value: true },
    { title: "DOB", value: true },
    { title: "GENDER", value: true },
    { title: "EMAIL", value: true },
    { title: "PHONE", value: true },
    { title: "WHATSAPP NO:", value: true },
    { title: "COURSE", value: true },
  ]);

  // handleFilter function

  const handleFilterFunction = (filterValue, checkedStatus, type) => {
    handleClearAllFunction();

    if (type === "course") {
      setSelectedCourse(filterValue === selectedCourse ? null : filterValue);
      if (checkedStatus) {
        setAssignedStudentsData(
          data?.filter(
            (item) =>
              item?.ielts_course?.CourseName?.toLowerCase() ===
              filterValue?.toLowerCase()
          )
        );
      } else {
        setAssignedStudentsData(data);
      }
    } else if (type === "gender") {
      setSelectedGender(filterValue === selectedGender ? null : filterValue);
      if (checkedStatus) {
        setAssignedStudentsData(
          data?.filter(
            (item) =>
              item?.ielts_entrolled_student?.gender?.toLowerCase() ===
              filterValue?.toLowerCase()
          )
        );
      } else {
        setAssignedStudentsData(data);
      }
    } else {
      setAssignedStudentsData(data);
    }
  };

  // column visibility

  const handleColumnVisibility = (e) => {
    const { name, checked } = e.target;
    setColumnVisibility((prevColumns) =>
      prevColumns.map((column) =>
        column.title === name ? { ...column, value: !checked } : column
      )
    );
  };

  // Handle ClearAll function

  const handleClearAllFunction = () => {
    setSelectedGender("");
    setSelectedCourse("");
  };

  const AssignedStudents = [
    { title: "SL NO", field: "sl" },
    { title: "NAME", field: "name" },
    { title: "DOB", field: "location" },
    { title: "GENDER", field: "gender" },
    { title: "EMAIL", field: "email" },
    { title: "PHONE", field: "phone" },
    { title: "WHATSAPP NO:", field: "whatsapp" },
    { title: "COURSE", field: "course" },
  ];

  const gender = ["Male", "Female"];

  const [ids, setIds] = useState([]);
  const selectBoxIdPassHandler = (ids) => {
    setIds(ids);
  };

  const searchHandleChange = (e) => {
    handleClearAllFunction();
    const { value } = e.target;
    if (value.length > 0) {
      setAssignedStudentsData(
        data?.filter((item) =>
          item?.ielts_entrolled_student?.fullName?.toLowerCase().includes(value)
        )
      );
    } else {
      setAssignedStudentsData(data);
    }
  };

  // Excel export function
  const excelDownloadClick = () => {
    DownloadExcel(
      AssignedStudents,
      AssignedStudentsData,
      "AssignedStudentList"
    );
  };

  // Pdf Export function

  const pdfDownloadClick = () => {
    DownloadPdf(AssignedStudents, "AssignedStudentList", "AssignedStudents");
  };




  const [page, setPage] = useState(10);
  const [pageError, setPageError] = useState("");

  const handlePageSearch = (e) => {
    const { value } = e.target;
    if (value >= 0) {
      setPage(value);
    }
    if (value >= 10) {
      setPageError("");
    } else {
      setPageError("please choose a value greater than 10");
    }
  };


  return (
    <Box>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: {
            xs: "1fr",
            lg: "330px 1fr",
          },
          gap: "25px",
        }}
      >
        <Box>
          <Box
            sx={{
              display: "flex",
              gap: "10px",
              flexWrap: "wrap",
              mb: "20px",
            }}
          >
            {selectedCourse && (
              <Chip
                sx={{
                  "&.MuiChip-root": {
                    pl: "5px",
                    pr: "5px",
                  },
                }}
                label={
                  <Typography
                    sx={{
                      fontSize: "10px",
                      fontWeight: "700",
                      color: "rgba(88, 94, 106, 1)",
                    }}
                  >
                    Course:{" "}
                    <Typography
                      sx={{
                        fontSize: "10px",
                        fontWeight: "400",
                      }}
                      component={"span"}
                    >
                      {selectedCourse}
                    </Typography>
                  </Typography>
                }
                onDelete={() => setSelectedCourse(null)}
              />
            )}

            {selectedGender && (
              <Chip
                sx={{
                  "&.MuiChip-root": {
                    pl: "5px",
                    pr: "5px",
                  },
                }}
                label={
                  <Typography
                    sx={{
                      fontSize: "10px",
                      fontWeight: "700",
                      color: "rgba(88, 94, 106, 1)",
                    }}
                  >
                    Gender:{" "}
                    <Typography
                      sx={{
                        fontSize: "10px",
                        fontWeight: "400",
                      }}
                      component={"span"}
                    >
                      {selectedGender}
                    </Typography>
                  </Typography>
                }
                onDelete={() => setSelectedGender(null)}
              />
            )}
          </Box>
          <Box
            sx={{
              " & .MuiListSubheader-root": {
                backgroundColor: "#F2F2F2",
              },
            }}
          >
            <List
              component="nav"
              aria-labelledby="nested-list-subheader"
              subheader={
                <ListSubheader component="div" id="nested-list-subheader">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <div>Filter</div>
                    <div
                      style={{
                        display: "flex",
                        marginTop: "12px",
                        cursor: "pointer",
                      }}
                      //   onClick={handleClearClick}
                    >
                      <MdClear style={{ fontSize: "22px", height: "20px" }} />{" "}
                      <Typography onClick={handleClearAllFunction}>
                        Clear All
                      </Typography>
                    </div>
                  </div>
                </ListSubheader>
              }
            >
              <ListItemButton
                onClick={() => setCourseOpen(!openCourse)}
                sx={{
                  mt: "20px",
                  fontWeight: "700",
                  borderBottom: "1px solid rgba(236, 236, 236, 1)",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                Course
                <svg
                  width="13"
                  height="7"
                  viewBox="0 0 13 7"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.30059 6.20042C5.83685 6.20042 5.37312 6.02042 5.02201 5.66708L0.702682 1.32042C0.510564 1.12708 0.510564 0.807083 0.702682 0.61375C0.894799 0.420417 1.21279 0.420417 1.4049 0.61375L5.72423 4.96042C6.04222 5.28042 6.55895 5.28042 6.87694 4.96042L11.1963 0.61375C11.3884 0.420417 11.7064 0.420417 11.8985 0.61375C12.0906 0.807083 12.0906 1.12708 11.8985 1.32042L7.57916 5.66708C7.22805 6.02042 6.76432 6.20042 6.30059 6.20042Z"
                    fill="#05050F"
                  />
                </svg>
              </ListItemButton>

              <Collapse in={openCourse} timeout="auto" unmountOnExit sx={{}}>
                <List component="div" disablePadding>
                  <ListItemButton>
                    <FormGroup
                      sx={{
                        "& .MuiFormControlLabel-root": {
                          width: "100%",
                        },
                        width: "100%",
                      }}
                    >
                      {course?.length > 0 &&
                        course?.map((item, i) => (
                          <FormControlLabel
                            key={i}
                            control={
                              <Checkbox
                                sx={{
                                  color: "#F8469A",
                                  "&.Mui-checked": {
                                    color: "#F8469A",
                                  },
                                }}
                                checked={item === selectedCourse}
                                onChange={(e) => {
                                  handleFilterFunction(
                                    item,
                                    e.target.checked,
                                    "course"
                                  );
                                }}
                              />
                            }
                            label={item}
                          />
                        ))}
                    </FormGroup>
                  </ListItemButton>
                </List>
              </Collapse>

              <ListItemButton
                onClick={() => setGenderOpen(!openGender)}
                sx={{
                  fontWeight: "700",
                  borderBottom: "1px solid rgba(236, 236, 236, 1)",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                Gender
                <svg
                  width="13"
                  height="7"
                  viewBox="0 0 13 7"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.30059 6.20042C5.83685 6.20042 5.37312 6.02042 5.02201 5.66708L0.702682 1.32042C0.510564 1.12708 0.510564 0.807083 0.702682 0.61375C0.894799 0.420417 1.21279 0.420417 1.4049 0.61375L5.72423 4.96042C6.04222 5.28042 6.55895 5.28042 6.87694 4.96042L11.1963 0.61375C11.3884 0.420417 11.7064 0.420417 11.8985 0.61375C12.0906 0.807083 12.0906 1.12708 11.8985 1.32042L7.57916 5.66708C7.22805 6.02042 6.76432 6.20042 6.30059 6.20042Z"
                    fill="#05050F"
                  />
                </svg>
              </ListItemButton>

              <Collapse in={openGender} timeout="auto" unmountOnExit sx={{}}>
                <List component="div" disablePadding>
                  <ListItemButton>
                    <FormGroup
                      sx={{
                        "& .MuiFormControlLabel-root": {
                          width: "100%",
                        },
                        width: "100%",
                      }}
                    >
                      {gender &&
                        gender?.map((item, i) => (
                          <FormControlLabel
                            key={i}
                            control={
                              <Checkbox
                                sx={{
                                  color: "#F8469A",
                                  "&.Mui-checked": {
                                    color: "#F8469A",
                                  },
                                }}
                                checked={item === selectedGender}
                                onChange={(e) => {
                                  handleFilterFunction(
                                    item,
                                    e.target.checked,
                                    "gender"
                                  );
                                }}
                              />
                            }
                            label={item}
                          />
                        ))}
                    </FormGroup>
                  </ListItemButton>
                </List>
              </Collapse>

              <Box
                sx={{
                  display: "flex",
                  gap: "10px",
                  alignItems: "center",
                  mt: "20px",
                  "& input": {
                    width: "30px",
                    outline: "none",
                  },
                }}
              >
                <Typography
                  sx={{
                    fontSize: 12,
                  }}
                >
                  Rows Per Page :{" "}
                </Typography>
                <input type="number" value={page} onChange={handlePageSearch} />
              </Box>
              {pageError && (
                <Typography
                  sx={{
                    fontSize: 10,
                    color: "red",
                  }}
                >
                  {pageError}
                </Typography>
              )}
            </List>
          </Box>
        </Box>
        <Box
          sx={{
            overflow: "hidden",
          }}
        >
          <AssignedStudentFilter
            columnVisibility={columnVisibility}
            columnVisibleHandler={handleColumnVisibility}
            searchHandleChange={searchHandleChange}
            excelDownloadClick={excelDownloadClick}
            pdfDownloadClick={pdfDownloadClick}
          />

          <AssignedStudentTable
            columnVisibility={columnVisibility}
            data={AssignedStudentsData}
            columns={AssignedStudents}
            component="ieltsCategory"
            selectBoxIdPassHandler={selectBoxIdPassHandler}
            pageNumber={page}  
          />
        </Box>
      </Box>
    </Box>
  );
}

export default AssignedStudentsMain;
