import React, { useEffect, useState } from "react";
import { Avatar, Box, createTheme, ThemeProvider } from "@mui/material";
import {
  SidebarContainer,
  SidebarMenuContainer,
  StyledNavLink,
  LogoContainer,
  LogoOut
} from "./SideElement";

import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import usePrivilegeCheck from "../../constants/customHooks/UsePrivilegeCheck.js";
import HomeIcon from "../../menuIcons/HomeIcon.js";
import LeadIcon from "../../menuIcons/LeadIcon.js";
import DcumentUploadIcon from "../../menuIcons/DcumentUploadIcon.js";
import PartnerIcons from "../../menuIcons/PartnerIcons.js";
import AdministrationIcon from "../../menuIcons/AdministrationIcon.js";
import ApplicationIcon from "../../menuIcons/ApplicationIcon.js";
import UniversityIcon from "../../menuIcons/UniversityIcon.js";
import TicketsIcon from "../../menuIcons/TicketsIcon.js";
import EnquiryIcon from "../../menuIcons/EnquiryIcon.js";
import WebsiteIcon from "../../menuIcons/WebsiteIcon.js";
import IeltsIcon from "../../menuIcons/IeltsIcon.js";
import HrManagement from "../../menuIcons/HrManagement.js";
import AccountsIcon from "../../menuIcons/AccountsIcon.js";
import ReferIcon from "../../menuIcons/ReferIcon.js";
import SettingsIcon from "../../menuIcons/SettingsIcon.js";
import ReportIcon from "../../menuIcons/reportIcon.js";
import DealIcon from "../../menuIcons/dealIcon.js";
import EntrolledIcon from "../../menuIcons/entrolledIcon.js";
const Sidebar = ({ mouseOnEnter, mouseOnLeave, isHover }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isActive, setActive] = useState(null);
  const [items, setItems] = useState([
    "Home",
    "Leads",
    "Deals",
    "Enrolled",
    "Approvals",
    "Document Upload",
    "Partner",
    "Administration",
    "Application",
    "university",
    // "Course",
    "Tickets",
    "Enquiry",
    "Website",
    "Ielts",
    "HR Management",
    "Report",
    "Accounts",
    "Refer & Earn",
    "Settings"
  ]);

  const [pages, setPages] = useState([
    "Home",
    "Leads",
    "deals",
    "enrolled",
    "approval",
    "DocumentUpload",
    "Partner",
    "Administration",
    "Application",
    "university",
    // "Course",
    "Tickets",
    "Enquiry",
    "landingPage",
    // privilege?.isIeltsView !== false ? "ielts" : null,
    "Ielts",
    "hrManagment",
    "Accounts",
    "ReferAndEarn",
    "Settings"
  ]);

  let { loginFindLoading, loginFindSuccess, loginFinderror } = useSelector(
    (state) => {
      return state.findUser;
    }
  );

  const { loginSuccess, loginSubmitLoading, loginSubmiterror } = useSelector(
    (state) => {
      return state.loginSubmit;
    }
  );

  // custom Hooks
  const { privilege, hasPrivilege } = usePrivilegeCheck();

  let isUserExist = localStorage.getItem("loginInfo")
    ? JSON.parse(localStorage.getItem("loginInfo"))
    : null;

  // useEffect(() => {
  //   if (loginFindSuccess) {
  //     alert(loginFindSuccess);
  //   }
  // }, [loginFindSuccess]);
  // hooks
  useEffect(() => {
    if (!isUserExist) {
      navigate("/login");
    }
  }, [isUserExist, navigate, privilege, loginFindSuccess, isHover]);

  useEffect(() => {
    if (privilege && privilege !== null) {
      // setItems(
      //   privilege?.role_privilages?.map(
      //     (item) => item?.side_menu?.role_sub_menu?.side_menu?.name
      //   )
      // );
      const uniqueNames = new Set(
        privilege?.role_sub_menus?.flatMap((item) =>
          item?.side_menu?.name ? [item.side_menu.name] : []
        )
      );

      setItems([...uniqueNames]);

      const uniquePage = new Set(
        privilege?.role_sub_menus?.flatMap((item) =>
          item?.side_menu?.path ? [item.side_menu.path] : []
        )
      );

      setPages([...uniquePage]);

      // setItems([
      //   "Home",
      //   privilege?.isLeadView !== false ? "Leads" : null,
      //   "Document Upload",
      //   "Partner",
      //   privilege?.isadministrationView !== false ? "Administration" : null,
      //   privilege?.isApplicationView !== false ? "Application" : null,
      //   privilege?.isUniversityView !== false ? "university" : null,
      //   privilege?.isCoursView !== false ? "Course" : null,
      //   "Tickets",
      //   privilege?.isEnquiryView !== false ? "Enquiry" : null,
      //   privilege?.isWebsitView !== false ? "Website" : null,
      //   privilege?.isIeltsView !== false ? "Ielts" : null,
      //   privilege?.isHRmanagmentView !== false ? "HR Management" : null,
      //   privilege?.isAccountsView !== false ? "Accounts" : null,
      //   privilege?.isAccountsView !== false ? "Refer & Earn" : null,
      //   privilege?.isSettingsView !== false ? "Settings" : null,
      // ]);

      // setPages([
      //   "Home",
      //   privilege?.isLeadView !== false ? "Leads" : null,
      //   "DocumentUpload",

      //   "Partner",
      //   privilege?.isadministrationView !== false ? "Administration" : null,
      //   privilege?.isApplicationView !== false ? "Application" : null,
      //   privilege?.isUniversityView !== false ? "university" : null,
      //   privilege?.isCoursView !== false ? "Course" : null,
      //   "Tickets",
      //   privilege?.isEnquiryView !== false ? "Enquiry" : null,
      //   privilege?.isWebsitView !== false ? "landingPage" : null,
      //   // privilege?.isIeltsView !== false ? "ielts" : null,
      //   privilege?.isIeltsView !== false ? "Ielts" : null,
      //   privilege?.isHRmanagmentView !== false ? "hrManagment" : null,
      //   privilege?.isAccountsView !== false ? "Accounts" : null,
      //   privilege?.isAccountsView !== false ? "ReferAndEarn" : null,
      //   privilege?.isSettingsView !== false ? "Settings" : null,
      // ]);
    } else {
      setItems([
        "Home",
        "Leads",
        "Deals",
        "Enrolled",
        "Approvals",
        "Document Upload",
        "Partner",
        "Administration",
        "Application",
        "university",
        // "Course",
        "Tickets",
        "Enquiry",
        "Website",
        "Ielts",
        "HR Management",
        "Accounts",
        "Report",
        "Refer & Earn",
        "Settings"
      ]);

      setPages([
        "Home",
        "Leads",
        "deals",
        "enrolled",
        "approval",
        "DocumentUpload",
        "Partner",
        "Administration",
        "Application",
        "University",
        // "Course",
        "Tickets",
        "Enquiry",
        "landingPage",
        // privilege?.isIeltsView !== false ? "ielts" : null,
        "Ielts",
        "hrManagment",
        "Accounts",
        "reports",
        "ReferAndEarn",
        "Settings"
      ]);
    }
  }, [privilege, loginFindSuccess, loginSubmitLoading]);

  // var items = [
  //   "Home",
  //   privilege?.isLeadView !== false ? "Leads" : null,
  //   "Document Upload",
  //   privilege?.isadministrationView !== false ? "Administration" : null,
  //   privilege?.isApplicationView !== false ? "Application" : null,
  //   privilege?.isUniversityView !== false ? "university" : null,
  //   privilege?.isCoursView !== false ? "Course" : null,
  //   privilege?.isTicketView !== false ? "Tickets" : null,
  //   privilege?.isEnquiryView !== false ? "Enquiry" : null,
  //   privilege?.isWebsitView !== false ? "Website" : null,
  //   privilege?.isIeltsView !== false ? "Ielts" : null,
  //   privilege?.isHRmanagmentView !== false ? "HR Management" : null,
  //   privilege?.isAccountsView !== false ? "Accounts" : null,
  //   privilege?.isSettingsView !== false ? "Settings" : null,
  // ];

  // var pages = [
  //   "Home",
  //   privilege?.isLeadView !== false ? "Leads" : null,
  //   "DocumentUpload",
  //   privilege?.isadministrationView !== false ? "Administration" : null,
  //   privilege?.isApplicationView !== false ? "Application" : null,
  //   privilege?.isUniversityView !== false ? "university" : null,
  //   privilege?.isCoursView !== false ? "Course" : null,
  //   privilege?.isTicketView !== false ? "Tickets" : null,
  //   privilege?.isEnquiryView !== false ? "Enquiry" : null,
  //   privilege?.isWebsitView !== false ? "landingPage" : null,
  //   privilege?.isIeltsView !== false ? "Ielts" : null,
  //   privilege?.isHRmanagmentView !== false ? "hrManagment" : null,
  //   privilege?.isAccountsView !== false ? "Accounts" : null,
  //   privilege?.isSettingsView !== false ? "Settings" : null,
  // ];

  const toggleClass = (index) => {
    setActive(index);
  };

  // theme Provider
  let theme = createTheme({
    width: "auto",
    palette: {
      primary: {
        main: "#0052cc"
      },
      secondary: {
        main: "#edf2ff"
      }
    }
  });

  theme = createTheme(theme, {
    palette: {
      info: {
        main: theme.palette.secondary.main
      }
    }
  });

  console.log(pages,"pagespagespages")
  console.log(items,"itemsitemsitems")


  return (
    <ThemeProvider theme={theme}>
      {isUserExist ? (
        <SidebarContainer
          act={isHover}
          onMouseEnter={mouseOnEnter}
          onMouseLeave={mouseOnLeave}
        >
          <SidebarMenuContainer>
            <Box>
              <LogoContainer act={isHover} id="item" to="/">
                <i className="bx bx-layer nav_logo-icon">
                  <img src="/gsl.svg" alt="gsl" />
                </i>
              </LogoContainer>

              {items.map(function (item, index) {
                console.log(item, "itemmmmmmm");
                var strClass = "";
                if (isActive === index) {
                  strClass = "active";
                } else {
                  strClass = "Noactive";
                }
                if (item !== null)
                  return (
                    <StyledNavLink
                      to={"/" + pages[index]}
                      className={strClass}
                      key={index}
                      onClick={() => toggleClass(index)}
                    >
                      {item == "Home" && (
                        <i>
                          <Box
                            sx={{
                              "& svg path": {
                                stroke:
                                  strClass === "active" ? "#FF1B6F" : "#ACB1C6"
                              }
                            }}
                          >
                            <HomeIcon />
                          </Box>
                          {/* {strClass === "active" ? (
                            <img
                              style={{
                                width: "20px",
                              }}
                              src="/menuIcons/Frame2Active.svg"
                              alt="gsl"
                            />
                          ) : (
                            <img
                              style={{
                                width: "20px",
                              }}
                              src="/menuIcons/Frame2.svg"
                              alt="gsl"
                            />
                          )} */}
                        </i>
                      )}
                      {item == "Leads" && (
                        <i>
                          <Box
                            sx={{
                              "& svg path": {
                                fill:
                                  strClass === "active" ? "#FF1B6F" : "#ACB1C6"
                              }
                            }}
                          >
                            <LeadIcon />
                          </Box>
                          {/* <img
                            style={{ width: "20px" }}
                            src="/menuIcons/lead.svg"
                            alt="gsl"
                          /> */}
                        </i>
                      )}

                      {item == "Deals" && (
                        <i>
                          <Box
                            sx={{
                              "& svg path": {
                                fill:
                                  strClass === "active" ? "#FF1B6F" : "#ACB1C6"
                              }
                            }}
                          >
                            <DealIcon />
                          </Box>
                          {/* <img
                            style={{ width: "20px" }}
                            src="/menuIcons/lead.svg"
                            alt="gsl"
                          /> */}
                        </i>
                      )}

                      {item == "Enrolled" || item == "Entrolled" ? (
                        <i>
                          <Box
                            sx={{
                              "& svg path": {
                                fill:
                                  strClass === "active" ? "#FF1B6F" : "#ACB1C6"
                              }
                            }}
                          >
                            <EntrolledIcon />
                          </Box>
                          {/* <img
                            style={{ width: "20px" }}
                            src="/menuIcons/lead.svg"
                            alt="gsl"
                          /> */}
                        </i>
                      ):""}

                      {item == "Approvals" ||  item == "Approval" ? (
                        <i>
                          <Box
                            sx={{
                              "& svg path": {
                                fill:
                                  strClass === "active" ? "#FF1B6F" : "#ACB1C6"
                              }
                            }}
                          >
                            <DealIcon />
                          </Box>
                        </i>
                      ):""}

                      {item == "Document Upload" && (
                        <i>
                          {/* <img
                            style={{ width: "20px" }}
                            src="/menuIcons/knowlegBank.png"
                            alt="gsl"
                          /> */}
                          <Box
                            sx={{
                              "& svg path": {
                                fill:
                                  strClass === "active" ? "#FF1B6F" : "#ACB1C6"
                              }
                            }}
                          >
                            <DcumentUploadIcon />
                          </Box>
                        </i>
                      )}

                      {item == "Partner" && (
                        <i>
                          {/* <img
                            style={{ width: "20px" }}
                            src="/menuIcons/partner.svg"
                            alt="gsl"
                          /> */}
                          <Box
                            sx={{
                              "& svg path": {
                                fill:
                                  strClass === "active" ? "#FF1B6F" : "#ACB1C6"
                              }
                            }}
                          >
                            <PartnerIcons />
                          </Box>
                        </i>
                      )}

                      {item == "Administration" && (
                        <i>
                          {/* <img
                            style={{ width: "20px" }}
                            src="/menuIcons/administration.svg"
                            alt="gsl"
                          /> */}
                          <Box
                            sx={{
                              "& svg path": {
                                stroke:
                                  strClass === "active" ? "#FF1B6F" : "#ACB1C6"
                              }
                            }}
                          >
                            <AdministrationIcon />
                          </Box>
                        </i>
                      )}

                      {item == "Application" && (
                        <i>
                          {/* <img
                            style={{ width: "20px" }}
                            src="/menuIcons/application.svg"
                            alt="gsl"
                          /> */}
                          <Box
                            sx={{
                              "& svg  path": {
                                fill:
                                  strClass === "active" ? "#FF1B6F" : "#ACB1C6"
                              }
                            }}
                          >
                            <ApplicationIcon />
                          </Box>
                        </i>
                      )}

                      {item == "university" && (
                        <i>
                          {/* <img
                            style={{ width: "20px" }}
                            src="/menuIcons/university.svg"
                            alt="gsl"
                          /> */}
                          <Box
                            sx={{
                              "& svg  path": {
                                fill:
                                  strClass === "active" ? "#FF1B6F" : "#ACB1C6"
                              }
                            }}
                          >
                            <UniversityIcon />
                          </Box>
                        </i>
                      )}

                      {item == "Course" && (
                        <i>
                          <img
                            style={{ width: "20px" }}
                            src="/menuIcons/course.svg"
                            alt="gsl"
                          />
                        </i>
                      )}

                      {item == "Tickets" && (
                        <i>
                          {/* <img
                            style={{ width: "20px" }}
                            src="/menuIcons/tickets.svg"
                            alt="gsl"
                          /> */}
                          <Box
                            sx={{
                              "& svg path": {
                                fill:
                                  strClass === "active" ? "#FF1B6F" : "#ACB1C6"
                              }
                            }}
                          >
                            <TicketsIcon />
                          </Box>
                        </i>
                      )}

                      {item == "Enquiry" && (
                        <i>
                          {/* <img
                            style={{ width: "20px" }}
                            src="/menuIcons/enquiry.svg"
                            alt="gsl"
                          /> */}
                          <Box
                            sx={{
                              "& svg path": {
                                fill:
                                  strClass === "active" ? "#FF1B6F" : "#ACB1C6"
                              }
                            }}
                          >
                            <EnquiryIcon />
                          </Box>
                        </i>
                      )}

                      {item == "Website" && (
                        <i>
                          {/* <img
                            style={{ width: "20px" }}
                            src="/menuIcons/website.svg"
                            alt="gsl"
                          /> */}
                          <Box
                            sx={{
                              "& svg path": {
                                fill:
                                  strClass === "active" ? "#FF1B6F" : "#ACB1C6"
                              }
                            }}
                          >
                            <WebsiteIcon />
                          </Box>
                        </i>
                      )}

                      {item === "IELTS" || item === "Ielts" ? (
                        <i>
                          {/* {strClass === "active" ? (
                            <img
                              style={{
                                width: "20px",
                              }}
                              src="/menuIcons/IeltsActive.svg"
                              alt="gsl"
                            />
                          ) : (
                            <img
                              style={{ width: "20px" }}
                              src="/menuIcons/ielts.svg"
                              alt="gsl"
                            />
                          )} */}
                          <Box
                            sx={{
                              "& svg path": {
                                fill:
                                  strClass === "active" ? "#FF1B6F" : "#ACB1C6"
                              }
                            }}
                          >
                            <IeltsIcon />
                          </Box>
                        </i>
                      ) : (
                        ""
                      )}

                      {item == "HR Management" && (
                        <i>
                          {/* <img
                            style={{ width: "20px" }}
                            src="/menuIcons/hr.svg"
                            alt="gsl"
                          /> */}
                          <Box
                            sx={{
                              "& svg path": {
                                fill:
                                  strClass === "active" ? "#FF1B6F" : "#ACB1C6"
                              }
                            }}
                          >
                            <HrManagement />
                          </Box>
                        </i>
                      )}

                      {item == "Accounts" && (
                        <i>
                          {/* <img
                            style={{ width: "20px" }}
                            src="/menuIcons/account.svg"
                            alt="gsl"
                          /> */}
                          <Box
                            sx={{
                              "& svg path": {
                                fill:
                                  strClass === "active" ? "#FF1B6F" : "#ACB1C6"
                              }
                            }}
                          >
                            <AccountsIcon />
                          </Box>
                        </i>
                      )}

                      {item == "Report" && (
                        <i>
                          {/* <img
                            style={{ width: "20px" }}
                            src="/menuIcons/gift.svg"
                            alt="gsl"
                          /> */}
                          <Box
                            sx={{
                              "& svg path": {
                                stroke:
                                  strClass === "active" ? "#FF1B6F" : "#ACB1C6"
                              }
                            }}
                          >
                            <ReportIcon />
                          </Box>
                        </i>
                      )}

                      {item == "refer&earn" || item == "Refer & Earn" ? (
                        <i>
                          {/* <img
                            style={{ width: "20px" }}
                            src="/menuIcons/refferAndEarnNotActive.svg"
                            alt="gsl"
                          /> */}
                          <Box
                            sx={{
                              "& svg path": {
                                stroke:
                                  strClass === "active" ? "#FF1B6F" : "#ACB1C6"
                              }
                            }}
                          >
                            <ReferIcon />
                          </Box>
                        </i>
                      ) : (
                        ""
                      )}

                      {item == "Settings" && (
                        <i>
                          {/* <img
                            style={{ width: "20px" }}
                            src="/menuIcons/setting.svg"
                            alt="gsl"
                          /> */}
                          <Box
                            sx={{
                              "& svg path": {
                                fill:
                                  strClass === "active" ? "#FF1B6F" : "#ACB1C6"
                              }
                            }}
                          >
                            <SettingsIcon />
                          </Box>
                        </i>
                      )}

                      {!isHover && (
                        <span
                          style={{
                            fontSize: "15px",
                            fontWeight: 500,
                            color: strClass === "active" ? "#FF1B6F" : "#ACB1C6"
                          }}
                        >
                          {item}
                        </span>
                      )}
                    </StyledNavLink>
                  );
              })}
            </Box>
            <LogoOut>
              {/* <i className="bx bx-bookmark nav_icon" />
              <Avatar
                alt="GSL"
                src="/static/images/avatar/1.jpg"
                sx={{ width: 56, height: 56 }}
              /> */}
            </LogoOut>
          </SidebarMenuContainer>
        </SidebarContainer>
      ) : null}
    </ThemeProvider>
  );
};
export default Sidebar;
