import React, { useEffect, useState } from "react";
import { Box, Chip, Typography } from "@mui/material";
import VideoPlayer from "../../../../../customComponent/VideoPlayer";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";

function VideoTabItem({ topic, demo, courseDetails }) {
  
  const [selectedVideo, setSelectedVideo] = useState(demo?.[0]?.Demovideo);

  useEffect(() => {
    if (demo) {
      setSelectedVideo(demo[0]?.Demovideo);
    }
  }, [demo]);

  return demo?.length > 0 ? (
    <Box
      sx={{
        mt: "24px",
        display: "grid",
        gridTemplateColumns: "1fr 2fr",
        gap: "50px",
        minHeight: "500px",
      }}
    >
      <Box
        sx={{
          pr: "40px",
          overflowY: "scroll",
          maxHeight: "500px",
          display: "flex",
          flexDirection: "column",
          gap: "20px",
        }}
      >
        <>
          {demo?.map((value, index) => (
            <Box
              key={index}
              sx={{
                cursor: "pointer",
                padding: "8px",
                borderRadius: "8px",
                display: "grid",
                gridTemplateColumns: "83px 1fr",
                gap: "10px",
                alignItems: "center",
                boxShadow: "0px 10px 30px 0px rgba(0, 0, 0, 0.08)",
                border: "1px solid #F5F5F5",
              }}
              onClick={() => setSelectedVideo(value?.Demovideo)}
            >
              <Box
                sx={{
                  width: "83px",
                  height: "83px",
                  borderRadius: "8px",
                  overflow: "hidden",
                  position: "relative",
                  "& video": {
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  },
                }}
              >
                {selectedVideo !== value?.Demovideo && (
                  <PlayArrowIcon
                    sx={{
                      color: "#fff",
                      position: "absolute",
                      top: "0",
                      bottom: "0",
                      left: "0",
                      right: "0",
                      margin: "auto",
                      fontSize: "40px",
                    }}
                  />
                )}
                <video src={value?.Demovideo} alt="video" />
              </Box>
              <Box>
                <Typography
                  sx={{
                    fontSize: "16px",
                    color: "rgba(105, 110, 114, 1)",
                  }}
                >
                  Lesson 1 {value?.type} Demo Class
                </Typography>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "700",
                    color: "rgba(20, 30, 60, 1)",
                  }}
                >
                  Start Demo Class
                </Typography>
                <Typography
                  sx={{
                    fontSize: "16px",
                    color: "rgba(255, 27, 111, 1)",
                  }}
                >
                  Duration : 1hr 30 m
                </Typography>
              </Box>
            </Box>
          ))}
        </>
        {topic?.map((item, index) => (
          <Box
            key={index}
            sx={{
              cursor: "pointer",
              padding: "8px",
              borderRadius: "8px",
              display: "grid",
              gridTemplateColumns: "83px 1fr",
              gap: "10px",
              alignItems: "center",
              boxShadow: "0px 10px 30px 0px rgba(0, 0, 0, 0.08)",
              border: "1px solid #F5F5F5",
            }}
            onClick={() => setSelectedVideo(item?.ielts_added_topic?.VideoUrl)}
          >
            <Box
              sx={{
                width: "83px",
                height: "83px",
                borderRadius: "8px",
                overflow: "hidden",
                position: "relative",
                "& video": {
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                },
              }}
            >
              {selectedVideo !== item?.ielts_added_topic?.VideoUrl && (
                <PlayArrowIcon
                  sx={{
                    color: "#fff",
                    position: "absolute",
                    top: "0",
                    bottom: "0",
                    left: "0",
                    right: "0",
                    margin: "auto",
                    fontSize: "40px",
                  }}
                />
              )}
              <video src={item?.ielts_added_topic?.VideoUrl} />
            </Box>
            <Box>
              <Typography
                sx={{
                  fontSize: "16px",
                  color: "rgba(105, 110, 114, 1)",
                }}
              >
                Lesson {index + 2} {item?.ielts_added_topic?.TopicName}
              </Typography>
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: "700",
                  color: "rgba(20, 30, 60, 1)",
                }}
              >
                Start Video Class
              </Typography>
              <Typography
                sx={{
                  fontSize: "16px",
                  color: "rgba(255, 27, 111, 1)",
                }}
              >
                Duration : {item?.ielts_added_topic?.VideoDuration}
              </Typography>
            </Box>
          </Box>
        ))}
      </Box>
      <Box
        sx={{
          "& .video-react.video-react-fluid": {
            height: "100% !important",
            maxHeight: "500px !important",
            paddingTop: "0 !important",
          },
        }}
      >
        {selectedVideo && <VideoPlayer url={selectedVideo && selectedVideo} />}
      </Box>
    </Box>
  ) : (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: "500px",
      }}
    >
      <Box
        sx={{
          "& img": {
            width: "200px",
          },
        }}
      >
        <img src="/course-no-item-to-display.png" alt="no item to list image" />
        <Typography
          sx={{
            textAlign: "center",
            fontWeight: "700",
          }}
        >
          No items to display
        </Typography>
      </Box>
    </Box>
  );
}

export default VideoTabItem;
