import { Alert, AlertTitle, Box, Button, Chip } from "@mui/material";
import React, { useState } from "react";
import NotificationTable from "./NotificationTable";
import { SubmitButton } from "../../customComponent/Buttons";
import AddNotification from "./AddNotification";
import usePrivilegeCheck from "../../../constants/customHooks/UsePrivilegeCheck";
import PremissionDialog from "../../customComponent/premissionDialog";

function NotificationMasterMain() {
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const { privilege, hasPrivilege, isCreatePrevilage } = usePrivilegeCheck();
  const [openAlert, setOpenAlert] = useState(false);

  // HandleClose dialog

  const handleCloseDialog = () => {
    setOpenAlert(false);
  };
  if (!hasPrivilege("notification") && privilege !== null) {
    return (
      <Alert severity="error">
        <AlertTitle>warning</AlertTitle>
        You don't have permission to access — <strong>this page.!</strong>
      </Alert>
    );
  }

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "10px",
          justifyContent: "end",
        }}
      >
        <Button
          onClick={() => {
            if (isCreatePrevilage("notification")) {
              setOpen(true);
            } else {
              setOpenAlert(true);
            }
          }}
          sx={{
            color: "white",
            backgroundColor: "rgba(20, 30, 60, 1)",
            borderRadius: "8px",
            fontWeight: 600,
            px: "20px",
            fontSize: "12px",
            "&:hover": {
              backgroundColor: "rgba(20, 30, 60, 0.9)",
            },
          }}
        >
          {"Send New Notification"}
        </Button>
      </Box>

      <Box>
        <NotificationTable subMenu="notification" />
      </Box>
      <AddNotification open={open} handleClose={handleClose} />

      {openAlert && (
        <PremissionDialog
          openAlert={openAlert}
          handleClose={handleCloseDialog}
        />
      )}
    </div>
  );
}

export default NotificationMasterMain;
