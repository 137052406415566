import React, { useEffect, useRef, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Grid, TextField, Typography } from "@mui/material";

import Select from "react-select";
import { countries } from "countries-list";

import { State } from "country-state-city";

import { useDispatch, useSelector } from "react-redux";
import {
  findEditStateFormAction,
  stateMasterEditAction,
} from "../../../../actions/StateMasterAction";
import { OutLinedButton, SubmitButton } from "../../../customComponent/Buttons";
import { successTrueMsgAction } from "../../../../actions/globalAction";

function EditState({ status, editMasterCategryHandler, id }) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(status);
  const [scroll, setScroll] = useState("paper");
  const [errorMsg, setErrorMsg] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [stateId, setStateId] = useState("");
  const [countryError, setCountryError] = useState(false);
  const [selectedState, setSelectedState] = useState("");
  const [states, setStates] = useState([]);
  const [error, setError] = useState({});

  let { stateFindEditLoading, stateFindEditSuccess, stateFindEditErr } =
    useSelector((state) => {
      return state.findEditStateForm;
    });

  useEffect(() => {
    dispatch(findEditStateFormAction(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (stateFindEditSuccess) {
      setStateId(stateFindEditSuccess.id);

      const countryStates = State.getStatesOfCountry(
        stateFindEditSuccess.countryCode
      );

      let selectedState = countryStates.filter(
        (c) => c.name === stateFindEditSuccess.stateName
      );

      setStates(countryStates);

      setSelectedCountry(stateFindEditSuccess);
      setSelectedState(
        selectedState.map((state) => ({
          value: state.isoCode,
          label: state.name,
        }))
      );
    }
  }, [stateFindEditSuccess]);

  const handleClose = () => {
    setOpen(false);
    editMasterCategryHandler(status, false);
  };

  const handleSubmit = () => {
    if (selectedState || selectedState !== null) {
      dispatch(stateMasterEditAction(selectedState, stateId));
      dispatch(successTrueMsgAction(true));
      editMasterCategryHandler(status, false);
      setSelectedCountry(null);
      setErrorMsg(false);
    } else {
      setErrorMsg(true);
    }
  };

  const descriptionElementRef = useRef(null);
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">Edit State</DialogTitle>
        <DialogContent dividers={scroll === "paper"}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            <div style={{ width: "500px", height: "400px" }}>
              {errorMsg && (
                <Typography align="center" color="error">
                  Please Choose Country
                </Typography>
              )}
              {countryError && (
                <Typography align="center" color="error">
                  Please Choose Country You Added
                </Typography>
              )}
              <TextField fullWidth value={selectedCountry?.countryName} />
              {Boolean(error?.country) && (
                <Typography
                  sx={{
                    fontSize: "12px",
                  }}
                  color="error"
                >
                  {error?.country}
                </Typography>
              )}

              <h3>Select State:</h3>
              {selectedState && (
                <Select
                  options={states.map((state) => ({
                    value: state.isoCode,
                    label: state.name,
                  }))}
                  value={selectedState}
                  onChange={(selectedOption) => {
                    setSelectedState(selectedOption);
                    console.log(selectedOption, "stateFindEditSuccess");
                  }}
                  isDisabled={!selectedCountry}
                />
              )}
              {Boolean(error?.state) && (
                <Typography
                  sx={{
                    fontSize: "12px",
                  }}
                  color="error"
                >
                  {error?.state}
                </Typography>
              )}
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit}>Submit</Button> */}
          <OutLinedButton
            title="Cancel"
            handleClick={handleClose}
            widthSize="81px"
            heightSize="35px"
          />
          <SubmitButton 
            title="Add"
            submit=""
            widthSize="81px"
            heightSize="35px"
            handleSubmit={handleSubmit}
            type="click"
          />
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default EditState;
