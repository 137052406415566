import axios from "axios";
import {
  NOTIFICATION_DELETE_ERR,
  NOTIFICATION_DELETE_REQUEST,
  NOTIFICATION_DELETE_SUCCESS,
  NOTIFICATION_EDIT_ERR,
  NOTIFICATION_EDIT_REQUEST,
  NOTIFICATION_EDIT_SUCCESS,
  PARTNER_APPROVAL_ERR,
  PARTNER_APPROVAL_REQUEST,
  PARTNER_APPROVAL_SUCCESS,
  PARTNER_COMMISSIONID_UPDATE_ERR,
  PARTNER_COMMISSIONID_UPDATE_REQUEST,
  PARTNER_COMMISSIONID_UPDATE_SUCCESS,
  PARTNER_COMMISSION_CATEGORY_ERR,
  PARTNER_COMMISSION_CATEGORY_REQUEST,
  PARTNER_COMMISSION_CATEGORY_SUCCESS,
  PARTNER_CREATE_ERR,
  PARTNER_CREATE_REQUEST,
  PARTNER_CREATE_SUCCESS,
  PARTNER_DELETE_ERR,
  PARTNER_DELETE_REQUEST,
  PARTNER_DELETE_SUCCESS,
  PARTNER_EDIT_DETAIL_ERR,
  PARTNER_EDIT_DETAIL_REQUEST,
  PARTNER_EDIT_DETAIL_SUCCESS,
  PARTNER_GET_ALL_ERR,
  PARTNER_GET_ALL_REQUEST,
  PARTNER_GET_ALL_SUCCESS,
  PARTNER_GET_DETAIL_ERR,
  PARTNER_GET_DETAIL_REQUEST,
  PARTNER_GET_DETAIL_SUCCESS,
  PARTNER_LEADS_LIST_ERR,
  PARTNER_LEADS_LIST_REQUEST,
  PARTNER_LEADS_LIST_SUCCESS,
  PARTNER_LEAD_APPLICATION_FIND_ERR,
  PARTNER_LEAD_APPLICATION_FIND_REQUEST,
  PARTNER_LEAD_APPLICATION_FIND_SUCCESS,
  PARTNER_LEAD_ENTROLLED_FIND_ERR,
  PARTNER_LEAD_ENTROLLED_FIND_REQUEST,
  PARTNER_LEAD_ENTROLLED_FIND_SUCCESS,
  PARTNER_LEAD_FIND_ERR,
  PARTNER_LEAD_FIND_REQUEST,
  PARTNER_LEAD_FIND_SUCCESS,
  PARTNER_NOTIFICATION_CREATE_ERR,
  PARTNER_NOTIFICATION_CREATE_REQUEST,
  PARTNER_NOTIFICATION_CREATE_SUCCESS,
  PARTNER_NOTIFICATION_FIND_ALL_ERR,
  PARTNER_NOTIFICATION_FIND_ALL_REQUEST,
  PARTNER_NOTIFICATION_FIND_ALL_SUCCESS,
  PARTNER_NOTIFICATION_FIND_ONE_ERR,
  PARTNER_NOTIFICATION_FIND_ONE_REQUEST,
  PARTNER_NOTIFICATION_FIND_ONE_SUCCESS,
  PARTNER_OTP_VERIFICATION_ERR,
  PARTNER_OTP_VERIFICATION_REQUEST,
  PARTNER_OTP_VERIFICATION_SUCCESS,
} from "../../constants/partnerConstant";
import {
  SUCCESS_TRUE_MSG_ERR,
  SUCCESS_TRUE_MSG_SUCCESS,
} from "../../constants/roleManagmentConstant";
import {
  APPLICATION_ALL_FIND_LEAD_ERR,
  APPLICATION_ALL_FIND_LEAD_REQUEST,
  APPLICATION_ALL_FIND_LEAD_SUCCESS,
  PARTNER_TEAM_LEAD_ASSIGN_ERR,
  PARTNER_TEAM_LEAD_ASSIGN_REQUEST,
  PARTNER_TEAM_LEAD_ASSIGN_SUCCESS,
  TEAM_LEAD_ASSIGN_STAFF_ERR,
  TEAM_LEAD_ASSIGN_STAFF_REQUEST,
  TEAM_LEAD_ASSIGN_STAFF_SUCCESS,
} from "../../constants/applicationConstant";

// partner
// findAllPartnerAction
export const findAllPartnerAction = () => async (dispatch, getState) => {
  try {
    dispatch({ type: PARTNER_GET_ALL_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let userId = isUserExist?.UserId;

    let { data } = await axios.get(
      `/api/admin/partner/findAllPartner?userid=${userId}`,
      config
    );

    dispatch({ type: PARTNER_GET_ALL_SUCCESS, payload: data });

    // let isUserExist = localStorage.getItem("loginInfo")
    //   ? JSON.parse(localStorage.getItem("loginInfo"))
    //   : null;
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({
      type: PARTNER_GET_ALL_ERR,
      payload: error.response.data,
    });
  }
};

// partnerKycDetailFindAction
export const partnerKycDetailFindAction = (id) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({ type: PARTNER_GET_DETAIL_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.get(
      `/api/admin/partner/findDetailPartner?id=${id}`,
      config
    );

    dispatch({ type: PARTNER_GET_DETAIL_SUCCESS, payload: data });

    // let isUserExist = localStorage.getItem("loginInfo")
    //   ? JSON.parse(localStorage.getItem("loginInfo"))
    //   : null;
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({
      type: PARTNER_GET_DETAIL_ERR,
      payload: error.response.data,
    });
  }
};

// kycDetailsEditAction
export const kycDetailsEditAction = (id, details) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({ type: PARTNER_EDIT_DETAIL_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.put(
      `/api/admin/partner/editKycDetails?id=${id}`,
      details,
      config
    );

    dispatch({ type: PARTNER_EDIT_DETAIL_SUCCESS, payload: data });
    dispatch({ type: SUCCESS_TRUE_MSG_SUCCESS, payload: true });

    // let isUserExist = localStorage.getItem("loginInfo")
    //   ? JSON.parse(localStorage.getItem("loginInfo"))
    //   : null;
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({
      type: PARTNER_EDIT_DETAIL_ERR,
      payload: error.response.data,
    });
    dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: true });
  }
};

// partnerKycApprovalAction
export const partnerKycApprovalAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: PARTNER_APPROVAL_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.put(
      `/api/admin/partner/partnerKycApproval?id=${id}`,
      { status: true },
      config
    );

    dispatch({ type: PARTNER_APPROVAL_SUCCESS, payload: data });
    dispatch({ type: SUCCESS_TRUE_MSG_SUCCESS, payload: true });

    // let isUserExist = localStorage.getItem("loginInfo")
    //   ? JSON.parse(localStorage.getItem("loginInfo"))
    //   : null;
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({
      type: PARTNER_APPROVAL_ERR,
      payload: error.response.data,
    });
    dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: true });
  }
};

// partnerAddAction
export const partnerAddAction = (details) => async (dispatch, getState) => {
  try {
    dispatch({ type: PARTNER_CREATE_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
        "Content-Type": "multipart/form-data",
      },
    };

    let { data } = await axios.post(
      "/api/admin/partner/partnerCreate",
      details,
      config
    );

    dispatch({ type: PARTNER_CREATE_SUCCESS, payload: data });
    dispatch({ type: SUCCESS_TRUE_MSG_SUCCESS, payload: true });

    // let isUserExist = localStorage.getItem("loginInfo")
    //   ? JSON.parse(localStorage.getItem("loginInfo"))
    //   : null;
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({
      type: PARTNER_CREATE_ERR,
      payload: error.response.data,
    });
    dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: true });
  }
};

// deletePartnerAction
export const deletePartnerAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: PARTNER_DELETE_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
        "Content-Type": "multipart/form-data",
      },
    };

    let { data } = await axios.delete(
      `/api/admin/partner/partnerDelete?id=${id}`,
      config
    );

    dispatch({ type: PARTNER_DELETE_SUCCESS, payload: data });
    dispatch({ type: SUCCESS_TRUE_MSG_SUCCESS, payload: true });

    // let isUserExist = localStorage.getItem("loginInfo")
    //   ? JSON.parse(localStorage.getItem("loginInfo"))
    //   : null;
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({
      type: PARTNER_DELETE_ERR,
      payload: error.response.data,
    });
    dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: true });
  }
};

// PARTNER LEAD FIND  ACTIONS
export const partnerLeadsFindAction = () => async (dispatch, getState) => {
  try {
    dispatch({ type: PARTNER_LEADS_LIST_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let UserId = isUserExist?.UserId;

    let { data } = await axios.get("/api/admin/lead/partnerLeadFind", config);

    dispatch({ type: PARTNER_LEADS_LIST_SUCCESS, payload: data });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({ type: PARTNER_LEADS_LIST_ERR, payload: error.response.data });
  }
};

// partnerOtpVerifyAction
export const partnerOtpVerifyAction = (otps) => async (dispatch, getState) => {
  try {
    dispatch({ type: PARTNER_OTP_VERIFICATION_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let UserId = isUserExist?.UserId;

    let { data } = await axios.post(
      "/api/admin/partner/partnerOtpVerfication",
      otps,
      config
    );

    dispatch({ type: PARTNER_OTP_VERIFICATION_SUCCESS, payload: data });
    dispatch({ type: SUCCESS_TRUE_MSG_SUCCESS, payload: true });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({
      type: PARTNER_OTP_VERIFICATION_ERR,
      payload: error.response.data,
    });
    dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: true });
  }
};

// partnerLeadFindAction
export const partnerLeadFindAction = () => async (dispatch) => {
  try {
    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    dispatch({ type: PARTNER_LEAD_FIND_REQUEST });
    // let createdBy = isUserExist.name;
    let { data } = await axios.get(
      "/api/admin/partner/partnerLeadFind",
      config
    );

    dispatch({ type: PARTNER_LEAD_FIND_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: PARTNER_LEAD_FIND_ERR, payload: error.response.data });
  }
};

// allPartnerCommissionCategoryFindAction
export const allPartnerCommissionCategoryFindAction = () => async (
  dispatch
) => {
  try {
    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    dispatch({ type: PARTNER_COMMISSION_CATEGORY_REQUEST });
    // let createdBy = isUserExist.name;
    let { data } = await axios.get(
      "/api/admin/partner/partnerAllCategoryFind",
      config
    );

    dispatch({ type: PARTNER_COMMISSION_CATEGORY_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: PARTNER_COMMISSION_CATEGORY_ERR,
      payload: error.response.data,
    });
  }
};

// partnerCommissionIdUpdateAction
export const partnerCommissionIdUpdateAction = (commissionId, leadId) => async (
  dispatch
) => {
  try {
    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    dispatch({ type: PARTNER_COMMISSIONID_UPDATE_REQUEST });
    // let createdBy = isUserExist.name;
    let { data } = await axios.put(
      `/api/admin/partner/partnerCommissionUpdate?id=${leadId}`,
      { commissionId },
      config
    );

    dispatch({ type: PARTNER_COMMISSIONID_UPDATE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: PARTNER_COMMISSIONID_UPDATE_ERR,
      payload: error.response.data,
    });
  }
};

// partnersLeadApplicationFindAction
export const partnersLeadApplicationFindAction = (
  pageCount,
  tabSection
) => async (dispatch) => {
  try {
    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    dispatch({ type: APPLICATION_ALL_FIND_LEAD_REQUEST });
    
    let userId = isUserExist.UserId;
    let { data } = await axios.get(
      `/api/admin/partner/partnersLeadApplicationFind?id=${userId}&pageCount=${pageCount}&tabSection=${tabSection}`,
      config
    );

    dispatch({ type: APPLICATION_ALL_FIND_LEAD_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: APPLICATION_ALL_FIND_LEAD_ERR,
      payload: error.response.data,
    });
  }
};


// partnersLeadEntrolledFindAction

export const partnersEntrolledLeadFindAction = (
  pageCount,
) => async (dispatch) => {
  try {
    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    dispatch({ type: PARTNER_LEAD_ENTROLLED_FIND_REQUEST });
    let userId = isUserExist.UserId;

    let { data } = await axios.get(
      `/api/admin/partner/partnersLeadEntrolledFind?id=${userId}&pageCount=${pageCount}`,
      config
    );

    dispatch({ type: PARTNER_LEAD_ENTROLLED_FIND_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: PARTNER_LEAD_ENTROLLED_FIND_ERR,
      payload: error.response.data,
    });
  }
};


// partnerManagerChangeAction
export const partnerManagerChangeAction = (userId, managerId) => async (
  dispatch
) => {
  try {
    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    dispatch({ type: PARTNER_TEAM_LEAD_ASSIGN_REQUEST });

    let { data } = await axios.put(
      `/api/admin/partner/partnerTeamLeadAssighn?id=${userId}`,
      { managerId },
      config
    );

    dispatch({ type: PARTNER_TEAM_LEAD_ASSIGN_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: PARTNER_TEAM_LEAD_ASSIGN_ERR,
      payload: error.response.data,
    });
    dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: true });
  }
};

// teamLeadAssignStaffChangeAction
export const teamLeadAssignStaffChangeAction = (userId, managerId) => async (
  dispatch
) => {
  try {
    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    dispatch({ type: TEAM_LEAD_ASSIGN_STAFF_REQUEST });

    let { data } = await axios.put(
      `/api/admin/partner/teamLeadAssignStaffChange?id=${userId}`,
      { managerId },
      config
    );

    dispatch({ type: TEAM_LEAD_ASSIGN_STAFF_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: TEAM_LEAD_ASSIGN_STAFF_ERR,
      payload: error.response.data,
    });
    dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: true });
  }
};


// notification add action
export const partnerNotificationAddAction = (formData) => async (dispatch, getState) => {
  try {
    dispatch({ type: PARTNER_NOTIFICATION_CREATE_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
        "Content-Type": "multipart/form-data",
      },
    };

    let { data } = await axios.post(
      "/api/admin/partner/partnerNotifications",
      formData,
      config
    );

    dispatch({ type: PARTNER_NOTIFICATION_CREATE_SUCCESS, payload: data });
    dispatch({ type: SUCCESS_TRUE_MSG_SUCCESS, payload: true });

  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({
      type: PARTNER_NOTIFICATION_CREATE_ERR,
      payload: error.response.data,
    });
    dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: true });
  }
};

// notification find all action
export const partnerNotificationFindAllAction = () => async (dispatch, getState) => {
  try {
    dispatch({ type: PARTNER_NOTIFICATION_FIND_ALL_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
        "Content-Type": "multipart/form-data",
      },
    };

    let { data } = await axios.get(
      "/api/admin/partner/partnerNotifications",
      config
    );

    dispatch({ type: PARTNER_NOTIFICATION_FIND_ALL_SUCCESS, payload: data });

  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({
      type: PARTNER_NOTIFICATION_FIND_ALL_ERR,
      payload: error.response.data,
    });
  }
};

// notification find one action
export const partnerNotificationFindOneAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: PARTNER_NOTIFICATION_FIND_ONE_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
        "Content-Type": "multipart/form-data",
      },
    };

    let { data } = await axios.get(
      `/api/admin/partner/partnerNotificationsFindOne?id=${id}`,
      config
    );

    dispatch({ type: PARTNER_NOTIFICATION_FIND_ONE_SUCCESS, payload: data });

  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({
      type: PARTNER_NOTIFICATION_FIND_ONE_ERR,
      payload: error.response.data,
    });
  }
};

// delete notification
export const deleteNotificationAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: NOTIFICATION_DELETE_REQUEST });

      let isUserExist = localStorage.getItem("loginInfo")
        ? JSON.parse(localStorage.getItem("loginInfo"))
        : null;

      const config = {
        headers: {
          Authorization: `Bearer ${isUserExist?.token}`,
        },
      };

      let { data } = await axios.delete(
        `/api/admin/partner/partnerNotificationsDelete?id=${id}`,
        config
      );

      dispatch({ type: NOTIFICATION_DELETE_SUCCESS, payload: data });
    } catch (error) {
      console.log(error.response, "error.response");
      dispatch({
        type: NOTIFICATION_DELETE_ERR,
        payload: error.response.data,
      });
      dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: true });
    }
  };

// delete notification
export const UpdateNotificationAction =
  (formData) => async (dispatch, getState) => {
    try {
      dispatch({ type: NOTIFICATION_EDIT_REQUEST });

      let isUserExist = localStorage.getItem("loginInfo")
        ? JSON.parse(localStorage.getItem("loginInfo"))
        : null;

      const config = {
        headers: {
          Authorization: `Bearer ${isUserExist?.token}`,
        },
      };

      let { data } = await axios.post(
        `/api/admin/partner/partnerNotificationsUpdate`,
        formData,
        config
      );

      dispatch({ type: NOTIFICATION_EDIT_SUCCESS, payload: data });
    } catch (error) {
      console.log(error.response, "error.response");
      dispatch({
        type: NOTIFICATION_EDIT_ERR,
        payload: error.response.data,
      });
      dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: true });
    }
  };