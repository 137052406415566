import React, { useEffect, useMemo, useState } from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableFooter,
  TableSortLabel,
  Menu,
  Paper,
  Alert,
  AlertTitle,
  Dialog,
} from "@mui/material";

import { makeStyles } from "@material-ui/core";

import { SlOptionsVertical } from "react-icons/sl";
import {
  Box,
  Checkbox,
  Grid,
  MenuItem,
  Modal,
  TableContainer,
  Typography,
} from "@mui/material";
import { TbEdit } from "react-icons/tb";
import { AiOutlineEye } from "react-icons/ai";
import { RiDeleteBin6Line } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import { MdNotInterested } from "react-icons/md";
import { BiSortAlt2 } from "react-icons/bi";
import usePrivilegeCheck from "../../constants/customHooks/UsePrivilegeCheck";

import "./LandingPage.css";

import { TopNavigationDeleteAction } from "../../actions/LandingPageAction";
import { Close } from "@mui/icons-material";
import AccessDeniedIcon from "../Leads/createLeads/AccessDeniedIcon";

// show role details popup
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 550,
  height: 600,
  overflow: "scroll",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 20,
  p: 4,
};

export const TopNavigationTable = ({
  columns,
  data,
  component,
  subMenu,
  //   isPresentMap,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // custom Hooks
  const {
    privilege,
    isUpdatePrevilage,
    isDeletePrevilage,
  } = usePrivilegeCheck();

  const [actionButtonShow, setActionButtonShow] = useState(null);
  const [tableId, setTableId] = useState("");
  const [openAlert, setOpenAlert] = useState(false);

  const openActionButton = Boolean(actionButtonShow);

  const handleClickAction = (event, id) => {
    console.log(id, "evide id kitttuvo");
    setActionButtonShow(event.currentTarget);
    setTableId(id);
  };

  const handleCloseAction = () => {
    setActionButtonShow(null);
  };

  //   // Delete Table

  const handleDeleteNavigation = (id) => {
    console.log(id, "id");
    dispatch(TopNavigationDeleteAction(id));
  };

  //   // navigate to Edit page

  const topNavigationEditHandler = (id) => {
    navigate(`/landingPage/topNavigationEditForm/${id}`);
  };

  const CustomSortIcon = ({ direction }) => {
    return direction === "asc" ? (
      <img
        src="/menuIcons/tableUpSort.png"
        className="administratCustomIcon"
        alt="gsl"
        style={{ padding: "15px" }}
      />
    ) : (
      <BiSortAlt2 className="administratCustomIcon" />
    );
  };

  // sorting table
  const [sortBy, setSortBy] = useState(""); // Current sort column
  const [sortOrder, setSortOrder] = useState("asc"); // Current sort order

  const requestSort = (column) => {
    let newSortOrder = "asc";

    if (column === sortBy && sortOrder === "asc") {
      newSortOrder = "desc";
    }

    setSortBy(column);
    setSortOrder(newSortOrder);
  };
  let sortedData = useMemo(() => {
    if (sortBy && data) {
      const sortedItems = [...data].sort((a, b) => {
        if (sortOrder === "asc") {
          return a[sortBy] < b[sortBy] ? -1 : 1;
        } else {
          return a[sortBy] < b[sortBy] ? 1 : -1;
        }
      });
      return sortedItems;
    }
    return data;
  }, [data, sortBy, sortOrder]);

  //   const handleClose = () => setOpen(false);

  // Create a function to handle selecting individual rows:

  // if (!hasPrivilege("isadministrationView") && privilege !== null) {
  //   return (
  //     <Alert severity="error">
  //       <AlertTitle>warning</AlertTitle>
  //       You don't have permission to access — <strong>this page.!</strong>
  //     </Alert>
  //   );
  // }

  console.log(sortedData, "haivishnu");
  return (
    <>
      <Table className="landingTable">
        <TableHead>
          <TableRow className="landingTableCell">
            <TableCell>
              <Checkbox
                // checked={selectedRows.length === sortedData.length}
                // onChange={handleSelectAllRows}
                style={{ color: "#f81b82" }}
              />
            </TableCell>
            {columns?.map((column) => {
              if (column.hidden) {
                return null;
              }
              return (
                <TableCell key={column.field} className="landingTableCell">
                  <TableSortLabel
                    className="landingHeaderText  "
                    active={column === sortBy}
                    direction={sortOrder}
                    onClick={() => requestSort(column)}
                    IconComponent={column.field !== "action" && CustomSortIcon}
                  >
                    {column.title}
                  </TableSortLabel>
                </TableCell>
              );
            })}
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedData?.map((item, indx) => (
            <TableRow className="landingTable" key={indx}>
              <TableCell>
                <Checkbox
                  // checked={selectedRows.includes(item.id)}
                  // onChange={() => handleSelectRow(item.id)}
                  style={{ color: "#f81b82" }}
                />
              </TableCell>
              <TableCell>{item.sl}</TableCell>
              <TableCell>{item.coloum1}</TableCell>
              <TableCell>{item.coloum2}</TableCell>
              <TableCell>{item.coloum3}</TableCell>
              <TableCell>{item.coloum4}</TableCell>

              <TableCell>
                <SlOptionsVertical
                  id="demo-positioned-button"
                  aria-controls={
                    openActionButton ? "demo-positioned-menu" : undefined
                  }
                  aria-haspopup="true"
                  aria-expanded={openActionButton ? "true" : undefined}
                  onClick={(e) => {
                    handleClickAction(e, item.id);
                  }}
                  variant="outlined"
                  style={{ marginLeft: "10px", color: "#F80B7A" }}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter />
      </Table>

      {/* TABLE ACTION MENU START*/}
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={actionButtonShow}
        open={openActionButton}
        onClose={handleCloseAction}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        anchorOrigin={{
          vertical: "bottom", // Change from "top" to "bottom"
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top", // Change from "top" to "bottom"
          horizontal: "left",
        }}
        sx={{
          borderColor: "black",
          borderRadius: "24px",
          mt: 1.5,
          width: "auto",
          height: "auto",
        }}
      >
        <Box style={{ display: "flex", justifyContent: "flex-end" }}>
        
            <MenuItem>
              <TbEdit
                style={{
                  fontSize: "lg",
                  width: "20px",
                  height: "19px",
                }}
                onClick={() => {
                  if (isUpdatePrevilage(subMenu)) {
                    topNavigationEditHandler(tableId);
                  } else {
                    setOpenAlert(true);
                  }
                }}
              
              
                className="icons"
              />
            </MenuItem>

          <MenuItem>
            {/* {component !=="holiday" ? (
                <RiDeleteBin6Line
                  onClick={() => RoledeleteHandler(tableId)}
                  className="icons"
                  style={{
                    fontSize: "lg",
                    width: "20px",
                    height: "19px",
                  }}
                />
              ) : ( */}
            <RiDeleteBin6Line
              onClick={() => {
                if (isDeletePrevilage(subMenu)) {
                  setActionButtonShow(false);
                  Swal.fire({
                    title: "Are you sure?",
                    text: "You won't be able to revert this!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes, delete it!",
                  }).then((result) => {
                    if (result.isConfirmed) {
                      handleDeleteNavigation(tableId);
                      Swal.fire(
                        "Deleted!",
                        "Your file has been deleted.",
                        "success"
                      );
                    }
                  });
                } else {
                  setOpenAlert(true);
                }
              }}
              style={{
                fontSize: "lg",
                width: "20px",
                height: "19px",
              }}
              className="icons"
            />
          </MenuItem>
        </Box>
      </Menu>

      <Dialog
        open={openAlert}
        onClose={() => setOpenAlert(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          zIndex: "9999",
          // background:"red",
          "& .MuiDialog-paper": {
            width: "100%",
            borderRadius: "5px",
            p: "15px",
            textAlign: "center",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "end",
          }}
        >
          <Close
            sx={{
              cursor: "pointer",
            }}
            onClick={() => setOpenAlert(false)}
          />
        </Box>
        <Typography
          sx={{
            color: "#05050F",
            fontSize: "26px",
            fontWeight: "700",
          }}
        >
          Access Denied
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            mt: "-10px",
          }}
        >
          <AccessDeniedIcon />
        </Box>
        <Typography
          sx={{
            color: "#717171",
            fontSize: "16px",
            mb: "10px",
          }}
        >
          Sorry, you don’t have permission
        </Typography>
      </Dialog>
    </>
  );
};
