import { Box, Typography } from "@mui/material";
import React from "react";
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

function ApplicationAndEnrollmentChart() {
  const data = [
    {
      name: "Jan",
      tl: 48,
      wl: 31,
    },
    {
      name: "Feb",
      tl: 34,
      wl: 38,
    },
    {
      name: "Mar",
      tl: 50,
      wl: 40,
    },
    {
      name: "Apr",
      tl: 58,
      wl: 30,
    },
    {
      name: "May",
      tl: 28,
      wl: 35,
    },
    {
      name: "Jun",
      tl: 40,
      wl: 55,
    },
    {
      name: "Jul",
      tl: 45,
      wl: 50,
    },
    {
      name: "Aug",
      tl: 22,
      wl: 65,
    },
    {
      name: "Sep",
      tl: 30,
      wl: 55,
    },
    {
      name: "Oct",
      tl: 48,
      wl: 55,
    },
    {
      name: "Nov",
      tl: 38,
      wl: 33,
    },
    {
      name: "Dec",
      tl: 30,
      wl: 40,
    },
  ];
  return (
    <Box
      sx={{
        backgroundColor: "rgba(255,255,255)",
        p: "20px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: "20px",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography
          sx={{
            color: "#05050FBF",
            fontSize: "16px",
          }}
        >
          Applications & Enrollment
        </Typography>
      </Box>

      <Box
        // width="100%"
        // height="100%"
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: "24px",
          mt: "40px",
          height:"300px",
        }}
      >
        {/* <BarChart
          width={500}
          height={300}
          data={data}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid vertical={false} />
          <Tooltip />
          <Legend />
          <Bar barSize={12} dataKey="tl" stackId="a" fill="#2D2F7B" />
          <Bar barSize={12} dataKey="wl" stackId="a" fill="#FE0B7A" />
        </BarChart> */}
        <ResponsiveContainer width="100%" height="100%">
          <LineChart width={800} height={300} data={data}>
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
            <Line
              type="monotone"
              dataKey="tl"
              strokeWidth={2}
              stroke="#5053EA"
            />
            <Line
              type="monotone"
              dataKey="wl"
              strokeWidth={2}
              stroke="#FF9364"
            />
          </LineChart>
        </ResponsiveContainer>
      </Box>
    </Box>
  );
}

export default ApplicationAndEnrollmentChart;
