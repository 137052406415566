import React, { useState } from "react";
import { MainContainer } from "../content/content.element";
import HeadBreadcrumbs from "../breadcrumbs/breadcrumbs";
import BasicTabs from "../Tabs/TabsMain";

function PartnerMain(props) {
  const [whichPage, setWhichPage] = useState(0);

  const callback = (index) => {
    setWhichPage(index);
  };
  return (
    <>
      <MainContainer active={props.toggle}>
        {/* {whichPage === "leadList" && ( */}
        <>
          <HeadBreadcrumbs
            head="home"
            sechead="Partner"
            first="Partner"
            main={
              whichPage === 0
                ? "All Partner"
                : whichPage === 1
                ? "Partner Pending"
                : whichPage === 2
                ? "Partner Approval"
                : whichPage === 3
                ? "Partner KYC Approving"
                : whichPage === 4
                ? "Partner Application"
                : whichPage === 5
                ? "Partner Entrolled Application"
                : null
            }
          />
          <BasicTabs
            tab1Name="All Partner"
            tab2Name="Partner Approval"
            tab3Name="Partner KYC Approving"
            tab4Name="Partner's Lead Application"
            tab5Name="Partner's Entrolled Application"
            tab1={props.tab1}
            tab2={props.tab2}
            tab3={props.tab3}
            tab4={props.tab4}
            tab5={props.tab5}
            head="Home"
            sechead="Partner"
            callback={callback}
          />
        </>
        {/* )} */}
      </MainContainer>
    </>
  );
}
export default PartnerMain;
