import axios from "axios";
import {
  COMMON_DOC_ADD_ERR,
  COMMON_DOC_ADD_REQUEST,
  COMMON_DOC_ADD_SUCCESS,
  COMMON_DOC_DELETE_ERR,
  COMMON_DOC_DELETE_REQUEST,
  COMMON_DOC_DELETE_SUCCESS,
  COMMON_DOC_FIND_ERR,
  COMMON_DOC_FIND_REQUEST,
  COMMON_DOC_FIND_SUCCESS,
  COMMON_DOC_IS_FRON_BACK_UPDATE_ERR,
  COMMON_DOC_IS_FRON_BACK_UPDATE_REQUEST,
  COMMON_DOC_IS_FRON_BACK_UPDATE_SUCCESS,
  COMMON_DOC_IS_MANDATORY_UPDATE_ERR,
  COMMON_DOC_IS_MANDATORY_UPDATE_REQUEST,
  COMMON_DOC_IS_MANDATORY_UPDATE_SUCCESS,
} from "../../constants/knowledgeBankConstant/commonDocsConstant";

// LoginSubmitAction
export const CommonDocumentApplicationAddAction =
  (DocName) => async (dispatch, getState) => {
    try {
      dispatch({ type: COMMON_DOC_ADD_REQUEST });

      let isUserExist = localStorage.getItem("loginInfo")
        ? JSON.parse(localStorage.getItem("loginInfo"))
        : null;

      const config = {
        headers: {
          Authorization: `Bearer ${isUserExist?.token}`,
        },
      };

      let { data } = await axios.post(
        "/api/admin/knowledgeBank/commonDocAdd",
        { DocName: DocName },
        config
      );

      dispatch({ type: COMMON_DOC_ADD_SUCCESS, payload: data });
    } catch (error) {
      console.log(error.response, "error.response");
      dispatch({ type: COMMON_DOC_ADD_ERR, payload: error.response.data });
    }
  };

export const CommonDocumentApplicationFindAction =
  () => async (dispatch, getState) => {
    try {
      dispatch({ type: COMMON_DOC_FIND_REQUEST });

      let isUserExist = localStorage.getItem("loginInfo")
        ? JSON.parse(localStorage.getItem("loginInfo"))
        : null;

      const config = {
        headers: {
          Authorization: `Bearer ${isUserExist?.token}`,
        },
      };

      let { data } = await axios.get(
        "/api/admin/knowledgeBank/commonDocFind",
        config
      );

      dispatch({ type: COMMON_DOC_FIND_SUCCESS, payload: data });
    } catch (error) {
      dispatch({ type: COMMON_DOC_FIND_ERR, payload: error.response.data });
    }
  };

// updateIsMandataryAction
export const updateIsMandatoryAction =
  (isMandatory, id) => async (dispatch, getState) => {
    try {
      dispatch({ type: COMMON_DOC_IS_MANDATORY_UPDATE_REQUEST });

      let isUserExist = localStorage.getItem("loginInfo")
        ? JSON.parse(localStorage.getItem("loginInfo"))
        : null;

      const config = {
        headers: {
          Authorization: `Bearer ${isUserExist?.token}`,
        },
      };

      let { data } = await axios.put(
        `/api/admin/knowledgeBank/commonDocMandatoryEdit?id=${id}`,
        { isMandatory: isMandatory },
        config
      );

      dispatch({ type: COMMON_DOC_IS_MANDATORY_UPDATE_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: COMMON_DOC_IS_MANDATORY_UPDATE_ERR,
        payload: error.response.data,
      });
    }
  };

// updateIsFrontAction
export const updateIsFrontAction =
  (isFrontBack, id) => async (dispatch, getState) => {
    try {
      dispatch({ type: COMMON_DOC_IS_FRON_BACK_UPDATE_REQUEST });

      let isUserExist = localStorage.getItem("loginInfo")
        ? JSON.parse(localStorage.getItem("loginInfo"))
        : null;

      const config = {
        headers: {
          Authorization: `Bearer ${isUserExist?.token}`,
        },
      };

      let { data } = await axios.put(
        `/api/admin/knowledgeBank/commonDocFrondBackEdit?id=${id}`,
        { isFrontBack: isFrontBack },
        config
      );

      dispatch({ type: COMMON_DOC_IS_FRON_BACK_UPDATE_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: COMMON_DOC_IS_FRON_BACK_UPDATE_ERR,
        payload: error.response.data,
      });
    }
  };

// deleteCommonDocAction
export const deleteCommonDocAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: COMMON_DOC_DELETE_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.delete(
      `/api/admin/knowledgeBank/commonDocDelete?id=${id}`,
      config
    );

    dispatch({ type: COMMON_DOC_DELETE_SUCCESS, payload: data });
    console.log(data, "delllllllleeeeeeeeeeeeeeeetttttt");
  } catch (error) {
    console.log(error.response, "delllllllleeeeeeeeeeeeeeeetttttt.response");
    dispatch({
      type: COMMON_DOC_DELETE_ERR,
      payload: error.response.data,
    });
  }
};
