import { Box, Button, Dialog, Grid, Paper, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { universityViewAction } from "../../../actions/universityActions/universityManagmentAction";
import { MainContainer } from "../../content/content.element";
import { InfoHeader } from "../../customComponent/InfoHeader";

import "./ViewUniversity.css";
import usePrivilegeCheck from "../../../constants/customHooks/UsePrivilegeCheck";
import AccessDeniedIcon from "../../Leads/createLeads/AccessDeniedIcon";
import { Close } from "@mui/icons-material";
import { getUniversityCoverImageAction } from "../../../actions/settings/settingsActions";

function ViewUniversity(props) {
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  const [openAlert, setOpenAlert] = useState(false);

  const { privilege, hasPrivilege, isUpdatePrevilage } = usePrivilegeCheck();

  const [viewData, setViewData] = useState({});

  const {
    universityViewByIdLoading,
    universityViewByIdSuccess,
    universityViewByIdErr,
  } = useSelector((state) => {
    return state.universityView;
  });

  // Response of getUniversityCoverImageAction

  let { getUniversityCoverImageSuccess } = useSelector(
    (state) => state.getUniversityCoverImage
  );

  // UseEffect to find Default universityCoverImage

  useEffect(() => {
    dispatch(getUniversityCoverImageAction());
  }, []);

  console.log(
    universityViewByIdSuccess,
    "universityViewByIdSuccessuniversityViewByIdSuccess"
  );

  useEffect(() => {
    dispatch(universityViewAction(id));
  }, [dispatch]);

  console.log(viewData, "viewDataviewData");

  useEffect(() => {
    if (universityViewByIdSuccess) {
      setViewData(universityViewByIdSuccess);
    }
  }, [universityViewByIdSuccess]);
  return (
    <>
      <MainContainer active={props.toggle}>
        <InfoHeader headTitle="University Details" url="/university" />
        <Box>
          <Grid
            container
            spacing={2}
            sx={{ marginTop: "15px", justifyContent: "space-between" }}
          >
            <Grid item xs={12} lg={9}>
              <div style={{ display: "flex" }}>
                <div style={{ margin: "10px" }}>
                  <img
                    alt="university_image"
                    src={
                      universityViewByIdSuccess?.universityImages.length !== 0
                        ? universityViewByIdSuccess?.universityImages[0]
                            ?.universityImageUrl
                        : getUniversityCoverImageSuccess.length !== 0
                        ? getUniversityCoverImageSuccess[0]?.image
                        : ""
                    }
                    style={{
                      borderRadius: "50px",
                      width: "80px",
                      height: "80px",
                      marginTop: "10px",
                    }}
                  />
                </div>
                <div style={{ marginTop: "50px", margin: "20px" }}>
                  <Typography
                    variant="h5"
                    style={{
                      fontWeight: 400,
                      fonFamily: "'Montserrat', sans-serif",
                      fontSize: "22px",
                    }}
                  >
                    {universityViewByIdSuccess?.length !== 0 &&
                      universityViewByIdSuccess?.basicInfo.universityName}
                  </Typography>
                  <Typography
                    variant="subtitle"
                    sx={{
                      fontFamily: "'Open Sans', sans-serif",
                      fontWeight: 400,
                      fontSize: "14px",
                    }}
                  >
                    {universityViewByIdSuccess?.length !== 0 &&
                      universityViewByIdSuccess?.basicInfo.universityLocation}
                  </Typography>
                </div>
              </div>
            </Grid>
            {/* </Grid>

        <Grid container spacing={2}> */}
            <Grid
              item
              xs={12}
              lg={2}
              sx={{
                display: "flex",
                justifyContent: "end",
              }}
            >
              {/* <div style={{ display: "flex", }}> */}
              {/* <Button
                variant="outlined"
                sx={{
                  marginRight: "10px",
                  width: "146px",
                  height: "35px",
                  color: "#141E3C",
                  borderColor: "#141E3C",
                  fontWeight: 700,
                  fontSize: "14px",
                  borderRadius: "5px",
                }} {/* <div style={{ display: "flex", }}>
              >
                scholarship
              </Button> */}
              <Button
                sx={{
                  marginRight: "10px",
                  width: "146px",
                  height: "35px",
                  color: "#141E3C",
                  borderColor: "#141E3C",
                  fontWeight: 700,
                  fontSize: "14px",
                  borderRadius: "5px",
                }}
                variant="outlined"
                onClick={() =>
                  navigate(
                    `/university/addUniversity/ViewUniversity/courses/${id}`
                  )
                }
              >
                View Course
              </Button>
              {/* </div> */}
            </Grid>
          </Grid>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              backgroundColor: "#F6F6F6",
            }}
          >
            <Typography
              variant="h5"
              sx={{
                m: "18px",
                fontWeight: 700,
                fontSize: "19px",
                fontFamily: "Montserrat', sans-serif",
              }}
            >
              Basic Information
            </Typography>
            {/* <TypographyText title=" Basic Information" variant="h4" style={{margin:"9px"}} /> */}
            <Button
              style={{
                display: "flex",
                justifyContent: "flex-end",
                margin: "10px",
                borderColor: "#141E3C",
                color: "black",
                fontSize: "15px",
                height: "35px",
                width: "70px",
                fontWeight: 600,
                borderRadius: "8px",
              }}
              variant="outlined"
              onClick={() => {
                if (isUpdatePrevilage("universities")) {
                  navigate(`/university/editUniversity/${id}`);
                } else {
                  setOpenAlert(true);
                }
              }}
            >
              Edit
            </Button>
          </div>

          <Paper elevation={0} sx={{ mt: 2, p: 2 }}>
            <Grid item xs={12} lg={6} sx={{ ml: "-13px" }}>
              <div style={{ display: "flex", justifyContent: "space-around" }}>
                <table
                  className="tableView"
                  style={{
                    width: "100%",
                    margin: "5px",
                  }}
                >
                  <tr>
                    <th className="tableHeader">Rank</th>
                    <td className="tabelTd">
                      {" "}
                      {universityViewByIdSuccess?.basicInfo.rank}
                    </td>
                  </tr>
                  <tr>
                    <th className="tableHeader">Country</th>
                    <td className="tabelTd">
                      {universityViewByIdSuccess?.basicInfo.universityCountry}
                    </td>
                  </tr>
                  <tr>
                    <th className="tableHeader">Established</th>
                    <td className="tabelTd">
                      {
                        universityViewByIdSuccess?.basicInfo
                          .universityEstablishedYear
                      }
                    </td>
                  </tr>
                </table>

                <table
                  className="tableView"
                  style={{ width: "100%", margin: "5px" }}
                >
                  {/* <tr>
                    <th className="tableHeader">University State</th>
                    <td className="tabelTd">
                      {" "}
                      {universityViewByIdSuccess?.basicInfo.universityState}
                    </td>
                  </tr>
                  <tr>
                    <th className="tableHeader">University city</th>
                    <td className="tabelTd">
                      {universityViewByIdSuccess?.basicInfo.universityCity}
                    </td>
                  </tr> */}
                  <tr>
                    <th className="tableHeader">Course</th>
                    <td className="tabelTd">
                      {universityViewByIdSuccess?.courseCount}
                    </td>
                  </tr>
                </table>
              </div>
            </Grid>
          </Paper>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              backgroundColor: "#F6F6F6",
              marginTop: "8px",
            }}
          >
            <Typography
              variant="h5"
              sx={{
                m: "18px",
                fontWeight: 700,
                fontSize: "19px",
                fontFamily: "Montserrat', sans-serif",
              }}
            >
              About
            </Typography>
          </div>

          {/* <Paper sx={{ mt: 2, p: 1 }}> */}
          <Typography
            sx={{
              m: 2,
              fontWeight: 400,
              fontSize: "15px",
              fontFamily: "'Poppins', sans-serif",
            }}
          >
            {universityViewByIdSuccess?.basicInfo.aboutUniversity}
            {/* The University of Cambridge is the second-oldest university in the English-speaking world and is regarded as one of the world's top five universities. The University is a member of the Russell Group, a network of research-led British universities; the Coimbra Group, an association of leading European universities; the League of European Research Universities; and the International Alliance of Research Universities. It is also considered part of the "Golden Triangle", a geographical concentration of leading research universities.
As a collegiate university, Cambridge is unusual for having no campus and as such is a group of 31 self-governing colleges coming under one Cambridge banner. Each college is a part of Cambridge whilst being largely independent. They appoint their own teaching staff and fellows in each subject, decide which students to admit, provide the supervision teaching for undergraduates, and are responsible for the domestic arrangements and welfare of students and staff. Choosing the right college is crucial, both to ensure the student win a place, and also ensuring the degree is a successful one.
Cambridge has produced 89 Nobel winners to date, more than any other university, while Trinity College alone has produced more Nobel prize winners than France. University of Cambridge is also the third biggest landowner in the country, after the Queen and the Church of England. */}
          </Typography>
          {/* </Paper> */}

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              backgroundColor: "#F6F6F6",
              marginTop: "8px",
            }}
          >
            <Typography
              variant="h5"
              sx={{
                m: "18px",
                fontWeight: 700,
                fontSize: "19px",
                fontFamily: "Montserrat', sans-serif",
              }}
            >
              Images
            </Typography>
            {/* <Button
            style={{
              display: "flex",
              justifyContent: "flex-end",
              margin: "10px",
              borderColor: "#141E3C",
              color: "black",
              fontSize: "15px",
              height: "35px",
              width: "auto",
              fontWeight: 600,
              borderRadius: "8px",
            }}
            variant="outlined"
          >
            Upload
          </Button> */}
          </div>

          <Grid item xs={4} lg={3} sx={{ m: 3 }}>
            {universityViewByIdSuccess?.universityImages.map((imageData) => {
              return (
                <img
                  alt="university_image"
                  src={imageData.universityImageUrl}
                  style={{
                    width: "200px",
                    height: "auto",
                    padding: "10px",
                  }}
                />
              );
            })}
          </Grid>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              backgroundColor: "#F6F6F6",
              marginTop: "8px",
            }}
          >
            <Typography
              variant="h5"
              sx={{
                m: "18px",
                fontWeight: 700,
                fontSize: "19px",
                fontFamily: "Montserrat', sans-serif",
              }}
            >
              Videos
            </Typography>
            {/* <Button
            style={{
              display: "flex",
              justifyContent: "flex-end",
              margin: "10px",
              borderColor: "#141E3C",
              color: "black",
              fontSize: "15px",
              height: "35px",
              width: "auto",
              fontWeight: 600,
              borderRadius: "8px",
            }}
            variant="outlined"
          >
            Upload
          </Button> */}
          </div>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            {universityViewByIdSuccess?.universityVideos.map((videoData) => {
              // const regex =
              //   /(?:youtube\.com\/(?:[^/]+\/[^/]+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=|.*[?&]vi=)|youtu\.be\/)([^"&?/ ]{11})/;
              // const match = videoData.universityVideoUrl.match(regex);
              // const videoId = match ? match[1] : null;
              return (
                <Grid item xs={12} md={6} lg={4}>
                  <Paper
                    sx={{
                      display: "flex",
                      // justifyContent: "center",
                      // alignItems: "center",
                      marginBottom: "10px",
                    }}
                    elevation={0}
                  >
                    <div
                      style={{
                        width: "100%",
                        height: "auto",
                        display: "flex",
                      }}
                      dangerouslySetInnerHTML={{
                        __html: videoData.universityVideoUrl,
                      }}
                    />
                  </Paper>
                  {/* <iframe
                  width="420"
                  height="280"
                  src={`https://www.youtube.com/embed/${videoId}`}
                  title="YouTube video player"
                  frameBorder="0"
                  showinfo="0"
                  controls="0"
                  autohide="1"
                ></iframe>{" "} */}
                </Grid>
              );
            })}
          </Grid>

          {/* <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              backgroundColor: "#F6F6F6",
              marginTop: "8px",
            }}
          >
            <Typography
              variant="h5"
              sx={{
                m: "18px",
                fontWeight: 700,
                fontSize: "19px",
                fontFamily: "Montserrat', sans-serif",
              }}
            >
              Ranking
            </Typography>
          </div>

          <Grid container spacing={2} sx={{ mt: 1 }}>
            {universityViewByIdSuccess?.universityRanking.map((item) => {
              return (
                <Grid item xs={2} sx={{ ml: 2 }}>
                  <Paper
                    elevation={1}
                    sx={{
                      width: "210px",
                      height: "120px",
                      mt: 2,
                      textAlign: "center",
                    }}
                  >
                    <img
                      style={{ width: "75px", height: "29px", margin: "15px" }}
                      src={item?.university_master?.university_master_icon_url}
                      alt="gsl_ranking"
                    />
                    <Box
                      sx={{
                        backgroundColor: "#F2F2F2",
                        height: "25px",
                        textAlign: "center",
                      }}
                    >
                      <Typography sx={{ fontSize: "14px", fontWeight: 700 }}>
                        {item.Rankname}
                      </Typography>
                    </Box>
                  </Paper>
                </Grid>
              );
            })}
          </Grid> */}

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              backgroundColor: "#F6F6F6",
              marginTop: "15px",
            }}
          >
            <Typography
              variant="h5"
              sx={{
                m: "18px",
                fontWeight: 700,
                fontSize: "19px",
                fontFamily: "Montserrat', sans-serif",
              }}
            >
              Accomodation
            </Typography>
            {/* <Button
            style={{
              display: "flex",
              justifyContent: "flex-end",
              margin: "10px",
              borderColor: "#141E3C",
              color:"black",
              fontSize:"15px",
              height:"35px",
              width:"auto",
              fontWeight:600,
              borderRadius:"8px"
            }}
            variant="outlined"
          >
            Upload
          </Button> */}
          </div>
        </Box>
      </MainContainer>

      <Dialog
        open={openAlert}
        onClose={() => setOpenAlert(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          zIndex: "9999",
          // background:"red",
          "& .MuiDialog-paper": {
            width: "100%",
            borderRadius: "5px",
            p: "15px",
            textAlign: "center",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "end",
          }}
        >
          <Close
            sx={{
              cursor: "pointer",
            }}
            onClick={() => setOpenAlert(false)}
          />
        </Box>
        <Typography
          sx={{
            color: "#05050F",
            fontSize: "26px",
            fontWeight: "700",
          }}
        >
          Access Denied
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            mt: "-10px",
          }}
        >
          <AccessDeniedIcon />
        </Box>
        <Typography
          sx={{
            color: "#717171",
            fontSize: "16px",
            mb: "10px",
          }}
        >
          Sorry, you don’t have permission
        </Typography>
      </Dialog>
    </>
  );
}

export default ViewUniversity;
