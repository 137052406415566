import React, { useEffect, useRef, useState } from "react";
import { Box, Button, Modal, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import { useParams } from "react-router-dom";
import io from "socket.io-client";

import {
  chatWithCounsilorAction,
  counsilorNotificationClearAction,
  getchatWithCounsilorAction,
} from "../../../actions/leadChatAction";
import useChatSocket from "../../../constants/customHooks/useChatSocket";
import { socketUrl } from "../../../constants/customHooks/url";

export const Chat = () => {
  const socket = io(socketUrl, {
    transports: ["websocket"],
  });

  const { id } = useParams();
  const dispatch = useDispatch();
  const chatContentRef = useRef(null);

  const chatSocket = useChatSocket();

  let userData = JSON.parse(localStorage.getItem("loginInfo"));

  // const [notifications, setNotifications] = useState([]);
  const [chats, setChats] = useState([]);
  const [inputData, setInputData] = useState();
  const [isHeightFetched, setIsHeightFetched] = useState(false);
  const [image, setImage] = useState("");
  const [open, setOpen] = useState(false);
  const [emit, setEmit] = useState("");

  let { getChatWithCounselorSuccess } = useSelector(
    (state) => state.getChatWithCounsilor
  );
  let { chatWithCounselorSuccess } = useSelector(
    (state) => state.chatWithCounsilor
  );

  let { counselorStatusChangeSuccess } = useSelector(
    (state) => state.counsilorNotificationClear
  );

  useEffect(() => {
    if (getChatWithCounselorSuccess) {
      socket.emit("message", { id: "hai" });
      setChats(getChatWithCounselorSuccess?.data);
    }
  }, [getChatWithCounselorSuccess]);

  useEffect(() => {
    // Listen for incoming messages
    socket.on("message", (message) => {
      setEmit((prevChats) => [...prevChats, message]);
    });

    return () => {
      // Clean up the event listener when the component unmounts
      socket.off("message");
    };
  }, []);

    useEffect(() => {
      setIsHeightFetched(true);
    }, [chats]);

    useEffect(() => {
      setIsHeightFetched(false);
      scrollToBottom();
    }, [isHeightFetched]);

  useEffect(() => {
    dispatch(counsilorNotificationClearAction(id, userData?.UserId));
  }, [id, chatWithCounselorSuccess, emit]);

  useEffect(() => {
    setInputData((prev) => ({
      ...prev,
      senderId: id,
      receiverId: userData?.UserId,
    }));
    dispatch(getchatWithCounsilorAction(id, userData?.UserId));
  }, [id, chatWithCounselorSuccess, emit]);

  // useEffect(() => {
  //   dispatch(getchatWithCounsilorAction(id, userData?.UserId));
  // }, [emit]);

  const handleSubmit = () => {
    if (inputData.message !== "") {
      const formData = new FormData();
      formData.append("message", inputData.message);
      formData.append("receiverId", inputData.senderId);
      formData.append("senderId", inputData.receiverId);
      dispatch(chatWithCounsilorAction(formData));

      setInputData({
        message: "",
        senderId: id,
        receiverId: userData?.UserId,
      });
    } else if (inputData.uploadedFile !== "") {
      const formData = new FormData();
      formData.append("message", inputData.uploadedFile);
      formData.append("receiverId", inputData.senderId);
      formData.append("senderId", inputData.receiverId);

      dispatch(chatWithCounsilorAction(formData));

      setInputData({
        message: "",
        senderId: id,
        receiverId: userData?.UserId,
      });
    }
    console.log(inputData);
  };

  const handleChange = (e) => {
    if (e.target.name === "uploadedFile") {
      let files = e.target.files[0];
      setInputData({
        ...inputData,
        ["message"]: "",
        ["uploadedFile"]: files,
      });
    } else {
      setInputData({ ...inputData, [e.target.name]: e.target.value });
      socket.emit("leadTyping", e.target.value);
    }
  };

  const showTime = (time) => {
    const date = new Date(time);

    const hours = date.getHours();
    const minutes = date.getMinutes();
    const formattedHours = hours % 12 || 12;
    const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;

    const period = hours < 12 ? "AM" : "PM";
    const formattedTime = `${formattedHours}:${formattedMinutes} ${period}`;
    return formattedTime;
  };

  const scrollToBottom = () => {
    chatContentRef.current.scrollTop = chatContentRef.current.scrollHeight;
  };

  const showContent = (item) => {
    let imgMatchResult = item.match(/\/([^\/?#]+)\.(png|jpg|jpeg|gif)$/i);
    let pdfMatchResult = item.match(/\/([^\/?#]+)\.pdf/i);

    if (pdfMatchResult && pdfMatchResult.length > 0) {
      return (
        <Box>
          <Box
            sx={{
              width: "100%",
              height: "100%",
              overflow: "hidden",
            }}
          >
            <embed
              src={item}
              type="application/pdf"
              width="100%"
              height="100%"
              download="false"
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
              px: "20px",
              mb: "6px",
            }}
          >
            <Typography
              component={"a"}
              href={item}
              sx={{
                fontSize: "16px",
                color: "#FF1B6F",
                textDecoration: "none",
              }}
              target="_blank"
            >
              View
            </Typography>
          </Box>
        </Box>
      );
    } else if (imgMatchResult && imgMatchResult.length > 0) {
      return (
        <>
          <Box
            sx={{
              padding: "8px 12px",
              borderRadius: "0 12px 12px 12px",
              background: "#F6F6F6",
              maxWidth: "291px",
              "& img": {
                width: "100%",
                height: "100%",
                objectFit: "contain",
                cursor: "pointer",
              },
            }}
          >
            <img src={item} alt={item} onClick={() => handleOpen(item)} />
          </Box>
        </>
      );
    } else {
      return (
        <Typography
          component={"p"}
          sx={{
            color: "#686868",
            fontSize: "16px",
            lineHeight: "24px",
          }}
        >
          {item}
        </Typography>
      );
    }
  };

  const handleOpen = (item) => {
    setOpen(true);
    setImage(item);
  };
  const handleClose = () => {
    setOpen(false);
    setImage("");
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            width: "calc(100% - 50px)",
            margin: "auto",
            height: "calc(100vh - 10px)",
            maxWidth: "500px",
            maxHeight: "250px",
          }}
        >
          <Box
            sx={{
              width: "100%",
              height: "100%",
              // background:"#fff",
              "& img": {
                width: "100%",
                height: "100%",
                objectFit: "contain",
              },
            }}
          >
            <img src={image} alt="" />
          </Box>
        </Box>
      </Modal>

      <Box
        sx={{
          width: "100%",
          padding: "4px 24px",
          paddingRight: "0",
          display: "flex",
          flexDirection: "column",
          height: "100%",
          gap: {
            xs: "24px",
            md: "50px",
            xl: "78px",
          },
        }}
      >
        {chats?.chat?.length > 0 ? (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              paddingRight: "24px",
              gap: "24px",
              height: "48vh",
              overflowY: "scroll",
              transition: "2s ease",
              scrollBehavior: "smooth",
              "&::-webkit-scrollbar": {
                width: "5px",
                display: "block !important",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#141E3C !important",
                borderRadius: "10px",
              },
            }}
            ref={chatContentRef}
          >
            {chats?.chat?.map((item) =>
              item.receiverId === id ? (
                <Box
                  key={item._id}
                  sx={{
                    display: "flex",
                    flexDirection: "row-reverse",
                    gap: "24px",
                    "& > img": {
                      width: "40px",
                      height: "40px",
                      objectFit: "cover",
                      borderRadius: "50%",
                    },
                  }}
                >
                  <img
                    src={
                      chats.sender.img_url
                        ? chats.sender.img_url
                        : "/avatar.png"
                    }
                    alt="reciever image"
                  />
                  <Box>
                    <Box
                      sx={{
                        padding: "8px 12px",
                        borderRadius: "0 12px 12px 12px",
                        background: "#F6F6F6",
                      }}
                    >
                      {showContent(item.chat)}
                    </Box>
                    <Typography
                      sx={{
                        color: "#000000",
                        fontSize: "12px",
                        lineHeight: "24px",
                        textAlign: "left",
                      }}
                      component={"p"}
                    >
                      {showTime(item.time)}
                    </Typography>
                  </Box>
                </Box>
              ) : (
                <Box
                  key={item._id}
                  sx={{
                    display: "flex",
                    gap: "24px",
                    "& > img": {
                      width: "40px",
                      height: "40px",
                      objectFit: "cover",
                      borderRadius: "50%",
                    },
                  }}
                >
                  <img
                    src={
                      chats.receiver.imgUrl
                        ? chats.receiver.imgUrl
                        : "/avatar.png"
                    }
                    alt="sender profile pic"
                  />
                  <Box>
                    <Box
                      sx={{
                        padding: "8px 12px",
                        borderRadius: "0 12px 12px 12px",
                        background: "#F6F6F6",
                      }}
                    >
                      {showContent(item.chat)}
                    </Box>
                    <Typography
                      sx={{
                        color: "#000000",
                        fontSize: "12px",
                        lineHeight: "24px",
                        textAlign: "right",
                      }}
                      component={"p"}
                    >
                      {showTime(item.time)}
                    </Typography>
                  </Box>
                </Box>
              )
            )}

            {/* <Box
            sx={{
              display: "flex",
              gap: "24px",
              "& > img": {
                width: "40px",
                height: "40px",
                objectFit: "cover",
                borderRadius: "50%",
              },
            }}
          >
            <img src={clientImg ? clientImg : "sasa/avatar.png"} alt="" />
            <Box
              sx={{
                padding: "8px 12px",
                borderRadius: "0 12px 12px 12px",
                background: "#F6F6F6",
              }}
            >
              <Typography
                component={"p"}
                sx={{
                  color: "#686868",
                  fontSize: "16px",
                  lineHeight: "24px",
                }}
              >
                How much will be the course fee?
              </Typography>
              <Typography
                sx={{
                  color: "#777AAE",
                  fontSize: "14px",
                  lineHeight: "24px",
                }}
                component={"p"}
              >
                06:57 PM
              </Typography>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row-reverse",
              gap: "24px",
              "& > img": {
                width: "40px",
                height: "40px",
                objectFit: "cover",
                borderRadius: "50%",
              },
            }}
          >
            <img
              src={counslorImg ? counslorImg : "sasa/avatar.png"}
              alt=""
            />
            <Box
              sx={{
                padding: "8px 12px",
                borderRadius: "0 12px 12px 12px",
                background: "#F6F6F6",
              }}
            >
              <Typography
                component={"p"}
                sx={{
                  color: "#686868",
                  fontSize: "16px",
                  lineHeight: "24px",
                }}
              >
                How much will be the course fee?
              </Typography>
              <Typography
                sx={{
                  color: "#777AAE",
                  fontSize: "14px",
                  lineHeight: "24px",
                }}
                component={"p"}
              >
                06:57 PM
              </Typography>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              gap: "24px",
              "& > img": {
                width: "40px",
                height: "40px",
                objectFit: "cover",
                borderRadius: "50%",
              },
            }}
          >
            <img src={clientImg ? clientImg : "sasa/avatar.png"} alt="" />
            <Box
              sx={{
                padding: "8px 12px",
                borderRadius: "0 12px 12px 12px",
                background: "#F6F6F6",
                maxWidth: "291px",
                "& img": {
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                },
              }}
            >
              <img
                src="https://www.typecalendar.com/wp-content/uploads/2023/01/Personal-Letter.jpg"
                alt=""
              />
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row-reverse",
              gap: "24px",
              "& > img": {
                width: "40px",
                height: "40px",
                objectFit: "cover",
                borderRadius: "50%",
              },
            }}
          >
            <img
              src={counslorImg ? counslorImg : "sasa/avatar.png"}
              alt=""
            />
            <Box
              sx={{
                padding: "8px 12px",
                borderRadius: "0 12px 12px 12px",
                background: "#F6F6F6",
              }}
            >
              <Typography
                component={"p"}
                sx={{
                  color: "#686868",
                  fontSize: "16px",
                  lineHeight: "24px",
                }}
              >
                How much will be the course fee?
              </Typography>
              <Typography
                sx={{
                  color: "#777AAE",
                  fontSize: "14px",
                  lineHeight: "24px",
                }}
                component={"p"}
              >
                06:57 PM
              </Typography>
            </Box>
          </Box> */}
          </Box>
        ) : (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              paddingRight: "24px",
              gap: "24px",
              height: "48vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            ref={chatContentRef}
          >
            <Typography
              component={"p"}
              sx={{
                color: "#686868",
                fontSize: "16px",
                lineHeight: "24px",
              }}
            >
              No previous chat available
            </Typography>
          </Box>
        )}

        <Box
          sx={{
            position: "relative",
            borderRadius: "16px",
            border: "1px solid #BDBEDC",
            marginRight: "24px",
            backgroundColor: "rgba(255,255,255)",
            display: "grid",
            gridTemplateColumns: "1fr 64px",
            gap: "10px",
            padding: "10px 24px",
            width: "100%",
            boxSizing: "border-box",
            "& textarea": {
              resize: "none",
              width: "100%",
              border: "0",
              outline: "none",
              // borderRadius: "16px",
              fontSize: "16px",
              lineHeight: "26px",
              // maxHeight: "30px",
              // mb: "42px",
            },
            "& textarea::placeholder": {
              color: "#BDBEDC",
              fontSize: "16px",
              lineHeight: "26px",
            },
          }}
        >
          {inputData?.uploadedFile && (
            <Box
              sx={{
                // height: "98px",
                background: "#DADBE4",
                width: "fit-content",
                border: "1px solid #DADBE4",
                borderRadius: "5px",
                margin: "10px 20px",
                position: "relative",
              }}
            >
              <CloseIcon
                sx={{
                  position: "absolute",
                  right: "-5px",
                  top: "-5px",
                  fontSize: "15px",
                  padding: "2px",
                  background: "#FF1B6F",
                  borderRadius: "50%",
                  color: "#FFFFFF",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setInputData({ ...inputData, ["uploadedFile"]: "" });
                }}
              />
              <Box
                sx={{
                  padding: "10px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 40 40"
                  width="40px"
                  height="40px"
                >
                  <path
                    fill="#fff"
                    d="M6.5 37.5L6.5 2.5 24.793 2.5 33.5 11.207 33.5 37.5z"
                  />
                  <path
                    fill="#788b9c"
                    d="M24.586,3L33,11.414V37H7V3H24.586 M25,2H6v36h28V11L25,2L25,2z"
                  />
                  <path
                    fill="#fff"
                    d="M24.5 11.5L24.5 2.5 24.793 2.5 33.5 11.207 33.5 11.5z"
                  />
                  <path
                    fill="#788b9c"
                    d="M25 3.414L32.586 11H25V3.414M25 2h-1v10h10v-1L25 2 25 2zM12 16H28V17H12zM12 19H24V20H12zM12 22H28V23H12zM12 25H24V26H12zM12 28H28V29H12z"
                  />
                </svg>
              </Box>
              <Typography
                sx={{
                  color: "#696E72",
                  backgroundColor: "#FFFFFF",
                  padding: "5px 10px",
                }}
              >
                {inputData?.uploadedFile?.name}
              </Typography>
            </Box>
          )}
          {!inputData?.uploadedFile && (
            <textarea
              id=""
              cols="10"
              placeholder="Type Here"
              name="message"
              onChange={(e) => handleChange(e)}
              value={inputData?.message}
            ></textarea>
          )}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              // position: "absolute",
              // bottom: "5px",
              // right: "24px",
              gap: "7px",
              background: "#fff",
            }}
          >
            <Box
              sx={{
                width: "24px",
                overflow: "hidden",
              }}
            >
              {!inputData?.uploadedFile && (
                <Button
                  component={"label"}
                  sx={{
                    padding: 0,
                    backgroundColor: "none",
                    display: "block",
                  }}
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M20.8392 9.57368L11.0444 19.2489C9.4215 20.8521 6.78996 20.8521 5.16703 19.2489C3.54409 17.6456 3.54409 15.0466 5.16703 13.4433L13.9828 4.73575C15.065 3.66712 16.8187 3.66712 17.9009 4.73575C18.9831 5.80434 18.9831 7.53751 17.9009 8.6061L9.08507 17.3137C8.54432 17.8484 7.66714 17.8484 7.1257 17.3137C6.58496 16.7798 6.58496 15.9132 7.1257 15.3786L14.9618 7.6385L13.9821 6.67093L6.14673 14.4116C5.06454 15.4803 5.06454 17.2134 6.14673 18.282C7.22891 19.3506 8.98258 19.3506 10.0648 18.282L18.8806 9.57437C20.5035 7.97113 20.5035 5.3721 18.8806 3.76883C17.2576 2.1656 14.6261 2.1656 13.0032 3.76883L3.69751 12.9602L3.73123 12.9939C2.04085 15.1407 2.18946 18.2442 4.18736 20.2171C6.18526 22.1901 9.32587 22.3379 11.4999 20.6673L11.5336 20.7009L21.8182 10.5419L20.8392 9.57368Z"
                      fill="#BDBEDC"
                    />
                  </svg>
                  <input
                    type="file"
                    onChange={(e) => handleChange(e)}
                    // value={inputData.uploadedFile}
                    name="uploadedFile"
                    hidden
                    accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
                  />
                </Button>
              )}
            </Box>
            <Box
              onClick={handleSubmit}
              sx={{
                cursor: "pointer",
                "& svg circle": {
                  transition: "0.5s ease",
                },
                "&:hover svg circle": {
                  stroke: "#141E3C",
                  fill: "#fff",
                },
                "&:hover svg path": {
                  stroke: "#141E3C",
                  fill: "#141E3C",
                },
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="33"
                height="32"
                viewBox="0 0 33 32"
                fill="none"
              >
                <circle cx="16.7031" cy="16" r="16" fill="#141E3C" />
                <path
                  d="M25.8563 16.2791H14.2219"
                  stroke="white"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M25.858 16.2789L11.5794 23.1538L14.2236 16.2789L11.5794 9.404L25.858 16.2789Z"
                  stroke="white"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};
