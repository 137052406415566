export const UNIVERSITY_MASTER_SUBMIT_REQUEST =
  "UNIVERSITY_MASTER_SUBMIT_REQUEST";
export const UNIVERSITY_MASTER_SUBMIT_SUCCESS =
  "UNIVERSITY_MASTER_SUBMIT_SUCCESS";
export const UNIVERSITY_MASTER_SUBMIT_ERR = "UNIVERSITY_MASTER_SUBMIT_ERR";

export const UNIVERSITY_MASTER_FIND_REQUEST = "UNIVERSITY_MASTER_FIND_REQUEST";
export const UNIVERSITY_MASTER_FIND_SUCCESS = "UNIVERSITY_MASTER_FIND_SUCCESS";
export const UNIVERSITY_MASTER_FIND_ERR = "UNIVERSITY_MASTER_FIND_ERR";

export const UNIVERSITY_MASTER_BY_TYPE_ID_FIND_REQUEST =
  "UNIVERSITY_MASTER_BY_TYPE_ID_FIND_REQUEST";
export const UNIVERSITY_MASTER_BY_TYPE_ID_FIND_SUCCESS =
  "UNIVERSITY_MASTER_BY_TYPE_ID_FIND_SUCCESS";
export const UNIVERSITY_MASTER_BY_TYPE_ID_FIND_ERR =
  "UNIVERSITY_MASTER_BY_TYPE_ID_FIND_ERR";

export const UNIVERSITY_MASTER_SUB_DELETE_REQUEST =
  "UNIVERSITY_MASTER_SUB_DELETE_REQUEST";
export const UNIVERSITY_MASTER_SUB_DELETE_SUCCESS =
  "UNIVERSITY_MASTER_SUB_DELETE_SUCCESS";
export const UNIVERSITY_MASTER_SUB_DELETE_ERR =
  "UNIVERSITY_MASTER_SUB_DELETE_ERR";

export const UNIVERSITY_MASTER_EDIT_REQUEST = "UNIVERSITY_MASTER_EDIT_REQUEST";
export const UNIVERSITY_MASTER_EDIT_SUCCESS = "UNIVERSITY_MASTER_EDIT_SUCCESS";
export const UNIVERSITY_MASTER_EDIT_ERR = "UNIVERSITY_MASTER_EDIT_ERR";
