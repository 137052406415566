import React, { useEffect, useState } from "react";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Dialog,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Skeleton,
  TextField,
  Typography,
} from "@mui/material";

import Swal from "sweetalert2";

import { useDispatch, useSelector } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";

import {
  findMasterbyTypeIDAction,
  universityMasterAddAction,
  universityMasterFindAction,
} from "../../../actions/universityActions/universityMasterManagmentAction";
import { TypographyText } from "../../customComponent/Typography";
import { TableWithoutCoulumnVisible } from "../../Table/TableWithoutCoulumnVisible";
import { OutLinedButton, SubmitButton } from "../../customComponent/Buttons";
import usePrivilegeCheck from "../../../constants/customHooks/UsePrivilegeCheck";
import { successTrueMsgAction } from "../../../actions/globalAction";
import { Close } from "@mui/icons-material";
import AccessDeniedIcon from "../../Leads/createLeads/AccessDeniedIcon";

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginBottom: theme.spacing(2),
    marginTop: "8px",
  },
  hoverElement: {
    "&:hover": {
      color: "#FE0B7A", // Set the hover color
    },
    "&.clicked": {
      color: "#FE0B7A", // Set the clicked color
    },
  },
}));

const MasterType = [
  { id: 1, masterTypeName: "University Type" },
  { id: 2, masterTypeName: "University Affiliation Master" },
  // { id: 3, masterTypeName: "University Rank Master" },
  { id: 4, masterTypeName: "University Facilities" },
];

const UniversityMasterMain = () => {
  const dispatch = useDispatch();
  const { privilege, hasPrivilege, isCreatePrevilage } = usePrivilegeCheck();

  const classes = useStyles();
  const [university, setUniversity] = useState("");
  const [name, setName] = useState("");
  const [file, setFile] = useState(null);
  const [errors, setErrors] = useState({});
  const [clicked, setClicked] = useState("");
  const [masterTypeData, setMasterTypeData] = useState("University Type");
  const [errorType, setErrorType] = useState("");
  const [typeData, setTypeData] = useState([]);
  const [countData, setCountData] = useState({
    findCount1: 0,
    findCount2: 0,
    findCount3: 0,
    findCount4: 0,
  });
  const [openAlert, setOpenAlert] = useState(false);
  const [clickedId, setClickedId] = useState("");

  console.log(typeData, "typeDatatypeDatatypeDatatypeData");

  // const [errorAddMaster, setErrorAddMaster] = useState("");

  let { universityMasterAddSuccess, universityMasterAddErr } = useSelector(
    (state) => {
      return state.universityMasterAdd;
    }
  );

  let {
    universityMasterEditSuccess,
    universityMasterEditErr,
    universityMasterEditMessage,
  } = useSelector((state) => {
    return state.universityMasterEdit;
  });

  let { universityMasterFindSuccess } = useSelector((state) => {
    return state.universityMasterFind;
  });

  let {
    universityMasterFindByIdLoading,
    universityMasterFindByIdSuccess,
  } = useSelector((state) => {
    return state.findMasterbyTypeID;
  });

  let { universityMasterDeleteByIdSuccess } = useSelector((state) => {
    return state.deleteUniversityMaster;
  });

  let { successAlertMsgSuccess, successAlertMsgError } = useSelector(
    (state) => {
      return state.successTrueMsg;
    }
  );

  useEffect(() => {
    if (universityMasterAddSuccess && successAlertMsgSuccess) {
      Swal.fire("Good job!", `university Added Successfully`, "success");
      dispatch(successTrueMsgAction(false));
      setUniversity("");
      setName("");
      setFile(null);
      setErrors({});
    }
  }, [universityMasterAddSuccess, successAlertMsgSuccess]);

  useEffect(() => {
    if (universityMasterFindByIdSuccess) {
      setTypeData(universityMasterFindByIdSuccess);
    }
  }, [universityMasterFindByIdSuccess]);

  useEffect(() => {
    if (universityMasterFindSuccess) {
      setCountData({
        findCount1: universityMasterFindSuccess.findCount1,
        findCount2: universityMasterFindSuccess.findCount2,
        findCount3: universityMasterFindSuccess.findCount3,
        findCount4: universityMasterFindSuccess.findCount4,
      });
    }
  }, [universityMasterFindSuccess, universityMasterDeleteByIdSuccess]);

  useEffect(() => {
    dispatch(universityMasterFindAction());
  }, [dispatch, universityMasterAddSuccess, universityMasterDeleteByIdSuccess]);

  useEffect(() => {
    if (universityMasterEditSuccess && successAlertMsgSuccess) {
      dispatch(findMasterbyTypeIDAction(universityMasterEditSuccess?.data));
      Swal.fire("Updated !", "Updated successfully.", "success");
      dispatch(successTrueMsgAction(false));
    }
  }, [universityMasterEditSuccess]);

  useEffect(() => {
    if (universityMasterAddErr) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `${universityMasterAddErr}`,
      });
    }
  }, [universityMasterAddErr]);

  const handleClick = (master, id) => {
    dispatch(findMasterbyTypeIDAction(id));
    setClicked(master);
    setClickedId(id);
  };

  const columns = [
    {
      title: "Sl no",
      field: "sl",
      // type: "numeric",
      // hidden: true,
      headerStyle: { backgroundColor: "#F9F9FB" },
    },
    {
      title: "MasterId",
      field: "masterId",
      // type: "numeric",
      // hidden: true,
      headerStyle: { backgroundColor: "#F9F9FB" },
    },
    {
      title: "Name",
      field: "name",
      //   hidden: showEmployeeNameColumn,
      headerStyle: { backgroundColor: "#F9F9FB" },
    },
    {
      title: "icon",
      field: "icon",
      //   hidden: showEmailColumn,
      headerStyle: { backgroundColor: "#F9F9FB" },
    },

    {
      title: "Action",
      field: "action",
      filtering: false,
      headerStyle: { backgroundColor: "#F9F9FB" },
    },
  ];

  const data = typeData?.map((master, index) => ({
    id: master.id,
    sl: index + 1,
    masterID: master?.masterId,
    Column1: master.universityMasterName,
    Column2: (
      <img
        style={{ width: "41px", height: "41px" }}
        src={
          master.university_master_icon_url
            ? master.university_master_icon_url
            : clicked === "University Type"
            ? "/University.svg"
            : clicked === "University Affiliation Master"
            ? "/Afiliation.svg"
            : clicked === "University Facilities"
            ? "/Facilities.svg"
            : ""
        }
        alt="gsl"
      />
    ),
    university: clicked,
    iconUrl: master.university_master_icon_url,
    clickedId: clickedId,
  }));

  const handleUniversityChange = (event) => {
    setUniversity(event.target.value);
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleFileChange = (event) => {
    if (event?.target?.files) {
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      }).then((base64Array) => {
        setFile(base64Array);
      });
    }
  };

  const handleReset = () => {
    setName("");
    setFile(null);
    setUniversity("");
    setErrors({});
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const errors = validateForm();
    if (isCreatePrevilage("university master")) {
      if (Object.keys(errors).length === 0) {
        dispatch(universityMasterAddAction(university, name, file));
        dispatch(successTrueMsgAction(true));
      } else {
        setErrors(errors);
      }
    } else {
      setOpenAlert(true);
    }
  };

  const validateForm = () => {
    const errors = {};

    // Validate University
    if (!university) {
      errors.university = "University is required";
    }

    // Validate Name
    if (!name) {
      errors.name = "Name is required";
    }

    // Validate File
    if (!file) {
      errors.file = "File is required";
    }

    return errors;
  };

  const [open, setOpen] = React.useState(false);

  // submit Master add
  const submitMasterHandler = (e) => {
    e.preventDefault();

    if (masterTypeData) {
      setErrorType("");
    } else {
      setErrorType("please write a new type");
    }
  };

  if (!hasPrivilege("university master") && privilege !== null) {
    return (
      <Alert severity="error">
        <AlertTitle>warning</AlertTitle>
        You don't have permission to access — <strong>this page.!</strong>
      </Alert>
    );
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={6}>
          <Paper elevation={0} sx={{ p: 2.5, border: "0.5px solid #ECECEC" }}>
            <TypographyText title="University Master Creation" variant="h6" />
            <form onSubmit={handleSubmit} style={{ margin: "9px" }}>
              <FormControl
                className={classes.formControl}
                fullWidth
                variant="outlined"
              >
                <InputLabel
                  id="demo-simple-select-label"
                  style={{
                    fontSize: "13px",
                    fontWeight: 600,
                    fontFamily: "'Open Sans', sans-serif",
                    marginBottom: "10px",
                  }}
                >
                  Choose University Master
                </InputLabel>
                <Select
                  label="Choose University Master"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={university}
                  onChange={handleUniversityChange}
                  error={!!errors.university}
                  style={{ height: "48px" }}
                >
                  {MasterType?.map((master) => (
                    <MenuItem value={master.id}>
                      {master.masterTypeName}
                    </MenuItem>
                  ))}
                </Select>
                {errors.university && (
                  <FormHelperText error>{errors.university}</FormHelperText>
                )}
              </FormControl>

              <FormControl className={classes.formControl} fullWidth>
                <TextField
                  variant="outlined"
                  label="Name"
                  id="name"
                  value={name}
                  onChange={handleNameChange}
                  error={!!errors.name}
                  helperText={errors.name}
                  style={{
                    height: "50px",
                    fontSize: "14px",
                    fontFamily: "'Open Sans', sans-serif",
                    marginBottom: "10px",
                    marginTop: "20px",
                  }}
                  InputLabelProps={{
                    style: {
                      fontSize: "12px",
                      fontWeight: 600,
                    },
                  }}
                  InputProps={{
                    style: {
                      fontSize: "13px",
                      height: "48px",
                    },
                  }}
                />
              </FormControl>

              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: "15px",
                  fontFamily: "'Open Sans', sans-serif",
                  color: "rgba(172, 177, 198, 1)",
                  "& span": {
                    color: "#f44336",
                  },
                }}
              >
                Choose Icon <span>*</span>
              </Typography>

              <FormControl
                className={classes.formControl}
                fullWidth
                style={{
                  minHeight: "65px",
                  border: "0.7px dashed #ECECEC",
                  minWidth: "150px",
                  padding: "25px",
                  align: "center",
                  boxSizing: "border-box",
                  width: "inline",
                }}
              >
                {file ? (
                  <Box
                    component="label"
                    sx={{
                      width: "200px",
                      // height: "400px",
                      "& img": {
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        display: "block",
                      },
                    }}
                  >
                    <img src={file} />
                    <input
                      style={{
                        height: "42px",
                        width: "1px",
                      }}
                      type="file"
                      hidden
                      onChange={handleFileChange}
                    />
                  </Box>
                ) : (
                  <Button
                    component="label"
                    variant="contained"
                    style={{
                      height: "32px",
                      width: "90px",
                      borderRadius: "4px",

                      padding: "10px",
                      margin: "18px",
                      boxShadow: "none",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "10px",
                        fontWeight: 500,
                        fontFamily: "'Poppins', sans-serif",
                      }}
                    >
                      {" "}
                      Choose File
                    </Typography>
                    <input
                      style={{
                        height: "42px",
                        width: "1px",
                      }}
                      type="file"
                      hidden
                      onChange={handleFileChange}
                    />
                  </Button>
                )}
                {errors.file && (
                  <FormHelperText error>{errors.file}</FormHelperText>
                )}
              </FormControl>

              <Typography
                sx={{
                  color: "#ACB1C6",
                  fontSize: "12px",
                  width: "100%",
                }}
              >
                {" "}
                Recommended size: 100x100 pixels
              </Typography>

              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  // margin: "-22px",
                  // marginTop: "1px",
                }}
              >
                {/* <Button
                variant="outlined"
                style={{
                  color: "#141E3C",
                  marginRight: "13px",
                  borderRadius: "8px",
                  borderColor: "#141E3C",
                  width: "67px",
                  height: "auto",
                  fontWeight: 600,
                  fontSize: "8px",
                  fontFamily: "'Open Sans', sans-serif",
                }}
                onClick={handleReset}
              >
                Reset
              </Button> */}

                <OutLinedButton
                  title="Reset"
                  handleClick={handleReset}
                  widthSize="67px"
                  heightSize="auto"
                />
                {/* <Button
                type="submit"
                style={{
                  color: "white",
                  backgroundColor: "#141E3C",
                  borderRadius: "8px",
                  width: "67px",
                  height: "auto",
                  fontWeight: 600,
                  fontSize: "8px",
                  fontFamily: "'Open Sans', sans-serif",
                }}
                variant="contained"
                color="primary"
              >
                Save
              </Button> */}
                <Box m={1}></Box>

                <SubmitButton
                  title="Save"
                  submit="submit"
                  widthSize="67px"
                  heightSize="auto"
                  type="submit"
                />
              </div>
            </form>
          </Paper>
        </Grid>

        <Grid item xs={12} lg={6}>
          <Paper elevation={0} sx={{ p: 2.2, border: "0.5px solid #ECECEC" }}>
            <Box sx={{ m: 1.7 }}>
              <TypographyText title="University Master" variant="h6" />
            </Box>
            {MasterType?.map((master, index) => (
              <>
                <Box
                  className={`${classes.hoverElement} ${
                    clicked === master.masterTypeName ? "clicked" : ""
                  }`}
                  sx={{
                    p: 2.4,
                    display: "flex",
                    justifyContent: "space-between",
                    cursor: "pointer",
                    color: "#ACB1C6",
                  }}
                  onClick={() => handleClick(master.masterTypeName, master.id)}
                >
                  <>
                    <Typography
                      variant="title"
                      sx={{
                        fontWeight: 300,
                        fontSize: "18px",
                        fontFamily: "'Montserrat', sans-serif",
                      }}
                    >
                      {master.masterTypeName}
                    </Typography>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        fontSize: "19px",
                      }}
                    >
                      <Typography sx={{ color: "#ACB1C6" }}>
                        {index === 0 && countData.findCount1}
                        {index === 1 && countData.findCount2}
                        {index === 2 && countData.findCount3}
                        {index === 3 && countData.findCount4}
                      </Typography>
                    </div>
                  </>
                </Box>
                <Divider />
              </>
            ))}
          </Paper>
        </Grid>
        {data?.length > 0 && (
          <Grid item xs={12} lg={12}>
            {universityMasterFindByIdLoading ? (
              <>
                {/* <Skeleton /> */}
                <Typography variant="h1">
                  <Skeleton animation={false} />
                </Typography>

                <Typography variant="h3">
                  <Skeleton animation="wave" />
                </Typography>

                <Skeleton animation={false} />
                <Typography variant="h3">
                  <Skeleton animation="wave" />
                </Typography>

                <Skeleton animation={false} />
                <Typography variant="h3">
                  <Skeleton animation="wave" />
                </Typography>

                <Skeleton animation={false} />
                <Typography variant="h3">
                  <Skeleton animation="wave" />
                </Typography>

                <Skeleton animation={false} />
              </>
            ) : (
              <TableWithoutCoulumnVisible
                columns={columns}
                data={data}
                findData={universityMasterFindByIdSuccess}
                component="universityMaster"
                subMenu="university master"
                Clicked={clicked}
              />
            )}
          </Grid>
        )}

        {/* popup page */}

        {/* <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <form onSubmit={submitMasterHandler}>
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleClose}
          >
            Add University Master
          </BootstrapDialogTitle>
          <DialogContent dividers>
            {errorType !== "" && (
              <Typography color="error">{errorType}</Typography>
            )}
            <TextField
              onChange={masterTypeHandler}
              fullWidth
              label="Enter Master Type"
              value={masterTypeData}
            />
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={handleClose}>
              Cancel
            </Button>
            <Button type="submit" variant="contained" color="primary">
              Save changes
            </Button>
          </DialogActions>
        </form>
      </BootstrapDialog> */}
      </Grid>

      <Dialog
        open={openAlert}
        onClose={() => setOpenAlert(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          zIndex: "9999",
          // background:"red",
          "& .MuiDialog-paper": {
            width: "100%",
            borderRadius: "5px",
            p: "15px",
            textAlign: "center",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "end",
          }}
        >
          <Close
            sx={{
              cursor: "pointer",
            }}
            onClick={() => setOpenAlert(false)}
          />
        </Box>
        <Typography
          sx={{
            color: "#05050F",
            fontSize: "26px",
            fontWeight: "700",
          }}
        >
          Access Denied
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            mt: "-10px",
          }}
        >
          <AccessDeniedIcon />
        </Box>
        <Typography
          sx={{
            color: "#717171",
            fontSize: "16px",
            mb: "10px",
          }}
        >
          Sorry, you don’t have permission
        </Typography>
      </Dialog>
    </>
  );
};

export default UniversityMasterMain;
