import {
  STATE_MASTER_ADD_ERR,
  STATE_MASTER_ADD_REQUEST,
  STATE_MASTER_ADD_SUCCESS,
  STATE_MASTER_DELETE_ERR,
  STATE_MASTER_DELETE_REQUEST,
  STATE_MASTER_DELETE_SUCCESS,
  STATE_MASTER_EDIT_ERR,
  STATE_MASTER_EDIT_FIND_ERR,
  STATE_MASTER_EDIT_FIND_REQUEST,
  STATE_MASTER_EDIT_FIND_SUCCESS,
  STATE_MASTER_EDIT_REQUEST,
  STATE_MASTER_EDIT_SUCCESS,
  STATE_MASTER_FIND_ERR,
  STATE_MASTER_FIND_REQUEST,
  STATE_MASTER_FIND_SUCCESS,
} from "../constants/StateMasterConstant";

// StateMasterAddReducer
export const StateMasterAddReducer = (state = {}, action) => {
  switch (action.type) {
    case STATE_MASTER_ADD_REQUEST:
      return {
        ...state,
        stateAddLoading: true,
      };
    case STATE_MASTER_ADD_SUCCESS:
      return {
        ...state,
        stateAddLoading: false,
        stateAddSuccess: action.payload,
      };
    case STATE_MASTER_ADD_ERR:
      return {
        ...state,
        stateAddLoading: false,
        stateAddErr: action.payload,
      };
    default:
      return state;
  }
};

// findStateMasterReducer
export const findStateMasterReducer = (state = {}, action) => {
  switch (action.type) {
    case STATE_MASTER_FIND_REQUEST:
      return {
        ...state,
        stateFindLoading: true,
      };
    case STATE_MASTER_FIND_SUCCESS:
      return {
        ...state,
        stateFindLoading: false,
        stateFindSuccess: action.payload,
      };
    case STATE_MASTER_FIND_ERR:
      return {
        ...state,
        stateFindLoading: false,
        stateFindErr: action.payload,
      };
    default:
      return state;
  }
};

// stateMasterDeleteReducer
export const stateMasterDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case STATE_MASTER_DELETE_REQUEST:
      return {
        ...state,
        stateDeleteLoading: true,
      };
    case STATE_MASTER_DELETE_SUCCESS:
      return {
        ...state,
        stateDeleteLoading: false,
        stateDeleteSuccess: action.payload,
      };
    case STATE_MASTER_DELETE_ERR:
      return {
        ...state,
        stateDeleteLoading: false,
        stateDeleteErr: action.payload,
      };
    default:
      return state;
  }
};

// findEditStateFormReducer
export const findEditStateFormReducer = (state = {}, action) => {
  switch (action.type) {
    case STATE_MASTER_EDIT_FIND_REQUEST:
      return {
        ...state,
        stateFindEditLoading: true,
      };
    case STATE_MASTER_EDIT_FIND_SUCCESS:
      return {
        ...state,
        stateFindEditLoading: false,
        stateFindEditSuccess: action.payload,
      };
    case STATE_MASTER_EDIT_FIND_ERR:
      return {
        ...state,
        stateFindEditLoading: false,
        stateFindEditErr: action.payload,
      };
    default:
      return state;
  }
};

// stateMasterEditReducer
export const stateMasterEditReducer = (state = {}, action) => {
  switch (action.type) {
    case STATE_MASTER_EDIT_REQUEST:
      return {
        ...state,
        stateEditLoading: true,
      };
    case STATE_MASTER_EDIT_SUCCESS:
      return {
        ...state,
        stateEditLoading: false,
        stateEditSuccess: action.payload,
      };
    case STATE_MASTER_EDIT_ERR:
      return {
        ...state,
        stateEditLoading: false,
        stateEditErr: action.payload,
      };
    default:
      return state;
  }
};
