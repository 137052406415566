import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableFooter,
  TableSortLabel,
  Menu,
  Checkbox,
  Box,
  MenuItem,
  Pagination,
  Typography,
  Button,
  InputLabel,
  Select,
  FormControl,
} from "@mui/material";
import React, { useState } from "react";
import { SlOptionsVertical } from "react-icons/sl";
import { RiDeleteBin6Line } from "react-icons/ri";
import { appliedJobStatusChangeAction, jobStatusChangeAction } from "../../../actions/HrActions/hrEmployeeManagmentAction";
import { useDispatch } from "react-redux";

function AppliedStudentsTable({ data }) {
  const columns = [
    "Sl No",
    "Name",
    "Phone Number",
    "Email",
    "Resume",
    "Status",
  ];

  const dispatch = useDispatch();
  const [status, setStatus] = useState("");
  const [actionButtonShow, setActionButtonShow] = useState(null);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const openActionButton = Boolean(actionButtonShow);

  const handleCloseAction = () => {
    setActionButtonShow(null);
  };

  const EditHandler = (item) => {
    console.log(item);
  };
  const DeleteHandler = (item) => {};

  const handleClickAction = (event) => {
    setActionButtonShow(event.currentTarget);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleStatusChange = (event, newPage) => {
    console.log(event, newPage);
    dispatch(appliedJobStatusChangeAction(event, newPage));
  };

  return (
    <Box
      sx={{
        mt: "20px",
      }}
    >
      <Typography
        sx={{
          fontSize: "16px",
          fontWeight: "600",
          mb: "10px",
          //   pb: "5px",
          //   borderBottom: "2px solid rgba(0, 0, 0, 0.25)",
          width: "fit-content",
        }}
      >
        Applied Candidates Lists
      </Typography>

      <Typography
        sx={{
          backgroundColor: "#fff",
          padding: "5px",
          paddingRight: "0px",
          borderRadius: "10px 10px 0 0",
          width: "100%",
          fontSize: "12px",
          fontWeight: "400",
          mb: "10px",
        }}
      >
        Showing{" "}
        <Typography
          component={"span"}
          sx={{
            backgroundColor: "#fff",
            padding: "5px",
            paddingLeft: "0px",
            borderRadius: "10px 10px 0 0",
            width: "100%",
            fontSize: "12px",
            // lineHeight: "24px",
            fontWeight: "700",
          }}
        >
          {data?.length} Result
        </Typography>
      </Typography>

      <Box
        sx={{
          overflowX: "scroll",
        }}
      >
        <Table
          className="masterTable"
          sx={{
            minWidth: "1000px",
          }}
        >
          <TableHead>
            <TableRow className="msterTableCell">
              <TableCell>
                <Checkbox
                  //   checked={selectedRows.length === sortedData.length}
                  //   onChange={handleSelectAllRows}
                  style={{ color: "#f81b82" }}
                />
              </TableCell>
              {columns?.map((column) => {
                return (
                  <TableCell key={column}>
                    <TableSortLabel
                      sx={{
                        fontWeight: "700",
                        textTransform: "uppercase",
                        fontSize: "13px",
                      }}
                    >
                      {column}
                    </TableSortLabel>
                  </TableCell>
                );
              })}
              <TableCell></TableCell>
              <TableCell
                sx={{
                  width: "30px",
                }}
              ></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((item, index) => (
              <TableRow className="tableRow" key={item?.id}>
                <TableCell>
                  <Checkbox
                    // checked={selectedRows.includes(item.id)}
                    // onChange={() => handleSelectRow(item.id)}
                    style={{ color: "#f81b82" }}
                  />
                </TableCell>
                <TableCell>{index + 1}</TableCell>
                <TableCell>{item?.name}</TableCell>
                <TableCell>{item?.phoneNumber}</TableCell>
                <TableCell>{item?.email}</TableCell>
                <TableCell>
                  {/* <Button
                      component={"a"}
                      sx={{
                        fontSize: "14px",
                        backgroundColor: "rgba(0, 71, 255, 0.1)",
                        color: "rgba(0, 71, 255, 1)",
                        border: "1px solid rgba(0, 71, 255, 0.5)",
                        px: "10px",
                        py: "1px !important",
                      }}
                      href={item.resume}
                      download={item.download}
                    >
                      Download
                    </Button> */}
                  <Button
                    sx={{
                      fontSize: "12px",
                      textTransform: "capitalize",
                      backgroundColor: "rgba(0, 71, 255, 0.1)",
                      color: "rgba(0, 71, 255, 1)",
                      border: "1px solid rgba(0, 71, 255, 0.5)",
                      px: "10px",
                      py: "1px !important",
                    }}
                    component={"a"}
                    href={item?.document}
                    target="_blank"
                  >
                    View Resume
                  </Button>
                </TableCell>

                <TableCell>
                  <FormControl
                    fullWidth
                    sx={{
                      bgcolor:
                        item.status === "Accepted"
                          ? "#228200"
                          : item.status === "Rejected"
                          ? "#E52900"
                          : "rgba(0, 71, 255, 0.1)",

                      borderRadius: "8px",
                      height: "30px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      "& label": {
                        fontSize: "14px",
                        color:
                          item?.status === "Accepted"
                            ? "#fff"
                            : item?.status === "Rejected"
                            ? "#fff"
                            : "rgba(0, 71, 255, 1) !important",
                      },
                      "& .MuiSelect-icon": {
                        top: "3px",
                      },
                      "&.MuiFormControl-root": {
                        width: "140px",
                      },
                    }}
                  >
                    <InputLabel
                      htmlFor="demo-simple-select-placeholder"
                      style={{
                        transform: "translate(10px, 10px)",
                        pointerEvents: "none",
                        zIndex: 1,
                        transition: "transform 0.25s, font-size 0.25s",
                        margin: "-6px",
                        color: "white",
                        fontWeight: 400,
                        paddingLeft: "10px",
                        border: "none !important",
                      }}
                    >
                      {item.status ? item?.status : "Not Selected"}
                    </InputLabel>

                    <Select
                      value={status}
                      onChange={(e) =>
                        handleStatusChange(e.target.value, item?.id)
                      }
                      inputProps={{
                        // name: {item.coloum3},
                        id: "demo-simple-select-placeholder",
                      }}
                      sx={{
                        position: "absolute",
                        top: "0px",
                        right: "0",
                        "& .MuiSelect-select": {
                          padding: "0",
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          outline: "none !important",
                          border: "none !important",
                        },
                      }}
                    >
                      <MenuItem value="Accepted">Accepted</MenuItem>
                      <MenuItem value="Rejected">Rejected</MenuItem>
                    </Select>
                  </FormControl>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter />
        </Table>
      </Box>

      <Pagination
        sx={{
          mt: "28px",
          "& .Mui-selected": {
            backgroundColor: "#f81b82 !important",
            color: "#fff",
          },
          "& .MuiPagination-ul": {
            justifyContent: "end",
          },
        }}
        count={Math.ceil(data?.length / rowsPerPage)}
        page={page}
        onChange={handleChangePage}
      />
    </Box>
  );
}

export default AppliedStudentsTable;
