import {
  Box,
  Checkbox,
  Menu,
  MenuItem,
  Pagination,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { BiSortAlt2 } from "react-icons/bi";
import { SlOptionsVertical } from "react-icons/sl";
import { AiOutlineEye } from "react-icons/ai";

function EnquiryTable({
  data,
  columns,
  pagiantionEntry,
  paginationPageNumber,
  totalEntry,
  handlePageNation,
  counsilorNote,
  teleCallerNote
}) {
  console.log(data, "datadatadatadata");
  const navigate = useNavigate();

  let { enquiryStudentDetailsSuccess, enquiryStudentDetailsLoading } =
    useSelector((state) => state.enquiryStudentDetails);

  const [selectedRows, setSelectedRows] = useState([]);
  const [pendingData, setPendingData] = useState([]);
  // pagination

  const [page, setPage] = useState(paginationPageNumber);
  const [rowsPerPage, setRowsPerPage] = useState(pagiantionEntry);

  useEffect(() => {
    setRowsPerPage(pagiantionEntry);
    setPage(paginationPageNumber);
  }, [data, paginationPageNumber, pagiantionEntry]);

  useEffect(() => {
    const maxPage = Math.ceil(totalEntry / pagiantionEntry);
    if (page > maxPage) {
      setPage(maxPage);
    }
  }, [data, pagiantionEntry, page]);

  // pagination

  // // Event handler for changing page
  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };

  // Create a function to handle selecting all rows:
  const handleSelectAllRows = () => {
    if (selectedRows.length === sortedData.length) {
      setSelectedRows([]);
    } else {
      let Ids = sortedData.map((item) => item.id);
      setSelectedRows(Ids);
    }
  };

  // Create a function to handle selecting individual rows:
  const handleSelectRow = (rowId) => {
    let updatedSelectedRows;
    if (selectedRows.includes(rowId)) {
      updatedSelectedRows = selectedRows.filter((id) => id !== rowId);
      setSelectedRows(selectedRows.filter((id) => id !== rowId));
    } else {
      updatedSelectedRows = [...selectedRows, rowId];
      setSelectedRows(updatedSelectedRows);
    }
  };

  // table action
  const [leadActionButtonShow, setLeadActionButtonShow] = useState(null);
  const [tableId, setTableId] = useState("");

  const leadOpenActionButton = Boolean(leadActionButtonShow);

  const handleClickAction = (event, id) => {
    setLeadActionButtonShow(event.currentTarget);
    setTableId(id);
  };

  const handleCloseAction = () => {
    setLeadActionButtonShow(null);
  };

  const CustomSortIcon = ({ direction }) => {
    return direction === "asc" ? (
      <img
        src="/menuIcons/tableUpSort.png"
        className="administratCustomIcon"
        alt="gsl"
        style={{ padding: "15px" }}
      />
    ) : (
      <BiSortAlt2 className="LeadCustomIcon" />
    );
  };

  // sorting table
  const [sortBy, setSortBy] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");

  const requestSort = (column) => {
    let newSortOrder = "asc";
    if (column === sortBy && sortOrder === "asc") {
      newSortOrder = "desc";
    }

    setSortBy(column);
    setSortOrder(newSortOrder);
  };

  let sortedData = useMemo(() => {
    if (sortBy && data) {
      const sortedItems = [...data].sort((a, b) => {
        if (sortOrder === "asc") {
          return a[sortBy] < b[sortBy] ? -1 : 1;
        } else {
          return a[sortBy] < b[sortBy] ? 1 : -1;
        }
      });

      return sortedItems;
    }

    return data;
  }, [data, sortBy, sortOrder]);

  return (
    <Box>
      {enquiryStudentDetailsLoading ? (
        <>
          {/* <Skeleton /> */}
          <Typography variant="h1">
            <Skeleton animation={false} />
          </Typography>

          <Typography variant="h3">
            <Skeleton animation="wave" />
          </Typography>

          <Skeleton animation={false} />
          <Typography variant="h3">
            <Skeleton animation="wave" />
          </Typography>

          <Skeleton animation={false} />
          <Typography variant="h3">
            <Skeleton animation="wave" />
          </Typography>

          <Skeleton animation={false} />
          <Typography variant="h3">
            <Skeleton animation="wave" />
          </Typography>

          <Skeleton animation={false} />
        </>
      ) : (
        <>
          <div
            style={{
              overflowX: "scroll",
              border: "0px solid #ffff"
            }}
            className="scrollBar"
          >
            <Table className="LeadTable">
              <TableHead>
                <TableRow className="LeadTableCell">
                  <TableCell
                    style={{
                      position: "sticky",
                      left: 0,
                      zIndex: 1,
                      padding: "1px",
                      border: "1px solid #ddd",
                      backgroundColor: "#fafafa"
                    }}
                  >
                    <Checkbox
                      checked={selectedRows?.length === sortedData?.length}
                      onChange={handleSelectAllRows}
                      style={{ color: "#f81b82" }}
                    />
                  </TableCell>
                  {columns?.map((column) => {
                    if (column.hidden) {
                      return null;
                    }
                    return (
                      <TableCell
                        key={column.field}
                        className="LeadTableCell LeadCommon"
                        style={{
                          padding: "0 16px",
                          whiteSpace: "nowrap",
                          textTransform: "uppercase",
                          fontWeight: "600"
                        }}
                      >
                        <TableSortLabel
                          className="LeadHeaderText"
                          active={column === sortBy}
                          direction={sortOrder}
                          onClick={() => requestSort(column)}
                          IconComponent={
                            column.field !== "action" && CustomSortIcon
                          }
                        >
                          {column.title}
                        </TableSortLabel>
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedData.map((item, indx) => (
                  <TableRow className="LeadTable" key={indx}>
                    <TableCell
                      style={{
                        position: "sticky",
                        left: 0,
                        zIndex: 1, // To ensure it's above other cells
                        backgroundColor: "white", // Adjust as needed
                        padding: "1px",
                        border: "1px solid #ddd"
                      }}
                    >
                      <Checkbox
                        checked={selectedRows.includes(indx)}
                        onChange={() => handleSelectRow(indx)}
                        style={{ color: "#f81b82" }}
                      />
                    </TableCell>
                    <TableCell
                      style={{ padding: "0 16px", whiteSpace: "nowrap" }}
                    >
                      {indx + 1}
                    </TableCell>

                    <TableCell
                      style={{
                        padding: "0 16px",
                        whiteSpace: "nowrap",
                        textTransform: "capitalize"
                      }}
                    >
                      {(item.firstName || "") + " " + (item.lastName || "")}
                    </TableCell>

                    <TableCell
                      style={{ padding: "0 16px", whiteSpace: "nowrap" }}
                    >
                      {item.email || " - "}
                    </TableCell>

                    <TableCell
                      style={{ padding: "0 16px", whiteSpace: "nowrap" }}
                    >
                      {item?.mobileNumber
                        ? (item?.countryId ? item?.countryId : "") +
                          " " +
                          item?.mobileNumber
                        : "-"}
                    </TableCell>

                    <TableCell
                      style={{ padding: "0 16px", whiteSpace: "nowrap" }}
                    >
                      {item?.whatsappNumber
                        ? (item?.countryId ? item?.countryId : "") +
                          " " +
                          item?.whatsappNumber
                        : "-"}
                    </TableCell>

                    <TableCell
                      style={{ padding: "0 16px", whiteSpace: "nowrap" }}
                    >
                      {item?.lead_personal_details[0]?.passportNumber
                        ? item?.lead_personal_details[0]?.passportNumber
                        : "-"}
                    </TableCell>

                    <TableCell
                      style={{
                        padding: "0 16px",
                        whiteSpace: "nowrap",
                        textTransform: "capitalize"
                      }}
                    >
                      {item.livingCountry || " - "}
                    </TableCell>

                    <TableCell
                      style={{
                        padding: "0 16px",
                        whiteSpace: "nowrap",
                        textTransform: "capitalize"
                      }}
                    >
                      {item.leadSource || " - "}
                    </TableCell>

                    <TableCell
                      style={{
                        padding: "0 16px",
                        whiteSpace: "nowrap",
                        textTransform: "capitalize"
                      }}
                    >
                      {item.leadOwnerName || " - "}
                    </TableCell>

                    <TableCell
                      style={{
                        padding: "0 16px",
                        whiteSpace: "nowrap",
                        textTransform: "capitalize"
                      }}
                    >
                      {item.Counselor?.firstName || " - "}
                    </TableCell>

                    <TableCell
                      style={{
                        padding: "0 16px",
                        whiteSpace: "nowrap",
                        textTransform: "capitalize"
                      }}
                    >
                      {counsilorNote?.leadId == item?.id
                        ? counsilorNote?.counsilorNote
                        : "-"}
                    </TableCell>

                    <TableCell
                      style={{
                        padding: "0 16px",
                        whiteSpace: "nowrap",
                        textTransform: "capitalize"
                      }}
                    >
                      {item.Telecaller?.firstName || " - "}
                    </TableCell>

                    <TableCell
                      style={{
                        padding: "0 16px",
                        whiteSpace: "nowrap",
                        textTransform: "capitalize"
                      }}
                    >
                      {teleCallerNote?.leadId == item?.id
                        ? teleCallerNote?.teleCallerNote
                        : "-"}
                    </TableCell>

                    <TableCell
                      style={{
                        padding: "0 16px",
                        whiteSpace: "nowrap",
                        textTransform: "capitalize"
                      }}
                    >
                      {item?.user_Branch?.branch_master?.branchName || " - "}
                    </TableCell>

                    <TableCell
                      style={{
                        padding: "0 16px",
                        whiteSpace: "nowrap",
                        textTransform: "capitalize"
                      }}
                    >
                      {item.status}
                    </TableCell>

                    <TableCell
                      style={{
                        padding: "0 16px",
                        whiteSpace: "nowrap"
                      }}
                    >
                      <SlOptionsVertical
                        id="demo-positioned-button"
                        aria-controls={
                          leadOpenActionButton
                            ? "demo-positioned-menu"
                            : undefined
                        }
                        aria-haspopup="true"
                        aria-expanded={
                          leadOpenActionButton ? "true" : undefined
                        }
                        onClick={(e) => {
                          handleClickAction(e, item?.id);
                        }}
                        variant="outlined"
                        style={{ marginLeft: "10px", color: "#F80B7A" }}
                        sx={{
                          backgroundColor: "#141E3C",
                          size: "10px",
                          padding: 1,
                          margin: 3,
                          color: "#fff",
                          width: "75px",
                          height: "40px"
                        }}
                      />
                      {/* TABLE ACTION MENU START*/}
                      <Menu
                        id="demo-positioned-menu"
                        aria-labelledby="demo-positioned-button"
                        open={leadActionButtonShow}
                        onClose={handleCloseAction}
                        anchorEl={leadActionButtonShow}
                        MenuListProps={{
                          "aria-labelledby": "basic-button"
                        }}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "left"
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "left"
                        }}
                        sx={{
                          borderColor: "black",
                          borderRadius: "24px",
                          mt: 1.5,
                          width: "auto",
                          height: "auto"
                        }}
                      >
                        <Box
                          style={{
                            display: "flex",
                            justifyContent: "flex-end"
                          }}
                        >
                          <MenuItem>
                            <AiOutlineEye
                              style={{
                                fontSize: "lg",
                                width: "20px",
                                height: "19px"
                              }}
                              onClick={() =>
                                navigate(`/enquiry/application/${tableId}`)
                              }
                            />
                          </MenuItem>
                        </Box>
                      </Menu>{" "}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter />
            </Table>
          </div>
          <Pagination
            sx={{
              mt: "28px",
              "& .Mui-selected": {
                background: "#FE0B7A !important"
              },
              "& .MuiPagination-ul": {
                justifyContent: "end"
              }
            }}
            count={Math.ceil(totalEntry / 8)}
            page={paginationPageNumber}
            onChange={handlePageNation}
            color="primary"
          />
        </>
      )}
    </Box>
  );
}

export default EnquiryTable;
