import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import {
  ieltsPurchasedStudentCourseStatusChangeAction,
  ieltsPurchasedStudentDetailsFindByIdAction,
} from "../../../actions/IeltsActions/purchasedStudentActions";

function IeltsPurchasedStudentView() {
  let { ieltsPurchasedStudentDetailsFindByIdSuccess } = useSelector((state) => {
    return state.ieltsPurchasedStudentDetailsFindById;
  });

  let { ieltsPurchasedStudentChangeStatusSuccess } = useSelector((state) => {
    return state.ieltsPurchasedStudentStatusChange;
  });

  const dispatch = useDispatch();
  const { id } = useParams();
  const { pathname } = useLocation();
  const location = pathname.split("/")[3];

  useEffect(() => {
    dispatch(ieltsPurchasedStudentDetailsFindByIdAction(id));
  }, [dispatch, ieltsPurchasedStudentChangeStatusSuccess]);

  useEffect(() => {
    if (ieltsPurchasedStudentDetailsFindByIdSuccess) {
      setData(ieltsPurchasedStudentDetailsFindByIdSuccess.data);
    }
  }, [ieltsPurchasedStudentDetailsFindByIdSuccess]);

  const [data, setData] = useState();

  const handleStatus = (status) => {
    dispatch(ieltsPurchasedStudentCourseStatusChangeAction(status, id));
  };

  return location === "online" ? (
    <Box
      sx={{
        display: "flex",
        border: "1px solid #ECECEC",
        gap: 10,
        pt: 2,
        pb: 2,
      }}
    >
      <Box
        sx={{
          p: 3,
          display: "flex",
          justifyContent: "space-between",
          gap: "100px",
          borderRight: "1px solid #ECECEC",
        }}
      >
        <Box sx={{ display: "flex", gap: "20px", flexDirection: "column" }}>
          <Box>
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: "14px",
                fontFamily: "'Poppins', sans-serif",
              }}
            >
              Name
            </Typography>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: "12px",
                fontFamily: "'Poppins', sans-serif",
                color: "#ACB1C6",
              }}
            >
              {data?.ielts_entrolled_student?.fullName}
            </Typography>
          </Box>

          <Box>
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: "14px",
                fontFamily: "'Poppins', sans-serif",
              }}
            >
              DOB
            </Typography>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: "12px",
                fontFamily: "'Poppins', sans-serif",
                color: "#ACB1C6",
              }}
            >
              {data?.ielts_entrolled_student?.dob}
            </Typography>
          </Box>

          <Box>
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: "14px",
                fontFamily: "'Poppins', sans-serif",
              }}
            >
              Email
            </Typography>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: "12px",
                fontFamily: "'Poppins', sans-serif",
                color: "#ACB1C6",
                // maxWidth:"150px",
                // overflow:"scroll"
              }}
            >
              {data?.ielts_entrolled_student?.email}
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: "flex", gap: "20px", flexDirection: "column" }}>
          <Box>
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: "14px",
                fontFamily: "'Poppins', sans-serif",
              }}
            >
              Gender
            </Typography>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: "12px",
                fontFamily: "'Poppins', sans-serif",
                color: "#ACB1C6",
              }}
            >
              {data?.ielts_entrolled_student?.gender}
            </Typography>
          </Box>

          <Box>
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: "14px",
                fontFamily: "'Poppins', sans-serif",
              }}
            >
              Phone Number
            </Typography>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: "12px",
                fontFamily: "'Poppins', sans-serif",
                color: "#ACB1C6",
              }}
            >
              {(data?.ielts_entrolled_student?.phoneNumberCountryCode !== null
                ? data?.ielts_entrolled_student?.phoneNumberCountryCode
                : "") +
                " " +
                data?.ielts_entrolled_student?.phoneNumber}
            </Typography>
          </Box>

          <Box>
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: "14px",
                fontFamily: "'Poppins', sans-serif",
              }}
            >
              Whatsapp Number
            </Typography>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: "12px",
                fontFamily: "'Poppins', sans-serif",
                color: "#ACB1C6",
              }}
            >
              {(data?.ielts_entrolled_student?.whatsAppNumberCountryCode !==
              null
                ? data?.ielts_entrolled_student?.whatsAppNumberCountryCode
                : "") +
                " " +
                data?.ielts_entrolled_student?.whatsAppNumber}
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: "flex", gap: "20px", flexDirection: "column" }}>
          <Box>
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: "14px",
                fontFamily: "'Poppins', sans-serif",
              }}
            >
              Secondary Phone Number
            </Typography>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: "12px",
                fontFamily: "'Poppins', sans-serif",
                color: "#ACB1C6",
              }}
            >
              {(data?.ielts_entrolled_student
                ?.secondaryPhoneNumberCountryCode !== null
                ? data?.ielts_entrolled_student?.secondaryPhoneNumberCountryCode
                : "") +
                " " +
                data?.ielts_entrolled_student?.secondaryPhoneNumber}
            </Typography>
          </Box>

          <Box>
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: "14px",
                fontFamily: "'Poppins', sans-serif",
              }}
            >
              Country
            </Typography>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: "12px",
                fontFamily: "'Poppins', sans-serif",
                color: "#ACB1C6",
              }}
            >
              {data?.ielts_entrolled_student?.country}
            </Typography>
          </Box>

          <Box>
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: "14px",
                fontFamily: "'Poppins', sans-serif",
              }}
            >
              State
            </Typography>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: "12px",
                fontFamily: "'Poppins', sans-serif",
                color: "#ACB1C6",
              }}
            >
              {data?.ielts_entrolled_student?.state}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          p: 3,
          display: "flex",
          justifyContent: "space-between",
          gap: "100px",
        }}
      >
        <Box sx={{ display: "flex", gap: "20px", flexDirection: "column" }}>
          <Box>
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: "14px",
                fontFamily: "'Poppins', sans-serif",
              }}
            >
              City
            </Typography>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: "12px",
                fontFamily: "'Poppins', sans-serif",
                color: "#ACB1C6",
              }}
            >
              {data?.ielts_entrolled_student?.city}
            </Typography>
          </Box>

          <Box>
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: "14px",
                fontFamily: "'Poppins', sans-serif",
              }}
            >
              Student Status
            </Typography>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: "12px",
                fontFamily: "'Poppins', sans-serif",
                color:
                  data?.ielts_entrolled_student?.status == "Inactive"
                    ? "red"
                    : "green",
              }}
            >
              {data?.ielts_entrolled_student?.status}
            </Typography>
          </Box>

          <Box>
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: "14px",
                fontFamily: "'Poppins', sans-serif",
              }}
            >
              Entry Created On
            </Typography>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: "12px",
                fontFamily: "'Poppins', sans-serif",
                color: "#ACB1C6",
              }}
            >
              {data?.ielts_entrolled_student?.createdAt?.split("T")[0]}
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: "flex", gap: "20px", flexDirection: "column" }}>
          <Box>
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: "14px",
                fontFamily: "'Poppins', sans-serif",
              }}
            >
              Status
            </Typography>
            <FormControl
              fullWidth
              sx={{
                mt: 1,
                bgcolor:
                  data?.ielts_entrolled_student?.status === "Active"
                    ? "#228200"
                    : "#E52900",
                borderRadius: "8px",
              }}
            >
              <InputLabel
                htmlFor="demo-simple-select-placeholder"
                style={{
                  transform: "translate(10px, 10px)",
                  pointerEvents: "none",
                  zIndex: 1,
                  transition: "transform 0.25s, font-size 0.25s",
                  margin: "-6px",
                  color: "white",
                  fontWeight: 400,
                  paddingLeft: "10px",
                  border: "none !important",
                }}
              >
                {data?.ielts_entrolled_student?.status}
              </InputLabel>
              <Select
                value={data?.ielts_entrolled_student?.status}
                onChange={(e) => handleStatus(e.target.value)}
                inputProps={{
                  // name: {item.coloum7},
                  id: "demo-simple-select-placeholder",
                }}
                sx={{
                  position: "relative",
                  width: "100px",
                  height: "30px",
                  // outline:"none !important",border:"4px solid #000",
                  ".MuiOutlinedInput-notchedOutline": {
                    outline: "none !important",
                    border: "none !important",
                  },
                }}
              >
                <MenuItem value="Active">Active</MenuItem>
                <MenuItem value="Inactive">Inactive</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Box>
      </Box>
    </Box>
  ) : (
    <Box
      sx={{
        display: "flex",
        border: "1px solid #ECECEC",
        gap: 10,
        pt: 2,
        pb: 2,
      }}
    >
      <Box
        sx={{
          p: 3,
          display: "flex",
          justifyContent: "space-between",
          gap: "50px",
          borderRight: "1px solid #ECECEC",
        }}
      >
        <Box sx={{ display: "flex", gap: "20px", flexDirection: "column" }}>
          <Box>
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: "14px",
                fontFamily: "'Poppins', sans-serif",
              }}
            >
              Name
            </Typography>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: "12px",
                fontFamily: "'Poppins', sans-serif",
                color: "#ACB1C6",
              }}
            >
              {data?.ielts_entrolled_student?.fullName}
            </Typography>
          </Box>
          <Box>
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: "14px",
                fontFamily: "'Poppins', sans-serif",
              }}
            >
              DOB
            </Typography>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: "12px",
                fontFamily: "'Poppins', sans-serif",
                color: "#ACB1C6",
              }}
            >
              {data?.ielts_entrolled_student?.dob}
            </Typography>
          </Box>
          <Box>
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: "14px",
                fontFamily: "'Poppins', sans-serif",
              }}
            >
              Email
            </Typography>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: "12px",
                fontFamily: "'Poppins', sans-serif",
                color: "#ACB1C6",
                // maxWidth:"150px",
                // overflow:"scroll"
              }}
            >
              {data?.ielts_entrolled_student?.email}
            </Typography>
          </Box>
          <Box>
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: "14px",
                fontFamily: "'Poppins', sans-serif",
              }}
            >
              Gender
            </Typography>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: "12px",
                fontFamily: "'Poppins', sans-serif",
                color: "#ACB1C6",
                // maxWidth:"150px",
                // overflow:"scroll"
              }}
            >
              {data?.ielts_entrolled_student?.gender}
            </Typography>
          </Box>
        </Box>

        <Box sx={{ display: "flex", gap: "20px", flexDirection: "column" }}>
          <Box>
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: "14px",
                fontFamily: "'Poppins', sans-serif",
              }}
            >
              Phone Number
            </Typography>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: "12px",
                fontFamily: "'Poppins', sans-serif",
                color: "#ACB1C6",
              }}
            >
              {(data?.ielts_entrolled_student?.phoneNumberCountryCode !== null
                ? data?.ielts_entrolled_student?.phoneNumberCountryCode
                : "") +
                " " +
                data?.ielts_entrolled_student?.phoneNumber}
            </Typography>
          </Box>
          <Box>
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: "14px",
                fontFamily: "'Poppins', sans-serif",
              }}
            >
              Whatsapp Number
            </Typography>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: "12px",
                fontFamily: "'Poppins', sans-serif",
                color: "#ACB1C6",
              }}
            >
              {(data?.ielts_entrolled_student?.whatsAppNumberCountryCode !==
              null
                ? data?.ielts_entrolled_student?.whatsAppNumberCountryCode
                : "") +
                " " +
                data?.ielts_entrolled_student?.whatsAppNumber}
            </Typography>
          </Box>
          <Box>
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: "14px",
                fontFamily: "'Poppins', sans-serif",
              }}
            >
              Secondary Phone Number
            </Typography>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: "12px",
                fontFamily: "'Poppins', sans-serif",
                color: "#ACB1C6",
              }}
            >
              {data?.ielts_entrolled_student?.secondaryPhoneNumber
                ? (data?.ielts_entrolled_student
                    ?.secondaryPhoneNumberCountryCode !== null
                    ? data?.ielts_entrolled_student
                        ?.secondaryPhoneNumberCountryCode
                    : "") +
                  " " +
                  data?.ielts_entrolled_student?.secondaryPhoneNumber
                : "Nill"}
            </Typography>
          </Box>
          <Box>
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: "14px",
                fontFamily: "'Poppins', sans-serif",
              }}
            >
              Country
            </Typography>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: "12px",
                fontFamily: "'Poppins', sans-serif",
                color: "#ACB1C6",
              }}
            >
              {data?.ielts_entrolled_student?.country
                ? data?.ielts_entrolled_student?.country
                : "Nill"}
            </Typography>
          </Box>
        </Box>

        {/* <Box sx={{ display: "flex", gap: "20px", flexDirection: "column" }}>
          <Box>
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: "14px",
                fontFamily: "'Poppins', sans-serif",
              }}
            >
              Secondary Phone Number
            </Typography>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: "12px",
                fontFamily: "'Poppins', sans-serif",
                color: "#ACB1C6",
              }}
            >
              {data?.ielts_entrolled_student?.secondaryPhoneNumber
                ? (data?.ielts_entrolled_student?.secondaryPhoneNumberCountryCode !== null
                    ? data?.ielts_entrolled_student?.secondaryPhoneNumberCountryCode
                    : "") +
                  " " +
                  data?.ielts_entrolled_student?.secondaryPhoneNumber
                : "Nill"}
            </Typography>
          </Box>
          <Box>
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: "14px",
                fontFamily: "'Poppins', sans-serif",
              }}
            >
              Country
            </Typography>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: "12px",
                fontFamily: "'Poppins', sans-serif",
                color: "#ACB1C6",
              }}
            >
              india
            </Typography>
          </Box>
          <Box>
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: "14px",
                fontFamily: "'Poppins', sans-serif",
              }}
            >
              State
            </Typography>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: "12px",
                fontFamily: "'Poppins', sans-serif",
                color: "#ACB1C6",
              }}
            >
              kerela
            </Typography>
          </Box>
        </Box> */}

        <Box sx={{ display: "flex", gap: "20px", flexDirection: "column" }}>
          <Box>
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: "14px",
                fontFamily: "'Poppins', sans-serif",
              }}
            >
              State
            </Typography>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: "12px",
                fontFamily: "'Poppins', sans-serif",
                color: "#ACB1C6",
              }}
            >
              {data?.ielts_entrolled_student?.state}
            </Typography>
          </Box>
          <Box>
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: "14px",
                fontFamily: "'Poppins', sans-serif",
              }}
            >
              City
            </Typography>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: "12px",
                fontFamily: "'Poppins', sans-serif",
                color: "#ACB1C6",
              }}
            >
              {data?.ielts_entrolled_student?.city}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          gap: "50px",
        }}
      >
        <Box sx={{ display: "flex", gap: "20px", flexDirection: "column" }}>
          <Box>
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: "14px",
                fontFamily: "'Poppins', sans-serif",
              }}
            >
              Course Name
            </Typography>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: "12px",
                fontFamily: "'Poppins', sans-serif",
                color: "#ACB1C6",
              }}
            >
              {data?.ielts_course?.CourseName}
            </Typography>
          </Box>

          <Box>
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: "14px",
                fontFamily: "'Poppins', sans-serif",
              }}
            >
              Course Type
            </Typography>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: "12px",
                fontFamily: "'Poppins', sans-serif",
                color: "#ACB1C6",
              }}
            >
              {data?.ielts_course?.isOnline ? "Online" : "Offline"}
            </Typography>
          </Box>

          <Box>
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: "14px",
                fontFamily: "'Poppins', sans-serif",
              }}
            >
              Entry Created On
            </Typography>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: "12px",
                fontFamily: "'Poppins', sans-serif",
                color: "#ACB1C6",
              }}
            >
              {data?.ielts_entrolled_student?.createdAt?.split("T")[0]}
            </Typography>
          </Box>

          <Box>
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: "14px",
                fontFamily: "'Poppins', sans-serif",
              }}
            >
              Entry Created On
            </Typography>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: "12px",
                fontFamily: "'Poppins', sans-serif",
                color: "#ACB1C6",
              }}
            >
              {data?.ielts_entrolled_student?.createdAt?.split("T")[0]}
            </Typography>
          </Box>
        </Box>
        {/* <Box sx={{ display: "flex", gap: "20px", flexDirection: "column" }}>
          <Box>
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: "14px",
                fontFamily: "'Poppins', sans-serif",
              }}
            >
              Course Start Date
            </Typography>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: "12px",
                fontFamily: "'Poppins', sans-serif",
                color: "#ACB1C6",
              }}
            >
              19/02/2023
            </Typography>
          </Box>

          <Box>
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: "14px",
                fontFamily: "'Poppins', sans-serif",
              }}
            >
              Course Expiry Date
            </Typography>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: "12px",
                fontFamily: "'Poppins', sans-serif",
                color: "#ACB1C6",
              }}
            >
              19/08/2023
            </Typography>
          </Box>
          <Box>
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: "14px",
                fontFamily: "'Poppins', sans-serif",
              }}
            >
              Entry Created By
            </Typography>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: "12px",
                fontFamily: "'Poppins', sans-serif",
                color: "#ACB1C6",
              }}
            >
              Aravind
            </Typography>
          </Box>

          <Box>
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: "14px",
                fontFamily: "'Poppins', sans-serif",
              }}
            >
              Entry Updated On
            </Typography>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: "12px",
                fontFamily: "'Poppins', sans-serif",
                color: "#ACB1C6",
              }}
            >
              {data?.ielts_entrolled_student?.updatedAt?.split("T")[0]}
            </Typography>
          </Box>

          <Box>
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: "14px",
                fontFamily: "'Poppins', sans-serif",
              }}
            >
              Entry Updated By
            </Typography>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: "12px",
                fontFamily: "'Poppins', sans-serif",
                color: "#ACB1C6",
              }}
            >
              {data?.ielts_entrolled_student?.studentId}
            </Typography>
          </Box>
        </Box> */}
        <Box sx={{ display: "flex", gap: "20px", flexDirection: "column" }}>
          <Box>
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: "14px",
                fontFamily: "'Poppins', sans-serif",
              }}
            >
              Center
            </Typography>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: "12px",
                fontFamily: "'Poppins', sans-serif",
                color: "#ACB1C6",
              }}
            >
              {data?.ielts_center?.CenterName}
            </Typography>
          </Box>

          <Box>
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: "14px",
                fontFamily: "'Poppins', sans-serif",
              }}
            >
              Slots
            </Typography>
            {data?.ielts_center?.ielts_center_slots?.map((item, i) => (
              <Typography
                key={i}
                sx={{
                  fontWeight: 500,
                  fontSize: "12px",
                  fontFamily: "'Poppins', sans-serif",
                  color: "#ACB1C6",
                }}
              >
                { item?.timeFrom + " - " + item?.timeTo}
              </Typography>
            ))}
          </Box>

          <Box>
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: "14px",
                fontFamily: "'Poppins', sans-serif",
              }}
            >
              Payment Status
            </Typography>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: "12px",
                fontFamily: "'Poppins', sans-serif",
                color: "#ACB1C6",
              }}
            >
              {data?.payment}
            </Typography>
          </Box>
        </Box>

        <Box sx={{ display: "flex", gap: "20px", flexDirection: "column" }}>
          <Box>
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: "14px",
                fontFamily: "'Poppins', sans-serif",
              }}
            >
              Status
            </Typography>
            <FormControl
              fullWidth
              sx={{
                mt: 1,
                bgcolor:
                  data?.ielts_entrolled_student?.status === "Active"
                    ? "#228200"
                    : "#E52900",
                borderRadius: "8px",
              }}
            >
              <InputLabel
                htmlFor="demo-simple-select-placeholder"
                style={{
                  transform: "translate(10px, 10px)",
                  pointerEvents: "none",
                  zIndex: 1,
                  transition: "transform 0.25s, font-size 0.25s",
                  margin: "-6px",
                  color: "white",
                  fontWeight: 400,
                  paddingLeft: "10px",
                  border: "none !important",
                }}
              >
                {data?.ielts_entrolled_student?.status}
              </InputLabel>
              <Select
                value={data?.ielts_entrolled_student?.status}
                onChange={(e) => handleStatus(e.target.value)}
                inputProps={{
                  // name: {item.coloum7},
                  id: "demo-simple-select-placeholder",
                }}
                sx={{
                  position: "relative",
                  width: "100px",
                  height: "30px",
                  // outline:"none !important",border:"4px solid #000",
                  ".MuiOutlinedInput-notchedOutline": {
                    outline: "none !important",
                    border: "none !important",
                  },
                }}
              >
                <MenuItem value="Active">Active</MenuItem>
                <MenuItem value="Inactive">Inactive</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default IeltsPurchasedStudentView;
