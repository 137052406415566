// CENTER MANAGMENT CONSTANTS ::::::::::::::::::::::::::::::

// IELTS CENTER MANAGMENT CREATE CONSTANTS :::::::::::::::

export const IELTS_CENTERMANAGMENT_CENTER_CREATE_REQUEST =
  "IELTS_CENTERMANAGMENT_CENTER_CREATE_REQUEST";
export const IELTS_CENTERMANAGMENT_CENTER_CREATE_SUCCESS =
  "IELTS_CENTERMANAGMENT_CENTER_CREATE_SUCCESS";
export const IELTS_CENTERMANAGMENT_CENTER_CREATE_ERR =
  "IELTS_CENTERMANAGMENT_CENTER_CREATE_ERR";

// IELTS CENTER MANAGMENT FIND CONSTANTS :::::::::::::::

export const IELTS_CENTERMANAGMENT_CENTER_FIND_REQUEST =
"IELTS_CENTERMANAGMENT_CENTER_FIND_REQUEST";
export const IELTS_CENTERMANAGMENT_CENTER_FIND_SUCCESS =
"IELTS_CENTERMANAGMENT_CENTER_FIND_SUCCESS";
export const IELTS_CENTERMANAGMENT_CENTER_FIND_ERR =
"IELTS_CENTERMANAGMENT_CENTER_FIND_ERR";


// IELTS CENTER MANAGMENT FINDBYID CONSTANTS :::::::::::::::

export const IELTS_CENTERMANAGMENT_CENTER_FINDBYID_REQUEST =
"IELTS_CENTERMANAGMENT_CENTER_FINDBYID_REQUEST";
export const IELTS_CENTERMANAGMENT_CENTER_FINDBYID_SUCCESS =
"IELTS_CENTERMANAGMENT_CENTER_FINDBYID_SUCCESS";
export const IELTS_CENTERMANAGMENT_CENTER_FINDBYID_ERR =
"IELTS_CENTERMANAGMENT_CENTER_FINDBYID_ERR";


// IELTS CENTER MANAGMENT STATUSCHANGE CONSTANTS :::::::::::::::

export const IELTS_CENTERMANAGMENT_CENTER_STATUSCHANGE_REQUEST =
"IELTS_CENTERMANAGMENT_CENTER_STATUSCHANGE_REQUEST";
export const IELTS_CENTERMANAGMENT_CENTER_STATUSCHANGE_SUCCESS =
"IELTS_CENTERMANAGMENT_CENTER_STATUSCHANGE_SUCCESS";
export const IELTS_CENTERMANAGMENT_CENTER_STATUSCHANGE_ERR =
"IELTS_CENTERMANAGMENT_CENTER_STATUSCHANGE_ERR";

// IELTS CENTER MANAGMENT SLOT UPDATE CONSTANTS :::::::::::::::

export const IELTS_CENTERMANAGMENT_CENTER_SLOTUPDATE_REQUEST =
"IELTS_CENTERMANAGMENT_CENTER_SLOTUPDATE_REQUEST";
export const IELTS_CENTERMANAGMENT_CENTER_SLOTUPDATE_SUCCESS =
"IELTS_CENTERMANAGMENT_CENTER_SLOTUPDATE_SUCCESS";
export const IELTS_CENTERMANAGMENT_CENTER_SLOTUPDATE_ERR =
"IELTS_CENTERMANAGMENT_CENTER_SLOTUPDATE_ERR";


// IELTS CENTER MANAGMENT CENTER DELETE CONSTANTS :::::::::::::::

export const IELTS_CENTERMANAGMENT_CENTER_DELETE_REQUEST =
"IELTS_CENTERMANAGMENT_CENTER_DELETE_REQUEST";
export const IELTS_CENTERMANAGMENT_CENTER_DELETE_SUCCESS =
"IELTS_CENTERMANAGMENT_CENTER_DELETE_SUCCESS";
export const IELTS_CENTERMANAGMENT_CENTER_DELETE_ERR =
"IELTS_CENTERMANAGMENT_CENTER_DELETE_ERR";


// IELTS CENTER MANAGMENT CENTER TEACHER DELETE CONSTANTS :::::::::::::::

export const IELTS_CENTERMANAGMENT_CENTER_TEACHER_DELETE_REQUEST =
"IELTS_CENTERMANAGMENT_CENTER_TEACHER_DELETE_REQUEST";
export const IELTS_CENTERMANAGMENT_CENTER_TEACHER_DELETE_SUCCESS =
"IELTS_CENTERMANAGMENT_CENTER_TEACHER_DELETE_SUCCESS";
export const IELTS_CENTERMANAGMENT_CENTER_TEACHER_DELETE_ERR =
"IELTS_CENTERMANAGMENT_CENTER_TEACHER_DELETE_ERR";


// IELTS CENTER MANAGMENT CENTER TEACHER DELETE CONSTANTS :::::::::::::::

export const IELTS_CENTER_ADD_TEACHER_MODAL_UPDATE_REQUEST =
"IELTS_CENTER_ADD_TEACHER_MODAL_UPDATE_REQUEST";
export const IELTS_CENTER_ADD_TEACHER_MODAL_UPDATE_SUCCESS =
"IELTS_CENTER_ADD_TEACHER_MODAL_UPDATE_SUCCESS";
export const IELTS_CENTER_ADD_TEACHER_MODAL_UPDATE_ERR =
"IELTS_CENTER_ADD_TEACHER_MODAL_UPDATE_ERR";
