import { Alert, AlertTitle, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TopNavigationTable } from "./TopNavigationTable";
import { TopNavigationFindAction } from "../../actions/LandingPageAction";
import usePrivilegeCheck from "../../constants/customHooks/UsePrivilegeCheck";
import TableNoItemComponent from "../customComponent/TableNoItemComponent";

function AllTopNavigation() {
  const dispatch = useDispatch();
  const { privilege, hasPrivilege } = usePrivilegeCheck();

  const [TopNavigationlist, SetTopNavigationList] = useState([]);
  // fetch_Data

  let { topNavigationCreateSuccess } = useSelector((state) => {
    return state.topNavigationCreate;
  });

  let { topNavigationDeleteSuccess } = useSelector((state) => {
    return state.topNavigationDelete;
  });

  console.log(topNavigationDeleteSuccess, "createsuccess");

  useEffect(() => {
    dispatch(TopNavigationFindAction());
  }, [dispatch, topNavigationCreateSuccess, topNavigationDeleteSuccess]);

  let { topNavigationFindSuccess } = useSelector((state) => {
    return state.topNavigationFind;
  });

  // setdata_store

  useEffect(() => {
    if (topNavigationFindSuccess) {
      SetTopNavigationList(topNavigationFindSuccess);
    }
  }, [topNavigationFindSuccess]);

  const data = TopNavigationlist.map((nav, index) => ({
    sl: index + 1,
    id: nav.id,
    coloum1: nav.topNavContent,
    coloum2: nav.callToAction,
  }));

  console.log(topNavigationFindSuccess, "Data_Kitty");

  if (!hasPrivilege("top navigation") && privilege !== null) {
    return (
      <Alert severity="error">
        <AlertTitle>warning</AlertTitle>
        You don't have permission to access — <strong>this page.!</strong>
      </Alert>
    );
  }

  console.log(
    hasPrivilege("slots"),
    "hasPrivilege",
    privilege,
    "hasPrivilegehasPrivilegehasPrivilege"
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} lg={12}>
        {data.length > 0 ? (
          <TopNavigationTable
            columns={[
              {
                title: "SL NO",
                field: "SL NO",
              },
              {
                title: "TOP NAV CONTENT",
                field: "TOP NAV CONTENT",
              },
              {
                title: "CALL TO ACTION",
                field: "CALL TO ACTION",
              },
            ]}
            data={data}
            component="TopNavigation"
            //   isPresentMap={isPresent}
            subMenu="top navigation"
          />
        ) : (
          <TableNoItemComponent />
        )}
      </Grid>
    </Grid>
  );
}

export default AllTopNavigation;
