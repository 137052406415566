import React, { useState } from "react";
import { MainContainer } from "../../content/content.element";
import { Box } from "@mui/material";
import { InfoHeader } from "../../customComponent/InfoHeader";
import BasicTabs from "../../Tabs/TabsMain";

function IeltsTeacherDetailedViewMain(props) {
  const [wichTab, setWichTab] = useState(0);

  const callback = (tab) => {
    setWichTab(tab);
  };
  return (
    <>
      <MainContainer active={props.toggle}>
        <InfoHeader headTitle="Teacher Details" url="/ielts" />
        <Box>
          <BasicTabs
            tab1Name="Details"
            tab2Name="Assigned Students"
            tab1={props.tab1}
            tab2={props.tab2}
            callback={callback}
          />
        </Box>
      </MainContainer>
    </>
  );
}

export default IeltsTeacherDetailedViewMain;
