import axios from "axios";
import { SUCCESS_TRUE_MSG_ERR } from "../../constants/roleManagmentConstant";
import {
  IELTS_TEACHERMANAGMENT_ASSIGNED_STUDENTS_ERR,
  IELTS_TEACHERMANAGMENT_ASSIGNED_STUDENTS_REQUEST,
  IELTS_TEACHERMANAGMENT_ASSIGNED_STUDENTS_SUCCESS,
  IELTS_TEACHERMANAGMENT_TEACHER_CREATE_ERR,
  IELTS_TEACHERMANAGMENT_TEACHER_CREATE_REQUEST,
  IELTS_TEACHERMANAGMENT_TEACHER_CREATE_SUCCESS,
  IELTS_TEACHERMANAGMENT_TEACHER_FINDBYCENTERID_ERR,
  IELTS_TEACHERMANAGMENT_TEACHER_FINDBYCENTERID_REQUEST,
  IELTS_TEACHERMANAGMENT_TEACHER_FINDBYCENTERID_SUCCESS,
  IELTS_TEACHERMANAGMENT_TEACHER_FINDBYID_ERR,
  IELTS_TEACHERMANAGMENT_TEACHER_FINDBYID_REQUEST,
  IELTS_TEACHERMANAGMENT_TEACHER_FINDBYID_SUCCESS,
  IELTS_TEACHERMANAGMENT_TEACHER_FIND_ERR,
  IELTS_TEACHERMANAGMENT_TEACHER_FIND_REQUEST,
  IELTS_TEACHERMANAGMENT_TEACHER_FIND_SUCCESS,
  IELTS_TEACHERMANAGMENT_TEACHER_STATUSCHANGE_ERR,
  IELTS_TEACHERMANAGMENT_TEACHER_STATUSCHANGE_REQUEST,
  IELTS_TEACHERMANAGMENT_TEACHER_STATUSCHANGE_SUCCESS,
  IELTS_TEACHERMANAGMENT_TEACHER_UPDATE_ERR,
  IELTS_TEACHERMANAGMENT_TEACHER_UPDATE_REQUEST,
  IELTS_TEACHERMANAGMENT_TEACHER_UPDATE_SUCCESS,
} from "../../constants/Ielts/ieltsTeacherManagmentConstants";

// TeacherManagment create action

export const ieltsTeacherManagmentCreateAction =
  (formData) => async (dispatch) => {
    try {
      // alert("jjjjjjjjjj");
      dispatch({ type: IELTS_TEACHERMANAGMENT_TEACHER_CREATE_REQUEST });

      let isUserExist = localStorage.getItem("loginInfo")
        ? JSON.parse(localStorage.getItem("loginInfo"))
        : null;

      const config = {
        headers: {
          Authorization: `Bearer ${isUserExist?.token}`,
        },
      };

      let { data } = await axios.post(
        "/api/admin/ielts/teacherManagment/teacherCreate",
        formData,
        config
      );

      dispatch({
        type: IELTS_TEACHERMANAGMENT_TEACHER_CREATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      console.log(error.response, "error.response");
      dispatch({
        type: IELTS_TEACHERMANAGMENT_TEACHER_CREATE_ERR,
        payload: error.response.data,
      });
    }
  };

// TeacherManagment find action

export const ieltsTeacherManagmentFindAction =
  (component) => async (dispatch) => {
    try {
      dispatch({ type: IELTS_TEACHERMANAGMENT_TEACHER_FIND_REQUEST });

      let isUserExist = localStorage.getItem("loginInfo")
        ? JSON.parse(localStorage.getItem("loginInfo"))
        : null;

      const config = {
        headers: {
          Authorization: `Bearer ${isUserExist?.token}`,
        },
      };

      let { data } = await axios.get(
        `/api/admin/ielts/teacherManagment/findTeacher?component=${component}`,
        config
      );

      dispatch({
        type: IELTS_TEACHERMANAGMENT_TEACHER_FIND_SUCCESS,
        payload: data,
      });
    } catch (error) {
      console.log(error.response, "error.response");
      dispatch({
        type: IELTS_TEACHERMANAGMENT_TEACHER_FIND_ERR,
        payload: error.response.data,
      });
    }
  };

// TeacherManagment findByID

export const ieltsTeacherManagmentFindByIdAction = (id) => async (dispatch) => {
  try {
    // alert(id)
    dispatch({ type: IELTS_TEACHERMANAGMENT_TEACHER_FINDBYID_REQUEST });
    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.get(
      `/api/admin/ielts/teacherManagment/findByIdTeacher?id=${id}`,
      config
    );
    console.log(data, "datata");
    dispatch({
      type: IELTS_TEACHERMANAGMENT_TEACHER_FINDBYID_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({
      type: IELTS_TEACHERMANAGMENT_TEACHER_FINDBYID_ERR,
      payload: error.response.data,
    });
  }
};

// TeacherManagment update

export const ieltsTeacherManagmentUpdateAction =
  (formatDate) => async (dispatch) => {
    try {
      // alert("klnononn");
      dispatch({ type: IELTS_TEACHERMANAGMENT_TEACHER_UPDATE_REQUEST });

      let isUserExist = localStorage.getItem("loginInfo")
        ? JSON.parse(localStorage.getItem("loginInfo"))
        : null;

      const config = {
        headers: {
          Authorization: `Bearer ${isUserExist?.token}`,
        },
      };

      let { data } = await axios.post(
        "/api/admin/ielts/teacherManagment/teacherUpdate",
        formatDate,
        config
      );

      dispatch({
        type: IELTS_TEACHERMANAGMENT_TEACHER_UPDATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      console.log(error.response, "error.response");
      dispatch({
        type: IELTS_TEACHERMANAGMENT_TEACHER_UPDATE_ERR,
        payload: error.response.data,
      });
    }
  };

// TeacherManagment StatusChange

export const ieltsTeacherManagmentStatusChangeAction = (status, id) => async (
  dispatch
) => {
  try {
    dispatch({ type: IELTS_TEACHERMANAGMENT_TEACHER_STATUSCHANGE_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.post(
      "/api/admin/ielts/teacherManagment/teacherStatusChange",
      {
        status,
        id,
      },
      config
    );

    dispatch({
      type: IELTS_TEACHERMANAGMENT_TEACHER_STATUSCHANGE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({
      type: IELTS_TEACHERMANAGMENT_TEACHER_STATUSCHANGE_ERR,
      payload: error.response.data,
    });
  }
};

// TeacherManagment FindByCenterId

export const ieltsTeacherManagmentFindByCenterIdAction = (id) => async (
  dispatch
) => {
  try {
    dispatch({ type: IELTS_TEACHERMANAGMENT_TEACHER_FINDBYCENTERID_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.get(
      `/api/admin/ielts/teacherManagment/teacherFindByCenterId?id=${id}`,
      config
    );

    dispatch({
      type: IELTS_TEACHERMANAGMENT_TEACHER_FINDBYCENTERID_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({
      type: IELTS_TEACHERMANAGMENT_TEACHER_FINDBYCENTERID_ERR,
      payload: error.response.data,
    });
  }
};


// TeacherManagment Assigned Students

export const ieltsTeacherManagmentAssignedStudentsAction =
  (id) => async (dispatch) => {
    try {
      dispatch({ type: IELTS_TEACHERMANAGMENT_ASSIGNED_STUDENTS_REQUEST });

      let isUserExist = localStorage.getItem("loginInfo")
        ? JSON.parse(localStorage.getItem("loginInfo"))
        : null;

      const config = {
        headers: {
          Authorization: `Bearer ${isUserExist?.token}`,
        },
      };

      let { data } = await axios.get(
        `/api/admin/ielts/teacherManagment/teacherAssignedStudents?id=${id}`,
        config
      );

      dispatch({
        type: IELTS_TEACHERMANAGMENT_ASSIGNED_STUDENTS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      console.log(error.response, "error.response");
      dispatch({
        type: IELTS_TEACHERMANAGMENT_ASSIGNED_STUDENTS_ERR,
        payload: error.response.data,
      });
    }
  };
