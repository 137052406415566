import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import TopicUploadComponent from "../../../customComponent/TopicUploadComponent";
import TeacherModal from "./TeacherModal";
import { VideoData } from "../../IeltsCourseManagement/courses/courseCategory/Topics/modalDataEntries";
import TeacherModalListItems from "./TeacherModalListItems";
import { useDispatch, useSelector } from "react-redux";
import { ieltsTeacherManagmentFindAction } from "../../../../actions/IeltsActions/teacherManagmentAction";

function SelectTeacher({
  setFormData,
  handleError,
  setSelectedTeacher,
  selectedTeacher,
}) {
  const dispatch = useDispatch();
  const [selectModal, setSelectModal] = useState(false);
  // const [selectedTeacher, setSelectedTeacher] = useState([]);
  const [modalData, setModalData] = useState([]);

  // Find Teachers

  let {
    ieltsTeacherManagmentTeacherFindSuccess,
    ieltsTeacherManagmentTeacherFinderror,
  } = useSelector((state) => {
    return state.ieltsTeacherManagmentTeacherFind;
  });

  useEffect(() => {
    dispatch(ieltsTeacherManagmentFindAction("centerComponent"));
  }, [dispatch]);

  useEffect(() => {
    if (ieltsTeacherManagmentTeacherFindSuccess) {
      setModalData(ieltsTeacherManagmentTeacherFindSuccess.data);
    }
  }, [ieltsTeacherManagmentTeacherFindSuccess]);

  // console.log(
  //   ieltsTeacherManagmentTeacherFindSuccess,
  //   "ieltsTeacherManagmentTeacherFindSuccess"
  // );

  const handleClickOpen = () => {
    setSelectModal(true);
  };

  const handleClose = () => {
    setSelectModal(false);
  };

  const handleDeleteTeacher = (teacherId) => {
    const updatedSelectedTeacher = selectedTeacher.filter(
      (teacher) => teacher.id !== teacherId
    );
    setSelectedTeacher(updatedSelectedTeacher);
    setFormData((prevObject) => ({
      ...prevObject,
      selectedTeacher: updatedSelectedTeacher,
    }));
  };

  const handleSelectedTeacher = (value) => {
    let resultArray;
    resultArray = modalData.filter((item) => value.includes(item.id));
    setSelectedTeacher(resultArray);
    setFormData((prevObject) => ({
      ...prevObject,
      ["selectedTeacher"]: value,
    }));
    handleError((prev) => ({
      ...prev,
      ["selectedTeacher"]: "",
    }));
    setSelectModal(false);
  };

  return (
    <Box sx={{}}>
      <Typography
        sx={{
          mb: "20px",
          fontWeight: "600",
          fontSize: "12px",
        }}
      >
        Select Teachers
      </Typography>

      {selectModal && (
        <TeacherModal
          data={modalData}
          selectedTeacher={selectedTeacher}
          selectModal={selectModal}
          handleClose={handleClose}
          handleSelectedTeacher={handleSelectedTeacher}
        />
      )}
      {selectedTeacher.length === 0 ? (
        <>
          <Box
            sx={{
              ml: "-24px",
              padding: "20px",
              backgroundColor: "rgba(245, 245, 245, 1)",
              display: "grid",
              gridTemplateColumns: {
                sm: "repeat(2,1fr)",
                md: "repeat(3,1fr)",
                lg: "repeat(4,1fr)",
                xl: "repeat(6,13%)",
              },
              gap: "25px",
            }}
          >
            <TopicUploadComponent
              type="add"
              title={"Select Teachers"}
              handleClick={handleClickOpen}
            />
          </Box>
        </>
      ) : (
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr",
            gap: "10px",
          }}
        >
          {selectedTeacher?.map((item, i) => (
            <TeacherModalListItems
              item={item}
              id={i}
              listview={true}
              handleDeleteTeacher={handleDeleteTeacher}
            />
          ))}
          <Box
            sx={{
              padding: "8px",
              boxShadow: "0px 10px 30px 0px rgba(0, 0, 0, 0.08)",
              borderRadius: "8px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "12px",
              position: "relative",
              cursor: "pointer",
              height: "92px",
            }}
            onClick={handleClickOpen}
          >
            <Typography
              sx={{
                fontSize: "16px",
              }}
            >
              Select Teachers
            </Typography>
            <svg
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                y="11.1953"
                width="24"
                height="3.06383"
                rx="1.53191"
                fill="#3B478F"
              />
              <rect
                x="13.5332"
                y="0.726562"
                width="24"
                height="3.06383"
                rx="1.53191"
                transform="rotate(90 13.5332 0.726562)"
                fill="#3B478F"
              />
            </svg>
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default SelectTeacher;
