import React, { useEffect, useRef, useState } from "react";
import { Box, Divider, Grid, Modal, Typography } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";

import InputField from "../../customComponent/InputField";
import { MainContainer } from "../../content/content.element";
import { InfoHeader } from "../../customComponent/InfoHeader";
import { OutLinedButton, SubmitButton } from "../../customComponent/Buttons";
import { ServiceFeature } from "./ServiceFeature";
import {
  LandingServiceEditAction,
  serviceFindByIdAction,
} from "../../../actions/LandingPageAction";
import { useParams } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "400",
  height: "400",
  //   overflow: "scroll",
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 20,
  p: 4,
};

function EditService(props) {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [serviceName, setServiceName] = useState("");
  const [description, setDescription] = useState("");
  const [description1, setDescription1] = useState("");
  const [description2, setDescription2] = useState("");
  const [featuresValue, setFeaturesValue] = useState("");
  const [iconUpload, SetIconUpload] = useState();
  const [feature, setFeatures] = useState([]);

  const serviceIcon = useRef();

  const [open, setOpen] = useState(false);

  let { serviceFindByIdSuccess } = useSelector((state) => {
    return state.serviceFindById;
  });

  let { serviceEditSuccess } = useSelector((state) => {
    return state.LandingServiceEdit;
  });

  // FAQ
  const handleFeatures = (event) => {
    setFeaturesValue(event.target.value);
  };

  const handleServiceIcon = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    // Read the image file as a data URL
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      const UploadDestinationimage = reader.result;

      SetIconUpload(UploadDestinationimage);
    };
  };

  useEffect(() => {
    dispatch(serviceFindByIdAction(id));
  }, [id, serviceEditSuccess]);

  useEffect(() => {
    if (serviceFindByIdSuccess) {
      setServiceName(serviceFindByIdSuccess?.serviceName);
      setDescription(serviceFindByIdSuccess?.description);
      setDescription1(serviceFindByIdSuccess?.description1);
      setDescription2(serviceFindByIdSuccess?.description2);
      setFeatures(serviceFindByIdSuccess?.landing_service_features);
      //   SetIconUpload();
      //   setFeatures();
    }
  }, [serviceFindByIdSuccess]);

  // HandleCreate

  const handleAdd = () => {
    if (featuresValue) {
      const newItem = {
        id: null,
        featureName: featuresValue,
        icon: iconUpload,
      };
      setFeatures((prevData) => [...prevData, newItem]);
      SetIconUpload("");
      setOpen(false); // Clear the input field after saving
    }
  };

  const HandleCreate = () => {
    if (serviceName && description && description1 && description2 && feature) {
      dispatch(
        LandingServiceEditAction(
          id,
          serviceName,
          description,
          description1,
          description2,
          feature
        )
      );
      // setServiceName("");
      // setDescription("");
      // setDescription1("");
      // setDescription2("");
      // setFeatures("");
    } else {
      alert("please fill all fields");
    }
  };

  const handleClose = () => setOpen(false);

  return (
    <MainContainer active={props.toggle}>
      <div
        style={{
          display: "flex",
          justifyContent: "start",
          backgroundColor: "white",
        }}
      >
        <InfoHeader headTitle="Edit Service" url={"/landingPage"} />
      </div>
      <Divider />

      <Grid container sx={{ mt: 3 }}>
        <Grid item xs={12} lg={12}>
          <InputField
            label="Service Name"
            handleChange={(e) => setServiceName(e.target.value)}
            widthSize="98%"
            value={serviceName}
            required
            paramses
          />
        </Grid>
        <Grid container sx={{ mt: 2 }}>
          <Grid item xs={12} lg={12}>
            <InputField
              label="Description"
              handleChange={(e) => setDescription(e.target.value)}
              widthSize="98%"
              value={description}
              InputType="discription"
              dispcriptionHeight="130px"
              required
              paramses
            />
          </Grid>
          <Grid item xs={12} lg={12} mt={4}>
            <Typography sx={{ fontWeight: 700, fontSize: "20px" }}>
              Section 1
            </Typography>
          </Grid>
          <Grid item xs={12} lg={12} mt={1.5}>
            <InputField
              label="Description"
              handleChange={(e) => setDescription1(e.target.value)}
              widthSize="98%"
              value={description1}
              InputType="discription"
              dispcriptionHeight="130px"
              required
              paramses
            />
          </Grid>
        </Grid>
        <Grid item xs={12} lg={12} sx={{ mt: 4 }}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <label
              style={{
                fontWeight: "700",
                fontSize: "20px",
                lineHeight: "24px",
              }}
            >
              Features
            </label>
            <div style={{ marginRight: "10px" }}>
              <OutLinedButton
                title="Add"
                handleClick={() => setOpen(true)}
                widthSize="71px"
                heightSize="35px"
              />
            </div>
          </div>
          <div style={{ marginTop: "20px" }}>
            <ServiceFeature
              columns={[
                {
                  title: "SL NO",
                  field: "SL NO",
                },
                {
                  title: "Features Name",
                  field: "Features",
                },
                {
                  title: "Icon",
                  field: "Icon",
                },
              ]}
              data={feature}
              component="FaqTable"
              //   isPresentMap={setFaq}
            />
          </div>
        </Grid>

        <Grid item xs={12} lg={12} mt={4}>
          <Typography sx={{ fontWeight: 700, fontSize: "20px" }}>
            Section 2
          </Typography>
        </Grid>
        <Grid item xs={12} lg={12} mt={1.5}>
          <InputField
            label="Description"
            handleChange={(e) => setDescription2(e.target.value)}
            widthSize="98%"
            value={description2}
            InputType="discription"
            dispcriptionHeight="130px"
            required
            paramses
          />
        </Grid>
      </Grid>
      {/* Submit Button */}
      <Grid
        item
        xs={4}
        lg={12}
        style={{
          display: "flex",
          justifyContent: "flex-start",
          marginTop: "10px",
        }}
      >
        <SubmitButton
          title="Save"
          submit=""
          widthSize="100px"
          heightSize="38px"
          type="click"
          handleSubmit={HandleCreate}
        />
      </Grid>

      {/* FAQ modal */}

      {open && (
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              sx={{ fontWeight: "bold" }}
            >
              Features:
            </Typography>

            <div
              style={{
                marginTop: "10px",
                paddingTop: "10px",
                width: "500px",
              }}
            >
              <InputField
                label="Features Name"
                handleChange={handleFeatures}
                widthSize="98%"
                value={featuresValue}
                required
                paramses
              />
            </div>
            <Grid item xs={12} lg={12}>
              <button
                onClick={() => serviceIcon.current.click()}
                style={{
                  width: "100px",
                  height: "40px",
                  fontSize: "13px",
                  fontFamily: "monospace",
                  border: "none",
                  cursor: "pointer",
                }}
              >
                Browse
              </button>
              <input
                ref={serviceIcon}
                type="file"
                accept="image/*"
                onChange={(event) => handleServiceIcon(event)}
                style={{ display: "none" }}
              ></input>
            </Grid>
            <Grid
              item
              xs={4}
              lg={12}
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "10px",
              }}
            >
              <SubmitButton
                title="Save"
                submit=""
                widthSize="100px"
                heightSize="38px"
                type="click"
                handleSubmit={handleAdd}
              />
            </Grid>
          </Box>
        </Modal>
      )}
    </MainContainer>
  );
}

export default EditService;
