import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import Swal from "sweetalert2";

import { Avatar, Box, Button, Checkbox, Grid, Typography } from "@mui/material";

import { MainContainer } from "../../content/content.element";
import { allRoleFindAction } from "../../../actions/roleMangmentAction.js";
import {
  addEmployeeAction,
  findAllEmployeeAction
} from "../../../actions/employeeManagmentAction";
import { InfoHeader } from "../../customComponent/InfoHeader";
import { TypographyText1 } from "../../customComponent/Typography";
import { findBranchMasterAction } from "../../../actions/branchMasterActions";

import "./AddEmployee.css";
import InputField, {
  MultiSelect,
  SelectInputField
} from "../../customComponent/InputField";
import { SubmitButton, SwitchButton } from "../../customComponent/Buttons";
import { successTrueMsgAction } from "../../../actions/globalAction";
import { SUCCESS_TRUE_MSG_ERR } from "../../../constants/roleManagmentConstant.js";
import { countries } from "countries-list";

const AddEmployee = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { allRoleFindLoading, allRoleFindSuccess, allRoleFindErr } =
    useSelector((state) => {
      return state.allRoleFind;
    });

  let {
    branchMasterFindLoading,
    branchMasterFindSuccess,
    branchMasterFindErr
  } = useSelector((state) => {
    return state.findBranchMaster;
  });

  let { findEmployeeLoading, findEmployeeSuccess, findEmployeeErr } =
    useSelector((state) => {
      return state.findAllEmployee;
    });

  let { addEmployeeLoading, addEmployeeSuccess, addEmployeeErr } = useSelector(
    (state) => {
      return state.addEmployee;
    }
  );

  const { successAlertMsgSuccess, successAlertMsgErr } = useSelector(
    (state) => {
      return state.successTrueMsg;
    }
  );

  useEffect(() => {
    if (successAlertMsgSuccess && addEmployeeSuccess) {
      Swal.fire("Good job!", ` Employee added successfully`, "success");
      dispatch(successTrueMsgAction(false));
      setAvatarFile(null);
      setName("");
      setPhone("");
      setEmail("");
      setRole("");
      setAdharNumber("");
      setPanNumber("");
      setPassword("");
      setCPassword("");
      setLocation("");
      setTeamLeadId("");
      setBranch([]);
    }
  }, [successAlertMsgSuccess, addEmployeeSuccess]);

  useEffect(() => {
    if (addEmployeeErr && successAlertMsgErr) {
      Swal.fire("error", `${addEmployeeErr}`, "error");
      dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: false });
    }
  }, [addEmployeeErr, successAlertMsgErr]);

  useEffect(() => {
    dispatch(findBranchMasterAction());
  }, [dispatch]);

  console.log(addEmployeeErr, "addEmployeeErraddEmployeeErr");

  const [avatarFile, setAvatarFile] = useState(null);
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [phoneError, setPhoneError] = useState(false);
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");
  const [branch, setBranch] = useState([]);
  const [teamLeadId, setTeamLeadId] = useState("");
  const [isTeamLead, setIsTeamLead] = useState(false);
  const [isLead_B_to_C, setIsLeadBtoC] = useState(false);
  const [isApplicationHandler, setIsApplicationHandler] = useState(false);
  const [isUniversityHandler, setIsUniversityHandler] = useState(false);

  const [isTeleCaller, setIsTeleCaller] = useState(false);
  const [isManager, setIsManager] = useState(false);

  const [adharNumber, setAdharNumber] = useState("");
  const [panNumber, setPanNumber] = useState("");
  const [location, setLocation] = useState("");
  const [password, setPassword] = useState("");
  const [valid, setValid] = useState(true);
  const [cpassword, setCPassword] = useState("");
  const [successAlert, setSuccessAlert] = useState(false);
  const [allEmplyeeData, setAllEmployeeData] = useState([]);
  const [allBtoCEmployeeData, setAllBtoCEmployeeData] = useState([]);
  const [teamLeadaddBtoCid, setTeamLeadBtoCid] = useState("");
  const [countryCode, setCountryCode] = useState("+91");

  const allCountries = Object.entries(countries).map(([code, country]) => ({
    value: code,
    label: country.name,
    phone: `+${country.phone}`,
  }));

  const [error, setError] = useState({});

  useEffect(() => {
    dispatch(findAllEmployeeAction());
  }, [dispatch]);

  useEffect(() => {
    if (findEmployeeSuccess) {
      setAllEmployeeData(findEmployeeSuccess.filter((user) => user.is_lead));
      setAllBtoCEmployeeData(
        findEmployeeSuccess.filter((user) => user.is_Lead_B_to_C)
      );
    }
  }, [findEmployeeSuccess]);

  console.log(
    allBtoCEmployeeData,
    "allBtoCEmployeeDataallBtoCEmployeeDataallBtoCEmployeeData"
  );
  //hook effect
  useEffect(() => {
    dispatch(allRoleFindAction());
  }, [dispatch]);

  useEffect(() => {
    if (allRoleFindSuccess) {
      setSuccessAlert(true);
    }
  }, [dispatch]);

  const handleAvatarChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    // Read the image file as a data URL
    reader.readAsDataURL(file);

    // Set the previewImage state when the reader has finished loading the image
    reader.onloadend = () => {
      setAvatarFile(reader.result);
    };
  };

  // password change
  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    setValid(validatePassword(newPassword));
    setPassword(newPassword);
    setError((prev) => ({
      ...prev,
      ["password"]: ""
    }));
  };

  const validatePassword = (password) => {
    // Check if the password contains at least 6 characters and one symbol
    const regex = /^(?=.*[A-Za-z0-9])(?=.*[!@#$%^&*])[\w!@#$%^&*]{6,}$/;
    return regex.test(password);
  };

  const validate = () => {
    let errors = {};

    if (name === "") {
      errors.name = "Name is required";
    }
    if (location === "") {
      errors.location = "Location is required";
    }
    if(phone !== ""){
      if (!/^\d{8,12}$/.test(phone)) {
        errors.phone = "Phone number must be 8 - 12 digits";
      }
    }
    if (email === "") {
      errors.email = "Email is required";
    } else if (!/^\S+@\S+\.\S+$/.test(email)) {
      errors.email = "Invalid email address";
    }
    if (role === "") {
      errors.role = "Role is required";
    }
    // if (adharNumber === "") {
    //   errors.adharNumber = "Adhar Number is required";
    // } else if (!/^\d{12}$/.test(adharNumber)) {
    //   errors.adharNumber = "Adhar Number must be 12 digits";
    // }
    // if (panNumber === "") {
    //   errors.panNumber = "Pan Number is required";
    // } else if (!/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/.test(panNumber)) {
    //   errors.panNumber = "Invalid PAN format";
    // }

    if (password == "") {
      errors.password = "password is required";
    }

    if (cpassword == "") {
      errors.cpassword = "confirm password is required";
    }

    setError(errors);
    console.log(errors);
    return Object.keys(errors).length === 0;
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    if (password !== cpassword) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Password Do Not Match!"
      });
      return;
    }

    // Perform form validation
    if (validate()) {
      dispatch(
        addEmployeeAction(
          avatarFile,
          name,
          phone,
          email,
          role,
          adharNumber,
          panNumber,
          password,
          cpassword,
          branch,
          location,
          isTeamLead,
          teamLeadId,
          isTeleCaller,
          isManager,
          isLead_B_to_C,
          isUniversityHandler,
          isApplicationHandler,
          teamLeadaddBtoCid,
          countryCode
        )
      );
    }

    // Perform form submission or data handling logic here

    // if (valid) {
    //   if (!phoneError) {
    //     dispatch(
    //       addEmployeeAction(
    //         avatarFile,
    //         name,
    //         phone,
    //         email,
    //         role,
    //         adharNumber,
    //         panNumber,
    //         password,
    //         cpassword,
    //         branch,
    //         location,
    //         isTeamLead,
    //         teamLeadId,isTeleCaller
    //       )
    //     );
    //     setAvatarFile(null);
    //     setName("");
    //     setPhone("");
    //     setEmail("");
    //     setRole("");
    //     setAdharNumber("");
    //     setPanNumber("");
    //     setPassword("");
    //     setCPassword("");
    //   } else {
    //     Swal.fire({
    //       icon: "error",
    //       title: "Oops...",
    //       text: "please add phone number atleast 10 numbers!",
    //     });
    //   }
    // } else {
    //   Swal.fire({
    //     icon: "error",
    //     title: "Oops...",
    //     text: "please add correct password in correct formate!",
    //   });
    // }

    // navigate("/Administration");
  };

  // Handle Change for MultipleBranchSelect

  const handleBranchChange = (event) => {
    // const { value } = event.target;
    // const filteredArray = value;
    console.log(event, "eventeventeventevent");

    setBranch(event);

    setError(false);
  };

    // country Code
    const handleCountryCodeChange = (event) => {
      let value = event.target.value;
      if (value) {
        setCountryCode(value);
      }
    };

  console.log(countryCode, "countryCodecountryCodecountryCode");

  return (
    <MainContainer active={props.toggle}>
      <InfoHeader headTitle="Add Employee" url="/Administration" />
      <Box
        sx={{
          width: "100%",
          height: "50px",
          display: "flex",
          alignItems: "center",
          paddingLeft: "16px",
          marginTop: "18px"
        }}
      >
        <TypographyText1 variant="h6" title="Enter Profile Details" />
      </Box>
      <Typography
        sx={{
          fontWeight: 400,
          fontFamily: "'Open Sans', sans-serif",
          color: "rgba(172, 177, 198, 1)",
          ml: 1.5
        }}
      >
        Profile Picture
      </Typography>

      <Box mt={3} mb={5}>
        <Avatar
          src={avatarFile}
          alt="Avatar"
          sx={{ width: 120, height: 120, backgroundColor: "#F4F4F4", m: 1.5 }}
        >
          {!avatarFile && (
            <label htmlFor="avatar-file">
              <img
                src="/menuIcons/fileUploadCenter.png"
                alt="gsl"
                style={{
                  width: 35,
                  height: 35,
                  cursor: "pointer"
                }}
              />
            </label>
          )}
        </Avatar>
        <input
          type="file"
          accept="image/*"
          id="avatar-file"
          style={{ display: "none" }}
          onChange={handleAvatarChange}
        />
        {/* <Typography
          sx={{
            fontSize: "12px",
            color: "#ACB1C6",
          }}
        >
          Please use 1:1 ratio to give best result
        </Typography> */}
      </Box>
      {/* <form onSubmit={handleFormSubmit}> */}
      <Grid container spacing={2}>
        <Grid item lg={6} xs={12}>
          <InputField
            label="Name"
            handleChange={(e) => {
              setName(e.target.value);
              setError((prev) => ({
                ...prev,
                ["name"]: ""
              }));
            }}
            widthSize="98.4%"
            value={name}
            required
            paramses
            InputType="text"
            dispcriptionHeight
            error={Boolean(error?.name)}
            helperText={error?.name}
          />
        </Grid>
        <Grid item xs={0.8}>
          <SelectInputField
            handleSelectChange={handleCountryCodeChange}
            value={countryCode}
            mapValue={allCountries}
            mapMethod="countryCode"
            widthSize="8px"
          />
        </Grid>
        <Grid item lg={5.1} xs={12}>
          <InputField
            label="Phone"
            widthSize="98.4%"
            handleChange={(e) => {
              setPhone(e.target.value);
              setError((prev) => ({
                ...prev,
                ["phone"]: ""
              }));
            }}
            // handleChange={(e) => {
            //   if (/^\d{0,16}$/.test(e.target.value)) {
            //     /^\d{0,9}$/.test(e.target.value)
            //       ? setPhoneError(true)
            //       : setPhoneError(false);
            //     setPhone(e.target.value);
            //   } else {
            //     setPhoneError(false);
            //   }
            // }}
            // required
            value={phone}
            InputType="number"
            error={Boolean(error?.phone)}
            helperText={error?.phone}
          />
          {/* {phoneError ? (
              <span
                style={{
                  color: "red",
                  fontSize: "13px",
                  fontWeight: 300,
                  paddingTop: "-20px",
                }}
              >
                Please Enter Atleast 10 Numbers
              </span>
            ) : null} */}
        </Grid>
        <Grid item lg={6} xs={12}>
          <InputField
            label="Email"
            handleChange={(e) => {
              setEmail(e.target.value);
              setError((prev) => ({
                ...prev,
                ["email"]: ""
              }));
            }}
            required
            value={email}
            widthSize="98.4%"
            error={Boolean(error?.email)}
            helperText={error?.email}
          />
        </Grid>
        <Grid item lg={6} xs={12}>
          <SelectInputField
            label="Role"
            handleSelectChange={(e) => {
              setRole(e.target.value);
              setError((prev) => ({
                ...prev,
                ["role"]: ""
              }));
            }}
            widthSize="32px"
            value={role}
            mapValue={allRoleFindSuccess}
            mapMethod="role"
          />

          <span
            style={{
              color: "#FF2400",
              fontSize: "12px"
            }}
          >
            {error?.role}
          </span>
        </Grid>
        <Grid item lg={6} xs={12}>
          <InputField
            label="Adhar Number"
            handleChange={(e) => {
              setAdharNumber(e.target.value);
              setError((prev) => ({
                ...prev,
                ["adharNumber"]: ""
              }));
            }}
            value={adharNumber}
            widthSize="98.4%"
            // error={Boolean(error?.adharNumber)}
            // helperText={error?.adharNumber}
          />
        </Grid>
        <Grid item lg={6} xs={12}>
          <InputField
            label="Pan Number"
            handleChange={(e) => {
              setPanNumber(e.target.value);
              setError((prev) => ({
                ...prev,
                ["panNumber"]: ""
              }));
            }}
            value={panNumber}
            widthSize="98.4%"
            // error={Boolean(error?.panNumber)}
            // helperText={error?.panNumber}
          />
        </Grid>
        <Grid item lg={6} xs={12}>
          <InputField
            label="Location"
            handleChange={(e) => {
              setLocation(e.target.value);
              setError((prev) => ({
                ...prev,
                ["location"]: ""
              }));
            }}
            widthSize="98.4%"
            value={location}
            required
            paramses
            InputType="text"
            dispcriptionHeight
            error={Boolean(error?.location)}
            helperText={error?.location}
          />
        </Grid>

        {/* <Grid item lg={6} xs={12}>
          <SelectInputField
            label="Branch"
            handleSelectChange={(e) => setBranch(e.target.value)}
            widthSize="49px"
            value={branch}
            mapValue={branchMasterFindSuccess}
            mapMethod="branch"
          />
        </Grid> */}

        <Grid item lg={6} xs={12}>
          <SelectInputField
            label="Branch"
            handleSelectChange={handleBranchChange}
            widthSize="49px"
            value={branch}
            mapValue={branchMasterFindSuccess}
            mapMethod="branchMaster"
            multiple="true"
          />
          {/* <MultiSelect /> */}
          {/* {rankError ? (
            <p style={{ color: "red", margin: 0, padding: 0 }}>
              Please Select Rank
            </p>
          ) : null} */}
        </Grid>

        {!isTeamLead && (
          <Grid item xs={12}>
            <SelectInputField
              label="Choose B2B lead"
              handleSelectChange={(e) => setTeamLeadId(e.target.value)}
              widthSize="49px"
              value={teamLeadId}
              mapValue={allEmplyeeData}
              mapMethod="teams"
            />
          </Grid>
        )}

        {!isLead_B_to_C && (
          <Grid item xs={12}>
            <SelectInputField
              label="Choose B2C lead"
              handleSelectChange={(e) => setTeamLeadBtoCid(e.target.value)}
              widthSize="49px"
              value={teamLeadaddBtoCid}
              mapValue={allBtoCEmployeeData}
              mapMethod="teams"
            />
          </Grid>
        )}

        <Grid item xs={6} mt={-1}>
          <Box sx={{ display: "flex", gap: "15px" }}>
            <Box>
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: "14px",
                  color: "rgba(138, 146, 166, 1)"
                }}
              >
                Team Lead B2B
              </Typography>
              <Checkbox
                checked={isTeamLead}
                onChange={(e) => {
                  setIsTeamLead(e.target.checked);
                  setTeamLeadId("");
                }}
                style={{ color: "#f81b82" }}
              />
            </Box>
            {/* <SwitchButton label="Team Lead" /> */}
            <Box>
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: "14px",
                  color: "rgba(138, 146, 166, 1)"
                }}
              >
                TeleCaller
              </Typography>
              <Checkbox
                checked={isTeleCaller}
                onChange={(e) => {
                  setIsTeleCaller(e.target.checked);
                  // setTeamLeadId("");
                }}
                style={{ color: "#f81b82" }}
              />
            </Box>

            <Box>
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: "14px",
                  color: "rgba(138, 146, 166, 1)"
                }}
              >
                Manager
              </Typography>
              <Checkbox
                checked={isManager}
                onChange={(e) => {
                  setIsManager(e.target.checked);
                  // setTeamLeadId("");
                }}
                style={{ color: "#f81b82" }}
              />
            </Box>
            <Box>
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: "14px",
                  color: "rgba(138, 146, 166, 1)"
                }}
              >
                Team Lead B2C
              </Typography>
              <Checkbox
                checked={isLead_B_to_C}
                onChange={(e) => {
                  setIsLeadBtoC(e.target.checked);
                  // setTeamLeadId("");
                }}
                style={{ color: "#f81b82" }}
              />
            </Box>
            <Box>
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: "14px",
                  color: "rgba(138, 146, 166, 1)"
                }}
              >
                Application handler
              </Typography>
              <Checkbox
                checked={isApplicationHandler}
                onChange={(e) => {
                  setIsApplicationHandler(e.target.checked);
                  // setTeamLeadId("");
                }}
                style={{ color: "#f81b82" }}
              />
            </Box>
            <Box>
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: "14px",
                  color: "rgba(138, 146, 166, 1)"
                }}
              >
                University handler
              </Typography>
              <Checkbox
                checked={isUniversityHandler}
                onChange={(e) => {
                  setIsUniversityHandler(e.target.checked);
                  // setTeamLeadId("");
                }}
                style={{ color: "#f81b82" }}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>

      <Box
        sx={{
          width: "100%",
          height: "50px",
          display: "flex",
          alignItems: "center",
          marginTop: "30px"
        }}
      >
        <TypographyText1 variant="h6" title="Login Details" />
      </Box>

      <Grid container spacing={2}>
        <Grid item lg={6} xs={12}>
          <InputField
            label="New Password"
            handleChange={handlePasswordChange}
            value={password}
            widthSize="99%"
            error={Boolean(error?.password)}
            helperText={error?.password}
          />
          {!valid && (
            <span
              style={{
                color: "red",
                fontSize: "14px",
                fontWeight: 300,
                paddingTop: "-20px"
              }}
            >
              Password must have at least 6 characters and contain at least one
              symbol (!@#$%^&*)
            </span>
          )}
        </Grid>

        <Grid item lg={6} xs={12}>
          <InputField
            label="Confirm Password"
            handleChange={(e) => {
              setCPassword(e.target.value);
            }}
            value={cpassword}
            widthSize="99%"
            error={Boolean(error?.cpassword)}
            helperText={error?.cpassword}
          />
        </Grid>
      </Grid>

      <Grid item xs={12} sx={{ textAlign: "right", mb: 2 }}>
        <SubmitButton
          title="Save"
          submit="submit"
          widthSize="75px"
          heightSize="42px"
          type="click"
          handleSubmit={handleFormSubmit}
        />
      </Grid>
      {/* </form> */}
    </MainContainer>
  );
};

export default AddEmployee;
