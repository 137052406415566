import React, { useEffect, useState } from "react";
import PartnerTable from "./PartnerTable/PartnerTable";
import { useDispatch, useSelector } from "react-redux";
import { findEnquiriedPartnerAction } from "../../actions/partnerAction/partnerEnquiryAction";
import usePrivilegeCheck from "../../constants/customHooks/UsePrivilegeCheck";
import { Alert, AlertTitle } from "@mui/material";

// material Table
const columns = [
  {
    title: "Sl no",
    field: "sl",
  },
  {
    title: "Partner Name",
    field: "partnerName",
    hidden: false,
  },
  {
    title: "Email",
    field: "email",
    hidden: false,
  },
  {
    title: "Phone     ",
    field: "phone",
    hidden: false,
  },
  {
    title: "Message",
    field: "message",
    hidden: false,
  },
  {
    title: "",
    field: "action",
    filtering: false,
  },
];

function PartnerApproval() {
  const dispatch = useDispatch();

  const [partnerdata, setPartnerData] = useState([]);
  const { privilege, hasPrivilege } = usePrivilegeCheck();


  let { partnerEnquiryFindSuccess } = useSelector((state) => {
    return state.findEnquiriedPartner;
  });

  let { partnerEnquiryStatusSuccess } = useSelector((state) => {
    return state.partnerApprovalStatusUpdate;
  });

  useEffect(() => {
    if (partnerEnquiryFindSuccess) {
      setPartnerData(
        partnerEnquiryFindSuccess?.map((item) => ({
          id: item.id,
          column1: item.name,
          column2: item.email,
          column3: item.phone,
          column4: item.message,
          status: item.status,
        }))
      );
    }
  }, [partnerEnquiryFindSuccess]);

  useEffect(() => {
    dispatch(findEnquiriedPartnerAction());
  }, [dispatch, partnerEnquiryStatusSuccess]);


  if (!hasPrivilege("Partner approvel") && privilege !== null) {
    return (
      <Alert severity="error">
        <AlertTitle>warning</AlertTitle>
        You don't have permission to access — <strong>this page.!</strong>
      </Alert>
    );
  }

  console.log(hasPrivilege("Partner approvel"),"hasPrivilegehasPrivilege")

  return (
    <>
      <PartnerTable
        columns={columns}
        data={partnerdata}
        component="partnerEnquiry"
        subMenu="Partner approvel"

      />
    </>
  );
}

export default PartnerApproval;
