import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Checkbox,
  Chip,
  Collapse,
  FormControlLabel,
  FormGroup,
  Grid,
  List,
  ListItemButton,
  ListSubheader,
  Typography,
} from "@mui/material";
import { SubmitButton } from "../../customComponent/Buttons";
import { useNavigate } from "react-router-dom";
import IeltsZoomConfigurationMain from "./zoomConfiguration/IeltsZoomConfigurationMain";
import IeltsFaqMain from "./Faq/IeltsFaqMain";
import ContactUsMain from "./contactUs/ContactUsMain";
import usePrivilegeCheck from "../../../constants/customHooks/UsePrivilegeCheck";
import PremissionDialog from "../../customComponent/premissionDialog";

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginBottom: theme.spacing(2),
    marginTop: "8px",
  },
  hoverElement: {
    "&:hover": {
      color: "#FE0B7A", // Set the hover color
    },
    "&.clicked": {
      backgroundColor: "#FE0B7A",
      color: "white",
    },
  },
}));

function IeltsSettingsMain() {
  const { privilege, hasPrivilege, isCreatePrevilage } = usePrivilegeCheck();

  const navigate = useNavigate();
  const classes = useStyles();
  const [clicked, setClicked] = useState("Zoom Configuration");

  const [openAlert, setOpenAlert] = useState(false);

  // HandleClose dialog

  const handleCloseDialog = () => {
    setOpenAlert(false);
  };

  const handleClick = (master) => {
    setClicked(master);
  };

  if (!hasPrivilege("settings") && privilege !== null) {
    return (
      <Alert severity="error">
        <AlertTitle>warning</AlertTitle>
        You don't have permission to access — <strong>this page.!</strong>
      </Alert>
    );
  }

  return (
    <>
      <Grid container>
        <Grid
          item
          xs={12}
          sx={{
            gap: "10px",
            display: "flex",
          }}
          lg={6}
        >
          <Chip
            className={`${classes.hoverElement} ${
              clicked === "Zoom Configuration" ? "clicked" : ""
            }`}
            label="Zoom Configuration"
            component="a"
            clickable
            sx={{ m: 1 }}
            onClick={() => handleClick("Zoom Configuration")}
          />
          <Chip
            className={`${classes.hoverElement} ${
              clicked === "FAQ" ? "clicked" : ""
            }`}
            label="FAQ"
            component="a"
            clickable
            sx={{ m: 1 }}
            onClick={() => handleClick("FAQ")}
          />
          <Chip
            className={`${classes.hoverElement} ${
              clicked === "Contact Us" ? "clicked" : ""
            }`}
            label="Contact Us"
            component="a"
            clickable
            sx={{ m: 1 }}
            onClick={() => handleClick("Contact Us")}
          />
        </Grid>
        <Grid
          item
          xs={12}
          lg={6}
          sx={{ display: "flex", justifyContent: "flex-end" }}
        >
          {clicked !== "Contact Us" && (
            <SubmitButton
              title="Add New"
              submit=""
              widthSize="123px"
              heightSize="38px"
              type="click"
              handleSubmit={() => {
                if (isCreatePrevilage("settings")) {
                  navigate(
                    clicked === "Zoom Configuration"
                      ? "/ielts/settings/zoom/addnew"
                      : clicked === "FAQ"
                      ? "/ielts/settings/faq/addnew"
                      : ""
                  );
                } else {
                  setOpenAlert(true);
                }
              }}
            />
          )}
        </Grid>
      </Grid>

      {clicked === "Zoom Configuration" && <IeltsZoomConfigurationMain />}
      {clicked === "FAQ" && <IeltsFaqMain />}
      {clicked === "Contact Us" && <ContactUsMain />}

      {openAlert && (
        <PremissionDialog
          openAlert={openAlert}
          handleClose={handleCloseDialog}
        />
      )}
    </>
  );
}

export default IeltsSettingsMain;
