import React, { useEffect, useState } from "react";
import LeadsTable from "../../Table/LeadsTable";

import { useDispatch, useSelector } from "react-redux";

import { Alert, AlertTitle, Box, Grid, InputAdornment, TextField } from "@mui/material";
import usePrivilegeCheck from "../../../constants/customHooks/UsePrivilegeCheck";
import {
  leadDuplicateApprovalFindAction,
  leadOwnerChangeRequestFindAction
} from "../../../actions/leadActions/leadActions";
import LeadApprovalTable from "./leadApprovalTable";
import Swal from "sweetalert2";
import {
  LEAD_DUPLICATE_APPROVAL_APPROVE_SUCCESS,
  LEAD_DUPLICATE_APPROVAL_REJECT_SUCCESS
} from "../../../constants/leadConstant";
import { makeStyles } from "@material-ui/core";
import { RiSearch2Line } from "react-icons/ri";

const useStyles = makeStyles((theme) => ({
  searchContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(2),
    padding: theme.spacing(1),
    position: "relative"
  },
  searchInput: {
    marginLeft: theme.spacing(1)
  }
}));

function DuplicateLeadApproval(props) {
  const classes = useStyles();

  const dispatch = useDispatch();
  const [leadData, setLeadData] = useState([]);
  const [data, setData] = useState([]);
  const [paginationPageNumber, setPaginationPageNumber] = useState(1);
  const [pagiantionEntry, setPagiantionEntry] = useState(5);
  const [searchText, setSearchText] = useState("");

  let { leadDuplicateApprovalFindSuccess, leadDuplicateApprovalFindError } =
    useSelector((state) => {
      return state.leadDuplicateApprovalFind;
    });

  let { leadDuplicateApproveSuccess, leadDuplicateApproveError } = useSelector(
    (state) => {
      return state.leadDuplicateApprove;
    }
  );

  let { leadDuplicateApprovalRejectSuccess, leadDuplicateApprovalRejectError } =
    useSelector((state) => {
      return state.leadDuplicateReject;
    });

  useEffect(() => {
    if (leadDuplicateApproveSuccess) {
      Swal.fire("success", "Lead Approved Successfully", "success");
      dispatch({
        type: LEAD_DUPLICATE_APPROVAL_APPROVE_SUCCESS,
        payload: false
      });
    }
    if (leadDuplicateApprovalRejectSuccess) {
      Swal.fire("success", "Lead Rejected Successfully", "success");
      dispatch({
        type: LEAD_DUPLICATE_APPROVAL_REJECT_SUCCESS,
        payload: false
      });
    }
  }, [leadDuplicateApproveSuccess, leadDuplicateApprovalRejectSuccess]);

  useEffect(() => {
    dispatch(leadDuplicateApprovalFindAction());
  }, [
    dispatch,
    leadDuplicateApproveSuccess,
    leadDuplicateApprovalRejectSuccess
  ]);

  console.log(
    leadDuplicateApprovalFindSuccess,
    "leadDuplicateApprovalFindSuccessleadDuplicateApprovalFindSuccess"
  );

  // material Table
  const columns = [
    {
      title: "Sl no",
      field: "sl"
    },
    {
      title: "Name",
      field: "name"
      // hidden: showNameColumn,
    },
    {
      title: "Email",
      field: "name"
      // hidden: showNameColumn,
    },
    {
      title: "Phone",
      field: "phone"
      // hidden: showPhoneColumn,
    },
    // {
    //   title: "Current Owner",
    //   field: "leadOwner",
    //   // hidden: showUniversityPlaceColumn,
    // },
    // {
    //   title: "Requested Owner",
    //   field: "counsilor",
    //   // hidden: showUniversityPlaceColumn,
    // },
    // {
    //   title: "Current Branch",
    //   field: "leadOwner",
    //   // hidden: showUniversityPlaceColumn,
    // },
    // {
    //   title: "Requested Branch",
    //   field: "counsilor",
    //   // hidden: showUniversityPlaceColumn,
    // },
    {
      title: "UpdatedBy",
      field: "UpdatedBy"
      // hidden: showUniversityPlaceColumn,
    },

    {
      title: "Reject reason",
      field: "Reject reason"
      // hidden: showUniversityPlaceColumn,
    },

    {
      title: "Request",
      field: "action",
      filtering: false
    }
  ];

  // custom hook for privilage check
  const { privilege, hasPrivilege } = usePrivilegeCheck();

  useEffect(() => {
    if (leadDuplicateApprovalFindSuccess) {
      setLeadData(leadDuplicateApprovalFindSuccess?.data);
    }
  }, [leadDuplicateApprovalFindSuccess]);

  useEffect(() => {
    setData(
      leadData?.map((lead, index) => ({
        id: lead.id,
        sl: index + 1,
        leadId: lead.lead?.id,
        name: lead?.lead?.firstName + " " + lead?.lead?.lastName,
        email: lead?.lead?.email,
        phone: lead.lead?.mobileNumber,
        updatedBy: lead.updatedBy,
        reason: lead?.reason,
        status: lead.Status
      }))
    );
  }, [leadData]);


  const searchHandleChange = (e) => {
    let value = e.target.value;
    if (value) {
      let result = leadDuplicateApprovalFindSuccess?.data?.filter(
        (item) =>
          item.lead?.firstName?.toLowerCase()?.includes(value?.toLowerCase()) ||
          item.lead?.email?.toLowerCase()?.includes(value?.toLowerCase())||
          item.lead?.mobileNumber?.toLowerCase()?.includes(value?.toLowerCase())
      );
      setLeadData(result);
     
    } else {
      setLeadData(leadDuplicateApprovalFindSuccess?.data);    }
  };


  console.log(
    leadDuplicateApprovalFindSuccess,
    "leadDuplicateApprovalFindSuccessleadDuplicateApprovalFindSuccess"
  );

  return (
    <>
      <Box
        sx={{
          mt: "30px",
          display: "flex",
          // alignItems: "center",
          justifyContent: "end"
        }}
      >
        <div className={classes.searchContainer}>
          <TextField
            className={classes.searchInput}
            variant="outlined"
            placeholder="Search Here"
            value={searchText}
            onChange={(e) => {
              setSearchText(e.target.value);
              searchHandleChange(e);
            }}
            sx={{
              "& .MuiInputBase-input": {
                borderRadius: "20px",
                height: "1px",
                paddingRight: "48px !important"
              },
              "& .MuiFormLabel-root": {
                lineHeight: "5px"
              },
              "& .MuiInputBase-root": {
                borderRadius: "3px",
                borderColor: "#fafafa"
              },
              "& .MuiOutlinedInput-notchedOutline ": {
                borderRadius: "3px",
                borderColor: "#ECECEC !important",
                borderWidth: "1px !important"
              }
            }}
          />
          <>
            <InputAdornment
              position="end"
              sx={{
                position: "absolute",
                right: "8px",
                width: "40px",
                // top:"0",
                // bottom:"0",
                border: "1px solid #ECECEC",
                borderRadius: "3px",
                height: "34px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer"
              }}
            >
              <RiSearch2Line />
            </InputAdornment>
          </>
        </div>
      </Box>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <LeadApprovalTable
            data={data}
            columns={columns}
            component="DuplicateLeadApproval"
            selectBoxIdPassHandler={""}
            pageNumber={"page"}
            subMenu={props.submenu}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default DuplicateLeadApproval;
