import {
  ADD_UNIVERSITY_COMMISSION_ERR,
  ADD_UNIVERSITY_COMMISSION_REQUEST,
  ADD_UNIVERSITY_COMMISSION_SUCCESS,
  DELETE_UNIVERSITY_COMMISSION_ERR,
  DELETE_UNIVERSITY_COMMISSION_REQUEST,
  DELETE_UNIVERSITY_COMMISSION_SUCCESS,
  GET_ALL_FINAL_SUMMARY_ERR,
  GET_ALL_FINAL_SUMMARY_REQUEST,
  GET_ALL_FINAL_SUMMARY_SUCCESS,
  LIST_UNIVERSITY_COMMISSION_ERR,
  LIST_UNIVERSITY_COMMISSION_REQUEST,
  LIST_UNIVERSITY_COMMISSION_SUCCESS,
  PAID_PAYMENT_STATUS_UPDATE_ERR,
  PAID_PAYMENT_STATUS_UPDATE_REQUEST,
  PAID_PAYMENT_STATUS_UPDATE_SUCCESS,
  UNIVERSITY_COMMISSION_ERR_BY_ID,
  UNIVERSITY_COMMISSION_REQUEST_BY_ID,
  UNIVERSITY_COMMISSION_SUCCESS_BY_ID,
  UPDATE_UNIVERSITY_COMMISSION_ERR,
  UPDATE_UNIVERSITY_COMMISSION_REQUEST,
  UPDATE_UNIVERSITY_COMMISSION_SUCCESS,
} from "../../constants/reportsConstant";

// get all final summary Reducer
export const getAllFinalSummaryReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_ALL_FINAL_SUMMARY_REQUEST:
      return {
        ...state,
        finalSummaryCountsLoading: true,
      };
    case GET_ALL_FINAL_SUMMARY_SUCCESS:
      return {
        ...state,
        finalSummaryCountsLoading: false,
        finalSummaryCountsSuccess: action.payload,
      };
    case GET_ALL_FINAL_SUMMARY_ERR:
      return {
        ...state,
        finalSummaryCountsLoading: false,
        finalSummaryCountsError: action.payload,
      };
    default:
      return state;
  }
};

// paidPaymentUpdationReducer
export const paidPaymentUpdationReducer = (state = {}, action) => {
  switch (action.type) {
    case PAID_PAYMENT_STATUS_UPDATE_REQUEST:
      return {
        ...state,
        paidPaymentStatusLoading: true,
      };
    case PAID_PAYMENT_STATUS_UPDATE_SUCCESS:
      return {
        ...state,
        paidPaymentStatusLoading: false,
        paidPaymentStatusSuccess: action.payload,
      };
    case PAID_PAYMENT_STATUS_UPDATE_ERR:
      return {
        ...state,
        paidPaymentStatusLoading: false,
        paidPaymentStatusError: action.payload,
      };
    default:
      return state;
  }
};

// add university commission
export const addUniversityCommissionReducer = (state = {}, action) => {
  switch (action.type) {
    case ADD_UNIVERSITY_COMMISSION_REQUEST:
      return {
        ...state,
        adduniversityCommissionLoading: true,
      };
    case ADD_UNIVERSITY_COMMISSION_SUCCESS:
      return {
        ...state,
        adduniversityCommissionLoading: false,
        adduniversityCommissionSuccess: action.payload,
      };
    case ADD_UNIVERSITY_COMMISSION_ERR:
      return {
        ...state,
        adduniversityCommissionLoading: false,
        adduniversityCommissionError: action.payload,
      };
    default:
      return state;
  }
};

// list university commission
export const listUniversityCommissionReducer = (state = {}, action) => {
  switch (action.type) {
    case LIST_UNIVERSITY_COMMISSION_REQUEST:
      return {
        ...state,
        listuniversityCommissionLoading: true,
      };
    case LIST_UNIVERSITY_COMMISSION_SUCCESS:
      return {
        ...state,
        listuniversityCommissionLoading: false,
        listuniversityCommissionSuccess: action.payload,
      };
    case LIST_UNIVERSITY_COMMISSION_ERR:
      return {
        ...state,
        listuniversityCommissionLoading: false,
        listuniversityCommissionError: action.payload,
      };
    default:
      return state;
  }
};

// delete university commission
export const deleteUniversityCommissionReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_UNIVERSITY_COMMISSION_REQUEST:
      return {
        ...state,
        deleteuniversityCommissionLoading: true,
      };
    case DELETE_UNIVERSITY_COMMISSION_SUCCESS:
      return {
        ...state,
        deleteuniversityCommissionLoading: false,
        deleteuniversityCommissionSuccess: action.payload,
      };
    case DELETE_UNIVERSITY_COMMISSION_ERR:
      return {
        ...state,
        deleteuniversityCommissionLoading: false,
        deleteuniversityCommissionError: action.payload,
      };
    default:
      return state;
  }
};

// university commission find by id
export const UniversityCommissionFindByIdReducer = (state = {}, action) => {
  switch (action.type) {
    case UNIVERSITY_COMMISSION_REQUEST_BY_ID:
      return {
        ...state,
        universityCommissionByIdLoading: true,
      };
    case UNIVERSITY_COMMISSION_SUCCESS_BY_ID:
      return {
        ...state,
        universityCommissionByIdLoading: false,
        universityCommissionByIdSuccess: action.payload,
      };
    case UNIVERSITY_COMMISSION_ERR_BY_ID:
      return {
        ...state,
        universityCommissionByIdLoading: false,
        universityCommissionByIdError: action.payload,
      };
    default:
      return state;
  }
};

// update university commission 
export const updateUniversityCommissionReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_UNIVERSITY_COMMISSION_REQUEST:
      return {
        ...state,
        updateUniversityCommissionLoading: true,
      };
    case UPDATE_UNIVERSITY_COMMISSION_SUCCESS:
      return {
        ...state,
        updateUniversityCommissionLoading: false,
        updateUniversityCommissionSuccess: action.payload,
      };
    case UPDATE_UNIVERSITY_COMMISSION_ERR:
      return {
        ...state,
        updateUniversityCommissionLoading: false,
        updateUniversityCommissionError: action.payload,
      };
    default:
      return state;
  }
};
