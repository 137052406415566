import React, { useEffect, useState } from "react";
import BasicTabs from "../../../../Tabs/TabsMain";
import { useSelector } from "react-redux";
import OnlineTabEdit from "./onlineEdit/OnlineTabEdit";
import OfflineTabEdit from "./offlineEdit/OfflineTabEdit";

function CourseCategoryMainEdit({ switches }) {
  let { ieltsCourseManagmentCourseCreateSuccess } = useSelector((state) => {
    return state.ieltsCourseManagmentCreateCourse;
  });

  const [formData, setFormData] = useState();

  useEffect(() => {
    if (ieltsCourseManagmentCourseCreateSuccess) {
      setFormData(ieltsCourseManagmentCourseCreateSuccess);
    }
  }, [ieltsCourseManagmentCourseCreateSuccess]);


  return (
    <div>
      {(formData?.isOnline || formData?.isOffline) && (
        <BasicTabs
          tab1Name={formData ? formData.isOnline && "Online" : null}
          tab2Name={formData ? formData.isOffline && "Offline" : null}
          // selected={true}
          tab1={
            formData?.isOnline ? (
              <OnlineTabEdit selectedTab={switches} />
            ) : formData?.isOffline ? (
              <OfflineTabEdit />
            ) : null
          }
          tab2={formData?.isOffline && <OfflineTabEdit />}
        />
      )}
    </div>
  );
}

export default CourseCategoryMainEdit;
