import { Button, Grid, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  DoubleBoutton,
  OutLinedButton,
  SubmitButton,
} from "../../../customComponent/Buttons";
import InputField from "../../../customComponent/InputField";
import { TypographyText } from "../../../customComponent/Typography";

function AboutUniversity({ handleBack, handleComplete }) {
  const [universityAbout, setUniversityAbout] = useState("");
  const [universityAboutError, setUniversityAboutError] = useState("");

  useEffect(() => {
    // Retrieve column visibility state from localStorage

    const storedUniversityAboutColumn = localStorage.getItem("universityAbout");

    if (storedUniversityAboutColumn !== null) {
      setUniversityAbout(JSON.parse(storedUniversityAboutColumn));
    }
  }, []);

  const handleUniversityAboutChange = (event) => {
    setUniversityAbout(event.target.value);
    setUniversityAboutError(false);
  };

  const aboutSubmitHandler = (e) => {
    e.preventDefault();
    if (universityAbout.trim() === "") {
      setUniversityAboutError(true);
    }
    handleComplete("completed");
    localStorage.setItem("universityAbout", JSON.stringify(universityAbout));
  };

  return (
    <>
      <TypographyText title="About University" />
      <form onSubmit={aboutSubmitHandler}>
        <Grid container>
          <Grid item xs={12} sx={{ mt: 2 }}>
            {/* <TextField
              multiline
              rows={4}
              sx={{ height: "auto" }}
              label="Write About University "
              value={universityAbout}
              onChange={handleUniversityAboutChange}
              fullWidth
              required
              error={universityAboutError}
              helperText={
                universityAboutError ? "University About is required" : ""
              }
            /> */}

            <InputField
              label="Write About University "
              handleChange={handleUniversityAboutChange}
              widthSize="99%"
              value={universityAbout}
              InputType="discription"
              dispcriptionHeight="130px"
              maxLength="1000"
              isRequired={true}
            />
          </Grid>
        </Grid>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            margin: "10px",
          }}
        >
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <div style={{ margin: "5px" }}>
              <OutLinedButton
                title="Previous"
                submit=""
                widthSize="105px"
                heightSize="45px"
                type="click"
                handleClick={handleBack}
              />
            </div>

            <div style={{ margin: "5px" }}>
              <SubmitButton
                title="Next"
                submit="submit"
                widthSize="105px"
                heightSize="45px"
                type=""
                handleBack=""
              />
            </div>
            {/* <DoubleBoutton
              buttonName1="Previous"
              buttonName2="Next"
              handleBack={handleBack}
            /> */}
          </div>
        </div>
      </form>
    </>
  );
}

export default AboutUniversity;
