import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ModalTopicListComponent from "../../IeltsCourseManagement/courses/courseCategory/Topics/ModalTopicListComponent";
import { SubmitButton } from "../../../customComponent/Buttons";
import TeacherModalListItems from "./TeacherModalListItems";
import { SelectinputFieldCustom } from "../../../customComponent/InputField";
import { useDispatch, useSelector } from "react-redux";
import { ieltsCategoryFindAction } from "../../../../actions/IeltsActions/learningManadmentAction";

function TeacherModal({
  selectModal,
  handleClose,
  type,
  data,
  handleSelectedTeacher,
  selectedTeacher,
}) {
  const dispatch = useDispatch();

  let { ieltsCategoryFindSuccess } = useSelector((state) => {
    return state.ieltsCategoryList;
  });

  const [filterData, setFilterData] = useState();
  const [searchKeyword, setSearchKeyword] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedCheckboxes, setSelectedCheckboxes] = useState();
  const [categoryList, setCategoryList] = useState();

  useEffect(() => {
    setSelectedCheckboxes(selectedTeacher?.map((teacher) => teacher.id));
  }, [selectedTeacher]);

  useEffect(() => {
    dispatch(ieltsCategoryFindAction("categoryTable"));
  }, [dispatch]);

  useEffect(() => {
    setFilterData(data);
  }, []);

  useEffect(() => {
    handleFilter(searchKeyword, selectedCategory);
  }, [searchKeyword, selectedCategory]);

  useEffect(() => {
    if (ieltsCategoryFindSuccess) {
      setCategoryList(ieltsCategoryFindSuccess.data);
    }
  }, [ieltsCategoryFindSuccess]);

  const handleFilter = (name, cat) => {
    let searchFilter =
      searchKeyword === ""
        ? data
        : data?.filter((search) =>
            search?.teacherName?.toLowerCase().includes(name?.toLowerCase())
          );

    let categoryfilter =
      selectedCategory === ""
        ? searchFilter
        : searchFilter?.filter((category) =>
            category?.teacherSpecialization
              ?.toLowerCase()
              .includes(cat?.toLowerCase())
          );

    setFilterData(categoryfilter);
  };

  const handleSearchChange = (event) => {
    setSearchKeyword(event.target.value);
  };

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };


  // const array = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 0];

  // const categoryList = ["Speaking", "Writing", "Reading", "Listening"];
  // const [selectedCheckboxes, setSelectedCheckboxes] = useState();
  // const [category,setCategory] = useState()

  // useEffect(() => {
  //   setSelectedCheckboxes(selectedTeacher.map((teacher) => teacher.id));
  // }, [selectedTeacher]);


  const handleCheckboxChange = (index) => {
    setSelectedCheckboxes((prevSelected) => {
      if (prevSelected.includes(index)) {
        return prevSelected.filter((item) => item !== index);
      } else {
        return [...prevSelected, index];
      }
    });
  };

  const handleSelected = () => {
    handleSelectedTeacher(selectedCheckboxes);
  };

  // const handleCategory = (e)=>{
  //   setCategory(e.target.value)
  // }
  // console.log(category,"categoryyyyyyyyyyyyyyyyyyyyy")

  return (
    <Dialog
      open={selectModal}
      onClose={handleClose}
      sx={{
        "& .MuiDialog-paper": {
          maxWidth: "100%",
          width: "1200px",
        },
      }}
    >
      <DialogTitle
        sx={{
          borderBottom: "1px solid #ECECEC",
          display: "flex",
          width: "900px",
          maxWidth: "100%",
          gap: "30px",
        }}
      >
        <Box
          component={"form"}
          sx={{
            width: "100%",
            maxWidth: "530px",
            display: "flex",
            alignItems: "center",
            border: "1px solid #C4C4C4",
            borderRadius: "4px",
            height: "44px",
            "& input": {
              padding: "8px",
              outline: "none",
              border: "none",
              width: "100%",
            },
          }}
        >
          <input placeholder="Search" onChange={handleSearchChange} />
          <Button>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.5 21.75C5.85 21.75 1.25 17.15 1.25 11.5C1.25 5.85 5.85 1.25 11.5 1.25C17.15 1.25 21.75 5.85 21.75 11.5C21.75 17.15 17.15 21.75 11.5 21.75ZM11.5 2.75C6.67 2.75 2.75 6.68 2.75 11.5C2.75 16.32 6.67 20.25 11.5 20.25C16.33 20.25 20.25 16.32 20.25 11.5C20.25 6.68 16.33 2.75 11.5 2.75Z"
                fill="#ACB1C6"
              />
              <path
                d="M21.9995 22.7495C21.8095 22.7495 21.6195 22.6795 21.4695 22.5295L19.4695 20.5295C19.1795 20.2395 19.1795 19.7595 19.4695 19.4695C19.7595 19.1795 20.2395 19.1795 20.5295 19.4695L22.5295 21.4695C22.8195 21.7595 22.8195 22.2395 22.5295 22.5295C22.3795 22.6795 22.1895 22.7495 21.9995 22.7495Z"
                fill="#ACB1C6"
              />
            </svg>
          </Button>
        </Box>
        <Box
          sx={{
            maxWidth: "300px",
            width: "100%",
          }}
        >
          <SelectinputFieldCustom
            array={categoryList}
            label="category"
            handleChange={handleCategoryChange}
            mapMethod="centerCategory"
          />
          {/* <SelectinputFieldCustom array={categoryList} label="category" handleChange={handleCategory} /> */}
        </Box>
      </DialogTitle>
      {filterData?.length > 0 ? (
        <DialogContent
          sx={{
            paddingTop: "15px !important",
            display: "grid",
            gridTemplateColumns: "1fr 1fr 1fr ",
            gap: "32px",
          }}
        >
          {filterData?.map((item, i) => (
            <TeacherModalListItems
              key={i}
              indexNumber={i}
              item={item}
              type={type}
              handleCheckboxChange={handleCheckboxChange}
              selectedCheckboxes={selectedCheckboxes}
            />
          ))}
        </DialogContent>
      ) : (
        <DialogContent
          sx={{
            paddingTop: "15px !important",
          }}
        >
          <Box
            sx={{
              padding: "55px",
              textAlign: "center",
              position: "relative",
            }}
          >
            No Items Found
          </Box>
        </DialogContent>
      )}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          gap: "20px",
          padding: "16px 24px",
        }}
      >
        <Typography>
          {" "}
          {selectedCheckboxes?.length} of {data?.length}
        </Typography>
        <Box
          sx={{
            display: "flex",
            gap: "12px",
          }}
        >
          <Button
            sx={{
              color: "#141E3C",
              fontWeight: "700",
            }}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <SubmitButton
            type={"click"}
            handleSubmit={handleSelected}
            title={"Done"}
          />
        </Box>
      </Box>
    </Dialog>
  );
}

export default TeacherModal;
