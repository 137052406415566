import React, { useEffect, useState } from "react";
import LeadsTable from "../Table/LeadsTable";
import LeadsFilterSearch from "./LeadsFilterSearch";

import { Alert, AlertTitle, Grid } from "@mui/material";
import LeadersSearchHeader from "./LeadersSearchHeader";
import {
  deleteLeadAction,
  leadFindAction,
} from "../../actions/leadActions/leadActions";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import usePrivilegeCheck from "../../constants/customHooks/UsePrivilegeCheck";
import { findUserAction } from "../../actions/adminAuthAction";
import DownloadExcel from "../extraComponents/DownloadExcel";

function LeadsDrop(props) {
  const dispatch = useDispatch();
  const [filterPassData, setFilterPassData] = useState();
  const [whichFilter, setwhichFilter] = useState();
  const [reset, setReset] = useState(false);
  const [leadData, setLeadData] = useState([]);
  const [data, setData] = useState([]);
  const [paginationPageNumber, setPaginationPageNumber] = useState(1);
  const [pagiantionEntry, setPagiantionEntry] = useState(8);

  const { privilege, hasPrivilege } = usePrivilegeCheck();

  let { leadFindLoading, leadFindSuccess } = useSelector((state) => {
    return state.leadFind;
  });

  let { leadAddSuccess } = useSelector((state) => {
    return state.leadAdd;
  });

  let { leadDeleteSuccess } = useSelector((state) => {
    return state.deleteLead;
  });

  let { leadOwnerSuccess } = useSelector((state) => {
    return state.leadOwnerChange;
  });

  let { loginFindSuccess } = useSelector((state) => {
    return state.findUser;
  });

  useEffect(() => {
    dispatch(findUserAction());
  }, [dispatch]);

  useEffect(() => {
    dispatch(leadFindAction());
  }, [dispatch, leadAddSuccess, leadDeleteSuccess, leadOwnerSuccess]);

  useEffect(() => {
    if (leadFindSuccess || reset) {
      setLeadData(leadFindSuccess?.filter((data) => data.status === "Drop"));
    }
  }, [leadFindSuccess, reset]);

  useEffect(() => {
    setData(
      leadData
        ?.map((lead, index) => {
          if (lead.status !== "Application Started") {
            return {
              id: lead.id,
              sl: index + 1,
              name: lead?.firstName,
              email: lead.email,
              phone: lead.mobileNumber,
              whatsappNumber: lead.whatsappNumber,
              preferredCountry: lead.preferredCountryId,
              leadSource: lead.leadSource,
              leadOwner:
                loginFindSuccess &&
                loginFindSuccess.role !== null &&
                loginFindSuccess.role?.roleName === "branch manager"
                  ? lead?.user?.firstName
                  : lead.leadOwnerName,
              status: lead.status,
              priority: lead.priority ? lead.priority : "-",
              leadCity: lead.location,
              branchId: lead.branchId,
              leadBranch: lead?.branch_master?.branchName
                ? lead?.branch_master?.branchName
                : "-",
              leadCounsilor:
                loginFindSuccess?.role?.roleName !== "counsellor"
                  ? lead?.counsilorID
                    ? lead?.counsilorID
                    : "-"
                  : null,
              leadTeleCaller:
                loginFindSuccess?.role?.roleName !== "tele caller"
                  ? lead?.teleCallerId
                    ? lead?.teleCallerId
                    : "-"
                  : null,
              Reason:lead?.Reason
            };
          }
          return null;
        })
        .filter(Boolean)
    );
  }, [leadData]);

  console.log(data, "leadFindSuccessleadFindSuccessleadFindSuccess");

  const searchHandleChange = (e) => {
    if (e.target.value !== "" && e.target.value !== " ") {
      setData(
        data?.filter((item) => {
          const name = item.name ? item.name.toLowerCase() : "";
          const email = item.email ? item.email.toLowerCase() : "";
          const phone = item.phone ? item.phone.toLowerCase() : "";
          const whatsappNumber = item.whatsappNumber
            ? item.whatsappNumber.toLowerCase()
            : "";
          const preferredCountry = item.preferredCountry
            ? item.preferredCountry.toLowerCase()
            : "";
          const leadSource = item.leadSource
            ? item.leadSource.toLowerCase()
            : "";
          const leadOwner = item.leadOwner ? item.leadOwner.toLowerCase() : "";
          const status = item.status ? item.status.toLowerCase() : "";
          const education = item.education ? item.education.toLowerCase() : "";

          const leadCity = item.leadCity ? item.leadCity.toLowerCase() : "";
          const leadBranch = item.branchMaster
            ? item.leadCity.toLowerCase()
            : "";

          // :lead?.branch_master?.branchName
          return (
            name.includes(e.target.value.toLowerCase()) ||
            phone.includes(e.target.value.toLowerCase()) ||
            whatsappNumber.includes(e.target.value.toLowerCase()) ||
            email.includes(e.target.value.toLowerCase()) ||
            preferredCountry.includes(e.target.value.toLowerCase()) ||
            leadSource.includes(e.target.value.toLowerCase()) ||
            leadOwner.includes(e.target.value.toLowerCase()) ||
            status.includes(e.target.value.toLowerCase()) ||
            education.includes(e.target.value.toLowerCase()) ||
            leadCity.includes(e.target.value.toLowerCase()) ||
            leadBranch.includes(e.target.value.toLowerCase())
          );
        })
      );
    } else {
      setData(
        leadData
          ?.map((lead, index) => {
            if (lead.status !== "Application Started") {
              return {
                id: lead.id,
                sl: index + 1,
                name: lead?.firstName,
                email: lead.email,
                phone: lead.mobileNumber,
                whatsappNumber: lead.whatsappNumber,
                preferredCountry: lead.preferredCountryId,
                leadSource: lead.leadSource,
                leadOwner:
                  loginFindSuccess &&
                  loginFindSuccess.role !== null &&
                  loginFindSuccess.role?.roleName === "branch manager"
                    ? lead?.user?.firstName
                    : lead.leadOwnerName,
                status: lead.status,
                priority: lead.priority ? lead.priority : "-",
                leadCity: lead.location,
                branchId: lead.branchId,
                leadBranch: lead?.branch_master?.branchName
                  ? lead?.branch_master?.branchName
                  : "-",
                leadCounsilor:
                  loginFindSuccess?.role?.roleName !== "counsellor"
                    ? lead?.counsilorID
                      ? lead?.counsilorID
                      : "-"
                    : null,
                leadTeleCaller:
                  loginFindSuccess?.role?.roleName !== "tele caller"
                    ? lead?.counsilorID
                      ? lead?.counsilorID
                      : "-"
                    : null,
              };
            }
            return null;
          })
          .filter(Boolean)
      );
    }
  };

  // Employee column Visibility
  const [showNameColumn, setShowNameColumn] = useState(false);
  const [showEmailColumn, setShowEmailColumn] = useState(false);
  const [showPrefferedColumn, setShowPrefferedColumn] = useState(false);
  const [showPhoneColumn, setShowPhoneColumn] = useState(false);
  const [showWhatsAppNumColumn, setShowWhatsAppNumColumn] = useState(false);
  const [showLeadSourceColumn, setShowLeadSourceColumn] = useState(false);
  const [showLeadOwnerColumn, setShowLeadOwnerColumn] = useState(false);
  const [showLeadBranchColumn, setShowLeadBranchColumnn] = useState(false);
  const [showStatusColumn, setShowStatusColumn] = useState(false);
  const [showPriorityColumn, setShowPriorityColumn] = useState(false);
  const [showLeadPlaceColumn, setShowLeadPlaceColumn] = useState(false);
  const [showTelecallerColumn, setShowTelecallerColumn] = useState(false);
  const [showCounsilorColumn, setShowCounsilorColumn] = useState(false);
  const [showReasonColumn, setReasonColumn] = useState(false);

  const [columnName, setColumnName] = useState("");
  const [leadId, setLeadId] = useState([]);
  const [ids, setIds] = useState([]);

  // material Table
  const columns = [
    {
      title: "Sl no",
      field: "sl",
    },
    {
      title: "Name",
      field: "name",
      hidden: showNameColumn,
    },
    {
      title: "Email",
      field: "email",
      hidden: showEmailColumn,
    },
    {
      title: "Phone     ",
      field: "phone",
      hidden: showPhoneColumn,
    },

    {
      title: "What's App Number",
      field: "whatsappNumber",
      hidden: showWhatsAppNumColumn,
    },
    {
      title: "Preferred Country",
      field: "preferredCountry",
      hidden: showPrefferedColumn,
    },
    {
      title: "Lead Source",
      field: "leadSource",
      hidden: showLeadSourceColumn,
    },
    {
      title:
        loginFindSuccess && loginFindSuccess?.role?.roleName == "branch manager"
          ? "Counsilor"
          : "Lead Owner",
      field: "leadOwner",
      hidden: showLeadOwnerColumn,
    },
    {
      title: "Lead Branch",
      field: "leadBranch",
      hidden: showLeadBranchColumn,
    },
    {
      title: "Status",
      field: "status",
      hidden: showStatusColumn,
    },
    {
      title: "Priority",
      field: "priority",
      hidden: showPriorityColumn,
    },
    {
      title: "Lead Place",
      field: "leadCity",
      hidden: showLeadPlaceColumn,
    },
    {
      title: "Reason",
      field: "reason",
      hidden: showReasonColumn,
    },
    {
      title:
        loginFindSuccess?.role == null ||
        loginFindSuccess?.isManager ||
        loginFindSuccess?.role?.roleName !== "tele caller"
          ? "TeleCaller"
          : null,
      field: "TeleCaller",
      hidden: showTelecallerColumn,
    },
    {
      title:
        loginFindSuccess?.role == null ||
        loginFindSuccess?.isManager ||
        loginFindSuccess?.role?.roleName !== "counsellor"
          ? "Counsilor"
          : null,
      field: "Counsilor",
      hidden: showCounsilorColumn,
    },
    {
      title: "",
      field: "action",
      filtering: false,
    },
  ];
  const excelDownloadClick = () => {
    DownloadExcel(columns, data, "Lead_Managment");
  };

  const toggleNameColumn = (status, column) => {
    if (column === "name") {
      setColumnName(column);
      setShowNameColumn(status);
    }

    if (column === "email") {
      setColumnName(column);
      setShowEmailColumn(status);
    }

    if (column === "mobile") {
      setColumnName(column);
      setShowPhoneColumn(status);
    }

    if (column === "whatappnumber") {
      setColumnName(column);
      setShowWhatsAppNumColumn(status);
    }

    if (column === "prefferedCountry") {
      setColumnName(column);
      setShowPrefferedColumn(status);
    }
    if (column === "leadSource") {
      setColumnName(column);
      setShowLeadSourceColumn(status);
    }

    if (column === "leadowner") {
      setColumnName(column);
      setShowLeadOwnerColumn(status);
    }

    if (column === "leadbranch") {
      setColumnName(column);
      setShowLeadBranchColumnn(status);
    }

    if (column === "status") {
      setColumnName(column);
      setShowStatusColumn(status);
    }

    if (column === "priority") {
      setColumnName(column);
      setShowPriorityColumn(status);
    }

    if (column === "place") {
      setColumnName(column);
      setShowLeadPlaceColumn(status);
    }

    if (column === "reason") {
      setColumnName(column);
      setReasonColumn(status);
    }

    if (column === "telecaller") {
      setColumnName(column);
      setShowTelecallerColumn(status);
    }

    if (column === "counsilor") {
      setColumnName(column);
      setShowCounsilorColumn(status);
    }
  };

  useEffect(() => {
    // Retrieve column visibility state from localStorage
    const storedRollNameColumn = localStorage.getItem("Dropname");
    const storedRollEmailColumn = localStorage.getItem("Dropemail");
    const storedPhoneColumn = localStorage.getItem("Dropmobile");
    const storedWhatsAppColumn = localStorage.getItem("Dropwhatappnumber");
    const storedPrefferedCountryColumn = localStorage.getItem(
      "DropprefferedCountry"
    );
    const storedLeadSourceColumn = localStorage.getItem("DropleadSource");
    const storedLeadOwnerColumn = localStorage.getItem("DropleadOwner");
    const storedLeadBranchColumn = localStorage.getItem("Dropleadbranch");
    const storedStatusColumn = localStorage.getItem("Dropstatus");
    const storedPriorityColumn = localStorage.getItem("Droppriority");
    const storedPlaceColumn = localStorage.getItem("Dropplace");
    const storedTeleCallerColumn = localStorage.getItem("Droptelecaller");
    const storedReasonColumn = localStorage.getItem("DropDeferalreason");
    const storedCounsilorColumn = localStorage.getItem("Dropcounsilor");

    if (storedRollNameColumn !== null) {
      setColumnName("name");
      setShowNameColumn(JSON.parse(storedRollNameColumn));
    }

    if (storedRollEmailColumn !== null) {
      setColumnName("email");
      setShowEmailColumn(JSON.parse(storedRollEmailColumn));
    }

    // if (storedDescriptionNameColumn !== null) {
    //   setColumnName("email");
    //   setShowPrefferedColumn(JSON.parse(storedDescriptionNameColumn));
    // }
    if (storedPhoneColumn !== null) {
      setColumnName("mobile");
      setShowPhoneColumn(JSON.parse(storedPhoneColumn));
    }

    if (storedWhatsAppColumn !== null) {
      setColumnName("whatsAppNumber");
      setShowWhatsAppNumColumn(JSON.parse(storedWhatsAppColumn));
    }

    if (storedPrefferedCountryColumn !== null) {
      setColumnName("prefferedCountry");
      setShowPrefferedColumn(JSON.parse(storedPrefferedCountryColumn));
    }
    if (storedLeadSourceColumn !== null) {
      setColumnName("leadSource");
      setShowLeadSourceColumn(JSON.parse(storedLeadSourceColumn));
    }

    if (storedLeadOwnerColumn !== null) {
      setColumnName("leadOwner");
      setShowLeadOwnerColumn(JSON.parse(storedLeadOwnerColumn));
    }
    if (storedLeadBranchColumn !== null) {
      setColumnName("leadbranch");
      setShowLeadBranchColumnn(JSON.parse(storedLeadBranchColumn));
    }
    if (storedStatusColumn !== null) {
      setColumnName("status");
      setShowStatusColumn(JSON.parse(storedStatusColumn));
    }

    if (storedPriorityColumn !== null) {
      setColumnName("priority");
      setShowPriorityColumn(JSON.parse(storedPriorityColumn));
    }

    if (storedPlaceColumn !== null) {
      setColumnName("place");
      setShowLeadPlaceColumn(JSON.parse(storedPlaceColumn));
    }

    if (storedReasonColumn !== null) {
      setColumnName("reason");
      setReasonColumn(JSON.parse(storedReasonColumn));
    }

    if (storedTeleCallerColumn !== null) {
      setColumnName("telecaller");
      setShowTelecallerColumn(JSON.parse(storedTeleCallerColumn));
    }

    if (storedCounsilorColumn !== null) {
      setColumnName("counsilor");
      setShowCounsilorColumn(JSON.parse(storedCounsilorColumn));
    }
  }, []);

  useEffect(() => {
    // Save column visibility state to localStorage
    localStorage.setItem("Dropname", JSON.stringify(showNameColumn));
    localStorage.setItem("Dropemail", JSON.stringify(showEmailColumn));
    localStorage.setItem("Dropemail", JSON.stringify(showPrefferedColumn));
    localStorage.setItem("Dropmobile", JSON.stringify(showPhoneColumn));
    localStorage.setItem("DropDeferalreason", JSON.stringify(showReasonColumn));

    localStorage.setItem(
      "Dropwhatappnumber",
      JSON.stringify(showWhatsAppNumColumn)
    );
    localStorage.setItem(
      "DropprefferedCountry",
      JSON.stringify(showPrefferedColumn)
    );
    localStorage.setItem(
      "DropleadSource",
      JSON.stringify(showLeadSourceColumn)
    );
    localStorage.setItem("DropleadOwner", JSON.stringify(showLeadOwnerColumn));
    localStorage.setItem(
      "Dropleadbranch",
      JSON.stringify(showLeadBranchColumn)
    );
    localStorage.setItem("Dropstatus", JSON.stringify(showStatusColumn));
    localStorage.setItem("Droppriority", JSON.stringify(showPriorityColumn));
    localStorage.setItem("Dropplace", JSON.stringify(showLeadPlaceColumn));
    localStorage.setItem(
      "Droptelecaller",
      JSON.stringify(showTelecallerColumn)
    );
    localStorage.setItem("Dropcounsilor", JSON.stringify(showCounsilorColumn));
  }, [
    showNameColumn,
    showEmailColumn,
    showPhoneColumn,
    showWhatsAppNumColumn,
    showPrefferedColumn,
    showLeadSourceColumn,
    showLeadOwnerColumn,
    showLeadBranchColumn,
    showStatusColumn,
    showPriorityColumn,
    showLeadPlaceColumn,
    showTelecallerColumn,
    showReasonColumn,
    showCounsilorColumn,
    columnName,
  ]);

  const LeadFilterFunction = (data, filterOption, checkedStatus) => {
    if (filterOption === "city") {
      console.log(data, "dataaaaaaaaaaaaaaaa");
      setLeadData(
        leadData?.filter((item) => {
          console.log(item, "itemitemitemitemitem");
          return (
            item.location !== null &&
            item.location.toLowerCase().includes(data.toLowerCase())
          );
        })
      );

      setwhichFilter(filterOption);
      setFilterPassData(data);
    }
    if (filterOption === "preffered country") {
      if (checkedStatus === true) {
        setLeadData(
          leadData?.filter((item) => {
            return (
              item.preferredCountryId !== null &&
              item.preferredCountryId.toLowerCase().includes(data.toLowerCase())
              // data.includes(item.leadSource)
            );
          })
        );
        setwhichFilter(filterOption);
        setFilterPassData(data);
      } else {
        setwhichFilter(null);
        setFilterPassData(null);
        setLeadData(leadFindSuccess.filter((item) => item.status === "dead"));
      }
    }

    if (filterOption === "Role Name") {
      if (checkedStatus === true) {
        setLeadData(
          leadData?.filter((item) => {
            return (
              item.name !== null &&
              item.name.toLowerCase().includes(data.toLowerCase())
            );
          })
        );
        setwhichFilter(filterOption);
        setFilterPassData(data);
      } else {
        setwhichFilter(null);
        setFilterPassData(null);
        setLeadData(leadFindSuccess.filter((item) => item.status === "dead"));
      }
    }

    if (filterOption === "leadStatus") {
      if (checkedStatus == true) {
        setLeadData(
          leadData?.filter((item) => {
            return (
              item.status !== null && item.status.toLowerCase().includes(data)
              // data.includes(item.status)
            );
          })
        );
        setwhichFilter(filterOption);
        setFilterPassData(checkedStatus);
      } else {
        setwhichFilter(null);
        setFilterPassData(null);
        setLeadData(leadFindSuccess.filter((item) => item.status === "dead"));
      }
    }

    if (filterOption === "leadSource") {
      if (checkedStatus === true) {
        setLeadData(
          leadData?.filter((item) => {
            return (
              item.leadSource !== null &&
              item.leadSource.toLowerCase().includes(data.toLowerCase())
            );
          })
        );
        setwhichFilter(filterOption);
        setFilterPassData(data);
      } else {
        setwhichFilter(null);
        setFilterPassData(null);
        setLeadData(leadFindSuccess.filter((item) => item.status === "dead"));
      }
    }

    if (filterOption === "leadBranch") {
      if (checkedStatus === true) {
        console.log(data, "evalueevalueevalue");
        setLeadData(
          leadData?.filter((item) => {
            return (
              item?.branch_master?.branchName !== null &&
              item.branch_master?.branchName
                .toLowerCase()
                .includes(data.toLowerCase())
            );
          })
        );
        setwhichFilter(filterOption);
        setFilterPassData(data);
      } else {
        setwhichFilter(null);
        setFilterPassData(null);
        setLeadData(leadFindSuccess.filter((item) => item.status === "dead"));
      }
    }
  };

  const handleResetData = (status) => {
    setwhichFilter(null);
    setFilterPassData(null);
    setReset(!reset);
    setwhichFilter(null);
    setFilterPassData(null);
    setPaginationPageNumber(1);
  };

  const handlePaginationEntry = (e) => {
    let value = e.target.value;
    if (value <= 100) {
      setPagiantionEntry(value);
    }
  };

  const setIdsFunction = (leadsId) => {
    setLeadId(leadsId);
  };
  const handleDeleteClick = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteLeadAction(leadId));
      }
    });
  };

  const selectBoxIdPassHandler = (ids) => {
    setIds(ids);
  };

  if (!hasPrivilege("Drop Leads") && privilege !== null) {
    return (
      <Alert severity="error">
        <AlertTitle>warning</AlertTitle>
        You don't have permission to access — <strong>this page.!</strong>
      </Alert>
    );
  }
  return (
    <>
      <LeadersSearchHeader
        columnVisibleHandler={toggleNameColumn}
        excelDownloadClick={excelDownloadClick}
        showNameColumn={showNameColumn}
        showEmailColumn={showEmailColumn}
        showPrefferedColumn={showPrefferedColumn}
        showPhoneColumn={showPhoneColumn}
        showWhatAppNumberColumn={showWhatsAppNumColumn}
        showLeadSourceColumn={showLeadSourceColumn}
        showLeadOwnerColumn={showLeadOwnerColumn}
        showLeadBranchColumn={showLeadBranchColumn}
        showStatusColumn={showStatusColumn}
        showReasonColumn={showReasonColumn}
        showPriorityColumn={showPriorityColumn}
        showLeadPlaceColumn={showLeadPlaceColumn}
        showTelecallerColumn={showTelecallerColumn}
        showCounsilorColumn={showCounsilorColumn}
        filterPassData={filterPassData ? filterPassData : null}
        whichFilter={whichFilter ? whichFilter : null}
        handleResetData={handleResetData}
        handleDeleteClick={handleDeleteClick}
        searchHandleChange={searchHandleChange}
        ids={ids}
        leadId={leadId}
        component="dead"
        subMenu="Drop Leads"
      />
      {/* <div style={{display:"flex",justifyContent:"space-evenly", width:"auto"}}> */}
      <Grid container spacing={-3} sx={{ mt: "-31px" }}>
        <Grid item xs={12} md={2} lg={2.2}>
          <LeadsFilterSearch
            LeadFilterFunction={LeadFilterFunction}
            handleResetData={handleResetData}
            statusComponent="lead"
            data={leadFindSuccess}
            pagiantionEntry={pagiantionEntry}
            handlePaginationEntry={handlePaginationEntry}
          />
        </Grid>
        <Grid item xs={12} md={10} lg={9.8}>
          <LeadsTable
            toggle={props.toggle}
            showNameColumn={showNameColumn}
            showEmailColumn={showEmailColumn}
            showPrefferedColumn={showPrefferedColumn}
            showPhoneColumn={showPhoneColumn}
            showWhatAppNumberColumn={showWhatsAppNumColumn}
            showLeadSourceColumn={showLeadSourceColumn}
            showLeadOwnerColumn={showLeadOwnerColumn}
            showLeadBranchColumn={showLeadBranchColumn}
            showStatusColumn={showStatusColumn}
            showPriorityColumn={showPriorityColumn}
            showReasonColumn={showReasonColumn}
            showLeadPlaceColumn={showLeadPlaceColumn}
            showTelecallerColumn={showTelecallerColumn}
            showCounsilorColumn={showCounsilorColumn}
            reset={reset}
            setIdsFunction={setIdsFunction}
            data={data}
            columns={columns}
            leadFindLoading={leadFindLoading}
            selectBoxIdPassHandler={selectBoxIdPassHandler}
            isExistCounsilor={loginFindSuccess}
            component="leadDrop"
            pagiantionEntry={pagiantionEntry}
            paginationPageNumber={paginationPageNumber}
            subMenu="Drop Leads"
            mainMenu="drop"
          />
        </Grid>
      </Grid>
    </>
  );
}

export default LeadsDrop;
