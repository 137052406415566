import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ieltsTeacherDashBoardaAssignedStudentsFindByIdaction } from "../../../actions/IeltsActions/teacherDashboardAction";
import { useParams } from "react-router-dom";
import { useState } from "react";

function IeltsTeacherDashboardSingleView({ toggle }) {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [studentsDetails, setStudentsDetails] = useState();

  let {
    ieltsTeacherDashBoardAssignedStudentsFindSuccess,
    ieltsTeacherDashBoardAssignedStudentsFinderror,
  } = useSelector((state) => {
    return state.ieltsTeacherDashboardAssignedStudentsFindByid;
  });

  useEffect(() => {
    dispatch(ieltsTeacherDashBoardaAssignedStudentsFindByIdaction(id));
  }, [dispatch]);

  useEffect(() => {
    if (ieltsTeacherDashBoardAssignedStudentsFindSuccess) {
      setStudentsDetails(ieltsTeacherDashBoardAssignedStudentsFindSuccess.data);
    }
  }, [ieltsTeacherDashBoardAssignedStudentsFindSuccess]);

  console.log(
    ieltsTeacherDashBoardAssignedStudentsFindSuccess,
    "ieltsTeacherDashBoardAssignedStudentsFindSuccess"
  );

  return (
    <Box
      sx={{
        display: "flex",
        border: "1px solid #ECECEC",
        gap: 10,
        pt: 2,
        pb: 2,
      }}
    >
      <Box
        sx={{
          p: 3,
          display: "flex",
          justifyContent: "space-between",
          gap: "50px",
          borderRight: "1px solid #ECECEC",
        }}
      >
        <Box sx={{ display: "flex", gap: "20px", flexDirection: "column" }}>
          <Box>
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: "14px",
                fontFamily: "'Poppins', sans-serif",
              }}
            >
              Name
            </Typography>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: "12px",
                fontFamily: "'Poppins', sans-serif",
                color: "#ACB1C6",
              }}
            >
              {studentsDetails?.ielts_entrolled_student?.fullName}
            </Typography>
          </Box>
          <Box>
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: "14px",
                fontFamily: "'Poppins', sans-serif",
              }}
            >
              DOB
            </Typography>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: "12px",
                fontFamily: "'Poppins', sans-serif",
                color: "#ACB1C6",
              }}
            >
              {studentsDetails?.ielts_entrolled_student?.dob}
            </Typography>
          </Box>
          <Box>
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: "14px",
                fontFamily: "'Poppins', sans-serif",
              }}
            >
              Email
            </Typography>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: "12px",
                fontFamily: "'Poppins', sans-serif",
                color: "#ACB1C6",
                // maxWidth:"150px",
                // overflow:"scroll"
              }}
            >
              {studentsDetails?.ielts_entrolled_student?.email}
            </Typography>
          </Box>
        </Box>

        <Box sx={{ display: "flex", gap: "20px", flexDirection: "column" }}>
          <Box>
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: "14px",
                fontFamily: "'Poppins', sans-serif",
              }}
            >
              Gender
            </Typography>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: "12px",
                fontFamily: "'Poppins', sans-serif",
                color: "#ACB1C6",
                // maxWidth:"150px",
                // overflow:"scroll"
              }}
            >
              {studentsDetails?.ielts_entrolled_student?.gender}
            </Typography>
          </Box>
          <Box>
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: "14px",
                fontFamily: "'Poppins', sans-serif",
              }}
            >
              Phone Number
            </Typography>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: "12px",
                fontFamily: "'Poppins', sans-serif",
                color: "#ACB1C6",
              }}
            >
              {studentsDetails?.ielts_entrolled_student
                ?.phoneNumberCountryCode +
                studentsDetails?.ielts_entrolled_student?.phoneNumber}
            </Typography>
          </Box>
          <Box>
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: "14px",
                fontFamily: "'Poppins', sans-serif",
              }}
            >
              Whatsapp Number
            </Typography>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: "12px",
                fontFamily: "'Poppins', sans-serif",
                color: "#ACB1C6",
              }}
            >
              {studentsDetails?.ielts_entrolled_student
                ?.whatsAppNumberCountryCode +
                studentsDetails?.ielts_entrolled_student?.whatsAppNumber}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          gap: "50px",
        }}
      >
        <Box sx={{ display: "flex", gap: "20px", flexDirection: "column" }}>
          <Box>
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: "14px",
                fontFamily: "'Poppins', sans-serif",
              }}
            >
              Course
            </Typography>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: "12px",
                fontFamily: "'Poppins', sans-serif",
                color: "#ACB1C6",
              }}
            >
              {studentsDetails?.ielts_course?.CourseName}
            </Typography>
          </Box>
          <Box>
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: "14px",
                fontFamily: "'Poppins', sans-serif",
              }}
            >
              Course Start Date
            </Typography>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: "12px",
                fontFamily: "'Poppins', sans-serif",
                color: "#ACB1C6",
              }}
            >
              {studentsDetails?.ielts_course?.createdAt}
            </Typography>
          </Box>
          <Box>
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: "14px",
                fontFamily: "'Poppins', sans-serif",
              }}
            >
              Course Expiry Date
            </Typography>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: "12px",
                fontFamily: "'Poppins', sans-serif",
                color: "#ACB1C6",
              }}
            >
              null
            </Typography>
          </Box>
        </Box>

        <Box sx={{ display: "flex", gap: "20px", flexDirection: "column" }}>
          <Box>
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: "14px",
                fontFamily: "'Poppins', sans-serif",
              }}
            >
              Student Status
            </Typography>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: "12px",
                fontFamily: "'Poppins', sans-serif",
                color: "#FF1010",
              }}
            >
              {studentsDetails?.ielts_entrolled_student?.status}
            </Typography>
          </Box>
          <Box>
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: "14px",
                fontFamily: "'Poppins', sans-serif",
              }}
            >
              Payment Status
            </Typography>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: "12px",
                fontFamily: "'Poppins', sans-serif",
                color: "#FF1010",
              }}
            >
              {studentsDetails?.payment}
            </Typography>
          </Box>
          {/* <Box>
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: "14px",
                fontFamily: "'Poppins', sans-serif",
              }}
            >
              Entry Created On
            </Typography>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: "12px",
                fontFamily: "'Poppins', sans-serif",
                color: "#ACB1C6",
              }}
            >
              19/08/2023
            </Typography>
          </Box> */}
        </Box>

        {/* <Box sx={{ display: "flex", gap: "20px", flexDirection: "column" }}>
          <Box>
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: "14px",
                fontFamily: "'Poppins', sans-serif",
              }}
            >
              Entry Created By
            </Typography>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: "12px",
                fontFamily: "'Poppins', sans-serif",
                color: "#ACB1C6",
              }}
            >
              Aravind
            </Typography>
          </Box>
          <Box>
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: "14px",
                fontFamily: "'Poppins', sans-serif",
              }}
            >
              Entry Updated On
            </Typography>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: "12px",
                fontFamily: "'Poppins', sans-serif",
                color: "#ACB1C6",
              }}
            >
              19/08/2023
            </Typography>
          </Box>
          <Box>
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: "14px",
                fontFamily: "'Poppins', sans-serif",
              }}
            >
              Entry Updated By
            </Typography>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: "12px",
                fontFamily: "'Poppins', sans-serif",
                color: "#ACB1C6",
              }}
            >
              Aravind
            </Typography>
          </Box>
        </Box> */}

        <Box sx={{ display: "flex", gap: "20px", flexDirection: "column" }}>
          <Box>
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: "14px",
                fontFamily: "'Poppins', sans-serif",
              }}
            >
              Center
            </Typography>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: "12px",
                fontFamily: "'Poppins', sans-serif",
                color: "#ACB1C6",
              }}
            >
              {studentsDetails?.ielts_center_slot?.ielts_center?.CenterName}
            </Typography>
          </Box>
          <Box>
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: "14px",
                fontFamily: "'Poppins', sans-serif",
              }}
            >
              Slots
            </Typography>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: "12px",
                fontFamily: "'Poppins', sans-serif",
                color: "#ACB1C6",
              }}
            >
              {studentsDetails?.ielts_center_slot?.timeFrom +
                "-" +
                studentsDetails?.ielts_center_slot?.timeTo}
            </Typography>
            {/* <Typography
              sx={{
                fontWeight: 500,
                fontSize: "12px",
                fontFamily: "'Poppins', sans-serif",
                color: "#ACB1C6",
              }}
            >
              Mon : 9 am - 10 am
            </Typography>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: "12px",
                fontFamily: "'Poppins', sans-serif",
                color: "#ACB1C6",
              }}
            >
              Mon : 9 am - 10 am
            </Typography> */}
          </Box>
        </Box>

        <Box sx={{ display: "flex", gap: "20px", flexDirection: "column" }}>
          <Box>
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: "14px",
                fontFamily: "'Poppins', sans-serif",
              }}
            >
              Teacher
            </Typography>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: "12px",
                fontFamily: "'Poppins', sans-serif",
                color: "#ACB1C6",
              }}
            >
              {studentsDetails?.ielts_teacher?.teacherName}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default IeltsTeacherDashboardSingleView;
