import axios from "axios";
import {
  LOCATION_CREATE_ERR,
  LOCATION_CREATE_REQUEST,
  LOCATION_CREATE_SUCCESS,
  LOCATION_DELETE_ERR,
  LOCATION_DELETE_REQUEST,
  LOCATION_DELETE_SUCCESS,
  LOCATION_FIND_ERR,
  LOCATION_FIND_REQUEST,
  LOCATION_FIND_SUCCESS,
  LOCATION_FINDONE_ERR,
  LOCATION_FINDONE_REQUEST,
  LOCATION_FINDONE_SUCCESS,
  LOCATION_UPDATE_ERR,
  LOCATION_UPDATE_REQUEST,
  LOCATION_UPDATE_SUCCESS
} from "../constants/locationConstants";
import { SUCCESS_TRUE_MSG_ERR } from "../constants/roleManagmentConstant";

// countryMasterAddAction

export const locationCreateAction =
  (country, location) => async (dispatch, getState) => {
    try {
      console.log(country, location, "kkkkkkkkkkkkkkkkkk");
      dispatch({ type: LOCATION_CREATE_REQUEST });

      let isUserExist = localStorage.getItem("loginInfo")
        ? JSON.parse(localStorage.getItem("loginInfo"))
        : null;

      const config = {
        headers: {
          Authorization: `Bearer ${isUserExist?.token}`
        }
      };

      console.log(config, "configconfigconfig");

      let taskOwner = isUserExist.name;

      let { data } = await axios.post(
        "/api/admin/location/addLocationMaster",
        { country, location, taskOwner },
        config
      );

      dispatch({ type: LOCATION_CREATE_SUCCESS, payload: data });
    } catch (error) {
      dispatch({ type: LOCATION_CREATE_ERR, payload: error.response.data });
      dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: true });
    }
  };

// countryMasterFindAction

export const locationFindAction = () => async (dispatch, getState) => {
  try {
    dispatch({ type: LOCATION_FIND_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`
      }
    };

    let taskOwner = isUserExist.name;

    let { data } = await axios.get(
      "/api/admin/location/findLocationMaster",
      config
    );

    dispatch({ type: LOCATION_FIND_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: LOCATION_FIND_ERR, payload: error.response.data });
    dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: true });
  }
};

// countryMasterFindOneAction

export const locationFindOneAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: LOCATION_FINDONE_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`
      }
    };

    let taskOwner = isUserExist.name;

    let { data } = await axios.get(
      `/api/admin/location/findOneLocationMaster?id=${id}`,
      config
    );

    dispatch({ type: LOCATION_FINDONE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: LOCATION_FINDONE_ERR, payload: error.response.data });
    dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: true });
  }
};

// countryMasterUpdateAction

export const locationUpdateAction =
  (country, location, id) => async (dispatch, getState) => {
    try {
      dispatch({ type: LOCATION_UPDATE_REQUEST });

      let isUserExist = localStorage.getItem("loginInfo")
        ? JSON.parse(localStorage.getItem("loginInfo"))
        : null;

      const config = {
        headers: {
          Authorization: `Bearer ${isUserExist?.token}`
        }
      };

      let taskOwner = isUserExist.name;

      let { data } = await axios.put(
        "/api/admin/location/updateLocationMaster",
       { country,
        location,
        id},
        config
      );

      dispatch({ type: LOCATION_UPDATE_SUCCESS, payload: data });
    } catch (error) {
      dispatch({ type: LOCATION_UPDATE_ERR, payload: error.response.data });
      dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: true });
    }
  };

// locationMasterDeleteAction

export const locationDeleteAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: LOCATION_DELETE_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`
      }
    };

    let taskOwner = isUserExist.name;

    let { data } = await axios.delete(
      `/api/admin/location/deleteLocationMaster?id=${id}`,
      config
    );

    dispatch({ type: LOCATION_DELETE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: LOCATION_DELETE_ERR, payload: error.response.data });
    dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: true });
  }
};
