import React, { useEffect, useState } from "react";
import {
  Typography,
  Grid,
  TextField,
  FormControl,
  MenuItem,
} from "@mui/material";
import { Box, InputLabel, makeStyles, Select } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteAcademicDetailsAction,
  leadEducationDetailsAddAction,
  leadEducationDetailsShowAction,
} from "../../../actions/leadActions/leadActions";
import { useParams } from "react-router-dom";
import { TypographyText1 } from "../../customComponent/Typography";
import { HiOutlinePlus } from "react-icons/hi";
import { SubmitButton } from "../../customComponent/Buttons";
import {
  SelectInputField,
  TopInputFieldBorderLabel,
} from "../../customComponent/InputField";
import { countries } from "countries-list";
import Swal from "sweetalert2";
import usePrivilegeCheck from "../../../constants/customHooks/UsePrivilegeCheck";

const useStyles = makeStyles((theme) => ({
  input: {
    height: "1px",
    border: "0.5px solid #ECECEC",
  },
  formLabel: {
    lineHeight: "13px",
  },
  outlinedInput: {
    // borderRadius: "13px",
    borderColor: "#ECECEC",
    border: "0.5px solid #ECECEC",
  },
}));
const EducationDetails = ({ handleNext, handleCompleteTick }) => {
  const {
    privilege,
    hasPrivilege,
    isApprovePrevilage,
    isCreatePrevilage,
    isDeletePrevilage,
    isUpdatePrevilage,
    isVerifyPrevilage,
    isChangeOwnerPrevilage,
    isChangePriorityPrevilage,
    isChangeStatusPrevilage,
    isAssignCounsilorPrevilage,
  } = usePrivilegeCheck();
  const dispatch = useDispatch();
  const { id } = useParams();
  const classes = useStyles();

  // AllCountry

  let allCountries = Object.entries(countries).map(([code, country]) => ({
    value: code,
    label: `${country.name}`,
    phone: `+${country.phone}`,
  }));

  let {
    leadEducationDetailFindLoading,
    leadEducationDetailFindSuccess,
    leadEducationDetailFinderror,
  } = useSelector((state) => {
    return state.leadEducationDetailsShow;
  });

  let {
    leadEducationDetailDeleteLoading,
    leadEducationDetailDeleteSuccess,
    leadEducationDetailDeleteerror,
  } = useSelector((state) => {
    return state.deleteAcademicDetails;
  });

  let {
    leadEducationEnglishTestDetailDeleteLoading,
    leadEducationEnglishTestDetailDeleteSuccess,
    leadEducationEnglishTestDetailDeleteerror,
  } = useSelector((state) => {
    return state.deleteEnglishTest;
  });

  const [highSchoolCompletionYear, setHighSchoolCompletionYear] = useState("");
  const [highSchoolBoard, setHighSchoolBoard] = useState("");
  const [highSchoolScore, setHighSchoolScore] = useState("");
  const [error, setError] = useState({});

  const [
    higherSecondaryCompletionYear,
    setHigherSecondaryCompletionYear,
  ] = useState("");
  const [higherSecondarySteam, setHigherSecondarySteam] = useState("");
  const [higherSecondaryBoard, setHigherSecondaryBoard] = useState("");
  const [higherSecondaryScore, setHigherSecondaryScore] = useState("");
  const [
    higherSecondaryQualification,
    setHigherSecondaryQualification,
  ] = useState("");

  const [course, setCourse] = useState("");
  const [institute, setInstitute] = useState("");
  const [country, setCountry] = useState("");
  const [levelOfStudy, setLevelOfStudy] = useState("");
  const [academicDetails, setAcademicDetails] = useState([]);

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [resultInfo, setResultInfo] = useState("");

  const [englishTestDetails, setEnglishTestDetails] = useState([]);
  const [englishTest, setEnglishTest] = useState("");
  const [englishTestDate, setEnglishTestDate] = useState("");
  const [englishTestScore, setEnglishTestScore] = useState("");
  const [highSecField, setHighSecField] = useState();

  useEffect(() => {
    dispatch(leadEducationDetailsShowAction(id));
  }, [
    dispatch,
    id,
    leadEducationDetailDeleteSuccess,
    leadEducationEnglishTestDetailDeleteSuccess,
  ]);

  useEffect(() => {
    if (leadEducationDetailFindSuccess) {
      console.log(
        leadEducationDetailFindSuccess,
        "leadEducationDetailFindSuccessleadEducationDetailFindSuccessleadEducationDetailFindSuccessleadEducationDetailFindSuccessleadEducationDetailFindSuccess"
      );

      setEnglishTestDetails(
        leadEducationDetailFindSuccess.educationEnglishTest !== null
          ? leadEducationDetailFindSuccess.educationEnglishTest
          : []
      );

      setAcademicDetails(
        leadEducationDetailFindSuccess?.educationAcademic !== null
          ? leadEducationDetailFindSuccess?.educationAcademic
          : []
      );
      if (leadEducationDetailFindSuccess?.aLeadEducationDeatails) {
        setHighSchoolCompletionYear(
          leadEducationDetailFindSuccess?.aLeadEducationDeatails
            ?.highSchoolCompletionYear
        );
        console.log(
          leadEducationDetailFindSuccess?.aLeadEducationDeatails
            ?.highSchoolCompletionYear,
          "5446665897777777777777777777777723313211"
        );
        setHighSchoolBoard(
          leadEducationDetailFindSuccess.aLeadEducationDeatails.highSchoolBoard
        );
        setHighSchoolScore(
          leadEducationDetailFindSuccess.aLeadEducationDeatails.highSchoolScore
        );
        setHigherSecondaryCompletionYear(
          leadEducationDetailFindSuccess.aLeadEducationDeatails
            .higherSecondaryCompletionYear
        );
        setHigherSecondarySteam(
          leadEducationDetailFindSuccess.aLeadEducationDeatails
            .higherSecondarySteam
        );
        setHigherSecondaryBoard(
          leadEducationDetailFindSuccess.aLeadEducationDeatails
            .higherSecondaryBoard
        );
        setHigherSecondaryScore(
          leadEducationDetailFindSuccess.aLeadEducationDeatails
            .higherSecondaryScore
        );
        setHighSecField(
          leadEducationDetailFindSuccess?.aLeadEducationDeatails
            ?.higherSecondarySteam
            ? "plusTwo"
            : "other"
        );
      }
      if (leadEducationDetailFindSuccess?.highSecondaryOtherQualification) {
        setHigherSecondaryQualification(
          leadEducationDetailFindSuccess?.highSecondaryOtherQualification
            ?.qualificationName
        );
      }
    }
  }, [leadEducationDetailFindSuccess]);

  useEffect(() => {
    if (leadEducationDetailFindSuccess) {
      handleCompleteTick();
    }
  }, [leadEducationDetailFindSuccess, handleCompleteTick]);

  const handleAcademinHistoryAdd = () => {
    console.log(
      {
        course: course,
        institute: institute,

        country: country,
        levelOfStudy: levelOfStudy,
        startDate: startDate,
        endDate: endDate,
        resultInfo: resultInfo,
      },
      "***********"
    );
    if (
      course !== "" &&
      institute !== "" &&
      country !== "" &&
      levelOfStudy !== "" &&
      startDate !== "" &&
      endDate !== "" &&
      resultInfo !== ""
    ) {
      const intakeExists = academicDetails.some(
        (data) => data.course === course
      );

      if (intakeExists) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "this course alredy you added!",
        });
      } else {
        setAcademicDetails([
          ...academicDetails,
          {
            course: course,
            institute: institute,
            country: country,
            levelOfStudy: levelOfStudy,
            startDate: startDate,
            endDate: endDate,
            resultInfo: resultInfo,
          },
        ]);

        setCourse("");
        setInstitute("");
        setCountry("");
        setLevelOfStudy("");
        setStartDate("");
        setEndDate("");
        setResultInfo("");
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please fill in all fields!",
      });
    }
  };
  // delete academic history
  const handleAcademicDeleteClick = (course, id) => {
    if (id) {
      dispatch(deleteAcademicDetailsAction(id));
    }
    const updatedTestData = academicDetails?.filter(
      (data) => data.course !== course
    );
    setAcademicDetails(updatedTestData);
  };
  const handleEnglishTestAdd = () => {
    if (
      englishTest !== "" &&
      englishTestDate !== "" &&
      englishTestScore !== ""
    ) {
      const intakeExists = englishTestDetails?.some(
        (data) => data.test === englishTest
      );

      if (intakeExists) {
        setError((prev) => ({
          ...prev,
          ["education"]: "this test alredy you added",
        }));
      } else {
        setEnglishTestDetails([
          ...englishTestDetails,
          {
            test: englishTest,
            date: englishTestDate,
            score: englishTestScore,
          },
        ]);

        setEnglishTest("");
        setEnglishTestDate("");
        setEnglishTestScore("");
        setError((prev) => ({
          ...prev,
          ["education"]: "",
        }));
      }
    } else {
      setError((prev) => ({
        ...prev,
        ["education"]: "Please enter values in fields",
      }));
    }
  };

  const handleTestDeleteClick = (test, id) => {
    if (id) {
      // dispatch(deleteEnglishTestAction(id));
    }
    const updatedTestData = englishTestDetails?.filter(
      (data) => data.test !== test
    );
    setEnglishTestDetails(updatedTestData);
  };
  const handleFormSubmit = (e) => {
    e.preventDefault();
    // Perform form submission or other actions
    console.log("Form submitted");
    dispatch(
      leadEducationDetailsAddAction(
        higherSecondaryQualification,
        highSecField,
        highSchoolCompletionYear,
        highSchoolBoard,
        highSchoolScore,
        higherSecondaryCompletionYear,
        higherSecondarySteam,
        higherSecondaryBoard,
        higherSecondaryScore,
        academicDetails,
        englishTestDetails,
        id
      )
    );
    handleNext();
  };

  return (
    <form onSubmit={handleFormSubmit}>
      {/* High School Section */}

      <TypographyText1 title="High School" variant="h6" />

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          backgroundColor: "#efedf0",
          mt: 2,
        }}
      >
        <Grid container spacing={2} sx={{ m: 1.5, mb: 2.5 }}>
          <Grid item xs={12} md={6} lg={3}>
            <TopInputFieldBorderLabel
              label="Completion Year"
              handleChange={(e) => setHighSchoolCompletionYear(e.target.value)}
              widthSize="fullWidth"
              heightSize="45px"
              value={highSchoolCompletionYear}
              required
              paramses
              type="date"
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <TopInputFieldBorderLabel
              label="Board"
              handleChange={(e) => setHighSchoolBoard(e.target.value)}
              widthSize="fullWidth"
              heightSize="42px"
              value={highSchoolBoard}
              required
              paramses
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            {/* <TextField
              label="Score"
              value={highSchoolScore}
              onChange={(e) => setHighSchoolScore(e.target.value)}
              fullWidth
              required
              sx={{ backgroundColor: "white" }}
              InputProps={{
                classes: {
                  input: classes.input,
                },
              }}
              InputLabelProps={{
                classes: {
                  root: classes.formLabel,
                },
              }}
            /> */}
            <Box
              sx={{
                "& .MuiFormHelperText-root.Mui-error": {
                  marginTop: "-12px",
                },
              }}
            >
              <TopInputFieldBorderLabel
                label="Score"
                handleChange={(e) => {
                  const value = e.target.value;
                  // Allow numbers with optional decimal points
                  if (/^\d*\.?\d*$/.test(value) || value === "") {
                    setHighSchoolScore(value);
                    setError((prev) => ({
                      ...prev,
                      ["schoolScore"]: "",
                    }));
                  } else {
                    setError((prev) => ({
                      ...prev,
                      ["schoolScore"]: "Please enter a valid number",
                    }));
                  }
                }}
                widthSize="fullWidth"
                heightSize="42px"
                value={highSchoolScore}
                error={Boolean(error.schoolScore)}
                helperText={error.schoolScore}
                required
                paramses
              />
            </Box>
          </Grid>
        </Grid>
      </Box>

      {/* Higher Secondary Section */}
      <Box
        sx={{
          mt: 5,
        }}
      >
        <TypographyText1 title="Higher Secondary" variant="h6" />

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            backgroundColor: "#efedf0",
            mt: 2,
            pb: "10px",
          }}
        >
          <Grid container spacing={2} style={{ margin: "15px" }}>
            {leadEducationDetailFindSuccess?.aLeadEducationDeatails
              ?.higherSecondaryBoard == null &&
              leadEducationDetailFindSuccess?.highSecondaryOtherQualification ==
                null && (
                <Grid item xs={12} md={12} lg={12}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      choose a qualification
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      // value={age}
                      label="qualification"
                      onChange={(e) => setHighSecField(e.target.value)}
                    >
                      <MenuItem value="other">Other</MenuItem>
                      <MenuItem value="plusTwo">Plus Two</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              )}

            {highSecField === "plusTwo" ? (
              <>
                {" "}
                <Grid item xs={12} md={6} lg={3}>
                  {/* <TextField
                label="Completion Year"
                value={higherSecondaryCompletionYear}
                onChange={(e) =>
                  setHigherSecondaryCompletionYear(e.target.value)
                }
                fullWidth
                required
                sx={{ backgroundColor: "white" }}
                InputProps={{
                  classes: {
                    input: classes.input,
                  },
                }}
                InputLabelProps={{
                  classes: {
                    root: classes.formLabel,
                  },
                }}
              /> */}

                  <TopInputFieldBorderLabel
                    label="Completion Year"
                    handleChange={(e) =>
                      setHigherSecondaryCompletionYear(e.target.value)
                    }
                    widthSize="fullWidth"
                    heightSize="42px"
                    value={higherSecondaryCompletionYear}
                    type="date"
                    required
                    paramses
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <TopInputFieldBorderLabel
                    label="Stream"
                    handleChange={(e) =>
                      setHigherSecondarySteam(e.target.value)
                    }
                    widthSize="fullWidth"
                    heightSize="42px"
                    value={higherSecondarySteam}
                    required
                    paramses
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <TopInputFieldBorderLabel
                    label="Board"
                    handleChange={(e) =>
                      setHigherSecondaryBoard(e.target.value)
                    }
                    widthSize="fullWidth"
                    heightSize="42px"
                    value={higherSecondaryBoard}
                    required
                    paramses
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <Box
                    sx={{
                      "& .MuiFormHelperText-root.Mui-error": {
                        marginTop: "-12px",
                      },
                    }}
                  >
                    <TopInputFieldBorderLabel
                      label="Score"
                      handleChange={(e) => {
                        if (
                          /^\d*\.?\d*$/.test(e.target.value) ||
                          e.target.value === ""
                        ) {
                          setHigherSecondaryScore(e.target.value);
                          setError((prev) => ({
                            ...prev,
                            ["secondaryScore"]: "",
                          }));
                        } else {
                          setError((prev) => ({
                            ...prev,
                            ["secondaryScore"]: "Please enter a number",
                          }));
                        }
                      }}
                      widthSize="fullWidth"
                      heightSize="42px"
                      value={higherSecondaryScore}
                      error={Boolean(error.secondaryScore)}
                      helperText={error.secondaryScore}
                      required
                      paramses
                    />
                  </Box>
                </Grid>{" "}
              </>
            ) : (
              <>
                {" "}
                <Grid item xs={12} md={6} lg={3}>
                  <TopInputFieldBorderLabel
                    label="Qualification Name"
                    handleChange={(e) =>
                      setHigherSecondaryQualification(e.target.value)
                    }
                    widthSize="fullWidth"
                    heightSize="42px"
                    value={higherSecondaryQualification}
                    required
                    paramses
                  />
                </Grid>
              </>
            )}
          </Grid>
        </Box>
      </Box>
      {/* Academic History Section */}
      <Box
        sx={{
          mt: 5,
        }}
      >
        <TypographyText1 title="Academic History" variant="h6" />

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            backgroundColor: "#efedf0",
            mt: 2,
          }}
        >
          <Grid container spacing={2} sx={{ m: 1 }}>
            <Grid item xs={12} md={6} lg={3}>
              <TopInputFieldBorderLabel
                label="Course"
                handleChange={(e) => setCourse(e.target.value)}
                widthSize="fullWidth"
                heightSize="42px"
                value={course}
                requiredSymbol
                paramses
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <TopInputFieldBorderLabel
                label="Institute"
                handleChange={(e) => setInstitute(e.target.value)}
                widthSize="fullWidth"
                heightSize="42px"
                value={institute}
                requiredSymbol
                paramses
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              {/* <TopInputFieldBorderLabel
                label="Country"
                handleChange={(e) => setCountry(e.target.value)}
                widthSize="fullWidth"
                heightSize="42px"
                value={country}
                required
                paramses
              /> */}

              <SelectInputField
                label="Country"
                handleSelectChange={(e) => setCountry(e.target.value)}
                widthSize="32px"
                value={country}
                mapValue={allCountries}
                mapMethod="nationality"
                name="country"
                height="50px"
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <TopInputFieldBorderLabel
                label="Level of Study"
                handleChange={(e) => setLevelOfStudy(e.target.value)}
                widthSize="fullWidth"
                heightSize="42px"
                value={levelOfStudy}
                requiredSymbol
                paramses
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3}>
              <TopInputFieldBorderLabel
                label="Start Date"
                handleChange={(e) => setStartDate(e.target.value)}
                widthSize="fullWidth"
                heightSize="42px"
                value={startDate}
                requiredSymbol
                paramses
                type="date"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3}>
              <TopInputFieldBorderLabel
                label="End Date"
                handleChange={(e) => setEndDate(e.target.value)}
                widthSize="fullWidth"
                heightSize="42px"
                value={endDate}
                requiredSymbol
                paramses
                type="date"
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <TopInputFieldBorderLabel
                label="Result Info"
                // handleChange={(e) => setResultInfo(e.target.value)}
                handleChange={(e) => {
                  if (
                    /^\d*\.?\d*$/.test(e.target.value) ||
                    e.target.value === ""
                  ) {
                    setResultInfo(e.target.value);
                    setError((prev) => ({
                      ...prev,
                      ["resultInfo"]: "",
                    }));
                  } else {
                    setError((prev) => ({
                      ...prev,
                      ["resultInfo"]: "Please enter a number",
                    }));
                  }
                }}
                widthSize="fullWidth"
                heightSize="42px"
                value={resultInfo}
                error={error.resultInfo}
                helperText={error.resultInfo}
                requiredSymbol
                paramses
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              lg={3}
              sx={{
                // display: "flex",
                // justifyContent: "flex-end",
                textAlign: "center",
              }}
            >
              <HiOutlinePlus
                style={{
                  fontSize: "60px",
                  margin: "10px",
                  cursor: "pointer",
                  width: "auto",
                  height: "40px",
                }}
                onClick={handleAcademinHistoryAdd}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>

      {academicDetails?.length !== 0 && (
        <Typography variant="h6" gutterBottom style={{ marginTop: "10px" }}>
          Academic History Added Result
        </Typography>
      )}
      {academicDetails?.map((data, index) => (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            backgroundColor: "#efedf0",
            marginTop: "30px",
          }}
          key={index}
        >
          <Grid container spacing={2} sx={{ m: 1 }}>
            <Grid item xs={12} md={6} lg={3}>
              <TopInputFieldBorderLabel
                label="Course"
                // handleChange={(e) => setResultInfo(e.target.value)}
                widthSize="fullWidth"
                heightSize="42px"
                value={data.course}
                requiredSymbol
                paramses
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <TopInputFieldBorderLabel
                label="Institute"
                // handleChange={(e) => setResultInfo(e.target.value)}
                widthSize="fullWidth"
                heightSize="42px"
                value={data.institute}
                requiredSymbol
                paramses
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              {/* <TopInputFieldBorderLabel
                label="Country"
                // handleChange={(e) => setResultInfo(e.target.value)}
                widthSize="fullWidth"
                heightSize="42px"
                value={data.country}
                required
                paramses
              /> */}

              <SelectInputField
                label="Country"
                handleSelectChange={(e) => setResultInfo(e.target.value)}
                widthSize="32px"
                value={data.country}
                mapValue={allCountries}
                mapMethod="nationality"
                name="country"
                height="50px"
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <TopInputFieldBorderLabel
                label="Level of Study"
                // handleChange={(e) => setResultInfo(e.target.value)}
                widthSize="fullWidth"
                heightSize="42px"
                value={data.levelOfStudy}
                requiredSymbol
                paramses
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3}>
              <TopInputFieldBorderLabel
                label="Start Date"
                // handleChange={(e) => setResultInfo(e.target.value)}
                widthSize="fullWidth"
                heightSize="42px"
                value={data?.startDate}
                requiredSymbol
                paramses
                type="date"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3}>
              <TopInputFieldBorderLabel
                label="End Date"
                // handleChange={(e) => setEndDate(e.target.value)}
                widthSize="fullWidth"
                heightSize="42px"
                value={data.endDate}
                requiredSymbol
                paramses
                type="date"
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <TopInputFieldBorderLabel
                label="Result Info"
                // handleChange={(e) => setResultInfo(e.target.value)}
                widthSize="fullWidth"
                heightSize="42px"
                value={data.resultInfo}
                requiredSymbol
                paramses
              />
            </Grid>

            <Grid item xs={12} md={6} lg={3}>
              <img
                src="/menuIcons/trash.png"
                alt="gsl"
                style={{
                  marginTop: "19px",
                  cursor: "pointer",
                }}
                onClick={() => handleAcademicDeleteClick(data.course, data.id)}
              />
            </Grid>
          </Grid>
        </Box>
      ))}
      {/* English Language and Standardized Exam Section */}
      <Box sx={{ mt: 5 }}>
        <TypographyText1
          title="English Language and Standardized Exam"
          variant="h6"
        />

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            backgroundColor: "#efedf0",
          }}
        >
          <Grid container spacing={2} sx={{ m: 1 }}>
            <Grid item xs={12} md={6} lg={3}>
              {/* <TopInputFieldBorderLabel
                label="Test Taken"
                handleChange={(e) => setEnglishTest(e.target.value)}
                widthSize="fullWidth"
                heightSize="42px"
                value={englishTest}
                required
                paramses
              /> */}

              <SelectInputField
                label="Test Taken"
                handleSelectChange={(e) => setEnglishTest(e.target.value)}
                widthSize="32px"
                value={englishTest}
                mapValue={[
                  "IELTS",
                  "OET",
                  "TOEFL",
                  "PTE",
                  "GMAT",
                  "GRE",
                  "SAT",
                  "Dulingo",
                ]}
                mapMethod="ielts"
                height="50px"
                requiredSymbol
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3}>
              <TopInputFieldBorderLabel
                label="Date of Test"
                handleChange={(e) => setEnglishTestDate(e.target.value)}
                widthSize="fullWidth"
                heightSize="42px"
                value={englishTestDate}
                requiredSymbol
                paramses
                type="date"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3}>
              <Box
                sx={{
                  "& .MuiFormHelperText-root.Mui-error": {
                    marginTop: "-12px",
                  },
                }}
              >
                <TopInputFieldBorderLabel
                  label="Score"
                  handleChange={(e) => {
                    if (/^\d*\.?\d*$/.test(e.target.value) || e.target.value === "") {
                      setEnglishTestScore(e.target.value);
                      setError((prev) => ({
                        ...prev,
                        ["score"]: "",
                      }));
                    } else {
                      setError((prev) => ({
                        ...prev,
                        ["score"]: "Please enter a number",
                      }));
                    }
                  }}
                  widthSize="fullWidth"
                  heightSize="42px"
                  value={englishTestScore}
                  requiredSymbol
                  paramses
                  error={error.score}
                  helperText={error.score}
                  // type="date"
                />
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              lg={3}
              sx={{
                textAlign: "center",
              }}
            >
              <HiOutlinePlus
                style={{
                  fontSize: "60px",
                  margin: "10px",
                  cursor: "pointer",
                  width: "auto",
                  height: "40px",
                  m: 2,
                }}
                onClick={handleEnglishTestAdd}
              />
            </Grid>
            {Boolean(error.education) && (
              <Typography
                sx={{
                  fontSize: "12px",
                  color: "red",
                  ml: "20px",
                }}
              >
                {error.education}
              </Typography>
            )}
            {englishTestDetails?.map((test, index) => (
              <Grid container spacing={2} key={index} sx={{ m: 0.2 }}>
                <Grid item xs={12} md={6} lg={3}>
                  {/* <TopInputFieldBorderLabel
                    label="Test Taken"
                    widthSize="fullWidth"
                    heightSize="42px"
                    value={test.test}
                    required
                    paramses
                  /> */}

                  <SelectInputField
                    label="Test Taken"
                    widthSize="32px"
                    value={test.test}
                    mapValue={[
                      "IELTS",
                      "OET",
                      "TOEFL",
                      "PTE",
                      "GMAT",
                      "GRE",
                      "SAT",
                      "Dulingo",
                    ]}
                    mapMethod="ielts"
                    height="50px"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={3}>
                  <TopInputFieldBorderLabel
                    label="Date of Test"
                    widthSize="fullWidth"
                    heightSize="42px"
                    value={test.date}
                    required
                    paramses
                    type="date"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={3}>
                  <TopInputFieldBorderLabel
                    label="Score"
                    widthSize="fullWidth"
                    heightSize="42px"
                    value={test.score}
                    required
                    paramses
                    // type="date"
                  />
                </Grid>

                <Grid item xs={12} md={6} lg={3}>
                  <img
                    src="/menuIcons/trash.png"
                    alt="gsl"
                    style={{
                      marginTop: "19px",
                      cursor: "pointer",
                    }}
                    onClick={() => handleTestDeleteClick(test.test, test.id)}
                  />
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>

      {/* Submit Button */}

      {isUpdatePrevilage("All Leads") ? (
        <Grid
          item
          xs={12}
          textAlign="center"
          marginTop="1rem"
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <SubmitButton
            title="Continue to next section"
            submit="submit"
            widthSize="250px"
            heightSize="30px"
            type="submit"
            handleSubmit=""
          />
        </Grid>
      ) : (
        ""
      )}
    </form>
  );
};

export default EducationDetails;
