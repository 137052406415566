import {
  Box,
  Typography,
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  TableSortLabel,
  Menu,
  Pagination,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { BiSortAlt2 } from "react-icons/bi";
import { Navigate, useNavigate } from "react-router-dom";
import { SlOptionsVertical } from "react-icons/sl";
import { AiOutlineEye } from "react-icons/ai";
import Dialogue from "../../../customComponent/Dialogue";
import Swal from "sweetalert2";
import { RiDeleteBin6Line } from "react-icons/ri";
import {
  ieltsSettingsFaqDeleteAction,
  ieltsSettingsFaqStatusChangeAction,
} from "../../../../actions/IeltsActions/SettingsAction";
import { useDispatch } from "react-redux";
import EditIcon from "@mui/icons-material/Edit";
import TableNoItemComponent from "../../../customComponent/TableNoItemComponent";

function IeltsSettingsFaqTable({
  data,
  columns,
  component,
  selectBoxIdPassHandler,
  pageNumber,
}) {
  useEffect(() => {
    if (pageNumber > 10) {
      setRowsPerPage(pageNumber);
    } else {
      setRowsPerPage(10);
    }
  }, [pageNumber]);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [status, setStatus] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  // Sort icon

  const CustomSortIcon = ({ direction }) => {
    return direction === "asc" ? (
      <img
        src="/menuIcons/tableUpSort.png"
        className="customIcon"
        alt="gsl"
        style={{ padding: "15px" }}
      />
    ) : (
      <BiSortAlt2 className="customIcon" />
    );
  };

  // sorting table
  const [sortBy, setSortBy] = useState(""); // Current sort column
  const [sortOrder, setSortOrder] = useState("asc"); // Current sort order

  const requestSort = (column) => {
    let newSortOrder = "asc";

    if (column === sortBy && sortOrder === "asc") {
      newSortOrder = "desc";
    }

    setSortBy(column);
    setSortOrder(newSortOrder);
  };

  let sortedData = useMemo(() => {
    setPage(1)
    if (sortBy && data) {
      const sortedItems = [...data].sort((a, b) => {
        if (sortOrder === "asc") {
          return a[sortBy] < b[sortBy] ? -1 : 1;
        } else {
          return a[sortBy] < b[sortBy] ? 1 : -1;
        }
      });

      return sortedItems;
    }

    return data;
  }, [data, sortBy, sortOrder]);

  // Handle status change
  const handleStatusChange = (status, id) => {
    // console.log(status,id)
    dispatch(ieltsSettingsFaqStatusChangeAction(status, id));
    // setStatus(status);
  };

  const handleSelectAllRows = () => {
    if (selectedRows?.length === sortedData?.length) {
      setSelectedRows([]);
    } else {
      let Ids = sortedData.map((item) => item.id);
      setSelectedRows(Ids);
      selectBoxIdPassHandler(Ids);
    }
  };

  // Create a function to handle selecting individual rows:
  const handleSelectRow = (rowId) => {
    let updatedSelectedRows;
    if (selectedRows.includes(rowId)) {
      updatedSelectedRows = selectedRows.filter((id) => id !== rowId);
      setSelectedRows(selectedRows.filter((id) => id !== rowId));
    } else {
      updatedSelectedRows = [...selectedRows, rowId];
      setSelectedRows(updatedSelectedRows);
    }
    //  setIdsFunction(updatedSelectedRows);
  };

  //  Table actions

  const [leadActionButtonShow, setLeadActionButtonShow] = useState(null);
  const [tableId, setTableId] = useState("");

  const leadOpenActionButton = Boolean(leadActionButtonShow);

  const handleClickAction = (event, id) => {
    setLeadActionButtonShow(event.currentTarget);
    setTableId(id);
  };

  const handleCloseAction = () => {
    setLeadActionButtonShow(null);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const [openDailogue, setOpenDailogue] = useState(false);

  return sortedData?.length === 0 ? (
    <TableNoItemComponent />
  ) : (
    <>
      {openDailogue === true && (
        <Dialogue
          openTrue={openDailogue}
          handleClose={() => setOpenDailogue(false)}
          headerName="Zoom Configuration Details"
          Dailogcomponent="ZoomConfiguration"
          width="1100px"
          maxWidth="100%"
          height={"auto"}
        />
      )}
      <Box
        sx={{
          overflow: "hidden",
        }}
      >
        <Typography
          sx={{
            backgroundColor: "#fff",
            padding: "5px",
            paddingRight: "0px",
            borderRadius: "10px 10px 0 0",
            width: "100%",
            fontSize: "16px",
            fontWeight: "400",
          }}
        >
          Showing{" "}
          <Typography
            component={"span"}
            sx={{
              backgroundColor: "#fff",
              padding: "5px",
              paddingLeft: "0px",
              borderRadius: "10px 10px 0 0",
              width: "100%",
              fontSize: "16px",
              // lineHeight: "24px",
              fontWeight: "700",
            }}
          >
            {
              sortedData?.slice(
                (page - 1) * rowsPerPage,
                (page - 1) * rowsPerPage + rowsPerPage
              )?.length
            }{" "}
            Result
          </Typography>
        </Typography>

        <Box
          sx={{
            overflowX: "scroll",
          }}
        >
          <Table
            className="masterTable"
            sx={{
              minWidth: "800px", 
            }}
          >
            <TableHead
              sx={{
                background: "#F9F9FB",
              }}
            >
              <TableRow className="msterTableCell">
                <TableCell>
                  <Checkbox
                    checked={selectedRows?.length === sortedData?.length}
                    onChange={handleSelectAllRows}
                    style={{ color: "#f81b82" }}
                  />
                </TableCell>
                {columns?.map((column) => {
                  if (column.hidden) {
                    return null;
                  }
                  return (
                    <TableCell key={column.field}>
                      <TableSortLabel
                        className="headerText"
                        active={column === sortBy}
                        direction={sortOrder}
                        onClick={() => requestSort(column)}
                        IconComponent={
                          column.field !== "action" && CustomSortIcon
                        }
                      >
                        {column.title}
                      </TableSortLabel>
                    </TableCell>
                  );
                })}
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedData
                ?.slice(
                  (page - 1) * rowsPerPage,
                  (page - 1) * rowsPerPage + rowsPerPage
                )
                .map((item, indx) => (
                  <TableRow className="tableRow" key={indx}>
                    <TableCell>
                      <Checkbox
                        checked={selectedRows.includes(item?.id)}
                        onChange={() => handleSelectRow(item?.id)}
                        style={{ color: "#f81b82" }}
                      />
                    </TableCell>
                    <TableCell>{indx + 1}</TableCell>
                    <TableCell>{item?.question}</TableCell>
                    {/* <TableCell>{item?.column2}</TableCell> */}
                    <TableCell>
                      <FormControl
                        fullWidth
                        sx={{
                          mt: 1,
                          bgcolor:
                            item.status === "Active" ? "#228200" : "#E52900",
                          borderRadius: "8px",
                          height: "30px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          "& .MuiSelect-icon": {
                            top: "3px",
                          },
                          "&.MuiFormControl-root": {
                            width: "100px",
                          },
                        }}
                      >
                        <InputLabel
                          htmlFor="demo-simple-select-placeholder"
                          style={{
                            transform: "translate(10px, 10px)",
                            pointerEvents: "none",
                            zIndex: 1,
                            transition: "transform 0.25s, font-size 0.25s",
                            margin: "-6px",
                            color: "white",
                            fontWeight: 400,
                            paddingLeft: "10px",
                            border: "none !important",
                          }}
                        >
                          {item.status ? item.status : "placeholder"}
                        </InputLabel>
                        <Select
                          value={status}
                          onChange={(e) =>
                            handleStatusChange(e.target.value, item.id)
                          }
                          inputProps={{
                            // name: {item.coloum3},
                            id: "demo-simple-select-placeholder",
                          }}
                          sx={{
                            position: "absolute",
                            top: "0px",
                            right: "0",
                            "& .MuiSelect-select": {
                              padding: "0",
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              outline: "none !important",
                              border: "none !important",
                            },
                          }}
                        >
                          <MenuItem value="Active">Active</MenuItem>
                          <MenuItem value="Inactive">Inactive</MenuItem>
                        </Select>
                      </FormControl>
                    </TableCell>

                    <TableCell
                      style={{ padding: "0 16px", whiteSpace: "nowrap" }}
                    >
                      <SlOptionsVertical
                        id="demo-positioned-button"
                        aria-controls={
                          leadOpenActionButton
                            ? "demo-positioned-menu"
                            : undefined
                        }
                        aria-haspopup="true"
                        aria-expanded={
                          leadOpenActionButton ? "true" : undefined
                        }
                        onClick={(e) => {
                          handleClickAction(e, item.id);
                        }}
                        variant="outlined"
                        style={{ marginLeft: "10px", color: "#F80B7A" }}
                        sx={{
                          backgroundColor: "#141E3C",
                          size: "10px",
                          padding: 1,
                          margin: 3,
                          color: "#fff",
                          width: "75px",
                          height: "40px",
                        }}
                      />
                      {/* TABLE ACTION MENU START*/}
                      <Menu
                        id="demo-positioned-menu"
                        aria-labelledby="demo-positioned-button"
                        open={leadActionButtonShow}
                        onClose={handleCloseAction}
                        anchorEl={leadActionButtonShow}
                        MenuListProps={{
                          "aria-labelledby": "basic-button",
                        }}
                        anchorOrigin={{
                          vertical: "bottom", // Change from "top" to "bottom"
                          horizontal: "left",
                        }}
                        transformOrigin={{
                          vertical: "top", // Change from "top" to "bottom"
                          horizontal: "left",
                        }}
                        sx={{
                          borderColor: "black",
                          borderRadius: "24px",
                          mt: 1.5,
                          width: "auto",
                          height: "auto",
                        }}
                      >
                        <Box
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <MenuItem>
                            <AiOutlineEye
                              style={{
                                fontSize: "lg",
                                width: "20px",
                                height: "19px",
                              }}
                              onClick={() =>
                                navigate(`/ielts/settings/faq/${tableId}`)
                              }
                            />
                          </MenuItem>

                          <MenuItem>
                            <EditIcon
                              style={{
                                fontSize: "lg",
                                width: "20px",
                                height: "19px",
                              }}
                              onClick={() =>
                                navigate(`/ielts/settings/faq/edit/${tableId}`)
                              }
                            />
                          </MenuItem>

                          <MenuItem>
                            <RiDeleteBin6Line
                              onClick={() => {
                                handleCloseAction();
                                Swal.fire({
                                  title: "Are you sure?",
                                  text: "You won't be able to revert this!",
                                  icon: "warning",
                                  showCancelButton: true,
                                  confirmButtonColor: "#3085d6",
                                  cancelButtonColor: "#d33",
                                  confirmButtonText: "Yes, delete it!",
                                }).then((result) => {
                                  if (result.isConfirmed) {
                                    dispatch(
                                      ieltsSettingsFaqDeleteAction(tableId)
                                    );
                                    Swal.fire(
                                      "Deleted!",
                                      "Your file has been deleted!.",
                                      "success"
                                    );
                                  }
                                });
                              }}
                              style={{
                                fontSize: "lg",
                                width: "20px",
                                height: "19px",
                              }}
                              className="icons"
                            />
                          </MenuItem>
                        </Box>
                      </Menu>{" "}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
            <TableFooter />
          </Table>
        </Box>

        <Pagination
          sx={{
            mt: "28px",
            "& .Mui-selected": {
              backgroundColor: "#f81b82 !important",
              color: "#fff",
            },
            "& .MuiPagination-ul": {
              justifyContent: "end",
            },
          }}
          count={Math.ceil(data?.length / rowsPerPage)}
          page={page}
          onChange={handleChangePage}
        />
      </Box>
    </>
  );
}

export default IeltsSettingsFaqTable;
