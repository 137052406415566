import axios from "axios";

import {
  COUNTRY_CURRENCY_FIND_ERR,
  COUNTRY_CURRENCY_FIND_REQUEST,
  COUNTRY_CURRENCY_FIND_SUCCESS,
  COUNTRY_FETCH_UNIQUE_TOKEN_ERR,
  COUNTRY_FETCH_UNIQUE_TOKEN_REQUEST,
  COUNTRY_FETCH_UNIQUE_TOKEN_SUCCESS,
  COUNTRY_MASTER_ADD_ERR,
  COUNTRY_MASTER_ADD_REQUEST,
  COUNTRY_MASTER_ADD_SUCCESS,
  COUNTRY_MASTER_DELETE_ERR,
  COUNTRY_MASTER_DELETE_REQUEST,
  COUNTRY_MASTER_DELETE_SUCCESS,
  COUNTRY_MASTER_EDIT_ERR,
  COUNTRY_MASTER_EDIT_FIND_ERR,
  COUNTRY_MASTER_EDIT_FIND_REQUEST,
  COUNTRY_MASTER_EDIT_FIND_SUCCESS,
  COUNTRY_MASTER_EDIT_REQUEST,
  COUNTRY_MASTER_EDIT_SUCCESS,
  COUNTRY_MASTER_FIND_ERR,
  COUNTRY_MASTER_FIND_REQUEST,
  COUNTRY_MASTER_FIND_SUCCESS,
} from "../constants/countryMasterConstant";
import { SUCCESS_TRUE_MSG_ERR } from "../constants/roleManagmentConstant";

// countryMasterAddAction
export const countryMasterAddAction = (selectedCountry) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({ type: COUNTRY_MASTER_ADD_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    selectedCountry.updatedby = isUserExist.name;

    let { data } = await axios.post(
      "/api/admin/country/addCountryMaster",
      selectedCountry,
      config
    );

    dispatch({ type: COUNTRY_MASTER_ADD_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: COUNTRY_MASTER_ADD_ERR, payload: error.response.data });
    dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: true });
  }
};

// findcountryMasterAddAction
export const findcountryMasterAddAction = () => async (dispatch, getState) => {
  try {
    dispatch({ type: COUNTRY_MASTER_FIND_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.get(
      "/api/admin/country/findCountryMaster",
      config
    );

    dispatch({ type: COUNTRY_MASTER_FIND_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: COUNTRY_MASTER_FIND_ERR, payload: error });
  }
};

// CountryMasterDeleteAction
export const CountryMasterDeleteAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: COUNTRY_MASTER_DELETE_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.delete(
      `/api/admin/country/deleteCountryMaster?id=${id}`,
      config
    );

    dispatch({ type: COUNTRY_MASTER_DELETE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: COUNTRY_MASTER_DELETE_ERR, payload: error });
  }
};

// editCountryFormAction
export const editCountryFormAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: COUNTRY_MASTER_EDIT_FIND_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.get(
      `/api/admin/country/editFindCountryMaster?id=${id}`,
      config
    );

    dispatch({ type: COUNTRY_MASTER_EDIT_FIND_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: COUNTRY_MASTER_EDIT_FIND_ERR, payload: error });
  }
};

// countryMasterEditAction
export const countryMasterEditAction = (editData, countryId) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({ type: COUNTRY_MASTER_EDIT_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };
    editData.updatedby = isUserExist.name;
    let { data } = await axios.put(
      `/api/admin/country/editCountryMaster?id=${countryId}`,
      editData,
      config
    );

    dispatch({ type: COUNTRY_MASTER_EDIT_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: COUNTRY_MASTER_EDIT_ERR, payload: error });
  }
};

// fetchCountryUniqueTokenAction
export const fetchCountryUniqueTokenAction = (searchData) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({ type: COUNTRY_FETCH_UNIQUE_TOKEN_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };
    let { data } = await axios.get(
      `/api/admin/country/fetchCountryUniqueToken?id=${searchData}`,
      config
    );

    dispatch({ type: COUNTRY_FETCH_UNIQUE_TOKEN_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: COUNTRY_FETCH_UNIQUE_TOKEN_ERR, payload: error });
  }
};

// currency Fetch data

export const CurrencyFindAction = () => async (
  dispatch,
  getState
) => {
  try {
    dispatch({ type: COUNTRY_CURRENCY_FIND_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };
    let { data } = await axios.get(
      '/api/admin/country/countryCurrencyFind',
      config
    );

    dispatch({ type: COUNTRY_CURRENCY_FIND_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: COUNTRY_CURRENCY_FIND_ERR, payload: error });
  }
};
