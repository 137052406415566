import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import ModalTopicListComponent from "./ModalTopicListComponentEdit";
import { SubmitButton } from "../../../../../customComponent/Buttons";
import CloseIcon from "@mui/icons-material/Close";
import ModalTopicListComponentEdit from "./ModalTopicListComponentEdit";

function TopicModalEdit({
  selectModal,
  handleClose,
  type,
  data,
  handleSelectedTopicData,
}) {

  // console.log(data,"dataaaaa")
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const [filteredData, setFilteredData] = useState();

  // console.log(data,"1151515151")

  useEffect(() => {
    setFilteredData(data)
  }, [data]);
  
  const handleSearchItems = (e) => {
    const value = e.target.value;
    // console.log(data);
    let filteredData = data.filter((item) =>
      item?.TopicName?.toLowerCase().includes(value?.toLowerCase())
    );
    // console.log(filteredData);
    setFilteredData(filteredData);
  };

  const handleCheckboxChange = (index) => {
    setSelectedCheckboxes((prevSelected) => {
      if (prevSelected.includes(index)) {
        return prevSelected.filter((item) => item !== index);
      } else {
        return [...prevSelected, index];
      }
    });
  };

  // console.log(selectedCheckboxes,"selectedChecboxxxxx")

  const handleSelected = () => {
    handleSelectedTopicData(type, selectedCheckboxes);
    handleClose();
  };

  return (
    <Dialog
      open={selectModal}
      onClose={handleClose}
      sx={{
        "& .MuiDialog-paper": {
          maxWidth: "70%",
          width: "100%",
        },
      }}
    >
      {data?.length != 0 && (
        <>
          <DialogTitle
            sx={{
              borderBottom: "1px solid #ECECEC",
            }}
          >
            <Box
              component={"form"}
              sx={{
                width: "100%",
                maxWidth: "530px",
                display: "flex",
                alignItems: "center",
                border: "1px solid #ECECEC",
                "& input": {
                  padding: "8px",
                  outline: "none",
                  border: "none",
                  width: "100%",
                },
              }}
            >
              <input placeholder="Search" onChange={handleSearchItems} />
              <Button>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.5 21.75C5.85 21.75 1.25 17.15 1.25 11.5C1.25 5.85 5.85 1.25 11.5 1.25C17.15 1.25 21.75 5.85 21.75 11.5C21.75 17.15 17.15 21.75 11.5 21.75ZM11.5 2.75C6.67 2.75 2.75 6.68 2.75 11.5C2.75 16.32 6.67 20.25 11.5 20.25C16.33 20.25 20.25 16.32 20.25 11.5C20.25 6.68 16.33 2.75 11.5 2.75Z"
                    fill="#ACB1C6"
                  />
                  <path
                    d="M21.9995 22.7495C21.8095 22.7495 21.6195 22.6795 21.4695 22.5295L19.4695 20.5295C19.1795 20.2395 19.1795 19.7595 19.4695 19.4695C19.7595 19.1795 20.2395 19.1795 20.5295 19.4695L22.5295 21.4695C22.8195 21.7595 22.8195 22.2395 22.5295 22.5295C22.3795 22.6795 22.1895 22.7495 21.9995 22.7495Z"
                    fill="#ACB1C6"
                  />
                </svg>
              </Button>
            </Box>
          </DialogTitle>
          {filteredData?.length > 0 ? (
            <DialogContent
              sx={{
                paddingTop: "15px !important",
                display: "grid",
                gridTemplateColumns: data?.length === 0 ? "1fr" : "1fr 1fr 1fr",
                gap: "32px",
              }}
            >
              {filteredData?.map((item, i) => (
                <ModalTopicListComponentEdit
                  key={i}
                  indexNumber={i}
                  item={item}
                  type={type}
                  handleCheckboxChange={handleCheckboxChange}
                  selectedCheckboxes={selectedCheckboxes}
                />
              ))}
            </DialogContent>
          ) : (
            <DialogContent
              sx={{
                paddingTop: "15px !important",
              }}
            >
              <Box
                sx={{
                  padding: "55px",
                  textAlign: "center",
                  position: "relative",
                }}
              >
                No Items Found
              </Box>
            </DialogContent>
          )}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              gap: "20px",
              padding: "16px 24px",
            }}
          >
            <Typography>
              {" "}
              {selectedCheckboxes?.length} of {filteredData?.length}
            </Typography>
            <Box
              sx={{
                display: "flex",
                gap: "12px",
              }}
            >
              <Button
                sx={{
                  color: "#141E3C",
                  fontWeight: "700",
                }}
                onClick={handleClose}
              >
                Cancel
              </Button>
              <SubmitButton
                type={"click"}
                handleSubmit={handleSelected}
                title={"Done"}
              />
            </Box>
          </Box>
        </>
      )}
      {data?.length === 0 && (
        <Box
          sx={{
            padding: "55px",
            textAlign: "center",
            position: "relative",
          }}
        >
          <CloseIcon
            sx={{
              position: "absolute",
              top: "20px",
              right: "20px",
              cursor: "pointer",
            }}
            onClick={handleClose}
          />
          No Items Found
        </Box>
      )}
    </Dialog>
  );
}

export default TopicModalEdit;
