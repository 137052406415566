import React, { useEffect, useState } from "react";
import { MainContainer } from "../../../../content/content.element";
import { InfoHeader } from "../../../../customComponent/InfoHeader";
import BasicTabs from "../../../../Tabs/TabsMain";
import CourseDetailedViewTabItem from "./CourseDetailedViewTabItem";
import { useSelector } from "react-redux";
import CourseDetailedOfflineViewTabItem from "./CourseDetailedViewOnlineTabItem";
import { useLocation } from "react-router-dom";

function CourseDetailedView({ toggle }) {

  const [coursDetails,setCoursDetails] = useState({})

  let {
    ieltsCourseManagmentCourseFindByIdSuccess,
    ieltsCourseManagmentCourseFindByIderror,
  } = useSelector((state) => {
    return state.ieltsCourseManagmentCourseFindById;
  });

  useEffect(() => {
    if (ieltsCourseManagmentCourseFindByIdSuccess) {
      setCoursDetails(ieltsCourseManagmentCourseFindByIdSuccess.data);
    }
  }, [ieltsCourseManagmentCourseFindByIdSuccess]);

  const {pathname} = useLocation()
  const location = pathname.split("/")[4];



  return (
    <MainContainer active={toggle}>
      <InfoHeader headTitle={coursDetails?.CourseDetails?.name} url="/IELTS" />
      {location === "online" ? (
        <BasicTabs
          tab1Name="Speaking"
          tab2Name="Reading"
          tab3Name="Listening"
          tab4Name="Writing"
          tab5Name="Grammar"
          tab1={<CourseDetailedViewTabItem tabName={"Speaking"} />}
          tab2={<CourseDetailedViewTabItem tabName={"Reading"} />}
          tab3={<CourseDetailedViewTabItem tabName={"Listening"} />}
          tab4={<CourseDetailedViewTabItem tabName={"Writing"} />}
          tab5={<CourseDetailedViewTabItem tabName={"Grammar"} />}
        />
      ) : location === "offline" ? (
        <BasicTabs
          tab1Name="offline"
          tab1={<CourseDetailedOfflineViewTabItem tabName={"Offline"} />}
        />
      ) : (
        <BasicTabs
          tab1Name="Speaking"
          tab2Name="Reading"
          tab3Name="Listening"
          tab4Name="Writing"
          tab5Name="Grammar"
          tab6Name="offline"
          tab1={<CourseDetailedViewTabItem tabName={"Speaking"} />}
          tab2={<CourseDetailedViewTabItem tabName={"Reading"} />}
          tab3={<CourseDetailedViewTabItem tabName={"Listening"} />}
          tab4={<CourseDetailedViewTabItem tabName={"Writing"} />}
          tab5={<CourseDetailedViewTabItem tabName={"Grammar"} />}
          tab6={<CourseDetailedOfflineViewTabItem tabName={"Offline"} />}
        />
      )}
    </MainContainer>
  );
}

export default CourseDetailedView;
