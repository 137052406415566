import React, { useEffect, useState } from "react";

import { makeStyles } from "@material-ui/core";

import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import { BiDownload } from "react-icons/bi";
import { hrEmployeeHolidayFindAction } from "../../../../../actions/HrActions/hrEmployeeManagmentAction";

function EmployeeHolidayTable({ role }) {
  const dispatch = useDispatch();

  const [employeeHoliday, setEmployeeHoliday] = useState([]);
  const [data, setData] = useState([]);

  useEffect(() => {
    dispatch(hrEmployeeHolidayFindAction());
  }, [dispatch]);

  let { hrEmployeeHolidaySuccess } = useSelector((state) => {
    return state.hrEmployeeHolidayFind;
  });


  useEffect(() => {
    if (hrEmployeeHolidaySuccess) {
      setEmployeeHoliday(hrEmployeeHolidaySuccess);
    }
  }, [hrEmployeeHolidaySuccess]);



  const columns = [
    {
      title: "Sl no",
      field: "sl",
      // type: "numeric",
      //   hidden: true,
      headerStyle: { backgroundColor: "#F9F9FB" },
    },
    {
      title: "Holiday Name",
      field: "name",
      //   hidden: showEmployeeNameColumn,
      headerStyle: { backgroundColor: "#F9F9FB" },
    },
    {
      title: "Working days",
      field: "day",
      //   hidden: showEmailColumn,
      headerStyle: { backgroundColor: "#F9F9FB" },
    },
    {
      title: "Holiday Month",
      field: "Month",
      //   hidden: showMobileColumn,
      headerStyle: { backgroundColor: "#F9F9FB" },
    },
    {
      title: "Holiday Date",
      field: "date",
      //   hidden: showRollColumn,
      headerStyle: { backgroundColor: "#F9F9FB" },
    },
  ];

  useEffect(() => {
    if (employeeHoliday.length !== 0) {
      setData(
        employeeHoliday &&
          employeeHoliday?.map((holiday, index) => ({
            sl: index + 1,
            id: holiday.paySlipId,
            userId: holiday.UserId,
            name: holiday.Holiday_Name,
            leaveCount: holiday.Leave_count,
            month: holiday.Holiday_Month,
            date: holiday.Holiday_Date,
          }))
      );
    }
  }, [employeeHoliday]);

  return (
    <Table className="leaveShowTable">
      <TableHead>
        <TableRow className="leaveShowTableCell">
          <TableCell>
            <Checkbox
              // checked={selectedRows.length === sortedData.length}
              // onChange={handleSelectAllRows}
              style={{ color: "#f81b82" }}
            />
          </TableCell>
          {columns?.map((column) => {
            if (column.hidden) {
              return null;
            }
            return (
              <TableCell key={column.field} className="leaveShowTableCell">
                <TableSortLabel
                  className="leaveShowHeaderText"
                  // active={column === sortBy}
                  // direction={sortOrder}
                  // onClick={() => requestSort(column)}
                >
                  {column.title}
                </TableSortLabel>
              </TableCell>
            );
          })}
        </TableRow>
      </TableHead>
      <TableBody>
        {data?.map((item, indx) => (
          <TableRow className="leaveShowTable" key={indx}>
            <TableCell>
              <Checkbox
                // checked={selectedRows.includes(item.id)}
                // onChange={() => handleSelectRow(item.id)}
                style={{ color: "#f81b82" }}
              />
            </TableCell>
            <TableCell>{item.sl}</TableCell>
            <TableCell>{item.name}</TableCell>

            <TableCell>{item.leaveCount}</TableCell>

            <TableCell>{item.month}</TableCell>

            <TableCell>{item.date}</TableCell>
            {/* <TableCell>
              <a
                style={{
                  color: "white",
                  backgroundColor: "#ACB1C6",
                  padding: "5px",
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  borderRadius: "5px",
                  textDecoration: "none",
                }}
                href={item.paySlip}
                download={item.paySlip}
                target="blank"
              >
                <BiDownload
                  style={{
                    marginRight: "10px",
                    height: "20px",
                    width: "25px",
                  }}
                />
                Download
              </a>
            </TableCell> */}
          </TableRow>
        ))}
      </TableBody>
      <TableFooter />
    </Table>
  );
}

export default EmployeeHolidayTable;
