  // TEACHER DASHBOARD FIND CONSTANTS

  export const IELTS_TEACHER_DASHBOARD_FIND_REQUEST =
  "IELTS_TEACHER_DASHBOARD_FIND_REQUEST";
  export const IELTS_TEACHER_DASHBOARD_FIND_SUCCESS =
  "IELTS_TEACHER_DASHBOARD_FIND_SUCCESS";
  export const IELTS_TEACHER_DASHBOARD_FIND_ERR =
  "IELTS_TEACHER_DASHBOARD_FIND_ERR";

// TEACHER DASHBOARD FINDBYID ASSIGNED STUDENTS CONSTANTS


export const IELTS_TEACHER_DASHBOARD_FINDBYID_ASSIGNEDSTUDENTS_REQUEST =
"IELTS_TEACHER_DASHBOARD_FINDBYID_ASSIGNEDSTUDENTS_REQUEST";
export const IELTS_TEACHER_DASHBOARD_FINDBYID_ASSIGNEDSTUDENTS_SUCCESS =
"IELTS_TEACHER_DASHBOARD_FINDBYID_ASSIGNEDSTUDENTS_SUCCESS";
export const IELTS_TEACHER_DASHBOARD_FINDBYID_ASSIGNEDSTUDENTS_ERR =
"IELTS_TEACHER_DASHBOARD_FINDBYID_ASSIGNEDSTUDENTS_ERR";

// TEACHER DASHBOARD ASSIGNED STUDENTS COURSE FIND CONSTANTS


export const IELTS_TEACHER_DASHBOARD__ASSIGNEDSTUDENTS_COURSEFIND_REQUEST =
"IELTS_TEACHER_DASHBOARD__ASSIGNEDSTUDENTS_COURSEFIND_REQUEST";
export const IELTS_TEACHER_DASHBOARD__ASSIGNEDSTUDENTS_COURSEFIND_SUCCESS =
"IELTS_TEACHER_DASHBOARD__ASSIGNEDSTUDENTS_COURSEFIND_SUCCESS";
export const IELTS_TEACHER_DASHBOARD__ASSIGNEDSTUDENTS_COURSEFIND_ERR =
"IELTS_TEACHER_DASHBOARD__ASSIGNEDSTUDENTS_COURSEFIND_ERR";


// TEACHER DASHBOARD LIVE CLASS FIND CONSTANTS


export const IELTS_TEACHER_DASHBOARD_LIVECLASS_FIND_REQUEST =
"IELTS_TEACHER_DASHBOARD_LIVECLASS_FIND_REQUEST";
export const IELTS_TEACHER_DASHBOARD_LIVECLASS_FIND_SUCCESS =
"IELTS_TEACHER_DASHBOARD_LIVECLASS_FIND_SUCCESS";
export const IELTS_TEACHER_DASHBOARD_LIVECLASS_FIND_ERR =
"IELTS_TEACHER_DASHBOARD_LIVECLASS_FIND_ERR";