import { Alert, AlertTitle, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { LeadSourceTable } from "./LeadSourceTable";
import { leadSourceFindAction } from "../../../actions/leadSourceAction";
import usePrivilegeCheck from "../../../constants/customHooks/UsePrivilegeCheck";
import PremissionDialog from "../../customComponent/premissionDialog";
import { is } from "date-fns/locale";
// import { bannerListAction } from "../../../actions/bannerAction";

function LeadSourceMain() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { privilege, hasPrivilege, isCreatePrevilage } = usePrivilegeCheck();

  const [openAlert, setOpenAlert] = useState(false);

  // HandleClose dialog

  const handleCloseDialog = () => {
    setOpenAlert(false);
  };

  const [page, setPage] = useState(1);
  const [bannerData, setBannerData] = useState([]);

  let { findLeadSourceSuccess } = useSelector((state) => {
    return state.leadSourceFind;
  });

  let { deleteLeadSourceSuccess } = useSelector((state) => {
    return state.deleteLeadSource;
  });

  useEffect(() => {
    dispatch(leadSourceFindAction(page));
  }, [page, deleteLeadSourceSuccess]);

  useEffect(() => {
    if (findLeadSourceSuccess) {
      setBannerData(findLeadSourceSuccess);
    }
  }, [findLeadSourceSuccess]);

  //find banner list
  const data = bannerData?.map((data, index) => ({
    id: data.id,
    sl: index + 1,
    column1: data.leadSource,
    column2: data.createdAt.split("T")[0],
    column3: data.updatedAt.split("T")[0],
  }));

  const columns = [
    {
      title: "Sl no",
      field: "sl",
      // type: "numeric",
      // hidden: true,
      headerStyle: { backgroundColor: "#F9F9FB" },
    },
    {
      title: "Lead Source",
      field: "leadSource",
      //   hidden: showEmployeeNameColumn,
      headerStyle: { backgroundColor: "#F9F9FB" },
    },
    // {
    //   title: "Lead Source Name",
    //   field: "leadSourceName",
    //   //   hidden: showEmployeeNameColumn,
    //   headerStyle: { backgroundColor: "#F9F9FB" },
    // },
    {
      title: "Create Date",
      field: "createDate",
      //   hidden: showEmployeeNameColumn,
      headerStyle: { backgroundColor: "#F9F9FB" },
    },

    {
      title: "Update Date",
      field: "upldateDate",
      //   hidden: showEmployeeNameColumn,
      headerStyle: { backgroundColor: "#F9F9FB" },
    },
    {
      title: "Action",
      field: "action",
      //   hidden: showEmployeeNameColumn,
      headerStyle: { backgroundColor: "#F9F9FB" },
    },
  ];

  if (!hasPrivilege("lead source") && privilege !== null) {
    return (
      <Alert severity="error">
        <AlertTitle>warning</AlertTitle>
        You don't have permission to access — <strong>this page.!</strong>
      </Alert>
    );
  }

  return (
    <>
      <Button
        sx={{
          color: "white",
          backgroundColor: "#141E3C",
          height: "38px",
          width: "143px",
          fontWeight: 700,
          fontSize: "12px",
          display: "flex",
          marginLeft: "auto",
          borderRadius: "8px",
          "&:hover": {
            backgroundColor: "rgba(20, 30, 60, 0.9)",
          },
        }}
        onClick={() => {
          if (isCreatePrevilage("lead source")) {
            navigate("/Settings/addleadSource");
          } else {
            setOpenAlert(true);
          }
        }}
      >
        Add New
      </Button>

      <div style={{ marginTop: "15px" }}></div>

      <LeadSourceTable
        data={data}
        columns={columns}
        component="LeadSourceMain"
        subMenu="lead source"
        // rollEditHandler={()=>}
      />

      {openAlert && (
        <PremissionDialog
          openAlert={openAlert}
          handleClose={handleCloseDialog}
        />
      )}
    </>
  );
}

export default LeadSourceMain;
