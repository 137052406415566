import React, { useEffect, useState } from "react";

import { AiOutlineDownload, AiOutlinePlus } from "react-icons/ai";

import { SiMicrosoftexcel } from "react-icons/si";

import { RiSearch2Line } from "react-icons/ri";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";

import {
  Box,
  Button,
  Checkbox,
  Chip,
  Grid,
  Icon,
  InputAdornment,
  ListItem,
  Menu,
  MenuItem,
  TextField,
} from "@mui/material";

import { RiUserFollowLine } from "react-icons/ri";
import { BsArrowUpCircle, BsArrowRightCircle } from "react-icons/bs";
import { MdOutlineAssignmentReturned } from "react-icons/md";
import { TbArrowsExchange2 } from "react-icons/tb";
import { RiDeleteBinLine } from "react-icons/ri";

import { useNavigate } from "react-router-dom";

import { makeStyles, OutlinedInput, Paper } from "@material-ui/core";

// import LeadCreatForm from "./createLeads/LeadCreatForm";
import { useDispatch, useSelector } from "react-redux";
import {
  leadFindAction,
  leadOwnerChangeAction,
} from "../../actions/leadActions/leadActions";
import { findBranchMasterAction } from "../../actions/branchMasterActions";
import {
  branchManagerFetchAction,
  teamLeadStaffsFindAction,
} from "../../actions/employeeManagmentAction";
import { OutLinedButton, SubmitButton } from "../customComponent/Buttons";
import Swal from "sweetalert2";
import { MultiSelect, SelectInputField } from "../customComponent/InputField";
import PartnerCreateForm from "./PartnerCreateForm";
import {
  partnerManagerChangeAction,
  teamLeadAssignStaffChangeAction,
} from "../../actions/partnerAction/partnerAction";
import { successTrueMsgAction } from "../../actions/globalAction";
import usePrivilegeCheck from "../../constants/customHooks/UsePrivilegeCheck";

const useStyles = makeStyles((theme) => ({
  searchContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(2),
    padding: theme.spacing(1),
  },
  searchInput: {
    marginLeft: theme.spacing(1),
  },
}));

function PartnerSearch({
  columnVisibleHandler,
  excelDownloadClick,
  data,
  searchHandleChange,
  pdfDownloadClick,
  component,
  showNameColumn,
  showPrefferedColumn,
  showPhoneColumn,
  showLeadSourceColumn,
  showLeadTypeColumn,
  showStatusColumn,
  showEducationColumn,
  showUniversityPlaceColumn,
  filterPassData,
  whichFilter,
  handleResetData,
  handleDeleteClick,
  ids,
  leadId,
  noAction,
  subMenu,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const {
    privilege,
    hasPrivilege,
    isCreateNewPartnerPrevilage,
    isChangeLeaderPrevilage,
    isDeletePrevilage,
    isCommisionCategoryChangePrevilage,
  } = usePrivilegeCheck();

  const [anchorEl, setAnchorEl] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [addForm, setAddForm] = React.useState(false);
  const [chipData, setChipData] = useState([]);
  const [openOwner, setOpenOwner] = useState(false);
  const [dialogModel, setDialogModel] = useState("");
  const [changeOwner, setChangeOwner] = useState();
  const [branchManager, setBranchManager] = useState();
  const [followUpActivities, setFollowUpActivities] = useState([]);

  let {
    branchMasterFindLoading,
    branchMasterFindSuccess,
    branchMasterFindErr,
  } = useSelector((state) => {
    return state.findBranchMaster;
  });

  let {
    branchManagerLoading,
    branchManagerSuccess,
    branchManagerError,
  } = useSelector((state) => {
    return state.branchManagerFetch;
  });

  useEffect(() => {
    dispatch(leadFindAction());
  }, [dispatch]);

  useEffect(() => {
    dispatch(findBranchMasterAction());
  }, [dispatch, dialogModel]);

  useEffect(() => {
    if (dialogModel === "Leader") {
      dispatch(branchManagerFetchAction("", dialogModel));
    }
  }, [dispatch, dialogModel]);

  useEffect(() => {
    if (dialogModel === "assignUser") {
      dispatch(teamLeadStaffsFindAction());
    }
  }, [dispatch, dialogModel]);

  useEffect(() => {
    if (whichFilter !== null && filterPassData !== null) {
      setChipData((prevChipData) => {
        const updatedChipData = prevChipData.map((chip) => {
          if (chip.label === whichFilter) {
            return {
              ...chip,
              result: filterPassData,
            };
          }
          return chip;
        });

        if (!updatedChipData.some((chip) => chip.label === whichFilter)) {
          return [
            ...updatedChipData,
            { label: whichFilter, result: filterPassData },
          ];
        }

        return updatedChipData;
      });
    } else {
      setChipData([]);
    }
  }, [whichFilter, filterPassData]);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleStateChange = () => {
    setAddForm(false);
  };
  // button Export
  const [exportShow, setExportShow] = React.useState(null);
  const openExport = Boolean(exportShow);

  const handleClickExport = (event) => {
    setExportShow(event.currentTarget);
  };
  const handleCloseExport = () => {
    setExportShow(null);
  };

  const handleClickOpen = (dialogModelName) => {
    setDialogModel(dialogModelName);
    setOpenOwner(true);
    // setApplyData(data);
  };

  const handleChange = (event) => {
    setChangeOwner(event.target.value);
    dispatch(branchManagerFetchAction(event.target.value, dialogModel));
  };

  const handleOwnerChange = (event) => {
    setBranchManager(event.target.value);
  };

  const handlePopClose = () => {
    setOpenOwner(false);
  };
  // button Action
  const [actionShow, setActionShow] = React.useState(null);
  const openAction = Boolean(actionShow);

  const handleClickAction = (event) => {
    setActionShow(event.currentTarget);
  };
  const handleCloseAction = () => {
    setActionShow(null);
  };

  // chip
  const handleDelete = (chipToDelete) => () => {
    setChipData((chips) => chips.filter((chip) => chip.label !== chipToDelete));
    handleResetData(true);
  };

  const handleMethodApplyClick = () => {
    if (ids.length !== 0 || leadId.length !== 0) {
      if (component == "application") {
        dispatch(
          teamLeadAssignStaffChangeAction(
            ids.length !== 0 ? ids : leadId,
            branchManager?.id
          )
        );
      } else {
        setOpenOwner(false);
        dispatch(
          partnerManagerChangeAction(
            ids.length !== 0 ? ids : leadId,
            branchManager?.id
          )
        );
      }
      dispatch(successTrueMsgAction(true));
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        footer: `Please choose any one`,
      });
    }
  };

  const handleAutocompleteChange = (event, newValue) => {
    setFollowUpActivities(newValue);
  };
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <div
            style={{
              display: "flex",
              justifyContent: "start",
              marginTop: "5px",
              width: "auto",
              fontSize: "8px",
            }}
          >
            {chipData.map((data, index) => {
              return (
                <ListItem key={index} style={{ marginBottom: "2px" }}>
                  <Chip
                    size="small"
                    className="MuiChip-sizeSmall"
                    classes={{
                      iconSmall: "MuiChip-iconSmall",
                      labelSmall: "MuiChip-labelSmall",
                    }}
                    icon={`${data.label}:`}
                    onDelete={handleDelete(data.label)}
                    label={
                      <Typography variant="body2" style={{ fontSize: 12 }}>
                        <span style={{ fontWeight: 600, fontSize: 12 }}>
                          {whichFilter}:{" "}
                        </span>
                        {data.result}
                      </Typography>
                    }
                    style={{
                      fontWeight: 90,
                      fontSize: 10,
                      marginLeft: "-10px",
                    }}
                  />
                </ListItem>
              );
            })}
          </div>
        </Grid>
        <Grid item xs={9}>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Grid>
              <div className={classes.searchContainer}>
                <TextField
                  className={classes.searchInput}
                  variant="outlined"
                  placeholder="Search Here"
                  value={searchText}
                  onChange={(e) => {
                    setSearchText(e.target.value);
                    searchHandleChange(e);
                  }}
                  sx={{
                    "& .MuiInputBase-input": {
                      borderRadius: "20px",
                      height: "1px",
                    },
                    "& .MuiFormLabel-root": {
                      lineHeight: "5px",
                    },

                    "& .MuiInputBase-root": {
                      borderRadius: "3px",
                      borderColor: "#fafafa",
                    },
                  }}
                />
                <>
                  <Box
                    sx={{
                      width: "40px",
                      height: "16px",
                      pt: 1.9,
                      textAlign: "center",
                      border: "1px solid #9d9fa1",
                      borderBottomRightRadius: "5px",
                      WebkitBorderTopRightRadius: "5px",
                      fontSize: "24px",
                    }}
                  >
                    <InputAdornment position="end">
                      <RiSearch2Line />
                    </InputAdornment>
                  </Box>
                </>
              </div>
            </Grid>
            <Grid sx={{ margin: 1 }}>
              <OutLinedButton
                id="demo-positioned-button"
                aria-controls={open ? "demo-positioned-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                title="Column visibility"
                handleClick={handleClick}
                widthSize="182px"
                heightSize="30px"
              />
              <Menu
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                sx={{ borderColor: "black", borderRadius: "24px", mt: 6 }}
              >
                <MenuItem>
                  <Checkbox
                    sx={{
                      color: "#F8469A",
                      "&.Mui-checked": {
                        color: "#F8469A",
                      },
                    }}
                    checked={showNameColumn === true ? true : false}
                    onChange={(e) =>
                      columnVisibleHandler(e.target.checked, "name")
                    }
                  />
                  Name
                </MenuItem>
                <MenuItem>
                  <Checkbox
                    sx={{
                      color: "#F8469A",
                      "&.Mui-checked": {
                        color: "#F8469A",
                      },
                    }}
                    checked={showPhoneColumn === true ? true : false}
                    onChange={(e) =>
                      columnVisibleHandler(e.target.checked, "mobile")
                    }
                  />
                  Phone
                </MenuItem>
                <MenuItem>
                  <Checkbox
                    sx={{
                      color: "#F8469A",
                      "&.Mui-checked": {
                        color: "#F8469A",
                      },
                    }}
                    checked={showPrefferedColumn === true ? true : false}
                    onChange={(e) =>
                      columnVisibleHandler(e.target.checked, "prefferedCountry")
                    }
                  />
                  preffered country
                </MenuItem>
                <MenuItem>
                  <Checkbox
                    sx={{
                      color: "#F8469A",
                      "&.Mui-checked": {
                        color: "#F8469A",
                      },
                    }}
                    checked={showLeadSourceColumn === true ? true : false}
                    onChange={(e) =>
                      columnVisibleHandler(e.target.checked, "leadSource")
                    }
                  />
                  Lead source
                </MenuItem>
                <MenuItem>
                  <Checkbox
                    sx={{
                      color: "#F8469A",
                      "&.Mui-checked": {
                        color: "#F8469A",
                      },
                    }}
                    checked={showLeadTypeColumn === true ? true : false}
                    onChange={(e) =>
                      columnVisibleHandler(e.target.checked, "leadType")
                    }
                  />
                  Lead Type
                </MenuItem>
                <MenuItem>
                  <Checkbox
                    sx={{
                      color: "#F8469A",
                      "&.Mui-checked": {
                        color: "#F8469A",
                      },
                    }}
                    checked={showStatusColumn === true ? true : false}
                    onChange={(e) =>
                      columnVisibleHandler(e.target.checked, "status")
                    }
                  />
                  Status
                </MenuItem>
                <MenuItem>
                  <Checkbox
                    sx={{
                      color: "#F8469A",
                      "&.Mui-checked": {
                        color: "#F8469A",
                      },
                    }}
                    checked={showEducationColumn === true ? true : false}
                    onChange={(e) =>
                      columnVisibleHandler(e.target.checked, "education")
                    }
                  />
                  Education
                </MenuItem>

                <MenuItem>
                  <Checkbox
                    sx={{
                      color: "#F8469A",
                      "&.Mui-checked": {
                        color: "#F8469A",
                      },
                    }}
                    checked={showUniversityPlaceColumn === true ? true : false}
                    onChange={(e) =>
                      columnVisibleHandler(e.target.checked, "universityPlace")
                    }
                  />
                  Lead Place
                </MenuItem>
              </Menu>
            </Grid>

            {/* export Button */}

            <Grid sx={{ margin: 1 }}>
              <OutLinedButton
                title="Export"
                handleClick={handleClickExport}
                widthSize="auto"
                heightSize="30px"
              />

              <Menu
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={exportShow}
                open={openExport}
                onClose={handleCloseExport}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                sx={{ borderColor: "black", borderRadius: "24px", mt: 6 }}
              >
                <MenuItem>
                  <Icon fontSize="medium" color="secondary" sx={{ m: 1 }}>
                    <AiOutlineDownload
                      style={{ color: "#141E3C", fontSize: "24px" }}
                      onClick={pdfDownloadClick}
                    />
                  </Icon>
                  Pdf
                </MenuItem>
                <MenuItem onClick={excelDownloadClick}>
                  <Icon fontSize="medium" color="secondary" sx={{ m: 1 }}>
                    <SiMicrosoftexcel
                      style={{ color: "#141E3C", fontSize: "24px" }}
                    />
                  </Icon>
                  Excel
                </MenuItem>
              </Menu>
            </Grid>
            {/* Action Button */}

            {/* Action Button */}
            {!noAction ? (
              isCreateNewPartnerPrevilage(subMenu) ||
              isChangeLeaderPrevilage(subMenu) ||
              isDeletePrevilage(subMenu) ? (
                <Grid sx={{ margin: 1 }}>
                  <SubmitButton
                    title="Action"
                    submit=""
                    widthSize="75px"
                    heightSize="30px"
                    type="click"
                    handleSubmit={handleClickAction}
                  />

                  <Menu
                    id="demo-positioned-menu"
                    aria-labelledby="demo-positioned-button"
                    anchorEl={actionShow}
                    open={openAction}
                    onClose={handleCloseAction}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    sx={{
                      borderColor: "rgba(20, 30, 60, 1)",
                      borderRadius: "24px",
                      mt: 6,
                      width: "280px",
                      height: "503px",
                    }}
                  >
                    {isCreateNewPartnerPrevilage(subMenu) ? (
                      <MenuItem>
                        <Button
                          startIcon={<AiOutlinePlus />}
                          sx={{
                            backgroundColor: "#141E3C",
                            color: "white",
                            "&:hover": {
                              backgroundColor: "rgba(20, 30, 60, 0.9)",
                            },
                          }}
                          onClick={() => setAddForm(true)}
                        >
                          Create a New Partner
                        </Button>
                      </MenuItem>
                    ) : (
                      ""
                    )}
                    {isChangeLeaderPrevilage(subMenu) ? (
                      <MenuItem>
                        <Button
                          startIcon={<BsArrowRightCircle />}
                          onClick={() =>
                            handleClickOpen(
                              component == "application"
                                ? "assignUser"
                                : "Leader"
                            )
                          }
                        >
                          {component == "application"
                            ? "Assign User"
                            : "Change Leader"}
                        </Button>
                      </MenuItem>
                    ) : (
                      ""
                    )}
                    {isDeletePrevilage(subMenu) ? (
                      <MenuItem>
                        <Button
                          startIcon={<RiDeleteBinLine />}
                          // sx={{ backgroundColor: "#141E3C" }}
                          onClick={handleDeleteClick}
                        >
                          Delete
                        </Button>
                      </MenuItem>
                    ) : (
                      ""
                    )}
                  </Menu>
                  {addForm && (
                    <PartnerCreateForm
                      addForm={addForm}
                      handleStateChange={handleStateChange}
                    />
                  )}
                </Grid>
              ) : (
                ""
              )
            ) : (
              ""
            )}
          </Box>
        </Grid>
      </Grid>

      {/* actions dialog */}
      <Dialog
        open={openOwner}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          sx: {
            bottom: dialogModel === "Leader" ? "25vh" : "30vh",
            width: "850px",
          },
        }}
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{ fontSize: "22px", fontWeight: 700 }}
        >
          {dialogModel === "Leader"
            ? "Change Leader"
            : dialogModel === "assignUser"
            ? "Assign User"
            : null}
        </DialogTitle>
        {dialogModel === "Leader" ? (
          <>
            <DialogContent>
              {/* <Box sx={{ mt: 1 }}>
                <SelectInputField
                  label="Choose Branch"
                  handleSelectChange={handleChange}
                  widthSize="101px"
                  value={changeOwner}
                  mapValue={branchMasterFindSuccess && branchMasterFindSuccess}
                  mapMethod="branchOwner"
                />
              </Box> */}

              <Box sx={{ mt: 2 }}>
                <SelectInputField
                  label="Choose Team Lead"
                  handleSelectChange={handleOwnerChange}
                  widthSize="160px"
                  value={branchManager}
                  mapValue={branchManagerSuccess && branchManagerSuccess}
                  mapMethod="branchManager"
                />
              </Box>
              {/* <div style={{ display: "flex", marginTop: "5px" }}>
                <Checkbox
                  id="full-day"
                  label="Send Email Notification To Leader"
                  // checked={isFullDay}
                  // onChange={(e) => setIsFullDay(e.target.checked)}
                  sx={{
                    color: "#F8469A",
                    "&.Mui-checked": {
                      color: "#F8469A",
                    },
                  }}
                />
                <Typography sx={{ mt: 1, fontSize: "14px", fontWeight: 400 }}>
                  Send Email Notification To Leader
                </Typography>
              </div> */}
              <Box sx={{ mt: 2.5 }}>
                {/* <Paper
                  sx={{
                    height: "auto",
                    pt: 1,
                    pb: 1,
                    border: "2px solid #ececec",
                  }}
                  elevation={0}
                >
                  <Autocomplete
                    multiple
                    id="tags-standard"
                    options={["Open Calls", "Open Tasks", "Open Meetings"]}
                    sx={{
                      textAlign: "center",
                      // backgroundColor: "white",
                      // border: "none",
                      border: "0.1px solid #ececec",
                      borderColor: "#ececec",
                      height: "50px",
                      "&:focus": {
                        outline: "none",
                      },
                      "& fieldset": {
                        border: "none",
                        borderRadius: "4px",
                        height: "50px",
                      },
                      // height: "auto",
                      "& .MuiFormLabel-root": {
                        height: "42px",
                        mt: -2,
                        // pb:2,

                        "&.Mui-focused": {
                          color: "#7d7d7d",
                          pt: 4,
                        },
                      },
                    }}
                    getOptionLabel={(option) => option}
                    value={followUpActivities}
                    onChange={handleAutocompleteChange}
                    disableCloseOnSelect
                    renderOption={(props, option, { selected }) => (
                      <MenuItem
                        key={option}
                        value={option}
                        sx={{ justifyContent: "space-between" }}
                        {...props}
                      >
                        {option}
                        {selected ? <CheckIcon color="info" /> : null}
                      </MenuItem>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Transfer Activity"
                      />
                    )}
                    renderTags={(value, getTagProps) => (
                      <Stack gap={1} direction="row" flexWrap="wrap">
                        {value.map((option, index) => (
                          <Chip
                            key={index}
                            label={option}
                            {...getTagProps({ index })}
                            deleteIcon={
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  cursor: "pointer",
                                }}
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  const newValues = [...followUpActivities];
                                  newValues.splice(index, 1);
                                  setFollowUpActivities(newValues);
                                }}
                              >
                                X
                              </div>
                            }
                          />
                        ))}
                      </Stack>
                    )}
                  />
                </Paper> */}
              </Box>
            </DialogContent>
            <DialogActions>
              <OutLinedButton
                title="Cancel"
                handleClick={handlePopClose}
                widthSize="75px"
                heightSize="30px"
              />

              <SubmitButton
                title="Submit"
                submit=""
                widthSize="75px"
                heightSize="30px"
                type="click"
                handleSubmit={handleMethodApplyClick}
              />
            </DialogActions>{" "}
          </>
        ) : dialogModel === "assignUser" ? (
          <>
            <DialogContent>
              <Box sx={{ mt: 2 }}>
                <SelectInputField
                  label="Assign Staff"
                  handleSelectChange={handleOwnerChange}
                  widthSize="160px"
                  value={branchManager}
                  mapValue={branchManagerSuccess && branchManagerSuccess}
                  mapMethod="branchManager"
                />
              </Box>
            </DialogContent>
            <DialogActions>
              <OutLinedButton
                title="Cancel"
                handleClick={handlePopClose}
                widthSize="75px"
                heightSize="30px"
              />

              <SubmitButton
                title="Submit"
                submit=""
                widthSize="75px"
                heightSize="30px"
                type="click"
                handleSubmit={handleMethodApplyClick}
              />
            </DialogActions>{" "}
          </>
        ) : null}
      </Dialog>
    </>
  );
}

export default PartnerSearch;
