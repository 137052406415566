import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

const DownloadExcel = (columns, data, tableName) => {
  console.log(data, "dataaa");
  // Create a worksheet for Excel

  let wsData;

  if (tableName === "Role_Managment" || tableName === "Employee_Managment") {
    wsData = [
      columns.map((item) => [item.title]), // Wrap each title in a sub-array
      ...data.map((item) => [
        item.sl,
        item.name,
        item.description,
        item.updatedby,
        item.lastupdateby
      ])
    ];
  }

  if (tableName === "Lead_Managment") {
    wsData = [
      columns.map((item) => [item.title]), // Wrap each title in a sub-array
      ...data.map((item) => [
        item.sl,
        item.name,
        item.phone,
        item.preferredCountry,
        item.leadSource,
        item.leadOwner,
        item.status,
        item.priority,
        item.leadCity,
        item.leadBranch
      ])
    ];
  }

  if (tableName === "Hr_Jobvacancies_Managment") {
    wsData = [
      columns.map((item) => [item.title]), // Wrap each title in a sub-array
      ...data.map((item) => [
        item.sl,
        item.coloum1,
        item.coloum2,
        item.coloum3,
        item.coloum4,
        item.coloum5,
        item.coloum6
      ])
    ];
  }

  if (tableName === "Entrolledstudentslist") {
    wsData = [
      columns.map((item) => [item.title]), // Wrap each title in a sub-array
      ...data.map((item, index) => [
        item.sl,
        item.coloum1,
        item.coloum2,
        item.coloum3,
        item.coloum4,
        item.coloum5,
        item.coloum6,
        item.coloum7,
        item.coloum8,
        item.coloum9,
        item.coloum10
      ])
    ];
  }

  if (tableName === "EntrolledTeacherList") {
    wsData = [
      columns.map((item) => [item.title]), // Wrap each title in a sub-array
      ...data.map((item, index) => [
        item.sl,
        item.coloumn1,
        item.coloumn2,
        item.coloumn3,
        item.coloumn4,
        item.coloumn5,
        item.coloumn6
      ])
    ];
  }

  if (tableName === "OnlinePurchasedStudentList") {
    wsData = [
      columns.map((item) => [item.title]),
      ...data.map((item, index) => [
        index + 1,
        item?.ielts_entrolled_student?.fullName,
        item?.ielts_entrolled_student?.dob,
        item?.ielts_entrolled_student?.gender,
        item?.ielts_entrolled_student?.email,
        item?.ielts_entrolled_student?.phoneNumber,
        item?.ielts_entrolled_student?.whatsAppNumber,
        item?.ielts_course?.CourseName,
        item?.ielts_entrolled_student?.status
      ])
    ];
  }

  if (tableName === "OfflinePurchasedStudentList") {
    wsData = [
      columns.map((item) => [item.title]),
      ...data.map((item, index) => [
        index + 1,
        item?.ielts_entrolled_student?.fullName,
        item?.ielts_entrolled_student?.dob,
        item?.ielts_entrolled_student?.gender,
        item?.ielts_entrolled_student?.email,
        item?.ielts_entrolled_student?.phoneNumber,
        item?.ielts_entrolled_student?.whatsAppNumber,
        item?.ielts_course?.CourseName,
        item?.ielts_center?.CenterName,
        item?.payment,
        item?.ielts_teacher?.teacherName,
        item?.ielts_entrolled_student?.status
      ])
    ];
  }

  if (tableName === "ALL_TICKETS_TABLE") {
    wsData = [
      columns.map((item) => [item.title]),
      ...data.map((item, index) => [
        index + 1,
        item?.ielts_entrolled_student?.fullName,
        item?.ielts_entrolled_student?.dob,
        item?.ielts_entrolled_student?.gender,
        item?.ielts_entrolled_student?.email,
        item?.ielts_entrolled_student?.phoneNumber,
        item?.ielts_entrolled_student?.whatsAppNumber,
        item?.ielts_course?.CourseName,
        item?.ielts_center?.CenterName,
        item?.payment,
        item?.ielts_teacher?.teacherName,
        item?.ielts_entrolled_student?.status
      ])
    ];
  }

  if (tableName === "Employee_Managment") {
    wsData = [
      columns.map((item) => [item.title]),
      ...data.map((item, index) => [
        index + 1,
        item?.ielts_entrolled_student?.fullName,
        item?.ielts_entrolled_student?.dob,
        item?.ielts_entrolled_student?.gender,
        item?.ielts_entrolled_student?.email,
        item?.ielts_entrolled_student?.phoneNumber,
        item?.ielts_entrolled_student?.whatsAppNumber,
        item?.ielts_course?.CourseName,
        item?.ielts_center?.CenterName,
        item?.payment,
        item?.ielts_teacher?.teacherName,
        item?.ielts_entrolled_student?.status
      ])
    ];
  }

  if (tableName === "sceduledMeeting") {
    wsData = [
      columns.map((item) => [item.title]),
      ...data.map((item, index) => [
        index + 1,
        item?.name,
        item?.counsilorName,
        item?.sceduledDate,
        item?.sceduledTime,
        item?.status
      ])
    ];
  }

  if (tableName == "Role_Managment") {
    wsData = [
      columns.map((item) => [item.title]),
      ...data.map((item, index) => [
        index + 1,
        item.name,
        item.description,
        item?.bulkID,
        "admin",
        item.lastupdateby
      ])
    ];
  }

  if (tableName === "slotList") {
    wsData = [
      columns.map((item) => [item.title]),
      ...data.map((item, index) =>
        item?.map((value) => [
          index + 1,
          item.date?.split("T")[0],
          value.timeFrom + "-" + value.timeTo,
          item.status
        ])
      )
    ];
  }

  console.log(wsData, "wsDataaaaaaaaaaaaaaaaaaa");

  const ws = XLSX.utils.aoa_to_sheet(wsData);

  // Create a workbook for Excel
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");

  // Convert the workbook to a Blob object (type "blob" is assumed by default)
  const excelBlob = XLSX.write(wb, { bookType: "xlsx", type: "buffer" });

  // Download the Excel file using FileSaver.js
  saveAs(
    new Blob([excelBlob], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    }),
    `${tableName}.xlsx`
  );
};

export default DownloadExcel;
