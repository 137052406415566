import React, { useEffect, useState } from "react";
import { Box, Button, List, ListItem, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import AppliedStudentsTable from "./AppliedStudentsTable";
import { jobApplyListFindAction } from "../../../actions/HrActions/hrEmployeeManagmentAction";
import { MainContainer } from "../../content/content.element";

function JobDetailedPage(props) {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const anchor = () => {
    navigate(-1);
  };

  let { jobAppliedListSuccess } = useSelector(
    (state) => state.employeeJobApplied
  );

  let { appliedJobStatusChangeSuccess } = useSelector(
    (state) => state.appliedJobStatusChange
  );

  const [jobDetails, setDetails] = useState(false);
  const [appliedList, setAppliedList] = useState([]);

  useEffect(() => {
    dispatch(jobApplyListFindAction(id));
  }, [dispatch, appliedJobStatusChangeSuccess]);

  useEffect(() => {
    if (jobAppliedListSuccess) {
      setDetails(jobAppliedListSuccess?.data?.jobDetails);
      setAppliedList(jobAppliedListSuccess?.data?.appliedList);
    }
  }, [jobAppliedListSuccess]);

  return (
    <MainContainer active={props.toggle}>
      <Box
        sx={{
          maxWidth: "793px",
          display: "flex",
          flexDirection: "column",
          mt: {
            xs: "43px",
            // sm: "70px",
            // md: "90px",
          },
          mb: {
            xs: "20px",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: "12px",
            width: "fit-content",
            alignItems: "center",
            "& svg": {
              cursor: "pointer",
            },
          }}
          onClick={anchor}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M9.57043 18.8201C9.38043 18.8201 9.19043 18.7501 9.04043 18.6001L2.97043 12.5301C2.68043 12.2401 2.68043 11.7601 2.97043 11.4701L9.04043 5.40012C9.33043 5.11012 9.81043 5.11012 10.1004 5.40012C10.3904 5.69012 10.3904 6.17012 10.1004 6.46012L4.56043 12.0001L10.1004 17.5401C10.3904 17.8301 10.3904 18.3101 10.1004 18.6001C9.96043 18.7501 9.76043 18.8201 9.57043 18.8201Z"
              fill="#05050F"
            />
            <path
              d="M20.4999 12.75H3.66992C3.25992 12.75 2.91992 12.41 2.91992 12C2.91992 11.59 3.25992 11.25 3.66992 11.25H20.4999C20.9099 11.25 21.2499 11.59 21.2499 12C21.2499 12.41 20.9099 12.75 20.4999 12.75Z"
              fill="#05050F"
            />
          </svg>
          <Typography
            sx={{
              fontFamily: "poppins",
              fontSize: "16px",
              lineHeight: "20px",
              color: "#05050F",
              fontWeight: 700,
            }}
          >
            Back
          </Typography>
        </Box>

        <Typography
          sx={{
            fontFamily: "poppins",
            fontSize: {
              xs: "24px",
            },
            lineHeight: {
              xs: "32px",
            },
            color: "#05050F",
            fontWeight: 700,
            my: "20px",
          }}
        >
          {jobDetails.jobtitle}
        </Typography>

        <Typography
          sx={{
            fontSize: {
              xs: "18px",
            },
            lineHeight: {
              xs: "24px",
            },
            fontFamily: "poppins",
            color: "#05050F",
            fontWeight: 600,
            mb: "20px",
          }}
        >
          Job Description
        </Typography>
        <Box>
          <Typography
            sx={{
              fontSize: {
                xs: "16px",
              },
              lineHeight: {
                xs: "24px",
              },
              fontFamily: "poppins",
              color: "#05050F",
            }}
          >
            Job Location : {jobDetails.jobLocation}
          </Typography>

          <Box
            sx={{
              mt: "16px",
            }}
          >
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                maxWidth: "232px",
              }}
            >
              <Typography
                sx={{
                  textTransform: "capitalize",
                  fontSize: {
                    xs: "14px",
                  },
                  lineHeight: {
                    xs: "24px",
                  },
                  fontFamily: "poppins",
                  color: "#05050F",
                }}
              >
                Job Type
              </Typography>
              <Typography
                sx={{
                  textTransform: "capitalize",
                  fontSize: {
                    xs: "14px",
                  },
                  lineHeight: {
                    xs: "24px",
                  },
                  fontFamily: "poppins",
                  color: "#05050F",
                }}
              >
                : {" " + jobDetails.jobType}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                maxWidth: "232px",
              }}
            >
              <Typography
                sx={{
                  textTransform: "capitalize",
                  fontSize: {
                    xs: "14px",
                  },
                  lineHeight: {
                    xs: "24px",
                  },
                  fontFamily: "poppins",
                  color: "#05050F",
                }}
              >
                Experience
              </Typography>
              <Typography
                sx={{
                  textTransform: "capitalize",
                  fontSize: {
                    xs: "14px",
                  },
                  lineHeight: {
                    xs: "24px",
                  },
                  fontFamily: "poppins",
                  color: "#05050F",
                }}
              >
                : {" " + jobDetails.Experience}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                maxWidth: "232px",
              }}
            >
              <Typography
                sx={{
                  textTransform: "capitalize",
                  fontSize: {
                    xs: "14px",
                  },
                  lineHeight: {
                    xs: "24px",
                  },
                  fontFamily: "poppins",
                  color: "#05050F",
                }}
              >
                Vacancy
              </Typography>
              <Typography
                sx={{
                  textTransform: "capitalize",
                  fontSize: {
                    xs: "14px",
                  },
                  lineHeight: {
                    xs: "24px",
                  },
                  fontFamily: "poppins",
                  color: "#05050F",
                }}
              >
                : {" " + jobDetails.Vacanices} Nos
              </Typography>
            </Box>
          </Box>
        </Box>

        <div dangerouslySetInnerHTML={{ __html: jobDetails.Description }}></div>
      </Box>

      <AppliedStudentsTable
        data={appliedList.length !== 0 ? appliedList : []}
      />
    </MainContainer>
  );
}

export default JobDetailedPage;
