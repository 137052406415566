// location create  constants

export const LOCATION_CREATE_REQUEST = "LOCATION_CREATE_REQUEST";
export const LOCATION_CREATE_SUCCESS = "LOCATION_CREATE_SUCCESS";
export const LOCATION_CREATE_ERR = "LOCATION_CREATE_ERR";


// location find  constants

export const LOCATION_FIND_REQUEST = "LOCATION_FIND_REQUEST";
export const LOCATION_FIND_SUCCESS = "LOCATION_FIND_SUCCESS";
export const LOCATION_FIND_ERR = "LOCATION_FIND_ERR";


// location findOne  constants

export const LOCATION_FINDONE_REQUEST = "LOCATION_FINDONE_REQUEST";
export const LOCATION_FINDONE_SUCCESS = "LOCATION_FINDONE_SUCCESS";
export const LOCATION_FINDONE_ERR = "LOCATION_FINDONE_ERR";


// location update  constants

export const LOCATION_UPDATE_REQUEST = "LOCATION_UPDATE_REQUEST";
export const LOCATION_UPDATE_SUCCESS = "LOCATION_UPDATE_SUCCESS";
export const LOCATION_UPDATE_ERR = "LOCATION_UPDATE_ERR";


// location delete  constants

export const LOCATION_DELETE_REQUEST = "LOCATION_DELETE_REQUEST";
export const LOCATION_DELETE_SUCCESS = "LOCATION_DELETE_SUCCESS";
export const LOCATION_DELETE_ERR = "LOCATION_DELETE_ERR";