import { Box, Chip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import VideoTabItem from "./tabItems/VideoTabItem";
import AudioTabItem from "./tabItems/AudioTabItem";
import NotesTabItem from "./tabItems/NotesTabItem";
import { useDispatch, useSelector } from "react-redux";
import { ieltsCourseManagmentCourseFindByIdAction } from "../../../../../actions/IeltsActions/courseManagmentAction";
import { useParams } from "react-router-dom";
import { InfoHeader } from "../../../../customComponent/InfoHeader";

function CourseDetailedViewTabItem({ tabName }) {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [selectedItem, setSelectedItem] = useState("video");
  const [tabname, setTabName] = useState();
  const [courseDetails, setCoursDetails] = useState({});
  const [topics, setTopics] = useState([]);

  // Find Course Details

  let {
    ieltsCourseManagmentCourseFindByIdSuccess,
    ieltsCourseManagmentCourseFindByIderror,
  } = useSelector((state) => {
    return state.ieltsCourseManagmentCourseFindById;
  });

  useEffect(() => {
    dispatch(ieltsCourseManagmentCourseFindByIdAction(id, tabName));
    setTabName(tabName);
  }, [dispatch, tabname]);

  // console.log(
  //   ieltsCourseManagmentCourseFindByIdSuccess,
  //   "ieltsCourseManagmentCourseFindByIdSuccess"
  // );

  useEffect(() => {
    if (ieltsCourseManagmentCourseFindByIdSuccess) {
      setCoursDetails(ieltsCourseManagmentCourseFindByIdSuccess.data);
    }
  }, [ieltsCourseManagmentCourseFindByIdSuccess]);

  useEffect(() => {
    setTopics(
      courseDetails?.TopicData?.filter((value) => value.type === selectedItem)
    );
  }, [ieltsCourseManagmentCourseFindByIdSuccess, selectedItem]);

  console.log(courseDetails, "courseDetailssss"); 
  // console.log(selectedItem, "okooknnn");
  // console.log(topics, "topicssssssss");

  return (
    <>
      <Box
        sx={{
          mb: "50px",
        }}
      >
        <Box sx={{ display: "flex", gap: "10px", mb: "24px" }}>
          {["video", "audio", "note"].map((item) => (
            <Chip
              sx={{
                borderRadius: "4px",
                textTransform:"capitalize",
                backgroundColor:
                  selectedItem === item
                    ? "rgba(254, 11, 122, 1)"
                    : "rgba(172, 177, 198, 1)",
                color: "#fff",
                "&.MuiChip-root": {
                  pl: "5px",
                  pr: "5px",
                },
              }}
              label={item}
              onClick={() => setSelectedItem(item)}
            />
          ))}
        </Box>

        {selectedItem == "video" && (
          <VideoTabItem
            topic={topics}
            demo={courseDetails?.DemoData}
            courseDetails={courseDetails.CourseDetails}
          />
        )}

        {selectedItem == "audio" && (
          <AudioTabItem
            topic={topics}
            demo={courseDetails?.DemoData}
            courseDetails={courseDetails?.CourseDetails}
          />
        )}

        {selectedItem == "note" && (
          <NotesTabItem
            topic={topics}
            demo={courseDetails?.DemoData}
            courseDetails={courseDetails.CourseDetails}
          />
        )}
        
      </Box>
    </>
  );
}

export default CourseDetailedViewTabItem;
