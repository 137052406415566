import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CircularProgress,
  Dialog,
  FormControlLabel,
  Grid,
  Paper,
  Typography
} from "@mui/material";
import { pink } from "@mui/material/colors";
import Checkbox from "@mui/material/Checkbox";
import { useDispatch, useSelector } from "react-redux";

import { useParams } from "react-router-dom";
import {
  createPrivilageAction,
  createSelectAllPermissionAction,
  findPermissionPrivilageAction,
  findRoleSubMenuAction
} from "../../../../actions/roleMangmentAction";
import { developerFindAllPermissionDetails } from "../../../../actions/developersActions";
import { Close } from "@mui/icons-material";
import AccessDeniedIcon from "../../../Leads/createLeads/AccessDeniedIcon";
import usePrivilegeCheck from "../../../../constants/customHooks/UsePrivilegeCheck";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { SwitchButton } from "../../../customComponent/Buttons";

function PermissionDetailsWithAccordian() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { privilege, hasPrivilege, isrolePermissionChangePrevilage } =
    usePrivilegeCheck();
  const [listRoleSub, setListRoleSub] = useState([]);
  const [AccordianData, setAccordianData] = useState([]);
  const [listPermission, setListPermission] = useState([]);
  const [listPrivilage, setListPrivilage] = useState([]);
  const [openAlert, setOpenAlert] = useState(false);
  const [index, setIndex] = useState(0);
  const [isSelecteAll, setIsSelecteAll] = useState([]);

  let { findRoleSubSuccessSuccess, findRoleSubSuccessLoading } = useSelector(
    (state) => {
      return state.findRoleSubMenu;
    }
  );

  const {
    findAllPermissionsDetailsInDeveloperTabSuccess,
    findAllPermissionsDetailsInDeveloperTabLoading
  } = useSelector((state) => {
    return state.findAllPermissionsDetailsInDeveloperTab;
  });

  const { createPrivilageSuccess } = useSelector((state) => {
    return state.createPrivilage;
  });

  const { findPrivilageSuccess, findPrivilageLoading } = useSelector(
    (state) => {
      return state.findPermissionPrivilage;
    }
  );

  useEffect(() => {
    dispatch(findRoleSubMenuAction(id));
  }, [dispatch]);

  useEffect(() => {
    dispatch(findPermissionPrivilageAction(id));
  }, [dispatch]);

  // all permission find
  useEffect(() => {
    dispatch(developerFindAllPermissionDetails());
  }, [dispatch]);

  useEffect(() => {
    if (findRoleSubSuccessSuccess) {
      const accordianData = groupBySideMenuName(
        findRoleSubSuccessSuccess?.data
      );
      setListRoleSub(findRoleSubSuccessSuccess?.data);
      setAccordianData(accordianData);
    }
  }, [findRoleSubSuccessSuccess]);

  useEffect(() => {
    if (findPrivilageSuccess) {
      setListPrivilage(findPrivilageSuccess);
    }
    if (findAllPermissionsDetailsInDeveloperTabSuccess) {
      setListPermission(findAllPermissionsDetailsInDeveloperTabSuccess);
    }
  }, [findPrivilageSuccess, findAllPermissionsDetailsInDeveloperTabSuccess]);

  // const handlePermissionClickCheckBox = (e, permissionId, subId) => {
  //   dispatch(createPrivilageAction(e.target.checked, permissionId, subId, id));
  // };

  const handlePermissionClickCheckBox = (e, permissionId, subId) => {
    const isChecked = e.target.checked;
    const updatedListPrivilage = listPrivilage.map((data) =>
      data.permissionId === permissionId && data.subId === subId
        ? { ...data, isChecked }
        : data
    );
    setListPrivilage(updatedListPrivilage);
    dispatch(createPrivilageAction(isChecked, permissionId, subId, id));
  };

  function groupBySideMenuName(data) {
    const groupedData = [];
    const groupedItems = new Map();
    for (const item of data) {
      const sideMenuName = item?.side_menu?.name;
      if (!groupedItems.has(sideMenuName)) {
        groupedItems.set(sideMenuName, []);
      }
      groupedItems.get(sideMenuName).push(item);
    }

    for (const [sideMenuName, items] of groupedItems.entries()) {
      groupedData.push({
        side_menu: { name: sideMenuName },
        data: items
      });
    }

    return groupedData;
  }

  // Function for making firstletter caps

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  // Toggle button handle

  const handleSwitchChange = (index, subMenuId) => {
    console.log(index, "indexindexindexindexindexindex");
    const newSwitchStates = [...isSelecteAll];
    newSwitchStates[index] = !newSwitchStates[index];
    setIsSelecteAll(newSwitchStates);
    console.log(subMenuId, "subMenuIdsubMenuIdsubMenuId");
    dispatch(
      createSelectAllPermissionAction(newSwitchStates[index], subMenuId, id)
    );
  };

  console.log(AccordianData, "AccordianDataAccordianDataAccordianData");
  console.log(listPrivilage, "listPrivilagelistPrivilagelistPrivilage");

  return (
    <>
      {!findRoleSubSuccessLoading &&
      !findAllPermissionsDetailsInDeveloperTabLoading ? (
        <Box
          sx={{
            position: "relative"
          }}
        >
          {!isrolePermissionChangePrevilage("role managment") && (
            <Box
              sx={{
                position: "absolute",
                right: "0",
                top: "0",
                left: "0",
                bottom: "0",
                // background:"red",
                zIndex: "9998"
              }}
              onClick={() => setOpenAlert(true)}
            ></Box>
          )}

          {AccordianData?.map((item, i) => (
            <Accordion
              // expanded={i === index ? true : false}
              onChange={() => setIndex(i)}
              sx={{
                "&.MuiAccordion-root": {
                  background: "#ECECEC",
                  mb: "0 !important"
                },
                "& .MuiAccordionSummary-root": {
                  minHeight: "40px"
                },
                "& .MuiAccordionSummary-content": {
                  margin: "10px 0px"
                }
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <Typography
                  sx={{
                    fontSize: "16px",
                    lineHeight: "24px",
                    color: "#141E3C",
                    fontWeight: "700",
                    textTransform: "uppercase"
                  }}
                >
                  {item?.side_menu?.name}
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  background: "#ECECEC",
                  borderTop: "1px solid #141E3C",
                  padding: "24px",
                  display: "grid",
                  gap: "20px"
                }}
              >
                {item?.data?.map((accItems, index) => (
                  <Box
                    sx={{
                      borderRadius: "8px",
                      overflow: "hidden"
                    }}
                  >
                    <Box
                      sx={{
                        backgroundColor: "#141E3C",
                        padding: "8px 15px",
                        fontSize: "16px",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center"
                      }}
                    >
                      <Typography
                        sx={{
                          backgroundColor: "#141E3C",
                          padding: "8px 15px",
                          fontSize: "14px",
                          fontWeight: "600",
                          color: "#FFFFFF"
                        }}
                      >
                        {accItems?.sub_menu?.name
                          ? capitalizeFirstLetter(accItems?.sub_menu?.name)
                          : ""}
                      </Typography>
                      <SwitchButton
                        checked={isSelecteAll[index]}
                        handleChange={() =>
                          handleSwitchChange(index, accItems?.subId)
                        }
                        color={pink[600]}
                      />
                    </Box>
                    <Box
                      sx={{
                        padding: "24px",
                        backgroundColor: "#FFF",
                        display: "grid",
                        gridTemplateColumns:
                          "repeat(auto-fit,minmax(200px, 1fr))"
                      }}
                    >
                      {accItems.sub_menu?.sub_menu_permissions?.map((value) => {
                        return findPrivilageSuccess?.some(
                          (data) =>
                            data.permissionId == value?.permission?.id &&
                            data.subId == accItems.subId
                        ) ? (
                          <Grid item xs={5}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  // {...label}
                                  defaultChecked
                                  sx={{
                                    color: pink[800],
                                    "&.Mui-checked": {
                                      color: pink[600]
                                    }
                                  }}
                                />
                              }
                              label={
                                value?.permission?.name
                                  ? capitalizeFirstLetter(
                                      value?.permission?.name
                                    )
                                  : ""
                              }
                              onChange={(e) =>
                                handlePermissionClickCheckBox(
                                  e,
                                  value?.permission?.id,
                                  accItems?.subId
                                )
                              }
                            />
                          </Grid>
                        ) : (
                          <Grid item xs={5}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    findPrivilageSuccess?.some(
                                      (data) =>
                                        data.permissionId ===
                                          value.permission?.id &&
                                        data.subId === accItems.subId
                                    )?.isChecked
                                  }
                                  onChange={(e) =>
                                    handlePermissionClickCheckBox(
                                      e,
                                      value.permission?.id,
                                      accItems.subId
                                    )
                                  }
                                  sx={{
                                    color: pink[800],
                                    "&.Mui-checked": {
                                      color: pink[600]
                                    }
                                  }}
                                />
                              }
                              label={
                                value?.permission?.name
                                  ? capitalizeFirstLetter(
                                      value?.permission?.name
                                    )
                                  : ""
                              }
                              // onChange={(e) =>
                              //   handlePermissionClickCheckBox(
                              //     e,
                              //     value?.permission?.id,
                              //     accItems?.subId
                              //   )
                              // }
                            />
                          </Grid>
                        );
                      })}
                    </Box>
                  </Box>
                ))}
              </AccordionDetails>
            </Accordion>
          ))}

          <Dialog
            open={openAlert}
            onClose={() => setOpenAlert(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{
              zIndex: "9999",
              // background:"red",
              "& .MuiDialog-paper": {
                width: "100%",
                borderRadius: "5px",
                p: "15px",
                textAlign: "center"
              }
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "end"
              }}
            >
              <Close
                sx={{
                  cursor: "pointer"
                }}
                onClick={() => setOpenAlert(false)}
              />
            </Box>
            <Typography
              sx={{
                color: "#05050F",
                fontSize: "26px",
                fontWeight: "700"
              }}
            >
              Access Denied
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                mt: "-10px"
              }}
            >
              <AccessDeniedIcon />
            </Box>
            <Typography
              sx={{
                color: "#717171",
                fontSize: "16px",
                mb: "10px"
              }}
            >
              Sorry, you don’t have permission
            </Typography>
          </Dialog>
        </Box>
      ) : (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <CircularProgress variant="outlined" />
        </Box>
      )}
    </>
  );
}

export default PermissionDetailsWithAccordian;
