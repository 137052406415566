import React, { useEffect, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { Chat } from "./Chat.js";
import { useNavigate } from "react-router-dom";
import { MainContainer } from "../../content/content.element.js";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

export const ChatScreen = (props) => {
  const naviagte = useNavigate()
  const [loading, setLoading] = useState(true);


  useEffect(() => {
    const delay = 1000;

    const timer = setTimeout(() => {
      setLoading(false);
    }, delay);

    return () => clearTimeout(timer);
  }, []);

  return (
    <MainContainer active={props.toggle}>
      {/* {loading && <Loader />} */}
      <Box sx={{ display: "flex", width: "calc(100% - 50px)" }}>
        <Box component="main" sx={{ flexGrow: 1 }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              py: "24px",
              borderBottom: "1px solid #ECECEC",
              mb: "42px",
            }}
          >
            <ArrowBackIcon
              onClick={() => naviagte(-1)}
              sx={{
                cursor: "pointer",
              }}
            />
            <Typography
              sx={{
                color: "#05050F",
                fontSize: "24px",
                lineHeight: "24px",
                fontWeight: "700",
              }}
            >
              Chat
            </Typography>
          </Box>
          <Grid container ml={1}>
            {/* <Box
              sx={{
                display: "flex",
                alignItems: "start",
                justifyContent: "center",
                flexDirection: { xs: "column", lg: "row" },
                width: "100%",
              }}
            >
              <OpenTickets />

              <Chat />
            </Box> */}

            {/* {!response && ( */}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "start",
                  justifyContent: "center",
                  flexDirection: { xs: "column", lg: "row" },
                  width: "100%",
                }}
              >
                <Chat />
              </Box>
            {/* )} */}
{/* 
            {response && (
              <Box
                sx={{
                  width: "100%",
                  height: "500px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Box
                  sx={{
                    "& img": {
                      width: "100%",
                      maxWidth: "600px",
                      margin: "auto",
                      display: "block",
                      mb: "30px",
                    },
                  }}
                >
                  <img src="/images/counsilorAssigned.png" />
                  <Typography
                    sx={{
                      textAlign: "center",
                      color: "#141E3C",
                      fontFamily: "'Poppins', sans-serif",
                      fontWeight: 700,
                      fontSize: "20px",
                      lineHeight: "31px",
                    }}
                  >
                    You are yet to be assigned to a counselor
                  </Typography>
                  <Typography
                    sx={{
                      textAlign: "center",
                      color: "#777AAE",
                      fontFamily: "'Poppins', sans-serif",
                      fontWeight: 400,
                      fontSize: "12px",
                      lineHeight: "24px",
                    }}
                  >
                    Chat access will be available once this assignment is
                    complete. Your patience is appreciated. For urgent matters,
                    kindly reach out to our administrative team.
                  </Typography>
                </Box>
              </Box>
            )} */}
          </Grid>
        </Box>
      </Box>
    </MainContainer>
  );
};
