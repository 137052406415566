import { Box, Button, Grid, Paper, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { forgotPassSentOTPAction } from "../../../../actions/adminAuthAction";
import { SubmitButton } from "../../../customComponent/Buttons";

function ForgotPass({ loginHandler }) {
  const dispatch = useDispatch();

  const {
    forgotPassSentOTPSuccess,
    forgotPassSentOTPLoading,
    forgotPassSentOTPerror,
  } = useSelector((state) => {
    return state.forgotPassSentOTP;
  });

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();

    if (email === "") {
      setEmailError(true);
    }

    if (email !== "") {
      event.preventDefault();
      dispatch(forgotPassSentOTPAction(email));
    }
  };

  // onchange email handle
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
    setEmailError(false);
  };
  return (
    <Grid item xs={12} sm={8} md={6} square sx={{ width: "100%" }}>
      <Box
        sx={{
          my: 8,
          mx: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
        }}
      >
        {forgotPassSentOTPerror && (
          <Typography
            style={{
              fontWeight: 90,
              fontSize: "20px",
              fontFamily: "'Open Sans', sans-serif",
              lineHeight: "52px",
              color: "red",
            }}
          >
            {forgotPassSentOTPerror}
          </Typography>
        )}
        <Typography
          component="h2"
          variant="h2"
          style={{
            fontWeight: 550,
            fontSize: "24px",
            fontFamily: "'Poppins', sans-serif",
            lineHeight: "52px",
            color: "#141E3C",
          }}
        >
          Forgot Password?
        </Typography>

        <Typography
          component="h6"
          variant="h6"
          style={{
            width: "425px",
            height: "24px",
            left: "40px",
            top: "101px",
            fontStyle: "normal",
            fontFamily: "'Poppins', sans-serif",
            fontWeight: 400,
            fontSize: "15px",
            lineHeight: "150%",

            color: "#777AAE",
          }}
        >
          Get OTP to GSL account using your email
        </Typography>
        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
          <Typography
            component="h6"
            variant="h6"
            style={{
              width: "425px",
              height: "24px",
              fontStyle: "normal",
              fontWeight: 700,
              fontSize: "16px",
              lineHeight: "150%",
              fontFamily: "'Poppins', sans-serif",
              color: "rgba(119, 122, 174, 1)",
            }}
          >
            Email
          </Typography>
          <TextField
            margin="normal"
            required
            fullWidth
            // id="email"
            // label="Email Address"
            name="email"
            autoComplete="email"
            onChange={handleEmailChange}
            error={emailError}
            helperText={emailError ? "Please enter your email" : ""}
            autoFocus
            sx={{
              "& .MuiInputBase-input": {
                borderRadius: "20px",
                height: "23px",
                backgroundColor: "white",
              },
              "& .MuiInputBase-root": {
                borderWidth: "1px",
                borderRadius: "12px",
                borderColor: "#fcfcfc",
                border: "1px solid #fcfcfc",
                "&:hover": {
                  borderColor: "#fcfcfc",
                },
                "&.Mui-focused": {
                  borderColor: "#fcfcfc",
                },
              },
            }}
          />

          <Grid container>
            <Link
              // href="#"
              variant="body2"
              style={{
                color: "#FF1B6F",
                textDecoration: "none",
                fontFamily: "Poppins",
                marginLeft: "auto",
                marginRight: "auto",
                paddingTop: "90px",
              }}
              onClick={loginHandler}
            >
              Login
            </Link>

            {/* <Button
              className="button"
              type="submit"
              fullWidth
              size="large"
              sx={{
                mt: 3,
                mb: 2,
                fontWeight: "700",
                backgroundColor: "#141E3C",
                color: "#fff",
              }}
            >
              Get OTP
            </Button> */}
            <Grid container mt="30px">
              <SubmitButton
                title="Get OTP"
                submit="submit"
                widthSize="100%"
                heightSize="58px"
                type="submit"
                handleSubmit=""
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Grid>
  );
}

export default ForgotPass;
