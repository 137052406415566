import React from "react";
import { MainContainer } from "../../content/content.element";
import { InfoHeader } from "../../customComponent/InfoHeader";
import { Box, Typography } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ieltsTeacherDashBoardFindaction } from "../../../actions/IeltsActions/teacherDashboardAction";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

function IeltsTeacherDashBoardHome({ toggle }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [assignedstudentsCount, setAssignedStudentsCount] = useState("");
  const [cousesCount, setCoursesCount] = useState("");
  const [liveClassCount, setLiveClassCount] = useState("");

  let isUserExist = localStorage.getItem("loginInfo")
    ? JSON.parse(localStorage.getItem("loginInfo"))
    : null;

  let {
    ieltsTeacherDashBoardFindSuccess,
    ieltsTeacherDashBoardFinderror,
  } = useSelector((state) => {
    return state.ieltsTeacherDashboardFind;
  });

  useEffect(() => {
    dispatch(ieltsTeacherDashBoardFindaction(isUserExist.UserId));
  }, [dispatch]);

  useEffect(() => {
    if (ieltsTeacherDashBoardFindSuccess) {
      setAssignedStudentsCount(
        ieltsTeacherDashBoardFindSuccess?.data?.AssignedStudents?.length
      );
      setCoursesCount(ieltsTeacherDashBoardFindSuccess?.data?.courses?.length);
      setLiveClassCount(
        ieltsTeacherDashBoardFindSuccess?.data?.liveClass?.length
      );
    }
  }, [ieltsTeacherDashBoardFindSuccess]);

  console.log(
    ieltsTeacherDashBoardFindSuccess,
    "ieltsTeacherDashBoardFindSuccessieltsTeacherDashBoardFindSuccess"
  );

  const handleNavigation = (url) => {
    navigate(url);
  };
  return (
    <MainContainer active={toggle}>
      <Box
        sx={{
          display: "grid",
          mt: "32px",
          gridTemplateColumns: "2fr 1fr",
          gap: "20px",
        }}
      >
        <Box>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr 1fr",
              gap: "20px",
            }}
          >
            <Box
              sx={{
                borderRadius: "14px",
                backgroundColor: "#141E3C",
                boxShadow: "0px 5.779px 28.893px 0px rgba(34, 148, 175, 0.25)",
                padding: "26px",
                cursor: "pointer",
              }}
              onClick={() =>
                handleNavigation("/ielts/teacherdashboard/assignedstudents/")
              }
            >
              <Box>
                <Typography
                  sx={{
                    pb: "20px",
                    borderBottom: "1px solid rgba(255,255,255,0.5)",
                    color: "#fff",
                    fontWeight: "700",
                    fontSize: "20px",
                  }}
                >
                  Assigned students
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  gap: "20px",
                  alignItems: "center",
                  mt: "20px",
                }}
              >
                <svg
                  width="29"
                  height="29"
                  viewBox="0 0 29 29"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_7522_56337)">
                    <path
                      d="M6.72496 28.706H22.9773C24.3216 28.706 25.4151 27.6125 25.4151 26.2682V9.09491H19.7268C18.3825 9.09491 17.289 8.00134 17.289 6.65706V0.96875H6.72496C5.38067 0.96875 4.28711 2.06231 4.28711 3.4066V26.2682C4.28711 27.6125 5.38067 28.706 6.72496 28.706ZM9.97542 12.3995H19.7268C20.176 12.3995 20.5394 12.763 20.5394 13.2122C20.5394 13.6613 20.176 14.0248 19.7268 14.0248H9.97542C9.52626 14.0248 9.1628 13.6613 9.1628 13.2122C9.1628 12.763 9.52626 12.3995 9.97542 12.3995ZM9.97542 15.65H19.7268C20.176 15.65 20.5394 16.0135 20.5394 16.4626C20.5394 16.9118 20.176 17.2752 19.7268 17.2752H9.97542C9.52626 17.2752 9.1628 16.9118 9.1628 16.4626C9.1628 16.0135 9.52626 15.65 9.97542 15.65ZM9.97542 18.9005H19.7268C20.176 18.9005 20.5394 19.2639 20.5394 19.7131C20.5394 20.1623 20.176 20.5257 19.7268 20.5257H9.97542C9.52626 20.5257 9.1628 20.1623 9.1628 19.7131C9.1628 19.2639 9.52626 18.9005 9.97542 18.9005ZM9.97542 22.1509H16.4763C16.9255 22.1509 17.289 22.5144 17.289 22.9636C17.289 23.4127 16.9255 23.7762 16.4763 23.7762H9.97542C9.52626 23.7762 9.1628 23.4127 9.1628 22.9636C9.1628 22.5144 9.52626 22.1509 9.97542 22.1509Z"
                      fill="white"
                    />
                    <path
                      d="M19.7267 7.4701H24.9389L18.9141 1.44531V6.65749C18.9141 7.10583 19.2783 7.4701 19.7267 7.4701Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_7522_56337">
                      <rect
                        width="27.7373"
                        height="27.7373"
                        fill="white"
                        transform="translate(0.982422 0.96875)"
                      />
                    </clipPath>
                  </defs>
                </svg>
                <Typography
                  sx={{
                    fontSize: "30px",
                    fontWeight: "600",
                    color: "#fff",
                  }}
                >
                  {assignedstudentsCount}
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                borderRadius: "14px",
                backgroundColor: "#141E3C",
                boxShadow: "0px 5.779px 28.893px 0px rgba(34, 148, 175, 0.25)",
                padding: "26px",
              }}
            >
              <Box>
                <Typography
                  sx={{
                    pb: "20px",
                    borderBottom: "1px solid rgba(255,255,255,0.5)",
                    color: "#fff",
                    fontWeight: "700",
                    fontSize: "20px",
                  }}
                >
                  Courses
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  gap: "20px",
                  alignItems: "center",
                  mt: "20px",
                }}
              >
                <svg
                  width="29"
                  height="29"
                  viewBox="0 0 29 29"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_7522_56337)">
                    <path
                      d="M6.72496 28.706H22.9773C24.3216 28.706 25.4151 27.6125 25.4151 26.2682V9.09491H19.7268C18.3825 9.09491 17.289 8.00134 17.289 6.65706V0.96875H6.72496C5.38067 0.96875 4.28711 2.06231 4.28711 3.4066V26.2682C4.28711 27.6125 5.38067 28.706 6.72496 28.706ZM9.97542 12.3995H19.7268C20.176 12.3995 20.5394 12.763 20.5394 13.2122C20.5394 13.6613 20.176 14.0248 19.7268 14.0248H9.97542C9.52626 14.0248 9.1628 13.6613 9.1628 13.2122C9.1628 12.763 9.52626 12.3995 9.97542 12.3995ZM9.97542 15.65H19.7268C20.176 15.65 20.5394 16.0135 20.5394 16.4626C20.5394 16.9118 20.176 17.2752 19.7268 17.2752H9.97542C9.52626 17.2752 9.1628 16.9118 9.1628 16.4626C9.1628 16.0135 9.52626 15.65 9.97542 15.65ZM9.97542 18.9005H19.7268C20.176 18.9005 20.5394 19.2639 20.5394 19.7131C20.5394 20.1623 20.176 20.5257 19.7268 20.5257H9.97542C9.52626 20.5257 9.1628 20.1623 9.1628 19.7131C9.1628 19.2639 9.52626 18.9005 9.97542 18.9005ZM9.97542 22.1509H16.4763C16.9255 22.1509 17.289 22.5144 17.289 22.9636C17.289 23.4127 16.9255 23.7762 16.4763 23.7762H9.97542C9.52626 23.7762 9.1628 23.4127 9.1628 22.9636C9.1628 22.5144 9.52626 22.1509 9.97542 22.1509Z"
                      fill="white"
                    />
                    <path
                      d="M19.7267 7.4701H24.9389L18.9141 1.44531V6.65749C18.9141 7.10583 19.2783 7.4701 19.7267 7.4701Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_7522_56337">
                      <rect
                        width="27.7373"
                        height="27.7373"
                        fill="white"
                        transform="translate(0.982422 0.96875)"
                      />
                    </clipPath>
                  </defs>
                </svg>
                <Typography
                  sx={{
                    fontSize: "30px",
                    fontWeight: "600",
                    color: "#fff",
                  }}
                >
                  {cousesCount}
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                borderRadius: "14px",
                backgroundColor: "#141E3C",
                boxShadow: "0px 5.779px 28.893px 0px rgba(34, 148, 175, 0.25)",
                padding: "26px",
              }}
              onClick={() =>
                handleNavigation("/ielts/teacherdashboard/assignedLiveClass")
              }
            >
              <Box>
                <Typography
                  sx={{
                    pb: "20px",
                    borderBottom: "1px solid rgba(255,255,255,0.5)",
                    color: "#fff",
                    fontWeight: "700",
                    fontSize: "20px",
                  }}
                >
                  Live Classes
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  gap: "20px",
                  alignItems: "center",
                  mt: "20px",
                }}
              >
                <svg
                  width="29"
                  height="29"
                  viewBox="0 0 29 29"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_7522_56337)">
                    <path
                      d="M6.72496 28.706H22.9773C24.3216 28.706 25.4151 27.6125 25.4151 26.2682V9.09491H19.7268C18.3825 9.09491 17.289 8.00134 17.289 6.65706V0.96875H6.72496C5.38067 0.96875 4.28711 2.06231 4.28711 3.4066V26.2682C4.28711 27.6125 5.38067 28.706 6.72496 28.706ZM9.97542 12.3995H19.7268C20.176 12.3995 20.5394 12.763 20.5394 13.2122C20.5394 13.6613 20.176 14.0248 19.7268 14.0248H9.97542C9.52626 14.0248 9.1628 13.6613 9.1628 13.2122C9.1628 12.763 9.52626 12.3995 9.97542 12.3995ZM9.97542 15.65H19.7268C20.176 15.65 20.5394 16.0135 20.5394 16.4626C20.5394 16.9118 20.176 17.2752 19.7268 17.2752H9.97542C9.52626 17.2752 9.1628 16.9118 9.1628 16.4626C9.1628 16.0135 9.52626 15.65 9.97542 15.65ZM9.97542 18.9005H19.7268C20.176 18.9005 20.5394 19.2639 20.5394 19.7131C20.5394 20.1623 20.176 20.5257 19.7268 20.5257H9.97542C9.52626 20.5257 9.1628 20.1623 9.1628 19.7131C9.1628 19.2639 9.52626 18.9005 9.97542 18.9005ZM9.97542 22.1509H16.4763C16.9255 22.1509 17.289 22.5144 17.289 22.9636C17.289 23.4127 16.9255 23.7762 16.4763 23.7762H9.97542C9.52626 23.7762 9.1628 23.4127 9.1628 22.9636C9.1628 22.5144 9.52626 22.1509 9.97542 22.1509Z"
                      fill="white"
                    />
                    <path
                      d="M19.7267 7.4701H24.9389L18.9141 1.44531V6.65749C18.9141 7.10583 19.2783 7.4701 19.7267 7.4701Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_7522_56337">
                      <rect
                        width="27.7373"
                        height="27.7373"
                        fill="white"
                        transform="translate(0.982422 0.96875)"
                      />
                    </clipPath>
                  </defs>
                </svg>
                <Typography
                  sx={{
                    fontSize: "30px",
                    fontWeight: "600",
                    color: "#fff",
                  }}
                >
                  {liveClassCount}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            border: "1px solid #ECECEC",
            borderRadius: "12px",
            padding: "32px",
            borderBottom: "0",
          }}
        >
          <Box
            sx={{
              pb: "32px",
              borderBottom: "1px solid rgba(132, 132, 132, 0.5)",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography
                sx={{
                  color: "#010101",
                  fontSize: "24px",
                  fontWeight: "700",
                }}
              >
                Notifications
              </Typography>
              <Typography
                sx={{
                  color: "#010101",
                  fontSize: "14px",
                  fontWeight: "500",
                  cursor: "pointer",
                }}
              >
                {" "}
                View all
              </Typography>
            </Box>
            <Typography
              sx={{
                color: "#848484",
                fontSize: "14px",
              }}
            >
              45 unread
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              mt: "12px",
            }}
          >
            {[1, 2, 3, 4, 5].map((item, i) => (
              <Box
                key={i}
                sx={{
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  gap: "20px",
                  py: "12px",
                }}
              >
                <Box
                  sx={{
                    width: "40px",
                    height: "40px",
                    borderRadius: "50%",
                    background: "rgba(1, 1, 1, 0.10)",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_7522_56372)">
                      <path
                        d="M14.2533 11.2753C13.2453 10.4233 12.6673 9.178 12.6673 7.85867V6C12.6673 3.654 10.9247 1.712 8.66732 1.38667V0.666667C8.66732 0.298 8.36865 0 8.00065 0C7.63265 0 7.33398 0.298 7.33398 0.666667V1.38667C5.07598 1.712 3.33398 3.654 3.33398 6V7.85867C3.33398 9.178 2.75598 10.4233 1.74198 11.2807C1.48265 11.5027 1.33398 11.8253 1.33398 12.1667C1.33398 12.81 1.85732 13.3333 2.50065 13.3333H13.5007C14.144 13.3333 14.6673 12.81 14.6673 12.1667C14.6673 11.8253 14.5187 11.5027 14.2533 11.2753Z"
                        fill="#010101"
                      />
                      <path
                        d="M8.00011 16C9.20745 16 10.2174 15.1393 10.4494 14H5.55078C5.78278 15.1393 6.79278 16 8.00011 16Z"
                        fill="#010101"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_7522_56372">
                        <rect width="16" height="16" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </Box>
                <Box
                  sx={{
                    opacity: i == 1 || i == 0 ? "1" : "0.5",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "14px",
                      color: "#010101",
                    }}
                  >
                    GSL-66518819 TESTKKO staus updated
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "10px",
                      color: "#848484",
                    }}
                  >
                    4 days ago
                  </Typography>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </MainContainer>
  );
}

export default IeltsTeacherDashBoardHome;
