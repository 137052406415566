// LEAD_SOURCE constants
export const LEAD_SOURCE_CREATE_REQUEST = "LEAD_SOURCE_CREATE_REQUEST";
export const LEAD_SOURCE_CREATE_SUCCESS = "LEAD_SOURCE_CREATE_SUCCESS";
export const LEAD_SOURCE_CREATE_ERR = "LEAD_SOURCE_CREATE_ERR";

export const LEAD_SOURCE_DELETE_REQUEST = "LEAD_SOURCE_DELETE_REQUEST";
export const LEAD_SOURCE_DELETE_SUCCESS = "LEAD_SOURCE_DELETE_SUCCESS";
export const LEAD_SOURCE_DELETE_ERR = "LEAD_SOURCE_DELETE_ERR";

export const LEAD_SOURCE_FIND_REQUEST = "LEAD_SOURCE_FIND_REQUEST";
export const LEAD_SOURCE_FIND_SUCCESS = "LEAD_SOURCE_FIND_SUCCESS";
export const LEAD_SOURCE_FIND_ERR = "LEAD_SOURCE_FIND_ERR";

export const LEAD_SOURCE_FIND_DETAILS_REQUEST =
  "LEAD_SOURCE_FIND_DETAILS_REQUEST";
export const LEAD_SOURCE_FIND_DETAILS_SUCCESS =
  "LEAD_SOURCE_FIND_DETAILS_SUCCESS";
export const LEAD_SOURCE_FIND_DETAILS_ERR = "LEAD_SOURCE_FIND_DETAILS_ERR";
