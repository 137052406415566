import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import Reorder from "../../HOME/HomePageComponents/Reorder";
import { useEffect } from "react";
import { useState } from "react";

function FinalChoicesSummery({ data }) {
  let [rows, setRows] = useState([]);
  function createData(name, ug, pg, research, fo, ps, preMast, other, total) {
    return {
      name,
      ug,
      pg,
      research,
      fo,
      ps,
      preMast,
      other,
      total,
    };
  }

  useEffect(() => {
    if (Object.keys(data).length > 0) {
      console.log(
        (data?.currentYearUg - data?.previousYearUg) / data?.previousYearUg,
        "datadatadatadatadatadatadatadatadatadatadata"
      );

      const previousYearUg = data?.previousYearUg || 0;
      const currentYearUg = data?.currentYearUg || 0;
      const percentageChangeUg =
        ((currentYearUg - previousYearUg) / Math.abs(previousYearUg)) * 100;

      const pgPercentage =
        ((data?.currentYearPg - data?.previousYearPg) /
          Math.abs(data?.previousYearPg)) *
          (100).toFixed(2) ==
          "Infinity" || "NaN"
          ? data?.currentYearPg
          : ((data?.currentYearPg - data?.previousYearPg) /
              Math.abs(data?.previousYearPg)) *
              (100).toFixed(2) +
            "%";

      const foPercentage =
        ((data?.currentYearFo - data?.previousYearFo) /
          Math.abs(data?.previousYearFo)) *
          (100).toFixed(2) ==
          "Infinity" || "NaN"
          ? data?.currentYearFo
          : ((data?.currentYearFo - data?.previousYearFo) /
              Math.abs(data?.previousYearFo)) *
              (100).toFixed(2) +
            "%";

      const psPercentage =
        ((data?.currentYearPs - data?.previousYearPs) /
          Math.abs(data?.previousYearPs)) *
          (100).toFixed(2) ==
          "Infinity" || "NaN"
          ? data?.currentYearPs
          : ((data?.currentYearPs - data?.previousYearPs) /
              Math.abs(data?.previousYearPs)) *
              (100).toFixed(2) +
            "%";

      const preMasterPercentage =
        ((data?.currentYearPreMaster - data?.previousYearPreMaster) /
          Math.abs(data?.previousYearPreMaster)) *
          (100).toFixed(2) ==
          "Infinity" || "NaN"
          ? data?.currentYearPreMaster
          : ((data?.currentYearPreMaster - data?.previousYearPreMaster) /
              Math.abs(data?.previousYearPreMaster)) *
              (100).toFixed(2) +
            "%";

      const previousPercentage =
        ((data?.currentYearOther - data?.previousYearOther) /
          Math.abs(data?.previousYearOther)) *
          (100).toFixed(2) ==
          "Infinity" || "NaN"
          ? data?.currentYearOther
          : ((data?.currentYearOther - data?.previousYearOther) /
              Math.abs(data?.previousYearOther)) *
              (100).toFixed(2) +
            "%";

      const totalPercentage =
        percentageChangeUg.toFixed(2) == "Infinity" || "NaN"
          ? 0
          : percentageChangeUg.toFixed(2) +
              ((data?.currentYearPg - data?.previousYearPg) /
                Math.abs(data?.previousYearPg)) *
                (100).toFixed(2) ==
              "Infinity" || "NaN"
          ? data?.currentYearPg
          : ((data?.currentYearPg - data?.previousYearPg) /
              Math.abs(data?.previousYearPg)) *
              (100).toFixed(2) +
            foPercentage +
            psPercentage +
            preMasterPercentage;

      setRows([
        createData(
          `Current Year (${
            data?.currentYear ? data?.currentYear : new Date().getFullYear()
          })`,
          data?.currentYearUg,
          data?.currentYearPg,
          data?.currentYearFo,
          data?.currentYearPs,
          data?.currentYearPreMaster,
          data?.currentYearOther,
          data?.currentYearUg +
            data?.currentYearPg +
            data?.currentYearFo +
            data?.currentYearPs +
            data?.currentYearOther
        ),
        createData(
          `Prior Year (${
            data?.previousYear ? data?.previousYear : new Date().getFullYear - 1
          })`,
          data?.previousYearUg,
          data?.previousYearPg,
          data?.previousYearFo,
          data?.previousYearPs,
          data?.previousYearPreMaster,
          data?.previousYearOther,
          data?.previousYearPg +
            data?.previousYearFo +
            data?.previousYearPs +
            data?.previousYearPreMaster +
            data?.previousYearOther
        ),

        createData(
          "% INC/DEC",
          percentageChangeUg.toFixed(2) == "Infinity" || "NaN"
            ? data?.currentYearUg
            : percentageChangeUg.toFixed(2) + "%",
          pgPercentage,
          foPercentage,
          psPercentage,
          preMasterPercentage,
          previousPercentage,
          // total
          totalPercentage
        ),
      ]);
    }
  }, [data]);

  return (
    <Box
      sx={{
        width: "100%",
        overflowX: "scroll",
        "&::-webkit-scrollbar": {
          display: "none",
        },
      }}
    >
      <Table
        sx={{
          minWidth: "1200px",
          "& .MuiTableCell-root": {
            borderBottom: "1px solid rgba(220, 223, 227, 0.50)",
          },
        }}
      >
        <TableHead
          sx={{
            background: "#F9F9FB",
            "& th": {
              textTransform: "uppercase",
              fontWeight: "600",
              fontFamily: "Inter",
              color: "#666E7D",
            },
          }}
        >
          <TableRow>
            <TableCell></TableCell>
            <TableCell>
              UG <Reorder />
            </TableCell>
            <TableCell>
              PG <Reorder />
            </TableCell>
            <TableCell>
              FO <Reorder />
            </TableCell>
            <TableCell>
              PS <Reorder />
            </TableCell>
            <TableCell>
              Pre mast <Reorder />
            </TableCell>
            <TableCell>
              other (ls,se) <Reorder />
            </TableCell>
            <TableCell>
              Total fc <Reorder />
            </TableCell>

            <TableCell>{""}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody
          sx={{
            background: "#fff",
            border: "1px solid rgba(220, 223, 227, 0.50)",
          }}
        >
          {rows.map((row, i) => (
            <TableRow key={i}>
              <TableCell>{row.name}</TableCell>
              <TableCell>{row.ug}</TableCell>
              <TableCell>{row.pg}</TableCell>
              <TableCell>{row.fo}</TableCell>
              <TableCell>{row.ps}</TableCell>
              <TableCell>{row.ug}</TableCell>
              <TableCell>{row.preMast}</TableCell>
              <TableCell>{row.other}</TableCell>
              <TableCell>{row.total}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
}

export default FinalChoicesSummery;
