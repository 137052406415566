import axios from "axios";

import {
  ADD_ALL_EMPLOYEE_FIND_ERR,
  ADD_ALL_EMPLOYEE_FIND_REQUEST,
  ADD_ALL_EMPLOYEE_FIND_SUCCESS,
  EMPLOYEE_ADD_ERR,
  EMPLOYEE_ADD_REQUEST,
  EMPLOYEE_ADD_SUCCESS,
  EMPLOYEE_CHANGE_PASSWORD_ERR,
  EMPLOYEE_CHANGE_PASSWORD_REQUEST,
  EMPLOYEE_CHANGE_PASSWORD_SUCCESS,
  EMPLOYEE_CHANGE_LEAVE_STATUS_CHANGE_REQUEST,
  EMPLOYEE_DELETE_ERR,
  EMPLOYEE_DELETE_REQUEST,
  EMPLOYEE_DELETE_SUCCESS,
  EMPLOYEE_DETAILS_EDIT_REQUEST,
  EMPLOYEE_DETAILS_SHOW_ERR,
  EMPLOYEE_DETAILS_SHOW_REQUEST,
  EMPLOYEE_DETAILS_SHOW_SUCCESS,
  EMPLOYEE_LEAVE_APPLY_ERR,
  EMPLOYEE_LEAVE_APPLY_REQUEST,
  EMPLOYEE_LEAVE_APPLY_SUCCESS,
  EMPLOYEE_LEAVE_FIND_ERR,
  EMPLOYEE_LEAVE_FIND_REQUEST,
  EMPLOYEE_LEAVE_FIND_SUCCESS,
  EMPLOYEE_CHANGE_LEAVE_STATUS_CHANGE_SUCCESS,
  EMPLOYEE_CHANGE_LEAVE_STATUS_CHANGE_ERR,
  EMPLOYEE_DELETE_LEAVE_APPLICATION_REQUEST,
  EMPLOYEE_DELETE_LEAVE_APPLICATION_SUCCESS,
  EMPLOYEE_DELETE_LEAVE_APPLICATION_ERR,
  BRANCH_MANAGER_FETCH_REQUEST,
  BRANCH_MANAGER_FETCH_SUCCESS,
  BRANCH_MANAGER_FETCH_ERR,
  EMPLOYEE_PRIVILEGE_CHECK_REQUEST,
  EMPLOYEE_PRIVILEGE_CHECK_SUCCESS,
  EMPLOYEE_PRIVILEGE_CHECK_ERR,
  MATCHED_ROLE_EMPLOYEE_FETCH_REQUEST,
  MATCHED_ROLE_EMPLOYEE_FETCH_SUCCESS,
  MATCHED_ROLE_EMPLOYEE_FETCH_ERR,
  NOTIFICATION_LIST_FIND_REQUEST,
  NOTIFICATION_LIST_FIND_SUCCESS,
  NOTIFICATION_LIST_FIND_ERR,
  EMPLOYEE_SALARY_SLIP_FIND_REQUEST,
  EMPLOYEE_SALARY_SLIP_FIND_SUCCESS,
  EMPLOYEE_SALARY_SLIP_FIND_ERR,
  EMPLOYEE_DETAILS_EDIT_SUCCESS,
  EMPLOYEE_DETAILS_EDIT_ERR,
  EMPLOYEE_BULKUPLOAD_REQUEST,
  EMPLOYEE_BULKUPLOAD_SUCCESS,
  EMPLOYEE_BULKUPLOAD_ERR,
  EMPLOYEE_STATUS_UPDATE_REQUEST,
  EMPLOYEE_STATUS_UPDATE_SUCCESS,
  EMPLOYEE_STATUS_UPDATE_ERR
} from "../constants/employeeManagmentConstant";
import {
  SUCCESS_TRUE_MSG_ERR,
  SUCCESS_TRUE_MSG_SUCCESS
} from "../constants/roleManagmentConstant";

export const addEmployeeAction =
  (
    avatar,
    name,
    phone,
    email,
    role,
    adharNumber,
    panNumber,
    password,
    cpassword,
    branch,
    location,
    isTeamLead,
    teamLeadId,
    isTeleCaller,
    isManager,
    isLead_B_to_C,
    isUniversityHandler,
    isApplicationHandler,
    teamLeadaddBtoCid,
    countryCode
  ) =>
  async (dispatch, getState) => {
    try {
      let isUserExist = localStorage.getItem("loginInfo")
        ? JSON.parse(localStorage.getItem("loginInfo"))
        : null;

      const config = {
        headers: {
          Authorization: `Bearer ${isUserExist?.token}`
        }
      };

      dispatch({ type: EMPLOYEE_ADD_REQUEST });

      let { data } = await axios.post(
        "/api/admin/employee/addEmployee",
        {
          avatar,
          name,
          phone,
          email,
          role,
          adharNumber,
          panNumber,
          password,
          cpassword,
          branch,
          location,
          isTeamLead,
          teamLeadId,
          isTeleCaller,
          isManager,
          isLead_B_to_C,
          isUniversityHandler,
          isApplicationHandler,
          teamLeadaddBtoCid,
          countryCode
        },
        config
      );

      dispatch({ type: EMPLOYEE_ADD_SUCCESS, payload: data });
      dispatch({ type: SUCCESS_TRUE_MSG_SUCCESS, payload: true });
    } catch (error) {
      dispatch({ type: EMPLOYEE_ADD_ERR, payload: error.response.data });
      dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: true });
    }
  };

// checkEmployeePrivilegeAction
export const checkEmployeePrivilegeAction =
  () => async (dispatch, getState) => {
    try {
      let isUserExist = localStorage.getItem("loginInfo")
        ? JSON.parse(localStorage.getItem("loginInfo"))
        : null;

      const config = {
        headers: {
          Authorization: `Bearer ${isUserExist?.token}`
        }
      };
      let id = isUserExist?.UserId;

      dispatch({ type: EMPLOYEE_PRIVILEGE_CHECK_REQUEST });

      let { data } = await axios.get(
        `/api/admin/employee/checkEmployeePrivilege?id=${id}`,
        config
      );

      dispatch({ type: EMPLOYEE_PRIVILEGE_CHECK_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: EMPLOYEE_PRIVILEGE_CHECK_ERR,
        payload: error.response.data
      });
    }
  };

// findAllEmployeeAction
export const findAllEmployeeAction = () => async (dispatch, getState) => {
  try {
    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`
      }
    };

    dispatch({ type: ADD_ALL_EMPLOYEE_FIND_REQUEST });

    let { data } = await axios.get(
      "/api/admin/employee/FindEmployee",

      config
    );

    dispatch({ type: ADD_ALL_EMPLOYEE_FIND_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: ADD_ALL_EMPLOYEE_FIND_ERR, payload: error.response.data });
  }
};

// findAllEmployeeAction
export const updateEmployeeStatusAction =
  (id, status) => async (dispatch, getState) => {
    try {
      let isUserExist = localStorage.getItem("loginInfo")
        ? JSON.parse(localStorage.getItem("loginInfo"))
        : null;

      const config = {
        headers: {
          Authorization: `Bearer ${isUserExist?.token}`
        }
      };

      dispatch({ type: EMPLOYEE_STATUS_UPDATE_REQUEST });

      let { data } = await axios.get(
        `/api/admin/employee/updateEmployee?id=${id}&status=${status}`,
        config
      );

      dispatch({ type: EMPLOYEE_STATUS_UPDATE_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: EMPLOYEE_STATUS_UPDATE_ERR,
        payload: error.response.data
      });
    }
  };

// deleteEmployeeAction
export const deleteEmployeeAction = (delData) => async (dispatch, getState) => {
  try {
    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`
      }
    };

    let tableDataIds;
    if (typeof delData !== "number") {
      tableDataIds = delData.map((user) => user.tableData.id);
    } else {
      tableDataIds = delData;
    }
    // delData = JSON.stringify(delData);
    // delData = JSON.parse(delData);

    dispatch({ type: EMPLOYEE_DELETE_REQUEST });

    let { data } = await axios.post(
      `/api/admin/employee/deleteEmployee`,
      { tableDataIds },
      config
    );

    dispatch({ type: EMPLOYEE_DELETE_SUCCESS, payload: data });
  } catch (error) {
    console.log(error, "sdjfhaskjhdfkasjhdkjh");
    dispatch({ type: EMPLOYEE_DELETE_ERR, payload: error.response });
  }
};

// employeedetailsShowAction

export const employeedetailsShowAction = (id) => async (dispatch, getState) => {
  try {
    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`
      }
    };

    dispatch({ type: EMPLOYEE_DETAILS_SHOW_REQUEST });

    let { data } = await axios.get(
      `/api/admin/employee/employeedetailsShow?id=${id}`,
      config
    );

    dispatch({ type: EMPLOYEE_DETAILS_SHOW_SUCCESS, payload: data });
  } catch (error) {
    console.log(error, "sdjfhaskjhdfkasjhdkjh");
    dispatch({ type: EMPLOYEE_DETAILS_SHOW_ERR, payload: error.response });
  }
};

// editedEmployeeDataAction
export const editedEmployeeDataAction =
  (
    id,
    avatarFile,
    name,
    phone,
    email,
    roleId,
    adharNumber,
    panNumber,
    location,
    branch,
    teamLeadId,
    isTeamLead,
    isTeleCaller,
    isManager,
    isLead_B_to_C,
    isUniversityHandler,
    isApplicationHandler,
    teamLeadBtoCid,
    countryCode
  ) =>
  async (dispatch, getState) => {
    try {
      let isUserExist = localStorage.getItem("loginInfo")
        ? JSON.parse(localStorage.getItem("loginInfo"))
        : null;

      const config = {
        headers: {
          Authorization: `Bearer ${isUserExist?.token}`
        }
      };

      dispatch({ type: EMPLOYEE_DETAILS_EDIT_REQUEST });

      let { data } = await axios.put(
        `/api/admin/employee/editEmployee`,
        {
          id,
          avatarFile,
          name,
          phone,
          email,
          roleId,
          adharNumber,
          panNumber,
          location,
          branch,
          teamLeadId,
          isTeamLead,
          isTeleCaller,
          isManager,
          isLead_B_to_C,
          isUniversityHandler,
          isApplicationHandler,
          teamLeadBtoCid,
          countryCode

        },
        config
      );
      dispatch({ type: EMPLOYEE_DETAILS_EDIT_SUCCESS, payload: data });
    } catch (error) {
      console.log(error, "sdjfhaskjhdfkasjhdkjh");
      dispatch({
        type: EMPLOYEE_DETAILS_EDIT_ERR,
        payload: error.response
      });
    }
  };

// leaveApplyAction
export const leaveApplyAction =
  (
    id,
    employeeId,
    mailId,
    leaveType,
    isFullDay,
    dateFrom,
    dateTo,
    leaveReason
  ) =>
  async (dispatch, getState) => {
    try {
      let isUserExist = localStorage.getItem("loginInfo")
        ? JSON.parse(localStorage.getItem("loginInfo"))
        : null;

      const config = {
        headers: {
          Authorization: `Bearer ${isUserExist?.token}`
        }
      };

      dispatch({ type: EMPLOYEE_LEAVE_APPLY_REQUEST });

      let { data } = await axios.post(
        "/api/admin/employee/applyLeave",
        {
          id,
          employeeId,
          mailId,
          leaveType,
          isFullDay: isFullDay,
          dateFrom,
          dateTo,
          leaveReason
        },
        config
      );

      dispatch({ type: EMPLOYEE_LEAVE_APPLY_SUCCESS, payload: data });
      dispatch({ type: SUCCESS_TRUE_MSG_SUCCESS, payload: true });
    } catch (error) {
      console.log(error, "sdjfhaskjhdfkasjhdkjh");
      dispatch({
        type: EMPLOYEE_LEAVE_APPLY_ERR,
        payload: error.response
      });
    }
  };

// findLeave
export const findLeaveAction = (id) => async (dispatch, getState) => {
  try {
    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`
      }
    };

    dispatch({ type: EMPLOYEE_LEAVE_FIND_REQUEST });

    let { data } = await axios.get(
      `/api/admin/employee/findLeave?id=${id}`,
      config
    );

    dispatch({ type: EMPLOYEE_LEAVE_FIND_SUCCESS, payload: data });
  } catch (error) {
    console.log(error, "sdjfhaskjhdfkasjhdkjh");
    dispatch({
      type: EMPLOYEE_LEAVE_FIND_ERR,
      payload: error.response
    });
  }
};

// EMPLOYEE SALARY SLIP ACTION

// findEmployeeSalaryAction
export const findEmployeeSalaryPaySlipAction =
  (id) => async (dispatch, getState) => {
    try {
      let isUserExist = localStorage.getItem("loginInfo")
        ? JSON.parse(localStorage.getItem("loginInfo"))
        : null;

      const config = {
        headers: {
          Authorization: `Bearer ${isUserExist?.token}`
        }
      };

      dispatch({ type: EMPLOYEE_SALARY_SLIP_FIND_REQUEST });

      let { data } = await axios.get(
        `/api/admin/employee/findEmployeeSalary?id=${id}`,
        config
      );

      dispatch({ type: EMPLOYEE_SALARY_SLIP_FIND_SUCCESS, payload: data });
    } catch (error) {
      console.log(error, "ERRORPFTXBJHJHGJGGYD");
      dispatch({
        type: EMPLOYEE_SALARY_SLIP_FIND_ERR,
        payload: error.response
      });
    }
  };

// changePasswordAction
export const changePasswordAction =
  (newPassword, confirmPassword, id) =>
  async (dispatch, getState) => {
    try {
      let isUserExist = localStorage.getItem("loginInfo")
        ? JSON.parse(localStorage.getItem("loginInfo"))
        : null;

      const config = {
        headers: {
          Authorization: `Bearer ${isUserExist?.token}`
        }
      };

      dispatch({ type: EMPLOYEE_CHANGE_PASSWORD_REQUEST });

      let { data } = await axios.post(
        "/api/admin/employee/changePass",
        { newPassword, confirmPassword, id },
        config
      );

      dispatch({ type: EMPLOYEE_CHANGE_PASSWORD_SUCCESS, payload: data });
      dispatch({ type: SUCCESS_TRUE_MSG_SUCCESS, payload: true });
    } catch (error) {
      console.log(error, "sdjfhaskjhdfkasjhdkjh");
      dispatch({
        type: EMPLOYEE_CHANGE_PASSWORD_ERR,
        payload: error.response.data
      });
      dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: true });
    }
  };

export const leaveApplicationStatusChangeAction =
  (id, status) => async (dispatch, getState) => {
    try {
      let isUserExist = localStorage.getItem("loginInfo")
        ? JSON.parse(localStorage.getItem("loginInfo"))
        : null;

      const config = {
        headers: {
          Authorization: `Bearer ${isUserExist?.token}`
        }
      };

      dispatch({ type: EMPLOYEE_CHANGE_LEAVE_STATUS_CHANGE_REQUEST });

      let { data } = await axios.patch(
        `/api/admin/employee/changeLeaveStatus?id=${id}&status=${status}`,
        {},
        config
      );

      dispatch({
        type: EMPLOYEE_CHANGE_LEAVE_STATUS_CHANGE_SUCCESS,
        payload: data
      });
    } catch (error) {
      console.log(error, "sdjfhaskjhdfkasjhdkjh");
      dispatch({
        type: EMPLOYEE_CHANGE_LEAVE_STATUS_CHANGE_ERR,
        payload: error.response.data
      });
    }
  };

// leaveApplicationDeleteAction
export const leaveApplicationDeleteAction =
  (id) => async (dispatch, getState) => {
    try {
      let isUserExist = localStorage.getItem("loginInfo")
        ? JSON.parse(localStorage.getItem("loginInfo"))
        : null;

      const config = {
        headers: {
          Authorization: `Bearer ${isUserExist?.token}`
        }
      };

      dispatch({ type: EMPLOYEE_DELETE_LEAVE_APPLICATION_REQUEST });

      let { data } = await axios.delete(
        `/api/admin/employee/deleteLeaveApplication?id=${id}`,
        config
      );

      dispatch({
        type: EMPLOYEE_DELETE_LEAVE_APPLICATION_SUCCESS,
        payload: data
      });
    } catch (error) {
      console.log(error, "sdjfhaskjhdfkasjhdkjh");
      dispatch({
        type: EMPLOYEE_DELETE_LEAVE_APPLICATION_ERR,
        payload: error.response.data
      });
    }
  };

// leaveApplicationDeleteAction
export const branchManagerFetchAction =
  (id, fetchName) => async (dispatch, getState) => {
    try {
      let isUserExist = localStorage.getItem("loginInfo")
        ? JSON.parse(localStorage.getItem("loginInfo"))
        : null;

      const config = {
        headers: {
          Authorization: `Bearer ${isUserExist?.token}`
        }
      };

      dispatch({ type: BRANCH_MANAGER_FETCH_REQUEST });

      let { data } = await axios.get(
        `/api/admin/employee/branchManagerFetch?branch=${id}&userData=${fetchName}`,
        config
      );

      dispatch({
        type: BRANCH_MANAGER_FETCH_SUCCESS,
        payload: data
      });
    } catch (error) {
      console.log(error, "sdjfhaskjhdfkasjhdkjh");
      dispatch({
        type: BRANCH_MANAGER_FETCH_ERR,
        payload: error.response.data
      });
    }
  };

// findEmployeeMatchedRoleAction
export const findEmployeeMatchedRoleAction =
  (roleName) => async (dispatch, getState) => {
    try {
      let isUserExist = localStorage.getItem("loginInfo")
        ? JSON.parse(localStorage.getItem("loginInfo"))
        : null;

      const config = {
        headers: {
          Authorization: `Bearer ${isUserExist?.token}`
        }
      };

      let id = isUserExist.UserId;
      dispatch({ type: MATCHED_ROLE_EMPLOYEE_FETCH_REQUEST });

      let { data } = await axios.get(
        `/api/admin/employee/findEmployeeMatchedRole?id=${id}&roleName=${roleName}`,
        config
      );

      dispatch({
        type: MATCHED_ROLE_EMPLOYEE_FETCH_SUCCESS,
        payload: data
      });
    } catch (error) {
      console.log(error, "sdjfhaskjhdfkasjhdkjh");
      dispatch({
        type: MATCHED_ROLE_EMPLOYEE_FETCH_ERR,
        payload: error.response.data
      });
    }
  };

// NOTIFICATION
// findEmployeeNotificationAction
export const findEmployeeNotificationAction =
  (isOpen) => async (dispatch, getState) => {
    try {
      let isUserExist = localStorage.getItem("loginInfo")
        ? JSON.parse(localStorage.getItem("loginInfo"))
        : null;

      const config = {
        headers: {
          Authorization: `Bearer ${isUserExist?.token}`
        }
      };

      let id = isUserExist.UserId;
      dispatch({ type: NOTIFICATION_LIST_FIND_REQUEST });

      let { data } = await axios.get(
        `/api/admin/employee/findEmployeeNotification?id=${id}&&isOpen=${isOpen} `,
        config
      );

      dispatch({
        type: NOTIFICATION_LIST_FIND_SUCCESS,
        payload: data
      });
    } catch (error) {
      console.log(error, "sdjfhaskjhdfkasjhdkjh");
      dispatch({
        type: NOTIFICATION_LIST_FIND_ERR,
        payload: error.response.data
      });
    }
  };

// teamLeadStaffsFindAction
export const teamLeadStaffsFindAction = () => async (dispatch, getState) => {
  try {
    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`
      }
    };

    dispatch({ type: BRANCH_MANAGER_FETCH_REQUEST });

    let id = isUserExist.UserId;
    let { data } = await axios.get(
      `/api/admin/employee/teamStaffsList?id=${id}`,
      config
    );

    dispatch({
      type: BRANCH_MANAGER_FETCH_SUCCESS,
      payload: data
    });
  } catch (error) {
    console.log(error, "sdjfhaskjhdfkasjhdkjh");
    dispatch({
      type: BRANCH_MANAGER_FETCH_ERR,
      payload: error.response.data
    });
  }
};

// employeeBulkUploadAction :::::::::::::: Bulk::::::::::::::::::

export const employeeBulkUploadAction =
  (formData) => async (dispatch, getState) => {
    try {
      let isUserExist = localStorage.getItem("loginInfo")
        ? JSON.parse(localStorage.getItem("loginInfo"))
        : null;

      const config = {
        headers: {
          Authorization: `Bearer ${isUserExist?.token}`,
          "Content-Type": "multipart/form-data"
        }
      };

      dispatch({ type: EMPLOYEE_BULKUPLOAD_REQUEST });

      let id = isUserExist.UserId;
      let { data } = await axios.post(
        "/api/admin/employee/bulkUploadForEmployee",
        formData,
        config
      );

      dispatch({
        type: EMPLOYEE_BULKUPLOAD_SUCCESS,
        payload: data
      });
    } catch (error) {
      console.log(error, "sdjfhaskjhdfkasjhdkjh");
      dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: true });

      dispatch({
        type: EMPLOYEE_BULKUPLOAD_ERR,
        payload: error.response.data
      });
    }
  };
