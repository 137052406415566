import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import { Button, Chip, Grid, Typography } from "@mui/material";
import { TypographyText1 } from "../../../customComponent/Typography";

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginBottom: theme.spacing(2),
    marginTop: "8px",
  },
  hoverElement: {
    "&:hover": {
      color: "#FE0B7A",
    },
    "&.clicked": {
      backgroundColor: "#FE0B7A !important",
      color: "white !important",
    },
  },
}));

function AppliedShortListChip({ masterPageHandler, array, handleChange }) {
  const classes = useStyles();
  const [clicked, setClicked] = useState("country");
  const [addFormCountry, setAddFormCountry] = useState(false);
  const [addFormState, setAddFormState] = useState(false);
  const [addFormCity, setAddFormCity] = useState(false);

  const handleClick = (master) => {
    setClicked(master);
    handleChange(master);
  };

  // const handleModalClick = () => {
  //   if (clicked === "country") {
  //     setAddFormCountry(true);
  //   }

  //   if (clicked === "state") {
  //     setAddFormState(true);
  //   }

  //   if (clicked === "city") {
  //     setAddFormCity(true);
  //   }
  // };

  // const countryFormStatusHandler = () => {
  //   setAddFormCountry(false);
  // };

  // const stateFormStatusHandler = () => {
  //   setAddFormState(false);
  // };
  // const cityFormStatusHandler = () => {
  //   setAddFormCity(false);
  // };

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <TypographyText1 title="Applications" variant="h5" />
        </Grid>
        <Grid item xs={12} lg={6} mt={3}>
          <Chip
            className={`${classes.hoverElement} ${
              clicked === "All" ? "clicked" : ""
            }`}
            label="All"
            component="a"
            // href="#basic-chip"
            clickable
            sx={{
              fontFamily: "'Akatab', sans-serif",
              fontSize: "5px",
              fontWeight: 500,
              m: 0.5,
              backgroundColor: "#acb1c6",
            }}
            onClick={() => handleClick("All")}
          />
          {/* <Chip
            className={`${classes.hoverElement} ${
              clicked === "United Kingdom" ? "clicked" : ""
            }`}
            label="United Kingdom"
            component="a"
            clickable
            sx={{
              fontFamily: "'Akatab', sans-serif",
              fontSize: "5px",
              fontWeight: 500,
              m: 0.6,
              backgroundColor: "#acb1c6",
            }}
            onClick={() => handleClick("United Kingdom")}
          />
          <Chip
            className={`${classes.hoverElement} ${
              clicked === "Ireland" ? "clicked" : ""
            }`}
            label="Ireland"
            component="a"
            clickable
            sx={{
              fontFamily: "'Akatab', sans-serif",
              fontSize: "5px",
              fontWeight: 500,
              m: 0.6,
              backgroundColor: "#acb1c6",
            }}
            onClick={() => handleClick("Ireland")}
          />

          <Chip
            className={`${classes.hoverElement} ${
              clicked === "United States" ? "clicked" : ""
            }`}
            label="United States"
            component="a"
            clickable
            sx={{
              fontFamily: "'Akatab', sans-serif",
              fontSize: "5px",
              fontWeight: 500,
              m: 0.6,
              backgroundColor: "#acb1c6",
            }}
            onClick={() => handleClick("United States")}
          /> */}

          {array?.map((item, i) => (
            <Chip
              className={`${classes.hoverElement} ${
                clicked === item ? "clicked" : ""
              }`}
              label={item.split("(")[0]}
              component="a"
              clickable
              sx={{
                fontFamily: "'Akatab', sans-serif",
                textTransform: "capitalize",
                fontSize: "5px",
                fontWeight: 500,
                m: 0.6,
                backgroundColor: "#acb1c6",
              }}
              onClick={() => handleClick(item)}
            />
          ))}
        </Grid>
      </Grid>
    </>
  );
}

export default AppliedShortListChip;
