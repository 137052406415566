import React, { useEffect, useState } from "react";
import { Alert, AlertTitle, Grid } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";

import { LandingTable } from "../LandingTable";

import { format } from "date-fns";
import usePrivilegeCheck from "../../../constants/customHooks/UsePrivilegeCheck";
import SearchHeader from "../SearchHeader";
import BlogTableFilter from "./BlogTableFilter";
import { findLandingBlogsAction } from "../../../actions/LandingPageAction";

function BlogMain(props) {
  const dispatch = useDispatch();
  const [leadData, setLeadData] = useState([]);
  const [data, setData] = useState([]);
  const [newsList, setNewsList] = useState([]);
  const { privilege, hasPrivilege } = usePrivilegeCheck();

  let { blogCreateSuccess } = useSelector((state) => {
    return state.blogCreate;
  });

  let { blogFindingSuccess } = useSelector((state) => {
    return state.findLandingBlogs;
  });

  let { blogDeleteSuccess } = useSelector((state) => {
    return state.blogDelete;
  });

  useEffect(() => {
    if (blogFindingSuccess) {
      setNewsList(blogFindingSuccess);
    }
  }, [blogFindingSuccess]);

  useEffect(() => {
    dispatch(findLandingBlogsAction());
  }, [dispatch, blogCreateSuccess, blogDeleteSuccess]);

  useEffect(() => {
    setData(
      newsList.map((news, index) => ({
        id: news.id,
        sl: index + 1,
        coloum1: news.newsCoverUrl,
        coloum2: news.title ? news.title :"-",
        coloum3:
          news.createdAt && format(new Date(news?.createdAt), "dd - MM - yyyy"),
        coloum4: "image",
      }))
    );
  }, [newsList]);

  let columns = [
    {
      title: "SL NO",
      field: "SL NO",
    },
    {
      title: "Image",
      field: "Image",
    },
    {
      title: "Title",
      field: "title",
    },
    {
      title: "Posted on",
      field: "posted on",
    },
    {
      title: "",
      field: "action",
      filtering: false,
    },
  ];

  const searchHandleChange = (e) => {
    if (e.target.value !== "" && e.target.value !== " ") {
      setData(
        data?.filter((item) => {
          const column1 = item.coloum1 ? item.coloum1.toLowerCase() : "";
          const column2 = item.coloum2 ? item.coloum2.toLowerCase() : "";
          const column3 = item.coloum3 ? item.coloum3.toLowerCase() : "";

          // :lead?.branch_master?.branchName
          return (
            column1.includes(e.target.value.toLowerCase()) ||
            column2.includes(e.target.value.toLowerCase()) ||
            column3.includes(e.target.value.toLowerCase())
          );
        })
      );
    } else {
      setData(
        newsList.map((news, index) => ({
          id: news.id,
          sl: index + 1,
          coloum1: news.newsCoverUrl,
          coloum2: news.title,
          coloum3:
            news.createdAt &&
            format(new Date(news?.createdAt), "dd - MM - yyyy"),
          coloum4: "image",
        }))
      );
    }
  };

  // Employee column Visibility
  const [showNameColumn, setShowNameColumn] = useState(false);
  const [showPhoneColumn, setShowPhoneColumn] = useState(false);
  const [showPrefferedColumn, setShowPrefferedColumn] = useState(false);
  const [showLeadSourceColumn, setShowLeadSourceColumn] = useState(false);
  const [showLeadTypeColumn, setShowLeadTypeColumn] = useState(false);
  const [showStatusColumn, setShowStatusColumn] = useState(false);
  const [showEducationColumn, setShowEducationColumn] = useState(false);
  const [showUniversityPlaceColumn, setShowUniversityPlaceColumn] =
    useState(false);

  const [columnName, setColumnName] = useState("");
  const [leadId, setLeadId] = useState([]);
  const [ids, setIds] = useState([]);

  const toggleNameColumn = (status, column) => {
    if (column === "name") {
      setColumnName(column);
      setShowNameColumn(status);
    }
    if (column === "mobile") {
      setColumnName(column);
      setShowPhoneColumn(status);
    }
    if (column === "prefferedCountry") {
      setColumnName(column);
      setShowPrefferedColumn(status);
    }
    if (column === "leadSource") {
      setColumnName(column);
      setShowLeadSourceColumn(status);
    }

    if (column === "leadType") {
      setColumnName(column);
      setShowLeadTypeColumn(status);
    }

    if (column === "status") {
      setColumnName(column);
      setShowStatusColumn(status);
    }

    if (column === "education") {
      setColumnName(column);
      setShowEducationColumn(status);
    }

    if (column === "universityPlace") {
      setColumnName(column);
      setShowUniversityPlaceColumn(status);
    }
  };

  useEffect(() => {
    // Retrieve column visibility state from localStorage
    const storedRollNameColumn = localStorage.getItem("name");
    const storedPhoneColumn = localStorage.getItem("mobile");
    const storedPrefferedCountryColumn =
      localStorage.getItem("prefferedCountry");
    const storedLeadSourceColumn = localStorage.getItem("leadSource");
    const storedLeadTypeColumn = localStorage.getItem("leadType");
    const storedStatusColumn = localStorage.getItem("status");
    const storedEducationColumn = localStorage.getItem("education");
    const storedUniversityTypeColumn = localStorage.getItem("universityPlace");

    if (storedRollNameColumn !== null) {
      setColumnName("name");
      setShowNameColumn(JSON.parse(storedRollNameColumn));
    }

    if (storedPhoneColumn !== null) {
      setColumnName("mobile");
      setShowPhoneColumn(JSON.parse(storedPhoneColumn));
    }
    if (storedPrefferedCountryColumn !== null) {
      setColumnName("prefferedCountry");
      setShowPrefferedColumn(JSON.parse(storedPrefferedCountryColumn));
    }
    if (storedLeadSourceColumn !== null) {
      setColumnName("leadSource");
      setShowLeadSourceColumn(JSON.parse(storedLeadSourceColumn));
    }

    if (storedLeadTypeColumn !== null) {
      setColumnName("leadType");
      setShowLeadTypeColumn(JSON.parse(storedLeadTypeColumn));
    }
    if (storedStatusColumn !== null) {
      setColumnName("status");
      setShowStatusColumn(JSON.parse(storedStatusColumn));
    }

    if (storedEducationColumn !== null) {
      setColumnName("education");
      setShowEducationColumn(JSON.parse(storedEducationColumn));
    }

    if (storedUniversityTypeColumn !== null) {
      setColumnName("universityPlace");
      setShowUniversityPlaceColumn(JSON.parse(storedUniversityTypeColumn));
    }
  }, []);

  useEffect(() => {
    // Save column visibility state to localStorage
    localStorage.setItem("name", JSON.stringify(showNameColumn));
    localStorage.setItem("email", JSON.stringify(showPrefferedColumn));
    localStorage.setItem("mobile", JSON.stringify(showPhoneColumn));
    localStorage.setItem(
      "prefferedCountry",
      JSON.stringify(showPrefferedColumn)
    );
    localStorage.setItem("leadSource", JSON.stringify(showLeadSourceColumn));
    localStorage.setItem("leadType", JSON.stringify(showLeadTypeColumn));
    localStorage.setItem("status", JSON.stringify(showStatusColumn));
    localStorage.setItem("education", JSON.stringify(showEducationColumn));
    localStorage.setItem(
      "universityPlace",
      JSON.stringify(showUniversityPlaceColumn)
    );
  }, [
    showNameColumn,
    showPhoneColumn,
    showPrefferedColumn,
    showLeadSourceColumn,
    showLeadTypeColumn,
    showStatusColumn,
    showEducationColumn,
    showUniversityPlaceColumn,
    columnName,
  ]);

  const setIdsFunction = (leadsId) => {
    setLeadId(leadsId);
  };

  const selectBoxIdPassHandler = (ids) => {
    setIds(ids);
  };

  if (!hasPrivilege("blogs") && privilege !== null) {
    return (
      <Alert severity="error">
        <AlertTitle>warning</AlertTitle>
        You don't have permission to access — <strong>this page.!</strong>
      </Alert>
    );
  }

  
  return (
    <>
      <SearchHeader
        columnVisibleHandler={toggleNameColumn}
        showNameColumn={showNameColumn}
        showPrefferedColumn={showPrefferedColumn}
        showPhoneColumn={showPhoneColumn}
        showLeadSourceColumn={showLeadSourceColumn}
        showLeadTypeColumn={showLeadTypeColumn}
        showStatusColumn={showStatusColumn}
        showEducationColumn={showEducationColumn}
        showUniversityPlaceColumn={showUniversityPlaceColumn}
        // handleResetData={handleResetData}
        searchHandleChange={searchHandleChange}
        ids={ids}
        leadId={leadId}
        // component="blog"
      />

      <Grid container spacing={1}>
        <Grid item xs={12} md={2} lg={2.2}>
          <BlogTableFilter
            // LeadFilterFunction={LeadFilterFunction}
            // handleResetData={handleResetData}
            statusComponent="blogs"
          />
        </Grid>
        <Grid item xs={12} md={10} lg={9.8}>
          <LandingTable
            toggle={props.toggle}
            showNameColumn={showNameColumn}
            showPrefferedColumn={showPrefferedColumn}
            showPhoneColumn={showPhoneColumn}
            showLeadSourceColumn={showLeadSourceColumn}
            showLeadTypeColumn={showLeadTypeColumn}
            showStatusColumn={showStatusColumn}
            showEducationColumn={showEducationColumn}
            showUniversityPlaceColumn={showUniversityPlaceColumn}
            // reset={reset}
            setIdsFunction={setIdsFunction}
            data={data}
            columns={columns}
            // leadFindLoading={leadFindLoading}
            selectBoxIdPassHandler={selectBoxIdPassHandler}
            // isExistCounsilor={loginFindSuccess}
            component="blogTab"
          />
        </Grid>
      </Grid>
    </>
  );
}

export default BlogMain;
