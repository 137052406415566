import {
  BRANCH_MASTER_ADD_ERR,
  BRANCH_MASTER_ADD_REQUEST,
  BRANCH_MASTER_ADD_SUCCESS,
  BRANCH_MASTER_DELETE_ERR,
  BRANCH_MASTER_DELETE_REQUEST,
  BRANCH_MASTER_DELETE_SUCCESS,
  BRANCH_MASTER_EDIT_ERR,
  BRANCH_MASTER_EDIT_REQUEST,
  BRANCH_MASTER_EDIT_SUCCESS,
  BRANCH_MASTER_FIND_ERR,
  BRANCH_MASTER_FIND_REQUEST,
  BRANCH_MASTER_FIND_SUCCESS,
} from "../constants/branchMasterConstant";

// branchMasterAddReducer
export const branchMasterAddReducer = (state = {}, action) => {
  switch (action.type) {
    case BRANCH_MASTER_ADD_REQUEST:
      return {
        ...state,
        branchMasterAddLoading: true,
      };
    case BRANCH_MASTER_ADD_SUCCESS:
      return {
        ...state,
        branchMasterAddLoading: false,
        branchMasterAddSuccess: action.payload,
      };
    case BRANCH_MASTER_ADD_ERR:
      return {
        ...state,
        branchMasterAddLoading: false,
        branchMasterAddErr: action.payload,
      };
    default:
      return state;
  }
};

// findBranchMasterReducer
export const findBranchMasterReducer = (state = {}, action) => {
  switch (action.type) {
    case BRANCH_MASTER_FIND_REQUEST:
      return {
        ...state,
        branchMasterFindLoading: true,
      };
    case BRANCH_MASTER_FIND_SUCCESS:
      return {
        ...state,
        branchMasterFindLoading: false,
        branchMasterFindSuccess: action.payload,
      };
    case BRANCH_MASTER_FIND_ERR:
      return {
        ...state,
        branchMasterFindLoading: false,
        branchMasterFindErr: action.payload,
      };
    default:
      return state;
  }
};

// branchMasterDeleteReducer
export const branchMasterDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case BRANCH_MASTER_DELETE_REQUEST:
      return {
        ...state,
        branchMasterDeleteLoading: true,
      };
    case BRANCH_MASTER_DELETE_SUCCESS:
      return {
        ...state,
        branchMasterDeleteLoading: false,
        branchMasterDeleteSuccess: action.payload,
      };
    case BRANCH_MASTER_DELETE_ERR:
      return {
        ...state,
        branchMasterDeleteLoading: false,
        branchMasterDeleteErr: action.payload,
      };
    default:
      return state;
  }
};

// branchMasterEditReducer
export const branchMasterEditReducer = (state = {}, action) => {
  switch (action.type) {
    case BRANCH_MASTER_EDIT_REQUEST:
      return {
        ...state,
        branchMasterEditLoading: true,
      };
    case BRANCH_MASTER_EDIT_SUCCESS:
      return {
        ...state,
        branchMasterEditLoading: false,
        branchMasterEditSuccess: action.payload,
      };
    case BRANCH_MASTER_EDIT_ERR:
      return {
        ...state,
        branchMasterEditLoading: false,
        branchMasterEditErr: action.payload,
      };
    default:
      return state;
  }
};
