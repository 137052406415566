export const EMPLOYEE_ADD_REQUEST = "EMPLOYEE_ADD_REQUEST";
export const EMPLOYEE_ADD_SUCCESS = "EMPLOYEE_ADD_SUCCESS";
export const EMPLOYEE_ADD_ERR = "EMPLOYEE_ADD_ERR";

export const EMPLOYEE_PRIVILEGE_CHECK_REQUEST =
  "EMPLOYEE_PRIVILEGE_CHECK_REQUEST";
export const EMPLOYEE_PRIVILEGE_CHECK_SUCCESS =
  "EMPLOYEE_PRIVILEGE_CHECK_SUCCESS";
export const EMPLOYEE_PRIVILEGE_CHECK_ERR = "EMPLOYEE_PRIVILEGE_CHECK_ERR";

export const ADD_ALL_EMPLOYEE_FIND_REQUEST = "ADD_ALL_EMPLOYEE_FIND_REQUEST";
export const ADD_ALL_EMPLOYEE_FIND_SUCCESS = "ADD_ALL_EMPLOYEE_FIND_SUCCESS";
export const ADD_ALL_EMPLOYEE_FIND_ERR = "ADD_ALL_EMPLOYEE_FIND_ERR";

export const EMPLOYEE_STATUS_UPDATE_REQUEST = "EMPLOYEE_STATUS_UPDATE_REQUEST";
export const EMPLOYEE_STATUS_UPDATE_SUCCESS = "EMPLOYEE_STATUS_UPDATE_SUCCESS";
export const EMPLOYEE_STATUS_UPDATE_ERR = "EMPLOYEE_STATUS_UPDATE_ERR";

export const EMPLOYEE_DELETE_REQUEST = "EMPLOYEE_DELETE_REQUEST";
export const EMPLOYEE_DELETE_SUCCESS = "EMPLOYEE_DELETE_SUCCESS";
export const EMPLOYEE_DELETE_ERR = "EMPLOYEE_DELETE_ERR";

export const EMPLOYEE_DETAILS_SHOW_REQUEST = "EMPLOYEE_DETAILS_SHOW_REQUEST";
export const EMPLOYEE_DETAILS_SHOW_SUCCESS = "EMPLOYEE_DETAILS_SHOW_SUCCESS";
export const EMPLOYEE_DETAILS_SHOW_ERR = "EMPLOYEE_DETAILS_SHOW_ERR";

export const EMPLOYEE_DETAILS_EDIT_REQUEST = "EMPLOYEE_DETAILS_EDIT_REQUEST";
export const EMPLOYEE_DETAILS_EDIT_SUCCESS = "EMPLOYEE_DETAILS_EDIT_SUCCESS";
export const EMPLOYEE_DETAILS_EDIT_ERR = "EMPLOYEE_DETAILS_EDIT_ERR";

export const EMPLOYEE_LEAVE_APPLY_REQUEST = "EMPLOYEE_LEAVE_APPLY_REQUEST";
export const EMPLOYEE_LEAVE_APPLY_SUCCESS = "EMPLOYEE_LEAVE_APPLY_SUCCESS";
export const EMPLOYEE_LEAVE_APPLY_ERR = "EMPLOYEE_LEAVE_APPLY_ERR";

export const EMPLOYEE_LEAVE_FIND_REQUEST = "EMPLOYEE_LEAVE_FIND_REQUEST";
export const EMPLOYEE_LEAVE_FIND_SUCCESS = "EMPLOYEE_LEAVE_FIND_SUCCESS";
export const EMPLOYEE_LEAVE_FIND_ERR = "EMPLOYEE_LEAVE_FIND_ERR";

// EMPLOYEE SALARY SLIP CONSTANT
export const EMPLOYEE_SALARY_SLIP_FIND_REQUEST =
  "EMPLOYEE_SALARY_SLIP_FIND_REQUEST";
export const EMPLOYEE_SALARY_SLIP_FIND_SUCCESS =
  "EMPLOYEE_SALARY_SLIP_FIND_SUCCESS";
export const EMPLOYEE_SALARY_SLIP_FIND_ERR = "EMPLOYEE_SALARY_SLIP_FIND_ERR";

export const EMPLOYEE_CHANGE_PASSWORD_REQUEST =
  "EMPLOYEE_CHANGE_PASSWORD_REQUEST";
export const EMPLOYEE_CHANGE_PASSWORD_SUCCESS =
  "EMPLOYEE_CHANGE_PASSWORD_SUCCESS";
export const EMPLOYEE_CHANGE_PASSWORD_ERR = "EMPLOYEE_CHANGE_PASSWORD_ERR";

export const EMPLOYEE_CHANGE_LEAVE_STATUS_CHANGE_REQUEST =
  "EMPLOYEE_CHANGE_LEAVE_STATUS_CHANGE_REQUEST";
export const EMPLOYEE_CHANGE_LEAVE_STATUS_CHANGE_SUCCESS =
  "EMPLOYEE_CHANGE_LEAVE_STATUS_CHANGE_SUCCESS";
export const EMPLOYEE_CHANGE_LEAVE_STATUS_CHANGE_ERR =
  "EMPLOYEE_CHANGE_LEAVE_STATUS_CHANGE_ERR";

export const EMPLOYEE_DELETE_LEAVE_APPLICATION_REQUEST =
  "EMPLOYEE_DELETE_LEAVE_APPLICATION_REQUEST";
export const EMPLOYEE_DELETE_LEAVE_APPLICATION_SUCCESS =
  "EMPLOYEE_DELETE_LEAVE_APPLICATION_SUCCESS";
export const EMPLOYEE_DELETE_LEAVE_APPLICATION_ERR =
  "EMPLOYEE_DELETE_LEAVE_APPLICATION_ERR";

export const BRANCH_MANAGER_FETCH_REQUEST = "BRANCH_MANAGER_FETCH_REQUEST";
export const BRANCH_MANAGER_FETCH_SUCCESS = "BRANCH_MANAGER_FETCH_SUCCESS";
export const BRANCH_MANAGER_FETCH_ERR = "BRANCH_MANAGER_FETCH_ERR";

export const MATCHED_ROLE_EMPLOYEE_FETCH_REQUEST =
  "MATCHED_ROLE_EMPLOYEE_FETCH_REQUEST";
export const MATCHED_ROLE_EMPLOYEE_FETCH_SUCCESS =
  "MATCHED_ROLE_EMPLOYEE_FETCH_SUCCESS";
export const MATCHED_ROLE_EMPLOYEE_FETCH_ERR =
  "MATCHED_ROLE_EMPLOYEE_FETCH_ERR";

export const NOTIFICATION_LIST_FIND_REQUEST = "NOTIFICATION_LIST_FIND_REQUEST";
export const NOTIFICATION_LIST_FIND_SUCCESS = "NOTIFICATION_LIST_FIND_SUCCESS";
export const NOTIFICATION_LIST_FIND_ERR = "NOTIFICATION_LIST_FIND_ERR";


export const EMPLOYEE_BULKUPLOAD_REQUEST = "EMPLOYEE_BULKUPLOAD_REQUEST";
export const EMPLOYEE_BULKUPLOAD_SUCCESS = "EMPLOYEE_BULKUPLOAD_SUCCESS";
export const EMPLOYEE_BULKUPLOAD_ERR = "EMPLOYEE_BULKUPLOAD_ERR";

