import React from "react";
import HeadBreadcrumbs from "../breadcrumbs/breadcrumbs";
import { MainContainer } from "../content/content.element";

import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useNavigate } from "react-router-dom";
import { Divider } from "@mui/material";
import NotificationList from "./NotificationList";

function NotificationMain(props) {
  const navigate = useNavigate();

  return (
    <>
      <MainContainer active={props.toggle}>
        <div
          style={{
            display: "flex",
            justifyContent: "start",
            backgroundColor: "white",
          }}
        >
          <KeyboardBackspaceIcon
            style={{
              margin: "20px",
              paddingTop: "5px",
              fontSize: "xl",
              cursor: "pointer",
            }}
            onClick={() => navigate("/home")}
          />

          <HeadBreadcrumbs
            head="Notifications"
            sechead="home"
            first="Notifications"
            // main="Creat Lead"
          />
        </div>
        <Divider />
        <NotificationList/>
      </MainContainer>
    </>
  );
}

export default NotificationMain;
