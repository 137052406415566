import React, { useEffect, useRef, useState } from "react";
import { Box, Divider, Grid, Modal, Typography } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";

import { FaqTable } from "./FaqTable";

import { universityFindCourseAction } from "../../../actions/universityActions/courseActions/universityCourseAction";
import {
  CountryDocFindAction,
  countryDocumentAdmissionApplicationFindAction,
} from "../../../actions/knowledgeBankActions/CountryDocsActions";
import {
  DestinationFormEditAction,
  destinationFindByIDAction,
} from "../../../actions/LandingPageAction";
import { findUniversityAction } from "../../../actions/universityActions/universityManagmentAction";
import { findcountryMasterAddAction } from "../../../actions/countryMasterAction";
import InputField, { SelectInputField } from "../../customComponent/InputField";
import { MainContainer } from "../../content/content.element";
import { InfoHeader } from "../../customComponent/InfoHeader";
import { SubmitButton } from "../../customComponent/Buttons";
import { useParams } from "react-router-dom";
import { GetCountries } from "react-country-state-city";
import {
  DESTINATION_EDIT_ERR,
  DESTINATION_EDIT_SUCCESS,
} from "../../../constants/LandingPage/LandingPageConstant";
import Swal from "sweetalert2";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "400",
  height: "400",
  //   overflow: "scroll",
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 20,
  p: 4,
};

function DestinationEditForm(props) {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [DestinationName, setDestinationName] = useState("");
  const [DestinationImage, setDestinationImage] = useState("");
  const [DestinationimageValue, setDestinationimageValue] = useState("");
  const [Guide, setGuide] = useState("");
  const [GuideValue, setGuideValue] = useState("");
  const [Description, setDescription] = useState("");
  const [admissionProcedure, setAdmissionProcedure] = useState("");
  const [costEstimate, setCostestimate] = useState("");
  const [currencySymbol, setCurrencySymbol] = useState("");
  const [symbolArray, setSymbolArray] = useState([]);
  const [TopUniversities, setTopUniversities] = useState([]);
  const [TopCourses, setTopCourses] = useState([]);
  const [AdmissionDocuments, setAdmissionDocuments] = useState([]);
  const [Faq, setFaq] = useState([]);
  const [faqValue, setFaqValue] = useState("");
  const [AllAdmissionDoc, setAllAdmissionDoc] = useState([]);
  const [allCountry, setAllCountry] = useState([]);
  const [UniversityList, SetTopUniversityList] = useState([]);
  const [universityData, setUniversityData] = useState([]);
  const [faqAnswer, setFaqAnswer] = useState("");
  const [AllCourses, setAllCourses] = useState([]);

  const [open, setOpen] = useState(false);

  const Guideimage = useRef();
  const Destinationimage = useRef();

  const handleGuidUpload = (event) => {
    const file = event.target.files[0];
    setGuideValue(file.name);
    setGuide(event.target.files[0]);
  };

  const handleDestinationImage = (event) => {
    const file = event.target.files[0];
    setDestinationimageValue(file.name);
    setDestinationImage(file);
  };

  console.log(TopUniversities, "TopUniversitiesTopUniversities");

  // List country

  // let { countryDocFindSuccess } = useSelector((state) => {
  //   return state.findcountryMasterAdd;
  // });

  let { countryDocFindSuccess } = useSelector((state) => {
    return state.CountryDocFind;
  });

  let { universityFindSuccess } = useSelector((state) => {
    return state.findUniversity;
  });

  let { destinationFindByIdSuccess } = useSelector((state) => {
    return state.destinationFindByID;
  });

  let { destinationEditSuccess, destinationEditErr } = useSelector((state) => {
    return state.destinationEdit;
  });

  useEffect(() => {
    if (countryDocFindSuccess) {
      setAllCountry(countryDocFindSuccess);
    }
  }, [countryDocFindSuccess]);

  console.log(
    destinationFindByIdSuccess,
    "destinationFindByIdSuccessdestinationFindByIdSuccess"
  );

  useEffect(() => {
    if (destinationFindByIdSuccess) {
      const destinationCountry = countryDocFindSuccess?.find(
        (value) => value?.DocName == destinationFindByIdSuccess?.destinationName
      );

      setDestinationName(destinationCountry);
      dispatch(findUniversityAction());
      dispatch(
        countryDocumentAdmissionApplicationFindAction(destinationCountry?.id)
      );
      setDestinationImage(destinationFindByIdSuccess?.destinationImage);
      setDestinationimageValue(destinationFindByIdSuccess?.destinationImage);
      setGuide(destinationFindByIdSuccess?.destinationGuide);
      setGuideValue(destinationFindByIdSuccess?.destinationGuide);
      setDescription(destinationFindByIdSuccess?.description);
      setAdmissionProcedure(destinationFindByIdSuccess?.admissionProcedure);
      setCostestimate(destinationFindByIdSuccess?.costEstimate);
      setCurrencySymbol(destinationFindByIdSuccess?.currency);
      setTopUniversities(
        destinationFindByIdSuccess?.landing_Destination_TopUniversities.map(
          (item) => ({
            id: item.universityId,
            universityname: item.universityName,
            tableID: item?.id,
          })
        )
      );
      setFaq(
        destinationFindByIdSuccess?.landing_Destination_faqs.map(
          (item, index) => ({
            slNo: index + 1,
            value: item?.question,
            answer: item.answer,
          })
        )
      );

      setTopCourses(
        destinationFindByIdSuccess?.landing_Destination_TopCourses.map(
          (item) => ({
            topCourses: item.courseName,
            id: item.id,
            tableID: item.id,
          })
        )
      );
      // setTopCourses(destinationFindByIdSuccess?.landing_Destination_TopCourses);
      setAdmissionDocuments(
        destinationFindByIdSuccess?.landing_Destination_admissionDocs?.map(
          (item) => ({
            admissionDoc: item.admissionDocName,
            id: item?.destinationId,
            tableID: item.id,
          })
        )
      );
    }
    // setFaq();
    // setFaqValue();
    // setAllAdmissionDoc();
    // setAllCountry();
    // SetTopUniversityList();
    // setUniversityData();
    // setAllCourses();
  }, [destinationFindByIdSuccess]);

  useEffect(() => {
    dispatch(CountryDocFindAction());
    GetCountries().then((result) => {
      setSymbolArray(result);
    });
  }, [dispatch]);

  useEffect(() => {
    dispatch(destinationFindByIDAction(id));
  }, [dispatch]);

  useEffect(() => {
    if (universityFindSuccess) {
      setUniversityData(universityFindSuccess);
    }
  }, [universityFindSuccess]);

  useEffect(() => {
    if (universityData && universityData.length !== 0) {
      SetTopUniversityList(
        universityData
          .filter(
            (master) =>
              master.universityCountry?.split("(")[0] ===
              DestinationName?.DocName?.split("(")[0]
          )
          .map((country, index) => ({
            universityname: country.universityName,
            id: country.id,
            tableID: country?.id,
          }))
      );
    }
  }, [universityData]);

  // Admission_Documents

  const { countryDocAdmissionFindSuccess } = useSelector((state) => {
    return state.countryDocumentAdmissionApplicationFind;
  });

  useEffect(() => {
    if (countryDocAdmissionFindSuccess) {
      setAllAdmissionDoc(countryDocAdmissionFindSuccess);
    }
  }, [countryDocAdmissionFindSuccess]);

  // Destination_Name_handle _function

  const handleDestinationChange = (event) => {
    const {
      target: { value },
    } = event;
    console.log(value, "valuevaluevalue");
    setDestinationName(value);
    dispatch(findUniversityAction());
    dispatch(countryDocumentAdmissionApplicationFindAction(value.id));
  };

  // TopCourses_reducer

  let { universityCourseFindSuccess } = useSelector((state) => {
    return state.universityFindCourse;
  });

  useEffect(() => {
    if (universityCourseFindSuccess) {
      setAllCourses((prevCourse) => {
        const updateTopCourse = [...prevCourse];
        universityCourseFindSuccess.forEach((selectedItem) => {
          const existingIndex = updateTopCourse.findIndex(
            (item) => item.id === selectedItem.id
          );
          if (existingIndex !== -1) {
            // Item already exists in the ranking, remove it
            updateTopCourse.splice(existingIndex, 1);
          } else {
            // Item doesn't exist in the ranking, add it

            updateTopCourse.push({
              id: selectedItem.id,
              name: selectedItem.courseTitle,
              tableID: selectedItem?.id,
            });
          }
        });
        return updateTopCourse;
      });
    }
  }, [universityCourseFindSuccess]);

  // FAQ

  const handleFaq = (event) => {
    setFaqValue(event.target.value);
  };

  const handleSave = () => {
    if (faqValue) {
      const newItem = {
        slNo: Faq.length + 1,
        value: faqValue,
        answer: faqAnswer,
      };
      setFaq((prevData) => [...prevData, newItem]);
      setFaqValue("");
      setOpen(false); // Clear the input field after saving
    }
  };

  // HandleCreate

  const HandleCreate = () => {
    console.log(
      DestinationName,
      "DestinationNameDestinationNameDestinationName"
    );
    const formData = new FormData();
    formData.append("destinationImage", DestinationImage);
    formData.append("Guide", Guide);
    formData.append("id", id);
    formData.append("destinationName", JSON.stringify(DestinationName));
    formData.append("description", Description);
    formData.append("topUniversities", JSON.stringify(TopUniversities));
    formData.append("topCourse", JSON.stringify(TopCourses));
    formData.append("admissionDoc", JSON.stringify(AdmissionDocuments));
    formData.append("Faq", JSON.stringify(Faq));

    formData.append("admissionProcedure", admissionProcedure);
    formData.append("costEstimate", costEstimate);
    formData.append("currency", currencySymbol);

    dispatch(DestinationFormEditAction(formData));

    // setDestinationName("");
    // setUniversityData([]);
    // setDestinationimageValue("");
    // setGuideValue("");
    // setDescription("");
    // setTopUniversities([]);
    // setTopCourses([]);
    // setAdmissionDocuments([]);
    // setAdmissionProcedure("");
    // setCostestimate("");
  };

  const handleClose = () => setOpen(false);

  // Success msg

  useEffect(() => {
    if (destinationEditSuccess) {
      Swal.fire("Success", "Update successfully", "success");
      dispatch({ type: DESTINATION_EDIT_SUCCESS, payload: false });
    }
    if (destinationEditErr) {
      Swal.fire("Error", destinationEditErr?.message, "Error");
      dispatch({ type: DESTINATION_EDIT_ERR, payload: false });
    }
  }, [destinationEditSuccess, destinationEditErr]);

  return (
    <MainContainer active={props.toggle}>
      <div
        style={{
          display: "flex",
          justifyContent: "start",
          backgroundColor: "white",
        }}
      >
        <InfoHeader headTitle="Edit Destination" url={"/landingPage"} />
      </div>

      <Divider />

      <Grid container sx={{ mt: 3 }}>
        <Grid item xs={12} lg={4} style={{}}>
          <SelectInputField
            label="Destination Name"
            handleSelectChange={handleDestinationChange}
            widthSize="120px"
            value={DestinationName}
            mapValue={allCountry}
            mapMethod="DestinationName"
            widthSelect="95%"
          />
        </Grid>
        <Grid item xs={12} lg={4} style={{ position: "relative" }}>
          <Grid>
            <InputField
              label="Destination Image"
              widthSize="92%"
              value={DestinationimageValue}
              required
              paramses
            ></InputField>
          </Grid>
          <Grid style={{ position: "absolute", top: 5, right: 29 }}>
            <button
              onClick={() => Destinationimage.current.click()}
              style={{
                width: "100px",
                height: "40px",
                fontSize: "13px",
                fontFamily: "monospace",
                border: "none",
              }}
            >
              Browse
            </button>
            <input
              ref={Destinationimage}
              type="file"
              accept="image/*"
              onChange={(event) => handleDestinationImage(event)}
              style={{ display: "none" }}
            ></input>
          </Grid>
        </Grid>

        <Grid item xs={12} lg={4} style={{ position: "relative" }}>
          <InputField
            label="Guide"
            widthSize="92%"
            value={GuideValue}
            required
            paramses
          ></InputField>
        </Grid>
        <Grid style={{ position: "absolute", top: 173, right: 49 }}>
          <button
            onClick={() => Guideimage.current.click()}
            style={{
              width: "100px",
              height: "40px",
              fontSize: "13px",
              fontFamily: "monospace",
              border: "none",
            }}
          >
            Browse
          </button>
          <input
            ref={Guideimage}
            type="file"
            accept="application/pdf"
            onChange={(event) => handleGuidUpload(event)}
            style={{ display: "none" }}
          ></input>
        </Grid>

        <Grid item xs={12} lg={12} sx={{ mt: 3 }}>
          <InputField
            label="Description"
            handleChange={(e) => setDescription(e.target.value)}
            widthSize="98%"
            value={Description}
            InputType="discription"
            dispcriptionHeight="95px"
            required
            paramses
          />
        </Grid>

        {/* List universities  */}

        <Grid item xs={12} lg={6} sx={{ mt: 4 }}>
          <label
            style={{ fontWeight: "700", fontSize: "20px", lineHeight: "24px" }}
          >
            Top Universities
          </label>
          <div style={{ marginTop: "20px" }}>
            <SelectInputField
              label="Select Universities"
              docName
              handleSelectChange={(event) => {
                // const {
                //   target: { value },
                // } = event;
                // const filteredArray = value.filter((obj) =>
                //   obj.hasOwnProperty("topUniversity")
                // );
                // setTopUniversities((prevRanking) => {
                //   const updatedRanking = [...prevRanking]; // Create a copy of the current ranking state

                //   filteredArray.forEach((selectedItem) => {
                //     const existingIndex = updatedRanking.findIndex(
                //       (item) => item.id == selectedItem.id
                //     );
                //     if (existingIndex !== -1) {
                //       // Item already exists in the ranking, remove it
                //       updatedRanking.splice(existingIndex, 1);
                //     } else {
                //       // Item doesn't exist in the ranking, add it

                //       updatedRanking.push({
                //         id: selectedItem.id,
                //         name: selectedItem.topUniversity,
                //       });
                //     }
                //     dispatch(universityFindCourseAction(selectedItem.id));
                //   });
                //   return updatedRanking;
                // });
                setTopUniversities(event);
                event?.forEach((value) => {
                  dispatch(universityFindCourseAction(value?.id));
                });
              }}
              widthSize="120px"
              value={TopUniversities}
              mapValue={UniversityList}
              mapMethod="alluniversities"
              multiple="true"
            />
          </div>
        </Grid>

        <Grid item xs={12} lg={5} sx={{ mt: 4, ml: 3 }}>
          <label
            style={{ fontWeight: "700", fontSize: "20px", lineHeight: "24px" }}
          >
            Top Courses
          </label>
          <div style={{ marginTop: "20px", width: "113%" }}>
            <SelectInputField
              label="Select Top Course"
              handleSelectChange={(event) => {
                // const {
                //   target: { value },
                // } = event;

                // // console.log(value, "############################333");
                // const filteredArray = value.filter((obj) =>
                //   obj.hasOwnProperty("topCourses")
                // );

                // setTopCourses((prevRanking) => {
                //   const updatedRanking = [...prevRanking]; // Create a copy of the current ranking state

                //   filteredArray.forEach((selectedItem) => {
                //     const existingIndex = updatedRanking.findIndex(
                //       (item) => item.id == selectedItem.id
                //     );

                //     if (existingIndex !== -1) {
                //       // Item already exists in the ranking, remove it
                //       updatedRanking.splice(existingIndex, 1);
                //     } else {
                //       // Item doesn't exist in the ranking, add it
                //       updatedRanking.push({
                //         name: selectedItem.topCourses,
                //         id: selectedItem.id,
                //       });
                //     }
                //   });

                //   return updatedRanking;
                // });
                setTopCourses(event);
              }}
              widthSize="120px"
              value={TopCourses}
              mapValue={AllCourses}
              mapMethod="allCourses"
              multiple="true"
            />
          </div>
        </Grid>
        <Grid item xs={12} lg={12} sx={{ mt: 4 }}>
          <label
            style={{ fontWeight: "700", fontSize: "20px", lineHeight: "24px" }}
          >
            Admission Requirement Documents
          </label>
          <div style={{ marginTop: "20px", width: "99%" }}>
            <SelectInputField
              label="Select Documents Needed"
              handleSelectChange={(event) => {
                // const {
                //   target: { value },
                // } = event;

                // console.log(value);
                // const filteredArray = value.filter((obj) =>
                //   obj.hasOwnProperty("admissionDoc")
                // );

                // setAdmissionDocuments((prevDoc) => {
                //   const updateDoc = [...prevDoc]; // Create a copy of the current ranking state

                //   filteredArray.forEach((selectedItem) => {
                //     const existingIndex = updateDoc.findIndex(
                //       (item) => item.id === selectedItem.id
                //     );

                //     if (existingIndex !== -1) {
                //       // Item already exists in the ranking, remove it
                //       updateDoc.splice(existingIndex, 1);
                //     } else {
                //       // Item doesn't exist in the ranking, add it
                //       updateDoc.push({
                //         name: selectedItem.admissionDoc,
                //         id: selectedItem.id,
                //       });
                //     }
                //   });

                //   return updateDoc;
                // });
                setAdmissionDocuments(event);
              }}
              widthSize="200px"
              value={AdmissionDocuments}
              mapValue={AllAdmissionDoc}
              mapMethod="allAdmissionDoc"
              multiple="true"
            />
          </div>
        </Grid>
        <Grid item xs={12} lg={12} sx={{ mt: 4 }}>
          <label
            style={{ fontWeight: "700", fontSize: "20px", lineHeight: "24px" }}
          >
            Admission Procedure
          </label>
          <div style={{ marginTop: "20px" }}>
            <InputField
              label="Admission Procedure"
              handleChange={(e) => setAdmissionProcedure(e.target.value)}
              widthSize="98%"
              value={admissionProcedure}
              InputType="discription"
              dispcriptionHeight="95px"
              required
              paramses
            />
          </div>
        </Grid>
        <Grid item xs={12} lg={12} sx={{ mt: 4 }}>
          <label
            style={{ fontWeight: "700", fontSize: "20px", lineHeight: "24px" }}
          >
            Cost estimated
          </label>
          <div style={{ marginTop: "20px", display: "flex" }}>
            <Grid sx={{ width: "25%", mr: 2 }}>
              <SelectInputField
                label="SelectCurrency"
                handleSelectChange={(e) => setCurrencySymbol(e.target.value)}
                widthSize="10px"
                value={currencySymbol}
                mapValue={symbolArray}
                mapMethod="currencyList"
                requiredSymbol
                required
              />
            </Grid>
            <Grid sx={{ width: "100%" }}>
              <InputField
                label="Cost"
                handleChange={(e) => setCostestimate(e.target.value)}
                widthSize="98%"
                value={costEstimate}
                required
                paramses
              />
            </Grid>
          </div>
        </Grid>
        <Grid item xs={12} lg={12} sx={{ mt: 4 }}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <label
              style={{
                fontWeight: "700",
                fontSize: "20px",
                lineHeight: "24px",
              }}
            >
              FAQ
            </label>
            <div>
              <button
                style={{
                  color: "black",
                  backgroundColor: "white",
                  borderRadius: "5px",
                  paddingTop: "5px",
                  paddingBottom: "5px",
                  paddingLeft: "25px",
                  paddingRight: "25px",
                  fontWeight: "700",
                  border: "1px solid",
                }}
                onClick={() => setOpen(true)}
              >
                Add
              </button>
            </div>
          </div>
          <div style={{ marginTop: "20px" }}>
            <FaqTable
              columns={[
                {
                  title: "SL NO",
                  field: "SL NO",
                },
                {
                  title: "QUESTION",
                  field: "QUESTION",
                },
                {
                  title: "ANSWER",
                  field: "ANSWER",
                },
              ]}
              data={Faq}
              component="FaqTable"
              isPresentMap={setFaq}
            />
          </div>
        </Grid>
      </Grid>
      {/* Submit Button */}
      <Grid
        item
        xs={4}
        lg={12}
        style={{
          display: "flex",
          justifyContent: "flex-start",
          marginTop: "10px",
        }}
      >
        <SubmitButton
          title="Update"
          submit=""
          widthSize="100px"
          heightSize="38px"
          type="click"
          handleSubmit={HandleCreate}
        />
      </Grid>

      {/* FAQ modal */}

      {open && (
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              sx={{ fontWeight: "bold" }}
            >
              FAQ:
            </Typography>

            <div
              style={{
                marginTop: "10px",
                paddingTop: "10px",
                width: "500px",
              }}
            >
              <InputField
                label="Question"
                handleChange={handleFaq}
                widthSize="490px"
                value={faqValue}
                InputType="discription"
                dispcriptionHeight="42px"
                required
                paramses
              />
              <InputField
                label="Answer"
                handleChange={(e) => setFaqAnswer(e.target.value)}
                widthSize="490px"
                InputType="discription"
                value={faqAnswer}
                dispcriptionHeight="220px"
                required
                paramses
              />
            </div>
            <Grid
              item
              xs={4}
              lg={12}
              style={{
                display: "flex",
                justifyContent: "flex-start",
                marginTop: "10px",
              }}
            >
              <SubmitButton
                title="Save"
                submit=""
                widthSize="100px"
                heightSize="38px"
                type="click"
                handleSubmit={handleSave}
              />
            </Grid>
          </Box>
        </Modal>
      )}
    </MainContainer>
  );
}

export default DestinationEditForm;
