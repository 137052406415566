import React, { useEffect, useState } from "react";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

// import { Menu, MenuItem } from "@mui/base";
import { RiDeleteBin6Line } from "react-icons/ri";
import { Box } from "@mui/system";
// import AddCountry from "./AddCountry";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import Swal from "sweetalert2";
import { TbEdit } from "react-icons/tb";
import AddCity from "./AddCity";
import {
  cityMasterDeleteAction,
  findCityMasterAction,
} from "../../../../actions/cityManagmentAction";
import EditCity from "./EditCity";
import { Skeleton, Typography } from "@mui/material";
import { MasterTable } from "../MasterTable";
import { successTrueMsgAction } from "../../../../actions/globalAction";
import { SUCCESS_TRUE_MSG_ERR } from "../../../../constants/roleManagmentConstant";
import TableNoItemComponent from "../../../customComponent/TableNoItemComponent";

const ITEM_HEIGHT = 48;

function CityMaster({ addFormCity, cityFormStatusHandler,searchHandle }) {
  const [open, setOpen] = useState(addFormCity);

  const dispatch = useDispatch();

  const [stateData, setStateData] = useState([]);
  const [editForm, setEditForm] = useState(false);
  const [isAlertShown, setIsAlertShown] = useState(false);

  // selectore

  let { cityFindLoading, cityFindSuccess, cityFindErr } = useSelector(
    (state) => {
      return state.findCityMaster;
    }
  );

  let { cityAddSuccess, cityAddErr } = useSelector((state) => {
    return state.cityMasterAdd;
  });

  let { cityDeleteSuccess } = useSelector((state) => {
    return state.cityMasterDelete;
  });

  let { cityEditSuccess, cityEditErr } = useSelector((state) => {
    return state.cityMasterEdit;
  });
  const { successAlertMsgSuccess, successAlertMsgErr } = useSelector(
    (state) => {
      return state.successTrueMsg;
    }
  );


  useEffect(() => {
    if (searchHandle !== "" && searchHandle !== " ") {
      console.log(searchHandle, "searchHandlesearchHandle");
      setStateData(
        cityFindSuccess?.filter((item) => {
          const CityName = item.cityName
            ? item.cityName.toLowerCase()
            : "";
          const CountryState = item.stateName
            ? item.stateName.toLowerCase()
            : "";

          return (
            CityName.includes(searchHandle.toLowerCase()) ||
            CountryState.includes(searchHandle.toLowerCase())
          );
        })
      );
    } else {
      if (cityFindSuccess) {
        let reversedData = cityFindSuccess?.reverse();
        setStateData(reversedData);
      }
    }
  }, [searchHandle]);

  useEffect(() => {
    if (successAlertMsgSuccess && !successAlertMsgErr) {
      Swal.fire("Good job!", `created a new city`, "success");
      dispatch(successTrueMsgAction(false));

      dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: false });
    }
  }, [successAlertMsgSuccess, dispatch]);

  useEffect(() => {
    if (successAlertMsgErr) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        footer: `<p>${cityAddErr}</p>`,
      });

      dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: false });
    }
  }, [successAlertMsgErr, cityAddErr, dispatch]);

  useEffect(() => {
    if (cityEditErr && !isAlertShown) {
      setIsAlertShown(true);
    }
  }, [cityEditErr, isAlertShown]);

  useEffect(() => {
    if (cityFindSuccess) {
       let reversedData = cityFindSuccess.reverse();
      setStateData(reversedData);
    }
  }, [cityFindSuccess, cityAddSuccess, cityEditSuccess]);

  useEffect(() => {
    if (addFormCity) {
      setOpen(addFormCity);
    }
  }, [addFormCity]);

  useEffect(() => {
    dispatch(findCityMasterAction());
  }, [dispatch, cityAddSuccess, cityDeleteSuccess, cityEditSuccess]);

  const addMasterCategryHandler = (category, status) => {
    cityFormStatusHandler(status);
    setOpen(status);
  };

  // edit form
  const editMasterCategryHandler = (category, status) => {
    setEditForm(status);
  };

  // material table action

  const [tableId, setTableId] = useState("");
  // action button
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openAction = Boolean(anchorEl);
  const handleClick = (event, id) => {
    setAnchorEl(event.currentTarget);
    setTableId(id);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // navigate to Edit page
  const rollEditHandler = (id) => {
    setTableId(id);
    setEditForm(true);
  };

  // delete action
  const RoledeleteHandler = (id) => {
    setAnchorEl(null);
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(cityMasterDeleteAction(tableId));
        Swal.fire("Deleted!", "Your file has been deleted.", "success");
      }
    });
  };

  const columns = [
    {
      title: "Sl no",
      field: "sl",
      // type: "numeric",
      // hidden: true,
      headerStyle: { backgroundColor: "#F9F9FB" },
    },

    {
      title: "City",
      field: "city",
      //   hidden: showEmailColumn,
      headerStyle: { backgroundColor: "#F9F9FB" },
    },
    {
      title: "State",
      field: "state",
      //   hidden: showMobileColumn,
      headerStyle: { backgroundColor: "#F9F9FB" },
    },
    {
      title: "Updated By",
      field: "updatedby",
      //   hidden: showRollColumn,
      headerStyle: { backgroundColor: "#F9F9FB" },
    },

    // {
    //   title: "Action",
    //   field: "action",
    //   filtering: false,
    //   headerStyle: { backgroundColor: "#F9F9FB" },
    // },
  ];

  const data = stateData?.map((data, index) => ({
    id: data.id,
    sl: index + 1,
    column1: data.cityName,
    column2: data.stateName,
    column3: data.updatedBy,
  }));

  return (
    <>
      {/* edit component */}
      {editForm && (
        <EditCity
          status={editForm}
          editMasterCategryHandler={editMasterCategryHandler}
          id={tableId}
        />
      )}
      {/* popup */}

      {open && (
        <AddCity
          status={open}
          addMasterCategryHandler={addMasterCategryHandler}
        />
      )}
      {/* <MasterChip
        masterPageHandler={masterPageHandler}
        addMasterCategryHandler={addMasterCategryHandler}
      /> */}
      <Box sx={{ mt: 1 }}>
        {cityFindLoading ? (
          <>
            <Typography variant="h1">
              <Skeleton animation={false} />
            </Typography>

            <Typography variant="h3">
              <Skeleton animation="wave" />
            </Typography>

            <Skeleton animation={false} />
            <Typography variant="h3">
              <Skeleton animation="wave" />
            </Typography>

            <Skeleton animation={false} />
            <Typography variant="h3">
              <Skeleton animation="wave" />
            </Typography>

            <Skeleton animation={false} />
            <Typography variant="h3">
              <Skeleton animation="wave" />
            </Typography>

            <Skeleton animation={false} />
          </>
        ) : (
          <>
            {data.length > 0 ? (
              <MasterTable
                data={data}
                columns={columns}
                component="cityMaster"
                rollEditHandler={rollEditHandler}
              />
            ) : (
              <TableNoItemComponent />
            )}
          </>
        )}
        {/*TABLE ACTION MENU START*/}
        <Menu
          id="long-menu"
          anchorEl={anchorEl}
          open={openAction}
          onClose={handleClose}
          PaperProps={{
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,
              width: "20ch",
              borderColor: "black",
              // borderRadius: "24px",
              marginLeft: "81px",
              backgroundColor: "white",
            },
          }}
        >
          <Box style={{ display: "flex", justifyContent: "flex-end" }}>
            <MenuItem>
              <TbEdit
                onClick={() => rollEditHandler(tableId)}
                style={{ fontSize: "lg", width: "50px", height: "23px" }}
              />
            </MenuItem>

            <MenuItem>
              <RiDeleteBin6Line
                onClick={() => RoledeleteHandler(tableId)}
                style={{ fontSize: "lg", width: "50px", height: "23px" }}
              />
            </MenuItem>
          </Box>
        </Menu>
        {/*TABLE ACTION MENU END*/}
      </Box>
    </>
  );
}

export default CityMaster;
