import axios from "axios";
import {
  ADD_BONUS_EMPLOYEES_ERR,
  ADD_BONUS_EMPLOYEES_REQUEST,
  ADD_BONUS_EMPLOYEES_SUCCESS,
  APPLICATION_ALL_FIND_LEAD_ERR,
  APPLICATION_ALL_FIND_LEAD_REQUEST,
  APPLICATION_ALL_FIND_LEAD_SUCCESS,
  APPLICATION_DECLARATION_APPROVE_ERR,
  APPLICATION_DECLARATION_APPROVE_REQUEST,
  APPLICATION_DECLARATION_APPROVE_SUCCESS,
  APPLICATION_DEPOSITE_CREATE_ERR,
  APPLICATION_DEPOSITE_CREATE_REQUEST,
  APPLICATION_DEPOSITE_CREATE_SUCCESS,
  APPLICATION_DETAILS_FIND_ERR,
  APPLICATION_DETAILS_FIND_REQUEST,
  APPLICATION_DETAILS_FIND_SUCCESS,
  APPLICATION_FIND_BY_ID_LEAD_ERR,
  APPLICATION_FIND_BY_ID_LEAD_REQUEST,
  APPLICATION_FIND_BY_ID_LEAD_SUCCESS,
  APPLICATION_PAYMENTHISTORY_FIND_ERR,
  APPLICATION_PAYMENTHISTORY_FIND_REQUEST,
  APPLICATION_PAYMENTHISTORY_FIND_SUCCESS,
  FIND_ALL_ASIGNED_STAFF_ERR,
  FIND_ALL_ASIGNED_STAFF_REQUEST,
  FIND_ALL_ASIGNED_STAFF_SUCCESS,
  FIND_BONUS_EMPLOYEES_DETAILS_REQUEST,
  FIND_BONUS_EMPLOYEES_DETAILS_SUCCESS,
  GET_BONUS_EMPLOYEES_ERR,
  GET_BONUS_EMPLOYEES_REQUEST,
  GET_BONUS_EMPLOYEES_SUCCESS,
  GET_STAFF_INCENTIVE_AMOUNT_ERR,
  GET_STAFF_INCENTIVE_AMOUNT_REQUEST,
  GET_STAFF_INCENTIVE_AMOUNT_SUCCESS,
  STAFF_ADD_INCENTIVE_AMOUNT_ERR,
  STAFF_ADD_INCENTIVE_AMOUNT_REQUEST,
  STAFF_ADD_INCENTIVE_AMOUNT_SUCCESS,
} from "../../constants/applicationConstant";
import {
  SUCCESS_TRUE_MSG_ERR,
  SUCCESS_TRUE_MSG_SUCCESS,
} from "../../constants/roleManagmentConstant";

export const findAllLeadApplicationAction = (pageCount, tabSection) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({ type: APPLICATION_ALL_FIND_LEAD_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let userId = isUserExist.UserId;

    let { data } = await axios.get(
      `/api/admin/application/findAllApplications?id=${userId}&pageCount=${pageCount}&tabSection=${tabSection}`,
      config
    );

    dispatch({ type: APPLICATION_ALL_FIND_LEAD_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: APPLICATION_ALL_FIND_LEAD_ERR,
      payload: error.response.data,
    });
    dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: true });
  }
};

// findByIdLeadApplicationAction
export const findByIdLeadApplicationAction = (leadId) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({ type: APPLICATION_FIND_BY_ID_LEAD_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let userId = isUserExist.UserId;

    let { data } = await axios.get(
      `/api/admin/application/findByIdApplicationDetails?id=${leadId}`,
      config
    );

    dispatch({ type: APPLICATION_FIND_BY_ID_LEAD_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: APPLICATION_FIND_BY_ID_LEAD_ERR,
      payload: error.response.data,
    });
    dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: true });
  }
};

// findAllAsignedStaffAction
export const findAllAsignedStaffAction = (applicationId) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({ type: FIND_ALL_ASIGNED_STAFF_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let userId = isUserExist.UserId;

    let { data } = await axios.get(
      `/api/admin/application/findAllAsignedStaff?id=${applicationId}`,
      config
    );

    dispatch({ type: FIND_ALL_ASIGNED_STAFF_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: FIND_ALL_ASIGNED_STAFF_ERR,
      payload: error.response.data,
    });
    dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: true });
  }
};

// staffIncentiveAmmountAddAction
export const staffIncentiveAmmountAddAction = (incentiveDetails) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({ type: STAFF_ADD_INCENTIVE_AMOUNT_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let userId = isUserExist.UserId;

    let { data } = await axios.post(
      "/api/admin/application/addStaffIncentive",
      incentiveDetails,
      config
    );

    dispatch({ type: STAFF_ADD_INCENTIVE_AMOUNT_SUCCESS, payload: data });
    dispatch({ type: SUCCESS_TRUE_MSG_SUCCESS, payload: true });
  } catch (error) {
    dispatch({
      type: STAFF_ADD_INCENTIVE_AMOUNT_ERR,
      payload: error.response.data,
    });
    dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: true });
  }
};

// findStaffIncentiveAction
export const findStaffIncentiveAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_STAFF_INCENTIVE_AMOUNT_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let userId = isUserExist.UserId;

    let { data } = await axios.get(
      `/api/admin/application/findStaffIncentive?id=${id}`,
      config
    );

    dispatch({ type: GET_STAFF_INCENTIVE_AMOUNT_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: GET_STAFF_INCENTIVE_AMOUNT_ERR,
      payload: error.response.data,
    });
    dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: true });
  }
};

// listBonusEndrolledAppEmployeeAction
export const listBonusEndrolledAppEmployeeAction = () => async (
  dispatch,
  getState
) => {
  try {
    dispatch({ type: GET_BONUS_EMPLOYEES_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let userId = isUserExist.UserId;

    let { data } = await axios.get(
      `/api/admin/application/listBonusEndrolledAppEmployee`,
      config
    );

    dispatch({ type: GET_BONUS_EMPLOYEES_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: GET_BONUS_EMPLOYEES_ERR,
      payload: error.response.data,
    });
    dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: true });
  }
};

// AddEmployeeBonusAction
export const AddEmployeeBonusAction = (details) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({ type: ADD_BONUS_EMPLOYEES_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let userId = isUserExist.UserId;

    let { data } = await axios.post(
      "/api/admin/application/addEmployeeBonus",
      details,
      config
    );

    dispatch({ type: ADD_BONUS_EMPLOYEES_SUCCESS, payload: data });
    dispatch({ type: SUCCESS_TRUE_MSG_SUCCESS, payload: true });
  } catch (error) {
    dispatch({
      type: ADD_BONUS_EMPLOYEES_ERR,
      payload: error.response.data,
    });
    dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: true });
  }
};

// findBonusDetailsAction
export const findBonusDetailsAction = (leadId) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({ type: FIND_BONUS_EMPLOYEES_DETAILS_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let userId = isUserExist.UserId;

    let { data } = await axios.get(
      `/api/admin/application/findEmployeeBonus?id=${leadId}`,
      config
    );

    dispatch({ type: FIND_BONUS_EMPLOYEES_DETAILS_SUCCESS, payload: data });
    dispatch({ type: SUCCESS_TRUE_MSG_SUCCESS, payload: true });
  } catch (error) {
    dispatch({
      type: ADD_BONUS_EMPLOYEES_ERR,
      payload: error.response.data,
    });
    dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: true });
  }
};

// findBonusDetailsAction
export const findApplicationDetailsAction = (applicationId) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({ type: APPLICATION_DETAILS_FIND_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let userId = isUserExist.UserId;

    let { data } = await axios.get(
      `/api/admin/application/findApplicationDetails?id=${applicationId}`,
      config
    );

    dispatch({ type: APPLICATION_DETAILS_FIND_SUCCESS, payload: data });
    dispatch({ type: SUCCESS_TRUE_MSG_SUCCESS, payload: true });
  } catch (error) {
    dispatch({
      type: APPLICATION_DETAILS_FIND_ERR,
      payload: error.response.data,
    });
    dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: true });
  }
};

// ApplicationDeclaration approve action

export const applicationDeclarationApproveAction = (applicationId) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({ type: APPLICATION_DECLARATION_APPROVE_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let userId = isUserExist.UserId;

    let { data } = await axios.post(
      "/api/admin/application/approveDeclaration",
      {
        applicationId: applicationId,
      },
      config
    );

    dispatch({ type: APPLICATION_DECLARATION_APPROVE_SUCCESS, payload: data });
    dispatch({ type: SUCCESS_TRUE_MSG_SUCCESS, payload: true });
  } catch (error) {
    dispatch({
      type: APPLICATION_DECLARATION_APPROVE_ERR,
      payload: error.response.data,
    });
    dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: true });
  }
};

// applicationDeposite create action

export const applicationDepositeCreateAction = (formData) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({ type: APPLICATION_DEPOSITE_CREATE_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let userId = isUserExist.UserId;

    let { data } = await axios.post(
      `/api/admin/application/depositPayment?userID=${userId}`,
      formData,
      config
    );

    dispatch({ type: APPLICATION_DEPOSITE_CREATE_SUCCESS, payload: data });
    dispatch({ type: SUCCESS_TRUE_MSG_SUCCESS, payload: true });
  } catch (error) {
    dispatch({
      type: APPLICATION_DEPOSITE_CREATE_ERR,
      payload: error.response.data,
    });
    dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: true });
  }
};

// applicationPaymenthistoryFind action

export const applicationPaymentHistoryFindAction = (leadID,applicationID) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({ type: APPLICATION_PAYMENTHISTORY_FIND_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let userId = isUserExist.UserId;

    let { data } = await axios.get(
      `/api/admin/application/paymentHistoryFind?leadID=${leadID}&applicationID=${applicationID}`,
      config
    );

    dispatch({ type: APPLICATION_PAYMENTHISTORY_FIND_SUCCESS, payload: data });
    dispatch({ type: SUCCESS_TRUE_MSG_SUCCESS, payload: true });
  } catch (error) {
    dispatch({
      type: APPLICATION_PAYMENTHISTORY_FIND_ERR,
      payload: error.response.data,
    });
    dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: true });
  }
};
