import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { MainContainer } from "../content/content.element";
import CountryFlag from "../customComponent/CountryFlag";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  leadEducationDetailsShowAction,
  leadPersonalDetailsShowAction,
} from "../../actions/leadActions/leadActions";
import {
  countryAllAdmissionDocsFindAction,
  leadCountryCommonDocsFindDocsAction,
  leadCountrySpecificDocsFindingDocsAction,
  leadSopFindingAction,
  leadTraveAndImmigrationFindingAction,
  leadWorkDetailsFindAction,
  shortListMethodFindAction,
} from "../../actions/leadActions/leadDocsActions";
import { BiLeftArrowAlt } from "react-icons/bi";


function EnquiryApplicationList({ toggle }) {
  const dispatch = useDispatch();
  let { id, applicationId } = useParams();
  const navigate = useNavigate();

  // Application details
  const { leadShortListAppliedFindSuccess } = useSelector((state) => {
    return state.shortListMethodFind;
  });

  const [data, setData] = useState({});

  // application details
  useEffect(() => {
   dispatch(shortListMethodFindAction(applicationId));
  }, [applicationId]);

  // setstate

 

  console.log(data, "dataaaaaa");
  return (
    <MainContainer active={toggle}>
      <Box
        sx={{
          width: "auto",
          height: "50px",
          display: "flex",
          alignItems: "center",
          // paddingLeft: "16px",
          backgroundColor: "white",
          borderRadius: "5px",
          m: 1,
        }}
      >
        <BiLeftArrowAlt
          style={{
            fontSize: "600px",
            width: "24px",
            height: "24px",
            cursor: "pointer",
          }}
          onClick={() => navigate(-1)}
        />
        <Typography
          variant="h6"
          sx={{
            fontWeight: 700,
            fontFamily: "Montserrat,sans-serif",
            ml: 1,
            fontSize: "22px",
          }}
        >
          Applications
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "20px",
          mb: "40px",
        }}
      >
       
        <Box
          sx={{
            filter: "drop-shadow(0px 0px 10px #00000012)",
            backgroundColor: "#ffffff",
            padding: "25px",
            maxWidth: "1850px",
          }}
        >
          <Typography
            sx={{
              fontWeight: 700,
              fontFamily: "Montserrat,sans-serif",
              fontSize: "20px",
              lineHeight: "24px",
              mb: "10px",
            }}
          >
            Application details
          </Typography>
          <Box
            sx={{
              display: "grid",
              gap: "20px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
              }}
            >
              <Typography
                sx={{
                  fontWeight: 600,
                  fontFamily: "Montserrat,sans-serif",
                  fontSize: "14px",
                  mt: "0px",
                  lineHeight: "20px",
                }}
              >
                Basic Details
              </Typography>

              <Box
                sx={{
                  display: "grid",
                  gap: "20px",
                  gridTemplateColumns: "repeat(auto-fit,minmax(400px,3fr))",
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                    width: "fit-content",
                  }}
                >
                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "1fr 2fr",
                      gap: "10px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "14px",
                        lineHeight: "24px",
                        color: "#010101",
                      }}
                    >
                      Application ID
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        lineHeight: "24px",
                        color: "#848484",
                      }}
                    >
                      {data?.application?.applicationId
                        ? data?.application?.applicationId
                        : "-"}
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "1fr 2fr",
                      gap: "10px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "14px",
                        lineHeight: "24px",
                        color: "#010101",
                      }}
                    >
                      Application Method
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        lineHeight: "24px",
                        color: "#848484",
                      }}
                    >
                      {data?.application?.applyMethod
                        ? data?.application?.applyMethod
                        : "-"}
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "1fr 2fr",
                      gap: "10px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "14px",
                        lineHeight: "24px",
                        color: "#010101",
                      }}
                    >
                      Date
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        lineHeight: "24px",
                        color: "#848484",
                      }}
                    >
                      {data?.application?.createdAt
                        ? data?.application?.createdAt?.split("T")[0]
                        : "-"}
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "1fr 2fr",
                      gap: "10px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "14px",
                        lineHeight: "24px",
                        color: "#010101",
                      }}
                    >
                      Status
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        lineHeight: "24px",
                        color: "#848484",
                      }}
                    >
                      {data?.application?.methodStatus
                        ? data?.application?.methodStatus
                        : "-"}
                    </Typography>
                  </Box>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                    width: "fit-content",
                  }}
                >
                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "1fr 2fr",
                      gap: "10px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "14px",
                        lineHeight: "24px",
                        color: "#010101",
                      }}
                    >
                      Lead Name
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        lineHeight: "24px",
                        color: "#848484",
                      }}
                    >
                      {data?.application?.lead?.firstName
                        ? data?.application?.lead?.firstName
                        : "-"}
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "1fr 2fr",
                      gap: "10px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "14px",
                        lineHeight: "24px",
                        color: "#010101",
                      }}
                    >
                      University
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        lineHeight: "24px",
                        color: "#848484",
                      }}
                    >
                      {data?.application?.universityName
                        ? data?.application?.universityName
                        : "-"}
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "1fr 2fr",
                      gap: "10px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "14px",
                        lineHeight: "24px",
                        color: "#010101",
                      }}
                    >
                      University ID
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        lineHeight: "24px",
                        color: "#848484",
                      }}
                    >
                      {data?.application?.universityId
                        ? data?.application?.universityId
                        : "-"}
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "1fr 2fr",
                      gap: "10px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "14px",
                        lineHeight: "24px",
                        color: "#010101",
                      }}
                    >
                      Course
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        lineHeight: "24px",
                        color: "#848484",
                      }}
                    >
                      {data?.application?.courseName
                        ? data?.application?.courseName
                        : "-"}
                    </Typography>
                  </Box>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                    width: "fit-content",
                  }}
                >
                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "1fr 2fr",
                      gap: "10px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "14px",
                        lineHeight: "24px",
                        color: "#010101",
                      }}
                    >
                      Interviewer
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        lineHeight: "24px",
                        color: "#848484",
                      }}
                    >
                      {data?.application?.lead?.interviewer
                        ? data?.application?.lead?.interviewer
                        : "-"}
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "1fr 2fr",
                      gap: "10px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "14px",
                        lineHeight: "24px",
                        color: "#010101",
                      }}
                    >
                      VISA Officer
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        lineHeight: "24px",
                        color: "#848484",
                      }}
                    >
                      {data?.application?.lead?.visaHandler
                        ? data?.application?.lead?.visaHandler
                        : "-"}
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "1fr 2fr",
                      gap: "10px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "14px",
                        lineHeight: "24px",
                        color: "#010101",
                      }}
                    >
                      Application Handler
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        lineHeight: "24px",
                        color: "#848484",
                      }}
                    >
                      {data?.application?.lead?.applicationHandler
                        ? data?.application?.lead?.applicationHandler
                        : "-"}
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "1fr 2fr",
                      gap: "10px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "14px",
                        lineHeight: "24px",
                        color: "#010101",
                      }}
                    >
                      University Handler
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        lineHeight: "24px",
                        color: "#848484",
                      }}
                    >
                      {data?.application?.lead?.universityHandler
                        ? data?.application?.lead?.universityHandler
                        : "-"}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
              }}
            >
              <Typography
                sx={{
                  fontWeight: 600,
                  fontFamily: "Montserrat,sans-serif",
                  fontSize: "14px",
                  mt: "0px",
                  lineHeight: "20px",
                }}
              >
                Visa Details
              </Typography>

              <Box
                sx={{
                  display: "grid",
                  gap: "20px",
                  gridTemplateColumns: "repeat(auto-fit,minmax(400px,3fr))",
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                    width: "fit-content",
                  }}
                >
                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "1fr 2fr",
                      gap: "10px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "14px",
                        lineHeight: "24px",
                        color: "#010101",
                      }}
                    >
                      Applied Date :
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        lineHeight: "24px",
                        color: "#848484",
                      }}
                    >
                      {"-"}
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "1fr 2fr",
                      gap: "10px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "14px",
                        lineHeight: "24px",
                        color: "#010101",
                      }}
                    >
                      Card Detail :
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        lineHeight: "24px",
                        color: "#848484",
                      }}
                    >
                      {"-"}
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "1fr 2fr",
                      gap: "10px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "14px",
                        lineHeight: "24px",
                        color: "#010101",
                      }}
                    >
                      Visa Receipt No :
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        lineHeight: "24px",
                        color: "#848484",
                      }}
                    >
                      {"-"}
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "1fr 2fr",
                      gap: "10px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "14px",
                        lineHeight: "24px",
                        color: "#010101",
                      }}
                    >
                      Registration amount :
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        lineHeight: "24px",
                        color: "#848484",
                      }}
                    >
                      {"-"}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
              }}
            >
              <Typography
                sx={{
                  fontWeight: 600,
                  fontFamily: "Montserrat,sans-serif",
                  fontSize: "14px",
                  mt: "0px",
                  lineHeight: "20px",
                }}
              >
                Payment Details
              </Typography>

              <Box
                sx={{
                  display: "grid",
                  gap: "20px",
                  gridTemplateColumns: "repeat(auto-fit,minmax(400px,3fr))",
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                    width: "fit-content",
                  }}
                >
                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "1fr 2fr",
                      gap: "10px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "14px",
                        lineHeight: "24px",
                        color: "#010101",
                      }}
                    >
                      Applied Date :
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        lineHeight: "24px",
                        color: "#848484",
                      }}
                    >
                      -
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "1fr 2fr",
                      gap: "10px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "14px",
                        lineHeight: "24px",
                        color: "#010101",
                      }}
                    >
                      Card Detail
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        lineHeight: "24px",
                        color: "#848484",
                      }}
                    >
                      -
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "1fr 2fr",
                      gap: "10px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "14px",
                        lineHeight: "24px",
                        color: "#010101",
                      }}
                    >
                      Visa Receipt No :
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        lineHeight: "24px",
                        color: "#848484",
                      }}
                    >
                      -
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "1fr 2fr",
                      gap: "10px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "14px",
                        lineHeight: "24px",
                        color: "#010101",
                      }}
                    >
                      Registration amount
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        lineHeight: "24px",
                        color: "#848484",
                      }}
                    >
                      -
                    </Typography>
                  </Box>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                    width: "fit-content",
                  }}
                >
                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "1fr 2fr",
                      gap: "10px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "14px",
                        lineHeight: "24px",
                        color: "#010101",
                      }}
                    >
                      Tuition Fund
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        lineHeight: "24px",
                        color: "#848484",
                      }}
                    >
                      -
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "1fr 2fr",
                      gap: "10px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "14px",
                        lineHeight: "24px",
                        color: "#010101",
                      }}
                    >
                      Source funding
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        lineHeight: "24px",
                        color: "#848484",
                      }}
                    >
                      -
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>

        {/* <Box>
          <Box>
            <Typography
              sx={{
                fontSize: "13px",
                mb: "10px",
              }}
            >
              Please download the form below and upload it after filling it out
              completely.
            </Typography>
          </Box>

          <Box sx={{ display: "flex", alignItems: "center", gap: "20px" }}>
            <Box
              component={"a"}
              href={data?.application?.declarationForm}
              sx={{
                display: "flex",
                gap: "10px",
                alignItems: "center",
                border: "1px solid #84848478",
                padding: "10px",
                borderRadius: "5px",
                textDecoration: "none",
              }}
              download={true}
            >
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: "600",
                  color: "#000",
                }}
              >
                Download Form
              </Typography>
              <Button
                sx={{
                  width: "30px",
                  minWidth: "30px",
                  height: "30px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "red !important",
                  borderRadius: "50%",
                }}
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_1379_26651)">
                    <path
                      d="M10.0006 13.3337C9.82973 13.3337 9.6664 13.2637 9.5489 13.1403L5.1739 8.55699C4.79473 8.16033 5.0764 7.50033 5.62556 7.50033H7.91723V2.70866C7.91723 2.13449 8.38473 1.66699 8.9589 1.66699H11.0422C11.6164 1.66699 12.0839 2.13449 12.0839 2.70866V7.50033H14.3756C14.9247 7.50033 15.2064 8.16033 14.8272 8.55699L10.4522 13.1403C10.3347 13.2637 10.1714 13.3337 10.0006 13.3337Z"
                      fill="white"
                    />
                    <path
                      d="M18.5417 18.3333H1.45833C0.654167 18.3333 0 17.6792 0 16.875V16.4583C0 15.6542 0.654167 15 1.45833 15H18.5417C19.3458 15 20 15.6542 20 16.4583V16.875C20 17.6792 19.3458 18.3333 18.5417 18.3333Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_1379_26651">
                      <rect width="20" height="20" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </Button>
            </Box>

            {Boolean(!file) && (
              <Box
                sx={{
                  "& label": {
                    display: "flex",
                    gap: "10px",
                    alignItems: "center",
                    border: "1px solid #84848478",
                    padding: "10px",
                    borderRadius: "5px",
                    cursor: "pointer",
                  },
                }}
              >
                <label>
                  <input
                    type="file"
                    hidden
                    accept=".pdf"
                    onChange={handleFileSubmit}
                  />
                  <svg
                    width="44"
                    height="28"
                    viewBox="0 0 64 48"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M50.0074 47.2839H39.6633H36.8778H36.2763V33.4089H40.8141C41.9649 33.4089 42.6449 32.1012 41.9649 31.1596L33.1377 18.9455C32.5754 18.1609 31.4115 18.1609 30.8492 18.9455L22.0221 31.1596C21.3421 32.1012 22.009 33.4089 23.1729 33.4089H27.7107V47.2839H27.1091H24.3237H12.3318C5.46629 46.9046 0 40.4837 0 33.5266C0 28.7273 2.60237 24.5425 6.46016 22.2802C6.10707 21.3255 5.92399 20.3055 5.92399 19.2332C5.92399 14.3292 9.88639 10.3668 14.7904 10.3668C15.8496 10.3668 16.8696 10.5499 17.8243 10.903C20.662 4.88746 26.7822 0.71582 33.8962 0.71582C43.1026 0.728898 50.6874 7.77753 51.5505 16.7616C58.6253 17.9778 64 24.5295 64 31.9443C64 39.8691 57.8275 46.7346 50.0074 47.2839Z"
                      fill="#FF1B6F"
                    />
                  </svg>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: "600",
                    }}
                  >
                    Upload Form
                  </Typography>
                </label>
              </Box>
            )}
            {file && (
              <Box>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "50px 1fr",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <Doc />
                  <Typography
                    sx={{
                      fontSize: "13px",
                    }}
                  >
                    {file?.name}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "end",
                    gap: "20px",
                  }}
                >
                  <Typography
                    component={"a"}
                    href={URL.createObjectURL(file)}
                    sx={{
                      fontSize: "14px",
                      color: "rgba(50, 157, 0, 1)",
                      textDecoration: "none",
                      cursor: "pointer",
                    }}
                    target="_blank"
                  >
                    View
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      color: "red",
                      textDecoration: "none",
                      cursor: "pointer",
                    }}
                    onClick={() => setFile()}
                  >
                    Delete
                  </Typography>
                </Box>
              </Box>
            )}
          </Box>

          {Boolean(error?.file) && (
            <Typography sx={{ fontSize: "12px", color: "red" }}>
              {error?.file}
            </Typography>
          )}

          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "30px 1fr",
              gap: "10px",
              mt: "40px",
            }}
          >
            <Checkbox
              sx={{
                padding: "0",
                color: "#f81b82",
                "&.Mui-checked": {
                  color: "#f81b82",
                },
              }}
              checked={checked}
              onChange={() => {
                setChecked(!checked);
                setError(false);
              }}
              inputProps={{ "aria-label": "primary checkbox" }}
            />
            <Typography
              sx={{
                fontSize: "13px",
              }}
            >
              I have reviewed the documents above and hereby declare that all
              the information provided is accurate and correct to the best of my
              knowledge.
            </Typography>
          </Box>

          {Boolean(error?.declare) && (
            <Typography sx={{ fontSize: "12px", color: "red" }}>
              {error?.declare}
            </Typography>
          )}

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
              mt: "20px",
            }}
          >
            <Button
              sx={{
                border: "1px solid #f81b82",
                padding: "2px 20px",
                color: "#f81b82",
              }}
              onClick={handleSubmit}
            >
              {" "}
              submit
            </Button>
          </Box>
        </Box> */}
      </Box>
    </MainContainer>
  );
}

export default EnquiryApplicationList;
