import {
  IELTS_CATEGORY_FIND_ERR,
  IELTS_CATEGORY_FIND_REQUEST,
  IELTS_CATEGORY_FIND_SUCCESS,
  IELTS_CATEGORY_STATUSCHANGE_ERR,
  IELTS_CATEGORY_STATUSCHANGE_REQUEST,
  IELTS_CATEGORY_STATUSCHANGE_SUCCESS,
  IELTS_LEARNINGMANAGMENT_CREATE_TOPIC_ERR,
  IELTS_LEARNINGMANAGMENT_CREATE_TOPIC_REQUEST,
  IELTS_LEARNINGMANAGMENT_CREATE_TOPIC_SUCCESS,
  IELTS_LEARNINGMANAGMENT_ENTIRE_TOPIC_DELETE_ERR,
  IELTS_LEARNINGMANAGMENT_ENTIRE_TOPIC_DELETE_REQUEST,
  IELTS_LEARNINGMANAGMENT_ENTIRE_TOPIC_DELETE_SUCCESS,
  IELTS_LEARNINGMANAGMENT_FIND_TOPIC_BYID_ERR,
  IELTS_LEARNINGMANAGMENT_FIND_TOPIC_BYID_REQUEST,
  IELTS_LEARNINGMANAGMENT_FIND_TOPIC_BYID_SUCCESS,
  IELTS_LEARNINGMANAGMENT_FIND_TOPIC_ERR,
  IELTS_LEARNINGMANAGMENT_FIND_TOPIC_REQUEST,
  IELTS_LEARNINGMANAGMENT_FIND_TOPIC_SUCCESS,
  IELTS_LEARNINGMANAGMENT_TOPIC_DELETE_ERR,
  IELTS_LEARNINGMANAGMENT_TOPIC_DELETE_REQUEST,
  IELTS_LEARNINGMANAGMENT_TOPIC_DELETE_SUCCESS,
  IELTS_LEARNINGMANAGMENT_TOPIC_STATUSCHANGE_ERR,
  IELTS_LEARNINGMANAGMENT_TOPIC_STATUSCHANGE_REQUEST,
  IELTS_LEARNINGMANAGMENT_TOPIC_STATUSCHANGE_SUCCESS,
  IELTS_LEARNINGMANAGMENT_TOPIC_UPDATE_ERR,
  IELTS_LEARNINGMANAGMENT_TOPIC_UPDATE_REQUEST,
  IELTS_LEARNINGMANAGMENT_TOPIC_UPDATE_SUCCESS,
  IELTS_STUDENTS_DETAILS_FIND_ERR,
  IELTS_STUDENTS_DETAILS_FIND_REQUEST,
  IELTS_STUDENTS_DETAILS_FIND_SUCCESS,
  IELTS_STUDENTS_ENTROLLED_FIND_ERR,
  IELTS_STUDENTS_ENTROLLED_FIND_REQUEST,
  IELTS_STUDENTS_ENTROLLED_FIND_SUCCESS,
  IELTS_STUDENTS_STATUSCHANGE_ERR,
  IELTS_STUDENTS_STATUSCHANGE_REQUEST,
  IELTS_STUDENTS_STATUSCHANGE_SUCCESS,
} from "../../constants/Ielts/ieltsConstants";

export const entrolledStudentsReducer = (state = {}, action) => {
  switch (action.type) {
    case IELTS_STUDENTS_ENTROLLED_FIND_REQUEST:
      return {
        ...state,
        ieltsStudentsEntrolledFindLoading: true,
      };
    case IELTS_STUDENTS_ENTROLLED_FIND_SUCCESS:
      return {
        ...state,
        ieltsStudentsEntrolledFindLoading: false,
        ieltsStudentsEntrolledFindSuccess: action.payload,
      };
    case IELTS_STUDENTS_ENTROLLED_FIND_ERR:
      return {
        ...state,
        ieltsStudentsEntrolledFindLoading: false,
        ieltsStudentsEntrolledFinderror: action.payload,
      };
    default:
      return state;
  }
};

// ENTROLLED STUDENTS STATUS CHANGE REDUCER

export const entrolledStudentsStatusChangeReducer = (state = {}, action) => {
  switch (action.type) {
    case IELTS_STUDENTS_STATUSCHANGE_REQUEST:
      return {
        ...state,
        ieltsStudentsStatusChangeLoading: true,
      };
    case IELTS_STUDENTS_STATUSCHANGE_SUCCESS:
      return {
        ...state,
        ieltsStudentsStatusChangeLoading: false,
        ieltsStudentsStatusChangeSuccess: action.payload,
      };
    case IELTS_STUDENTS_STATUSCHANGE_ERR:
      return {
        ...state,
        ieltsStudentsStatusChangeLoading: false,
        ieltsStudentsStatusChangeerror: action.payload,
      };
    default:
      return state;
  }
};

// ENTROLLED STUDENTS DETAILS FIND REDUCER

export const entrolledStudentsDetailsFindReducer = (state = {}, action) => {
  switch (action.type) {
    case IELTS_STUDENTS_DETAILS_FIND_REQUEST:
      return {
        ...state,
        ieltsStudentsDetailsFindLoading: true,
      };
    case IELTS_STUDENTS_DETAILS_FIND_SUCCESS:
      return {
        ...state,
        ieltsStudentsDetailsFindLoading: false,
        ieltsStudentsDetailsFindSuccess: action.payload,
      };
    case IELTS_STUDENTS_DETAILS_FIND_ERR:
      return {
        ...state,
        ieltsStudentsDetailsFindLoading: false,
        ieltsStudentsDetailsFinderror: action.payload,
      };
    default:
      return state;
  }
};

// IELTS CATEGORY FIND REDUCER

export const ieltsCategoryFindReducer = (state = {}, action) => {
  switch (action.type) {
    case IELTS_CATEGORY_FIND_REQUEST:
      return {
        ...state,
        ieltsCategoryFindLoading: true,
      };
    case IELTS_CATEGORY_FIND_SUCCESS:
      return {
        ...state,
        ieltsCategoryFindLoading: false,
        ieltsCategoryFindSuccess: action.payload,
      };
    case IELTS_CATEGORY_FIND_ERR:
      return {
        ...state,
        ieltsCategoryFindLoading: false,
        ieltsCategoryFinderror: action.payload,
      };
    default:
      return state;
  }
};

// IELTS CATEGORY STATUS CHANGE REDUCER

export const ieltsCategoryStatusChangeReducer = (state = {}, action) => {
  switch (action.type) {
    case IELTS_CATEGORY_STATUSCHANGE_REQUEST:
      return {
        ...state,
        ieltsCategoryStatusChangeLoading: true,
      };
    case IELTS_CATEGORY_STATUSCHANGE_SUCCESS:
      return {
        ...state,
        ieltsCategoryStatusChangeLoading: false,
        ieltsCategoryStatusChangeSuccess: action.payload,
      };
    case IELTS_CATEGORY_STATUSCHANGE_ERR:
      return {
        ...state,
        ieltsCategoryStatusChangeLoading: false,
        ieltsCategoryStatusChangeerror: action.payload,
      };
    default:
      return state;
  }
};

// IELTS LEARNING MANAGMENT CREATE TOPIC REDUCER

export const ieltsLearningManagmentCreateTopicReducer = (
  state = {},
  action
) => {
  switch (action.type) {
    case IELTS_LEARNINGMANAGMENT_CREATE_TOPIC_REQUEST:
      return {
        ...state,
        ieltsLearningManagmentCreateTopicLoading: true,
      };
    case IELTS_LEARNINGMANAGMENT_CREATE_TOPIC_SUCCESS:
      return {
        ...state,
        ieltsLearningManagmentCreateTopicLoading: false,
        ieltsLearningManagmentCreateTopicSuccess: action.payload,
      };
    case IELTS_LEARNINGMANAGMENT_CREATE_TOPIC_ERR:
      return {
        ...state,
        ieltsLearningManagmentCreateTopicLoading: false,
        ieltsLearningManagmentCreateTopicerror: action.payload,
      };
    default:
      return state;
  }
};

// IELTS LEARNING MANAGMENT FIND TOPIC REDUCER

export const ieltsLearningManagmentFindTopicReducer = (state = {}, action) => {
  switch (action.type) {
    case IELTS_LEARNINGMANAGMENT_FIND_TOPIC_REQUEST:
      return {
        ...state,
        ieltsLearningManagmentFindTopicLoading: true,
      };
    case IELTS_LEARNINGMANAGMENT_FIND_TOPIC_SUCCESS:
      return {
        ...state,
        ieltsLearningManagmentFindTopicLoading: false,
        ieltsLearningManagmentFindTopicSuccess: action.payload,
      };
    case IELTS_LEARNINGMANAGMENT_FIND_TOPIC_ERR:
      return {
        ...state,
        ieltsLearningManagmentFindTopicLoading: false,
        ieltsLearningManagmentFindTopicerror: action.payload,
      };
    default:
      return state;
  }
};

// IELTS LEARNING MANAGMENT FIND TOPIC BYID REDUCER

export const ieltsLearningManagmentFindTopicByIdReducer = (
  state = {},
  action
) => {
  switch (action.type) {
    case IELTS_LEARNINGMANAGMENT_FIND_TOPIC_BYID_REQUEST:
      return {
        ...state,
        ieltsLearningManagmentFindTopicByIdLoading: true,
      };
    case IELTS_LEARNINGMANAGMENT_FIND_TOPIC_BYID_SUCCESS:
      return {
        ...state,
        ieltsLearningManagmentFindTopicByIdLoading: false,
        ieltsLearningManagmentFindTopicByIdSuccess: action.payload,
      };
    case IELTS_LEARNINGMANAGMENT_FIND_TOPIC_BYID_ERR:
      return {
        ...state,
        ieltsLearningManagmentFindTopicByIdLoading: false,
        ieltsLearningManagmentFindTopicByIderror: action.payload,
      };
    default:
      return state;
  }
};

// IELTS LEARNING MANAGMENT TOPIC STATUSCHANGE REDUCER

export const ieltsLearningManagmentTopicStatusChangeReducer = (
  state = {},
  action
) => {
  switch (action.type) {
    case IELTS_LEARNINGMANAGMENT_TOPIC_STATUSCHANGE_REQUEST:
      return {
        ...state,
        ieltsLearningManagmentTopicStatusChangeLoading: true,
      };
    case IELTS_LEARNINGMANAGMENT_TOPIC_STATUSCHANGE_SUCCESS:
      return {
        ...state,
        ieltsLearningManagmentTopicStatusChangeLoading: false,
        ieltsLearningManagmentTopicStatusChangeSuccess: action.payload,
      };
    case IELTS_LEARNINGMANAGMENT_TOPIC_STATUSCHANGE_ERR:
      return {
        ...state,
        ieltsLearningManagmentTopicStatusChangeLoading: false,
        ieltsLearningManagmentTopicStatusChangeerror: action.payload,
      };
    default:
      return state;
  }
};


// IELTS LEARNING MANAGMENT TOPIC UPDATE REDUCER

export const ieltsLearningManagmentTopicUpdateReducer = (
  state = {},
  action
) => {
  switch (action.type) {
    case IELTS_LEARNINGMANAGMENT_TOPIC_UPDATE_REQUEST:
      return {
        ...state,
        ieltsLearningManagmentTopicUpdateLoading: true,
      };
    case IELTS_LEARNINGMANAGMENT_TOPIC_UPDATE_SUCCESS:
      return {
        ...state,
        ieltsLearningManagmentTopicUpdateLoading: false,
        ieltsLearningManagmentTopicUpdateSuccess: action.payload,
      };
    case IELTS_LEARNINGMANAGMENT_TOPIC_UPDATE_ERR:
      return {
        ...state,
        ieltsLearningManagmentTopicUpdateLoading: false,
        ieltsLearningManagmentTopicUpdateerror: action.payload,
      };
    default:
      return state;
  }
};

// IELTS LEARNING MANAGMENT TOPIC DELETE REDUCER

export const ieltsLearningManagmentTopicDeleteReducer = (
  state = {},
  action
) => {
  switch (action.type) {
    case IELTS_LEARNINGMANAGMENT_TOPIC_DELETE_REQUEST:
      return {
        ...state,
        ieltsLearningManagmentTopicDeleteLoading: true,
      };
    case IELTS_LEARNINGMANAGMENT_TOPIC_DELETE_SUCCESS:
      return {
        ...state,
        ieltsLearningManagmentTopicDeleteLoading: false,
        ieltsLearningManagmentTopicDeleteSuccess: action.payload,
      };
    case IELTS_LEARNINGMANAGMENT_TOPIC_DELETE_ERR:
      return {
        ...state,
        ieltsLearningManagmentTopicDeleteLoading: false,
        ieltsLearningManagmentTopicDeleteerror: action.payload,
      };
    default:
      return state;
  }
};


// IELTS LEARNING MANAGMENT TOPIC DELETE REDUCER

export const ieltsLearningManagmentEntireTopicDeleteReducer = (
  state = {},
  action
) => {
  switch (action.type) {
    case IELTS_LEARNINGMANAGMENT_ENTIRE_TOPIC_DELETE_REQUEST:
      return {
        ...state,
        ieltsLearningManagmentEntireTopicDeleteLoading: true,
      };
    case IELTS_LEARNINGMANAGMENT_ENTIRE_TOPIC_DELETE_SUCCESS:
      return {
        ...state,
        ieltsLearningManagmentEntireTopicDeleteLoading: false,
        ieltsLearningManagmentEntireTopicDeleteSuccess: action.payload,
      };
    case IELTS_LEARNINGMANAGMENT_ENTIRE_TOPIC_DELETE_ERR:
      return {
        ...state,
        ieltsLearningManagmentEntireTopicDeleteLoading: false,
        ieltsLearningManagmentEntireTopicDeleteerror: action.payload,
      };
    default:
      return state;
  }
};

