import React, { useState, startTransition } from "react";
import HeadBreadcrumbs from "../breadcrumbs/breadcrumbs";
import { MainContainer } from "../content/content.element";
import BasicTabs from "../Tabs/TabsMain";
import LeadsDrop from "./LeadsDrop";
import { Skeleton, Typography } from "@mui/material";
import RecalledLeads from "./RecalledLeads";

const All_leadListTable = React.lazy(() => import("./All_leadListTable"));
const DealsMain = React.lazy(() => import("./DealsMain"));
const LeadsPending = React.lazy(() => import("./LeadsPending"));
const LeadApprovals = React.lazy(() => import("./LeadApprovals/leadApprovals"));
const LeadsDeferral = React.lazy(() => import("./LeadsDeferral"));
const LeadDead = React.lazy(() => import("./LeadDead"));

function DealsMenuMain(props) {
  const [whichPage, setWhichPage] = useState(0);
  const [isPending, setIsPending] = useState(false);

  const fetchData = async () => {
    setIsPending(true);
    await new Promise((resolve) => setTimeout(resolve, 0)); // Simulate async fetch
    setIsPending(false);
  };

  const callback = (index) => {
    startTransition(() => {
      fetchData().then(() => {
        setWhichPage(index);
      });
    });
  };

  return (
    <>
      <MainContainer active={props.toggle}>
        <>
          <HeadBreadcrumbs
            head="home"
            sechead="Leads"
            first="Leads"
            main={
              whichPage === 0
                ? "Deals"
                : whichPage === 1
                ? "Deferral Leads"
                : whichPage === 2
                ? "Dead Leads"
                : null
            }
          />
          <BasicTabs
            // tab1Name="All Leads"
            tab1Name="Deals"
            // tab3Name="Document Approval"
            // tab2Name="Approval requests"
            tab2Name="Deferral"
            // tab6Name="Dead Leads"
            tab3Name="Drop Leads"
            // tab8Name="Recalled Leads"
            // tab1={
            //   <React.Suspense
            //     fallback={
            //       <div
            //         style={{ textAlign: "center", padding: "1px,1px,1px,1px" }}
            //       >
            //         <>
            //           {/* <Skeleton /> */}
            //           <Typography variant="h1">
            //             <Skeleton animation={false} />
            //           </Typography>

            //           <Typography variant="h3">
            //             <Skeleton animation="wave" />
            //           </Typography>

            //           <Skeleton animation={false} />
            //           <Typography variant="h3">
            //             <Skeleton animation="wave" />
            //           </Typography>

            //           <Skeleton animation={false} />
            //           <Typography variant="h3">
            //             <Skeleton animation="wave" />
            //           </Typography>

            //           <Skeleton animation={false} />
            //           <Typography variant="h3">
            //             <Skeleton animation="wave" />
            //           </Typography>

            //           <Skeleton animation={false} />
            //         </>
            //       </div>
            //     }
            //   >
            //     <All_leadListTable toggle={props.toggle} />
            //   </React.Suspense>
            // }
            tab1={
              <React.Suspense
                fallback={
                  <div
                    style={{ textAlign: "center", padding: "1px,1px,1px,1px" }}
                  >
                    <>
                      {/* <Skeleton /> */}
                      <Typography variant="h1">
                        <Skeleton animation={false} />
                      </Typography>

                      <Typography variant="h3">
                        <Skeleton animation="wave" />
                      </Typography>

                      <Skeleton animation={false} />
                      <Typography variant="h3">
                        <Skeleton animation="wave" />
                      </Typography>

                      <Skeleton animation={false} />
                      <Typography variant="h3">
                        <Skeleton animation="wave" />
                      </Typography>

                      <Skeleton animation={false} />
                      <Typography variant="h3">
                        <Skeleton animation="wave" />
                      </Typography>

                      <Skeleton animation={false} />
                    </>
                  </div>
                }
              >
                <DealsMain toggle={props.toggle} />
              </React.Suspense>
            }
            // tab3={
            //   <React.Suspense
            //     fallback={
            //       <div
            //         style={{ textAlign: "center", padding: "1px,1px,1px,1px" }}
            //       >
            //         <>
            //           {/* <Skeleton /> */}
            //           <Typography variant="h1">
            //             <Skeleton animation={false} />
            //           </Typography>

            //           <Typography variant="h3">
            //             <Skeleton animation="wave" />
            //           </Typography>

            //           <Skeleton animation={false} />
            //           <Typography variant="h3">
            //             <Skeleton animation="wave" />
            //           </Typography>

            //           <Skeleton animation={false} />
            //           <Typography variant="h3">
            //             <Skeleton animation="wave" />
            //           </Typography>

            //           <Skeleton animation={false} />
            //           <Typography variant="h3">
            //             <Skeleton animation="wave" />
            //           </Typography>

            //           <Skeleton animation={false} />
            //         </>
            //       </div>
            //     }
            //   >
            //     <LeadsPending toggle={props.toggle} />
            //   </React.Suspense>
            // }
            // tab2={
            //   <React.Suspense
            //     fallback={
            //       <div
            //         style={{ textAlign: "center", padding: "1px,1px,1px,1px" }}
            //       >
            //         <>
            //           {/* <Skeleton /> */}
            //           <Typography variant="h1">
            //             <Skeleton animation={false} />
            //           </Typography>

            //           <Typography variant="h3">
            //             <Skeleton animation="wave" />
            //           </Typography>

            //           <Skeleton animation={false} />
            //           <Typography variant="h3">
            //             <Skeleton animation="wave" />
            //           </Typography>

            //           <Skeleton animation={false} />
            //           <Typography variant="h3">
            //             <Skeleton animation="wave" />
            //           </Typography>

            //           <Skeleton animation={false} />
            //           <Typography variant="h3">
            //             <Skeleton animation="wave" />
            //           </Typography>

            //           <Skeleton animation={false} />
            //         </>
            //       </div>
            //     }
            //   >
            //     <LeadApprovals toggle={props.toggle} />
            //   </React.Suspense>
            // }
            tab2={
              <React.Suspense
                fallback={
                  <div
                    style={{ textAlign: "center", padding: "1px,1px,1px,1px" }}
                  >
                    <>
                      {/* <Skeleton /> */}
                      <Typography variant="h1">
                        <Skeleton animation={false} />
                      </Typography>

                      <Typography variant="h3">
                        <Skeleton animation="wave" />
                      </Typography>

                      <Skeleton animation={false} />
                      <Typography variant="h3">
                        <Skeleton animation="wave" />
                      </Typography>

                      <Skeleton animation={false} />
                      <Typography variant="h3">
                        <Skeleton animation="wave" />
                      </Typography>

                      <Skeleton animation={false} />
                      <Typography variant="h3">
                        <Skeleton animation="wave" />
                      </Typography>

                      <Skeleton animation={false} />
                    </>
                  </div>
                }
              >
                <LeadsDeferral toggle={props.toggle} />
              </React.Suspense>
            }
            // tab6={
            //   <React.Suspense
            //     fallback={
            //       <div
            //         style={{ textAlign: "center", padding: "1px,1px,1px,1px" }}
            //       >
            //         <>
            //           {/* <Skeleton /> */}
            //           <Typography variant="h1">
            //             <Skeleton animation={false} />
            //           </Typography>

            //           <Typography variant="h3">
            //             <Skeleton animation="wave" />
            //           </Typography>

            //           <Skeleton animation={false} />
            //           <Typography variant="h3">
            //             <Skeleton animation="wave" />
            //           </Typography>

            //           <Skeleton animation={false} />
            //           <Typography variant="h3">
            //             <Skeleton animation="wave" />
            //           </Typography>

            //           <Skeleton animation={false} />
            //           <Typography variant="h3">
            //             <Skeleton animation="wave" />
            //           </Typography>

            //           <Skeleton animation={false} />
            //         </>
            //       </div>
            //     }
            //   >
            //     <LeadDead toggle={props.toggle} />
            //   </React.Suspense>
            // }
            tab3={
              <React.Suspense
                fallback={
                  <div
                    style={{ textAlign: "center", padding: "1px,1px,1px,1px" }}
                  >
                    <>
                      {/* <Skeleton /> */}
                      <Typography variant="h1">
                        <Skeleton animation={false} />
                      </Typography>

                      <Typography variant="h3">
                        <Skeleton animation="wave" />
                      </Typography>

                      <Skeleton animation={false} />
                      <Typography variant="h3">
                        <Skeleton animation="wave" />
                      </Typography>

                      <Skeleton animation={false} />
                      <Typography variant="h3">
                        <Skeleton animation="wave" />
                      </Typography>

                      <Skeleton animation={false} />
                      <Typography variant="h3">
                        <Skeleton animation="wave" />
                      </Typography>

                      <Skeleton animation={false} />
                    </>
                  </div>
                }
              >
                <LeadsDrop toggle={props.toggle} />
              </React.Suspense>
            }
            // tab8={
            //   <React.Suspense
            //     fallback={
            //       <div
            //         style={{ textAlign: "center", padding: "1px,1px,1px,1px" }}
            //       >
            //         <>
            //           {/* <Skeleton /> */}
            //           <Typography variant="h1">
            //             <Skeleton animation={false} />
            //           </Typography>

            //           <Typography variant="h3">
            //             <Skeleton animation="wave" />
            //           </Typography>

            //           <Skeleton animation={false} />
            //           <Typography variant="h3">
            //             <Skeleton animation="wave" />
            //           </Typography>

            //           <Skeleton animation={false} />
            //           <Typography variant="h3">
            //             <Skeleton animation="wave" />
            //           </Typography>

            //           <Skeleton animation={false} />
            //           <Typography variant="h3">
            //             <Skeleton animation="wave" />
            //           </Typography>

            //           <Skeleton animation={false} />
            //         </>
            //       </div>
            //     }
            //   >
            //     <RecalledLeads toggle={props.toggle} />
            //   </React.Suspense>
            // }
            head="Home"
            sechead="lead"
            callback={callback}
          />
        </>
      </MainContainer>
    </>
  );
}

export default React.memo(DealsMenuMain);
