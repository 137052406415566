import React, { useEffect, useState } from "react";
import { MdClear } from "react-icons/md";
import { AiOutlineSearch } from "react-icons/ai";

import ListSubheader from "@mui/material/ListSubheader";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import StarBorder from "@mui/icons-material/StarBorder";
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { RiSearch2Line } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
// import { findcountryMasterAddAction } from "../../actions/countryMasterAction";

function EmployeeJobFilterSearch(props) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [secListopen, setSecListOpen] = useState(false);
  const [thirdListopen, setThirdListOpen] = useState(false);
  const [fourthListopen, setFourthListOpen] = useState(false);
  const [fifthListopen, setFifthListOpen] = useState(false);
  const [sixthListopen, setSixthListOpen] = useState(false);
  const [sevenListopen, seventhListOpen] = useState(false);
  //   const [cityFind, setCityFind] = useState("");
  //   const [countryList, setCountryList] = useState([]);

  //   let { countryFindLoading, countryFindSuccess } = useSelector((state) => {
  //     return state.findcountryMasterAdd;
  //   });

  //   useEffect(() => {
  //     dispatch(findcountryMasterAddAction());
  //   }, [dispatch]);

  //   useEffect(() => {
  //     if (countryFindSuccess) {
  //       setCountryList(countryFindSuccess);
  //     }
  //   }, [countryFindSuccess]);

  //   const handleSubmit = (e, chipName) => {
  //     e.preventDefault();
  // dispatch(city(cityFind, id, "cityFind"));
  // props.LeadFilterFunction(cityFind, chipName);
  // setCityFind("");
  // Add your logic here to handle the form submission with the entered application fee
  //   };

  const handleClick = () => {
    setOpen(!open);
  };
  const handleClick2 = () => {
    setSecListOpen(!secListopen);
  };
  const handleClick3 = () => {
    setThirdListOpen(!thirdListopen);
  };
  const handleClick4 = () => {
    setFourthListOpen(!fourthListopen);
  };
  const handleClick5 = () => {
    setFifthListOpen(!fifthListopen);
  };
  const handleClick6 = () => {
    setSixthListOpen(!sixthListopen);
  };
  const handleClick7 = () => {
    seventhListOpen(!sevenListopen);
  };

  //   const handleClearClick = () => {
  //     // setSelectedDate(e.target.value);
  //     setCityFind("");
  //     props.handleResetData(true);
  //   };

  //   const handleChange = (e) => {
  //     const searchText = e.target.value.toLowerCase();

  //     if (searchText === "") {
  //       setCountryList(countryFindSuccess); // Reset to the original data when search text is empty
  //     } else {
  //       setCountryList((chips) =>
  //         chips.filter((data) =>
  //           data.countryName.toLowerCase().includes(searchText)
  //         )
  //       );
  //     }
  //   };

  //   const handleCheckCountry = (event, branchId) => {
  //     props.LeadFilterFunction(
  //       branchId,
  //       "preffered country",
  //       event.target.checked
  //     );
  // const searchText = event.target.cheked
  // alert(searchText)

  // if (searchText === false) {
  //   setCountryList(countryFindSuccess); // Reset to the original data when search text is empty
  // } else {
  //   setCountryList((chips) =>
  //     chips.filter((data) =>
  //       data.countryName.toLowerCase().includes(searchText)
  //     )
  //   );
  // }
  //   };

  // country

  return (
    <>
      <List
        sx={{
          // width:"fit-content",
          maxWidth: "316px",
          // height: "620px",
          height: "auto",
          bgcolor: "background.paper",
          borderRadius: "8px",
          backgroundColor: "rgba(255, 255, 255, 0.5)",
          // overflow: "scroll",
          // overflowX: "hidden",
          border: "1px solid #ECECEC",
          mt: 2.5,
        }}
        component="nav"
        aria-labelledby="nested-list-subheader"
        subheader={
          <ListSubheader
            component="div"
            id="nested-list-subheader"
            sx={{ backgroundColor: "#F2F2F2" }}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>Filter</div>
              <div
                style={{
                  display: "flex",
                  marginTop: "12px",
                  cursor: "pointer",
                }}
                // onClick={handleClearClick}
              >
                <MdClear style={{ fontSize: "22px", height: "20px" }} />{" "}
                <Typography>Clear All</Typography>
              </div>
            </div>
          </ListSubheader>
        }
      >
        {props.statusComponent === "EmployeeJobs" && (
          <>
            {/* first List */}
            <ListItemButton onClick={handleClick}>
              <ListItemText primary="Title" sx={{fontWeight:600,fontSize:"14px",fontFamily:'Open Sans'}}/>
              {open ? (
                <ExpandLess sx={{ fontSize: "17px", fontWeight: "thin" }} />
              ) : (
                <ExpandMore sx={{ fontSize: "17px", fontWeight: "thin" }} />
              )}
            </ListItemButton>
            {/* collapse */}
            <Collapse in={open} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton sx={{ textAlign: "center" }}>
                  <form>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <TextField
                        variant="outlined"
                        id="city"
                        placeholder="Search Here"
                        // value={cityFind}
                        // onChange={(e) => setCityFind(e.target.value)}
                        sx={{
                          "& .MuiInputBase-input": {
                            borderRadius: "20px",
                            height: "1px",
                          },
                          "& .MuiFormLabel-root": {
                            lineHeight: "5px",
                          },

                          "& .MuiInputBase-root": {
                            borderRadius: "3px",
                            borderColor: "#fafafa",
                          },
                        }}
                      />
                      <>
                        <Box
                          component="button"
                          sx={{
                            width: "40px",
                            height: "34px",
                            textAlign: "center",
                            border: "1px solid #9d9fa1",
                            borderBottomRightRadius: "5px",
                            WebkitBorderTopRightRadius: "5px",
                            fontSize: "24px",
                          }}
                          type="submit"
                        >
                          <InputAdornment position="end">
                            <RiSearch2Line />
                          </InputAdornment>
                        </Box>
                      </>
                    </div>
                  </form>
                </ListItemButton>
              </List>
            </Collapse>
            {/* second List */}
            <ListItemButton onClick={handleClick2}>
              <ListItemText primary="Location" />
              {secListopen ? (
                <ExpandLess sx={{ fontSize: "17px", fontWeight: "thin" }} />
              ) : (
                <ExpandMore sx={{ fontSize: "17px", fontWeight: "thin" }} />
              )}
            </ListItemButton>
            <Collapse in={secListopen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton>
                  <TextField
                    placeholder="Search Here"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment>
                          <IconButton>
                            <AiOutlineSearch />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    // onChange={handleChange}
                    sx={{
                      backgroundColor: "white",
                      margin: 1,
                      width: "auto",
                      "& .MuiInputBase-input": {
                        borderRadius: "20px",
                        height: "1px",
                      },
                      "& .MuiFormLabel-root": {
                        lineHeight: "5px",
                      },

                      "& .MuiInputBase-root": {
                        borderRadius: "3px",
                        borderColor: "#fafafa",
                      },
                    }}
                  />
                </ListItemButton>

                <ListItemButton>
                  <FormGroup>
                    {/* {countryList &&
                      countryList?.map((country) => ( */}
                    <FormControlLabel
                      control={
                        <Checkbox
                          sx={{
                            color: "#F8469A",
                            "&.Mui-checked": {
                              color: "#F8469A",
                            },
                          }}
                          //   onChange={(e) => {
                          //     handleCheckCountry(
                          //       e,
                          //       country.countryName.split("(")[0]
                          //     );
                          //   }}
                        />
                      }
                      //   label={country.countryName.split("(")[0]}
                    />
                    {/* ))} */}
                    {/* <FormControlLabel
                      control={
                        <Checkbox
                          sx={{
                            color: "#F8469A",
                            "&.Mui-checked": {
                              color: "#F8469A",
                            },
                          }}
                        />
                      }
                      label="View all..."
                    /> */}
                  </FormGroup>
                </ListItemButton>
              </List>
              {/* <ListItemButton>
                <Typography color="#F8469A" variant="p">
                  view all ...
                </Typography>
              </ListItemButton> */}
            </Collapse>
            {/* third List */}
            <ListItemButton onClick={handleClick3}>
              <ListItemText primary="Type" />
              {thirdListopen ? (
                <ExpandLess sx={{ fontSize: "17px", fontWeight: "thin" }} />
              ) : (
                <ExpandMore sx={{ fontSize: "17px", fontWeight: "thin" }} />
              )}
            </ListItemButton>
            {/* collapse */}
            <Collapse in={thirdListopen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {/* <ListItemButton>
                  <ListItemIcon>
                    <StarBorder />
                  </ListItemIcon>
                  <ListItemText primary="Starred" />
                </ListItemButton> */}
              </List>
            </Collapse>
            {/* fourth List */}
            <ListItemButton onClick={handleClick4}>
              <ListItemText primary="Experience" />
              {fourthListopen ? (
                <ExpandLess sx={{ fontSize: "17px", fontWeight: "thin" }} />
              ) : (
                <ExpandMore sx={{ fontSize: "17px", fontWeight: "thin" }} />
              )}
            </ListItemButton>
            {/* collapse */}
            <Collapse in={fourthListopen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {/* <ListItemButton>
                  <ListItemIcon>
                    <StarBorder />
                  </ListItemIcon>
                  <ListItemText primary="Starred" />
                </ListItemButton> */}
              </List>
            </Collapse>
            {/* fifth List */}
            <ListItemButton onClick={handleClick5}>
              <ListItemText primary="Vacancy" />
              {fifthListopen ? (
                <ExpandLess sx={{ fontSize: "17px", fontWeight: "thin" }} />
              ) : (
                <ExpandMore sx={{ fontSize: "17px", fontWeight: "thin" }} />
              )}
            </ListItemButton>
            {/* collapse */}
            <Collapse in={fifthListopen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {/* <ListItemButton>
                  <ListItemIcon>
                    <StarBorder />
                  </ListItemIcon>
                  <ListItemText primary="Starred" />
                </ListItemButton> */}
              </List>
            </Collapse>
            {/* sixth List */}
            <ListItemButton onClick={handleClick6}>
              <ListItemText primary="Posted Date" />
              {sixthListopen ? (
                <ExpandLess sx={{ fontSize: "17px", fontWeight: "thin" }} />
              ) : (
                <ExpandMore sx={{ fontSize: "17px", fontWeight: "thin" }} />
              )}
            </ListItemButton>
            {/* collapse */}
            <Collapse in={sixthListopen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {/* <ListItemButton>
                  <ListItemIcon>
                    <StarBorder />
                  </ListItemIcon>
                  <ListItemText primary="Starred" />
                </ListItemButton> */}
              </List>
            </Collapse>
          </>
        )}

        {/* {props.statusComponent === "EmployeeJobs" && (
            {/* first List */}
            {/* <ListItemButton onClick={handleClick}>
              <ListItemText primary="Branch" />
              {open ? (
                <ExpandLess sx={{ fontSize: "17px", fontWeight: "thin" }} />
              ) : (
                <ExpandMore sx={{ fontSize: "17px", fontWeight: "thin" }} />
              )}
            </ListItemButton> */} 

            {/* collapse */}
            {/* <Collapse in={open} timeout="auto" unmountOnExit>
              <List component="div" disablePadding> */}
                {/* <ListItemButton sx={{ textAlign: "center" }}>
                  <form onSubmit={(e) => handleSubmit(e, "Branch")}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <TextField
                        variant="outlined"
                        id="city"
                        placeholder="Search Here"
                        value={cityFind}
                        onChange={(e) => setCityFind(e.target.value)}
                        sx={{
                          "& .MuiInputBase-input": {
                            borderRadius: "20px",
                            height: "1px",
                          },
                          "& .MuiFormLabel-root": {
                            lineHeight: "5px",
                          },

                          "& .MuiInputBase-root": {
                            borderRadius: "3px",
                            borderColor: "#fafafa",
                          },
                        }}
                      />
                      <>
                        <Box
                          component="button"
                          sx={{
                            width: "40px",
                            height: "34px",
                            textAlign: "center",
                            border: "1px solid #9d9fa1",
                            borderBottomRightRadius: "5px",
                            WebkitBorderTopRightRadius: "5px",
                            fontSize: "24px",
                          }}
                          type="submit"
                        >
                          <InputAdornment position="end">
                            <RiSearch2Line />
                          </InputAdornment>
                        </Box>
                      </>
                    </div>
                  </form>
                </ListItemButton> */}
              {/* </List>
            </Collapse> */}

            {/* second List */}
            {/* <ListItemButton onClick={handleClick2}>
              <ListItemText primary="Location" />
              {secListopen ? (
                <ExpandLess sx={{ fontSize: "17px", fontWeight: "thin" }} />
              ) : (
                <ExpandMore sx={{ fontSize: "17px", fontWeight: "thin" }} />
              )}
            </ListItemButton>

            <Collapse in={secListopen} timeout="auto" unmountOnExit> */}
              {/* <List component="div" disablePadding> */}
              {/* <ListItemButton>
                  <TextField
                    placeholder="Search Here"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment>
                          <IconButton>
                            <AiOutlineSearch />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    sx={{
                      backgroundColor: "white",
                      margin: 1,
                      width: "auto",
                      "& .MuiInputBase-input": {
                        borderRadius: "20px",
                        height: "1px",
                      },
                      "& .MuiFormLabel-root": {
                        lineHeight: "5px",
                      },

                      "& .MuiInputBase-root": {
                        borderRadius: "3px",
                        borderColor: "#fafafa",
                      },
                    }}
                  />
                </ListItemButton> */}
              {/* 
                <ListItemButton>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          sx={{
                            color: "#F8469A",
                            "&.Mui-checked": {
                              color: "#F8469A",
                            },
                          }}
                        />
                      }
                      label="united kindom"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          sx={{
                            color: "#F8469A",
                            "&.Mui-checked": {
                              color: "#F8469A",
                            },
                          }}
                        />
                      }
                      label="Required"
                    />
                  </FormGroup>
                </ListItemButton>
              </List> */}
              {/* <ListItemButton>
                <Typography color="#F8469A" variant="p">
                  view all ...
                </Typography>
              </ListItemButton> */}
            {/* </Collapse> */}
          
        {/* )} */}
      </List>
    </>
  );
}

export default EmployeeJobFilterSearch;
