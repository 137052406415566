import React, { useState } from "react";

import { MainContainer } from "../content/content.element";
import HeadBreadcrumbs from "../breadcrumbs/breadcrumbs";
import BasicTabs from "../Tabs/TabsMain";

function AdministrationMain(props) {
  const [tabValue, setTabValue] = useState(0);

  return (
    <>
      <MainContainer active={props.toggle}>
        {tabValue === 0 && (
          <HeadBreadcrumbs
            head="home"
            sechead="Administration"
            first="Administration"
            main="Role Managment"
          />
        )}

        {tabValue === 1 && (
          <HeadBreadcrumbs
            head="home"
            sechead="Administration"
            first="Administration"
            main="Employee Managment"
          />
        )}
{/* 
        {tabValue === 2 && (
          <HeadBreadcrumbs
            head="home"
            sechead="Administration"
            first="Administration"
            main="Student Managment"
          />
        )} */}
        <BasicTabs
          tab1={props.tab1}
          tab2={props.tab2}
          callback={(data) => setTabValue(data)}
          tab1Name="Role Management"
          tab2Name="Employee Management"
          // tab3Name="Student Management"
          head="Home"
          sechead="role"
        />
      </MainContainer>
    </>
  );
}

export default AdministrationMain;
