import React, { useState } from "react";
import { MdClear } from "react-icons/md";
import ListSubheader from "@mui/material/ListSubheader";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

import {
  Checkbox,
  Chip,
  FormControlLabel,
  FormGroup,
  Typography,
} from "@mui/material";
import { CustomDateField } from "../../customComponent/InputField";

function WithDrawalFilterSearch({ LeadFilterFunction, handleResetData }) {
  const [selectedCategory, setSelectedCategory] = useState("");
  const [firstListopen, setFirstListOpen] = useState(false);
  const [dateListopen, setDateListOpen] = useState(false);
  const [date, setDate] = useState();
  const statusList = ["Pending", "Paid"];

  const handleClearAllFunction = () => {
    setSelectedCategory(null);
    handleResetData();
    setDate("")
  };

  const handleClick4 = () => {
    setDateListOpen(!dateListopen);
  };

  const handleClick1 = () => {
    setFirstListOpen(!firstListopen);
  };

  const handleClearClick = () => {
    handleResetData();
    setSelectedCategory("");
    setDate("");
  };

  const handleCheck = (event, specialization) => {
    setSelectedCategory(specialization);
    setSelectedCategory(() =>
      specialization === selectedCategory ? null : specialization
    );
    LeadFilterFunction(specialization, "status", event.target.checked);
  };

  const handleDate = (value) => {
    setDate(value);
    let date = convertToDate(value);
    setSelectedCategory(date);
    LeadFilterFunction(value, "Date", "");
  };

  const convertToDate = (date) => {
    let timestamp = new Date(date);
    return timestamp.toLocaleDateString();
  };

  return (
    <>
      {selectedCategory && (
        <Chip
          sx={{
            "&.MuiChip-root": {
              pl: "5px",
              pr: "5px",
            },
          }}
          label={
            <Typography
              sx={{
                fontSize: "10px",
                fontWeight: "700",
                color: "rgba(88, 94, 106, 1)",
              }}
            >
              Category:{" "}
              <Typography
                sx={{
                  fontSize: "10px",
                  fontWeight: "400",
                }}
                component={"span"}
              >
                {selectedCategory}
              </Typography>
            </Typography>
          }
          onDelete={handleClearAllFunction}
        />
      )}
      <List
        sx={{
          // width:"fit-content",
          maxWidth: "316px",
          // height: "620px",
          height: "auto",
          bgcolor: "background.paper",
          borderRadius: "8px",
          backgroundColor: "rgba(255, 255, 255, 0.5)",
          // overflow: "scroll",
          // overflowX: "hidden",
          border: "1px solid #ECECEC",
          mt: 2.5,
        }}
        component="nav"
        aria-labelledby="nested-list-subheader"
        subheader={
          <ListSubheader
            component="div"
            id="nested-list-subheader"
            sx={{ backgroundColor: "#F2F2F2" }}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>Filter</div>
              <div
                style={{
                  display: "flex",
                  marginTop: "12px",
                  cursor: "pointer",
                }}
                onClick={handleClearClick}
              >
                <MdClear style={{ fontSize: "22px", height: "20px" }} />{" "}
                <Typography>Clear All</Typography>
              </div>
            </div>
          </ListSubheader>
        }
      >
        <ListItemButton onClick={handleClick4}>
          <ListItemText primary="Status" />
          {dateListopen ? (
            <ExpandLess sx={{ fontSize: "17px", fontWeight: "thin" }} />
          ) : (
            <ExpandMore sx={{ fontSize: "17px", fontWeight: "thin" }} />
          )}
        </ListItemButton>
        <Collapse in={dateListopen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton>
              <FormGroup>
                {statusList &&
                  statusList?.map((status) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          sx={{
                            color: "#F8469A",
                            "&.Mui-checked": {
                              color: "#F8469A",
                            },
                          }}
                          checked={status === selectedCategory}
                          onChange={(e) => {
                            handleCheck(e, status, "status");
                          }}
                        />
                      }
                      label={status}
                    />
                  ))}
              </FormGroup>
            </ListItemButton>
          </List>
        </Collapse>

        <ListItemButton onClick={handleClick1}>
          <ListItemText primary="Date" />
          {firstListopen ? (
            <ExpandLess sx={{ fontSize: "17px", fontWeight: "thin" }} />
          ) : (
            <ExpandMore sx={{ fontSize: "17px", fontWeight: "thin" }} />
          )}
        </ListItemButton>
        <Collapse in={firstListopen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton
              sx={{
                "& .example-custom-input > p": {
                  color: date ? "#000" : "#ACB1C6",
                },
              }}
            >
              <CustomDateField
                placeholder={"DD/MM/YY"}
                handleChange={handleDate}
                value={date}
              />
            </ListItemButton>
          </List>
        </Collapse>
      </List>
    </>
  );
}

export default WithDrawalFilterSearch;
