import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import InputField from "../../../customComponent/InputField";
import { RxCross1 } from "react-icons/rx";
import { LocalizationProvider, MobileTimePicker } from "@mui/x-date-pickers";
import { DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { SubmitButton } from "../../../customComponent/Buttons";
import { useParams } from "react-router-dom";
import { followUpActivitiesEditAction } from "../../../../actions/leadActions/leadFollowUpActions";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";

function ActivitiesEditModal({
  handleClose,
  open,
  followUpDetails,
  errorData,
}) {

  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    if (followUpDetails && followUpDetails?.activityType === "task") {
      setFormData({
        id: followUpDetails?.id,
        subject: followUpDetails?.subject,
        dueData: followUpDetails?.dueData,
        priority: followUpDetails?.priority,
        owner: followUpDetails?.owner,
        status: followUpDetails?.status,
        reminder: followUpDetails?.reminder,
        repeat: followUpDetails?.repeat,
        description: followUpDetails?.description,
        Dailogcomponent: "taskCreate",
        leadId: id,
      });
    }

    if (followUpDetails && followUpDetails?.activityType === "meet") {
      setFormData({
        id: followUpDetails?.id,
        subject: followUpDetails?.subject,
        dueData: followUpDetails?.dueData,
        priority: followUpDetails?.priority,
        owner: followUpDetails?.owner,
        repeat: followUpDetails?.repeat,
        reminder: followUpDetails?.reminder,
        status: followUpDetails?.status,
        meetLink: followUpDetails?.meetLink,
        description: followUpDetails?.description,
        Dailogcomponent: "meetCreate",
        leadId: id,
      });
    }

    if (followUpDetails && followUpDetails?.activityType === "call") {
        const isoFormat = dayjs(followUpDetails?.from);
        console.log(isoFormat, "isoFormat");
      setFormData({
        id: followUpDetails?.id,
        subject: followUpDetails?.subject,
        dueData: isoFormat,
        owner: followUpDetails?.owner,
        callType: followUpDetails?.callType,
        callTo: followUpDetails?.host,
        reminder: followUpDetails?.reminder,
        status: followUpDetails?.status,
        description: followUpDetails?.description,
        Dailogcomponent: "callCreate",
        leadId: id,
      });
    }
  }, [followUpDetails]);

  const [formData, setFormData] = useState();

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(name, value, "description");
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleTimeChange = (name,value) => {
    console.log(name, value, "description");
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    if (followUpDetails?.activityType === "task") {
      dispatch(
        followUpActivitiesEditAction(
          formData?.id,
          formData?.subject,
          formData?.dueData,
          formData?.priority,
          formData?.owner,
          formData?.status,
          formData?.reminder,
          formData?.repeat,
          "",
          formData?.description,
          formData?.leadId,
          formData?.Dailogcomponent
        )
      );
    } else if (followUpDetails?.activityType === "meet") {
      const isoFormat = formData?.dueData.toISOString();
      const isoFormatPriority = formData?.priority.toISOString();
      dispatch(
        followUpActivitiesEditAction(
          formData?.id,
          formData?.subject,
          isoFormat,
          isoFormatPriority,
          formData?.owner,
          formData?.status,
          formData?.reminder,
          formData?.repeat,
          formData?.meetLink,
          formData?.description,
          formData?.leadId,
          formData?.Dailogcomponent
        )
      );
    } else if (followUpDetails?.activityType === "call") {
      dispatch(
        followUpActivitiesEditAction(
          formData?.id,
          formData?.subject,
          formData?.dueData,
          formData?.priority,
          formData?.owner,
          formData?.status,
          formData?.reminder,
          formData?.repeat,
          "",
          formData?.description,
          formData?.leadId,
          formData?.Dailogcomponent
        )
      );
    }
    handleClose();
  };

  return (
    <Box>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          sx: {
            // bottom: "35vh",
            width: "850px",
            maxWidth: "585px",
            height: "502px",
            // marginTop: "15%",
          },
        }}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <DialogTitle
            id="alert-dialog-title"
            sx={{ fontSize: "22px", fontWeight: 700 }}
          >
            {followUpDetails?.activityType === "task"
              ? "Task Information"
              : followUpDetails?.activityType === "meet"
              ? "Meeting Information"
              : followUpDetails?.activityType === "call"
              ? "Call Information"
              : ""}
          </DialogTitle>

          <RxCross1
            style={{
              width: "30px",
              height: "auto",
              margin: "15px",
              cursor: "pointer",
            }}
            onClick={handleClose}
          />
        </div>

        <DialogContent
          sx={{
            pt: "0",
          }}
        >
          {/* //task */}

          {followUpDetails?.activityType === "task" && (
            <Grid container spacing={2}>
              <Grid item xs={12} sx={{ mt: 1 }}>
                <InputField
                  label="Subject"
                  handleChange={handleChange}
                  widthSize="100%"
                  value={formData?.subject}
                  // required
                  name={"subject"}
                  paramses
                  error={Boolean(errorData?.subject)}
                  helperText={errorData?.subject}
                />
              </Grid>

              <Grid item xs={12} lg={6}>
                <InputField
                  label="Due date"
                  handleChange={handleChange}
                  widthSize="100%"
                  value={formData?.dueData}
                  name={"dueData"}
                  // required
                  paramses
                  InputType="date"
                  error={Boolean(errorData?.dueData)}
                  helperText={errorData?.dueData}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <InputField
                  label="Priority"
                  handleChange={handleChange}
                  name={"priority"}
                  widthSize="100%"
                  value={formData?.priority}
                  // required
                  paramses
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <InputField
                  label="Owner"
                  handleChange={handleChange}
                  widthSize="100%"
                  value={formData?.owner}
                  name={"owner"}
                  // required
                  paramses
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <InputField
                  label="Status"
                  handleChange={handleChange}
                  widthSize="100%"
                  value={formData?.status}
                  name={"status"}
                  // required
                  paramses
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <InputField
                  label="Reminder"
                  handleChange={handleChange}
                  widthSize="100%"
                  value={formData?.reminder}
                  InputType="date"
                  name={"reminder"}
                  // required
                  paramses
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <InputField
                  label="Repeat"
                  handleChange={handleChange}
                  widthSize="100%"
                  value={formData?.repeat}
                  name={"repeat"}
                  // required
                  paramses
                />
              </Grid>
              <Grid item xs={12}>
                <InputField
                  label="Description"
                  handleChange={handleChange}
                  widthSize="100%"
                  value={formData?.description}
                  name={"description"}
                  required
                  paramses
                  InputType="discription"
                  dispcriptionHeight="150px"
                  error={Boolean(errorData?.description)}
                  helperText={errorData?.description}
                />
              </Grid>
            </Grid>
          )}

          {/* //meet */}
          {followUpDetails?.activityType === "meet" && (
            <Grid container spacing={2}>
              <Grid item xs={12} sx={{ mt: 1 }}>
                <InputField
                  label="Subject"
                  // handleChange={(e) => setSubject(e.target.value)}
                  widthSize="100%"
                  value={formData?.subject}
                  // required
                  paramses
                  error={Boolean(errorData?.subject)}
                  helperText={errorData?.subject}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                {/* <InputField
                  label="From"
                  handleChange={(e) => setDueData(e.target.value)}
                  widthSize="100%"
                  value={dueData}
                  // required
                  paramses
                  InputType="text"
                /> */}
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  {/* <InputField
                    label="From"
                    widthSize="100%"
                    value={dueData.format("YYYY-MM-DDTHH:mm")}
                    InputType="text"
                  /> */}

                  <DemoItem label="From">
                    <MobileTimePicker
                      value={formData?.dueData}
                      // onChange={(newValue) => setDueData(newValue)}
                      onChange={(value) => handleTimeChange("dueData", value)}
                    />
                  </DemoItem>
                </LocalizationProvider>
                {Boolean(errorData?.dueData) && (
                  <Box
                    sx={{
                      ml: "4px",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#FF2400",
                        fontSize: "12px",
                      }}
                    >
                      {errorData?.dueData}
                    </Typography>
                  </Box>
                )}
              </Grid>
              <Grid item xs={12} lg={6}>
                {/* <InputField
                  label="To"
                  handleChange={(e) => setPriority(e.target.value)}
                  widthSize="100%"
                  value={priority}
                  // required
                  paramses
                /> */}

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoItem label="To">
                    <MobileTimePicker
                      value={formData?.priority}
                      // onChange={(newValue) => setPriority(newValue)}
                      onChange={(value) => handleTimeChange("priority", value)}
                    />
                  </DemoItem>
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} lg={6}>
                <InputField
                  label="Location"
                  // handleChange={(e) => setOwner(e.target.value)}
                  widthSize="100%"
                  value={formData?.owner}
                  // required
                  paramses
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <InputField
                  label="Host"
                  // handleChange={(e) => setRepeat(e.target.value)}
                  widthSize="100%"
                  value={formData?.repeat}
                  // required
                  paramses
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <InputField
                  label="Related to"
                  // handleChange={(e) => setReminder(e.target.value)}
                  widthSize="100%"
                  value={formData?.reminder}
                  // InputType="date"
                  // required
                  paramses
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <InputField
                  label="Status"
                  // handleChange={(e) => setStatus(e.target.value)}
                  widthSize="100%"
                  value={formData?.status}
                  // required
                  paramses
                />
              </Grid>
              <Grid item xs={12} lg={12}>
                <InputField
                  label="Meeting Link"
                  // handleChange={(e) => setMeetLink(e.target.value)}
                  widthSize="100%"
                  value={formData?.meetLink}
                  // required
                  paramses
                  error={Boolean(errorData?.meetLink)}
                  helperText={errorData?.meetLink}
                />
              </Grid>

              <Grid item xs={12}>
                <InputField
                  label="Description"
                  // handleChange={(e) => setDescription(e.target.value)}
                  widthSize="100%"
                  value={formData?.description}
                  required
                  paramses
                  InputType="discription"
                  dispcriptionHeight="150px"
                  error={Boolean(errorData?.description)}
                  helperText={errorData?.description}
                />
              </Grid>
            </Grid>
          )}

          {/* call */}
          {followUpDetails?.activityType === "call" && (
            <Grid container spacing={2}>
              <Grid item xs={12} sx={{ mt: 1 }}>
                <InputField
                  label="Subject"
                  handleChange={handleChange}
                  widthSize="100%"
                  name={"subject"}
                  value={formData?.subject}
                  // required
                  paramses
                  error={Boolean(errorData?.subject)}
                  helperText={errorData?.subject}
                />
              </Grid>
              <Grid item xs={12} lg={12}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoItem label="Call start time">
                    <MobileTimePicker
                      style={{
                        width: "100%",
                        height: "auto",
                      }}
                      value={formData?.dueData}
                      onChange={(value) => handleTimeChange("dueData", value)}
                    />
                  </DemoItem>
                </LocalizationProvider>

                {Boolean(errorData?.dueData) && (
                  <Box
                    sx={{
                      ml: "4px",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#FF2400",
                        fontSize: "12px",
                      }}
                    >
                      {errorData?.dueData}
                    </Typography>
                  </Box>
                )}
              </Grid>
              <Grid item xs={12} lg={6}>
                <InputField
                  label="Call owner"
                  handleChange={handleChange}
                  widthSize="100%"
                  name={"owner"}
                  value={formData?.owner}
                  // required
                  paramses
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <InputField
                  label="Call type"
                  handleChange={handleChange}
                  widthSize="100%"
                  name={"callType"}
                  value={formData?.callType}
                  // required
                  paramses
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <InputField
                  label="Call to"
                  handleChange={handleChange}
                  widthSize="100%"
                  value={formData?.callTo}
                  // required
                  paramses
                  name={"callTo"}
                  error={Boolean(errorData?.callTo)}
                  helperText={errorData?.callTo}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <InputField
                  label="Reminder"
                  handleChange={handleChange}
                  widthSize="100%"
                  value={formData?.reminder}
                  name={"reminder"}
                  // InputType="date"
                  // required
                  paramses
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <InputField
                  label="Status"
                  handleChange={handleChange}
                  widthSize="100%"
                  value={formData?.status}
                  name={"status"}
                  // required
                  paramses
                />
              </Grid>
              <Grid item xs={12}>
                <InputField
                  label="Description"
                  handleChange={handleChange}
                  widthSize="100%"
                  value={formData?.description}
                  name={"description"}
                  required
                  paramses
                  InputType="discription"
                  dispcriptionHeight="150px"
                  error={Boolean(errorData?.description)}
                  helperText={errorData?.description}
                />
              </Grid>
            </Grid>
          )}
        </DialogContent>
        <DialogActions
          sx={{
            mr: "20px",
          }}
        >
          <SubmitButton
            title={"Update"}
            submit=""
            widthSize="75px"
            heightSize="30px"
            type="click"
            handleSubmit={handleSubmit}
          />
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default ActivitiesEditModal;
