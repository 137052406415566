import React, { forwardRef, useEffect, useState } from "react";
import {
  Box,
  Divider,
  Grid,
  Menu,
  Paper,
  Skeleton,
  Typography,
} from "@mui/material";

import MaterialTable from "@material-table/core";

import Swal from "sweetalert2";

import { useDispatch, useSelector } from "react-redux";

import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";

import { makeStyles } from "@material-ui/core/styles";
import {
  FormControl,
  TextField,
  Button,
  Select,
  MenuItem,
  InputLabel,
  FormHelperText,
} from "@material-ui/core";
import ViewColumn from "@material-ui/icons/ViewColumn";
import { RiDeleteBin6Line } from "react-icons/ri";
import { AiOutlineEye } from "react-icons/ai";
import { SlOptionsVertical } from "react-icons/sl";
import { useNavigate, useParams } from "react-router-dom";
import {
  deleteCourseAction,
  universityFindCourseAction,
} from "../../../actions/universityActions/courseActions/universityCourseAction";
import { CourseViewTable } from "./CourseViewTable";

const CourseManagment = ({ tabName }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  console.log(tabName,"tabNametabNametabNametabName")

  const [typeData, setTypeData] = useState([]);
  const [reset, setResetData] = useState(false);

  let handleResetData = (status) => {
    setResetData(!reset);
  };
  let {
    universityMasterAddLoading,
    universityMasterAddSuccess,
    universityMasterAddErr,
  } = useSelector((state) => {
    return state.universityMasterAdd;
  });

  let {
    universityCourseFindLoading,
    universityCourseFindSuccess,
    universityCourseFindErr,
  } = useSelector((state) => {
    return state.universityFindCourse;
  });

  let {
    universityMasterFindByIdLoading,
    universityMasterFindByIdSuccess,
    universityMasterFindByIdErr,
  } = useSelector((state) => {
    return state.findMasterbyTypeID;
  });

  let {
    universityCourseDeletingLoading,
    universityCourseDeletingSuccess,
    universityCourseDeletingErr,
  } = useSelector((state) => {
    return state.deleteCourse;
  });

  let {
    universityCourseFilterDataLoading,
    universityCourseFilterDataSuccess,
    universityCourseFilterDataErr,
  } = useSelector((state) => {
    return state.courseTableFilter;
  });

  useEffect(() => {
    if (universityCourseFilterDataSuccess) {
      setTypeData(universityCourseFilterDataSuccess);
    }
  }, [universityCourseFilterDataSuccess]);

  useEffect(() => {
    if (universityCourseFindSuccess) {
      if (tabName !== "All") {
        setTypeData(
          universityCourseFindSuccess.filter(
            (value) => value?.courseLevel === tabName
          )
        );
      } else {
        setTypeData(universityCourseFindSuccess);
      }
    }
  }, [universityCourseFindSuccess, universityCourseDeletingSuccess, tabName]);

  useEffect(() => {
    dispatch(universityFindCourseAction(id));
  }, [dispatch, id, universityCourseDeletingSuccess, reset]);

  console.log(typeData, "typeDatatypeDatatypeDatatypeData");

  useEffect(() => {
    if (universityMasterAddErr) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `${universityMasterAddErr}`,
      });
    }
  }, [universityMasterAddErr]);

  const columns = [
    {
      title: "Sl no",
      field: "sl",
      // type: "numeric",
      // hidden: true,
      headerStyle: { backgroundColor: "#F9F9FB" },
    },
    {
      title: "Name",
      field: "name",
      //   hidden: showEmployeeNameColumn,
      headerStyle: { backgroundColor: "#F9F9FB" },
    },
    {
      title: "Course Duration",
      field: "CourseDuration",
      //   hidden: showEmailColumn,
      headerStyle: { backgroundColor: "#F9F9FB" },
    },
    {
      title: "Total Fee",
      field: "totalFee",
      //   hidden: showEmailColumn,
      headerStyle: { backgroundColor: "#F9F9FB" },
    },

    {
      title: "Action",
      field: "action",
      filtering: false,
      headerStyle: { backgroundColor: "#F9F9FB" },
    },
  ];

  const data = typeData?.map((master, index) => ({
    id: master.id,
    sl: index + 1,
    name: master.courseTitle || "-",
    CourseDuration: master.CourseDuration || "-",
    totalFee: master.totalFee || "-",
  }));

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <button
          style={{
            fontSize: "15px",
            color: "white",
            backgroundColor: "#141E3C",
            width: "155px",
            height: "40px",
            borderRadius: "7px",
            fontWeight: 700,
          }}
          onClick={() => navigate(`/university/courses/addCourse/${id}`)}
        >
          Add Course
        </button>
      </Box>

      <Grid container sx={{ mt: 1 }}>
        <Grid item xs={12} lg={12}>
          {universityMasterFindByIdLoading ? (
            <>
              {/* <Skeleton /> */}
              <Typography variant="h1">
                <Skeleton animation={false} />
              </Typography>

              <Typography variant="h3">
                <Skeleton animation="wave" />
              </Typography>

              <Skeleton animation={false} />
              <Typography variant="h3">
                <Skeleton animation="wave" />
              </Typography>

              <Skeleton animation={false} />
              <Typography variant="h3">
                <Skeleton animation="wave" />
              </Typography>

              <Skeleton animation={false} />
              <Typography variant="h3">
                <Skeleton animation="wave" />
              </Typography>

              <Skeleton animation={false} />
            </>
          ) : (
            <CourseViewTable
              columns={columns}
              data={data}
              component="course"
              handleResetData={handleResetData}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
};
export default CourseManagment;
