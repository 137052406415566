import React, { useEffect, useState } from "react";
import { Alert, AlertTitle, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import usePrivilegeCheck from "../../../constants/customHooks/UsePrivilegeCheck";
import DownloadExcel from "../../extraComponents/DownloadExcel";
import DownloadPdf from "../../extraComponents/DownloadPdf";
import IeltsEntrolledSearchHeader from "./ieltsEntrolledSearchHeader";
import IeltsEntrolledStudentTable from "./ieltsEntrolledStudentTable";
import EntrolledFilterEmployee from "./ieltsEntorlledFilterSearch";
import { entrolledStudentsFindAction } from "../../../actions/IeltsActions/entrolledStudentsAction";

function IeltsEntrolledMain(props) {
  const dispatch = useDispatch();
  const [filterPassData, setFilterPassData] = useState();
  const [whichFilter, setwhichFilter] = useState();
  const [reset, setReset] = useState(false);
  const [leadData, setLeadData] = useState([]);
  const [entrolledStudentsList, setEntrolledStudentsList] = useState([]);

  const { privilege, hasPrivilege } = usePrivilegeCheck();

  // Employee column Visibility
  const [showStudentId, setShowStudentIdColumn] = useState(false);
  const [showfullNameColumn, setShowfullNameColumn] = useState(false);
  const [showDobColumn, setShowDobColumn] = useState(false);
  const [showgenderColumn, setShowGenderColumn] = useState(false);
  const [showEmailColumn, setShowEmailColumn] = useState(false);
  const [showPhoneColumn, setShowPhoneColumn] = useState(false);
  const [showWhatsAppNoColumn, setShowWhatsAppNoColumn] = useState(false);
  const [showCountryColumn, setShowCountryColumn] = useState(false);
  const [showCityColumn, setShowCityColumn] = useState(false);
  const [showStateColumn, setShowStateColumn] = useState(false);
  const [showStatusColumn, setShowStatusColumn] = useState(false);

  const [columnName, setColumnName] = useState("");
  const [leadId, setLeadId] = useState([]);
  const [ids, setIds] = useState([]);

  // tabel columns

  const columns = [
    {
      title: "SL NO",
      field: "sl",
    },
    {
      title: "STUDENT ID",
      field: "STUDENT ID",
      hidden: showStudentId,
    },
    {
      title: "NAME",
      field: "NAME",
      hidden: showfullNameColumn,
    },
    {
      title: "DOB",
      field: "DOB",
      hidden: showDobColumn,
    },
    {
      title: "GENDER",
      field: "GENDER",
      hidden: showgenderColumn,
    },
    {
      title: "EMAIL",
      field: "EMAIL",
      hidden: showEmailColumn,
    },
    {
      title: "PHONE",
      field: "PHONE",
      hidden: showPhoneColumn,
    },

    {
      title: "WHATSAPP NO",
      field: "WHATSAPP NO",
      hidden: showWhatsAppNoColumn,
    },

    {
      title: "COUNTRY",
      field: "COUNTRY",
      hidden: showCountryColumn,
    },

    {
      title: "STATE",
      field: "STATE",
      hidden: showStateColumn,
    },

    {
      title: "CITY",
      field: "CITY",
      hidden: showCityColumn,
    },
    {
      title: "STATUS",
      field: "STATUS",
      hidden: showStatusColumn,
    },
  ];

  // Excel export function
  const excelDownloadClick = () => {
    DownloadExcel(columns, entrolledStudentsList, "Entrolledstudentslist");
  };

  // Pdf Export function

  const pdfDownloadClick = () => {
    DownloadPdf(
      columns,
      entrolledStudentsList,
      "Ieltsentrolledstudents",
      "EntrolledStudentsList"
    );
  };

  const toggleNameColumn = (status, column) => {
    if (column === "studentid") {
      setColumnName(column);
      setShowStudentIdColumn(status);
    }

    if (column === "name") {
      setColumnName(column);
      setShowfullNameColumn(status);
    }
    if (column === "dob") {
      setColumnName(column);
      setShowDobColumn(status);
    }
    if (column === "gender") {
      setColumnName(column);
      setShowGenderColumn(status);
    }
    if (column === "email") {
      setColumnName(column);
      setShowEmailColumn(status);
    }
    if (column === "phone") {
      setColumnName(column);
      setShowPhoneColumn(status);
    }
    if (column === "whatsapp") {
      setColumnName(column);
      setShowWhatsAppNoColumn(status);
    }
    if (column === "city") {
      setColumnName(column);
      setShowCityColumn(status);
    }
    if (column === "state") {
      setColumnName(column);
      setShowStateColumn(status);
    }
    if (column === "country") {
      setColumnName(column);
      setShowCountryColumn(status);
    }
    if (column === "status") {
      setColumnName(column);
      setShowStatusColumn(status);
    }
  };

  useEffect(() => {
    // Retrieve column visibility state from localStorage
    const storedStudentId = localStorage.getItem("studentid");
    const storedFullName = localStorage.getItem("name");
    const storedDob = localStorage.getItem("dob");
    const storedGender = localStorage.getItem("gender");
    const storedEmail = localStorage.getItem("email");
    const storedPhone = localStorage.getItem("phone");
    const storedWhatsApp = localStorage.getItem("whatsapp");
    const storedCountry = localStorage.getItem("country");
    const storedCity = localStorage.getItem("city");
    const storedState = localStorage.getItem("state");
    const storedStatus = localStorage.getItem("status");

    if (storedStudentId !== null) {
      setColumnName("studentid");
      setShowStudentIdColumn(JSON.parse(storedFullName));
    }

    if (storedFullName !== null) {
      setColumnName("name");
      setShowfullNameColumn(JSON.parse(storedFullName));
    }
    if (storedDob !== null) {
      setColumnName("dob");
      setShowDobColumn(JSON.parse(storedDob));
    }
    if (storedGender !== null) {
      setColumnName("gender");
      setShowGenderColumn(JSON.parse(storedGender));
    }
    if (storedEmail !== null) {
      setColumnName("email");
      setShowEmailColumn(JSON.parse(storedEmail));
    }
    if (storedPhone !== null) {
      setColumnName("phone");
      setShowPhoneColumn(JSON.parse(storedPhone));
    }
    if (storedWhatsApp !== null) {
      setColumnName("whatsapp");
      setShowWhatsAppNoColumn(JSON.parse(storedWhatsApp));
    }
    if (storedCity !== null) {
      setColumnName("city");
      setShowCityColumn(JSON.parse(storedCity));
    }
    if (storedState !== null) {
      setColumnName("state");
      setShowStateColumn(JSON.parse(storedState));
    }

    if (storedCountry !== null) {
      setColumnName("country");
      setShowCountryColumn(JSON.parse(storedCountry));
    }
    if (storedStatus !== null) {
      setColumnName("status");
      setShowCountryColumn(JSON.parse(storedStatus));
    }
  }, []);

  useEffect(() => {
    // Save column visibility state to localStorage
    localStorage.setItem("studentid", JSON.stringify(showStudentId));
    localStorage.setItem("name", JSON.stringify(showfullNameColumn));
    localStorage.setItem("dob", JSON.stringify(showDobColumn));
    localStorage.setItem("gender", JSON.stringify(showgenderColumn));
    localStorage.setItem("email", JSON.stringify(showEmailColumn));
    localStorage.setItem("phone", JSON.stringify(showPhoneColumn));
    localStorage.setItem("whatsapp", JSON.stringify(showWhatsAppNoColumn));
    localStorage.setItem("city", JSON.stringify(showCityColumn));
    localStorage.setItem("state", JSON.stringify(showStateColumn));
    localStorage.setItem("country", JSON.stringify(showCountryColumn));
    localStorage.setItem("status", JSON.stringify(showStatusColumn));
  }, [
    showStudentId,
    showfullNameColumn,
    showDobColumn,
    showgenderColumn,
    showEmailColumn,
    showPhoneColumn,
    showWhatsAppNoColumn,
    showCityColumn,
    showStateColumn,
    showCountryColumn,
    showStatusColumn,
    columnName,
  ]);

  // Find entrolled students

  let {
    ieltsStudentsEntrolledFindSuccess,
    ieltsStudentsEntrolledFinderror,
  } = useSelector((state) => {
    return state.ieltsEntrolledStudents;
  });

  let {
    ieltsStudentsStatusChangeSuccess,
    ieltsStudentsStatusChangeerror,
  } = useSelector((state) => {
    return state.ieltsStudentsStatusChange;
  });

  useEffect(() => {
    dispatch(entrolledStudentsFindAction());
  }, [dispatch, ieltsStudentsStatusChangeSuccess]);

  useEffect(() => {
    if (ieltsStudentsEntrolledFindSuccess) {
      setEntrolledStudentsList(
        ieltsStudentsEntrolledFindSuccess?.map((students, index) => ({
          sl: index + 1,
          id: students.id,
          coloum1: students.studentId,
          coloum2: students.fullName,
          coloum3: students.dob,
          coloum4: students.gender,
          coloum5: students.email,
          coloum6: students.phoneNumberCountryCode + students.phoneNumber,
          coloum7: students.whatsAppNumberCountryCode + students.whatsAppNumber,
          coloum8: students.country,
          coloum9: students.state,
          coloum10: students.city,
          coloum11: students.status,
        }))
      );
    }
  }, [ieltsStudentsEntrolledFindSuccess,reset]);

  console.log(
    ieltsStudentsEntrolledFindSuccess,
    "ieltsStudentsEntrolledFindSuccessieltsStudentsEntrolledFindSuccess"
  );

  // Handle SearchFunction

  const searchHandleChange = (value) => {
    if (value) {
      setEntrolledStudentsList(
        entrolledStudentsList?.filter((item) => {
          const name = item.coloum2 ? item.coloum2.toLowerCase() : "";
          // :lead?.branch_master?.branchName
          return name.includes(value.toLowerCase());
        })
      );
    } else {
      setEntrolledStudentsList(
        ieltsStudentsEntrolledFindSuccess?.map((students, index) => ({
          sl: index + 1,
          id: students.id,
          coloum1: students.studentId,
          coloum2: students.fullName,
          coloum3: students.dob,
          coloum4: students.gender,
          coloum5: students.email,
          coloum6: students.phoneNumberCountryCode + students.phoneNumber,
          coloum7: students.whatsAppNumberCountryCode + students.whatsAppNumber,
          coloum8: students.country,
          coloum9: students.state,
          coloum10: students.city,
          coloum11: students.status,
        }))
      );
    }
  };

  const setIdsFunction = (leadsId) => {
    setLeadId(leadsId);
  };

  const selectBoxIdPassHandler = (ids) => {
    setIds(ids);
  };


  // handleFilter Data

  const LeadFilterFunction = (data, filterOption, checkedStatus) => {
    console.log(data, filterOption, checkedStatus, "hhhhhhhhhhhhhhhhhhhhi");
    if (filterOption === "Country") {
      if (checkedStatus === true) {
        let studentArray = ieltsStudentsEntrolledFindSuccess?.filter(
          (students, index) => {
            return data == students.country;
          }
        );
        let mapValue = studentArray?.map((students, index) => ({
          sl: index + 1,
          id: students.id,
          coloum1: students.studentId,
          coloum2: students.fullName,
          coloum3: students.dob,
          coloum4: students.gender,
          coloum5: students.email,
          coloum6: students.phoneNumberCountryCode + students.phoneNumber,
          coloum7: students.whatsAppNumberCountryCode + students.whatsAppNumber,
          coloum8: students.country,
          coloum9: students.state,
          coloum10: students.city,
          coloum11: students.status,
        }));

        console.log(mapValue, "mapValuemapValuemapValuemapValue");

        setEntrolledStudentsList(mapValue);
        setwhichFilter(filterOption);
        setFilterPassData(data);
      } else {
        setwhichFilter(null);
        setFilterPassData(null);
        setEntrolledStudentsList(
          ieltsStudentsEntrolledFindSuccess?.map((students, index) => ({
            sl: index + 1,
            id: students.id,
            coloum1: students.studentId,
            coloum2: students.fullName,
            coloum3: students.dob,
            coloum4: students.gender,
            coloum5: students.email,
            coloum6: students.phoneNumberCountryCode + students.phoneNumber,
            coloum7:
              students.whatsAppNumberCountryCode + students.whatsAppNumber,
            coloum8: students.country,
            coloum9: students.state,
            coloum10: students.city,
            coloum11: students.status,
          }))
        );
      }
    }
    if (filterOption === "State") {
      if (checkedStatus === true) {
        let studentArray = ieltsStudentsEntrolledFindSuccess?.filter(
          (students, index) => {
            return data == students.state;
          }
        );
        let mapValue = studentArray?.map((students, index) => ({
          sl: index + 1,
          id: students.id,
          coloum1: students.studentId,
          coloum2: students.fullName,
          coloum3: students.dob,
          coloum4: students.gender,
          coloum5: students.email,
          coloum6: students.phoneNumberCountryCode + students.phoneNumber,
          coloum7: students.whatsAppNumberCountryCode + students.whatsAppNumber,
          coloum8: students.country,
          coloum9: students.state,
          coloum10: students.city,
          coloum11: students.status,
        }));

        console.log(mapValue, "mapValuemapValuemapValuemapValue");

        setEntrolledStudentsList(mapValue);
        setwhichFilter(filterOption);
        setFilterPassData(data);
      } else {
        setwhichFilter(null);
        setFilterPassData(null);
        setEntrolledStudentsList(
          ieltsStudentsEntrolledFindSuccess?.map((students, index) => ({
            sl: index + 1,
            id: students.id,
            coloum1: students.studentId,
            coloum2: students.fullName,
            coloum3: students.dob,
            coloum4: students.gender,
            coloum5: students.email,
            coloum6: students.phoneNumberCountryCode + students.phoneNumber,
            coloum7:
              students.whatsAppNumberCountryCode + students.whatsAppNumber,
            coloum8: students.country,
            coloum9: students.state,
            coloum10: students.city,
            coloum11: students.status,
          }))
        );
      }
    }
    if (filterOption === "City") {
      if (checkedStatus === true) {
        let studentArray = ieltsStudentsEntrolledFindSuccess?.filter(
          (students, index) => {
            return data == students.city;
          }
        );
        let mapValue = studentArray?.map((students, index) => ({
          sl: index + 1,
          id: students.id,
          coloum1: students.studentId,
          coloum2: students.fullName,
          coloum3: students.dob,
          coloum4: students.gender,
          coloum5: students.email,
          coloum6: students.phoneNumberCountryCode + students.phoneNumber,
          coloum7: students.whatsAppNumberCountryCode + students.whatsAppNumber,
          coloum8: students.country,
          coloum9: students.state,
          coloum10: students.city,
          coloum11: students.status,
        }));

        console.log(mapValue, "mapValuemapValuemapValuemapValue");

        setEntrolledStudentsList(mapValue);
        setwhichFilter(filterOption);
        setFilterPassData(data);
      } else {
        setwhichFilter(null);
        setFilterPassData(null);
        setEntrolledStudentsList(
          ieltsStudentsEntrolledFindSuccess?.map((students, index) => ({
            sl: index + 1,
            id: students.id,
            coloum1: students.studentId,
            coloum2: students.fullName,
            coloum3: students.dob,
            coloum4: students.gender,
            coloum5: students.email,
            coloum6: students.phoneNumberCountryCode + students.phoneNumber,
            coloum7:
              students.whatsAppNumberCountryCode + students.whatsAppNumber,
            coloum8: students.country,
            coloum9: students.state,
            coloum10: students.city,
            coloum11: students.status,
          }))
        );
      }
    }

    if (data === "Male") {
      if (checkedStatus === true) {
        let studentArray = ieltsStudentsEntrolledFindSuccess?.filter(
          (students, index) => {
            return data == students.gender;
          }
        );
        let mapValue = studentArray?.map((students, index) => ({
          sl: index + 1,
          id: students.id,
          coloum1: students.studentId,
          coloum2: students.fullName,
          coloum3: students.dob,
          coloum4: students.gender,
          coloum5: students.email,
          coloum6: students.phoneNumberCountryCode + students.phoneNumber,
          coloum7: students.whatsAppNumberCountryCode + students.whatsAppNumber,
          coloum8: students.country,
          coloum9: students.state,
          coloum10: students.city,
          coloum11: students.status,
        }));

        console.log(mapValue, "mapValuemapValuemapValuemapValue");

        setEntrolledStudentsList(mapValue);
        setwhichFilter(filterOption);
        setFilterPassData(data);
      } else {
        setwhichFilter(null);
        setFilterPassData(null);
        setEntrolledStudentsList(
          ieltsStudentsEntrolledFindSuccess?.map((students, index) => ({
            sl: index + 1,
            id: students.id,
            coloum1: students.studentId,
            coloum2: students.fullName,
            coloum3: students.dob,
            coloum4: students.gender,
            coloum5: students.email,
            coloum6: students.phoneNumberCountryCode + students.phoneNumber,
            coloum7:
              students.whatsAppNumberCountryCode + students.whatsAppNumber,
            coloum8: students.country,
            coloum9: students.state,
            coloum10: students.city,
            coloum11: students.status,
          }))
        );
      }
    }


    if (data === "Female") {
      if (checkedStatus === true) {
        let studentArray = ieltsStudentsEntrolledFindSuccess?.filter(
          (students, index) => {
            return data == students.gender;
          }
        );
        let mapValue = studentArray?.map((students, index) => ({
          sl: index + 1,
          id: students.id,
          coloum1: students.studentId,
          coloum2: students.fullName,
          coloum3: students.dob,
          coloum4: students.gender,
          coloum5: students.email,
          coloum6: students.phoneNumberCountryCode + students.phoneNumber,
          coloum7: students.whatsAppNumberCountryCode + students.whatsAppNumber,
          coloum8: students.country,
          coloum9: students.state,
          coloum10: students.city,
          coloum11: students.status,
        }));

        console.log(mapValue, "mapValuemapValuemapValuemapValue");

        setEntrolledStudentsList(mapValue);
        setwhichFilter(filterOption);
        setFilterPassData(data);
      } else {
        setwhichFilter(null);
        setFilterPassData(null);
        setEntrolledStudentsList(
          ieltsStudentsEntrolledFindSuccess?.map((students, index) => ({
            sl: index + 1,
            id: students.id,
            coloum1: students.studentId,
            coloum2: students.fullName,
            coloum3: students.dob,
            coloum4: students.gender,
            coloum5: students.email,
            coloum6: students.phoneNumberCountryCode + students.phoneNumber,
            coloum7:
              students.whatsAppNumberCountryCode + students.whatsAppNumber,
            coloum8: students.country,
            coloum9: students.state,
            coloum10: students.city,
            coloum11: students.status,
          }))
        );
      }
    }
  };

  // handleResetData

  const handleResetData = (status) => {
    setwhichFilter(null);
    setFilterPassData(null);
    setReset(!reset);
    setwhichFilter(null);
    setFilterPassData(null);
  };

  console.log(
    entrolledStudentsList,
    "entrolledStudentsListentrolledStudentsList"
  );

  if (!hasPrivilege("entrolled") && privilege !== null) {
    return (
      <Alert severity="error">
        <AlertTitle>warning</AlertTitle>
        You don't have permission to access — <strong>this page.!</strong>
      </Alert>
    );
  }
  console.log(hasPrivilege("entrolled"),"Entrolledddddddddddddddddddddd",privilege,"privilegeprivilege")
  return (
    <>
      <IeltsEntrolledSearchHeader
        columnVisibleHandler={toggleNameColumn}
        excelDownloadClick={excelDownloadClick}
        pdfDownloadClick={pdfDownloadClick}
        showStudentIdColumn={showStudentId}
        showfullNameColumn={showfullNameColumn}
        showgenderColumn={showgenderColumn}
        showDobColumn={showDobColumn}
        showEmailColumn={showEmailColumn}
        showPhoneColumn={showPhoneColumn}
        showWhatsAppNoColumn={showWhatsAppNoColumn}
        showCountryColumn={showCountryColumn}
        showCityColumn={showCityColumn}
        showStateColumn={showStateColumn}
        showStatusColumn={showStatusColumn}
        handleResetData={handleResetData}
        searchHandleChange={searchHandleChange}
        ids={ids}
        leadId={leadId}
      />
      {/* <div style={{display:"flex",justifyContent:"space-evenly", width:"auto"}}> */}
      <Grid container spacing={-3} sx={{ mt: "-31px" }}>
        <Grid item xs={12} md={2} lg={2.2}>
          <EntrolledFilterEmployee
            LeadFilterFunction={LeadFilterFunction}
            handleResetData={handleResetData}
            statusComponent="EntrolledStudents"
          />
        </Grid>
        <Grid item xs={12} md={10} lg={9.8}>
          <IeltsEntrolledStudentTable
            toggle={props.toggle}
            showStudentIdColumn={showStudentId}
            showfullNameColumn={showfullNameColumn}
            showgenderColumn={showgenderColumn}
            showDobColumn={showDobColumn}
            showEmailColumn={showEmailColumn}
            showPhoneColumn={showPhoneColumn}
            showWhatsAppNoColumn={showWhatsAppNoColumn}
            showCountryColumn={showCountryColumn}
            showCityColumn={showCityColumn}
            showStateColumn={showStateColumn}
            showStatusColumn={showStatusColumn}
            reset={reset}
            setIdsFunction={setIdsFunction}
            data={entrolledStudentsList}
            // leadFindLoading={leadFindLoading}
            selectBoxIdPassHandler={selectBoxIdPassHandler}
            // isExistCounsilor={loginFindSuccess}
            component="EmployeeJob"
            columns={columns}
            subMenu="entrolled"
          />
        </Grid>
      </Grid>
    </>
  );
}

export default IeltsEntrolledMain;
