import React, { useEffect, useMemo, useState } from "react";

import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableFooter,
  TableSortLabel,
  Menu,
  Chip,
  Button,
  Modal,
  FormControl,
  InputLabel,
  Select,
} from "@mui/material";

import { SlOptionsVertical } from "react-icons/sl";
import { Box, Checkbox, Grid, MenuItem, Typography } from "@mui/material";

import { AiOutlineEye } from "react-icons/ai";
import { RiDeleteBin6Line } from "react-icons/ri";
import { MdNotInterested } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";

import { Skeleton } from "@mui/material";
import {
  deleteLeadAction,
  pendingLeadStatusDocsAction,
  findPendingStatusLeadChangeAction,
} from "../../actions/leadActions/leadActions";
import { checkEmployeePrivilegeAction } from "../../actions/employeeManagmentAction";
import { BiSortAlt2 } from "react-icons/bi";

import "./EmployeeJobTable.css";
import usePrivilegeCheck from "../../constants/customHooks/UsePrivilegeCheck";
import { ContentState, Editor, EditorState, convertFromHTML } from "draft-js";
import {
  hrEmployeeJobDeleteAction,
  jobStatusChangeAction,
} from "../../actions/HrActions/hrEmployeeManagmentAction";
import { TbEdit } from "react-icons/tb";
import PremissionDialog from "../customComponent/premissionDialog";
import TableNoItemComponent from "../customComponent/TableNoItemComponent";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "400",
  height: "400",
  //   overflow: "scroll",
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 20,
  p: 4,
};

function EmployeeJobTable({
  showJobTitleColumn,
  showJobTypeColumn,
  showLocationColumn,
  showExperienceColumn,
  showVacanciesColumn,
  showPostedonColumn,
  setIdsFunction,
  data,
  leadFindLoading,
  selectBoxIdPassHandler,
  component,
  columns,
  subMenu,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    privilege,
    isStatusChangePrevilage,
    isUpdatePrevilage,
    isDeletePrevilage,
  } = usePrivilegeCheck();

  const [openAlert, setOpenAlert] = useState(false);

  // HandleClose dialog

  const handleCloseDialog = () => {
    setOpenAlert(false);
  };
  const [status, setStatus] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [pendingData, setPendingData] = useState([]);
  const [open, setOpen] = useState(false);
  const [JobDescription, SetJobDescription] = useState();

  //Function to handle Description

  let { hrEmployeeJobFindSuccess } = useSelector((state) => {
    return state.hrEmployeeJobFind;
  });
  console.log(hrEmployeeJobFindSuccess, "kitttiyallo");

  // Function to convert html to plaintext

  function convertHTMLToPlainText(html) {
    // Convert HTML to Draft.js ContentState
    const blocksFromHTML = convertFromHTML(html);
    const contentState = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap
    );

    // Convert Draft.js ContentState to plain text
    const plainText = contentState.getPlainText();

    return plainText;
  }

  const handleOpen = (id) => {
    setOpen(true);
    const foundItem = hrEmployeeJobFindSuccess.find((item) => item.id === id);
    console.log((foundItem, "foundItemfoundItemfoundItem"));
    if (foundItem) {
      const plainText = convertFromHTML(foundItem?.Description);

      const contentState = ContentState.createFromBlockArray(
        plainText.contentBlocks
      );

      SetJobDescription(EditorState.createWithContent(contentState));
      console.log(contentState, "*************************");
    } else {
      console.log("item not found");
    }
  };

  // Create a function to handle selecting all rows:
  const handleSelectAllRows = () => {
    if (selectedRows.length === sortedData.length) {
      setSelectedRows([]);
    } else {
      let Ids = sortedData.map((item) => item.id);
      setSelectedRows(Ids);
      selectBoxIdPassHandler(Ids);
    }
  };

  // Handle status change
  const handleStatusChange = (status, id) => {
    if (isStatusChangePrevilage(subMenu)) {
      const statusField = status == "Open" ? false : true;
      dispatch(jobStatusChangeAction(statusField, id));
      // console.log(status, id);
    } else {
      setOpenAlert(true);
    }
    // setStatus(status);
  };

  // Create a function to handle selecting individual rows:
  const handleSelectRow = (rowId) => {
    let updatedSelectedRows;
    if (selectedRows.includes(rowId)) {
      updatedSelectedRows = selectedRows.filter((id) => id !== rowId);
      setSelectedRows(selectedRows.filter((id) => id !== rowId));
    } else {
      updatedSelectedRows = [...selectedRows, rowId];
      setSelectedRows(updatedSelectedRows);
    }
    setIdsFunction(updatedSelectedRows);
  };

  // table action
  const [leadActionButtonShow, setLeadActionButtonShow] = useState(null);
  const [tableId, setTableId] = useState("");

  const leadOpenActionButton = Boolean(leadActionButtonShow);

  const handleClickAction = (event, id) => {
    setLeadActionButtonShow(event.currentTarget);
    setTableId(id);
  };

  // Function_to_handle_EditForm

  const EmployeeJobEditHandler = (id) => {
    if (isUpdatePrevilage(subMenu)) {
      navigate(`/hrmanagment/Editjobs/${id}`);
    } else {
      setOpenAlert(true);
    }
  };

  const handleCloseAction = () => {
    setLeadActionButtonShow(null);
  };

  const CustomSortIcon = ({ direction }) => {
    return direction === "asc" ? (
      <img
        src="/menuIcons/tableUpSort.png"
        className="administratCustomIcon"
        alt="gsl"
        style={{ padding: "15px" }}
      />
    ) : (
      <BiSortAlt2 className="LeadCustomIcon" />
    );
  };

  // sorting table
  const [sortBy, setSortBy] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");

  const requestSort = (column) => {
    let newSortOrder = "asc";
    if (column === sortBy && sortOrder === "asc") {
      newSortOrder = "desc";
    }

    setSortBy(column);
    setSortOrder(newSortOrder);
  };

  const handleClose = () => setOpen(false);

  let sortedData = useMemo(() => {
    if (sortBy && data) {
      const sortedItems = [...data].sort((a, b) => {
        if (sortOrder === "asc") {
          return a[sortBy] < b[sortBy] ? -1 : 1;
        } else {
          return a[sortBy] < b[sortBy] ? 1 : -1;
        }
      });

      return sortedItems;
    }

    return data;
  }, [data, sortBy, sortOrder]);

  return (
    <>
      <Grid container spacing={2} sx={{ mt: 1, ml: 1 }}>
        <Grid item xs={12} lg={12}>
          {leadFindLoading ? (
            <>
              {/* <Skeleton /> */}
              <Typography variant="h1">
                <Skeleton animation={false} />
              </Typography>

              <Typography variant="h3">
                <Skeleton animation="wave" />
              </Typography>

              <Skeleton animation={false} />
              <Typography variant="h3">
                <Skeleton animation="wave" />
              </Typography>

              <Skeleton animation={false} />
              <Typography variant="h3">
                <Skeleton animation="wave" />
              </Typography>

              <Skeleton animation={false} />
              <Typography variant="h3">
                <Skeleton animation="wave" />
              </Typography>

              <Skeleton animation={false} />
            </>
          ) : sortedData?.length > 0 ? (
            <div
              style={{
                overflowX: "scroll",
                border: "0px solid #ffff",
              }}
              className="scrollBar"
            >
              <Table className="EmployeeJobTable">
                <TableHead>
                  <TableRow className="EmployeeJobTableCell">
                    <TableCell
                      style={{
                        position: "sticky",
                        left: 0,
                        zIndex: 1, // To ensure it's above other cells
                        padding: "1px",
                        border: "1px solid #ddd",
                        // borderLeft: "1px solid #ddd",
                        backgroundColor: "#fafafa",
                      }}
                    >
                      <Checkbox
                        checked={selectedRows.length === sortedData.length}
                        onChange={handleSelectAllRows}
                        style={{ color: "#f81b82" }}
                      />
                    </TableCell>
                    {columns?.map((column) => {
                      if (column.hidden) {
                        return null;
                      }
                      return (
                        <TableCell
                          key={column.field}
                          className="EmployeeJobTableCell LeadCommon"
                          style={{ padding: "0 16px", whiteSpace: "nowrap" }}
                        >
                          <TableSortLabel
                            className="LeadHeaderText"
                            active={column === sortBy}
                            direction={sortOrder}
                            onClick={() => requestSort(column)}
                            IconComponent={
                              column.field !== "action" && CustomSortIcon
                            }
                          >
                            {column.title}
                          </TableSortLabel>
                        </TableCell>
                      );
                    })}
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sortedData?.map((item, indx) => (
                    <TableRow className="EmployeeJobTable" key={indx}>
                      <TableCell
                        style={{
                          position: "sticky",
                          left: 0,
                          zIndex: 1, // To ensure it's above other cells
                          backgroundColor: "white", // Adjust as needed
                          padding: "1px",
                          border: "1px solid #ddd",
                        }}
                      >
                        <Checkbox
                          checked={selectedRows.includes(item.id)}
                          onChange={() => handleSelectRow(item.id)}
                          style={{ color: "#f81b82" }}
                        />
                      </TableCell>
                      <TableCell
                        style={{ padding: "0 16px", whiteSpace: "nowrap" }}
                      >
                        {item.sl}
                      </TableCell>
                      {!showJobTitleColumn && (
                        <TableCell
                          style={{ padding: "0 16px", whiteSpace: "nowrap" }}
                        >
                          {item.coloum1}
                        </TableCell>
                      )}

                      {!showLocationColumn && (
                        <TableCell
                          style={{ padding: "0 16px", whiteSpace: "nowrap" }}
                        >
                          {item.coloum2}
                        </TableCell>
                      )}

                      {!showJobTypeColumn && (
                        <TableCell
                          style={{ padding: "0 16px", whiteSpace: "nowrap" }}
                        >
                          {item.coloum3}
                          &nbsp;&nbsp;&nbsp;&nbsp;
                        </TableCell>
                      )}
                      {!showExperienceColumn && (
                        <TableCell
                          style={{ padding: "0 16px", whiteSpace: "nowrap" }}
                        >
                          {item.coloum4}
                        </TableCell>
                      )}
                      {!showVacanciesColumn && (
                        <TableCell
                          style={{ padding: "0 16px", whiteSpace: "nowrap" }}
                        >
                          {`${item.coloum5 ? item.coloum5 : "-"}     `}
                        </TableCell>
                      )}

                      {!showPostedonColumn && (
                        <TableCell
                          style={{ padding: "0 16px", whiteSpace: "nowrap" }}
                        >
                          {item.coloum6}
                        </TableCell>
                      )}
                      {/* 
                      {!showPostedonColumn && (
                        <TableCell
                          style={{ padding: "0 16px", whiteSpace: "nowrap" }}
                        >
                          {item.coloum7 == false ? "Open" : "Closed"}
                        </TableCell>
                      )} */}

                      <TableCell>
                        <FormControl
                          fullWidth
                          sx={{
                            mt: 1,
                            bgcolor:
                              item.coloum7 == false ? "#228200" : "#E52900",
                            borderRadius: "8px",
                            height: "30px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            "& .MuiSelect-icon": {
                              top: "3px",
                            },
                            "&.MuiFormControl-root": {
                              width: "100px",
                            },
                          }}
                        >
                          <InputLabel
                            htmlFor="demo-simple-select-placeholder"
                            style={{
                              transform: "translate(10px, 10px)",
                              pointerEvents: "none",
                              zIndex: 1,
                              transition: "transform 0.25s, font-size 0.25s",
                              margin: "-6px",
                              color: "white",
                              fontWeight: 400,
                              paddingLeft: "10px",
                              border: "none !important",
                            }}
                          >
                            {item.coloum7 == true ? "Close" : "Open"}
                          </InputLabel>
                          <Select
                            value={status}
                            onChange={(e) =>
                              handleStatusChange(e.target.value, item.id)
                            }
                            inputProps={{
                              // name: {item.coloum3},
                              id: "demo-simple-select-placeholder",
                            }}
                            sx={{
                              position: "absolute",
                              top: "0px",
                              right: "0",
                              "& .MuiSelect-select": {
                                padding: "0",
                              },
                              "& .MuiOutlinedInput-notchedOutline": {
                                outline: "none !important",
                                border: "none !important",
                              },
                            }}
                          >
                            <MenuItem value="Open">Open</MenuItem>
                            <MenuItem value="Closed">Closed</MenuItem>
                          </Select>
                        </FormControl>
                      </TableCell>

                      {component !== "pending" ? (
                        <TableCell
                          style={{ padding: "0 16px", whiteSpace: "nowrap" }}
                        >
                          <SlOptionsVertical
                            id="demo-positioned-button"
                            aria-controls={
                              leadOpenActionButton
                                ? "demo-positioned-menu"
                                : undefined
                            }
                            aria-haspopup="true"
                            aria-expanded={
                              leadOpenActionButton ? "true" : undefined
                            }
                            onClick={(e) => {
                              handleClickAction(e, item.id);
                            }}
                            variant="outlined"
                            style={{ marginLeft: "10px", color: "#F80B7A" }}
                            sx={{
                              backgroundColor: "#141E3C",
                              size: "10px",
                              padding: 1,
                              margin: 3,
                              color: "#fff",
                              width: "75px",
                              height: "40px",
                            }}
                          />
                          {/* TABLE ACTION MENU START*/}
                          <Menu
                            id="demo-positioned-menu"
                            aria-labelledby="demo-positioned-button"
                            open={leadActionButtonShow}
                            onClose={handleCloseAction}
                            anchorEl={leadActionButtonShow}
                            MenuListProps={{
                              "aria-labelledby": "basic-button",
                            }}
                            anchorOrigin={{
                              vertical: "bottom", // Change from "top" to "bottom"
                              horizontal: "left",
                            }}
                            transformOrigin={{
                              vertical: "top", // Change from "top" to "bottom"
                              horizontal: "left",
                            }}
                            sx={{
                              borderColor: "black",
                              borderRadius: "24px",
                              mt: 1.5,
                              width: "auto",
                              height: "auto",
                            }}
                          >
                            <Box
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              <MenuItem>
                                <TbEdit
                                  style={{
                                    fontSize: "lg",
                                    width: "20px",
                                    height: "19px",
                                  }}
                                  onClick={() =>
                                    EmployeeJobEditHandler(tableId)
                                  }
                                  className="icons"
                                />
                              </MenuItem>
                              <MenuItem>
                                <AiOutlineEye
                                  style={{
                                    fontSize: "lg",
                                    width: "20px",
                                    height: "19px",
                                  }}
                                  // onClick={() => handleOpen(tableId) navigate(`/hrmanagment/jobs/${tableId}`)}
                                  onClick={() =>
                                    navigate(`/hrmanagment/jobs/${tableId}`)
                                  }
                                />
                              </MenuItem>

                              <MenuItem>
                                <RiDeleteBin6Line
                                  style={{
                                    fontSize: "lg",
                                    width: "20px",
                                    height: "19px",
                                  }}
                                  onClick={() => {
                                    if (isDeletePrevilage(subMenu)) {
                                      setLeadActionButtonShow(null);
                                      Swal.fire({
                                        title: "Are you sure?",
                                        text: "You won't be able to revert this!",
                                        icon: "warning",
                                        showCancelButton: true,
                                        confirmButtonColor: "#3085d6",
                                        cancelButtonColor: "#d33",
                                        confirmButtonText: "Yes, delete it!",
                                      }).then((result) => {
                                        if (result.isConfirmed) {
                                          dispatch(
                                            hrEmployeeJobDeleteAction(tableId)
                                          );
                                        }
                                      });
                                    } else {
                                      setOpenAlert(true);
                                    }
                                  }}
                                />
                              </MenuItem>
                            </Box>
                          </Menu>{" "}
                        </TableCell>
                      ) : (
                        <>
                          <TableCell
                            style={{ padding: "0 16px", whiteSpace: "nowrap" }}
                          >
                            {pendingData.length !== 0 ? (
                              pendingData?.map((data) => {
                                if (data.leadId == item.id) {
                                  if (
                                    data.leadId == item.id &&
                                    data.status === "approved"
                                  ) {
                                    return (
                                      <>
                                        {/* <Button
                                      sx={{
                                        backgroundColor: "rgba(229, 41, 0, 1)",
                                        color: "white",
                                        width: "76px",
                                        height: "25px",
                                        fontWeight: 400,
                                        fontSize: "12px",
                                        ml: 1,
                                        "&:hover": {
                                          backgroundColor:
                                            "rgba(229, 21, 30, 0.5)",
                                        },
                                      }}
                                      onClick={() =>
                                        dispatch(
                                          pendingLeadStatusDocsAction(
                                            item.id,
                                            "rejected"
                                          )
                                        )
                                      }
                                    >
                                      Reject
                                    </Button> */}
                                        <Chip
                                          sx={{
                                            height: "auto",
                                            borderRadius: "3px",
                                            backgroundColor:
                                              "rgba(34, 130, 1, 0.2)",
                                            color: "rgba(34, 130, 0, 1)",
                                            "& .MuiChip-label": {
                                              display: "block",
                                              whiteSpace: "normal",
                                              fontSize: "14px",
                                              fontWeight: 400,
                                            },
                                          }}
                                          label="Approved"
                                        />
                                      </>
                                    );
                                  } else if (
                                    data.leadId == item.id &&
                                    data.status === "rejected"
                                  ) {
                                    return (
                                      <>
                                        <Chip
                                          sx={{
                                            height: "auto",
                                            borderRadius: "3px",
                                            backgroundColor:
                                              "rgba(229, 21, 30, 0.2)",
                                            color: "rgba(229, 41, 0, 1)",
                                            "& .MuiChip-label": {
                                              display: "block",
                                              whiteSpace: "normal",
                                              fontSize: "14px",
                                              fontWeight: 400,
                                              //  ml:2,
                                              //  width:"fit-content"
                                            },
                                          }}
                                          label="Rejected"
                                        />

                                        {/* <Button
                                      sx={{
                                        backgroundColor: "rgba(34, 130, 0, 1)",
                                        color: "white",
                                        width: "76px",
                                        height: "25px",
                                        fontWeight: 400,
                                        fontSize: "12px",
                                        mr: 2,
                                        "&:hover": {
                                          backgroundColor:
                                            "rgba(34, 110, 0, 0.5)",
                                        },
                                      }}
                                      onClick={() =>
                                        dispatch(
                                          pendingLeadStatusDocsAction(
                                            item.id,
                                            "approved"
                                          )
                                        )
                                      }
                                    >
                                      Approve
                                    </Button> */}
                                      </>
                                    );
                                  }
                                } else {
                                  console.log(
                                    item.data,
                                    "***********************"
                                  );

                                  if (pendingData.length === 1) {
                                    // if(data.leadId != item.id && !data.status)
                                    return (
                                      <>
                                        {" "}
                                        <Button
                                          sx={{
                                            backgroundColor:
                                              "rgba(34, 130, 0, 1)",
                                            color: "white",
                                            width: "76px",
                                            height: "25px",
                                            fontWeight: 400,
                                            fontSize: "12px",
                                            mr: 2,
                                            "&:hover": {
                                              backgroundColor:
                                                "rgba(34, 110, 0, 0.5)",
                                            },
                                          }}
                                          onClick={() =>
                                            dispatch(
                                              pendingLeadStatusDocsAction(
                                                item.id,
                                                "approved"
                                              )
                                            )
                                          }
                                        >
                                          Approve
                                        </Button>
                                        <Button
                                          sx={{
                                            backgroundColor:
                                              "rgba(229, 41, 0, 1)",
                                            color: "white",
                                            width: "76px",
                                            height: "25px",
                                            fontWeight: 400,
                                            fontSize: "12px",
                                            ml: 1,
                                            "&:hover": {
                                              backgroundColor:
                                                "rgba(229, 21, 30, 0.5)",
                                            },
                                          }}
                                          onClick={() =>
                                            dispatch(
                                              pendingLeadStatusDocsAction(
                                                item.id,
                                                "rejected"
                                              )
                                            )
                                          }
                                        >
                                          Reject
                                        </Button>
                                      </>
                                    );
                                  } else {
                                    if (data.leadId != item.id && !data.status)
                                      return (
                                        <>
                                          {" "}
                                          <Button
                                            sx={{
                                              backgroundColor:
                                                "rgba(34, 130, 0, 1)",
                                              color: "white",
                                              width: "76px",
                                              height: "25px",
                                              fontWeight: 400,
                                              fontSize: "12px",
                                              mr: 2,
                                              "&:hover": {
                                                backgroundColor:
                                                  "rgba(34, 110, 0, 0.5)",
                                              },
                                            }}
                                            onClick={() =>
                                              dispatch(
                                                pendingLeadStatusDocsAction(
                                                  item.id,
                                                  "approved"
                                                )
                                              )
                                            }
                                          >
                                            Approve
                                          </Button>
                                          <Button
                                            sx={{
                                              backgroundColor:
                                                "rgba(229, 41, 0, 1)",
                                              color: "white",
                                              width: "76px",
                                              height: "25px",
                                              fontWeight: 400,
                                              fontSize: "12px",
                                              ml: 1,
                                              "&:hover": {
                                                backgroundColor:
                                                  "rgba(229, 21, 30, 0.5)",
                                              },
                                            }}
                                            onClick={() =>
                                              dispatch(
                                                pendingLeadStatusDocsAction(
                                                  item.id,
                                                  "rejected"
                                                )
                                              )
                                            }
                                          >
                                            Reject
                                          </Button>
                                        </>
                                      );
                                  }
                                }
                              })
                            ) : (
                              <>
                                {" "}
                                <Button
                                  sx={{
                                    backgroundColor: "rgba(34, 130, 0, 1)",
                                    color: "white",
                                    width: "76px",
                                    height: "25px",
                                    fontWeight: 400,
                                    fontSize: "12px",
                                    mr: 2,
                                    "&:hover": {
                                      backgroundColor: "rgba(34, 110, 0, 0.5)",
                                    },
                                  }}
                                  onClick={() =>
                                    dispatch(
                                      pendingLeadStatusDocsAction(
                                        item.id,
                                        "approved"
                                      )
                                    )
                                  }
                                >
                                  Approve
                                </Button>
                                <Button
                                  sx={{
                                    backgroundColor: "rgba(229, 41, 0, 1)",
                                    color: "white",
                                    width: "76px",
                                    height: "25px",
                                    fontWeight: 400,
                                    fontSize: "12px",
                                    ml: 1,
                                    "&:hover": {
                                      backgroundColor: "rgba(229, 21, 30, 0.5)",
                                    },
                                  }}
                                  onClick={() =>
                                    dispatch(
                                      pendingLeadStatusDocsAction(
                                        item.id,
                                        "rejected"
                                      )
                                    )
                                  }
                                >
                                  Reject
                                </Button>
                              </>
                            )}
                          </TableCell>
                        </>
                      )}

                      {/* {/*TABLE ACTION MENU END */}
                    </TableRow>
                  ))}
                </TableBody>
                <TableFooter />
              </Table>
            </div>
          ) : (
            <TableNoItemComponent />
          )}
        </Grid>
      </Grid>
      {/* view modal */}

      {open && (
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              sx={{ fontWeight: "bold" }}
            >
              Job Description:
            </Typography>

            <div
              style={{
                marginTop: "10px",
                paddingTop: "10px",
                width: "500px",
                border: "1px solid #ECECEC",
                paddingBottom: "10rem",
                paddingLeft: "5px",
              }}
            >
              <Editor
                editorState={JobDescription}
                readOnly={true} // Set readOnly to true
              />
            </div>
          </Box>
        </Modal>
      )}

      {openAlert && (
        <PremissionDialog
          openAlert={openAlert}
          handleClose={handleCloseDialog}
        />
      )}
    </>
  );
}

export default EmployeeJobTable;
