import axios from "axios";
import {
  LEAD_SOURCE_CREATE_ERR,
  LEAD_SOURCE_CREATE_REQUEST,
  LEAD_SOURCE_CREATE_SUCCESS,
  LEAD_SOURCE_DELETE_ERR,
  LEAD_SOURCE_DELETE_REQUEST,
  LEAD_SOURCE_DELETE_SUCCESS,
  LEAD_SOURCE_FIND_DETAILS_ERR,
  LEAD_SOURCE_FIND_DETAILS_REQUEST,
  LEAD_SOURCE_FIND_DETAILS_SUCCESS,
  LEAD_SOURCE_FIND_ERR,
  LEAD_SOURCE_FIND_REQUEST,
  LEAD_SOURCE_FIND_SUCCESS,
} from "../constants/leadSourceConstant";
import { SUCCESS_TRUE_MSG_ERR } from "../constants/roleManagmentConstant";

// leadSourceCreateAction

export const leadSourceCreateAction =
  (formData) => async (dispatch, getState) => {
    try {
      dispatch({ type: LEAD_SOURCE_CREATE_REQUEST });

      let isUserExist = localStorage.getItem("loginInfo")
        ? JSON.parse(localStorage.getItem("loginInfo"))
        : null;

      const config = {
        headers: {
          Authorization: `Bearer ${isUserExist?.token}`,
        },
      };

      let { data } = await axios.post(
        "/api/admin/leadSource/leadSourceCreate",
        formData,
        config
      );

      dispatch({ type: LEAD_SOURCE_CREATE_SUCCESS, payload: data });
    } catch (error) {
      dispatch({ type: LEAD_SOURCE_CREATE_ERR, payload: error.response.data });
      dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: true });
    }
  };
// // lead source list action

export const leadSourceFindAction = (page) => async (dispatch, getState) => {
  try {
    dispatch({ type: LEAD_SOURCE_FIND_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.get(
      `/api/admin/leadSource/leadSourceFind?page=${page}`,
      config
    );

    dispatch({ type: LEAD_SOURCE_FIND_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: LEAD_SOURCE_FIND_ERR, payload: error.response.data });
    dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: true });
  }
};

// leadSourceFindDetailAction
export const leadSourceFindDetailAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: LEAD_SOURCE_FIND_DETAILS_REQUEST });

      let isUserExist = localStorage.getItem("loginInfo")
        ? JSON.parse(localStorage.getItem("loginInfo"))
        : null;

      const config = {
        headers: {
          Authorization: `Bearer ${isUserExist?.token}`,
        },
      };

      let { data } = await axios.get(
        `/api/admin/leadSource/leadSourceFindDetail?id=${id}`,
        config
      );

      dispatch({ type: LEAD_SOURCE_FIND_DETAILS_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: LEAD_SOURCE_FIND_DETAILS_ERR,
        payload: error.response.data,
      });
      dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: true });
    }
  };

export const leadSourceEditAction =
  (formData, id) => async (dispatch, getState) => {
    try {
      dispatch({ type: LEAD_SOURCE_CREATE_REQUEST });

      let isUserExist = localStorage.getItem("loginInfo")
        ? JSON.parse(localStorage.getItem("loginInfo"))
        : null;

      const config = {
        headers: {
          Authorization: `Bearer ${isUserExist?.token}`,
        },
      };

      let { data } = await axios.put(
        `/api/admin/leadSource/leadSourceEdit?id=${id}`,
        formData,
        config
      );

      dispatch({ type: LEAD_SOURCE_CREATE_SUCCESS, payload: data });
    } catch (error) {
      dispatch({ type: LEAD_SOURCE_CREATE_ERR, payload: error.response.data });
      dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: true });
    }
  };

// // Banner delete action

export const deleteLeadSourceAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: LEAD_SOURCE_DELETE_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.delete(
      `/api/admin/leadSource/deleteLeadSource?id=${id}`,
      config
    );

    dispatch({ type: LEAD_SOURCE_DELETE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: LEAD_SOURCE_DELETE_ERR, payload: error.response.data });
    dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: true });
  }
};
