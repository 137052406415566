import React, { useEffect, useRef, useState } from "react";
import { Divider, Grid } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";

import InputField, { SelectInputField } from "../../customComponent/InputField";
import { MainContainer } from "../../content/content.element";
import { InfoHeader } from "../../customComponent/InfoHeader";
import { SubmitButton } from "../../customComponent/Buttons";
import {
  findTestimonialByIdAction,
  testimonialEditAction,
} from "../../../actions/LandingPageAction";
import { findUniversityAction } from "../../../actions/universityActions/universityManagmentAction";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { successTrueMsgAction } from "../../../actions/globalAction";

function EditTestimonials(props) {
  const dispatch = useDispatch();
  const { id } = useParams();

  const [DestinationimageValue, setDestinationimageValue] = useState("");
  const [content, setContent] = useState("");
  const [name, setName] = useState("");
  const [universities, setUniversities] = useState();
  const [universityValue, setUniversityValue] = useState("");
  const [newsImage, setNewsImage] = useState();
  const newsimage = useRef();

  let { universityFindSuccess } = useSelector((state) => {
    return state.findUniversity;
  });

  let { testimonialFindByIdSuccess } = useSelector((state) => {
    return state.findTestimonialById;
  });

  let { testimonialEditedSuccess } = useSelector((state) => {
    return state.testimonialEdit;
  });

  let { successAlertMsgSuccess, successAlertMsgErr } = useSelector((state) => {
    return state.successTrueMsg;
  });

  useEffect(() => {
    if (testimonialEditedSuccess && successAlertMsgSuccess) {
      Swal.fire("Updated", "Testimonials Edited Successfully.", "success");
      dispatch(successTrueMsgAction(false));
    }
  }, [testimonialEditedSuccess]);

  useEffect(() => {
    dispatch(findTestimonialByIdAction(id));
  }, [dispatch]);

  useEffect(() => {
    dispatch(findUniversityAction());
  }, [dispatch]);

  useEffect(() => {
    if (testimonialFindByIdSuccess) {
      setDestinationimageValue(testimonialFindByIdSuccess?.imageUrl);
      setContent(testimonialFindByIdSuccess?.content);
      setName(testimonialFindByIdSuccess?.name);
      setUniversityValue(testimonialFindByIdSuccess?.university);
    }
  }, [testimonialFindByIdSuccess]);

  useEffect(() => {
    if (universityFindSuccess) {
      setUniversities(
        universityFindSuccess.map((country, index) => ({
          universityname: country.universityName,
          id: country.id,
        }))
      );
    }
  }, [universityFindSuccess]);

  const handleDestinationImage = (event) => {
    const file = event.target.files[0];
    setDestinationimageValue(file.name);
    const reader = new FileReader();

    // Read the image file as a data URL
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      const UploadDestinationimage = reader.result;
      setNewsImage(UploadDestinationimage);
    };
  };
  
  // HandleCreate

  const HandleCreate = () => {
    console.log(
      id,
      newsImage,
      name,
      universityValue,
      content,
      "id, newsImage, name, universityValue, content"
    );
    dispatch(
      testimonialEditAction(id, newsImage, name, universityValue, content)
    );
      dispatch(successTrueMsgAction(true));
  };

  return (
    <MainContainer active={props.toggle}>
      <div
        style={{
          display: "flex",
          justifyContent: "start",
          backgroundColor: "white",
        }}
      >
        <InfoHeader headTitle="Edit Testimonials" url={"/landingPage"} />
      </div>
      <Divider />

      <Grid container sx={{ mt: 3 }}>
        <Grid item xs={12} md={6} lg={4} style={{ position: "relative" }}>
          <Grid>
            <InputField
              label="Picture"
              widthSize="92%"
              value={DestinationimageValue}
              required
              paramses
            ></InputField>
          </Grid>
          <Grid style={{ position: "absolute", top: 5, right: 30 }}>
            <button
              onClick={() => newsimage.current.click()}
              style={{
                width: "100px",
                height: "40px",
                fontSize: "13px",
                fontFamily: "monospace",
                border: "none",
              }}
            >
              Browse
            </button>
            <input
              ref={newsimage}
              type="file"
              accept="image/*"
              onChange={(event) => handleDestinationImage(event)}
              style={{ display: "none" }}
            ></input>
          </Grid>
        </Grid>

        <Grid item xs={12} md={6} lg={4} style={{ position: "relative" }}>
          <InputField
            label="Name"
            widthSize="92%"
            value={name}
            handleChange={(e) => setName(e.target.value)}
            required
            paramses
          ></InputField>
        </Grid>

        <Grid item xs={12} md={6} lg={4} style={{ position: "relative" }}>
          {/* <div style={{ marginTop: "20px" }}> */}
          {universityValue && (
            <SelectInputField
              label="Select Universities"
              docName
              handleSelectChange={(event) => {
                const {
                  target: { value },
                } = event;

                setUniversityValue(value);
              }}
              widthSize="auto"
              value={universityValue}
              mapValue={universities}
              mapMethod="alluniversities"
            />
          )}
          {console.log(universityValue,"universityValue")}
          {/* </div> */}
        </Grid>

        <Grid item xs={12} lg={12} sx={{ mt: 3 }}>
          <InputField
            label="Content"
            handleChange={(e) => setContent(e.target.value)}
            widthSize="99.2%"
            value={content}
            InputType="discription"
            dispcriptionHeight="100px"
            required
            paramses
          />
        </Grid>
      </Grid>
      {/* Submit Button */}
      <Grid
        item
        xs={4}
        lg={12}
        style={{
          display: "flex",
          justifyContent: "flex-start",
          marginTop: "10px",
        }}
      >
        <SubmitButton
          title="Save"
          submit=""
          widthSize="100px"
          heightSize="38px"
          type="click"
          handleSubmit={HandleCreate}
        />
      </Grid>
    </MainContainer>
  );
}

export default EditTestimonials;
