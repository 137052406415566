import React from "react";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Typography from "@material-ui/core/Typography";

const CustomCheckbox = ({
  name,
  checked,
  onChange,
  helpertext,
  error,
  label,
}) => {
  return (
    <>
      {/* <FormControlLabel
      control={
        <Checkbox
          name={name}
          checked={checked}
          onChange={onChange}
          color="primary"
        />
      }
      label={
        <Typography
          variant="body2"
          style={{ fontSize: '12px', fontWeight: 400 }}
        >
          {label}
        </Typography>
      }
    /> */}
      <label >
        <input
          type="checkbox"
          name={name}
          checked={checked}
          onChange={onChange}
          style={{width:"20px",height:"15px",marginTop:"16px"}}
        />
      </label>
        <span style={{ fontSize: "13px", fontWeight: 450,marginTop:"-10px" }}>{label}</span>
    </>
  );
};

export default CustomCheckbox;
