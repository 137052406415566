import {
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import React, { useMemo, useState } from "react";
import { SlOptionsVertical } from "react-icons/sl";
// import "./Master.table.css";
import { BiSortAlt2 } from "react-icons/bi";
import { ieltsCategoryStatusChangeAction } from "../../../actions/IeltsActions/learningManadmentAction";
import { useDispatch } from "react-redux";
import TableNoItemComponent from "../../customComponent/TableNoItemComponent";

function CategoryTable({ data, columns, component }) {
  const dispatch = useDispatch()
  const [status, setStatus] = useState("");

  // Sort icon

  const CustomSortIcon = ({ direction }) => {
    return direction === "asc" ? (
      <img
        src="/menuIcons/tableUpSort.png"
        className="customIcon"
        alt="gsl"
        style={{ padding: "15px" }}
      />
    ) : (
      <BiSortAlt2 className="customIcon" />
    );
  };

  // sorting table
  const [sortBy, setSortBy] = useState(""); // Current sort column
  const [sortOrder, setSortOrder] = useState("asc"); // Current sort order

  const requestSort = (column) => {
    let newSortOrder = "asc";

    if (column === sortBy && sortOrder === "asc") {
      newSortOrder = "desc";
    }

    setSortBy(column);
    setSortOrder(newSortOrder);
  };

  let sortedData = useMemo(() => {
    if (sortBy && data) {
      const sortedItems = [...data].sort((a, b) => {
        if (sortOrder === "asc") {
          return a[sortBy] < b[sortBy] ? -1 : 1;
        } else {
          return a[sortBy] < b[sortBy] ? 1 : -1;
        }
      });

      return sortedItems;
    }

    return data;
  }, [data, sortBy, sortOrder]);


  // Handle status change
  const handleStatusChange = (status, id) => {
    dispatch(ieltsCategoryStatusChangeAction(status, id));
  };

  console.log(sortedData,"sortedDatasortedData")

  return sortedData?.length === 0 ? (
    <TableNoItemComponent />
  ) : (
    <div>
      <Table className="masterTable">
        <TableHead
          sx={{
            background: "#F9F9FB",
          }}
        >
          <TableRow className="msterTableCell">
            <TableCell>
              <Checkbox
                // checked={selectedRows.length === sortedData.length}
                // onChange={handleSelectAllRows}
                style={{ color: "#f81b82" }}
              />
            </TableCell>
            {columns?.map((column) => {
              if (column.hidden) {
                return null;
              }
              return (
                <TableCell key={column.field}>
                  <TableSortLabel
                    className="headerText"
                    active={column === sortBy}
                    direction={sortOrder}
                    onClick={() => requestSort(column)}
                    IconComponent={column.field !== "action" && CustomSortIcon}
                  >
                    {column.title}
                  </TableSortLabel>
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedData?.map((item, indx) => (
            <TableRow className="tableRow" key={indx}>
              <TableCell>
                <Checkbox
                  //   checked={selectedRows.includes(item.id)}
                  //   onChange={() => handleSelectRow(item.id)}
                  style={{ color: "#f81b82" }}
                />
              </TableCell>
              <TableCell>{indx + 1}</TableCell>
              <TableCell>{item?.CategoryName}</TableCell>

              <TableCell>
                <FormControl
                  fullWidth
                  sx={{
                    mt: 1,
                    bgcolor: item.status == "Active" ? "#228200" : "#E52900",
                    borderRadius: "8px",
                    height: "30px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    "& .MuiSelect-icon": {
                      top: "3px",
                    },
                    "&.MuiFormControl-root": {
                      width: "100px",
                    },
                  }}
                >
                  <InputLabel
                    htmlFor="demo-simple-select-placeholder"
                    style={{
                      transform: "translate(10px, 10px)",
                      pointerEvents: "none",
                      zIndex: 1,
                      transition: "transform 0.25s, font-size 0.25s",
                      margin: "-6px",
                      color: "white",
                      fontWeight: 400,
                      paddingLeft: "10px",
                      border: "none !important",
                    }}
                  >
                    {item.status ? item.status : "placeholder"}
                  </InputLabel>
                  <Select
                    value={status}
                    onChange={(e) =>
                      handleStatusChange(e.target.value, item.id)
                    }
                    inputProps={{
                      // name: {item.coloum7},
                      id: "demo-simple-select-placeholder",
                    }}
                    sx={{
                      position: "absolute",
                      top: "0px",
                      right: "0",
                      ".MuiSelect-select": {
                        padding: "0",
                      },
                      ".MuiOutlinedInput-notchedOutline": {
                        outline: "none !important",
                        border: "none !important",
                      },
                    }}
                  >
                    <MenuItem value="Active">Active</MenuItem>
                    <MenuItem value="Inactive">Inactive</MenuItem>
                  </Select>
                </FormControl>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter />
      </Table>
    </div>
  );
}

export default CategoryTable;
