import { IELTS_FIND_PURCHASED_STUDENT_DETAILS_BY_ID_ERR, IELTS_FIND_PURCHASED_STUDENT_DETAILS_BY_ID_REQUEST, IELTS_FIND_PURCHASED_STUDENT_DETAILS_BY_ID_SUCCESS, IELTS_FIND_PURCHASED_STUDENT_COURSE_ERR, IELTS_FIND_PURCHASED_STUDENT_COURSE_REQUEST, IELTS_FIND_PURCHASED_STUDENT_COURSE_SUCCESS, IELTS_PURCHASED_STUDENT_COURSE_STATUS_CHANGE_ERR, IELTS_PURCHASED_STUDENT_COURSE_STATUS_CHANGE_REQUEST, IELTS_PURCHASED_STUDENT_COURSE_STATUS_CHANGE_SUCCESS, IELTS_FIND_PURCHASED_STUDENT_COURSE_BY_ID_REQUEST, IELTS_FIND_PURCHASED_STUDENT_COURSE_BY_ID_SUCCESS, IELTS_FIND_PURCHASED_STUDENT_COURSE_BY_ID_ERR, IELTS_PURCHASED_STUDENT_ASSIGN_TEACHER_REQUEST, IELTS_PURCHASED_STUDENT_ASSIGN_TEACHER_ERR, IELTS_PURCHASED_STUDENT_ASSIGN_TEACHER_SUCCESS } from "../../constants/Ielts/IeltsPurchasedConstants";

export const ieltsPurchasedStudentFindReducer = (state = {}, action) => {
  switch (action.type) {
    case IELTS_FIND_PURCHASED_STUDENT_COURSE_REQUEST:
      return {
        ...state,
        ieltsPurchasedStudentFindLoading: true,
      };
    case IELTS_FIND_PURCHASED_STUDENT_COURSE_SUCCESS:
      return {
        ...state,
        ieltsPurchasedStudentFindLoading: false,
        ieltsPurchasedStudentFindSuccess: action.payload,
      };
    case IELTS_FIND_PURCHASED_STUDENT_COURSE_ERR:
      return {
        ...state,
        ieltsPurchasedStudentFindLoading: false,
        ieltsPurchasedStudentFinderror: action.payload,
      };
    default:
      return state;
  }
};

export const ieltsPurchasedStudentDetailsFindByIdReducer = (state = {}, action) => {
  switch (action.type) {
    case IELTS_FIND_PURCHASED_STUDENT_DETAILS_BY_ID_REQUEST:
      return {
        ...state,
        ieltsPurchasedStudentDetailsFindByIdLoading: true,
      };
    case IELTS_FIND_PURCHASED_STUDENT_DETAILS_BY_ID_SUCCESS:
      return {
        ...state,
        ieltsPurchasedStudentDetailsFindByIdLoading: false,
        ieltsPurchasedStudentDetailsFindByIdSuccess: action.payload,
      };
    case IELTS_FIND_PURCHASED_STUDENT_DETAILS_BY_ID_ERR:
      return {
        ...state,
        ieltsPurchasedStudentDetailsFindByIdLoading: false,
        ieltsPurchasedStudentDetailsFindByIderror: action.payload,
      };
    default:
      return state;
  }
};

export const ieltsPurchasedStudentCourseFindByIdReducer = (state = {}, action) => {
  switch (action.type) {
    case IELTS_FIND_PURCHASED_STUDENT_COURSE_BY_ID_REQUEST:
      return {
        ...state,
        ieltsPurchasedStudentCourseFindByIdLoading: true,
      };
    case IELTS_FIND_PURCHASED_STUDENT_COURSE_BY_ID_SUCCESS:
      return {
        ...state,
        ieltsPurchasedStudentCourseFindByIdLoading: false,
        ieltsPurchasedStudentCourseFindByIdSuccess: action.payload,
      };
    case IELTS_FIND_PURCHASED_STUDENT_COURSE_BY_ID_ERR:
      return {
        ...state,
        ieltsPurchasedStudentCourseFindByIdLoading: false,
        ieltsPurchasedStudentCourseFindByIderror: action.payload,
      };
    default:
      return state;
  }
};

export const ieltsPurchasedStudentAssignTeacherReducer = (state = {}, action) => {
  switch (action.type) {
    case IELTS_PURCHASED_STUDENT_ASSIGN_TEACHER_REQUEST:
      return {
        ...state,
        ieltsPurchasedStudentAssignTeacherLoading: true,
      };
    case IELTS_PURCHASED_STUDENT_ASSIGN_TEACHER_SUCCESS:
      return {
        ...state,
        ieltsPurchasedStudentAssignTeacherLoading: false,
        ieltsPurchasedStudentAssignTeacherSuccess: action.payload,
      };
    case IELTS_PURCHASED_STUDENT_ASSIGN_TEACHER_ERR:
      return {
        ...state,
        ieltsPurchasedStudentAssignTeacherLoading: false,
        ieltsPurchasedStudentAssignTeachererror: action.payload,
      };
    default:
      return state;
  }
};

export const ieltsPurchasedStudentStatusChangeReducer = (state = {}, action) => {
  switch (action.type) {
    case IELTS_PURCHASED_STUDENT_COURSE_STATUS_CHANGE_REQUEST:
      return {
        ...state,
        ieltsPurchasedStudentChangeStatusLoading: true,
      };
    case IELTS_PURCHASED_STUDENT_COURSE_STATUS_CHANGE_SUCCESS:
      return {
        ...state,
        ieltsPurchasedStudentChangeStatusLoading: false,
        ieltsPurchasedStudentChangeStatusSuccess: action.payload,
      };
    case IELTS_PURCHASED_STUDENT_COURSE_STATUS_CHANGE_ERR:
      return {
        ...state,
        ieltsPurchasedStudentChangeStatusLoading: false,
        ieltsPurchasedStudentChangeStatuserror: action.payload,
      };
    default:
      return state;
  }
};
