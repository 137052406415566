import React, { useEffect, useMemo, useState } from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableFooter,
  TableSortLabel,
  Menu,
  Paper,
  Alert,
  AlertTitle,
} from "@mui/material";

import { makeStyles } from "@material-ui/core";

import { SlOptionsVertical } from "react-icons/sl";
import {
  Box,
  Checkbox,
  Grid,
  MenuItem,
  Modal,
  TableContainer,
  Typography,
} from "@mui/material";
import { TbEdit } from "react-icons/tb";
import { AiOutlineEye } from "react-icons/ai";
import { RiDeleteBin6Line } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { useDispatch } from "react-redux";
import { MdNotInterested } from "react-icons/md";
import { BiSortAlt2 } from "react-icons/bi";
import usePrivilegeCheck from "../../constants/customHooks/UsePrivilegeCheck";

import "./hrMain.css";
import { hrEmployeeAttendanceAction } from "../../actions/HrActions/hrEmployeeManagmentAction";
import PremissionDialog from "../customComponent/premissionDialog";
import TableNoItemComponent from "../customComponent/TableNoItemComponent";

// show role details popup
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 550,
  height: 600,
  overflow: "scroll",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 20,
  p: 4,
};

export const HrAttendanceTable = ({
  columns,
  data,
  component,
  isPresentMap,
  subMenu,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // custom Hooks
  const { privilege, hasPrivilege, isUpdatePrevilage } = usePrivilegeCheck();

  const [openAlert, setOpenAlert] = useState(false);

  // HandleClose dialog

  const handleCloseDialog = () => {
    setOpenAlert(false);
  };

  const [actionButtonShow, setActionButtonShow] = useState(null);
  const [tableId, setTableId] = useState("");
  const [roleData, setRoleData] = useState();
  const [popupData, setPopupData] = useState({});
  const [open, setOpen] = useState(false);

  const openActionButton = Boolean(actionButtonShow);

  const handleClickAction = (event, id) => {
    setActionButtonShow(event.currentTarget);
    setTableId(id);
  };

  const handleCloseAction = () => {
    setActionButtonShow(null);
  };

  // navigate to Edit page
  const rollEditHandler = (tableId) => {};

  const RoledeleteHandler = (role) => {};

  const handleOpen = (id) => {};

  //

  const filterBranch = (item) => {
    let result = item?.map((branch) => branch?.branch_master?.branchName);
    let arrayTosend =
      result.length > 0 ? result?.join(",") : "Branch Not Assigned";
    return arrayTosend;
  };

  const CustomSortIcon = ({ direction }) => {
    return direction === "asc" ? (
      <img
        src="/menuIcons/tableUpSort.png"
        className="administratCustomIcon"
        alt="gsl"
        style={{ padding: "15px" }}
      />
    ) : (
      <BiSortAlt2 className="administratCustomIcon" />
    );
  };

  // sorting table
  const [sortBy, setSortBy] = useState(""); // Current sort column
  const [sortOrder, setSortOrder] = useState("asc"); // Current sort order

  const requestSort = (column) => {
    let newSortOrder = "asc";

    if (column === sortBy && sortOrder === "asc") {
      newSortOrder = "desc";
    }

    setSortBy(column);
    setSortOrder(newSortOrder);
  };
  let sortedData = useMemo(() => {
    if (sortBy && data) {
      const sortedItems = [...data].sort((a, b) => {
        if (sortOrder === "asc") {
          return a[sortBy] < b[sortBy] ? -1 : 1;
        } else {
          return a[sortBy] < b[sortBy] ? 1 : -1;
        }
      });
      return sortedItems;
    }
    return data;
  }, [data, sortBy, sortOrder]);

  const handleClose = () => setOpen(false);

  // Create a function to handle selecting individual rows:

  // if (!hasPrivilege("isadministrationView") && privilege !== null) {
  //   return (
  //     <Alert severity="error">
  //       <AlertTitle>warning</AlertTitle>
  //       You don't have permission to access — <strong>this page.!</strong>
  //     </Alert>
  //   );
  // }

  // Create a function to handle the present and absent coloumn
  const handleAttendanceClick = (event, id, status) => {
    const isChecked = event.target.checked;
    if (isUpdatePrevilage(subMenu)) {
      if (status == "present") {
        dispatch(hrEmployeeAttendanceAction(id, isChecked, status));
      }
      if (status == "absent") {
        dispatch(hrEmployeeAttendanceAction(id, isChecked, status));
      }
    } else {
      setOpenAlert(true);
    }
  };
  console.log(sortedData, "iubibibib");

  return (
    <>
      {sortedData.length > 0 ? (
        <Table className="hrTable">
          <TableHead>
            <TableRow className="hrTableCell">
              <TableCell>
                <Checkbox
                  // checked={selectedRows.length === sortedData.length}
                  // onChange={handleSelectAllRows}
                  style={{ color: "#f81b82" }}
                />
              </TableCell>
              {columns?.map((column) => {
                if (column.hidden) {
                  return null;
                }
                return (
                  <TableCell key={column.field} className="hrTableCell">
                    <TableSortLabel
                      className="hrHeaderText"
                      active={column === sortBy}
                      direction={sortOrder}
                      onClick={() => requestSort(column)}
                      IconComponent={
                        column.field !== "action" && CustomSortIcon
                      }
                    >
                      {column.title}
                    </TableSortLabel>
                  </TableCell>
                );
              })}
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedData?.map((item, indx) => (
              <TableRow className="hrTable" key={indx}>
                <TableCell>
                  <Checkbox
                    // checked={selectedRows.includes(item.id)}
                    // onChange={() => handleSelectRow(item.id)}
                    style={{ color: "#f81b82" }}
                  />
                </TableCell>
                <TableCell>{item.sl}</TableCell>
                <TableCell>{item.coloumn1}</TableCell>
                <TableCell>{item.coloumn2}</TableCell>
                <TableCell>{item.coloumn3}</TableCell>

                <TableCell>{filterBranch(item.coloumn4)}</TableCell>
                <TableCell>
                  <Checkbox
                    checked={
                      item.coloumn6
                        ? item.coloumn6 === true
                          ? true
                          : false
                        : null
                    }
                    onChange={(event) =>
                      handleAttendanceClick(event, item.id, "present")
                    }
                    style={{ color: "#f81b82" }}
                  />
                </TableCell>

                <TableCell>
                  <Checkbox
                    checked={
                      item.coloumn7
                        ? item.coloumn7 === true
                          ? true
                          : false
                        : null
                    }
                    onChange={(event) =>
                      handleAttendanceClick(event, item.id, "absent")
                    }
                    style={{ color: "#f81b82" }}
                  />
                </TableCell>
                <TableCell>{item.coloumn5}</TableCell>
                {/* <TableCell>
                <SlOptionsVertical
                  id="demo-positioned-button"
                  aria-controls={
                    openActionButton ? "demo-positioned-menu" : undefined
                  }
                  aria-haspopup="true"
                  aria-expanded={openActionButton ? "true" : undefined}
                  onClick={(e) => {
                    handleClickAction(e, item.id);
                  }}
                  variant="outlined"
                  style={{ marginLeft: "10px", color: "#F80B7A" }}
                />
              </TableCell> */}
              </TableRow>
            ))}
          </TableBody>
          <TableFooter />
        </Table>
      ) : (
        <TableNoItemComponent />
      )}
      {/* TABLE ACTION MENU START*/}
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={actionButtonShow}
        open={openActionButton}
        onClose={handleCloseAction}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        anchorOrigin={{
          vertical: "bottom", // Change from "top" to "bottom"
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top", // Change from "top" to "bottom"
          horizontal: "left",
        }}
        sx={{
          borderColor: "black",
          borderRadius: "24px",
          mt: 1.5,
          width: "auto",
          height: "auto",
        }}
      >
        <Box style={{ display: "flex", justifyContent: "flex-end" }}>
          {component !== "employee" && (
            <MenuItem>
              <TbEdit
                style={{
                  fontSize: "lg",
                  width: "20px",
                  height: "19px",
                }}
                onClick={() => rollEditHandler(tableId)}
                className="icons"
              />
            </MenuItem>
          )}

          <MenuItem>
            <AiOutlineEye
              onClick={() => handleOpen(tableId)}
              className="icons"
              style={{
                fontSize: "lg",
                width: "20px",
                height: "19px",
              }}
            />
          </MenuItem>

          {privilege?.isadministrationDelete !== false ? (
            <MenuItem>
              {component !== "employee" ? (
                <RiDeleteBin6Line
                  onClick={() => RoledeleteHandler(tableId)}
                  className="icons"
                  style={{
                    fontSize: "lg",
                    width: "20px",
                    height: "19px",
                  }}
                />
              ) : (
                <RiDeleteBin6Line
                  onClick={() => {
                    Swal.fire({
                      title: "Are you sure?",
                      text: "You won't be able to revert this!",
                      icon: "warning",
                      showCancelButton: true,
                      confirmButtonColor: "#3085d6",
                      cancelButtonColor: "#d33",
                      confirmButtonText: "Yes, delete it!",
                    }).then((result) => {
                      if (result.isConfirmed) {
                        setActionButtonShow(false);
                        Swal.fire(
                          "Deleted!",
                          "Your file has been deleted!.",
                          "success"
                        );
                      }
                    });
                  }}
                  style={{
                    fontSize: "lg",
                    width: "20px",
                    height: "19px",
                  }}
                  className="icons"
                />
              )}
            </MenuItem>
          ) : (
            <MdNotInterested
              className="icons"
              onClick={() => {
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "you don't have delete permission",
                });
              }}
            />
          )}
        </Box>
      </Menu>
      {openAlert && (
        <PremissionDialog
          openAlert={openAlert}
          handleClose={handleCloseDialog}
        />
      )}
    </>
  );
};
