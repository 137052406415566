import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import RoleMangment from "../administration/role/RoleMangment";
import { ThemeProvider } from "styled-components";
import { createTheme } from "@material-ui/core";

import { styled } from "@mui/material/styles";
import { useEffect } from "react";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs({
  tab1,
  tab2,
  tab3,
  tab4,
  tab5,
  tab6,
  tab7,
  tab8,
  tab9,
  tab10,
  tab11,
  tab12,
  tab13,
  tab1Name,
  tab2Name,
  tab3Name,
  tab4Name,
  tab5Name,
  tab6Name,
  tab7Name,
  tab8Name,
  tab9Name,
  tab10Name,
  tab11Name,
  tab12Name,
  tab13Name,
  callback,
  selected,
}) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    callback && callback(newValue);
    if (selected) {
      localStorage.setItem("selectedTab", newValue.toString());
    }
  };

  useEffect(() => {
    if (selected) {
      const storedTab = localStorage.getItem("selectedTab");
      if (storedTab !== null) {
        setValue(parseInt(storedTab, 10));
      }
    }
  }, []);

  // theme Provider
  let theme = createTheme({
    palette: {
      primary: {
        main: "#0052cc",
      },
      secondary: {
        main: "#edf2ff",
      },
    },
  });

  theme = createTheme(theme, {
    palette: {
      info: {
        main: theme.palette.secondary.main,
      },
    },
  });

  const StyledTab = styled(Tab)({
    "&.Mui-selected": {
      color: "black",
      // borderBottom: "3px solid black",
    },
  });


  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ width: "100%", mt: 1 }}>
        <Box class="tab-head" sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            {tab1Name && <StyledTab label={tab1Name} {...a11yProps(0)} />}
            {tab2Name && <StyledTab label={tab2Name} {...a11yProps(1)} />}
            {tab3Name && <StyledTab label={tab3Name} {...a11yProps(2)} />}
            {tab4Name && <StyledTab label={tab4Name} {...a11yProps(3)} />}
            {tab5Name && <StyledTab label={tab5Name} {...a11yProps(4)} />}
            {tab6Name && <StyledTab label={tab6Name} {...a11yProps(5)} />}
            {tab7Name && <StyledTab label={tab7Name} {...a11yProps(6)} />}
            {tab8Name && <StyledTab label={tab8Name} {...a11yProps(7)} />}
            {tab9Name && <StyledTab label={tab9Name} {...a11yProps(8)} />}
            {tab10Name && <StyledTab label={tab10Name} {...a11yProps(9)} />}
            {tab11Name && <StyledTab label={tab11Name} {...a11yProps(10)} />}
            {tab12Name && <StyledTab label={tab12Name} {...a11yProps(11)} />}
            {tab13Name && <StyledTab label={tab13Name} {...a11yProps(11)} />}
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          {tab1}
        </TabPanel>
        <TabPanel value={value} index={1}>
          {tab2}
        </TabPanel>
        <TabPanel value={value} index={2}>
          {tab3}
        </TabPanel>
        <TabPanel value={value} index={3}>
          {tab4}
        </TabPanel>
        <TabPanel value={value} index={4}>
          {tab5}
        </TabPanel>
        <TabPanel value={value} index={5}>
          {tab6}
        </TabPanel>
        <TabPanel value={value} index={6}>
          {tab7}
        </TabPanel>
        <TabPanel value={value} index={7}>
          {tab8}
        </TabPanel>
        <TabPanel value={value} index={8}>
          {tab9}
        </TabPanel>
        <TabPanel value={value} index={9}>
          {tab10}
        </TabPanel>
        <TabPanel value={value} index={10}>
          {tab11}
        </TabPanel>
        <TabPanel value={value} index={11}>
          {tab12}
        </TabPanel>
        <TabPanel value={value} index={12}>
          {tab13}
        </TabPanel>
      </Box>
    </ThemeProvider>
  );
}
