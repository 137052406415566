import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ReferalSettingsTable from "./ReferalSettingsTable";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import {
  createFaqAction,
  createReferalsAction,
  getFaqAction,
  getReferalsAction,
} from "../../../actions/referAndEarn/referAndEarnActions";
import usePrivilegeCheck from "../../../constants/customHooks/UsePrivilegeCheck";
import PremissionDialog from "../../customComponent/premissionDialog";

function ReferalSettings() {
  const dispatch = useDispatch();
  const {
    privilege,
    hasPrivilege,
    isCreatePrevilage,
    isUpdatePrevilage,
  } = usePrivilegeCheck();

  const [openAlert, setOpenAlert] = useState(false);

  // HandleClose dialog

  const handleCloseDialog = () => {
    setOpenAlert(false);
  };

  let { getReferalSettingsSuccess } = useSelector(
    (state) => state.getReferalSettings
  );
  let { createReferalSettingsSuccess } = useSelector(
    (state) => state.createReferalSettings
  );

  let { getFaqSuccess } = useSelector((state) => state.getFaqChange);

  let { createFaqSuccess } = useSelector((state) => state.createFaqChange);

  let { updateReferalFaqSuccess } = useSelector(
    (state) => state.updatereferalFaq
  );

  let { deleteReferalFaqSuccess } = useSelector(
    (state) => state.deleteReferalFaq
  );

  const [referalSettings, setReferalSettings] = useState();
  const [referalFaq, setReferalFaq] = useState();

  useEffect(() => {
    if (getReferalSettingsSuccess) {
      setReferalSettings(getReferalSettingsSuccess.data);
    }
  }, [getReferalSettingsSuccess]);

  useEffect(() => {
    if (getFaqSuccess) {
      setReferalFaq(getFaqSuccess.data);
    }
  }, [getFaqSuccess]);

  useEffect(() => {
    dispatch(getReferalsAction());
  }, [dispatch, createReferalSettingsSuccess]);

  useEffect(() => {
    dispatch(getFaqAction());
  }, [
    dispatch,
    createFaqSuccess,
    deleteReferalFaqSuccess,
    updateReferalFaqSuccess,
  ]);

  const [open, setOpen] = useState(false);
  const [modal, setModal] = useState("");
  const [formData, setFormData] = useState({});
  const [errorData, setErrorData] = useState();

  const handleClose = () => {
    setOpen(false);
    setFormData({});
  };

  const handleNumberChange = (e) => {
    const { name, value } = e.target;
    const regex = /^[0-9]+$/;
    if (regex.test(value)) {
      setFormData((prev) => ({ ...prev, [name]: value }));
      setErrorData((prev) => ({ ...prev, [name]: "" }));
    } else {
      setFormData((prev) => ({ ...prev, [name]: "" }));
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    setErrorData((prev) => ({ ...prev, [name]: "" }));
  };

  const handleOpen = (title) => {
    if (isCreatePrevilage("referral settings")) {
      setOpen(true);
      setModal(title);
    } else {
      setOpenAlert(true);
    }
  };

  const NumberValidateForm = () => {
    const newErrors = {};
    if (!formData.amountPerEnrollment) {
      newErrors.amountPerEnrollment = "Amount per enrollment count is required";
    } else if (isNaN(formData.amountPerEnrollment)) {
      newErrors.amountPerEnrollment =
        "Amount per enrollment count must be a number";
    }
    if (!formData.withDrawLimit) {
      newErrors.withDrawLimit = "Withdraw Limit is required";
    } else if (isNaN(formData.withDrawLimit)) {
      newErrors.withDrawLimit = "Withdraw Limit must be a number";
    }
    setErrorData(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.question) {
      newErrors.question = "please enter a question";
    }
    if (!formData.answer) {
      newErrors.answer = "please enter a answer";
    }
    setErrorData(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (modal === "referalDetails") {
      if (NumberValidateForm()) {
        let datas = {
          referalAmount: formData.amountPerEnrollment,
          WithDrawalLimit: formData.withDrawLimit,
        };
        dispatch(createReferalsAction(datas));
        setFormData({
          amountPerEnrollment: "",
          withDrawLimit: "",
        });
        setOpen(false);
      } else {
        console.log("Form has errors:", errorData);
      }
    }
    if (modal === "faq") {
      if (validateForm()) {
        let datas = {
          question: formData.question,
          answer: formData.answer,
        };
        dispatch(createFaqAction(datas));
        setFormData({
          question: "",
          answer: "",
        });
        setOpen(false);
      } else {
        console.log("Form has errors:", errorData);
      }
    }
  };

  if (!hasPrivilege("referral settings") && privilege !== null) {
    return (
      <Alert severity="error">
        <AlertTitle>warning</AlertTitle>
        You don't have permission to access — <strong>this page.!</strong>
      </Alert>
    );
  }

  return (
    <>
      <Dialog
        open={open}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        sx={{
          "& .MuiDialog-paper": {
            margin: "0",
            width: "calc(100% - 30px)",
            maxWidth: "850px",
            borderRadius: "4px",
          },
        }}
        PaperProps={{
          style: {
            alignSelf: "flex-start",
          },
        }}
      >
        <form onSubmit={handleSubmit}>
          <DialogTitle
            sx={{
              display: "flex",
              alignSelf: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography
              sx={{
                fontWeight: "700",
                fontSize: "22px",
                color: "#05050F",
              }}
            >
              {modal === "referalDetails" ? "Change values" : "Add FAQ"}
            </Typography>
            <CloseIcon
              sx={{
                fontSize: "30px",
                color: "#000",
                cursor: "pointer",
              }}
              onClick={handleClose}
            />
          </DialogTitle>
          <DialogContent
            sx={{
              "& input": {
                border: "1px solid #ECECEC !important",
                outline: "none",
                padding: "16px",
                width: "100%",
                boxSizing: "border-box",
                borderRadius: "4px",
              },
              "& input::placeholder": {
                color: "#ACB1C6",
              },
              "& textarea": {
                border: "1px solid #ECECEC !important",
                outline: "none",
                padding: "16px",
                width: "100%",
                boxSizing: "border-box",
                resize: "none",
                borderRadius: "4px",
                // mt: "24px",
              },
              "& textarea::placeholder": {
                color: "#ACB1C6",
              },
            }}
          >
            {modal === "faq" && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                  mt: "13px",
                }}
              >
                <Box
                  sx={{
                    "& input": {
                      border: errorData?.question
                        ? "1px solid red !important"
                        : "1px solid #ECECEC !important",
                    },
                    "& input::placeholder": {
                      color: errorData?.question ? "red" : "#ACB1C6",
                    },
                  }}
                >
                  <input
                    type="text"
                    placeholder="Question"
                    onChange={handleChange}
                    name={"question"}
                    value={formData?.question}
                  />
                  <Typography
                    sx={{
                      color: "red",
                      fontSize: "12px",
                    }}
                  >
                    {errorData?.question}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    "& textarea": {
                      border: errorData?.answer
                        ? "1px solid red !important"
                        : "1px solid #ECECEC !important",
                    },
                    "& textarea::placeholder": {
                      color: errorData?.answer ? "red" : "#ACB1C6",
                    },
                  }}
                >
                  <textarea
                    placeholder="Answer"
                    name={"answer"}
                    value={formData?.answer}
                    onChange={handleChange}
                  />
                  <Typography
                    sx={{
                      color: "red",
                      fontSize: "12px",
                    }}
                  >
                    {errorData?.answer}
                  </Typography>
                </Box>
              </Box>
            )}
            {modal === "referalDetails" && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                  mt: "13px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "18px",
                    lineHeight: "24px",
                    color: "#05050F",
                  }}
                >
                  Refer Amount per enrollment
                </Typography>

                <Box
                  sx={{
                    "& input": {
                      border: errorData?.amountPerEnrollment
                        ? "1px solid red !important"
                        : "1px solid #ECECEC !important",
                    },
                    "& input::placeholder": {
                      color: errorData?.amountPerEnrollment ? "red" : "#ACB1C6",
                    },
                  }}
                >
                  <input
                    type="text"
                    placeholder="Enter Amount per enrollment"
                    onChange={handleNumberChange}
                    value={formData?.amountPerEnrollment}
                    name={"amountPerEnrollment"}
                  />
                  <Typography
                    sx={{
                      color: "red",
                      fontSize: "12px",
                    }}
                  >
                    {errorData?.amountPerEnrollment}
                  </Typography>
                </Box>

                <Typography
                  sx={{
                    fontSize: "18px",
                    lineHeight: "24px",
                    color: "#05050F",
                  }}
                >
                  Withdrawal Limit
                </Typography>

                <Box
                  sx={{
                    "& input": {
                      border: errorData?.withDrawLimit
                        ? "1px solid red !important"
                        : "1px solid #ECECEC !important",
                    },
                    "& input::placeholder": {
                      color: errorData?.withDrawLimit ? "red" : "#ACB1C6",
                    },
                  }}
                >
                  <input
                    type="text"
                    placeholder="Enter Withdrawal Limit"
                    onChange={handleNumberChange}
                    name={"withDrawLimit"}
                    value={formData?.withDrawLimit}
                  />
                  <Typography
                    sx={{
                      color: "red",
                      fontSize: "12px",
                    }}
                  >
                    {errorData?.withDrawLimit}
                  </Typography>
                </Box>
              </Box>
            )}
          </DialogContent>
          <DialogActions
            sx={{
              mb: "20px",
              mr: "15px",
            }}
          >
            <Button
              type={"text"}
              sx={{
                padding: "16px 24px",
                color: "white",
                backgroundColor: "rgba(20, 30, 60, 1)",
                borderRadius: "8px",
                fontWeight: 600,
                fontSize: "12px",
                "&:hover": {
                  backgroundColor: "rgba(20, 30, 60, 0.9)",
                },
              }}
            >
              {modal === "referalDetails" ? "Change values" : "Add FAQ"}
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      <Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            gap: "20px",
            mb: "20px",
            borderBottom: "1px solid #ECECEC",
            pb: "20px",
          }}
        >
          <Box>
            <Typography
              sx={{
                fontSize: "18px",
                lineHeight: "24px",
                color: "#05050F",
              }}
            >
              Referral Amount per enrollment :{" "}
              <Typography
                component="span"
                sx={{
                  fontSize: "20px",
                  fontWeight: "700",
                  lineHeight: "24px",
                  color: "#05050F",
                }}
              >
                {referalSettings?.referalAmountPerEnrollment}
              </Typography>{" "}
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: "400",
                lineHeight: "24px",
                color: "#05050F",
                mt: "10px",
              }}
            >
              Withdrawal Limit :{" "}
              <Typography
                component="span"
                sx={{
                  fontSize: "20px",
                  fontWeight: "700",
                  lineHeight: "24px",
                  color: "#05050F",
                }}
              >
                {referalSettings?.withDrawalLimit}
              </Typography>{" "}
            </Typography>
          </Box>
          <Button
            type={"text"}
            sx={{
              padding: "8px 24px",
              color: "#141E3C",
              backgroundColor: "#fff",
              border: "1px solid #141E3C",
              borderRadius: "8px",
              fontWeight: 600,
              fontSize: "12px",
              height: "fit-content",
              textTransform: "capitalize",
              "&:hover": {
                backgroundColor: "#fff",
              },
            }}
            onClick={() => handleOpen("referalDetails")}
          >
            Edit
          </Button>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            mb: "20px",
          }}
        >
          <Typography
            sx={{
              fontSize: "20px",
              lineHeight: "24px",
              color: "#05050F",
            }}
          >
            FAQ
          </Typography>
          <Button
            type={"text"}
            sx={{
              padding: "8px 24px",
              color: "#141E3C",
              backgroundColor: "#fff",
              border: "1px solid #141E3C",
              borderRadius: "8px",
              fontWeight: 600,
              fontSize: "12px",
              height: "fit-content",
              textTransform: "capitalize",
              "&:hover": {
                backgroundColor: "#fff",
              },
            }}
            onClick={() => handleOpen("faq")}
          >
            add
          </Button>
        </Box>

        <ReferalSettingsTable data={referalFaq} subMenu="referral settings" />
      </Box>

      {openAlert && (
        <PremissionDialog
          openAlert={openAlert}
          handleClose={handleCloseDialog}
        />
      )}
    </>
  );
}

export default ReferalSettings;
