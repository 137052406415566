import React, { useEffect, useState } from "react";

import { makeStyles } from "@material-ui/core";

import {
  Box,
  Checkbox,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  findLeaveAction,
  leaveApplicationDeleteAction,
  leaveApplicationStatusChangeAction,
} from "../../../../actions/employeeManagmentAction";
import { SlOptionsVertical } from "react-icons/sl";
import { FcCancel } from "react-icons/fc";
import { AiOutlineDelete } from "react-icons/ai";
import Swal from "sweetalert2";

import "./LeaveShowTable.css";

const useStyles = makeStyles({
  table: {
    borderCollapse: "separate",
    borderSpacing: "18px",
    border: "1px solid #ececec",
  },
  tableRow: {
    "& > *": {
      border: "none",
    },
  },
});
function LeaveShowTable({ employeeId }) {
  const dispatch = useDispatch();
  const { id } = useParams();

  let { findLeaveSuccess } = useSelector((state) => {
    return state.findLeave;
  });

  let { leaveApplicationStatusChangeSuccess } = useSelector((state) => {
    return state.leaveApplicationStatusChange;
  });

  let { leaveApplicationDeleteSuccess } = useSelector((state) => {
    return state.leaveApplicationDelete;
  });

  useEffect(() => {
    dispatch(findLeaveAction(id));
  }, [
    leaveApplicationStatusChangeSuccess,
    leaveApplicationDeleteSuccess,
    dispatch,
    id,
  ]);

  // table action
  const [employeeActionButtonShow, setEmployeeActionButtonShow] =
    useState(null);
  const [tableId, setTableId] = useState("");

  const employeeOpenActionButton = Boolean(employeeActionButtonShow);

  const handleClickAction = (event, id) => {
    setEmployeeActionButtonShow(event.currentTarget);
    setTableId(id);
  };

  const handleCloseAction = () => {
    setEmployeeActionButtonShow(null);
  };

  const columns = [
    {
      title: "Sl no",
      field: "sl",
      // type: "numeric",
      //   hidden: true,
      headerStyle: { backgroundColor: "#F9F9FB" },
    },
    {
      title: "Employee Name",
      field: "name",
      //   hidden: showEmployeeNameColumn,
      headerStyle: { backgroundColor: "#F9F9FB" },
    },
    {
      title: "ID",
      field: "id",
      //   hidden: showEmailColumn,
      headerStyle: { backgroundColor: "#F9F9FB" },
    },
    {
      title: "Leave Type",
      field: "leavetype",
      //   hidden: showMobileColumn,
      headerStyle: { backgroundColor: "#F9F9FB" },
    },
    {
      title: "Start Date",
      field: "startDate",
      //   hidden: showRollColumn,
      headerStyle: { backgroundColor: "#F9F9FB" },
    },
    {
      title: "End Date",
      field: "endDate",
      //   hidden: showRollColumn,
      headerStyle: { backgroundColor: "#F9F9FB" },
    },
    {
      title: "Duration",
      field: "duration",
      //   hidden: showRollColumn,
      headerStyle: { backgroundColor: "#F9F9FB" },
    },
    {
      title: "Status",
      field: "status",
      //   hidden: showRollColumn,
      headerStyle: { backgroundColor: "#F9F9FB" },
    },
    {
      title: "Action",
      field: "action",
      filtering: false,
      headerStyle: { backgroundColor: "#F9F9FB" },
    },
  ];

  const data = findLeaveSuccess?.map((leaveData, index) => ({
    sl: index + 1,
    name: leaveData.leaveEmployeeName,
    id: leaveData.id,
    leavetype: leaveData.leaveType,
    startDate: leaveData.leaveStartDate,
    endDate: leaveData.leaveEndDate,
    duration: leaveData.leaveDuration,
    status: leaveData.leaveStatus,
  }));


  const formateDate = (timestampString) => {
    const date = new Date(timestampString);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); 
    const day = String(date.getDate()).padStart(2, "0");
    return timestampString ? `${year}-${month}-${day}` : "-";
  };



  return (
    <Table className="leaveShowTable">
      <TableHead>
        <TableRow className="leaveShowTableCell">
          <TableCell>
            <Checkbox
              // checked={selectedRows.length === sortedData.length}
              // onChange={handleSelectAllRows}
              style={{ color: "#f81b82" }}
            />
          </TableCell>
          {columns?.map((column) => {
            if (column.hidden) {
              return null;
            }
            return (
              <TableCell key={column.field} className="leaveShowTableCell">
                <TableSortLabel
                  className="leaveShowHeaderText"
                  // active={column === sortBy}
                  // direction={sortOrder}
                  // onClick={() => requestSort(column)}
                >
                  {column.title}
                </TableSortLabel>
              </TableCell>
            );
          })}
        </TableRow>
      </TableHead>
      <TableBody>
        {data?.map((item, indx) => (
          <TableRow className="leaveShowTable" key={indx}>
            <TableCell>
              <Checkbox
                // checked={selectedRows.includes(item.id)}
                // onChange={() => handleSelectRow(item.id)}
                style={{ color: "#f81b82" }}
              />
            </TableCell>
            <TableCell>{item.sl}</TableCell>
            <TableCell>{item.name}</TableCell>

            <TableCell>{employeeId}</TableCell>

            <TableCell>{item.leavetype}</TableCell>

            <TableCell>{formateDate(item.startDate)}</TableCell>
            <TableCell>{formateDate(item.endDate)}</TableCell>
            <TableCell>{item.duration}</TableCell>
            <TableCell>{item.status}</TableCell>
            <TableCell>
              <SlOptionsVertical
                id="demo-positioned-button"
                aria-controls={
                  employeeOpenActionButton ? "demo-positioned-menu" : undefined
                }
                aria-haspopup="true"
                aria-expanded={employeeOpenActionButton ? "true" : undefined}
                onClick={(e) => {
                  handleClickAction(e, item.id);
                }}
                variant="outlined"
                style={{ marginLeft: "10px", color: "#F80B7A" }}
              />
              <Menu
                open={employeeOpenActionButton}
                onClose={handleCloseAction}
                anchorEl={employeeActionButtonShow}
                placement="bottom"
                disablePortal
                modifiers={{
                  flip: {
                    enabled: true,
                  },
                }}
                style={{
                  marginLeft: "-59px",
                  marginTop: "10px",
                  color: "#F80B7A",
                }}
              >
                <Box style={{ display: "flex", justifyContent: "flex-end" }}>
                  <MenuItem>
                    <FcCancel
                      style={{ fontSize: "lg", width: "50px", height: "23px" }}
                      onClick={() =>
                        dispatch(
                          leaveApplicationStatusChangeAction(tableId, "cancel")
                        )
                      }
                    />
                  </MenuItem>

                  <MenuItem>
                    <AiOutlineDelete
                      style={{ fontSize: "lg", width: "50px", height: "23px" }}
                      onClick={() => {
                        setEmployeeActionButtonShow(null);
                        Swal.fire({
                          title: "Are you sure?",
                          text: "You won't be able to revert this!",
                          icon: "warning",
                          showCancelButton: true,
                          confirmButtonColor: "#3085d6",
                          cancelButtonColor: "#d33",
                          confirmButtonText: "Yes, delete it!",
                        }).then((result) => {
                          if (result.isConfirmed) {
                            dispatch(leaveApplicationDeleteAction(tableId));
                            Swal.fire(
                              "Deleted!",
                              "Your leave application has been deleted.",
                              "success"
                            );
                          }
                        });
                      }}
                    />
                  </MenuItem>
                </Box>
              </Menu>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
      <TableFooter />
    </Table>
  );
}

export default LeaveShowTable;
